import React from "react";

const NoRecordFound = ({ title }: any) => {
  return (
    <div className="w-full flex-1 justify-center items-center flex min-h-[45vh] bg-bgWhite dark:bg-bgtetriary">
      <p className="text-lg font-medium text-secondaryLight dark:text-textMain">
        No Record Found
      </p>
    </div>
  );
};

export default NoRecordFound;
