import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (cellValue !== null && columnDef.id === "tagName") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3  w-[50%] ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap rounded-lg py-1 px-2 bg-primaryExtraLight`}>
          {ellipsize(`${cellValue}`, 150)}
        </p>
      </div>
    );
  }
  if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3  w-[50%] ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 150)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
