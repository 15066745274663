import {
  ERROR_LOADING_FILES,
  GET_ALL_FILES,
  START_LOADING_FILES,
  STOP_LOADING_FILES,
  RESET_FILES_FILTER_ACTIONS,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";
export const fetchFilesAction =
  (
    moduleId: any,
    moduleName: string,
    searchString?: string,
    sortBy?: string,
    sortType?: string
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_FILES });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const { data } = await apiLibrary.Files.getAllFiles(
        moduleId,
        moduleName,
        searchString,
        sortType,
        sortBy
      );

      dispatch({
        type: GET_ALL_FILES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({ type: ERROR_LOADING_FILES });
    } finally {
      dispatch({ type: STOP_LOADING_FILES });
      // dispatch(setLoadingProgress(100));
    }
  };
export const filesResetFilterActions = () => {
  return {
    type: RESET_FILES_FILTER_ACTIONS,
  };
};
