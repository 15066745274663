import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { AddImportBtnProps } from "../../types";

const AddImportBtn = ({
  handleAddImport,
  label,
}: AddImportBtnProps) => {
  return (
    <button
      onClick={handleAddImport}
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition"
    >
      <div className="flex justify-center items-center relative pt-1.5 pb-[7px] ">
        <ArrowUpTrayIcon className="flex-shrink-0 mr-2 text-primary dark:text-primaryOutlined dark:group-hover:text-primary w-5" />
        <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary dark:text-primaryOutlined dark:group-hover:text-primary">
          {label}
        </p>
      </div>
    </button>
  );
};

export { AddImportBtn };
