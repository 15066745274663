import { useRef } from "react";
import { useInitializeMap } from "../../hooks";
import {
  useGetCurrentQuestionState,
  useHandleMapMovement,
  useHandleMovementOfMapCenterPoint,
} from "./hooks";

const Map = ({ questionId }: any) => {
  const mapContainer = useRef<any>(null);

  // initializing the map
  const { map } = useInitializeMap(mapContainer);

  // getting question state from global states
  const currentQuestionEle: any = useGetCurrentQuestionState(questionId);
  // handle map center point property
  useHandleMovementOfMapCenterPoint(currentQuestionEle, map);

  // handle movement of map
  useHandleMapMovement(currentQuestionEle, map);

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
