import React from 'react'

export const QualityAssuranceProfile = () => {
  return (
    <div>
      <h1>Quality Assurance Profile</h1>
    </div>
  )
}

export default QualityAssuranceProfile
