import React, { useState } from "react";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile/initialState";

export const MissionStatement = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const [showFullText, setShowFullText] = useState(false);

  const maxChars = 290;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className=" bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight rounded-lg shadow-md hover:shadow-2xl lg:p-4 xl:p-6  mb-[16px]">
      <h4 className="pb-2 text-base font-semibold text-secondaryMid dark:text-textMain">
        Description
      </h4>

      <p className="text-sm font-normal text-secondaryMid dark:text-caption ">
        {showFullText
          ? communityProfile?.missionStatement
          : communityProfile?.missionStatement?.slice(0, maxChars)}
      </p>

      {communityProfile?.missionStatement?.length > maxChars && (
        <button onClick={toggleText} className="flex items-center mt-2">
          <h5 className="pr-1 text-sm font-semibold text-secondaryMid dark:text-paleSkyBlue">
            {showFullText ? "See Less" : "See More"}
          </h5>
          {showFullText ? (
            <CheveronUpIcon height={16} />
          ) : (
            <CheveronDownIcon height={16} />
          )}
        </button>
      )}
    </div>
  );
};
