import { apiClient } from "../config";

async function deleteSecondaryEmailOrPhone(payload: any, personId: any) {
  const res = await apiClient.put(
    `/admin/profile/secondary-email-phone/${personId}`,
    {
      phone_or_email: payload,
    }
  );
  return res.data;
}

export default deleteSecondaryEmailOrPhone;
