import React from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { reportsStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps { }

export const Tabs: React.FC<TabsProps> = ({ }) => {
  const { reportsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  const TabsData = [
    {
      label: "Active",
      value: "submitted"
    },
    {
      label: "Draft",
      value: "draft"
    },
    {
      label: "Past",
      value: "past"
    },
    {
      label: "Archived",
      value: "archived"
    }
  ]

  //handlers
  const handleLink = (linkName: string) => {
    dispatch(reportsStatusAction(linkName));
  };

  return (
    <>
      {TabsData?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${reportsFilter?.status === item.value
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
              }`}
            onClick={() => handleLink(item.value)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${reportsFilter.status === item.value
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                  }`}
              >
                {sentenceCase(item.label ?? "")}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
