import { ERROR_LOADING_CATEGORIES, GET_ALL_CATEGORIES, RESET_DATA, START_LOADING_CATEGORIES, STOP_LOADING_CATEGORIES } from "./action-types";
import initialState from "./initialState";

export const categoriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_CATEGORIES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_CATEGORIES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_CATEGORIES:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_CATEGORIES:
      const categories = action.payload.categories.map((item: any) => {
        const { categoryName, id, categoryCode } = item;
        const data = {
          id: id,
          categoryName: {
            name: categoryName,
            id: id,
          },
          categoryCode,
        };

        return data;
      });

      return {
        ...state,
        data: {
          categories: categories,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
