import { useEffect } from "react";
import { debounce } from "lodash";
import calculateMultiEntityZoomLevel from "utils/calculateMultiEntityZoomLevel";

const useApplyFitboundsToTheMap = (
  allCommunityLayers: any[],
  map: any,
  isMapFullyLoaded: any
) => {
  const updateBounds = debounce(() => {
    if (
      allCommunityLayers &&
      allCommunityLayers.length > 0 &&
      map &&
      isMapFullyLoaded
    ) {
      const calculation = calculateMultiEntityZoomLevel(
        allCommunityLayers.filter(
          (item) => item.mapData !== null && item.type !== "raster"
        ),
        map
      );

      if (calculation?.bounds && calculation?.maxZoom) {
        map.fitBounds(calculation.bounds, {
          padding: 40,
          // maxZoom: calculation.maxZoom,
        });
      }
    }
  }, 300);

  // map?.on("fullscreenchange", () => {
  //   console.log("hellow updateBounds");
  //   updateBounds();
  // });

  useEffect(() => {
    updateBounds();
  }, [map, isMapFullyLoaded, allCommunityLayers]);
};

export { useApplyFitboundsToTheMap };
