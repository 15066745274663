import { Dispatch } from "redux";
import {
  GET_ALL_REPORTS,
  RESET_DATA,
  START_LOADING_REPORTS,
  STOP_LOADING_REPORTS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";

export const fetchReportsAction = (programId?:any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_REPORTS });
    // dispatch(setLoadingProgress(getRandomProgress()));

    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState?.Filters?.reportsFilter
      );
      const query = appendOrganizationIdInParams(programId, queryString);
      const { data } = await apiLibrary.Reports.getAllReports(query);
      dispatch({
        type: GET_ALL_REPORTS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the reports:", error);
    } finally {
      dispatch({ type: STOP_LOADING_REPORTS });
      // dispatch(setLoadingProgress(100));
    }
  };
const appendOrganizationIdInParams = (programId: any, params: any) => {
  let updatedParams = { ...params };

  if (programId) {
    updatedParams.programIds = programId.toString();
  }

  return updatedParams;
};
