import React, { useEffect, useState } from "react";
/************ Components ************/
import Button from "view/components/Button";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import PopOverMenu from "view/components/PopOverMenu";
// import Breadcrumb from 'view/components/Breadcrumbs';
import { Breadcrumb } from "Components/Users/BreadCrumb";
import ProfileSideBar from "../ProfileSideBar";
/************ hooks ************/
import UseRoles from "hooks/roles";
import useActions from "hooks/useActions";
/************ Utils ************/
/************ API Services ************/
import apiLibrary from "services/api";
/************ Store Utils ************/
import {
  fetchUserProfileAction,
  resetData,
} from "store/userProfile/reducer.actions";
import { UserProfileState } from "store/userProfile/initialState";
import { ThunkDispatch } from "redux-thunk"; // Import ThunkDispatch
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
/************ Assets ************/
import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";
/************ External Library ************/
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Head from "view/components/Head";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";

interface UserProfile {
  firstName?: string;
  lastName?: string;
}

export const ProfileLayout = () => {
  const { personId } = useParams();
  const { isMyProfile } = UseRoles();
  const location = useLocation();
  const pathname = location.pathname;
  const { userActions } = useActions();
  const { users } = usePermissions();
  const navigate = useNavigate();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const generateDynamicUsersLinks = (
    userProfile: UserProfile,
    personId: any,
    isMyProfile: boolean
  ) => {
    switch (true) {
      case Boolean(isMyProfile):
        return [{ path: `/profile/${personId}/person`, label: `My Profile` }];
      case Boolean(userProfile?.firstName && userProfile?.lastName):
        return [
          { path: users.canViewUsersInSideBar && `/users/list`, label: `People` },
          {
            path: `/profile/${personId}/person`,
            label: `${userProfile?.firstName} ${userProfile?.lastName}`,
          },
          { path: `/profile/${personId}/person`, label: `Person` },
        ];
      default:
        return [
          { path: `/users/list`, label: `People` },
          { path: `/profile/${personId}/person`, label: `Person` },
          //
        ];
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const dynamicUsersLinks = generateDynamicUsersLinks(
    userProfile ?? "",
    personId ?? "",
    isMyProfile(personId)
  );

  useEffect(() => {
    if (users.canViewUsersProfile) {
      dispatch(fetchUserProfileAction(personId, isMyProfile(personId)));
    }
  }, [personId]);

  // useEffect(() => {
  //   const fetchUserProfile = async() => {
  //     setIsLoading(true)
  //     dispatch(resetData());
  //     try{
  //       await dispatch(fetchUserProfileAction(personId, isMyProfile(personId)));
  //     } catch{

  //     }finally{
  //       setIsLoading(false);
  //     }
  //   }
  //   fetchUserProfile();
  // }, [personId]);

  /**
   * Handles the action of composing and opening an email client with a pre-filled recipient.
   */
  const handleSendEmail = () => {
    const mailtoLink = `mailto:${
      userProfile?.user?.email
        ? userProfile?.user?.email
        : userProfile?.secondaryEmail.find(
            (item: any) => item?.isPrimary !== true
          )?.email
    }`;
    window.location.href = mailtoLink;
  };

  /**
   * Generates user actions based on the provided data.
   *
   * @param {any} data - Data object containing user information.
   * @returns {UserActions} User actions based on the data.
   */
  const getUserActions = (data: any) => {
    const modalData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      imageUrl:
        data?.personImage?.file !== null
          ? data?.personImage?.file?.original
          : getBrandPlaceholder("userProfile"),
      personId: data?.personId,
      userId: data.user.id,
      email: data?.user?.email,
      phoneNumber: data?.user?.phoneNumber,
      contactPreference: data.contactPreference,
      virtual: data.virtual,
      status: data.status,
      path: "userProfile",
    };
    return userActions(modalData);
  };

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-10">
      <Head
        title={`My Profile: ${userProfile?.firstName + " " + userProfile?.lastName}`}
      />
      <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex items-start self-stretch justify-start flex-grow">
          <div className="flex flex-col items-start justify-start flex-grow gap-2">
            <div className="flex justify-between align-items-center">
              <Breadcrumb links={dynamicUsersLinks} />
            </div>
            <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <div className="flex flex-col justify-start items-start flex-grow py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow w-full text-[28px] overflow-hidden text-overflow-ellipsis   max-h-32 font-bold text-left text-secondaryMid break-all dark:text-textMain">
                    {userProfile?.firstName} {userProfile?.lastName}
                  </p>
                </div>
              </div>

              {!isMyProfile(personId) &&
                userProfile.virtual === true &&
                userProfile.status !== "deleted" && (
                  <div className="flex items-center justify-between align-items-center">
                    <Button
                      onClick={handleSendEmail}
                      type="button"
                      text="Send Email"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                    />
                    <PopOverMenu
                      icon={
                        <Button
                          text={<PerimeterIcon />}
                          filledColor="primary"
                          outlinedColor="primary"
                          textColor="textWhite"
                          className="px-5 py-2"
                          width="35"
                          height="10"
                          fontStyle="font-semibold"
                          variant="outlined"
                        />
                      }
                    >
                      <ActionDropDownMenu
                        data={userProfile}
                        actions={getUserActions(userProfile)}
                      />
                    </PopOverMenu>
                  </div>
                )}
            </div>
            <div
              className="relative flex w-full mt-3 rounded-lg bg-bgWhite dark:border-lineLight"
              style={{
                boxShadow:
                  "0px 0px 1px 0 rgba(2,13,36,0.14), 0px 1px 2px 0 rgba(2,13,36,0.08)",
              }}
            >
              <ProfileSideBar personId={personId} />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileLayout;
