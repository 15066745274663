import React from "react";

const Title = ({ handleTitleOnChange, value }: any) => {
  return (
    <div>
      <h2 className="mb-2 text-secondaryMidLight">Title</h2>
      <input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTitleOnChange(e.target.value)
        }
        value={value}
        className="flex justify-start items-center w-full focus:outline-none gap-1.5 px-3 py-2 bg-bgWhite rounded bg-white border border-lineDark"
      />
    </div>
  );
};

export { Title };
