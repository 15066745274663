import { convertGeoJSON } from "utils/convertFeatureCollection";

function handleCreationOfMarkerOnMap(item: any, map: any, mapboxgl: any) {
  const processFeature = (feature: any, featureId: any) => {
    if (feature.geometry && feature.geometry.type === "Point") {
      const coordinates = feature.geometry.coordinates;
      const marker = new mapboxgl.Marker({ draggable: false })
        .setLngLat(coordinates)
        .addTo(map);
    }
  };

  const geojsonData = convertGeoJSON(item?.mapData);

  if (geojsonData?.type === "Feature") {
    processFeature(geojsonData, item.id);
  } else if (geojsonData?.type === "FeatureCollection") {
    geojsonData.features.forEach((feature: any) =>
      processFeature(feature, item.id)
    );
  } else {
    console.error("Invalid GeoJSON data");
  }
}

export { handleCreationOfMarkerOnMap };
