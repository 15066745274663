import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateDropPin, updateDropPins } from "store/surveyJS";
import { v4 as uuidv4 } from "uuid";

import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import {
  AbleToSelectMultiple,
  Description,
  MapCenterPoint,
  Required,
  SelectLayers,
  Snapping,
  Title,
} from "./components";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";
import { MapZoomLevel } from "./components/MapZoomLevel";
import _ from "lodash";
import MarkerGroupsDropDown from "Components/Geography/SideBar/components/MarkerGroupsDropDown";
import MarkerStylesSelector from "Components/Geography/SideBar/components/MarkerStylesSelector";
import { IfetchSurveyJsFormDatails } from "store/formDetailsSurveyJsFields";
interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
const DropPinSidebar = () => {
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchSurveyJsFormDatails
  >((state) => state.formSurveyJsDetails);
  const { selectedQuestionElement, dropPins } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const [currentDropPin, setCurrentDropPin] = useState<any>({});

  const dispatch = useDispatch();

  // structuring data of default points according to dropdown component
  const defaultLayersSelectedOptions = currentDropPin.selectedDefaultLayers;

  const defaultLayersOptions = _.differenceBy(
    currentDropPin.allGlobalCommunityLayers,
    currentDropPin.selectedDefaultLayers,
    "id"
  );

  useEffect(() => {
    if (selectedQuestionElement) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentDropPin]);

  // recognize and set current point picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;
    if (questionId) {
      const ele = dropPins.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentDropPin({ ...ele, title, description });
      }
    }
  }, [selectedQuestionElement, dropPins]);

  // handlers
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    if (name === "zoomLevel") {
      updatedCurrentDropPin.zoomLevel = parseFloat(value);
    } else {
      updatedCurrentDropPin.mapCenterPoint = {
        ...updatedCurrentDropPin.mapCenterPoint,
        doFly: false,
        [name]: value,
      };
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentDropPin = { ...currentDropPin };

    // update current element
    updatedCurrentDropPin.mapCenterPoint = {
      ...updatedCurrentDropPin.mapCenterPoint,
      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleClickOnZoomLevelBtn = () => {
    const updatedCurrentDropPin = { ...currentDropPin };

    if (isNaN(updatedCurrentDropPin.zoomLevel)) {
      return;
    }
    // update current element
    updatedCurrentDropPin.mapCenterPoint = {
      ...updatedCurrentDropPin.mapCenterPoint,
      ...updatedCurrentDropPin.zoomLevel,

      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleAbleToSelectMultipleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.ableToCheckMultiple = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleEnableSnappingCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.enableSnapping = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleDefaultLayersOnSelection = (option: OptionType) => {
    const { selectedDefaultLayers } = currentDropPin;

    // Check if the layer already exists
    const existingIndex = selectedDefaultLayers.findIndex(
      (item: OptionType) => item.id === option.id
    );

    let updatedSelectedDefaultLayers;

    if (existingIndex !== -1) {
      // Replace the existing layer with the new option
      updatedSelectedDefaultLayers = [
        ...selectedDefaultLayers.slice(0, existingIndex),
        option,
        ...selectedDefaultLayers.slice(existingIndex + 1),
      ];
    } else {
      // Add the new option since it doesn't exist
      updatedSelectedDefaultLayers = [...selectedDefaultLayers, option];
    }

    // Update the currentDropPin with new or replaced layers
    const updatedCurrentDropPin = {
      ...currentDropPin,
      selectedDefaultLayers: updatedSelectedDefaultLayers,
    };
    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleDefaultLayersOnRemove = (option: OptionType) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.selectedDefaultLayers =
      currentDropPin.selectedDefaultLayers.filter(
        (l: OptionType) => l.id !== option.id
      );

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleMarkerGroupSelectionChange = (value: any) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.markerGroup = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.markerGroup = value;


    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleMarkerStyleSelectionChange = (value: any) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.markerStyle = {
      groupId: updatedCurrentDropPin.markerGroup.id,
      id: value.id,
      markerStyleId: uuidv4(),
      styleImage: value.markerStyleImage.thumb,
    };

    if (selectedQuestionElement) {
      selectedQuestionElement.markerStyle = {
        groupId: updatedCurrentDropPin.markerGroup.id,
        id: value.id,
        markerStyleId: uuidv4(),
        styleImage: value.markerStyleImage.thumb,
      };
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  return (
    <div className="flex flex-col py-[40px] px-[32px] bg-[#F3F5F7]  w-full h-full gap-8">
      <Title
        handleTitleOnChange={handleTitleOnChange}
        value={currentDropPin.title}
      />
      <Description
        handleDescriptionOnChange={handleDescriptionOnChange}
        value={currentDropPin.description}
      />
      <Required
        handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
        checked={currentDropPin.isRequired}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentDropPin.mapCenterPoint}
      />

      {/* <MarkerGroupsDropDown
        error={null}
        handleGroupSelectorOnChange={(Option: any) => {
          handleMarkerGroupSelectionChange({
            id: Option.value,
            name: Option.label,
          });
        }}
        programId={formDetails.programId}
        title={"Marker Group"}
        value={{
          label: currentDropPin.markerGroup?.name,
          value: currentDropPin.markerGroup?.id,
        }}
      /> */}
      {/* <MarkerStylesSelector
        communityId={null}
        programId={formDetails.programId}
        groupId={{
          label: currentDropPin.markerGroup?.name,
          value: currentDropPin.markerGroup?.id,
        }}
        error={null}
        handleStyleSelectorOnChange={(Option: any) => {
          handleMarkerStyleSelectionChange(Option);
        }}
        title={"Marker  Style"}
        value={currentDropPin?.markerStyle?.id}
      /> */}
      <MapZoomLevel
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnZoomLevelBtn={handleClickOnZoomLevelBtn}
        zoomLevel={currentDropPin.zoomLevel}
      />
      <div>
        <AbleToSelectMultiple
          handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
          ableToCheckMultiple={currentDropPin.ableToCheckMultiple}
        />
        <Snapping
          enableSnapping={currentDropPin.enableSnapping}
          handleCheckboxOnChange={handleEnableSnappingCheckboxOnChange}
        />
      </div>
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      />
    </div>
  );
};

export { DropPinSidebar };
