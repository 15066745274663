import { GET_NOTIFICATION_SUMMARY } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchNotificationSummaryProfileAction =
  (id: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.NotificationsSummary.getDetailsofNotificationsummary(
          id
        );

      dispatch({
        type: GET_NOTIFICATION_SUMMARY,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/organizations/list";
    }
  };

export const resetNotificationSummaryAction = () => {
  return {
    type: "RESET_NOTIFICATIONS_SUMMARY_DATA",
  };
};
