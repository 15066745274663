import React, { useEffect, useLayoutEffect, useState } from "react";
import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import Button from "view/components/Button";
import TextInput from "view/components/InputField";
import apiLibrary from "services/api";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { Box, Modal } from "@mui/material";
import { Toasts } from "view/components/Toasts";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeAssignProgramAdminPermissionToImportModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import _ from "lodash";
import { Admin } from "./Admin";
import { fetchImportsAction } from "store/imports";

const style: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#ffff",
};

export const AssignProgramAdminPermissionToImportModal = () => {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const { assignProgramAdminPermissionToImportModal } = useSelector((state: RootState) => state.modals);

    const [searchString, setSearchString] = useState("");
    const [entitiesList, setEntitiesList] = useState<any[]>([]);
    const [permittedIds, setPermittedIds] = useState<any>([]);
    const [removedIds, setRemovedIds] = useState<any>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [page, setPage] = useState(1);

    const removeAllEntities = (): void => {
        setEntitiesList((prevEntitiesList) => {
            // Map through prevEntitiesList and set obsImportPermission to false for each entity
            const updatedEntitiesList = prevEntitiesList.map(entity => ({
                ...entity,
                obsImportPermission: false  // Set obsImportPermission to false
            }));

            return updatedEntitiesList;
        });


        setPermittedIds([]);
    };

    const handleClose = () => {
        dispatch(closeAssignProgramAdminPermissionToImportModalAction());
    };

    const getAllProgramAdmins = async (
        isLoadMore: boolean,
        page: number,
        searchString: string
    ) => {
        if (!isLoadMore) {
            setIsLoading(true);
        }

        try {
            const { data } = await apiLibrary.Imports.getProgramAdmins(
                searchString,
                page
            );

            setEntitiesList(
                isLoadMore ? (prevList) => [...prevList, ...data?.admins] : data?.admins
            );

            if (!isLoadMore) {
                setPermittedIds(data?.permittedIds);
            }

            setTotalPages(data.totalPages);
            setHasMoreData(data.totalPages > page);

        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllProgramAdmins(false, 1, searchString);
    }, []);

    const handleLoadMore = () => {
        setPage((prevPage) => {
            const page = prevPage + 1;
            getAllProgramAdmins(true, page, searchString);
            return page;
        });
    };

    const searchProgramAdmin = async (
        page: number,
        searchString: string,
    ) => {
        setIsLoading(true);

        try {
            const { data } = await apiLibrary.Imports.getProgramAdmins(
                searchString,
                page
            );

            const permittedEntities = data?.admins?.map((admin: any) => {
                if (permittedIds.includes(admin.userId)) {
                    admin.obsImportPermission = true;
                }

                else {
                    admin.obsImportPermission = false;
                }

                return {
                    ...admin
                }
            })

            setEntitiesList(permittedEntities);
            setTotalPages(data.totalPages);
            setHasMoreData(data.totalPages > page);
        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchProgramAdmin = (
        searchString: string
    ): void => {
        setPage((pre: number) => {
            searchProgramAdmin(
                1,
                searchString,
            );
            return 1;
        });
    };

    const debouncedSearch = _.debounce(
        handleSearchProgramAdmin,
        100
    );

    const handleToggleAdminPermission = (admin: any, value: boolean) => {
        setEntitiesList((prevEntitiesList) => {
            // Map through prevEntitiesList to find and update the matching admin entity
            const updatedEntitiesList = prevEntitiesList.map(entity => ({
                ...entity,
                obsImportPermission: entity.userId === admin.userId ? value : entity.obsImportPermission
            }));

            return updatedEntitiesList;
        });

        if (value) {
            setPermittedIds([...permittedIds, admin.userId])
        }

        else {
            const removedUsers = permittedIds?.filter((id: number) => id !== admin.userId)
            setPermittedIds(removedUsers)
        }
    };

    const handleSaveAdminPermissions = async () => {

        setLoading(true);

        try {

            const body = {
                userIds: permittedIds
            }

            const { data, message } = await apiLibrary.Imports.grantPermissionToImport(
                body
            );
            handleClose();
            Toasts.success(message);
            dispatch(fetchImportsAction());
        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={assignProgramAdminPermissionToImportModal.isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            disableAutoFocus={true}
            aria-describedby="modal-modal-description"
            className="border-none"
        >
            <Box sx={style} className="dark:bg-secondaryLight ">
                <div className="bg-bgWhite w-[700px] max-h-[95vh] rounded-lg dark:bg-secondaryLight">
                    <div className="flex flex-col items-start self-stretch justify-start p-4 bg-white rounded-lg">
                        <div className="w-full">
                            <div className="flex items-center justify-items-center">
                                <div className="flex flex-col items-start self-stretch justify-center w-full">
                                    <div className="flex items-center self-stretch justify-start gap-2">
                                        <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                                            <p className="self-stretch capitalize w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                                                Choose which admin you want to grant import permissions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    title="close"
                                    type="button"
                                    onClick={handleClose}
                                    className="ml-8 cursor-pointer"
                                >
                                    <XCloseIcon />
                                </button>
                            </div>

                            <div className="flex flex-col items-start self-stretch justify-start bg-white">
                                <div className="flex flex-col items-start self-stretch justify-start gap-1 pt-4 pb-2">
                                    <div className="w-full">
                                        <TextInput
                                            type="Search"
                                            placeholder="Search Admin"
                                            value={searchString}
                                            onChange={(e: any) => {
                                                setSearchString(e.target.value);
                                                debouncedSearch(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                {isLoading ? (
                                    <div className="loader min-h-[42vh] justify-center items-center flex w-full my-4">
                                        <TailSpin
                                            height="50"
                                            width="50"
                                            color="#005C89"
                                            ariaLabel="tail-spin-loading"
                                            radius="2"
                                            wrapperStyle={{}}
                                            wrapperClass="tailspin-loader"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full px-1">
                                        <>

                                            <div className="w-full">
                                                <div className="flex justify-between p-3">
                                                    <div className="flex gap-2">
                                                        {/* <div>
                                                            <UsersIcon />
                                                        </div> */}
                                                        <div className="flex w-full gap-1">
                                                            <p className="font-medium text-md text-textMid dark:text-textMain">
                                                                Admins
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeAllEntities()}
                                                    >
                                                        <p className="text-primary">Clear All</p>
                                                    </button>
                                                </div>
                                                <div className="rounded-lg border border-lineMid dark:border-lineLight min-h-[40vh] max-h-[40vh]">
                                                    <InfiniteScroll
                                                        dataLength={entitiesList.length}
                                                        next={handleLoadMore}
                                                        hasMore={hasMoreData}
                                                        height={vhToPixels(40)}
                                                        loader={
                                                            <div className="flex items-center justify-center w-full loader">
                                                                <TailSpin
                                                                    height="30"
                                                                    width="30"
                                                                    color="#005C89"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="2"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="tailspin-loader"
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        }
                                                        pullDownToRefresh={true}
                                                        refreshFunction={() => { }}
                                                        pullDownToRefreshContent={
                                                            <h3 style={{ textAlign: "center" }}>
                                                                &#8595; Pull down to refresh &#8595;
                                                            </h3>
                                                        }
                                                        releaseToRefreshContent={
                                                            <h3 style={{ textAlign: "center" }}>
                                                                &#8593; Release to refresh &#8593;
                                                            </h3>
                                                        }
                                                    >
                                                        <>
                                                        </>
                                                        {entitiesList?.map((admin, index) => (
                                                            <Admin
                                                                key={index}
                                                                adminData={admin}
                                                                toggleAdminPermission={(admin: any, value: boolean) => {
                                                                    handleToggleAdminPermission(admin, value)
                                                                }}
                                                            />
                                                        ))}
                                                    </InfiniteScroll>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 pt-4">
                            <Button
                                type="button"
                                text="Cancel"
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="w-24 h-11"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="outlined"
                                disabled={isLoading}
                                onClick={handleClose}
                            />

                            <Button
                                type="button"
                                text="Save"
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="w-24 h-11"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="filled"
                                disabled={loading}
                                onClick={handleSaveAdminPermissions}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};


function vhToPixels(vh: number) {
    return window.innerHeight * (vh / 100);
}