import { MenuItem, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InputField from "view/components/InputField";
// types

interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;  
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
type SearchableDropdownProps = {
  options: OptionType[];
  onAddSelection: (option: OptionType) => void;
};

const SearchableDropdown = ({
  options = [],
  onAddSelection,
}: SearchableDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownDirection, setDropdownDirection] = useState<"up" | "down">(
    "down"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const determineDropdownDirection = () => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;
      const estimatedDropdownHeight = 200;

      if (spaceBelow < estimatedDropdownHeight && spaceAbove > spaceBelow) {
        setDropdownDirection("up");
      } else {
        setDropdownDirection("down");
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      determineDropdownDirection();
    }
  }, [isOpen]);

  const filteredOptions = options.filter((option) =>
    option.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (option: OptionType) => {
    setSelectedOption(option);
    onAddSelection(option);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <InputField
        placeholder="Search"
        type="Search"
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
        onFocus={() => setIsOpen(true)}
        className="bg-bgWhite"
      />
      {isOpen && (
        <ul
          className={`absolute z-10 left-0 ${
            dropdownDirection === "up" ? "bottom-full" : ""
          } mt-1 w-full bg-bgWhite shadow-lg max-h-60 rounded-md text-base overflow-auto focus:outline-none sm:text-sm`}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="relative py-2 pl-10 pr-4 text-gray-900 cursor-pointer select-none hover:bg-gray-100"
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>

    // <div className="relative" ref={dropdownRef}>
    //   <TextField
    //     type="text"
    //     size="small"
    //     value={searchTerm}
    //     onChange={(e) => setSearchTerm(e.target.value)}
    //     onFocus={() => setIsOpen(true)}
    //     placeholder="Search"
    //     variant="outlined"
    //     fullWidth
    //     InputProps={{
    //       className:
    //         "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 pl-4 pr-10 ",
    //     }}
    //   />
    //   {isOpen && (
    //     <ul
    //       className={`absolute z-10 left-0 ${
    //         dropdownDirection === "up" ? "bottom-full" : ""
    //       } mt-1 w-full bg-bgWhite shadow-lg max-h-60 rounded-md text-base overflow-auto focus:outline-none sm:text-sm`}
    //     >
    //       {filteredOptions.map((option, index) => (
    //         <MenuItem
    //           key={index}
    //           className="relative py-2 pl-10 pr-4 cursor-pointer select-none hover:bg-gray-100"
    //           onClick={() => handleSelect(option)}
    //         >
    //           {option.label}
    //         </MenuItem>
    //       ))}
    //     </ul>
    //   )}
    // </div>
  );
};

// hook to detect outside click
const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export { SearchableDropdown };
