import { Dispatch } from "redux";
import { ERROR_LOADING_GEOGRAPHIES, GET_ALL_GEOGRAPHIES, RESET_DATA, START_LOADING_GEOGRAPHIES, STOP_LOADING_GEOGRAPHIES } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchAdminGeographyAction =
  () => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({type: START_LOADING_GEOGRAPHIES});
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.geographyFilter
      );

      const { data } = await apiLibrary.geography.getAllLayersList(params);
      dispatch({
        type: GET_ALL_GEOGRAPHIES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({type: ERROR_LOADING_GEOGRAPHIES})
      // dispatch(fetchError(error))
    } finally {
      dispatch({type: STOP_LOADING_GEOGRAPHIES})
      // dispatch(loadingState(false))
    }
  };
