import sortObjectByOrder from "utils/SortObjectByOrder";
import { ERROR_LOADING_COMMUNITIES, GET_ALL_COMMUNITIES, RESET_DATA, START_LOADING_COMMUNITIES, STOP_LOADING_COMMUNITIES } from "./action-types";
import initialState from "./initialState";
import { v4 as uuidv4 } from "uuid";

import { communityDirectoryOrder } from "utils/directoryColumnsOrders";
export const communitiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_COMMUNITIES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_COMMUNITIES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_COMMUNITIES:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_COMMUNITIES:
      const communities = action.payload.communities.map((item: any) => {
        const {
          communityName,
          organizations,
          type,
          programs,
          members,
          dateAdded,
          profileImage,
          backgroundImage,
          id,
          status,
          accessibleForSuperAdmin,
          communityAdmins,
          location,
          openAccess,
        } = item;
        const data = {
          id: id,
          communityName: {
            name: communityName,
            profileImage,
            backgroundImage,
            id: id,
            status,
            accessibleForSuperAdmin,
          },
          organizations: organizations?.join(","),
          type,
          programs,
          members,
          dateAdded,
          openAccess,
          communityAdmins: communityAdmins
            .map((item: any) => `${item.firstName} ${item.lastName}`)
            .join(","),
          location: convertLocationToPointsGeoJson(location, {
            name: communityName,
            profileImage,
            id: id,
            status,
            accessibleForSuperAdmin,
          }),
        };

        return sortObjectByOrder(data, communityDirectoryOrder);
      });

      return {
        ...state,
        data: {
          communities: communities,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

const convertLocationToPointsGeoJson = (data: any, communityDetails: any) => {
  const { name, profileImage, id, status } = communityDetails;
  if (data === null) {
    return null;
  }
  const geoJson = {
    description: "Pointer",
    id: uuidv4(),
    mapData: {
      features: [
        {
          geometry: {
            coordinates: data.coordinates,
            type: "Point",
          },
          properties: {
            iconSize: [60, 60],
            name: name,
            profileImage: profileImage,
            id: id,
          },
          type: "Feature",
        },
      ],
      type: "FeatureCollection",
    },
    itemType: "point",
  };
  return geoJson;
};
