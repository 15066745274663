import getAllCategories from "./getAllCategories";
import archiveOrRestoreCategory from "./archiveOrRestoreCategory";
import addCategory from "./addCategory";
import editCategory from "./editCategory";
import getAllTypes from "./getAllTypes";
import editType from "./editType";
import addType from "./addType";
import archiveOrRestoreType from "./archiveOrRestoreType";
import getCategoriesDropdown from "./getCategoriesDropdown";
import getTypesDropdown from "./getTypesDropdown";
import getAllSpecies from "./getAllSpecies";
import getSpecieProfile from "./getSpecieProfile";
import addSpecie from "./addSpecie";
import editSpecie from "./editSpecie";
import archiveOrRestoreSpecie from "./archiveOrRestoreSpecie";
import getAllObjects from "./getAllObjects";
import getObjectProfile from "./getObjectProfile";
import addObject from "./addObject";
import editObject from "./editObject";
import archiveOrRestoreObject from "./archiveOrRestoreObject";
import reUseSpeciesAndObjectsList from "./reUseSpeciesAndObjectsList";
const SpeciesAndObjects = {
  getAllCategories,
  archiveOrRestoreCategory,
  addCategory,
  editCategory,
  getAllTypes,
  archiveOrRestoreType,
  addType,
  editType,
  getCategoriesDropdown,
  getAllSpecies,
  getTypesDropdown,
  getSpecieProfile,
  addSpecie,
  editSpecie,
  archiveOrRestoreSpecie,
  getAllObjects,
  getObjectProfile,
  addObject,
  editObject,
  archiveOrRestoreObject,
  reUseSpeciesAndObjectsList,
};

export default SpeciesAndObjects;
