import {
  ERROR_LOADING_REPORTS_RESULT,
  GET_ALL_REPORTS_RESULT,
  RESET_DATA,
  START_LOADING_REPORTS_RESULT,
  STOP_LOADING_REPORTS_RESULT,
} from "./action-types";
import initialState from "./initialState";
export const reportsResultReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_REPORTS_RESULT:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_REPORTS_RESULT:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_REPORTS_RESULT:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_REPORTS_RESULT:
      const result = action.payload;
      // console.log(result.data, "result")
      return {
        ...state,
        data: {
          responseData: result.responseData.data,
          title: result.data.title,
          downloadPermissions: result.data.downloadPermission,
        },
        message: action.payload?.message ?? "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
