import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { panelsName } from "../../../panelsTitle";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import { updateGetGpsDatas } from "store/surveyJS";

const useHandleMapMovement = (currentQuestionEle: any, map: any) => {
  const [isCenterPointSetup, setIsCenterPointSetup] = useState(false);
  const { getGpsDatas, selectedQuestionElement, surveyActiveTab } = useSelector(
    (state: RootState) => state.surveyJS
  );

  const selectedQuestionType = selectedQuestionElement?.getType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentQuestionEle && map && !isCenterPointSetup) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,

        });
      }
      // update map center values
      else {
        map.on("load", () => {
          updateGetGpsDataCenterPoint(
            currentQuestionEle,
            map,
            selectedQuestionType,
            getGpsDatas,
            dispatch
          );
        });
      }

      if (
        selectedQuestionType !== panelsName.HARVEST &&
        selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        setIsCenterPointSetup(true);
      }
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (currentQuestionEle && map) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        updateGetGpsDataCenterPoint(
          currentQuestionEle,
          map,
          selectedQuestionType,
          getGpsDatas,
          dispatch
        );
      });
    }
  }, [currentQuestionEle, map]);

  return null;
};

function updateGetGpsDataCenterPoint(
  currentQuestionEle: any,
  map: any,
  selectedQuestionType: any,
  getGpsDatas: any,
  dispatch: any
) {
  const updatedCurrentgetGpsDatas = { ...currentQuestionEle };

  // update current element
  updatedCurrentgetGpsDatas.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };
  updatedCurrentgetGpsDatas.zoomLevel =map.getZoom();
  if (
    selectedQuestionType !== panelsName.HARVEST &&
    selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
  ) {
    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentgetGpsDatas,
      (updatedGetGpsDatas: any) => {
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  }
}


export { useHandleMapMovement }