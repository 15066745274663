import { apiClient } from "../config";

async function getAllRolesForReminders(search: any) {
  const params = {
    searchString: search,
  };

  const res = await apiClient.get(`/admin/reminders/roles`, {
    params,
  });
  return res.data;
}

export default getAllRolesForReminders;
