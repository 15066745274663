import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { addSurveyFormScema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddSurveyFormModal } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPollsAndSurveysAction } from "store/pollsAndSurveys";
import MultiSelect from "view/components/Multiselect";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */

export const AddSurveyForm = () => {
  // State variables
  const { addOrEditSurveyFormModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isProgramDisable, setIsProgramDisable] = useState(false);
  const [allPrograms, setAllPrograms] = useState([]);
  const { programId } = useParams();
  const [Image, setImage] = useState<any>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [formInitialValues, setFormInitialValues] = useState<any>({
    description: "",
    name: "",
    pictureId: null,
    programId: null,
  });

  // Initialize formInitialValues when in editMode or templateMode
  useEffect(() => {
    if (addOrEditSurveyFormModal.editMode) {
      const { name, description, pictureId, program } =
        addOrEditSurveyFormModal?.data;

      setFormInitialValues({
        description,
        name: name,
        programId: program
          ? { value: program?.id, label: program?.name }
          : null,
      });
      setIsProgramDisable(true);
    } else if (programId) {
      setFormInitialValues(addOrEditSurveyFormModal?.data);
      setIsProgramDisable(true);
    }
  }, [addOrEditSurveyFormModal?.data, addOrEditSurveyFormModal.editMode]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    {
      setSubmitting,
      setFieldError,
    }: { setSubmitting: (isSubmitting: boolean) => void; setFieldError: any }
  ) => {
    try {
      // if (values.programId === null) {
      //   setFieldError("programId", "Program is required");
      //   return;
      // }
      const formValues = {
        description: values.description,
        name: values.name,
        programId: values.programId?.value,
      };

      if (
        addOrEditSurveyFormModal.editMode &&
        addOrEditSurveyFormModal.data.id
      ) {
        // Edit an existing
        const { data, message } = await apiLibrary.pollsAndSurveys.editSurvey(
          addOrEditSurveyFormModal.data.id,
          formValues
        );
        dispatch(fetchPollsAndSurveysAction(programId));
        Toasts.success(message);
        handleClose();
      } else {
        const { data, message } =
          await apiLibrary.pollsAndSurveys.createSurveyForm(formValues);
        Toasts.success(message);
        handleClose();
        navigate(`/polls-and-surveys/${data.id}/edit`);
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    setImage(null);
    dispatch(closeAddSurveyFormModal());
    setFormInitialValues({
      description: "",
      name: "",
      pictureId: null,
      programId: null,
    });
  };

  // Fetch all programs on component mount
  useEffect(() => {
    /**
     * Fetches all programs from the API.
     * @example
     * fetchAllPrograms();
     */

    if (addOrEditSurveyFormModal.isOpen) {
      if (!programId) {
        fetchAllPrograms();
      }
    }
  }, [addOrEditSurveyFormModal.isOpen]);

  const fetchAllPrograms = useCallback(
    async (searchQuery?: string) => {
      try {
        const res = await apiLibrary.Forms.getAllPrograms(searchQuery);
        setAllPrograms(
          res.data.programs.map((p: any) => ({ label: p?.name, value: p?.id }))
        );
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching programs:", error);
      }
    },
    [allPrograms]
  );

  const handleAddProgramSearch = (query: string) => {
    fetchAllPrograms(query);
  };

  return (
    <Modal
      open={addOrEditSurveyFormModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={formInitialValues}
          validationSchema={addSurveyFormScema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start max-w-[700px] max-h-[95vh] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid dark:text-textMain">
                        {addOrEditSurveyFormModal?.data?.id &&
                        addOrEditSurveyFormModal.editMode
                          ? "Edit Survey"
                          : "Add Survey"}
                      </p>
                      <button title="close" onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col max-h-[70vh] w-full pr-1 overflow-y-auto items-start justify-start">
                    {/* <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 h-[200px] gap-2">
                          <div className="flex flex-col items-center self-stretch justify-start flex-grow gap-1">
                            <div className="relative flex flex-col items-start self-stretch justify-start flex-grow gap-1 p-1 bg-white border border-dashed rounded-lg border-lineDark">
                              {Image ||
                              addOrEditSurveyFormModal?.data?.imageUrl ? (
                                <img
                                  src={
                                    Image
                                      ? URL.createObjectURL(Image)
                                      : addOrEditSurveyFormModal.data.imageUrl
                                  }
                                  alt="Dropped file"
                                  className="relative flex-grow-0 flex-shrink-0 object-cover w-[300px] h-[192px]"
                                />
                              ) : (
                                <svg
                                  width={292}
                                  height={192}
                                  viewBox="0 0 292 192"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="relative flex-grow w-full"
                                  preserveAspectRatio="none"
                                >
                                  <g clipPath="url(#clip0_5958_12457)">
                                    <rect
                                      width={292}
                                      height={192}
                                      fill="#DCDEE0"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M125 75L139.643 94.0357L125 94.0357V96.9643H139.643L125 116H166L151.357 96.9643H165.998V94.0357L151.357 94.0357L166 75H125ZM151.355 96.9643L139.643 96.9643V94.0357L151.355 94.0357V96.9643ZM130.948 77.9286L141.085 91.1071H149.915L160.052 77.9286H130.948ZM141.085 99.8929L130.948 113.071H160.052L149.915 99.8929H141.085Z"
                                      fill="#202123"
                                      fillOpacity="0.1"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5958_12457">
                                      <rect
                                        width={292}
                                        height={192}
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                          </div>
                          <div
                            {...getRootProps()}
                            onClick={handleClick}
                            className="flex flex-col items-center self-stretch justify-center flex-grow px-4 py-2 bg-white border border-dashed rounded-lg cursor-pointer border-lineDark"
                          >
                            <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                              <div className="relative flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                                <input
                                  {...getInputProps()}
                                  ref={fileInputRef}
                                />
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11 3C8.79086 3 7 4.79086 7 7C7 7.30288 7.03348 7.59674 7.09656 7.87852C7.21716 8.41729 6.8783 8.95187 6.33958 9.07271C5.00015 9.37316 4 10.571 4 12C4 13.6569 5.34315 15 7 15C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17C4.23858 17 2 14.7614 2 12C2 9.94357 3.24073 8.1787 5.01385 7.41034C5.00466 7.27465 5 7.13781 5 7C5 3.68629 7.68629 1 11 1C13.627 1 15.858 2.68745 16.6713 5.03713C19.669 5.37085 22 7.91321 22 11C22 13.9039 19.938 16.324 17.1989 16.88C16.6577 16.9899 16.1299 16.6402 16.02 16.0989C15.9101 15.5577 16.2598 15.0298 16.8011 14.92C18.6264 14.5494 20 12.934 20 11C20 8.79086 18.2091 7 16 7C15.9732 7 15.9465 7.00026 15.9198 7.00079C15.4368 7.01024 15.0161 6.67312 14.9201 6.19971C14.5499 4.37395 12.9343 3 11 3ZM11.2929 9.29289C11.6834 8.90237 12.3166 8.90237 12.7071 9.29289L15.7071 12.2929C16.0976 12.6834 16.0976 13.3166 15.7071 13.7071C15.3166 14.0976 14.6834 14.0976 14.2929 13.7071L13 12.4142L13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22L11 12.4142L9.70711 13.7071C9.31658 14.0976 8.68342 14.0976 8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929L11.2929 9.29289Z"
                                    fill="#2C3236"
                                  />
                                </svg>
                              </div>
                              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                                <div className="flex justify-center items-center flex-grow relative px-1 pt-1.5 pb-2">
                                  <p className="flex-grow w-[260px] text-sm text-center text-textMid">
                                    Drag and drop your file here or click in
                                    this area
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 w-[608px] text-xs text-left text-textMidLight">
                            For the best results on all devices, use an image
                            that’s at least{" "}
                            <span className="font-medium">1920 x 1080</span>{" "}
                            pixels and{" "}
                            <span className="font-medium">10 Mb</span> or less.
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <TextInput
                        label="Survey Name*"
                        type="text"
                        name="name"
                        setFieldValue={setFieldValue}
                        value={values.name}
                        error={errors?.name}
                        touched={touched.name}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Survey Name"
                      />
                    </div>
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <TextInput
                        label="Description*"
                        type="text"
                        name="description"
                        fieldAs="textarea"
                        setFieldValue={setFieldValue}
                        value={values.description}
                        // error={errors?.description}
                        // touched={touched.description}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Description"
                      />
                      <div className="relative mt-[-8px] flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p
                          className={`flex-grow w-[608px] text-xs text-left ${
                            errors?.description && touched.description
                              ? "text-accent_1Dark"
                              : "text-textMidLight"
                          }`}
                        >
                          6000 Max Characters
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <AddProgramName
                        handleAddProgramNameOnChange={(option: any) =>
                          setFieldValue("programId", option)
                        }
                        errors={errors}
                        touched={touched}
                        programs={allPrograms}
                        values={values}
                        handleAddProgramSearch={handleAddProgramSearch}
                        isEditMode={isProgramDisable}
                      />
                    </div>
                  </div>
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />

                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Next"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const AddProgramName = ({
  touched,
  errors,
  handleAddProgramNameOnChange,
  programs,
  values,
  handleAddProgramSearch,
  isEditMode,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const { programId } = values;

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
            <p
              className={`flex-grow w-[596px] text-sm font-medium text-left capitalize pb-1 dark:text-caption ${
                !isEditMode && touched.programId && errors.programId
                  ? "text-accent_1Dark"
                  : "text-secondaryMid"
              }`}
            >
              Program Name
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
              !isEditMode && touched.programId && errors.programId
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
            }`}
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                fill="#9B9FA5"
              />
            </svg>
            <div className="flex flex-col items-start justify-start flex-grow w-full ">
              <MultiSelect
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleAddProgramSearch(value);
                }}
                placeholder="Program Name"
                options={programs}
                defaultValue={{ value: "", label: "" }}
                isMulti={false}
                value={[programId]}
                isDisable={isEditMode}
                transformY="translateY(-130%)"
                onChange={
                  (option: any) => {
                    handleAddProgramNameOnChange(option);
                    // setSelectedOption(option);
                  }
                  // handleCommunityChange(
                  //   selectedOptions,
                  //   setFieldValue
                  // )
                }
              />
            </div>
          </div>
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            {/* {!isEditMode && touched.programId && errors.programId && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {errors?.programId?.value}
              </p>
            )} */}

            {errors.programId && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {errors?.programId}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
