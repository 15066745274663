import getAllNotifications from "./getAllNotifications";
import updateNotification from "./updateNotification";
import deleteNotification from "./deleteNotification";
import getAllRolesForReminders from "./getAllRolesForReminders";
import getAllTargetMembers from "./getAllTargetMembers";
import createNotification from "./createNotification";
import editNotification from "./editNotification";

const Notifications = {
  getAllNotifications,
  updateNotification,
  deleteNotification,
  getAllRolesForReminders,
  getAllTargetMembers,
  createNotification,
  editNotification
};

export default Notifications;
