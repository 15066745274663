import { TableHeadProps } from "../../types";
import { ColumnHeading,TableHeadActions } from "../index";

export const TableHead = ({
    table,
    sorting,
    flexRender,
    DirectoryActions,
    handleClickOnColumnHead,
  }: TableHeadProps) => {
    return (
      <thead className="table_row_header w-full sticky top-0 h-[48px] z-20  dark:bg-bgtetriary">
        <tr className="w-full cursor-pointer " style={{ height: "49px" }}>
         
          {table.getHeaderGroups().map((headerGroup: any) => {
            return (
              <ColumnHeading
                headerGroup={headerGroup}
                sorting={sorting}
                flexRender={flexRender}
                handleClickOnHead={handleClickOnColumnHead}
              />
            );
          })}
  
          <th className="whitespace-nowrap sticky right-0 dark:bg-bgtetriary">
            <TableHeadActions DirectoryActions={DirectoryActions} />
          </th>
        </tr>
      </thead>
    );
  };

