import { apiClient } from "../config";

async function getAllReports(queryParams: any) {
  const res = await apiClient.get(`/admin/reports`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllReports;
