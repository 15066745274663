import { useEffect, useState } from "react";
// Store utils
import { useDispatch } from "react-redux";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import { SelectForm, UploadForm, DrawRegion } from "./Components";
import { RootState } from "store";
import { useSelector } from "react-redux";
import Head from "view/components/Head";
import {
  displaySurveyView,
  setDoResetMap,
  updateChoosedLayersItems,
} from "store/surveyJS";
import {
  ActiveAreaSelectModeType,
  I_InitialStateOfSurveyJS,
} from "store/surveyJS/initialState";
import {
  removeUploadedItemAction,
  selectedUploadedItemToHighlightAction,
  setActiveAreaSelectMode,
  setDrawShapeGeometry,
  setDrawShapeMode,
  setUploadedLayersAction,
  updateSelectedLayersItems,
} from "store/surveyJS/reducer.actions";
import questionTypes from "../../../SurveyJs/questionTypes";
import { FETCH_SURVEYJS_FORM_DETAILS_SUCCESS } from "store/formDetailsSurveyJsFields/action-types";

export const TABS = {
  SELECT: "SELECT",
  UPLOAD: "UPLOAD",
  DRAW: "DRAW",
};

// Schema
const CreateRegionLayer = ({ dataSourcesData }: any) => {
  const [activeTab, setActiveTab] = useState<any>();
  const [isActiveTabChangeOnClick, setIsActiveTabChangeOnClick] =
    useState(false);
  const [isUploadFormEditDataApplied, setIsUploadFormEditDataApplied] =
    useState(false);
  const { formDetails } = useSelector(
    (state: RootState) => state.formSurveyJsDetails
  );
  const { geography } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );

  const {
    choosedLayers,
    drawShapeGeometry,
    selectedLayersItems,
    drawShapeMode,
    uploadedItems,
    selectedUploadedItemTobehighlight,
  } = geography;

  const dispatch = useDispatch();

  const formHeight = calculateFormHeight();

  useEffect(() => {
    const { formAreaMode }: { formAreaMode: string } = formDetails;

    if (formAreaMode) {
      setActiveTab(formAreaMode.toUpperCase());
      dispatch(
        setActiveAreaSelectMode(
          formAreaMode.toUpperCase() as ActiveAreaSelectModeType
        )
      );
    } else {
      setActiveTab(TABS.SELECT);
      dispatch(
        setActiveAreaSelectMode(TABS.SELECT as ActiveAreaSelectModeType)
      );
    }
  }, []);

  // reset the map states whenever we switch the tab
  useEffect(() => {
    if (isActiveTabChangeOnClick) {
      dispatch(setDoResetMap(true));

      if (!choosedLayers || choosedLayers?.length > 0) {
        dispatch(updateChoosedLayersItems([]));
      }
      if (drawShapeGeometry) {
        dispatch(setDrawShapeGeometry(null));
      }
      if (selectedLayersItems?.length > 0) {
        dispatch(updateSelectedLayersItems([]));
      }
      if (drawShapeMode) {
        dispatch(setDrawShapeMode("simple_select"));
      }
      // if (uploadedItems.length > 0) {
      //   uploadedItems.forEach((element: any) => {
      //     dispatch(
      //       removeUploadedItemAction(element.featureCollection.collectionId)
      //     );
      //   });
      //   dispatch(setUploadedLayersAction([]));
      // }

      if (uploadedItems && uploadedItems.length > 0) {
        const uploadedItemsIds = uploadedItems.map(
          (element: any) => element.featureCollection.collectionId
        );
        if (uploadedItemsIds.length > 0) {
          dispatch(removeUploadedItemAction(uploadedItemsIds));
        }

        setTimeout(() => {
          dispatch(setUploadedLayersAction([]));
          dispatch(removeUploadedItemAction(null));

          const updatedFormDetails = {
            ...formDetails,
            formArea: null,
          };

          dispatch({
            type: FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
            payload: updatedFormDetails,
          });
        }, 1);

        if (selectedUploadedItemTobehighlight !== null) {
          dispatch(selectedUploadedItemToHighlightAction(null));
        }
      }

      dispatch(setDrawShapeMode("simple_select"));

      setIsActiveTabChangeOnClick(false);
    }
  }, [isActiveTabChangeOnClick]);

  // cleaning the cache after leaving the page
  useEffect(() => {
    return () => {
      dispatch(updateSelectedLayersItems([]));
      dispatch(updateChoosedLayersItems([]));
      dispatch(setDrawShapeGeometry(null));
      dispatch(setUploadedLayersAction([]));
    };
  }, []);

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(displaySurveyView());
  };

  const handleClickOnTab = (item: any) => {
    setIsActiveTabChangeOnClick(item === activeTab ? false : true);
    setActiveTab(item);
    dispatch(setActiveAreaSelectMode(item as ActiveAreaSelectModeType));
  };

  function calculateFormHeight() {
    const containerPadding = 32;
    const headBarHeight = 24 + 24;
    const breadcrumbHeight = 48 + 32;
    const formButtonsHeight = 52 + 8;
    const heading = 42 + 8;
    const allElementsHeight =
      containerPadding +
      headBarHeight +
      breadcrumbHeight +
      heading +
      formButtonsHeight * 2;

    return `calc(100vh - ${allElementsHeight}px)`;
  }

  return (
    <div className="relative flex flex-col h-full">
      <Head title="Indicate form area for offline use" />
      {/* <Breadcrumb
        label="Indicate form area for offline use"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      /> */}
      <div className="mb-8">
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          handleOnClick={handleClickOnTab}
        />
      </div>
      {activeTab === TABS.SELECT && (
        <SelectForm dataSourcesData={dataSourcesData} height={formHeight} />
      )}
      {activeTab === TABS.UPLOAD && (
        <UploadForm
          dataSourcesData={dataSourcesData}
          height={formHeight}
          setIsUploadFormEditDataApplied={setIsUploadFormEditDataApplied}
          isUploadFormEditDataApplied={isUploadFormEditDataApplied}
        />
      )}
      {activeTab === TABS.DRAW && (
        <DrawRegion dataSourcesData={dataSourcesData} height={formHeight} />
      )}
    </div>
  );
};

export { CreateRegionLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};
const Tabs = ({ tabs, activeTab, handleOnClick }: any) => {
  return (
    <div className="flex border-b border-lineMid dark:border-lineLight ">
      {tabs &&
        Object.keys(tabs)?.map((tab: any) => {
          return (
            <button
              key={tab}
              className={`py-3 flex-1 px-4 text-sm text-secondary  ${
                activeTab === tab
                  ? "border-b-2 border-primaryMid font-semibold dark:text-primaryMid"
                  : "font-medium hover:border-b hover:border-lineMid dark:text-textMain"
              } `}
              onClick={() => handleOnClick(tabs[tab])}
            >
              {tab}
            </button>
          );
        })}
    </div>
  );
};

export function getSelectedShapesAndDefaultLayers(data: any) {
  const selectedShapes: number[] = [];
  const selectedDefaultLayers: number[] = [];
  const geospatilQuestions = [
    questionTypes.pointPicker,
    questionTypes.shapePicker,
    questionTypes.drawPolygon,
    questionTypes.dropPin,
  ];

  data.pages[0].elements.forEach((ele: any) => {
    if (geospatilQuestions.includes(ele.type)) {
      ele?.selected_shapes?.forEach((s: any) => {
        if (!selectedShapes.find((id) => id === s.id)) {
          selectedShapes.push(s.id);
        }
      });

      ele?.selected_layers?.forEach((l: any) => {
        if (!selectedDefaultLayers.find((id) => id === l.id)) {
          selectedDefaultLayers.push(l.id);
        }
      });
    } else if (ele.elements || ele.templateElements) {
      ele[ele.elements ? "elements" : "templateElements"].forEach(
        (panelEle: any) => {
          if (geospatilQuestions.includes(panelEle.type)) {
            panelEle?.selected_shapes?.forEach((s: any) => {
              if (!selectedShapes.find((id) => id === s.id)) {
                selectedShapes.push(s.id);
              }
            });

            panelEle?.selected_layers?.forEach((l: any) => {
              if (!selectedDefaultLayers.find((id) => id === l.id)) {
                selectedDefaultLayers.push(l.id);
              }
            });
          }
        }
      );
    }
  });

  return { selectedShapes, selectedDefaultLayers };
}
