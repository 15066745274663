import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { RootState } from "store";
import { setLoadingProgress } from "store/loadingBar";
import { UserProfileState } from "store/userProfile/initialState";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";

interface DeleteEmailBtnProps {
  remove: any;
  currentEmailIndex: any;
  isEmailExist: any;
  refreshNewData: (data: any) => void;
}
export const DeleteEmailBtn: React.FC<DeleteEmailBtnProps> = ({
  remove,
  currentEmailIndex,
  isEmailExist,
  refreshNewData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const { personId } = useParams();
  const dispatch = useDispatch();
  const { profile } = usePermissions();
  const existingEmailIndex = userProfile.secondaryEmail.findIndex(
    (item: any) => item.email === isEmailExist?.email
  );

  const handleRemoveEmail = async (currentEmailIndex: number) => {
    dispatch(setLoadingProgress(getRandomProgress()));
    setIsLoading(true);
    try {
      if (
        isEmailExist &&
        existingEmailIndex === currentEmailIndex &&
        profile.canEditSecondaryEmailPhoneProfile
      ) {
        const res = await apiLibrary.userProfile.deleteSecondaryEmailOrPhone(
          isEmailExist.email,
          personId
        );
        refreshNewData(res.data);
        Toasts.success(res.message);
      }
      remove(currentEmailIndex);
    } catch (error: any) {
      // Handle the error if needed
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  if (!profile.canEditSecondaryEmailPhoneProfile) {
    return <></>;
  }
  return (
    <>
      {profile.canDeleteSecondaryEmailPhoneProfile && (
        <button
          disabled={
            isLoading ||
            isEmailExist.isPrimary ||
            !profile.canEditSecondaryEmailPhoneProfile
          }
          type="button"
          onClick={() => handleRemoveEmail(currentEmailIndex)}
          className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 disabled:cursor-not-allowed rounded-3xl focus:outline-none"
          title="Delete"
          aria-label="Delete"
        >
          <TrashIcon width={24} height={24} fill="#2C3236" />
        </button>
      )}
    </>
  );
};
