import { useEffect } from "react";

import mapboxgl from "mapbox-gl";
import pointIconRed from "assets/images/location-marker-red.png";
import { generatingLayerId } from "../utils";
import { screens } from "store/geography/initialState";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";
const LAYER_DEFAULT_COLOR = "#FFA500";
const LAYER_SELECTED_COLOR = "#FF0000";

const useCustomizeColorOfHigilightedPoint = (
  map: any,
  markers: any,
  setMarkers: any,
  clickedPoint: any,
  setClickedPoint: any,
  activeSidebarScreen: any
) => {
  // if (activeSidebarScreen === screens.ADD_POINTS_LAYER) {

  // }

  useEffect(() => {
    if (markers && map && activeSidebarScreen === screens.ADD_POINTS_LAYER) {
      const newMarkers = markers.map((markerData: any) => {
        const { sourceId, layerId } = generatingLayerId(
          "point",
          markerData?.item.id
        );

        const markerId = layerId + "-point" + clickedPoint.id;

        const isMarkerSelected = markerData.markerId === markerId;
        const markerColor = isMarkerSelected
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        const coordinates = markerData.marker.getLngLat();

        // Remove the old marker
        markerData.marker.remove();

        const markerElement = document.createElement("div");
        markerElement.className = "custom-marker-point-picker";

        markerElement.innerHTML = `
            <div class="marker-content-point-picker" style="
       width: 50px; 
       height: 50px; 
       border-radius: 50%; 
       border: 3px solid ${markerColor}; 
      
       overflow: hidden;
        ">
             <img src="${markerData?.item?.markerStyle?.styleImage ?? pointIconRed}" alt="Marker Image" class="marker-image" style="width: 100%; height: 100%; object-fit: cover;" />
          </div>
           `;

        const newMarker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map);

        markerData.marker = newMarker;
        return markerData;
      });

      setMarkers(newMarkers);
    }
  }, [clickedPoint]);

  return null;
};

export { useCustomizeColorOfHigilightedPoint };
