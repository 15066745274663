import React from "react";
import { snakeToCamel } from "utils/caseConvertor";
import {
  TextField,
  Dropdown,
  MultiselectDropdown,
  RadioButtonGroup,
} from "../../Components";

interface RiverConditionProps {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  handleUpdateStatus: any;
}

const RiverCondition: React.FC<RiverConditionProps> = ({
  panel,
  editMode,
  formikProps,
  handleUpdateStatus,
}) => {

  const onChange = (eleName: string, value: any, name: string) => {
    formikProps.setFieldValue(eleName, value)
    handleUpdateStatus(name, panel.sectionId)
  }

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name);

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-5">
        {panelElements.map((element: any, index: number) => {
          const {
            name,
            title,
            choices,
            fieldAs,
            rows,
            value,
            inputType,
            type,
            placeholder,
            isRequired = false,
            visible = true,
            choicesByUrl,
            defaultValue,
            colCount
          } = element;
          const eleName = [panelName, element.name].join(".");
          const error = formikProps.errors[panelName]?.[element.name];
          const touched = formikProps.touched[panelName]?.[name];

          if (visible === false || type === "html" || defaultValue) {
            return;
          }

          switch (element.type) {
            case "radiogroup":
              return (
                <RadioButtonGroup
                  key={index}
                  label={title}
                  name={name}
                  editMode={editMode}
                  choicesByUrl={choicesByUrl}
                  value={formikProps.values[panelName]?.[name]}
                  handleChange={(item: any) =>
                    onChange(eleName, item, name)
                  }
                  data={choices}
                  required={isRequired}
                  error={error}
                  touched={touched}
                  columns={colCount}
                  panel={panel}
                  element={element}
                />
              );

            case "dropdown":
              return (
                <Dropdown
                  key={index}
                  label={title}
                  name={name}
                  choices={choices}
                  choicesByUrl={choicesByUrl}
                  value={formikProps.values[panelName]?.[name]}
                  handleChange={(item: any) =>
                    onChange(eleName, item, name)
                  }
                  handleBlur={() =>
                    formikProps.setFieldTouched(eleName, true, true)
                  }
                  editMode={editMode}
                  placeholder={placeholder || `Select ${title}`}
                  required={isRequired}
                  error={error}
                  touched={touched}
                  panel={panel}
                  element={element}
                />
              );

            case "text":
              return (
                <TextField
                  key={index}
                  label={title}
                  type={inputType || type}
                  name={name}
                  value={formikProps.values[panelName]?.[name]}
                  handleChange={(value: any) =>
                    onChange(eleName, value, name)
                  }
                  handleBlur={() =>
                    formikProps.setFieldTouched(eleName, true, true)
                  }
                  fieldAs={fieldAs}
                  rows={rows}
                  editMode={editMode}
                  placeholder={placeholder || `Enter ${title}`}
                  required={isRequired}
                  error={error}
                  touched={touched}
                  panel={panel}
                  element={element}
                />
              );

            default:
              return null; // Or render a default component for unknown types
          }
        })}
      </div>
    </>
  );
};

export default RiverCondition;
