import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddTypeBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditTypeModalAction } from "store/modals";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/types/list`, label: `Type` }];
  }
};
export const Header = () => {
  const { typesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch = useDispatch();
  const dynamicTypeLinks = generateDynamicBreadcrumbLinks(typesFilter.status);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicTypeLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <AddTypeBtn
                handleAddTypeModel={() =>
                  dispatch(openAddOrEditTypeModalAction())
                }
                label={"Add Type"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
