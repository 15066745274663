export interface runReportDirectoryState {
  versionId: any;
  processedReports: boolean | null
}

const runReportDirectoryInitialState: runReportDirectoryState = {
  versionId: 0,
  processedReports: false
};

export default runReportDirectoryInitialState;


