import React from "react";
import { PrimaryAddressToggler } from "./PrimaryAddressToggler";
import { AddressInputField } from "./AddressInputField";
import { DeleteAnAddressBtn } from "./DeleteAnAddressBtn";

interface AddressCreatorProps {
  remove: any;
  updatePrimaryAddress: (cureentAddressIndex: number) => void;
  cureentAddressIndex: number;
  address: any;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
}

const AddressCreator: React.FC<AddressCreatorProps> = ({
  remove,
  updatePrimaryAddress,
  cureentAddressIndex,
  address,
  touched,
  handleChange,
  errors,
  handleBlur,
  setFieldValue,
}) => {
  const addressInputFieldName = `addresses[${cureentAddressIndex}]['address']`;
  const addressFieldError =
    touched[addressInputFieldName] && errors[addressInputFieldName];
  return (
    <>
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-3 py-1 ">
        <PrimaryAddressToggler
          currentAddressIndex={cureentAddressIndex}
          onClick={() => updatePrimaryAddress(cureentAddressIndex)}
          address={address}
        />

        <div className="flex flex-col items-start justify-start flex-grow w-full gap-2">
          <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
            <div className="min-w-[520px]">
              <AddressInputField
                setFieldValue={setFieldValue}
                addressFieldError={addressFieldError}
                addressInputFieldName={addressInputFieldName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                address={address}
              />
            </div>

            {!address.isPrimary && (
              <DeleteAnAddressBtn
                address={address}
                remove={remove}
                cureentAddressIndex={cureentAddressIndex}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AddressCreator);
