import { apiClient } from "../config";

async function downloadProtocols(id: number, zipData: string) {
  const data = {
    zip: zipData
  };
  
  const res = await apiClient.get(`/admin/protocols/${id}/download?zip=${zipData}`);
  return res.data;
}

export default downloadProtocols;
