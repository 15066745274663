import { Ifetch } from "../initialState";

export interface IActiveUsers {
  firstName: string;
  lastName: string;
  observationCount: number;
  personId: number;
  personImage: {
    original: string;
    thumb: string;
  } | null;
}

export interface IfetchActiveUsers extends Ifetch {
  data: IActiveUsers[];
}

const activeUsersInitialState: IfetchActiveUsers = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default activeUsersInitialState;
