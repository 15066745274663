import { useEffect, useState } from "react";
import { PageHeading } from "./components";

export const Header = ({ surveyDetails }: any) => {
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading surveyDetails={surveyDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};
