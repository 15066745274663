// @ts-ignore
import toGeoJSON from "@mapbox/togeojson";
// @ts-ignore
import * as shapefile from "shapefile";
import { convertGeoJSON } from "utils/convertFeatureCollection";

import fs from "fs";
// @ts-ignore
import * as turf from "@turf/turf";
// @ts-ignore
import shp from "shpjs";
import { validateGeoJSON } from "utils/validateGeoJson";
import JSZip from "jszip";

interface GeoJSONGeometry {
  type:
  | "Point"
  | "MultiPoint"
  | "LineString"
  | "MultiLineString"
  | "Polygon"
  | "MultiPolygon";
  coordinates: any;
}
export interface ErrorDetails {
  message: string;
  code: string;
}

interface Feature {
  type: "Feature";
  geometry: GeoJSONGeometry;
  properties: any;
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
}

interface FeatureCollection {
  type: "FeatureCollection";
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
  features: Feature[];
}

async function handleShapeAndKMLTypeFiles(File: File) {
  const fileExtension = File.name.split(".").pop()?.toLowerCase();
  const errorDetails: ErrorDetails = {
    message:
      "Oops! Your file could not be processed. It appears that you are trying to upload a file without any Coordinate Reference System (CRS) Information associated. To help us process your file correctly, please include the CRS information in your file. Don't know how to do this? Please contact the Super Admin or User Support. Thank you!",
    code: "CRS_MISSING",
  };
  if (fileExtension === "kml") {
    const kmlText = await File.text();
    const parser = new DOMParser();
    const kml = parser.parseFromString(kmlText, "text/xml");
    const geojson = toGeoJSON.kml(kml);

    const isValidGeoJson = validateGeoJSON(geojson);
    if (isValidGeoJson) {
      const convertedGeoJson = convertGeoJSON(geojson);
      return convertedGeoJson;
    } else {
      if (!geojson.crs) {
        let error: any = new Error("An error occurred");
        error.code = errorDetails.code;
        error.details = errorDetails.message;
        throw error;
      }
      const convertedGeoJson = convertGeoJSON(geojson);
      return convertedGeoJson;
    }
  } else if (fileExtension === "shp") {
    try {
      const arrayBuffer = await File.arrayBuffer();
      const geojson: any = await shapefile.read(arrayBuffer);

      const isValidGeoJson = validateGeoJSON(geojson);
      if (isValidGeoJson) {
        const convertedGeoJson = convertGeoJSON(geojson);
        return convertedGeoJson;
      } else {
        if (!geojson.crs) {
          let error: any = new Error("An error occurred");
          error.code = errorDetails.code;
          error.details = errorDetails.message;
          throw error;
        }
        const convertedGeoJson = convertGeoJSON(geojson);
        return convertedGeoJson;
      }
    } catch (error) {
      console.error("Error processing Shapefile:", error);
      throw error;
    }
  } else if (fileExtension === "geojson") {
    const geojsonText = await File.text();
    const geojson = JSON.parse(geojsonText) as FeatureCollection | Feature;
    const isValidGeoJson = validateGeoJSON(geojson);
    if (isValidGeoJson) {
      const convertedGeoJson = convertGeoJSON(geojson);
      return convertedGeoJson;
    } else {
      if (!geojson.crs) {
        let error: any = new Error("An error occurred");
        error.code = errorDetails.code;
        error.details = errorDetails.message;
        throw error;
      }
      const convertedGeoJson = convertGeoJSON(geojson);
      return convertedGeoJson;
    }
  } else if (fileExtension === "zip") {
    try {
      // const arrayBuffer = await File.arrayBuffer();
      // const geojson: any = await shp(arrayBuffer);

      // const isValidGeoJson = validateGeoJSON(geojson);
      // if (isValidGeoJson) {
      //   const convertedGeoJson = convertGeoJSON(geojson);
      //   return convertedGeoJson;
      // } else {
      //   if (!geojson.crs) {
      //     let error: any = new Error("An error occurred");
      //     error.code = errorDetails.code;
      //     error.details = errorDetails.message;
      //     throw error;
      //   }
      //   const convertedGeoJson = convertGeoJSON(geojson);
      //   return convertedGeoJson;
      // }
      const processedZipFile = await processZippedShapefiles(File);
      return processedZipFile;
    } catch (error) {
      console.error("Error processing Shapefile:", error);
      throw error;
    }
  } else {
    return null;
  }
}

export default handleShapeAndKMLTypeFiles;

async function processZippedShapefiles(file: File) {
  const errorDetails: ErrorDetails = {
    message:
      "Oops! Your file could not be processed. It appears that you are trying to upload a file without .prj or .shp files in the ZIP. To help us process your file correctly, please include these files in  your ZIP. Don't know how to do this? Please contact the Super Admin or User Support. Thank you!",
    code: "SHP_OR_PRJ_MISSING",
  };

  try {
    const arrayBuffer = await file.arrayBuffer();
    const zip = new JSZip();
    const content = await zip.loadAsync(arrayBuffer);
    const prjPromise = fetchFileContent(content, ".prj", "text");
    const shpPromise = fetchFileContent(content, ".shp", "arraybuffer");

    const results = await Promise.all([prjPromise, shpPromise]);
    const prjContent: any = results[0];
    const shpArrayBuffer = results[1];

    if (prjContent && shpArrayBuffer) {
      console.log("prjContent", prjContent)

      const geojson: any = await shapefile.read(shpArrayBuffer);
      const isValidGeoJson = validateGeoJSON(geojson);
      if (isValidGeoJson) {
        const convertedGeoJson = convertGeoJSON(geojson);
        return convertedGeoJson;
      } else {

        const convertedGeoJson = convertGeoJSON(geojson, prjContent);

        return convertedGeoJson;
      }

    } else {
      let error: any = new Error("An error occurred");
      error.code = errorDetails.code;
      error.details = errorDetails.message;
      throw error;
    }
  } catch (error) {
    console.error("Failed to read the file:", error);
    throw error;
  }
}

const fetchFileContent = async (
  zip: JSZip,
  extension: string,
  returnType: "text" | "arraybuffer"
) => {
  const fileKey = Object.keys(zip.files).find((fileName) =>
    fileName.endsWith(extension)
  );
  if (fileKey) {
    return await zip.files[fileKey].async(returnType);
  }
  return null;
};
