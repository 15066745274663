import * as actionTypes from "./action-types";
import initialState from "./initialState";

export const runReportDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.REPORTS_VERIONS_ID_DATA:
      return {
        ...state,
        versionId: action.payload,
      };
    case actionTypes.RUN_PROCESSED_REPORT:
      return {
        ...state,
        processedReports: action.payload,
      };
    case actionTypes.RESET_RUN_REPORTS:
      return {
        state,
      };
    default:
      return state;
  }
};
