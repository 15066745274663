import PlusCircleIcon from "assets/icons/HeroIcons/PlusCircleIcon";
import React from "react";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";

interface UserProps {
  organizationData: any;
  setSelectedOrganization: any;
  ConnectOrganization: any;
}

export const OrganizationList: React.FC<UserProps> = ({
  organizationData,
  setSelectedOrganization,
  ConnectOrganization,
}) => {
  return (
    <div className="overflow-y-auto user-list max-h-12">
      <div className="flex justify-between items-center relative gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
        <div
          onClick={() => setSelectedOrganization(organizationData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          {organizationData?.organizationImage !== null ? (
            <img
              className="w-8 h-8 rounded-full"
              src={organizationData?.organizationImage.original}
              alt=""
            />
          ) : (
            <ListImagePlaceholder />
          )}
          <p className="ml-3 dark:text-textMain">{organizationData.name}</p>
        </div>
        <button
          type="button"
          onClick={() => ConnectOrganization(organizationData)}
        >
          <PlusCircleIcon />
        </button>
      </div>
    </div>
  );
};
