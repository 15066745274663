import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Roles from "hooks/roles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import {
  CancelButton,
  EditButton,
  UpdateButton,
} from "Components/Profile/components";
import { useLocation, useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

interface HeaderProps {
  // Include FormikProps
  editMode: boolean;
  handleEdit: () => void;
  title: string;
  resetForm: any;
  isSubmitting?: any;
  AccountCreated: any;
}

const Header: React.FC<HeaderProps> = ({
  editMode,
  handleEdit,
  title,
  resetForm,
  isSubmitting,
  AccountCreated,
}) => {
  const { isMyProfile } = Roles();
  const [matchId, setMatchId] = useState();
  const { personId } = useParams();
  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const { users, profile } = usePermissions();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  useEffect(() => {
    if (auth.person.personId == personId) {
      setMatchId(users.canEditPersonProfile);
    } else {
      setMatchId(profile.canEditUserProfile);
    }
  }, [personId]);
  
  const isEditableProfile = (userProfile: UserProfileState) => {
    const { personId, virtual, status } = userProfile;
    return (
      isMyProfile(personId) ||
      (virtual &&
        status !== "deleted" &&
        location.pathname !== `/profile/${userProfile.personId}/account`)
    );
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pt-6 pl-8 pr-8 xl:pl-12">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 px-5">
        <Heading title={title} />
        {/* {isEditableProfile(userProfile) && (
          <motion.div
            animate={{
              translateX: editMode ? 0 : 20,
            }}
          >
            {!editMode ? (
              <EditButton onClick={handleEdit} />
            ) : (
              <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                <CancelButton
                  onClick={() => {
                    handleEdit();
                    resetForm();
                  }}
                />
                <UpdateButton isSubmitting={isSubmitting} />
              </div>
            )}
          </motion.div>
        )}
         */}

        {AccountCreated && matchId && (
          <motion.div
            animate={{
              translateX: editMode ? 0 : 20,
            }}
          >
            {!editMode ? (
              <EditButton onClick={handleEdit} />
            ) : (
              <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                <CancelButton
                  onClick={() => {
                    handleEdit();
                    resetForm();
                  }}
                />
                <UpdateButton isSubmitting={isSubmitting} />
              </div>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export { Header };

const Heading = ({ title }: { title: string }) => {
  return (
    <div className="relative flex flex-col items-start justify-center flex-grow py-1 ml-[-19px]">
      <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-lg font-semibold text-left text-textMid dark:text-textMain">
        {title}
      </p>
    </div>
  );
};
