import questionTypes from "./questionTypes";

const staticConfigurations = [
  {
    name: questionTypes.radio,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "choicesFromQuestion",
      "choicesOrder",
      "showOtherItem",
      "showNoneItem",
      "showClearButton",
      "separateSpecialChoices",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.checkbox,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "choicesFromQuestion",
      "choicesOrder",
      "showOtherItem",
      "showNoneItem",
      "showSelectAllItem",
      "maxSelectedChoices",
      "minSelectedChoices",
      "separateSpecialChoices",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      //"colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
      "valuePropertyName",
    ],
  },
  {
    name: questionTypes.dropdown,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "autocomplete",
      "choicesFromQuestion",
      "choicesOrder",
      "showOtherItem",
      "showNoneItem",
      "choicesMin",
      "choicesMax",
      "choicesStep",
      "allowClear",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
      "valuePropertyName",
    ],
  },
  {
    name: questionTypes.tagbox,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "textUpdateMode",
      "rows",
      "autoGrow",
      "allowResize",
      "validation",
    ],
  },
  {
    name: questionTypes.boolean,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "valueTrue",
      "valueFalse",
    ],
  },
  {
    name: questionTypes.file,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "sourceType",
      "allowMultiple",
      "allowImagesPreview",
      "acceptedTypes",
      "storeDataAsText",
      "showPreview",
      "imageHeight",
      "imageWidth",
      "maxSize",
      "needConfirmRemoveFile",
      "waitForUpload",
      "allowCameraAccess",
      "valueName",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.imagepicker,
    properties: [
      "readOnly",
      "showCommentArea",
      "defaultValueExpression",
      "readOnly",
      "showCommentArea",
      "visible",
      "sourceType",
      "allowMultiple",
      "allowImagesPreview",
      "acceptedTypes",
      "storeDataAsText",
      "showPreview",
      "imageHeight",
      "imageWidth",
      "maxSize",
      "needConfirmRemoveFile",
      "waitForUpload",
      "allowCameraAccess",
      "valueName",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "choicesOrder",
      "choicesFromQuestion",
      "choicesByUrl",
    ],
  },
  {
    name: questionTypes.text,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "autocomplete",
      "dataList",
      "hideNumber",
      "state",
      "startWithNewLine",
      "size",
      "textUpdateMode",
      "maxLength",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      // "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.comment,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "rows",
      "autoGrow",
      "allowResize",
      "validation",
      "maxLength",
      "textUpdateMode",
    ],
  },
  {
    name: questionTypes.geocoder,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "innerIndent",
      "showNumber",
      "showQuestionNumbers",
      "questionStartIndex",
      "defaultPanelValue",
      "keyName",
      "keyDuplicationError",
      "defaultValueFromLastPanel",
      // "visibleIf",
      // "enableIf",
      // "requiredIf",
      "maxTimeToFinish",
    ],
  },
  {
    name: questionTypes.paneldynamic,
    properties: [
      "readOnly",
      "visible",
      "showCommentArea",
      "renderMode",
      "panelCount",
      "templateTitle",
      "templateDescription",
      "noEntriesText",
      "allowAddPanel",
      "allowRemovePanel",
      "panelCount",
      "minPanelCount",
      "panelsState",
      "confirmDelete",
      "confirmDeleteText",
      "panelAddText",
      "panelRemoveText",
      "panelPrevText",
      "panelNextText",
      "showQuestionNumbers",
      "showRangeInProgress",
      "maxPanelCount",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "templateTitleLocation",
      "templateErrorLocation",
      "panelRemoveButtonLocation",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.audio,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "choicesFromQuestion",
      "choicesOrder",
      "showOtherItem",
      "showNoneItem",
      "showSelectAllItem",
      "maxSelectedChoices",
      "minSelectedChoices",
      "separateSpecialChoices",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
      "valuePropertyName",
    ],
  },
  {
    name: questionTypes.shapePicker,
    properties: [
      "name",
      "title",
      "_id",
      "readOnly",
      "visible",
      "visibleIf",
      "enableIf",
      "isRequired",
      "requiredIf",
      "description",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "maxWidth",
      "valueName",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.pointPicker,
    properties: [
      "name",
      "title",
      "_id",
      "readOnly",
      "visible",
      "visibleIf",
      "enableIf",
      "isRequired",
      "requiredIf",
      "description",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "maxWidth",
      "valueName",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.drawPolygon,
    properties: [
      "name",
      "title",
      "_id",
      "readOnly",
      "visible",
      "visibleIf",
      "enableIf",
      "isRequired",
      "requiredIf",
      "description",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "maxWidth",
      "valueName",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.dropPin,
    properties: [
      "name",
      "title",
      "_id",
      "readOnly",
      "visible",
      "visibleIf",
      "enableIf",
      "isRequired",
      "requiredIf",
      "description",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "maxWidth",
      "valueName",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
    ],
  },
  {
    name: questionTypes.getGpsData,
    properties: [
      "name",
      "title",
      "_id",
      "readOnly",
      "visible",
      "visibleIf",
      "enableIf",
      "isRequired",
      "requiredIf",
      "description",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "maxWidth",
      "valueName",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "defaultValueExpression",
    ],
  },

  {
    name: questionTypes.panel,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "innerIndent",
      "showNumber",
      "showQuestionNumbers",
      "questionStartIndex",
    ],
  },
  {
    name: questionTypes.paneldynamic,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "innerIndent",
      "showNumber",
      "showQuestionNumbers",
      "questionStartIndex",
      "defaultPanelValue",
      "keyName",
      "keyDuplicationError",
      "defaultValueFromLastPanel",
    ],
  },

  {
    name: questionTypes.page,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "innerIndent",
      "showNumber",
      "showQuestionNumbers",
      "questionStartIndex",
      "defaultPanelValue",
      "keyName",
      "keyDuplicationError",
      "defaultValueFromLastPanel",
      "visibleIf",
      "enableIf",
      "requiredIf",
      "maxTimeToFinish",
    ],
  },

  {
    name: questionTypes.survey,
    properties: [
      "title",
      "description",
      "showTitle",
      "locale",
      "mode",
      "cookieName",
      // "widthMode",
      "width",
      "fitToContainer",
      "logo",
      "logoWidth",
      "logoHeight",
      "logoFit",
      "showPreviewBeforeComplete",
      "pagePrevText",
      "pageNextText",
      "completeText",
      "previewText",
      "editText",
      "startSurveyText",
      "showNavigationButtons",
      "showPrevButton",
      "firstPageIsStarted",
      "goNextPageAutomatic",
      "showProgressBar",
      "progressBarType",
      "questionsOnPageMode",
      "showTOC",
      "tocLocation",
      "questionTitleLocation",
      "questionDescriptionLocation",
      "showQuestionNumbers",
      "questionTitlePattern",
      "requiredText",
      "questionStartIndex",
      "questionErrorLocation",
      "focusFirstQuestionAutomatic",
      "questionsOrder",
      "maxTextLength",
      "maxOthersLength",
      "autoGrowComment",
      "allowResizeComment",
      "pages",
      "showPageTitles",
      "showPageNumbers",
      "calculatedValues",
      "triggers",
      "clearInvisibleValues",
      "textUpdateMode",
      "sendResultOnPageNext",
      "storeOthersAsComment",
      "focusOnFirstError",
      "checkErrorsMode",
      "navigateToUrl",
      "showCompletedPage",
      "completedHtml",
      "navigateToUrlOnCondition",
      "completedHtmlOnCondition",
      "loadingHtml",
      "completedBeforeHtml",
      "maxTimeToFinish",
      "maxTimeToFinishPage",
      "showTimerPanel",
      "showTimerPanelMode",
    ],
  },

  {
    name: questionTypes.paneldynamic,
    properties: [
      "readOnly",
      "showCommentArea",
      "visible",
      "closeOnSelect",
      "searchEnabled",
      "allowClear",
      "hideSelectedItems",
      "startWithNewLine",
      "hideNumber",
      "state",
      "titleLocation",
      "descriptionLocation",
      "errorLocation",
      "indent",
      "width",
      "minWidth",
      "colCount",
      "valueName",
      "maxWidth",
      "defaultValue",
      "correctAnswer",
      "useDisplayValuesInDynamicTexts",
      "clearIfInvisible",
      "setValueExpression",
      "hideIfChoicesEmpty",
      "choicesEnableIf",
      "resetValueIf",
      "setValueIf",
      "requiredErrorText",
      "validators",
      "valuePropertyName",
      "questionTitleLocation",
      "questionErrorLocation",
      "questionsOrder",
      "navigationButtonsVisibility",
      "defaultValueExpression",
      "innerIndent",
      "showNumber",
      "showQuestionNumbers",
      "questionStartIndex",
      "defaultPanelValue",
      "keyName",
      "keyDuplicationError",
      "defaultValueFromLastPanel",
    ],
  },
];
const processQuestionConfigurations = (configs: any[]) => {
  return configs;
};

const questionsPropertiesToBeRemove = processQuestionConfigurations([
  ...staticConfigurations,
]);
export default questionsPropertiesToBeRemove;
