import { PageHeadingProps } from "../../types";

const PageHeading = ({  name }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {/* {communityId !== "" ? <>{name} People</> : "People"} */}
          {name}
        </p>
      </div>
    </div>
  );
};

export {PageHeading};
