import { apiClient } from "../config";

async function getNotifications(queryParams: any) {
  const res = await apiClient.get(`/admin/dashboard/widget/notifications`, {
    params: queryParams,
  });
  return res.data;
}

export default getNotifications;
