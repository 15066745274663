import { apiClient } from "../config";

async function getAllRoles(queryParams: any) {
  const res = await apiClient.get(`/admin/roles`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllRoles;
