import { CameraIcon } from "assets/icons/HeroIcons";
import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IOrganization } from "store/organizationProfile/initialState";
import { fetchOrganizationProfileAction } from "store/organizationProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";

export const Banner = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );
  const { organizationId } = useParams();
  const { organizations } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const coverImageRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        dispatch(setLoadingProgress(getRandomProgress()));

        const fileSize = file.size / 1024 / 1024; // convert bytes to mb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error("File size must be less than 10 mb!");
        }

        setIsLoading(true);

        const uploadResponse = await apiLibrary.file.fileUpload(file);
        const { data } =
          await apiLibrary.OrganizationDirectory.updateOrganization(
            organizationProfile.id,
            {
              coverImageId: uploadResponse.data.id,
            }
          );
        dispatch(
          fetchOrganizationProfileAction(organizationId ? organizationId : "")
        );
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
        // Handle error during upload or profile update
      } finally {
        setIsLoading(false);
        dispatch(setLoadingProgress(100));
      }
    }
  };

  const handleClick = (imageType: "profile" | "cover") => {
    if (coverImageRef.current) {
      coverImageRef.current.click();
    }
  };

  return (
    <div className="self-stretch w-full  flex-grow-0 flex-shrink-0 h-[272px] relative overflow-hidden">
      {isLoading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
          <div className="inline-flex items-center">
            <svg
              className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      )}
      <input
        disabled={isLoading}
        type="file"
        name="cover"
        className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
        multiple={false}
        ref={coverImageRef}
        accept=".jpg, .jpeg, .png"
        onChange={(event) => handleImageUpload(event)}
      />

      <img
        src={
          organizationProfile?.coverImage?.original
            ? organizationProfile.coverImage?.original
            : getBrandPlaceholder("cover")
        }
        alt={organizationProfile.name}
        className="h-[272px] w-full max-w-full object-cover"
      />
      {organizations.canEditOrganization && (
        <button
          type="button"
          disabled={isLoading}
          onClick={() => handleClick("cover")}
          className="flex justify-center items-center absolute right-2 top-4 gap-2 p-0.5 bg-[white] dark:bg-secondaryLight w-[46px] h-[46px] rounded-3xl"
        >
          <CameraIcon className="w-[32px] h-[32px] dark:stroke-caption" />
        </button>
      )}
    </div>
  );
};
