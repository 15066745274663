import ListingPlaceholder from "assets/placeholders/ListingPlaceholder";
import React from "react";

export const ReportAccessPlaceholder = () => {
  const handleSupportRoute = () => {
    window.open(
      `https://confluence.web.app/i/lyzM/EDV/544538742/isn+end+data+viewer+home`
    );
  };
  return (
    <div className="placeholder_img bg-bgWhite px-4 py-8 rounded-lg flex flex-col justify-center items-center w-full min-h-[60vh] gap-3">
      <div>
        <ListingPlaceholder />
      </div>

      <div className="flex items-center justify-center flex-col">
        <p className="text-Overpass text-textMid font-semibold text-lg mb-1">
          Currently, there are no reports accessible to you.
        </p>
        <p className="text-Overpass text-textMidLight font-regular text-sm">
          If you have any questions, please contact your ISN contact for
          assistance.
        </p>
      </div>
      <button
        className="flex justify-center items-center mt-4 flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition cursor-pointer"
        onClick={handleSupportRoute}
      >
        <div className="flex justify-center items-center relative pt-1.5 pb-[7px]">
          <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary dark:text-primaryOutlined dark:group-hover:text-primary">
            Contact Support
          </p>
        </div>
      </button>
    </div>
  );
};
