import { MarkAllReadBtnProps } from "../../types";
import Button from "view/components/Button";

const MarkAllReadBtn = ({
  handleMarkAllRead,
  disabled,
}: MarkAllReadBtnProps) => {
  return (
    <>
      <Button
        type="button"
        text="Mark All As Read"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleMarkAllRead}
        disabled={disabled}
      />
    </>
  );
};

export { MarkAllReadBtn };
