import { ERROR_LOADING_NOTIFICATIONS_STATUS, GET_ALL_NOTIFICATIONS_STATUS, RESET_DATA, START_LOADING_NOTIFICATIONS_STATUS, STOP_LOADING_NOTIFICATIONS_STATUS } from "./action-types";
import initialState, { INotificationsStatus } from "./initialState";
export const notificationStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_NOTIFICATIONS_STATUS:
      return {
        ...state,
        stateIs: "Pending",
      };

      case ERROR_LOADING_NOTIFICATIONS_STATUS:
        return {
          ...state,
          stateIs: "Error",
        };

    case STOP_LOADING_NOTIFICATIONS_STATUS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_NOTIFICATIONS_STATUS:
      const noticationsStatus = action.payload?.reminders?.map((item: INotificationsStatus) => {
        const {
          notificationsDate,
          status
        } = item;
        const data = {
          notificationsDate,
          status
        };
        return data;
      });
      console.log(noticationsStatus)
      return {
        ...state,
        data: {
          reminders: noticationsStatus,
          totalItems: action.payload.totalItems,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
