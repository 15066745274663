import { camelCase } from "lodash";

/* eslint-disable no-prototype-builtins */
export const convertKeysToSnakeCase = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToSnakeCase);
  }

  const result: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`
      );

      result[snakeKey] = ["uniqueProps", "mapData"].includes(key)
        ? obj[key]
        : convertKeysToSnakeCase(obj[key]);
    }
  }
  return result;
};

export const convertKeysToCamelCase = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  }

  const result: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = camelCase(key ?? "");
      result[camelKey] = ["uniqueProps", "mapData"].includes(camelKey)
        ? obj[key]
        : convertKeysToCamelCase(obj[key]);
    }
  }
  return result;
};

// Function to convert snake_case to camelCase
export function snakeToCamel(s: string): string {
  return s
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    )
    .replace(/-/g, "");
}

export const camelCaseToTitleCase = (title: string) => {
  // Add space before capital letters and replace camelCase with spaces
  let spaced = title.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter and remove leading space
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
};

export const toCamelCase = (inputString: string): string => {
  return inputString
    .split("_")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
};

export const camelToSnake = (title: string) => {
  return title.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
};
