import { FormControlLabel, Radio } from "@mui/material";

type LabelPosition = "start" | "end" | "top" | "bottom";

interface RadioBtnProps {
  value: any;
  labelContent?: React.ReactNode | string;
  checked?: boolean;
  labelPosition?: LabelPosition;
  className?: string;
  handleChange?: any;
  disabled?:boolean
}

export const RadioBtn: React.FC<RadioBtnProps> = ({
  value,
  labelContent,
  checked,
  labelPosition = "end",
  className,
  handleChange,
  disabled
}) => {
  return (
    <FormControlLabel
      className={className}
      value={value}
      disabled={disabled}
      control={
        <Radio
          sx={{
            "&.Mui-checked": {
              color: "#024C6F",
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "none",
              boxShadow: "none",
            },
          }}
          disableRipple
          checked={checked}
          onChange={handleChange}
        />
      }
      label={labelContent}
      labelPlacement={labelPosition}
    />
  );
};
