import apiClient from "services/apiClient";

async function getObservsersByProgramDropdown(programId: any) {
  const res = await apiClient.get(`/admin/observers`, {
    params: {
      programId: programId,
      limit:100000
    },
  });
  return res.data;
}

export default getObservsersByProgramDropdown;
