import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { GET_TOTAL_COUNTS, GET_TOTAL_COUNTS_STATE } from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- TOTAL COUNTS
 * @returns
 */
export const fetchTotalCounts =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_TOTAL_COUNTS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.datePeriod) {
        Object.assign(params, {
          date_from: params.datePeriod.from,
          date_to: params.datePeriod.to,
        });
        delete params.datePeriod;
      }
      const { data } =
        await apiLibrary.Dashboard.widgets.getTotalCounts(params);

      const {
        communityCount,
        formCount,
        observationCount,
        programCount,
        speciesCount,
        userCount,
      } = data;

      const updatedData = {
        communities: {
          title: "Communities",
          count: communityCount,
          link: "/community/list",
        },
        forms: {
          title: "Forms",
          count: formCount,
          link: "/forms/list",
        },
        observations: {
          title: "Observations",
          count: observationCount,
          link: "/observations/list",
        },
        programs: {
          title: "Programs",
          count: programCount,
          link: "/programs/list",
        },
        species: {
          title: "Species",
          count: speciesCount,
          link: "/observations/list",
        },
        users: {
          title: "Users",
          count: userCount,
          link: "/users/list",
        },
      };

      dispatch({
        type: GET_TOTAL_COUNTS,
        payload: { data: updatedData },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_TOTAL_COUNTS_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log("An error occurred while fetching the KPIS:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
