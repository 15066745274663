import { ColumnProps } from "../../types";
import { RenderCellContent } from "../index";

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  programId,
  id
}: ColumnProps) => {
  const columnId = cell?.column?.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell?.column?.columnDef !== undefined;

  if (isColumnVisible && cell?.column?.columnDef?.id !== "select") {
    return (
      <td key={cell.id} className="px-4 cursor-pointer">
        <RenderCellContent
          columnDef={cell.column.columnDef}
          cellValue={cell.getValue()}
          programId={programId}
          id={id}
        />
      </td>
    );
  } 
  else {
    return (
      <td
        key={cell.id}
        className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
      >
        {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
      </td>
    );
  }
};
