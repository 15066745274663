import { useMemo } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { BackToImportsBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { fetchImportsFile } from "store/importsViewData/initialState";

const generateDynamicImportsFileLinks = (
  label: any,
  importId: any
) => {
  return [
    { path: `/imports/list`, label: `Imports` },
    { path: `/imports/view-data/${importId}`, label: `View Data: ${sentenceCase(label)}` },
  ];
};

export const Header = () => {
  const { importId } = useParams();
  const navigate = useNavigate();

  const { data } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );

  const dynamicImportsFileLinks = useMemo(() => {
    return generateDynamicImportsFileLinks(
      data?.fileName ?? "",
      importId,
    );
  }, [data])


  const handleBackToImportsBtn = () => {
    navigate("/imports/list");
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-y-5">
          <Breadcrumb links={dynamicImportsFileLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-y-2 gap-x-8">
            <PageHeading
              name={data?.fileName}
            />

            <BackToImportsBtn handleBackToImportsBtn={handleBackToImportsBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};