import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddTagBtn, PageHeading } from "./components";

// Store Utils
import { useDispatch } from "react-redux";
import { openAddOrEditTagModalAction } from "store/modals";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = () => {
  switch (true) {
    default:
      return [{ path: `/tagging/list`, label: `Tagging` }];
  }
};
export const Header = () => {
  const dispatch = useDispatch();
  const dynamicTagLinks = generateDynamicBreadcrumbLinks();

  const { tags } = usePermissions();
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicTagLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            {tags.canCreateTag && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                <AddTagBtn
                  handleAddTagModel={() =>
                    dispatch(openAddOrEditTagModalAction())
                  }
                  label={"Add Tag"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
