import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { HARVEST_QUESTIONS_NAME } from "../panelsQuestionsName";
import { HARVEST_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

import { Serializer } from "survey-core";
import questionTypes from "../questionTypes";

export default {
  name: panelsName.HARVEST,
  title: panelsTitle.HARVEST,
  iconName: "icon-harvest",
  elementsJSON: [
    // {
    //   type: "html",
    //   html: `<h1 class="panels-title">${panelsTitle.HARVEST}</h1>`,
    // },
    {
      type: "html",
      html: `<h2 class="panels-title">Retrieval </h2>`,
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: HARVEST_QUESTIONS_NAME.RetrievalDate,
      title: "Retrieval Date And Time",
      isRequired: true,
    },
    // {
    //   type: "text",
    //   inputType: "time",
    //   name: HARVEST_QUESTIONS_NAME.RetrievalTime,
    //   title: "Retrieval Time",
    //   startWithNewLine: false,
    //   isRequired: true,
    // },
    {
      type: questionTypes.shapePicker,
      name: HARVEST_QUESTIONS_NAME.RetrievalLocation,
      title: "Retrieval Location (Region)",
      isRequired: true,
    },
    {
      type: "html",
      name:HARVEST_QUESTIONS_NAME.RetrievalLocation+"Type",
      html: `<h2 class="map-sub-heading">Shape picker</h2>`,
    },
    {
      type: questionTypes.pointPicker,
      name: HARVEST_QUESTIONS_NAME.ValuedFeautres,
      title: "Retrieval Location (Point)",
      isRequired: true,
    },
    {
      type: "html",
      name:HARVEST_QUESTIONS_NAME.ValuedFeautres+"Type",
      html: `<h2 class="map-sub-heading">Point picker</h2>`,
    },
    {
      type: "html",
      html: `<hr/>`,
    },
    {
      type: "html",
      html: `<h2 class="panels-title">Struck & lost </h2>`,
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: HARVEST_QUESTIONS_NAME.StruckLostDate,
      title: "Struck & Lost Date And Time",
      isRequired: true,
    },
    // {
    //   type: "text",
    //   inputType: "time",
    //   name: HARVEST_QUESTIONS_NAME.StruckLostTime,
    //   title: "Struck & Lost Time",
    //   startWithNewLine: false,
    //   isRequired: true,
    // },
    {
      type: questionTypes.shapePicker,
      name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion,
      title: "Struck & Lost Location (Region)",
      isRequired: true,
    },
    {
      type: "html",
      name:HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion+"Type",
      html: `<h2 class="map-sub-heading">Shape picker</h2>`,
    },
    {
      type: questionTypes.pointPicker,
      name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint,
      title: "Struck & Lost Location (Point)",
      isRequired: true,
    },
    {
      type: "html",
      name:HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint+"Type",
      html: `<h2 class="map-sub-heading">Point picker</h2>`,
    },
    {
      type: "dropdown",
      name: HARVEST_QUESTIONS_NAME.HarvestMethod,
      title: "Harvest Method",
      choices: HARVEST_QUESTIONS_CHOICES.HarvestMethod,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: HARVEST_QUESTIONS_NAME.ShotsFired,
      title: "Shots Fired",
      placeholder: "1",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: HARVEST_QUESTIONS_NAME.ExactAge,
      title: "Exact Age",
      placeholder: "1",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "text",
      name: HARVEST_QUESTIONS_NAME.ChaseTimeHours,
      title: "Chase Time (Hours)",
      placeholder: "Type",
      inputType: "number",
      isRequired: true,
    },
    {
      type: "text",
      name: HARVEST_QUESTIONS_NAME.ChaseTimeMinutes,
      title: "Chase Time (Minutes)",
      placeholder: "Type",
      inputType: "number",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: HARVEST_QUESTIONS_NAME.RetrievalMethod,
      title: "Retrieval Method",
      choices: HARVEST_QUESTIONS_CHOICES.RetrievalMethod,
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.HARVEST, HARVEST_QUESTIONS_NAME, Serializer),
};

export const harvestIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M16.7368 8.32932C17.322 8.32932 17.7963 7.85501 17.7963 7.26992C17.7963 6.68483 17.322 6.21053 16.7368 6.21053C16.1517 6.21053 15.6774 6.68483 15.6774 7.26992C15.6774 7.85501 16.1517 8.32932 16.7368 8.32932Z" fill="#909090"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.3568 20.9406V18.2474C11.9686 19.3288 16.044 18.4431 18.8969 15.5904C22.1588 12.3287 22.8491 7.46905 20.9678 3.53142C20.8633 3.31283 20.6871 3.13663 20.4685 3.03219C16.5306 1.15098 11.6706 1.8412 8.4087 5.10289C5.55542 7.95599 4.66983 12.0318 5.7519 15.6436H3.05947C2.47434 15.6436 2 16.1179 2 16.703C2 17.2881 2.47434 17.7624 3.05947 17.7624H6.23787V20.9406C6.23787 21.5257 6.71221 22 7.29733 22C7.88246 22 8.3568 21.5257 8.3568 20.9406ZM8.09473 15.9043C11.1821 17.2125 14.8852 16.6054 17.3986 14.0922C19.912 11.579 20.5191 7.87615 19.2109 4.78897C16.1235 3.48078 12.4204 4.08789 9.90702 6.6011C7.39365 9.1143 6.78648 12.8171 8.09473 15.9043Z" fill="#909090"/>
</svg>`;
