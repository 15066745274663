interface BehaviorType {
  id: number;
  typeName: string;
}

interface Behavior {
  behaviorName: string;
  dateAdded: string;
  id: number;
  status: string;
  types: BehaviorType[];
}

export interface IBehaviorsData {
  behaviors: Behavior[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}


export interface IfetchBehaviors {
  data: IBehaviorsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchBehaviors = {
  data: {
    behaviors: [],
    fields: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
