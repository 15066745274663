export interface IStatusHistory {
  date: string;
  seasonStatus: string;
  updatedBy: {
    id: number;
    name: string;
    image: {
      large: string;
      original: string;
      thumb: string;
    };
  };
}

export interface IProgramStatusHistoryData {
  history: IStatusHistory[];
  fields: string[];
}

export interface IfetchProgramStatusHistory {
  data: IProgramStatusHistoryData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

export const initialState: IfetchProgramStatusHistory = {
  data: {
    history: [],
    fields:[]
  },
  message: "",
  stateIs: "Idle",
};
