import coverImage from "assets/images/Auth/img-placeholder.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchSpecies } from "store/species/initialState";
import ellipsize from "ellipsize";
import "./Gridcard.scss";
import useActions from "hooks/useActions";
import PopOverMenu from "view/components/PopOverMenu";
import { PerimeterIcon } from "assets/icons/HeroIcons";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import { filtersInitialState } from "store/filters/initialState";

export const GridCard = () => {
  const { data } = useSelector<RootState, IfetchSpecies>(
    (state) => state?.allSpecies
  );
  const { speciesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { specieActions } = useActions();

  const getSpeciesActions = (data: any) => {
    const modalData = {
      status: speciesFilter.status,
      id: data.id,
      path: "directory",
    };

    return specieActions(modalData);
  };

  return (
    <>
      <div className="flex w-full flex-wrap gap-4">
        {data.species.map((items: any, index: number) => {
          return (
            <div key={index} className="overflow-hidden shadow-md transform hover:scale-105 transition-transform  bg-[white] rounded-lg w-full  md:w-[calc(50%-16px)] xl:w-[calc(33.3%-16px)] 2xl:w-[calc(25%-16px)]">
              <div className="flex flex-col justify-start items-center flex-grow relative rounded-lg bg-white">
                <Link
                  to={`/species/${items.id}/profile`}
                  key={index}
                  className="w-full h-[136px]"
                >
                  <img
                    src={items.image ? items.image : coverImage}
                    className="object-cover w-full h-full"
                    alt="specieImage"
                  />
                </Link>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3 p-3">
                  <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 pr-2">
                      <p className="self-stretch flex-grow-0 flex-shrink-0 w-52 text-base font-medium text-left capitalize text-secondary">
                        {ellipsize(`${items.commonName.name}`, 40)}
                      </p>
                    </div>
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
                        <p className="flex-grow w-[216px] text-sm text-left text-textMidLight">
                          {ellipsize(`${items.scientificName}`, 40)}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1">
                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 p-1 rounded-lg bg-primary/[0.08]">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-1">
                          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid">
                            {ellipsize(`${items.type.name}`, 40)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {getSpeciesActions(items).some(
                      (action: any) => action.isVisible === true
                    ) ? (
                      <PopOverMenu
                        style={"0px"}
                        icon={<PerimeterIcon className={` `} />}
                      >
                        <ActionDropDownMenu
                          data={items}
                          actions={getSpeciesActions(items)}
                        />
                      </PopOverMenu>
                    ) : (
                      <PerimeterIcon className="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
