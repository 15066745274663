import apiClient from "services/apiClient";

const getProgramsCommunities = async (
  programId: number,
  sortBy?: string,
  sortType?: string,
  searchString?: any
) => {
  const res = await apiClient.get(`/admin/programs/${programId}/communities`, {
    params: {
      sortBy: sortBy,
      sortType: sortType,
      searchString: searchString,
    },
  });
  return res.data;
};

export default getProgramsCommunities;
