import { useEffect } from "react";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateGetGpsDatas } from "store/surveyJS";
import { useDispatch } from "react-redux";

const useHandleMovementOfMapCenterPoint = (
  currentQuestionEle: any,
  map: any
) => {
  const { getGpsDatas, surveyActiveTab } = useSelector((state: RootState) => state.surveyJS);
  console.log("getGpsDatas", getGpsDatas)
  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyActiveTab === "test") {
      return;
    }
    if (
      currentQuestionEle?.mapCenterPoint &&
      currentQuestionEle.mapCenterPoint.doFly &&
      map
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      if (!longitude || !latitude) {
        return;
      }

      const coordinates = [parseFloat(longitude), parseFloat(latitude)];

      map.flyTo({
        center: coordinates,
        zoom: currentQuestionEle.zoomLevel ?? 2,

      });

      // stop the map moving on inputs change to set map center point

      const updatedCurrentQuestionEle = { ...currentQuestionEle };
      updatedCurrentQuestionEle.mapCenterPoint.doFly = false;

      updateElementByIdFromArray(
        getGpsDatas,
        updatedCurrentQuestionEle,
        (updatedGetGpsDatas: any) => {
          dispatch(updateGetGpsDatas(updatedGetGpsDatas));
        }
      );
    }
  }, [currentQuestionEle, map]);

  return null;
};

export { useHandleMovementOfMapCenterPoint };
