import usePermissions from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { RootState } from "store";
import { setLoadingProgress } from "store/loadingBar";
import { getUserProfileAction } from "store/userProfile";
import { UserProfileState } from "store/userProfile/initialState";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";
import { MaterialUiSelect } from "view/pages/MyProfile/Components/Inputs/Select";

const ChangePrimaryEmailAddress: React.FC<{
  refreshNewData: (data: any) => void;
}> = ({ refreshNewData }) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const [updatedEmail, setUpdatedEmail] = useState<any>(null);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const verifiedEmails = userProfile.secondaryEmail.filter(
    (item: any) => item.isVerified
  );
  const { personId } = useParams();
  const { profile } = usePermissions();

  const handlePrimaryChange = async () => {
    setIsLoading(true);
    if (
      updatedEmail &&
      updatedEmail?.isPrimary &&
      !profile.canEditPrimaryEmailPhoneProfile
    ) {
      return;
    }
    try {
      dispatch(setLoadingProgress(getRandomProgress()));
      if (updatedEmail && updatedEmail?.isVerified) {
        const res = await apiLibrary.userProfile.changePrimaryEmailOrPhone(
          {
            phone_or_email: updatedEmail?.email,
          },
          personId
        );

        refreshNewData(res.data);
        Toasts.success(res.message);
        setUpdatedEmail(null);
      }
    } catch (error: any) {
      // Handle the error appropriately
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  useEffect(() => {
    if (!updatedEmail) {
      setUpdatedEmail(verifiedEmails?.find((email: any) => email.isPrimary));
    }
  }, []);

  return (
    <div className="flex items-center ">
      <div className=" flex flex-col w-[300px]">
        <p
          className={`flex-grow pb-2 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption`}
        >
          Update Primary Email Address
        </p>
        <MaterialUiSelect
          options={verifiedEmails}
          labelKey="email"
          valueKey="email"
          initialValue={
            updatedEmail
              ? updatedEmail.email
              : verifiedEmails?.find((email: any) => email.isPrimary)?.email
          }
          handleChange={(value: any) => {
            setUpdatedEmail(value);
          }}
          width={300}
        />
      </div>

      <button
        disabled={
          isLoading ||
          (updatedEmail && updatedEmail?.isPrimary) ||
          !profile.canEditPrimaryEmailPhoneProfile
        }
        type="button"
        className=" border border-lineMid disabled:cursor-not-allowed dark:border-lineLight rounded h-[40px] w-[70px] ml-3 mt-7 bg-primary text-textMain dark:text-caption"
        onClick={handlePrimaryChange}
      >
        Save
      </button>
    </div>
  );
};

export default ChangePrimaryEmailAddress;
