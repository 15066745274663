import { apiClient } from "../config";

async function adminObservationRequestConsent(
  observationId: number,
  consent: any
) {
  const res = await apiClient.put(
    `/admin/observations/${observationId}/request-consent`,
    {
      requestConsent: consent,
    }
  );
  return res.data;
}

export default adminObservationRequestConsent;
