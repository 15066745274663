import { GET_NOTIFICATION_SUMMARY } from "./action-types";
import { initialNotificationsSummaryData } from "./initialState";

const notificationsSummaryReducer = (
  state = initialNotificationsSummaryData,
  action: any
): any => {
  switch (action.type) {
    case GET_NOTIFICATION_SUMMARY:
      return action.payload;
    case "RESET_NOTIFICATIONS_SUMMARY_DATA":
      return initialNotificationsSummaryData;
    default:
      return state;
  }
};

export default notificationsSummaryReducer;
