import { useEffect } from "react";
import { debounce } from "lodash";
import calculateMultiEntityZoomLevel from "utils/calculateMultiEntityZoomLevel";

const useApplyFitboundsToTheMap = (
  allCommunityMarkers: any[],
  map: any,
  isMapFullyLoaded: any
) => {
  const updateBounds = debounce(() => {
    if (
      allCommunityMarkers &&
      allCommunityMarkers.length > 0 &&
      map &&
      isMapFullyLoaded
    ) {
      const calculation = calculateMultiEntityZoomLevel(
        allCommunityMarkers.filter(
          (item) => item.mapData !== null && item.type !== "raster"
        ),
        map
      );

      if (calculation?.bounds && calculation?.maxZoom) {
        map.fitBounds(calculation.bounds, {
          padding: 40,
          // maxZoom: calculation.maxZoom,
        });
      }
    }
  }, 300);

  // useEffect(() => {
  //   if (map) {
  //     map.on("fullscreenchange", updateBounds);

  //   }
  // }, [map, isMapFullyLoaded]);

  useEffect(() => {
    updateBounds();
  }, [map, isMapFullyLoaded, allCommunityMarkers]);
};

export { useApplyFitboundsToTheMap };
