import useDateFormatter from "hooks/formatDateWithPattern";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import { Link } from "react-router-dom";
import useRoles from "hooks/roles";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const { isUserInternalReviewer, isInternalReviewerCommunityAdmin } = useRoles();
  const { organizations } = usePermissions();
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "name"
  ) {

    return (
      <Link
        to={
          (organizations.canViewOrganization &&
            organizations.canViewOrganizationDirectory) &&
            isInternalReviewerCommunityAdmin()
            ? `/organizations/${cellValue?.id}/profile`
            : ""
        }
      >
        <div className=" flex justify-start  items-center relative hover_press_effect min-w-[200px] max-w-[350px] group">
          <img
            src={
              cellValue.organizationImage.file.thumb
                ? cellValue.organizationImage.file.thumb
                : getBrandPlaceholder("organizationProfile")
            }
            alt=""
            height="100"
            width="100"
            className="mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: "wrap" }}
            className="flex flex-col justify-start items-start"
          >
            <p className="w-[200px] text-sm text-left break-all text-textMid group-hover:text-primaryDark py-2">
              {cellValue?.name}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (columnDef.id === "status" && !cellValue !== null) {
    return (
      <div className="flex items-start justify-start flex-grow-0 flex-shrink-0 px-3">
        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-1 px-1.5 py-0.5 rounded bg-tertiaryExtraLight">
          <div className="relative flex items-start justify-start flex-grow-0 flex-shrink-0 py-1">
            <p className="flex-grow-0 flex-shrink-0 text-xs text-[tertiary] dark:text-caption ">
              {cellValue ? "active" : "inactive"}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id !== "dateAdded") {
    return (
      <div
        className={`relative flex items-start justify-start flex-grow py-1 px-3} ${columnDef.id === "tags" ? "justify-start pl-2 w-[170px]" : ""
          } ${columnDef.id === "members" || columnDef.id === "programs"
            ? "justify-start pl-3"
            : "justify-start"
          }
        ${columnDef.id === "communities" ? "justify-start pl-3" : "ml-0"}`}
      >
        <p className="text-sm text-left text-textMid dark:text-caption ">
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div className="relative flex items-start flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid dark:text-caption ">
          {formatDate(cellValue)}
        </p>
      </div>
    );
  } else {
    return <></>;
  }
};
