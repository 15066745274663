import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { qualityAssuranceObservationsReviewerAction } from "store/filters/reducer.actions";

export const SelectedReviewers: React.FC = () => {
  const dispatch = useDispatch();
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const { reviewerId } = useParams<{
    reviewerId: string;
  }>();

  const resetFilter = (reviewer: any) => {
    dispatch(qualityAssuranceObservationsReviewerAction(reviewer));
  };

  if (qualityAssuranceObservationsFilter.reviewerIds?.applied) {
    return qualityAssuranceObservationsFilter.reviewerIds.value.map(
          (item: any, index: number) => (
             <div
        key={index}
        className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="break-all w-full flex-shrink-0 text-sm text-start text-textMid dark:text-inputText capitalize">
            {item.name}
          </p>
        </div>
              {!reviewerId && (
                <ClearFilters handleClick={() => resetFilter(item)} />
              )}
            </div>
          )
        )
  } else {
    return null;
  }
};
