import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { useDispatch } from "react-redux";
// types
import { TableProps } from "./types";

export const KEYS_TO_BE_REMOVED = ["id"];

export const Table: React.FC<TableProps> = ({
  table,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {

  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort()
  return (
    <div className="w-full overflow-hidden overflow-x-auto max-h-[80vh] overflow-y-auto">
      <table className="">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        />
      </table>
    </div>
  );
};
