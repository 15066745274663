import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { SAMPLE_QUESTIONS_NAME } from "../panelsQuestionsName";

import { Serializer } from "survey-core";
import { SAMPLE_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

const currentState = window.history.state;

export default {
  name: panelsName.SAMPLE,
  title: panelsTitle.SAMPLE,
  iconName: "icon-sample",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.SAMPLE}</h1>`,
    },
    {
      type: "text",
      name: SAMPLE_QUESTIONS_NAME.SampleBioId,
      title: "Bio Sample ID",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SAMPLE_QUESTIONS_NAME.SampleType,
      title: "Sample Type",
      choices: SAMPLE_QUESTIONS_CHOICES.SampleType,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: SAMPLE_QUESTIONS_NAME.SampleAmount,
      title: "Sample Amount",
      placeholder: "1",
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SAMPLE_QUESTIONS_NAME.AmountUnits,
      title: "Amount Units",
      choices: SAMPLE_QUESTIONS_CHOICES.AmountUnits,
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "text",
      name: SAMPLE_QUESTIONS_NAME.SampleCount,
      title: "Sample Count",
      inputType: "number",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SAMPLE_QUESTIONS_NAME.Sampler,
      title: "Sampler(S)",
      isRequired: true,
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: SAMPLE_QUESTIONS_NAME.TissueRemovalTime,
      title: "Tissue Removal Time",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: SAMPLE_QUESTIONS_NAME.TissueFrozenTime,
      title: "Tissue Frozen Time",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "text",
      name: SAMPLE_QUESTIONS_NAME.ShippedTo,
      title: "Shipped To",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: SAMPLE_QUESTIONS_NAME.ShippingDate,
      title: "Shipping Date",
      isRequired: true,
    },
    {
      type: "text",
      name: SAMPLE_QUESTIONS_NAME.CurrentLocation,
      title: "Current Location",
      placeholder: "Current location",
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.SAMPLE, SAMPLE_QUESTIONS_NAME, Serializer),
};

export const sampleIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C6 3.34315 7.34315 2 9 2H15C16.6569 2 18 3.34315 18 5V6.17071C19.1652 6.58254 20 7.69378 20 9V10.1707C21.1652 10.5825 22 11.6938 22 13V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V13C2 11.6938 2.83481 10.5825 4 10.1707V9C4 7.69378 4.83481 6.58254 6 6.17071V5ZM8 6H16V5C16 4.44772 15.5523 4 15 4H9C8.44772 4 8 4.44772 8 5V6ZM6 10H18V9C18 8.44772 17.5523 8 17 8H7C6.44772 8 6 8.44772 6 9V10ZM5 12C4.44772 12 4 12.4477 4 13V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V13C20 12.4477 19.5523 12 19 12H5Z" fill="#909090"/>
</svg>`;
