import AdjustmentsIcon from "assets/icons/HeroIcons/AdjustmentsIcon";
import { FiltersBtnProps } from "../../types";

const FiltersBtn = ({ handleToggleDropdown, isCloseDiv }: FiltersBtnProps) => {
  return (
    <button
      className={`flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg ${isCloseDiv && "bg-primary/[0.08]"} `}
      onClick={handleToggleDropdown}
    >
      <AdjustmentsIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#2C3236"
        className="rotate-90"
      />
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-inputText">
          Filters
        </p>
      </div>
    </button>
  );
};

export default FiltersBtn;
