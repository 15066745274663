import apiClient from "services/apiClient";

async function createManualReportVersion(
  reportId: any,
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string
) {
  const res = await apiClient.post(
    `/admin/reports/${reportId}/manual-version`,
    {
      startDate,
      endDate,
      startTime,
      endTime,
    }
  );
  return res.data;
}

export default createManualReportVersion;
