import { Dispatch } from "redux";
import {
  ERROR_LOADING_COMMUNITIES,
  GET_ALL_COMMUNITIES,
  RESET_DATA,
  START_LOADING_COMMUNITIES,
  STOP_LOADING_COMMUNITIES,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchCommunitiesAction =
  (organizationId?: any, programId?: any) =>
  async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_COMMUNITIES });
    // dispatch(setLoadingProgress(getRandomProgress()));

    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.communitiesFilter
      );
      
      const query = appendOrganizationOrProgramIdInParams(
        organizationId,
        programId,
        params
      );
      
      const { data } = await apiLibrary.Communities.getAllCommunities(query);
      dispatch({
        type: GET_ALL_COMMUNITIES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      // dispatch(fetchError(error))
      dispatch({ type: ERROR_LOADING_COMMUNITIES });
    } finally {
      dispatch({ type: STOP_LOADING_COMMUNITIES });
      // dispatch(setLoadingProgress(100));

      // dispatch(loadingState(false))
    }
  };

const appendOrganizationOrProgramIdInParams = (
  organizationId: any,
  programId: any,
  params: any
) => {
  let updatedParams = { ...params };

  if (organizationId) {
    updatedParams.organizationIds = organizationId.toString();
  } else if (programId) {
    updatedParams.programIds = programId.toString();
  }

  return updatedParams;
};
