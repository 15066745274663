
const Required = ({
  checked,
  handleCheckboxOnChange,
}: any) => {
  return (
    <div className="flex gap-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxOnChange}
        id="isRequired"
        className="w-4" 
      />
      <label htmlFor="isRequired" className="cursor-pointer text-secondaryMidLight">
        Required
      </label>
    </div>
  );
};

export { Required };
