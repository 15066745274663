import moment from "moment";

interface FilterData {
  path: string;
  filters: any;
  date: any;
}

export function saveFiltersDataToLocalStorage(filterData: any) {
  const currentTime = moment().valueOf().toString();
  const storedFilters = localStorage.getItem("filtersData");
  const filtersArray: FilterData[] = storedFilters
    ? JSON.parse(storedFilters)
    : [];

  const filterIndex = filtersArray.findIndex(
    (filter) => filter.path === window.location.pathname
  );

  const updatedData = { ...filterData };
  delete updatedData.status;
  // delete updatedData.type;
  if (filterIndex > -1) {
    filtersArray[filterIndex] = {
      path: window.location.pathname,
      date: currentTime,
      filters: {
        ...updatedData,
        sortBy: "",
        sortType: "",
        page: 1,
        limit: 10,
        viewType: "list",
      },
    };
  } else {
    filtersArray.push({
      path: window.location.pathname,
      date: currentTime,
      filters: {
        ...updatedData,
        sortBy: "",
        sortType: "",
        page: 1,
        limit: 10,
        viewType: "list",
      },
    });
  }

  localStorage.setItem("filtersData", JSON.stringify(filtersArray));
}

export function getFiltersDataFromLocalStorage(): FilterData | undefined {
  const storedFilters = localStorage.getItem("filtersData");

  const returivedFilters = storedFilters ? JSON.parse(storedFilters) : [];

  return returivedFilters.find(
    (filter: FilterData) => filter.path === window.location.pathname
  );
}

export function removeExpiredFiltersFromLocalStorage() {
  const storedFilters = localStorage.getItem("filtersData");
  const retrievedFilters: any[] = storedFilters
    ? JSON.parse(storedFilters)
    : [];

  const updatedFilters = retrievedFilters.reduce((acc, filter: FilterData) => {
    if (!shouldClearFilter(filter.date)) {
      acc.push(filter);
    }
    return acc;
  }, []);

  localStorage.setItem("filtersData", JSON.stringify(updatedFilters));
}

function shouldClearFilter(time: string): boolean {
  const filterTimeMoment = moment(parseInt(time));
  const minutesSinceDismissed = moment().diff(filterTimeMoment, "minutes");
  return minutesSinceDismissed >= 30;
}
