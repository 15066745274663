import React, { useEffect, useState } from "react";
/************ Components ************/
import SocialButton from "./SocialAccountButton";
import { Toasts } from "view/components/Toasts";
/************ Utils ************/
import { googleClientId } from "config";
/************ API Services ************/
import apiLibrary from "services/api";
/************ Store Utils ************/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { updateSocialAccountsAction } from "store/userProfile";
/************ Assets ************/
import GoogleImg from "assets/images/Auth/googeIcon.png";
import SocialGoogleIcon from "assets/icons/SocialIcons/SocialGoogleIcon";
/************ External Library ************/
import { loadGapiInsideDOM } from "gapi-script";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";

export const ConnectGoogle: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { detachSocialAccount, attachSocialAccount } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  useEffect(() => {
    (async () => {
      await loadGapiInsideDOM();
    })();
  });

  /**
   * Handles the process of determining if a social account is connected.
   *
   * @param {string} name - The name of the social account to check.
   * @returns {string} The connection status, either "Connected" or "Disconnected".
   */
  const isConnected = (name: any) => {
    return userProfile?.socialAccounts?.find((item: any) => item === name)
      ? "Connected"
      : "Disconnected";
  };

  /**
   * Handles the response from Google login and performs appropriate actions.
   *
   * @param {GoogleLoginResponse | GoogleLoginResponseOffline} response - The response object from Google login.
   */
  const responseGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("profileObj" in response) {
      const accessToken = (response as GoogleLoginResponse).accessToken;
      setIsLoading(true);
      try {
        if (
          isConnected("google") === "Connected" &&
          detachSocialAccount.canCreateDetachSocialAccount
        ) {
          const res = await apiLibrary.Auth.detachSocialAccounts({
            channel: "google",
          });
          dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
          Toasts.success(res.message);
          return;
        }
        if (attachSocialAccount.canCreateAttachSocialAccount) {
          const res = await apiLibrary.Auth.attachSocialAccounts({
            accessToken,
            channel: "google",
          });
          dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));

          Toasts.success(res.message);
        }
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("Google login failed:", response);
      setIsLoading(false);
    }
  };

  const deAttachSocialAccounts = async () => {
    if (
      isConnected("google") === "Connected" &&
      detachSocialAccount.canCreateDetachSocialAccount
    ) {
      setIsLoading(true);
      try {
        const res = await apiLibrary.Auth.detachSocialAccounts({
          channel: "google",
        });
        dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
        Toasts.success(res.message);
      } catch (error: any) {
        console.error("Error detaching social account:", error);
        // Handle error here
      } finally {
        setIsLoading(false);
      }
    }
  };
  if (isConnected("google") === "Connected") {
    return (
      <>
        <div className="relative">
          {isLoading && (
            <div className="loader absolute justify-center items-center flex w-full">
              <TailSpin
                height="30"
                width="30"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{ marginTop: "3px" }}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          )}
          <SocialButton
            onClick={deAttachSocialAccounts}
            className={isLoading ? "opacity-[0.4]" : ""}
            itemHover={isLoading ? true : false}
            disabled={
              isLoading || !detachSocialAccount.canCreateDetachSocialAccount
                ? true
                : false
            }
            icon={SocialGoogleIcon}
            text={isConnected("google")}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className="relative">
        {isLoading && (
          <div className="loader absolute justify-center items-center flex w-full">
            <TailSpin
              height="30"
              width="30"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{ marginTop: "3px" }}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        )}
        <GoogleLogin
          className=""
          clientId={googleClientId}
          render={(renderProps: any) => (
            <SocialButton
              className={isLoading ? "opacity-[0.4]" : ""}
              disabled={
                renderProps.disabled ||
                isLoading ||
                (isConnected("google") === "Connected" &&
                  !detachSocialAccount.canCreateDetachSocialAccount) ||
                !attachSocialAccount.canCreateAttachSocialAccount
                  ? true
                  : false
              }
              onClick={renderProps.onClick}
              itemHover={isLoading ? true : false}
              icon={SocialGoogleIcon}
              text={isConnected("google")}
            />
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </div>
    );
  }
};
