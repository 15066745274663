import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  className?: string;
}

export const XCloseSmallIcon = ({className, ...restProps }: IconProps) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.52827 3.52876C3.78862 3.26841 4.21073 3.26841 4.47108 3.52876L7.99967 7.05735L11.5283 3.52876C11.7886 3.26841 12.2107 3.26841 12.4711 3.52876C12.7314 3.78911 12.7314 4.21122 12.4711 4.47157L8.94248 8.00016L12.4711 11.5288C12.7314 11.7891 12.7314 12.2112 12.4711 12.4716C12.2107 12.7319 11.7886 12.7319 11.5283 12.4716L7.99967 8.94297L4.47108 12.4716C4.21073 12.7319 3.78862 12.7319 3.52827 12.4716C3.26792 12.2112 3.26792 11.7891 3.52827 11.5288L7.05687 8.00016L3.52827 4.47157C3.26792 4.21122 3.26792 3.78911 3.52827 3.52876Z"
      fill="#2C3236"
      className={className}
    />
  </svg>
);
export default XCloseSmallIcon;
