import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const CheveronLeftIcon = ({ fill, isActive, ...restProps }: IconProps) => (
  <svg
    width={18}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-5 relative"
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:fill-primary"
      }`}
      fillRule="evenodd"
      d="M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheveronLeftIcon;
