import React from "react";
import { snakeToCamel } from "utils/caseConvertor";
import { TextField, Dropdown, MultiselectDropdown } from "../../Components";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import { PointPicker, ShapePicker } from "../../MapComponents";

interface GeographicalInformationProps {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  handleUpdateStatus: any;
}

const GeographicalInformation: React.FC<GeographicalInformationProps> = ({
  panel,
  editMode,
  formikProps,
  handleUpdateStatus,
}) => {
  const onChange = (eleName: string, value: any, name: string) => {
    formikProps.setFieldValue(eleName, value);
    handleUpdateStatus(name, panel.sectionId);
  };

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mb-5">
        {panelElements.map((element: any, index: number) => {
          const {
            name,
            title,
            choices,
            fieldAs,
            rows,
            value,
            inputType,
            type,
            placeholder,
            isRequired = false,
            visible = true,
            choicesByUrl,
            defaultValue,
          } = element;
          const eleName = [panelName, element.name].join(".");
          const error = formikProps.errors[panelName]?.[element.name];
          const touched = formikProps.touched[panelName]?.[name];
          if (visible === false || type === "html" || defaultValue) {
            return;
          }

          switch (element.type) {
            case "comment":
              return (
                <div className="col-span-12">
                  <TextField
                    key={index}
                    label={title || name}
                    type="text"
                    name={eleName}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) =>
                      onChange(eleName, value, name)
                    }
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    fieldAs="textarea"
                    rows={rows}
                    editMode={editMode}
                    placeholder={placeholder || "Type"}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    answers={formikProps.values[panelName]}
                    panel={panel}
                    element={element}    
                  />
                </div>
              );

            case questionTypes.shapePicker:
              return (
                <div className="col-span-12">
                  <ShapePicker
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(eleName: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                </div>
              );
            case questionTypes.pointPicker:
              return (
                <div className="col-span-12">
                  <PointPicker
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(eleName: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                </div>
              );

            default:
              return null; // Or render a default component for unknown types
          }
        })}
      </div>
    </>
  );
};

export default GeographicalInformation;
