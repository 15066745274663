import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { panelsName } from "../../../panelsTitle";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import { updateDropPins } from "store/surveyJS";

const useHandleMapMovement = (currentQuestionEle: any, map: any) => {
  const [isCenterPointSetup, setIsCenterPointSetup] = useState(false);
  const { dropPins, selectedQuestionElement, surveyActiveTab } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const selectedQuestionType = selectedQuestionElement?.getType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentQuestionEle && map && !isCenterPointSetup) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
      
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,
        });
      }
      // update map center values
      else {
        map.on("load", () => {
          updateDropPinsCenterPoint(
            currentQuestionEle,
            map,
            selectedQuestionType,
            dropPins,
            dispatch
          );
        });
      }

      if (
        selectedQuestionType !== panelsName.HARVEST &&
        selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        setIsCenterPointSetup(true);
      }
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (currentQuestionEle && map) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        updateDropPinsCenterPoint(
          currentQuestionEle,
          map,
          selectedQuestionType,
          dropPins,
          dispatch
        );
      });
    }
  }, [currentQuestionEle, map]);

  return null;
};

function updateDropPinsCenterPoint(
  currentQuestionEle: any,
  map: any,
  selectedQuestionType: any,
  dropPins: any,
  dispatch: any
) {
  const updatedCurrentDropPin = { ...currentQuestionEle };

  // update current element
  updatedCurrentDropPin.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };

  updatedCurrentDropPin.zoomLevel = map.getZoom();
  if (
    selectedQuestionType !== panelsName.HARVEST &&
    selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
  ) {
    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  }
}

export { useHandleMapMovement };
