import { useSelector } from "react-redux";
import { HeaderBarProps } from "../../types";
import ColumnBtn from "../ColumnBtn";
import FiltersBtn from "../FiltersBtn";
import { RootState } from "store";
import GridBtn from "../GridBtn";
import ListBtn from "../ListBtn";
import { filtersInitialState } from "store/filters/initialState";
import SearchBar from "../SearchBar";

const HeaderBar = ({
  data,
  isOpen,
  setIsOpen,
  handleToggleDropdown,
  isCloseDiv
}: HeaderBarProps) => {
  const { observationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-caption">
            {data?.totalItems} Items
          </p>
        </div>
      </div>

      <div className="flex items-center justify-end flex-grow h-8 gap-2">
        <div className="flex justify-end items-center flex-grow h-8 gap-2">
          <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
            <SearchBar />
            <div className="flex-grow-0 flex-shrink-0 h-6 relative rotate-y-180 border border-b-0 border-lineMid"></div>
            <ListBtn />
            <GridBtn />
          </div>
          <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 relative gap-2 ">
            <div className="flex-grow-0 flex-shrink-0 h-6 relative rotate-y-180 border border-b-0 border-lineMid"></div>
            {observationsFilter.viewType === "list" && (
              <ColumnBtn isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
            <FiltersBtn handleToggleDropdown={handleToggleDropdown} isCloseDiv={isCloseDiv} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
