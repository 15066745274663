import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import useDateRange from "hooks/useDateRange";
import { useDispatch } from "react-redux";
import { orgzanizationsDateAddedAction } from "store/filters";

interface DateProps {
    name: string;
    from: string;
    to: string;
    isApplied: boolean;
  }
  


  export const Date: React.FC<DateProps> = ({
    name,
    from,
    to,
    isApplied,
  }) => {
    const dispatch = useDispatch();
    const { getDateOption } = useDateRange();
    const resetFilter = () => {
      dispatch(orgzanizationsDateAddedAction("", ""));

    };
  
    if (!isApplied) {
      return null;
    }
    if (from === "Invalid date") {
      dispatch(orgzanizationsDateAddedAction("", to));
    } else if (to === "Invalid date") {
      dispatch(orgzanizationsDateAddedAction(from, ""));
    }
    return (
      <div className="flex justify-between px-2  items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center capitalize text-textMid dark:text-inputText ">
            {sentenceCase(name)}: {getDateOption(from, to)}


          </p>
        </div>
        <ClearFilters handleClick={resetFilter} />
      </div>
    );
  };