import { Dispatch } from "redux";
import {
  ERROR_LOADING_POLLS_AND_SURVEYS,
  GET_ALL_POLLS_AND_SURVEYS,
  RESET_DATA,
  START_LOADING_POLLS_AND_SURVEYS,
  STOP_LOADING_POLLS_AND_SURVEYS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";


export const fetchPollsAndSurveysAction =
  (programId?: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_POLLS_AND_SURVEYS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.pollsAndSurveysFilter
      );
      const query = appendProgramIdInParams(
        programId,
        params
      );

      const { data } = await apiLibrary.pollsAndSurveys.getAllPolls(query);
      dispatch({
        type: GET_ALL_POLLS_AND_SURVEYS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      dispatch({ type: ERROR_LOADING_POLLS_AND_SURVEYS });
      console.log("An error occurred while fetching the imports:", error);
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_POLLS_AND_SURVEYS });
      // dispatch(setLoadingProgress(100));
    }
  };

export const resetpollsAndSurveys = () => {
  return {
    type: RESET_DATA,
  };
};



const appendProgramIdInParams = (
  programId: any,
  params: any
) => {
  let updatedParams = { ...params };
  if (programId) {
    updatedParams.programIds = programId.toString();
  }

  return updatedParams;
};
