import { panelsTitle,panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";
import { Serializer } from "survey-core";
import { COUNT_DETAILS_QUESTIONS_NAME } from "../panelsQuestionsName";
import { COUNT_DETAILS_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";


export default {
  name: panelsName.COUNT_DETAILS,
  title: panelsTitle.COUNT_DETAILS,
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.COUNT_DETAILS}</h1>`,
    },
    {
      type: "text",
      inputType: "number",
      placeholder: "Select",
      name: COUNT_DETAILS_QUESTIONS_NAME.ExactCount,
      title: "Exact Count",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      placeholder: "Select",
      name: COUNT_DETAILS_QUESTIONS_NAME.MinimumCount,
      title: "Minimum Count",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      placeholder: "Select",
      name: COUNT_DETAILS_QUESTIONS_NAME.MaximumCount,
      title: "Maximum Count",
      startWithNewLine: false,
      isRequired: true,
    },

    {
      type: "dropdown",
      name: COUNT_DETAILS_QUESTIONS_NAME.CountUnits,
      title: "Count Units",
      choices: COUNT_DETAILS_QUESTIONS_CHOICES.CountUnits,
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.COUNT_DETAILS, COUNT_DETAILS_QUESTIONS_NAME, Serializer),
};


export const countDetailsIcon = `<svg class="sv-svg-icon sv-list__item-icon" role="img" style="width: 24px; height: 24px;"><use xlink:href="#icon-default"></use></svg>`;

