import { ERROR_LOADING_COMMUNITY_GEOGRAPHIES, GET_ALL_COMMUNITY_GEOGRAPHIES, RESET_DATA, START_LOADING_COMMUNITY_GEOGRAPHIES, STOP_LOADING_COMMUNITY_GEOGRAPHIES } from "./action-types";
import { initialState } from "./initialState";

export const communitiesGeographiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_COMMUNITY_GEOGRAPHIES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_COMMUNITY_GEOGRAPHIES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_COMMUNITY_GEOGRAPHIES:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_COMMUNITY_GEOGRAPHIES:


      return {
        ...state,
        data: action.payload,
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

