export interface IProtocolForm {
  id: number;
  name: string;
  image: {
    original: string;
    thumb: string;
  };
  isInherit: boolean;
  protocolConnectedAt: string;
}

export interface IProtocol {
  form: IProtocolForm[],
  program: {
    id: number;
    name: string;
    image: {
      original: string;
      thumb: string;
    };
    programStatus: string | undefined;
  };
  createdBy: {
    name: string;
  };
  edited: {
    date: string;
  };
  protocolDescription: string;
  totalFiles: number;

  files: [
    {
      file: {
        original: string;
        large: string;
        thumb: string;
      };
      fileSize: string;
      name: string;
      type: string;
      id: string;
      uploadedDate: string;
    },
  ];
  protocolId: number;
  protocolTitle: string;
}

export const initialState: IProtocol = {
  form: [],
  program: {
    id: 0,
    name: "",
    image: {
      original: "",
      thumb: "",
    },
    programStatus: "",
  },
  createdBy: {
    name: "",
  },
  edited: {
    date: "",
  },
  protocolDescription: "",
  totalFiles: 0,
  files: [
    {
      file: {
        original: "",
        large: "",
        thumb: "",
      },
      fileSize: "",
      name: "",
      type: "",
      id: "",
      uploadedDate: "",
    },
  ],
  protocolId: 0,
  protocolTitle: "",
};
