import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeProcessedReportModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface ReportsProcessedModalProps {}

export const ReportsProcessedModal: React.FC<
  ReportsProcessedModalProps
> = () => {
  const { processedReportModal } = useSelector(
    (state: RootState) => state?.modals
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeProcessedReportModalAction());
  };

  useEffect(() => {
    if (processedReportModal?.isOpen) {
      const timeout = setTimeout(() => {
        handleClose(); // Automatically close modal after 2 seconds
      }, 2000);

      return () => clearTimeout(timeout); // Clear the timeout on unmount
    }
  }, [processedReportModal?.isOpen]);

  return (
    <Modal
      open={processedReportModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Your Report is being Processed
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                Are you sure you want to delete this? Anyone that had access
                will no longer have access to it.
              </p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
