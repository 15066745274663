import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import audioThumbnailImg from "assets/images/Placeholders/audio-placeholder.png";

import { Toasts } from "view/components/Toasts";
import ellipsize from "ellipsize";

// File Upload Interface props
interface FileUploadProps {
  handleOnDropFiles: (files: any, rejectedFiles: any) => void;
  handleDeleteFile: (id: any) => void;
  handleUpdateFile: (file: any, id: any) => void;
  files: any;
  acceptedFileTypes: any;
  isSubmitting?: boolean;
  editMode?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  handleOnDropFiles,
  handleDeleteFile,
  handleUpdateFile,
  files,
  acceptedFileTypes,
  isSubmitting,
  editMode,
}) => {
  return (
    <div
      className={`w-full flex flex-col justify-start items-start gap-6 ${isSubmitting ? "pointer-events-none" : ""} `}
    >
      <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
        {({ getRootProps, getInputProps }) => (
          <div
            className={`w-full flex flex-col justify-center items-center mt-2 px-4 py-2 rounded-lg bg-bgWhite dark:bg-[transparent] border border-lineDark border-dashed`}
          >
            <section>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="w-full">
                  <div className="flex justify-center">
                    <CloudUploadIcon />
                  </div>
                  <p className="dark:text-caption">
                    Or drag and drop your files here or click in this area
                  </p>
                  {/* <p>add new things  </p> */}
                </div>
              </div>
            </section>
          </div>
        )}
      </Dropzone>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
        <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-textMain ">
          Visit{" "}
          <a
            className="font-medium text-primary"
            href="https://www.iloveimg.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.iloveimg.com
          </a>{" "}
          to crop your image. For the best result on all devices, use a file of{" "}
          <span className="font-medium">10 MB</span> or less.
        </p>
      </div>{" "}
      {files?.length > 0 && (
        <div className="grid grid-cols-1 gap-2 px-2 mb-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {files.map((f: any, index: number) => (
            <FileBox
              file={f}
              editMode={editMode}
              key={index.toString()}
              index={index}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              acceptedFileTypes={acceptedFileTypes}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const FileBox = ({
  editMode,
  file,
  index,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
}: any) => {
  const [thumbnail, setThumbnail] = useState("");
  const allowedFileTypes = ["mp3", "wav", "acc"];
  const isAudio =
    file?.type?.startsWith("audio/") || allowedFileTypes.includes(file?.type);

  // Update thumbnail logic to handle both images and audio
  useEffect(() => {
    if (!isAudio) {
      setThumbnail(
        editMode && file?.file
          ? file?.file?.original
          : URL.createObjectURL(file)
      );
    } else {
      // Assuming `audioThumbnailImg` is a valid import for your audio thumbnail image
      setThumbnail(audioThumbnailImg);
    }
  }, [file, editMode, isAudio, audioThumbnailImg]);

  return (
    <div className="flex flex-col items-end justify-start gap-1">
      <div>
        {/* Use index for deletion */}
        <button
          className="gap-1"
          type="button"
          onClick={() => handleDeleteFile(index)}
        >
          <TrashIcon />
        </button>
      </div>
      <div className="gap-1 p-1 border border-dashed rounded-lg bg-bgWhite dark:bg-[transparent]">
        <div className="relative w-full h-full">
          {/* Conditional rendering for audio and image */}
          <img
            src={thumbnail}
            alt=""
            className={`w-[138px] h-[80px] mx-auto ${isAudio ? "object-contain" : "object-cover"}`}
          />
          {/* File name display */}
          <div
            className="relative w-full mt-1"
            style={{ wordBreak: "break-word" }}
          >
            <p className="text-center break-words dark:text-caption">
              {ellipsize(file.title || file.name, 15)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
