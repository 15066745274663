import apiClient from "services/apiClient";

async function getCommunitiesMembers(
  search: string,
  organizationId: any,
  page?: number,
  appName?: string
) {
  const url = "/admin/organizations/communities-members";

  const params = {
    searchString: search,
    organizationId: organizationId,
    page: page ?? 1,
    appName: appName
  };

  const res = await apiClient.get(url, { params });

  return res.data;
}

export default getCommunitiesMembers;
