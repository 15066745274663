import React, { useEffect, useState } from "react";
import { Step } from "store/addImportStepper/initialState";
import { Toasts } from "view/components/Toasts";
import { moveToNextStepAction } from "store/addReportStepper/reducer.actions";
import { useDispatch } from "react-redux";
import FileUploadPlaceholderIcon from "assets/placeholders/FileUploadPlaceholderIcon";
import { TailSpin } from "react-loader-spinner";
import LoadingBar from "react-top-loading-bar";
import apiLibrary from "services/api";

interface ReportSettingsProps {
  activeStep: any;
  isLastStep: any;
  // steps: Step[];
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
}

export const ImportProgress: React.FC<ReportSettingsProps> = ({
  activeStep,
  isLastStep,

  moveToPreviousStep,
  moveToNextStep,
}) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  const importDataIntoSystem = async () => {
    const importId = Number(localStorage.getItem("importId"));
    let progress = 10;

    // Define the number of steps and maximum progress value
    const steps = 3;
    const maxProgress = 100;

    for (let step = 1; step <= steps; step++) {
      // Calculate the random progress increment for this step
      const increment = (maxProgress - progress) / (steps - step + 1);

      // Add the increment to the progress
      progress += increment;
      setProgress(Math.min(maxProgress, progress));

      // Delay for 1-2 seconds (adjust the delay as needed)
      await new Promise((resolve) =>
        setTimeout(resolve, Math.random() * 1000 + 1000)
      );
    }

    try {
      const { data, message } = await apiLibrary.Imports.importData(importId);
      setProgress(100);
      moveToNextStep();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      setProgress(0);
    }
  };

  useEffect(() => {
    importDataIntoSystem();

    return () => {
      setProgress(0);
    };
  }, []);

  return (
    <div className="py-4">
      <div className="relative w-full gap-4 bg-white rounded-lg">
        <div className="flex flex-col items-center justify-center">
          <FileUploadPlaceholderIcon width={180} height={180} />

          <h5 className="text-lg font-semibold font-Overpass text-textMid dark:text-textMain">
            Please wait! Your data are now being imported...
          </h5>
          <p className="text-sm text-textMidLight font-Overpass dark:text-caption ">
            Processing... Approximate remaining time about 10 min...
          </p>

          {progress > 0 && (
            <div className="my-4 spinner">
              <TailSpin
                height="40"
                width="40"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
                strokeWidth={3}
              />
            </div>
          )}

          <div className="flex items-center gap-2 loadingbar">
            <div className="relative w-[30vw] h-1 bg-secondary_25">
              <LoadingBar
                color="#005C89"
                progress={progress}
                height={3}
                containerClassName="!absolute"
                shadow={false}
                waitingTime={1000000}
              />
            </div>
            <p className="text-sm font-Overpass text-textMid dark:text-caption ">
              {progress}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
