import axios from "axios";
import { useDispatch } from "react-redux";
import { Toast } from "react-toastify/dist/components";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

export const DownloadReportProfile = async (id: any, versionId: any) => {
  try {
    const res = await apiLibrary.Reports.downloadReports(id, versionId);

    if (res?.message) {
      const { data, message } = await apiLibrary.Reports.getResultVersion(
        id,
        versionId,
        true
      );

      const response = await axios.get(data.path);
      // Convert the response to a Blob
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `reportId_${id}_versionId_${versionId}_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      // const res = await apiLibrary.Reports.downloadReports(id, versionId);
      Toasts.success("Download complete");
    }
  } catch (error: any) {
    Toasts.error(error.response.data.message);
    console.log(error, "error downloading the file");
  }
};
// export const DownloadReportProfile = async (id: any, versionId: any) => {
//   const { data, message } = await apiLibrary.Reports.getResultVersion(id, versionId, true);
//   try {
//     const response = await axios.get(data.path);
//     // Convert the response to a Blob
//     const blob = new Blob([response.data], {
//       type: "application/octet-stream",
//     });

//     // Create a link element
//     const link = document.createElement("a");

//     // Set the href attribute to a URL created from the Blob
//     link.href = window.URL.createObjectURL(blob);

//     // Set the download attribute to specify the file name
//     link.download = `reportId_${id}_versionId_${versionId}_${new Date().toISOString()}.csv`;

//     // Append the link to the body
//     document.body.appendChild(link);

//     // Programmatically click the link to trigger the download
//     link.click();

//     // Remove the link from the DOM
//     document.body.removeChild(link);

//     const res = await apiLibrary.Reports.downloadReports(id, versionId);
//     Toasts.success("Download complete");
//   } catch (error: any) {
//     Toasts.error(message);
//     console.log(error, "error downloading the file");
//   }
// };
