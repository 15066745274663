import { useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
import { DrawShapesDropdown, ToggleButton } from "../components";

const useInitializeCustomShapesControlsDropdown = (
  values: any,
  isMapFullyLoaded: boolean,
  map: any,
  selectedDrawShape: any,
  setSelectedDrawShape: any
) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if (map && isMapFullyLoaded && values.regionFilter.length > 0) {
      ReactDOM.render(
        <>
          <DrawShapesDropdown
            isVisible={isDropdownVisible}
            toggleDropdownListener={toggleDropdownListener}
            selectedDrawShape={selectedDrawShape}
            setSelectedDrawShape={setSelectedDrawShape}
          />
          <ToggleButton handleClick={toggleDropdownListener} />
        </>,
        document.getElementById("customDrawShapesControlContainer")
      );
    }

    return () => {
      const container = document.getElementById(
        "customDrawShapesControlContainer"
      );
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
    };
  }, [map, isMapFullyLoaded, isDropdownVisible, values]);

  const toggleDropdownListener = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return null;
};

export { useInitializeCustomShapesControlsDropdown };
