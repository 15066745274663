import { Dispatch } from "redux";
import {
  ERROR_LOADING_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS,
  STOP_LOADING_NOTIFICATIONS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchNotificationCenterAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_NOTIFICATIONS });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.notificationCenterFilter
      );

      const { data } =
        await apiLibrary.NotificationCenter.getAllNotifications(queryString);
      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the notifications:", error);
      dispatch({ type: ERROR_LOADING_NOTIFICATIONS });
    } finally {
      dispatch({ type: STOP_LOADING_NOTIFICATIONS });
    }
  };

// export const notificationData = {
//   total_pages: 10671,
//   notifications: [
//     {
//       id: 190510,
//       description:
//         "Great news! The <a href='https://dev-admin.isn.landscape.network/forms/list?formId=764' style='text-decoration: none;'>Alaska Denali Explorer Form</a> has been successfully published in the <a href='https://dev-admin.isn.landscape.network/programs/671/profile' style='text-decoration: none;'>Alaska Denali Explorer Programs</a> program.<br> Visit the  Form directory to learn more. ",
//       title: "Form published",
//       entity_id: 764,
//       person_id: 1,
//       entity: "dynamic_forms",
//       profile_image:
//         "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/299/thumb/1712303740203_1713442418567985.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240418%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240418T123635Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=fa7af47cf81eb0f2b73a1be570e816e80ee1b15660f0f4c33d2aab881d4c1021",
//       other_entity: {
//         programs: 671,
//       },
//       is_read: false,
//       created_at: "2024-04-18T12:28:40Z",
//       redirect_link: "/admin/dynamic_forms/764",
//     },
//     {
//       id: 190510,
//       description:
//         "Great news! The <a href='https://dev-admin.isn.landscape.network/forms/list?formId=764' style='text-decoration: none;'>Alaska Denali Explorer Form</a> has been successfully published in the <a href='https://dev-admin.isn.landscape.network/programs/671/profile' style='text-decoration: none;'>Alaska Denali Explorer Programs</a> program.<br> Visit the  Form directory to learn more. ",
//       title: "Form published",
//       entity_id: 764,
//       person_id: 1,
//       entity: "dynamic_forms",
//       profile_image:
//         "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/299/thumb/1712303740203_1713442418567985.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240418%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240418T123635Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=fa7af47cf81eb0f2b73a1be570e816e80ee1b15660f0f4c33d2aab881d4c1021",
//       other_entity: {
//         programs: 671,
//       },
//       is_read: false,
//       created_at: "2024-04-18T12:28:40Z",
//       redirect_link: "/admin/dynamic_forms/764",
//     },
//     {
//       id: 190510,
//       description:
//         "Great news! The <a href='https://dev-admin.isn.landscape.network/forms/list?formId=764' style='text-decoration: none;'>Alaska Denali Explorer Form</a> has been successfully published in the <a href='https://dev-admin.isn.landscape.network/programs/671/profile' style='text-decoration: none;'>Alaska Denali Explorer Programs</a> program.<br> Visit the  Form directory to learn more. ",
//       title: "Form published",
//       entity_id: 764,
//       person_id: 1,
//       entity: "dynamic_forms",
//       profile_image:
//         "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/299/thumb/1712303740203_1713442418567985.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240418%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240418T123635Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=fa7af47cf81eb0f2b73a1be570e816e80ee1b15660f0f4c33d2aab881d4c1021",
//       other_entity: {
//         programs: 671,
//       },
//       is_read: false,
//       created_at: "2024-04-18T12:28:40Z",
//       redirect_link: "/admin/dynamic_forms/764",
//     },
//   ],
//   unread_count: 0,
// };
