function parseArray(originalArray: any[], removeToBeValues: string[]) {
  if (originalArray[0]?.column?.id) {
    return originalArray.filter(
      (v: any) => !removeToBeValues.includes(v.column.id)
    );
  }
  return originalArray;
}

export default parseArray;



export function columnsModalParsedArray(originalArray: any[], removeToBeValues: string[]) {
  if (originalArray[0]?.id) {
    return originalArray.filter(
      (v: any) => !removeToBeValues.includes(v.id)
    );
  }
  return originalArray;
}

