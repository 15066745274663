import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IfetchContactPersons } from 'store/messages/initialState';
import { filtersInitialState } from 'store/filters/initialState';
import RecipientsList from '../RecipientsList';
import ContactsList from '../ContactsList';
interface MessagesContactsProps {
  selectedContact: any | null;
  setSelectedContact: (data: any) => void;
  handleSelectedOption: (value: any) => void;
  handleSelectedContact: (data: any) => void;
}

const MessagesContacts: React.FC<MessagesContactsProps> = ({
  selectedContact,
  setSelectedContact,
  handleSelectedOption,
  handleSelectedContact,
}) => {
  const contacts = useSelector<RootState, IfetchContactPersons>(
    (state) => state?.allContactPersons
  );

  const { messageNewChatFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // Creating Contact person list
  const contactPersonList = useMemo(() => {
    return contacts?.data?.contactPersons?.map((contact) => ({
      value: String(contact.id),
      label: contact.firstName + ' ' + contact.lastName,
      personImg: contact.personImage,
    }));
  }, [contacts?.data?.contactPersons]);
  return (
    <div className='flex flex-col w-full px-2 py-6 bg-bgWhite dark:bg-bgtetriary relative'>
      {messageNewChatFilter?.newChat ? (
        <ContactsList
          selectedContact={selectedContact}
          contactPersonList={contactPersonList}
          handleSelectedContact={handleSelectedContact}
          handleSelectedOption={handleSelectedOption}
        />
      ) : (
        <RecipientsList
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          contactPersonList={contactPersonList}
          handleSelectedContact={handleSelectedContact}
          handleSelectedOption={handleSelectedOption}
        />
      )}
    </div>
  );
};

export default MessagesContacts;
