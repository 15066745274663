import { OptionsModalProps } from "../../types";
import { CreateBtn } from "../index";
import { SearchToAddExistingBtn } from "../index";

const OptionsModal = ({
  handleClickOnCreateNewBtn,
  handleClickOnAddExistingBtn,
  isOpen,
}: OptionsModalProps) => {
  if (isOpen) {
    return (
      <div
        className="z-10 absolute flex flex-col items-start justify-start mt-1 bg-white rounded-lg top-12 right-3 z-1 bg-textWhite"
        style={{
          boxShadow:
            "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
        }}
      >
        <div className="h-[85px] flex flex-col  justify-start items-center px-1.5 py-2">
          <CreateBtn handleClickOnCreateNewBtn={handleClickOnCreateNewBtn} />
          <SearchToAddExistingBtn
            handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
          />
        </div>
      </div>
    );
  }
  return <></>;
};

export { OptionsModal };
