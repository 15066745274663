import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { Breadcrumb } from "../BreadCrumb";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import Head from "view/components/Head";
import { useDispatch } from "react-redux";
import { getCommunityCenterpointAction } from "store/geography";
interface BreadcrumbLink {
  path: string;
  label: string;
}

const generateDynamicBreadcrumbLinks = (
  communityId: string | number,
  communityName: string
): BreadcrumbLink[] => {
  if (communityId) {
    return [
      { path: `/community/list`, label: `Communities` },
      {
        path: `/community/${communityId}/profile`,
        label: communityName,
      },
      {
        path: `/community/${communityId}/geography`,
        label: "Geography",
      },
    ];
  } else {
    return [{ path: `/geography`, label: "Geography" }];
  }
};

const HeaderBar = () => {
  const navigate = useNavigate();
  const { communityId, specieId } = useParams();
  const dispatch = useDispatch();
  const [breadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  // useEffect(() => {
  //   if (communityId) {
  //     getCommunityDetails(communityId)
  //       .then(setBreadcrumbData)
  //       .catch((error) => {
  //         const errMsg = error?.response?.data?.message ?? error.message;
  //         Toasts.error(errMsg);
  //         navigate("/organizations/list");
  //       });
  //   }
  // }, [communityId, specieId, navigate]);

  useEffect(() => {
    const fetchCommunityDetails = async () => {
      if (communityId) {
        try {
          const details = await getCommunityDetails(communityId);
          setBreadcrumbData(details);
          if (details?.location?.coordinates) {
            dispatch(
              getCommunityCenterpointAction(details?.location?.coordinates)
            );
          } else {
            dispatch(getCommunityCenterpointAction(null));
          }
        } catch (error: any) {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        }
      }
    };

    fetchCommunityDetails();
  }, [communityId, specieId, navigate]);

  const dynamicCommunityLinks = useMemo(
    () =>
      generateDynamicBreadcrumbLinks(
        communityId ?? "",
        breadcrumbData?.name ?? ""
      ),
    [communityId, specieId, breadcrumbData]
  );
  return (
    <div className="w-full py-3 ">
      <Head title="Geography" />
      <DirectoryBreadCrumb links={dynamicCommunityLinks} />
    </div>
  );
};

export default HeaderBar;

async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
