import { ElementFactory, Question, SvgRegistry } from "survey-core";

import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";


export default class QuestionGeoCoderModel extends Question {
  getType() {
    return questionTypes.geocoder;
  }

  //   get locationCoordinates() {
  //     return this.getPropertyValue("locationCoordinates");
  //   }
  //   set locationCoordinates(val) {
  //     this.setPropertyValue("locationCoordinates", val);
  //   }
}