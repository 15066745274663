import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";

interface ChangeContactPreferencesProps {
  values: {
    contactPreference: string;
  };
  setFieldValue: (field: string, value: any) => void;
}

export const ChangeContactPreferences: React.FC<
  ChangeContactPreferencesProps
> = ({ values, setFieldValue }) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  return (
    <div className="w-full">
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={values.contactPreference}
        onChange={(e: any) =>
          setFieldValue("contactPreference", e.target.value)
        }
      >
        <div className="grid w-full gap-2 xl:grid-cols-2 lg:grid-cols-1">
          {userProfile?.user?.email !== null && (
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                  <RadioBtn
                    value="email"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-secondaryMidLight dark:text-textMain dark:text-textMain">
                              Email
                            </p>
                            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                              <p className="flex-grow text-[15px] text-left text-secondary dark:text-inputText">
                                {userProfile?.user?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {userProfile?.user?.phoneNumber !== null && (
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                  <RadioBtn
                    value="phone_number"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-secondaryMidLight dark:text-textMain dark:text-textMain">
                              Phone
                            </p>
                            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                              <p className="flex-grow  text-[15px] text-left text-secondary dark:text-inputText">
                                {userProfile?.user?.phoneNumber}
                              </p>
                            </div>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </RadioGroup>
    </div>
  );
};
