export interface ICommunityRegion {
  color: null | string;
  communityId: number;
  id: number;
  mapData: any;
  name: string;
  shapeType: null | string;
  type: string;
}

export interface ICommunityLocation {
  coordinates: [number, number];
  crs: {
    properties: {
      name: string;
    },
    type: string;
  },
  type: string;
}

export interface IGeographyData {
  communityRegions: ICommunityRegion[];
  communityLocation: ICommunityLocation;
}

export interface IFetchCommunityGeographyData {
  data: IGeographyData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

export const initialState: IFetchCommunityGeographyData = {
  data: {
    communityRegions: [],
    communityLocation: {
      coordinates: [0, 0],
      crs: {
        properties: {
          name: ""
        },
        type: ""
      },
      type: ""
    },
  },
  message: "",
  stateIs: "Idle",
};

