import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;

}

export const AdminIcon = ({ fill, isActive, ...restProps }: IconProps) => (
    <svg width="17" height="16" viewBox="0 0 17 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
        {...restProps}>
        <path
            fill={fill}
            className={`dark:${isActive
                ? "fill-primary"
                : "fill-caption group-hover:dark:fill-primary"
                }`}
            fill-rule="evenodd" clip-rule="evenodd" d="M7.00065 1.66536C4.05513 1.66536 1.66732 4.05318 1.66732 6.9987C1.66732 8.11848 2.01242 9.15767 2.60207 10.0157C3.93006 9.36429 5.42345 8.9987 7.00065 8.9987C8.57786 8.9987 10.0712 9.36429 11.3992 10.0157C11.9889 9.15766 12.334 8.11848 12.334 6.9987C12.334 4.05318 9.94617 1.66536 7.00065 1.66536ZM12.1035 11.2891C13.0794 10.1296 13.6673 8.63282 13.6673 6.9987C13.6673 3.3168 10.6826 0.332031 7.00065 0.332031C3.31875 0.332031 0.333984 3.3168 0.333984 6.9987C0.333984 8.63283 0.921934 10.1296 1.89783 11.2891C1.90311 11.2956 1.90849 11.302 1.91397 11.3082C3.13685 12.7502 4.96187 13.6654 7.00065 13.6654C9.03942 13.6654 10.8644 12.7502 12.0873 11.3082C12.0928 11.302 12.0982 11.2956 12.1035 11.2891ZM10.4664 11.0526C9.40559 10.5892 8.23379 10.332 7.00065 10.332C5.76751 10.332 4.59571 10.5892 3.5349 11.0526C4.46705 11.8503 5.67759 12.332 7.00065 12.332C8.32371 12.332 9.53425 11.8503 10.4664 11.0526ZM7.00065 4.33203C6.26427 4.33203 5.66732 4.92899 5.66732 5.66536C5.66732 6.40174 6.26427 6.9987 7.00065 6.9987C7.73703 6.9987 8.33398 6.40174 8.33398 5.66536C8.33398 4.92899 7.73703 4.33203 7.00065 4.33203ZM4.33398 5.66536C4.33398 4.19261 5.52789 2.9987 7.00065 2.9987C8.47341 2.9987 9.66732 4.19261 9.66732 5.66536C9.66732 7.13812 8.47341 8.33203 7.00065 8.33203C5.52789 8.33203 4.33398 7.13812 4.33398 5.66536Z"
        />
    </svg>
);
export default AdminIcon;