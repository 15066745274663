import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useNavigate, useParams } from "react-router-dom";
import "../../Observations.scss"

export const TableBody = ({
  table,
  columnVisibility,
  getManagersActions,
  flexRender,
}: TableBodyProps) => {
  const navigate = useNavigate();
const navigateToProfile = (row: any) => {
    // navigate(`/profile/${row.personId}/person`);
}
  const { programId } = useParams();
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className="Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
            style={{ height: "66px" }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  navigateToProfile={() =>
                    navigateToProfile(row?.original)
                  }
                  personId={row?.original?.personId}
                  programId={Number(programId)}
                />
              )
            )}
            <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight ">
              <RowsActions
                row={row}
                getManagersActions={getManagersActions}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}
