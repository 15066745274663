import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

import { getUserProfileAction } from "store/userProfile";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Header } from "Components/Profile/components";
import AddressContainer from "./AddressContainer";
import ellipsize from "ellipsize";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";
interface AddressesProps {
  addresses: any;
}

const Addresses: React.FC<AddressesProps> = ({ addresses }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { users } = usePermissions();

  // const validationSchema = Yup.object().shape({
  //   addresses: Yup.array().of(
  //     Yup.object().shape({
  //       address: Yup.string().required("Address is required"),
  //     })
  //   ),
  // });
  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any> // Use FormikHelpers
  ) => {
    if (!users.canEditPersonProfile && editMode) {
      return;
    }
    try {
      dispatch(setLoadingProgress(getRandomProgress()));
      const { data, message } = await apiLibrary.userProfile.updateUserProfile(
        userProfile.personId,
        {
          addresses: values.addresses.filter(
            (address: any) => address.address !== ""
          ),
        }
      );
      Toasts.success(message);

      dispatch(getUserProfileAction(data));
      setEditMode(false);

      // Reset the form after successful submission
      resetForm(); // This will clear the form's values and errors
    } catch (error: any) {
      // Handle the error appropriately
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const handleEditPerson = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="dark:bg-secondaryLight flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full overflow-hidden bg-white ">
      <Formik
        initialValues={{ addresses: addresses }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <Form className="w-full" onSubmit={handleSubmit}>
            <Header
              title="Address Info"
              editMode={editMode}
              handleEdit={handleEditPerson}
              resetForm={resetForm}
              isSubmitting={isSubmitting}
            />
            <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0 w-full pb-10 pl-8 pr-8 xl:pl-12">
              <motion.div
                animate={{
                  translateY: editMode ? 30 : 0,
                }}
                className="w-full"
              >
                {editMode ? (
                  <motion.div className="w-full pt-4">
                    <div className="flex flex-col items-start justify-start flex-grow w-full">
                      <AddressContainer
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                  </motion.div>
                ) : (
                  <div className="flex flex-col w-full gap-2 pt-4 pb-12">
                    <div className="flex flex-col w-full">
                      <div className="flex w-full gap-4">
                        <div className="flex w-full rounded-lg">
                          <div className="flex flex-col relative gap-0.5 py-2 w-full">
                            <p className="w-full text-sm text-left capitalize text-textMidLight dark:text-textMain">
                              Primary Address
                            </p>
                            <div className="relative flex items-center justify-start w-full">
                              <p className="w-full text-sm text-left break-all text-secondary dark:text-textMain">
                                {addresses && addresses.length > 0
                                  ? addresses.find(
                                      (item: any) => item.isPrimary
                                    )?.address || ""
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Addresses;
