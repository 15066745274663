import { apiClient } from "../config";

async function getTotalCounts(queryParams: any) {
  const res = await apiClient.get(`/admin/dashboard/widget/kpis`, {
    params: queryParams,
  });
  return res.data;
}

export default getTotalCounts;
