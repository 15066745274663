// Define an interface for the category data
export interface ICategory {
  categoryName: string;
  categoryCode: string;
  id: number;
}

export interface ICategoriesData {
  categories: ICategory[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchCategories {
  data: ICategoriesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchCategories = {
  data: {
    categories: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
