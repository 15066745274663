import { Formik } from "formik";
import * as Yup from "yup";

// Components
import Button from "view/components/Button";
import { Toasts } from "view/components/Toasts";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiLibrary from "services/api";

import { useDispatch } from "react-redux";
import RectangleIcon from "assets/icons/MapIcons/RectangleIcon";
import CircleIcon from "assets/icons/MapIcons/CircleIcon";
import PolygonIcon from "assets/icons/MapIcons/PolygonIcon";
import { shapeTypes } from "store/geography/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";
import usePermissions from "hooks/usePermissions";
import {
  setDoResetMap,
  setDrawShapeMode,
  setIsShapeDrawingCompleted,
  updateDoRemoveAllDrawnShapesSignal,
} from "store/surveyJS";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import structureFormJSON from "../../../../SurveyJs/utils/structureFormJSON";
import { dynamicFormsStatusAction } from "store/filters";
import { getSelectedShapesAndDefaultLayers } from "..";

const initialValues = {
  description: "",
  mapData: {},
  type: "region",
  vectorLayers: [],
  name: "",
  color: "#FF0000",
};
const validationSchema = Yup.object().shape({
  mapData: Yup.object(),
});

interface DrawRegionInterface {
  height: string;
  dataSourcesData: any;
}

export const DrawRegion = ({
  dataSourcesData,
  height,
}: DrawRegionInterface) => {
  const [doActiveShape, SetDoActiveShape] = useState();
  const [selectedShapeType, setSelectedShapeType] = useState("");

  const surveyState = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );
  const { formDetails } = useSelector(
    (state: RootState) => state.formSurveyJsDetails
  );

  const {
    shapePickers,
    pointPickers,
    drawPolygons,
    dropPins,
    getGpsDatas,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
    formJson,
    geography,
  } = surveyState;

  const { drawShapeGeometry, drawShapeMode,isShapeDrawingCompleted } = geography;

  const { communities, dynamicForms } = usePermissions();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const shapes = [
    {
      name: shapeTypes.POLYGON,
      Icon: <PolygonIcon />,
    },
    {
      name: shapeTypes.CIRCLE,
      Icon: <CircleIcon />,
    },
    {
      name: shapeTypes.RECTANGLE,
      Icon: <RectangleIcon />,
    },
  ];

  useEffect(() => {
    // reset selected layers
    dispatch(setDoResetMap(true));
  }, []);
  useEffect(()=>{
    if(isShapeDrawingCompleted && selectedShapeType){
      dispatch(setDrawShapeMode(selectedShapeType as any));
      dispatch(setIsShapeDrawingCompleted(false));
    }

  },[isShapeDrawingCompleted])


  // Handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    try {
      if (!drawShapeGeometry) {
        setFieldError("mapData", "Shape is required");
        return;
      }

      const mapData = {
        type: "FeatureCollection",
        features: drawShapeGeometry,
      };

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const shapeType = !!selectedShapeType
        ? selectedShapeType
        : formDetails.formAreaShapeType;

      const payload: any = {
        form_area: mapData,
        form_area_shape_type: shapeType,
        form_area_mode: "draw",
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData,
        ),
        status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };
  const handleClickOnShape = (name: any) => {
    // dispatch(
    //   updateDoRemoveAllDrawnShapesSignal({
    //     remove: true,
    //     variant: "local_draw",
    //   })
    // );

    setSelectedShapeType(name);
    dispatch(setDrawShapeMode(name));
  };
  const handleSubmitFormWithoutFormArea = async (
    setSubmitting: Dispatch<SetStateAction<boolean>> | any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    setSubmitting(true);
    try {
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        formJson,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({ errors, handleSubmit, isSubmitting, setSubmitting }) => (
        <div>
          <form onSubmit={handleSubmit} className="">
            <div
              className="pb-12"
              style={{
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
          

              <p className="text-[15px] text-textMidLight italic mb-2 dark:text-textMain ">
              Draw one or multiple polygons to specify the area in which this
              form should be available to the observers when using the app
              offline. Double click to finish one polygon, then click anywhere
              on the map to start your next polygon.
            </p>
              <div className="overflow-y-auto">
                <h1 className="mb-2 dark:text-textMain">Draw On The Map</h1>
                <div className="flex justify-between w-full mt-1">
                  {shapes.map((el, index) => {
                    return (
                      <button
                        type="button"
                        key={index.toString()}
                        className={`border border-lineMid dark:border-lineLight w-[32%] rounded-lg p-2 flex flex-col justify-center items-center capitalize ${
                          selectedShapeType === el.name ? "bg-[#005C8924]" : " "
                        }`}
                        onClick={() => handleClickOnShape(el.name)}
                      >
                        {/* <p>{el.Icon}</p> */}
                        <p
                          className={`mt-2 text-sm font-extralight ${
                            selectedShapeType === el.name
                              ? "text-primaryDark font-medium dark:text-textMain"
                              : "dark:text-textMain"
                          }`}
                          onClick={() => handleClickOnShape(el.name)}
                        >
                          {el.Icon}
                        </p>
                        <p
                          className={`mt-2 text-sm font-extralight ${
                            doActiveShape === el.name
                              ? "text-primaryDark font-medium dark:text-textMain"
                              : "dark:text-textMain"
                          }`}
                        >
                          {el.name}
                        </p>
                      </button>
                    );
                  })}
                </div>
                {errors.mapData && (
                  <p className="flex-grow pt-1 text-xs text-left text-accent_1Dark">
                    Shape is required
                  </p>
                )}
              </div>

              <div className="flex justify-between gap-2 mt-2 absolute bottom-[-15px] w-full p-4 bg-bgWhite">
                <Button
                  disabled={
                    isSubmitting ||
                    formJson.status === "published" ||
                    !dynamicForms.canEditDynamicFormFields
                  }
                  type="button"
                  text="Skip"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.t%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={() => handleSubmitFormWithoutFormArea(setSubmitting)}
                />
                <Button
                  type="submit"
                  text="Save"
                  disabled={
                    isSubmitting || !dynamicForms.canEditDynamicFormFields
                  }
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.5%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};
