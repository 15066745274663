import apiClient from "services/apiClient";

interface Import {
    comment: string;
    dataReceivedFrom: string;
    dynamicFormId: number | null;
    fileId: number;
    fileName: string;
    programId: number | null;
}

async function importUpload(data: Import) {
    const url = `/admin/imports`;
    const res = await apiClient.post(url, data);
    return res.data;
}

export default importUpload;
