import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { AddMarkerStylesBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { openAddOrEditMarkerStylesModalAction } from "store/modals";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = () => {
  return [{ path: `/marker-styles`, label: `Marker Styles` }];
};
export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  const { markerStylesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // const { markerStyles } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks();

  // displaying models based on communityId
  const handleMarkerStylesModel = () => {
    dispatch(openAddOrEditMarkerStylesModalAction());
  };
  const { markerStyles } = usePermissions()

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              communityId={"null"}
              name={BreadcrumbData?.name}
              parentName={""}
            />

            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {markerStyles.canCreate && <AddMarkerStylesBtn
                handleAddMarkerStylesModel={handleMarkerStylesModel}
                label={"Add Marker Style"}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
