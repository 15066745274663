import React, { useState } from "react";

interface IProps {
    auth: any;
}
export const ExternalReviewerHeader: React.FC<IProps> = ({
    auth
}) => {
  return (
    <>
      <div className=" bg-bgWhite w-full py-5">
        <div className="ml-[72px]">
        <p className="text-sm text-textMidLight capitalize">Quality assurance</p>
        <p className="text-base capitalize">Quality assurance: {auth.person?.firstName} {auth.person?.lastName} </p>
        </div>
      </div>
    </>
  );
};
