import { SurveyQuestionElementBase } from "survey-react-ui";
import mapboxgl from "mapbox-gl";

import Map from "./Map";

export const drawPolygonProperties = [{
  name: "_id",
  category: "general",
  visibleIndex: 2,
}];

export default class SurveyDrawPolygonQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderInput() {
    return <div>ITS a draw polygon componenet and render will be soon!</div>;
  }

  renderElement() {
    return <Map questionId={this.question._id} />;
  }
}
