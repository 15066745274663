import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Button from "view/components/Button";
import XCloseSmallIcon from "assets/icons/HeroIcons/XCloseSmallIcon";
import apiLibrary from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  closeAddExistingCommunityToOrganizationModalAction,
  openCreateCommunityModalAction,
} from "store/modals/reducer.actions";
import TextInput from "view/components/InputField";
import { Toasts } from "view/components/Toasts";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchCommunitiesAction } from "store/communities";
import Placeholderavatar from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { setLoadingProgress } from "store/loadingBar";
import usePermissions from "hooks/usePermissions";
// Define the interface for SearchToAddExistingOrganizationProps (if required)
interface SearchToAddExistingCommunitiesToOrganizationProps {}

// Styles for the Modal component
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

// SearchToAddExistingCommunitiesToOrganization component
export const SearchToAddExistingCommunitiesToOrganization: React.FC<
  SearchToAddExistingCommunitiesToOrganizationProps
> = () => {
  const navigate = useNavigate();

  // Redux hooks
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { addExistingCommunityModal } = useSelector(
    (state: RootState) => state.modals
  );

  // State hooks
  const [communitiesList, setCommunitiesList] = useState<any[]>([]);
  const [connectedCommunitiesList, setConnectedCommunitiesList] = useState<
    any[]
  >([]);
  const [isCommunityRemoved, setIsCommunityRemoved] = useState<boolean>(false);
  const [searchString, setSearchString] = useState("");

  const [totalCommunitites, setTotalCommunitites] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();
  const { organizations, communities } = usePermissions();
  const addExistingCommunnities = async () => {
    if (isCommunityRemoved) {
      handleClose();
      return;
    }
    setIsLoading(true);
    try {
      if (addExistingCommunityModal?.data?.organizationId) {
        const response =
          await apiLibrary.OrganizationDirectory.updateOrganization(
            addExistingCommunityModal?.data?.organizationId,
            {
              communityIds: connectedCommunitiesList.map((org: any) => {
                return org.id;
              }),
            }
          );
        Toasts.success(response.message);
      } else if (addExistingCommunityModal?.data?.programId) {
        const response = await apiLibrary.Programs.connectCommunitiesToProgram(
          addExistingCommunityModal?.data?.programId,
          {
            communityIds: connectedCommunitiesList.map((org: any) => {
              return org.id;
            }),
          }
        );
        Toasts.success(response.message);
      }

      handleClose();
      dispatch(fetchCommunitiesAction(organizationId, programId));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCommunitiesByOrganization = async (
    isLoadMore: boolean,
    organizationId: number,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedCommunities?: any[] | null
  ) => {
    setIsLoading(true);

    try {
      const { data } =
        await apiLibrary.OrganizationDirectory.getCommunitiesByOrganization(
          organizationId,
          isConnected,
          page,
          searchString
        );
      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.communities,
        isConnected
      );
      if (isConnected) {
        if (connectedCommunities && connectedCommunities?.length > 0) {
          const EntitiesList = getSortedDifference(
            reStructuredEntitiesList,
            connectedCommunities
          );
          setConnectedCommunitiesList(EntitiesList);
        } else {
          setConnectedCommunitiesList(reStructuredEntitiesList);
        }
      } else {
        if (!isLoadMore) {
          setCommunitiesList(reStructuredEntitiesList);
        } else {
          if (connectedCommunities && connectedCommunities?.length > 0) {
            const communitiesList = getSortedDifference(
              reStructuredEntitiesList,
              connectedCommunities
            );

            setCommunitiesList((prevList) => [...prevList, ...communitiesList]);
          } else {
            setCommunitiesList((prevList) => [
              ...prevList,
              ...reStructuredEntitiesList,
            ]);
          }
        }

        setTotalPages(data.totalPages);
        setHasMoreData(data.totalPages > page);
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConnectedAndExistingCommunitiesByOrganization = async () => {
    await getCommunitiesByOrganization(
      false,
      parseInt(addExistingCommunityModal?.data?.organizationId),
      false,
      page,
      searchString
    );
    await getCommunitiesByOrganization(
      false,
      parseInt(addExistingCommunityModal?.data?.organizationId),
      true,
      page,
      searchString
    );
  };
  useEffect(() => {
    if (addExistingCommunityModal.isOpen) {
      setCommunitiesList([]);
      setPage(1);
      fetchConnectedAndExistingCommunitiesByOrganization();
    }
  }, [
    addExistingCommunityModal?.data?.organizationId,
    addExistingCommunityModal.isOpen,
  ]);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getCommunitiesByOrganization(
        true,
        parseInt(addExistingCommunityModal?.data?.organizationId),
        false,
        page,
        searchString,
        connectedCommunitiesList
      );
      return page;
    });
  };

  const searchCommunitiesByOrganization = async (
    organizationId: any,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedCommunitites: any[]
  ) => {
    setIsLoading(true);
    try {
      const { data } =
        await apiLibrary.OrganizationDirectory.getCommunitiesByOrganization(
          organizationId,
          isConnected,
          page,
          searchString
        );

      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.communities,
        isConnected
      );

      const searchedCommunitiesList = getSortedDifference(
        reStructuredEntitiesList,
        connectedCommunitites
      );

      setCommunitiesList(searchedCommunitiesList);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchCommunitiesByOrganization = async (
    searchString: string
  ) => {
    setPage(1);
    await searchCommunitiesByOrganization(
      parseInt(addExistingCommunityModal?.data?.organizationId),
      false,
      1,
      searchString,
      connectedCommunitiesList
    );
  };

  const debouncedSearch = _.debounce(
    handleSearchCommunitiesByOrganization,
    1000
  );

  const removeCommunityFromOrganization = async (community: any) => {
    if (!organizations.canDeleteEntity) {
      return;
    }
    dispatch(setLoadingProgress(100));
    setIsLoading(true);
    try {
      const res =
        await apiLibrary.OrganizationDirectory.removeEntityFromOrganization(
          parseInt(addExistingCommunityModal?.data?.organizationId),
          "community",
          parseInt(community.id)
        );
      setIsCommunityRemoved(true);
      Toasts.success(res.message);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const addExistingCommunity = (community: any): void => {
    setCommunitiesList((prevCommunitiesList) => {
      const filteredCommunities = prevCommunitiesList.filter(
        (item) => item.id !== community.id
      );

      if (filteredCommunities.length === 0) {
        callApiIfCommunitiesLengthIsZero();
      }
      return filteredCommunities;
    });

    setConnectedCommunitiesList((prevconnectedCommunitiesList) => {
      const isCommunityAlreadyConnected = prevconnectedCommunitiesList.some(
        (item) => item.id === community.id
      );

      if (!isCommunityAlreadyConnected) {
        const updatedList = [...prevconnectedCommunitiesList, community];
        return updatedList;
      }

      return prevconnectedCommunitiesList;
    });
  };

  const removeExistingMember = async (community: any) => {
    if (community.isConnected) {
      await removeCommunityFromOrganization(community);
      await getCommunitiesByOrganization(
        false,
        parseInt(addExistingCommunityModal?.data?.organizationId),
        false,
        1,
        searchString
      );
      setConnectedCommunitiesList((prevconnectedCommunitiesList) =>
        prevconnectedCommunitiesList.filter((item) => item.id !== community.id)
      );
    }

    setCommunitiesList((prevCommunitiesList) => {
      const isCommunityPresent = prevCommunitiesList.some(
        (item) => item.id === community.id
      );

      if (!isCommunityPresent) {
        const updatedCommunitiesList = [...prevCommunitiesList, community];
        return updatedCommunitiesList;
      } else {
        return prevCommunitiesList;
      }
    });

    setConnectedCommunitiesList((prevconnectedCommunitiesList) => {
      const updatedConnectedCommunitiesList =
        prevconnectedCommunitiesList.filter((item) => item.id !== community.id);
      return updatedConnectedCommunitiesList;
    });
  };

  const removeExistingCommunitites = async () => {
    setIsLoading(true);
    try {
      const response =
        await apiLibrary.OrganizationDirectory.updateOrganization(
          addExistingCommunityModal?.data?.organizationId,
          {
            communityIds: connectedCommunitiesList
              .filter((item: any) => !item.isConnected)
              .map((community: any) => {
                return community.id;
              }),
          }
        );
      setIsCommunityRemoved(true);
      Toasts.success("All Communitites Have Been Removed");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAllCommunitites = (): void => {
    const isAllCommunititesConnected = connectedCommunitiesList.every(
      (community: any) => community.isConnected
    );

    if (isAllCommunititesConnected) {
      removeAllConnectedCommunitites();
    } else if (searchString !== "") {
      setCommunitiesList((prevCommunitiesList) => {
        const updatedCommunitiesList = [
          ...prevCommunitiesList,
          ...connectedCommunitiesList.filter(
            (connectedMember) =>
              !connectedMember.isConnected &&
              connectedMember.name
                .toLowerCase()
                .includes(searchString.toLowerCase())
          ),
        ];
        return updatedCommunitiesList;
      });

      setConnectedCommunitiesList((pre: any[]) => {
        const updatedConnectedCommunitiesList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedCommunitiesList;
      });
    } else {
      setCommunitiesList((prevCommunitiesList) => {
        const filteredConnectedCommunitiesList =
          connectedCommunitiesList.filter(
            (connectedMember) => !connectedMember.isConnected
          );

        const updatedCommunitiesList = [
          ...prevCommunitiesList,
          ...filteredConnectedCommunitiesList,
        ];

        return updatedCommunitiesList;
      });

      setConnectedCommunitiesList((pre: any[]) => {
        const updatedConnectedCommunitiesList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedCommunitiesList;
      });
    }
  };

  const removeAllConnectedCommunitites = async () => {
    await removeExistingCommunitites();
    await fetchConnectedAndExistingCommunitiesByOrganization();
  };

  const addAllCommunitites = (): void => {
    setCommunitiesList(() => {
      return [];
    });
    setConnectedCommunitiesList((prevCommunitiesList) => {
      const updatedCommunitiesList = [
        ...prevCommunitiesList,
        ...communitiesList.filter(
          (newMember) =>
            !prevCommunitiesList.some(
              (prevMember) => prevMember.id === newMember.id
            )
        ),
      ];
      return updatedCommunitiesList;
    });
  };

  // Function to handle modal close
  const handleClose = () => {
    if (isCommunityRemoved) {
      dispatch(fetchCommunitiesAction(organizationId, programId));
    }
    setCommunitiesList([]);
    setConnectedCommunitiesList([]);
    setTotalCommunitites(0);
    setTotalPages(1);
    setSearchString("");
    dispatch(closeAddExistingCommunityToOrganizationModalAction());
  };

  const redirectToTheDirectory = () => {
    if (communities.canViewSideBarCommunities) {
      navigate("/community/list");
    }
    handleClose();
    if (communities.canCreateCommunities) {
      setTimeout(() => {
        dispatch(openCreateCommunityModalAction());
      }, 1000);
    }
  };
  const callApiIfCommunitiesLengthIsZero = () => {
    if (hasMoreData) {
      handleLoadMore();
    }
  };

  return (
    <Modal
      open={addExistingCommunityModal.isOpen}
      onClose={() => {
        handleClose();

        if (isCommunityRemoved) {
          dispatch(fetchCommunitiesAction(organizationId, programId));
        }
      }}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[850px] rounded-lg">
          <div
            className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg bg-bgWhite dark:bg-secondaryLight"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                  Search To Add Communitites to the Organization
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  if (isCommunityRemoved) {
                    dispatch(fetchCommunitiesAction(organizationId, programId));
                  }
                }}
              >
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <TextInput
              onChange={(e: any) => {
                setSearchString(e.target.value);
                debouncedSearch(e.target.value);
              }}
              value={searchString}
              type="Search"
              placeholder="Search among existing"
            />
            <div className="max-h-[60vh] overflow-y-hidden w-full px-1">
              <div className="flex self-stretch justify-between flex-grow-0 flex-shrink-0 w-full gap-4 items-between">
                <div className="flex flex-col justify-start items-start w-[50%]">
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
                    <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
                      <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
                        {communitiesList.length} Existing Communitites
                      </div>
                      <button
                        onClick={() => {
                          addAllCommunitites();
                          callApiIfCommunitiesLengthIsZero();
                        }}
                        className="font-semibold text-primary"
                      >
                        Add All
                      </button>
                    </div>
                  </div>

                  <div className="w-full border border-lineMid">
                    <InfiniteScroll
                      dataLength={communitiesList.length}
                      next={handleLoadMore}
                      hasMore={hasMoreData}
                      height="48vh"
                      className="w-full"
                      style={{ width: "100%" }}
                      loader={
                        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                          {communitiesList.length === 0 && (
                            <TailSpin
                              height="50"
                              width="50"
                              color="#005C89"
                              ariaLabel="tail-spin-loading"
                              radius="2"
                              wrapperStyle={{}}
                              wrapperClass="tailspin-loader"
                              visible={true}
                            />
                          )}
                        </div>
                      }
                    >
                      <div className="flex flex-col items-start justify-start w-full px-2 py-3 overflow-y-auto rounded-lg ">
                        {communitiesList.map((community: any) => {
                          return (
                            <div
                              className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer group hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2]"
                              onClick={() => {
                                addExistingCommunity(community);
                              }}
                            >
                              <img
                                src={
                                  community?.image
                                    ? community?.image.thumb
                                    : Placeholderavatar
                                }
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_community_Data"
                                style={{ flexShrink: 0 }}
                              />
                              <div className="flex items-center justify-start flex-grow">
                                <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                                  <p className="w-[260px] text-left break-words dark:text-textMain">{`${community.name}`}</p>
                                </div>
                              </div>
                              <svg
                                width={14}
                                height={12}
                                viewBox="0 0 14 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.19526 0.861888C7.93491 1.12224 7.93491 1.54435 8.19526 1.8047L11.7239 5.33329L1.33333 5.33329C0.965143 5.33329 0.666666 5.63177 0.666666 5.99996C0.666666 6.36815 0.965143 6.66663 1.33333 6.66663H11.7239L8.19526 10.1952C7.93491 10.4556 7.93491 10.8777 8.19526 11.138C8.45561 11.3984 8.87772 11.3984 9.13807 11.138L13.8047 6.47136C14.0651 6.21101 14.0651 5.78891 13.8047 5.52856L9.13807 0.861888C8.87772 0.601539 8.45561 0.601539 8.19526 0.861888Z"
                                  className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                                />
                              </svg>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
                <ConnectedCommunitiesList
                  connectedCommunitites={connectedCommunitiesList}
                  removeCommunity={removeExistingMember}
                  removeAllCommunitites={removeAllCommunitites}
                  isLoading={isLoading}
                />
              </div>
              <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                <p className="text-left break-words dark:text-textMain">
                  You can’t find the community you’re looking for,
                  <span
                    className="px-2 cursor-pointer text-primaryDark"
                    onClick={redirectToTheDirectory}
                  >
                    Add It Here
                  </span>
                </p>
              </div>
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={() => {
                  handleClose();
                  if (isCommunityRemoved) {
                    dispatch(fetchCommunitiesAction(organizationId, programId));
                  }
                }}
              />

              <Button
                type="button"
                disabled={isLoading}
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={addExistingCommunnities}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

interface ConnectedCommunitiesListProps {
  connectedCommunitites: any[];
  removeCommunity: (community: any) => void;
  removeAllCommunitites: () => void;
  isLoading: boolean;
}

const ConnectedCommunitiesList: React.FC<ConnectedCommunitiesListProps> = ({
  connectedCommunitites,
  removeCommunity,
  removeAllCommunitites,
  isLoading,
}) => {
  const { organizations } = usePermissions();
  return (
    <div className="flex flex-col justify-start items-start w-[50%] relative">
      <div className="flex flex-col w-full justify-start items-start flex-grow h-[48vh]">
        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
          <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
              {connectedCommunitites.length} Added
            </div>
            <button
              onClick={removeAllCommunitites}
              disabled={
                isLoading ||
                connectedCommunitites.length === 0 ||
                !organizations.canDeleteEntity
              }
              className="font-semibold text-primary disabled:cursor-not-allowed"
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow w-full px-2 py-3 overflow-y-auto border rounded-lg border-lineMid">
          {connectedCommunitites.map((community: any, index: number) => {
            return (
              <div
                key={index}
                className="flex items-center justify-start w-full gap-4 px-2 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2] dark:hover:text-textMid"
              >
                <img
                  src={
                    community?.image
                      ? community?.image?.thumb
                      : Placeholderavatar
                  }
                  alt=""
                  height="100"
                  width="100"
                  className="object-cover Img_community_Data"
                  style={{ flexShrink: 0 }}
                />

                <div className="flex items-center justify-start ">
                  <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                    <p className="w-[260px] dark:text-textMain text-left break-words">{`${community.name}`}</p>
                  </div>
                </div>

                <button
                  disabled={isLoading || !organizations.canDeleteEntity}
                  className="disabled:cursor-not-allowed"
                  onClick={() => removeCommunity(community)}
                >
                  <XCloseSmallIcon
                    width={24}
                    height={24}
                    className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                  />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function reStructureTheEntitiesList(
  entitiesList: any,
  isConnected: boolean
): any[] {
  const reStructuredList = entitiesList.map((community: any) => {
    const { ...rest } = community;
    return {
      ...rest,
      isConnected: isConnected,
    };
  });

  return reStructuredList;
}

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  return difference;
}
