import apiClient from "services/apiClient";

const getDetailsofNotificationsummary = async (
    id: any,
) => {
  const res = await apiClient.get(
    `/admin/reminders/${id}`
  );
  return res.data;
};

export default getDetailsofNotificationsummary;
