// import React, { useLayoutEffect, useState } from "react";
// import BpCheckbox from "view/components/CheckBox";
// import "./versions.scss";
// import apiLibrary from "services/api";
// import InputField from "view/components/InputField";
// import { useDispatch } from "react-redux";
// import { pollAndSurveyResultsVersionAction } from "store/filters/reducer.actions";
// import { useSelector } from "react-redux";
// import { RootState } from "store";
// import ellipsize from "ellipsize";
// import { filtersInitialState } from "store/filters/initialState";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { TailSpin } from "react-loader-spinner";

// import _ from "lodash";
// import { useParams } from "react-router-dom";

// export const Versions: React.FC = () => {
//   const { pollAndSurveyResultsFilter } = useSelector<
//     RootState,
//     filtersInitialState
//   >((state) => state.Filters);
//   const dispatch = useDispatch();
//   const { pollId } = useParams();
//   const [dropDownValues, setDropDownValues] = useState<any[]>([]);
//   const handleSelection = (
//     e: React.ChangeEvent<HTMLInputElement>,
//     selectedValue: any // Change the parameter name here
//   ) => {
//     dispatch(pollAndSurveyResultsVersionAction(selectedValue));
//   };

//   const [searchString, setSearchString] = useState("");
//   const [totalPages, setTotalPages] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasMoreData, setHasMoreData] = useState(true);
//   const [page, setPage] = useState(1);

//   const fetchOrgDropdownData = async (
//     id: any,
//     isLoadMore: boolean,
//     page: number = 1,
//     search?: string
//   ) => {
//     setIsLoading(true);

//     try {
//       const { data } =
//         await apiLibrary.pollsAndSurveys.getPollAndSurveyVersions(id);
//       const sortedData = sortVersions(data);
//       const dataa = sortedData.map((item: any) => {
//         return { name: item.name, id: item.id };
//       });
//       if (isLoadMore) {
//         setDropDownValues((pre: any[]) => {
//           return [...pre, ...dataa];
//         });
//       } else {
//         setDropDownValues(dataa);
//       }
//       setHasMoreData(data.hasMore);
//       setTotalPages(data.totalPages);
//       setHasMoreData(data.totalPages > page);
//     } catch (error: any) {
//       // Handle error
//       console.error("Failed to fetch program dropdown values:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   useLayoutEffect(() => {
//     if (pollId !== undefined) {
//       fetchOrgDropdownData(pollId, false, 1);
//     }
//   }, []);

//   const handleSearchEntities = (searchString: string): void => {
//     setPage((pre: number) => {
//       fetchOrgDropdownData(pollId, false, 1, searchString);
//       return 1;
//     });
//   };

//   const debouncedSearch = _.debounce(handleSearchEntities, 100);

//   const handleLoadMore = () => {
//     setPage((prevPage) => {
//       const page = prevPage + 1;
//       fetchOrgDropdownData(pollId, true, page, searchString);
//       return page;
//     });
//   };

//   return (
//     <div className="bg-bgWhite dark:bg-secondaryLight  min-w-[315px]  shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid dark:border-lineLight">
//       <div className="w-full">
//         <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
//           <InputField
//             placeholder="Search"
//             type="Search"
//             onChange={(e: any) => {
//               setSearchString(e.target.value);
//               debouncedSearch(e.target.value);
//             }}
//           />
//         </div>
//         <div className="pl-1.5 bg-bgWhite dark:bg-secondaryLight">
//           <InfiniteScroll
//             dataLength={dropDownValues.length}
//             next={handleLoadMore}
//             hasMore={hasMoreData}
//             height={400}
//             loader={
//               <div className="flex items-center justify-center w-full loader">
//                 <TailSpin
//                   height="30"
//                   width="30"
//                   color="#005C89"
//                   ariaLabel="tail-spin-loading"
//                   radius="2"
//                   wrapperStyle={{}}
//                   wrapperClass="tailspin-loader"
//                   visible={true}
//                 />
//               </div>
//             }
//             pullDownToRefresh={true}
//             refreshFunction={() => {}}
//             pullDownToRefreshContent={
//               <h3 style={{ textAlign: "center" }}>
//                 &#8595; Pull down to refresh &#8595;
//               </h3>
//             }
//             releaseToRefreshContent={
//               <h3 style={{ textAlign: "center" }}>
//                 &#8593; Release to refresh &#8593;
//               </h3>
//             }
//           >
//             {dropDownValues.map((dropDown: any, index: any) => (
//               <div
//                 key={index}
//                 className="flex items-center justify-start w-full px-1 rounded-lg"
//               >
//                 <BpCheckbox
//                   checked={pollAndSurveyResultsFilter.versionIds.value.some(
//                     (item: any) => item.id === dropDown.id
//                   )}
//                   label={
//                     <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
//                       <p className="dark:text-inputText">
//                         {ellipsize(`${dropDown.name}`, 25)}
//                       </p>
//                     </div>
//                   }
//                   labelPlacement="end"
//                   onChange={(e) => handleSelection(e, dropDown)}
//                 />
//               </div>
//             ))}
//           </InfiniteScroll>
//         </div>
//       </div>
//     </div>
//   );

// };

// export function sortVersions(versions: any[]) {
//   if (!Array.isArray(versions)) {
//     return [];
//   }
//   return versions.sort((a, b) => {
//     const versionA = parseInt(a.name.split("-")[1]);
//     const versionB = parseInt(b.name.split("-")[1]);
//     return versionB - versionA;
//   });
// }

import React, { useEffect, useState } from "react";

import Select, { SingleValue, components } from "react-select";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { RootState } from "store";
import { pollAndSurveyResultsVersionAction } from "store/filters";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

interface OptionType {
  value: string;
  label: string;
}

export const Versions = ({ isCloseDiv }: any) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { surveyAndPolls } = usePermissions();
  const [surveyVersions, setSurveyVersions] = useState<any[]>([]);
  const { pollId } = useParams();
  const { pollAndSurveyResultsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const getSurveyVersions = async (id: any) => {
    try {
      const { data } =
        await apiLibrary.pollsAndSurveys.getPollAndSurveyVersions(id);
      const sortedData = sortVersions(data);
      const dataa = sortedData.map((item: any) => {
        return { label: item.name, value: item.id };
      });

      setSelectedOption(dataa[0]);
      dispatch(
        pollAndSurveyResultsVersionAction({
          name: dataa[0]?.label,
          id: dataa[0]?.value,
        })
      );
      setSurveyVersions(dataa);
    } catch (error) {
      console.error("Error fetching survey versions:", error);
    }
  };

  useEffect(() => {
    if (pollId !== undefined) {
      getSurveyVersions(pollId);
    }
  }, []);

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option as OptionType);
    dispatch(
      pollAndSurveyResultsVersionAction({
        name: option?.label,
        id: option?.value,
      })
    );
  };

  return (
    <div>
      {surveyAndPolls.canViewVersionSurveyAndPolls && (
        <div className="relative flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <div className="z-[99]">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={surveyVersions}
              isSearchable={false}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              styles={{
                container: (provided) => ({
                  position: "relative",
                  zIndex: 999,
                  border: "none",
                }),
                control: (provided) => ({
                  ...provided,
                  fontWeight: "500",
                  background: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "0.5rem",
                  borderColor: "#E6E8E9",
                }),
                menu: (provided) => ({
                  ...provided,
                  minWidth: "180px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  textTransform: "capitalize",
                  fontSize: "14px",
                }),
                indicatorsContainer: () => ({
                  padding: 0,

                  "&.indicatorContainer": {
                    padding: 0,
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  display: "flex",
                  paddingRight: 0,
                }),
                option: (provided, state) => ({
                  ...provided,
                  cursor: "pointer",
                  color: state.isSelected ? "#005C89" : "#2C3236",
                  backgroundColor: state.isSelected ? "#DBE8EE" : "transparent",
                  fontWeight: state.isSelected ? "500" : "400",
                }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CheveronDownIcon
        style={{
          transform: props.selectProps.menuIsOpen
            ? "rotate(180deg)"
            : "rotate(0deg)",
          transition: "transform 0.2s",
          position: "relative",
          height: "18px",
          width: "18px",
        }}
      />
    </components.DropdownIndicator>
  );
};

export function sortVersions(versions: any[]) {
  if (!Array.isArray(versions)) {
    return [];
  }
  return versions.sort((a, b) => {
    const versionA = parseInt(a.name.split("-")[1]);
    const versionB = parseInt(b.name.split("-")[1]);
    return versionB - versionA;
  });
}
