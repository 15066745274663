import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { filtersInitialState } from 'store/filters/initialState';
import { getBrandPlaceholder } from 'utils/customBranding';

interface AppButtonsProps {
  appInfo?: any;
  handleOnClick: (buttonType: string) => void;
}

const AppButtons: React.FC<AppButtonsProps> = ({ appInfo, handleOnClick }) => {
  const { messageAppIdFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  return (
    <div className='flex items-center gap-5 mt-4 mx-4 '>
      {appInfo?.map((app: any) => (
        <button
          key={app?.id}
          onClick={() => {
            if (messageAppIdFilter?.appId !== app?.id) {
              handleOnClick(app?.id);
            }
          }}
          className='flex flex-col items-center'
        >
          <div
            className={`relative rounded-xl p-2 mb-3 border border-bgWhite dark:border-bgtetriary
            ${
              messageAppIdFilter?.appId === app?.id
                ? 'bg-primaryLight  border-primary dark:border-primary'
                : 'hover:bg-[#F0F0F0] '
            }`}
          >
            <div className='w-7 h-7'>
              <img
                src={app?.image?.large || getBrandPlaceholder("profile")}
                className='w-full h-full'
              />
            </div>

            {messageAppIdFilter?.appId !== app?.id && app?.unreadCount ? (
              <div className='absolute rounded-3xl border border-[#74ABCA] bg-primaryLight top-7 left-5'>
                <p className='text-[7px] text-secondaryMid  py-1 px-2 min-w-[28px] '>
                  {app?.unreadCount}
                </p>
              </div>
            ) : null}
          </div>
          <p className='font-normal text-sm text-textMidLight dark:text-caption whitespace-nowrap'>
            {app?.name}
          </p>
        </button>
      ))}
    </div>
  );
};

export default AppButtons;
