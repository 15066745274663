import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import { sentenceCase } from "change-case";
import PlaceHolderAvatar from "assets/images/Placeholders/reportsSummaryAvatar.png";

interface IProps {
  permissions: any;
  id: number;
}

export const SummaryPermissions: React.FC<IProps> = ({ permissions, id }) => {
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  return (
    <div className="py-4 border-b border-lineMid dark:border-lineLight">
      <Accordion
        className="dark:bg-secondaryLight "
        style={{ boxShadow: "none" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex items-center justify-between w-full">
              <p className="text-secondaryMid dark:text-textMain ">
                Permissions
              </p>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: "-20px" }}>
          <Typography>
            {permissions.map((permission: any) => {
              return (
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center py-2">
                    <img
                      src={
                        permission?.profileImage?.file?.thumb
                          ? permission?.profileImage?.file?.thumb
                          : PlaceHolderAvatar
                      }
                      alt=""
                      height="100"
                      width="100"
                      className="object-cover Img_user_Data"
                      style={{ flexShrink: 0 }}
                    />
                    <p className="text-sm ml-4 mt-[-3px] capitalize  dark:text-textMain ">
                      {permission.name}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="mr-4 text-sm text-secondaryMid opacity-60 dark:opacity-100 dark:text-textMain">
                      {sentenceCase(permission?.role ?? "")}
                    </p>
                    <p className="text-sm dark:text-textMain ">
                      {sentenceCase(permission?.permission ?? "")}{" "}
                    </p>
                  </div>
                </div>
              );
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
