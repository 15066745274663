import React, { useState } from "react";
import "./Organizations.scss";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import { notificationsRecepientsDateCreatedUpdatedArchivedDeletedAction, notificationsRecipientsRecievedDateAction } from "store/filters/reducer.actions";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


interface IProps {
  name: string;
  dateRecieved: string;
}
export const RecievedDate: React.FC<IProps> = ({ name, dateRecieved }) => {
  const dispatch = useDispatch();
  const formatDate = (date: any) => moment(date).format(DATE_FORMATS.DEFAULT);
  const handleDateInputChange = (
    event: any
  ) => {
    const selectedDate = new Date(event);
    const formattedDate = formatDate(selectedDate);
    handleDateChange(formattedDate);
  };
  const handleDateChange = (date: any) => {
    dispatch(notificationsRecipientsRecievedDateAction(date));
  };

  return (
    <div className="flex py-2 pl-2 shadow-xl bg-bgWhite dark:bg-secondaryLight rounded-xl">
      <div className="border-r-0 border-b-0 border-t-0 border-lineMid w-[255px] flex flex-col justify-start items-start p-3">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <InputField
              type="date"
              className="uppercase"
              label="Recieved Date"
              placeholder="YYYY/MM/DD"
              value={dateRecieved} // Set the value from state
              onChange={(event: any) => handleDateInputChange(event)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
