import getAllCommunities from "./getAllCommunities";
import getCommunityProfile from "./getCommunityProfile";
import getallUsers from "./getallUsers";
import getallOrganization from "./getallOrganizations";
import createCommunity from "./createCommunity";
import editCommunity from "./editCommunity";
import addExistingAdmis from "./addExistingAdmis";
import exitCommunity from "./exitCommunity";
import archiveCommunity from "./archiveCommunity";
import addCommunityMember from "./addCommunityMember";
import deleteCommunity from "./deleteCommunity";
import removePersonFromCommunity from "./removePersonFromCommunity";
import getAllUserWhichCanBeAddAsAdmin from "./getAllUserWhichCanBeAddAsAdmin";
import getOrganizationsByCommunity from "./getOrganizationsByCommunity";
import getMembersByCommunity from "./getMembersByCommunity";
import addExistingMembersToCommunity from "./addExistingMembersToCommunity";
import getSpeciesAndObjectsByCommunity from "./getSpeciesAndObjectsByCommunity";
import addExistingObjectsToCommunity from "./addExistingObjectsToCommunity";
import addExistingSpeciesToCommunity from "./addExistingSpeciesToCommunity";
import editCommunityTags from "./editCommunityTags";
import removeSpeciesOrObjectFromCommunity from "./removeSpeciesOrObjectFromCommunity";
import getCommunityMapGeographies from "./getCommunityMapGeographies";
const Communities = {
  getAllCommunities,
  getCommunityProfile,
  getallUsers,
  getallOrganization,
  createCommunity,
  editCommunity,
  addExistingAdmis,
  exitCommunity,
  archiveCommunity,
  addCommunityMember,
  deleteCommunity,
  removePersonFromCommunity,
  getAllUserWhichCanBeAddAsAdmin,
  getOrganizationsByCommunity,
  getMembersByCommunity,
  addExistingMembersToCommunity,
  getSpeciesAndObjectsByCommunity,
  addExistingObjectsToCommunity,
  addExistingSpeciesToCommunity,
  editCommunityTags,
  removeSpeciesOrObjectFromCommunity,
  getCommunityMapGeographies
};
export default Communities;
