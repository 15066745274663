import React from 'react';

interface ImageThumbnailProps {
  url: string;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({ url }) => {
  return (
    <div className='w-[65px] h-[65px] bg-bgWhite dark:bg-bgtetriary relative object-cover rounded overflow-hidden'>
      <img src={url} alt='Image Thumbnail' className='w-full h-full rounded' />
    </div>
  );
};

export default ImageThumbnail;
