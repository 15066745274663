

export interface PropertyDropdown {
  mappedProperty: string,
  propertyType: string,
  section: string,
  value?: number,
}

export interface PropertyDropdownData {
  properties: PropertyDropdown[];
}

export interface FetchPropertyDropdownData {
  data: PropertyDropdownData;
  message: string;
}

// Create an initial state object
const initialPropertyDropdownState: FetchPropertyDropdownData = {
  data: {
    properties: [],
  },
  message: "",
};

export default initialPropertyDropdownState;
