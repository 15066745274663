import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { observationsSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Observations.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { filterKeys } from "Components/Users/Table";
import { managesortingActions } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";

export const KEYS_TO_BE_REMOVED = ["id"];

const generatingDirectoryActions = (columns: any, rowsData: any) => {
  const { programId } = useParams<{
    programId: string;
  }>();
  const fileDownload = async () => {
    if (rowsData.length === 0) {
      Toasts.error("Please select at least one manager from the table");
      return;
    }

    try {
      const res = await apiLibrary.file.fileDownload(
        "managers",
        columns,
        rowsData?.map((item: any) => {
          return item.reviewer.personId;
        }),
        programId
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `managers_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return [
    {
      label: "Export Details",
      onClick: () => fileDownload(),
      divider: false,
      isVisible: true,
    },
  ];
};

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();
  const { managerActions } = useActions();
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const DirectoryActions = () => {
    const filteredKeys = filterKeys(columnVisibility, columnOrder);
    return generatingDirectoryActions(
      filteredKeys,
      getAllRowsData(table, columnOrder, columnVisibility, columns)
    );
  };

  const getManagersActions = (data: any) => {
    const modalData = {
      type: managerFilter.type,
      personId: data?.personId,
      reviewersDetail: data,
    };

    return managerActions(modalData);
  };

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        if (columnsId === "reviewer") {
          dispatch(managesortingActions("name", "asc"));
        } else {
          dispatch(managesortingActions(columnsId, "asc"));
        }

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        if (columnsId === "reviewer") {
          dispatch(managesortingActions("name", "desc"));
        } else {
          dispatch(managesortingActions(columnsId, "desc"));
        }

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(managesortingActions("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          DirectoryActions={DirectoryActions()}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
          getManagersActions={getManagersActions}
        />
      </table>
    </div>
  );
};
