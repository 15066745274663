import React from "react";
import "./AllAnalytics.scss";
import { useDispatch } from "react-redux";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import {
  reportsAnalyticsActivityAction,
  reportsAnalyticsResetFiltersAction,
} from "store/filters";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { RootState } from "store";

interface AllAnalyticsProps { }
export const AllAnalytics: React.FC<AllAnalyticsProps> = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { reportsAnalyticsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const analyticsActions = () => {
    return [
      {
        label: "All",
        onClick: () => dispatch(reportsAnalyticsResetFiltersAction()),
        divider: false,
        isVisible: true,
        isActive: reportsAnalyticsFilter.activityType === ""
      },
      {
        label: "Viewed",
        onClick: () => dispatch(reportsAnalyticsActivityAction("viewed")),
        divider: false,
        isVisible: true,
        isActive: reportsAnalyticsFilter.activityType === "viewed"
      },
      {
        label: "Download",
        onClick: () => dispatch(reportsAnalyticsActivityAction("download")),
        divider: false,
        isVisible: true,
        isActive: reportsAnalyticsFilter.activityType === "download"
      },
    ];
  };

  return (
    <div className="bg-bgWhite">
      <ActionDropDownMenu actions={analyticsActions()} />
    </div>
  );
};
