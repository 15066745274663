import React, { useEffect, useState } from "react";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { IfetchManager } from "store/managers/initialState";
import { filtersInitialState } from "store/filters/initialState";

export const Filters = () => {
  const { data } = useSelector<RootState, IfetchManager>(
    (state) => state?.allManagers
  );
  const [typeMessage, setTypeMessage] = useState("");
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const StatusMessage = () => {
    switch (managerFilter.type) {
      case "internal":
        setTypeMessage(
          "This internal directory shows all admin users connected to this program."
        );
        break;
      case "external":
        setTypeMessage(
          "This tab shows all external reviewers who are ONLY registered in this role for this program."
        );
        break;
      case "external_data_end_viewer":
        setTypeMessage(
          "This tab shows all data-end viewers who are ONLY registered in this role for this program."
        );
        break;
      default:
        setTypeMessage(
          "This internal directory shows all admin users connected to this program."
        );
        break;
    }
  };
  useEffect(() => {
    StatusMessage();
  }, [managerFilter.type]);
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar data={data} />
        <div className=" pb-4">
          <p className="text-sm font-normal text-secondaryMidLight">
            {typeMessage}
          </p>
        </div>
      </div>
    </>
  );
};
