import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import useDateRange from "hooks/useDateRange";
import { useDispatch } from "react-redux";
import { usersLastActiveAction, usersMemberSinceAction } from "store/filters";

interface DateProps {
  name: string;
  from: string;
  to: string;
  isApplied: boolean;
}

export const Date: React.FC<DateProps> = ({ name, from, to, isApplied }) => {
  const dispatch = useDispatch();
  const { getDateOption } = useDateRange();
  const resetFilter = () => {
    if (name === "memberSince") {
      dispatch(usersMemberSinceAction("", ""));
    } else if (name === "lastActive") {
      dispatch(usersLastActiveAction("", ""));
    }
  };

  if (!isApplied) {
    return null;
  }
  if (name === "memberSince") {
    if (from === "Invalid date") {
      dispatch(usersMemberSinceAction("", to));
    } else if (to === "Invalid date") {
      dispatch(usersMemberSinceAction(from, ""));
    }
  } else if (name === "lastActive") {
    if (from === "Invalid date") {
      dispatch(usersLastActiveAction("", to));
    } else if (to === "Invalid date") {
      dispatch(usersLastActiveAction(from, ""));
    }
  }
  console.log("from => ", from, "to => ", to);

  return (
    <div className="flex flex-wrap justify-center items-center relative gap-1 px-2 capitalize  rounded-lg bg-primary/[0.08]">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
        <p className="flex-grow-0 flex-shrink-0 text-sm text-center capitalize text-textMid dark:text-inputText ">
          {sentenceCase(name)}: {getDateOption(from, to)}
        </p>
      </div>
      <ClearFilters handleClick={resetFilter} />
    </div>
  );
};
