import React, { useEffect } from "react";
import TextInput from "../Components/Inputs/TextInput";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { updatePersonProfileValidationSchema } from "utils/validationSchemas";
import apiLibrary from "services/api";
import { motion } from "framer-motion";
import { getUserProfileAction } from "store/userProfile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { Toasts } from "view/components/Toasts";
import Roles from "hooks/roles";
import { updateAuthDataAction } from "store/auth/reducer.actions";
import { Header } from "Components/Profile/components";
import {
  ContactDetails,
  DynamicEmailAndPhoneContainer,
} from "Components/Profile/Person";
import Addresses from "Components/Profile/Person/Addresses";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import ellipsize from "ellipsize";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import { resetData } from "store/userProfile/reducer.actions";
import usePermissions from "hooks/usePermissions";

export const Person = () => {
  const [isDivActive, setIsDivActive] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMyProfile } = Roles();

  const tenYearsAgo = moment().subtract(10, "years").format(DATE_FORMATS.DEFAULT);

  const dispatch = useDispatch();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const formValues = {
        ...values,
        secondaryEmail: values.secondaryEmail.filter(
          (item: any) => !item.isPrimary && item.email !== ""
        ),
        secondaryPhoneNumber: values.secondaryPhoneNumber.filter(
          (item: any) => !item.isPrimary && item.phoneNumber !== ""
        ),
      };
      const { data, message } = await apiLibrary.userProfile.updateUserProfile(
        userProfile.personId,
        formValues
      );
      if (isMyProfile(data?.personId)) {
        dispatch(updateAuthDataAction(data));
      }
      if (data) {
        dispatch(getUserProfileAction(data));
      }
      Toasts.success(message);
      resetForm();
      setEditMode(false);
    } catch (error: any) {
      console.error(error);
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const handleEditPerson = () => {
    setEditMode(!editMode);
    setIsDivActive(!isDivActive);
  };

  const updatePersonData = async (values: any) => {
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      setIsLoading(true);
      const formValues = {
        ...values,
        secondaryEmail: values.secondaryEmail.filter(
          (item: any) => !item.isPrimary && item.email !== ""
        ),
        secondaryPhoneNumber: values.secondaryPhoneNumber.filter(
          (item: any) => !item.isPrimary && item.phoneNumber !== ""
        ),
      };
      const { data, message } = await apiLibrary.userProfile.updateUserProfile(
        userProfile.personId,
        formValues
      );
      if (isMyProfile(data?.personId)) {
        dispatch(updateAuthDataAction(data));
      }
      dispatch(getUserProfileAction(data));

      Toasts.success(message);
    } catch (error: any) {
      console.error(error);
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch(setLoadingProgress(100));
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow pb-8 overflow-hidden border-t-0 border-b-0 border-l border-r-0 border-lineMid dark:border-lineLight dark:bg-secondaryLight">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 overflow-hidden bg-white">
        <Formik
          initialValues={userProfile}
          validationSchema={updatePersonProfileValidationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validateOnChange
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            resetForm,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form className={`dark:bg-secondaryLight w-full`}>
              <Header
                title="Personal Info"
                editMode={editMode}
                handleEdit={handleEditPerson}
                resetForm={resetForm}
                isSubmitting={isSubmitting}
              />
              <div
                className={`w-full relative border-b border-l-0 border-t-0 border-r-0 border-lineMid dark:border-lineLight pb-16`}
              >
                {/* {isSubmitting && <Overlay />} */}
                <div className="flex flex-col w-full px-8 pt-4 pb-12 xl:px-12 ">
                  <div className="flex flex-col w-full ">
                    <div
                      className={`grid ${
                        editMode
                          ? "xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1"
                          : "xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1"
                      } gap-4 `}
                    >
                      <div className="flex flex-col flex-wrap flex-grow w-full break-all rounded-lg">
                        <TextInput
                          label={editMode ? "First name*" : "First name"}
                          type="text"
                          name="firstName"
                          setFieldValue={setFieldValue}
                          error={errors?.firstName}
                          editMode={editMode}
                          value={values?.firstName}
                          touched={touched.firstName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </div>
                      <div className="flex flex-col flex-grow w-full break-all rounded-lg">
                        <TextInput
                          label={editMode ? "Last name*" : "Last name"}
                          type="text"
                          name="lastName"
                          setFieldValue={setFieldValue}
                          error={errors?.lastName}
                          editMode={editMode}
                          value={values?.lastName}
                          touched={touched.lastName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <motion.div
                    animate={{
                      translateY: editMode ? 20 : 0,
                    }}
                    className={`grid ${
                      editMode
                        ? "xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1"
                        : "xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1"
                    } gap-4 mt-[-10px] `}
                  >
                    <div className="flex flex-col flex-grow">
                      <TextInput
                        label="Prefix"
                        type="text"
                        name="prefix"
                        setFieldValue={setFieldValue}
                        error={errors?.prefix}
                        editMode={editMode}
                        value={ellipsize(values?.prefix, 30)}
                        touched={touched.prefix}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                    <div className="flex flex-col flex-grow rounded-lg">
                      <TextInput
                        label="Suffix"
                        type="text"
                        name="suffix"
                        setFieldValue={setFieldValue}
                        error={errors?.suffix}
                        editMode={editMode}
                        // value={splitString(values?.suffix,30)}
                        value={ellipsize(values?.suffix, 30)}
                        touched={touched.suffix}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                  </motion.div>
                  {editMode ? (
                    <>
                      <motion.div
                        animate={{
                          translateY: editMode ? 90 : 0,
                        }}
                        className="mt-[-40px]"
                      >
                        <DynamicEmailAndPhoneContainer
                          values={userProfile}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          updatePersonData={updatePersonData}
                          isLoading={isLoading}
                        />
                      </motion.div>
                    </>
                  ) : (
                    <ContactDetails editMode={editMode} />
                  )}
                  <motion.div
                    animate={{
                      translateY: editMode ? 100 : 0,
                    }}
                    className={`grid ${
                      editMode
                        ? "xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1"
                        : "xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1"
                    } gap-4  mt-[]`}
                  >
                    <TextInput
                      height={37.5}
                      label="Birthday"
                      type="date"
                      name="dateOfBirth"
                      inputClassName=""
                      setFieldValue={setFieldValue}
                      error={errors?.dateOfBirth}
                      editMode={editMode}
                      value={
                        values?.dateOfBirth ? values.dateOfBirth.toString() : ""
                      }
                      touched={touched.dateOfBirth}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      max={tenYearsAgo}
                    />

                    <TextInput
                      label="Gender"
                      type="select"
                      name="gender"
                      setFieldValue={setFieldValue}
                      error={errors?.gender}
                      editMode={editMode}
                      value={values?.gender ? values?.gender.toString() : ""}
                      data={["male", "female"]}
                      touched={touched.gender}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </motion.div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <Addresses addresses={userProfile?.addresses} />
      </div>
    </div>
  );
};

const Overlay = () => (
  <div
    className="cursor-progress"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  ></div>
);
