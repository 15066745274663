import { apiClient } from "../config";

async function inviteExternalReviewer(observationId: string, body: any) {
  const { accessUntil, reviewerId } = body;

  const res = await apiClient.put(
    `/admin/observations/${observationId}/invite-external-reviewer`,
    {
      accessUntil,
      reviewerId,
    }
  );
  return res.data;
}

export default inviteExternalReviewer;
