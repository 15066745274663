import apiClient from "services/apiClient";

interface Message {
  message: string;
  fileId?: string | null;
}
async function sendMessage(id: any, data: Message) {
  const url = `/admin/conversations/${id}/message`;
  const res = await apiClient.post(url, data);
  return res.data;
}

export default sendMessage;
