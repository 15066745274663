import MapIcon from "assets/icons/HeroIcons/MapIcon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { geographyViewTypeAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const MapBtn = () => {
  const { geographyFilter } = useSelector<RootState,filtersInitialState>((state) => state.Filters)
  const dispatch = useDispatch();
  return (
    <button
      title="MapBtn"
      onClick={() => dispatch(geographyViewTypeAction("map"))}
      className={`relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded-lg ${
        geographyFilter.viewType === "map"
          ? "bg-primary/[0.08]"
          : "hover:bg-primary/[0.08]"
      } `}
    >
      <MapIcon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
    </button>
  );
};

export default MapBtn;
