import CheckCircleIcon from "assets/icons/HeroIcons/CheckCircleIcon";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { openExifViewerModalAction } from "store/modals";

interface Choice {
  value: string;
  imageLink: string;
}

interface ImagePickerProps {
  choices: Choice[];
  value: Choice | Choice[]; // Can be a single object or an array of objects
  name: string;
  handleClick: (value: Choice | Choice[]) => void; // Updated to handle single or multiple values
  editMode?: boolean;
  required?: boolean;
  error?: any;
  touched?: any;
  visibleIf?: boolean;
  enableIf?: boolean;
  setValueIf?: boolean;
  setValueExpression?: boolean;
  requiredIf?: boolean;
  answers?: any;
  label?: any;
  multiSelect?: boolean; // New prop to allow multiple selections
  panel?: any;
  element?: any;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  choices,
  value,
  name,
  handleClick,
  editMode,
  required,
  touched,
  error,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  label,
  panel,
  element,
  multiSelect = false // Default to false if not provided
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [selectedImages, setSelectedImages] = useState<Choice[]>([]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedImages(value);
    } else if (value && typeof value === "object") {
      setSelectedImages([value]);
    }
  }, [value]);

  const isVideo = (link: string) => {
    return /\.(mp4|avi|mkv|wmv|flv|mov)$/i.test(link);
  };

  const handleImageClick = (choice: Choice) => {
    let updatedSelection;
    if (multiSelect) {
      if (
        selectedImages.some((image) => image.imageLink === choice.imageLink)
      ) {
        updatedSelection = selectedImages.filter(
          (image) => image.imageLink !== choice.imageLink
        );
      } else {
        updatedSelection = [...selectedImages, choice];
      }
    } else {
      updatedSelection = [choice];
    }
    setSelectedImages(updatedSelection);
    handleClick(multiSelect ? updatedSelection : updatedSelection[0]);
  };

  const renderChoices = () => {
    if (!editMode) {
      if (!selectedImages.length) {
        return (
          <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
            (No Selection)
          </p>
        );
      }

      return selectedImages.map((choice, index) => (
        <div
          key={index}
          className="relative w-48 h-40 mb-4 overflow-hidden border rounded cursor-pointer border-primary"
          onClick={() => handleImageClick(choice)}
        >
          <div className="relative w-full h-full">
            {isVideo(choice.imageLink) ? (
              <video
                onClick={() => {
                  dispatch(
                    openExifViewerModalAction({
                      fileUrl: choice.imageLink,
                      dataType: "video",
                    })
                  );
                }}
                src={choice.imageLink}
                className="object-cover w-full h-full"
                controls
              />
            ) : (
              <img
                onClick={() => {
                  dispatch(
                    openExifViewerModalAction({
                      fileUrl: choice.imageLink,

                      dataType: "image",
                    })
                  );
                }}
                src={choice.imageLink}
                alt={choice.value}
                className="object-cover w-full h-full"
              />
            )}
            <div className="absolute z-20 bg-blue-500 rounded-full top-1 right-1 w-7 h-7">
              <CheckCircleIcon fill="#fff" className="w-full h-full" />
            </div>
            <div className="absolute inset-0 bg-[#000] opacity-30 rounded transition-opacity duration-300 pointer-events-none"></div>
          </div>
        </div>
      ));
    }

    return choices.map((choice, index) => {
      const isSelected = selectedImages.some(
        (image) => image.imageLink === choice.imageLink
      );
      return (
        <div
          key={index}
          className={`cursor-pointer w-48 h-40 rounded overflow-hidden relative mb-4 ${isSelected ? "border border-primary" : ""}`}
          onClick={() => handleImageClick(choice)}
        >
          <div className="relative w-full h-full">
            {isVideo(choice.imageLink) ? (
              <video
                src={choice.imageLink}
                className="object-cover w-full h-full"
                controls
              />
            ) : (
              <img
                src={choice.imageLink}
                alt={choice.value}
                className="object-cover w-full h-full"
              />
            )}
            {isSelected && (
              <>
                <div className="absolute z-20 bg-blue-500 rounded-full top-1 right-1 w-7 h-7">
                  <CheckCircleIcon fill="#fff" className="w-full h-full" />
                </div>
                <div className="absolute inset-0 bg-[#000] opacity-30 rounded transition-opacity duration-300 pointer-events-none"></div>
              </>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="dark:bg-secondaryLight bg-bgPrimaryLight p-4 rounded-lg" id={`${element?.name}${panel?.sectionId}`}>
      <p className={`w-full pb-2 text-md text-left capitalize font-Overpass ${editMode ? "text-primary dark:text-caption font-medium " : "text-primary dark:text-caption font-regular "}`}>
        {label || name} {required && <span>*</span>}
      </p>

      <div className="flex flex-wrap gap-4">
        {renderChoices()}
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {touched && error && (
            <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark capitalize">
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImagePicker;
