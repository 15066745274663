import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const VideoCameraIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M2 8a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v.382l3.106-1.553A2 2 0 0 1 22 8.618v6.764a2 2 0 0 1-2.894 1.789L16 15.618V16a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8Zm12 5.978a.97.97 0 0 0 0 .042V16a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.978Zm2-.596 4 2V8.618l-4 2v2.764Z"
      clipRule="evenodd"
    />
  </svg>
);
export default VideoCameraIcon;
