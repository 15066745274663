import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const ObservationsIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59"
    height="50"
    viewBox="0 0 59 50"
    fill="none"
  >
    <g filter="url(#filter0_d_6535_1108374)">
      <circle cx="29.7139" cy="29" r="19" fill="#E7FFDF" />
    </g>

    <g clip-path="url(#clip0_12902_15326)">
      <path
        d="M37.6645 28.7676L36.8576 27.9676C32.5973 23.5342 25.6259 23.5342 21.3656 27.9676L20.591 28.7676C20.4619 28.9009 20.4619 29.1009 20.591 29.2342L21.3656 30.0342C23.4957 32.2342 26.3037 33.3342 29.1116 33.3342C31.9195 33.3342 34.7275 32.2342 36.8576 30.0342L37.6322 29.2342C37.6968 29.1676 37.7291 29.1009 37.7291 29.0009C37.7291 28.9009 37.6968 28.8342 37.6645 28.7676ZM29.1116 31.5009C27.7883 31.5009 26.691 30.3676 26.691 29.0009C26.691 27.6342 27.7883 26.5009 29.1116 26.5009C30.4672 26.5009 31.5322 27.6342 31.5322 29.0009C31.5322 30.3676 30.4672 31.5009 29.1116 31.5009Z"
        fill="#5B9D45"
      />
      <path
        d="M29.1114 27.6016C28.3691 27.6016 27.7559 28.2349 27.7559 29.0016C27.7559 29.2016 27.885 29.3349 28.0786 29.3349C28.2723 29.3349 28.4014 29.2016 28.4014 29.0016C28.4014 28.6016 28.7241 28.2682 29.1114 28.2682C29.3051 28.2682 29.4342 28.1349 29.4342 27.9349C29.4342 27.7349 29.3051 27.6016 29.1114 27.6016Z"
        fill="#5B9D45"
      />
      <path
        d="M36.5994 21.3673C36.5994 22.0673 36.0507 22.634 35.3729 22.634C34.6951 22.634 34.1465 22.0673 34.1465 21.3673C34.1465 21.1673 34.1787 21.0007 34.2433 20.834C32.6295 19.634 30.693 19.0007 28.692 19.0007C26.82 19.0007 24.9803 19.5673 23.4311 20.6007C23.2697 20.7007 23.0761 20.6673 22.9793 20.5007C22.8824 20.334 22.9147 20.134 23.0761 20.034C24.7221 18.934 26.6586 18.334 28.692 18.334C30.8544 18.334 32.92 19.034 34.6951 20.334C34.8888 20.1673 35.147 20.1007 35.4052 20.1007C36.0507 20.1007 36.5994 20.6673 36.5994 21.3673Z"
        fill="#5B9D45"
      />
      <path
        d="M35.3077 37.8672C33.5971 39.0339 31.6283 39.6672 29.5627 39.6672C27.4003 39.6672 25.3347 38.9672 23.5596 37.6672C23.3659 37.8339 23.1077 37.9005 22.8495 37.9005C22.1717 37.9005 21.623 37.3339 21.623 36.6339C21.623 35.9339 22.1717 35.3672 22.8495 35.3672C23.5273 35.3672 24.076 35.9339 24.076 36.6339C24.076 36.8005 24.0437 37.0005 23.9791 37.1339C25.6252 38.3339 27.5294 38.9672 29.5627 38.9672C31.4992 38.9672 33.3712 38.3672 34.9527 37.2672C35.114 37.1672 35.3077 37.2005 35.4045 37.3672C35.5014 37.5339 35.4691 37.7672 35.3077 37.8672Z"
        fill="#5B9D45"
      />
      <path
        d="M22.9467 22.5342C22.9467 23.2342 22.398 23.8009 21.7202 23.8009C21.5266 23.8009 21.3652 23.7676 21.2361 23.7009C20.0419 25.3676 19.4287 27.3676 19.4287 29.4676C19.4287 31.4676 20.0097 33.4009 21.0747 35.0342C21.1716 35.2009 21.1393 35.4009 20.9779 35.5009C20.9134 35.5342 20.8488 35.5676 20.7843 35.5676C20.6874 35.5676 20.5906 35.5009 20.5261 35.4342C19.3964 33.6342 18.7832 31.6009 18.7832 29.4676C18.7832 27.2342 19.461 25.1009 20.7197 23.2676C20.5906 23.0676 20.4938 22.8009 20.4938 22.5342C20.4938 21.8342 21.0425 21.2676 21.7202 21.2676C22.398 21.2676 22.9467 21.8342 22.9467 22.5342Z"
        fill="#5B9D45"
      />
      <path
        d="M37.5024 34.7349C37.6315 34.9349 37.7283 35.2016 37.7283 35.4682C37.7283 36.1682 37.1796 36.7349 36.5018 36.7349C35.8241 36.7349 35.2754 36.1682 35.2754 35.4682C35.2754 34.7682 35.8241 34.2016 36.5018 34.2016C36.6955 34.2016 36.8569 34.2349 36.986 34.3016C38.1479 32.6016 38.7611 30.6349 38.7611 28.5349C38.7611 26.5349 38.1802 24.6016 37.1151 22.9682C37.0182 22.8016 37.0505 22.6016 37.2119 22.5016C37.3733 22.4016 37.5669 22.4349 37.6638 22.6016C38.8257 24.3682 39.4066 26.4349 39.4066 28.5682C39.4389 30.8016 38.7611 32.9349 37.5024 34.7349Z"
        fill="#5B9D45"
      />
      <path
        d="M28.7881 21.4681V22.6681C28.7881 22.8681 28.9172 23.0014 29.1108 23.0014C29.3045 23.0014 29.4336 22.8681 29.4336 22.6681V21.4681C29.4336 21.2681 29.3045 21.1348 29.1108 21.1348C28.9172 21.1348 28.7881 21.3014 28.7881 21.4681Z"
        fill="#5B9D45"
      />
      <path
        d="M28.7881 35.3333V36.5333C28.7881 36.7333 28.9172 36.8667 29.1108 36.8667C29.3045 36.8667 29.4336 36.7333 29.4336 36.5333V35.3333C29.4336 35.1333 29.3045 35 29.1108 35C28.9172 35 28.7881 35.1333 28.7881 35.3333Z"
        fill="#5B9D45"
      />
      <path
        d="M23.7208 23.434C23.5917 23.5673 23.5917 23.7673 23.7208 23.9007L24.56 24.7673C24.6246 24.834 24.7214 24.8673 24.7859 24.8673C24.8505 24.8673 24.9473 24.834 25.0119 24.7673C25.141 24.634 25.141 24.434 25.0119 24.3007L24.1727 23.434C24.0759 23.3007 23.8499 23.3007 23.7208 23.434Z"
        fill="#5B9D45"
      />
      <path
        d="M33.2101 33.2348C33.081 33.3681 33.081 33.5681 33.2101 33.7014L34.0493 34.5681C34.1138 34.6348 34.2106 34.6681 34.2752 34.6681C34.3397 34.6681 34.4366 34.6348 34.5011 34.5681C34.6302 34.4348 34.6302 34.2348 34.5011 34.1014L33.662 33.2348C33.5329 33.1014 33.3392 33.1014 33.2101 33.2348Z"
        fill="#5B9D45"
      />
      <path
        d="M24.56 33.2348L23.7208 34.1014C23.5917 34.2348 23.5917 34.4348 23.7208 34.5681C23.7854 34.6348 23.8822 34.6681 23.9468 34.6681C24.0113 34.6681 24.1082 34.6348 24.1727 34.5681L25.0119 33.7014C25.141 33.5681 25.141 33.3681 25.0119 33.2348C24.8828 33.1014 24.6891 33.1014 24.56 33.2348Z"
        fill="#5B9D45"
      />
      <path
        d="M34.0493 23.434L33.2101 24.3007C33.081 24.434 33.081 24.634 33.2101 24.7673C33.2747 24.834 33.3715 24.8673 33.436 24.8673C33.5006 24.8673 33.5974 24.834 33.662 24.7673L34.5011 23.9007C34.6302 23.7673 34.6302 23.5673 34.5011 23.434C34.372 23.3007 34.1784 23.3007 34.0493 23.434Z"
        fill="#5B9D45"
      />
      <path
        d="M37.6645 28.7676L36.8576 27.9676C32.5973 23.5342 25.6259 23.5342 21.3656 27.9676L20.591 28.7676C20.4619 28.9009 20.4619 29.1009 20.591 29.2342L21.3656 30.0342C23.4957 32.2342 26.3037 33.3342 29.1116 33.3342C31.9195 33.3342 34.7275 32.2342 36.8576 30.0342L37.6322 29.2342C37.6968 29.1676 37.7291 29.1009 37.7291 29.0009C37.7291 28.9009 37.6968 28.8342 37.6645 28.7676ZM29.1116 31.5009C27.7883 31.5009 26.691 30.3676 26.691 29.0009C26.691 27.6342 27.7883 26.5009 29.1116 26.5009C30.4672 26.5009 31.5322 27.6342 31.5322 29.0009C31.5322 30.3676 30.4672 31.5009 29.1116 31.5009Z"
        fill="#5B9D45"
      />
      <path
        d="M29.1114 27.6016C28.3691 27.6016 27.7559 28.2349 27.7559 29.0016C27.7559 29.2016 27.885 29.3349 28.0786 29.3349C28.2723 29.3349 28.4014 29.2016 28.4014 29.0016C28.4014 28.6016 28.7241 28.2682 29.1114 28.2682C29.3051 28.2682 29.4342 28.1349 29.4342 27.9349C29.4342 27.7349 29.3051 27.6016 29.1114 27.6016Z"
        fill="#5B9D45"
      />
      <path
        d="M36.5994 21.3673C36.5994 22.0673 36.0507 22.634 35.3729 22.634C34.6951 22.634 34.1465 22.0673 34.1465 21.3673C34.1465 21.1673 34.1787 21.0007 34.2433 20.834C32.6295 19.634 30.693 19.0007 28.692 19.0007C26.82 19.0007 24.9803 19.5673 23.4311 20.6007C23.2697 20.7007 23.0761 20.6673 22.9793 20.5007C22.8824 20.334 22.9147 20.134 23.0761 20.034C24.7221 18.934 26.6586 18.334 28.692 18.334C30.8544 18.334 32.92 19.034 34.6951 20.334C34.8888 20.1673 35.147 20.1007 35.4052 20.1007C36.0507 20.1007 36.5994 20.6673 36.5994 21.3673Z"
        fill="#5B9D45"
      />
      <path
        d="M35.3077 37.8672C33.5971 39.0339 31.6283 39.6672 29.5627 39.6672C27.4003 39.6672 25.3347 38.9672 23.5596 37.6672C23.3659 37.8339 23.1077 37.9005 22.8495 37.9005C22.1717 37.9005 21.623 37.3339 21.623 36.6339C21.623 35.9339 22.1717 35.3672 22.8495 35.3672C23.5273 35.3672 24.076 35.9339 24.076 36.6339C24.076 36.8005 24.0437 37.0005 23.9791 37.1339C25.6252 38.3339 27.5294 38.9672 29.5627 38.9672C31.4992 38.9672 33.3712 38.3672 34.9527 37.2672C35.114 37.1672 35.3077 37.2005 35.4045 37.3672C35.5014 37.5339 35.4691 37.7672 35.3077 37.8672Z"
        fill="#5B9D45"
      />
      <path
        d="M22.9467 22.5342C22.9467 23.2342 22.398 23.8009 21.7202 23.8009C21.5266 23.8009 21.3652 23.7676 21.2361 23.7009C20.0419 25.3676 19.4287 27.3676 19.4287 29.4676C19.4287 31.4676 20.0097 33.4009 21.0747 35.0342C21.1716 35.2009 21.1393 35.4009 20.9779 35.5009C20.9134 35.5342 20.8488 35.5676 20.7843 35.5676C20.6874 35.5676 20.5906 35.5009 20.5261 35.4342C19.3964 33.6342 18.7832 31.6009 18.7832 29.4676C18.7832 27.2342 19.461 25.1009 20.7197 23.2676C20.5906 23.0676 20.4938 22.8009 20.4938 22.5342C20.4938 21.8342 21.0425 21.2676 21.7202 21.2676C22.398 21.2676 22.9467 21.8342 22.9467 22.5342Z"
        fill="#5B9D45"
      />
      <path
        d="M37.5024 34.7349C37.6315 34.9349 37.7283 35.2016 37.7283 35.4682C37.7283 36.1682 37.1796 36.7349 36.5018 36.7349C35.8241 36.7349 35.2754 36.1682 35.2754 35.4682C35.2754 34.7682 35.8241 34.2016 36.5018 34.2016C36.6955 34.2016 36.8569 34.2349 36.986 34.3016C38.1479 32.6016 38.7611 30.6349 38.7611 28.5349C38.7611 26.5349 38.1802 24.6016 37.1151 22.9682C37.0182 22.8016 37.0505 22.6016 37.2119 22.5016C37.3733 22.4016 37.5669 22.4349 37.6638 22.6016C38.8257 24.3682 39.4066 26.4349 39.4066 28.5682C39.4389 30.8016 38.7611 32.9349 37.5024 34.7349Z"
        fill="#5B9D45"
      />
      <path
        d="M28.7881 21.4681V22.6681C28.7881 22.8681 28.9172 23.0014 29.1108 23.0014C29.3045 23.0014 29.4336 22.8681 29.4336 22.6681V21.4681C29.4336 21.2681 29.3045 21.1348 29.1108 21.1348C28.9172 21.1348 28.7881 21.3014 28.7881 21.4681Z"
        fill="#5B9D45"
      />
      <path
        d="M28.7881 35.3333V36.5333C28.7881 36.7333 28.9172 36.8667 29.1108 36.8667C29.3045 36.8667 29.4336 36.7333 29.4336 36.5333V35.3333C29.4336 35.1333 29.3045 35 29.1108 35C28.9172 35 28.7881 35.1333 28.7881 35.3333Z"
        fill="#5B9D45"
      />
      <path
        d="M23.7208 23.434C23.5917 23.5673 23.5917 23.7673 23.7208 23.9007L24.56 24.7673C24.6246 24.834 24.7214 24.8673 24.7859 24.8673C24.8505 24.8673 24.9473 24.834 25.0119 24.7673C25.141 24.634 25.141 24.434 25.0119 24.3007L24.1727 23.434C24.0759 23.3007 23.8499 23.3007 23.7208 23.434Z"
        fill="#5B9D45"
      />
      <path
        d="M33.2101 33.2348C33.081 33.3681 33.081 33.5681 33.2101 33.7014L34.0493 34.5681C34.1138 34.6348 34.2106 34.6681 34.2752 34.6681C34.3397 34.6681 34.4366 34.6348 34.5011 34.5681C34.6302 34.4348 34.6302 34.2348 34.5011 34.1014L33.662 33.2348C33.5329 33.1014 33.3392 33.1014 33.2101 33.2348Z"
        fill="#5B9D45"
      />
      <path
        d="M24.56 33.2348L23.7208 34.1014C23.5917 34.2348 23.5917 34.4348 23.7208 34.5681C23.7854 34.6348 23.8822 34.6681 23.9468 34.6681C24.0113 34.6681 24.1082 34.6348 24.1727 34.5681L25.0119 33.7014C25.141 33.5681 25.141 33.3681 25.0119 33.2348C24.8828 33.1014 24.6891 33.1014 24.56 33.2348Z"
        fill="#5B9D45"
      />
      <path
        d="M34.0493 23.434L33.2101 24.3007C33.081 24.434 33.081 24.634 33.2101 24.7673C33.2747 24.834 33.3715 24.8673 33.436 24.8673C33.5006 24.8673 33.5974 24.834 33.662 24.7673L34.5011 23.9007C34.6302 23.7673 34.6302 23.5673 34.5011 23.434C34.372 23.3007 34.1784 23.3007 34.0493 23.434Z"
        fill="#5B9D45"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6535_1108374"
        x="0.713867"
        y="0"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.7 0 0 0 0 0.7 0 0 0 0 0.7 0 0 0 0.22 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6535_1108374"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6535_1108374"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default ObservationsIcon;
