import { useEffect, useState } from "react";
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { EditBtn, PageHeading } from "./components";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";
import { openCreateOrEditDataAccessPolicyModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IDataAccessPolicy } from "store/DataAccessPolicy/initialState";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import { IProgram } from "store/programs/initialState";

const generateDynamicBreadcrumbLinks = (programId: any, name: any) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/${programId}/profile`, label: `${name}` },
        {
          path: ``,
          label: `Data Access Policy`,
        },
      ];

    default:
      return [];
  }
};
export const Header = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    status: "",
  });
  const { programId } = useParams<{
    programId: string;
  }>();
  const { programs } = usePermissions();

  const dataAccessPolicy = useSelector<RootState, IDataAccessPolicy>(
    (state) => state.dataAccessPolicy
  );
  const DataAccessPolicyLinks = generateDynamicBreadcrumbLinks(
    programId ?? "",
    BreadcrumbData?.name ?? ""
  );

  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
        });
    }
  }, [programId]);

  const editClickHandler = () => {
    dispatch(
      openCreateOrEditDataAccessPolicyModalAction(dataAccessPolicy, true)
    );
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={DataAccessPolicyLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              programId={programId ?? ""}
              name={BreadcrumbData?.name}
            />
            {/* <Restricted permissions={programs} requiredPermission="canEditDataAccessPolicy"> */}
            {dataAccessPolicy.programStatus !== "completed" && programs.canEditDataAccessPolicy && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                <EditBtn handleEditDataAccessPolicyModal={editClickHandler} />
              </div>
            )}
            {/* </Restricted> */}
          </div>
        </div>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    return error;
  }
}
