import apiClient from "services/apiClient";

async function updateReport(reportId: number, data: any) {
  const url = `/admin/reports/${reportId}`;

  const res = await apiClient.put(url, data);

  return res.data;
}

export default updateReport;
