import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { reportsAnalyticsMemberAction } from "store/filters/reducer.actions";

export const SelectedMembers: React.FC = () => {
  const dispatch = useDispatch();
  const { reportsAnalyticsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const resetFilter = (memberIds: any) => {
    dispatch(reportsAnalyticsMemberAction(memberIds));
  };

  if (reportsAnalyticsFilter.memberIds?.applied) {
    return reportsAnalyticsFilter.memberIds.value.map(
      (item: any, index: number) => (
        <div
          key={index}
          className="flex justify-between px-2  items-center gap-2 flex-grow-0 flex-shrink-0 relative rounded-lg bg-primary/[0.08]"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">
              {item.name}
            </p>
          </div>
          <ClearFilters handleClick={() => resetFilter(item)} />
        </div>
      )
    );
  } else {
    return null;
  }
};
