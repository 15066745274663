import PopOverMenu from "view/components/PopOverMenu";
import { RowsActionsProps } from "../../types";
import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";
import ActionDropDownMenu from "Components/ActionDropDownMenu";

export const RowsActions = ({ getObjectsActions, row }: RowsActionsProps) => {
    return (
      <div className="flex items-center justify-start flex-grow svg-container">
        <div className="flex justify-center items-center flex-grow relative py-0.5">
          {getObjectsActions(row.original).some(
            (action: any) => action.isVisible === true
          ) ? (
            <PopOverMenu icon={<PerimeterIcon />}>
              <ActionDropDownMenu
                data={row.original}
                actions={getObjectsActions(row.original)}
              />
            </PopOverMenu>
          ) : (
            <PerimeterIcon  />
          )}
        </div>
      </div>
    );
  };