
import React, { useCallback, useEffect, useState } from "react";
import CustomCheckbox from "view/components/CheckBox";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { SearchBar } from "./SearchBar";
import { ISummaryReports } from "store/reportsSummary";
import PopOverMenu from "./PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import ActiondropdownMenuForReport from "./PopOverMenu/ActiondropdownMenuForReport";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import AdminsDropdown from "./AdminsDropdown";
import { sentenceCase } from "change-case";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";


interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  reportId: any;
  reportSummary: ISummaryReports;
}

export interface IPersonData {
  name: string;
  role: string;
  personId: number;
  lastActive: string;
  personImage: {
    large: string;
    original: string;
    thumb: string;
  };
  isSelected: boolean;
  canView: boolean;
  canDownload: boolean;
  isCreator: boolean;
}

interface SortBy {
  name: string,
  value: string
}

export const InputFields: React.FC<IProps> = ({
  values,
  setFieldValue,
  reportId = null,
  reportSummary,
}) => {
  const [membersList, setMembersList] = useState<any[]>([]);
  const [searchString, isSearchString] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [sortBy, setSortBy] = useState<SortBy>({
    name: "Selected",
    value: "selected"
  })
  const {users} = usePermissions();

  const handleMembersChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: IPersonData
  ) => {
    const { checked, value } = e.target;

    setMembersList((pre: any[]) => {
      const updatedUsers = pre.map((user: IPersonData) => {
        if (user.personId === Number(value) && !user.isCreator) {
          return { ...user, isSelected: checked };
        }
        return user;
      });

      return updatedUsers;
    });

    setFieldValue("data", processTheValuesData(values.data, data, checked));
  };

  const handleCanViewAndDownloadSettings = (person: IPersonData) => {
    setMembersList((pre: IPersonData[]) => {
      return pre.map((user: IPersonData) => {
        if (user.personId === person.personId && !user.isCreator) {
          return person;
        }
        return user;
      });
    });
    const updatedUsersData = values.data.map((user: IPersonData) => {
      if (user.personId === person.personId && !user.isCreator) {
        return person;
      }
      return user;
    });

    setFieldValue("data", updatedUsersData);
  };

  const handleSelectAllMembers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setMembersList((pre: any[]) => {
      const updatedUsers = pre.map((user: IPersonData) => {
        if (!user.isCreator && checked) {
          return { ...user, isSelected: checked };
        } else if (!user.isCreator && !checked) {
          return {
            ...user,
            isSelected: checked,
            canView: checked,
            canDownload: checked,
          };
        }
        return user;
      });
      const updatedValues = handleSelectAllForSelectedOnes(
        updatedUsers,
        values.data,
        checked
      );
      setFieldValue("data", updatedValues);
      return updatedUsers;
    });
  };

  const extractIdsFromProgramsOrganizationsFormAndCommunities = (
    data: any[]
  ) => {
    const values = data?.map((entity: any) => entity.id);
    return values;
  };

  const handleRemoveMember = (data: IPersonData) => {
    setMembersList((pre: any[]) => {
      const updatedUsers = pre.map((user: IPersonData) => {
        if (user.personId === data.personId && !user.isCreator) {
          return { ...user, isSelected: false };
        }
        return user;
      });

      return updatedUsers;
    });

    setFieldValue("data", processTheValuesData(values.data, data, false));
  };
  
  const fetchAdmins = async (
    isLoadMore: boolean,
    reportId: any,
    page: number,
    searchString: string
  ) => {
    let queryParams = {};
    if (reportSummary.filterByEntity) {
      queryParams = {
        communityIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.communitiesFilter
        ),
        formIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.formsFilter
        ),
        organizationIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.organizationsFilter
        ),
        programIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.programsFilter
        ),
        searchString: searchString,
        page: page,
        sortBy: sortBy.value,
        sortType: sortBy.value === 'selected' ? "desc" : "asc"
      };
    } else {
      queryParams = {
        searchString: searchString,
        page: page,
        sortBy: sortBy.value,
        sortType: sortBy.value === 'selected' ? "desc" : "asc"
      };
    }

    try {
      const { data } =
        await apiLibrary.Reports.getAdminsByReportIdCommunityFormsOrganizationAndProgramIds(
          reportId,
          queryParams
        );

      const selectedAdmins = data.admins.filter(
        (admin: IPersonData) => admin.isSelected === true
      );

      if (!isLoadMore) {
        setFieldValue("data", selectedAdmins);
        setMembersList(data.admins);
      } else {
        setFieldValue("data", [...values.data, ...selectedAdmins]);
        setMembersList((prev: IPersonData[]) => [...prev, ...data.admins]);
      }

      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  useEffect(() => {
    if (reportSummary.id !== null) {
      fetchAdmins(false, reportSummary.id, 1, searchString);
    }
  }, [reportSummary, sortBy]);

  const handleNotificationStatus = (data?: any) => {
    if(users.canViewUsersProfile){
      window.open(`/profile/${data.personId}/person`, "_blank");
    }
  };

  const messagesActions = (item?: any) => {
    const actions: {
      label: string;
      onClick: () => void;
      Icon?: React.ElementType;
      iconProps: { fill?: string; width?: number; height?: number };
      divider: boolean;
      isVisible: boolean;
    }[] = [
        {
          label: "See profile in new window",
          onClick: () => handleNotificationStatus(item),
          // Icon: VolumeOffIconOutlined,
          iconProps: { fill: "#2C3236", width: 20, height: 20 },
          divider: false,
          isVisible: true,
        },
      ];

    return actions;
  };

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchAdmins(true, reportSummary.id, page, searchString);
      return page;
    });
  };

  const searchAdmins = async (
    reportId: any,
    page: number,
    searchString: string,
    existingUsers: IPersonData[]
  ) => {
    let queryParams = {};
    if (reportSummary.filterByEntity) {
      queryParams = {
        communityIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.communitiesFilter
        ),
        formIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.formsFilter
        ),
        organizationIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.organizationsFilter
        ),
        programIds: extractIdsFromProgramsOrganizationsFormAndCommunities(
          reportSummary.programsFilter
        ),
        searchString: searchString,
        page: page,
        sortBy: sortBy.value,
        sortType: sortBy.value === 'selected' ? "desc" : "asc"
      };
    } else {
      queryParams = {
        searchString: searchString,
        page: page,
        sortBy: sortBy.value,
        sortType: sortBy.value === 'selected' ? "desc" : "asc"
      };
    }

    try {
      const { data } =
        await apiLibrary.Reports.getAdminsByReportIdCommunityFormsOrganizationAndProgramIds(
          reportId,
          queryParams
        );

      const selectedAdmins = data.admins.filter(
        (admin: IPersonData) => admin.isSelected === true
      );

      setFieldValue(
        "data",
        [...existingUsers, ...selectedAdmins].reduce(
          (acc, currentUser: IPersonData) => {
            const isDuplicate = acc.some(
              (user: IPersonData) => user.personId === currentUser.personId
            );
            if (!isDuplicate) {
              acc.push(currentUser);
            }
            return acc;
          },
          []
        )
      );
      setMembersList(data.admins);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const handleSearch = useCallback(
    async (searchString: string, existingUsers: any[]) => {
      setPage(1);
      await searchAdmins(reportSummary.id, 1, searchString, existingUsers);
    },
    [reportId]
  );

  const debouncedSearch = _.debounce(handleSearch, 1000);
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  useEffect(() => {
    setAllUsersSelected(areAllSelectedAdminsExisting(values.data, membersList));
  }, [values.data, membersList]);

  const adminDropdownOptions = [
    {
      value: "alphabetical",
      name: "Alphabetical"
    },
    {
      value: "last_active",
      name: "Last Active"
    },
    {
      value: "selected",
      name: "Selected"
    }
  ]

  const handleSortChange = (selected: SortBy) => {
    setPage(1);
    setSortBy(selected)
  }
  return (
    <div className="flex flex-col items-start self-stretch justify-start ">
      <div className="mb-2">
        <p className="text-sm text-textMidLight font-Overpass dark:text-textMain">
          Choose members who can access this report and define their permissions
          on this report (This list show all admins that have access to the
          report by default. You could also select data end-viewers that should
          have access).
        </p>
      </div>

      <div className="w-full">
        <div className="w-full">
          <SearchBar
            handleMembersSearch={(e: any) => {
              debouncedSearch(e.target.value, values.data);
              isSearchString(e.target.value);
            }}
            searchString={searchString}
          />
        </div>
        <div className="max-w-[150px]">
          <AdminsDropdown
            data={adminDropdownOptions}
            value={sortBy}
            handleChange={handleSortChange}
            placeholder={sortBy.name}
          />
        </div>
        <div>
          <div>
            <CustomCheckbox
              checked={allUsersSelected}
              indeterminate={values.data.some((admin: IPersonData) =>
                allUsersSelected
                  ? false
                  : membersList.some(
                    (user: IPersonData) => user.personId === admin.personId
                  )
              )}
              onChange={handleSelectAllMembers}
              label={
                <p className="text-secondaryMid font-Overpass dark:text-textMain">
                  Selected{" "}
                  {
                    values.data.filter((item: IPersonData) => item.isSelected)
                      .length
                  }{" "}
                  Members
                </p>
              }
              labelPlacement="end"
            />
          </div>
          {/* <div className="flex items-center">
            <p>Selected </p>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              className="ml-3"
            />
          </div> */}


          <InfiniteScroll
            dataLength={membersList.length}
            next={handleLoadMore}
            hasMore={hasMoreData}
            height={400}
            className="w-full"
            style={{ width: "100%" }}
            loader={
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                {/* <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                /> */}
              </div>
            }
          >
            {membersList.length === 0 && (
              <div className="flex justify-center items-center h-[60vh]">
                <p className="text-lg font-semibold text-textMid font-Overpass dark:text-textMain">
                  No Record Found
                </p>
              </div>
            )}
            {membersList?.map((item: IPersonData, key: number) => {
              return (
                <div
                  key={key}
                  className="flex items-center self-stretch justify-between w-full gap-4 px-3 py-2 rounded-lg "
                >
                  <div>
                    <CustomCheckbox
                      checked={values.data.some(
                        (user: IPersonData) => user.personId === item.personId
                      )}
                      onChange={(e: any) => handleMembersChange(e, item)}
                      disabled={item.isCreator}
                      value={item.personId}
                      label={
                        <div className="flex items-center">
                          <img
                            src={item?.personImage?.thumb || getBrandPlaceholder("userProfile")}
                            alt=""
                            height="100"
                            width="100"
                            className="object-cover Img_user_Data"
                            style={{ flexShrink: 0 }}
                            onError={(e: any) => {
                              e.target.onerror = null;
                              e.target.src = getBrandPlaceholder("userProfile");
                            }}
                          />

                          <span
                            className={`ml-3 break-words break-all text-secondaryMid font-Overpass capitalize ${item.isSelected
                              ? "dark:text-textMain"
                              : "dark:text-caption"
                              }`}
                          >
                            {item.name}
                          </span>
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </div>

                  <div className="flex items-center justify-start ">
                    <div className="flex items-center justify-start gap-2">
                      <div className="flex items-center justify-center gap-1 py-1 rounded-lg opacity-40">
                        <div className="flex justify-center items-center   px-1 pt-0.5 pb-1">
                          <p className="text-sm text-center capitalize text-secondaryMid dark:text-textMain">
                            {sentenceCase(item.role)}
                          </p>
                        </div>
                      </div>
                      {item.isCreator ? (
                        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-textMain">
                          creator (can edit)
                        </p>
                      ) : (
                        <div>

                          <PopOverMenu style={{ marginLeft: "62px", marginTop: "-8px" }} icon={<PermissionStatus data={item} />}>
                            <ActiondropdownMenuForReport
                              handleRemoveMember={(data: IPersonData) => {
                                handleRemoveMember(data);
                              }}
                              handleCanViewAndDownloadSettings={
                                handleCanViewAndDownloadSettings
                              }
                              data={item}
                            />
                          </PopOverMenu>
                        </div>
                      )}
                    </div>
                    {!item.isCreator && (
                      <div className="flex items-center justify-end gap-2 pl-6">
                        <div className="flex items-center justify-center gap-1 py-1 rounded-3xl">
                          <div className="">
                            <PopOverMenu
                              icon={<DotsVerticalIcon />}
                            >
                              <ActionDropDownMenu
                                actions={messagesActions(item)}
                                data={item}
                              />
                            </PopOverMenu>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

interface PermissionStatusProps {
  data: IPersonData;
}

const PermissionStatus: React.FC<PermissionStatusProps> = ({ data }) => {
  const getPermissionText = () => {
    if (data.canView && data.canDownload) {
      return "Can Download";
    } else if (data.canView) {
      return "Can View";
    } else {
      return "Set Permissions";
    }
  };

  return (
    <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-0.5 pb-1">
        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-textMain">
          {getPermissionText()}
        </p>
      </div>
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.86225 5.52876C3.1226 5.26841 3.54471 5.26841 3.80506 5.52876L8.00033 9.72402L12.1956 5.52876C12.4559 5.26841 12.878 5.26841 13.1384 5.52876C13.3987 5.78911 13.3987 6.21122 13.1384 6.47157L8.47173 11.1382C8.21138 11.3986 7.78927 11.3986 7.52892 11.1382L2.86225 6.47157C2.6019 6.21122 2.6019 5.78911 2.86225 5.52876Z"
          fill="#2C3236"
        />
      </svg>
    </div>
  );
};

function processTheValuesData(
  users: IPersonData[],
  newUser: IPersonData,
  isChecked: boolean
): IPersonData[] {
  const userIndex = users.findIndex(
    (user) => user.personId === newUser.personId
  );

  if (isChecked) {
    return [...users, { ...newUser, isSelected: isChecked }];
  } else {
    return users.filter((user) => user.personId !== newUser.personId);
  }
}

function areAllSelectedAdminsExisting(
  existingUsers: IPersonData[],
  selectedAdmins: IPersonData[]
) {
  return selectedAdmins.every((admin) =>
    existingUsers.some((user) => user.personId === admin.personId)
  );
}

function handleSelectAllForSelectedOnes(
  users: IPersonData[],
  existingUsers: IPersonData[],
  isChecked: boolean
): IPersonData[] {
  if (isChecked) {
    users.forEach((user) => {
      const exists = existingUsers.some(
        (existingUser) => existingUser.personId === user.personId
      );
      if (!exists) {
        existingUsers.push(user);
      }
    });
    return existingUsers;
  } else {
    return existingUsers.filter(
      (existingUser) =>
        !users.some(
          (user) => user.personId === existingUser.personId && !user.isCreator
        )
    );
  }
}

function updateExistingUsers(
  users: IPersonData[],
  existingUsers: IPersonData[]
) {
  users.forEach((user) => {
    const existingUserIndex = existingUsers.findIndex(
      (existingUser) => existingUser.personId === user.personId
    );

    if (existingUserIndex === -1) {
      existingUsers.push(user);
    }
  });
  return existingUsers;
}

function FindUniqueUsers(array1: any[], array2: any[]) {
  return _.unionBy(array1, array2, "personId");
}
