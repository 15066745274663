import { TableBodyProps } from '../../types';
import { Cell } from '@tanstack/react-table';
import { Column, RowsActions } from '../index';

export const TableBody = ({
  table,
  columnVisibility,
  getprotocolsActions,
  flexRender,
}: TableBodyProps) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        let programId = row?.original.programName?.id;
        return (
          <tr
            key={index}
            className='w-full cursor-pointer Close item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group'
            style={{ height: '66px' }}
          >
            {row.getVisibleCells().map((cell: Cell<any, any>) => (
              <Column
                hoverEffect='item_hover_effect'
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
                programId={programId}
                rowData={row}
              />
            ))}
            <td className='sticky right-0 whitespace-nowrap dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight'>
              <RowsActions
                row={row}
                getprotocolsActions={getprotocolsActions}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
