import { HeaderBarProps } from "../../types";
import FiltersBtn from "../FiltersBtn";
import SearchBar from "../SearchBar";

const HeaderBar = ({ data, handleToggleDropdown,isCloseDiv }: HeaderBarProps) => {
  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-caption ">
            {data.totalItems} Items
          </p>
        </div>
      </div>

      <div className="flex items-center justify-end flex-grow h-8 gap-2">
        <div className="flex items-center justify-end flex-grow h-8 gap-2">
          <div className="relative flex items-center justify-between flex-grow-0 flex-shrink-0 gap-2 ">
            <SearchBar />
            {/* <div className="relative flex-grow-0 flex-shrink-0 h-6 border border-b-0 rotate-y-180 border-lineMid"></div> */}
            {/* <FiltersBtn handleToggleDropdown={handleToggleDropdown} isCloseDiv={isCloseDiv} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
