import {
  ERROR_LOADING_POLLS_AND_SURVEYS,
  GET_ALL_POLLS_AND_SURVEYS,
  RESET_DATA,
  START_LOADING_POLLS_AND_SURVEYS,
  STOP_LOADING_POLLS_AND_SURVEYS,
} from "./action-types";
import initialState, { Poll } from "./initialState";
export const pollsAndSurveysReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_POLLS_AND_SURVEYS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_POLLS_AND_SURVEYS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_POLLS_AND_SURVEYS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_POLLS_AND_SURVEYS:
      const polls = action.payload.polls?.map((item: Poll) => {
        const {
          archivedBy,
          createdBy,
          dateCreated,
          dateUpdated,
          description,
          id,
          surveyName,
          programName,
          picture,
          numberOfResponses,
          token,
          status,
          expiryDate,
          surveyAndPollVersionId,
        } = item;

        const data = {
          id: id,
          archivedBy,
          createdBy,
          dateCreated,
          dateUpdated,
          description,
          programName,
          surveyName: {
            name: surveyName,
            picture: picture,
            id,
          },
          surveyAndPollVersionId: surveyAndPollVersionId,
          token,
          numberOfResponses,
          status,
          expiryDate,
        };

        return data;
      });

      return {
        ...state,
        data: {
          polls: polls,
          fields: action.payload.fields,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
