// Define an interface for the category data
export interface IMarkerGroup {
  name: string;
  status:boolean;
  id: number;
}

export interface IMarkerGroupsData {
  markerGroups: IMarkerGroup[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchMarkerGroups {
  data: IMarkerGroupsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchMarkerGroups = {
  data: {
    markerGroups: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
