import React, { useState } from "react";
import Button from "view/components/Button";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import ellipsize from "ellipsize";
import axios from "axios";
import { apiUrl } from "config";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Toasts } from "view/components/Toasts";
import { useDispatch } from "react-redux";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";

interface HeaderProps {
  protocolTitle: any;
  editProtocolHandle: any;
  programStatus: string | undefined;
}

export const Header: React.FC<HeaderProps> = ({
  protocolTitle,
  editProtocolHandle,
  programStatus,
}) => {
  const { protocolId } = useParams();
  const { protocols } = usePermissions();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  /**
   * Function to get the authentication token from cookies
   */
  const getAuthToken = () => cookies.get("token") || "";
  const protocolActions = () => {
    const handleDownload = async (type: string) => {
      try {
        dispatch(setLoadingProgress(getRandomProgress()));
        setIsDownloading(true);
        const authToken = getAuthToken();

        const response = await axios({
          url: `${apiUrl}/admin/protocols/${protocolId}/download?zip=${type}`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        if (type === "text") {
          link.setAttribute("download", `${protocolTitle}-${type}.pdf`);
        } else {
          link.setAttribute("download", `${protocolTitle}-${type}.zip`);
        }

        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
        Toasts.success("Downloded Successfully");
      } catch (error: any) {
        Toasts.error("Failed To Download");
        console.error("Error during file download", error);
      } finally {
        dispatch(setLoadingProgress(100));
        setIsDownloading(false);
      }
    };
    return [
      {
        label: "Download All",
        onClick: () => handleDownload("all"),
        divider: false,
        isVisible: true,
      },
      {
        label: "Download Text",
        onClick: () => handleDownload("text"),
        divider: false,
        isVisible: true,
      },
      {
        label: "Download Attachments",
        onClick: () => handleDownload("attachments"),
        divider: false,
        isVisible: true,
      },
    ];
  };

  return (
    <div className="flex w-full">
      <div className="w-full ">
        <p className="text-[28px] w-full break-all font-bold text-left text-textMid dark:text-textMain">
          {ellipsize(protocolTitle, 170)}
        </p>
      </div>
      <div className={`flex items-center justify-end w-1/5 gap-3`}>
        {protocols.canEditProtocol && programStatus !== "completed" && (
          <div className={` ${isDownloading ? "mr-2" : ""}`}>
            <Button
              type="button"
              text="Edit"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2"
              width="35"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
              onClick={editProtocolHandle}
            />
          </div>
        )}
        <div>
          {isDownloading ? (
            <DotsVerticalIcon className="" />
          ) : (
            <PopOverMenu icon={<DotsVerticalIcon />}>
              <div>
                <ActionDropDownMenu actions={protocolActions()} />
              </div>
            </PopOverMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export const generateZipFromCloud = () => {
  let filename = "SingleFileDownload";
  const url =
    "https://assets.thehindustep.in/user_tasks/0304e0e4913e9a37a5ee6c38a8a2b1a8.pdf";
  const zip = new JSZip();

  const fetchAndAddToZip = async (url: string) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      if (response.status === 200) {
        const blob = response.data;
        const name = url.substring(url.lastIndexOf("/"));
        zip.file(name, blob);
      } else {
        throw new Error(`Failed to fetch ${url}`);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  fetchAndAddToZip(url)
    .then(() => zip.generateAsync({ type: "blob" }))
    .then((blob) => saveAs(blob, filename))
    .catch((e) => console.log(e));
};
