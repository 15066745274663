import { generatingLayerId } from "../mapUtils";

function __changeColorOfZoomedShapeLayer__(
  layer: any,
  map: any,
  zoomedColor: any
) {
  if (!layer || !map) {
    return;
  }
  const { layerId } = generatingLayerId(layer.itemType, layer.item.id);

  const mapLayer = map.getLayer(layerId);

  if (mapLayer) {
    const layerFeatures = map.queryRenderedFeatures({
      layers: [layerId],
    });
    if(layerFeatures.length > 0 &&layerFeatures[0] && layerFeatures[0].geometry){
      const featureType = layerFeatures[0].geometry.type;
      const isLayerHasLineString = featureType === "LineString" || featureType === "MultiLineString";
      

      map.setPaintProperty(
        layerId,
         mapLayer.type === 'line' ? "line-color" : "fill-color",
        zoomedColor
      );
      map.setPaintProperty(
        layerId,
        mapLayer.type === "line" ? "line-opacity" : "fill-opacity",
        0.4
      );
    }
  }
}

export { __changeColorOfZoomedShapeLayer__ };