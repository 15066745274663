import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";
import { Serializer } from "survey-core";
import { GENERAL_INFO_QUESTIONS_NAME } from "../panelsQuestionsName";
const currentState = window.history.state;

export default {
  name: panelsName.GENERAL_INFORMATION,
  title: panelsTitle.GENERAL_INFORMATION,
  iconName: "icon-general",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.GENERAL_INFORMATION}</h1>`,
    },

    {
      type: "dropdown",
      name: GENERAL_INFO_QUESTIONS_NAME.DataEntry,
      title: "Data Entry",

      isRequired: true,
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "tagbox",
      name: GENERAL_INFO_QUESTIONS_NAME.Observers,
      title: "Observers",

      isRequired: true,
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "dropdown",
      name: GENERAL_INFO_QUESTIONS_NAME.Reported,
      title: "Reported",

      isRequired: true,
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: GENERAL_INFO_QUESTIONS_NAME.StartDate,
      title: "Start Date",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "datetime-local",
      name: GENERAL_INFO_QUESTIONS_NAME.EndDate,
      title: "End Date",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "comment",
      name: GENERAL_INFO_QUESTIONS_NAME.Comments,
      title: "Comments",
      placeholder: "Comments",
      isRequired: true,
    },

    {
      type: "audio",
      name: GENERAL_INFO_QUESTIONS_NAME.AudioComments,
      title: "Audio Comments",
      isRequired: true,
    },
  ],
  ...panelSettings(
    panelsName.GENERAL_INFORMATION,
    GENERAL_INFO_QUESTIONS_NAME,
    Serializer
  ),
};

export const generalIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 6C4.44772 6 4 6.44772 4 7V17C4 17.5523 4.44772 18 5 18C5.55228 18 6 17.5523 6 17V12C6 10.3431 7.34315 9 9 9H16C16 8.44771 15.5523 8 15 8H10.5858L8.58579 6H5ZM9 11C8.44772 11 8 11.4477 8 12V17C8 17.3506 7.93985 17.6872 7.82929 18H19C19.5523 18 20 17.5523 20 17V12C20 11.4477 19.5523 11 19 11H9ZM5 20C3.34315 20 2 18.6569 2 17V7C2 5.34315 3.34315 4 5 4H9.41421L11.4142 6H15C16.6569 6 18 7.34315 18 9H19C20.6569 9 22 10.3431 22 12V17C22 18.6569 20.6569 20 19 20H5Z" fill="#909090"/>
</svg>`;
