import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeEditPermissionModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRolesPermissionsAction } from "store/rolesPermissions";
import { PermissionCheckbox } from "./components/PermissionCheckbox";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
  width: "60%",
};

interface EditPermissionModalProps {}

export const EditPermissionModal: React.FC<EditPermissionModalProps> = ({}) => {
  const { editPermissionModal } = useSelector(
    (state: RootState) => state.modals
  );

  const { permissions } = usePermissions();
  const [permissionsData, setPermissionsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // Get ALL Permissions Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiLibrary.RolesPermissions.getAllPermissions(
          editPermissionModal?.data?.id
        );
        setPermissionsData(data);
      } catch (error: any) {
      } finally {
        setIsLoading(false); // Set loading to false
      }
    };
    if (editPermissionModal?.isOpen) {
      fetchData();
      setIsLoading(true);
    }
  }, [editPermissionModal?.isOpen]);

  //Form Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const res = await apiLibrary.RolesPermissions.editPermission(
        editPermissionModal?.data?.id,
        values
      );
      Toasts.success(res.message);
      dispatch(fetchRolesPermissionsAction());
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    dispatch(closeEditPermissionModalAction());
  };

  return (
    <Modal
      open={editPermissionModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={permissionsData}
          validationSchema={null}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="w-[100%]  rounded">
                <div className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                  <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                        Edit Permissions
                      </p>
                    </div>
                    <button onClick={handleClose} type="button">
                      <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                    </button>
                  </div>

                  {isLoading ? (
                    <div className="loader min-h-[40vh] justify-center items-center flex w-full">
                      <TailSpin
                        height="50"
                        width="50"
                        color="#005C89"
                        ariaLabel="tail-spin-loading"
                        radius="2"
                        wrapperStyle={{}}
                        wrapperClass="tailspin-loader"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="w-full overflow-y-auto mt-4 min-h-[40vh] max-h-[60vh]">
                      <PermissionCheckbox
                        values={values}
                        setFieldValue={setFieldValue}
                        permissionsData={permissionsData}
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-end gap-2 pb-4 pr-4">
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="w-24 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !(
                        editPermissionModal.data.editable &&
                        permissions.canEditPermissions
                      )
                    }
                    text="Save"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="w-24 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
