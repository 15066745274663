import {
  Banner,
  ProfileActions,
  ProfileImage,
  ProfileNavigationLinks,
} from "./components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProgram } from "store/programProfile/initialState";

export const Profile = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );

  return (
    <>
      <Banner />
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-6 pt-6 bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="w-full flex justify-between items-start  gap-6 px-9">
          <ProfileImage />
          <ProfileActions />
        </div>
        {/* {!programProfile?.isPublic && <ProfileNavigationLinks />} */}
        <ProfileNavigationLinks />
      </div>
    </>
  );
};
