import * as React from "react";
import { SVGProps, memo } from "react";
const ListImagePlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <circle cx="16" cy="16" r="16" fill="#DCDEE0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 9L14 15.5H9V16.5H14L9 23H23L18 16.5H23V15.5H18L23 9H9ZM18 15.5V16.5H14V15.5H18ZM11.0309 10L14.4924 14.5H17.5076L20.9691 10H11.0309ZM14.4924 17.5L11.0309 22H20.9691L17.5076 17.5H14.4924Z"
      fill="#202123"
      fill-opacity="0.25"
    />
  </svg>
);
const Memo = memo(ListImagePlaceholder);
export default Memo;
