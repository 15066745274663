import { apiClient } from "../config";

async function getAllTargetMembers(queryParams: any) {
  const formattedParams = {
    ...queryParams,
    programIds: queryParams.programIds?.join(","), // Convert array to comma-separated string
    communityIds: queryParams.communityIds?.join(","), // Convert array to comma-separated string
    roleIds: queryParams.roleIds?.join(","), // Convert array to comma-separated string
  };
  const res = await apiClient.get(`/admin/reminders/target-members`, {
    params: formattedParams
  })
  return res.data;
}

export default getAllTargetMembers;
