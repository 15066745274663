
const AbleToSelectMultiple = ({
  ableToCheckMultiple,
  handleCheckboxOnChange,
}: any) => {
  return (
    <div className="flex gap-2">
      <input
        type="checkbox"
        checked={ableToCheckMultiple}
        onChange={handleCheckboxOnChange}
        id="ableToCheckMulti"
        className="w-4" 
      />
      <label htmlFor="ableToCheckMulti" className="cursor-pointer text-secondaryMidLight">
        Able to select multiple
      </label>
    </div>
  );
};

export { AbleToSelectMultiple };
