import apiClient from "services/apiClient";

async function seenObservationsActivites(ids: any) {
  const res = await apiClient.put(`/admin/observations/seen-activities`, null, {
    params: {
      observation_activity_ids: ids?.join(","),
    },
  });
  return res.data;
}

export default seenObservationsActivites;
