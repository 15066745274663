import sortObjectByOrder from "utils/SortObjectByOrder";
import { GET_ALL_ORGZANIZATIONS,START_LOADING_ORGANIZATIONS,ERROR_LOADING_ORGANIZATIONS,STOP_LOADING_ORGANIZATIONS, RESET_DATA } from "./action-types";
import initialState from "./initialState";
import { organizationDirectoryOrder } from "utils/directoryColumnsOrders";

export const organizationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_ORGANIZATIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

      case ERROR_LOADING_ORGANIZATIONS:
        return {
          ...state,
          stateIs: "Error",
        };

    case STOP_LOADING_ORGANIZATIONS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_ORGZANIZATIONS:
      const organizations = action.payload.organizations.map((item: any) => {
        const {
          id,
          name,
          tags,
          programs,
          communities,
          members,
          organizationImage,
          dateAdded,
          status,
          openAccess,
        } = item;
        const data = {
          id,
          name: {
            name,
            organizationImage,
            id,
          },
          members: members,
          tags: tags && tags.length > 0 ? tags.join(", ") : null,
          programs: programs,
          communities: communities,
          dateAdded,
          status,
          openAccess
        };

        return sortObjectByOrder(data, organizationDirectoryOrder);
      });

      return {
        ...state,
        data: {
          organizations: organizations,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
      case RESET_DATA:{
        return initialState
      }
    default:
      return state;
  }
};
