import { SurveyQuestionElementBase } from "survey-react-ui";

import Map from "./Map";

export const dropPinProperties = [
  {
    name: "_id",
    category: "general",
    visibleIndex: 2,
  },
];
export default class SurveyDropPinQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderInput() {
    return <div>ITS a draw pin componenet and render will be soon!</div>;
  }

  renderElement() {
    return (
      <div>
        <Map questionId={this.question._id} />
      </div>
    );
  }
}
