import usePermissions from "hooks/usePermissions";
import { OptionsModalProps } from "../../types";
import { CreateBtn } from "../index";
import { SearchToAddExistingBtn } from "../index";

const OptionsModal = ({
  handleClickOnCreateNewBtn,
  handleClickOnAddExistingBtn,
  isOpen,
}: OptionsModalProps) => {
  const { communities, users } = usePermissions();
  if (isOpen) {
    return (
      <div
        className="z-10 absolute flex flex-col items-start justify-start mt-1 bg-white rounded-lg top-12 right-3 bg-bgWhite dark:bg-secondaryLight"
        style={{
          boxShadow:
            "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
        }}
      >
        <div className="flex flex-col  justify-start items-center self-stretch flex-grow-0 flex-shrink-0 px-1.5 py-2">
          {communities.canCreateMemberCommunities && users.canCreatePerson && (
            <CreateBtn handleClickOnCreateNewBtn={handleClickOnCreateNewBtn} />
          )}
          {communities.canCreateMembersCommunities &&
            communities.canViewUserMembersCommunities && (
              <SearchToAddExistingBtn
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              />
            )}
        </div>
      </div>
    );
  }
  return <></>;
};

export { OptionsModal };
