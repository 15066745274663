import { ERROR_LOADING_IMPORT_INFO, GET_IMPORT_INFO, START_LOADING_IMPORT_INFO, RESET_IMPORT_INFO, STOP_LOADING_IMPORT_INFO } from "./action-types";
import { initialState } from "./initialState";

const importInfoReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case START_LOADING_IMPORT_INFO:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_IMPORT_INFO:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_IMPORT_INFO:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_IMPORT_INFO:
      return {
        ...state,
        data: action.payload,
        message: "",
        stateIs: "Idle",
      };

      return;
    case RESET_IMPORT_INFO:
      return initialState;
    default:
      return state;
  }
};

export default importInfoReducer;
