import { apiClient } from "../config";

async function getResultVersion(id: any, versionId: any, isDownloading?: boolean) {

  const params = {
    isDownloading
  };

  const res = await apiClient.get(`/admin/reports/${id}/result/${versionId}`,
    {
      params
    }
  )
  return res.data;
}

export default getResultVersion;
