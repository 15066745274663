import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, AddOrganizationBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { orgzanizationsResetFiltersAction } from "store/filters/reducer.actions";
import {
  openAddExistingCommunitiesOrOrganizationsToProgramModalAction,
  openAddExistingOrgzanizationsModalAction,
  openCreateOrganizationModalAction,
} from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import OrganizationsPrograms from "view/components/Modals/OrganizationProfileDetails/OrganizationsPrograms";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";
import organizations from "services/api/organizations";
import Restricted from "view/components/Restricted";

const generateDynamicBreadcrumbLinks = (
  communityId: any,
  programId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/organizations`,
          label: `Organizations`,
        },
      ];
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/organizations`,
          label: `Organizations`,
        },
      ];

    default:
      return [
        { path: `/organizations/list`, label: `Organizations` },
        //
      ];
  }
};
export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { organizationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { organizations, programs } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();
  const navigate = useNavigate();
  const organizationsLinks = generateDynamicBreadcrumbLinks(
    communityId ?? "",
    programId ?? "",
    BreadcrumbData?.name ?? "",
    organizationsFilter.status
  );

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/community/list");
        });
    } else if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    } else {
      // Reseting applied filters
      // dispatch(orgzanizationsResetFiltersAction());
    }
    // return () => {
    //   dispatch(orgzanizationsResetFiltersAction());
    // };
  }, [communityId, programId]);

  // Handlers

  // displaying models based on communityId
  const handleAddOrganizationModel = () => {
    if (communityId) {
      // setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);

      dispatch(
        openAddExistingOrgzanizationsModalAction({
          communityId,
          entity: "Community",
        })
      );
    } else if (programId) {
      dispatch(
        openAddExistingCommunitiesOrOrganizationsToProgramModalAction({
          id: programId,
          entity: "organization",
        })
      );
    } else {
      dispatch(
        openCreateOrganizationModalAction(
          {
            communityIds: [],
            missionStatement: "",
            name: "",
            organizationImageId: null,
            tagIds: [],
            adminIds: [],
            profileImage: null,
          },
          false
        )
      );
    }
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewBtn = () => {
    dispatch(
      openCreateOrganizationModalAction({
        communityIds: [],
        missionStatement: "",
        name: "",
        organizationImageId: "",
        tagIds: [],
        adminIds: [],
        profileImage: "",
      })
    );
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    dispatch(
      openAddExistingOrgzanizationsModalAction({
        communityId,
        entity: "Community",
      })
    );
    setIsShowExistingOptionsModal(false);
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={organizationsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              communityId={
                communityId ? communityId : programId ? programId : ""
              }
              name={BreadcrumbData?.name}
              parentName={
                communityId ? "Communities" : programId ? "Programs" : ""
              }
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {/* {(BreadcrumbData?.showAddButton && programId) || communityId ? (
                <AddOrganizationBtn
                  handleAddOrganizationModel={handleAddOrganizationModel}
                  label={"Add Existing Organizations"}
                />
              ) : (
                <AddOrganizationBtn
                  handleAddOrganizationModel={handleClickOnCreateNewBtn}
                  label={"Add Organization"}
                />
              )} */}

              <RenderAddOrganizationBtn breadcrumbData={BreadcrumbData} />
              <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                handleAddOrganizationModel={handleAddOrganizationModel}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderAddOrganizationBtn = ({
  breadcrumbData,
}: {
  breadcrumbData: any;
}) => {
  const { communityId, programId } = useParams();
  const { organizations, programs } = usePermissions();
  const dispatch = useDispatch();

  if (communityId) {
    return;

    return (
      <>
        {organizations.canCreateOrganization && (
          <AddOrganizationBtn
            handleAddOrganizationModel={() => {
              dispatch(
                openCreateOrganizationModalAction({
                  communityIds: [],
                  missionStatement: "",
                  name: "",
                  organizationImageId: "",
                  tagIds: [],
                  adminIds: [],
                  profileImage: "",
                })
              );
            }}
            label={"Add Organization"}
          />
        )}
      </>
    );
  } else if (programId && programs.canViewEntityDropdown && programs.canEditProgram) {
    if (breadcrumbData.showAddButton && !breadcrumbData.openAccess)
      return (
        <AddOrganizationBtn
          handleAddOrganizationModel={() => {
            dispatch(
              openAddExistingCommunitiesOrOrganizationsToProgramModalAction({
                id: programId,
                entity: "organization",
                breadcrumbData,
              })
            );
          }}
          label={"Connect  Organizations"}
        />
      );
  } else {
    return (
      <>
        {organizations.canCreateOrganization && (
          <AddOrganizationBtn
            handleAddOrganizationModel={() => {
              dispatch(
                openCreateOrganizationModalAction({
                  communityIds: [],
                  missionStatement: "",
                  name: "",
                  organizationImageId: "",
                  tagIds: [],
                  adminIds: [],
                  profileImage: "",
                })
              );
            }}
            label={"Add Organization"}
          />
        )}
      </>
    );
  }
};

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
