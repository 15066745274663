import React from "react";
import NotFound from "view/components/NotFound";
import BackgroundImage from "assets/images/404.png";
import Head from "view/components/Head";
import AccessDenied from "view/components/AcessDenied";
const AcessDenied = () => {
    return (
        <div
            className="flex justify-between relative  w-full h-screen "
            style={{
                backgroundImage: `url('${BackgroundImage}')`,
                backgroundSize: "cover",
                backgroundPositionX: "center"
            }}
        >
            <Head
                title={`Access Denied`}
            />
            {/* <Head title="404 NOT FOUND" /> */}
            <div className="absolute left-1/2 transform -translate-x-1/2">
                <AccessDenied
                    title="Access Denied"
                    link="/"
                    btnText="Back to home page"
                />
            </div>
        </div>
    );
};

export default AcessDenied;
