import axios from "axios";
import { baseURL } from "config";
import Cookies from "universal-cookie";

async function fileUpload(id: any, files: File[]) {
  const cookies = new Cookies();

  /**
   * Function to get the authentication token from cookies
   */

  const getAuthToken = () => cookies.get("token") || "";
  const authToken = getAuthToken();
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("files[]", files[i]);
  }
  const res = await axios.post(
    `${baseURL}/admin/programs/${id}/files`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`, // Include the token in the 'Authorization' header
      },
    }
  );

  return res.data;
}

export default fileUpload;
