import React, { useState } from "react";
/************ hooks ************/
import UseRoles from "hooks/roles";
/************ Store Utils ************/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { useSelector } from "react-redux";
/************ Assets ************/
import LockClosedIcon from "assets/icons/HeroIcons/LockClosedIcon";
import UserCircleIcon from "assets/icons/HeroIcons/UserCircleIcon";
import ChatAlt2Icon from "assets/icons/HeroIcons/ChatAlt2Icon";
import CogIcon from "assets/icons/HeroIcons/CogIcon";
import KeyIcon from "assets/icons/HeroIcons/KeyIcon";
import LogoutIcon from "assets/icons/HeroIcons/LogoutIcon";
import MoonIcon from "assets/icons/HeroIcons/MoonIcon";
import SwitchUserIcon from "assets/icons/HeroIcons/SwitchUserIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
/************ External Library ************/
import { useLocation } from "react-router-dom";
import { openSignOutModal } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import usePermissions from "hooks/usePermissions";
import { IAuthResponseData } from "store/auth/initialState";

interface IProps {
  icon: JSX.Element;
  url: string;
  label: string;
  visible: boolean;
  type: "link" | "button";
  onClick?: () => void;
}
const useProfileLinks = () => {
  const [activeLink, setActiveLink] = useState("person");
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { users, profile } = usePermissions();
  const auth = useSelector<RootState, IAuthResponseData>(
    (state) => state?.auth
  );

  const dispatch = useDispatch();

  const location = useLocation();
  const currentUrl = location.pathname;
  const { isMyProfile } = UseRoles();

  const linkData: IProps[] = [
    {
      icon: <UserCircleIcon />,
      url: `/profile/${userProfile.personId}/person`,
      label: "Person",
      visible: users.canViewUsersProfile,
      type: "link",
    },
    {
      icon: <KeyIcon />,
      url: `/profile/${userProfile.personId}/account`,
      label: "Account",
      visible: users.canViewUsersProfile,
      type: "link",
    },
    {
      icon: <LockClosedIcon />,
      url: `/profile/${userProfile.personId}/changePassword`,
      label: "Change Password",
      visible: isMyProfile(userProfile.personId),
      type: "link",
    },
    {
      icon: <ChatAlt2Icon />,
      url: `/profile/${userProfile.personId}/notifications`,
      label: "Notifications",
      visible: isMyProfile(userProfile.personId) && users.canViewNotification,
      type: "link",
    },
    {
      icon: <SwitchUserIcon />,
      url: `/profile/${userProfile.personId}/roles`,
      label: "Roles",
      visible: profile.canViewRolesProfile,
      type: "link",
    },
    {
      icon: <CogIcon />,
      url: `/profile/${userProfile.personId}/settings`,
      label: "Settings",
      visible: users.canViewSettings,
      type: "link",
    },
    // {
    //   icon: <MoonIcon />,
    //   url: `/profile`,
    //   label: "Dark Theme",
    //   visible: isMyProfile(userProfile.personId),
    //   type: "link",
    // },
    {
      icon: <LogoutIcon />,
      url: `/profile/${userProfile.personId}/deleteAccount`,
      label: "Sign Out",
      visible: isMyProfile(userProfile.personId),
      type: "button",
      onClick: () => {
        dispatch(openSignOutModal());
      },
    },
    {
      icon: <TrashIcon />,
      url: `/profile/${userProfile.personId}/deleteAccount`,
      label: "Delete Account",
      visible: !isMyProfile(userProfile.personId),
      type: "link",
    },
  ];

  /**
   * Handles the click event on a link, updating the active link.
   * @param {string} linkName - The name of the link that was clicked.
   */
  const handleLinkClick = (linkName: string) => {
    setActiveLink(linkName);
  };

  const visibleLinks = linkData
    .filter((link) => link.visible)
    .map((item: any) => {
      return {
        ...item,
        isActive: item.url === currentUrl,
      };
    });

  return {
    activeLink,
    visibleLinks,
    handleLinkClick,
  };
};

export default useProfileLinks;
