import React from "react";
import { Filters, Header, Table } from "Components/NotificationStatus";
import { NotificationsStatusTable } from "hooks/useTable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { useParams } from "react-router-dom";
import { fetchNotificationsStatusAction } from "store/notificationsStatus";
import { IfetchNotificationsStatus } from "store/notificationsStatus/initialState";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";

export const NotificationStatus = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { notificationId } = useParams();
  const { data, stateIs } = useSelector<RootState, IfetchNotificationsStatus>(
    (state) => state?.notificationStatus
  );
  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnsConfig,
    selectedRows,
    table,
    setSorting,
    sorting,
  } = NotificationsStatusTable(data?.reminders);

  useEffect(() => {
    dispatch(fetchNotificationsStatusAction(notificationId));
  }, []);

  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 mt-5">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters columns={columnsConfig} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs === "Idle" && data?.reminders?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationStatus;
