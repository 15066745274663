import axios from "axios";
import { baseURL } from "config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

/**
 * Function to get the authentication token from cookies
 */
const getAuthToken = () => cookies.get("token") || "";

async function surveyFileUpload(
  file: File,
  isGeoJson: boolean = false,
  fileStatus?: "public" | "private",
  geometryType: string = "",
  shapeType: string = ""
) {
  const formData = new FormData();
  formData.append("file", file);

  const params = {
    status: fileStatus,
    geometry_type: geometryType,
    shape_type: shapeType,
  };

  const res = await axios.post(`${baseURL}/survey-file`, formData, {
    params,
    headers: {
      "Content-Type": isGeoJson
        ? "application/geo+json"
        : "multipart/form-data",
      Accept: "application/json",

    },
  });

  return res.data;
}

export default surveyFileUpload;
