import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { RadioBtn } from "Components/RadioBtn";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import InputField from "view/components/InputField";
import CustomCheckbox from "view/components/CheckBox";
import { DayCheckbox } from "./DayCheckbox";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";
import { TIME_FORMATS } from "constants/timeFormats";
import { formatTheTime } from "utils/formatTheTime";

export const InputFields = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}: any) => {
  const formatTime = (time: string) => {
    return moment(time, TIME_FORMATS.HOURS_MINUTES).format(
      TIME_FORMATS.FULL_TIME
    );
  };

  const currentDate = moment().format(DATE_FORMATS.DEFAULT);
  const isToday = moment(values.startDate).isSame(currentDate, "day");

  const weekdays = [
    { name: "Mon", value: "mon" },
    { name: "Tue", value: "tue" },
    { name: "Wed", value: "wed" },
    { name: "Thu", value: "thu" },
    { name: "Fri", value: "fri" },
    { name: "Sat", value: "sat" },
    { name: "Sun", value: "sun" },
  ];

  const handleDayCheckboxChange = (dayValue: any) => {
    const updatedValues = values.occurrence.repeatOn.includes(dayValue)
      ? values.occurrence.repeatOn.filter((value: any) => value !== dayValue)
      : [...values.occurrence.repeatOn, dayValue];

    setFieldValue("occurrence.repeatOn", updatedValues);
    setFieldTouched("occurrence.repeatOn", false);
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="mb-2">
        <p className="text-sm text-textMidLight font-Overpass dark:text-textMain">
          Indicate the times on which this notification should be sent. You can
          create a one-time notification or one that repeats.
        </p>
      </div>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={values?.occurrence?.now ? "now" : "pickDate"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue("occurrence.now", event.target.value === "now");
        }}
      >
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
          <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
            <div className="flex items-center justify-start flex-grow py-2">
              <div className="relative flex items-center justify-start flex-grow">
                <p className="text-base font-semibold text-left text-textMid dark:text-textMain">
                  When would you like to send the notification?
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-2 rounded-[100px]">
                  <RadioBtn
                    value="now"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
                            <p className="text-sm text-left text-textMid dark:text-textMain">
                              Now
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-2 rounded-[100px]">
                  <RadioBtn
                    value="pickDate"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 ">
                            <p className="text-sm text-left text-textMid dark:text-textMain">
                              Pick date
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </RadioGroup>

      {!values?.occurrence?.now && (
        <>
          <div className="flex items-center w-full gap-4 mt-2">
            <div className="w-[360px] mt-[3px]">
              <InputField
                label=""
                type="date"
                name="occurrence.startDate"
                onChange={(values: any) =>
                  setFieldValue("occurrence.startDate", values)
                }
                onBlur={handleBlur}
                value={values?.occurrence?.startDate}
                error={errors?.occurrence?.startDate}
                touched={true}
                inputClassName="uppercase"
                className={"w-full py-[2px]"}
                min={currentDate}
              />
            </div>

            <div className="w-[360px]">
              <TextInput
                label=""
                type="time"
                name="occurrence.startTime"
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const formattedTime = formatTheTime(
                    event.target.value,
                    TIME_FORMATS.FULL_TIME,
                    { utc: false }
                  );
                  console.log("Formatted time is: ", formattedTime);
                  setFieldValue("occurrence.startTime", formattedTime);
                }}
                handleBlur={handleBlur}
                value={values?.occurrence?.startTime}
                error={errors?.occurrence?.startTime}
                touched={true}
                inputClassName="uppercase"
                min={isToday && moment().format(TIME_FORMATS.HOURS_MINUTES)}
              />
            </div>
          </div>
          <div className="mt-2 mb-3">
            <CustomCheckbox
              name="occurrence.repeat"
              checked={values?.occurrence?.repeat}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("occurrence.repeat", event.target.checked);
              }}
              label={
                <p className="text-secondaryMid font-Overpass dark:text-textMain">
                  Repeat
                </p>
              }
              labelPlacement="end"
            />
          </div>

          {values?.occurrence?.repeat && (
            <>
              <div className="flex w-full gap-4 mb-2">
                <div className="w-[360px] ">
                  <TextInput
                    label="Every"
                    type="number"
                    name="occurrence.every"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values?.occurrence?.every}
                    error={errors?.occurrence?.every}
                    touched={touched?.occurrence?.every}
                  />{" "}
                </div>

                <div className="w-[360px] mt-5">
                  <TextInput
                    fieldAs="select"
                    label=" "
                    type="select"
                    name="occurrence.repeatUnit"
                    data={["Days", "Weeks", "Months", "Years"]}
                    handleChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      const lowerCase = event.target.value.toLocaleLowerCase();
                      setFieldValue("occurrence.repeatUnit", lowerCase);
                    }}
                    handleBlur={handleBlur}
                    value={values?.occurrence?.repeatUnit}
                    error={errors?.occurrence?.repeatUnit}
                    touched={true}
                  />{" "}
                </div>
              </div>
              <div className="mb-2">
                <p className="text-secondaryMid font-Overpass dark:text-textMain">
                  Repeat on
                </p>
                <div className="flex flex-wrap ml-3">
                  {weekdays?.map((day) => (
                    <div className="py-1 pr-1">
                      <DayCheckbox
                        day={day}
                        checked={values?.occurrence?.repeatOn?.includes(
                          day.value
                        )}
                        handleChange={() => handleDayCheckboxChange(day.value)}
                      />
                    </div>
                  ))}
                </div>

                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                  {errors?.occurrence?.repeatOn && (
                    <p className="flex-grow text-xs text-left text-accent_1Dark">
                      {errors?.occurrence?.repeatOn}
                    </p>
                  )}
                </div>
              </div>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={values.occurrence.endsAfterTimes ? "after" : "on"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const selectedValue = e.target.value;
                  if (selectedValue === "on") {
                    setFieldValue("occurrence.endsAfterTimes", "");
                    setFieldValue("occurrence.endsOnDateIsSelected", true);
                    setFieldValue("occurrence.endsAfterTimesIsSelected", false);
                  } else if (selectedValue === "after") {
                    setFieldValue("occurrence.endsOnDate", "");
                    setFieldValue("occurrence.endsOnDateIsSelected", false);
                    setFieldValue("occurrence.endsAfterTimesIsSelected", true);
                  }
                }}
              >
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
                    <div className="flex items-center justify-start flex-grow">
                      <div className="relative flex items-center justify-start flex-grow">
                        <p className="text-base font-semibold text-left text-textMid dark:text-textMain">
                          Ends
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                        <div className="flex  justify-center items-center px-2 rounded-[100px]">
                          <RadioBtn
                            value="on"
                            labelContent={
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#2C3236",
                                }}
                              >
                                <div className="flex items-center w-full pl-2">
                                  <p className="text-sm text-left capitalize text-textMid dark:text-textMain">
                                    On
                                  </p>
                                  <div className="w-[296px] ml-12">
                                    <InputField
                                      label=""
                                      type="date"
                                      name="occurrence.endsOnDate"
                                      onChange={(values: any) =>
                                        setFieldValue(
                                          "occurrence.endsOnDate",
                                          values
                                        )
                                      }
                                      onBlur={handleBlur}
                                      value={values?.occurrence?.endsOnDate}
                                      error={errors?.occurrence?.endsOnDate}
                                      className={"w-full"}
                                      touched={true}
                                      min={
                                        values.occurrence.startDate !== ""
                                          ? values.occurrence.startDate
                                          : currentDate
                                      }
                                      inputClassName="uppercase"
                                      disabled={
                                        values.occurrence
                                          .endsAfterTimesIsSelected
                                      }
                                    />
                                  </div>
                                </div>
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                        <div className="flex justify-center items-center px-2 rounded-[100px]">
                          <RadioBtn
                            value="after"
                            labelContent={
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#2C3236",
                                }}
                              >
                                <div className="flex items-center justify-start w-full pl-2">
                                  <p className="text-sm text-left textMid dark:text-textMain">
                                    After
                                  </p>

                                  <div className="w-[296px] ml-9 mr-3">
                                    <TextInput
                                      label=""
                                      type="number"
                                      name="occurrence.endsAfterTimes"
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values?.occurrence?.endsAfterTimes}
                                      error={errors?.occurrence?.endsAfterTimes}
                                      touched={true}
                                      disabled={
                                        values?.occurrence?.endsOnDateIsSelected
                                      }
                                    />{" "}
                                  </div>
                                  <p className="text-sm text-left textMid dark:text-textMain">
                                    times
                                  </p>
                                </div>
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </>
          )}
        </>
      )}
    </div>
  );
};
