import { useDispatch } from "react-redux";
import { ViewLayerDetail } from "../ViewLayerDetail";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import {
  openAllLayersListingScreen,
  openEditRasterLayerScreen,
} from "store/geography";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Button from "view/components/Button";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import Head from "view/components/Head";

const ViewRasterLayerDetail = () => {
  const dispatch = useDispatch();
  const { viewRasterLayerData: data } = useSelector(
    (state: RootState) => state.geography
  );
  const { communities } = usePermissions();

  // remove and structure data for detail view
  const detailData: any = {
    "Layer name": data?.name,
    URL: data?.mapData?.snippet,
    description: data?.description ?? "",

  };
  // convert object to array 
  const convertDataToArrayForm = Object.keys(detailData).map((key: string) => [
    key,
    detailData[key],
  ]);

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };
  const handleClickOnEditButton = () => {
    // open edit reaster layer screen
    dispatch(openEditRasterLayerScreen(data));
  };

  return (
    <div>
      <Head title="Geography: Raster Layer Details" />
      <Breadcrumb
        label="Raster Layer Details"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <ViewLayerDetail data={convertDataToArrayForm} />
      <div className="flex justify-end gap-2 mt-8">
        <Button
          type="reset"
          text="Cancel"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="px-5 py-2 w-[48.5%]"
          width="[48.t%]"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={goBackToAllLayersScreen}
        />
        <Restricted permissions={communities} requiredPermission="canEditGeographyCommunities">
          <Button
           disabled={data.isPublic ?? false}
            text="Edit"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2 w-[48.5%]"
            width="[48.5%]"
            height="13"
            fontStyle="font-semibold"
            variant="filled"
            onClick={handleClickOnEditButton}
          />
        </Restricted>
      </div>
    </div>
  );
};

export { ViewRasterLayerDetail };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">{label}</span>
    </button>
  );
};