import React, { useState } from "react";
import { ObservationDurationConstants } from "constants/observationDurationConstants";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from "@mui/material";
import ToggleIcon from "./ToggleIcon";

interface DurationProps {
  name?: string;
  toggleDuration: (props: any) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function getStyles(theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Overpass",
    fontSize: "14px",
    color: "#7E7E7E",
  };
}

export const DurationFilter: React.FC<DurationProps> = (props) => {
  const theme = useTheme();
  const [duration, setDuration] = React.useState<string>(
    ObservationDurationConstants.PER_YEAR.VALUE
  );

  const handleChange = (event: SelectChangeEvent<typeof duration>) => {
    const {
      target: { value },
    } = event;
    setDuration(value);
    props.toggleDuration(value);
  };

  return (
    <Select
      className="my-custom-select"
      displayEmpty
      value={duration}
      onChange={handleChange}
      MenuProps={MenuProps}
      inputProps={{
        // className: "my-custom-select",
        "aria-label": "Without label",
      }}
      IconComponent={() => (
        <InputAdornment position="start">
          <ToggleIcon fontSize="small" /> {/* Customize the icon */}
        </InputAdornment>
      )}
      sx={{
        width: "125px",
        outline: "none",
        ml: "auto",
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        ".MuiOutlinedInput-input": {
          borderRadius: "10px",
          textAlign: "left",
          // bgcolor: "#F9FBFF",
          p: 0,
          py: "10px",
          pl: "16px",
          // color: "#7E7E7E",
          fontFamily: "Overpass",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          letterSpacing: "-0.12px",
          textTransform: "capitalize",
        },
      }}
    >
      {Object.values(ObservationDurationConstants).map((item) => (
        <MenuItem key={item.VALUE} value={item.VALUE} style={getStyles(theme)}>
          {item.LABEL}
        </MenuItem>
      ))}
    </Select>
  );
};
