import { generatingLayerId } from "../utils";
import {
  __drawShapeByGeoJsonOnMap__,
  __paintPointsByGeoJsonOnMap__,
  __paintShapeByGeoJsonOnMap__,
} from "../workerMehods";
import { convertGeoJSON } from "utils/convertFeatureCollection";
import { handleCreationOfMarkerOnMap } from "./handleCreationOfMarkerOnMap";
import mapboxgl from "mapbox-gl";
function handlePaintingOfVectorRegionShapeLayersOnMap(
  item: any,
  map: any,
  draw: any,
  defaultColor: any,
  cb?: CallableFunction
) {
  const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);
  const geoJson = convertGeoJSON(item.item?.mapData) ?? item.item?.geoFilePath;
  // stop the layer painting if its already painted
  if (map.getLayer(layerId)) {
    if (item.itemType === "point") {
      map.setLayoutProperty(layerId, 'icon-image', "custom-marker-1");
    } else {
      map.setPaintProperty(layerId, "fill-color", defaultColor);
    }
  }
  else {
    // if layer item is unchecked or it doesn't has geojson data then blocks its painting on map
    if (item.status === false || !geoJson) {
      return;
    }

    // register click event listener on layer
    if (cb) {
      map.on("click", layerId, () => cb(item));
    }

    __paintShapeByGeoJsonOnMap__(
      map,
      draw,
      sourceId,
      layerId,
      geoJson,
      defaultColor,
    );

    // if (item.item.type === "point") {
    //   __paintPointsByGeoJsonOnMap__(
    //     map,
    //     sourceId,
    //     layerId,
    //     geoJson,
    //   );
    // } else {
    //   // paint the layer
    //   __paintShapeByGeoJsonOnMap__(
    //     map,
    //     draw,
    //     sourceId,
    //     layerId,
    //     geoJson,
    //     defaultColor
    //   );
    // }

  }
}

export { handlePaintingOfVectorRegionShapeLayersOnMap };
