import { Box, Modal } from "@mui/material";
import Button from "view/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  closeDashboardAlertConfirmationModal,
  closeDashboardAlertModal,
} from "store/modals/reducer.actions";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 480,
  bgcolor: "background.paper",
};

const dashboardAlertConfirmationModal = () => {
  const { dashboardAlertConfirmationModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch = useDispatch();

  const removeAlertFunction = () => {
    dispatch(closeDashboardAlertModal());
  };

  const handleClose = () => {
    dispatch(closeDashboardAlertConfirmationModal());
  };

  return (
    <>
      <Modal
        open={dashboardAlertConfirmationModal.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark:bg-secondaryLight ">
          <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="flex  justify-between pb-2 items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                    Dashboard Configuration
                  </p>
                </div>
                <button onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>
              <div>
                <p className="w-[410px] text-sm text-left text-secondaryMid dark:text-textMain">
                  <span className="text-sm text-left break-all text-secondaryMid dark:text-caption">
                    Do you want to hide this permanently?
                  </span>
                </p>
              </div>

              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="No"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />
                <Button
                  type="button"
                  text="Yes"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={removeAlertFunction}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default dashboardAlertConfirmationModal;
