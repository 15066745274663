import { useEffect, useState } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "../handlers";

const usePaintAllLayersOnMap = (
  reportGeographies: any,
  selectedGeographies: any,
  map: any,
  isMapFullyLoaded: any,
  LAYER_SELECTED_COLOR: any,
  LAYER_DEFAULT_COLOR: any
) => {
  const [clickedLayer, setClickedLayer] = useState<any>(null);

  useEffect(() => {
    if (
      reportGeographies &&
      reportGeographies.length > 0 &&
      map &&
      isMapFullyLoaded
    ) {
      // console.table({
      //   selectedGeographies,
      //   reportGeographies
      // })
      reportGeographies.forEach((layer: any) => {
        const item = { item: layer, itemType: "shapepicker", status: true };



        const isItemHighlighted = selectedGeographies?.find(
          (answerLayer: any) => {
            return answerLayer.id == item.item.id;
          }
        );

        // paint layers on map
        handlePaintingOfVectorRegionShapeLayersOnMap(
          item,
          map,
          {},
          isItemHighlighted ? LAYER_SELECTED_COLOR : LAYER_DEFAULT_COLOR,
          (layerItem: any) => setClickedLayer(layerItem)
        );
      });
    }
  }, [map, isMapFullyLoaded, selectedGeographies, reportGeographies]);

  return [clickedLayer, setClickedLayer];
};

export { usePaintAllLayersOnMap };
