import getAllProtocols from "./getAllProtocols";
import getProtocolProfile from "./getProtocolProfile";
import createProtocol from "./createProtocol";
import editProtocol from "./editProtocol";
import duplicateProtocol from "./duplicateProtocol";
import getAllPrograms from "./getAllPrograms";
import getFormsByPrograms from "./getFormsByPrograms";
import connectProtocol from "./connectProtocol";
import disconnectProtocol from "./disconnectProtocol";
import changeProtocolStatus from "./changeProtocolStatus";
import downloadProtocols from "./downloadProtocols";



const Protocols = {
  getAllProtocols,
  getProtocolProfile,
  createProtocol,
  editProtocol,
  duplicateProtocol,
  getAllPrograms,
  getFormsByPrograms,
  connectProtocol,
  disconnectProtocol,
  changeProtocolStatus,
  downloadProtocols,
};

export default Protocols;
