import { SurveyQuestionElementBase } from "survey-react-ui";

import Map from "./Map";

export const shapePickerProperties = [
  {
    name: "_id",
    category: "general",
    visibleIndex: 2,
  },
];
export default class SurveyShapePickerQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = {
      value: this.question.value,
    };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderElement() {
    //@ts-ignore
    return <Map questionId={this.question._id} name={this.question.name} />
  }
}
