// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// APIs services
import apiLibrary from "services/api";
// Assets
import uploadIcon from "assets/icons/upload.svg";
// Store utils
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  displayRasterLayerOnMap,
  openAllLayersListingScreen,
  resetMap,
} from "store/geography";
// Third party services
import Dropzone from "react-dropzone";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
// Icons
import { ArrowLeftIcon, UploadIcon } from "assets/icons/HeroIcons";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, Typography } from "@mui/material";
import Head from "view/components/Head";
import usePermissions from "hooks/usePermissions";

// Types
interface I_CreateRasterLayer {
  setActiveScreen: Dispatch<string>;
}
const MAXIMUM_FILE_SIZE = 200; // kbs

// Schema
const rasterLayerSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "255 max characters")
    .required("Layer name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(255, "255 max characters"),
  legendFileId: Yup.string(),
  type: Yup.string().required("Type is required"),
  mapData: Yup.object().shape({
    snippet: Yup.string().required("Url is required"),
    // x: Yup.number(),
    // y: Yup.number(),
    // z: Yup.number(),
  }),
});

const CreateRasterLayer = () => {
  const dispatch = useDispatch();
  const {communities} = usePermissions();
  const { communityId } = useParams();
  
  const [MapData, setMapData] = useState<any>({});

  const initialValues = {
    name: "",
    description: "",
    legendFileId: "",
    type: "raster",
    mapData: {
      snippet: "",
      // x: "",
      // y: "",
      // z: "",
    },
  };

  const CustomContent = (
    <div>
      <Typography variant="body1" className="font-Overpass">
        {`By providing a URL to a WMS server that supports EPSG:3857 (or EPSG:900913) as a source of tiled data. 
        The server URL should contain a "{bbox-epsg-3857}" replacement token to supply the bbox parameter.`}
      </Typography>
    </div>
  );

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };
  const handleOnChangeFields = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const { name, value } = e.target;
    setFieldValue("mapData." + name, value);
    setMapData({
      ...MapData,
      mapData: {
        ...MapData.mapData,
        [name]: value,
      },
    });
  };
  const handleFileUpload = async (
    files: any,
    setFieldValue: any,
    setFieldError: any
  ) => {
    const file = files?.[0];
    if (file) {
      try {
        const fileSize = file.size / 1024; // convert bytes to kb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return setFieldError(
            "legendFileId",
            "File size must be less than or equal to 200kb!"
          );
        }

        const uploadResponse = await apiLibrary.file.fileUpload(
          file,
          false,
          "public"
        );

        setFieldValue("legendFileId", uploadResponse.data.id);
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      }
    }
  };
  const isGlobalRasterLayer = !!(!communityId || global);

  const handleSubmitForm: any = async (values: any, { setSubmitting }: any) => {
    try {
      // values.mapData.x = parseInt(values.mapData.x);
      // values.mapData.y = parseInt(values.mapData.y);
      // values.mapData.z = parseInt(values.mapData.z);

      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, values);
      } else {
        await apiLibrary.geography.createLayerForAdmin(values);
      }

      Toasts.success(`Raster layer created successfully`);
      dispatch(openAllLayersListingScreen());
    } catch (err: any) {
      Toasts.error(err.message);
    } finally {
      setSubmitting(false); // Assuming you want to set this to false once the submission is complete
    }
  };
  const handleClickOnPreview = () => {
    if (!MapData?.mapData?.snippet) {
      return;
    }

    dispatch(
      displayRasterLayerOnMap({ ...MapData, id: Date.now(), type: "raster" })
    );
  };

  return (
    <div>
      <Head title="Geography: Add Raster Layer" />
      <Breadcrumb
        label="Add Raster Layer"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={rasterLayerSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => (
          <Form>
            <div className="max-h-[70vh] overflow-y-auto mb-4 px-2">
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label="Layer Name*"
                  type="text"
                  placeholder="Layer name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors?.name}
                  touched={touched?.name}
                />
              </div>
              <div className="w-full mb-4">
                <TextInput
                  label="Description*"
                  type="text"
                  rows={3}
                  fieldAs="textarea"
                  placeholder="Description"
                  name="description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  error={errors?.description}
                  touched={touched?.description}
                />
                {!errors?.description && (
                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                    255 max characters
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label={
                    <div className="flex  items-center w-full">
                      <span>Snippet*</span>
                      <Tooltip
                        title={CustomContent}
                        className="cursor-pointer font-Overpass"
                      >
                        <InformationCircleIcon
                          width={20}
                          className=" mb-[3px] ml-1"
                        />
                      </Tooltip>
                    </div>
                  }
                  type="url"
                  placeholder="Snippet"
                  name="snippet"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeFields(e, setFieldValue)
                  }
                  handleBlur={handleBlur}
                  value={values.mapData.snippet}
                  error={errors?.mapData?.snippet}
                  touched={touched.mapData?.snippet}
                />
              </div>
              <div className="flex items-center gap-2">
                {/* <div className="flex flex-col items-start justify-start w-1/4">
                <TextInput
                  max={4194303}
                  min={0}
                  label="X"
                  type="number"
                  placeholder="Type"
                  name="x"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeFields(e, setFieldValue)
                  }
                  handleBlur={handleBlur}
                  value={values.mapData.x}
                  error={errors?.mapData?.x}
                  touched={touched.mapData?.x}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-1/4">
                <TextInput
                  max={4194303}
                  min={0}
                  label="Y"
                  type="number"
                  placeholder="Type"
                  name="y"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeFields(e, setFieldValue)
                  }
                  handleBlur={handleBlur}
                  value={values.mapData.y}
                  error={errors?.mapData?.y}
                  touched={touched.mapData?.y}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-1/4">
                <TextInput
                  max={22}
                  min={0}
                  label="Z"
                  type="number"
                  placeholder="Type"
                  name="z"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeFields(e, setFieldValue)
                  }
                  handleBlur={handleBlur}
                  value={values.mapData.z}
                  error={errors?.mapData?.z}
                  touched={touched.mapData?.z}
                />
              </div> */}
                <div className="flex flex-col items-center justify-start w-1/4">
                  <Button
                    type="button"
                    text="Preview"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClickOnPreview}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start mb-6">
                <FileUpload
                  ACCEPTED_FILES_TYPES={{
                    "image/jpeg": [".jpeg", ".jpg", ".png"],
                  }}
                  handleOnFileUpload={(files: any) =>
                    handleFileUpload(files, setFieldValue, setFieldError)
                  }
                  error={errors?.legendFileId}
                  touched={touched?.legendFileId}
                />
              </div>
            </div>

            <div className="flex justify-between gap-2">
              <Button
                type="reset"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.t%]"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={goBackToAllLayersScreen}
              />
              <Button
                type="submit"
                text="Save"
                disabled={isSubmitting || (!isGlobalRasterLayer && !communities.canCreateGeographyCommunities)}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { CreateRasterLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};

const FileUpload = ({ handleOnFileUpload, error, touched }: any) => {
  return (
    <div className="w-full py-2">
      <label className="pb-1 text-sm text-textMid dark:text-caption">
        Legend
      </label>
      <Dropzone
        accept={{
          "image/jpeg": [".jpeg", ".jpg", ".png"],
        }}
        onDrop={handleOnFileUpload}
        multiple={false}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          const fileName =
            acceptedFiles && acceptedFiles.length > 0
              ? acceptedFiles[0]?.name
              : "Upload File";

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${error && touched
                    ? "border-accent_1Dark"
                    : "border-lineDark dark:border-lineLight"
                  } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p className="text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain">
                  {fileName}
                </p>
                <UploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {error ? (
        <p className="flex-grow w-[1/2] capitalize text-xs text-left text-accent_1Dark">
          {error}
        </p>
      ) : (
        <p className="flex-grow pt-1 text-xs text-left text-textMidLight dark:text-textMain">
          Use an image that’s at least 200 x 200 pixels and 200KB or less.
        </p>
      )}
    </div>
  );
};
