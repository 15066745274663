import {panelsTitle,panelsName} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";
import { Serializer } from "survey-core";
import questionTypes from "../questionTypes";

const QUESTIONS_NAME = {
  Region: "Region",
  View: "View",
  VantagePoint: "VantagePoint",
  LocationNote: "LocationNote",
};

export default {
  name: panelsName.GEOGRAPHICAL_INFORMATION,
  title: panelsTitle.GEOGRAPHICAL_INFO,
  iconName: "icon-geographical",
  elementsJSON: [
    // {
    //   type: "html",
    //   html: `<h1 class="panels-title">${panelsTitle.GEOGRAPHICAL_INFO}</h1>`,
    // },
    {
      type: questionTypes.shapePicker,
      name: QUESTIONS_NAME.Region,
      title: "Region",
      isRequired: true,
    },
    {
      type: "html",
      name:QUESTIONS_NAME.Region+"Type",
      html: `<h2 class="map-sub-heading">Shape picker</h2>`,
    },
    {
      type: questionTypes.shapePicker,
      name: QUESTIONS_NAME.View,
      title: "View",
      isRequired: true,
    },
    {
      type: "html",
      name:QUESTIONS_NAME.View+"Type",
      html: `<h2 class="map-sub-heading">Shape picker</h2>`,
    },
    {
      type: questionTypes.pointPicker,
      name: QUESTIONS_NAME.VantagePoint,
      title: "Vantage Point",
      isRequired: true,
    },
    {
      type: "html",
      name:QUESTIONS_NAME.VantagePoint+"Type",
      html: `<h2 class="map-sub-heading">Point picker</h2>`,
    },
    {
      type: "comment",
      name: QUESTIONS_NAME.LocationNote,
      title: "Location Note",
      placeholder: "Location Note",
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.GEOGRAPHICAL_INFORMATION, QUESTIONS_NAME, Serializer),
};

export const geographicalIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5257 3.14935C21.8205 3.33156 22 3.65342 22 4V14C22 14.5523 21.5523 15 21 15C20.4477 15 20 14.5523 20 14V5.61804L16 7.61803V12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12V7.61803L10 5.61804V16.382L11.4472 17.1056C11.9412 17.3526 12.1414 17.9532 11.8944 18.4472C11.6474 18.9412 11.0468 19.1414 10.5528 18.8944L9 18.118L3.44721 20.8944C3.13723 21.0494 2.76909 21.0329 2.47427 20.8507C2.17945 20.6684 2 20.3466 2 20V7C2 6.62123 2.214 6.27497 2.55279 6.10557L8.53479 3.11457C8.67376 3.04141 8.83204 3 9 3C9.16796 3 9.32624 3.04141 9.46521 3.11457L15 5.88197L20.5528 3.10557C20.8628 2.95058 21.2309 2.96714 21.5257 3.14935ZM8 16.382V5.61804L4 7.61804V18.382L8 16.382Z" fill="#909090"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13 17.5C13 15.567 14.567 14 16.5 14C18.433 14 20 15.567 20 17.5C20 18.1028 19.8476 18.6699 19.5793 19.1651L21.7071 21.2929C22.0976 21.6834 22.0976 22.3166 21.7071 22.7071C21.3166 23.0976 20.6834 23.0976 20.2929 22.7071L18.1651 20.5793C17.6699 20.8476 17.1028 21 16.5 21C14.567 21 13 19.433 13 17.5ZM16.5 16C15.6716 16 15 16.6716 15 17.5C15 18.3284 15.6716 19 16.5 19C17.3284 19 18 18.3284 18 17.5C18 16.6716 17.3284 16 16.5 16Z" fill="#909090"/>
</svg>`;
