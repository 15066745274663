import React, { useCallback, useLayoutEffect } from "react";
import Roles from "hooks/roles";
/***** API services *****/
import apiLibrary from "services/api";
/***** Store utils *****/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { profileSettingsAction } from "store/userProfilesettings";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import { useDispatch, useSelector } from "react-redux";
import {
  CoordinateSystemSwitcher,
  DateFormatSelector,
  LanguageSelector,
  MeasurementUnitSwitcher,
  TempUnitSwitcher,
  DefaultTimeZoneSelector,
} from "Components/Profile/Settings/components";

export const Settings = () => {
  const dispatch = useDispatch();
  const { isMyProfile } = Roles();
  // Getting user profile from Redux state
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  // Function to fetch user profile settings
  const fetchUserProfileSettings = useCallback(
    async (personId: any | undefined) => {
      try {
        const res = await apiLibrary.Settings.userProfileSettings(
          personId !== undefined ? parseInt(personId) : undefined
        );
        const settings = convertKeysToCamelCase(res.data);
        // Updating Redux state with fetched settings
        if (isMyProfile(personId)) {
          dispatch(profileSettingsAction(settings));
        }
        dispatch(profileSettingsAction(settings));
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );
  // Fetching user profile settings on component mount
  useLayoutEffect(() => {
    fetchUserProfileSettings(userProfile.user.id);
  }, [fetchUserProfileSettings, userProfile.user.id]);

  const isProfileVisible = isMyProfile(userProfile.personId);

  return (
    <div className="w-full border-l dark:bg-secondaryLight border-l-lineMid dark:border-lineLight">
      <div className="w-full">
        <div className="pt-8 pl-8 xl:pl-12">
          <p className="text-lg font-semibold text-secondaryMid dark:text-textMain">
            Settings
          </p>
        </div>
        <div className="flex flex-col px-8 pt-4 xl:px-12">
          <div className="grid w-full gap-3 xl:grid-cols-2 lg:grid-cols-1">
            <LanguageSelector />
            {isProfileVisible && <DateFormatSelector />}
          </div>
          <div className="w-full">
          {isProfileVisible && <DefaultTimeZoneSelector />}
          </div>
          <div>
            {/* Radio buttons for Preferred unit of measurement */}
            <MeasurementUnitSwitcher />
            {/* Radio buttons for Preferred temperature measurement */}
            <TempUnitSwitcher />
            {/* Radio buttons for Preferred coordinate system */}
            <CoordinateSystemSwitcher />
          </div>
        </div>
      </div>
    </div>
  );
};
