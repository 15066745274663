import React, { useEffect, useState } from "react";
import { InputFields } from "./components/InputFields";
import { Step } from "store/addImportStepper/initialState";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import {
  moveToNextStepAction,
  moveToPreviousStepAction,
} from "store/addReportStepper/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { IImportSummaryData } from "store/importSummary/initialState";
import { RootState } from "store";
import { fetchImportSummaryAction } from "store/importSummary/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  InformationCircleIcon,
} from "assets/icons/HeroIcons";
import { fetchImportPropertyDropdownAction } from "store/addImport";
import { FetchPropertyDropdownData } from "store/addImport/initialState";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";
import { importsSummaryDataLimitAction } from "store/filters";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";
import usePermissions from "hooks/usePermissions";

interface ColumnMappingProps {
  activeStep: any;
  isLastStep: any;
  // steps: Step[];
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
}

// Define a type for dropdown values
type DropdownValues = Record<string, any>;

export const ColumnMapping: React.FC<ColumnMappingProps> = ({
  activeStep,
  isLastStep,
  // steps,
  moveToPreviousStep,
  moveToNextStep,
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IImportSummaryData>(
    (state) => state?.importSummary
  );

  const { importsSummaryDataFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const {
    data: { properties },
  } = useSelector<RootState, FetchPropertyDropdownData>(
    (state) => state?.importsPropertyDropdowns
  );
  const { imports } = usePermissions();

  const [dropdownValues, setDropdownValues] = useState<DropdownValues>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const importId = localStorage.getItem("importId");

  useEffect(() => {
    // Convert the import ID back to a number if necessary
    const id = Number(importId);

    // Ensure that there's an import ID before dispatching the action
    if (importId) {
      dispatch(importsSummaryDataLimitAction(1000));
      dispatch(
        fetchImportSummaryAction({
          id,
          path: "",
        })
      );
      if (imports.canViewPropertyDropdownImports) {
        dispatch(fetchImportPropertyDropdownAction(id));
      }
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const updatedColumnMapping = data?.columnMapping.map((column: any) => {
      const dropdownValue = dropdownValues[column.columnNameFromFile];

      const defaultValue = [...properties][0];
      delete dropdownValue?.value;
      delete defaultValue?.value;

      if (dropdownValue) {
        // If a matching dropdown value exists, update mapToField
        return {
          id: column.id,
          ...dropdownValue,
        };
      } else if (column.propertyType !== "hardcoded") {
        return {
          id: column.id,
          ...defaultValue,
        };
      } else {
        return column;
      }
    });

    const columnMappingData = {
      columnMapping: updatedColumnMapping,
    };

    try {
      setIsSubmitting(true);
      const { data } = await apiLibrary.Imports.updateColumnMapping(
        Number(importId),
        columnMappingData
      );
      moveToNextStep();
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };
  const removeUnderscores = (str: string | null): string | null => {
    return str ? str.replace(/_/g, " ") : null;
  };

  // Function to apply sentence casing to a string
  const sentenceCaseFormate = (str: string | null): string | null => {
    if (!str) return null;
    // Split the string into words, apply capitalization, and join back
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const isValidDate = (dateString: any) => {
    return dateString
      ? moment(dateString, moment.ISO_8601, true).isValid()
      : false;
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-start gap-2 mb-3 info-message">
        <InformationCircleIcon className="w-5 h-5 fill-accent_3 mt-1.5" />
        <div className="flex justify-start items-center flex-grow relative py-0.5">
          <p className="w-auto text-[14px] text-left text-textMidLight font-Overpass mt-1 dark:text-caption ">
            Select what columns from your file to map against fields in the
            system, be aware that the observation code will always be
            automatically generated by the system. You can also ignore columns
            by selecting 'do not import'.
          </p>
        </div>
      </div>

      <div className="grid w-full grid-cols-2 mt-8 mb-4 gap-x-4">
        <div className="flex w-full col-span-2">
          <h6 className="w-1/2 mb-4 text-lg font-semibold capitalize font-Overpass text-textMid dark:text-textMain">
            Column name from file / Sample file content
          </h6>

          <h6 className="w-1/2 mb-2 text-lg font-semibold capitalize font-Overpass text-textMid dark:text-caption">
            Map to field
          </h6>
        </div>

        {data?.columnMapping &&
          data.columnMapping.map((column: any, index: number) => {
            const isHardcoded = column.propertyType === "hardcoded";
            return (
              <div key={index} className="flex flex-col w-full col-span-2">
                <div className="flex w-full">
                  {/* First Half */}
                  <div className="flex flex-col w-1/2">
                    <p className="text-sm text-textMidLight font-Overpass dark:text-caption">
                      {typeof column.columnNameFromFile === "string" &&
                      column.columnNameFromFile
                        ? sentenceCase(column.columnNameFromFile)
                        : column.columnNameFromFile}
                    </p>
                    <p className="text-md text-secondary font-Overpass dark:text-textMain">
                      {typeof column.sampleFileContent === "string" &&
                      column.sampleFileContent
                        ? !isValidDate(column.sampleFileContent)
                          ? sentenceCaseFormate(
                              removeUnderscores(column.sampleFileContent)
                            )
                          : moment
                              .utc(column.sampleFileContent)
                              .format("DD MMM YYYY, hh:mm A")
                        : ""}
                    </p>
                  </div>

                  {/* Second Half */}
                  <div className="flex flex-col w-1/2">
                    <InputFields
                      key={column.columnNameFromFile}
                      name={column.columnNameFromFile}
                      value={
                        isHardcoded
                          ? column.mapToField
                          : dropdownValues[column.columnNameFromFile]
                      }
                      handleChange={(value: any) => {
                        setDropdownValues((prevState) => ({
                          ...prevState,
                          [column.columnNameFromFile]: value,
                        }));
                      }}
                      options={properties}
                      isDisabled={isHardcoded}
                      placeholder={
                        isHardcoded
                          ? sentenceCase(column.mapToField)
                          : "Select Field"
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <SubmissionButtons
        isSubmitting={isSubmitting}
        handleGoBackBtn={() => moveToPreviousStep()}
      />
    </form>
  );
};

const SubmissionButtons = ({ handleGoBackBtn, isSubmitting }: any) => {
  const { imports } = usePermissions();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleGoBackBtn}
        icon={
          <ArrowLeftIcon
            width={18}
            className="text-primary stroke-primary mt-[2px] dark:stroke-textWhite"
          />
        }
        iconPosition="before"
      />
      <Button
        disabled={isSubmitting || !imports.canEditColumnMappingImports}
        type="submit"
        text="Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        icon={
          <ArrowRightIcon
            width={18}
            className="text-primary stroke-primary mt-[2px] dark:stroke-textWhite"
          />
        }
        iconPosition="after"
      />
    </div>
  );
};
