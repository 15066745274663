import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";
import { Serializer } from "survey-core";
import { WEATHER_CONDITIONS_QUESTIONS_NAME } from "../panelsQuestionsName";
import { WEATHER_CONDITIONS_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

export default {
  name: panelsName.WEATHER_CONDITION,
  title: panelsTitle.WEATHER_CONDITIONS,
  iconName: "icon-weather",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.WEATHER_CONDITIONS}</h1>`,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.WeatherMethod,
      title: "Weather Method",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.WeatherMethod,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.WindSpeed,
      title: "Wind Speed",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.WindSpeed,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.WindDirection,
      title: "Wind Direction",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.WindDirection,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.SeaState,
      title: "Sea State",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.SeaState,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.Tide,
      title: "Tide",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.Tide,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.AirTemperature,
      title: "Air Temperature",
      placeholder: "42",
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.AirTemperatureUnit,
      title: "Temperature Unit",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.AirTemperatureUnit,
      isRequired: true,
      // titleLocation: "hidden", 
      startWithNewLine: false,
    },
    {
      type: "tagbox",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.WeatherCondition,
      title: "Weather Condition",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.WeatherCondition,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: WEATHER_CONDITIONS_QUESTIONS_NAME.HorizonVisibility,
      title: "Horizon Visibility",
      choices: WEATHER_CONDITIONS_QUESTIONS_CHOICES.HorizonVisibility,
      isRequired: true,
    },
  ],

  ...panelSettings(
    panelsName.WEATHER_CONDITION,
    WEATHER_CONDITIONS_QUESTIONS_NAME,
    Serializer
  ),
};

export const weatherIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2ZM4.92893 4.92893C5.31946 4.53841 5.95262 4.53841 6.34315 4.92893L7.05025 5.63604C7.44078 6.02656 7.44078 6.65973 7.05025 7.05025C6.65973 7.44078 6.02656 7.44078 5.63604 7.05025L4.92893 6.34315C4.53841 5.95262 4.53841 5.31946 4.92893 4.92893ZM19.0711 4.92899C19.4616 5.31951 19.4616 5.95267 19.0711 6.3432L18.364 7.05031C17.9735 7.44083 17.3403 7.44083 16.9498 7.05031C16.5593 6.65978 16.5593 6.02662 16.9498 5.63609L17.6569 4.92899C18.0474 4.53846 18.6806 4.53846 19.0711 4.92899ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12ZM2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12ZM19 12C19 11.4477 19.4477 11 20 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12ZM16.9497 16.9497C17.3403 16.5592 17.9734 16.5592 18.364 16.9497L19.0711 17.6569C19.4616 18.0474 19.4616 18.6805 19.0711 19.0711C18.6805 19.4616 18.0474 19.4616 17.6569 19.0711L16.9497 18.364C16.5592 17.9734 16.5592 17.3403 16.9497 16.9497ZM5.63609 16.9498C6.02662 16.5593 6.65978 16.5593 7.05031 16.9498C7.44083 17.3403 7.44083 17.9735 7.05031 18.364L6.3432 19.0711C5.95267 19.4616 5.31951 19.4616 4.92898 19.0711C4.53846 18.6806 4.53846 18.0474 4.92899 17.6569L5.63609 16.9498ZM12 19C12.5523 19 13 19.4477 13 20V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V20C11 19.4477 11.4477 19 12 19Z" fill="#909090"/>
</svg>`;
