/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Radio } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closePollsAndSurveysExpiryDateModal } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { UserProfileState } from "store/userProfile/initialState";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "view/components/Button";
import FormInput from "view/pages/Auth/Components/Inputs/formInput";
import { Form, Formik, FormikHelpers } from "formik";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";
import InputField from "view/components/InputField";
import { pollsAndSurveysStatusAction } from "store/filters";
import moment from "moment";
import { getEndOfDayInLocalTimeZoneToUtc } from "utils/getMidnightOrLocalDayeEndTime";
import { formatTheDates } from "utils/formatTheDates";
import { DATE_FORMATS } from "constants/dateFormats";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface PollsAndSurveysExpiryDateModalProps {}
interface FormValues {
  tokenValidTill: string;
}

export const PollsAndSurveysExpiryDateModal: React.FC<
  PollsAndSurveysExpiryDateModalProps
> = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const {
    isOpen,
    data: { surveyAndPollsId, payload, tokenValidTill },
  } = useSelector(
    (state: RootState) => state.modals.pollsAndSurveysExpiryDateModal
  );

  const { timezone: userTimezone } = useSelector(
    (state: RootState) => state.userProfileSettings.profileSettings
  );

  const [initialValues, setInitialValues] = useState({
    tokenValidTill: tokenValidTill ?? "",
  });

  useEffect(() => {
    if (tokenValidTill) {
      setInitialValues({ tokenValidTill });
    }
  }, [tokenValidTill]);

  const handleClose = () => dispatch(closePollsAndSurveysExpiryDateModal());
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const pollsAndSurveysExpiryDateValidationSchema = Yup.object().shape({
    tokenValidTill: Yup.date()
      .min(
        moment()
          .tz(userTimezone ?? currentTimezone)
          .format("YYYY-MM-DD"),
        "Expiry date must be today or later"
      )
      .typeError("Invalid date format, please use a valid date"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    const selectedTimezone = userTimezone || currentTimezone;

    dispatch(setLoadingProgress(getRandomProgress()));

    const endOfTheDay = getEndOfDayInLocalTimeZoneToUtc(
      moment(values.tokenValidTill).format("YYYY-MM-DD"),
      selectedTimezone
    );

    try {
      const res = await apiLibrary.pollsAndSurveys.updateSurveyFields(
        surveyAndPollsId,
        {
          ...payload,
          token_valid_till: values.tokenValidTill === "" ? "" : endOfTheDay,
        }
      );

      dispatch(pollsAndSurveysStatusAction(payload.status));
      Toasts.success(res.message);
      handleClose();
      navigate("/polls-and-surveys/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      setFieldError("tokenValidTill", errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };


  console.log("timee",moment()
  .tz(userTimezone ?? currentTimezone)
  .format("YYYY-MM-DD"))
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={pollsAndSurveysExpiryDateValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
            isSubmitting,
            setFieldError,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch  py-0.5">
                    <div className="relative flex items-center self-stretch justify-start ">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid dark:text-textWhite">
                        Expiry Date
                      </p>
                      <button onClick={handleClose}>
                        <XCloseIcon width={22} height={22} fill="#2C3236" />
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-start items-center self-stretch  relative py-0.5">
                    <p className="flex-grow w-[268px] text-sm text-left text-secondaryMid dark:text-textWhite">
                      You can indicate below until what date you want this
                      survey link to remain functional. If you do not indicate a
                      date, the survey link will remain functional indefinitely.
                    </p>
                  </div>
                  <div className="w-full">
                    <InputField
                      type="date"
                      label=""
                      placeholder="YYYY/MM/DD"
                      value={values?.tokenValidTill}
                      className={"py-[-2px] w-full"}
                      name="tokenValidTill"
                      error={errors?.tokenValidTill}
                      min={moment()
                        .tz(userTimezone ?? currentTimezone)
                        .format("YYYY-MM-DD")}
                      onChange={(values: any) => {
                        setFieldValue("tokenValidTill", values ?? "");
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex items-center self-stretch justify-end gap-2">
                    <div className="flex items-center self-stretch justify-end gap-2">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="px-5 py-2"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="outlined"
                        onClick={handleClose}
                      />

                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        text="Create"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="px-5 py-2"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

function getUserTimezoneDate(userTimezone: string, currentTimezone: string) {
  return moment()
    .tz(userTimezone ?? currentTimezone)
    .startOf("day")
    .toDate();
}
