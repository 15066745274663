import React from "react";
// Components
import {
  Date,
  Responses,
  SelectedCreatedBy,
  SelectedArchivedBy,
  SelectedPrograms,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { pollsAndSurveysResetFiltersAction } from "store/filters/reducer.actions";
import { pollsAndSurveysDirectoryFilterState } from "store/filters/initialState";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting pollsAndSurveysFilter state from Redux store
  const { pollsAndSurveysFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  
  const clearAllFilters = (data: any) => {
    dispatch(pollsAndSurveysResetFiltersAction(data));
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyPollsAndSurveysFilterApplied(pollsAndSurveysFilter) && (
        <button
          onClick={() => clearAllFilters(pollsAndSurveysFilter.status)}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start "
      >
        <div className="flex flex-wrap gap-2">
          <SelectedPrograms />
          
          <Responses
            name="responses"
            min={pollsAndSurveysFilter.responses.min}
            max={pollsAndSurveysFilter.responses.max}
            isApplied={pollsAndSurveysFilter.responses?.applied}
          />
          <SelectedCreatedBy />

          {pollsAndSurveysFilter.status === "archived" && (
            <SelectedArchivedBy />
          )}

          <Date
            name="dateAdded"
            from={pollsAndSurveysFilter.dateAdded.from}
            to={pollsAndSurveysFilter.dateAdded.to}
            isApplied={pollsAndSurveysFilter.dateAdded?.applied}
          />
          {pollsAndSurveysFilter.status === "archived" && (
            <Date
              name="dateArchived"
              from={pollsAndSurveysFilter.dateArchived.from}
              to={pollsAndSurveysFilter.dateArchived.to}
              isApplied={pollsAndSurveysFilter.dateArchived?.applied}
            />
          )}
          {/* {pollsAndSurveysFilter.status !== "archived" && (
            <Date
              name="dateUpdated"
              from={pollsAndSurveysFilter.dateUpdated.from}
              to={pollsAndSurveysFilter.dateUpdated.to}
              isApplied={pollsAndSurveysFilter.dateUpdated?.applied}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;



// Function to check if any filter is applied
export function isAnyPollsAndSurveysFilterApplied (
  pollsAndSurveysFilter: pollsAndSurveysDirectoryFilterState
): boolean  {
  const {
    dateAdded,
    dateArchived,
    dateUpdated,
    archivedByIds,
    createdByIds,
    responses,
    programIds,
  } = pollsAndSurveysFilter;
  const applied =
    dateAdded?.applied ||
    dateArchived?.applied ||
    dateUpdated?.applied ||
    archivedByIds?.applied ||
    createdByIds?.applied ||
    responses?.applied ||
    programIds?.applied;

  return applied;
};
