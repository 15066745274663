export interface IFiles {
  dateUpdated: string;
  fileSize: string;
  id: number;
  fileName: string;
  path: string;
}

export interface IfilesData {
  files: IFiles[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchFiles {
  data: IfilesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

export const initialState: IfetchFiles = {
  data: {
    files: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
