import { apiClient } from "../config";

async function getPropertyDropdownOptions(id: number | null, queryParams: any) {
  const res = await apiClient.get(`/admin/imports/${id}/property-dropdown`, {
    params: queryParams,
  });
  return res.data;
}

export default getPropertyDropdownOptions;
