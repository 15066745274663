import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import { fetchJoinRequestMembersAction } from "store/joinRequests";
import { getBrandPlaceholder } from "utils/customBranding";
import { Toasts } from "view/components/Toasts";
import { Statuses } from "../Statuses";
import { useState } from "react";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formateDate } = useDateFormatter();
  const { programId } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleMembersRequest = async (data: string, userId: number) => {
    try {
      // disable the button
      setIsDisable(true);
      // set the user data
      const userData = {
        request: data,
        userId: userId,
        programId: programId,
      };
      const response =
        await apiLibrary.joinRequests.responseMembersRequests(userData);
      Toasts.success(response.message);
      dispatch(fetchJoinRequestMembersAction(programId));
    } catch (error: any) {
      Toasts.error(error?.response?.data.message);
      console.error("An error occurred:", error);
    } finally {
      setIsDisable(false);
    }
  };

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "name"
  ) {
    return (
      <Link to={`/profile/${cellValue?.id}/person`}>
        <div className="flex justify-start w-[250px] items-center relative group">
          <img
            src={
              cellValue?.image?.file
                ? cellValue?.image?.file?.thumb
                : getBrandPlaceholder("userProfile")
            }
            alt=""
            height="100"
            width="100"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: "wrap" }}
            className="flex flex-col flex-wrap w-full justify-start items-start relative gap-0.5"
          >
            <p className="text-sm text-left break-words text-textMid group-hover:text-primaryDark">
              {cellValue?.personName}
            </p>
            <p className="text-sm text-left break-all text-textMid group-hover:text-primaryDark">
              {cellValue?.observations} observations
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === "lastActive") {
    return (
      <div
        style={{ flexWrap: "wrap" }}
        className=" flex flex-col w-full px-2 justify-start items-start relative gap-0.5"
      >
        <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
          {`${cellValue}`}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "status") {
    return <Statuses cellValue={cellValue} />;
  } else if (
    cellValue !== null &&
    (columnDef.id === "requestedOn" ||
      columnDef.id === "declinedOn" ||
      columnDef.id === "memberSince")
  ) {
    return (
      <div
        style={{ flexWrap: "wrap" }}
        className=" flex flex-col w-full px-2 justify-start items-start relative gap-0.5"
      >
        <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
          {formateDate(cellValue)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "requests") {
    return (
      <div className={`relative flex justify-between py-1 space-x-2`}>
        <button
          disabled={isDisable}
          onClick={() => handleMembersRequest("accept", cellValue)}
          className="bg-primary rounded-3xl px-12 py-1 text-base text-textWhite"
        >
          Accept
        </button>
        <button
          disabled={isDisable}
          onClick={() => handleMembersRequest("decline", cellValue)}
          className="rounded-3xl px-12 py-1 text-base text-primary border-2 border-primary"
        >
          Decline
        </button>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 40)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
