import { apiClient } from "../config";

async function getAllManagers(queryParams: any) {
  const res = await apiClient.get(`/admin/programs/managers`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllManagers;
