
const Snapping = ({
  enableSnapping,
  handleCheckboxOnChange,
}: any) => {
  return (
    <div className="flex gap-2 mt-2">
      <input
        type="checkbox"
        checked={enableSnapping}
        onChange={handleCheckboxOnChange}
        id="enableSnapping"
        className="w-4" 
      />
      <label htmlFor="enableSnapping" className="cursor-pointer text-secondaryMidLight">
        Snapping
      </label>
    </div>
  );
};

export { Snapping };
