import { useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, AddNotificationBtn } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useNavigate } from "react-router-dom";
import { sentenceCase } from "change-case";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

const generateDynamicNotificationsLinks = (label: string) => {
  return [{ path: `/custom-notifications/list`, label: `Custom notifications` }];
};

export const Header = () => {
  const { notificationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const navigate = useNavigate();
  const { reminders } = usePermissions();
  const dynamicNotificationsLinks = generateDynamicNotificationsLinks(
    notificationsFilter.status
  );

  const handleAddNotification = () => {
    navigate("/custom-notifications/add");
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicNotificationsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {reminders.canCreateReminders && (
                <AddNotificationBtn
                  handleAddNotification={handleAddNotification}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
