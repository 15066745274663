import { useRef } from "react";
import { useInitializeMap } from "../../hooks";
import {
  useApplyFitboundsToTheMap,
  useCustomizeColorOfHigilightedLayer,
  useGetCurrentQuestionState,
  useHandleClickOnLayer,
  useHandleMapMovement,
  useHandleMovementOfMapCenterPoint,
  usePaintAllLayersOnMap,
} from "./hooks";

const LAYER_DEFAULT_COLOR = "#FF0000";
const LAYER_SELECTED_COLOR ="#FFA500";

const Map = ({ questionId, name }: any) => {
  const mapContainer = useRef<any>(null);

  const currentQuestionEle: any = useGetCurrentQuestionState(questionId, name);
  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
  );

  // getting question state from global states
  // paint all layers on the map
  const [clickedLayer, setClickedLayer] = usePaintAllLayersOnMap(
    currentQuestionEle,
    map,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR
  );

  // handle click on layer
  useHandleClickOnLayer(
    clickedLayer,
    setClickedLayer,
    currentQuestionEle,
    name
  );

  // change the color of highlighted layer
  useCustomizeColorOfHigilightedLayer(
    map,
    isMapFullyLoaded,
    currentQuestionEle,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR
  );

  // handle map center point property
  useHandleMovementOfMapCenterPoint(currentQuestionEle, map);

  // handle movement of map
  useHandleMapMovement(currentQuestionEle, map);
  

  // useApplyFitboundsToTheMap(currentQuestionEle?.allCommunityLayers ?? [],map, isMapFullyLoaded)


  // map?.on("fullscreenchange", () => {
  //   console.log("hellow updateBounds");
  // });
  // hanlde for panels
  // useEffect(()=>{
  //   if(selectedQuestionElement && currentQuestionEle && map && geographicalPanelMapQuestions){
  //     const questionType = selectedQuestionElement.getType();

  //     if(questionType === panelsName.GEOGRAPHICAL_INFORMATION){
  //       console.log("testing in panels",{
  //         selectedQuestionElement,currentQuestionEle,geographicalPanelMapQuestions
  //       });

  //     }
  //   }
  // },[selectedQuestionElement,currentQuestionEle,map,geographicalPanelMapQuestions])

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
