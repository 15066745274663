import initialState from "./initialState";
import { IActiveUsers } from "./activeUsers/initialState";
import { IMostObservedObjects } from "./mostObservedObjects/initialState";
import {
  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_STATE,
  GET_OBSERVATION_BY_LOCATION,
  GET_OBSERVATION_BY_LOCATION_STATE,
  GET_CONFIGURATION,
  GET_CONFIGURATION_STATE,
  GET_CUMULATIVE_OBSERVATIONS,
  GET_CUMULATIVE_OBSERVATIONS_STATE,
  GET_HORIZONTAL_BAR_GRAPH,
  GET_HORIZONTAL_BAR_GRAPH_STATE,
  GET_MOST_OBSERVED_OBJECTS,
  GET_MOST_OBSERVED_OBJECTS_STATE,
  GET_MOST_OBSERVED_SPECIES,
  GET_MOST_OBSERVED_SPECIES_STATE,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_STATE,
  GET_OBSERVATIONS,
  GET_OBSERVATIONS_BY_REGION,
  GET_OBSERVATIONS_BY_REGION_STATE,
  GET_OBSERVATIONS_STATE,
  GET_QA_NOTIFICATIONS,
  GET_QA_NOTIFICATIONS_STATE,
  GET_QA_PROGRESS,
  GET_QA_PROGRESS_STATE,
  GET_REPORT_NOTIFICATIONS,
  GET_REPORT_NOTIFICATIONS_STATE,
  GET_TOTAL_COUNTS,
  GET_TOTAL_COUNTS_STATE,
  SAVE_CONFIGURATION_STATE,
} from "./action-types";
import { getHBGStateAndName } from "helpers/dashboard";
import { IHorizontalBarGraph } from "./horizontalBarGraph/initialState";
import { IObservationsByRegion } from "./observationsByRegion/initialState";

export const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_HORIZONTAL_BAR_GRAPH_STATE:
      let { name: moduleNameForState, stateObj: stateObjForState } =
        getHBGStateAndName(action.payload.name, state);
      return {
        ...state,
        [moduleNameForState]: {
          ...stateObjForState,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_HORIZONTAL_BAR_GRAPH:
      let { name: moduleName, stateObj } = getHBGStateAndName(
        action.payload.name,
        state
      );
      let horizontalBarGraph = action.payload.data.slice(0, 6).sort(function (
        a: IHorizontalBarGraph,
        b: IHorizontalBarGraph
      ) {
        return a.percent - b.percent;
      });
      return {
        ...state,
        [moduleName]: {
          ...stateObj,
          data: horizontalBarGraph,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_TOTAL_COUNTS_STATE:
      return {
        ...state,
        totalCounts: {
          ...state.totalCounts,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_TOTAL_COUNTS:
      let totalCounts = action.payload.data;
      return {
        ...state,
        totalCounts: {
          ...state.totalCounts,
          data: totalCounts,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_OBSERVATIONS_STATE:
      return {
        ...state,
        observations: {
          ...state.observations,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_OBSERVATIONS:
      let observations = action.payload;

      return {
        ...state,
        observations: {
          ...state.observations,
          data: observations?.data || [],
          maxYAxis: observations?.maxYAxis || 0,
          totalCount: observations?.totalCount || 0,
          message: observations?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_QA_PROGRESS_STATE:
      return {
        ...state,
        qaProgress: {
          ...state.qaProgress,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_QA_PROGRESS:
      let qaProgress = action.payload.data;

      return {
        ...state,
        qaProgress: {
          ...state.qaProgress,
          data: qaProgress,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };

    case GET_QA_NOTIFICATIONS_STATE:
      return {
        ...state,
        qaNotifications: {
          ...state.qaNotifications,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_QA_NOTIFICATIONS:
      let qaNotifications = action.payload.data || [];
      qaNotifications = qaNotifications.slice(0, 5);
      return {
        ...state,
        qaNotifications: {
          ...state.qaNotifications,
          data: qaNotifications,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_REPORT_NOTIFICATIONS_STATE:
      return {
        ...state,
        reportNotifications: {
          ...state.reportNotifications,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_REPORT_NOTIFICATIONS:
      let reportNotifications = action.payload.data || [];
      reportNotifications = reportNotifications.slice(0, 5);

      return {
        ...state,
        reportNotifications: {
          ...state.reportNotifications,
          data: reportNotifications,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_NOTIFICATIONS_STATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_NOTIFICATIONS:
      let notifications = action.payload.data || [];
      notifications = notifications.slice(0, 4);
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: notifications,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_CUMULATIVE_OBSERVATIONS_STATE:
      return {
        ...state,
        cumulativeObservations: {
          ...state.cumulativeObservations,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_CUMULATIVE_OBSERVATIONS:
      let cumulativeObservations = action.payload;
      return {
        ...state,
        cumulativeObservations: {
          ...state.cumulativeObservations,
          data: cumulativeObservations?.data || [],
          maxYAxis: cumulativeObservations?.maxYAxis || 0,
          totalCount: cumulativeObservations?.totalCount || 0,
          message: cumulativeObservations?.message || "",
          stateIs: "Idle",
        },
      };

    case GET_OBSERVATION_BY_LOCATION_STATE:
      return {
        ...state,
        observationByLocation: {
          ...state.observationByLocation,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_OBSERVATION_BY_LOCATION:
      let observationByLocation = action.payload;

      return {
        ...state,
        observationByLocation: {
          ...state.observationByLocation,
          data: observationByLocation,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };

    case GET_OBSERVATIONS_BY_REGION_STATE:
      return {
        ...state,
        observationsByRegion: {
          ...state.observationsByRegion,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_OBSERVATIONS_BY_REGION:
      let observationsByRegion: IObservationsByRegion = action.payload.data;
      observationsByRegion = {
        ...observationsByRegion,
        regions: observationsByRegion.regions.length
          ? observationsByRegion.regions.slice(0, 5)
          : [],
      };
      return {
        ...state,
        observationsByRegion: {
          ...state.observationsByRegion,
          data: observationsByRegion,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_ACTIVE_USERS_STATE:
      return {
        ...state,
        activeUsers: {
          ...state.activeUsers,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_ACTIVE_USERS:
      const activeUsersData =
        action?.payload
          ?.map((item: IActiveUsers) => {
            const {
              firstName,
              lastName,
              observationCount,
              personId,
              personImage,
            } = item;
            return {
              firstName,
              lastName,
              observationCount,
              personId,
              personImage,
            };
          })
          .slice(0, 5) || [];
      return {
        ...state,
        activeUsers: {
          data: activeUsersData,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_MOST_OBSERVED_SPECIES_STATE:
      return {
        ...state,
        mostObservedSpecies: {
          ...state.mostObservedSpecies,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_MOST_OBSERVED_SPECIES:
      const mostObservedSpecies =
        action.payload
          .map((item: IMostObservedObjects) => {
            const { count, name } = item;
            return {
              count,
              name,
            };
          })
          .slice(0, 6) || [];
      return {
        ...state,
        mostObservedSpecies: {
          ...state.mostObservedSpecies,
          data: mostObservedSpecies,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case GET_MOST_OBSERVED_OBJECTS_STATE:
      return {
        ...state,
        mostObservedObjects: {
          ...state.mostObservedObjects,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_MOST_OBSERVED_OBJECTS:
      const mostObservedObjects =
        action.payload
          .map((item: IMostObservedObjects) => {
            const { count, name } = item;
            return {
              count,
              name,
            };
          })
          .slice(0, 6) || [];
      return {
        ...state,
        mostObservedObjects: {
          ...state.mostObservedObjects,
          data: mostObservedObjects,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    case SAVE_CONFIGURATION_STATE:
      const { postStateIs } = action.payload;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          postStateIs,
        },
      };
    case GET_CONFIGURATION_STATE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          stateIs: action.payload.stateIs,
        },
      };
    case GET_CONFIGURATION:
      let dashboardConfiguration = action.payload.data;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          data: dashboardConfiguration,
          message: action.payload?.message || "",
          stateIs: "Idle",
        },
      };
    default:
      return state;
  }
};
