import React, { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileBackupIcon from "assets/icons/HeroIcons/FileBackupIcon";
import Button from "view/components/Button";
import EmptyBoxIcon from "assets/icons/HeroIcons/EmptyBoxIcon";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import usePermissions from "hooks/usePermissions";
import LoadingBar from "react-top-loading-bar";
import { TailSpin } from "react-loader-spinner";
import { runProcessedReports } from "store/reportsRun/reducer.actions";
import { useDispatch } from "react-redux";
import { runReportsResetFiltersAction } from "store/filters/reducer.actions";

export const ReportCard = () => {
  const [versionId, setVersionId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { reports } = usePermissions();
  const data = useSelector<RootState, any>((state) => state?.runReport);
  const navigate = useNavigate();
  const { reportsId } = useParams<any>();
  const [progress, setProgress] = useState(0);

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const { data } =
          await apiLibrary.Reports.getProcessedReportVersion(reportsId);
        setVersionId(data?.id);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        console.log("error", errorMsg);
      }
    };

    fetchData();
  }, [reportsId, data.processedReports, data.versionId]);
  const handleProcessedReports = () => {
    navigate(`/reports/${reportsId}/profile`);
  };

  const runReportManually = async () => {
    let progress = 10;

    // Define the number of steps and maximum progress value
    const steps = 3;
    const maxProgress = 100;

    for (let step = 1; step <= steps; step++) {
      // Calculate the random progress increment for this step
      const increment = (maxProgress - progress) / (steps - step + 1);

      // Add the increment to the progress
      progress += increment;
      setProgress(Math.min(maxProgress, progress));

      // Delay for 1-2 seconds (adjust the delay as needed)
      await new Promise((resolve) =>
        setTimeout(resolve, Math.random() * 1000 + 1000)
      );
    }

    try {
      setProgress(100);
      navigate(`/reports/${reportsId}/report-version/${data.versionId}/result`);
      dispatch(runReportsResetFiltersAction());
    } catch (error: any) {
      setProgress(0);
    } finally {
      dispatch(runProcessedReports(false));
    }
  };
  useEffect(() => {
    if (data.processedReports && data.versionId) {
      runReportManually();
    }
  }, [data.processedReports, data.versionId]);

  return (
    <div className="flex flex-col items-center justify-center w-full p-6 bg-bgWhite">
      {!versionId && !data?.versionId ? (
        <div className="flex flex-col items-center justify-center w-full mb-2">
          <EmptyBoxIcon />
          <p className="pt-6 text-lg font-semibold text-center">
            There’s nothing to report on — yet. Get data first!
          </p>
          <p className="text-sm text-center textMidLight">
            No observations data from observers for the specified period. Select
            another time range.
          </p>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center w-full">
            <FileBackupIcon />
            <p className="pt-6 text-lg font-semibold text-center">
              {data.processedReports
                ? "Please wait! Your report is being processed..."
                : "To run a manual report, first select your date range in the top left!"}
            </p>
            <p className="text-sm text-center textMidLight">
              {data.processedReports
                ? "Processing... Approximate remaining time about 1 min..."
                : "Please note that choosing a date range longer than 6 months can result in long processing times. In order to avoid lengthy processing, it is advised to choose a date range up to 30 days.Looking for a report that was already processed? Click the button below to navigate to all processed reports."}
            </p>
          </div>
          {data.processedReports && (
            <>
              {progress > 0 && (
                <div className="my-4 spinner">
                  <TailSpin
                    height="40"
                    width="40"
                    color="#005C89"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{}}
                    wrapperClass="tailspin-loader"
                    visible={true}
                    strokeWidth={3}
                  />
                </div>
              )}

              <div className="flex items-center gap-2 loadingbar">
                <div className="relative w-[30vw] h-1 bg-secondary_25">
                  <LoadingBar
                    color="#005C89"
                    progress={progress}
                    height={3}
                    containerClassName="!absolute"
                    shadow={false}
                    waitingTime={1000000}
                  />
                </div>
                <p className="text-sm font-Overpass text-textMid dark:text-caption ">
                  {progress}%
                </p>
              </div>
            </>
          )}
          {reports.canViewProcessedVersionReports && !data.processedReports && (
            <div className="flex justify-center mt-6 mb-4">
              <Button
                type="button"
                text="See Processed Reports"
                disabled={!reports.canViewProcessedVersionReports}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 "
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleProcessedReports}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ReportCard;
