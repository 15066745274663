import moment from "moment-timezone"

/**
 * Gets the UTC time for midnight of a given date in a specified time zone.
 *
 * @param {string} date - The date in 'YYYY-MM-DD' format.
 * @param {string} timeZone - The time zone ID, e.g., 'America/New_York'.
 * @return {string} The UTC time of 12 AM in the specified time zone.
 */
export function getMidnightInLocalTimeZoneToUtc(date: string, timeZone: string) {
  const dateTime = `${date} 00:00:00`;

  // Validate date, time, and timeZone
  if (!moment(dateTime, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    return "";
  }
  const localMidnight = moment.tz(dateTime, timeZone);
  const utcMidnight = localMidnight.utc().format("YYYY-MM-DD HH:mm:ss");

  return utcMidnight;
}

/**
 * Gets the UTC time for the end of a given date (23:59:59) in a specified time zone.
 *
 * @param {string} date - The date in 'YYYY-MM-DD' format.
 * @param {string} timeZone - The time zone ID, e.g., 'America/New_York'.
 * @return {string} The UTC time of 23:59:59 in the specified time zone.
 */
export function getEndOfDayInLocalTimeZoneToUtc(date: string, timeZone: string) {
  const dateTime = `${date} 23:59:59`;

  // Validate date, time, and timeZone
  if (!moment(dateTime, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    return "";
  }
  const localEndOfDay = moment.tz(dateTime, timeZone);

  // Convert that moment to UTC
  const utcEndOfDay = localEndOfDay.utc().format("YYYY-MM-DD HH:mm:ss");

  return utcEndOfDay;
}



export function convertDateAndTimeToUTC(
  date: string,
  time: string,
  timeZone: string
) {
  const dateTime = `${date} ${time}`;

  // Validate date, time, and timeZone
  if (!moment(dateTime, "YYYY-MM-DD HH:mm", true).isValid()) {
    return "";
  }
  if (!moment.tz.zone(timeZone)) {
    throw new Error("Invalid time zone.");
  }

  // Create a moment object for the given date, time, and timeZone
  const localMidnight = moment.tz(dateTime, timeZone);

  // Convert to UTC and format
  const utcMidnight = localMidnight.utc().format("YYYY-MM-DD HH:mm:ss");

  return utcMidnight;
}
