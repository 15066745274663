import React, { useState } from "react";
/************ Components ************/
import { Toasts } from "view/components/Toasts";
/************ Utils ************/
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
/************ API Services ************/
import apiLibrary from "services/api";
/************ Store Utils ************/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { updateSocialAccountsAction } from "store/userProfile";
import { useDispatch, useSelector } from "react-redux";
/************ Assets ************/
import SocialAppleIcon from "assets/icons/SocialIcons/SocialAppleIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import AppleSignin from "react-apple-signin-auth";
import jwtDecode, { JwtPayload } from "jwt-decode";
import SocialButton from "./SocialAccountButton";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";

interface AppleAuthorization {
  code: string;
  id_token: string;
}

interface AppleAuthResponse {
  authorization: AppleAuthorization;
}

interface AppleIdTokenPayload extends JwtPayload {
  nonce: string;
  c_hash: string;
  email: string;
  email_verified: string;
  auth_time: number;
  nonce_supported: boolean;
}

export const ConnectApple: React.FC = () => {
  const dispatch = useDispatch();
  const { detachSocialAccount, attachSocialAccount } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  /**
   * Handles the response from apple login and performs appropriate actions.
   */
  const responseApple = async (response: AppleAuthResponse) => {
    const { id_token } = response.authorization;
    const decoded = jwtDecode<AppleIdTokenPayload>(id_token);
    setIsLoading(true);
    try {
      if (
        isConnected("apple") === "Connected" &&
        detachSocialAccount.canCreateDetachSocialAccount
      ) {
        const res = await apiLibrary.Auth.detachSocialAccounts({
          channel: "apple",
          email: decoded.email,
          accessToken: id_token,
          id: decoded.email,
        });
        dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
        Toasts.success(res.message);
        return;
      }
      if (attachSocialAccount.canCreateAttachSocialAccount) {
        const res = await apiLibrary.Auth.attachSocialAccounts(
          convertKeysToSnakeCase({
            channel: "apple",
            email: decoded.email,
            accessToken: id_token,
            id: decoded.email,
          })
        );

        const convertedData = convertKeysToCamelCase(res?.data?.socialAccounts);
        dispatch(updateSocialAccountsAction(convertedData));

        Toasts.success(res.message);
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Determines if a social account is connected based on the provided name.
   *
   * @param {string} name - The name of the social account to check for connection.
   * @returns {string} The connection status, either "Connected" or "Disconnected".
   */
  const isConnected = (name: any) => {
    return userProfile?.socialAccounts?.find((item: any) => item === name)
      ? "Connected"
      : "Disconnected";
  };

  const deAttachSocialAccounts = async () => {
    if (
      isConnected("apple") === "Connected" &&
      detachSocialAccount.canCreateDetachSocialAccount
    ) {
      setIsLoading(true);
      try {
        const res = await apiLibrary.Auth.detachSocialAccounts({
          channel: "apple",
        });
        dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
        Toasts.success(res.message);
      } catch (error: any) {
        console.error("Error detaching social account:", error);
        // Handle error here
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [showContent, setShowContent] = useState(false);

  const handleMouseEnter = () => {
    if (isLoading) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  };

  const handleMouseLeave = () => {
    setShowContent(false);
  };

  if (isConnected("apple") === "Connected") {
    return (
      <>
        <div className="relative">
          {isLoading && (
            <div className="loader absolute justify-center items-center flex w-full">
              <TailSpin
                height="30"
                width="30"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{ marginTop: "3px" }}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          )}
          <SocialButton
            onClick={deAttachSocialAccounts}
            className={isLoading ? "opacity-[0.4]" : ""}
            itemHover={isLoading ? true : false}
            disabled={
              isLoading || !detachSocialAccount.canCreateDetachSocialAccount
                ? true
                : false
            }
            icon={SocialAppleIcon}
            text={isConnected("apple")}
            isConnected={isConnected("apple") === "Connected"}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="relative">
          {isLoading && (
            <div className="loader absolute justify-center items-center flex w-full">
              <TailSpin
                height="30"
                width="30"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{ marginTop: "3px" }}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          )}
          <AppleSignin
            authOptions={{
              clientId: process.env.REACT_APP_APPLE_CLIENT_ID ?? "",
              scope: "email name",
              redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL ?? "",
              state: "",
              nonce: "nonce",
              usePopup: true,
            }}
            uiType="dark"
            className="apple-auth-btn"
            noDefaultStyle={false}
            buttonExtraChildren="Continue with Apple"
            onSuccess={(response: AppleAuthResponse) => {
              responseApple(response);
            }}
            onError={(error: any) => console.error(error)}
            skipScript={false}
            iconProps={{ style: { marginTop: "10px" } }}
            render={(props: any) => (
              <button
                {...props}
                type="button"
                disabled={
                  props.disabled ||
                  isLoading ||
                  (isConnected("apple") === "Connected" &&
                    !detachSocialAccount.canCreateDetachSocialAccount) ||
                  !attachSocialAccount.canCreateAttachSocialAccount
                }
                className={`flex h-9 ${
                  isLoading ? "opacity-[0.4]" : ""
                } bg-primary w-10 hover:flex-grow transition-all duration-500 overflow-hidden relative flex-initial ${
                  isConnected("apple") ? "bg-primaryLight" : "bg-primary/[0.08]"
                } items-center justify-center flex-grow-0 flex-shrink-0 gap-2 rounded-lg ${
                  showContent
                    ? "w-[180px] justify-between"
                    : "w-10 justify-center"
                }`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={`flex justify-center items-center w-full flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg relative ${
                    showContent ? "pl-1 " : ""
                  }`}
                >
                  <SocialAppleIcon />
                  <div
                    className={`w-full flex justify-around items-center ${
                      showContent ? "" : "hidden"
                    }`}
                  >
                    <p className="ml-7">{isConnected("apple")}</p>
                    <XCloseIcon />
                  </div>
                </div>
              </button>
            )}
          />
        </div>
      </>
    );
  }
};
