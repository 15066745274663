import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import { AddExistingBtnProps } from "../../types";
import Plus2Icon from "assets/icons/HeroIcons/Plus2Icon";

const SearchToAddExistingBtn = ({
  handleClickOnAddExistingBtn,
}: AddExistingBtnProps) => {
  return (
    <button
      onClick={handleClickOnAddExistingBtn}
      className="relative flex items-center justify-start gap-4 px-3 py-1 rounded-lg  hover:bg-primary/[0.2]"
    >
      <Plus2Icon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
      <div className="flex items-center justify-start flex-grow">
        <div className="relative flex items-center justify-start flex-grow">
          <p className="flex-grow w-[172px] text-[15px] text-left text-textMid">
            Vector
          </p>
        </div>
      </div>
    </button>
  );
};

export { SearchToAddExistingBtn };
