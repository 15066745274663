import Head from "view/components/Head";

const PageHeading = () => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title="Roles And Permissions" />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          Roles And Permissions
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
