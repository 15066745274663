import { RenderCellContentProps } from "../../types";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (cellValue !== null &&
    columnDef.id === "notificationsDate") {
    return (
      <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
        <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid w-[180px] break-all">
          {cellValue}
        </p>
      </div>
    )
  } else if (cellValue !== null &&
    columnDef.id === "status") {
    return (
      <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
        {cellValue === "paused" ? <>
          <p className="text-sm text-left text-secondaryLight bg-brandHoverLight break-all py-1 px-2 rounded-lg">
            {cellValue}
          </p>
        </> : <>
          <p className="text-sm text-left text-tertiary bg-tertiaryLight break-all py-1 px-2 rounded-lg">
            {cellValue}
          </p>
        </>}

      </div>
    )
  }
};
