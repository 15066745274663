import { apiClient } from "../config";

async function getallOrganization(searchString: string, page?: number, appName?: string) {
  const params: {
    search_string?: string;
    page: number | undefined;
    appName?: string
  } = {
    page: page === undefined ? 1 : page,
    appName: appName
  };

  if (searchString.trim() !== "") {
    params.search_string = searchString.trim();
  }

  const res = await apiClient.get("/admin/communities/organizations", {
    params,
  });
  return res.data;
}

export default getallOrganization;
