import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";
import PlaceholderAvatar from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";

interface ConnectedCommunitiesProps {
  communityData: any;
  setSelectedCommunity: any;
  disconnectCommunity: any;
}

const ConnectedCommunities: React.FC<ConnectedCommunitiesProps> = ({
  communityData,
  disconnectCommunity,
  setSelectedCommunity,
}) => {
  return (
    <div>
      <div
        className=" group relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2"
        // onClick={() => setSelectedCommunity(communityData)}
      >
        <img
          src={
            communityData?.image
              ? communityData?.image?.thumb
              : PlaceholderAvatar
          }
          alt=""
          height="100"
          width="100"
          className="object-cover Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div className="flex items-center justify-start flex-grow">
          <div className="flex items-center justify-start w-full pl-1 rounded-lg">
            <p className="max-w-[520px] text-left break-words dark:text-textMain dark:hover:text-primary group-hover:dark:text-primary">{`${communityData.name}`}</p>
          </div>
        </div>
        <XCloseSmallControlIcon
          onClick={() => disconnectCommunity(communityData)}
        />
      </div>
    </div>
  );
};

export default ConnectedCommunities;
