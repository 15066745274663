import getAllOrganizations from "./getAllOrganizations";
import getAllTags from "./tags";
import createOrgaznization from "./createOrgaznization";
import getallCommuities from "./getAllCommunitiesInOrganizations";
import getCommunitiesMembers from "./getCommunitiesMembers";
import addAdminsToOrganization from "./addOrganizationAdmins";
import getOrganizationProfile from "./getOrganizationProfile";
import updateOrganization from "./updateOrganization";
import archiveOrganization from "./archiveOrganization";
import getAllConnectedCommunities from "./getAllConnectedCommunities";
import getCommunitiesByOrganization from "./getCommunitiesByOrganization";
import  getAllCommunities from "./getAllCommunities";
import removeEntityFromOrganization from "./removeEntityFromOrganization"
const Organization = {
  getAllOrganizations,
  getOrganizationProfile,
  getAllTags,
  createOrgaznization,
  getallCommuities,
  getCommunitiesMembers,
  addAdminsToOrganization,
  updateOrganization,
  archiveOrganization,
  getAllConnectedCommunities,
  getCommunitiesByOrganization,
  getAllCommunities,
  removeEntityFromOrganization
};
export default Organization;
