import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  height?: any;
  width?: any;
  className?: any;
  isActive?: boolean;
}

export const CheveronDownIcon = ({
  fill,
  isActive,
  className,
  ...restProps
}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    // className={`flex-grow-0 flex-shrink-0 w-[${width}] h-[${height}] relative`}
    preserveAspectRatio="xMidYMid meet"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86128 5.52864C3.12163 5.26829 3.54374 5.26829 3.80409 5.52864L7.99935 9.7239L12.1946 5.52864C12.455 5.26829 12.8771 5.26829 13.1374 5.52864C13.3978 5.78899 13.3978 6.2111 13.1374 6.47145L8.47075 11.1381C8.2104 11.3985 7.78829 11.3985 7.52794 11.1381L2.86128 6.47145C2.60093 6.2111 2.60093 5.78899 2.86128 5.52864Z"
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
    />
  </svg>
);
export default CheveronDownIcon;
