import { loginSuccess, resetAuthData, updateAuthData ,updatePermissions } from "./action-types";
import { IPERMISSIONS } from "./initialState";

export const loginAction = (res: any) => {
  return {
    type: loginSuccess,
    payload: res,
  };
};


export const updateAuthPermissions=(permission:IPERMISSIONS)=>{
  return {
    type: updatePermissions,
    payload: permission,
  };
}



export const resetAuthDataAction = () => {
  return {
    type: resetAuthData,
  };
};



export const updateAuthDataAction = (data: any) => {
  return {
    type: updateAuthData,
    payload: data,
  };
};
