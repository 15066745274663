import * as React from 'react';
import { SVGProps } from 'react';
const ISNSidebarLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='relative flex flex-shrink-0 mt-1 ml-2 group-dark: grow-0 w-7 h-7'
    preserveAspectRatio='xMidYMid meet'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0L10 13H0V15H10L0 28H28L18 15H28V13H18L28 0H0ZM18 13V15H10V13H18ZM4.06173 2L10.9848 11H17.0152L23.9383 2H4.06173ZM10.9848 17L4.06173 26H23.9383L17.0152 17H10.9848Z'
      fill='#005C89'
    />
  </svg>
);
export default ISNSidebarLogo;
