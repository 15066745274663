

import React, { useState } from 'react';
import { snakeToCamel } from 'utils/caseConvertor';
import {
  TextField,
  Dropdown,
  MultiselectDropdown,
  RadioButtonGroup,
} from '../../Components';
import AudioRecorderComponent from '../../Components/Audio';
import { stubFalse } from 'lodash';

interface GeneralInfoProps {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  handleUpdateStatus: any;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  panel,
  editMode,
  formikProps,
  handleUpdateStatus,
}) => {
  const onChange = (eleName: string, value: any, name: string) => {
    formikProps.setFieldValue(eleName, value);
    handleUpdateStatus(name, panel.sectionId);
  };

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name);

  return (
    <>
      <div className='grid grid-cols-12 gap-4 mb-5'>
        {panelElements.map((element: any, index: number) => {
          const {
            name,
            title,
            choices,
            fieldAs,
            rows,
            value,
            inputType,
            type,
            placeholder,
            isRequired = false,
            choicesByUrl,
            visible = true,
            defaultValue,
          } = element;
          const eleName = [panelName, element.name].join('.');
          const error = formikProps.errors[panelName]?.[element.name];
          const touched = formikProps.touched[panelName]?.[name];

          if (visible === false || type === 'html' || defaultValue) {
            return;
          }
          switch (element.type) {
            case 'dropdown':
              return (
                <div
                  className={`${name === 'DataEntry' ? 'col-span-12' : 'col-span-6'}`}
                >
                  <Dropdown
                    key={index}
                    label={title}
                    name={name}
                    choices={choices}
                    choicesByUrl={choicesByUrl}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(item: any) => onChange(eleName, item, name)}
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    editMode={editMode}
                    placeholder={placeholder || `Select ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );
            case 'tagbox':
              // Options for the select
              return (
                <div className='col-span-12'>
                  <MultiselectDropdown
                    name={eleName}
                    data={choices}
                    choicesByUrl={choicesByUrl}
                    label={title}
                    placeholder={placeholder || `Select ${title}`}
                    editMode={editMode}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(selectedOptions: any) => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option
                      );
                      onChange(eleName, selectedValues, name);
                    }}
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );
            case 'text':
              return (
                <div className='col-span-3'>
                  <TextField
                    key={index}
                    label={title}
                    type={inputType || type}
                    name={eleName}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) =>
                      onChange(eleName, value, name)
                    }
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    fieldAs={fieldAs}
                    rows={rows}
                    editMode={editMode}
                    placeholder={placeholder || `Enter ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );
            case 'comment':
              return (
                <div className='col-span-12'>
                  <TextField
                    key={index}
                    label={title}
                    type='text'
                    name={eleName}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) => {
                      onChange(eleName, value, name);
                    }}
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    fieldAs='textarea'
                    rows={rows}
                    editMode={editMode}
                    placeholder={placeholder || `Enter ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );

            case 'audio':
              return (
                <div className='col-span-12'>
                  <AudioRecorderComponent
                    deleteAudio={() => {
                      onChange(eleName, {url: '', transcription: ''}, name);
                    }}
                    setAudio={(audioSrc: any) =>{
                      onChange(eleName, audioSrc, name);
                    }
                    }
                    editMode={editMode}
                    label={title || name}
                    required={isRequired}
                    panel={panel}
                    element={element}
                    placeholder={placeholder}
                    key={index}
                    name={eleName}
                    value={formikProps.values[panelName]?.[name]}
                    formikProps={formikProps}
                    touched={touched}
                    error={error}
                  />
                </div>
              );

            default:
              return null; // Or render a default component for unknown types
          }
        })}
      </div>
    </>
  );
};

export default GeneralInfo;
