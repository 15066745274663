import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import {
  profileSettingsAction,
  userProfileSettingsAction,
} from "store/userProfilesettings";
import { Toasts } from "view/components/Toasts";
import SelectInput from "../SelectInput";
import Roles from "hooks/roles";
import apiLibrary from "services/api";
import usePermissions from "hooks/usePermissions";

export const DateFormatSelector = () => {
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const { isMyProfile } = Roles();
  const {profile} = usePermissions();

  // Getting user profile from Redux state
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const Date_formate = ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD"];
  const dispatch = useDispatch();

  // Function to update user profile settings
  const updateUserProfileSettings = async (e: any) => {
    if(!profile.canEditSettingsProfile){
      return;
    }
    try {
      const { name, value } = e.target;
      const res = await apiLibrary.Settings.updateUserProfileSettings(
        name,
        value
      );
      // Updating Redux state with new settings
      if (isMyProfile(userProfile.personId)) {
        dispatch(userProfileSettingsAction(res.data));
      }
      dispatch(profileSettingsAction(res.data));

      Toasts.success(res.message);
    } catch (error: any) {
      const errorMsg = error.response.data.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("An error occurred:", error);
    }
  };
  return (
    <div className="flex flex-col gap-1">
      <p className="text-sm font-medium capitalize text-secondaryMid dark:text-textMain">
        Display Date format
      </p>
      <SelectInput
        name="date_format"
        updateUserProfileSettings={updateUserProfileSettings}
        disabled={!profile.canEditSettingsProfile}
        value={profileSettings.dateFormat}
        data={Date_formate}
        className={'lowerCase'}
        helperText="Please be aware that your date settings will only be applied to dates displayed in the system."
      />
    </div>
  );
};
