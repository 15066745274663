// initialState.ts

import customBrandingColors from "utils/customBrandingColors";

// Define the shape of the theme state
export interface ThemeState {
  colors: typeof customBrandingColors;
}

// Define and export the initial state
export const initialThemeState: ThemeState = {
  colors: customBrandingColors,  // Initial theme (you can dynamically load this)
};
