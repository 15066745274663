import { apiClient } from "../config";

async function getallCommuities(searchString?: any) {
  const queryString = searchString ? `&search_string=${searchString}` : "";
  const res = await apiClient.get(
    `/admin/users/drop-down?entity=communities${queryString}`
  );

  return res.data;
}

export default getallCommuities;
