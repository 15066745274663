import React, { useEffect, useLayoutEffect, useState } from "react";
// Components
import {
  Filters,
  Header,
  Pagination,
  Table,
} from "Components/ResultObservations";
// Hooks
import { ReportResultTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TableProps } from "Components/Programs/Table/types";
import { fetchResultReportsAction } from "store/reportsResult";
import { IfetchResultReports } from "store/reportsResult/initialState";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import { filtersInitialState } from "store/filters/initialState";
import { reportsVersionResetFiltersAction, setResultReportsAction } from "store/filters/reducer.actions";
import ResultVersionsDirectoryMap from "Components/ResultObservations/MapBox";
import InformationCircleIcon from "assets/icons/HeroIcons/WarningIcon";
import InformationIcon from "assets/icons/HeroIcons/InformationIcon";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { resetRunReports } from "store/reportsRun/reducer.actions";

export const ResultObservations = () => {
  const { reportsId, versionId } = useParams();
 
  useLayoutEffect(() => {
    dispatch(setResultReportsAction());
  }, []);


  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const [communityLocation, setCommunityLocation] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
    currentPage,
    changePageByInput,
    csvData,
    sliceData,
  } = ReportResultTable();


  
  useLayoutEffect(() => {
    dispatch(fetchResultReportsAction(reportsId, versionId));
    saveFiltersDataToLocalStorage(reportsVersionFilter);

  }, [reportsId, versionId]);

  useEffect(() => {
    if (columnOrder.includes("longitude") && columnOrder.includes("latitude")) {
      setCommunityLocation(true);
    } else {
      setCommunityLocation(false);
    }


  }, [reportsId, versionId, sliceData, columnOrder, reportsVersionFilter]);


  useEffect(() => {
    return () => {
      dispatch(resetRunReports());
    };
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-wrap items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
                csvData={csvData}
              />
            </div>
          </div>
        </div>
        {reportsVersionFilter.viewType === "map" && !communityLocation && (
          <div className="flex items-center pb-5">
            <InformationIcon className="w-5 h-5" />
            <p className="ml-2 text-sm text-textMidLight">
              There is no specific location available for all observations in
              your report. Therefore, the map displays the distribution of
              observations across community locations.
            </p>
          </div>
        )}
        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
          currentPage={currentPage}
          changePageByInput={changePageByInput}
          csvData={csvData}
          sliceData={sliceData}
        />
      </div>
    </div>
  );
};
export default ResultObservations;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
  changePageByInput,
  currentPage,
  csvData,
  sliceData,
}) => {
  const { stateIs } = useSelector<RootState, IfetchResultReports>(
    (state) => state?.reportsResult
  );
  const tableData = table.getRowModel().rows;

  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  function _renderView(type: any) {
    switch (type) {
      case "list":
        return (
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
            {stateIs == "Idle" && tableData?.length === 0 ? (
              <NoRecordFound />
            ) : stateIs === "Pending" ? (
              <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <Table
                flexRender={flexRender}
                table={table}
                columnOrder={columnOrder}
                columns={columns}
                columnVisibility={columnVisibility}
                setSorting={setSorting}
                sorting={sorting}
              />
            )}
            <Pagination />
          </div>
        );

      case "map":
        return (
          <div className="h-[100%] w-full ">
            <ResultVersionsDirectoryMap sliceData={sliceData} />
            {/* <Pagination /> */}
          </div>
        );

      default:
        return <></>;
    }
  }

  return _renderView(reportsVersionFilter.viewType);
};
