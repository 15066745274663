import React from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { pollsAndSurveysStatusAction } from "store/filters/reducer.actions";
import { updateColumnsVisibilityByStatus } from "store/directoriesOrders/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { resetpollsAndSurveys } from "store/pollsAndSurveys/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({}) => {
  const { pollsAndSurveysFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const tabsData = [
    {
      title: "Published",
      value: "published",
    },
    {
      title: "Completed",
      value: "completed",
    },
    {
      title: "Draft",
      value: "draft",
    },
    {
      title: "Archived",
      value: "archived",
    },
    
  ];

  //handlers
  const handleLink = (linkName: string) => {
    // dispatch(resetpollsAndSurveys());
    dispatch(pollsAndSurveysStatusAction(linkName));
    dispatch(updateColumnsVisibilityByStatus(linkName));
  };

  return (
    <>
      {tabsData.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${
              pollsAndSurveysFilter.status === item.value
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
            }`}
            onClick={() => handleLink(item.value)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${
                  pollsAndSurveysFilter.status === item.value
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                }`}
              >
                {/* {sentenceCase(item)} */}
                {item.title}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
