import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import Button from "view/components/Button";

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
}: any) => {
  const { reports } = usePermissions();
  return (
    <div className="flex items-center flex-shrink-0 gap-2 mt-6">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="before"
        onClick={handleGoBackBtn}
      />
      <Button
        disabled={isSubmitting || !reports.canCreateGeospatialFilteringReports}
        type="submit"
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};

export { SubmissionButtons };
