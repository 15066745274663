function __paintShapeByGeoJsonOnMap__(
  map: any,
  sourceId: string,
  layerId: string,
  geoJson: any,
  defaultColor: any
) {
  if (!map || !sourceId || !layerId || !geoJson) {
    return;
  }


  // adding geojson source
  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  // checking if the first feature is a Point to determine the layer type
  const layerType =
    geoJson?.features?.length > 0 && geoJson.features[0].geometry.type === "Point"
      ? "circle"
      : "fill";

  if (layerType === "circle") {
    return;
  }

  // desning and registering the added geojson source layer
  map.addLayer({
    id: layerId,
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": defaultColor,
      "fill-opacity": 0.6,
    },
  });
}

export { __paintShapeByGeoJsonOnMap__ };
