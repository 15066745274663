import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { editCommunityTagsSchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeEditCommunityTagsModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import MultiSelect from "view/components/Multiselect";
import { useParams } from "react-router-dom";
import { fetchCommunityProfileAction } from "store/communityProfile/reducer.actions";
import usePermissions from "hooks/usePermissions";
// import MultiSelect from "Components/Messages/MultiSelectInput";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */
export const EditCommunityTagsModal = () => {
  // State variables

  const { communityId } = useParams<{
    communityId: string;
  }>();
  const { editCommunityTagsModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { tags, communities } = usePermissions();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [allTags, setAllTags] = useState([]);

  // Initialize formInitialValues when in editMode or templateMode
  useEffect(() => {
    fetchAllTags("");
  }, [editCommunityTagsModal?.data]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!communities.canEditTagsCommunities) {
      return;
    }
    try {
      const formValues = {
        tagIds: values.tagIds.map((tagId: any) => tagId.value),
      };

      // Add a new form
      const { data, message } = await apiLibrary.Communities.editCommunityTags(
        communityId,
        formValues
      );
      Toasts.success(message);
      handleClose();
      if (communityId) {
        dispatch(fetchCommunityProfileAction(communityId));
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeEditCommunityTagsModalAction());
  };

  const fetchAllTags = useCallback(
    async (searchQuery?: string) => {
      try {
        const res = await apiLibrary.dropdowns.getTagsDropdown(searchQuery);
        setAllTags(
          res.data.tags.map((p: any) => ({ label: p?.name, value: p?.id }))
        );
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching programs:", error);
      }
    },
    [allTags]
  );

  const handleAddTagsSearch = (query: string) => {
    fetchAllTags(query);
  };

  return (
    <Modal
      open={editCommunityTagsModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={editCommunityTagsModal.data}
          validationSchema={editCommunityTagsSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px] max-h-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid">
                        Edit Tags
                      </p>
                      <button title="close" onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                    <AddTags
                      handleAddTagsOnChange={(option: any) => {
                        setFieldValue("tagIds", option);
                      }}
                      errors={errors}
                      touched={touched}
                      programs={allTags}
                      values={values}
                      handleAddTagsSearch={handleAddTagsSearch}
                      communitiesPermissions={true}
                    />
                  </div>

                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />

                    <Button
                      disabled={
                        isSubmitting || !communities.canEditTagsCommunities
                      }
                      type="submit"
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const AddTags = ({
  touched,
  errors,
  handleAddTagsOnChange,
  programs,
  values,
  handleAddTagsSearch,
  isEditMode,
  communitiesPermissions,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const { tagIds } = values;

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
            <p className="flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ">
              Tags
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
              touched.tagIds && errors.tagIds
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
            }`}
          >
            <div className="flex flex-col items-start justify-start flex-grow w-full ">
              <MultiSelect
                // height="200px"
                placeholder="Search Tags"
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleAddTagsSearch(value);
                }}
                options={programs}
                defaultValue={tagIds}
                isMulti={true}
                closeOnSelect={false}
                transformY="translateY(0)"
                value={tagIds}
                isDisable={isEditMode}
                onChange={(option: any) => {
                  handleAddTagsOnChange(option);
                }}
                allowRemoveOption={communitiesPermissions}
                // allowRemoveOption={communitiesPermissions.canDeleteTagsCommunities}
              />
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched.tagIds && errors.tagIds ? (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {errors?.tagIds}
              </p>
            ) : (
              <p className="capitalize text-xs text-left text-textMidLight">
                15 max tags
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
