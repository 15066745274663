import { useDispatch } from "react-redux";
import { usersSearchAction } from "store/filters";
import InputField from "view/components/InputField";

interface SearchByProps {
  searchBy: any;
  data:any;
}

export const SearchBy: React.FC<SearchByProps> = ({
  searchBy,
  data
}) => {
  
  const dispatch = useDispatch();
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(usersSearchAction(value, name));

   
  };
  return (
    <div
      className="bg-bgWhite shadow-xl flex flex-col justify-start items-start w-[315px] py-2 rounded-lg dark:bg-secondaryLight"
      style={{
        boxShadow:
          "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
      }}
    >
      <div className="w-[315px]">
        <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
          <InputField
            onChange={handleChange}
            placeholder="Search"
            type="Search"
            name={searchBy}
            value={data.searchBy === searchBy ? data?.searchString : ""}
          />
        </div>
      </div>
    </div>
  );
};
