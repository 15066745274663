import TextInput from "view/components/InputField";

export const SearchBar = ({ handleMembersSearch, searchString }: any) => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start gap-3 pt-4 pb-2">
      <div className="w-full">
        <TextInput
          value={searchString}
          type="Search"
          placeholder="Search by member name"
          onChange={handleMembersSearch}
        />
      </div>
    </div>
  );
};
