import { CurrentPageInputFieldProps } from "../../types";

export const CurrentPageInputField = ({
  pageInput,
  handleChangeOnInput,
  handleBlurOnInput,
}: CurrentPageInputFieldProps) => {
  return (
    <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-14">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1 ">
        <input
          className="border-2 border-lineDark flex justify-start items-center dark:focus:border-primary self-stretch flex-grow-0 flex-shrink-0 gap-1.5 px-3  py-1 rounded dark:bg-bgtetriary dark:text-inputText dark:border-lineLight focus:border-2 focus:border-primary focus:outline-none"
          value={pageInput}
          onChange={handleChangeOnInput}
          onBlur={handleBlurOnInput}
        />
      </div>
    </div>
  );
};
