import { apiClient } from "../config";

async function updateUserProfileSettings(name: any, value: any) {
  const res = await apiClient.patch(`/admin/profile/settings`, {
    [name]: value,
  });
  return res.data;
}

export default updateUserProfileSettings;
