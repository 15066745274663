import { actions } from "react-table";
import * as actionTypes from "./action-types";
import {
  IDirectoryState,
  initialCommunitiesDirectoryOrder,
  initialDirectoryOrder,
  initialDynamicFormsDirectoryOrder,
  initialOrganizationsDirectoryOrder,
  initialUsersDirectoryOrder,
  initialProgramsDirectoryOrder,
  initialProtocolsDirectoryOrder,
  initialObservationsDirectoryOrder,
  initialManagerDirectoryOrder,
  initialQualityAssuranceObservationsDirectoryOrder,
  initialNotificationRecipientsDirectoryOrder,
  initialNotificationsDirectoryOrder,
  initialReportsDirectoryOrder,
  initialReportsProfileDirectoryOrder,
  initialReportsReportsDirectoryOrder,
  initialImportsDirectoryOrder,
  initialJoinRequestDirectoryOrder,
  // initialReportsDirectoryOrderssss,
} from "./initialState";
import { notificationsRecipientsDirectoryColumnsOrderAction } from "./reducer.actions";

export const directoryReducer = (
  state: IDirectoryState = initialDirectoryOrder,
  action: any
): IDirectoryState => {
  switch (action.type) {
    case actionTypes.CHANGE_USERS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        usersDirectory: {
          ...state.usersDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_USERS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        usersDirectory: {
          ...state.usersDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_USERS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        usersDirectory: initialUsersDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        communitiesDirectory: {
          ...state.communitiesDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        communitiesDirectory: {
          ...state.communitiesDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_COMMUNITIES_DIRECTORY_COLUMNS: {
      return {
        ...state,
        communitiesDirectory: initialCommunitiesDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        organizationsDirectory: {
          ...state.organizationsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        organizationsDirectory: {
          ...state.organizationsDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_ORGANIZATIONS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        organizationsDirectory: initialOrganizationsDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        dynamicFormDirectory: {
          ...state.dynamicFormDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        dynamicFormDirectory: {
          ...state.dynamicFormDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_DYNAMIC_FORMS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        dynamicFormDirectory: initialDynamicFormsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_DYNAMIC_FORMS_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedynamicFormsDirectory = {
        ...state.dynamicFormDirectory,
        columnVisibility: {
          ...state.dynamicFormDirectory.columnVisibility,
          dateArchived: status === "archived",
          archivedBy: status === "archived",
          dateUpdated: status !== "archived",
        },
      };
      return {
        ...state,
        dynamicFormDirectory: updatedynamicFormsDirectory,
      };
    }
    case actionTypes.CHANGE_PROGRAMS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        programsDirectory: {
          ...state.programsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        programsDirectory: {
          ...state.programsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.UPDATE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedProgramsDirectory = {
        ...state.programsDirectory,
        columnVisibility: {
          ...state.programsDirectory.columnVisibility,
          pausedSince: status === "paused",
          dataAccessPolicy: status === "draft",
        },
      };
      return {
        ...state,
        programsDirectory: updatedProgramsDirectory,
      };
    }

    case actionTypes.RESET_PROGRAMS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        programsDirectory: initialProgramsDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        protocolsDirectory: {
          ...state.protocolsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_ID: {
      const programId = action.payload;
      if (programId) {
        return {
          ...state,
          protocolsDirectory: {
            ...state.protocolsDirectory,
            columnVisibility: {
              ...state.protocolsDirectory.columnVisibility,
              programName: false,
            },
          },
        };
      } else {
        return {
          ...state,
          protocolsDirectory: initialProtocolsDirectoryOrder,
        };
      }
    }
    // }
    case actionTypes.CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        protocolsDirectory: {
          ...state.protocolsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_PROTOCOLS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        protocolsDirectory: initialProtocolsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedProtocolsDirectory = {
        ...state.protocolsDirectory,
        columnVisibility: {
          ...state.protocolsDirectory.columnVisibility,
          dateArchived: status === "archived",
          archivedBy: status === "archived",
          dateUpdated: status !== "archived",
        },
      };
      return {
        ...state,
        protocolsDirectory: updatedProtocolsDirectory,
      };
    }
    case actionTypes.CHANGE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        programsDirectory: {
          ...state.programsDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_PROGRAMS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        programsDirectory: initialProgramsDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        protocolsDirectory: {
          ...state.protocolsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        protocolsDirectory: {
          ...state.protocolsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_PROTOCOLS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        protocolsDirectory: initialProtocolsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedProtocolsDirectory = {
        ...state.protocolsDirectory,
        columnVisibility: {
          ...state.protocolsDirectory.columnVisibility,
          dateArchived: status === "archived",
          archivedBy: status === "archived",
          dateUpdated: status !== "archived",
        },
      };
      return {
        ...state,
        protocolsDirectory: updatedProtocolsDirectory,
      };
    }
    case actionTypes.CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        notificationsRecipientsDirectory: {
          ...state.notificationsRecipientsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        notificationsRecipientsDirectory:
          initialNotificationRecipientsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_NOTIFICATIONS_RECIPIENTS_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedNotificationsRecipientsDirectory = {
        ...state.notificationsRecipientsDirectory,
        columnVisibility: {
          ...state.notificationsRecipientsDirectory.columnVisibility,
        },
      };
      return {
        ...state,
        notificationsRecipientsDirectory:
          updatedNotificationsRecipientsDirectory,
      };
    }
    case actionTypes.CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        observationsDirectory: {
          ...state.observationsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        observationsDirectory: {
          ...state.observationsDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_OBSERVATIONS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        observationsDirectory: initialObservationsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS: {
      const path = action.payload;
      const updateObservationsDirectory = {
        ...state.observationsDirectory,
        columnVisibility: {
          ...state.observationsDirectory.columnVisibility,
          communityName: path !== "community",
          programName: path !== "program",
        },
      };
      return {
        ...state,
        observationsDirectory: updateObservationsDirectory,
      };
    }
    case actionTypes.UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY: {
      const Id = action.payload;
      const updateObservationsDirectory = {
        ...state.observationsDirectory,
        columnVisibility: {
          ...state.observationsDirectory.columnVisibility,
          formName: Id ? true : false,
        },
      };
      return {
        ...state,
        observationsDirectory: updateObservationsDirectory,
      };
    }

    case actionTypes.CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        qualityAssuranceObservationsDirectory: {
          ...state.qualityAssuranceObservationsDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        qualityAssuranceObservationsDirectory: {
          ...state.qualityAssuranceObservationsDirectory,
          columnVisibility: action.payload,
        },
      };
    }
    case actionTypes.RESET_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        qualityAssuranceObservationsDirectory:
          initialQualityAssuranceObservationsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_QUALITY_ASSURANCE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS: {
      const { status, role } = action.payload;
      if (role && status === "completed") {
        return {
          ...state,
          qualityAssuranceObservationsDirectory: {
            ...state.qualityAssuranceObservationsDirectory,
            columnVisibility: {
              ...state.qualityAssuranceObservationsDirectory.columnVisibility,
              unreadMessages: false,
              flaggedSections: false,
            },
          },
        };
      } else {
        return {
          ...state,
          qualityAssuranceObservationsDirectory: {
            ...state.qualityAssuranceObservationsDirectory,
            columnVisibility: {
              ...state.qualityAssuranceObservationsDirectory.columnVisibility,
              unreadMessages: status !== "archived",
              flaggedSections: status !== "archived",
            },
          },
        };
      }
    }
    case actionTypes.CHANGE_MANAGERS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        managersDirectory: {
          ...state.managersDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_MANAGERS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        managersDirectory: {
          ...state.managersDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.UPDATE_MANAGERS_COLUMNS_VISIBILITY_BY_TYPE: {
      const type = action.payload;

      const updatedManagersDirectory = {
        ...state.managersDirectory,
        columnVisibility: {
          ...state.managersDirectory.columnVisibility,
          role: type === "internal",
          community: type === "internal",
          activeObservations: type === "external",
          status: type !== "internal",
          activeReportAccess: type === "external_data_end_viewer",
          dataEndViewerSince: type === "external_data_end_viewer",
          reviewerSince: type !== "external_data_end_viewer",
          endOfAccess: type === "external",
        },
      };
      return {
        ...state,
        managersDirectory: updatedManagersDirectory,
      };
    }
    case actionTypes.RESET_MANAGERS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        managersDirectory: initialManagerDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        notificationsDirectory: {
          ...state.notificationsDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        notificationsDirectory: {
          ...state.notificationsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_NOTIFICATIONS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        notificationsDirectory: initialNotificationsDirectoryOrder,
      };
    }
    case actionTypes.CHANGE_REPORTS_COLUMNS_ORDER: {
      return {
        ...state,
        reportsDirectory: {
          ...state.reportsDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_REPORTS_COLUMNS_VISIBILITY: {
      return {
        ...state,
        reportsDirectory: {
          ...state.reportsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_REPORTS_COLUMNS: {
      return {
        ...state,
        reportsDirectory: initialReportsDirectoryOrder,
        // reportsDirectory: initialReportsDirectoryOrderssss,
      };
    }
    case actionTypes.CHANGE_REPORTS_PROFILE_COLUMNS_ORDER: {
      return {
        ...state,
        reportsProfileDirectory: {
          ...state.reportsProfileDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_REPORTS_PROFILE_COLUMNS_VISIBILITY: {
      return {
        ...state,
        reportsProfileDirectory: {
          ...state.reportsProfileDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_REPORTS_PROFILE_COLUMNS: {
      return {
        ...state,
        reportsProfileDirectory: initialReportsProfileDirectoryOrder,
      };
    }
    case actionTypes.CHANGE_REPORTS_RESULT_COLUMNS_ORDER: {
      return {
        ...state,
        reportResultDirectory: {
          ...state.reportResultDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_REPORTS_RESULT_COLUMNS_VISIBILITY: {
      return {
        ...state,
        reportResultDirectory: {
          ...state.reportResultDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_REPORTS_RESULT_COLUMNS: {
      return {
        ...state,
        reportResultDirectory: initialReportsReportsDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_REPORTS_ANALYTTICS_COLUMNS_ORDER: {
      return {
        ...state,
        reportsAnalyticsDirectory: {
          ...state.reportsAnalyticsDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_REPORTS_ANALYTTICS_COLUMNS_VISIBILITY: {
      return {
        ...state,
        reportsAnalyticsDirectory: {
          ...state.reportsAnalyticsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_IMPORTS_COLUMNS_ORDER: {
      return {
        ...state,
        importsDirectory: {
          ...state.importsDirectory,
          columnOrder: action.payload,
        },
      };
    }

    case actionTypes.CHANGE_IMPORTS_COLUMNS_VISIBILITY: {
      return {
        ...state,
        importsDirectory: {
          ...state.importsDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_IMPORT_COLUMNS: {
      return {
        ...state,
        importsDirectory: initialImportsDirectoryOrder,
      };
    }

    case actionTypes.CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_ORDER: {
      return {
        ...state,
        dynamicFormDirectory: {
          ...state.dynamicFormDirectory,
          columnOrder: action.payload,
        },
      };
    }
    case actionTypes.CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_VISIBILITY: {
      return {
        ...state,
        dynamicFormDirectory: {
          ...state.dynamicFormDirectory,
          columnVisibility: action.payload,
        },
      };
    }

    case actionTypes.RESET_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS: {
      return {
        ...state,
        dynamicFormDirectory: initialDynamicFormsDirectoryOrder,
      };
    }

    case actionTypes.UPDATE_POLLS_AND_SURVEYS_COLUMNS_VISIBILITY_BY_STATUS: {
      const status = action.payload;
      const updatedynamicFormsDirectory = {
        ...state.dynamicFormDirectory,
        columnVisibility: {
          ...state.dynamicFormDirectory.columnVisibility,
          dateArchived: status === "archived",
          archivedBy: status === "archived",
          dateUpdated: status !== "archived",
        },
      };
      return {
        ...state,
        dynamicFormDirectory: updatedynamicFormsDirectory,
      };
    }

    case actionTypes.CHANGE_JOIN_REQUEST_COLUMN_COLUMN_ORDER: {
      const status = action.payload;
      const updatedJoinRequestedMembersDirectory = {
        ...state.joinRequestDirectory,
        columnVisibility: {
          ...state.joinRequestDirectory.columnVisibility,
          requests: status === "requested",
          declinedOn: status === "declined",
        },
      };
      return {
        ...state,
        joinRequestDirectory: updatedJoinRequestedMembersDirectory,
      };
    }

    case actionTypes.RESET_JOIN_REQUEST_COLUMNS: {
      return {
        ...state,
        joinRequestDirectory: initialJoinRequestDirectoryOrder,
      };
    }
    default:
      return state;
  }
};
