import { TIME_FORMATS } from "./timeFormats";

export const DATE_FORMATS = {
    DEFAULT: "YYYY-MM-DD",
    FULL_DATE: "YYYY-MM-DD HH:mm:ss",
    SHORT_DATE: "YYYY-MM-DD",
    LONG_DATE: "dddd, MMMM Do YYYY",
    ISO_8601: "YYYY-MM-DDTHH:mm:ssZ",
    TIME_ONLY: TIME_FORMATS.FULL_TIME,
    DATE_TIME: "YYYY-MM-DD HH:mm:ss",
    DATE_TIME_WITH_ZONE: "YYYY-MM-DDTHH:mm:ssZ",
    MONTH_DAY_YEAR: "MM/DD/YYYY",
    DAY_MONTH_YEAR: "DD/MM/YYYY",
    MONTH_NAME_DAY_YEAR: "MMMM Do, YYYY",
    DAY_MONTH_NAME_YEAR: "Do MMMM YYYY",
    COMPACT_DATE: "YYYYMMDD",
    FRIENDLY_DATE: "dddd, MMMM Do YYYY, h:mm:ss a",
  };
  
  export const DATE_FORMAT_ALIASES = {
    shortDate: DATE_FORMATS.SHORT_DATE,
    longDate: DATE_FORMATS.LONG_DATE,
    iso: DATE_FORMATS.ISO_8601,
    time: DATE_FORMATS.TIME_ONLY,
    dateTime: DATE_FORMATS.DATE_TIME,
    dateTimeWithZone: DATE_FORMATS.DATE_TIME_WITH_ZONE,
    monthDayYear: DATE_FORMATS.MONTH_DAY_YEAR,
    dayMonthYear: DATE_FORMATS.DAY_MONTH_YEAR,
    monthNameDayYear: DATE_FORMATS.MONTH_NAME_DAY_YEAR,
    dayMonthNameYear: DATE_FORMATS.DAY_MONTH_NAME_YEAR,
    compactDate: DATE_FORMATS.COMPACT_DATE,
    friendlyDate: DATE_FORMATS.FRIENDLY_DATE,
  };
  