import { MoveToLastPageBtnProps } from "../../types";

export const MoveToLastPageBtn = ({
  page,
  totalPages,
  handleClickOnBtn,
}: MoveToLastPageBtnProps) => {
  return (
    <button
      disabled={page === totalPages}
      className={`flex justify-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight dark:hover:border-primary group items-center disabled:cursor-not-allowed disabled:opacity-50 flex-grow-0 flex-shrink-0 w-[88px] gap-1 px-3 py-1 rounded-lg border-2 border-primary bg-white hover:bg-primaryLight`}
      onClick={handleClickOnBtn}
    >
      <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0">
        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary dark:text-inputText group-hover:text-primary">
          Last
        </p>
      </div>
    </button>
  );
};
