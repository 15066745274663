import { GET_IMPORT_PROPERTY_DROPDOWN, RESET_DATA } from "./action-types";
import initialState from "./initialState";
export const importsPropertyDropdownReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case GET_IMPORT_PROPERTY_DROPDOWN:
      return {
        ...state,
        data: {
          properties: action.payload?.data.properties,
        },
        message: action.payload?.message,
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
