import { Dispatch } from "redux";
import {
  GET_ADDITIONAL_INFORMATION,
  GET_ALL_PROFILE_ROLES,
  RESET_DATA,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchProfileRolesAction =
  (personId: any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    console.log("personId=>", personId);
    
    try {
      const { data } = await apiLibrary.profileRoles.getProfileRoles(personId);
      dispatch({
        type: GET_ALL_PROFILE_ROLES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the notifications:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };

export const fetchAdditionalInformationAction =
  (personaId :any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    try {
      const { data } = await apiLibrary.profileRoles.getAdditionalInformation(personaId);
    
      dispatch({
        type: GET_ADDITIONAL_INFORMATION,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the notifications:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
