import { SearchIcon } from "assets/icons/HeroIcons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select, { SelectInstance, components } from "react-select";
import { RootState } from "store";
import { DarkMode } from "store/darkMode/initialState";

interface DropdownProps {
  label: string;
  error?: any;
  name: string;
  value?: any;
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  placeholder?: any;
  required?: boolean;
  options?: any[];
  isDisabled?: boolean;
  onSearch?: (inputValue: string) => void;
  defaultValue?: any;
  searchable?: boolean;
}

interface OptionType {
  label: string;
  value: number;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  error,
  name,
  value,
  handleChange,
  handleBlur,
  touched,
  placeholder,
  required,
  options,
  isDisabled,
  onSearch,
  defaultValue,
  searchable
}) => {

  const selectRef = useRef<SelectInstance | null>(null);
  // Handle change event for react-select
  const onSelectChange = (selectedOption: any) => {
    handleChange(selectedOption ? selectedOption : "");
  };

  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  // Handle search input change
  const handleInputChange = (inputValue: string) => {
    if (onSearch) {
      onSearch(inputValue);
    }
  };

  useEffect(() => {
    if (selectRef?.current && name === "formId" && value == null) {
      // Use selectRef to clear the value
      selectRef.current?.clearValue();
    }
  }, [value, name]);

  const SearchIndicator = (props: any) => (
    <components.IndicatorsContainer {...props}>
      <SearchIcon className="absolute left-[8px] fill-textLightExtra w-6" />
      {props.children}
    </components.IndicatorsContainer>
  );

  const EmptyComponent = () => null;

  return (
    <div className="flex flex-col flex-grow w-full rounded-lg">
      <div className="relative flex flex-col py-2">
        <p className="w-full pb-1 text-[15px] text-left capitalize font-Overpass font-regular text-textMid dark:text-caption ">
          {label} {required && <span>*</span>}
        </p>
        <Select
          classNamePrefix="my-react-select"
          isSearchable={searchable}
          ref={selectRef}
          value={options?.find((option) => option.value === value)}
          defaultValue={defaultValue}
          onChange={onSelectChange}
          options={options}
          onBlur={handleBlur}
          isDisabled={isDisabled}
          placeholder={placeholder || "Select"}
          className="font-Overpass text-textMid text-[15px] placeholder:text-textLightExtra placeholder:font-semiBold  my-react-select-container"
          onInputChange={handleInputChange}
          styles={{
            control: (provided, state) => ({
              ...provided,
              // Add a red border if there's an error and the field has been touched
              borderColor: error && touched ? "#BF200B" : isDarkMode ? "#f3f5f614" : provided.borderColor,
              borderStyle: "solid !important",
              // Additional styles for focus or other states
              "&:hover": {
                borderColor:
                  error && touched ? "#BF200B" : isDarkMode ? "#f3f5f614" : provided.borderColor,
              },
            }),
            placeholder: (provided) => ({
              ...provided,
              marginTop: "5px",
              color: "#9B9FA5",
              fontWeight: "500",
              fontFamily: "Overpass",
              textTransform: "capitalize"
            }),
            option: (provided, state) => ({
              ...provided,
              cursor: "pointer",
              color: state.isSelected ? '#005C89' : '#2C3236',
              background: state.isSelected || state.isFocused ? "#DBE8EE" : "none",
              fontWeight: state.isSelected ? "500" : "400",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              paddingLeft: searchable ? "35px" : "12px"
            })
            // Add other custom styles if needed
          }}
          components={{
            IndicatorsContainer: searchable ? SearchIndicator : EmptyComponent
          }}
          menuPosition="fixed"
          menuPlacement="auto"
        />
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {touched && error && (
            <p className="flex-grow text-xs text-left text-accent_1Dark">
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dropdown;
