import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { panelsName } from "../../../panelsTitle";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateShapePickers,
} from "store/surveyJS";

const useHandleMapMovement = (currentQuestionEle: any, map: any) => {
  const [isCenterPointSetup, setIsCenterPointSetup] = useState(false);
  const [regionTypeShapePicker, setRegionTypeShapePicker] = useState<any>(null);
  const [viewTypeShapePicker, setViewTypeShapePicker] = useState<any>(null);
  const [retrievalShapePicker, setRetrievalShapePicker] = useState<any>(null);
  const [struckShapePicker, setStruckShapePicker] = useState<any>(null);

  const {
    shapePickers,
    selectedQuestionElement,
    geographicalPanelMapQuestions,
    harvestPanelMapQuestions,
    surveyActiveTab,
  } = useSelector((state: RootState) => state.surveyJS);

  const selectedQuestionType = selectedQuestionElement?.isPanel
    ? selectedQuestionElement.name
    : selectedQuestionElement?.getType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      !isCenterPointSetup &&
      (selectedQuestionType === panelsName.GEOGRAPHICAL_INFORMATION ||
        selectedQuestionType === panelsName.HARVEST)
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,
        });
      }

      setIsCenterPointSetup(true);
    }
  }, [
    currentQuestionEle,
    map,
    selectedQuestionElement,
    geographicalPanelMapQuestions,
    harvestPanelMapQuestions,
  ]);
  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      !isCenterPointSetup &&
      selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION &&
      selectedQuestionType !== panelsName.HARVEST
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,
        });
      }
      // update map center values
      else {
        map.on("load", () => {
          updateShapePickersCenterPoint(
            currentQuestionEle,
            map,
            selectedQuestionType,
            shapePickers,
            dispatch
          );
        });
      }

      setIsCenterPointSetup(true);
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      (selectedQuestionType === panelsName.GEOGRAPHICAL_INFORMATION ||
        panelsName.HARVEST === selectedQuestionType)
    ) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }

        switch (selectedQuestionType) {
          case panelsName.GEOGRAPHICAL_INFORMATION:
            updateGeographicalInfoPanelCenterPoint(
              currentQuestionEle,
              map,
              geographicalPanelMapQuestions,
              dispatch,
              setRegionTypeShapePicker,
              setViewTypeShapePicker
            );
            break;

          case panelsName.HARVEST:
            updateHarvestPanelCenterPoint(
              currentQuestionEle,
              map,
              harvestPanelMapQuestions,
              dispatch,
              setRetrievalShapePicker,
              setStruckShapePicker
            );
            break;

          default:
        }
      });
    }
  }, [
    currentQuestionEle,
    map,
    geographicalPanelMapQuestions,
    selectedQuestionElement,
    harvestPanelMapQuestions,
  ]);
  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION &&
      panelsName.HARVEST !== selectedQuestionType
    ) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        updateShapePickersCenterPoint(
          currentQuestionEle,
          map,
          selectedQuestionType,
          shapePickers,
          dispatch
        );
      });
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (viewTypeShapePicker) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          viewTypeShapePicker,
        })
      );
    }
  }, [viewTypeShapePicker]);

  useEffect(() => {
    if (regionTypeShapePicker) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          regionTypeShapePicker,
        })
      );
    }
  }, [regionTypeShapePicker]);

  useEffect(() => {
    if (retrievalShapePicker) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          retrievalShapePicker,
        })
      );
    }
  }, [retrievalShapePicker]);

  useEffect(() => {
    if (struckShapePicker) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          struckShapePicker,
        })
      );
    }
  }, [struckShapePicker]);

  return null;
};

function updateShapePickersCenterPoint(
  currentQuestionEle: any,
  map: any,
  selectedQuestionType: any,
  shapePickers: any,
  dispatch: any
) {
  const updatedCurrentShapePickers = { ...currentQuestionEle };

  // update current element
  updatedCurrentShapePickers.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };

  updatedCurrentShapePickers.zoomLevel = map.getZoom();

  if (
    selectedQuestionType !== panelsName.HARVEST &&
    selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
  ) {
    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePickers,
      (updatedshapePickers: any) => {
        dispatch(updateShapePickers(updatedshapePickers));
      }
    );
  }
}
function updateGeographicalInfoPanelCenterPoint(
  currentQuestionEle: any,
  map: any,
  geographicalPanelMapQuestions: any,
  dispatch: any,
  setRegionTypeShapePicker: any,
  setViewTypeShapePicker: any
) {
  const updatedCurrentShapePickers = { ...currentQuestionEle };

  // update current element
  updatedCurrentShapePickers.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };

  updatedCurrentShapePickers.zoomLevel = map.getZoom();

  const variant =
    currentQuestionEle.id ===
    geographicalPanelMapQuestions.regionTypeShapePicker.id
      ? "region"
      : "view";

  if (variant === "region") {
    setRegionTypeShapePicker(updatedCurrentShapePickers);
  } else {
    setViewTypeShapePicker(updatedCurrentShapePickers);
  }
}
function updateHarvestPanelCenterPoint(
  currentQuestionEle: any,
  map: any,
  harvestPanel: any,
  dispatch: any,
  setRetrievalShapePicker: any,
  setStruckShapePicker: any
) {
  const updatedCurrentShapePickers = { ...currentQuestionEle };

  // update current element
  updatedCurrentShapePickers.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };

  updatedCurrentShapePickers.zoomLevel = map.getZoom();

  const variant =
    currentQuestionEle.id === harvestPanel.retrievalShapePicker.id
      ? "retrievalShapePicker"
      : "struckShapePicker";

  if (variant === "retrievalShapePicker") {
    setRetrievalShapePicker(updatedCurrentShapePickers);
  } else {
    setStruckShapePicker(updatedCurrentShapePickers);
  }

  // dispatch(
  //   updateAddedHarvestPanelMapQuestions({
  //     ...harvestPanel,
  //     shapePicker: updatedCurrentShapePickers,
  //   })
  // );
}

export { useHandleMapMovement };
