import { apiClient } from "../config";

async function getReportsFilteringDropdowns(id:any , queryParams: any) {
  const res = await apiClient.get(`/admin/reports/entity-dropdown`, {
    params: queryParams,
  })
  return res.data;
}

export default getReportsFilteringDropdowns;
