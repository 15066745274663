import React from "react";
import { ColumnHeadingProps } from "../../types";
import { KEYS_TO_BE_REMOVED } from "../..";
import parseArray from "utils/parseArray";
import { RowsSortingBtn } from "../index";

export const ColumnHeading = ({
  headerGroup,
  sorting,
  flexRender,
  handleClickOnHead,
}: ColumnHeadingProps) => {
  return (
    <React.Fragment key={headerGroup.id}>
      {parseArray(headerGroup.headers, KEYS_TO_BE_REMOVED).map(
        (header: any) => {
          // if (header.column.columnDef.id === "id") {
          //   return null;
          // }
          const isSorted = sorting.some(
            (sort: any) => sort.columnId === header.column.id
          );
          const arrowDirection: "asc" | "dsc" = sorting.find(
            (sort: any) => sort.columnId === header.column.id
          )?.type;

          return (
            <th
              key={header.id}
              className={` pl-2 pr-6 h-[47px] whitespace-nowrap  h-[47px] dark:bg-bgtetriary ${isSorted ? "add_border_bottom" : ""
                } ${header.column.getCanSort() ? "cursor-pointer select-none" : ""
                }`}
              onClick={() => handleClickOnHead(header)}
            >
              <div
                className={`flex items-center w-full svg-container px-1 relative h-[47px]`}
              >
                <div className="flex justify-start items-start flex-grow relative py-0.5">
                  <p className="flex-grow px-2 text-sm font-medium capitalize text-start text-secondary dark:text-caption">
                    {header.id === "name" ? "Group Name" : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort()}
                  </p>
                </div>
                <RowsSortingBtn
                  header={header}
                  isSorted={isSorted}
                  direction={arrowDirection}
                />
              </div>
            </th>
          )
          // if (header.column.id === "select") {
          //   return (
          //     <th
          //       key={header.id}
          //       className={`pl-3 whitespace-nowrap px-3 h-[47px] dark:bg-bgtetriary w-8 sticky left-0 bg-bgBluish_2 dark:bg-bgtetriary z-10`}
          //     >
          //       <div
          //         className={`flex items-center w-full svg-container px-1 relative h-[47px]`}
          //       >
          //         <div className="flex justify-start items-start flex-grow relative py-0.5">
          //           <p className="flex-grow text-sm font-medium capitalize text-start text-secondary">
          //             {flexRender(
          //               header.column.columnDef.header,
          //               header.getContext()
          //             )}
          //           </p>
          //         </div>
          //       </div>
          //     </th>
          //   );
          // } else {
          //   return (
          //     <th
          //       key={header.id}
          //       className={`pl-3 whitespace-nowrap px-3 h-[47px] dark:bg-bgtetriary ${
          //         isSorted ? "add_border_bottom" : ""
          //       } ${
          //         header.column.getCanSort() ? "cursor-pointer select-none" : ""
          //       }`}
          //       onClick={() => handleClickOnHead(header)}
          //     >
          //       <div
          //         className={`flex items-center w-full svg-container px-1 relative h-[47px]`}
          //       >
          //         <div className="flex justify-start items-start flex-grow relative py-0.5">
          //           <p className="flex-grow px-2 text-sm font-medium capitalize text-start text-secondary dark:text-caption">
          //             { header.id ==="name"?"Group Name":flexRender(
          //               header.column.columnDef.header,
          //               header.getContext()
          //             )}
          //             {header.column.getCanSort()}
          //           </p>
          //         </div>
          //         <RowsSortingBtn
          //           header={header}
          //           isSorted={isSorted}
          //           direction={arrowDirection}
          //         />
          //       </div>
          //     </th>
          //   );
          // }
        }
      )}
    </React.Fragment>
  );
};
