import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
} from "@reach/combobox";
import LocationMarkerIcon from "assets/icons/HeroIcons/LocationMarkerIcon";
import apiClient from "services/apiClient";
import _ from "lodash";
import {
  FolderListIcon,
  OfficeBuildingIcon,
  User2Icon,
  UserGroupIcon,
} from "assets/icons/HeroIcons";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
import { useNavigate } from "react-router-dom";

interface GlobalSearchProps {}

const GlobalSearch: React.FC<GlobalSearchProps> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const navigate = useNavigate();
  const { globalSearch } = usePermissions();

  const getGlobalSearchData = async (value: string) => {
    setIsLoading(true);
    setData([]);
    try {
      const params: any = {};
      if (value !== "") {
        params.searchString = value;
      } else {
        setData([]);
        setIsLoading(false);
        return;
      }
      const { data } = await apiClient.get(`/admin/global-search`, {
        params: params,
      });
      setData(data.data.entities);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (item: any): void => {
    const linkPath = redirectionLink(item.type, item.id);
    navigate(linkPath);
    setInputValue("");
    setData([]);
  };

  const debouncedSearch = _.debounce(getGlobalSearchData, 1000);

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map((item: any) => (
      <div
        key={item.id}
        onClick={() => handleSelect(item)}
        className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-2 px-2 py-2 whitespace-pre-line cursor-pointer hover:text-primary hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight dark:hover:text-primary"
      >
        {ItemIcon(item.type)}
        <p className="flex justify-start  font-semibold  overflow-hidden break-all text-overflow-ellipsis whitespace-pre-line w-full items-center flex-grow relative py-0.5 dark:text-caption dark:hover:text-primary">
          {item.name}
        </p>
      </div>
    ));

    return <>{suggestions}</>;
  };

  return (
    globalSearch.canViewGlobalSearch && (
      <Combobox
        style={{ width: "100%", marginTop: "10px" }}
        onSelect={handleSelect}
        aria-labelledby="demo"
      >
        <div className="flex flex-col flex-grow w-full rounded-lg">
          <div className="relative flex flex-col">
            <>
              <div
                className={`flex items-center border rounded bg-white ${"border-lineDark dark:border-lineLight"}`}
              >
                {/* <button type="button" className="pl-2 focus:outline-none">
                <LocationMarkerIcon />
              </button> */}

                <ComboboxInput
                  type="search"
                  className={`dark:text-textMain flex-1 justify-start items-center w-full focus:outline-none gap-1.5 px-3 py-2 rounded bg-bgWhite dark:bg-secondaryLight`}
                  placeholder={"Search"}
                  value={inputValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputValue(e.target.value);
                    debouncedSearch(e.target.value);
                    if (e.target.value === "") {
                      setData([]);
                    }
                  }}
                />
              </div>
            </>
          </div>
        </div>
        <ComboboxPopover
          style={{ zIndex: 9999, maxHeight: "500px", overflowY: "auto" }}
        >
          {isLoading ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <ComboboxList>{renderSuggestions()}</ComboboxList>
          )}
        </ComboboxPopover>
      </Combobox>
    )
  );
};

export default GlobalSearch;

function ItemIcon(type: string) {
  switch (type) {
    case "program":
      return <FolderListIcon fill="#2C3236" width={20} height={20} />;
    case "organization":
      return <OfficeBuildingIcon fill="#2C3236" width={20} height={20} />;
    case "person":
      return <User2Icon fill="#2C3236" width={20} height={20} />;
    case "community":
      return <UserGroupIcon fill="#2C3236" width={20} height={20} />;
    // case "place":
    //   return <PlaceIcon fill="#2C3236" width={20} height={20} />;
    default:
      return <></>;
  }
}

function redirectionLink(type: string, id: number) {
  switch (type) {
    case "program":
      return `/programs/${id}/profile`;
    case "organization":
      return `/organizations/${id}/profile`;
    case "person":
      return `/profile/${id}/person`;
    case "community":
      return `/community/${id}/profile`;
    // case "place":
    //   return `/places/${id}`;
    default:
      return `/`;
  }
}
