import React, { useCallback, useEffect, useRef, useState } from "react";
// Components
import { Toasts } from "view/components/Toasts";
// Store Utils
import { useDispatch } from "react-redux";
// API Services
import apiLibrary from "services/api";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ReportSettings } from "./components/ReportSettings";

import { ReportName } from "./components/ReportName";
import { BreadCrumbs } from "./components/BreadCrumbs";
import CustomAccordion from "./components/CustomAccordian";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { useSelector } from "react-redux";
import { ISummaryReports } from "store/reportsSummary";
import { RootState } from "store";
import { Stepper, reportStepNames } from "store/addReportStepper/initialState";
import { Permissions } from "./components/Permissions";
import { ReportExporting } from "./components/ReportExporting";
import { ReportGeospatialQueryBuilding } from "./components/ReportGeospatialQueryBuilding";
import { ColumnnsConfiguration } from "./components/ColumnnsConfiguration";
import { Filtering } from "./components/Filtering";
import { initialReportsSummaryData } from "store/reportsSummary/initialState";
import { FormikProps } from "formik";
import useReportsStepper, { Step } from "hooks/useReportsStepper";
import { Header } from "../Header";
import usePermissions from "hooks/usePermissions";

interface ReportFormStepperProps {}

export const ReportFormStepper: React.FC<ReportFormStepperProps> = () => {
  const formRef = useRef<FormikProps<any>>(null);
  const { reportsId } = useParams();
  const [reportSummary, setReportSummary] = useState<ISummaryReports>(
    initialReportsSummaryData
  );
  const { reports } = usePermissions();
  const dispatch = useDispatch();
  const {
    stepper,
    moveToSpecificStep,
    setReportId,
    setReportName,
    resetStepper,
    moveToPreviousStep,
    moveToNextStep,
    setAnythingUpdatedThisStep,
  } = useReportsStepper();

  const [activeStep, setActiveStep] = useState<any>({
    title: "Name",
    step: "reportName",
    isActive: true,
    isSubmitted: false,
    hasAccess: false,
    needsReview: false,
    isUpdated: false,
  });

  useEffect(() => {
    if (stepper.steps && stepper.steps.length > 0) {
      const currentActiveStep: Step | undefined = stepper.steps.find(
        (step: Step) => step.isActive
      );

      if (currentActiveStep) {
        setActiveStep(currentActiveStep);
      }
    }
  }, [stepper.steps]);

  const isLastStep = activeStep.step === reportStepNames.filtering;

  const fetchReportsSummary = async (reportsId: any) => {
    try {
      const { data } = await apiLibrary.Reports.getSummaryReports(reportsId);
      setReportSummary(data);
      setReportName(data?.reportName);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  useEffect(() => {
    const effectiveReportId = stepper.currentReportId ?? reportsId;

    if (effectiveReportId != null) {
      fetchReportsSummary(effectiveReportId);
    }

    if (reportsId !== undefined) {
      const numericReportId = Number(reportsId);
      if (!isNaN(numericReportId)) {
        setReportId(numericReportId);
      }
    }
  }, [stepper.currentReportId, reportsId]);

  useEffect(() => {
    return () => {
      resetStepper();
    };
  }, []);

  function moveToSpecific(currentStep: Step) {
    if (
      currentStep.step !== reportStepNames.reportName &&
      !stepper.currentReportId &&
      formRef.current
    ) {
      const allFieldsTouched = markAllFieldsTouched(formRef.current.values);
      formRef.current.setTouched(allFieldsTouched);
      formRef.current.validateForm();
    } else {
      moveToSpecificStep(currentStep.step);
    }
  }

  function _renderStepContent() {
    switch (activeStep.step) {
      case reportStepNames.reportName:
        return (
          <ReportName
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            ref={formRef}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      case reportStepNames.permissions:
        return (
          <Permissions
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      case reportStepNames.reportSettings:
        return (
          <ReportSettings
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      case reportStepNames.exporting:
        return (
          <ReportExporting
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      case reportStepNames.geospatialFiltering:
        return (
          <ReportGeospatialQueryBuilding
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );

      case reportStepNames.filtering:
        return (
          <Filtering
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      case reportStepNames.columnsConfiguration:
        return (
          <ColumnnsConfiguration
            activeStep={activeStep}
            steps={stepper.steps}
            isLastStep={isLastStep}
            reportId={reportSummary.id}
            reportSummary={reportSummary}
            fetchReportsSummary={fetchReportsSummary}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={() => moveToNextStep(activeStep.step)}
            setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <div className="flex flex-col w-full">
      <Header reportName={stepper.reportName} />

      <div className="flex flex-col justify-center w-full px-5 mb-10">
        <BreadCrumbs
          steps={stepper.steps}
          moveToSpecificStep={moveToSpecific}
        />
        {activeStep.step !== reportStepNames.filtering ? (
          <CustomAccordion
            expandIcon={
              <CheveronUpIcon height={16} width={16} fill="#2C3236" />
            }
            detailsClassName="mt-[-15px]"
            summaryClassName="YOUR_CLASS_NAME_HERE"
            heading={activeStep.title}
          >
            <div className="rounded-lg bg-bgWhite">{_renderStepContent()}</div>
          </CustomAccordion>
        ) : (
          _renderStepContent()
        )}
      </div>
    </div>
  );
};

const markAllFieldsTouched = (fields: Record<string, any>) => {
  const touchedFields = Object.keys(fields).reduce(
    (acc, key) => {
      acc[key] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  return touchedFields;
};

export function checkIsAnythingUpdatedThisStep(
  steps: Step[],
  currentStep: string
) {
  return steps.find((step) => step.step === currentStep)?.isUpdated ?? false;
}
