import { apiClient } from "../config";

async function exitCommunity(id: any, access: any) {
  const url = `/admin/communities/access/${id}`;
  const res = await apiClient.put(url, null, {
    params: {
      access: access,
    },
  });
  return res.data;
}

export default exitCommunity;
