import apiClient from "services/apiClient";

async function addMembersToProgram(programId: any, data: any) {
  const url = `/admin/programs/members`;

  const res = await apiClient.post(url, {
    data,
    programId,
  });

  return res.data;
}

export default addMembersToProgram;
