import { apiClient } from "../config";
interface RequestParams {
  searchString: string | undefined;
  entity: string;
  page: number;
  connected: boolean;
}

async function getSpeciesAndObjectsByCommunity(
  id: number,
  entity: string,
  connected: boolean = false,
  searchString?: string,
  page?: number
) {
  const params: RequestParams = {
    entity,
    searchString: searchString,
    page: 1,
    connected,
  };

  if (page && page > 0) {
    params.page = page;
  }

  const res = await apiClient.get(`/admin/communities/${id}/entity-dropdown`, {
    params,
  });
  return res.data;
}

export default getSpeciesAndObjectsByCommunity;
