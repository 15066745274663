import apiClient from "services/apiClient";

async function getAllContactPersons(queryParams: any) {
    const res = await apiClient.get(`/admin/conversations/contact-persons`, {
    params: queryParams,
  })
    return res.data;
}

export default getAllContactPersons;
