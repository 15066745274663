import { Row } from "@tanstack/react-table";
import CustomCheckbox from "view/components/CheckBox";

export interface Person {
  activeOn: null;
  addresses: Address[];
  apartment: string;
  city: null;
  communities: number;
  country: null;
  phoneNumber: null;
  programs: number;
  role: string;
  stateRegion: null;
  status: string;
  virtual: boolean;
  zipCode: null;
  name: Name;
}

interface Address {
  address: string;
  isPrimary: boolean;
}

interface Name {
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}
type CustomColumnDef<T> = {
  id: string;
  header: any;
  accessor: (data: T) => any;
  cell: (info: { row: Row<T> }) => JSX.Element;
  canSort: boolean;
};

export function createColumnsConfig<T>(data: T): CustomColumnDef<T>[] {
  const columns: CustomColumnDef<T>[] = [];

  // Add the select column as the first column
  columns.push({
    id: "select",
    header: ({ table }: any) => (
      <CustomCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={
          table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
        }
        onChange={table.getToggleAllRowsSelectedHandler()}
        inputProps={{ "aria-label": "select all rows" }}
      />
    ),
    accessor: () => "", // Modify this as per your data structure
    cell: ({ row }) => (
      <div className="px-1">
        <CustomCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          inputProps={{ "aria-label": "select row" }}
        />
      </div>
    ),
    canSort: false,
  });

  for (const key in data) {
    const header = key.replace(/([A-Z])/g, " $1"); // Add space before capital letters
    const capitalizedHeader = header.charAt(0).toUpperCase() + header.slice(1); // Capitalize the first letter
    if (key !== "select") {
      columns.push({
        id: key.toString(),
        header: capitalizedHeader,
        accessor: (data: any) => data[key as keyof T],
        cell: (info: any) => info.getValue(),
        canSort: true,
      });
    }
  }

  return columns;
}

type DefaultCustomColumnDef<T> = {
  id: string;
  header: any; // Assuming the header can be a string or React node
  accessor: (data: T) => any;
  canSort: boolean;
  cell?: (props: { row: Row<T> }) => JSX.Element;
  isVisible: boolean;
};

export function createDefaultColumnsConfig<T>(
  defaultColumns: string[]
): DefaultCustomColumnDef<T>[] {
  const columns: DefaultCustomColumnDef<T>[] = [];
  // Add the select column as the first column
  columns.push({
    id: "select",
    header: ({ table }: any) => (
      <CustomCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={
          table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
        }
        onChange={table.getToggleAllRowsSelectedHandler()}
        inputProps={{ "aria-label": "select all rows" }}
      />
    ),
    accessor: (data: T) => "",
    canSort: false,
    cell: ({ row }) => {
      return (
        <CustomCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          inputProps={{ "aria-label": "select row" }}
        />
      );
    },
    isVisible: true,
  });

  defaultColumns?.forEach((columnName, index) => {
    const header = columnName.replace(/([A-Z])/g, " $1");
    const capitalizedHeader = header.charAt(0).toUpperCase() + header.slice(1);
    if (columnName !== "select") {
      columns.push({
        id: columnName,
        header: capitalizedHeader,
        accessor: (data: T) => data[columnName as keyof T],
        canSort: defaultColumns.includes(columnName),
        isVisible: columnName !== "id",
      });
    }
  });
  return columns;
}

export default createColumnsConfig;
