import usePermissions from "hooks/usePermissions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import { RootState } from "store";
import { notificationsStatusAction } from "store/filters";
import { closeNotificationsSummaryModalAction } from "store/modals";
import { Toasts } from "view/components/Toasts";

interface IProps {
  name: string;
  createdBy: string;
  dateCreated: string;
  notificationStatus: string;
  id: number;
}

export const SummaryHeaders: React.FC<IProps> = ({
  name,
  createdBy,
  dateCreated,
  notificationStatus,
  id,
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { reminders } = usePermissions();

  const pauseResumeHandleClick = async () => {
    try {
      const status = notificationStatus === "active" ? "paused" : "active";
      const res = await apiLibrary.Notifications.updateNotification(id, status);
      Toasts.success(res.message);
      dispatch(notificationsStatusAction(status));
      dispatch(closeNotificationsSummaryModalAction());
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
    }
  };
  return (
    <>
      <div className="">
        <div className="py-2">
          <p className="text-sm text-textMidLight dark:text-caption ">
            Notification name
          </p>
          <p className="capitalize dark:text-textMain">{name}</p>
        </div>
        <div className="">
          <div className=" flex py-2">
            <div className="w-1/2">
              <p className="text-sm text-textMidLight dark:text-caption ">
                Created By
              </p>
              <p className="capitalize dark:text-textMain">{createdBy}</p>
            </div>
            <div className="w-1/2">
              <p className="text-sm text-textMidLight dark:text-caption ">
                Date Created
              </p>
              <p className="capitalize dark:text-textMain">{dateCreated}</p>
            </div>
          </div>

          <div className="flex py-1">
            {notificationStatus === "complete" ? (
              <></>
            ) : (
              <>
                <button
                  className={`w-1/2 border-primaryLight40 border-2 ${!reminders.canEditReminders && "opacity-50 cursor-not-allowed"}  py-1 mr-1 rounded-2xl text-primaryLight40 ${
                    notificationStatus === "active"
                      ? "border-textLink border-2 bg-textLink text-textWhite"
                      : "text-primaryLight40 border-primaryLight40 border-2"
                  }`}
                  disabled={
                    notificationStatus !== "active" ||
                    !reminders.canEditReminders
                  }
                  // disabled={notificationStatus === "active" ? false : true && reminders.canEditReminders}
                  onClick={pauseResumeHandleClick}
                >
                  Pause
                </button>
                <button
                  className={`w-1/2 border-primaryLight40 border-2 ${!reminders.canEditReminders && "opacity-50 cursor-not-allowed"}  py-1 mr-1 rounded-2xl text-primaryLight40 ${
                    notificationStatus === "paused"
                      ? "border-textLink border-2 bg-textLink text-textWhite "
                      : "text-primaryLight40 border-primaryLight40 border-2"
                  }`}
                  disabled={
                    notificationStatus !== "paused" ||
                    !reminders.canEditReminders
                  }
                  onClick={pauseResumeHandleClick}
                >
                  Resume
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
