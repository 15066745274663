import { AddReportBtnProps } from "../../types";

const RunReportBtn = ({ handleRunReport }: any) => {
  return (
    <div
      onClick={handleRunReport}
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 bg-primary border-primary cursor-pointer"
    >
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
        <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-textWhite">
          Run Report
        </p>
      </div>
    </div>
  );
};

export { RunReportBtn };
