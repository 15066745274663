import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";

const AbleToSelectMultiple = ({
  ableToCheckMultiple,
  handleCheckboxOnChange,
}: any) => {
  const id = generateUniqueId("checkbox#");
  
  return (
    <div className="flex gap-2">
      <input
        type="checkbox"
        checked={ableToCheckMultiple}
        onChange={handleCheckboxOnChange}
        id={id}
        className="w-4" 
      />
      <label htmlFor={id} className="cursor-pointer text-secondaryMidLight">
        Able to select multiple
      </label>
    </div>
  );
};

export { AbleToSelectMultiple };
