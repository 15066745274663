import { ERROR_LOADING_FORM_DETAILS, GET_FORM_DETAILS, RESET_FORM_DATA, START_LOADING_FORM_DETAILS, STOP_LOADING_FORM_DETAILS } from "./action-types";
import { initialState } from "./initialState";

const formDetailsReducer = (state = initialState, action: any): any => {
  switch (action.type) {

    case START_LOADING_FORM_DETAILS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_FORM_DETAILS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_FORM_DETAILS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_FORM_DETAILS:
      return {
        ...state,
        formDetails: action.payload,
        message: "",
        stateIs: "Idle",
      };
    case RESET_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};

export default formDetailsReducer;
