import activeUsersInitialState, {
  IfetchActiveUsers,
} from "./activeUsers/initialState";
import mostObservedObjectsInitialState, {
  IfetchMostObservedObjects,
} from "./mostObservedObjects/initialState";

import mostObservedSpeciesInitialState, {
  IfetchMostObservedSpecies,
} from "./mostObservedSpecies/initialState";

import observationByLocationInitialState, {
  IfetchObservationByLocation,
} from "./observationByLocation/initialState";

import cumulativeObservationsInitialState, {
  IfetchCumulativeObservations,
} from "./cumulativeObservations/initialState";

import horizontalBarGraphInitialState, {
  IfetchHorizontalBarGraph,
} from "./horizontalBarGraph/initialState";

import totalCountsInitialState, {
  IfetchTotalCounts,
} from "./totalCounts/initialState";

import notificationsInitialState, {
  IfetchNotifications,
} from "./notifications/initialState";

import observationsInitialState, {
  IfetchObservations,
} from "./observations/initialState";

import observationsByRegionInitialState, {
  IfetchObservationsByRegion,
} from "./observationsByRegion/initialState";

import qaNotificationsInitialState, {
  IfetchQANotifications,
} from "./qaNotifications/initialState";

import qaProgressInitialState, {
  IfetchQAProgress,
} from "./qaProgress/initialState";

import reportNotificationsInitialState, {
  IfetchReportNotifications,
} from "./reportNotifications/initialState";
import dashboardConfigurationInitialState, {
  IfetchAllDashboardConfiguration,
} from "./configurations/initialState";

export enum EDashboardWidgets {
  totalCounts,
  qaProgress,
  activeUsers,
  observations,
  observationByLocation,
  notifications,
  qaNotifications,
  horizontalBarGraph,
  reportNotifications,
  mostObservedObjects,
  mostObservedSpecies,
  observationsByRegion,
  cumulativeObservations,
}

export interface IDashboardWidgets {
  qaProgress: IfetchQAProgress;
  activeUsers: IfetchActiveUsers;
  totalCounts: IfetchTotalCounts;
  observations: IfetchObservations;
  observationByLocation: IfetchObservationByLocation;
  notifications: IfetchNotifications;
  qaNotifications: IfetchQANotifications;
  forms: IfetchHorizontalBarGraph;
  communities: IfetchHorizontalBarGraph;
  programs: IfetchHorizontalBarGraph;
  reportNotifications: IfetchReportNotifications;
  mostObservedObjects: IfetchMostObservedObjects;
  mostObservedSpecies: IfetchMostObservedSpecies;
  observationsByRegion: IfetchObservationsByRegion;
  cumulativeObservations: IfetchCumulativeObservations;
  configuration: IfetchAllDashboardConfiguration;
}

export interface Ifetch {
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IDashboardWidgets = {
  totalCounts: totalCountsInitialState,
  qaProgress: qaProgressInitialState,
  activeUsers: activeUsersInitialState,
  observations: observationsInitialState,
  observationByLocation: observationByLocationInitialState,
  notifications: notificationsInitialState,
  qaNotifications: qaNotificationsInitialState,
  forms: horizontalBarGraphInitialState,
  communities: horizontalBarGraphInitialState,
  programs: horizontalBarGraphInitialState,
  reportNotifications: reportNotificationsInitialState,
  mostObservedObjects: mostObservedObjectsInitialState,
  mostObservedSpecies: mostObservedSpeciesInitialState,
  observationsByRegion: observationsByRegionInitialState,
  cumulativeObservations: cumulativeObservationsInitialState,
  configuration: dashboardConfigurationInitialState,
};

export default initialState;
