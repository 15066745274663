import { TableBodyProps } from "../../types";
import { Cell } from "@tanstack/react-table";
import { Column } from "../index";
export const TableBody = ({
  table,
  columnVisibility,
  flexRender,
}: TableBodyProps) => {
  return (
    <tbody>
      {table?.getRowModel()?.rows?.map((row: any, index: number) => (
        <tr
          key={index}
          className=" Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {row
            ?.getVisibleCells()
            ?.map((cell: Cell<any, any>) => (
              <Column
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            ))}
          <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight"></td>
        </tr>
      ))}
    </tbody>
  );
};
