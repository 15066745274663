import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { setLoadingProgress } from "store/loadingBar";
import { openPhoneVerificationModalAction } from "store/modals/reducer.actions";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";

interface PhoneVerificationBtnProps {
  contact: {
    isVerified: boolean;
    phoneNumber: string;
  };
  errMsg: string | null;
}

export const PhoneVerificationBtn: React.FC<PhoneVerificationBtnProps> = ({
  contact,
  errMsg,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { personId } = useParams();
  const { verifyEmailPhone, confirmEmailPhone } = usePermissions();
  const dispatch = useDispatch();
  const verifyPhoneNumber = async () => {
    if (!verifyEmailPhone.canCreateVerifyEmailPhone) {
      return;
    }
    try {
      setIsLoading(true);
      dispatch(setLoadingProgress(getRandomProgress()));
      const res = await apiLibrary.userProfile.verifyEmailOrPhone(
        contact.phoneNumber,
        personId
      );
      if (confirmEmailPhone.canCreateConfirmEmailPhone) {
        dispatch(
          openPhoneVerificationModalAction({
            isOpen: true,
            value: contact.phoneNumber,
          })
        );
      }

      Toasts.success(res.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;

      if (
        errorMsg === "Code already sent." &&
        confirmEmailPhone.canCreateConfirmEmailPhone
      ) {
        dispatch(
          openPhoneVerificationModalAction({
            isOpen: true,
            value: contact.phoneNumber,
          })
        );
      } else {
        Toasts.error(errorMsg);
      }
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  if (!contact.isVerified && contact.phoneNumber !== "" && !errMsg) {
    return (
      verifyEmailPhone.canCreateVerifyEmailPhone && (
        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 pt-1 rounded-3xl">
          <button
            disabled={isLoading || !verifyEmailPhone.canCreateVerifyEmailPhone}
            onClick={verifyPhoneNumber}
            type="button"
            className="ml-2 flex justify-center disabled:cursor-not-allowed items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2 outline-none"
          >
            <p className="text-sm font-semibold text-center text-primary hover:underline">
              Verify
            </p>
          </button>
        </div>
      )
    );
  } else {
    return null;
  }
};
