import { apiClient } from "../config";

async function getCumulativeObservations(queryParams: any) {
  const res = await apiClient.get(
    `/admin/dashboard/widget/cumulative-observations`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getCumulativeObservations;
