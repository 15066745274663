import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const CloseIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52851 3.52864C3.78886 3.26829 4.21097 3.26829 4.47132 3.52864L7.99992 7.05723L11.5285 3.52864C11.7889 3.26829 12.211 3.26829 12.4713 3.52864C12.7317 3.78899 12.7317 4.2111 12.4713 4.47145L8.94273 8.00004L12.4713 11.5286C12.7317 11.789 12.7317 12.2111 12.4713 12.4714C12.211 12.7318 11.7889 12.7318 11.5285 12.4714L7.99992 8.94285L4.47132 12.4714C4.21097 12.7318 3.78886 12.7318 3.52851 12.4714C3.26816 12.2111 3.26816 11.789 3.52851 11.5286L7.05711 8.00004L3.52851 4.47145C3.26816 4.2111 3.26816 3.78899 3.52851 3.52864Z"
      fill="#2C3236"
    />
  </svg>
);
export default CloseIcon;
