import { Link } from 'react-router-dom';
import { ColumnProps } from '../../types';
import { RenderCellContent } from '../index';
import usePermissions from 'hooks/usePermissions';

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  rowData,
}: ColumnProps) => {
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;

  const { reports } = usePermissions();

  const linkTo = reports.canViewReports
    ? `/reports/${rowData?.original?.id}/profile`
    : '';
  const cursor = linkTo ? 'cursor-pointer' : 'cursor-default';

  if (isColumnVisible && cell.column.columnDef.id !== 'select') {
    return (
      <td key={cell.id} className={`px-4 ${hoverEffect}`}>
        <Link to={linkTo} className={cursor}>
          <RenderCellContent
            columnDef={cell.column.columnDef}
            cellValue={cell.getValue()}
          />
        </Link>
      </td>
    );
  } else {
    return (
      <td
        key={cell.id}
        className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }
};
