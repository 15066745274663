import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useGetCurrentQuestionState = (questionId: any) => {
  const { getGpsDatas } = useSelector((state: RootState) => state.surveyJS);
  const [currentQuestion, setCurrentQuestion] = useState<any>();

  console.log("getGpsDatas", getGpsDatas)

  useEffect(() => {
    const currentQuestion = getGpsDatas.find((s: any) => s.id == questionId);
    setCurrentQuestion(currentQuestion);

  }, [questionId, getGpsDatas])

  return currentQuestion;
}

export { useGetCurrentQuestionState };