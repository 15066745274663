import { ElementFactory, Question, SvgRegistry } from "survey-core";

import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";


export default class QuestionPointPickerModel extends Question {
  getType() {
    return questionTypes.pointPicker;
  }

}