 /***** Import the React library and useState hook *****/ 
import React, { useState } from "react";
/***** Components *****/ 
import TextInput from "../Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
/***** API services *****/
import apiLibrary from "services/api";
/***** External tools *****/
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { profileChangePasswordValidationSchema } from "utils/validationSchemas";
import usePermissions from "hooks/usePermissions";


export const ChangePassword = () => {
  const [editMode, seteditMode] = useState(true); 
  // const {profile} = usePermissions();

  // Initializing values for the form fields
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  /**
 * Handles form submission for updating a password.
 *
 * @param {any} values - The form values containing newPassword and oldPassword.
 * @param {Object} options - Options object containing setSubmitting and resetForm functions.
 * @param {Function} options.setSubmitting - A function to set the submitting state.
 * @param {Function} options.resetForm - A function to reset the form state.
 */
const handleSubmit = async (
  values: any, // Form values, including newPassword and oldPassword
  {
    setSubmitting, // Function to control form submitting state
    resetForm,     // Function to reset form state
  }: 
  {
    setSubmitting: (isSubmitting: boolean) => void;
    resetForm: () => void;
  }
) => {
  try {
    // Calling the API to update the password
    const res = await apiLibrary.Auth.updatePassword({
      new_password: values.newPassword, // New password value from the form
      old_password: values.oldPassword, // Old password value from the form
    });

    // Display a success toast message with the API response message
    Toasts.success(res.message);

    // Reset form state after successful submission
    setSubmitting(false);
    resetForm();
  } catch (error: any) {
    // Handling errors from the API call
    const errorMsg = error?.response?.data?.message;
    if (errorMsg) {
      // Displaying an error toast message with the error message from the API response
      Toasts.error(errorMsg);
    }
    // Reseting form state after unsuccessful submission
    setSubmitting(false);
  }
};

  return (
    <div className="w-full bg-bgWhite dark:bg-secondaryLight border-t-0 border-b-0 border-l border-r-0 cursor-default border-lineMid dark:border-lineLight">
      <div className="flex flex-col w-full bg-white">
        <div className="w-full pt-6 pl-8 xl:pl-12">
          <p className="text-lg font-semibold text-secondaryMid dark:text-textMain">
            Change Password
          </p>
        </div>
        <div className="px-8 pt-4 pb-12 xl:px-12">
          <div className="py-2 bg-white ">
            {/* Using Formik to manage the form */}
            <Formik
              initialValues={initialValues}
              validationSchema={profileChangePasswordValidationSchema}
              onSubmit={handleSubmit}
              validateOnChange
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
              }) => (
                <Form>
                  {/* Rendering individual input fields using custom TextInput */}
                  <div>
                    <div className="flex flex-col w-full xl:w-1/2 lg:w-full">
                      {/* Custom TextInput component */}
                      <TextInput
                        label="Old Password"
                        type="password"
                        name="oldPassword"
                        placeholder="Password"
                        error={errors.oldPassword}
                        value={values.oldPassword}
                        editMode={editMode}
                        touched={touched.oldPassword}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        // disabled={!profile.canEditPasswordProfile}
                      /> 
                    </div>
                    <div className="w-full my-4 xl:w-1/2 lg:w-full">
                    <div>
                        <p className="text-sm text-left text-secondaryMidLight dark:text-textMain">
                          Use 8 or more characters.
                        </p>
                      </div>
                     {/* Custom TextInput component */}
                      <TextInput
                        label="New Password"
                        type="password"
                        name="newPassword"
                        placeholder="Password"
                        error={errors.newPassword}
                        value={values.newPassword}
                        editMode={editMode}
                        touched={touched.newPassword}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        // disabled={!profile.canEditPasswordProfile}
                      />
                    </div>
                    <div className="w-full xl:w-1/2 lg:w-full">
                      {/* Custom TextInput component */}
                      <TextInput
                        label="Confirm New Password"
                        type="password"
                        name="confirmPassword"
                        placeholder="Password"
                        error={errors.confirmPassword}
                        value={values.confirmPassword}
                        editMode={editMode}
                        touched={touched.confirmPassword}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        // disabled={!profile.canEditPasswordProfile}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 my-6">
                    {/* Cancel button */}
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={resetForm}
                    />
                    {/* Submit button */}
                    <Button
                      type="submit"
                      text="Update"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                      disabled={isSubmitting}
                      // disabled={isSubmitting && !profile.canEditPasswordProfile}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};