import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import apiLibrary from 'services/api';
import { TextField } from '../../Components';

interface AudioRecorderProps {
  editMode: boolean;
  error?: any;
  label?: string;
  required?: boolean;
  deleteAudio: any;
  setAudio: any;
  panel?: any;
  element?: any;
  key: number;
  name: any;
  value: any;
  formikProps: any;
  touched: any;
  placeholder?: any;
}

interface AudioData {
  url: string;
  blob: Blob;
  chunks: BlobPart[];
  duration: number;
}

const AudioRecorderComponent: React.FC<AudioRecorderProps> = ({
  editMode,
  error,
  label,
  required,
  deleteAudio,
  setAudio,
  panel,
  element,
  key,
  name,
  value,
  formikProps,
  touched,
  placeholder,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<any>(null);

  // Set state to false once audio finishes
  useEffect(() => {
    const audio = audioRef.current;

    const setPlayStateToEnd = () => setIsPlaying(false);

    if (audio) {
      audio.addEventListener('ended', setPlayStateToEnd);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('ended', setPlayStateToEnd);
      }
    };
  }, []);

  // Upload audio file, get audio path and set to formik
  const addAudioElement = async (blob: any) => {
    // Create a File object from the Blob with additional properties
    const audioFile = new File([blob], 'audio.mp3', {
      type: 'audio/mpeg', // Set the MIME type for MP3 files
      lastModified: Date.now(),
    });

    // Call the API to upload the audio file
    try {
      const res = await apiLibrary.file.fileUpload(audioFile, false, 'public');
      setAudio({
        url: res.data?.path,
        transcription: value?.transcription || '',
      });
    } catch (error: any) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div
      className='p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight'
      id={`${element?.name}${panel?.sectionId}`}
    >
      <p
        className={`w-full pb-1 text-md text-left capitalize font-Overpass ${
          editMode
            ? 'text-primary dark:text-caption font-medium '
            : 'text-primary dark:text-caption font-regular '
        }`}
      >
        {label} {required && <span>*</span>}
      </p>

      {editMode ? (
        <div>
          <div className='audio-wrapper flex items-center gap-5'>
            <AudioRecorder
              onRecordingComplete={addAudioElement}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              downloadOnSavePress={false}
              downloadFileExtension='mp3'
              showVisualizer={true}
            />

            {
              <div
                className={`flex items-center gap-2 ${!value?.url ? 'opacity-50 pointer-events-none' : ''}`}
              >
                <audio
                  id='player'
                  ref={audioRef}
                  src={value?.url}
                  className=''
                />
                <button onClick={deleteAudio} type='button'>
                  {<TrashIcon width={26} className='stroke-accent_1Dark' />}
                </button>
              </div>
            }
          </div>

          <div className='flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5 mt-2'>
            {(error?.url || error) && (
              <p className='flex-grow w-[1/2] text-xs text-left text-accent_1Dark'>
                {error?.url || error}
              </p>
            )}
          </div>
        </div>
      ) : value?.url ? (
        <>
          <audio src={value?.url} controls />
        </>
      ) : (
        <p className='text-textNoSelection font-Overpass font-normal text-[15px] italic'>
          (No Selection)
        </p>
      )}

      <TextField
        key={key}
        label={'Transcription'}
        type='text'
        name={name}
        value={!value?.url ? '' : value?.transcription}
        handleChange={(val: any) => {
          setAudio({
            url: value?.url || '',
            transcription: val,
          });
        }}
        handleBlur={() => formikProps.setFieldTouched(name, true, true)}
        fieldAs='textarea'
        rows={element?.rows}
        editMode={editMode}
        placeholder={placeholder || `Enter Transcription`}
        error={error?.transcription}
        touched={touched?.transcription}
        panel={panel}
        element={element}
        labelSize='sm'
        disabled={!value?.url}
      />
    </div>
  );
};

export default AudioRecorderComponent;
