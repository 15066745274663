import { useEffect, useState } from "react";
// Store utils
import { useDispatch } from "react-redux";
import {
  openAllLayersListingScreen,
  resetMap,
  updateDrawShapeCoordinates,
} from "store/geography";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import { SelectForm, UploadForm, DrawRegion } from "./Components";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { showSnapingControl } from "store/geography/reducer.actions";
import Head from "view/components/Head";

const TABS = {
  SELECT: "SELECT",
  UPLOAD: "UPLOAD",
  DRAW: "DRAW",
};

// Schema
const CreateRegionLayer = () => {
  const [activeTab, setActiveTab] = useState(TABS.SELECT);
  const { shapeCoordinates } = useSelector(
    (state: RootState) => state.geography
  );
  const dispatch = useDispatch();
  
  const formHeight = calculateFormHeight();

  useEffect(() => {
    // reset coordinates on
    return () => {
      if (shapeCoordinates) {
        dispatch(updateDrawShapeCoordinates(null));
      }
    };
  }, []);

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(showSnapingControl(false));
    dispatch(openAllLayersListingScreen());
  };

  const handleClickOnTab = (item: any) => {
    if (item === TABS.DRAW) {
      dispatch(showSnapingControl(true));
    } else {
      dispatch(showSnapingControl(false));
    }
    setActiveTab(item);
  };

  function calculateFormHeight(){
    const containerPadding = 32;
    const headBarHeight = 24 + 24; 
    const breadcrumbHeight = 48 + 32;
    const formButtonsHeight = 52 + 8;
    const allElementsHeight =
      containerPadding +
      headBarHeight +
      breadcrumbHeight +
      formButtonsHeight * 2;

    return `calc(100vh - ${allElementsHeight}px)`;
  };

  return (
    <div className="h-full">
      <Head title="Geography: Add Region" />
      <Breadcrumb
        label="Add Region"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <div className="mb-8">
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          handleOnClick={handleClickOnTab}
        />
      </div>
      {activeTab === TABS.SELECT && <SelectForm height={formHeight} />}
      {activeTab === TABS.UPLOAD && <UploadForm height={formHeight} />}
      {activeTab === TABS.DRAW && <DrawRegion height={formHeight} />}
    </div>
  );
};

export { CreateRegionLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};
const Tabs = ({ tabs, activeTab, handleOnClick }: any) => {
  return (
    <div className="flex border-b border-lineMid dark:border-lineLight ">
      {tabs &&
        Object.keys(tabs)?.map((tab: any) => {
          return (
            <button
              key={tab}
              className={`py-3 flex-1 px-4 text-sm text-secondary  ${
                activeTab === tab
                  ? "border-b-2 border-primaryMid font-semibold dark:text-primaryMid"
                  : "font-medium hover:border-b hover:border-lineMid dark:text-textMain"
              } `}
              onClick={() => handleOnClick(tabs[tab])}
            >
              {tab}
            </button>
          );
        })}
    </div>
  );
};
