import fileUpload from "./fileUpload";
import fileDownload from "./fileDownload";
import surveyFileUpload from "./surveyFileUpload"
const file = {
  fileUpload,
  fileDownload,
  surveyFileUpload
};

export default file;
