import { ERROR_LOADING_PROGRAMS, GET_ALL_PROGRAMS, RESET_DATA, START_LOADING_PROGRAMS, STOP_LOADING_PROGRAMS } from "./action-types";
import initialState, { IProgram } from "./initialState";
export const programsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_PROGRAMS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_PROGRAMS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_PROGRAMS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_PROGRAMS:
      const programs = action.payload.programs.map((item: IProgram) => {
        const {
          communityName,
          connectedForms,
          image,
          type,
          members,
          numberOfObservations,
          organizationName,
          programCode,
          programId,
          programName,
          protocol,
          status,
          timeframe,
          pausedSince,
          openAccess,
          dataAccessPolicy
        } = item;

        const data = {
          id: programId,
          programName: {
            programName,
            programId,
            image,
            status,
          },
          programCode,
          members,
          type,
          numberOfObservations,
          connectedForms,
          protocol,
          timeframe,
          pausedSince,
          openAccess,
          dataAccessPolicy,
          organizationName: organizationName?.join(","),
          communityName: communityName?.join(","),
        };

        return data;
      });

      return {
        ...state,
        data: {
          programs,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
