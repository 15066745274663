import { useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { MarkAllReadBtn } from "./components/MarkAllReadBtn";
import { Toasts } from "view/components/Toasts";
import { fetchNotificationCenterAction } from "store/notificationCenter";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IfetchNotifications } from "store/notificationCenter/initialState";
import usePermissions from "hooks/usePermissions";

const generateDynamicNotificationsLinks = () => {
  return [{ path: `/notification-center/list`, label: `Notification Center` }];
};

export const Header = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IfetchNotifications>(
    (state) => state.notificationCenter
  );
  const { notifications } = usePermissions();
  const dynamicNotificationsLinks = generateDynamicNotificationsLinks();
  const isReadStatus = data.notificationsCenter.some(
    (notificationsCenter) => !notificationsCenter.isRead
  );

  const handleMarkAllRead = async () => {
    try {
      const response =
        await apiLibrary.NotificationCenter.readAllNotifications();
      Toasts.success(response.message);
      dispatch(fetchNotificationCenterAction());
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicNotificationsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {notifications.canCreateNotifications && (
                <MarkAllReadBtn
                  disabled={!isReadStatus}
                  handleMarkAllRead={handleMarkAllRead}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
