export interface IAuthResponseData {
  accessToken: string;
  appName: string;
  autoGeneratePass: boolean;
  email: string;
  id: number;
  permissions: IPERMISSIONS;
  person: Person;
  phoneNumber: string | null;
  refreshToken: string;
  roles: string[];
  accessUntil: any;
}

interface SocialAccount {
  action: string;
  operation: string | null;
}

interface Address {
  address: string;
  apartment: string | null;
  city: string | null;
  country: string | null;
  id: number;
  isPrimary: boolean;
  state: string | null;
  zipCode: string | null;
}

interface SecondaryEmail {
  email: string;
  isVerified: boolean;
}

interface PersonImage {
  file: {
    original: string;
    thumb: string;
  };
}

interface User {
  email: string;
  id: number;
  phoneNumber: string | null;
}

interface Person {
  addresses: Address[];
  communities: number;
  contactPreference: string;
  dateOfBirth: string;
  firstName: string;
  forms: number;
  gender: string;
  lastName: string;
  observations: number;
  personId: number;
  personImage: PersonImage;
  personImageId: number;
  prefix: string;
  secondaryEmail: SecondaryEmail[];
  secondaryPhoneNumber: any[];
  socialAccounts: string;
  status: string;
  suffix: string;
  user: User;
  virtual: boolean;
}

export interface IPermissions {
  access: boolean;
  action: string;
  operation: string;
}

export interface IPERMISSIONS {
  qa: IPermissions[];
  verifyEmailPhone: IPermissions[];
  permissions: IPermissions[];
  attachSocialAccount: IPermissions[];
  users: IPermissions[];
  types: IPermissions[];
  markerStyle: IPermissions[];
  markerGroups: IPermissions[];
  newPassword: IPermissions[];
  contactInformation: IPermissions[];
  password: IPermissions[];
  protocols: IPermissions[];
  programs: IPermissions[];
  logout: IPermissions[];
  deleteAccountRequest: IPermissions[];
  organizations: IPermissions[];
  confirmEmailPhone: IPermissions[];
  tags: IPermissions[];
  dynamicForms: IPermissions[];
  detachSocialAccount: IPermissions[];
  dashboard: IPermissions[];
  species: IPermissions[];
  observations: IPermissions[];
  download: IPermissions[];
  file: IPermissions[];
  directory: IPermissions[];
  userQueries: IPermissions[];
  universalSettings: IPermissions[];
  accountInfo: IPermissions[];
  roles: IPermissions[];
  assignRoles: IPermissions[];
  conversations: IPermissions[];
  reports: IPermissions[];
  objects: IPermissions[];
  geographies: IPermissions[];
  imports: IPermissions[];
  categories: IPermissions[];
  universalSettingsImages: IPermissions[];
  communities: IPermissions[];
  profile: IPermissions[];
  reminders: IPermissions[];
  notifications: IPermissions[];
  behaviors: IPermissions[];
  conditions: IPermissions[];
  surveyAndPolls: IPermissions[];
  trackings: IPermissions[];
  globalSearch: IPermissions[];
}

export const initialAuthState: IAuthResponseData = {
  accessToken: "",
  appName: "",
  autoGeneratePass: false,
  email: "",
  id: 0,
  permissions: {
    qa: [],
    verifyEmailPhone: [],
    permissions: [],
    attachSocialAccount: [],
    users: [],
    types: [],
    markerGroups:[],
    markerStyle:[],
    newPassword: [],
    contactInformation: [],
    password: [],
    protocols: [],
    programs: [],
    logout: [],
    deleteAccountRequest: [],
    organizations: [],
    confirmEmailPhone: [],
    tags: [],
    dynamicForms: [],
    detachSocialAccount: [],
    dashboard: [],
    species: [],
    observations: [],
    download: [],
    file: [],
    directory: [],
    userQueries: [],
    universalSettings: [],
    accountInfo: [],
    roles: [],
    assignRoles: [],
    conversations: [],
    reports: [],
    objects: [],
    geographies: [],
    imports: [],
    categories: [],
    universalSettingsImages: [],
    communities: [],
    profile: [],
    reminders: [],
    notifications: [],
    behaviors: [],
    conditions: [],
    surveyAndPolls: [],
    trackings: [],
    globalSearch: [],
  },
  person: {
    addresses: [],
    communities: 0,
    contactPreference: "",
    dateOfBirth: "",
    firstName: "",
    forms: 0,
    gender: "",
    lastName: "",
    observations: 0,
    personId: 0,
    personImage: {
      file: {
        original: "",
        thumb: "",
      },
    },
    personImageId: 0,
    prefix: "",
    secondaryEmail: [],
    secondaryPhoneNumber: [],
    socialAccounts: "",
    status: "",
    suffix: "",
    user: {
      email: "",
      id: 0,
      phoneNumber: null,
    },
    virtual: false,
  },
  phoneNumber: null,
  refreshToken: "",
  roles: [],
  accessUntil: "",
};
