export interface DynamicForm {
  archivedBy: {
    id: null | number;
    name: null | string;
    personImage: {
      original: null | string;
      thumb: null | string;
    };
  };
  createdBy: {
    id: number;
    name: string;
    personImage: {
      original: null | string;
      thumb: null | string;
    };
  };
  dateAdded: string;
  dateArchived: null | string;
  dateUpdated: string;
  description: string;
  formName: string;
  id: number;
  picture: {
    original: null | string;
    thumb: null | string;
  };
  programName: {
    id: number;
    name: string;
  };
  protocol: {
    id: null | number;
    name: null | string;
  };
  status: string;
  templateCount: number;
  numberOfObservations: number;
  programStatus: string | undefined;
  allowAnonObs: boolean
}

export interface DynamicFormsData {
  dynamicForms: DynamicForm[];
  fields: string[];
  totalItems: number;
  totalPages: number;
  programStatus: string | null;
}

export interface IfetchDynamicForms {
  data: DynamicFormsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialDynamicFormsState: IfetchDynamicForms = {
  data: {
    dynamicForms: [],
    fields: [],
    totalItems: 0,
    totalPages: 0,
    programStatus: "",
  },
  message: "",
  stateIs: "Idle",
};

export default initialDynamicFormsState;
