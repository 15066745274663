import { ButtonBase, Checkbox } from "@mui/material";
import { TableHeadProps } from "../../types";
import { ColumnHeading, TableHeadActions } from "../index";

import fileDownloadIcon from "assets/images/file-download.png";


export const TableHead = ({
  table,
  sorting,
  flexRender,
  handleClickOnColumnHead,
}: TableHeadProps) => {
  return (
    <thead className="table_row_header w-full sticky top-0 h-[48px] z-20  dark:bg-bgtetriary">
      <tr className="w-full cursor-pointer dark:border-none" style={{ height: "49px" }}>
        {table.getHeaderGroups().map((headerGroup: any) => {
          return (
            <ColumnHeading
              headerGroup={headerGroup}
              sorting={sorting}
              flexRender={flexRender}
              handleClickOnHead={handleClickOnColumnHead}
            />
          );
        })}
      </tr>
    </thead>
  );
};
