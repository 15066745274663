import React from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { notificationsStatusAction } from "store/filters/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({}) => {
  const { notificationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const TabsData = ["active", "paused", "completed"];

  //handlers
  const handleLink = (linkName: string) => {
    dispatch(notificationsStatusAction(linkName));
  };

  return (
    <>
      {TabsData.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${
              notificationsFilter.status === item
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
            }`}
            onClick={() => handleLink(item)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${
                  notificationsFilter.status === item
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                }`}
              >
                {/* {sentenceCase(item)} */}
                {item}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
