import React from "react";
// Components
import {
  Date,
  SelectedForms,
  SelectedMembers,
  SelectedPrograms,
  SelectedCreatedBy,
  SelectedCommunities,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { notificationsResetFiltersAction } from "store/filters/reducer.actions";
import { notificationDirectoryFilterState } from "store/filters/initialState";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  const { notificationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();



  const clearAllFilters = () => {
    dispatch(notificationsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyNotificationFilterApplied(notificationsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start "
      >
        <div className="flex flex-wrap gap-2">
          <SelectedPrograms />
          <SelectedForms />
          <SelectedCommunities />
          <SelectedMembers />
          <Date
            name="date"
            scheduledFor={notificationsFilter?.scheduledFor?.value}
            isApplied={notificationsFilter?.scheduledFor?.applied}
          />
          <SelectedCreatedBy />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;



  // Function to check if any filter is applied
  export function isAnyNotificationFilterApplied  (
    notificationsFilter: notificationDirectoryFilterState
  )  {
    const {
      searchBy,
      searchString,
      scheduledFor,
      programIds,
      memberIds,
      communityIds,
      formIds,
      createdByIds,
    } = notificationsFilter;
    const applied =
      (searchBy && searchString !== "") ||
      scheduledFor?.applied  ||
      programIds?.applied ||
      memberIds?.applied ||
      communityIds?.applied ||
      formIds?.applied ||
      createdByIds?.applied;
    return applied;
  };