import CheveronLeftIcon from "assets/icons/HeroIcons/CheveronLeftIcon";
import { GoToBackBtnProps } from "../../types";
import { useSelector } from "react-redux";

export const GoToBackBtn = ({ page, handleClickOnBtn }: GoToBackBtnProps) => {
  const { primary } = useSelector((state: any) => state.theme.colors);
  return (
    <button
      disabled={page === 1}
      onClick={handleClickOnBtn}
      title="Previous"
      className={`hover:bg-primaryLight dark:bg-bgtetriary dark:text-inputText dark:border-lineLight dark:hover:border-primary group relative flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed flex-grow-0 flex-shrink-0 w-8 gap-1 px-3  py-[2px] border-2 rounded-lg ${page === 1 ? "opacity-50" : ""
        } border-primary`}
    >
      <CheveronLeftIcon fill={primary} />
    </button>
  );
};
