import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_MOST_OBSERVED_OBJECTS,
  GET_MOST_OBSERVED_OBJECTS_STATE,
} from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- MOST OBSERVED OBJECTS
 * @returns
 */
export const fetchMostObservedObjects =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_MOST_OBSERVED_OBJECTS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.datePeriod) {
        Object.assign(params, {
          date_from: params.datePeriod.from,
          date_to: params.datePeriod.to,
        });
        delete params.datePeriod;
      }
      const { data } =
        await apiLibrary.Dashboard.widgets.getMostObservedObjects(params);

      dispatch({
        type: GET_MOST_OBSERVED_OBJECTS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_MOST_OBSERVED_OBJECTS_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log(
        "An error occurred while fetching the most observed objects:",
        error
      );
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };

const dummyData = [
  {
    count: 5,
    name: "Salmon Runs",
  },
  {
    count: 4,
    name: "Wildflowers",
  },
  {
    count: 3,
    name: "Region name",
  },
  {
    count: 2,
    name: "Railroad",
  },
  {
    count: 1,
    name: "Bald Eagles",
  },
];
