import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { fetchImportSummaryAction } from "store/importSummary/reducer.actions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import usePermissions from "hooks/usePermissions";
import { filtersInitialState } from "store/filters/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const TableBody = ({
  table,
  columnVisibility,
  getImportActions,
  flexRender,
}: TableBodyProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { imports } = usePermissions();
  const { importsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const showImportSummary = (row: any) => {
    if (imports.canViewSummaryImports && importsFilter.status !== "permission") {
      dispatch(
        fetchImportSummaryAction({
          id: row?.id,
          path: "directory",
        })
      );
    }
  };

  return (
    <tbody>
      {table.getRowModel().rows?.map((row: any, index: number) => (
        <tr
          key={index}
          className="cursor-pointer Close w-full item_data  border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
            (cell: Cell<any, any>) => (
              <Column
                hoverEffect="item_hover_effect"
                flexRender={flexRender}
                importSummary={() => showImportSummary(row.original)}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            )
          )}
          <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions row={row} getImportActions={getImportActions} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
