export interface IManager {
    reviewer: {
      image: any;
      id: number;
      firstName: string;
      lastName: string;
    };
    lastActive: string;
    personId: number;
    roles: string[];
    community: {
        image: string;
        id: number;
        name: string;
    }
    observationsReviewed: string;
    reviewerSince: string;
    activeObservations: number;
    dataEndViewerSince: string;
    activeReportAccess: number;
    status: string;
    endOfAccess: string;
  }
  
  export interface IManagerData {
    // fields: string[];
    managers: IManager[];
    totalItems: number;
    totalPages: number;
  }
  
  export interface IfetchManager {
    data: IManagerData;
    message: string;
    stateIs: "Idle" | "Pending" | "Error";
  }
  
  const initialManagementData: IfetchManager = {
    data: {
      managers: [],
      totalItems: 0,
      totalPages: 0,
    },
    message: "",
    stateIs: "Idle",
  };
  
  export default initialManagementData;
  