export interface IReminders {
  createdBy: {
    image: {
      original: string;
      thumb: string;
    };
    id: number;
    name: string;
  };
  notificationName: string;
  description: string;
  email: boolean;
  id: number;
  inApp: boolean;
  notificationCenter: boolean;
  push: boolean;
  scheduledFor: string;
  targetBy: [];
  toast: boolean;
  status: string;
  totalUsersReceived: number;

}
export interface NotificationsData {
  reminders: IReminders[];
  totalItems: number;
  totalPages: number;
}
export interface IfetchNotifications {
  data: NotificationsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialRemindersData: IfetchNotifications = {
  data: {
    reminders: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
export default initialRemindersData;