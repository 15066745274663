import { apiClient } from "../config";

async function getGlobalVectorLayers(layerType: any,params:any) {
  const res = await apiClient.get(
    `/admin/geographies/vector-layers?type=${layerType}`,{params}
  );
  return res.data;
}

export default getGlobalVectorLayers;
