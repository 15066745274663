import React from "react";
import { useNavigate } from "react-router-dom";

interface CardProps {
  title?: string;
  count?: any;
  description?: string;
  icon?: React.ReactNode;
  redirectLinkAction?: () => void;
}

const KpiCard: React.FC<CardProps> = ({
  title,
  count,
  description,
  icon,
  redirectLinkAction = null,
}) => {
  const redirectToLink = () => {
    if (redirectLinkAction) {
      redirectLinkAction()
    }
  };
  return (
    <div
      onClick={redirectToLink}
      className="flex flex-col items-center justify-start w-full bg-white rounded-lg shadow-md cursor-pointer bg-bgWhite dark:bg-secondaryLight hover:shadow-xl dark:border dark:border-solid dark:border-lineLight dark:bg-gray-800"
    >
      <div className="flex flex-col justify-between items-start h-full gap-1.5 p-4">
        <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 gap-2 pr-2">
          {icon}
          <p className="text-sm font-medium text-center text-secondaryMid dark:text-caption ">
            {title}
          </p>
        </div>
        <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
          <p className="flex-grow-0 flex-shrink-0 text-3xl text-center text-secondaryMid dark:text-textMain">
            {count}
          </p>
          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-3xl"></div>
        </div>
      </div>
    </div>
  );
};

export default KpiCard;
