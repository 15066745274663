import { PlayIcon } from 'assets/icons/HeroIcons';
import React, { useRef, useState, useEffect } from 'react';

interface VideoThumbnailProps {
  url: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ url }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [thumbnail, setThumbnail] = useState<string>('');

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadeddata', () => {
        if (video.readyState >= 4) {
          captureThumbnail();
        }
      });
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', () => { });
        URL.revokeObjectURL(url); // Cleanup the blob URL when the component unmounts
      }
    };
  }, [url]);

  const captureThumbnail = () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        setThumbnail(dataUrl);
      } else {
        console.error('Failed to get canvas context');
      }
    } else {
      console.error('Video element is not loaded');
    }
  };

  return (
    <div className='relative w-[65px] h-[65px] rounded overflow-hidden'>
      <video ref={videoRef} src={url} style={{ display: 'none' }} preload="auto" controls />
      {thumbnail && <img src={thumbnail} alt="Video Thumbnail" className='object-cover w-full h-full' />}
      <div className="overlay absolute inset-0 bg-[#000] bg-opacity-20 w-full h-full z-1"></div>
      <PlayIcon className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' fill='#fff' />
    </div>
  );
};

export default VideoThumbnail;
