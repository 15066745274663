import { TIME_FORMATS } from "constants/timeFormats";
import moment from "moment-timezone";

interface FormatTimeOptions {
  utc?: boolean;
  timezone?: string;
  local?: boolean;
  useCurrentTime?: boolean;
}

/**
 * Formats a given time string into a specified format.
 * @param {string | null} timeStr - The time string to format.
 * @param {string} format - The desired format.
 * @param {FormatTimeOptions} options - Additional options for formatting.
 * @returns {string} - The formatted time string.
 */
export function formatTheTime(
  timeStr: string | null,
  format: string = TIME_FORMATS.FULL_TIME,
  options: FormatTimeOptions = {}
): any {
  if (options.useCurrentTime && !timeStr) {
    timeStr = moment().toISOString();
  }

  const timeFormats = [...Object.values(TIME_FORMATS), moment.ISO_8601];
  let time = moment(timeStr, timeFormats, true);
  if (!time.isValid()) {
    console.error("Invalid time:", timeStr);
    return timeStr;
  }

  if (options.utc) {
    time = time.utc();
  } else if (options.timezone) {
    time = time.tz(options.timezone);
  } else if (options.local) {
    time = time.local();
  }

  return time.format(format);
}
