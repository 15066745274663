import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import MapPinIcon from 'assets/icons/MapIcons/map-pin.svg';

const MapCenterPoint = ({
  handleInputsOnChange,
  handleClickOnLocationNavigatorButton,
  mapCenterPoint,
}: any) => {
  return (
    <div className='w-full'>
      <h2 className='mb-3 text-secondaryMidLight'>Map Center Point</h2>
      <div className='grid grid-cols-12 gap-2'>
        <div className='col-span-5'>
          <TextField
            type='number'
            size='small'
            placeholder='Latitude'
            name='latitude'
            inputProps={{ min: -90, max: 90 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = parseFloat(e.target.value);
              if (value > 90 || value < -90) {
                return;
              }
              handleInputsOnChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            value={mapCenterPoint?.latitude}
            className='w-full bg-bgWhite'
          />
        </div>
        <div className='col-span-5'>
          <TextField
            type='number'
            size='small'
            placeholder='Longitude'
            name='longitude'
            inputProps={{ min: -180, max: 180 }}
            value={mapCenterPoint?.longitude}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = parseFloat(e.target.value);
              console.log(value, e.target.value, 'Hellooo');
              if (value > 180 || value < -180) {
                return;
              }
              handleInputsOnChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            className='w-full bg-bgWhite'
          />
        </div>
        <div className='col-span-2 bg-bgWhite'>
          <Tooltip title='Pick Map Center point' placement='top'>
            <button
              onClick={handleClickOnLocationNavigatorButton}
              className='flex justify-center items-center w-full h-full'
            >
              <img src={MapPinIcon} alt='Map Pin' />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export { MapCenterPoint };
