import { apiClient } from "../config";

async function deleteAddress(addressIds: any) {
  const config = {
    headers: {
      accept: "application/json",
    },
    params: {
      addressIds: addressIds.join(","),
    },
  };
  const res = await apiClient.put(
    `/admin/profile/delete-address`,
    null,
    config
  );
  return res.data;
}

export default deleteAddress;
