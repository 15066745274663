import { useMemo } from "react";

const useHandleClickOnLayer = (
  clickedLayer: any,
  setClickedLayer: any,
  currentQuestionEle: any,
  questionName: any,
  setCurrentQuestionEle: any,
  editMode: boolean,
  answer: any = []
) => {
  useMemo(() => {
    if (!clickedLayer) return;

    if (!editMode) {
      setClickedLayer(null);
      return;
    }

    const layerId = clickedLayer.item.id;

    const alreadyExistedAnswer:any = answer?.find((l: any) => l.id === layerId);

    if (!alreadyExistedAnswer ) {
      setCurrentQuestionEle(questionName, [
        ...answer ?? [],
        clickedLayer.item,
      ]);

    } 
    else {
      setCurrentQuestionEle(
        questionName,
        answer.filter(
          (answerLayer: any) => answerLayer.id !== alreadyExistedAnswer.id
        )
      );
    }

    setClickedLayer(null);
  }, [clickedLayer, editMode]);

  return null;
};

export { useHandleClickOnLayer };
