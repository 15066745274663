import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = ({ handlePicker, color }: any) => {
  const [doShowColorPicker, setDoShowColorPicker] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [position, setPosition] = useState<{ top?: number }>({});
  const [localColor, setLocalColor] = useState<any>({ hex: color } ?? {});

  const eleBgColor = localColor?.rgb ? rgbaToHex(localColor.rgb) : color

  useEffect(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const newPosition = calculatePickerPosition(buttonRect);
      if (newPosition.top !== position.top) {
        setPosition(newPosition);
      }
    }

    function calculatePickerPosition(buttonRect: DOMRect) {
      const windowHeight = window.innerHeight;
      const pickerHeight = 297; // Replace with actual picker height

      // Calculate available space below the button
      const spaceBelow = windowHeight - (buttonRect.top + buttonRect.height);

      // Check if there's enough space below
      if (spaceBelow > pickerHeight) {
        return { top: buttonRect.top + buttonRect.height - 50 };
      } else {
        // Not enough space below, open upwards
        const spaceAbove = buttonRect.top;
        return { top: spaceAbove - pickerHeight - 59 };
      }
    }
  }, [doShowColorPicker, position]);

  // handlers
  const toggleColorPicker = () => {
    setDoShowColorPicker((prev) => !prev);
  };

  const handleColorPickerOnChange = (color: any) => {
    setLocalColor(color);
    handlePicker(rgbaToHex(color.rgb));
  };

  return (
    <div className="">
      <button
        className="flex items-center w-full h-[26px] p-1 border border-lineDark  dark:border-lineLight rounded"
        type="button"
        onClick={toggleColorPicker}
        ref={buttonRef}
      >
        <span
          className="h-full flex-1 inline-block rounded"
          style={{ backgroundColor: eleBgColor }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="pl-1 w-[18px]  stroke-secondary   dark:stroke-primaryLight"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
      {doShowColorPicker && (
        <div className="absolute z-10" style={{ top: position?.top }}>
          <div
            className="fixed top-0 left-0 right-0 bottom-0"
            onClick={toggleColorPicker}
          />
          <SketchPicker
            color={localColor?.rgb ?? color}
            onChange={handleColorPickerOnChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;

function rgbaToHex({ r, g, b, a }:any) {
  // Convert RGB to hexadecimal
  const rgbHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  // Convert alpha from decimal (0-1) to hexadecimal (00-FF)
  const alphaHex = Math.round(a * 255).toString(16).padStart(2, '0');

  // Combine RGB and alpha into hex color string
  const hexColor = `#${rgbHex}${alphaHex}`;

  return hexColor.toUpperCase(); // Convert to uppercase for consistency
}
