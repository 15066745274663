import { useSelector } from "react-redux";
import { TableHeadProps } from "../../types";
import { ColumnHeading, TableHeadActions } from "../index";
import { RootState } from "store";
import { fetchImportsFile } from "store/importsViewData/initialState";

export const TableHead = ({
  table,
  sorting,
  flexRender,
  DirectoryActions,
  handleClickOnColumnHead,
}: TableHeadProps) => {

  const { data, stateIs } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );
  const hasFailedImport = data?.importedFileData?.some((item: any) => item?.importStatus?.toLowerCase() === "failed")
  console.log("hasFailedImport =>", hasFailedImport)
  return (
    <thead className="table_row_header w-full sticky top-0 h-[48px] z-20  dark:bg-bgtetriary">
      <tr className="w-full cursor-pointer " style={{ height: "49px" }}>
        {table.getHeaderGroups().map((headerGroup: any) => {
          return (
            <ColumnHeading
              headerGroup={headerGroup}
              sorting={sorting}
              flexRender={flexRender}
              handleClickOnHead={handleClickOnColumnHead}
            />
          );
        })}

        {hasFailedImport && <th className="whitespace-nowrap sticky right-0 dark:bg-bgtetriary">
          <TableHeadActions DirectoryActions={DirectoryActions} />
        </th>}
      </tr>
    </thead>
  );
};
