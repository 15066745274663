import getAllPrograms from "./getAllPrograms";
import getAllOrganizations from "./getAllOrganizations";
import getProgramProfile from "./getProgramProfile";
import createProgram from "./createProgram";
import getAllUsersByOrganizationId from "./getAllUsersByOrganizationId";
import editProgram from "./editProgram";
import getAllUserWhichCanBeAddAsAdmin from "./getAllUserWhichCanBeAddAsAdmin";
import changeProgramStatus from "./changeProgramStatus";
import removeFromProgram from "./removeFromProgram";
import getProgramsCommunities from "./getProgramsCommunities";
import getProgramUserMembers from "./getProgramUserMembers";
import connectCommunitiesToProgram from "./connectCommunitiesToProgram";
import addMembersToProgram from "./addMembersToProgram";
import getCommunitiesAndOrganizationsByProgram from "./getCommunitiesAndOrganizationsByProgram";
import archiveOrRestoreProgram from "./archiveOrRestoreProgram";
import editProgramTags from "./editProgramTags";
import removeProgram from "./removeProgram";
import getProgramStatusHistory from "./getProgramStatusHistory";
const Programs = {
  getAllPrograms,
  getAllOrganizations,
  getProgramProfile,
  createProgram,
  getAllUsersByOrganizationId,
  editProgram,
  changeProgramStatus,
  getAllUserWhichCanBeAddAsAdmin,
  removeFromProgram,
  removeProgram,
  getProgramsCommunities,
  getProgramUserMembers,
  connectCommunitiesToProgram,
  addMembersToProgram,
  getCommunitiesAndOrganizationsByProgram,
  archiveOrRestoreProgram,
  editProgramTags,
  getProgramStatusHistory,
};
export default Programs;
