import ViewGridIcon from "assets/icons/HeroIcons/ViewGridIcon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { communitiesViewTypeAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const GridBtn = () => {
  const { communitiesFilter } = useSelector<RootState,filtersInitialState>((state) => state.Filters)

  const dispatch = useDispatch()
  
  return (
    <button
      title="viewGrid"
      onClick={() => dispatch(communitiesViewTypeAction("grid"))}
      className={`relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded-lg ${
        communitiesFilter.viewType === "grid"
          ? "bg-primary/[0.08]"
          : "hover:bg-primary/[0.08]"
      } `}
    >
      <ViewGridIcon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
    </button>
  );
};

export default GridBtn;
