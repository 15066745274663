import {
  FETCH_DYNAMIC_FORMS_SUCCESS,
  REMOVE_DYNAMIC_FORM_SUCCESS,
  RESET_DYNAMIC_FORMS,
  UPDATE_DYNAMIC_FORM_SUCCESS,
} from "./action-types";

export const getAllFormsAction = (forms: any) => {
  return {
    type: FETCH_DYNAMIC_FORMS_SUCCESS,
    payload: forms,
  };
};

export const updateFormAction = (form: any) => {
  return {
    type: UPDATE_DYNAMIC_FORM_SUCCESS,
    payload: form,
  };
};

export const resetDynamicForms = () => {
  return {
    type: RESET_DYNAMIC_FORMS,

  };
};
