import VideoPlayIcon from 'assets/icons/HeroIcons/VideoPlayIcon';
import React, { useRef, useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface VideoThumbnailProps {
  url: string;
  userId: number;
  sendTime: string;
  handlePreviewFiles: () => void;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  url,
  userId,
  sendTime,
  handlePreviewFiles,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [thumbnail, setThumbnail] = useState<string>('');
  const auth = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    const video = videoRef.current;

    const handleCanPlay = () => {
      if (video) {
        captureThumbnail();
      }
    };

    if (video) {
      video.crossOrigin = 'anonymous';
      video.addEventListener('canplay', handleCanPlay);
      return () => {
        video.removeEventListener('canplay', handleCanPlay);
      };
    }
  }, [url]);

  const captureThumbnail = () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        try {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const dataUrl = canvas.toDataURL('image/png');
          setThumbnail(dataUrl);
        } catch (error) {
          console.error('Failed to capture thumbnail:', error);
        }
      } else {
        console.error('Failed to get canvas context');
      }
    } else {
      console.error('Video element is not loaded');
    }
  };

  return (
    <>
      <video
        ref={videoRef}
        src={url}
        style={{ display: 'none' }}
        preload='auto'
      />

      <div
        onClick={handlePreviewFiles}
        className={`w-[246px]  h-[180px]  p-2 rounded-md relative cursor-pointer ${!thumbnail && 'bg-textMidDark'} ${userId === auth.id ? ' bg-bgChatMessage dark:bg-primary_8 ' : 'text-secondaryMidLight dark:text-textMain  bg-bgWhite dark:bg-bgtetriary  '}  `}
      >
        {thumbnail ? (
          <>
            <img
              src={thumbnail}
              alt='Video Thumbnail'
              className='object-cover w-full h-full rounded'
            />
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <VideoPlayIcon />
            </div>
          </>
        ) : (
          <div className='flex justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 loader w-[246px]  h-[246px]'>
            <TailSpin
              height='30'
              width='30'
              color='#005C89'
              ariaLabel='tail-spin-loading'
              radius='2'
              wrapperStyle={{}}
              wrapperClass='tailspin-loader'
              visible={true}
            />
          </div>
        )}

        <span className='absolute bottom-3 right-5 text-xs text-textWhite dark:text-caption'>
          {sendTime}
        </span>
      </div>
    </>
  );
};

export default VideoThumbnail;
