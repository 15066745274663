interface ConditionsType {
  id: number;
  typeName: string;
}

interface Conditions {
  conditionName: string;
  dateAdded: string;
  id: number;
  status: string;
  types: ConditionsType[];
}

export interface IConditionsData {
  conditions: Conditions[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}


export interface IfetchConditions {
  data: IConditionsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchConditions = {
  data: {
    conditions: [],
    fields: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
