import mapPenIcon from "assets/images/map-pen-edit.svg";
import { ReactComponent as RectangleIcon } from "assets/images/empty-rectangle.svg";
import { ReactComponent as CircleIcon } from "assets/images/empty-circle.svg";
import { ReactComponent as PolygonIcon } from "assets/images/empty-polygon.svg";
import { ReactComponent as LineIcon } from "assets/images/line-vertic.svg";
import { ReactComponent as CheckIcon } from "assets/images/check.svg";
import { useState } from "react";

 const shapeTypeOptions = [
  { value: "rectangle", label: "Rectangle", Icon: RectangleIcon },
  { value: "circle", label: "Circle", Icon: CircleIcon },
  { value: "polygon", label: "Polygon", Icon: PolygonIcon },
  { value: "line", label: "Line", Icon: LineIcon },
];
const DrawShapesDropdown = ({
  isVisible,
  toggleDropdownListener,
  selectedDrawShape,
  setSelectedDrawShape,
}: any) => {
  const [currentShape, setCurrentShape] = useState(selectedDrawShape);
  // handlers
  const handleClickOnShape = (shape: string) => {
    setSelectedDrawShape(shape);
    setCurrentShape(shape);
    toggleDropdownListener();
  };

  return (
    <div
      className="w-40 bg-bgWhite h-[180px] absolute top-0 right-[38px] rounded shadow-md"
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <ul className="flex flex-col w-full py-2">
        {shapeTypeOptions.map((shape) => (
          <ListItem
            key={shape.value}
            name={shape.label}
            value={shape.value}
            selectedShape={currentShape}
            handleClickOnShape={handleClickOnShape}
            Icon={shape.Icon}
          />
        ))}

        <li className="flex gap-2 px-4 pt-[15px] pb-[3px] w-full items-center cursor-pointer mt-auto border-t border-t-lineMid ">
          <CheckIcon className="opacity-50 fill-secondaryMid" />
          <span className="text-sm font-Overpass text-secondaryMid -mb-[2px] opacity-50 select-none">
            Finish
          </span>
        </li>
      </ul>
    </div>
  );
};

const ToggleButton = ({ handleClick }: any) => {
  return (
    <button type="button" onClick={handleClick}>
      <img src={mapPenIcon} className="mx-auto" />
    </button>
  );
};

const ListItem = ({
  name,
  value,
  selectedShape,
  handleClickOnShape,
  Icon,
}: any) => {
  return (
    <li
      className={` flex gap-2 px-4 py-[6px] w-full items-center cursor-pointer ${
        selectedShape === value ? "bg-primaryLight" : ""
      } `}
      onClick={() => handleClickOnShape(value)}
    >
      <Icon
        className={`${
          selectedShape === value ? "fill-primary" : "fill-secondaryMid"
        }`}
      />
      <span
        className={`text-sm font-Overpass ${
          selectedShape === value ? "text-primaryDark" : "text-secondaryMid"
        } -mb-[2px] select-none`}
      >
        {name}
      </span>
      {selectedShape === value && (
        <CheckIcon className="ml-auto fill-primaryDark" />
      )}
    </li>
  );
};

export { DrawShapesDropdown, ToggleButton };
