import { useEffect } from "react";
import pointIcon from "../../../../../../../../assets/icons/MapIcons/pointIcon.png";
import rectangleIcon from "../../../../../../../../assets//icons/MapIcons/rectangle-tooltip-icon.png";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import registerImageOnMap from "utils/registerImageOnMap";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR ="#FFA500";
const usePaintClustersPoints = (
  clusterData: any,
  map: any,
  isMapFullyLoaded: boolean
) => {
  useEffect(() => {
    if (!clusterData || !map || !isMapFullyLoaded) return;

    async function loadCluster() {
      if (!map.hasImage("custom-marker")) {
        await registerImageOnMap(map, pointIcon, "custom-marker");
      }
      if (!map.hasImage("cluster-count-bg")) {
        await registerImageOnMap(map, rectangleIcon, "cluster-count-bg");
      }

      if (!map.getSource("earthquakes")) {
        map.addSource("earthquakes", {
          type: "geojson",
          data: clusterData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
      }

      if (!map.getLayer("clusters")) {
        map.addLayer({
          id: "clusters",
          type: "symbol",
          source: "earthquakes",
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "custom-marker",
            "icon-size": 1.5,
            "icon-allow-overlap": true,
          },
        });
      }

      if (!map.getLayer("unclustered-point")) {
        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "earthquakes",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "custom-marker",
            "icon-size": 1.5,
            "icon-allow-overlap": true,
          },
        });
      }

      if (!map.getLayer("cluster-count")) {
        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "earthquakes",
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "cluster-count-bg",
            "icon-size": 0.3,
            "icon-allow-overlap": true,
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
            "text-anchor": "center",
            "text-offset": [0, -3],
            "icon-anchor": "bottom",
            "icon-offset": [0, -4],
          },
          paint: {
            "text-color": "#000",
          },
        });
      }

      map.on(
        "click",
        "clusters",
        (
          e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
          }
        ) => {
          const features: any = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          const clusterId = features[0]?.properties?.["cluster_id"] as
            | number
            | undefined;

          if (clusterId !== undefined) {
            const source = map.getSource(
              "earthquakes"
            ) as mapboxgl.GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              const coordinates = features[0].geometry.coordinates;
              if (coordinates) {
                map.easeTo({
                  center: coordinates,
                  zoom: zoom,
                });
              }
            });
          }
        }
      );
      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    }
    loadCluster();
  }, [clusterData, map, isMapFullyLoaded]);

  return 0;
};

export { usePaintClustersPoints };
