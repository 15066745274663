import React, { forwardRef, useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { InputFields } from "./components/InputFields";

import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import Button from "view/components/Button";
import ArrowRightIcon from "assets/icons/HeroIcons/ArrowRightIcon.tsx";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ISummaryReports } from "store/reportsSummary";
import useReportsStepper, { Step } from "hooks/useReportsStepper";
import { checkIsAnythingUpdatedThisStep } from "../..";

interface ReportNameProps {
  activeStep: Step;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

interface IFormInitialState {
  communityIds: any[];
  filterByEntity: boolean;
  formIds: any[];
  name: string;
  organizationIds: any[];
  programIds: any[];
}

export const ReportName = forwardRef<FormikProps<any>, ReportNameProps>(
  (
    {
      activeStep,
      isLastStep,
      steps,
      reportId,
      reportSummary,
      fetchReportsSummary,
      moveToPreviousStep,
      moveToNextStep,
      setAnythingUpdatedThisStep,
    },
    ref
  ) => {
    const {
      setReportId,
      // moveToNextStep,
    } = useReportsStepper();

    const getProgramsFormsOrganizationsCommunitiesValues = (data: any[]) => {
      const values = data?.map((item: any) => item.value);
      return values;
    };

    const convertDataForMultiselectDropDowns = (data: any[]) => {
      const values = data?.map((entity: any) => ({
        value: entity?.id,
        label: entity?.name,
      }));
      return values;
    };
    const [formInitialValues, setFormInitialValues] =
      useState<IFormInitialState>({
        communityIds: [],
        filterByEntity: true,
        formIds: [],
        name: "",
        organizationIds: [],
        programIds: [],
      });

    useEffect(() => {
      if (reportSummary.filterByEntity) {
        setFormInitialValues((pre: IFormInitialState) => {
          const updatedData = {
            communityIds: convertDataForMultiselectDropDowns(
              reportSummary.communitiesFilter
            ),
            formIds: convertDataForMultiselectDropDowns(
              reportSummary.formsFilter
            ),
            organizationIds: convertDataForMultiselectDropDowns(
              reportSummary.organizationsFilter
            ),
            programIds: convertDataForMultiselectDropDowns(
              reportSummary.programsFilter
            ),
            filterByEntity: reportSummary.filterByEntity ?? true,
            name: reportSummary.reportName,
          };

          return updatedData;
        });
      } else {
        setFormInitialValues((pre: IFormInitialState) => {
          const updatedData = {
            communityIds: [],
            formIds: [],
            organizationIds: [],
            programIds: [],
            filterByEntity: true,
            name: reportSummary.reportName,
          };

          return updatedData;
        });
      }
    }, [reportSummary]);

    /**
     * Handle form submission.
     * @param {object} values - Form values
     * @param {Function} setSubmitting - Function to set submitting state
     * @example
     * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
     */
    const handleSubmit = async (
      values: any,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      // if (!reportSummary.isCreator) {
      //   Toasts.error("You are not authorized to create reports");
      //   setSubmitting(false);
      //   return;
      // }
      if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
        moveToNextStep();
        return;
      }
      try {
        const formValues = {
          ...values,
          communityIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.communityIds
          ),
          formIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.formIds
          ),
          organizationIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.organizationIds
          ),
          programIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.programIds
          ),
        };

        let responseData;

        if (reportId) {
          const { data } = await apiLibrary.Reports.AddReportApis.updateReport(
            reportId,
            formValues
          );
          responseData = data;
        } else {
          const { data } =
            await apiLibrary.Reports.AddReportApis.addReport(formValues);
          responseData = data;
        }

        setReportId(responseData.id);
        await fetchReportsSummary(responseData.id);
        moveToNextStep();
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setSubmitting(false);
      }
    };

    const reportNameSchema = Yup.object().shape({
      communityIds: Yup.array(),
      filterByEntity: Yup.boolean(),
      formIds: Yup.array(),
      name: Yup.string()
        .required("Name is required")
        .max(255, "max 255 characters long"),
      organizationIds: Yup.array(),
      programIds: Yup.array(),
    });

    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={reportNameSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        innerRef={ref}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          setFieldTouched,
          isValid,
          dirty,
        }) => {
          useEffect(() => {
            setAnythingUpdatedThisStep("reportName", dirty);
          }, [dirty]);
          return (
            <Form>
              <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                <InputFields
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  reportSummary={reportSummary}
                />

                <SubmissionButtons
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  // handleGoBackBtn={handleGoBackBtn}
                  values={values}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
);

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
}: any) => {
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      {/* <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleGoBackBtn}
      /> */}
      <Button
        disabled={isSubmitting || !isValid}
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};
