import { TableBodyProps } from "../../types";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";

export const TableBody = ({
  table,
  columnVisibility,
  getPollsAndSurveysActions,
  flexRender,
}: TableBodyProps) => {

  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className="cursor-pointer Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight group dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {row.getVisibleCells().map((cell: Cell<any, any>) => (
            <Column
              hoverEffect="item_hover_effect"
              flexRender={flexRender}
              cell={cell}
              rowData={row}
              columnVisibility={columnVisibility}
            />
          ))}
          <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions
              row={row}
              getPollsAndSurveysActions={getPollsAndSurveysActions}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
