import { shapeTypes } from "store/geography/initialState";

const changeShapeMode = (
  type: string,
  drawRef: any,
  zoom: null | number = null
) => {
  const draw = drawRef?.current ?? drawRef;
  if (draw) {
    switch (type) {
      case shapeTypes.CIRCLE:
        if (zoom) {
          draw.changeMode("draw_circle", {
            initialRadiusInKm: calculateRadiusByZoom(zoom) / 1000,
          });
        }
        else {
          draw.changeMode("draw_circle");
        }
        break;
      case shapeTypes.POLYGON:
        draw.changeMode("draw_polygon");

        break;
      case shapeTypes.LINE:
        draw.changeMode("draw_line_string");

        break;
      case shapeTypes.POINT:
        draw.changeMode("draw_point");

        break;
      case shapeTypes.RECTANGLE:
        draw.changeMode("draw_rectangle");

        break;
    }
  }
};

export default changeShapeMode;

function calculateRadiusByZoom(zoom: number) {
  const baseRadius = 500;
  const zoomLevel10 = 15;
  return baseRadius * Math.pow(2, zoomLevel10 - zoom);
}
