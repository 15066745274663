// Define an interface for the category data
interface ISpeciesType {
  typeId: number;
  name: string;
}

export interface ISpecie {
  alternativeName: string;
  commonName: string;
  dateAdded: string;
  id: number;
  image: string;
  scientificName: string;
  type: ISpeciesType;
  preferredName: string
}

export interface ISpecieData {
  species: ISpecie[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchSpecies {
  data: ISpecieData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchSpecies = {
  data: {
    species: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
