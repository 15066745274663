import React from "react";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";

interface HeaderProps {
  handleClose: () => void;
  handleTitleChange: Boolean;
  inviteDataEndViewerModal?: any;
  isSubmitting?: any
}
export const Header: React.FC<HeaderProps> = ({
  handleClose,
  handleTitleChange,
  inviteDataEndViewerModal,
  isSubmitting,
}) => {
  return (
    <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 ">
        <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain capitalize">
          {inviteDataEndViewerModal.data?.isEdit
            ? "Reviewer details"
            : "Data end viewer details"}
        </p>
      </div>
      <button onClick={handleClose} type="button" disabled={isSubmitting}>
        <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
      </button>
    </div>
  );
};
