import apiClient from "services/apiClient";

async function getTypesDropdown(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
  };

  const res = await apiClient.get(`/admin/types/dropdown`, { params });
  return res.data;
}

export default getTypesDropdown;
