import apiClient from "services/apiClient";

async function updateOrganization(
  organizationId: number,
  organizationData: any
) {
  const url = `/admin/organizations/${organizationId}`;
  const res = await apiClient.put(url, organizationData);

  return res.data;
}

export default updateOrganization;
