import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { currentEnvironment, sentryDNS } from "config";

function initSentry() {
  Sentry.init({
    dsn: sentryDNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/https?:\/\/.*/],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      "https://dev-admin.isn.landscape.network",
      "https://admin.sentinelsnetwork.org",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: currentEnvironment,
  });
}

export default initSentry;
