import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { dynamicFormsCreatedByAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

export const SelectedCreatedBy: React.FC = () => {
  const dispatch = useDispatch();
  const { dynamicFormsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const resetFilter = (program: any) => {
    dispatch(dynamicFormsCreatedByAction(program));
  };

  if (dynamicFormsFilter.createdByIds?.applied) {
    return (
      <div className="flex flex-wrap items-center justify-start gap-2 ">
        {dynamicFormsFilter.createdByIds.value.map(
          (item: any, index: number) => (
            <div
              key={index}
              className="flex justify-between px-2  items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText capitalize">
                  {item.name}
                </p>
              </div>
              <ClearFilters handleClick={() => resetFilter(item)} />
            </div>
          )
        )}
      </div>
    );
  } else {
    return null;
  }
};
