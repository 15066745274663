import {
  ERROR_LOADING_PROTOCOLS,
  GET_ALL_PROTOCOLS,
  RESET_DATA,
  START_LOADING_PROTOCOLS,
  STOP_LOADING_PROTOCOLS,
} from "./action-types";
import initialState, { IProtocol } from "./initialState";
export const protocolsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_PROTOCOLS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_PROTOCOLS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_PROTOCOLS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_PROTOCOLS:
      const protocols = action.payload.protocols.map((item: IProtocol) => {
        const {
          id,
          program,
          protocol,
          form,
          createdBy,
          dateCreated,
          dateUpdated,
          archivedBy,
          dateArchived,
          status,
        } = item;
        const data = {
          id,
          protocolName: {
            id,
            name: protocol,
          },
          programName: program,
          formName: form,
          createdBy,
          dateCreated,
          dateUpdated,
          archivedBy,
          status,
          dateArchived,
        };

        return data;
      });

      return {
        ...state,
        data: {
          protocols: protocols,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
