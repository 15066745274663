import { useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, AddRoleBtn } from "./components";

const generateDynamicNotificationsLinks = () => {
  return [
    {
      path: `/roles-permissions/list`,
      label: `Roles and permissions`,
    },
  ];
};

export const Header = () => {
  const handleAddRole = () => {};
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={generateDynamicNotificationsLinks()} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            {/* <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <AddRoleBtn handleAddRole={handleAddRole} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
