import React, { useState, useEffect, FC } from "react";
import Dropzone from "react-dropzone";
import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import csvFilePlaceholder from "assets/images/Placeholders/csv-file-placeholder.png";
import xlsFilePlaceholder from "assets/images/Placeholders/xls-file-placeholder.png";
import xlsxFilePlaceholder from "assets/images/Placeholders/xlsx-file-placeholder.png";

import ellipsize from "ellipsize";

interface FileUploadProps {
  handleOnDropFiles: (files: File[]) => void;
  handleDeleteFile: (index: number) => void;
  handleUpdateFile: (file: File, index: number) => void;
  files: File[];
  acceptedFileTypes: any;
  isSubmitting?: boolean;
}

export const FileUpload: FC<FileUploadProps> = ({
  handleOnDropFiles,
  handleDeleteFile,
  handleUpdateFile,
  files,
  acceptedFileTypes,
  isSubmitting,
}) => {
  return (
    <div
      className={`w-full flex flex-col gap-2 ${isSubmitting ? "pointer-events-none" : ""
        }`}
    >
      <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
        {({ getRootProps, getInputProps }) => (
          <div className="w-full flex flex-col justify-center items-center px-4 py-3 rounded-lg bg-bgWhite border border-lineDark border-dashed dark:bg-secondaryLight dark:border-lineLight">
            <section>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="w-full flex flex-col items-center">
                  <CloudUploadIcon className="mb-2" fill="#2C3236" />
                  <p className="font-Overpass text-secondaryMid dark:text-caption ">
                    Drag and drop your files here or click in this area to
                    upload file
                  </p>
                </div>
              </div>
            </section>
          </div>
        )}
      </Dropzone>
      {files.length > 0 && (
        <div className="flex flex-wrap justify-start">
          {files.map((file, index) => (
            <FileBox
              file={file}
              index={index}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              acceptedFileTypes={acceptedFileTypes}
              key={index}
            />
          ))}
        </div>
      )}
      <span className="text-sm text-textMidLight font-Overpass dark:text-caption ">
        Supported formats are:{" "}
        <span className="font-bold">.csv, .xls, .xlsx.</span> Max size up to{" "}
        <span className="font-bold">100 MB</span>
      </span>
    </div>
  );
};

interface FileBoxProps {
  file: File;
  index: number;
  handleDeleteFile: (index: number) => void;
  handleUpdateFile: (file: File, index: number) => void;
  acceptedFileTypes: any;
}

const FileBox: FC<FileBoxProps> = ({
  file,
  index,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
}) => {
  const [thumbnail, setThumbnail] = useState<string>(csvFilePlaceholder);

  useEffect(() => {
    const fileType = file.name.split(".").pop()?.toLowerCase();
    setThumbnail(
      fileType === "csv"
        ? csvFilePlaceholder
        : fileType === "xls"
          ? xlsFilePlaceholder
          : fileType === "xlsx"
            ? xlsxFilePlaceholder
            : ""
    );

    return () => {
      if (thumbnail.startsWith("blob:")) {
        URL.revokeObjectURL(thumbnail);
      }
    };
  }, [file, thumbnail]);

  return (
    <div className="flex flex-col items-end gap-1 mr-2">
      <button
        type="button"
        onClick={() => handleDeleteFile(index)}
        className="gap-1"
      >
        <TrashIcon />
      </button>
      <div className="gap-1 p-1 border border-dashed rounded-lg bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-full">
          <Dropzone
            onDrop={(acceptedFiles) =>
              handleUpdateFile(acceptedFiles[0], index)
            }
            multiple={false}
            accept={acceptedFileTypes}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {/* <CameraIcon className="absolute right-1 bg-bgWhite w-7 h-7 rounded-full" /> */}
              </div>
            )}
          </Dropzone>
          {thumbnail && (
            <img
              src={thumbnail}
              alt={`Thumbnail for ${file.name}`}
              className="w-[90px] h-[50px] mx-auto object-contain"
            />
          )}
          <p className="w-full text-sm text-center break-words dark:text-caption ">
            {ellipsize(file.name, 10)}
          </p>
        </div>
      </div>
    </div>
  );
};
