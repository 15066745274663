import React from 'react';
import audioPlaceholder from 'assets/images/Placeholders/audio-placeholder.png';

interface AudioThumbnailProps {
  url: string;
  sendTime?: string;
}

const AudioThumbnail: React.FC<AudioThumbnailProps> = ({ url, sendTime }) => {
  return (
    <div className='w-[65px] h-[80px] overflow-hidden relative'>
      <img
        src={audioPlaceholder}
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </div>
  );
};

export default AudioThumbnail;
