import React from "react";
// Components
import {
  Date,
  Members,
  Search,
  SelectedOrganizations,
  SelectedPrograms,
  Type,
  SelectedTags,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  communitiesResetFiltersAction,
  communitiesResetFiltersExceptOrganizationAction,
  communitiesResetFiltersExceptProgramAction,
} from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting communitiesFilter state from Redux store
  const { communitiesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  
  const dispatch = useDispatch();

  // Getting organizationId from URL parameters
  const { organizationId, programId } = useParams<{
    organizationId: string;
    programId: string;
  }>();
  const typeLabels = ["open", "private"];

  // Function to check if any filter is applied

  const clearAllFilters = () => {
    // if (organizationId) {
    //   dispatch(communitiesResetFiltersExceptOrganizationAction());
    // } else if (programId) {
    //   dispatch(communitiesResetFiltersExceptProgramAction());
    // } else {

    // }
    dispatch(communitiesResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyCommunityFilterApplied(communitiesFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Search />
          <SelectedOrganizations />
          <Type />
          <SelectedTags />
          <SelectedPrograms />
          <Members
            name="members"
            min={communitiesFilter.members.min}
            max={communitiesFilter.members.max}
            isApplied={communitiesFilter.members?.applied}
          />

          <Date
            name="dateAdded"
            from={communitiesFilter.dateAdded.from}
            to={communitiesFilter.dateAdded.to}
            isApplied={communitiesFilter.dateAdded?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

export function isAnyCommunityFilterApplied(communitiesFilter: any): boolean {
  const {
    type,
    searchBy,
    searchString,
    dateAdded,
    programIds,
    members,
    organizationIds,
    tagIds,
  } = communitiesFilter;
  const applied =
    (searchBy && searchString !== "") ||
    dateAdded?.applied ||
    programIds?.applied ||
    members?.applied ||
    organizationIds?.applied ||
    tagIds?.applied ||
    (type?.applied && ["open", "private"].includes(type.value));

  return applied;
}
