import apiClient from "services/apiClient";

async function getAllRecipients(queryParams: any) {
  const res = await apiClient.get(`/admin/conversations`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllRecipients;
