import usePermissions from "hooks/usePermissions";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import apiLibrary from "services/api";
import MultiSelect from "view/components/Multiselect";

interface MarkerStylesSelectorProps {
  communityId?: any;
  groupId?: any;
  programId?: any;
  error: any;
  handleStyleSelectorOnChange: (value: any) => void;
  value?: any;
  title: string;
}

interface MarkerStylesParams {
  page: number;
  groupId: string;
  communityId?: string;
}

interface MarkerStyleData {
  totalPages: number;
}

const MarkerStylesSelector: React.FC<MarkerStylesSelectorProps> = ({
  communityId = null,
  groupId = null,
  programId = null,
  error,
  handleStyleSelectorOnChange,
  value = null,
  title,
}) => {
  const { markerStyles } = usePermissions();
  const [inputValue, setInputValue] = useState("");

  const [allMarkerStyles, setAllMarkerStyles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);

  const fetchAllMarkerStyles = useCallback(
    async (
      isLoadMore: boolean,
      page: number,
      groupId: { label: string; value: string },
      communityId?: string,
      programId?: any
    ) => {
      if (!markerStyles.canViewDropdown || checkObject(groupId) === null) return
      const params: MarkerStylesParams = {
        page,
        groupId: groupId.value,
        ...(communityId ? { communityId } : {}),
        ...(programId ? { programId } : {}),
      };

      try {
        const { data } =
          await apiLibrary.markerGroupsAndStyles.getMarkerStylesDropdown(
            params
          );

        setAllMarkerStyles((prev) =>
          isLoadMore ? [...prev, ...data.markerStyles] : data.markerStyles
        );

        setTotalPages(data.totalPages);
        setHasMoreData(data.totalPages > page);
      } catch (error) {
        console.error("Error fetching MarkerStyles:", error);
      }
    },

    [allMarkerStyles]
  );

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchAllMarkerStyles(true, page, groupId, communityId, programId);
      return page;
    });
  };

  useEffect(() => {
    if (groupId) {
      fetchAllMarkerStyles(false, 1, groupId, communityId, programId);
    }
  }, [groupId, communityId]);
  return (
    <div className="flex flex-col items-start self-stretch justify-start w-full gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start ">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start pt-1">
            <p
              className={`flex-grow w-full text-sm font-medium text-left capitalize pb-1 dark:text-caption ${error ? "text-accent_1Dark" : "text-secondaryMid"
                }`}
            >
              {title}
            </p>
          </div>

          {markerStyles.canViewDropdown ? (
            <InfiniteScroll
              dataLength={allMarkerStyles.length}
              next={handleLoadMore}
              hasMore={hasMoreData}
              height={100}
              className="w-full"
              style={{ width: "100%" }}
              loader={
                <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                  {/* <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                /> */}
                </div>
              }
            >
              {allMarkerStyles.length === 0 && (
                <div className="flex items-center justify-center align-center h-[100px]">
                  <p className="w-full text-lg font-semibold text-center text-textMid font-Overpass dark:text-textMain">
                    No Style Found
                  </p>
                </div>
              )}
              <div className="flex flex-wrap items-start justify-start w-full gap-2">
                {allMarkerStyles?.map((item: any, key: number) => {
                  return (
                    <button
                      type="button"
                      key={key}
                      onClick={() => {
                        handleStyleSelectorOnChange(item);
                      }}
                      className={`flex justify-center items-center  w-12 h-12 relative  rounded-lg border ${item.id === value ? "border-[#005c89]" : "border-[#bdc0c4]"} `}
                    >
                      <img
                        src={item.markerStyleImage.thumb}
                        alt=""
                        className="object-cover mx-2 Img_user_Data"
                        style={{ flexShrink: 0 }}
                      />
                    </button>
                  );
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <p>
              Sorry, you do not have permission to access the marker styles.
            </p>
          )}

          <div className="relative flex items-center self-stretch justify-start ">
            {error && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkerStylesSelector;



interface MyObject {
  value: string | null | undefined;
  label: string | null | undefined;
}
function checkObject(obj: MyObject | null | undefined): MyObject | null {
  if (
    obj === null ||
    obj === undefined ||
    obj.value === null ||
    obj.value === undefined ||
    obj.value === "" ||
    obj.label === null ||
    obj.label === undefined ||
    obj.label === ""
  ) {
    return null;
  }
  return obj;
}
