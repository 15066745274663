import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import useRoles from "hooks/roles";
import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import { RootState } from "store";
import { setLoadingProgress } from "store/loadingBar";
import { UserProfileState } from "store/userProfile/initialState";
import { fetchUserProfileAction } from "store/userProfile/reducer.actions";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";

interface DeletePhoneBtnProps {
  remove: any;
  currentPhoneIndex: any;
  isPhoneExist: any;
  refreshNewData: (data: any) => void;
}
export const DeletePhoneBtn: React.FC<DeletePhoneBtnProps> = ({
  remove,
  currentPhoneIndex,
  isPhoneExist,
  refreshNewData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { isMyProfile } = useRoles();
  const { personId } = useParams();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { profile, users } = usePermissions();
  const existingNumberIndex = userProfile.secondaryPhoneNumber.findIndex(
    (item: any) => item.phoneNumber === isPhoneExist?.phoneNumber
  );
  const handleRemovePhone = async (currentPhoneIndex: number) => {
    setIsLoading(true);
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      if (
        isPhoneExist &&
        !isPhoneExist.isPrimary &&
        existingNumberIndex === currentPhoneIndex &&
        profile.canEditSecondaryEmailPhoneProfile
      ) {
        const res = await apiLibrary.userProfile.deleteSecondaryEmailOrPhone(
          isPhoneExist.phoneNumber,
          personId
        );
        refreshNewData(res.data);
        // Display a success message using res
        Toasts.success(res.message);
        if (users.canViewUsersProfile) {
          dispatch(fetchUserProfileAction(personId, isMyProfile(personId)));
        }
      }
      remove(currentPhoneIndex);
    } catch (error: any) {
      // Handle the error if needed
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  if (!profile.canEditSecondaryEmailPhoneProfile) {
    return <></>;
  }

  return (
    <>
      {profile.canDeleteSecondaryEmailPhoneProfile && (
        <button
          disabled={
            isLoading ||
            isPhoneExist.isPrimary ||
            !profile.canEditSecondaryEmailPhoneProfile
          }
          type="button"
          onClick={() => handleRemovePhone(currentPhoneIndex)}
          className="relative  flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 disabled:cursor-not-allowed rounded-3xl focus:outline-none"
          title="Delete"
          aria-label="Delete"
        >
          <TrashIcon width={24} height={24} fill="#2C3236" />
        </button>
      )}
    </>
  );
};
