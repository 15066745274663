import { ImportBtnsProps } from "../../types";
import Button from "view/components/Button";

export const ImportBtns = ({ handleClose }: ImportBtnsProps) => {
  return (
    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Cancel"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleClose}
      />
    </div>
  );
};

