import { Dispatch } from "redux";
import {
  ERROR_LOADING_COMMUNITY_GEOGRAPHIES,
  GET_ALL_COMMUNITY_GEOGRAPHIES,
  RESET_DATA,
  START_LOADING_COMMUNITY_GEOGRAPHIES,
  STOP_LOADING_COMMUNITY_GEOGRAPHIES,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchCommunityGeographies =
  (communityId: any) =>
    async (dispatch: Dispatch, getState: any) => {
      // dispatch(loadingState(true))
      dispatch({ type: START_LOADING_COMMUNITY_GEOGRAPHIES });
      // dispatch(setLoadingProgress(getRandomProgress()));

      try {
        
        const { data } =
          await apiLibrary.Communities.getCommunityMapGeographies(communityId);

        dispatch({
          type: GET_ALL_COMMUNITY_GEOGRAPHIES,
          payload: data,
        });
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
        dispatch({
          type: RESET_DATA,
        });
        console.log("An error occurred while fetching the user profile:", error);
        // dispatch(fetchError(error))
        dispatch({ type: ERROR_LOADING_COMMUNITY_GEOGRAPHIES });
      } finally {
        dispatch({ type: STOP_LOADING_COMMUNITY_GEOGRAPHIES });
        // dispatch(setLoadingProgress(100));

        // dispatch(loadingState(false))
      }
    };

