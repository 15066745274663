import { apiClient } from "../config";

async function editPermission(roleId: any, permissions: any) {
  const url = `/admin/permissions/${roleId}`;
  const res = await apiClient.put(url, {
    data: {
      permissions,
    },
  });
  return res.data;
}

export default editPermission;
