import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const RefreshIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M4 3a1 1 0 0 1 1 1v2.343A8.982 8.982 0 0 1 12 3c4.59 0 8.377 3.436 8.93 7.876a1 1 0 1 1-1.984.248A7.001 7.001 0 0 0 6.254 8H9a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm-.062 9.008a1 1 0 0 1 1.116.868A7.001 7.001 0 0 0 17.746 16H15a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-2.343A8.982 8.982 0 0 1 12 21c-4.59 0-8.377-3.436-8.93-7.876a1 1 0 0 1 .868-1.116Z"
      clipRule="evenodd"
    />
  </svg>
);
export default RefreshIcon;
