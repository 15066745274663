import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import ExpandAllIcon from "assets/icons/CustomIcons/ExpandAllIcon";
import CollapseAllIcon from "assets/icons/CustomIcons/CollapseAllIcon";

interface CollapseOptionsProps {
    onExpandAll: () => void;
    onCollapseAll: () => void;
}

export const CollapseOptions: React.FC<CollapseOptionsProps> = ({ onExpandAll, onCollapseAll }) => {
    const observationProfile = useSelector<RootState, IProfileObservation>(
        (state) => state?.observationProfile
    );
    const { primary } = useSelector((state: any) => state.theme.colors);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    return (
        <div className="flex justify-end">
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                    type="button"
                    text="Expand All"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 text-sm"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    icon={<ExpandAllIcon fill={primary} />}
                    iconPosition="before"
                    onClick={onExpandAll}
                />

                <Button
                    // disabled={isLoading}
                    type="button"
                    text="Collapse All"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 text-sm"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                    icon={<CollapseAllIcon />}
                    iconPosition="before"
                    onClick={onCollapseAll}
                />
            </div>
        </div>
    );
};
