import {
  FETCH_SURVEYJS_FORM_DETAILS_START,
  FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
  FETCH_SURVEYJS_FORM_DETAILS_FAILURE,
  RESET_SURVEYJS_FORM_DATA,
  FETCH_SURVEYJS_FORM_DETAILS_COMPLETE,
} from "./action-types";
import { initialState } from "./initialState";

const formSurveyJsDetailsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case FETCH_SURVEYJS_FORM_DETAILS_START:
      return {
        ...state,
        stateIs: "Pending",
      };

    case FETCH_SURVEYJS_FORM_DETAILS_SUCCESS:
      return {
        ...state,
        formDetails: action.payload,
        stateIs: "Idle",
        message: "",
      };

    case FETCH_SURVEYJS_FORM_DETAILS_FAILURE:
      return {
        ...state,
        stateIs: "Error",
        message: action.error,
      };

    case FETCH_SURVEYJS_FORM_DETAILS_COMPLETE:
      return {
        ...state,
        stateIs: "Idle",
      };

    case RESET_SURVEYJS_FORM_DATA:
      return initialState;

    default:
      return state;
  }
};

export default formSurveyJsDetailsReducer;
