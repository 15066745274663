import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const RefreshSingleCircleIcon = ({
  fill = "#005C89",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    className="flex-grow-0 flex-shrink-0 w-3 h-5 relative"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M2.36021 3.44264C2.28938 3.4239 2.22353 3.38983 2.16732 3.34282C2.11112 3.29582 2.06593 3.23704 2.03496 3.17064C2.00398 3.10424 1.98798 3.03184 1.98808 2.95857C1.98818 2.88531 2.00439 2.81295 2.03554 2.74664L3.14221 0.389973C3.18252 0.303672 3.24664 0.23068 3.32702 0.179586C3.40741 0.128492 3.50072 0.101423 3.59597 0.101563C3.69122 0.101703 3.78445 0.129047 3.86468 0.180377C3.94492 0.231707 4.00882 0.304887 4.04888 0.391306L4.53688 1.43731L4.59021 1.41931C5.74155 1.11095 6.96234 1.18975 8.06448 1.64355C9.16663 2.09735 10.089 2.90097 10.6894 3.9306C11.2898 4.96024 11.535 6.15874 11.3872 7.34145C11.2394 8.52417 10.7068 9.62546 9.87145 10.4757C9.03609 11.3259 7.94433 11.8778 6.76441 12.0464C5.58448 12.215 4.38184 11.9909 3.3418 11.4087C2.30176 10.8265 1.48203 9.91841 1.0089 8.82443C0.535772 7.73044 0.435504 6.51123 0.723544 5.35464C0.739478 5.29091 0.767809 5.23093 0.80692 5.17815C0.846031 5.12536 0.895157 5.0808 0.951491 5.047C1.00782 5.01319 1.07026 4.99082 1.13525 4.98115C1.20023 4.97148 1.26648 4.97471 1.33021 4.99064C1.39395 5.00657 1.45392 5.03491 1.5067 5.07402C1.55949 5.11313 1.60406 5.16225 1.63786 5.21859C1.67166 5.27492 1.69403 5.33736 1.7037 5.40234C1.71337 5.46732 1.71014 5.53357 1.69421 5.59731C1.46062 6.53606 1.5405 7.52545 1.92166 8.41458C2.30281 9.3037 2.96433 10.0438 3.80531 10.5219C4.64629 11 5.62056 11.1899 6.57954 11.0626C7.53851 10.9354 8.42954 10.4979 9.11673 9.81705C9.80391 9.13617 10.2495 8.2492 10.3856 7.29144C10.5217 6.33368 10.3408 5.3577 9.87043 4.51235C9.4001 3.667 8.66618 2.9987 7.7806 2.60936C6.89503 2.22003 5.90641 2.13104 4.96554 2.35597L5.45888 3.41131C5.49926 3.4975 5.51421 3.59342 5.50196 3.68781C5.4897 3.7822 5.45076 3.87114 5.38971 3.94416C5.32866 4.01718 5.24804 4.07126 5.15731 4.10005C5.06658 4.12883 4.96953 4.13112 4.87754 4.10664L2.36021 3.44264Z"
      fill={fill}
    />
  </svg>
);
export default RefreshSingleCircleIcon;
