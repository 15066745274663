export interface Organization {
  address: null | string;
  communities: number;
  createdBy: {
    id: number;
    name: string;
    profileImage: {
      file: null | string;
      id: null | number;
      path: null | string;
    };
  }[];
  dateAdded: string;
  id: number;
  members: number;
  missionStatement: string;
  name: string;
  organizationImage: {
    file: {
      original: string;
      thumb: string;
    };
    id: number;
    path: string;
  };
  programs: number;
  status: string;
  tags: string[];
  type: string;
  openAccess: boolean;
}

export interface OrganizationsData {
  organizations: Organization[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchOrganizations {
  data: OrganizationsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialOrganizationsState: IfetchOrganizations = {
  data: {
    organizations: [],
    fields: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialOrganizationsState;
