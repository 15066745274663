import { apiClient } from "../config";

async function changePrimaryEmailOrPhone(payload: any,personId:any) {
  const res = await apiClient.put(
    `/admin/profile/primary-email-phone/${personId}`,
    payload
  );
  return res.data;
}

export default changePrimaryEmailOrPhone;
