import React, { useState } from "react";
// Components
import { TableBody, TableHead } from "./components";
// Store Utils
import { joinRequestSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./JoinRequestMembers.scss";
// types
import { TableProps } from "./types";

export const KEYS_TO_BE_REMOVED = ["id"];
export const Table: React.FC<TableProps> = ({
  table,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        if (columnId === "alternativeNames") {
          dispatch(joinRequestSortingAction("alternative_name", "asc"));
        } else {
          dispatch(joinRequestSortingAction(columnsId, "asc"));
        }
        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        if (columnId === "alternativeNames") {
          dispatch(joinRequestSortingAction("alternative_name", "desc"));
        } else {
          dispatch(joinRequestSortingAction(columnsId, "desc"));
        }
        return [{ columnId, type: "desc" }];
      } else {
        dispatch(joinRequestSortingAction("", ""));

        return [];
      }
    });
  };  
  const [hoveredHeader, setHoveredHeader] = useState<any>([]);
  const handleClickOnTableColumnHeading = (header: any, id: any) => {
    if(id === 1){
      setHoveredHeader("");
      header.column.getCanSort() && toggleSorting(header.column.id);
    }else {
      setHoveredHeader(header.id)
    }

  }
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
          setHoveredHeader= {setHoveredHeader}
          hoveredHeader= {hoveredHeader}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        />
      </table>
    </div>
  );
};
