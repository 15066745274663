import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import { IProtocol } from "store/protocolProfile/initialState";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import { Toasts } from "view/components/Toasts";
import DownloadIcon from "assets/icons/HeroIcons/DownloadIcon";
import ArchiveIcon from "assets/icons/HeroIcons/ArchiveIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { openChatArchiveModal, openChatDeleteModal } from "store/modals";
import { filtersInitialState } from "store/filters/initialState";
import apiLibrary from "services/api";
import { fetchRecipientsAction } from "store/messages";
import VolumeOffIconOutlined from "assets/icons/HeroIcons/VolumeOffIconOutlined";
import DoubleChatIcon from "assets/icons/HeroIcons/DoubleChatIcon";
import { GET_RECIPIENTS } from "store/messages/action-types";
import { IfetchRecipients } from "store/messages/initialState";
import { cloneDeep } from "lodash";
import { IChatDetails } from "store/messagesDetail/initialState";
import usePermissions from "hooks/usePermissions";

interface MessageActionProps {
  selectedContact: any | null;
}

export const MessageActions: React.FC<MessageActionProps> = ({
  selectedContact,
}) => {
  const { messageStatusFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { data, isLoading } = useSelector<RootState, IfetchRecipients>(
    (state) => state?.allRecipients
  );

  const chatDetails = useSelector<RootState, IChatDetails>(
    (state) => state.chatDetails
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleNotificationStatus = async () => {
    try {
      if (selectedContact?.id) {
        const queryParams = {
          muted: selectedContact?.muted ? false : true,
        };

        const { message } = await apiLibrary.Messages.updateMuteStatus(
          selectedContact.id,
          queryParams
        );

        // Updating recipient's muted status
        const updatedRecipients = data.recipients.map((recipient) => {
          if (recipient.id === selectedContact.id) {
            // Toggle the muted status
            recipient.muted = !recipient.muted;
          }
          return recipient;
        });

        const updatedData = {
          ...data,
          conversations: updatedRecipients,
        };

        dispatch({
          type: GET_RECIPIENTS,
          payload: updatedData,
        });

        // dispatch(fetchRecipientsAction());
        Toasts.success(message);
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const chatDownload = async () => {
    if (!selectedContact?.id) {
      Toasts.error("Please select chat to download");
      return;
    }

    try {
      const res = await apiLibrary.Messages.exportChat(selectedContact?.id);

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `messages_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  const messagesActions = (link: string) => {
    const { conversations } = usePermissions();
    const actions: {
      label: string;
      onClick: () => void;
      Icon: React.ElementType;
      iconProps: { fill?: string; width?: number; height?: number };
      divider: boolean;
      isVisible: boolean;
    }[] = [
      {
        label: selectedContact?.muted
          ? "Turn on notifications"
          : "Turn off notifications",
        onClick: () => handleNotificationStatus(),
        Icon: VolumeOffIconOutlined,
        iconProps: { fill: "#2C3236", width: 20, height: 20 },
        divider: false,
        isVisible:
          !messageStatusFilter.archived &&
          conversations.canEditMuteUnmuteConversations,
      },
      {
        label: "Export chat history",
        onClick: chatDownload,
        Icon: DownloadIcon,
        iconProps: { fill: "#2C3236", width: 20, height: 20 },
        divider: false,
        isVisible: conversations.canViewExportConversations,
      },
      {
        label: messageStatusFilter.archived ? "Restore chat" : "Archive chat",
        onClick: () => {
          dispatch(openChatArchiveModal(selectedContact));
        },
        Icon: messageStatusFilter.archived ? DoubleChatIcon : ArchiveIcon,
        iconProps: { fill: "#2C3236", width: 20, height: 20 },
        divider: false,
        isVisible: conversations.canArchiveConversations,
      },
      {
        label: "Remove chat",
        onClick: () => {
          dispatch(openChatDeleteModal(selectedContact));
        },
        Icon: TrashIcon,
        iconProps: { fill: "#2C3236", width: 20, height: 20 },
        divider: false,
        isVisible: conversations.canDeleteChatConversations,
      },
    ];

    // // Conditionally remove the first item if messageStatusFilter.archived is true
    // if (messageStatusFilter.archived) {
    //   actions.splice(0, 1);
    // }

    return actions;
  };

  return (
    <div className="">
      <div className="items-center w-full rounded-lg hover:bg-primaryExtraLight">
        <div className="">
          <PopOverMenu
            style="ml-2"
            icon={
              <DotsVerticalIcon
                className={
                  chatDetails?.isLoading ? "pointer-events-none" : "pointer"
                }
              />
            }
          >
            <ActionDropDownMenu actions={messagesActions("")} />
          </PopOverMenu>
        </div>
      </div>
    </div>
  );
};
