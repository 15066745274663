import LinkDisconnectIcon from "assets/icons/HeroIcons/LinkDisconnectIcon";
import { FormikProps, FormikValues } from "formik";
import React, { useState } from "react";
import Button from "view/components/Button";
import SearchInput from "../../components/SearchInput";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import usePermissions from "hooks/usePermissions";

// Program Interface
interface ProgramItem {
  value: string;
  label: string;
}

// Form Interface
interface FormItem {
  value: string;
  label: string;
}

// Interface For Modal Form extending Formik values
interface ManageConnectionModalFormProps extends FormikProps<FormikValues> {
  handleClose: () => void;
  programList: ProgramItem[];
  handleProgramSearch: (search: string) => void;
  fetchProtocolFormsByPrograms: (
    programId: number,
    searchString?: string
  ) => void;
  setFormsList: any;
  formsList: FormItem[];
  handleMenuScrollToBottom: () => void;
  loading?: boolean;
  setDisConnectProtocol: (data: boolean) => void;
}

const ManageConnectionModalForm: React.FC<ManageConnectionModalFormProps> = ({
  handleClose,
  setFieldValue,
  values,
  errors,
  touched,
  programList,
  isSubmitting,
  handleProgramSearch,
  fetchProtocolFormsByPrograms,
  formsList,
  setFormsList,
  handleMenuScrollToBottom,
  loading,
  setDisConnectProtocol,
}) => {
  // States
  const [inputValue, setInputValue] = useState("");
  const [forminputValue, setFormInputValue] = useState("");

  // Making program and form values empty on disconnecting protocol
  const disconnectProtocol = () => {
    setDisConnectProtocol(true);
    if (values?.programId?.label !== "" || values?.formId?.label !== "") {
      setFieldValue("programId", { value: "", label: "" });
      setFieldValue("formId", []);
      setFieldValue("disConnectProtocol", true);
    }
    return;
  };

  const filteredForms = values?.formId?.filter(
    (form: any) => form?.isInherit === false || form?.isInherit === undefined
  );

  const form =
    filteredForms && filteredForms.length > 0
      ? filteredForms.map((form: any) => ({
          label: form?.name || form?.label,
          value: form?.id || form?.value,
        }))
      : null;

  const { protocols } = usePermissions();

  return (
    <div className="p-6 w-[700px] rounded-lg overflow-y-auto bg-bgWhite">
      <div className="relative flex flex-col items-start justify-start gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
        <div className="flex justify-between items-start self-stretch py-0.5">
          <div className="relative flex items-center justify-start">
            <p className="flex-grow text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
              Manage Connection
            </p>
          </div>
          <button onClick={handleClose} type="button">
            <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
          </button>
        </div>
      </div>
      <div className="w-full overflow-y-auto px-1 pb-4 max-h-[70vh]">
        <div className="mt-4">
          <p className="text-textMidLight pt-2">
            Choose the program and form you want to connect to this protocol.
          </p>
        </div>
        <div>
          <div className="w-full flex gap-4">
            <div className="w-1/2">
              <SearchInput
                inputValue={inputValue}
                name="programId"
                label="Program"
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleProgramSearch(value);
                  setDisConnectProtocol(false);
                }}
                options={programList}
                defaultValue={{ value: "", label: "" }}
                isMulti={false}
                value={values?.programId?.value ? [values?.programId] : []}
                onChange={(selectedOption: any) => {
                  if (selectedOption?.value) {
                    fetchProtocolFormsByPrograms(selectedOption.value);
                  } else if (selectedOption?.value == null) {
                    setFormsList([]);
                  }
                  setFieldValue("formId", null);
                  setFieldValue("programId", selectedOption);
                  setFieldValue("disConnectProtocol", true);
                }}
                placeholder="Program Name"
                touched={touched.programId}
                error={errors?.programId}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                isLoading={loading}
              />
            </div>
            <div className="w-1/2">
              <SearchInput
                inputValue={forminputValue}
                name="formId"
                label="Form"
                onInputChange={(value: string) => {
                  setFormInputValue(value);
                  if (values?.programId?.value) {
                    fetchProtocolFormsByPrograms(values.programId.value, value);
                  }
                }}
                options={
                  values?.programId && values?.programId?.label ? formsList : []
                }
                defaultValue={{ value: "", label: "" }}
                isMulti={false}
                value={form}
                onChange={(selectedOption: any) => {
                  setFieldValue("formId", [selectedOption]);
                }}
                placeholder="Form Name"
                touched={touched.formId}
                error={errors?.formId}
              />
            </div>
          </div>
          {protocols.canDisconnectProgramForm && (
            <button
              type="button"
              disabled={
                (values?.programId == null || values?.programId?.label == "") &&
                (values?.formId == null || values?.formId?.label == "")
              }
              className="inline-flex gap-1 py-4 font-medium cursor-pointer disabled:text-secondaryLight text-textMid disabled:cursor-not-allowed dark:text-textMain"
              onClick={disconnectProtocol}
            >
              <LinkDisconnectIcon />
              Disconnect Protocol
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-2">
        <Button
          type="button"
          text="Cancel"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="w-24 h-11"
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={handleClose}
        />
        <Button
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          text="Save"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="w-24 h-11"
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="filled"
        />
      </div>
    </div>
  );
};

export default ManageConnectionModalForm;
