import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

interface CustomizedSwitchProps extends SwitchProps {
  label?: string;
  checked?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const IOSSwitch = styled(
  ({ label, checked, onChange, ...props }: CustomizedSwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={checked}
      onChange={onChange}
      {...props}
    />
  )
)(({ theme }) => ({
  width: 24,
  height: 14,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(10px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#005C89" : "#005C89",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 10,
    height: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CustomizedSwitches: React.FC<CustomizedSwitchProps> = (props) => {
  const { label, checked, onChange, ...switchProps } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={checked}
            onChange={onChange}
            {...switchProps}
          />
        }
        label={
          <div className="flex justify-start items-center flex-grow pl-2">
            <div className="flex justify-start items-center flex-grow relative py-0.5">
              <p className="text-[15px]">{label}</p>
            </div>
          </div>
        }
      />
    </FormGroup>
  );
};
