import usePermissions from "hooks/usePermissions";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import { RootState } from "store";
import {
  updateAllCommunityLayers,
  updateAllGlobalCommunityLayers,
  updateAllGlobalCommunityLayersFetchingProgress,
} from "store/surveyJS";

const useFetchAllGlobalCommunityLayers = () => {
  const dispatch = useDispatch();
  const { formId } = useParams();
  const { allGlobalCommunityLayers } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const { dynamicForms } = usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      if (
        !formId ||
        (allGlobalCommunityLayers && allGlobalCommunityLayers?.length != 0)
      )
        return;
      dispatch(updateAllGlobalCommunityLayersFetchingProgress(false));
      try {
        if (!dynamicForms.canViewDynamicFormGeographies) {
          return;
        }
        const response = await surveyJsApi.default.fetchLayers(
          formId,
          "layers"
        );

        dispatch(updateAllGlobalCommunityLayers(response.data));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(updateAllGlobalCommunityLayersFetchingProgress(true));
      }
    };

    fetchData();

    return () => {
      dispatch(updateAllGlobalCommunityLayers([]));
    };
  }, [formId, dispatch]);

  return null;
};

export { useFetchAllGlobalCommunityLayers };
