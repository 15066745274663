export interface ImportInfo {
  comment: string,
  dataReceivedFrom: string,
  fileName: string,
  importDate: string,
  importedBy: {
    id: number,
    name: string,
  }
}

export interface ImportInfoData {
  data: ImportInfo,
  message: string,
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: ImportInfoData = {
  data: {
    comment: "",
    dataReceivedFrom: "",
    fileName: "",
    importDate: "",
    importedBy: {
      id: 0,
      name: ""
    }
  },
  message: "",
  stateIs: "Idle",
};

export { initialState };
