import { apiClient } from "../config";

async function addExternalReviewer(observationId: string, body: any) {
  const { firstName, lastName, accessUntil, email } = body;

  const res = await apiClient.post(
    `/admin/observations/${observationId}/external-reviewer`,
    {
      person: {
        firstName,
        lastName,
      },
      user: {
        accessUntil,
        email,
      },
    }
  );
  return res.data;
}

export default addExternalReviewer;
