// Components
import { ClearFilters } from "Components/Users/ClearFilters";
// Hooks
import useDateRange from "hooks/useDateRange";
// Store utils
import { useDispatch } from "react-redux";
// External Tools
import { sentenceCase } from "change-case";
import { reportsAnalyticsDateAction } from "store/filters/reducer.actions";

// Defining the DateProps interface for the Date component.
interface DateProps {
  name: string;
  from: string;
  to: string;
  isApplied: boolean;
}
// Defining the Date component as a functional component that receives DateProps.
export const Date: React.FC<DateProps> = ({ name, from, to, isApplied }) => {
  const dispatch = useDispatch(); // Initializing the Redux dispatch function.
  const { getDateOption } = useDateRange(); // Using the useDateRange hook to get the getDateOption function.

  // Defining a function to reset the filter when ClearFilters is clicked.
  const resetFilter = () => {
    dispatch(dispatch(reportsAnalyticsDateAction("", "")));
  };

  if (!isApplied) {
    return null;
  }

  return (
    <div className="flex flex-wrap justify-center items-center relative gap-1 px-4 rounded-lg bg-primary/[0.08]">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid">
          {sentenceCase(name)}: {getDateOption(from, to)}
        </p>
      </div>
      {/* <ClearFilters handleClick={resetFilter} /> */}
    </div>
  );
};
