import axios, { AxiosResponse } from "axios";
import apiClient from "services/apiClient";

interface GetProgramUserMembersParams {
  programId: number;
  communityId?: number;
  searchString?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
}

const getProgramUserMembers = async ({
  programId,
  communityId,
  searchString,
  page,
  sortBy,
  sortType,
}: GetProgramUserMembersParams) => {
  const response = await apiClient.get(
    `/admin/programs/${programId}/user-members`,
    {
      params: {
        community_id: communityId,
        search_string: searchString,
        page,
        limit: 10000,
        sort_by: sortBy,
        sort_type: sortType,
      },
    }
  );

  return response.data;
};

export default getProgramUserMembers;
