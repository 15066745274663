import { ModalInitialState, modalsInitialStates } from './initialState';
import * as actionTypes from './action-types';
export const modalsReducer = (
  state: ModalInitialState = modalsInitialStates,
  action: any
): ModalInitialState => {
  switch (action.type) {
    case actionTypes.CLOSE_DASHBOARD_ALERT_MODAL:
      localStorage.setItem('dashboardWelcomeAlert', 'false');
      return {
        ...state,
        dashboardAlertConfirmationModal: {
          ...state.dashboardAlertConfirmationModal,
          isWelcomeAlertOpen: false,
          isOpen: false,
        },
      };
    case actionTypes.CLOSE_DASHBOARD_ALERT_CONFIRMATION_MODAL:
      return {
        ...state,
        dashboardAlertConfirmationModal: {
          ...state.dashboardAlertConfirmationModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_DASHBOARD_ALERT_CONFIRMATION_MODAL:
      return {
        ...state,
        dashboardAlertConfirmationModal: {
          ...state.dashboardAlertConfirmationModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_SIGNOUT_MODAL:
      return {
        ...state,
        signOutModal: {
          ...state.signOutModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_SIGNOUT_MODAL:
      return {
        ...state,
        signOutModal: {
          ...state.signOutModal,
          isOpen: true,
        },
      };
    case actionTypes.OPEN_EMAIL_VERIFICATION_MODAL:
      return {
        ...state,
        emailVerificationModal: {
          ...state.emailVerificationModal,
          isOpen: true,
          value: action.payload.value,
        },
      };
    case actionTypes.CLOSE_EMAIL_VERIFICATION_MODAL:
      return {
        ...state,
        emailVerificationModal: {
          ...state.emailVerificationModal,
          isOpen: false,
          value: '',
        },
      };
    case actionTypes.OPEN_PHONE_NUMBER_VERIFICATION_MODAL:
      return {
        ...state,
        phoneVerificationModal: {
          ...state.phoneVerificationModal,
          isOpen: true,
          value: action.payload.value,
        },
      };
    case actionTypes.CLOSE_PHONE_NUMBER_VERIFICATION_MODAL:
      return {
        ...state,
        phoneVerificationModal: {
          ...state.phoneVerificationModal,
          isOpen: false,
          value: '',
        },
      };
    case actionTypes.OPEN_DELETE_ACCOUNT_MODAL:
      return {
        ...state,
        deleteAccountModal: {
          ...state.deleteAccountModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_DELETE_ACCOUNT_MODAL:
      return {
        ...state,
        deleteAccountModal: {
          ...state.deleteAccountModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_REMOVE_PERSON_COMMUNITY_MODAL:
      return {
        ...state,
        removePersonFromCommunityModal: {
          ...state.removePersonFromCommunityModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_REMOVE_PERSON_COMMUNITY_MODAL:
      return {
        ...state,
        removePersonFromCommunityModal: {
          ...state.removePersonFromCommunityModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_DELETE_ACCOUNT_REQUEST_MODAL:
      return {
        ...state,
        deleteAccountRequestModal: {
          ...state.deleteAccountRequestModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_DELETE_ACCOUNT_REQUEST_MODAL:
      return {
        ...state,
        deleteAccountRequestModal: {
          ...state.deleteAccountRequestModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_RESET_PASSWORD_MODAL:
      return {
        ...state,
        resetPasswordModal: {
          ...state.resetPasswordModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_RESET_PASSWORD_MODAL:
      return {
        ...state,
        resetPasswordModal: {
          ...state.resetPasswordModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_CREATE_PASSWORD_MODAL:
      return {
        ...state,
        createPasswordModal: {
          ...state.createPasswordModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_CREATE_PASSWORD_MODAL:
      return {
        ...state,
        createPasswordModal: {
          ...state.createPasswordModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REINVITE_USER_MODAL:
      return {
        ...state,
        reinviteUserModal: {
          ...state.reinviteUserModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_REINVITE_USER_MODAL:
      return {
        ...state,
        reinviteUserModal: {
          ...state.reinviteUserModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL:
      return {
        ...state,
        confirmationAssignAsAdminModal: {
          ...state.confirmationAssignAsAdminModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL:
      return {
        ...state,
        confirmationAssignAsAdminModal: {
          ...state.confirmationAssignAsAdminModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_DEACTIVATE_USER_MODAL:
      return {
        ...state,
        deactivateUserModal: {
          ...state.deactivateUserModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DEACTIVATE_USER_MODAL:
      return {
        ...state,
        deactivateUserModal: {
          ...state.deactivateUserModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_RESTORE_PERSON_MODAL:
      return {
        ...state,
        restorePersonModal: {
          ...state.restorePersonModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_RESTORE_PERSON_MODAL:
      return {
        ...state,
        restorePersonModal: {
          ...state.restorePersonModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_ORGANIZATION_PROFILE_DETAIL_MODAL:
      return {
        ...state,
        OrganizationProfileDetailModal: {
          ...state.OrganizationProfileDetailModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ORGANIZATION_PROFILE_DETAIL_MODAL:
      return {
        ...state,
        OrganizationProfileDetailModal: {
          ...state.OrganizationProfileDetailModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_CREATE_COMMUNITY_MODAL:
      return {
        ...state,
        createCommunityModal: {
          ...state.createCommunityModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_CREATE_COMMUNITY_MODAL:
      return {
        ...state,
        createCommunityModal: {
          ...state.createCommunityModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_COMMUNITY_MODAL:
      return {
        ...state,
        editCommunityModal: {
          ...state.editCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_EDIT_COMMUNITY_MODAL:
      return {
        ...state,
        editCommunityModal: {
          ...state.editCommunityModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_COMMUNITY_ACCESS_MODAL:
      return {
        ...state,
        communityAccessModal: {
          ...state.communityAccessModal,
          isOpen: true,
          data: action.payload,
          access: action.access,
        },
      };
    case actionTypes.CLOSE_COMMUNITY_ACCESS_MODAL:
      return {
        ...state,
        communityAccessModal: {
          ...state.communityAccessModal,
          isOpen: false,
          data: null,
          access: '',
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL:
      return {
        ...state,
        archiveOrRestoreCommunityModal: {
          ...state.archiveOrRestoreCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL:
      return {
        ...state,
        archiveOrRestoreCommunityModal: {
          ...state.archiveOrRestoreCommunityModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL:
      return {
        ...state,
        archiveOrPublishOrganizationModal: {
          ...state.archiveOrPublishOrganizationModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL:
      return {
        ...state,
        archiveOrPublishOrganizationModal: {
          ...state.archiveOrPublishOrganizationModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_DELETE_A_COMMUNITY_MODAL:
      return {
        ...state,
        deleteACommunityModal: {
          ...state.deleteACommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_A_COMMUNITY_MODAL:
      return {
        ...state,
        deleteACommunityModal: {
          ...state.deleteACommunityModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL:
      return {
        ...state,
        assignAnAdminToCommunityModal: {
          ...state.assignAnAdminToCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL:
      return {
        ...state,
        assignAnAdminToCommunityModal: {
          ...state.assignAnAdminToCommunityModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_MEMBERS_TO_COMMUNITY_MODAL:
      return {
        ...state,
        addMemberstoCommunityModal: {
          ...state.addMemberstoCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_MEMBERS_TO_COMMUNITY_MODAL:
      return {
        ...state,
        addMemberstoCommunityModal: {
          ...state.addMemberstoCommunityModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_CREATE_ORGANIZATION_MODAL:
      return {
        ...state,
        createOrganizationModal: {
          ...state.createOrganizationModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_CREATE_ORGANIZATION_MODAL:
      return {
        ...state,
        createOrganizationModal: {
          ...state.createOrganizationModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ADD_EXISTING_ORGANIZATIONS_MODAL:
      return {
        ...state,
        addExistingOrganizationsModal: {
          ...state.addExistingOrganizationsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EXISTING_ORGANIZATIONS_MODAL:
      return {
        ...state,
        addExistingOrganizationsModal: {
          ...state.addExistingOrganizationsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_EXISTING_COMMUNITY_MODAL:
      return {
        ...state,
        addExistingCommunityModal: {
          ...state.addExistingCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EXISTING_COMMUNITY_MODAL:
      return {
        ...state,
        addExistingCommunityModal: {
          ...state.addExistingCommunityModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_EDIT_TEMPLATE_FORM_MODAL:
      return {
        ...state,
        addEditTemplateFormModal: {
          ...state.addEditTemplateFormModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
          templateMode: action.templateMode,
        },
      };
    case actionTypes.CLOSE_ADD_EDIT_TEMPLATE_FORM_MODAL:
      return {
        ...state,
        addEditTemplateFormModal: {
          ...state.addEditTemplateFormModal,
          isOpen: false,
          data: null,
          editMode: false,
          templateMode: false,
        },
      };

    case actionTypes.OPEN_FORM_DETAILS_MODAL:
      return {
        ...state,
        formDetailsModal: {
          ...state.formDetailsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_FORM_DETAILS_MODAL:
      return {
        ...state,
        formDetailsModal: {
          ...state.formDetailsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_IMPORT_SUMMARY_MODAL:
      return {
        ...state,
        importSummaryModal: {
          ...state.importSummaryModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_IMPORT_SUMMARY_MODAL:
      return {
        ...state,
        importSummaryModal: {
          ...state.importSummaryModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_IMPORT_INFO_MODAL:
      return {
        ...state,
        importInfoModal: {
          ...state.importInfoModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_IMPORT_INFO_MODAL:
      return {
        ...state,
        importInfoModal: {
          ...state.importInfoModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_PERSON_MODAL:
      return {
        ...state,
        addPersonModal: {
          ...state.addPersonModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_ADD_PERSON_MODAL:
      return {
        ...state,
        addPersonModal: {
          ...state.addPersonModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_FORM_MODAL:
      return {
        ...state,
        archiveOrRestoreFormModal: {
          ...state.archiveOrRestoreFormModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_FORM_MODAL:
      return {
        ...state,
        archiveOrRestoreFormModal: {
          ...state.archiveOrRestoreFormModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_DELETE_A_FORM_MODAL:
      return {
        ...state,
        deleteFormModal: {
          ...state.archiveOrRestoreFormModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_A_FORM_MODAL:
      return {
        ...state,
        deleteFormModal: {
          ...state.archiveOrRestoreFormModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_ADD_PROGRAM_MODAL:
      return {
        ...state,
        addProgramModal: {
          ...state.addProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_PROGRAM_MODAL:
      return {
        ...state,
        addProgramModal: {
          ...state.addProgramModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_PROGRAM_MODAL:
      return {
        ...state,
        editProgramModal: {
          ...state.editProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_EDIT_PROGRAM_MODAL:
      return {
        ...state,
        editProgramModal: {
          ...state.editProgramModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_ADD_FILE_MODAL:
      return {
        ...state,
        addFileModal: {
          ...state.addFileModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_FILE_MODAL:
      return {
        ...state,
        addFileModal: {
          ...state.addFileModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_PROGRAM_MODAL:
      return {
        ...state,
        archiveOrRestoreProgramModal: {
          ...state.archiveOrRestoreProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_PROGRAM_MODAL:
      return {
        ...state,
        archiveOrRestoreProgramModal: {
          ...state.archiveOrRestoreProgramModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL:
      return {
        ...state,
        assignAnAdminToProgramModal: {
          ...state.assignAnAdminToProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL:
      return {
        ...state,
        assignAnAdminToProgramModal: {
          ...state.assignAnAdminToProgramModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_COMPLETE_PROGRAM_MODAL:
      return {
        ...state,
        completeProgramModal: {
          ...state.completeProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_COMPLETE_PROGRAM_MODAL:
      return {
        ...state,
        completeProgramModal: {
          ...state.completeProgramModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REMOVE_FROM_PROGRAM_MODAL:
      return {
        ...state,
        removeFromProgramModal: {
          ...state.removeFromProgramModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_REMOVE_FROM_PROGRAM_MODAL:
      return {
        ...state,
        removeFromProgramModal: {
          ...state.removeFromProgramModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL:
      return {
        ...state,
        removeCommunityFromOrganizationModal: {
          ...state.removeCommunityFromOrganizationModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL:
      return {
        ...state,
        removeCommunityFromOrganizationModal: {
          ...state.removeCommunityFromOrganizationModal,
          data: null,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_REMOVE_PROGRAM_MODAL:
      return {
        ...state,
        removeProgramModal: {
          ...state.removeProgramModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_REMOVE_PROGRAM_MODAL:
      return {
        ...state,
        removeProgramModal: {
          ...state.removeProgramModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_MANAGE_PROGRAM_MEMBERS_MODAL:
      return {
        ...state,
        manageProgramMembersModal: {
          ...state.manageProgramMembersModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_MANAGE_PROGRAM_MEMBERS_MODAL:
      return {
        ...state,
        manageProgramMembersModal: {
          ...state.manageProgramMembersModal,
          data: null,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_VIDEO_LIGHTBOX_MODAL:
      return {
        ...state,
        videoLightBoxModal: {
          ...state.videoLightBoxModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_VIDEO_LIGHTBOX_MODAL:
      return {
        ...state,
        videoLightBoxModal: {
          ...state.manageProgramMembersModal,
          data: null,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_IMAGE_LIGHTBOX_MODAL:
      return {
        ...state,
        imageLightBoxModal: {
          ...state.imageLightBoxModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_IMAGE_LIGHTBOX_MODAL:
      return {
        ...state,
        imageLightBoxModal: {
          ...state.manageProgramMembersModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_IMAGE_VIDEO_LIGHTBOX_MODAL:
      return {
        ...state,
        imageVideoLightBoxModal: {
          ...state.imageVideoLightBoxModal,
          data: action.payload,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_IMAGE_VIDEO_LIGHTBOX_MODAL:
      return {
        ...state,
        imageVideoLightBoxModal: {
          ...state.imageVideoLightBoxModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_DELETE_A_FILE_MODAL:
      return {
        ...state,
        deleteFileModal: {
          ...state.deleteFileModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_A_FILE_MODAL:
      return {
        ...state,
        deleteFileModal: {
          ...state.deleteFileModal,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL:
      return {
        ...state,
        addEditDuplicateProtocolModal: {
          ...state.addEditDuplicateProtocolModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
          duplicateMode: action.duplicateMode,
        },
      };
    case actionTypes.CLOSE_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL:
      return {
        ...state,
        addEditDuplicateProtocolModal: {
          ...state.addEditDuplicateProtocolModal,
          isOpen: false,
          data: {
            title: '',
            description: '',
            programId: {
              value: '',
              label: '',
            },
            formId: {
              value: '',
              label: '',
            },

            files: [],
            path: '',
          },
          editMode: false,
          duplicateMode: false,
        },
      };

    case actionTypes.OPEN_MANAGE_CONNECTION_PROTOCOL_MODAL:
      return {
        ...state,
        manageConnectioProtocolModal: {
          ...state.manageConnectioProtocolModal,
          data: action.payload,
          isOpen: true,
        },
      };

    case actionTypes.CLOSE_MANAGE_CONNECTION_PROTOCOL_MODAL:
      return {
        ...state,
        manageConnectioProtocolModal: {
          ...state.manageConnectioProtocolModal,
          data: null,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL:
      return {
        ...state,
        archiveOrRestoreProtocolModal: {
          ...state.archiveOrRestoreProtocolModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL:
      return {
        ...state,
        archiveOrRestoreProtocolModal: {
          ...state.archiveOrRestoreProtocolModal,
          isOpen: false,
        },
      };
    case actionTypes.OPEN_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL:
      return {
        ...state,
        addExistingCommunitiesOrOrganizationsToProgramModal: {
          ...state.addExistingCommunitiesOrOrganizationsToProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL:
      return {
        ...state,
        addExistingCommunitiesOrOrganizationsToProgramModal: {
          ...state.addExistingCommunitiesOrOrganizationsToProgramModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL:
      return {
        ...state,
        createOrEditDataAccessPolicyModal: {
          ...state.createOrEditDataAccessPolicyModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL:
      return {
        ...state,
        createOrEditDataAccessPolicyModal: {
          ...state.createOrEditDataAccessPolicyModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL:
      console.log('action.payload', action.payload);
      return {
        ...state,
        archiveOrRestoreObservationModal: {
          ...state.archiveOrRestoreObservationModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL:
      return {
        ...state,
        archiveOrRestoreObservationModal: {
          ...state.archiveOrRestoreObservationModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REMOVE_MANAGER_MODAL:
      return {
        ...state,
        removeManagerModal: {
          ...state.removeManagerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_REMOVE_MANAGER_MODAL:
      return {
        ...state,
        removeManagerModal: {
          ...state.removeManagerModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_NOTIFICATIONS_CENTER_MODAL:
      return {
        ...state,
        notificationsCenterModal: {
          ...state.notificationsCenterModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_NOTIFICATIONS_CENTER_MODAL:
      return {
        ...state,
        notificationsCenterModal: {
          ...state.notificationsCenterModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL:
      return {
        ...state,
        inviteRegisteredExternalReviewerModal: {
          ...state.inviteRegisteredExternalReviewerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL:
      return {
        ...state,
        inviteRegisteredExternalReviewerModal: {
          ...state.inviteRegisteredExternalReviewerModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_DELETE_MANAGER_MODAL:
      return {
        ...state,
        deleteManagerModal: {
          ...state.deleteManagerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_DELETE_MANAGER_MODAL:
      return {
        ...state,
        deleteManagerModal: {
          ...state.deleteManagerModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL:
      return {
        ...state,
        successfullInviteMessageModal: {
          ...state.successfullInviteMessageModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL:
      return {
        ...state,
        successfullInviteMessageModal: {
          ...state.successfullInviteMessageModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_NOTIFICATIONS_RECIPIENTS_MANAGER_MODAL:
      return {
        ...state,
        removeNotificationRecipientsModal: {
          ...state.removeNotificationRecipientsModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_REMOVE_NOTIFICATIONS_RECIPIENTS_MODAL:
      return {
        ...state,
        removeNotificationRecipientsModal: {
          ...state.removeNotificationRecipientsModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_ADD_MANAGER_MODAL:
      return {
        ...state,
        addManagerModal: {
          ...state.addManagerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_ADD_MANAGER_MODAL:
      return {
        ...state,
        addManagerModal: {
          ...state.addManagerModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_INVITE_DATA_END_VIEWER_MODAL:
      return {
        ...state,
        inviteDataEndViewerModal: {
          ...state.inviteDataEndViewerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_INVITE_DATA_END_VIEWER_MODAL:
      return {
        ...state,
        inviteDataEndViewerModal: {
          ...state.inviteDataEndViewerModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REVIEWER_DETAILS_MODAL:
      return {
        ...state,
        reviewerDetailModal: {
          ...state.reviewerDetailModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_REVIEWER_DETAILS_MODAL:
      return {
        ...state,
        reviewerDetailModal: {
          ...state.reviewerDetailModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL:
      return {
        ...state,
        inviteExternalReviewerQualityAssuranceObservationModal: {
          ...state.inviteExternalReviewerQualityAssuranceObservationModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL:
      return {
        ...state,
        inviteExternalReviewerQualityAssuranceObservationModal: {
          ...state.inviteExternalReviewerQualityAssuranceObservationModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL:
      return {
        ...state,
        archiveOrRestoreQualityAssuranceObservationModal: {
          ...state.archiveOrRestoreQualityAssuranceObservationModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL:
      return {
        ...state,
        archiveOrRestoreQualityAssuranceObservationModal: {
          ...state.archiveOrRestoreQualityAssuranceObservationModal,
          isOpen: false,
          data: null,
        },
      };
    // OPEN/CLOSE DELETE MODAL
    case actionTypes.OPEN_CHAT_DELETE_MODAL:
      return {
        ...state,
        messageActionsModal: {
          ...state.messageActionsModal,
          isDelete: true,
          isArchive: false,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_CHAT_DELETE_MODAL:
      return {
        ...state,
        messageActionsModal: {
          ...state.messageActionsModal,
          data: null,
          isDelete: false,
          isArchive: false,
        },
      };

    // OPEN/CLOSE ARCHIVE MODAL
    case actionTypes.OPEN_CHAT_ARCHIVE_MODAL:
      return {
        ...state,
        messageActionsModal: {
          ...state.messageActionsModal,
          isDelete: false,
          isArchive: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_CHAT_ARCHIVE_MODAL:
      return {
        ...state,
        messageActionsModal: {
          ...state.messageActionsModal,
          data: null,
          isDelete: false,
          isArchive: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_CATEGORY_MODAL:
      return {
        ...state,
        archiveOrRestoreCategoryModal: {
          ...state.archiveOrRestoreCategoryModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_CATEGORY_MODAL:
      return {
        ...state,
        archiveOrRestoreCategoryModal: {
          ...state.archiveOrRestoreCategoryModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_CATEGORY_MODAL:
      return {
        ...state,
        addOrEditCategoryModal: {
          ...state.addOrEditCategoryModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_CATEGORY_MODAL:
      return {
        ...state,
        addOrEditCategoryModal: {
          ...state.addOrEditCategoryModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL:
      return {
        ...state,
        archiveOrRestoreBehaviorsModal: {
          ...state.archiveOrRestoreBehaviorsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL:
      return {
        ...state,
        archiveOrRestoreBehaviorsModal: {
          ...state.archiveOrRestoreBehaviorsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_BEHAVIORS_MODAL:
      return {
        ...state,
        addOrEditBehaviorsModal: {
          ...state.addOrEditBehaviorsModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_BEHAVIORS_MODAL:
      return {
        ...state,
        addOrEditBehaviorsModal: {
          ...state.addOrEditBehaviorsModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL:
      return {
        ...state,
        archiveOrRestoreConditionsModal: {
          ...state.archiveOrRestoreConditionsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL:
      return {
        ...state,
        archiveOrRestoreConditionsModal: {
          ...state.archiveOrRestoreConditionsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_CONDITIONS_MODAL:
      return {
        ...state,
        addOrEditConditionsModal: {
          ...state.addOrEditConditionsModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL:
      return {
        ...state,
        addOrEditConditionsModal: {
          ...state.addOrEditConditionsModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_TYPE_MODAL:
      return {
        ...state,
        archiveOrRestoreTypeModal: {
          ...state.archiveOrRestoreTypeModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_TYPE_MODAL:
      return {
        ...state,
        archiveOrRestoreTypeModal: {
          ...state.archiveOrRestoreTypeModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_TYPE_MODAL:
      return {
        ...state,
        addOrEditTypeModal: {
          ...state.addOrEditTypeModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_TYPE_MODAL:
      return {
        ...state,
        addOrEditTypeModal: {
          ...state.addOrEditTypeModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };


      case actionTypes.OPEN_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL:
        return {
          ...state,
          archiveOrRestoreMarkerStylesModal: {
            ...state.archiveOrRestoreMarkerStylesModal,
            isOpen: true,
            data: action.payload,
          },
        };
      case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL:
        return {
          ...state,
          archiveOrRestoreMarkerStylesModal: {
            ...state.archiveOrRestoreMarkerStylesModal,
            isOpen: false,
            data: null,
          },
        };
  
      case actionTypes.OPEN_ADD_OR_EDIT_MARKER_STYLES_MODAL:
        return {
          ...state,
          addOrEditMarkerStylesModal: {
            ...state.addOrEditMarkerStylesModal,
            isOpen: true,
            data: action.payload,
            editMode: action.editMode,
          },
        };
      case actionTypes.CLOSE_ADD_OR_EDIT_MARKER_STYLES_MODAL:
        return {
          ...state,
          addOrEditMarkerStylesModal: {
            ...state.addOrEditMarkerStylesModal,
            isOpen: false,
            data: null,
            editMode: false,
          },
        };


        case actionTypes.OPEN_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL:
        return {
          ...state,
          archiveOrRestoreMarkerGroupModal: {
            ...state.archiveOrRestoreMarkerGroupModal,
            isOpen: true,
            data: action.payload,
          },
        };
      case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL:
        return {
          ...state,
          archiveOrRestoreMarkerGroupModal: {
            ...state.archiveOrRestoreMarkerGroupModal,
            isOpen: false,
            data: null,
          },
        };
  
      case actionTypes.OPEN_ADD_OR_EDIT_MARKER_GROUPS_MODAL:
        return {
          ...state,
          addOrEditMarkerGroupModal: {
            ...state.addOrEditMarkerGroupModal,
            isOpen: true,
            data: action.payload,
            editMode: action.editMode,
          },
        };
      case actionTypes.CLOSE_ADD_OR_EDIT_MARKER_GROUPS_MODAL:
        return {
          ...state,
          addOrEditMarkerGroupModal: {
            ...state.addOrEditMarkerGroupModal,
            isOpen: false,
            data: null,
            editMode: false,
          },
        };

    case actionTypes.OPEN_ADD_OR_EDIT_CATEGORY_MODAL:
      return {
        ...state,
        addOrEditCategoryModal: {
          ...state.addOrEditCategoryModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_CATEGORY_MODAL:
      return {
        ...state,
        addOrEditCategoryModal: {
          ...state.addOrEditCategoryModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_SPECIE_MODAL:
      return {
        ...state,
        addOrEditSpecieModal: {
          ...state.addOrEditSpecieModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_SPECIE_MODAL:
      return {
        ...state,
        addOrEditSpecieModal: {
          ...state.addOrEditSpecieModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_SPECIE_MODAL:
      return {
        ...state,
        archiveOrRestoreSpecieModal: {
          ...state.archiveOrRestoreSpecieModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_SPECIE_MODAL:
      return {
        ...state,
        archiveOrRestoreSpecieModal: {
          ...state.archiveOrRestoreSpecieModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_RE_USE_SPECIES_LIST_MODAL:
      return {
        ...state,
        reUseSpeciesListModal: {
          ...state.reUseSpeciesListModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_RE_USE_SPECIES_LIST_MODAL:
      return {
        ...state,
        reUseSpeciesListModal: {
          ...state.reUseSpeciesListModal,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_OBJECT_MODAL:
      return {
        ...state,
        addOrEditObjectModal: {
          ...state.addOrEditObjectModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_OBJECT_MODAL:
      return {
        ...state,
        addOrEditObjectModal: {
          ...state.addOrEditObjectModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_OBJECT_MODAL:
      return {
        ...state,
        archiveOrRestoreObjectModal: {
          ...state.archiveOrRestoreObjectModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_OBJECT_MODAL:
      return {
        ...state,
        archiveOrRestoreObjectModal: {
          ...state.archiveOrRestoreObjectModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_RE_USE_OBJECTS_LIST_MODAL:
      return {
        ...state,
        reUseObjectsListModal: {
          ...state.reUseObjectsListModal,
          isOpen: true,
        },
      };
    case actionTypes.CLOSE_RE_USE_OBJECTS_LIST_MODAL:
      return {
        ...state,
        reUseObjectsListModal: {
          ...state.reUseObjectsListModal,
          isOpen: false,
        },
      };

    case actionTypes.OPEN_DELETE_A_TAG_MODAL:
      return {
        ...state,
        deleteTagModal: {
          ...state.deleteTagModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_A_TAG_MODAL:
      return {
        ...state,
        deleteTagModal: {
          ...state.deleteTagModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_OR_EDIT_TAG_MODAL:
      return {
        ...state,
        addOrEditTagModal: {
          ...state.addOrEditTagModal,
          isOpen: true,
          data: action.payload,
          editMode: action.editMode,
        },
      };
    case actionTypes.CLOSE_ADD_OR_EDIT_TAG_MODAL:
      return {
        ...state,
        addOrEditTagModal: {
          ...state.addOrEditTagModal,
          isOpen: false,
          data: null,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL:
      return {
        ...state,
        addExistingSpeciesOrObjectsToCommunityModal: {
          ...state.addExistingSpeciesOrObjectsToCommunityModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL:
      return {
        ...state,
        addExistingSpeciesOrObjectsToCommunityModal: {
          ...state.addExistingSpeciesOrObjectsToCommunityModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_NOTIFICATIONS_SUMMARY_MODAL:
      return {
        ...state,
        openNotificationsSummaryModal: {
          ...state.openNotificationsSummaryModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_NOTIFICATIONS_SUMMARY_MODAL:
      return {
        ...state,
        openNotificationsSummaryModal: {
          ...state.openNotificationsSummaryModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REPORTS_SUMMARY_MODAL:
      return {
        ...state,
        openReportsSummaryModal: {
          ...state.openReportsSummaryModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_REPORTS_SUMMARY_MODAL:
      return {
        ...state,
        openReportsSummaryModal: {
          ...state.openReportsSummaryModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_REPORTS_MAP_MODAL:
      return {
        ...state,
        openReportsMapModal: {
          ...state.openReportsMapModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_REPORTS_MAP_MODAL:
      return {
        ...state,
        openReportsMapModal: {
          ...state.openReportsMapModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL:
      return {
        ...state,
        pauseResumeDeleteNotificationModal: {
          ...state.pauseResumeDeleteNotificationModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL:
      return {
        ...state,
        pauseResumeDeleteNotificationModal: {
          ...state.pauseResumeDeleteNotificationModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL:
      return {
        ...state,
        archiveOrRestoreGeographyLayerModal: {
          ...state.archiveOrRestoreGeographyLayerModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL:
      return {
        ...state,
        archiveOrRestoreGeographyLayerModal: {
          ...state.archiveOrRestoreGeographyLayerModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ARCHIVE_RESTORE_DELETE_REPORT_MODAL:
      return {
        ...state,
        archiveRestoreDeleteReportModal: {
          ...state.archiveRestoreDeleteReportModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_RESTORE_DELETE_REPORT_MODAL:
      return {
        ...state,
        archiveRestoreDeleteReportModal: {
          ...state.archiveRestoreDeleteReportModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_COMMUNITY_TAGS_MODAL:
      return {
        ...state,
        editCommunityTagsModal: {
          ...state.editCommunityTagsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_EDIT_COMMUNITY_TAGS_MODAL:
      return {
        ...state,
        editCommunityTagsModal: {
          ...state.editCommunityTagsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_ORGANIZATION_TAGS_MODAL:
      return {
        ...state,
        editOrganizationTagsModal: {
          ...state.editOrganizationTagsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_EDIT_ORGANIZATION_TAGS_MODAL:
      return {
        ...state,
        editOrganizationTagsModal: {
          ...state.editOrganizationTagsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_PROGRAM_TAGS_MODAL:
      return {
        ...state,
        editProgramTagsModal: {
          ...state.editProgramTagsModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_EDIT_PROGRAM_TAGS_MODAL:
      return {
        ...state,
        editProgramTagsModal: {
          ...state.editProgramTagsModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_PROCESSED_REPORT_MODAL:
      return {
        ...state,
        processedReportModal: {
          ...state.processedReportModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_PROCESSED_REPORT_MODAL:
      return {
        ...state,
        processedReportModal: {
          ...state.processedReportModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_EDIT_DUPLICATE_ROLE_MODAL:
      return {
        ...state,
        addEditDuplicateRoleModal: {
          ...state.addEditDuplicateRoleModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EDIT_DUPLICATE_ROLE_MODAL:
      return {
        ...state,
        addEditDuplicateRoleModal: {
          ...state.addEditDuplicateRoleModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EDIT_PERMISSIONS_MODAL:
      return {
        ...state,
        editPermissionModal: {
          ...state.editPermissionModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_EDIT_PERMISSIONS_MODAL:
      return {
        ...state,
        editPermissionModal: {
          ...state.editPermissionModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_DELETE_ROLE_MODAL:
      return {
        ...state,
        deleteRoleModal: {
          ...state.deleteRoleModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_ROLE_MODAL:
      return {
        ...state,
        deleteRoleModal: {
          ...state.deleteRoleModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_SUCCESSFUL_MESSAGE_MODAL:
      return {
        ...state,
        successMessageModal: {
          ...state.successMessageModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_SUCCESSFUL_MESSAGE_MODAL:
      return {
        ...state,
        successMessageModal: {
          ...state.successMessageModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_IMPORT_STATUS_DETAIL_MODAL:
      return {
        ...state,
        importStatusDetailModal: {
          ...state.importStatusDetailModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_IMPORT_STATUS_DETAIL_MODAL:
      return {
        ...state,
        importStatusDetailModal: {
          ...state.importStatusDetailModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_DOWNLOAD_OPTIONS_IMPORT_MODAL:
      return {
        ...state,
        downloadOptionsImportModal: {
          ...state.downloadOptionsImportModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DOWNLOAD_OPTIONS_IMPORT_MODAL:
      return {
        ...state,
        downloadOptionsImportModal: {
          ...state.downloadOptionsImportModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_SURVEY_FORM_MODAL:
      const { editMode } = action;
      return {
        ...state,
        addOrEditSurveyFormModal: {
          isOpen: true,
          data: action.payload,
          editMode: editMode,
        },
      };

    case actionTypes.CLOSE_ADD_SURVEY_FORM_MODAL:
      return {
        ...state,
        addOrEditSurveyFormModal: {
          isOpen: false,
          data: action.payload,
          editMode: false,
        },
      };

    case actionTypes.OPEN_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL:
      return {
        ...state,
        archiveOrRestorePollsAndSurveyModal: {
          ...state.archiveOrRestorePollsAndSurveyModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL:
      return {
        ...state,
        archiveOrRestorePollsAndSurveyModal: {
          ...state.archiveOrRestorePollsAndSurveyModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_DELETE_POLLS_AND_SURVEY_MODAL:
      return {
        ...state,
        deletePollsAndSurveyModal: {
          ...state.deletePollsAndSurveyModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DELETE_POLLS_AND_SURVEY_MODAL:
      return {
        ...state,
        deletePollsAndSurveyModal: {
          ...state.archiveRestoreDeleteReportModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_CONFIGURATION_DASHBOARD_MODAL:
      return {
        ...state,
        configurationDashboardModal: {
          ...state.configurationDashboardModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_CONFIGURATION_DASHBOARD_MODAL:
      return {
        ...state,
        configurationDashboardModal: {
          ...state.configurationDashboardModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL:
      return {
        ...state,
        downladPollsAndSurveysResultByVersionModal: {
          ...state.downladPollsAndSurveysResultByVersionModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL:
      return {
        ...state,
        downladPollsAndSurveysResultByVersionModal: {
          ...state.downladPollsAndSurveysResultByVersionModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_CONTACT_INFO_MODAL:
      const { data, status } = action.payload;
      return {
        ...state,
        contactInfoModal: {
          ...state.contactInfoModal,
          isOpen: true,
          data: data,
          status: status,
        },
      };
    case actionTypes.CLOSE_CONTACT_INFO_MODAL:
      return {
        ...state,
        contactInfoModal: {
          ...state.contactInfoModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ADD_EDIT_CONTACT_INFO_MODAL:
      return {
        ...state,
        addEditContactInfoModal: {
          ...state.addEditContactInfoModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ADD_EDIT_CONTACT_INFO_MODAL:
      return {
        ...state,
        addEditContactInfoModal: {
          ...state.addEditContactInfoModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_NOTIFY_ABOUT_NEW_VERSION_MODAL:
      return {
        ...state,
        notifyAboutNewVersionModal: {
          ...state.notifyAboutNewVersionModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_NOTIFY_ABOUT_NEW_VERSION_MODAL:
      return {
        ...state,
        notifyAboutNewVersionModal: {
          ...state.notifyAboutNewVersionModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_MAP_SETTINGS_PROGRAM_MODAL:
      return {
        ...state,
        mapSettingsProgramProfileModal: {
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_MAP_SETTINGS_PROGRAM_MODAL:
      return {
        ...state,
        mapSettingsProgramProfileModal: {
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ACTIVE_SEASONS_HISTORY_MODAL:
      return {
        ...state,
        activeSeasonsHistoryModal: {
          ...state.activeSeasonsHistoryModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_ACTIVE_SEASONS_HISTORY_MODAL:
      return {
        ...state,
        activeSeasonsHistoryModal: {
          ...state.activeSeasonsHistoryModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_REPORT_VERSION_DATA_MODAL:
      return {
        ...state,
        reportVersionDataModal: {
          ...state.reportVersionDataModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_REPORT_VERSION_DATA_MODAL:
      return {
        ...state,
        reportVersionDataModal: {
          ...state.reportVersionDataModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL:
      return {
        ...state,
        pollsAndSurveysExpiryDateModal: {
          ...state.pollsAndSurveysExpiryDateModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL:
      return {
        ...state,
        pollsAndSurveysExpiryDateModal: {
          ...state.pollsAndSurveysExpiryDateModal,
          isOpen: false,
          data: null,
        },
      };
    case actionTypes.OPEN_PAUSE_RESUME_PROGRAM_MODAL:
      return {
        ...state,
        pauseResumeProgramModal: {
          ...state.pauseResumeProgramModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_PAUSE_RESUME_PROGRAM_MODAL:
      return {
        ...state,
        pauseResumeProgramModal: {
          ...state.pauseResumeProgramModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_UNDO_AND_ARCHIVE_IMPORT_MODAL:
      return {
        ...state,
        undoAndArchiveImportModal: {
          ...state.undoAndArchiveImportModal,
          isOpen: true,
          data: action.payload,
        },
      };
    case actionTypes.CLOSE_UNDO_AND_ARCHIVE_IMPORT_MODAL:
      return {
        ...state,
        undoAndArchiveImportModal: {
          ...state.undoAndArchiveImportModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_EXIF_VIEWER_MODAL:
      return {
        ...state,
        exifViewerModal: {
          ...state.exifViewerModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_EXIF_VIEWER_MODAL:
      return {
        ...state,
        exifViewerModal: {
          ...state.exifViewerModal,
          isOpen: false,
          data: null,
        },
      };

    case actionTypes.OPEN_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION:
      return {
        ...state,
        assignProgramAdminPermissionToImportModal: {
          ...state.assignProgramAdminPermissionToImportModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION:
      return {
        ...state,
        assignProgramAdminPermissionToImportModal: {
          ...state.assignProgramAdminPermissionToImportModal,
          isOpen: false,
          data: null,
        },
      };


      case actionTypes.OPEN_MANAGE_LAYERS_PROPERTIES_MODAL:
      return {
        ...state,
        manageLayersPropertiesModal: {
          ...state.manageLayersPropertiesModal,
          isOpen: true,
          data: action.payload,
        },
      };

    case actionTypes.CLOSE_MANAGE_LAYERS_PROPERTIES_MODAL:
      return {
        ...state,
        manageLayersPropertiesModal: {
          ...state.manageLayersPropertiesModal,
          isOpen: false,
          data: null,
        },
      };

    default:
      return state;
  }
};
