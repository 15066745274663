import { apiClient } from "../config";

async function editNotification
(id: any, data: any) {
  const url = `/admin/reminders/${id}`;
  const res = await apiClient.put(url, data);
  return res.data;
}

export default editNotification
;
