import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import { GoToNextBtnProps } from "../../types";

export const GoToNextBtn = ({
  page,
  totalPages,
  handleClickOnBtn,
}: GoToNextBtnProps) => {
  return (
    <button
      onClick={handleClickOnBtn}
      title="Next"
      className={`hover:bg-primaryLight relative flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed flex-grow-0 flex-shrink-0 w-8 gap-1 px-3 py-[2px] border-2 rounded-lg  border-primary dark:bg-secondaryLight dark:border-lineLight`}
      disabled={page === totalPages}
    >
      <CheveronRightIcon fill="#005C89" />
    </button>
  );
};
