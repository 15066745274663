import React, { useEffect, useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import ReportsColumnModal from "view/components/Modals/Reports/ReportsColumnModal";
import { IfetchObservations } from "store/observations/initialState";
import { IfetchReports } from "store/reports/initialState";
import useRoles from "hooks/roles";
import { isAnyReportsFilterApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { reportsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { data } = useSelector<RootState, IfetchReports>(
    (state) => state?.allReports
  );
  const { isExternalDataEndReviewer } = useRoles();
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

 

  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyReportsFilterApplied(filtersData.filters);
      } else {
        return isAnyReportsFilterApplied(reportsFilter);
      }
    });
  }, []);
  
  // Initializing event listener
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        {!isExternalDataEndReviewer() && <FiltersList isCloseDiv={isCloseDiv} />}
      </div>
      {isOpen && (
        <ReportsColumnModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columns={columns.filter((item: any) => item.id !== "select")}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
      )}
    </>
  );
};
