import apiClient from "services/apiClient";

async function addAdminsToOrganization(
  organizationId: any,
  personIds: any,
  personId?: any
) {
  const url = `/admin/organizations/${organizationId}/admins`;
  const data = {
    person_ids: personIds,
  };
  const personData = {
    person_id: personIds.admin_id,
  };

  const res = await apiClient.post(url, personId ? personData : data);

  return res.data;
}

export default addAdminsToOrganization;
