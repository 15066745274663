export default {
  audio: "audio",
  text: "text",
  radio: "radiogroup",
  checkbox: "checkbox",
  dropdown: "dropdown",
  tagbox: "tagbox",
  boolean: "boolean",
  file: "file",
  imagepicker: "imagepicker",
  comment: "comment",
  panel: "panel",
  paneldynamic: "paneldynamic",
  geocoder: "geocoder",
  // map:"map",
  shapePicker: "shape_picker",
  getGpsData: "get_gps_data",
  pointPicker: "pointpicker",
  drawPolygon: "drawpolygon",
  dropPin: "droppin",
  page: "page",
  survey: "survey",
};
