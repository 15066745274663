export interface IProfileRoles {
  data: {
    communityRoles: [
      {
        community: string;
        id: string;
        role: string;
        type: string;
      },
    ];
    organizationRoles: [
      {
        id: string;
        organization: string;
        role: string;
        type: string;
      },
    ];
    programsRoles: [
      {
        id: string;
        program: string;
        role: string;
        type: string;
      },
    ];
  };
  message: string;
}

export const initialState = {
  data: {
    communityRoles: [
      {
        community: "",
        id: 0,
        role: "",
        type: "",
      },
    ],
    organizationRoles: [
      {
        id: 0,
        organization: "",
        role: "",
        type: "",
      },
    ],
    programsRoles: [
      {
        id: 0,
        program: "",
        role: "",
        type: "",
      },
    ],
  },
  message: "",
};


export interface IProfileRoleAdditionalInfo {
  code: string | null;
  permitNumber: string | null;
  type: string;
  personId: number;
  id: number;
  isChecked: boolean;
}

export interface IProfileRoleAdditionalInfoData {
  data: IProfileRoleAdditionalInfo[];
  message: string;
}

export const profileRoleAdditionalInfoIntitialState: IProfileRoleAdditionalInfoData =
  {
    data: [],
    message: "",
  };
