import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import { getBrandPlaceholder } from "utils/customBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();

  if (cellValue !== null && columnDef.id === "name") {
    return (
      <div className='flex justify-start items-center relative w-[230px] group'>
          <img
            src={
              cellValue?.image
                ? cellValue?.image
                : getBrandPlaceholder('listingProfile')
            }
            alt=''
            height='100'
            width='100'
            className='object-cover mx-2 Img_user_Data'
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: 'wrap' }}
            className='flex flex-col items-start justify-start w-full '
          >
            <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark py-2'>
              {`${cellValue?.name}`}
            </p>
          </div>
        </div>
    );
  } else if (cellValue !== null && ["community", "group"]) {
    return (
      <div
        style={{ flexWrap: "wrap" }}
        className=" flex flex-col w-full justify-start items-start relative gap-0.5"
      >
        <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
          {`${cellValue?.name}`}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 40)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
