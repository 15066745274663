import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgressWithLabelAndCount from "../LinearProgressWithLabel";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { IMostObservedProps, MostObservedModuleName } from "./types";
import { filtersInitialState } from "store/filters/initialState";
import MostObservedNoData from "../imgs/MostObservedNoData";
import { TailSpin } from "react-loader-spinner";

const MostObservedObjects = (props: IMostObservedProps) => {
  const title = `Most Observed ${
    props.moduleName === MostObservedModuleName.objects ? "Objects" : "Species"
  }`;

  const widgetId =  props.moduleName === MostObservedModuleName.objects ? "most_observed_objects" : "most_observed_species"

  const {
    dashboard,
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    fetchData();
  }, [dashboardFilter]);

  const fetchData = () => {
    dispatch(
      props.moduleName === MostObservedModuleName.objects
        ? dashboardActions.fetchMostObservedObjects()
        : dashboardActions.fetchMostObservedSpecies()
    );
  };
  const mostObservedData =
    props.moduleName === MostObservedModuleName.objects
      ? dashboard.mostObservedObjects
      : dashboard.mostObservedSpecies;

  // console.log(props.moduleName, "mostObservedObjects:", mostObservedData);
  return (
    <Box height={"100%"}>
      <DashboardCardHeading text={title} widgetId={widgetId}/>

      {mostObservedData.stateIs == "Idle" &&
      mostObservedData.data.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <MostObservedNoData />
        </div>
      ) : mostObservedData.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        mostObservedData.data.map((item, index) => {
          return (
            <Box
              sx={{ flexGrow: 1, mb: 1, "&:not(:last-child)": { mb: "20px" } }}
              key={index}
            >
              <LinearProgressWithLabelAndCount
                label={item.name}
                value={item.count}
                barColor={props.barColor}
                barBgColor={
                  props.moduleName === MostObservedModuleName.species
                    ? "#BDDFDA"
                    : ""
                }
              />
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default MostObservedObjects;
