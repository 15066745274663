import { RoutesConstants } from "constants/RoutesConstants";
import useRoles from "hooks/roles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, useNavigate } from "react-router-dom";
import Auth from "services/api/auth";
import { RootState } from "store";
import Cookies from "universal-cookie";
import AcessDenied from "view/pages/AcessDenied";
import { SignIn } from "view/pages/Auth";

// AuthRoutes Component
interface AuthRoutesProps {
  component: React.ComponentType;
}

export const AuthRoutes: React.FC<AuthRoutesProps> = ({
  component: Component,
}) => {
  const cookies = new Cookies();
  const getAuthToken = () => cookies.get("token");
  const auth = useSelector((state: RootState) => state.auth);
  const isExternalReviewer = auth.roles.includes("external_reviewer");

  if (!getAuthToken()) {
    return <Component />;
  } else if (isExternalReviewer) {
    return (
      <Navigate
        to={
          auth.autoGeneratePass
            ? "/auth/createPassword"
            : `/quality-assurance/list`
        }
      />
    );
  } else {
    return (
      <Navigate
        to={
          auth.autoGeneratePass
            ? "/auth/createPassword"
            : RoutesConstants.default
        }
      />
    );
  }
};

interface ProtectedRoutesProps {
  component: React.ComponentType;
  permissions?: boolean;
  fallbackPath?: any;
}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
  component: Component,
  permissions = true,
  fallbackPath = "/",
}) => {
  const cookies = new Cookies();
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const isAuthenticated = () => !!cookies.get("token");

  useEffect(() => {
    if (!isAuthenticated()) return
    // if (typeof fallbackPath === 'number' && fallbackPath === -1 && !permissions) {
    //   navigate(-1);
    // }
  }, [fallbackPath, navigate]);

  if (isAuthenticated()) {
    if (auth.autoGeneratePass) {
      return <Navigate to="/auth/createPassword" />;
    } else if (permissions) {
      return <Component />;
    } else {
      if (typeof fallbackPath === 'string') {
        return <Navigate to={fallbackPath} />;
      }
      else if (typeof fallbackPath === 'number' && fallbackPath === -1 && !permissions) {
        return <AcessDenied />
      }
    }
  } else {

    console.log("No fallback")
    // Navigate to sign-in page if not authenticated
    return <Navigate to="/auth/signin" />;
  }
};

interface SpeacialProtectedRoutesProps {
  component: React.ComponentType;
}

export const SpeacialProtectedRoutes: React.FC<
  SpeacialProtectedRoutesProps
> = ({ component: Component }) => {
  const cookies = new Cookies();
  const auth = useSelector((state: RootState) => state.auth);
  const getAuthToken = () => cookies.get("token");
  const { isExternalReviewer } = useRoles();

  if (getAuthToken() && auth.autoGeneratePass) {
    // Call the function here
    return <Component />;
  } else if (isExternalReviewer()) {
    return (
      <Navigate
        to={
          getAuthToken() ? `dashboard/quality-assurance/list` : `/auth/signin`
        }
      />
    );
  } else {
    return (
      <Navigate
        to={getAuthToken() ? RoutesConstants.default : `/auth/signin`}
      />
    );
    // return <SignIn />;
  }
};
