import { apiClient } from "../config";

async function adminObservationSectionReview(observationId: number, body: any) {
  const res = await apiClient.put(
    `/admin/qa/review-section/${observationId}`,
    body
  );
  return res.data;
}

export default adminObservationSectionReview;
