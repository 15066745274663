import { ClearFilters } from "Components/Dashboard/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { dashboardProgramsAction } from "store/filters";
import { filtersInitialState } from "store/filters/initialState";

export const SelectedPrograms: React.FC = () => {
  const dispatch = useDispatch();
  const { dashboardFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { programId } = useParams<{
    programId: string;
  }>();

  const resetFilter = (program: any) => {
    dispatch(dashboardProgramsAction(program));
  };

  if (dashboardFilter.programIds?.applied) {
    return dashboardFilter.programIds.value.map((item: any, index: number) => (
     <div
        key={index}
        className=" flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="break-all w-full flex-shrink-0 text-sm text-start text-textMid dark:text-inputText capitalize">
            {item.name}
          </p>
        </div>
        {!programId && <ClearFilters handleClick={() => resetFilter(item)} />}
      </div>
    ));
  } else {
    return null;
  }
};
