import {
  SAVE_ALL_LAYERS_FETCHED_DATA,
  SHOW_ADD_RASTER_LAYER_SCREEN,
  SHOW_ADD_REGION_LAYER_SCREEN,
  SHOW_ADD_SHAPES_SCREEN,
  SHOW_ADD_VECTOR_LAYER_SCREEN,
  SHOW_ALL_LAYERS_LISTING_SCREEN,
  SHOW_EDIT_RASTER_LAYER_SCREEN,
  SHOW_EDIT_REGION_LAYER_SCREEN,
  SHOW_EDIT_VECTOR_LAYER_SCREEN,
  SHOW_VIEW_RASTER_LAYER_SCREEN,
  SHOW_VIEW_VECTOR_LAYER_SCREEN,
  SHOW_EDIT_SHAPE_LAYER_SCREEN,
  DISPLAY_RASTER_LAYER_ON_MAP,
  DISPLAY_DEFAULT_MAP,
  DISPLAY_REGION_LAYER_ON_MAP,
  UPDATE_SELECTED_SHAPE,
  SHOW_VIEW_POINT_LAYER_SCREEN,
  SHOW_EDIT_POINT_LAYER_SCREEN,
  SHOW_VIEW_REGION_LAYER_SCREEN,
  SHOW_VIEW_SHAPE_LAYER_SCREEN,
  SHOW_ADD_POINTS_LAYER_SCREEN,
  UPDATE_DRAW_SHAPES_COORDINATES,
  DISPLAY_SHAPE_LAYER_ON_MAP,
  DISPLAY_POINT_LAYER_ON_MAP,
  UPDATE_SELECTED_LAYERS_ITEMS_DATA,
  UPDATE_ZOOM_TO_SELECTION_ITEM,
  DO_RESET_MAP,
  REMOVE_ALL_DRAW_SHAPES,
  DO_FETCH_ALL_LAYERS_DATA,
  SHOW_LOADER_LAYER,
  LAYERS_DATA_TYPE,
  DISPLAY_POINT_LAYER_BY_PICK_ON_MAP,
  SHOW_SNAPING_CONTROL,
  SET_SELECTED_LAYER_TO_REGION_SELECT_FORM,
  SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM,
  SET_SELECTED_POINT_TO_POINT_SELECT_FORM,
  CLEAR_SELECTED_LAYERS_ITEMS_DATA,
  REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN,
  COMMUNITY_CENTER_POINT
} from "./action-types";

export const openAddRasterLayerScreen = () => {
  return {
    type: SHOW_ADD_RASTER_LAYER_SCREEN,
  };
};

export const openAddVectorLayerScreen = () => {
  return {
    type: SHOW_ADD_VECTOR_LAYER_SCREEN,
  };
};

export const openAddRegionLayerScreen = () => {
  return {
    type: SHOW_ADD_REGION_LAYER_SCREEN,
  };
};

export const openAllLayersListingScreen = () => {
  return {
    type: SHOW_ALL_LAYERS_LISTING_SCREEN,
  };
};

export const openEditRasterLayerScreen = (payload: any) => {
  return {
    type: SHOW_EDIT_RASTER_LAYER_SCREEN,
    payload,
  };
};

export const openViewRasterLayerScreen = (payload: any) => {
  return {
    type: SHOW_VIEW_RASTER_LAYER_SCREEN,
    payload,
  };
};

export const openViewVectorLayerScreen = (payload: any) => {
  return {
    type: SHOW_VIEW_VECTOR_LAYER_SCREEN,
    payload,
  };
};

export const openViewRegionLayerScreen = (payload: any) => {
  return {
    type: SHOW_VIEW_REGION_LAYER_SCREEN,
    payload,
  };
};

export const openViewShapeLayerScreen = (payload: any) => {
  return {
    type: SHOW_VIEW_SHAPE_LAYER_SCREEN,
    payload,
  };
};

export const saveAllLayersFetchedData = (payload: any) => {
  return {
    type: SAVE_ALL_LAYERS_FETCHED_DATA,
    payload,
  };
};

export const openAddShapeScreen = () => {
  return {
    type: SHOW_ADD_SHAPES_SCREEN,
  };
};
export const openEditVectorLayerScreen = (payload: any) => {
  return {
    type: SHOW_EDIT_VECTOR_LAYER_SCREEN,
    payload,
  };
};

export const openEditRegionLayerScreen = (payload: any) => {
  return {
    type: SHOW_EDIT_REGION_LAYER_SCREEN,
    payload,
  };
};

export const openEditShapeLayerScreen = (payload: any) => {
  return {
    type: SHOW_EDIT_SHAPE_LAYER_SCREEN,
    payload,
  };
};

export const displayRasterLayerOnMap = (payload: any) => {
  return {
    type: DISPLAY_RASTER_LAYER_ON_MAP,
    payload: { ...payload, type: "raster" },
  };
};

export const displayDefaultMap = () => {
  return {
    type: DISPLAY_DEFAULT_MAP,
  };
};

export const displayRegionLayerOnMap = (payload: any) => {
  return {
    type: DISPLAY_REGION_LAYER_ON_MAP,
    payload: { ...payload, type: "region" },
  };
};

export const displayShapeLayerOnMap = (payload: any) => {
  return {
    type: DISPLAY_SHAPE_LAYER_ON_MAP,
    payload: { ...payload, type: "shape" },
  };
};

export const displayPointLayerOnMap = (payload: any) => {
  return {
    type: DISPLAY_POINT_LAYER_ON_MAP,
    payload: { ...payload, type: "point" },
  };
};

export const displayPickPointOnMap = (payload: any) => {
  return {
    type: DISPLAY_POINT_LAYER_BY_PICK_ON_MAP,
    payload: { ...payload, type: "pick_on_map" },
  };
};

export const updateSelectedShape = (payload: any) => {
  return {
    type: UPDATE_SELECTED_SHAPE,
    payload,
  };
};
export const openAddPointsLayerScreen = () => {
  return {
    type: SHOW_ADD_POINTS_LAYER_SCREEN,
  };
};

export const openViewPointLayerScreen = (payload: any) => {
  return {
    type: SHOW_VIEW_POINT_LAYER_SCREEN,
    payload,
  };
};

export const openEditPointLayerScreen = (payload: any) => {
  return {
    type: SHOW_EDIT_POINT_LAYER_SCREEN,
    payload,
  };
};

export const updateDrawShapeCoordinates = (payload: any) => {
  return {
    type: UPDATE_DRAW_SHAPES_COORDINATES,
    payload,
  };
};

export const updateSelectedLayersItems = (payload: any) => {
  return {
    type: UPDATE_SELECTED_LAYERS_ITEMS_DATA,
    payload,
  };
};

export const clearSelectedLayersItems = () => {
  return {
    type: CLEAR_SELECTED_LAYERS_ITEMS_DATA,
  };
};

export const updateSelectedItemToZoom = (payload: any) => {
  return {
    type: UPDATE_ZOOM_TO_SELECTION_ITEM,
    payload,
  };
};

export const resetMap = (payload: boolean) => {
  return {
    type: DO_RESET_MAP,
    payload,
  };
};

export const removeAllDrawShapes = (payload: any) => {
  return {
    type: REMOVE_ALL_DRAW_SHAPES,
    payload,
  };
};

export const doFetchAllLayers = (payload: any) => {
  return {
    type: DO_FETCH_ALL_LAYERS_DATA,
    payload,
  };
};

export const updateShowLoaderLayer = (payload: any) => {
  return {
    type: SHOW_LOADER_LAYER,
    payload,
  };
};

export const updateLayersDataType = (payload: "archive" | "active") => {
  return {
    type: LAYERS_DATA_TYPE,
    payload,
  };
};

export const showSnapingControl = (payload: boolean) => {
  return {
    type: SHOW_SNAPING_CONTROL,
    payload,
  };
};

export const setSelectedRegionLayerToRegionSelectForm = (payload: any) => {
  return {
    type: SET_SELECTED_LAYER_TO_REGION_SELECT_FORM,
    payload,
  };
};
export const setSelectedShapeLayerToShapeSelectForm = (payload: any) => {
  return {
    type: SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM,
    payload,
  };
};
export const setSelectedPointLayerToPointSelectForm = (payload: any) => {
  return {
    type: SET_SELECTED_POINT_TO_POINT_SELECT_FORM,
    payload,
  };
};

export const removeMarkerOfPickOnMapScreen = (payload:boolean)=>{
  return{
    type: REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN,
    payload
  }
}


export const getCommunityCenterpointAction = (payload:any)=>{
  return{
    type: COMMUNITY_CENTER_POINT,
    payload
  }
}