/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Radio } from "@mui/material";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeConfirmationAssignAsAdminAction,
  closeResetPasswordModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { Form, Formik, FormikHelpers } from "formik";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import CustomCheckbox from "view/components/CheckBox";
import Button from "view/components/Button";
import FormInput from "view/pages/Auth/Components/Inputs/formInput";
// import { resetPasswordValidationSchema } from "utils/validationSchemas";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { fetchUsersAction } from "store/users";
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  backgroundColor: "#ffff",
};
interface FormValues {
  password: string;
  forceToUpdatePassword: boolean;
}

export const ConfirmationAssignAsAdminModal = () => {
  const { confirmationAssignAsAdminModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { assignRoles } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const { communityId, programId, organizationId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const handleClose = () => {
    dispatch(closeConfirmationAssignAsAdminAction());
  };

  const handleSubmit = async () => {
    if (!assignRoles.canEditAssignRoles) {
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      setIsLoading(true);
      //   Call the API to assign "admin" role to the user
      const res = await apiLibrary.userProfile.assignRoles({
        role_id: "admin",
        user_id: confirmationAssignAsAdminModal.data.userId,
      });
      dispatch(fetchUsersAction(communityId, programId, organizationId));
      Toasts.success(res.message);
      handleClose();
      // If you need to do something after assigning the role, add the code here
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      console.error("Error occurred while assigning admin role:", error);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  return (
    <Modal
      open={confirmationAssignAsAdminModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[500px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex justify-between w-full items-center">
              <p className="capitalize font-semibold text-xl text-secondaryMid">
                Assign an admin
              </p>
              <button onClick={handleClose}>
                <XCloseIcon />
              </button>
            </div>
            <div>
              <p className="text-sm text-secondaryMid font-normal leading-5">
                Are you sure you want to assign this user the role of system
                admin? By doing so, they will get create, read, update and
                delete (CRUD) access to all programs, communities, organizations
                and their data (observations, members, etc.).
              </p>
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 h-11"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={isLoading || !assignRoles.canEditAssignRoles}
                  onClick={handleSubmit}
                  text="Confirm"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 h-11"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
