import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddMarkerGroupBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditMarkerGroupModalAction } from "store/modals";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/marker-groups`, label: `Marker Groups` }];
  }
};
export const Header = () => {
  const { markerGroupsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch = useDispatch();
  const dynamicTypeLinks = generateDynamicBreadcrumbLinks(markerGroupsFilter.status);
  const { markerGroups } = usePermissions()
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicTypeLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {markerGroups.canCreate && <AddMarkerGroupBtn
                handleAddMarkerGroupModel={() =>
                  dispatch(openAddOrEditMarkerGroupModalAction())
                }
                label={"Add Marker Group"}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
