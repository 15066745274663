import { useMemo, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { ExportBtn, PageHeading } from "./components";
// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { fetchPollAndSurveyResults } from "store/pollsAndSurveyResults/initialState";
import { Toasts } from "view/components/Toasts";
import { useDispatch } from "react-redux";
import { openDownladPollsAndSurveysResultByVersionModalAction } from "store/modals";
import usePermissions from "hooks/usePermissions";

const generateDynamicPollAndSurveyLinks = (label: any, pollId: any) => {
  return [
    { path: `/polls-and-surveys/list`, label: `Polls & Surveys` },
    // { path: `/polls-and-surveys/${pollId}/view-data`, label: `View Results` },
  ];
};

export const Header = () => {
  const { pollId } = useParams();
  const { surveyAndPolls } = usePermissions();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector<RootState, fetchPollAndSurveyResults>(
    (state) => state?.pollAndSurveyResults
  );

  const dynamicPollAndSurveyLinks = useMemo(() => {
    return generateDynamicPollAndSurveyLinks(data?.name ?? "", pollId);
  }, [data]);

  const handleDownloadSurveyResult = async () => {
    setIsLoading(true);
    try {
      const res = await apiLibrary.file.fileDownload(
        "survey_result",
        data?.questions,
        [],
        pollId
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `survey_result_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePollAndSurveyExport = () => {
    // handleDownloadSurveyResult();

    dispatch(openDownladPollsAndSurveysResultByVersionModalAction());
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-y-5">
          <Breadcrumb links={dynamicPollAndSurveyLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-y-2 gap-x-8">
            <PageHeading name={data?.name} />
            {surveyAndPolls.canViewVersionSurveyAndPolls && (
              <ExportBtn
                handleClickOnExportBtn={handlePollAndSurveyExport}
                loading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
