import { ERROR_LOADING_FORM_DETAILS, GET_FORM_DETAILS, RESET_FORM_DATA, START_LOADING_FORM_DETAILS, STOP_LOADING_FORM_DETAILS } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { setLoadingProgress } from "store/loadingBar";
import { openformDetailsAction } from "store/modals/reducer.actions";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";
export const fetchFormDetailsAction =
  (formDetails: any) => async (dispatch: Dispatch) => {

    dispatch({ type: START_LOADING_FORM_DETAILS });
    dispatch(setLoadingProgress(getRandomProgress()));

    try {
      const { data } = await apiLibrary.Forms.getFormDetails(formDetails.id);

      localStorage.setItem("formProgramId", data.program.id);
      dispatch({
        type: GET_FORM_DETAILS,
        payload: data,
      });
      if (formDetails.path === "directory") {
        dispatch(openformDetailsAction(formDetails.id));
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_FORM_DATA,
      });

      dispatch({ type: ERROR_LOADING_FORM_DETAILS });
      console.log("An error occurred while fetching the form details:", error);
    } finally {
      dispatch({ type: STOP_LOADING_FORM_DETAILS });
      dispatch(setLoadingProgress(100));
    }
  };

export const resetFormDetailsAction = () => {
  return {
    type: RESET_FORM_DATA,
  };
};
