import GooglePlacesAutocomplete from "Components/AddressInput";
interface AddressInputFieldProps {
  addressFieldError: string | null;
  address: { isPrimary: boolean; address: string };
  handleChange: any;
  handleBlur: any;
  addressInputFieldName: string;
  setFieldValue: any;
}

export const AddressInputField: React.FC<AddressInputFieldProps> = ({
  addressFieldError,
  address,
  handleChange,
  handleBlur,
  addressInputFieldName,
  setFieldValue,
}) => {
  return (
    <GooglePlacesAutocomplete
      label={address?.isPrimary ? "Primary Address " : "Secondary Address"}
      error={addressFieldError}
      onBlur={handleBlur}
      editMode={true}
      inputValue={address?.address}
      hanndleAddressSelect={(address: any) => {
        setFieldValue(addressInputFieldName, address);
      }}
      handleLatitudeAndLongitude={(longitude:any, latitude:any) => {}}
    />
  );
};
