import { useEffect } from "react";
// Components
import { PageHeading, DownloadReportBtn } from "./components";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
// Store Utils
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import { useDispatch } from "react-redux";
import {
  openArchiveRestoreDeleteReportsModalAction,
  openReportsSummaryModalAction,
} from "store/modals";
import { IfetchResultReports } from "store/reportsResult/initialState";
import { DownloadReportProfile } from "utils/donwloadRePortProfile";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import { fetchProfileReportsAction } from "store/reportsProfile";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import useRoles from "hooks/roles";

const dynamicReportProfileLinks = (
  reportId: any,
  reportName: string,
  resultTitle: string
) => [
  { path: `/reports/list`, label: `Reports` },
  // {
  //   path: `/reports/${reportId}/profile`,
  //   label: "Report profile",
  // },
  {
    path: `/reports/${reportId}/profile`,
    label: reportName,
  },
  {
    path: ``,
    label: resultTitle,
  },
];

export const Header = () => {
  const reportsProfile = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );
  const reportsResult = useSelector<RootState, IfetchResultReports>(
    (state) => state?.reportsResult
  );
  const { reports } = usePermissions();
  const { isExternalDataEndReviewer } = useRoles();

  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { reportsId, versionId } = useParams<{
    reportsId: any;
    versionId: any;
  }>();

  const dynamicReportLink = dynamicReportProfileLinks(
    reportsId ?? "",
    reportsProfile?.data?.reportName ?? "",
    reportsResult?.data?.title ?? ""
  );

  const actions = [
    {
      label: "Report Summary",
      onClick: () => {
        dispatch(openReportsSummaryModalAction(reportsId));
      },
      divider: false,
      isVisible: reports.canViewSummaryReports,
    },
    {
      label: "Edit",
      onClick: () => {
        navigate(`/reports/${reportsId}/edit`);
      },
      divider: false,
      isVisible: !isExternalDataEndReviewer() && reports.canEditReports,
    },
    {
      label: "Delete",
      onClick: () => {
        dispatch(
          openArchiveRestoreDeleteReportsModalAction({
            versionId,
            status: "deletedProfile",
            path: "directory",
          })
        );
      },
      divider: false,
      isVisible:
        reports.canDeleteVersionReports && !isExternalDataEndReviewer(),
    },
  ];

  useEffect(() => {
    dispatch(fetchProfileReportsAction(reportsId));
  }, []);

  const handleDownload = async () => {
    DownloadReportProfile(reportsId, versionId);
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <DirectoryBreadCrumb links={dynamicReportLink} />
          <div className="flex items-start justify-between w-full">
            <PageHeading
              reportsName={reportsResult?.data.title}
              parentReportName={reportsProfile?.data?.reportName}
            />
            <div className="flex gap-2">
              {reports.canViewDownloadResultReports &&
                reportsResult.data.downloadPermissions && (
                  <DownloadReportBtn handleDownload={handleDownload} />
                )}
              <div className="flex justify-end items-center flex-grow relative py-0.5">
                {(reports.canViewSummaryReports ||
                  reports.canEditReports ||
                  reports.canDeleteVersionReports) && (
                  <>
                    {actions.some(
                      (action: any) => action.isVisible === true
                    ) ? (
                      <PopOverMenu icon={<DotsVerticalIcon className="" />}>
                        <ActionDropDownMenu data="data" actions={actions} />
                      </PopOverMenu>
                    ) : (
                      <DotsVerticalIcon className="mt-3" />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
