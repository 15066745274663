import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SpecieImages, EditButton } from "Components/SpecieProfile/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { ISpecie } from "store/specieProfile/initialState";
import useDateFormatter from "hooks/formatDateWithPattern";
import {
  fetchSpecieProfileAction,
  resetSpeciesProfileAction,
} from "store/specieProfile/reducer.actions";
import { openAddOrEditSpecieModalAction } from "store/modals";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import ellipsize from "ellipsize";
import Button from "view/components/Button";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import Head from "view/components/Head";
interface BreadcrumbLink {
  path: string;
  label: string;
}

const generateDynamicBreadcrumbLinks = (
  communityId: string | number,
  specieId: string | number,
  communityName: string,
  specieName: string
): BreadcrumbLink[] => {
  if (communityId) {
    return [
      { path: `/community/list`, label: `Communities` },
      {
        path: `/community/${communityId}/profile`,
        label: communityName,
      },
      { path: `/community/${communityId}/species`, label: `Species` },
      {
        path: `/community/${communityId}/species/${specieId}/profile`,
        label: specieName,
      },
    ];
  } else {
    return [
      { path: `/species/list`, label: `Species` },
      { path: `/species/${specieId}/profile`, label: specieName },
    ];
  }
};

export const SpecieProfile = () => {
  const [showFullText, setShowFullText] = useState(false);
  const maxChars = 800;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const navigate = useNavigate();
  const { communityId, specieId } = useParams();
  const { formatDate } = useDateFormatter();
  const specieProfile = useSelector<RootState, ISpecie>(
    (state) => state.specieProfile
  );

  const { species } = usePermissions();

  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const [breadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then(setBreadcrumbData)
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        });
    }
    dispatch(fetchSpecieProfileAction(specieId || ""));
  }, [communityId, specieId, dispatch, navigate]);

  const dynamicCommunityLinks = useMemo(
    () =>
      generateDynamicBreadcrumbLinks(
        communityId ?? "",
        specieId ?? "",
        breadcrumbData?.name ?? "",
        specieProfile.commonName
      ),
    [communityId, specieId, breadcrumbData, specieProfile.commonName]
  );
  const editSpecie = async () => {
    const specieData = {
      alternativeName: specieProfile.alternativeName,
      commonName: specieProfile.commonName,
      description: specieProfile.description,
      images: specieProfile?.attachments?.map((attachment: any) => {
        const image = {
          file: attachment.file.thumb,
          isStar: attachment.isStar,
          id: attachment.id,
          name: attachment.title,
        };
        return image;
      })??[],
      isStar: specieProfile?.attachments?.find((image: any) => image.isStar)
        ?.id,
      scientificName: specieProfile.scientificName,
      preferredName: specieProfile.preferredName,
      typeId: {
        label: specieProfile.type.typeName,
        value: specieProfile.type.typeId,
      },
      id: specieProfile.id,
      path: "profilePage",
    };
    dispatch(openAddOrEditSpecieModalAction(specieData, true));
  };

  const handleClose = () => {
    navigate(-1);
  };
  useEffect(() => {
    return () => {
      dispatch(resetSpeciesProfileAction());
    };
  }, []);
  return (
    <div className="w-full pt-5">
      <Head title={`Species ${specieProfile.commonName}`} />
      <div className="mb-3">
        <DirectoryBreadCrumb links={dynamicCommunityLinks} />
      </div>
      <div className="relative flex flex-col w-full">
        <div className="flex justify-between w-full py-2">
          <p className="text-2xl font-semibold dark:text-textMain">
            {ellipsize(specieProfile.commonName, 70)}
          </p>
          <Button
            type="button"
            text="Back"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="outlined"
            onClick={handleClose}
          />
        </div>
        <div className="py-5">
          <div className="flex items-start rounded-lg gap-4 px-8 py-4 bg-bgWhite w-full max-h-[75vh] overflow-y-auto dark:bg-secondaryLight">
            <div className="flex flex-col items-start justify-start flex-grow gap-4">
              <SpecieImages images={specieProfile?.attachments ?? []} />
            </div>
            <div className="flex p-0.5 rounded-sm bg-white w-full">
              <div className="flex w-full gap-6">
                <div className="flex flex-col gap-1.5 pt-1.5 pb-2 w-full">
                  <div className="flex justify-between w-full gap-2">
                    <div className="w-full py-1">
                      <p className="text-lg font-semibold text-left capitalize text-secondaryMid dark:text-textMain">
                        {specieProfile?.commonName}
                      </p>
                    </div>
                    {species.canEditSpecies && (
                      <EditButton onClick={editSpecie} />
                    )}
                  </div>
                  <div className="w-full">
                    <span className="mr-1 dark:text-caption ">
                      Scientific Name:
                    </span>
                    <span className="inline-block text-sm text-left capitalize break-words text-secondaryMid dark:text-textMain">
                      {specieProfile?.scientificName}
                    </span>
                  </div>

                  <div className="w-full">
                    <span className="mr-1 dark:text-caption ">
                      Alternative Names:
                    </span>{" "}
                    <span className="inline-block text-sm text-left capitalize break-words text-secondaryMid dark:text-textMain">
                      {specieProfile.alternativeName}
                    </span>
                  </div>

                  <div className="w-full">
                    <span className="mr-1 dark:text-caption ">Type:</span>{" "}
                    <span className="inline-block text-sm text-left capitalize break-words text-secondaryMid dark:text-textMain">
                      <div className="flex gap-1 rounded bg-primaryExtraLight">
                        <p className="capitalize text-xs text-center p-1.5 text-primary">
                          {specieProfile.type.typeName}
                        </p>
                      </div>
                    </span>
                  </div>

                  <div className="flex items-center justify-start gap-2 py-1">
                    <p className="text-sm text-left text-secondaryMid dark:text-caption ">
                      Date Added:
                    </p>
                    <p className="text-sm text-left text-secondaryMid dark:text-textMain">
                      {formatDate(specieProfile.dateAdded)}
                    </p>
                  </div>

                  <div className="flex flex-col justify-start items-start w-full relative py-[3px] max-h-[320px] overflow-y-auto">
                    <p className="flex-grow w-full text-sm text-left break-words text-secondaryMid dark:text-textMain">
                      {showFullText
                        ? specieProfile?.description
                        : specieProfile?.description?.slice(0, maxChars)}
                    </p>
                    {specieProfile?.description?.length > maxChars && (
                      <button
                        onClick={toggleText}
                        className="flex items-center"
                      >
                        <h5 className="pr-1 font-semibold text-secondaryMid">
                          {showFullText ? "Read Less" : "Read More"}
                        </h5>
                        {showFullText ? (
                          <CheveronUpIcon height={16} />
                        ) : (
                          <CheveronDownIcon height={16} />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
