import { GET_SPECIE_PROFILE, RESET_SPECIE_PROFILE } from "./action-types";

import { initialState } from "./initialState";

export const specieProfileReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case GET_SPECIE_PROFILE:
      return {
        ...action.payload,
        isLoading: false,
      };
    case RESET_SPECIE_PROFILE:
      return initialState;
    default:
      return state;
  }
};
