import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeDownloadOptionsImportModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { fetchRolesPermissionsAction } from "store/rolesPermissions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { sentenceCase } from "change-case";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { fetchImportsFile } from "store/importsViewData/initialState";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface DownloadOptionsImportModalProps {}

export const DownloadOptionsImportModal: React.FC<
  DownloadOptionsImportModalProps
> = () => {
  const [selectedFileType, setSelectedFileType] = useState("csv");
  const { downloadOptionsImportModal } = useSelector(
    (state: RootState) => state?.modals
  );
  const { data } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeDownloadOptionsImportModalAction());
  };

  const handleDownloadImportFile = async () => {
    setIsLoading(true);
    try {
        const res = await apiLibrary.file.fileDownload(
          "failed_imports",
          data?.fields,
          [],
          downloadOptionsImportModal?.data?.importId
        );
  
        // Convert the response to a Blob
        const blob = new Blob([res], { type: "application/octet-stream" });
  
        // Create a link element
        const link = document.createElement("a");
  
        // Set the href attribute to a URL created from the Blob
        link.href = window.URL.createObjectURL(blob);
  
        // Set the download attribute to specify the file name
        link.download = `failed_imports_${new Date().toISOString()}.${selectedFileType}`;
  
        // Append the link to the body
        document.body.appendChild(link);
  
        // Programmatically click the link to trigger the download
        link.click();
  
        // Remove the link from the DOM
        document.body.removeChild(link);
        Toasts.success("Download complete");
        dispatch(closeDownloadOptionsImportModalAction())
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={downloadOptionsImportModal?.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Download Options
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                Download failed imports to receive a file for manual correction
                of missing fields. After making corrections in this file, the
                administrator can import the file once again.
              </p>
            </div>

            <div className="w-full">
              <TextInput
                fieldAs="select"
                label="Preferred File Format"
                type="select"
                name="type"
                height="20"
                data={["csv", "xls", "xlsx"]}
                handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedFileType(e.target.value);
                }}
                value={selectedFileType}
              />
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text="Download Failed Imports"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={handleDownloadImportFile}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
