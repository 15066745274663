import { apiClient } from "../config";

async function getAllMarkerStyles(queryParams: any) {
  const res = await apiClient.get(`/admin/marker-style`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllMarkerStyles;
