import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const GlobeAltIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
     <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10Zm-2-9c.2 4.6 1.5 7 2 7s1.8-2.4 2-7h-4Zm6 0c-.1 2.4-.5 4.8-1.2 6.5 2.8-1 4.8-3.5 5.2-6.5h-4ZM4.1 13c.4 3 2.4 5.5 5.2 6.5-.8-1.7-1.2-4.1-1.3-6.5H4.1ZM16 11h4c-.4-3-2.4-5.5-5.2-6.5.7 1.7 1.1 4.1 1.2 6.5Zm-6 0h4c-.2-4.6-1.5-7-2-7s-1.8 2.4-2 7Zm-5.9 0h4c.1-2.4.5-4.8 1.2-6.5C6.5 5.5 4.4 8 4.1 11Z"
    />
  </svg>
);
export default GlobeAltIcon;
