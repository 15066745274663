import ActionDropDownMenu from "Components/ActionDropDownMenu";
import {
  ArrowDownLeftIcon,
  CheckIcon,
  CheveronRightIcon,
} from "assets/icons/HeroIcons";
import AdjustmentsHorizIcon from "assets/icons/HeroIcons/AdjustmentsHorizIcon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { activitiesFilterReset, activityTypeAction } from "store/filters";
import { fetchObservationsActivitiesAction } from "store/observationsActivities/reducer.actions";
import RefreshIcon from "assets/icons/HeroIcons/RefreshIcon";
import PopOverMenu from "view/components/PopOverMenu";
import { fetchObservationProfileAction } from "store/observationProfile/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";

const Header = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [selectedItem, setSelectedItem] = useState<string>("All");
  const { observationId } = useParams();
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );

  const seeAllActivityStatus = async (data: string) => {
    setSelectedItem(data);
    try {
      dispatch(activitiesFilterReset());
      dispatch(fetchObservationsActivitiesAction(observationId));
    } catch (error: any) {
      console.error("Failed to see all activities:", error);
    }
  };
  const updateActivityStatus = async (activityType: string) => {
    setSelectedItem(activityType);
    try {
      // Reset the activities filter
      dispatch(activitiesFilterReset());
      // Set the new activity type
      dispatch(activityTypeAction(activityType));
      dispatch(fetchObservationsActivitiesAction(observationId));
    } catch (error: any) {
      console.error("Failed to update activity status:", error);
    }
  };

  const actions = () => {
    return [
      {
        label: "See All",
        onClick: () => seeAllActivityStatus("All"),
        divider: false,
        isVisible: true,
        Icon: selectedItem === "All" && CheckIcon,
      },
      {
        label: "Updates",
        onClick: () => updateActivityStatus("update"),
        divider: false,
        isVisible: true,
        Icon: selectedItem === "update" && CheckIcon,
      },
      {
        label: "Comments",
        onClick: () => updateActivityStatus("comment"),
        divider: false,
        isVisible: true,
        Icon: selectedItem === "comment" && CheckIcon,
      },
    ];
  };
  const handleRefresh = () => {
    dispatch(fetchObservationProfileAction(observationId));
    dispatch(fetchObservationsActivitiesAction(observationId));
  };
  return (
    <div className="flex w-full justify-between p-4 bg-bgWhite rounded-tr-lg rounded-tl-lg dark:bg-secondaryLight items-center">
      <div className="font-semibold font-Overpass dark:text-textMain ml-2">
        Messages
      </div>
      <div className="flex justify-center items-center">
        <RefreshIcon className="cursor-pointer" onClick={handleRefresh} />
        {!observationProfile.requireQa && (
          <PopOverMenu
            icon={<AdjustmentsHorizIcon fill="#2C3236" />}
            style={"unset"}
          >
            <div>
              <ActionDropDownMenu actions={actions()} IconData={true} />
            </div>
          </PopOverMenu>
        )}
      </div>
    </div>
  );
};

export default Header;
