import apiClient from "services/apiClient";

async function updateObservationsFields(id: string, queryParams: any) {
  const res = await apiClient.put(`/admin/dynamic-forms/${id}/fields`, null, {
    params: queryParams,
  });
  return res.data;
}

export default updateObservationsFields;
