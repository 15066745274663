export interface IAnalyticsReportsGraph {
  date: string;
  downloads: number;
  views: number;
}

export interface IReportsAnalytics {
  graphData: IAnalyticsReportsGraph[];
  tableData: IReportsTableData;
  totalPages: number;
}

export interface IReportsTableData {
  reports: IReportsAnalyticsTableData[];
}

export interface IReportsAnalyticsTableData {
  date: string;
  download: boolean;
  member: {
    id: number;
    image: {
      file: {};
      id: number;
    };
    name: string;
  };
  reportVersion: {
    id: number;
    name: string;
  };
  viewed: boolean;
}

export interface IfetchReportsAnalytics {
  data: IReportsAnalytics;
  message: string;
}
const initialReportsAnalytics: IfetchReportsAnalytics = {
  data: {
    graphData: [],
    tableData: {
      reports: [],
    },
    totalPages: 0,
  },
  message: "",
};
export default initialReportsAnalytics;
