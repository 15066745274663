import React, { ChangeEvent, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
} from "@reach/combobox";
import LocationMarkerIcon from "assets/icons/HeroIcons/LocationMarkerIcon";

import TrashIcon from "assets/icons/HeroIcons/TrashIcon";

interface GooglePlacesAutocompleteProps {
  editMode?: boolean;
  inputValue?: string;
  label?: string;
  error?: any;
  placeholder?: any;
  onBlur?: any;
  handleAddressSelect: any;
  handleLatitudeAndLongitude?: any;
  location?: any;
  AddressValue?: any;
  required?: boolean;
  name?: string;
  panel?: any;
  element?: any;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  editMode = false,
  inputValue,
  label = "",
  error,
  placeholder,
  onBlur,
  handleAddressSelect,
  handleLatitudeAndLongitude,
  location,
  AddressValue,
  required,
  name,
  panel,
  element,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    clearSuggestions,
    setValue,
  } = usePlacesAutocomplete({ callbackName: "initMap" });

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  function reverseGeocode(latitude: any, longitude: any) {
    const geocoder = new (window as any).google.maps.Geocoder();
    var latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

    geocoder.geocode(
      { location: latlng },
      function (results: any, status: any) {
        if (status === "OK") {
          if (results[0]) {
            var address = results[0].formatted_address;
            setValue(address, false);
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      }
    );
  }
  const handleSelect = (val: string): void => {
    const geocoder = new (window as any).google.maps.Geocoder();
    geocoder.geocode({ address: val }, (results: any, status: any) => {
      if (status === "OK") {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        handleLatitudeAndLongitude(longitude, latitude);
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

    // Clear suggestions and set the selected value
    handleAddressSelect({
      ...AddressValue,
      addresses: [...(AddressValue?.addresses || []), val],
    });
    setValue("");
    clearSuggestions();
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map(({ place_id, description }: any) => (
      <div
        key={place_id}
        onClick={() => handleSelect(description)}
        className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 cursor-pointer hover:text-primary hover:bg-primaryExtraLight"
      >
        <div className="flex items-center justify-start flex-grow">
          <div className="flex justify-start   font-semibold  items-center flex-grow relative py-0.5">
            {description}
          </div>
        </div>
      </div>
    ));

    return <>{suggestions}</>;
  };

  useEffect(() => {
    if (editMode && inputValue) {
      setValue(inputValue, false);
    }
    if (location?.lat && location?.long) {
      reverseGeocode(location.lat, location.long);
    }
  }, [editMode, setValue, inputValue, location]);

  const handleLocationRemove = (index: number) => {
    if (AddressValue?.addresses) {
      // Calculate the correct index based on the original order
      const originalIndex = AddressValue.addresses.length - 1 - index;

      // Create a copy of the addresses array
      const addresses = [...AddressValue.addresses];

      // Remove the element at the calculated originalIndex
      addresses.splice(originalIndex, 1);

      handleAddressSelect({
        ...AddressValue,
        addresses: addresses,
      });
    } else {
      handleAddressSelect({
        ...AddressValue,
        addresses: [],
      });
    }
  };

  return (
    <div className="p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight" id={`${element?.name}${panel?.sectionId}`}>
      <p
        className={`w-full pb-1 text-md text-left capitalize font-Overpass ${editMode
          ? "text-primary dark:text-caption font-medium "
          : "text-primary dark:text-caption font-regular "
          }`}
      >
        {label} {required && <span>*</span>}
      </p>

      {editMode ? (
        <div>
          <Combobox
            style={{ width: "100%" }}
            onSelect={handleSelect}
            aria-labelledby="demo"
          >
            <div className="flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight">
              <div className="relative flex flex-col">
                <>
                  <div
                    className={`flex items-center border roundde bg-bgWhite dark:bg-secondaryLight ${error
                      ? "border-accent_1Dark"
                      : "border-lineDark dark:border-lineLight"
                      }`}
                  >
                    <button type="button" className="pl-2 focus:outline-none">
                      <LocationMarkerIcon />
                    </button>
                    <ComboboxInput
                      className={`flex-1 justify-start items-center font-Overpass w-full focus:outline-none gap-1.5 px-3 py-2 rounded bg-white`}
                      placeholder={placeholder}
                      value={value}
                      onChange={handleInput}
                      disabled={!ready}
                      name={name}
                    />
                  </div>

                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                    {error && (
                      <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                        {error}
                      </p>
                    )}
                  </div>
                </>
              </div>
            </div>
            {status === "OK" && (
              <ComboboxPopover style={{ zIndex: 9999 }}>
                <ComboboxList>{renderSuggestions()}</ComboboxList>
              </ComboboxPopover>
            )}
          </Combobox>

          <div className="mt-1 location-list">
            <ul>
              {AddressValue &&
                AddressValue?.addresses?.length > 0 &&
                AddressValue?.addresses
                  .slice()
                  .reverse()
                  .map((address: string, index: number) => (
                    <li
                      key={address}
                      className="flex items-center justify-between w-full py-1 mt-2 border rounded border-lineMid bg-primaryLight"
                    >
                      <span className="w-[95%] px-2 font-Overpass text-md">
                        {address}
                      </span>
                      <button
                        onClick={() => handleLocationRemove(index)}
                        type="button"
                        className="w-[5%]"
                      >
                        <TrashIcon
                          width={20}
                          height={20}
                          className="transition-all hover:text-accent_1Dark "
                        />
                      </button>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
          {AddressValue?.addresses?.length > 0 ? (
            <ul className="ml-5">
              {AddressValue.addresses.map((address: string, index: number) => (
                <li
                  key={index}
                  className=" font-Overpass  text-[15px] list-disc mb-1 text-textAnswer "
                >
                  {address.replace('""', "")}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
              (No Selection)
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default GooglePlacesAutocomplete;
