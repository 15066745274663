import apiClient from "services/apiClient";

async function updateMuteStatus(id: string, queryParams: any) {
  const res = await apiClient.put(
    `/admin/conversations/${id}/mute-unmute`,
    null,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default updateMuteStatus;
