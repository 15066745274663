import apiClient from "services/apiClient";

async function createProgram(data:any) {
  const url = `/admin/programs`;


  const res = await apiClient.post(url, data);

  return res.data;
}

export default createProgram;
