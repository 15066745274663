export interface ImportsFile {
  comment: string,
  communityName: string,
  formName: string,
  id: number,
  importStatus: string,
  observationCode: string,
  observationDate: string,
  observationStatus: string,
  observationId: number,
  observer: any,
  status:string,
  programName: {
    id: number,
    name: string,
    programImage: {
      large: string,
      original: string,
      thumb: string
    }
  }
}

export interface ImportsFileData {
  fields: string[];
  fileName: string;
  importedFileData: ImportsFile[];
  totalItems: number;
  totalPages: number;
}

export interface fetchImportsFile {
  data: ImportsFileData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialObservationsData: fetchImportsFile = {
  data: {
    fields: [],
    fileName: "",
    importedFileData: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialObservationsData;
