import React, { useRef } from "react";
import { useCreateMarker, useInitializeMap } from "./hooks";

const Map = ({coordiantes,editMode}:any) => {
  const mapContainer = useRef<any>(null);

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer,editMode);

  // showing the long lat by creating the marker
  useCreateMarker(map, isMapFullyLoaded, coordiantes);

  return <div ref={mapContainer} className="h-[300px] w-full"></div>;
};

export default Map;
