import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const ViewBoardsIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M2 7a3 3 0 0 1 3-3h2c.768 0 1.47.289 2 .764A2.989 2.989 0 0 1 11 4h2c.768 0 1.47.289 2 .764A2.989 2.989 0 0 1 17 4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0 1 13 20h-2c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0 1 7 20H5a3 3 0 0 1-3-3V7Zm8 10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v10ZM8 7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V7Zm8 0v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ViewBoardsIcon;
