import { apiClient } from "../config";
interface RequestParams {
  connected: boolean;
}

async function getCommunitiesByOrganization(
  id: number,
  connected: boolean = false,
  page: number,
  searchString?: string
) {
  const params: any = {
    connected,
  };

  if (page && page > 0) {
    params.page = page;
  }
  if (searchString !== "") {
    params.searchString = searchString;
  }

  const res = await apiClient.get(`/admin/organizations/${id}/communities`, {
    params,
  });
  return res.data;
}

export default getCommunitiesByOrganization;
