const Description = ({ handleDescriptionOnChange, value }: any) => {
  return (
    <div>
      <h2 className="mb-2 text-secondaryMidLight">Description</h2>
      <textarea
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          handleDescriptionOnChange(e.target.value)
        }
        value={value}
        className="flex justify-start items-center w-full focus:outline-none px-3 py-2 bg-bgWhite rounded bg-white border border-lineDark"
      />
    </div>
  );
};

export { Description };
