import React from "react";
import PlaceholderAvatar from "assets/images/Placeholders/OrganizationPlaceholder.png";

interface OrganizationProgramsProps {
  OrganizationData: any;
}
const OrganizationsPrograms: React.FC<OrganizationProgramsProps> = (
  OrganizationData
) => {
  return (
    <>
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4 pt-[9px] pb-[7px]">
          <div className="relative flex items-center justify-start flex-grow">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[596px] text-base font-semibold text-left text-secondaryMid dark:text-textMain cursor-default">
              Programs
            </p>
          </div>
        </div>
        <div className="flex gap-[4px] flex-wrap">
          {OrganizationData?.OrganizationData?.slice(0, 5).map(
            (tag: any, index: any) => {
              return (
                <div key={index}>
                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded bg-primary/[0.08] h-[32px] mr-1 hover:bg-primary/[0.2] cursor-default dark:bg-primary_40">
                    <img
                      src={
                        tag?.profileImage?.file?.original
                          ? tag?.profileImage?.file?.original
                          : PlaceholderAvatar
                      }
                      alt=""
                      className="w-5 h-5 rounded-full"
                    />
                    <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
                      <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-secondaryMid dark:text-textMain">
                        {tag.name}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationsPrograms;
