import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  className?: any;
}

export const ArrowUpRightExpandIcon = ({
  fill = "#6F747B",
  isActive,
  className,
  ...restProps
}: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`flex-grow-0 flex-shrink-0 w-6 h-6 relative ${className}`}
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 2C10.4477 2 10 1.55228 10 1C10 0.447715 10.4477 0 11 0H17C17.5523 0 18 0.447715 18 1V7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7V3.41421L7.70711 11.7071C7.31658 12.0976 6.68342 12.0976 6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929L14.5858 2H11ZM3 4C2.44772 4 2 4.44772 2 5V15C2 15.5523 2.44772 16 3 16H13C13.5523 16 14 15.5523 14 15V11C14 10.4477 14.4477 10 15 10C15.5523 10 16 10.4477 16 11V15C16 16.6569 14.6569 18 13 18H3C1.34315 18 0 16.6569 0 15V5C0 3.34315 1.34315 2 3 2H7C7.55228 2 8 2.44772 8 3C8 3.55228 7.55228 4 7 4H3Z"
    />
  </svg>
);
export default ArrowUpRightExpandIcon;
