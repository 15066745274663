import { baseURL } from "config";
import { apiClient } from "../config";
import axios from "axios";

async function submitSurvey(id: string | number, data: any) {
  const url = `${baseURL}/${id}/fill-survey`;
  const res = await axios.post(url, data);
  return res.data;
}

export default submitSurvey;
