import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const EmptyBoxIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width="1472"
    height="256"
    viewBox="0 0 1472 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M719.261 82.5723C722.473 81.2963 726.129 80.4163 730.185 80.0083C731.285 79.9003 732.085 78.9203 731.977 77.8203C731.865 76.7243 730.885 75.9203 729.789 76.0283C725.329 76.4763 721.313 77.4523 717.785 78.8563C716.757 79.2603 716.257 80.4243 716.665 81.4523C717.073 82.4763 718.237 82.9803 719.261 82.5723Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M739.985 79.9319C744.713 80.2879 749.041 80.3639 752.993 80.1959C754.097 80.1519 754.957 79.2199 754.909 78.1159C754.865 77.0119 753.929 76.1559 752.829 76.1999C749.017 76.3599 744.845 76.2839 740.285 75.9399C739.185 75.8599 738.225 76.6839 738.141 77.7839C738.057 78.8879 738.885 79.8479 739.985 79.9319Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M763.793 79.0126C768.457 78.1366 772.413 76.8406 775.737 75.2446C776.733 74.7646 777.153 73.5686 776.673 72.5766C776.197 71.5806 775.001 71.1606 774.005 71.6366C770.957 73.1006 767.329 74.2806 763.053 75.0846C761.969 75.2886 761.253 76.3326 761.457 77.4206C761.661 78.5046 762.709 79.2166 763.793 79.0126Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M783.889 69.6883C787.473 66.2283 789.577 62.2443 790.525 58.1923C790.777 57.1163 790.105 56.0403 789.033 55.7883C787.957 55.5363 786.881 56.2083 786.629 57.2803C785.845 60.6443 784.085 63.9403 781.113 66.8083C780.317 67.5763 780.293 68.8443 781.061 69.6363C781.829 70.4323 783.093 70.4563 783.889 69.6883Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M789.572 44.4205C787.984 40.2045 785.292 36.6245 782.012 34.3605C781.104 33.7365 779.86 33.9645 779.232 34.8725C778.604 35.7805 778.832 37.0285 779.744 37.6565C782.396 39.4845 784.544 42.4125 785.828 45.8285C786.216 46.8605 787.368 47.3845 788.404 46.9965C789.436 46.6085 789.96 45.4525 789.572 44.4205Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M737.461 34.3522C739.657 24.0482 748.409 19.0362 754.721 19.0002C756.793 18.9882 758.589 19.4762 759.521 20.7242C760.073 21.4642 760.241 22.4562 760.029 23.6722C759.841 24.7602 760.573 25.7962 761.661 25.9842C762.749 26.1722 763.785 25.4402 763.973 24.3522C764.405 21.8522 763.861 19.8482 762.725 18.3322C761.137 16.2002 758.225 14.9802 754.701 15.0002C747.017 15.0442 736.221 20.9762 733.549 33.5202C733.317 34.6002 734.005 35.6602 735.085 35.8922C736.165 36.1242 737.229 35.4322 737.461 34.3522Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M733.565 34.3598C736.273 46.8398 747.029 52.7438 754.693 52.7918C758.489 52.8118 761.565 51.3998 763.069 48.9558C763.977 47.4838 764.373 45.6078 763.949 43.3158C763.429 40.4958 761.573 36.8838 757.689 32.5958C756.949 31.7758 755.681 31.7118 754.865 32.4518C754.045 33.1958 753.981 34.4598 754.725 35.2798C757.429 38.2678 759.033 40.8158 759.737 42.9558C760.269 44.5878 760.257 45.8958 759.665 46.8598C759.129 47.7278 758.169 48.2518 757.013 48.5398C755.561 48.8998 753.821 48.8718 751.985 48.4838C746.117 47.2438 739.389 42.3358 737.477 33.5118C737.241 32.4318 736.177 31.7478 735.097 31.9798C734.017 32.2158 733.333 33.2798 733.565 34.3598Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M734.977 37.9365H761.757C763.965 37.9365 765.757 36.1445 765.757 33.9365C765.757 31.7285 763.965 29.9365 761.757 29.9365H734.977C732.769 29.9365 730.977 31.7285 730.977 33.9365C730.977 36.1445 732.769 37.9365 734.977 37.9365Z"
      fill="#0A7BAC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M654 194.08V216.516C654 220.932 656.896 224.824 661.128 226.092C677.2 230.916 722.408 244.48 733.128 247.692C735 248.256 737 248.256 738.872 247.692C749.592 244.48 794.8 230.916 810.872 226.092C815.104 224.824 818 220.932 818 216.516C818 205.836 818 184.528 818 184.528C818 183.424 817.104 182.528 816 182.528C814.896 182.528 814 183.424 814 184.528V216.516C814 219.164 812.26 221.5 809.724 222.264C793.652 227.084 748.44 240.648 737.724 243.864C736.6 244.2 735.4 244.2 734.276 243.864L662.276 222.264C659.74 221.5 658 219.164 658 216.516V194.08C658 192.976 657.104 192.08 656 192.08C654.896 192.08 654 192.976 654 194.08Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M649.344 148.76C649.344 148.76 639.216 163.776 634.04 171.448C632.988 173.008 632.728 174.976 633.344 176.756C633.956 178.536 635.368 179.928 637.16 180.508L709.14 203.896C711.684 204.72 714.464 203.768 715.964 201.556C721.328 193.636 737.656 169.548 737.656 169.548C738.008 169.028 738.096 168.372 737.892 167.776C737.688 167.18 737.216 166.716 736.616 166.524L656.616 140.564C655.568 140.224 654.44 140.8 654.096 141.852C653.756 142.9 654.332 144.028 655.384 144.372L732.852 169.508L712.652 199.312C712.152 200.048 711.224 200.368 710.376 200.092L638.396 176.704C637.8 176.508 637.328 176.048 637.124 175.452C636.92 174.86 637.008 174.204 637.356 173.684L652.66 150.996C653.28 150.08 653.036 148.836 652.12 148.22C651.208 147.6 649.964 147.844 649.344 148.76Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M804.005 144.256L735.385 166.524C734.333 166.864 733.757 167.992 734.097 169.044C734.441 170.092 735.569 170.668 736.617 170.328L805.237 148.064C806.289 147.724 806.865 146.592 806.525 145.544C806.181 144.492 805.053 143.916 804.005 144.256Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M740.009 177.904C740.009 177.904 751.613 195.024 756.037 201.556C757.537 203.768 760.317 204.72 762.861 203.896C774.657 200.064 819.645 185.444 834.841 180.508C836.633 179.928 838.045 178.536 838.657 176.756C839.273 174.976 839.013 173.008 837.961 171.448C831.689 162.148 817.661 141.348 817.661 141.348C817.041 140.436 815.797 140.192 814.881 140.808C813.969 141.428 813.725 142.672 814.341 143.588C814.341 143.588 828.373 164.384 834.645 173.684C834.993 174.204 835.081 174.86 834.877 175.452C834.673 176.048 834.201 176.508 833.605 176.704L761.625 200.092C760.777 200.368 759.849 200.048 759.349 199.312L743.321 175.66C742.701 174.744 741.457 174.508 740.541 175.128C739.629 175.744 739.389 176.992 740.009 177.904Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M668.768 140.416L736.62 118.328C737.216 118.132 737.688 117.668 737.892 117.072C738.096 116.476 738.008 115.82 737.652 115.3C737.652 115.3 723.284 94.168 718.288 86.82C716.788 84.616 714.012 83.664 711.472 84.488L639.488 107.876C637.696 108.46 636.284 109.848 635.668 111.632C635.056 113.412 635.316 115.376 636.368 116.94C642.144 125.504 654.34 143.588 654.34 143.588C654.96 144.5 656.204 144.744 657.12 144.124C658.032 143.508 658.276 142.264 657.66 141.348C657.66 141.348 645.46 123.268 639.684 114.704C639.332 114.18 639.248 113.528 639.452 112.932C639.656 112.34 640.128 111.876 640.724 111.68L712.708 88.292C713.556 88.016 714.48 88.336 714.98 89.068L732.848 115.348L667.532 136.612C666.48 136.952 665.908 138.084 666.248 139.132C666.592 140.18 667.72 140.756 668.768 140.416Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M735.381 118.328L815.381 144.368C816.229 144.644 817.161 144.328 817.661 143.588C817.661 143.588 829.857 125.504 835.633 116.94C836.685 115.376 836.945 113.412 836.333 111.632C835.717 109.848 834.305 108.46 832.513 107.876L760.529 84.488C757.989 83.664 755.213 84.616 753.713 86.82L739.721 107.396C739.101 108.312 739.337 109.556 740.249 110.176C741.165 110.796 742.409 110.56 743.029 109.648L757.021 89.068C757.521 88.336 758.445 88.016 759.293 88.292L831.277 111.68C831.873 111.876 832.345 112.34 832.549 112.932C832.753 113.528 832.669 114.18 832.317 114.704L815.185 140.1L736.621 114.524C735.569 114.18 734.441 114.756 734.101 115.808C733.757 116.856 734.333 117.984 735.381 118.328Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M796.588 142.46L736 162.116L675.412 142.46L736 122.736L796.588 142.46Z"
      fill="#0A7BAC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M715.992 116.24C714.916 114.724 713.992 113.2 713.216 111.676C712.716 110.692 711.512 110.3 710.528 110.804C709.544 111.304 709.152 112.508 709.652 113.492C710.512 115.184 711.536 116.876 712.732 118.56C713.372 119.46 714.62 119.668 715.52 119.032C716.42 118.392 716.632 117.14 715.992 116.24Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M710.444 103.356C710.084 101.076 710.068 98.8482 710.384 96.7122C710.544 95.6202 709.788 94.6042 708.696 94.4442C707.604 94.2842 706.588 95.0402 706.424 96.1322C706.056 98.6522 706.068 101.284 706.492 103.976C706.664 105.068 707.688 105.812 708.78 105.64C709.868 105.468 710.616 104.444 710.444 103.356Z"
      fill="#6F747B"
    />
  </svg>
);
export default EmptyBoxIcon;
