export interface StyleImage {
  large: string;
  original: string;
  thumb: string;
  imageId: number;
}

export interface Community {
  id: number;
  name: string;
}

export interface Group {
  id: number;
  name: string;
}

export interface MarkerStyle {
  community: Community;
  group: Group;
  id: number;
  markerStyleId: string;
  name: string;
  markerStyleImage: StyleImage;
}

export interface IMarkerStylesData {
  markerStyles: MarkerStyle[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export interface IFetchMarkerStyles {
  data: IMarkerStylesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IFetchMarkerStyles = {
  data: {
    markerStyles: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
