import React, { ReactNode } from 'react';

interface RestrictedProps {
  permissions: { [key: string]: boolean };
  requiredPermission: string;
  children: ReactNode;
}

const Restricted: React.FC<RestrictedProps> = ({ permissions, requiredPermission, children }) => {
  const hasPermission = permissions[requiredPermission];

  return hasPermission ? <>{children}</> : null;
};

export default Restricted;
