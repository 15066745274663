import React from 'react'
import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import CheveronUpIcon from 'assets/icons/HeroIcons/CheveronUpIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeNotificationsSummaryModalAction } from 'store/modals/reducer.actions';

interface IProps {
  columnConfigration: any;
}

export const ColumnConfigration: React.FC<IProps> = ({
  columnConfigration,
}) => {
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (notificationId: any) => {
    navigate(`/notifications/${notificationId}/recipients`);
    dispatch(closeNotificationsSummaryModalAction());
  }
  return (
    <div className='py-4 border-b border-lineMid'>
      <Accordion className="dark:bg-secondaryLight "  style={{ boxShadow: "none" }} defaultExpanded >
        <AccordionSummary
          expandIcon={<CheveronUpIcon
            height={16}
            width={16}
            fill="#2C3236"
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='w-full' onClick={handleIconClick}>
            <div
              className="flex justify-between items-center w-full"
            >
              <p className="text-secondaryMid dark:text-textMain ">Column Configuration</p>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '-20px' }}>
          <Typography>
            {columnConfigration?.map((el: any) => {
              return (
                <div className='flex items-center justify-between py-2 px-4'>
                  <p className='text-sm mt-[-3px] dark:text-textMain'>{el}</p>
                </div>
              )
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
