import { ERROR_LOADING_IMPORT_STATUS_DETAIL, GET_IMPORT_STATUS_DETAIL, START_LOADING_IMPORT_STATUS_DETAIL, RESET_IMPORT_STATUS_DETAIL, STOP_LOADING_IMPORT_STATUS_DETAIL } from "./action-types";
import { initialState } from "./initialState";

const importStatusDetailReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case START_LOADING_IMPORT_STATUS_DETAIL:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_IMPORT_STATUS_DETAIL:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_IMPORT_STATUS_DETAIL:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_IMPORT_STATUS_DETAIL:
      return {
        ...state,
        data: action.payload,
        message: "",
        stateIs: "Idle",
      };

      return ;
    case RESET_IMPORT_STATUS_DETAIL:
      return initialState;
    default:
      return state;
  }
};

export default importStatusDetailReducer;
