import {
    RESET_MODE,
    TOGGLE_DARK_MODE,
} from "./action-types";
import { Dispatch } from "redux";

export const toggleDarkMode =
    () => async (dispatch: Dispatch) => {
        dispatch({
            type: TOGGLE_DARK_MODE,
        });
    };


export const resetMode = () => {
    return {
        type: RESET_MODE,
    };
};
