import React from "react";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import { SurveyQuestionElementBase } from "survey-react-ui";

export const geoCoderProperties = [
  {
    name: "AbleToSelectMultiple",
    default: "false",
    type:"boolean",
    category: "general",
    visibleIndex: 0, // After the Name and Title
  },
];

export default class SurveyGeoCoderQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get type() {
    return this.question.inputType;
  }

  handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.question.value = e.target.value;
  };

  renderInput() {
    return (
      <div className="flex items-center w-full gap-2 px-3 py-2 border rounded border-lineDark">
        <SearchIcon  className="fill-lineDark w-5 mb-[2px]" />
        <input
          type="search"
          placeholder="Search on a map"
          className="flex-1 outline-none text-textLightExtra"
          onChange={this.handleInputOnChange}
        />
      </div>
    );
  }

  renderElement() {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {this.renderInput()}
      </div>
    );
  }
}
