import login from "./login";
import socialLogin from "./socialLogin";
import forgotPassword from "./forgotPassword";
import logout from "./logout";
import createNewPassword from "./createNewPassword";
import updatePassword from "./updatePassword";
import attachSocialAccounts from "./attachSocialAccounts"
import resetPassword from "./resetPassword";
import detachSocialAccounts from "./detachSocialAccounts"
const Auth = {
  login,
  socialLogin,
  attachSocialAccounts,
  forgotPassword,
  logout,
  createNewPassword,
  updatePassword,
  resetPassword,
  detachSocialAccounts

};

export default Auth;
