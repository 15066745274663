export const RIVER_CONDITION_QUESTIONS_NAME = {
  RiverLevel: "RiverLevel",
  WaterClarity: "WaterClarity",
  StreamTemperature: "StreamTemperature",
  TemperatureType: "TemperatureType",
};

export const GPS_INFO_QUESTIONS_NAME = {
  CoordinatesFormat: "CoordinatesFormat",
  Latitude: "Latitude",
  Longitude: "Longitude",
  AltitudeFormat: "AltitudeFormat",
  Altitude: "Altitude",
  LocationAccuracy: "LocationAccuracy",
  AltitudeAccuracy: "AltitudeAccuracy",
};

export const SPECIES_DETAILS_QUESTIONS_NAME = {
  Category: "Category",
  Type: "Type",
  ObjectName: "ObjectName",
  SpecieName: "SpecieName",
  PresenceAbsence: "PresenceAbsence",
  IdConfidence: "IdConfidence",
  Sex: "Sex",
  AgeClass: "AgeClass",
};

export const COUNT_DETAILS_QUESTIONS_NAME = {
  ExactCount: "ExactCount",
  MinimumCount: "MinimumCount",
  MaximumCount: "MaximumCount",
  CountUnits: "CountUnits",
};

export const SIGHTING_DETAILS_QUESTIONS_NAME = {
  CoordinatesFormat:"CoordinatesFormat",
  Latitude:"Latitude",
  Longitude:"Longitude",
  ObservationMethod: "ObservationMethod",
  ObservationDirection: "ObservationDirection",
  DistanceUnits: "DistanceUnits",
  MinimumDistance: "MinimumDistance",
  MaximumDistance: "MaximumDistance",
  DistanceMethod: "DistanceMethod",
  Visibility: "Visibility",
};

export const WEATHER_CONDITIONS_QUESTIONS_NAME = {
  WeatherMethod: "WeatherMethod",
  WindSpeed: "WindSpeed",
  WindDirection: "WindDirection",
  SeaState: "SeaState",
  Tide: "Tide",
  AirTemperature: "AirTemperature",
  AirTemperatureUnit: "AirTemperatureUnit",
  WeatherCondition: "WeatherCondition",
  HorizonVisibility: "HorizonVisibility",
};

export const GENERAL_INFO_QUESTIONS_NAME = {
  DataEntry: "DataEntry",
  Observers: "Observers",
  Reported: "Reported",
  StartDate: "StartDate",
  EndDate: "EndDate",
  Comments: "Comments",
  AudioComments: "AudioComments",
};

export const HARVEST_QUESTIONS_NAME = {
  RetrievalDate: "RetrievalDateAndTime",
  // RetrievalTime: "RetrievalTime",
  RetrievalLocation: "RetrievalLocationRegion",
  ValuedFeautres: "RetrievalLocationPoint",
  StruckLostDate: "StruckAndLostDateAndTime",
  // StruckLostTime: "StruckAndLostTime",
  StruckAndLostLocationRegion:"StruckAndLostLocationRegion",
  StruckAndLostLocationPoint:"StruckAndLostLocationPoint",
  HarvestMethod: "HarvestMethod",
  ShotsFired: "ShotsFired",
  ExactAge: "ExactAge",
  ChaseTimeHours: "ChaseTimeHours",
  ChaseTimeMinutes: "ChaseTimeMinutes",
  RetrievalMethod: "RetrievalMethod",
};

export const SAMPLE_QUESTIONS_NAME = {
  SampleBioId: "SampleBioId",
  SampleType: "SampleType",
  SampleAmount: "SampleAmount",
  AmountUnits: "AmountUnits",
  SampleCount: "SampleCount",
  Sampler: "Sampler(S)",
  TissueRemovalTime: "TissueRemovalTime",
  TissueFrozenTime: "TissueFrozenTime",
  ShippedTo: "ShippedTo",
  ShippingDate: "ShippingDate",
  CurrentLocation: "CurrentLocation",
};
