import React from "react";
import Button from "view/components/Button";

const UpdateButton = ({ isSubmitting }: { isSubmitting?: any }) => {
  return (
    <Button
      disabled={isSubmitting}
      type="submit"
      text="Save"
      filledColor="primary"
      outlinedColor="primary"
      textColor="textWhite"
      className="px-5 py-2"
      width="35"
      height="13"
      fontStyle="font-semibold"
      variant="filled"
    />
  );
};

export { UpdateButton };
