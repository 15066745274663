import {
  ERROR_LOADING_NOTIFICATIONS_RECIPIENTS,
  GET_ALL_NOTIFICATIONS_RECIPIENTS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS_RECIPIENTS,
  STOP_LOADING_NOTIFICATIONS_RECIPIENTS,
} from "./action-types";
import initialState, { INotificationsRecipients } from "./initialState";
export const notificationRecipientsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case START_LOADING_NOTIFICATIONS_RECIPIENTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_NOTIFICATIONS_RECIPIENTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_NOTIFICATIONS_RECIPIENTS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_NOTIFICATIONS_RECIPIENTS:
      const noticationsRecipients = action.payload?.members?.map(
        (item: INotificationsRecipients) => {
          const {
            recipientName,
            reminderId,
            notificationCenter,
            toast,
            push,
            email,
            dateRecieved,
          } = item;
          const data = {
            recipientName,
            reminderId,
            notificationCenter,
            toast,
            push,
            email,
            dateRecieved,
          };

          return data;
        }
      );

      return {
        ...state,
        data: {
          members: noticationsRecipients,
          status: action.payload.status,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
