import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { panelsName } from "../../../panelsTitle";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import { updateDrawPolygons } from "store/surveyJS";

const useHandleMapMovement = (currentQuestionEle: any, map: any) => {
  const [isCenterPointSetup, setIsCenterPointSetup] = useState(false);
  const { drawPolygons, selectedQuestionElement,surveyActiveTab } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const selectedQuestionType = selectedQuestionElement?.getType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentQuestionEle && map && !isCenterPointSetup) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,

        });
      }
      // update map center values
      else {
        map.on("load", () => {
          updateDrawPolygonCenterPoint(
            currentQuestionEle,
            map,
            selectedQuestionType,
            drawPolygons,
            dispatch
          );
        });
      }

      if (
        selectedQuestionType !== panelsName.HARVEST &&
        selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        setIsCenterPointSetup(true);
      }
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (currentQuestionEle && map) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        updateDrawPolygonCenterPoint(
          currentQuestionEle,
          map,
          selectedQuestionType,
          drawPolygons,
          dispatch
        );
      });
    }
  }, [currentQuestionEle, map]);

  return null;
};

function updateDrawPolygonCenterPoint(
  currentQuestionEle: any,
  map: any,
  selectedQuestionType: any,
  drawPolygons: any,
  dispatch: any
) {
  const updatedCurrentDrawPolygon = { ...currentQuestionEle };

  // update current element
  updatedCurrentDrawPolygon.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };
  updatedCurrentDrawPolygon.zoomLevel =map.getZoom();

  if (
    selectedQuestionType !== panelsName.HARVEST &&
    selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
  ) {
    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  }
}

export { useHandleMapMovement };
