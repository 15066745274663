
import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import { getBrandPlaceholder } from "utils/customBranding";

interface AdminProps {
  userData: any;
  unAssignAdmin: any;
}
const Admins: React.FC<AdminProps> = ({ userData, unAssignAdmin }) => {
  return (
    <div>
      <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">
        {" "}
        <div className="flex items-center flex-grow w-full cursor-pointer">
          <div className="relative flex items-center justify-between cursor-pointer">
            <img
              src={
                userData?.personImage
                  ? userData?.personImage
                  : getBrandPlaceholder("userProfile")
              }
              alt=""
              height="32px"
              width="32px"
              className="object-cover mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
            <div>
              <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${userData.name} `}</p>{" "}
              <p className="ml-3 dark:text-textMain text-secondaryMid">
                {userData.observations} observations
              </p>
            </div>
          </div>
        </div>
        <button type="button" className="flex-shrink-0">
          <XCloseSmallControlIcon onClick={() => unAssignAdmin(userData)} />
        </button>
      </div>
    </div>
  );
};

export default Admins;
