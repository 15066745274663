import usePermissions from "hooks/usePermissions";
import React from "react";
import { useSelector } from "react-redux";
/************ Store Utils ************/
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "store";
import { openDeleteAccountModalAction } from "store/modals/reducer.actions";
import { UserProfileState } from "store/userProfile/initialState";

interface IProps {
  personId?: any;
}

export const DeleteAccount: React.FC<IProps> = () => {
  const { personId } = useParams<{
    personId: string;
  }>();

  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { users } = usePermissions();

  const dispatch = useDispatch();

  const openDeletionModal = () => {
    dispatch(
      openDeleteAccountModalAction({ personId: personId, path: "userProfile" })
    );
  };
  return (
    <div className="flex flex-col items-start justify-start overflow-hidden border-t-0 border-b-0 border-l border-r-0 border-lineMid">
      <div className="flex flex-col items-start justify-start overflow-hidden bg-white -0">
        <div className="flex flex-col items-start justify-center pt-6 pl-12 pr-8 -0">
          <div className="flex items-center justify-start gap-2 -0">
            <div className="relative flex flex-col items-start justify-center py-1">
              <p className="text-lg font-semibold text-left -0 text-secondaryMid">
                Request Deletion
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-2 px-12 pt-4 pb-12 -0">
          <div className="flex flex-col justify-start items-start  -0 -0 relative pt-1.5">
            <p className="text-sm text-left -0 text-secondaryMidLight">
              You can Request Deletion of your account by clicking the button
              below. This will send a request to the Super Admin, who will then
              contact you about further steps for your account deletion.
            </p>
          </div>
          {users.canDeletePerson && (
            <button
              disabled={userProfile.status === "deleted"}
              onClick={openDeletionModal}
              className="flex items-center justify-start gap-6 disabled:opacity-50 disabled:cursor-not-allowed "
            >
              <div className="flex justify-center items-center -0 -0 gap-1.5 rounded-3xl">
                <div className="flex justify-center items-center -0 -0 relative pt-1.5 pb-[7px]">
                  <p className="text-base font-semibold text-center -0 text-primary">
                    Delete Account
                  </p>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
