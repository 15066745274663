import { Dispatch } from "redux";
import { ERROR_LOADING_MANAGERS, GET_ALL_MANAGERS, RESET_DATA, START_LOADING_MANAGERS, STOP_LOADING_MANAGERS } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchManagerAction =
  (programId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_MANAGERS});
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.managerFilter
      );

      const { data } = await apiLibrary.Managers.getAllManagers({...queryString, id:programId});
      dispatch({
        type: GET_ALL_MANAGERS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({type: ERROR_LOADING_MANAGERS})
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_MANAGERS});
      // dispatch(loadingState(false))
    }
  };
