import { CheveronDownIcon } from "assets/icons/HeroIcons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomCheckbox from "view/components/CheckBox";

import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { SearchBar } from "./SearchBar";
import { RootState } from "store";
import { ISummaryReports } from "store/reportsSummary";
import { useSelector } from "react-redux";
import PopOverMenu from "./PopOverMenu";
import ActiondropdownMenuForReport from "./PopOverMenu/ActiondropdownMenuForReport";
import { InformationCircleIcon } from "assets/icons/HeroIcons";
import { Dropdown } from "view/pages/imports/addImport/Components";
import { sentenceCase } from "change-case";

interface IProps {
  name: string;
  value: any;
  handleChange: (value: any) => void;
  options: any[],
  isDisabled: boolean,
  placeholder: string;
  // Other props...
}

export const InputFields: React.FC<IProps> = ({
  name,
  value,
  options,
  handleChange,
  isDisabled,
  placeholder
}) => {

  // Initialize default value based on props
  const [selectedValue, setSelectedValue] = useState<number | undefined>(value);

  useEffect(() => {
    // Automatically select the first option as default if not disabled and no value is selected
    if (!isDisabled && (value === undefined || value === '') && options.length > 0) {
      const firstOptionValue = 0; // Since you set value as index, the first option value is 0
      handleChange(firstOptionValue);
      setSelectedValue(firstOptionValue);
    } else {
      setSelectedValue(value);
    }
  }, [value, options, isDisabled, handleChange]);

  const dropdownOptions = useMemo(() => {
    const updatedOptions = options.map((option, index) => {
      return {
        ...option,
        label: sentenceCase(option.mappedProperty),
        value: index
      }
    })
    return updatedOptions;
  }, [options])

  return (
    <div className="flex flex-col items-start self-stretch justify-start ">
      <div className="w-full">
        <Dropdown
          label=""
          name={name}
          options={dropdownOptions}
          value={selectedValue}
          handleChange={handleChange}
          placeholder={placeholder}
          required={false}
          isDisabled={isDisabled}
          defaultValue={!isDisabled && dropdownOptions[0]}
          searchable={true}
        />
      </div>
    </div>
  );
};
