import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Oranizations.scss";
// types
import { TableProps } from "./types";
import { orgzanizationsSortingAction } from "store/filters/reducer.actions";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import { filterKeys } from "Components/Users/Table";
import {
  openArchiveOrPublishOrganizationModalAction,
  openRemoveFromProgramAction,
} from "store/modals";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

export const KEYS_TO_BE_REMOVED = ["id"];

const generatingDirectoryActions = (columns: any, rowsData: any) => {
  const fileDownload = async () => {
    if (rowsData.length === 0) {
      Toasts.error("Please select at least one organization from the table");
      return;
    }

    try {
      const res = await apiLibrary.file.fileDownload(
        "organizations",
        columns,
        rowsData.map((item: any) => {
          return item.name.id;
        })
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `organizations_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return [
    {
      label: "Export Details",
      onClick: () => fileDownload(),
      divider: false,
      isVisible: true,
    },
  ];
};

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  selectAll,
  setSelectAll,
  flexRender,
  setSelectedRows,
  isRowSelected,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();
  const { programId } = useParams();
  const { programs } = usePermissions();
  const DirectoryActions = () => {
    const filteredKeys = filterKeys(columnVisibility, columnOrder);
    return generatingDirectoryActions(
      filteredKeys,
      getAllRowsData(table, columnOrder, columnVisibility, columns)
    );
  };
  const organizationsActions = (data: any) => {
    return [
      {
        label: "Restore",
        onClick: () => {
          dispatch(
            openArchiveOrPublishOrganizationModalAction({
              id: data.id,
              status: data.status,
            })
          );
        },
        divider: false,
        isVisible:
          shouldShowRemoveProgramOption(programs, programId) &&
          data.status === "archived",
      },
      {
        label: "Remove from program",
        onClick: () => {
          const dataa = {
            id: data.id,
            name: data.name,
            programId,
            entity: "organization",
          };
          dispatch(openRemoveFromProgramAction(dataa));
        },
        divider: false,
        isVisible:
          shouldShowRemoveProgramOption(programs, programId) &&
          !data.openAccess,
      },
    ];
  };
  function shouldShowRemoveProgramOption(programs: any, programId: any) {
    const isProgramPresent = programId ? true : false;
    const isProgramAdminRoleExist = programs.canDeleteEntity;
    const canAssignAsAdmin = isProgramPresent && isProgramAdminRoleExist;
    return canAssignAsAdmin;
  }
  const getOrganizationsActions = (data: any) => {
    const modalData = {
      status: data?.status,
      id: data?.id,
      name: data.name.name,
      submissionDate: data?.submissionDate,
      path: "directory",
      openAccess: data?.openAccess,
    };

    return organizationsActions(modalData);
  };
  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(orgzanizationsSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(orgzanizationsSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(orgzanizationsSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden overflow-x-auto max-h-[80vh] overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          DirectoryActions={DirectoryActions()}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
          getOrganizationsActions={getOrganizationsActions}
        />
      </table>
    </div>
  );
};
