import React from "react";
import KpiCard from "./KpiCard";
import img1 from "assets/images/Auth/binoculars.png";
import member from "assets/images/Auth/member.png";
import programsIcon from "assets/images/Auth/folder.png";
import qaqcIcon from "assets/images/Auth/qaqc.png";
import form from "assets/images/Auth/forms.png";
import admins from "assets/images/Auth/admins.png";
import species from "assets/images/Auth/species.png";
import objects from "assets/images/Auth/objetcs.png";
import region from "assets/images/Auth/regions.png";
import { useSelector } from "react-redux";
import { ICommunity } from "store/communityProfile";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import BinocularsKpiIcon from "assets/icons/HeroIcons/BinocularsKpiIcon";
import MembersKpiIcon from "assets/icons/HeroIcons/MembersKpiIcon";
import DocumentsListIcon from "assets/icons/HeroIcons/DocumentsListIcon";
import CollectionKpiIcon from "assets/icons/HeroIcons/CollectionKpiIcon";
import FolderFileIcon from "assets/icons/HeroIcons/FolderFileIcon";
import FolderSharedKpiIcon from "assets/icons/HeroIcons/FolderSharedKpiIcon";
import CubeKpiIcon from "assets/icons/HeroIcons/CubeKpiIcon";
import MapMarkerKpiIcon from "assets/icons/HeroIcons/MapMarkerKpiIcon";

export const CommunityKpis = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const { communities } = usePermissions();

  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-start flex-grow gap-4 md:w-full ">
      <div className="grid w-full grid-cols-3 gap-3 justify-evenly">
        <KpiCard
          icon={<BinocularsKpiIcon fill="#2C3236"/>}
          title="Observations"
          count={communityProfile.observationsCount}
          redirectLinkAction={() => {
            navigate(`/community/${communityProfile.id}/observations`);
          }}
        />

        <KpiCard
          icon= {<FolderSharedKpiIcon fill="#2C3236"/>}
          title="Members"
          count={communityProfile.membersCount}
          redirectLinkAction={() => {
            navigate(`/community/${communityProfile.id}/users`);
          }}
        />
        <KpiCard
          icon= {<FolderFileIcon/>}
          title="Programs"
          count={communityProfile.programsCount}
          redirectLinkAction={() => {
            navigate(`/community/${communityProfile.id}/programs`);
          }}
        />
        <KpiCard
          // imgSrc={qaqcIcon}
          title="Quality Assurance"
          count={communityProfile.qaqcCount}
          redirectLinkAction={() => {
            navigate(`/community/${communityProfile.id}/quality-assurance`);
          }}
        />
        <KpiCard
          icon={<DocumentsListIcon fill="#2C3236" />}
          title="Forms"
          count={communityProfile.formsCount}
          redirectLinkAction={() => {
            navigate(`/community/${communityProfile.id}/forms`);
          }}
        />
        <Restricted permissions={communities} requiredPermission="canViewUserAdminsCommunities">
          <KpiCard
            icon = {<MembersKpiIcon/>}
            title="Community Admins"
            count={communityProfile.adminsCount}
            redirectLinkAction={() => {
              const state = { isAdminRole: true };
              navigate(`/community/${communityProfile.id}/users`, { state });
            }}
          />
        </Restricted>
        <KpiCard
          icon={<CollectionKpiIcon fill="#2C3236" />}
          title="Species"
          count={communityProfile.speciesCount}
          redirectLinkAction={() => {
            const state = { redirectFromKPI: true };
            navigate(`/community/${communityProfile.id}/observations`, {
              state,
            });
          }}
        />
        <KpiCard
          icon= {<CubeKpiIcon fill="#2C3236"/>}
          title="Objects"
          count={communityProfile.objectsCount}
          redirectLinkAction={() => {
            const state = { redirectFromKPI: false };
            navigate(`/community/${communityProfile.id}/observations`, {
              state,
            });
          }}
        />
        <Restricted permissions={communities} requiredPermission="canViewRegionsCommunities">
          <KpiCard
            icon= {<MapMarkerKpiIcon fill="#2C3236"/>}
            title="Regions"
            count={communityProfile.regionsCount}
            redirectLinkAction={() => {
              const state = { redirectFromKPI: true };
              navigate(`/community/${communityProfile.id}/geography`, { state });
            }}
          />
        </Restricted>
      </div>
    </div>
  );
};
