import apiClient from "services/apiClient";

async function choseReportColumns(reportId: any, data: any) {
  const url = `/admin/reports/${reportId}/choose-columns`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default choseReportColumns;
