import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeDownladPollsAndSurveysResultByVersionModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { fetchRolesPermissionsAction } from "store/rolesPermissions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { camelCase, sentenceCase } from "change-case";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { fetchImportsFile } from "store/importsViewData/initialState";
import { useParams } from "react-router-dom";
import apiClient from "services/apiClient";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";
import MultiSelect from "view/components/Multiselect";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface DownladPollsAndSurveysResultByVersionModalProps {}

export const DownladPollsAndSurveysResultByVersionModal: React.FC<
  DownladPollsAndSurveysResultByVersionModalProps
> = () => {
  const [selectedVersion, setSelectedVersion] = useState<any>(null);

  const { downladPollsAndSurveysResultByVersionModal } = useSelector(
    (state: RootState) => state?.modals
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    dispatch(closeDownladPollsAndSurveysResultByVersionModalAction());
  };

  const [surveyVersions, setSurveyVersions] = useState<any[]>([]);

  const { pollId } = useParams();

  const getSurveyVersions = async (id: any) => {
    try {
      const { data } =
        await apiLibrary.pollsAndSurveys.getPollAndSurveyVersions(id);
      const sortedData = sortVersions(data);
      const dataa = sortedData.map((item: any) => {
        return { label: item.name, value: item.id };
      });

      setSurveyVersions(dataa);
    } catch (error) {
      console.error("Error fetching survey versions:", error);
    }
  };

  useEffect(() => {
    if (pollId !== undefined) {
      getSurveyVersions(pollId);
    }
  }, []);

  async function handleDownloadResultFile(
    entity: any,
    id?: any,
    versions?: any[]
  ) {
    console.log("versions", versions);
    dispatch(setLoadingProgress(getRandomProgress()));
    setIsLoading(true);
    try {
      const queryParams: any = {};
      if (versions) {
        queryParams.versionIds = versions
          .map((version: any) => version.value)
          .join(",");
      }

      const res = await apiClient.get(`/admin/survey-and-polls/${id}/result`, {
        params: queryParams,
      });
      const workSheet = convertTheDataIntoWorkSheet(res.data.data);
      saveExcel(workSheet);
    } catch (error: any) {
      console.log("error", error);
      const errorMsg =
        error?.response?.data?.message ??
        "An error occurred while downloading the file";
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  }

  function convertTheDataIntoWorkSheet(surveyData: any[] = []): any[] {
    const updatedSurveyData = surveyData.map((survey: any) => {
      const updatedResponses = standardizeResponseKeys(survey.responses ?? []);
      return {
        name: survey.name,
        columns: survey.questions.map((question: any) => ({
          header:
            question === "responseDateTime"
              ? "Response Date-Time"
              : sentenceCase(question ?? ""),
          key: camelCase(question ?? ""),
        })),
        data: updatedResponses.map((response) => {
          if (response.hasOwnProperty("responseDateTime")) {
            const newResponse = { ...response };
            newResponse.responseDateTime = moment(
              response.responseDateTime
            ).format("DD MMM YYYY h:mm A");

            return newResponse;
          } else {
            return response;
          }
        }),
      };
    });
    return updatedSurveyData;
  }

  function isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const saveExcel = async (workSheets: any) => {
    const workbook = new Excel.Workbook();

    workSheets.forEach((sheet: any) => {
      const worksheet = workbook.addWorksheet(sheet.name);
      worksheet.columns = sheet.columns;

      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column: any) => {
        column.width =
          column.header.length < 12 ? 12 : column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      sheet.data.forEach((dataRow: any) => {
        worksheet.addRow(dataRow);
      });

      worksheet.eachRow({ includeEmpty: false }, (row: any) => {
        row.eachCell((cell: any) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          if (Array.isArray(cell.value)) {
            cell.alignment = { horizontal: "center" };
            cell.value = cell.value.join(", ");
          }
        });
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileName = `survey_results_${new Date().toISOString()}.xlsx`;
    saveAs(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      fileName
    );
    handleClose();
  };
  return (
    <Modal
      open={downladPollsAndSurveysResultByVersionModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  w-full justify-between items-start   py-0.5">
              <div className="relative flex items-center justify-start ">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Export Survey Results
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>

            <div className="w-full">
              <AddVersion
                handleAddVersionOnChange={(option: any) => {
                  setSelectedVersion(option);
                }}
                handleAddVersionSearch={() => {}}
                value={selectedVersion}
                Versions={surveyVersions}
              />
            </div>

            <div className="flex items-center justify-end gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text={isLoading ? "Exporting..." : "Export"}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={() => {
                  handleDownloadResultFile(
                    "survey_result",
                    Number(pollId),
                    selectedVersion
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export function sortVersions(versions: any = []) {
  if (!Array.isArray(versions)) {
    return [];
  }
  return versions.sort((a, b) => {
    const versionA = parseInt(a.name.split("-")[1]);
    const versionB = parseInt(b.name.split("-")[1]);
    return versionA - versionB;
  });
}

function standardizeResponseKeys(responses: any[]) {
  return responses.map((response: any) => {
    const standardizedResponse: any = {};
    Object.keys(response).forEach((key) => {
      let newKey = camelCase(key);
      standardizedResponse[newKey] = response[key];
    });

    return standardizedResponse;
  });
}

const AddVersion = ({
  handleAddVersionOnChange,
  Versions,
  value,
  handleAddVersionSearch,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  // const { VersionId } = values;
  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full gap-1 rounded">
      <div className="relative flex items-center justify-start pt-1">
        <p
          className={`flex-grow w-[596px] text-sm font-medium text-left capitalize pb-1 dark:text-caption ${"text-secondaryMid"}`}
        >
          Version Name
        </p>
      </div>
      <div
        className={`flex justify-start items-center  w-full  relative gap-1.5 px-3  rounded bg-white border border-lineDark dark:border-lineLight`}
      >
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative w-6 h-6"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
            fill="#9B9FA5"
          />
        </svg>
        <div className="flex flex-col items-start justify-start flex-grow w-full ">
          <MultiSelect
            inputValue={inputValue}
            onInputChange={(value: string) => {
              setInputValue(value);
              handleAddVersionSearch(value);
            }}
            placeholder="Please choose the versions whose results you would like to export."
            options={Versions}
            defaultValue={{ value: "", label: "" }}
            isMulti={true}
            value={value}
            transformY="translateY(-130%)"
            onChange={(option: any) => {
              handleAddVersionOnChange(option);
            }}
          />
        </div>
      </div>
      <p className="flex-grow w-full text-xs text-left text-textMidLight dark:text-textMain">
        If you don't select any versions, we'll go ahead and export the results
        for all versions by default.
      </p>
    </div>
  );
};
