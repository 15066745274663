import { camelCase } from "change-case";
import { sentenceCase } from "change-case";
import React from "react";

interface StatusProps {
  status: string;
}


const ImportedStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-tertiaryLight">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-tertiaryDark">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};


const CompletedStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-tertiaryLight">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-tertiaryDark">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const FailedStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-accent_1Light">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-accent_1Dark">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const InReviewStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-primaryExtraLight">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-primaryMid capitalize">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const ArchivedStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-brandHover">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const DraftStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-2 py-0.5 rounded bg-brandHoverLight">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMidLight">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const UnknownStatus: React.FC<StatusProps> = ({ status }) => {
  return (
    <div className="flex justify-center items-center   gap-1 py-0.5 rounded bg-brandHover">
      <div className="relative flex items-center justify-center px-2 py-1">
        <p className="text-xs text-center text-secondaryLight">{status}</p>
      </div>
    </div>
  );
};
export const Statuses: React.FC<StatusProps> = ({ status }) => {
  if (status === null || status === undefined) {
    return null;
  }
  const camelCaseStatus = camelCase(status);
  switch (camelCaseStatus) {
    case "imported":
      return <ImportedStatus status={sentenceCase(camelCaseStatus)} />;
    case "completed":
      return <CompletedStatus status={sentenceCase(camelCaseStatus)} />;
    case "failed":
      return <FailedStatus status={sentenceCase(camelCaseStatus)} />;
    case "inReview":
      return <InReviewStatus status={sentenceCase(camelCaseStatus)} />;

    default:
      return <UnknownStatus status={sentenceCase(camelCaseStatus)} />;
  }
};
