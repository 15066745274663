export interface IProtocol {
  archivedBy: {
    id: number;
    name: string;
    image: string;
  }[];
  createdBy: {
    id: number;
    name: string;
    image: string;
  };
  dateCreated: string;
  dateUpdated: string;
  form: {
    id: number;
    name: string;
    image: string;
    protocolConnectedAt: string | null;
  };
  id: number;
  protocol: string;
  program: {
    id: number;
    name: string;
    image: string;
    programStatus: string | undefined;
  };
  dateArchived: any;
  title: string;
  status: string;
}

export interface ProtocolsData {
  protocols: IProtocol[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchProtocols {
  data: ProtocolsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialProtocolsState: IfetchProtocols = {
  data: {
    protocols: [],
    fields: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialProtocolsState;
