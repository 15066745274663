import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeOrganizationsModalAction,
  closeOrganizationsProfileDetailModalAction,
  closeRemoveCommunityFromOrganizationAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchUsersAction } from "store/users";
import { fetchCommunitiesAction } from "store/communities";
import { fetchOrganizationsAction } from "store/oranizations";
import { useParams } from "react-router-dom";
import { fetchProgramsAction } from "store/programs";
import { useState } from "react";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

export const RemoveCommunityFromOrganizationModal = () => {
  const { removeCommunityFromOrganizationModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communityId, organizationId, programId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const handleClose = () => {
    dispatch(closeRemoveCommunityFromOrganizationAction());
  };
  const removeFromProgram = async () => {
    setIsLoading(true);
    try {
      if (removeCommunityFromOrganizationModal.data.removeCommunity) {
        const res = await apiLibrary.OrganizationDirectory.removeEntityFromOrganization(
          removeCommunityFromOrganizationModal.data.id,
          removeCommunityFromOrganizationModal?.data?.entity,
          parseInt(removeCommunityFromOrganizationModal?.data?.communityId)
        );
        switch (removeCommunityFromOrganizationModal?.data?.entity) {
          case "community":
            dispatch(fetchCommunitiesAction(organizationId, programId));
            break;

          default:
            break;
        }

        Toasts.success(res.message);
        handleClose();
      }
      // navigate(`/dashoard/users/list`);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(removeCommunityFromOrganizationModal.data,'sdlfdsklfklsfklsfkdlsklf');

  return (
    <Modal
      open={removeCommunityFromOrganizationModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight">
        <div className="flex items-center justify-center w-full">
          <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  {removeCommunityFromOrganizationModal?.data?.entity === "member" && (
                    <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                      Remove user from this program
                    </p>
                  )}

                  {(removeCommunityFromOrganizationModal?.data?.entity === "community" ||
                    removeCommunityFromOrganizationModal?.data?.entity ===
                      "organization") && (
                    <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                      Remove from{" "}
                      {organizationId
                        ? "Organization"
                        : communityId
                          ? "Community"
                          : programId
                            ? "Program"
                            : ""}
                    </p>
                  )}
                </div>
                <button title="close" onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5 ">
                {removeCommunityFromOrganizationModal?.data?.entity === "member" && (
                  <p className="flex-grow w-[432px] text-sm text-left text-secondaryMid dark:text-textMain">
                    {`Are you sure you want to remove `}
                    <b>{`${removeCommunityFromOrganizationModal?.data?.user?.firstName} ${removeCommunityFromOrganizationModal?.data?.user?.lastName}`}</b>
                    {` from this program?`}
                  </p>
                )}

                {removeCommunityFromOrganizationModal?.data?.entity === "community" && (
                  <p className="flex-grow w-[432px] text-sm text-left text-secondaryMid dark:text-textMain">
                    {`Are you sure you want to remove this community? This will remove `}{" "}
                    <b>{removeCommunityFromOrganizationModal?.data?.members}</b>{" "}
                    {`connected observers. This cannot be undone.`}
                  </p>
                )}

                {removeCommunityFromOrganizationModal?.data?.entity === "organization" && (
                  <p className="flex-grow w-[432px] text-sm text-left text-secondaryMid dark:text-textMain">
                    {`Are you sure? This will remove the connected organization `}
                    <b>{`${removeCommunityFromOrganizationModal?.data?.name}`}</b>
                    {`. This action cannot be undone.`}
                  </p>
                )}
              </div>
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={isLoading}
                  type="button"
                  text="Remove"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={removeFromProgram}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
