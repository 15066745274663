import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Button from "view/components/Button";
import XCloseSmallIcon from "assets/icons/HeroIcons/XCloseSmallIcon";
import apiLibrary from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { closeAddExistingMemberstocommunityModalAction } from "store/modals/reducer.actions";
import TextInput from "view/components/InputField";
import { Toasts } from "view/components/Toasts";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchUsersAction } from "store/users/reducer.actions";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { setLoadingProgress } from "store/loadingBar";
import { getBrandPlaceholder } from "utils/customBranding";
// Define the interface for SearchToAddExistingMemberProps (if required)
interface SearchToAddExistingMembersToCommunityProps { }

// Styles for the Modal component
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

// SearchToAddExistingMembersToCommunity component
export const SearchToAddExistingMembersToCommunity: React.FC<
  SearchToAddExistingMembersToCommunityProps
> = () => {
  // Redux hooks
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { addMemberstoCommunityModal } = useSelector(
    (state: RootState) => state.modals
  );

  // State hooks
  const [membersList, setMembersList] = useState<any[]>([]);
  const [connectedMembersList, setConnectedMembersList] = useState<any[]>([]);
  const [isMemberRemoved, setIsMemberRemoved] = useState<boolean>(false);
  const [searchString, setSearchString] = useState("");

  const [totalMembers, setTotalMembers] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const addExistingMembers = async () => {
    setIsLoading(true);
    try {
      const response =
        await apiLibrary.Communities.addExistingMembersToCommunity({
          communityId: addMemberstoCommunityModal?.data?.communityId,
          personIds: connectedMembersList.map((person: any) => {
            return person.id;
          }),
        });
      Toasts.success(response.message);

      handleClose();
      if (isMemberRemoved) {
        dispatch(fetchUsersAction(communityId, programId, organizationId));
      }
      dispatch(fetchUsersAction(communityId, programId, organizationId));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getMembersByCommunity = async (
    isLoadMore: boolean,
    communityId: any,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedUsers?: any[] | null
  ) => {
    setIsLoading(true);

    try {
      const { data } = await apiLibrary.Communities.getMembersByCommunity(
        communityId,
        isConnected,
        searchString,
        page
      );

      const reStructuredEntitiesList = reStructureTheEntitiesList(data?.users);

      if (isConnected) {
        // Directly updating the state when isConnected is true
        setConnectedMembersList(reStructuredEntitiesList);
      } else {
        let membersList = reStructuredEntitiesList;

        if (connectedUsers && connectedUsers.length > 0) {
          membersList = getSortedDifference(
            reStructuredEntitiesList,
            connectedUsers
          );
        }

        // Merging state update conditions to reduce re-renders
        setMembersList((prevList) =>
          isLoadMore ? [...prevList, ...membersList] : membersList
        );
      }

      // Grouped state updates for totalPages and hasMoreData
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getMembersByCommunity = async (
  //   isLoadMore: boolean,
  //   communityId: any,
  //   isConnected: boolean,
  //   page: number,
  //   searchString: string,
  //   connectedUsers?: any[] | null
  // ) => {
  //   setIsLoading(true);

  //   try {
  //     const { data } = await apiLibrary.Communities.getMembersByCommunity(
  //       communityId,
  //       isConnected,
  //       searchString,
  //       page
  //     );
  //     const reStructuredEntitiesList = reStructureTheEntitiesList(data?.users);
  //     if (isConnected === true) {
  //       setConnectedMembersList(reStructuredEntitiesList);
  //     } else {
  //       if (!isLoadMore) {
  //         if (connectedUsers && connectedUsers?.length > 0) {
  //           const membersList = getSortedDifference(
  //             reStructuredEntitiesList,
  //             connectedUsers
  //           );
  //           setMembersList(membersList);
  //         } else {
  //           setMembersList(reStructuredEntitiesList);
  //         }
  //       } else {
  //         if (connectedUsers && connectedUsers?.length > 0) {
  //           const membersList = getSortedDifference(
  //             reStructuredEntitiesList,
  //             connectedUsers
  //           );

  //           setMembersList((prevList) => [...prevList, ...membersList]);
  //         } else {
  //           setMembersList((prevList) => [
  //             ...prevList,
  //             ...reStructuredEntitiesList,
  //           ]);
  //         }
  //       }

  //       setTotalPages(data.totalPages);
  //       setHasMoreData(data.totalPages > page);
  //     }
  //   } catch (error: any) {
  //     const errorMsg = error?.response?.data?.message ?? error.message;
  //     Toasts.error(errorMsg);
  //     console.error("API call failed:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchConnectedAndExistingMembersByCommunity = async () => {
    await getMembersByCommunity(
      false,
      parseInt(addMemberstoCommunityModal?.data?.communityId),
      false,
      page,
      searchString
    );
    await getMembersByCommunity(
      false,
      parseInt(addMemberstoCommunityModal?.data?.communityId),
      true,
      page,
      searchString
    );
  };
  useEffect(() => {
    if (addMemberstoCommunityModal.isOpen) {
      // setMembersList([]);
      setPage(1);
      fetchConnectedAndExistingMembersByCommunity();
    }
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getMembersByCommunity(
        true,
        parseInt(addMemberstoCommunityModal?.data?.communityId),
        false,
        page,
        searchString,
        connectedMembersList
      );
      return page;
    });
  };

  const searchMembersByCommunity = async (
    communityId: any,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedMembers: any[]
  ) => {
    setIsLoading(true);
    try {
      const { data } = await apiLibrary.Communities.getMembersByCommunity(
        communityId,
        isConnected,
        searchString,
        page
      );

      const reStructuredEntitiesList = reStructureTheEntitiesList(data?.users);

      const searchedMembersList = getSortedDifference(
        reStructuredEntitiesList,
        connectedMembers
      );

      setMembersList(searchedMembersList);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchMembersByCommunity = async (searchString: string) => {
    setPage(1);
    await searchMembersByCommunity(
      parseInt(addMemberstoCommunityModal?.data?.communityId),
      false,
      1,
      searchString,
      connectedMembersList
    );
  };

  const debouncedSearch = _.debounce(handleSearchMembersByCommunity, 1000);

  const removeUserFromCommunity = async (user: any) => {
    dispatch(setLoadingProgress(100));
    setIsLoading(true);
    try {
      const res = await apiLibrary.Communities.removePersonFromCommunity(
        parseInt(addMemberstoCommunityModal?.data?.communityId),
        user.id
      );

      Toasts.success(res.message);
      setIsMemberRemoved(true);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const addExistingCommunity = (member: any): void => {
    setMembersList((prevMembersList) => {
      const filteredMembers = prevMembersList.filter(
        (item) => item.id !== member.id
      );

      if (filteredMembers.length === 0) {
        callApiIfMembersLengthIsZero();
      }
      return filteredMembers;
    });

    setConnectedMembersList((prevconnectedMembersList) => {
      const isCommunityAlreadyConnected = prevconnectedMembersList.some(
        (item) => item.id === member.id
      );

      if (!isCommunityAlreadyConnected) {
        const updatedList = [...prevconnectedMembersList, member];
        return updatedList;
      }

      return prevconnectedMembersList;
    });
  };

  const removeExistingMember = async (member: any) => {
    if (member.isConnected) {
      await removeUserFromCommunity(member);
      await getMembersByCommunity(
        false,
        parseInt(addMemberstoCommunityModal?.data?.communityId),
        false,
        1,
        searchString,
        connectedMembersList
      );
      setConnectedMembersList((prevconnectedMembersList) =>
        prevconnectedMembersList.filter((item) => item.id !== member.id)
      );
    }

    setMembersList((prevMembersList) => {
      const isCommunityPresent = prevMembersList.some(
        (item) => item.id === member.id
      );

      if (!isCommunityPresent) {
        const updatedMembersList = [...prevMembersList, member];
        return updatedMembersList;
      } else {
        return prevMembersList;
      }
    });

    setConnectedMembersList((prevconnectedMembersList) => {
      const updatedConnectedMembersList = prevconnectedMembersList.filter(
        (item) => item.id !== member.id
      );
      return updatedConnectedMembersList;
    });
  };

  const removeExistingMembers = async () => {
    setIsLoading(true);
    try {
      const response =
        await apiLibrary.Communities.addExistingMembersToCommunity({
          communityId: addMemberstoCommunityModal?.data?.communityId,
          personIds: connectedMembersList
            .filter((item: any) => !item.isConnected)
            .map((person: any) => {
              return person.id;
            }),
        });
      setIsMemberRemoved(true);
      Toasts.success("All Members Have Been Removed");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAllMembers = (): void => {
    const isAllMembersConnected = connectedMembersList.every(
      (member: any) => member.isConnected
    );

    if (isAllMembersConnected) {
      removeAllConnectedMembers();
    } else if (searchString !== "") {
      setMembersList((prevMembersList) => {
        const updatedMembersList = [
          ...prevMembersList,
          ...connectedMembersList.filter(
            (connectedMember) =>
              !connectedMember.isConnected &&
              connectedMember.name
                .toLowerCase()
                .includes(searchString.toLowerCase())
          ),
        ];
        return updatedMembersList;
      });

      setConnectedMembersList((pre: any[]) => {
        const updatedConnectedMembersList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedMembersList;
      });
    } else {
      setMembersList((prevMembersList) => {
        const filteredConnectedMembersList = connectedMembersList.filter(
          (connectedMember) => !connectedMember.isConnected
        );

        const updatedMembersList = [
          ...prevMembersList,
          ...filteredConnectedMembersList,
        ];

        return updatedMembersList;
      });

      setConnectedMembersList((pre: any[]) => {
        const updatedConnectedMembersList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedMembersList;
      });
    }
  };

  const removeAllConnectedMembers = async () => {
    await removeExistingMembers();
    await fetchConnectedAndExistingMembersByCommunity();
  };

  const addAllMembers = (): void => {
    setMembersList([]);
    setConnectedMembersList((prevMembersList) => {
      const updatedMembersList = [
        ...prevMembersList,
        ...membersList.filter(
          (newMember) =>
            !prevMembersList.some(
              (prevMember) => prevMember.id === newMember.id
            )
        ),
      ];
      return updatedMembersList;
    });
  };

  // Function to handle modal close
  const handleClose = () => {
    setMembersList([]);
    setConnectedMembersList([]);
    setTotalMembers(0);
    setTotalPages(1);
    setSearchString("");

    dispatch(closeAddExistingMemberstocommunityModalAction());
  };

  const callApiIfMembersLengthIsZero = () => {
    if (hasMoreData) {
      handleLoadMore();
    }
  };

  return (
    <Modal
      open={addMemberstoCommunityModal.isOpen}
      onClose={() => {
        handleClose();
        if (isMemberRemoved) {
          dispatch(fetchUsersAction(communityId, programId, organizationId));
        }
      }}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[850px] rounded-lg">
          <div
            className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg bg-bgWhite dark:bg-secondaryLight"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                  Search To Add Members to the Community
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  if (isMemberRemoved) {
                    dispatch(
                      fetchUsersAction(communityId, programId, organizationId)
                    );
                  }
                }}
              >
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <TextInput
              onChange={(e: any) => {
                setSearchString(e.target.value);
                debouncedSearch(e.target.value);
              }}
              value={searchString}
              type="Search"
              placeholder="Search among existing"
            />
            <div className="max-h-[60vh]  w-full   overflow-y-auto px-1">
              <div className="flex self-stretch justify-between flex-grow-0 flex-shrink-0 w-full gap-4 items-between">
                <div className="flex flex-col justify-start items-start w-[50%]">
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
                    <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
                      <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
                        {membersList.length} Existing Members
                      </div>
                      <button
                        onClick={() => {
                          addAllMembers();
                          callApiIfMembersLengthIsZero();
                        }}
                        className="font-semibold text-primary"
                      >
                        Add All
                      </button>
                    </div>
                  </div>

                  <div className="w-full border border-lineMid">
                    <InfiniteScroll
                      dataLength={membersList.length}
                      next={handleLoadMore}
                      hasMore={hasMoreData}
                      height="50vh"
                      className="w-full"
                      style={{ width: "100%" }}
                      loader={
                        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                          {membersList.length === 0 && (
                            <TailSpin
                              height="50"
                              width="50"
                              color="#005C89"
                              ariaLabel="tail-spin-loading"
                              radius="2"
                              wrapperStyle={{}}
                              wrapperClass="tailspin-loader"
                              visible={true}
                            />
                          )}
                        </div>
                      }
                    >
                      <div className="flex flex-col items-start justify-start w-full px-2 py-3 overflow-y-auto rounded-lg">
                        {membersList.map((member: any) => {
                          return (
                            <div
                              className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer group hover:bg-bgBluish_2"
                              onClick={() => addExistingCommunity(member)}
                            >
                              <img
                                src={
                                  member?.personImage
                                    ? member?.personImage?.thumb
                                    : getBrandPlaceholder("userProfile")
                                }
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_user_Data"
                                style={{ flexShrink: 0 }}
                              />
                              <div className="flex items-center justify-start flex-grow">
                                <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                                  <p className="w-[260px] text-left break-words dark:text-textMain dark:group-hover:text-secondary">{`${member.name}`}</p>
                                </div>
                              </div>
                              <svg
                                width={14}
                                height={12}
                                viewBox="0 0 14 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.19526 0.861888C7.93491 1.12224 7.93491 1.54435 8.19526 1.8047L11.7239 5.33329L1.33333 5.33329C0.965143 5.33329 0.666666 5.63177 0.666666 5.99996C0.666666 6.36815 0.965143 6.66663 1.33333 6.66663H11.7239L8.19526 10.1952C7.93491 10.4556 7.93491 10.8777 8.19526 11.138C8.45561 11.3984 8.87772 11.3984 9.13807 11.138L13.8047 6.47136C14.0651 6.21101 14.0651 5.78891 13.8047 5.52856L9.13807 0.861888C8.87772 0.601539 8.45561 0.601539 8.19526 0.861888Z"
                                  className="fill-secondaryMid dark:fill-caption dark:group-hover:fill-secondary"
                                />
                              </svg>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
                <ConnectedMembersList
                  connectedMembers={connectedMembersList}
                  removeMember={removeExistingMember}
                  removeAllMembers={removeAllMembers}
                  isLoading={isLoading}
                />
              </div>
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={() => {
                  handleClose();
                  if (isMemberRemoved) {
                    dispatch(
                      fetchUsersAction(communityId, programId, organizationId)
                    );
                  }
                }}
              />

              <Button
                type="button"
                disabled={isLoading}
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={addExistingMembers}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

interface ConnectedMembersListProps {
  connectedMembers: any[];
  removeMember: (member: any) => void;
  removeAllMembers: () => void;
  isLoading: boolean;
}

const ConnectedMembersList: React.FC<ConnectedMembersListProps> = ({
  connectedMembers,
  removeMember,
  removeAllMembers,
  isLoading,
}) => {
  console.log("isLoading =>", isLoading)
  console.log("connectedMembers.length =>", connectedMembers.length)
  return (
    <div className="flex flex-col justify-start items-start w-[50%] relative">
      <div className="flex flex-col w-full justify-start items-start flex-grow h-[50vh]">
        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
          <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
              {connectedMembers.length} Added
            </div>
            <button
              onClick={removeAllMembers}
              disabled={isLoading || connectedMembers.length === 0}
              className="font-semibold text-primary disabled:cursor-not-allowed"
            >
              Clear All
            </button>
          </div>
        </div>
        <div className={`flex flex-col ${connectedMembers.length === 0 ? "items-center" : "items-start"} justify-start self-stretch flex-grow w-full px-2 py-3 overflow-y-auto border rounded-lg border-lineMid`}>
          {(isLoading && connectedMembers.length === 0) ?

            <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
            : (connectedMembers.length > 0 && !isLoading) ? connectedMembers.map((member: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-start w-full gap-4 px-2 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2 group dark:hover:text-textMid"
                >
                  <img
                    src={
                      member?.personImage
                        ? member?.personImage?.thumb
                        : getBrandPlaceholder("userProfile")
                    }
                    alt=""
                    height="100"
                    width="100"
                    className="object-cover Img_user_Data"
                    style={{ flexShrink: 0 }}
                  />

                  <div className="flex items-center justify-start ">
                    <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                      <p className="w-[260px] dark:text-textMain dark:hover:text-textMid text-left break-words dark:group-hover:text-secondary">{`${member.name}`}</p>
                    </div>
                  </div>

                  <button
                    disabled={isLoading}
                    className="disabled:cursor-not-allowed"
                    onClick={() => removeMember(member)}
                  >
                    <XCloseSmallIcon width={24} height={24} className="fill-secondaryMid dark:!fill-caption dark:group-hover:!fill-secondary" />
                  </button>
                </div>
              );
            }) : null}
        </div>
      </div>
    </div>
  );
};

function reStructureTheEntitiesList(entitiesList: any): any[] {
  const reStructuredList = entitiesList.map((user: any) => {
    const { ...rest } = user;
    return {
      ...rest,
      name: `${user.firstName} ${user.lastName}`,
      profileImage: user.profileImage,
    };
  });
  console.log("reStructuredList", reStructuredList);
  return reStructuredList;
}

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  return difference;
}
