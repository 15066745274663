import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import "../../UserData.scss"

export const TableBody = ({
  table,
  columnVisibility,
  getUserActions,
  flexRender,
}: TableBodyProps) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any) => (
        <tr
          key={row.id}
          className=" Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight"
          style={{ height: "66px" }}
        >
          {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
            (cell: Cell<any, any>) => (
              <Column
                hoverEffect='item_hover_effect'
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            )
          )}
          <td className="whitespace-nowrap sticky right-0 z-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions row={row} getUserActions={getUserActions} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
