import React, { useCallback } from "react";
import { FieldArray, FormikProps } from "formik";
import AddressCreator from "./AddressCreator";
import AddAnotherAddressButton from "./components";

interface Props {
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: FormikProps<any>["handleChange"];
  handleBlur: FormikProps<any>["handleBlur"];
}

const AddressContainer: React.FC<Props> = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  const updatePrimaryAddress = useCallback(
    (index: number) => {
      const updatedData = [...values.addresses];
      const dataToMove = updatedData.splice(index, 1)[0];
      updatedData.unshift({ ...dataToMove, isPrimary: true, verified: false });
      updatedData.forEach((data, i) => {
        if (i !== 0) {
          data.isPrimary = false;
        }
      });

      setFieldValue("addresses", updatedData);
    },
    [values, setFieldValue]
  );
  const AddAnotherAddressHandler = (push: any) => {
    push({
      address: "",
      isPrimary: values.addresses.length === 0,
    });
  };
  return (
    <div className="flex xl:w-[592px] w-full  flex-col justify-center items-center flex-grow">
      <FieldArray name="addresses">
        {({ push, remove }) => (
          <div className="w-full p-0">
            {values.addresses?.map((address: any, index: number) => (
              <AddressCreator
                remove={remove}
                key={index}
                updatePrimaryAddress={updatePrimaryAddress}
                cureentAddressIndex={index}
                address={address}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
            ))}
            <AddAnotherAddressButton
              title=" Add Secondary Address"
              onClick={() => AddAnotherAddressHandler(push)}
            />
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default React.memo(AddressContainer);
