import coverImage from "assets/images/Auth/img-placeholder.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import "./Gridcard.scss";
import { IfetchObjects } from "store/objects/initialState";
import PopOverMenu from "view/components/PopOverMenu";
import { PerimeterIcon } from "assets/icons/HeroIcons";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import useActions from "hooks/useActions";
import { filtersInitialState } from "store/filters/initialState";

export const GridCard = () => {
  const { data } = useSelector<RootState, IfetchObjects>(
    (state) => state?.allObjects
  );

  const { objectsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { objectActions } = useActions();

  const getObjectsActions = (data: any) => {
    const modalData = {
      status: objectsFilter.status,
      id: data.id,
      path: "directory",
    };

    return objectActions(modalData);
  };
  // return (
  //   <>
  //     <div className="flex flex-wrap w-full gap-4">
  //       {data.objects.map((items: any, index: number) => {
  //         return (
  //           <Link
  //             to={`/object/${items.id}/profile`}
  //             key={index}
  //             className="overflow-hidden shadow-md transform hover:scale-105 transition-transform  bg-[white] rounded-lg w-full  md:w-[calc(50%-16px)] xl:w-[calc(33.3%-16px)] 2xl:w-[calc(25%-16px)]"
  //           >
  //             <div className="relative flex flex-col items-center justify-start flex-grow bg-white rounded-lg">
  //               <div className="w-full h-[136px] ">
  //                 <img
  //                   src={items.image ? items.image : coverImage}
  //                   className="object-cover w-full h-full"
  //                   alt="specieImage"
  //                 />
  //               </div>
  //               <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3 p-3">
  //                 <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
  //                   <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1 pr-2">
  //                     <p className="self-stretch flex-grow-0 flex-shrink-0 text-base font-medium text-left capitalize w-52 text-secondary">
  //                       {ellipsize(`${items.objectName.name}`, 40)}
  //                     </p>
  //                   </div>
  //                   <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
  //                     <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
  //                       <p className="flex-grow w-[216px] text-sm text-left text-textMidLight">
  //                         {ellipsize(`${items.description || ""}`, 40)}
  //                       </p>
  //                     </div>
  //                   </div>
  //                   <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
  //                     <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 p-1 rounded-lg bg-primary/[0.08]">
  //                       <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
  //                         <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid">
  //                           {ellipsize(`${items.type.name}`, 40)}
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="">
  //               {getObjectsActions(items).some(
  //                 (action: any) => action.isVisible === true
  //               ) ? (
  //                 <PopOverMenu
  //                   style={"0px"}
  //                   icon={<PerimeterIcon className={` `} />}
  //                 >
  //                   <ActionDropDownMenu
  //                     data={items}
  //                     actions={getObjectsActions(items)}
  //                   />
  //                 </PopOverMenu>
  //               ) : (
  //                 <PerimeterIcon className="" />
  //               )}
  //             </div>
  //           </Link>
  //         );
  //       })}
  //     </div>
  //   </>
  // );


  return (
    <>
      <div className="flex w-full flex-wrap gap-4">
        {data.objects.map((items: any, index: number) => {
          return (
            <div key={index} className="overflow-hidden shadow-md transform hover:scale-105 transition-transform  bg-[white] rounded-lg w-full  md:w-[calc(50%-16px)] xl:w-[calc(33.3%-16px)] 2xl:w-[calc(25%-16px)]">
              <div className="flex flex-col justify-start items-center flex-grow relative rounded-lg bg-white">
                <Link
                  to={`/object/${items.id}/profile`}
                  key={index}
                  className="w-full h-[136px]"
                >
                  <img
                    src={items.image ? items.image : coverImage}
                    className="object-cover w-full h-full"
                    alt="objectImage"
                  />
                </Link>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3 p-3">
                  <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 pr-2">
                      <p className="self-stretch flex-grow-0 flex-shrink-0 w-52 text-base font-medium text-left capitalize text-secondary">
                        {ellipsize(`${items.objectName.name}`, 40)}
                      </p>
                    </div>
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
                        <p className="flex-grow w-[216px] text-sm text-left text-textMidLight">
                        {ellipsize(`${items.description || ""}`, 40)}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1">
                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 p-1 rounded-lg bg-primary/[0.08]">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-1">
                          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid">
                            {ellipsize(`${items.type.name}`, 40)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {getObjectsActions(items).some(
                      (action: any) => action.isVisible === true
                    ) ? (
                      <PopOverMenu
                        style={"0px"}
                        icon={<PerimeterIcon className={` `} />}
                      >
                        <ActionDropDownMenu
                          data={items}
                          actions={getObjectsActions(items)}
                        />
                      </PopOverMenu>
                    ) : (
                      <PerimeterIcon className="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
