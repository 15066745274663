import apiClient from "services/apiClient";

async function getObjectsDropdown(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
    limit: 100000,
  };

  const res = await apiClient.get(`/admin/objects/dropdown`, { params });
  return res.data;
}

export default getObjectsDropdown;
