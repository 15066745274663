import { FormikValues } from "formik";

type ConditionResult = {
    questionName: string;
    operator: string;
    value: string | string[] | number | Date | null;
};

// Parse the condition string into individual conditions and operators
export function parseCondition(element: any, panels?: any): ConditionResult[] {
    const condition = element?.visibleIf;
    if (!condition) return [];

    // Split the condition based on "and" or "or"
    const conditions = condition.split(/\s+(and|or)\s+/);
    const parsedConditions: ConditionResult[] = [];

    // Loop through the conditions and parse each one
    for (let i = 0; i < conditions.length; i++) {
        let questionName: string | null = null;
        let operator = '';
        let value: string | string[] | number | Date | null = null;
        const currentCondition = conditions[i];

        // Handle dynamic panel questions (e.g., question4[0].question5)
        if (/\{.*?\[\d+\]\..*?\}/.test(currentCondition)) {
            const dynamicPanelMatch = currentCondition.match(/\{(.*?)\[(\d+)\]\.(.*?)\}/);
            if (dynamicPanelMatch) {
                const panelName = dynamicPanelMatch[1];
                const subQuestionName = dynamicPanelMatch[3];

                const groupQuestionName = panels
                    ?.find((panel: any) => panel.name === panelName)
                    ?.templateElements?.find((element: any) => element.name === subQuestionName)
                    ?.groupQuestionName;

                questionName = groupQuestionName;
            }
        } else {
            // Handle simple question extraction
            const questionMatch = currentCondition.match(/\{(.*?)\}/);
            questionName = questionMatch ? questionMatch[1] : null;
        }

        if (!questionName) continue;

        // Determine the operator and value
        switch (true) {
            case /empty$/.test(currentCondition):
                operator = 'empty';
                value = null;
                break;

            case /notempty$/.test(currentCondition):
                operator = 'notempty';
                value = null;
                break;

            case /=/.test(currentCondition):
                operator = '=';
                value = extractValue(currentCondition);
                break;

            case /<>/.test(currentCondition):
                operator = '<>';
                value = extractValue(currentCondition);
                break;

            case /anyof/.test(currentCondition):
                operator = 'anyof';
                value = extractArray(currentCondition);
                break;

            case />=/.test(currentCondition):
                operator = '>=';
                value = extractValue(currentCondition);
                break;

            case /<=/.test(currentCondition):
                operator = '<=';
                value = extractValue(currentCondition);
                break;

            case />/.test(currentCondition):
                operator = '>';
                value = extractValue(currentCondition);
                break;

            case /</.test(currentCondition):
                operator = '<';
                value = extractValue(currentCondition);
                break;

            default:
                continue;
        }

        parsedConditions.push({ questionName, operator, value });
    }

    return parsedConditions;
}

// Helper function to extract single value from quotes or numbers or dates
function extractValue(condition: string): string | number | Date | null {
    const quotedMatch = condition.match(/'(.*?)'/);
    if (quotedMatch) {
        const value = quotedMatch[1];

        // Check if the value looks like a valid ISO 8601 date
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2}(?:\.\d{1,3})?)?(?:Z|[+-]\d{2}:\d{2})?$/;
        if (isoDateRegex.test(value)) {
            const dateValue = new Date(value);
            if (!isNaN(dateValue.getTime())) {
                return dateValue; // Return the Date object if it's a valid ISO date
            }
        }

        return value; // Return the quoted string if it's not a date
    }

    const numberMatch = condition.match(/(>|<|>=|<=|=)\s*(\d+)/);
    if (numberMatch) {
        return parseFloat(numberMatch[2]);
    }

    return null;
}

// Helper function to extract array of values inside []
function extractArray(condition: string): string[] {
    const arrayMatch = condition.match(/\[(.*?)\]/);
    return arrayMatch ? arrayMatch[1].split(',').map(v => v.trim().replace(/'/g, '')) : [];
}

// Function to evaluate single or multiple conditions, including date comparisons
export function evaluateCondition(
    formikVal: FormikValues,
    parsedConditions: ConditionResult[],
    logicalOperator: string = 'and'
): boolean {
    let result = logicalOperator === 'and' ? true : false;

    for (const condition of parsedConditions) {
        const { questionName, operator, value } = condition;

        // Iterate over each panel in formikValues
        for (const panelKey in formikVal) {
            if (formikVal.hasOwnProperty(panelKey)) {
                const panel = formikVal[panelKey];
                const matchedKey = Object.keys(panel).find((key) => key === questionName);
                if (matchedKey) {
                    let matchedValue = panel[matchedKey];

                    // Handle arrays and objects
                    if (Array.isArray(matchedValue)) {
                        matchedValue = matchedValue.map((item) =>
                            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
                        );
                    }

                    if (typeof matchedValue === 'object' && matchedValue !== null && 'value' in matchedValue) {
                        matchedValue = matchedValue.value;
                    }

                    // Handle date comparisons
                    if (matchedValue instanceof Date && value instanceof Date) {
                        switch (operator) {
                            case '=':
                                result = matchedValue.getTime() === value.getTime();
                                break;
                            case '<>': // Not equals
                                result = matchedValue.getTime() !== value.getTime();
                                break;
                            case '>':
                                result = matchedValue.getTime() > value.getTime();
                                break;
                            case '<':
                                result = matchedValue.getTime() < value.getTime();
                                break;
                            case '>=':
                                result = matchedValue.getTime() >= value.getTime();
                                break;
                            case '<=':
                                result = matchedValue.getTime() <= value.getTime();
                                break;
                        }
                    } else {
                        // Handle non-date comparisons
                        let conditionResult = false;
                        switch (operator) {
                            case '=':
                                conditionResult = Array.isArray(matchedValue)
                                    ? matchedValue.includes(value)
                                    : matchedValue === value;
                                break;
                            case '<>': // Not equals
                                conditionResult = Array.isArray(matchedValue)
                                    ? !matchedValue.includes(value)
                                    : matchedValue !== value;
                                break;
                            case '>':
                                conditionResult = value !== null && matchedValue > value;
                                break;
                            case '<':
                                conditionResult = value !== null && matchedValue < value;
                                break;
                            case '>=':
                                conditionResult = value !== null && matchedValue >= value;
                                break;
                            case '<=':
                                conditionResult = value !== null && matchedValue <= value;
                                break;
                            case 'empty':
                                conditionResult = matchedValue === null || matchedValue === '';
                                break;
                            case 'notempty':
                                conditionResult = matchedValue !== null && matchedValue !== '';
                                break;
                            case 'anyof':
                                conditionResult = Array.isArray(value) ? value.includes(matchedValue) : false;
                                break;
                            default:
                                conditionResult = false;
                        }

                        // Combine results based on the logical operator (and/or)
                        if (logicalOperator === 'and') {
                            result = result && conditionResult;
                        } else if (logicalOperator === 'or') {
                            result = result || conditionResult;
                        }
                    }

                    break;
                }
            }
        }
    }

    return result;
}
