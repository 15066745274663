import apiClient from "services/apiClient";

async function addReportExporting(reportId: number, data: any) {
  const url = `/admin/reports/${reportId}/export-permission`;

  const res = await apiClient.put(url, data);

  return res.data;
}

export default addReportExporting;
