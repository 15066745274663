import { ERROR_LOADING_IMPORTS, GET_ALL_ADMINS, GET_ALL_IMPORTS, RESET_DATA, START_LOADING_IMPORTS, STOP_LOADING_IMPORTS } from "./action-types";
import initialState, { Admin, Import } from "./initialState";
export const importsReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case START_LOADING_IMPORTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_IMPORTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_IMPORTS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_IMPORTS:
      const imports = action.payload.imports.map((item: Import) => {
        const {
          id,
          fileName,
          dataReceivedFrom,
          numberOfObservations,
          importedBy,
          importedDate,
        } = item;

        const data = {
          id: id,
          fileName: {
            id,
            fileName
          },
          dataReceivedFrom,
          numberOfObservations,
          importedBy,
          importedDate,
        };

        return data;
      });

      return {
        ...state,
        data: {
          imports: imports,
          fields: action.payload.fields,
          admins: [],
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };


    case GET_ALL_ADMINS:
      const admins = action.payload.admins?.map((item: Admin) => {
        const {
          id,
          name,
          personId,
          personImage,
          programs,
          date
        } = item;

        const data = {
          id: personId,
          personId,
          name: {
            name, personId, personImage
          },
          personImage,
          programs,
          date,
        };

        return data;
      });


      return {
        ...state,
        data: {
          imports: [],
          fields: [],
          admins: admins,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
