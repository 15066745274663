import { apiClient } from "../config";

async function getAllFiles(
  moduleId: any,
  moduleName: string,
  searchString?: string,
  sortBy?: string,
  sortType?: string
) {
  const url = "/admin/file";

  const params = {
    id: moduleId,
    module: moduleName,
    searchString,
    sortBy,
    sortType,
  };

  const res = await apiClient.get(url, { params });

  return res.data;
}

export default getAllFiles;
