import useDocumentTitle from "hooks/useDocumentTitle";
import { PageHeadingProps } from "../../types";
import Head from "view/components/Head";

const PageHeading = ({ programId, name, parentName }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head
        title={
          parentName +
          " " +
          (programId !== "" ? `${name}: Polls & Surveys` : "Polls & Surveys")
        }
      />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {programId !== "" ? <>{name}: Polls & Surveys </> : "Polls & Surveys"}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
