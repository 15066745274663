import React, { useLayoutEffect, useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import "./Programs.scss";
import apiLibrary from "services/api";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import { communitiesTypeAction, observationsProgramsAction, reportsProfileStatusAction, reportsStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import { filtersInitialState } from "store/filters/initialState";
import { sentenceCase } from "change-case";

interface StatusesProps {
  data: any;
}
export const Statuses:React.FC<StatusesProps> = ({data}) => {
  const dispatch = useDispatch();
  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(reportsProfileStatusAction(value));
    } else {
      dispatch(reportsProfileStatusAction(""));
    }
  };
  const status = ["processing", "ready", "in_queue"];
  return (
    <div className="bg-bgWhite dark:bg-bgtetriary max-h-[490px] w-[315px] overflow-hidden shadow-xl rounded-xl flex flex-col justify-start items-start relative overflow-y-auto border border-lineMid dark:border-lineLight">
      {status.map((label, index) => (
        <div key={index} className="flex items-center justify-start rounded-lg">
          <div className="flex items-center justify-start">
            <div className="relative flex items-center justify-start">
              <BpCheckbox
                checked={data?.value === label}
                onChange={handleCheckboxChange}
                value={label.trim()}
              />
              <p className="ml-2 dark:text-caption capitalize">{sentenceCase(label)}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
