import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import { switchToLocalTimeZoneAction } from "store/observationProfile/reducer.actions";
import useDateFormatter from "hooks/formatDateWithPattern";
import moment from "moment";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";

export const ObserverTimeZoneAccordionToggle = () => {
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const convertToObserverTimeZone = (sumbissionDateTime: string) => {
    const userTimeZone = profileSettings.timezone;
    // Define the current timezone either from the submission date or the system's timezone
    const currentTimeZone = userTimeZone
      ? userTimeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Define the observer's timezone from the observation profile
    const observerTimeZone = observationProfile.observerTimezone;

    // Check if we should switch to the observer's timezone
    const switchToObserverTimeZone =
      observationProfile.useLocalTimeZone;

    // Format the date-time according to the specified timezone
    const formattedDateTime = switchToObserverTimeZone
      ? moment
        .tz(sumbissionDateTime, currentTimeZone)
        .tz(observerTimeZone)
        .format("MM/DD/YYYY [at] hh:mm A")
      : moment
        .utc(sumbissionDateTime)
        .tz(currentTimeZone)
        .format("MM/DD/YYYY [at] hh:mm A");

    return formattedDateTime;
  };

  const updateObservationTimeZonePreference = async (checked: boolean) => {
    const body = { useLocalTimeZone: checked };

    try {
      const response = await apiLibrary.Observations.updateObservationTimeZoneToggle(observationProfile.id, body);

      const { data, status } = response;

      // Handle success for 2xx status codes
      if (status >= 200 && status < 300 && data?.message) {
        dispatch(switchToLocalTimeZoneAction(checked));
        Toasts.success(data.message);
      } else {
        // Generic fallback message for unexpected responses
        throw new Error("Something went wrong. Please try again.");
      }

    } catch (error: any) {
      const errMsg = error?.response?.data?.message || error.message || "An unexpected error occurred.";
      Toasts.error(errMsg);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start">
      <CustomizedSwitches
        label={observationProfile.observerTimezone ? `Observer’s time zone (${observationProfile.observerTimezone})` : "Observer’s time zone"}
        value={observationProfile.useLocalTimeZone}
        onChange={(e: any) => {
          updateObservationTimeZonePreference(e.target.checked);
        }}
        checked={observationProfile.useLocalTimeZone}
        labelClassName="text-secondary !m-0 font-Overpass"
        sx={{
          "&.MuiFormControlLabel-root": {
            margin: 0
          }
        }}
      />

      <p className="font-Overpass text-[15px] text-secondary ml-2">{observationProfile.useLocalTimeZone ? "Submission Local Date/Time" : "Submission Date/Time"}: {observationProfile?.submissionLocalDateTime ? convertToObserverTimeZone(observationProfile?.submissionLocalDateTime) : ""}</p>
    </div>
  );
};
