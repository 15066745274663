import { TableHeadProps } from "../../types";
import { ColumnHeading, TableHeadActions } from "../index";
import ButtonBase from "@mui/material/ButtonBase";

import fileDownloadIcon from "assets/images/file-download.png";

export const TableHead = ({
  table,
  sorting,
  flexRender,
  DirectoryActions,
  handleClickOnColumnHead,
}: TableHeadProps) => {
  return (
    <thead className="table_row_header w-full sticky top-0 h-[48px] z-20  dark:bg-bgtetriary">
      <tr className="w-full cursor-pointer dark:border-none" style={{ height: "49px" }}>
        {table.getHeaderGroups().map((headerGroup: any) => {
          return (
            <ColumnHeading
              headerGroup={headerGroup}
              sorting={sorting}
              flexRender={flexRender}
              handleClickOnHead={handleClickOnColumnHead}
            />
          );
        })}

        <th className="whitespace-nowrap sticky right-0 dark:bg-bgtetriary z-[99]">
          <TableHeadActions DirectoryActions={DirectoryActions} />
          {/* <div className="flex items-center justify-center w-full h-full 2xl:pr-[12px] pr-6">
            <ButtonBase
              style={{ borderRadius: "100% ",padding:"5px" }}
              // @ts-ignore
              onClick={DirectoryActions[0]?.onClick}
            >
              <img
                src={fileDownloadIcon}
                className=" w-[23px]"
                title="Export Details"
                alt="Export Details"
              />
            </ButtonBase>
          </div> */}
        </th>
      </tr>
    </thead>
  );
};
