import { HeaderBarProps } from "../../types";
import MapBtn from "../MapBtn";
import SearchBar from "../SearchBar";

const HeaderBar = ({ csvData }: HeaderBarProps) => {
  return (
    <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid">
            {csvData.length} Items
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
