export interface INotificationsStatus {
  notificationsDate: string;
  status: string
}

export interface NotificationsStatusData {
  reminders: INotificationsStatus[];
  fields: string[];
  totalItems: number;
}

export interface IfetchNotificationsStatus {
  data: NotificationsStatusData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialNotificationRecipientsState: IfetchNotificationsStatus = {
  data: {
    reminders: [],
    fields: [],
    totalItems: 0,
    },
  message: "",
  stateIs: "Idle",
};

export default initialNotificationRecipientsState;
