import React, { useEffect, useState } from "react";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import ObservationColumnModal from "view/components/Modals/Observations/ObservationsColumnModal";
import { IfetchObservations } from "store/observations/initialState";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import FiltersList from "../FiltersList";
import SearchBar from "./components/SearchBar";
import MapBtn from "./components/MapBtn";
import ListBtn from "./components/ListBtn";
import { reportsVersionsViewtypeActions } from "store/filters/reducer.actions";
import { useDispatch } from "react-redux";
import AppliedFilters, {
  isAnyReportsVersionFilterApplied,
} from "../AppliedFilters";
import FiltersBtn from "./components/FiltersBtn";
import { filtersInitialState } from "store/filters/initialState";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
  csvData,
}) => {
  const dispatch = useDispatch();
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  // Getting programsFilter state from Redux store
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyReportsVersionFilterApplied(filtersData.filters);
      } else {
        return isAnyReportsVersionFilterApplied(reportsVersionFilter);
      }
    });
  }, []);

  return (
    <>
      <div className="flex items-center justify-between w-full py-2 ">
        <div className="flex items-center flex-1">
          <HeaderBar csvData={csvData} />
          <div className="ml-[50px]"></div>
        </div>
        <div className="flex-1">
          <div className="flex items-center justify-end flex-grow h-8 gap-2">
            <div className="flex items-center justify-end flex-grow h-8 gap-2">
              <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                <SearchBar />
                <div className="relative flex-grow-0 flex-shrink-0 h-6 border border-b-0 rotate-y-180 border-lineMid"></div>
                <ListBtn />
                <MapBtn />
                <button>
                  <FiltersBtn
                    handleToggleDropdown={handleToggleDropdown}
                    isCloseDiv={isCloseDiv}
                  />
                </button>
              </div>
              <div className="relative flex items-center justify-between flex-grow-0 flex-shrink-0 gap-2 ">
                <div className="relative flex-grow-0 flex-shrink-0 h-6 border border-b-0 rotate-y-180 border-lineMid"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      <div>
        <AppliedFilters />
      </div>
    </>
  );
};
