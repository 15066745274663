import * as React from 'react';

export const AudioCircleIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='12.5' cy='12.5' r='12.5' fill='#005C89' />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17.5725 16.1429V10.8095L13.763 7H9.9535C9.54936 7 9.16177 7.16054 8.876 7.44631C8.59023 7.73208 8.42969 8.11967 8.42969 8.52381V16.1429C8.42969 16.547 8.59023 16.9346 8.876 17.2204C9.16177 17.5061 9.54936 17.6667 9.9535 17.6667H16.0487C16.4529 17.6667 16.8405 17.5061 17.1262 17.2204C17.412 16.9346 17.5725 16.547 17.5725 16.1429Z'
      stroke='white'
      stroke-width='0.7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M13.7656 7V9.28571C13.7656 9.68985 13.9262 10.0774 14.2119 10.3632C14.4977 10.649 14.8853 10.8095 15.2894 10.8095H17.5751'
      stroke='white'
      stroke-width='0.7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M11.8557 15.7621C12.2764 15.7621 12.6176 15.421 12.6176 15.0002C12.6176 14.5794 12.2764 14.2383 11.8557 14.2383C11.4349 14.2383 11.0938 14.5794 11.0938 15.0002C11.0938 15.421 11.4349 15.7621 11.8557 15.7621Z'
      stroke='white'
      stroke-width='0.7'
    />
    <path
      d='M12.6172 15.0009V11.1914L14.141 12.7152'
      stroke='white'
      stroke-width='0.7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
export default AudioCircleIcon;
