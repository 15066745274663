import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useGetCurrentQuestionState = (questionId:any) => {
    const {dropPins} = useSelector((state:RootState)=>state.surveyJS);
    const [currentQuestion,setCurrentQuestion] = useState();

    useEffect(()=>{
        const currentQuestion = dropPins.find((s:any)=>s.id == questionId);
        setCurrentQuestion(currentQuestion);
        
      },[questionId,dropPins])

    return currentQuestion;
}

export { useGetCurrentQuestionState};