import {
  ERROR_LOADING_MARKER_GROUPS,
  GET_ALL_MARKER_GROUPS,
  RESET_DATA,
  START_LOADING_MARKER_GROUPS,
  STOP_LOADING_MARKER_GROUPS,
} from "./action-types";
import initialState from "./initialState";

export const markerGroupsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_MARKER_GROUPS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_MARKER_GROUPS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_MARKER_GROUPS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_MARKER_GROUPS:
      const markerGroups = action.payload.markerGroups.map((item: any) => {
        const { name, id } = item;
        const data = {
          id: id,
          name: {
            name: name,
            id: id,
          },
        };
        return data;
      });

      return {
        ...state,
        data: {
          markerGroups: markerGroups,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
