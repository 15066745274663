import persistReducer from "redux-persist/es/persistReducer";
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  resetAuthData,
  updateAuthData,
  updatePermissions,
} from "./action-types";
import storage from "redux-persist/lib/storage";
import { initialAuthState } from "./initialState";

const authReducer = (state = initialAuthState, action: any): any => {
  switch (action.type) {
    case loginRequest:
      return {
        ...state,
        loading: true,
      };
    case loginSuccess:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        error: null,
        ...action.payload,
      };

    case updatePermissions:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        error: null,
        permissions: action.payload,
      };
    case loginFailure:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.error,
        accessToken: "",
        refreshToken: "",
        email: "",
        id: 0,
      };
    case resetAuthData:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: null,
        accessToken: "",
        refreshToken: "",
        email: "",
        id: 0,
        autoGeneratePass: false,
      };

    case updateAuthData:
      return {
        ...state,
        person: action.payload,
      };
    default:
      return state;
  }
};

// Configuration for Redux Persist
const persistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["loggedIn"], // Specify the keys you want to persist
};

// Create a persisted reducer
const persistedAuthReducer = persistReducer(persistConfig, authReducer);
export default persistedAuthReducer;
