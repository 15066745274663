import { apiClient } from "../config";

async function extendsReviewerDate(id: any, access_for: string) {
  const url = `/admin/observations/reviewer-access-for`
  const res = await apiClient.put(url, {
    id,
    access_for: access_for.replace(" ", "-")
  }
  );
  return res.data;
}

export default extendsReviewerDate;
