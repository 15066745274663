import questionTypes from "../questionTypes";
import { convertJsonOfUpComingGetGpsData } from "./convertJsonOfUpComingGetGpsData";

import {
  convertJsonOfUpComingDrawPolygon,
  convertJsonOfUpComingDropPin,
  convertJsonOfUpComingPointPicker,
  convertJsonOfUpComingShapePicker,
} from "./index";

function convertUpComingQuestion(ele: any) {
  switch (ele?.type) {
    case questionTypes.shapePicker:
      return convertJsonOfUpComingShapePicker(ele);

    case questionTypes.pointPicker:
      return convertJsonOfUpComingPointPicker(ele);

    case questionTypes.drawPolygon:
      return convertJsonOfUpComingDrawPolygon(ele);

    case questionTypes.dropPin:
      return convertJsonOfUpComingDropPin(ele);
    case questionTypes.getGpsData:
      return convertJsonOfUpComingGetGpsData(ele);

    default:
      if (ele.maskType && ele.maskSettings) {
        return {
          maskType: ele.maskType,
          maskSettings: ele.maskSettings,
          ...ele,
        };
      }
      return ele;
  }
}

export { convertUpComingQuestion };
