export interface IJoinRequests {
  id: number;
  name: string;
  image: {
    file: string;
    id: number;
    path: string;
  };
  lastActive: string;
  memberSince: string;
  status: string;
  requestedOn: string;
  declinedOn: string;
  observations: number;
}

export interface IJoinRequestData {
  requests: IJoinRequests[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchJoinRequest {
  data: IJoinRequestData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchJoinRequest = {
  data: {
    requests: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
