import { ManageLayersPropertiesModal } from "view/components/Modals/Forms/ManageLayersPropertiesModal";
import { SearchableDropdown } from "../../../index";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { useState } from "react";
import CustomToolTip from "view/components/ToolTip";
import { IconButton } from "@mui/material";

interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;  
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
type SelectLayersProps = {
  handleLayerOnRemove: (option: OptionType) => void;
  selectedOptions: any;
  options: any;
  handleSelectorOnChange: (option: OptionType) => void;
};
type SelectedDefaultLayersProps = {
  selectedOptions: OptionType[];
  onRemoveSelection: (option: OptionType) => void;
  handleSelectorOnChange: (option: OptionType) => void;
};
type LayerItemProps = {
  option: OptionType;
  onDelete: () => void;
  onAddSelection: (option: OptionType) => void;
};

const SelectLayers = ({
  handleSelectorOnChange,
  handleLayerOnRemove,
  selectedOptions,
  options,
}: SelectLayersProps) => {
  return (
    <div>
      <h2 className="mb-2 text-secondaryMidLight">Select Layers </h2>
      <SearchableDropdown
        options={options}
        onAddSelection={handleSelectorOnChange}
      />
      <SelectedDefaultLayers
        selectedOptions={selectedOptions}
        onRemoveSelection={handleLayerOnRemove}
        handleSelectorOnChange={handleSelectorOnChange}
      />
    </div>
  );
};

const SelectedDefaultLayers = ({
  selectedOptions = [],
  onRemoveSelection,
  handleSelectorOnChange,
}: SelectedDefaultLayersProps) => {
  return (
    <div className="mt-2 overflow-hidden shadow bg-bgWhite">
      <ul className="divide-y divide-lineLightExtra">
        {selectedOptions.map((option, index) => (
          <LayerItem
            key={index}
            option={option}
            onDelete={() => onRemoveSelection(option)}
            onAddSelection={handleSelectorOnChange}
          />
        ))}
      </ul>
    </div>
  );
};

const LayerItem = ({ option, onDelete, onAddSelection }: LayerItemProps) => {
  const [isMoDalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <>
      <li className="flex items-center justify-between p-2 hover:bg-gray-100">
        <div className="flex items-center">
          <span className="font-medium text-gray-700">{option.name}</span>
        </div>
        <div className="flex justify-end ">
          <CustomToolTip
            title="Select Attributes for this layer."
            showTooltip={showTooltip}
            toggleTooltip={toggleTooltip}
          >
            <IconButton
              disabled={!option.uniqueProps}
              onClick={() => setIsModalOpen(true)}
            >
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.75 11.25L18.75 13.25V18C18.75 18.55 18.5543 19.021 18.163 19.413C17.7717 19.805 17.3007 20.0007 16.75 20H2.75C2.2 20 1.72933 19.8043 1.338 19.413C0.946667 19.0217 0.750667 18.5507 0.75 18V4C0.75 3.45 0.946 2.97933 1.338 2.588C1.73 2.19667 2.20067 2.00067 2.75 2H8.25C8.11667 2.3 8.01667 2.621 7.95 2.963C7.88333 3.305 7.83333 3.65067 7.8 4H2.75V18H16.75V11.25ZM18.05 6.9L21.25 10.1L19.85 11.5L16.65 8.3C16.3 8.5 15.925 8.66667 15.525 8.8C15.125 8.93333 14.7 9 14.25 9C13 9 11.9377 8.56233 11.063 7.687C10.1883 6.81167 9.75067 5.74933 9.75 4.5C9.74933 3.25067 10.187 2.18833 11.063 1.313C11.939 0.437667 13.0013 0 14.25 0C15.4987 0 16.5613 0.437667 17.438 1.313C18.3147 2.18833 18.752 3.25067 18.75 4.5C18.75 4.95 18.6833 5.375 18.55 5.775C18.4167 6.175 18.25 6.55 18.05 6.9ZM14.25 7C14.95 7 15.5417 6.75833 16.025 6.275C16.5083 5.79167 16.75 5.2 16.75 4.5C16.75 3.8 16.5083 3.20833 16.025 2.725C15.5417 2.24167 14.95 2 14.25 2C13.55 2 12.9583 2.24167 12.475 2.725C11.9917 3.20833 11.75 3.8 11.75 4.5C11.75 5.2 11.9917 5.79167 12.475 6.275C12.9583 6.75833 13.55 7 14.25 7ZM2.75 11.25V18V4V11V10.7V11.25Z"
                  fill={isMoDalOpen || showTooltip ? `#F25757` : "#C7C7C7"}
                />
              </svg>
            </IconButton>
          </CustomToolTip>

          <button
            onClick={onDelete}
            className="text-gray-400 hover:text-gray-600"
          >
            <TrashIcon fill="#6F747B" />
          </button>
        </div>
      </li>
      {isMoDalOpen && (
        <ManageLayersPropertiesModal
          isMoDalOpen={isMoDalOpen}
          setIsModalOpen={setIsModalOpen}
          option={option}
          onAddSelection={onAddSelection}
        />
      )}
    </>
  );
};

export { SelectLayers };
