import { useSelector } from "react-redux";
import { HeaderBarProps } from "../../types";
import ColumnBtn from "../ColumnBtn";
import FiltersBtn from "../FiltersBtn";
import { RootState } from "store";
import GridBtn from "../GridBtn";
import ListBtn from "../ListBtn";
import { filtersInitialState } from "store/filters/initialState";

const HeaderBar = ({
  data,
  isOpen,
  setIsOpen,
  handleToggleDropdown,
  isCloseDiv
}: HeaderBarProps) => {
  const { observationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  return (
    <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-caption">
            {data?.totalItems} Items
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
