interface VersionData {
  version: string;
}

export async function getVersionAsync(
  url: string = "/static/version.json"
): Promise<string | null> {
  try {
    const response = await fetch(url, {
      headers: {
        "Cache-Control": "no-cache", 
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const jsonData: VersionData = await response.json();
    return jsonData.version;
  } catch (error) {
    console.error("Failed to fetch version file:", error);
    return null;
  }
}
