import apiClient from "services/apiClient";

async function updateConversationStatus(id: string, queryParams: any) {
  const res = await apiClient.put(`/admin/conversations/${id}/archive`, null, {
    params: queryParams,
  },);
  return res.data;
}

export default updateConversationStatus;
