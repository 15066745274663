import { apiClient } from "../config";

async function addExistingSpeciesToCommunity(id: any, data: any) {
  const res = await apiClient.post(
    `/admin/communities/${id}/species
  `,
    data
  );
  return res.data;
}

export default addExistingSpeciesToCommunity;
