import getUserProfile from "./getUserProfile";
import verifyEmailOrPhone from "./verifyEmailOrPhone";
import updateContactPrefrences from "./updateContactPrefrences";
import changePrimaryEmailOrPhone from "./changePrimaryEmailOrPhone";
import deleteSecondaryEmailOrPhone from "./deleteSecondaryEmailOrPhone";
import confirmEmailOrPhone from "./confirmEmailOrPhone";
import updateUserProfile from "./updateUserProfile";
import getAllUsers from "./getAllsuers";
import addPerson from "./addPerson";
import getUsersFilterDropdowns from "./getUsersFilterDropdowns";
import deletePerson from "./deletePerson";
import deleteAccountRequest from "./deleteAccountRequest";
import inviteUser from "./inviteUser";
import changeUserStatus from "./changeUserStatus";
import assignRoles from "./assignRoles";
import deleteAddress from "./deleteAddress"
import restorePerson from "./restorePerson";
const userProfile = {
  getUserProfile,
  verifyEmailOrPhone,
  updateContactPrefrences,
  changePrimaryEmailOrPhone,
  deleteSecondaryEmailOrPhone,
  confirmEmailOrPhone,
  updateUserProfile,
  getAllUsers,
  addPerson,
  getUsersFilterDropdowns,
  deletePerson,
  inviteUser,
  changeUserStatus,
  assignRoles,
  deleteAccountRequest,
  deleteAddress,
  restorePerson
};

export default userProfile;