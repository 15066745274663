import {panelsTitle,panelsName} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { SIGHTING_DETAILS_QUESTIONS_NAME } from "../panelsQuestionsName";
import { SIGHTING_DETAILS_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

import { Serializer } from "survey-core";


export default {
  name: panelsName.SIGHTING_DETAIL,
  title: panelsTitle.SIGHTING_DETAIL,
  iconName: "icon-sighting-details",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.SIGHTING_DETAIL}</h1>`,
    },
    {
      type: "radiogroup",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.CoordinatesFormat,
      title: "Coordinates Format",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.CoordinatesFormat,
      isRequired: true,
    },
    {
      type: "html",
      html: `<div>
     
      <button class="get-gps-data-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13604 4.63604C9.65076 1.12132 15.3492 1.12132 18.864 4.63604C22.3787 8.15076 22.3787 13.8492 18.864 17.364L14.6209 21.607C13.4496 22.7783 11.5514 22.7793 10.3795 21.6074L6.13604 17.364C2.62132 13.8492 2.62132 8.15076 6.13604 4.63604ZM12.5 14C14.1569 14 15.5 12.6569 15.5 11C15.5 9.34315 14.1569 8 12.5 8C10.8431 8 9.5 9.34315 9.5 11C9.5 12.6569 10.8431 14 12.5 14Z" fill="white"/>
      </svg>Get GPS data</button>
      </div>`,
    },
    {
      type: "text",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.Latitude,
      title: "Latitude",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "text",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.Longitude,
      title: "Longitude",
      placeholder: "Type",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "html",
      html: `<div>
      <button class="pick-on-map-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_2167_236744)">
        <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 2V4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 20V22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 12H22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 12H4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2167_236744">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>Pick on Map</button>
      
      </div>`,
      startWithNewLine: false,
    },
    {
      type: "dropdown",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.ObservationMethod,
      title: "Observation Method",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.ObservationMethod,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.ObservationDirection,
      title: "Observation Direction",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.ObservationDirection,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.DistanceUnits,
      title: "Distance Units",
      placeholder: "Meters",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.DistanceUnits,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.MinimumDistance,
      title: "Minimum Distance",
      placeholder: "1",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.MaximumDistance,
      title: "Maximum Distance",
      placeholder: "1000",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.DistanceMethod,
      title: "Distance Method",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.DistanceMethod,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SIGHTING_DETAILS_QUESTIONS_NAME.Visibility,
      title: "Visibility",
      choices: SIGHTING_DETAILS_QUESTIONS_CHOICES.Visibility,
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.SIGHTING_DETAIL, SIGHTING_DETAILS_QUESTIONS_NAME, Serializer),
};

export const sightingIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.44772 4 4 4.44772 4 5V14.1707C4.31278 14.0602 4.64936 14 5 14H11.5C11.7652 14 12.0196 14.1054 12.2071 14.2929L12.9142 15H19.382L17.1056 10.4472C16.9648 10.1657 16.9648 9.83431 17.1056 9.55279L19.382 5H13V9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9V4H5ZM12.9142 3H21C21.3466 3 21.6684 3.17945 21.8507 3.47427C22.0329 3.76909 22.0494 4.13723 21.8944 4.44721L19.118 10L21.8944 15.5528C22.0494 15.8628 22.0329 16.2309 21.8507 16.5257C21.6684 16.8205 21.3466 17 21 17H12.5C12.2348 17 11.9804 16.8946 11.7929 16.7071L11.0858 16H5C4.44772 16 4 16.4477 4 17V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V5C2 3.34315 3.34315 2 5 2H11.5C11.7652 2 12.0196 2.10536 12.2071 2.29289L12.7066 2.79236C12.7069 2.79272 12.7073 2.79307 12.7076 2.79342L12.9142 3Z" fill="#909090"/>
</svg>`;
