
export interface PollAndSurveyResultsData {
  questions: string[];
  responses: any[];
  id: number;
  name: string;
  createdBy: string;
  createdOn: string;
  totalResponses: number;
  totalPages: number;
}

export interface fetchPollAndSurveyResults {
  data: PollAndSurveyResultsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialpollAndSurveyResults: fetchPollAndSurveyResults = {
  data: {
    questions: [],
    responses: [],
    id: 0,
    name: "",
    createdBy: "",
    createdOn: "",
    totalResponses: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialpollAndSurveyResults;
