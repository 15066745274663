import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import React, { useState } from "react";
import NestedDropdown from "view/components/NestedDropdown";
import { useDispatch } from "react-redux";
import {
  openAddFormModalAction,
  openAddOrEditObjectModalAction,
  openAddOrEditSpecieModalAction,
  openAddPersonModalAction,
  openAddProgramModalAction,
  openCreateCommunityModalAction,
  openCreateOrganizationModalAction,
} from "store/modals/reducer.actions";
import { useNavigate } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

interface SideBarDropdownProps {
  className: string;
  ref?: any;
}
const SidebarDropdown: React.FC<SideBarDropdownProps> = ({
  className,
  ref,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    communities,
    organizations,
    programs,
    dynamicForms,
    species,
    objects,
    users,
  } = usePermissions();
  const [showDropdown, setShowDropdown] = useState(false);
  const handelShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <>
      <div
        className={`flex flex-col justify-start items-start w-full rounded-lg bg-white absolute bg-bgWhite dark:bg-secondaryLight z-10 ${className}`}
        style={{
          boxShadow:
            "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
        }}
        ref={ref}
      >
        <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-1.5 py-2">
          {programs.canCreateProgram && (
            <button
              onClick={() => {
                dispatch(openAddProgramModalAction(null));
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-3xl bg-primary hover:bg-primaryMid"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 6C4.44772 6 4 6.44772 4 7V17C4 17.5523 4.44772 18 5 18H19C19.5523 18 20 17.5523 20 17V9C20 8.44771 19.5523 8 19 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711L10.5858 6H5ZM2 7C2 5.34315 3.34315 4 5 4H11C11.2652 4 11.5196 4.10536 11.7071 4.29289L13.4142 6H19C20.6569 6 22 7.34315 22 9V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM12 9C12.5523 9 13 9.44772 13 10V12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14H9C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12H11V10C11 9.44772 11.4477 9 12 9Z"
                  fill="white"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5 text-textWhite">
                  <p>Programs</p>
                </div>
              </div>
            </button>
          )}
          {dynamicForms.canCreateDynamicForm && (
            <button
              onClick={() => {
                dispatch(openAddFormModalAction());
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-3xl bg-tertiary hover:bg-tertiaryMid"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 4C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19L18 9.41421L12.5858 4H7ZM4 5C4 3.34315 5.34315 2 7 2H12.5858C13.1162 2 13.6249 2.21071 14 2.58579L19.4142 8C19.7893 8.37507 20 8.88378 20 9.41421V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V5ZM12 9C12.5523 9 13 9.44772 13 10V12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14H9C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12H11V10C11 9.44772 11.4477 9 12 9Z"
                  fill="white"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5 text-textWhite">
                  <p>Forms</p>
                </div>
              </div>
            </button>
          )}
        </div>
        <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 px-1.5 pb-2">
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4 pt-3 pb-[11px] rounded-lg">
            <div className="flex-grow h-px bg-lineMid dark:bg-secondaryLight" />
          </div>
          {users.canCreatePerson && (
            <button
              onClick={() => {
                dispatch(openAddPersonModalAction());
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7ZM18 8C18.5523 8 19 8.44772 19 9V11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V13H15C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11H17V9C17 8.44772 17.4477 8 18 8ZM9 15C6.23858 15 4 17.2386 4 20H14C14 17.2386 11.7614 15 9 15ZM2 20C2 16.134 5.13401 13 9 13C12.866 13 16 16.134 16 20V21C16 21.5523 15.5523 22 15 22H3C2.44772 22 2 21.5523 2 21V20Z"
                  fill="#2C3236"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p className="flex-grow  text-[15px] text-left text-textMid dark:text-inputText">
                    Add Person
                  </p>
                </div>
              </div>
            </button>
          )}
          {organizations.canCreateOrganization && (
            <button
              onClick={() => {
                dispatch(
                  openCreateOrganizationModalAction(
                    {
                      communityIds: [],
                      missionStatement: "",
                      name: "",
                      organizationImageId: null,
                      tagIds: [],
                      adminIds: [],
                      profileImage: null,
                    },
                    false
                  )
                );
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V20L21 20C21.5523 20 22 20.4477 22 21C22 21.5522 21.5523 22 21 22H2.99999C2.4477 22 1.99999 21.5522 2 21C2.00001 20.4477 2.44773 20 3.00001 20L4 20V5ZM6 20H9V16C9 14.8954 9.89543 14 11 14H13C14.1046 14 15 14.8954 15 16V20H18V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44772 6 5V20ZM13 20V16H11V20H13ZM8 6.99998C8 6.44769 8.44772 5.99998 9 5.99998H10C10.5523 5.99998 11 6.44769 11 6.99998C11 7.55226 10.5523 7.99998 10 7.99998H9C8.44772 7.99998 8 7.55226 8 6.99998ZM13 6.99998C13 6.44769 13.4477 5.99998 14 5.99998H15C15.5523 5.99998 16 6.44769 16 6.99998C16 7.55226 15.5523 7.99998 15 7.99998H14C13.4477 7.99998 13 7.55226 13 6.99998ZM8 11C8 10.4477 8.44772 9.99998 9 9.99998H10C10.5523 9.99998 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H9C8.44772 12 8 11.5523 8 11ZM13 11C13 10.4477 13.4477 9.99998 14 9.99998H15C15.5523 9.99998 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H14C13.4477 12 13 11.5523 13 11Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p className="flex-grow  text-[15px] text-left text-textMid  dark:text-inputText">
                    Add Organization
                  </p>
                </div>
              </div>
            </button>
          )}
          {communities.canCreateCommunities && (
            <div
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg cursor-pointer"
              onClick={() => {
                dispatch(openCreateCommunityModalAction());
              }}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5ZM8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7ZM5 9C4.44772 9 4 9.44772 4 10C4 10.5523 4.44772 11 5 11C5.55228 11 6 10.5523 6 10C6 9.44772 5.55228 9 5 9ZM2 10C2 8.34315 3.34315 7 5 7C6.65685 7 8 8.34315 8 10C8 11.6569 6.65685 13 5 13C3.34315 13 2 11.6569 2 10ZM19 9C18.4477 9 18 9.44772 18 10C18 10.5523 18.4477 11 19 11C19.5523 11 20 10.5523 20 10C20 9.44772 19.5523 9 19 9ZM16 10C16 8.34315 17.3431 7 19 7C20.6569 7 22 8.34315 22 10C22 11.6569 20.6569 13 19 13C17.3431 13 16 11.6569 16 10ZM12 14C10.3174 14 8.87516 15.0392 8.28463 16.5145C8.10133 16.9725 8 17.4732 8 18V19H16V18C16 17.4732 15.8987 16.9725 15.7154 16.5145C15.1248 15.0392 13.6826 14 12 14ZM18 19H21V18C21 16.8954 20.1046 16 19 16C18.5439 16 18.1237 16.1521 17.7867 16.4097C17.9259 16.917 18 17.4504 18 18V19ZM16.9286 14.5777C15.8455 13.0206 14.0427 12 12 12C9.9573 12 8.15455 13.0206 7.07138 14.5777C6.46741 14.2114 5.75829 14 5 14C2.79086 14 1 15.7909 1 18V20C1 20.5523 1.44772 21 2 21H22C22.5523 21 23 20.5523 23 20V18C23 15.7909 21.2091 14 19 14C18.2417 14 17.5326 14.2114 16.9286 14.5777ZM6.21328 16.4097C5.87632 16.1521 5.45607 16 5 16C3.89543 16 3 16.8954 3 18V19H6V18C6 17.4504 6.07414 16.917 6.21328 16.4097Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p className="flex-grow text-[15px] text-left text-textMid  dark:text-inputText">
                    Add Community
                  </p>
                </div>
              </div>
            </div>
          )}
          {species.canCreateSpecies && (
            <button
              onClick={() => {
                dispatch(
                  openAddOrEditSpecieModalAction({
                    alternativeName: "",
                    commonName: "",
                    description: "",
                    images: [],
                    isStar: null,
                    scientificName: "",
                    typeId: null,
                    path: "",
                  })
                );
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M4 5C4 3.34315 5.34315 2 7 2H12.5858C13.1162 2 13.6249 2.21071 14 2.58579L19.4142 8C19.7893 8.37507 20 8.88378 20 9.41421V11C20 11.5523 19.5523 12 19 12C18.4477 12 18 11.5523 18 11V9.41421L12.5858 4H7C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44771 20 7 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H7C5.34315 22 4 20.6569 4 19V5Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
                <path
                  d="M17 14C17.5523 14 18 14.4477 18 15V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H18V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19H14C13.4477 19 13 18.5523 13 18C13 17.4477 13.4477 17 14 17H16V15C16 14.4477 16.4477 14 17 14Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p className="flex-grow text-[15px] text-left text-textMid  dark:text-inputText">
                    Add Species
                  </p>
                </div>
              </div>
            </button>
          )}
          {objects.canCreateObjects && (
            <button
              onClick={() => {
                dispatch(
                  openAddOrEditObjectModalAction({
                    name: "",
                    description: "",
                    images: [],
                    isStar: null,
                    typeId: null,
                  })
                );
              }}
              type="button"
              className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M4 5C4 3.34315 5.34315 2 7 2H12.5858C13.1162 2 13.6249 2.21071 14 2.58579L19.4142 8C19.7893 8.37507 20 8.88378 20 9.41421V11C20 11.5523 19.5523 12 19 12C18.4477 12 18 11.5523 18 11V9.41421L12.5858 4H7C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44771 20 7 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H7C5.34315 22 4 20.6569 4 19V5Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
                <path
                  d="M17 14C17.5523 14 18 14.4477 18 15V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H18V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19H14C13.4477 19 13 18.5523 13 18C13 17.4477 13.4477 17 14 17H16V15C16 14.4477 16.4477 14 17 14Z"
                  className="fill-[#2C3236] dark:fill-[#D3DFEA]"
                />
              </svg>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p className="flex-grow text-[15px] text-left text-textMid  dark:text-inputText">
                    Add Object
                  </p>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarDropdown;
