import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";
import { generatingLayerId } from "../utils";
import {
  __drawShapeByGeoJsonOnMap__,
  __paintShapeByGeoJsonOnMap__,
} from "../workerMehods";
import { convertGeoJSON } from "utils/convertFeatureCollection";
import mapboxgl from "mapbox-gl";
import { __deletePaintedLayers__ } from "Components/Communities/MapBox/workerMehods";
import { getFilteredOrCompleteProperties } from "../utils/getFilteredOrCompleteProperties";
import { sentenceCase } from "change-case";

function handlePaintingOfVectorRegionShapeLayersOnMap(
  item: any,
  map: any,
  draw: any,
  defaultColor: any,
  cb?: CallableFunction,
  popups?: any,
  setPopups?: any,
  setNewPopup?: any,
  editMode?: boolean
) {
  if (item.clickable) {
    handlePaintingOfClickableRegionShapeLayersOnMap(
      item,
      map,
      draw,
      defaultColor,
      cb
    );
    return;
  }

  const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);

  // stop the layer painting if its already painted
  if (map.getLayer(layerId)) {
    // if layer item is unchecked then unpaint it from the map
    if (item.status === false) {
      const borderLayerId = layerId + "-border";

      map.removeLayer(layerId);
      map.removeLayer(borderLayerId);
      map.removeSource(sourceId);

      const popup = popups?.find((p: any) => p.id === item.item.id);
      if (popup && setPopups) {
        popup.item.remove();

        const updatedPopups = popups?.filter((p: any) => p.id !== popup.id);

        setPopups((prev: any) => [...updatedPopups]);
      }
    }
  } else {
    // if layer item is unchecked or it doesn't has geojson data then blocks its painting on map
    const geoJson =
      convertGeoJSON(item.item?.mapData) ?? item.item?.geoFilePath;
    if (item.status === false || !geoJson) {
      return;
    }

    // draw the layer
    if (item.draw) {
      __drawShapeByGeoJsonOnMap__(draw, item.item.id, item.item.mapData);

      return;
    }

    // register click event listener on layer
    if (cb && !item.clickable) {
      map.on("click", layerId, (e: any) => {
        cb(item);
        // create a popup
        if (e.features.length > 0 && setNewPopup) {
          const feature = e.features[0];
          const name = feature.properties.name ?? item.item.name;
          const properties = feature.properties ?? {};

          setNewPopup({
            coordinates: e.lngLat,
            html: getPopupHtml(name, properties, item.item.uniqueProps),
            isAlreadyAdded: popups?.find((p: any) => p.id === item.item.id),
            id: item.item.id,
          });
        }
      });
    }

    // paint the layer
    __paintShapeByGeoJsonOnMap__(
      map,
      draw,
      sourceId,
      layerId,
      geoJson,
      item.item.color ?? defaultColor,
      item.item.borderColor,
      item.shapeType,
      item.item

    );
  }
}

export { handlePaintingOfVectorRegionShapeLayersOnMap };

const handlePaintingOfClickableRegionShapeLayersOnMap = (
  item: any,
  map: any,
  draw: any,
  defaultColor: any,
  cb?: CallableFunction
) => {
  const allLayers = map.getStyle().layers;

  const alreadyPaintedLayers = allLayers.filter((l: any) =>
    l.id.includes(item.item.id)
  );

  if (alreadyPaintedLayers.length > 0) {
    if (item.status === false) {
      alreadyPaintedLayers.forEach((l: any) => {
        map.removeLayer(l.id);
        map.removeSource(l.source);
      });
    }
  } else {
    if (item.status !== true) return;
    // const allFeatures = item.item.mapData.features.map((f: any) => ({
    //   features: [f],
    //   type: "FeatureCollection",
    // }));

    const { sourceId, layerId } = generatingLayerId(
      item.itemType,
      item.item.id
    );


    __paintShapeByGeoJsonOnMap__(
      map,
      draw,
      sourceId,
      layerId,
      item.item.mapData,
      item.item.color ?? defaultColor,
      item.item.borderColor,
      item.shapeType,
      item.item
    );

    if (cb) {
      map.on("click", layerId, (event: any) => {
        cb(item);
      });
    }
    // allFeatures.forEach((geojson: any) => {
    //   const uniqueId = generateUniqueId(item.item.id + ".");
    //   const layerId = uniqueId + ".layer";
    //   const sourceId = uniqueId;

    //   geojson.features[0].id = layerId;

    //   __paintShapeByGeoJsonOnMap__(
    //     map,
    //     draw,
    //     sourceId,
    //     layerId,
    //     geojson,
    //     item.item.color ?? defaultColor
    //   );

    //   if (cb) {
    //     map.on("click", layerId, (event: any) => {
    //       cb(item);
    //     });
    //   }
    // });
  }
};
const getPopupHtml = (
  name: string,
  properties: { [key: string]: any } = {},
  uniqueProps: { [key: string]: any } = {}
): string => {
  const filteredData = getFilteredOrCompleteProperties(properties, uniqueProps);

  return `<div class="min-w-[200px]  max-h-[200px]   relative overflow-auto rounded bg-bgWhite">
    <div class="flex flex-col justify-start items-start gap-[9px]">
      <p class="self-stretch  text-left text-secondaryMid">
        ${name}
      </p>
      <div class="min-w-[200px]  max-h-[120px]   relative overflow-auto rounded bg-bgWhite">
      ${Object.entries(filteredData)
        .map(
          ([key, value]) =>
            `<p class=text-secondaryMid">
          ${sentenceCase(key ?? "")}: ${value}
        </p>`
        )
        .join("")}
    </div>
  </div>`;
};
