import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { observationsProgramsAction, reportsProfileResetFiltersAction } from "store/filters/reducer.actions";

export const Statuses: React.FC = () => {
  const dispatch = useDispatch();
  const { reportProfileFilter } = useSelector<RootState,filtersInitialState>((state) => state.Filters);
  const statusLabels = ["processing", "ready"]
  const isStatusApplied = reportProfileFilter.status?.applied;
  const isStatusValueValid = statusLabels.includes(reportProfileFilter.status.value);

  const resetFilter = () => {
    dispatch(reportsProfileResetFiltersAction());
  };

  if (isStatusApplied && isStatusValueValid) {
    return (
      <div className="flex flex-wrap justify-center items-center relative gap-1 px-2 capitalize  rounded-lg bg-primary/[0.08]">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">
            {sentenceCase(reportProfileFilter.status.value)}
          </p>
        </div>
        <ClearFilters handleClick={resetFilter} />
      </div>
    );
  } else {
    return null; // or fallback JSX if needed
  }
};
