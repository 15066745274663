import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { editProgramTagsSchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  closeEditProgramTagsModalAction,
  closeIniviteDataEndViewerModal,
  closeInviteRegisteredExternalReviewerModalAction,
  openSuccessfullInviteMessageModalAction,
} from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import MultiSelect from "view/components/Multiselect";
import { useParams } from "react-router-dom";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import usePermissions from "hooks/usePermissions";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import * as Yup from "yup";
import { fetchManagerAction } from "store/managers";
import { managerTypeAction } from "store/filters";
import { updateColumnsVisibilityOfManagersByStatus } from "store/directoriesOrders/reducer.actions";
import Select, { SelectInstance, components } from "react-select";
// import MultiSelect from "Components/Messages/MultiSelectInput";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

const invitationValidationSchema = Yup.object().shape({
  reviewersName: Yup.string().required("First name is required"),
});
const initialValues = {
  reviewersName: "",
};
const customStyles: any = {
  control: (styles: any) => ({
    ...styles,
    border: 'none',  // Remove border
    boxShadow: 'none',  // Remove box-shadow if any
    '&:hover': {
      border: 'none'  // Remove border on hover
    }
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontWeight: 'semiBold',
    color: '#B1B1B1'  // Example color, replace with your desired color code
  })
};
export const InviteRegisteredDataEndReviewer = () => {
  
  const { programId } = useParams<{
    programId: string;
  }>();
  const { inviteRegisteredExternalReviewerModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { programs } = usePermissions();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const { data, message } = await apiLibrary.Managers.inviteDataEndViewer(
        programId,
        values
      );
      Toasts.success(message);
      handleClose();
      if (programId) {
        dispatch(fetchManagerAction(parseInt(programId)));
        dispatch(managerTypeAction("external_data_end_viewer"));
        dispatch(
          updateColumnsVisibilityOfManagersByStatus("external_data_end_viewer")
        );
      }
      dispatch(openSuccessfullInviteMessageModalAction(values.email));
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeInviteRegisteredExternalReviewerModalAction());
  };


  return (
    <Modal
      open={inviteRegisteredExternalReviewerModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={invitationValidationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px]  max-h-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex justify-between w-full">
                    <p className="font-semibold text-xl leading-7 text-textMid">
                      Data end viewer details
                    </p>
                    <button onClick={handleClose} type="button">
                      <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                    </button>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="text-textLink text-sm font-semibold leading-4">
                      Resend invitation
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-normal text-secondaryMidLight leading-4">
                      By adding a person as an Data-end viewer, they won't
                      automatically gain access to any reports. They must be
                      added through the permissions settings of a specific
                      report to view it.
                    </p>
                  </div>
                  <div>
                    <div className="flex w-full flex-col justify-start items-start flex-grow">
                      <Select
                        classNamePrefix="my-react-select"
                        onBlur={handleBlur}
                        placeholder={"Select"}
                        className="font-Overpass text-textMid text-[15px] placeholder:text-textLightExtra placeholder:font-semiBold  my-react-select-container w-full"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        // styles={customStyles}
                      />
                    </div>

                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-auto">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        variant="outlined"
                        onClick={handleClose}
                      />
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        text="Save"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
