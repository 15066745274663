import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { AddManagerBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import {
  observationsCommunityAction,
} from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { updateColumnsVisibilityOfObservationsByStatus } from "store/directoriesOrders/reducer.actions";
import {
  openAddManagerModal,
  openIniviteDataEndViewerModal,
} from "store/modals/reducer.actions";
import { IManager, IfetchManager } from "store/managers/initialState";
import usePermissions from "hooks/usePermissions";
import IniviteDataEndViewerButton from "./components/IniviteDataEndViewerButton";
import { getBrandPlaceholder } from "utils/customBranding";

const generateDynamicObservationsLinks = (
  communityId: any,
  programId: any,
  name: any,
  label: any,
  profileImage: any
) => {
  switch (true) {
    case Boolean(programId):
      return [
        {
          path: `/programs/list`,
          label: `Programs`,
        },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/management`,
          label: `Management`,
        },
      ];
    default:
      return [{ path: `/observations/list`, label: `Observations` }];
  }
};

export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    profileImage: "",
  });
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { data } = useSelector<RootState, IfetchManager>(
    (state) => state?.allManagers
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();
  const { programs, users } = usePermissions();
  const dynamicObservationsLinks = generateDynamicObservationsLinks(
    communityId ?? "",
    programId ?? "",
    BreadcrumbData?.name ?? "",
    managerFilter.status,
    BreadcrumbData.profileImage ?? ""
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          dispatch(updateColumnsVisibilityOfObservationsByStatus("program"));
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    } else if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          // fetching communities according to specific filteration
          dispatch(
            observationsCommunityAction({
              name: data.name,
              id: communityId,
            })
          );
          dispatch(updateColumnsVisibilityOfObservationsByStatus("community"));
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/community/list");
        });
    }
  }, [programId, communityId]);
  const programData = {
    name: BreadcrumbData.name,
    profileImage: BreadcrumbData.profileImage,
    programId: programId,
    programManagers: data.managers.map((manager: IManager) => {
      const data = {
        name: manager.reviewer.firstName + " " + manager.reviewer.lastName,
        profileImage: manager?.reviewer?.image?.file?.thumb,
        id: manager.personId,
        observations: manager?.activeObservations,
      };
      return data;
    }),
  };
  const handleAddManagerModal = () => {
    dispatch(openAddManagerModal(programData));
  };

  const handleInviteDataEndViewerModal = () => {
    dispatch(openIniviteDataEndViewerModal());
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicObservationsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              id={programId ? programId : communityId ? communityId : ""}
              name={BreadcrumbData?.name}
              image={
                BreadcrumbData.profileImage
                  ? BreadcrumbData.profileImage
                  : getBrandPlaceholder("listingProfile")
              }
              parentName={
                programId ? "Program" : communityId ? "Community" : ""
              }
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {managerFilter.type === "internal" &&
                programs.canCreateManagers &&
                programs.canViewManagersDropdown && (
                  <AddManagerBtn
                    handleAddManagerModal={handleAddManagerModal}
                  />
                )}
              {managerFilter.type === "external_data_end_viewer" &&
                users.canEditUsers && (
                  <IniviteDataEndViewerButton
                    handleAddManagerModal={handleInviteDataEndViewerModal}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// controllers
async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
