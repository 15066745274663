import { apiClient } from "../config";

async function getProgramStatusHistory(programId: string) {
  const res = await apiClient.get(
    `/admin/programs/${programId}/status-history`
  );
  return res.data;
}

export default getProgramStatusHistory;
