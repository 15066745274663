import { TableBodyProps } from '../../types';
import parseArray from 'utils/parseArray';
import { KEYS_TO_BE_REMOVED } from '../..';
import { Cell } from '@tanstack/react-table';
import { Column, RowsActions } from '../index';
import { useParams } from 'react-router-dom';

export const TableBody = ({
  table,
  columnVisibility,
  flexRender,
  getOrganizationsActions,
}: TableBodyProps) => {
  const { programId } = useParams();
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className='w-full Close item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight'
          style={{ height: '66px' }}
        >
          {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
            (cell: Cell<any, any>) => (
              <Column
                hoverEffect='item_hover_effect'
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            )
          )}
          <td className='sticky right-0  whitespace-nowrap dark:bg-bgtetriaryz-10 bg-bgWhite dark:bg-secondaryLight item_hover_effect'>
            {programId && (
              <RowsActions
                row={row}
                getOrganizationsActions={getOrganizationsActions}
              />
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};
