import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { openCommunityAccessModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const TableBody = ({
  table,
  columnVisibility,
  getCommunityActions,
  flexRender,
}: TableBodyProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const regainAccess = (row: any) => {
    // const adminAccess = row?.original?.communityName?.accessibleForSuperAdmin;
    // if (!adminAccess) {
    //   dispatch(
    //     openCommunityAccessModalAction(
    //       { id: row?.original?.communityName?.id },
    //       "enter"
    //     )
    //   );
    //   return;
    // }
    return;
  };
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className="Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
            (cell: Cell<any, any>) => (
              <Column
                hoverEffect="item_hover_effect"
                flexRender={flexRender}
                regainAccess={() => regainAccess(row)}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            )
          )}
          <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions row={row} getCommunityActions={getCommunityActions} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
