import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { HARVEST_QUESTIONS_NAME } from "../../../panelsQuestionsName";

const useGetCurrentQuestionState = (questionId: any, name: any) => {
  const {
    shapePickers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
  } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );
  const [currentQuestion, setCurrentQuestion] = useState<any>();

  useEffect(() => {
    let currentQues = shapePickers.find((s: any) => s.id == questionId);

    if (!currentQues) {
      if (
        harvestPanelMapQuestions?.id
      ) {
        if (name === HARVEST_QUESTIONS_NAME.RetrievalLocation) {
          currentQues = harvestPanelMapQuestions.retrievalShapePicker;
        }
        else if (name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion) {
          currentQues = harvestPanelMapQuestions.struckShapePicker;
        }
      }
      if (geographicalPanelMapQuestions?.id) {

        if (name === "Region") {
          currentQues = geographicalPanelMapQuestions.regionTypeShapePicker;
        }
        else if (name === "View") {
          currentQues = geographicalPanelMapQuestions.viewTypeShapePicker;
        }
      }
    }


    setCurrentQuestion(currentQues);
  },
    [
      questionId,
      shapePickers,
      harvestPanelMapQuestions,
      geographicalPanelMapQuestions,
    ]);

  return currentQuestion;
};

export { useGetCurrentQuestionState };
