import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IDataAccessPolicy } from "store/DataAccessPolicy/initialState";
import { DarkMode } from "store/darkMode/initialState";

const Content = () => {
  const dataAccessPolicy = useSelector<RootState, IDataAccessPolicy>(
    (state) => state?.dataAccessPolicy
  );
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const html = `${dataAccessPolicy?.policyContent}
`;

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const doc = iframe.contentDocument;
      if (doc) {
        // Load the TinyMCE editor within the iframe
        function linkify(inputText: any) {
          const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
          return inputText.replace(urlRegex, function (url: any) {
            return '<a href="' + url + '" target="_blank">' + url + "</a>";
          });
        }
        const linkedHtml = linkify(html);
        doc.open();
        doc.write(
          `<!DOCTYPE html><html><head><style>body { font-family: "system-ui"; margin: 0px; color: ${isDarkMode ? "#fff" : 'inherit'}}</style></head><body>` +
            linkedHtml +
            "</body></html>"
        );
        doc.close();

        // Calculate the content height and set the iframe's height
        const contentHeight = doc.body.offsetHeight;
        iframe.style.height = `${contentHeight + 30}px`;
        // Prevent the iframe from showing scrollbars
        iframe.scrolling = "no";
      }
    }
  }, [dataAccessPolicy]);

  return (
    <iframe
      title="Content"
      className="w-full"
      ref={iframeRef}
      sandbox="allow-same-origin allow-popups"
    />
  );
};

export default Content;
