import { apiClient } from "../config";

async function updateLayerStatus(
  communityId: string,
  geographyId: string,
  isArchived: boolean
) {
  const url = `/admin/communities/${communityId}/geography/${geographyId}/archive
  `;
  const res = await apiClient.put(url, { isArchived });
  return res.data;
}

export default updateLayerStatus;
