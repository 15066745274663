import { useCallback, useRef } from "react";
import mapboxgl from "mapbox-gl";

import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchCommunities } from "store/communities/initialState";
import {
  useCreateMarkers,
  useFetchReportsVersionsRegionLayers,
  useInitializeMap,
  usePaintLayers,
} from "./hooks";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export default function ResultVersionsDirectoryMap({ sliceData }: any) {
  const mapContainer = useRef<null | HTMLDivElement>(null);
  const centerCoordinatesDataOfResultsVersions = useCallback(
    parseLocationFromResults(sliceData),
    [sliceData]
  );

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // creating markers for individual community
  const currentSelectedCommunity = useCreateMarkers(
    centerCoordinatesDataOfResultsVersions,
    map,
    isMapFullyLoaded
  );

  // fetching region layer of selected community
  const regionLayers = useFetchReportsVersionsRegionLayers(
    currentSelectedCommunity,
    sliceData
  );

  // painting regions layers of selected community
  usePaintLayers(regionLayers, map, isMapFullyLoaded);

  return (
    <section className="bg-primaryDark  h-[60vh] w-[100%] relative ">
      <div ref={mapContainer} className="h-full w-full"></div>
    </section>
  );
}

// utils
function parseLocationFromResults(data: any) {
  const locations = data?.flatMap((item: any) =>
    (item?.longitude !== "" && item?.latitude !== "") ||
    (item.observationCommunityLatitude !== "" &&
      item.observationCommunityLongitude !== "")
      ? {
          locationLong: item.longitude,
          locationLat: item.latitude,
          obsCode: item.observationCode,
          observationCommunityLatitude: item.observationCommunityLatitude,
          observationCommunityLongitude: item.observationCommunityLongitude,
          observationDate: item.observationDateTime,
        }
      : {}
  );
  return locations ?? [];
}
