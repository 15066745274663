import {
  GET_CHAT_DETAILS,
  RESET_CHAT_DETAILS,
  APPEND_NEW_CHAT,
  START_LOADING_CHATS,
  STOP_LOADING_CHATS,
  GET_SEARCHED_CHAT_DETAILS,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import {
  convertFiltersToQueryString,
  generateQueryStringForUsers,
} from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { MessageFormValues } from "./initialState";

export const fetchChatDetailAction =
  (id: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({ type: START_LOADING_CHATS });
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.messageDetailFilter
      );
      const { data } = await apiLibrary.Messages.getChatDetail(id, queryString);
      // Check if data array is not empty
      if (currentState.Filters.messageDetailFilter.searchString.length > 0) {
        dispatch({
          type: GET_SEARCHED_CHAT_DETAILS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_CHAT_DETAILS,
          payload: data,
        });
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_CHAT_DETAILS,
      });
      console.log("An error occurred while fetching the user profile:", error);
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_CHATS });
    }
  };

export const resetChatDetails = (): { type: string } => {
  return {
    type: RESET_CHAT_DETAILS,
  };
};

export const appendNewChatDetails = (updatedMessage: any) => {
  return {
    type: APPEND_NEW_CHAT,
    payload: updatedMessage,
  };
};

export const sendMessageText = async (message: string, contactId: string | undefined): Promise<any> => {
  let formValues: MessageFormValues = {
    message: message,
    fileId: null
  };
  return apiLibrary.Messages.sendMessage(contactId, formValues);
}

export const sendAttachment = (fileId: string, contactId: string | undefined): Promise<any> => {
  let attachmentFormValues: MessageFormValues = {
    message: "",
    fileId: fileId
  };
  return apiLibrary.Messages.sendMessage(contactId, attachmentFormValues);
}
