import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getBrandPlaceholder } from "utils/customBranding";

interface IFile {
  id: string;
  file: {
    original: string;
  };
  type: string;
  path: string;
}

interface IDataAccessPolicy {
  attachments: IFile[];
}

export const MediaGrid: React.FC = () => {
  const dataAccessPolicy = useSelector<RootState, IDataAccessPolicy>(
    (state) => state?.dataAccessPolicy
  );

  // Function to check if file is an audio type
  const isAudio = (fileType: string): boolean => {
    const audioTypes = ['mpeg', 'wav', 'aac', 'mp3'];
    return audioTypes.includes(fileType);
  };

  // Separate attachments into images and audio files
  const images: IFile[] = [];
  const audioFiles: IFile[] = [];
  dataAccessPolicy?.attachments?.forEach((file) => {
    if (isAudio(file?.type)) {
      audioFiles.push(file);
    } else {
      images.push(file);
    }
  });

  // Concatenate images and audio files so images appear first
  const sortedAttachments = images.concat(audioFiles);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 gap-2 pb-4 w-full">
      {sortedAttachments.map((file) => {
        const fileIsAudio = isAudio(file?.type);

        return (
          <div key={file?.id} className="h-36 w-full overflow-hidden">
            {fileIsAudio ? (
              <audio controls className="w-full h-full">
                <source src={file?.path || ''} type={`audio/${file?.type}`} />
                Your browser does not support the audio tag.
              </audio>
            ) : (
              <img src={file?.file?.original || getBrandPlaceholder("moduleCover")} alt=" " className="w-full h-full object-cover rounded-sm" />
            )}
          </div>
        );
      })}
    </div>
  );
};
