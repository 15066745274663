import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { programsOrganizationsAction } from "store/filters/reducer.actions";

export const SelectedOrganizations: React.FC = () => {
  const dispatch = useDispatch();
  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const resetFilter = (organization: any) => {
    dispatch(programsOrganizationsAction(organization));
  };

  if (programsFilter.organizationIds?.applied) {
    return programsFilter.organizationIds.value.map(
      (item: any, index: number) => (
         <div
        key={index}
        className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="break-all w-full flex-shrink-0 text-sm text-start text-textMid dark:text-inputText capitalize">
            {item.name}
          </p>
        </div>
          {!organizationId && (
            <ClearFilters handleClick={() => resetFilter(item)} />
          )}
        </div>
      )
    );
  } else {
    return null;
  }
};
