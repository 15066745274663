import usePermissions from "hooks/usePermissions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { IAuthResponseData } from "store/auth/initialState";
import { openDeleteAccountRequestModalAction } from "store/modals/reducer.actions";

export const AccountDeletionRequest = () => {
  const dispatch = useDispatch();
  const { deleteAccountRequest } = usePermissions();

  const handleDeleteAccount = () => {
    dispatch(openDeleteAccountRequestModalAction());
  };
  const auth = useSelector<RootState, IAuthResponseData>((state) => state.auth);
  if (auth.roles.includes("super_admin")) return <></>;
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 overflow-hidden bg-white">
      <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pt-6 pl-12 pr-8">
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <div className="relative flex flex-col items-start justify-center flex-grow py-1">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-lg font-semibold text-left text-secondaryMid">
              Request Deletion
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 px-12 pt-4 pb-12">
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative .5">
          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMidLight dark:text-caption">
            You can Request Deletion of your account by clicking the button
            below. This will send a request to the Super Admin, who will then
            contact you about further steps for your account deletion.
          </p>
        </div>
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
          <button
            type="button"
            onClick={handleDeleteAccount}
            className="flex items-center justify-center flex-grow-0 flex-shrink-0 cursor-pointer rounded-3xl"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative .5 pb-[7px]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary">
                Delete Account
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
