import React from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, ImportBtns } from "./components";

// Store Utils
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { resetImportStepperAction } from "store/addImportStepper/reducer.actions";
import { RootState } from "store";
import { Stepper, Step } from "store/addImportStepper/initialState";
import { useSelector } from "react-redux";

// Generate links dynamically

const generateDynamicBreadcrumbLinks = () => {
  return [
    { path: `/imports/list`, label: `Imports` },
    { path: `/imports/add`, label: `Import data from a file` },
  ];
};

interface AddImportHeaderProps {
  steps: any;
}

export const Header: React.FC<AddImportHeaderProps> = ({ steps }) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const abortController = new AbortController();
  const { signal } = abortController;
  // Get Notification ID
  const { notificationId } = useParams<{
    notificationId: string;
  }>();
  const importLinks = generateDynamicBreadcrumbLinks();

  // Handlers
  const handleCancelBtn = () => {
    if (signal.aborted) {
      console.log("API call aborted");
    } else {
      console.log("Aborted");
      // Abort the ongoing API call by calling the abort method on the AbortController
      abortController.abort();
    }
    dispatch(resetImportStepperAction());
    // Navigate back to the "imports" page
    navigate("/imports/list");
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 p-5 bg-bgWhite dark:bg-secondaryLight">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={importLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {steps.findIndex((step: any) => step.isActive) < 2 && (
                <ImportBtns handleClose={handleCancelBtn} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
