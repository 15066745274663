// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// APIs services
import apiLibrary from "services/api";
// Assets
import uploadIcon from "assets/icons/upload.svg";
// Store utils
import { Dispatch, Key, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen, resetMap } from "store/geography";
// Third party services
import Dropzone from "react-dropzone";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import handleShapeAndKMLTypeFiles from "Components/Geography/SideBar/utils/handleShapeAndKMLTypeFiles";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import usePermissions from "hooks/usePermissions";

interface Shape {
  name: string;
  geoJSON: any; // Replace 'any' with the actual type of geoJSON
  description: string;
  shapeType: string;
  mapData?: any;
  color: string;
  borderColor: string;
}

const SHAPE_TYPES = [
  { value: "road", label: "Road" },
  { value: "trails", label: "Trails" },
  { value: "river_or_stream", label: "River or Stream" },
  { value: "view", label: "View" },
];

// Schema

const validationSchema = Yup.object().shape({
  shapes: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Shape name is required"),
        description: Yup.string()
          .max(255, "255 max characters")
          .required("description is required"),
        geoJSON: Yup.mixed().required("GeoJSON is required"),
        shapeType: Yup.string().required("Shape type is required"),
        mapData: Yup.mixed(),
        color: Yup.string().required("Fill Color is required"),
        borderColor: Yup.string().required("Border Color is required"),
      })
    )
    .required("Shapes are required"),

  // color: Yup.string().required("Fill Color is required"),
  // borderColor: Yup.string().required("Border Color is required"),
});

interface UploadFormInterface {
  height: string;
}

const UploadForm = ({ height }: UploadFormInterface) => {
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const [geoJsonData, setGeoJsonData] = useState<any[]>([]);

  interface FormValues {
    shapes: Shape[];
  }

  const initialValues: FormValues = {
    shapes: [
      {
        name: "",
        geoJSON: {},  // Properly replace 'any' with the actual type of geoJSON
        description: "",
        shapeType: "",
        mapData: {},  // Include specific type if needed
        color: "#FF0000",  // Default color
        borderColor: "#FF0000"  // Default border color
      }
    ],
  };

  const { communities } = usePermissions();

  useEffect(() => {
    // reset selected layers
    dispatch(resetMap(true));
  }, []);

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };

  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    try {

      for (let index = 0; index < values.shapes.length; index++) {
        const shape = values.shapes[index];

        if (!willColorBeVisibleOnMap(shape.color)) {
          setFieldError(`shapes[${index}].color`, "Please select a fill color that is easily visible");
          return; // Exit if an error is found
        }

        if (!willColorBeVisibleOnMap(shape.borderColor)) {
          setFieldError(`shapes[${index}].borderColor`, "Please select a border color that is easily visible");
          return; // Exit if an error is found
        }
      }

      const uploadPromises = values.shapes.map(
        async (shape: any, index: any) => {
          try {
            const mapData = await handleShapeAndKMLTypeFiles(shape.geoJSON);

            const blob = new Blob([JSON.stringify(mapData)], {
              type: "application/json",
            });
            const file = new File([blob], "location.geojson", {
              type: "application/geo+json",
            });

            const { data } = await apiLibrary.file.fileUpload(
              file,
              true,
              "public",
              "shape"
            );

            // Get shape type
            const shapeType = mapData?.features[0]?.geometry?.type;

            let updatedShape: any = {
              description: shape.description,
              geoFileId: data.id,
              type: "shape",
              name: shape.name,
              shapeType: shape.shapeType,
              color: shape.color,
              uniqueProps: mapData.uniqueProps,
              borderColor: shapeType !== "LineString" ? shape.borderColor : undefined,
            };

            return { status: "fulfilled", updatedShape, geoFileId: data.id };
          } catch (error: any) {
            if (error.code === "CRS_MISSING") {
              return {
                status: "rejected",
                reason: error.code,
                shape,
                error: error,
                fieldIndex: index,
              };
            } else {
              return {
                status: "rejected",
                reason: null,
                shape,
                error: error?.response?.data ?? error?.message,
                fieldIndex: index,
              };
            }
          }
        }
      );

      const uploadResults = await Promise.allSettled(uploadPromises);

      const failedUploads = uploadResults.filter(
        (result) =>
          result.status === "rejected" || result.value.status === "rejected"
      );

      if (failedUploads.length > 0) {
        let errorMessages: any = [];

        failedUploads.forEach((failedUpload: any, index: number) => {
          const { reason, error, shape, fieldIndex } = failedUpload.value;
          const shapeName = shape?.name;
          const errorMessage = error?.message;
          const detailMessage = error?.details;

          if (reason === "CRS_MISSING") {
            setFieldError(`shapes[${fieldIndex}].geoJSON`, detailMessage);
          } else if (errorMessage && shapeName) {
            errorMessages.push(`shape: ${shapeName}, Error: ${errorMessage}`);
          }
        });

        if (errorMessages.length > 0) {
          Toasts.error(errorMessages.join(", "));
        }
        return;
      }

      const successfulPayload = uploadResults
        .filter(
          (result: any) =>
            result.status === "fulfilled" && result.value.status === "fulfilled"
        )
        .map((result: any) => result.value.updatedShape);

      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, {
          shapes: successfulPayload,
          type: "shape",
        });

        Toasts.success(`Shape layer created successfully`);
        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message ?? error?.message ?? "An error occurred";
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false); // Indicate that the submission process is complete
    }
  };

  // Parse the file here and get its contents
  const handleFileParsing = async (file: File, index: number) => {
    if (!file) return;
    try {
      const data = await handleShapeAndKMLTypeFiles(file);
      setGeoJsonData(prevData => {
        // Create a new array with the data inserted at the correct index
        let newData = [...prevData];
        newData[index] = data; // Set or replace the data at the specified index
        return newData;
      });
    } catch (error) {
      console.error("Error parsing file at index", index, ":", error);
    }
  };

  // Utility function to check if geoJSON is empty
  const isEmptyGeoJSON = (geoJSON: any) => {
    return (
      geoJSON === null ||
      geoJSON === "" ||
      (typeof geoJSON === "object" && Object.keys(geoJSON).length === 0)
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => (
          <Form>
            <FieldArray name="shapes">
              {({ push, remove }) => (
                <div className="pt-3">
                  <div className="overflow-y-auto " style={{ height }}>
                    <FilesDragAndDrop push={(data: Shape) => {
                      if (!data?.geoJSON) return; // Guard clause to handle invalid input

                      const file = data.geoJSON; // Get the GeoJSON file

                      // Find index of first empty geoJSON in shapes
                      const emptyGeoJSONIndex = values.shapes?.findIndex((shape) => isEmptyGeoJSON(shape.geoJSON));

                      if (emptyGeoJSONIndex === -1) {
                        // If no empty geoJSON, push new shape
                        push(data);
                      } else {

                        const existingShape = values.shapes[emptyGeoJSONIndex] || {};
                        const updatedShapeData = { ...existingShape, geoJSON: file }; // Merge with existing values
                        // Otherwise, update the existing empty geoJSON shape
                        setFieldValue(`shapes[${emptyGeoJSONIndex}]`, updatedShapeData);
                      }

                      // Handle file parsing (use new or updated index)
                      handleFileParsing(file, emptyGeoJSONIndex === -1 ? geoJsonData.length : emptyGeoJSONIndex);
                    }} remove={remove} />
                    <div className="pt-3">
                      {values.shapes?.map((_shape: any, index: number) => {
                        const fieldsErrors: any = errors?.shapes
                          ? errors.shapes[index]
                          : {
                            name: null,
                            description: null,
                            geoJSON: null,
                            shapeType: null,
                            color: null,
                            borderColor: null
                          };

                        const fieldsTouched: any = errors.shapes
                          ? errors.shapes[index]
                          : {
                            name: false,
                            description: false,
                            geoJSON: false,
                            shapeType: false,
                            color: false,
                            borderColor: false
                          };

                        // Get shape type
                        const shapeType = geoJsonData[index]?.features[0]?.geometry?.type;

                        return (
                          <div key={index} className="pb-1">
                            <div className="relative flex w-full">
                              <div className="flex flex-col items-start justify-start w-[93%] ">
                                <FileUpload
                                  handleOnFileUpload={(files: any) => {
                                    setFieldValue(
                                      `shapes[${index}].geoJSON`,
                                      files[0]
                                    );
                                    handleFileParsing(files[0], index);
                                  }}
                                  error={fieldsErrors?.geoJSON}
                                  touched={fieldsTouched?.geoJSON}
                                  values={values.shapes[index].geoJSON}
                                />
                                <TextInput
                                  label="Shape name*"
                                  type="text"
                                  placeholder="Shape name"
                                  name={`shapes[${index}].name`}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values?.shapes[index]?.name}
                                  touched={fieldsTouched?.name}
                                  error={fieldsErrors?.name}
                                />
                                <div className="flex flex-col items-start justify-start w-full mb-2">
                                  <CustomSelect
                                    data={SHAPE_TYPES}
                                    placeholder="Select road, trails, river or stream , view"
                                    label="Type"
                                    name={`shapes[${index}].shapeType`}
                                    handleOnChange={(selectedOpt: any) =>
                                      setFieldValue(
                                        `shapes[${index}].shapeType`,
                                        selectedOpt.value
                                      )
                                    }
                                    touched={fieldsTouched?.shapeType}
                                    error={fieldsErrors?.shapeType}
                                  />
                                </div>
                                <TextInput
                                  label="Description*"
                                  type="text"
                                  rows={3}
                                  fieldAs="textarea"
                                  placeholder="Description"
                                  name={`shapes[${index}].description`}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values.shapes[index].description}
                                  error={fieldsErrors?.description}
                                  touched={fieldsTouched?.description}
                                />
                              </div>
                              <button
                                type="button"
                                className="absolute right-0 flex items-center justify-center flex-grow-0 flex-shrink-0 top-4 rounded-3xl"
                                onClick={() => {
                                  remove(index);
                                  setGeoJsonData((prevData: any) => prevData.filter((item: any, idx: number) => idx !== index));
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </div>

                            <div className="w-full mt-4 mb-4">
                              <div>
                                <p
                                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${fieldsErrors?.touched && fieldsErrors?.color
                                    ? "text-accent_1Dark dark:text-accent_1Dark"
                                    : ""
                                    }`}
                                >
                                  Select Fill Color
                                </p>
                                <ColorPicker
                                  handlePicker={(color: string) =>
                                    setFieldValue(`shapes[${index}].color`, color)
                                  }
                                  color={values.shapes[index].color}
                                />
                              </div>
                              {fieldsErrors?.color && (
                                <p
                                  className={`flex-grow text-xs text-left   ${fieldsErrors?.color
                                    ? "text-accent_1Dark dark:text-accent_1Dark"
                                    : "text-textMidLight dark:text-textMain"
                                    } `}
                                >
                                  {fieldsErrors.color}
                                </p>
                              )}
                            </div>
                            {shapeType !== "LineString" && <div className="w-full mt-4 mb-4">
                              <div>
                                <p
                                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${fieldsErrors?.touched && fieldsErrors?.borderColor
                                    ? "text-accent_1Dark dark:text-accent_1Dark"
                                    : ""
                                    }`}
                                >
                                  Select Border Color
                                </p>
                                <ColorPicker
                                  handlePicker={(color: string) =>
                                    setFieldValue(`shapes[${index}].borderColor`, color)
                                  }
                                  color={values.shapes[index].borderColor}
                                />
                              </div>
                              {fieldsErrors?.borderColor && (
                                <p
                                  className={`flex-grow text-xs text-left   ${fieldsErrors?.borderColor
                                    ? "text-accent_1Dark dark:text-accent_1Dark"
                                    : "text-textMidLight dark:text-textMain"
                                    } `}
                                >
                                  {fieldsErrors.borderColor}
                                </p>
                              )}
                            </div>}
                          </div>
                        );
                      })}
                    </div>
                    <button
                      disabled={isSubmitting}
                      onClick={() =>
                        push({
                          name: "",
                          description: "",
                          geoJSON: null,
                          color: "#FF0000",
                          borderColor: "#FF0000"
                        })
                      }
                      type="button"
                      className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl"
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 5C12.5523 5 13 5.44772 13 6V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11L11 11V6C11 5.44772 11.4477 5 12 5Z"
                          fill="#005C89"
                        />
                      </svg>
                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0  pt-1.5 pb-2">
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
                          Add another one
                        </p>
                      </div>
                    </button>
                  </div>
                  <div className="flex justify-between gap-2">
                    <Button
                      type="reset"
                      text="Cancel"
                      disabled={isSubmitting}
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2 w-[48.5%]"
                      width="[48.t%]"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={goBackToAllLayersScreen}
                    />
                    <Button
                      type="submit"
                      text="Save"
                      disabled={isSubmitting || !communities.canCreateGeographyCommunities}
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2 w-[48.5%]"
                      width="[48.5%]"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { UploadForm };

const ACCEPTED_FILES_TYPES = {
  "application/geo+json": [".geojson"],
  "application/vnd.google-earth.kml+xml": [".kml"],
  "application/octet-stream": [".shp"],
  "application/zip": [".zip"]
};


const FileUpload = ({ handleOnFileUpload, error, touched, values }: any) => {
  return (
    <div className="w-full py-2">
      <Dropzone
        onDrop={handleOnFileUpload}
        multiple={false}
        accept={ACCEPTED_FILES_TYPES}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          const fileName = values
            ? values.name
            : "Upload GeoJson, Shape or Zipped Shapefiles, or KML File";

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${error && touched
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
                  } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p className="text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain">
                  {fileName}
                </p>
                <CloudUploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {touched && error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};

const FilesDragAndDrop = ({ push, remove }: any) => {
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        push({
          name: "",
          description: "",
          shapeType: "",
          geoJSON: file,
          color: "#FF0000",
          borderColor: "#FF0000"
        });
      });
    },
    [push]
  );

  return (
    <Dropzone onDrop={handleOnDropFiles} accept={ACCEPTED_FILES_TYPES}>
      {({ getRootProps, getInputProps }) => (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="flex flex-col items-center self-stretch justify-center flex-grow-0 flex-shrink-0 px-4 py-2 bg-white border border-dashed rounded-lg hover:cursor-shapeer border-lineDark"
          >
            <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <input {...getInputProps()} />
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg ">
                <CloudUploadIcon />
              </div>
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                <div className="flex justify-center items-center flex-grow  px-1 pt-1.5 pb-2">
                  <p className="flex-grow w-[368px] text-sm text-center text-textMid dark:text-textMain">
                    Drag and drop your GeoJSON , Shape Or Kml files here or
                    click in this area
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dropzone>
  );
};

const CustomSelect = ({
  data,
  placeholder,
  label,
  handleOnChange,
  name,
  error,
  touched,
}: any) => {
  const [selectedOpt, setSelectedOpt] = useState<any>(null);
  const [doShowMenu, setDoShowMenu] = useState(false);

  const handleClickOnOption = (opt: any) => {
    setSelectedOpt(opt);
    handleOnChange({ value: opt.value, name });
    toggleMenu();
  };
  const toggleMenu = () => {
    setDoShowMenu(!doShowMenu);
  };
  return (
    <div className="flex flex-col w-full">
      <label className="w-full pb-1 text-sm text-left capitalize text-secondaryMidLight dark:text-caption">
        {label}
      </label>
      <div className="relative">
        <button
          type="button"
          className={`border ${error && touched
            ? "border-accent_1Dark"
            : "border-lineDark dark:border-lineLight"
            } px-3 py-2 rounded flex justify-between items-center cursor-shapeer w-full`}
          onClick={toggleMenu}
        >
          <span
            className={`${selectedOpt
              ? "text-secondary dark:text-textMain"
              : "text-textLightExtra dark:text-caption"
              } text-base`}
          >
            {selectedOpt && selectedOpt.label ? selectedOpt.label : placeholder}
          </span>
          <CheveronDownIcon className="w-6 text-secondary" />
        </button>
        {touched && error && (
          <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark pt-2">
            {error}
          </p>
        )}
        {doShowMenu && (
          <div className="absolute z-50 flex flex-col items-start w-full py-2 border rounded font-Overpass border-lineDark dark:border-lineLight bg-bgWhite dark:bg-secondaryLight ">
            {data.map((opt: any, index: number) => {
              return (
                <button
                  key={index.toString()}
                  onClick={() => handleClickOnOption(opt)}
                  className={`w-full px-4 py-1 text-left text-secondaryMid ${selectedOpt && selectedOpt.value === opt.value
                    ? "dark:bg-primary_40 dark:text-textMain"
                    : ""
                    } dark:hover:bg-primary_40 dark:text-caption`}
                  type="button"
                >
                  {opt.label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
