import React from 'react';
import DocumentPreviewIcon from 'assets/icons/HeroIcons/DocumentPreviewIcon';

interface DocumentThumbnailProps {
  url: string;
  fileName: string;
}

const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({
  url,
  fileName,
}) => {
  return (
    <div className=' flex gap-2 items-center mx-5'>
      <div>
        <DocumentPreviewIcon />
      </div>

      <p className='text-sm text-[#353535] dark:text-textMain font-medium font-Overpass'>
        {fileName}
      </p>
    </div>
  );
};

export default DocumentThumbnail;
