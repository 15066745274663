import { apiClient } from "../config";

async function responseMembersRequests(userData: any) {
  const res = await apiClient.put(
    `/admin/programs/response-program-request/${userData.programId}`,
    {
      requestResponse: userData.request,
      requesterId: userData.userId,
    }
  );
  return res.data;
}

export default responseMembersRequests;
