import { apiClient } from "../config";

async function inviteDataEndViewer(programId: any, data: any) {
  const personData = {
    person: {
      firstName: data.firstName,
      lastName: data.lastName,
    },
    user: {
      accessUntil: "2024-10-12",
      email: data.email,
    },
  };

  const response = await apiClient.post(
    `/admin/programs/${programId}/external-end-data-viewer`,
    personData
  );

  return response.data;
}

export default inviteDataEndViewer;
