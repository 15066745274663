import { camelCase } from "change-case";
import React, { useEffect, useState } from "react";
import { sentenceCase } from "change-case";

export const Statuses = ({ cellValue }: { cellValue: any }) => {
  const [changeColor, setChangeColor] = useState<any>();
  useEffect(() => {
    if (cellValue) {
      changeStatusColor(cellValue);
    }
  }, [cellValue]);
  const changeStatusColor = (cellValue: any) => {
    const camelCaseStatus = camelCase(cellValue);
    switch (camelCaseStatus) {
      case "active":
        setChangeColor("bg-primaryExtraLight text-textLink");
        break;
      case "inActive":
        setChangeColor("bg-brandHover text-secondaryMid");
        break;
      case "suspended":
        setChangeColor("bg-accent_1LightExtraMid text-accent_tertiary_red");
        break;
      default:
        setChangeColor("bg-primaryExtraLight text-textLink");
        break;
    }
  };
  return (
    <div
      style={{ flexWrap: "wrap" }}
      className=" flex flex-col w-[170px] justify-start items-start relative gap-0.5"
    >
      <p
        className={`text-sm px-2 py-1 text-left break-all capitalize text-sm rounded-md ${changeColor}`}
      >
        {sentenceCase(cellValue)}
      </p>
    </div>
  );
};
