import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useNavigate } from "react-router-dom";
import { openNotificationsSummaryModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import "../../../Table/Notifications.scss"

export const TableBody = ({
  table,
  columnVisibility,
  getNotificationsActions,
  flexRender,
}: TableBodyProps) => {
  const dispatch = useDispatch();
  const openNotificationDetails = (row: any) => {
    dispatch(openNotificationsSummaryModalAction(row.id))
  };
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className="cursor-pointer Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
            style={{ height: "66px" }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  openNotificationDetails={() =>
                    openNotificationDetails(row?.original)
                  }
                />
              )
            )}
            <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
              <RowsActions
                row={row}
                getNotificationsActions={getNotificationsActions}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
