import { useEffect } from "react";
import {
  __deletePaintedLayers__,
  __paintShapeByGeoJsonOnMap__,
} from "../workerMehods";
import { generatingLayerId } from "../utils";
import { convertGeoJSON } from "utils/convertFeatureCollection";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR ="#FFA500";

const usePaintLayers = (
  regionData: null | any[],
  map: any,
  isMapFullyLoaded: boolean
) => {
  useEffect(() => {
    if (!regionData || !map || !isMapFullyLoaded) return;

    __deletePaintedLayers__(map, "region");

    regionData.forEach((dataItem: any) => {
      // console.log(dataItem, "log");
      if (dataItem && dataItem.type === "raster") return;

      const { sourceId, layerId } = generatingLayerId("region", dataItem.id);

      const geoJson = convertGeoJSON(dataItem.mapData);
      console.log("dataItem", dataItem.color)

      if (geoJson && !map.getLayer(layerId) && !map.getSource(sourceId)) {
        // paint the layer
        __paintShapeByGeoJsonOnMap__(
          map,
          sourceId,
          layerId,
          geoJson,
          dataItem.color ?? LAYER_DEFAULT_COLOR
        );
      }
    });
  }, [regionData, map, isMapFullyLoaded]);

  return 0;
};

export { usePaintLayers };
