export interface IDataAccessPolicy {
  lastUpdated: string;
  policyContent: string;
  programStatus: string;
  attachments: any[];
}

export const initialState: IDataAccessPolicy = {
  lastUpdated: "",
  policyContent: "",
  programStatus: "",
  attachments: [],
};
