import { Ifetch } from "../initialState";

export interface IReportNotifications {
  description: string;
  duration: string;
  reportId: number;
}

const reportNotifications = {
  description: "",
  duration: "",
  reportId: 0,
};

export interface IfetchReportNotifications extends Ifetch {
  data: IReportNotifications[];
}

const reportNotificationsInitialState: IfetchReportNotifications = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default reportNotificationsInitialState;
