import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const FolderSharedKpiIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
    {...restProps}>
    <path
      fill={fill}
      className={`dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      d="M2 7a3 3 0 0 1 3-3h6a1 1 0 0 1 .707.293L13.414 6H19a3 3 0 0 1 3 3v2a1 1 0 1 1-2 0V9a1 1 0 0 0-1-1h-6a1 1 0 0 1-.707-.293L10.586 6H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3V7Z"
    />
    <path
      fill={fill}
      className={`dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      fillRule="evenodd"
      d="M15.002 17c0 .675-.224 1.299-.6 1.8a4.01 4.01 0 0 1 1.372 1.867 1 1 0 1 1-1.886.666 2.001 2.001 0 0 0-3.773 0 1 1 0 1 1-1.886-.666A4.01 4.01 0 0 1 9.601 18.8a3 3 0 1 1 5.4-1.8Zm-4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      className={`dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      d="M18.002 15a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3ZM18.002 19a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Z"
    />
  </svg>
);
export default FolderSharedKpiIcon;
