import apiClient from "services/apiClient";

async function createSurveyForm(data:any) {
  const url = `/admin/survey-and-polls`;


  const res = await apiClient.post(url, data);

  return res.data;
}

export default createSurveyForm;