import React from 'react';
import SearchIcon from 'assets/icons/HeroIcons/SearchIcon';
import CloseIcon from '../Modals/OrganizationColumnModal/CloseIcon';

interface SearchInputProps {
  type: string;
  placeholder?: string;
  className?: string;
  name?: string;
  inputValue?: string;
  handleOnChange?: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  type,
  placeholder,
  className,
  name,
  inputValue,
  handleOnChange,
  ...restProps
}) => {
  const handleClearInput = () => {
    if (handleOnChange) {
      handleOnChange(''); // Clear the input value
    }
  };

  return (
    <div
      className={`flex justify-start items-center w-full focus:outline-none gap-1.5 px-3 py-2 rounded bg-white border border-lineDark dark:border-lineLight ${className}`}
    >
      <button type='button' className='focus:outline-none'>
        <SearchIcon />
      </button>
      <input
        type={type}
        className='h-full w-full focus:outline-none dark:text-inputText dark:bg-secondaryLight'
        name={name}
        value={inputValue}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange && handleOnChange(e.target.value ?? '')
        }
        {...restProps}
      />

      {inputValue && (
        <button
          type='button'
          onClick={handleClearInput}
        >
          <CloseIcon/>
        </button>
      )}
    </div>
  );
};

export default SearchInput;
