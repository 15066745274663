function updateElementByIdFromArray(
  array: any,
  element: any,
  cb: CallableFunction
) {
  const updatedArray = [...array];

  const currentEleIndex = updatedArray.findIndex(
    (s: any) => s.id === element.id
  );

  if (currentEleIndex >= 0) {
    updatedArray[currentEleIndex] = element;

    cb(updatedArray);
  }
}

export default updateElementByIdFromArray;
