import { snakeCase } from "change-case";
import { apiClient } from "../config";

async function getReportsAnalyticsFilterDropdown(
  reportId: any,
  page: number,
  entity: any,
  search?: string
) {
  const params = {
    entity: snakeCase(entity),
    searchString: search,
    page: page,
  };
  const res = await apiClient.get(
    `/admin/reports/${reportId}/analytics-dropdown`,
    {
      params,
    }
  );
  return res.data;
}

export default getReportsAnalyticsFilterDropdown;
