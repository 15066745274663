interface User {
  activeOn: string;
  address: string;
  apartment: string;
  city: string;
  communities: number;
  country: string;
  email: string;
  usedApps: any;
  firstName: string;
  forms: string;
  id: string | null;
  lastActive: string;
  lastName: string;
  memberSince: string;
  contactPreference: string;
  observations: string;
  personId: number;
  personImage: {
    file: {
      original: string;
      thumb: string;
    };
  };
  programs: number;
  role: string;
  state: string;
  status: string;
  virtual: boolean;
  zipCode: string;
  openAccess: boolean;
}

export interface UsersData {
  fields: string[];
  totalItems: number;
  totalPages: number;
  users: User[];
}
export interface IfetchUsers {
  data: UsersData;
  stateIs: "Idle" | "Pending" | "Error";
  message: string;
}

const initialState: IfetchUsers = {
  data: {
    fields: [],
    totalItems: 0,
    totalPages: 0,
    users: [],
  },
  stateIs: "Idle",
  message: "",
};
export default initialState;
