import React from "react";
// Components
import {
  Date,
  Search,
  SelectedPrograms,
  SelectedCommunities,
  SelectedObservers,
  SelectedReviewers,
  SelectedStatuses,
} from "./Components";

// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { qualityAssuranceObservationsResetFiltersAction } from "store/filters/reducer.actions";
import {
  filtersInitialState,
  qualityAssuranceObservationsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting programsFilter state from Redux store
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const dispatch = useDispatch();

  const clearAllFilters = () => {
    dispatch(qualityAssuranceObservationsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyQualityAssuranceObservationsFilterApplied(
        qualityAssuranceObservationsFilter
      ) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Search />
          <SelectedStatuses />
          <SelectedObservers />
          <SelectedPrograms />
          <SelectedCommunities />
          <SelectedReviewers />
          <Date
            name="date"
            from={qualityAssuranceObservationsFilter.dateUpdated.from}
            to={qualityAssuranceObservationsFilter.dateUpdated.to}
            isApplied={qualityAssuranceObservationsFilter.dateUpdated?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

// Function to check if any filter is applied
export function isAnyQualityAssuranceObservationsFilterApplied(
  qualityAssuranceObservationsFilter: qualityAssuranceObservationsDirectoryFilterState
): boolean {
  const {
    searchBy,
    searchString,
    dateUpdated,
    communityIds,
    programIds,
    observerIds,
    reviewerIds,
    filterStatus,
  } = qualityAssuranceObservationsFilter;
  const applied =
    (searchBy && searchString !== "") ||
    dateUpdated?.applied ||
    programIds?.applied ||
    observerIds?.applied ||
    communityIds?.applied ||
    reviewerIds?.applied ||
    filterStatus?.applied;
  return applied;
}
