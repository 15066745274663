import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_STATE } from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- NOTIFICATIONS
 * @returns
 */
export const fetchNotifications =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_NOTIFICATIONS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      // removing extra params
      if (params.formIds) {
        delete params.formIds;
      }
      if (params.datePeriod) {
        delete params.datePeriod;
      }

      const { data } =
        await apiLibrary.Dashboard.widgets.getNotifications(params);
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: { data },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_NOTIFICATIONS_STATE,
        payload: {
          stateIs: "Error",
        },
      });

      console.log("An error occurred while fetching the notifications:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
