import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  className?: string;
}

export const CheveronRightIcon = ({
  fill,
  isActive,
  className,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={` ${className ? className : "flex-grow-0 flex-shrink-0 w-6 h-5 relative"}`}
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${isActive ? "fill-primary" : "fill-caption group-hover:fill-primary"}`}
      fillRule="evenodd"
      d="M8.293 4.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414L14.586 12 8.293 5.707a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheveronRightIcon;
