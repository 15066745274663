import { generatingLayerId } from "./generatingLayerId";

function getCenterCoordinatesForRasterLayer(layer: any, map: any) {
  const {  layerId } = generatingLayerId(
    layer.itemType,
    layer.item.id
  );

  const geojsonData = map.getLayer(layerId);

  // const center = geojsonData?._eventedParent?.stylesheet?.center;

    const center = geojsonData._eventedParent.map._listeners.zoom[0]().transform._center;  
  // map.setZoom(geojsonData?._eventedParent?.z);


  return [center.lng,center.lat];
}

export { getCenterCoordinatesForRasterLayer };
