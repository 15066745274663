import React from "react";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import BpCheckbox from "view/components/CheckBox";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import useDateRange from "hooks/useDateRange";
import { notificationsScheduledForAction } from "store/filters/reducer.actions";

interface DateFilterProps {
  name: any;
  scheduledFor: string;
}

export const DateFilter: React.FC<DateFilterProps> = ({ scheduledFor }) => {
  const dispatch = useDispatch();
  const formatDate = (date: any) => moment(date).format(DATE_FORMATS.DEFAULT);

  const handleDateInputChange = (
    event: any
  ) => {
    const selectedDate = new Date(event);
    const formattedDate = formatDate(selectedDate);
    handleDateChange(formattedDate);
  };

  const handleDateChange = (scheduledDate: any) => {
    dispatch(notificationsScheduledForAction(scheduledDate));
  };

  return (
    <div className="flex py-2 pl-2 shadow-xl bg-bgWhite dark:bg-secondaryLight rounded-xl">
      <div className=" w-[255px] flex flex-col justify-start items-start p-3">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <InputField
              type="date"
              className={"uppercase"}
isForFilters={true}
              label="Scheduled for"
              placeholder="YYYY/MM/DD"
              value={scheduledFor}
              onChange={(event: any) => handleDateInputChange(event)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
