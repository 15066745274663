import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
// Components
import SearchInput from "view/components/SearchInput";
import {
  RasterLayerBox,
  VectorLayerBox,
  RegionLayerBox,
  ShapeLayerBox,
  PointLayerBox,
  ListBtn,
  MapBtn,
} from "../index";
import { CustomDropdown } from "view/components/CustomDropdown";
// APIs services
// Third party services
import { useNavigate, useParams } from "react-router-dom";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  clearSelectedLayersItems,
  doFetchAllLayers,
  resetMap,
  saveAllLayersFetchedData,
  updateLayersDataType,
  updateShowLoaderLayer,
} from "store/geography";
import { useDispatch } from "react-redux";
import { screens } from "store/geography/initialState";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { toast } from "react-toastify";
import usePermissions from "hooks/usePermissions";
import { TailSpin } from "react-loader-spinner";
import useDebouncedValue from "hooks/useDebouncedValue";
import { getFiltersDataFromLocalStorage, saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

// Types
export type T_RasterLayersData = {
  id: number;
  legendFilePath: URL;
  mapData: {
    snippet: URL;
    x: number;
    y: number;
    z: number;
  };
  name: string;
};
export type T_VectorLayersData = {
  id: number;
  legendFilePath: URL;
  mapData: {
    snippet: URL;
    x: number;
    y: number;
    z: number;
  };
  name: string;
};
export type T_RegionLayersData = {
  id: number;
  legendFilePath: URL;
  mapData: {
    snippet: URL;
    x: number;
    y: number;
    z: number;
  };
  name: string;
};
export type T_ShapeLayersData = {
  id: number;
  legendFilePath: URL;
  mapData: {
    snippet: URL;
    x: number;
    y: number;
    z: number;
  };
  name: string;
};
export type T_PointLayerData = {
  id: number;
  legendFilePath: URL;
  mapData: {
    snippet: URL;
    x: number;
    y: number;
    z: number;
  };
  name: string;
};
interface T_AllLayersData {
  raster: T_RasterLayersData[] | [];
  publicRaster: T_RasterLayersData[] | [];
  vector: T_VectorLayersData[] | null;
  publicVector: T_VectorLayersData[] | [];
  point: T_PointLayerData[] | [];
  region: T_RegionLayersData[] | [];
  shape: T_ShapeLayersData[] | [];
}

export type CurrentOpenToggleType =
  | "publicVector"
  | "publicRaster"
  | "vector"
  | "raster"
  | "region"
  | "shape"
  | "point"
  | "";

const pagesInitialState = {
  publicRaster: 1,
  publicVector: 1,
  raster: 1,
  vector: 1,
  region: 1,
  shape: 1,
  point: 1,
};

const LayersListing = () => {
  const [entityType, setEntityType] = useState<string | null>(null);
  const [page, setPage] = useState<any>(pagesInitialState);
  const [currentOpenToggle, setCurrentOpenToggle] =
    useState<CurrentOpenToggleType>("");
  const [searchQuery, setSearchQuery] = useState<any>(() => {
    const storedFilters = getFiltersDataFromLocalStorage();
    return storedFilters?.filters?.searchString || null;
  });
  const debouncedSearchTerm = useDebouncedValue(searchQuery, 500);

  const { communityId } = useParams<{ communityId: string }>();

  const {
    allLayers,
    doFetchAllLayersData,
    layersDataType,
    showLoaderLayer,
    selectedLayersItems,
  } = useSelector((state: RootState) => state.geography);

  const { communities } = usePermissions();
  const { geographies } = usePermissions();

  const [actionsList, setActionsList] = useState([
    {
      key: "edit",
      label: "Edit",
      isVisible:
        communities.canEditGeographyCommunities ||
        geographies.canEditGeographies,
    },
    {
      key: "viewInformation",
      label: "View Information",
      isVisible: true,
    },
    {
      key: "archive",
      label: "Archive",
      isVisible: true,
    },
    {
      key: "restore",
      label: "Restore",
      isVisible: false,
    },
  ]);
  const [loading, setLoading] = useState<null | string>(null);

  const dispatch = useDispatch();

  const dataLimit = 10;
  const layers = allLayers;
  const isCommunityDirectory = !!communityId;

  useEffect(() => {
    // Set the state with the new array
    setActionsList((pre: any) => {
      return pre.map((item: any) => {
        if (item.key === "archive") {
          return { ...item, isVisible: layersDataType !== "archive" };
        } else if (item.key === "restore") {
          return { ...item, isVisible: layersDataType === "archive" };
        } else {
          return item;
        }
      });
    });

    // reset the pages state
    setPage(pagesInitialState);
    setEntityType(null);
  }, [layersDataType]);

  // fetching layers on changing data modes (archive or active) and on searching layer
  useEffect(() => {
    if (!doFetchAllLayersData) return;

    const isArchived = layersDataType === "archive";
    const doShowLoader = debouncedSearchTerm === null;

    doShowLoader && dispatch(updateShowLoaderLayer(true));

    const params = {
      archived: isArchived,
      limit: dataLimit,
      searchString: debouncedSearchTerm,
    };

    const fetchLayers = isCommunityDirectory
      ? apiLibrary.geography.getAllLayers(communityId, params)
      : apiLibrary.geography.getAllLayersForAdmins(params);

    fetchLayers
      .then(({ data }) => {
        dispatch(saveAllLayersFetchedData(data));
        findLayerOfHighestDataLength(data, setCurrentOpenToggle);
        setPage(() => ({ ...pagesInitialState }));
      })
      .catch((err) => {
        Toasts.error(err.message);
      })
      .finally(() => {
        dispatch(updateShowLoaderLayer(false));
        dispatch(doFetchAllLayers(false));
      });
  }, [communityId, layersDataType, doFetchAllLayersData]);

  // fetching paginated layers
  useEffect(() => {
    if (!entityType) return;

    const isArchived = layersDataType === "archive";

    // if current page of paginated entity equal to its limited page range
    // then end the data fetching process
    if (
      (entityType === "raster" &&
        allLayers?.raster?.totalPages < page?.raster) ||
      (entityType === "vector" &&
        allLayers?.vector?.totalPages < page?.vector) ||
      (entityType === "publicRaster" &&
        allLayers?.publicRaster?.totalPages < page?.publicRaster) ||
      (entityType === "publicVector" &&
        allLayers?.publicVector?.totalPages < page?.publicVector) ||
      (entityType === "region" &&
        allLayers?.region?.totalPages < page?.region) ||
      (entityType === "shape" && allLayers?.shape?.totalPages < page?.shape) ||
      (entityType === "point" && allLayers?.point?.totalPages < page?.point)
    ) {
      return;
    }

    const params: any = {
      archived: isArchived,
      limit: dataLimit,
      page: page[entityType ?? ""],
      entityType,
    };

    if (debouncedSearchTerm !== null) {
      params.searchString = debouncedSearchTerm;
    }

    const fetchLayers =
      isCommunityDirectory &&
        entityType !== "publicVector" &&
        entityType !== "publicRaster"
        ? apiLibrary.geography.getAllLayers(communityId, params)
        : apiLibrary.geography.getAllLayersForAdmins(params);

    setLoading(entityType);
    fetchLayers
      .then(({ data }) => {
        // appending new data to its relevant entity
        const layerMap: any = {
          raster: "raster",
          vector: "vector",
          region: "region",
          shape: "shape",
          point: "point",
          publicRaster: "publicRaster",
          publicVector: "publicVector",
        };

        if (layerMap[entityType]) {
          const updatedLayers = [
            ...allLayers[layerMap[entityType]].data,
            ...data,
          ];
          allLayers[layerMap[entityType]].data = updatedLayers;
        }

        dispatch(saveAllLayersFetchedData(allLayers));
      })
      .catch((err) => {
        Toasts.error(err.message);
      })
      .finally(() => {
        setLoading(null);
      });
  }, [communityId, entityType, page]);

  // handling search state on change
  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      if (entityType) setEntityType(null);
      dispatch(doFetchAllLayers(true));
      dispatch(clearSelectedLayersItems());
      dispatch(resetMap(true));
    }
  }, [debouncedSearchTerm]);

  // reseting selected layers global state on leaving the main listing screen
  useEffect(() => {
    return () => {
      dispatch(clearSelectedLayersItems());
      dispatch(updateLayersDataType("active"));
    };
  }, []);

  // handlers
  const handleSearchFieldOnChange = async (query: string) => {
    setSearchQuery(query);
    saveFiltersDataToLocalStorage({ searchString: query });
  };
  const handleOnStatusChange = async (type: "active" | "archive") => {
    await dispatch(updateLayersDataType(type));
    dispatch(doFetchAllLayers(true));
    dispatch(resetMap(true));
  };

  return (
    <>
      {showLoaderLayer ? (
        <div className="h-full w-full bg-[#ffffff8c] absolute top-0 left-0 z-10 flex flex-col items-center justify-center">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="mb-6">
            <SearchInput
              type="text"
              placeholder="Search"
              handleOnChange={handleSearchFieldOnChange}
              inputValue={searchQuery}
            />
          </div>
          <div className="mb-3">
            <Filter handleOnStatusChange={handleOnStatusChange} />
          </div>
          <div className="overflow-y-auto ">
            {actionsList && (
              <>
                <div className="pb-2 border-bgBlack_75">
                  {isCommunityDirectory && (
                    <h1 className="text-[17px] dark:text-textMain mb-2 font-semibold">
                      Global Layers
                    </h1>
                  )}
                  <RasterLayerBox
                    data={layers?.publicRaster?.data}
                    actionsList={actionsList}
                    global={true}
                    setPage={setPage}
                    setEntityType={setEntityType}
                    entityType={entityType}
                    totalPages={layers?.publicRaster?.totalPages}
                    page={page}
                    totalItems={layers.publicRaster?.totalItems}
                    loading={loading === "publicRaster"}
                    currentOpenToggle={currentOpenToggle}
                  // setCurrentOpenToggle={setCurrentOpenToggle}
                  />
                  {((isCommunityDirectory &&
                    communities.canViewVectorLayersCommunities) ||
                    !isCommunityDirectory) && (
                      <VectorLayerBox
                        data={layers?.publicVector?.data}
                        actionsList={actionsList}
                        global={true}
                        totalPages={layers?.publicVector?.totalPages}
                        page={page}
                        setPage={setPage}
                        setEntityType={setEntityType}
                        entityType={entityType}
                        totalItems={layers?.publicVector?.totalItems}
                        loading={loading === "publicVector"}
                        currentOpenToggle={currentOpenToggle}
                      // setCurrentOpenToggle={setCurrentOpenToggle}
                      />
                    )}
                </div>
                <div>
                  {isCommunityDirectory && (
                    <h1 className="text-[17px] dark:text-textMain mb-2 font-semibold">
                      Community Layers
                    </h1>
                  )}
                  {isCommunityDirectory && (
                    <RasterLayerBox
                      data={layers?.raster?.data}
                      totalPages={layers?.raster?.totalPages}
                      actionsList={actionsList}
                      global={false}
                      page={page}
                      setPage={setPage}
                      setEntityType={setEntityType}
                      entityType={entityType}
                      totalItems={layers.raster?.totalItems}
                      loading={loading === "raster"}
                      currentOpenToggle={currentOpenToggle}
                    // setCurrentOpenToggle={setCurrentOpenToggle}
                    />
                  )}
                  {isCommunityDirectory &&
                    communities.canViewVectorLayersCommunities && (
                      <VectorLayerBox
                        data={layers?.vector?.data}
                        actionsList={actionsList}
                        global={false}
                        totalPages={layers?.vector?.totalPages}
                        page={page}
                        setPage={setPage}
                        setEntityType={setEntityType}
                        entityType={entityType}
                        totalItems={layers?.vector?.totalItems}
                        loading={loading === "vector"}
                        currentOpenToggle={currentOpenToggle}
                      // setCurrentOpenToggle={setCurrentOpenToggle}
                      />
                    )}
                  {isCommunityDirectory && (
                    <>
                      {communities.canViewRegionsCommunities && (
                        <RegionLayerBox
                          data={layers?.region?.data}
                          actionsList={actionsList}
                          totalPages={layers?.region?.totalPages}
                          page={page}
                          setPage={setPage}
                          setEntityType={setEntityType}
                          entityType={entityType}
                          totalItems={layers?.region?.totalItems}
                          loading={loading === "region"}
                          currentOpenToggle={currentOpenToggle}
                        // setCurrentOpenToggle={setCurrentOpenToggle}
                        />
                      )}
                      <ShapeLayerBox
                        data={layers?.shape?.data}
                        actionsList={actionsList}
                        totalPages={layers?.shape?.totalPages}
                        page={page}
                        setPage={setPage}
                        setEntityType={setEntityType}
                        entityType={entityType}
                        totalItems={layers?.shape?.totalItems}
                        loading={loading === "shape"}
                        currentOpenToggle={currentOpenToggle}
                      // setCurrentOpenToggle={setCurrentOpenToggle}
                      />
                      <PointLayerBox
                        data={layers?.point?.data}
                        actionsList={actionsList}
                        totalPages={layers?.point?.totalPages}
                        page={page}
                        setPage={setPage}
                        setEntityType={setEntityType}
                        entityType={entityType}
                        totalItems={layers?.point?.totalItems}
                        loading={loading === "point"}
                        currentOpenToggle={currentOpenToggle}
                      // setCurrentOpenToggle={setCurrentOpenToggle}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { LayersListing };

const Filter = ({ handleOnStatusChange }: any) => {
  const list = [
    { label: "Active", isVisible: true },
    { label: "Archive", isVisible: true },
  ];

  const navigate = useNavigate();
  const [doShowMenu, setDoShowMenu] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(list[0].label);
  const { communityId } = useParams<{ communityId: string }>();

  const { layersDataType } = useSelector((state: RootState) => state.geography);

  useEffect(() => {
    if (layersDataType) {
      setSelectedOpt(layersDataType === "active" ? "Active" : "Archive");
    }
  }, []);

  // Handlers
  const handleClickOnMenuItem = (item: any) => {
    if (item.label.toLowerCase() === layersDataType) return;
    setSelectedOpt(item.label);
    handleOnStatusChange(item.label.toLowerCase());

    const state = { redirectFromKPI: false };
    navigate(window.location.pathname, { state });
  };

  return (
    <div className="flex items-center justify-between w-full">
      <CustomDropdown
        isOpenDropdown={doShowMenu}
        setIsOpenDropdown={setDoShowMenu}
        menuList={list}
        TabComponent={({ handleOnClick }: any) => (
          <DropdownTab handleOnClick={handleOnClick} label={selectedOpt} />
        )}
        handleClickOnMenuItem={handleClickOnMenuItem}
        openFromLeft={true}
      />
      {!communityId && (
        <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
          <ListBtn />
          <MapBtn />
        </div>
      )}
    </div>
  );
};

const DropdownTab = ({ handleOnClick, label }: any) => {
  return (
    <button onClick={handleOnClick} className="flex items-center gap-2">
      <span className="text-sm text-secondaryMid dark:text-textMain">
        {label}
      </span>
      <CheveronDownIcon className="w-4 text-secondaryMid mb-[2px]" />
    </button>
  );
};

function findLayerOfHighestDataLength(
  data: any,
  setCurrentOpenToggle: Dispatch<SetStateAction<CurrentOpenToggleType>>
) {
  const dataKeys: any = Object.keys(data);

  let entityOfMaximumDataLength: {
    layer: CurrentOpenToggleType;
    length: number;
  } = { layer: "", length: 0 };

  dataKeys.forEach((key: CurrentOpenToggleType) => {
    const totalItems = data[key].totalItems;
    if (totalItems > entityOfMaximumDataLength.length) {
      entityOfMaximumDataLength = { layer: key, length: totalItems };
    }
  });

  setCurrentOpenToggle(entityOfMaximumDataLength.layer);
}
