import { useEffect } from "react";
import {
  useAppendCommunityToCurrentQuestion,
  useFetchAllCommunityLayers,
  useFetchAllCommunityPoints,
  useFetchAllGlobalCommunityLayers,
  useHandleDeleteOfQuestion,
  useHandleEditModeMapTypeComponents,
  useHandleStateOfMapTypeQuestionsOnAdded,
  useIdentifyCurrentQuestion,
} from "../../hooks";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "store/sideBar/reducer.actions";

// Note: handler component doesn't return any jsx, its work is to handle the logic and play with global state
const HandlerComponent = () => {
  // toggle the sidebar 
  useToggleSidebar();

  // fetching all community layers
  useFetchAllCommunityLayers();

  // fetching global community layers
  useFetchAllGlobalCommunityLayers();

  // fetching all community points
  useFetchAllCommunityPoints();

  // appending fetched communities layers and points to current added question
  useAppendCommunityToCurrentQuestion();

  // identifying current added or selected question
  useIdentifyCurrentQuestion();

  // handling edit mode for map type components (shape picker, point picker, draw polygon, drop a pin)
  useHandleEditModeMapTypeComponents();

  // reset highlighted layers property on delete of shape picker question
  useHandleDeleteOfQuestion();

  // handle state of map type questions on creaton 
  useHandleStateOfMapTypeQuestionsOnAdded();

  return <></>;
};

export { HandlerComponent };

const useToggleSidebar = ()=>{
  const dispatch:any = useDispatch();
 
  useEffect(()=>{
    dispatch(toggleSidebar(true));
    
    // return ()=>{
    //   dispatch(toggleSidebar(true));
    // }
  },[]);

  return null
}