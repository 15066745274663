import separateColorAndOpacity from "./separateColorAndOpacity";

function willColorBeVisibleOnMap(color: string) {
  if (!color) return null;

  const { opacity, color: hexColor } = separateColorAndOpacity(color);
  
  if (opacity <= 0.1 || hexColor === "#FFFFFF") {
    return false;
  }

  return true;
}
export default willColorBeVisibleOnMap;
