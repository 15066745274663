import { apiClient } from "../config";

async function getAllObservations(queryParams: any) {
  const res = await apiClient.get(`/admin/observations`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllObservations;
