import React, { useEffect } from "react";
import { Header, Table } from "Components/Files";
// Hooks
import { FilesTable } from "hooks/useTable";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchFiles } from "store/files/initialState";
import {
  fetchFilesAction,
  filesResetFilterActions,
} from "store/files/reducer.actions";
import { useParams } from "react-router-dom";
import { Filters } from "Components/Files";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import usePermissions from "hooks/usePermissions";

export const Files = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  useEffect(() => {
    dispatch(fetchFilesAction(programId, "program"));
  }, []);

  const { file } = usePermissions();

  const { programId } = useParams<{
    programId: string;
  }>();

  const { data, stateIs } = useSelector<RootState, IfetchFiles>(
    (state) => state?.allFiles
  );
  const {
    flexRender,
    columnVisibility,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnVisibility,
    setSorting,
    sorting,
  } = FilesTable(data?.files);

  // useEffect(() => {
  //   return () => {
  //     dispatch(filesResetFilterActions());
  //   };
  // }, []);
  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-[95.5%] w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs == "Idle" && data?.files?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Files;
