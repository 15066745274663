export interface IImportSummary {
  columnMapping: Array<{
    columnNameFromFile: string;
    mapToField: string;
    sampleFileContent: string;
    id: number;
  }>;
  comment: string;
  dataReceivedFrom: string;
  dynamicForm: {
    id: number;
    name: string;
  };
  failedImport: number;
  fileName: string;
  id: number;
  importedBy: {
    id: number,
    name: string
  },
  importedDate: string;
  program: {
    id: number;
    name: string;
  };
  successfulImports: number;
  totalObservations: number;
  status: string;
  filePath: string;
}

export interface IImportSummaryData {
  data: IImportSummary,
  totalItems: number;
  totalPages: number;
  message: string,
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IImportSummaryData = {
  data: {
    columnMapping: [{
      columnNameFromFile: "",
      mapToField: "",
      sampleFileContent: "",
      id: 0,

    }
    ],
    comment: "",
    dataReceivedFrom: "",
    dynamicForm: {
      id: 0,
      name: "",
    },
    failedImport: 0,
    fileName: "",
    id: 0,
    importedBy: {
      id: 0,
      name: ""
    },
    importedDate: "",
    program: {
      id: 0,
      name: "",
    },
    successfulImports: 0,
    totalObservations: 0,
    status: "",
    filePath: ""
  },
  totalItems: 0,
  totalPages: 0,
  message: "",
  stateIs: "Idle",
};

export { initialState };
