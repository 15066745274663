export interface IProfileReports {
  id: number;
  version: any;
  processedReports: string;
  timeRange: string;
  processingStatus: string;
  observationsProcessed: number;
  viewed: number;
  downloaded: number;
  dateAdded: string;
  reportId: number;
  runDateTime: string;
}
export interface ProfileReportsData {
  versions: IProfileReports[];
  dateAdded: string;
  recurrenceSettings: string;
  reportName: string;
  status: string;
  totalItems: number;
  totalPages: number;
}
export interface IfetchProfileReports {
  data: ProfileReportsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialProfileReportsData: IfetchProfileReports = {
  data: {
    versions: [],
    dateAdded: "",
    recurrenceSettings: "",
    reportName: "",
    status: "",
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
export default initialProfileReportsData;
