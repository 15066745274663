import * as React from "react";
import { SVGProps } from "react";
const ISNLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}>
    <path
      fill="#005C89"
      fillRule="evenodd"
      d="m0 0 14.43 19.036H0v2.928h14.643L0 41h41L26.357 21.964h14.641v-2.928h-14.64L41 0H0Zm26.355 21.964H14.643v-2.928h11.712v2.928ZM5.948 2.93l10.137 13.178h8.83L35.053 2.93H5.948Zm10.137 21.964L5.948 38.07h29.105L24.915 24.893h-8.83Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ISNLogo;
