import React, { useEffect, useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { closeReportsSummaryModalAction } from "store/modals/reducer.actions";
import { useNavigate } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import { SummaryHeaders } from "./components/SummaryHeaders";
import { SummaryPermissions } from "./components/SummaryPermissions";
import { SummaryGeospatialFiltering } from "./components/SummaryGeospatialFiltering";
import { SummaryFiltering } from "./components/SummaryFiltering";
import { ColumnConfigration } from "./components/ColumnConfigration";
import { ExportReport } from "./components/ExportReport";
import {
  fetchReportsSummaryProfileAction,
  reportsSummaryResetAction,
} from "store/reportsSummary/reducer.actions";
import { ISummaryReports } from "store/reportsSummary";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import useRoles from "hooks/roles";

export const ReportsSummary = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { openReportsSummaryModal } = useSelector(
    (state: RootState) => state.modals
  );
  const {reports} = usePermissions();
  const navigate = useNavigate();
  // const { reports } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const reportSummary = useSelector<RootState, ISummaryReports>(
    (state) => state?.reportSummary
  );
  const { isExternalDataEndReviewer } = useRoles();
  useLayoutEffect(() => {
    if (openReportsSummaryModal.data) {
      dispatch(fetchReportsSummaryProfileAction(openReportsSummaryModal.data));
    }
  }, [openReportsSummaryModal.data]);

  const openModal = () => {
    setModalOpen(openReportsSummaryModal?.isOpen);
  };
  const handleClose = () => {
    dispatch(closeReportsSummaryModalAction());
  };

  const handleNavigate = () => {
    navigate(`/reports/${openReportsSummaryModal?.data}/edit`, {
      state: {
        editMode: true,
      },
    });
    dispatch(closeReportsSummaryModalAction());
  };
  useEffect(() => {
    return () => {
      dispatch(reportsSummaryResetAction());
    };
  }, []);
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={openReportsSummaryModal?.isOpen}
        onClose={handleClose}
        onOpen={openModal}
      >
        <Box sx={{ width: 656 }} role="presentation" onKeyDown={handleClose}>
          <div className="flex flex-col justify-start items-start w-[656px] pl-3 pr-6 py-4 bg-bgWhite dark:bg-secondaryLight ">
            <div className="relative flex items-center self-stretch justify-between flex-grow-0 flex-shrink-0 gap-4 px-3 py-">
              <div className="flex justify-center items-center">
                <div className="cursor-pointer" onClick={handleClose}>
                  <ArrowLeftIcon height={22} width={22} />
                </div>
                <div className="flex items-center justify-start flex-grow">
                  <div className="flex justify-start items-center flex-grow relative py-0.5 cursor-default ml-3 dark:text-textMain  text-lg font-semibold">
                    Reports Summary
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                {!isExternalDataEndReviewer() && reports.canEditReports && reportSummary.status !== "archived" && (
                    <div
                      className="flex items-center gap-1 cursor-pointer"
                      onClick={handleNavigate}
                    >
                      <PencilAltIcon
                        width={24}
                        height={24}
                        fill="#54595F"
                        className="hover:text-primary"
                      />
                      <div className="mr-8 px-1 pt-1.5 pb-2">
                        <p className="text-sm text-textMid hover:text-primary  dark:text-caption ">
                          Edit
                        </p>
                      </div>
                    </div>
                )}
                <XCloseIcon
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="#2C3236"
                  onClick={handleClose}
                  className="cursor-pointer hover:text-primaryDark"
                />
              </div>
            </div>
            <div className="pl-4 pr-2 w-full">
              <SummaryHeaders
                id={reportSummary.id}
                name={reportSummary.reportName}
                createdBy={reportSummary.createdBy}
                dateCreated={reportSummary.dateCreated}
                recurrenceSettings={reportSummary.recurrenceSettings}
                programFilter={reportSummary.programsFilter}
                formFitler={reportSummary.formsFilter}
                organizationFitler={reportSummary.organizationsFilter}
                communitiesFilter={reportSummary.communitiesFilter}
              />
              <SummaryPermissions
                id={reportSummary.id}
                permissions={reportSummary.permissions}
              />
              <SummaryGeospatialFiltering
                geospatialFilter={reportSummary.geospatialFilter}
              />
              <SummaryFiltering filter={reportSummary.filters}  dateFilter={reportSummary.dateFilter} reportSetting={reportSummary.reportSetting} repeatedDateFilter={reportSummary.repeatedDateFilter}/>
              <ColumnConfigration
                columnConfigration={reportSummary.columnConfiguration}
              />
              <ExportReport allowExport={reportSummary.allowExport} />
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};
