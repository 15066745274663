import React from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import { useDispatch } from "react-redux";
import {
  openImageLightBoxModalAction,
  openReportVersionDataModalAction,
  openVideoLightBoxModalAction,
} from "store/modals";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import audioThumbnailImg from "assets/images/Placeholders/audio-placeholder.png";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useDateFormatter from "hooks/formatDateWithPattern";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const dispatch = useDispatch();
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const { formatDate } = useDateFormatter();

  function containsGroups(data: string): boolean {
    const regex = /group#\d+/;
    return regex.test(data);
  }

  const getBaseUrl = (url: string) => {
    const lastUnderscoreIndex = url.lastIndexOf("_");
    return lastUnderscoreIndex !== -1
      ? url.substring(0, lastUnderscoreIndex)
      : url;
  };

  const isImageUrl = (url: any) => {
    const baseUrl = url?.trim();
    return /\.(jpg|jpeg|png|gif|webp|svg|bmp)$/i.test(baseUrl);
  };

  const isAudioUrl = (url: any) => {
    const baseUrl = getBaseUrl(url?.trim());
    // const baseUrl = url?.trim();
    return /\.(mp3|wav|ogg|m4a)$/i.test(baseUrl);
  };

  const isVideoUrl = (url: any) => {
    // const baseUrl = getBaseUrl(url?.trim());
    const baseUrl = url?.trim();
    return /\.(mp4|avi|mkv|wmv|flv|mov)$/i.test(baseUrl);
  };

  const isPdfUrl = (url: any) => {
    const baseUrl = getBaseUrl(url?.trim());
    return /\.pdf$/i.test(baseUrl);
  };

  const renderImage = (url: any) => (
    <img
      className="inline-block rounded-full cursor-pointer Img_user_Data"
      height="100"
      width="100"
      src={url?.trim()}
      alt=""
      onClick={() => handleFile(url)}
    />
  );

  const renderAudio = (url: any) => (
    <img
      className="inline-block Img_user_Data !rounded-none cursor-pointer"
      height="100"
      width="100"
      src={audioThumbnailImg}
      alt="Audio Thumbnail"
      onClick={() => {
        const newWindow = window.open("", "_blank");
        if (newWindow) {
          newWindow.document.write(`
            <html>
              <body style="display: flex;">
                <audio controls autoplay style="margin: auto;">
                  <source src="${url?.trim()}" type="audio/${getBaseUrl(url).split(".").pop()}">
                  Your browser does not support the audio element.
                </audio>
              </body>
            </html>
          `);
          newWindow.document.close();
        }
      }}
    />
  );

  const renderThumbnail = (url: any, thumbnail: any) => {
    const fileType = url
      ?.trim()
      ?.match(/\.([a-z0-9]+)(?:[\?#]|$)/i)?.[1]
      ?.toLowerCase();
    return (
      <img
        className={`inline-block Img_user_Data cursor-pointer ${fileType === "pdf" ? "!rounded-none" : "rounded-full"}`}
        height="100"
        width="100"
        src={thumbnail}
        alt=""
        onClick={() => handleFile(url)}
      />
    );
  };

  const handleFile = (url: any) => {
    const fileType = url
      ?.trim()
      ?.match(/\.([a-z0-9]+)(?:[\?#]|$)/i)?.[1]
      ?.toLowerCase();
    if (isImageUrl(url)) {
      dispatch(
        openImageLightBoxModalAction({
          width: 1080,
          filePath: url,
        })
      );
    } else if (isPdfUrl(url)) {
      window.open(url, "_blank");
    } else if (isVideoUrl(url)) {
      dispatch(
        openVideoLightBoxModalAction({
          filePath: url,
          fileType: fileType,
        })
      );
    } else if (isAudioUrl(url)) {
      window.open(url, "_blank");
    }
  };

  const hasUrls = (value: any, typeCheckFn: (url: any) => boolean) => {
    if (typeof value !== "string") return false;
    const trimmedVal = value?.trim();
    if (trimmedVal.startsWith('"') && trimmedVal.endsWith('"')) {
      const urls = trimmedVal
        ?.slice(1, -1)
        .split(",")
        .map((url: any) => url.trim());
      return urls.some((url: any) => typeCheckFn(url));
    }

    return typeCheckFn(trimmedVal);
  };

  const renderContent = (
    value: any,
    renderFn: (url: any) => JSX.Element,
    typeCheckFn: (url: any) => boolean
  ) => {
    if (typeof value !== "string") return null;
    const trimmedVal = value?.trim();
    let urls;
    if (trimmedVal.startsWith('"') && trimmedVal.endsWith('"')) {
      urls = trimmedVal
        .slice(1, -1)
        .split(",")
        .filter((url: any) => url.trim());
    } else {
      urls = [trimmedVal];
    }

    return (
      <div
        className={`flex -space-x-2 w-[200px] py-1 px-2 ${urls.length > 1 ? "gap-2" : ""}`}
      >
        {urls.filter(typeCheckFn).map((url: any, index: any) => (
          <div key={index}>{renderFn(url)}</div>
        ))}
      </div>
    );
  };

  const getCellValue = (value: string) => {
    const newValue =
      value.startsWith('"') && value.endsWith('"') ? value.slice(1, -1) : value;

    if (containsGroups(newValue)) {
      return newValue + "...";
    }

    return ellipsize(newValue, 50);
  };

  const handleOpenDataModal = (value: string, question: string) => {
    dispatch(
      openReportVersionDataModalAction({ question: question, group: value })
    );
  };

  if (cellValue !== null && columnDef.id === "submissionDateTime") {
    const currentTimeZone = profileSettings.timezone
      ? profileSettings.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const updatedSubmitionDate = moment
      .utc(cellValue)
      .tz(currentTimeZone)
      .format("YYYY-MM-DD HH:mm:ss");

    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {updatedSubmitionDate}
        </p>
      </div>
    );
  }

  if (cellValue !== null && cellValue !== undefined) {
    return hasUrls(cellValue, isImageUrl) ? (
      renderContent(cellValue, renderImage, isImageUrl)
    ) : hasUrls(cellValue, isAudioUrl) ? (
      renderContent(cellValue, renderAudio, isAudioUrl)
    ) : hasUrls(cellValue, isVideoUrl) ? (
      renderContent(
        cellValue,
        (url) => renderThumbnail(url, videoThumbnailImg),
        isVideoUrl
      )
    ) : hasUrls(cellValue, isPdfUrl) ? (
      renderContent(
        cellValue,
        (url) => renderThumbnail(url, pdfThumbnailImg),
        isPdfUrl
      )
    ) : (
      <div className="relative flex items-start flex-grow px-3 py-1 pb-5">
        <p className="text-sm text-left text-textMid whitespace-nowrap">
          {getCellValue(cellValue)}
          {containsGroups(getCellValue(cellValue)) ? (
            <button
              className="font-medium transition text-primary hover:underline"
              onClick={() => handleOpenDataModal(cellValue, columnDef.header)}
            >
              Read More
            </button>
          ) : cellValue.length > 50 ? (
            <button
              className="font-medium transition text-primary hover:underline"
              onClick={() => handleOpenDataModal(cellValue, columnDef.header)}
            >
              Read More
            </button>
          ) : (
            ""
          )}
        </p>
      </div>
    );
  }

  return null;
};
