import { apiClient } from "../config";

async function getHorizentalBarGraph(queryParams: any) {
  const res = await apiClient.get(
    `/admin/dashboard/widget/horizontal-bar-graph`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getHorizentalBarGraph;
