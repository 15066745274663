import {
  ERROR_LOADING_OBJECTS,
  GET_ALL_OBJECTS,
  RESET_DATA,
  START_LOADING_OBJECTS,
  STOP_LOADING_OBJECTS,
} from "./action-types";
import initialState, { IObject } from "./initialState";

export const objectsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_OBJECTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_OBJECTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_OBJECTS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_OBJECTS:
      const objects = action.payload.objects.map((item: IObject) => {
        const { image, dateAdded, description, id, objectName, type } = item;
        const data = {
          dateAdded,
          id,
          image: image ?? null,
          type: type,
          description,
          objectName : {
            name: objectName,
            id: id
          },
        };
        return data;
      });

      return {
        ...state,
        data: {
          objects: objects,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
