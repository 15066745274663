import React, { useEffect, useState } from "react";
// Components
import { CommunitiesColumnModal } from "view/components/Modals/Community/CommunitiesColumnModal";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import { IfetchTypes } from "store/types/initialState";
import { isAnyTypesFilterApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({}) => {
  const { typesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { data } = useSelector<RootState, IfetchTypes>(
    (state) => state?.allTypes
  );

  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };


  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyTypesFilterApplied(filtersData.filters);
      } else {
        return isAnyTypesFilterApplied(typesFilter);
      }
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
    </>
  );
};
