import { Dispatch } from "redux";
import {
  ERROR_LOADING_NOTIFICATIONS_RECIPIENTS,
  GET_ALL_NOTIFICATIONS_RECIPIENTS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS_RECIPIENTS,
  STOP_LOADING_NOTIFICATIONS_RECIPIENTS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
// import { recipients } from "utils/dummyDataNotificationsRecipients";

export const fetchNotificationsRecipientsAction =
  (notificationId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_NOTIFICATIONS_RECIPIENTS });
    // dispatch(loadingState(true))
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.notificationsRecipientsFilter
      );
      
      const query = appendNotificationIdInParams(notificationId, params);
      const { data } =
        await apiLibrary.NotificationsRecipients.getAllRecipients(query);
      dispatch({
        type: GET_ALL_NOTIFICATIONS_RECIPIENTS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the protocols:", error);
      dispatch({ type: ERROR_LOADING_NOTIFICATIONS_RECIPIENTS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_NOTIFICATIONS_RECIPIENTS });
      // dispatch(loadingState(false))
    }
  };

const appendNotificationIdInParams = (notificationId: any, params: any) => {
  let updatedParams = { ...params };

  if (notificationId) {
    updatedParams.notificationId = notificationId;
  }

  return updatedParams;
};
