import { Dispatch } from "redux";
import {
  ERROR_LOADING_PROTOCOLS,
  GET_ALL_PROTOCOLS,
  RESET_DATA,
  START_LOADING_PROTOCOLS,
  STOP_LOADING_PROTOCOLS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchProtocolsAction =
  (programId?: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_PROTOCOLS });
    // dispatch(loadingState(true))
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.protocolsFilter
      );
      const query = appendProgramIdInParams(programId, params);
      const { data } = await apiLibrary.Protocols.getAllProtocols(query);
      dispatch({
        type: GET_ALL_PROTOCOLS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the protocols:", error);
      dispatch({ type: ERROR_LOADING_PROTOCOLS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_PROTOCOLS });
      // dispatch(loadingState(false))
    }
  };

export const resetDynamicForms = () => {
  return {};
};

const appendProgramIdInParams = (programId: any, params: any) => {
  let updatedParams = { ...params };

  if (programId) {
    updatedParams.programIds = programId.toString();
  }

  return updatedParams;
};
