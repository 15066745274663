import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const VolumeOffIconOutlined =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (

  <svg xmlns="http://www.w3.org/2000/svg" width={restProps.width || 24} height={restProps.height || 24} viewBox="0 0 10 10" fill={fill}
    className="relative flex-grow-0 flex-shrink-0"
    {...restProps}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.502C6 0.6111 4.92286 0.164933 4.29289 0.794898L2.89745 2.19034L1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L2.19034 2.89745L2.08579 3.002H1.5C0.947715 3.002 0.5 3.44972 0.5 4.002V6.002C0.5 6.55429 0.947715 7.002 1.5 7.002H2.08579L4.29289 9.20911C4.92286 9.83908 6 9.39291 6 8.502V6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6 5.29289V1.502ZM5 4.29289V1.502L3.60456 2.89745L5 4.29289ZM2.89745 3.60456L2.64645 3.85556C2.55268 3.94933 2.4255 4.002 2.29289 4.002H1.5V6.002H2.29289C2.4255 6.002 2.55268 6.05468 2.64645 6.14845L5 8.502V5.70711L2.89745 3.60456Z" fill={fill} />
  </svg>
);
export default VolumeOffIconOutlined;
