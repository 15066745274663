import { apiClient } from "../config";

async function getFormsByPrograms(id: number, searchQuery?: string) {
  
  const params = {
    searchString: searchQuery
  };
  
  const res = await apiClient.get(`/admin/programs/${id}/forms`, { params });
  return res.data;
}

export default getFormsByPrograms;
