import {
  GET_PROGRAM_STATUS_HISTORY,
  START_LOADING_PROGRAM_STATUS_HISTORY,
  STOP_LOADIND_PROGRAM_STATUS_HISTORY,
  ERROR_LOADING_PROGRAM_STATUS_HISTORY,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchProgramStatusHistoryAction =
  (programId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_PROGRAM_STATUS_HISTORY });
    try {
      const { data } =
        await apiLibrary.Programs.getProgramStatusHistory(programId);
      dispatch({
        type: GET_PROGRAM_STATUS_HISTORY,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({ type: ERROR_LOADING_PROGRAM_STATUS_HISTORY });
    } finally {
      dispatch({ type: STOP_LOADIND_PROGRAM_STATUS_HISTORY });
    }
  };
