import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import { useInitializeMap, usePaintClustersPoints } from "./hooks";
import { Box } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";

const ObservationsByLocationMap = () => {
  const mapContainer = useRef<null | HTMLDivElement>(null);

  const {
    dashboard: { observationByLocation },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const clusterData = convertToFeatureCollection(observationByLocation.data);
  // const regionLayers = useCallback(
  //   processRegions(observationByLocation.data.regions),
  //   [observationByLocation.data.regions]
  // );

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // painting the clusters
  usePaintClustersPoints(clusterData, map, isMapFullyLoaded);

  return (
    <Box
      sx={{
        width: "100%",
        height: "312px",
        bgcolor: lightGreen[50],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
      }}
    >
      <div ref={mapContainer} className="w-full h-full"></div>
    </Box>
  );
};

export { ObservationsByLocationMap };

function convertToFeatureCollection(geometryCollections: any[]) {
  // console.log("convertToFeatureCollection", geometryCollections);

  // return;
  const filteredCollections = geometryCollections.filter(
    (collection: any) => collection !== null
  );

  const features = filteredCollections.flatMap((collection: any) =>
    collection.geometries.map((geometry: any) => ({
      type: "Feature",
      geometry: {
        type: geometry.type,
        coordinates: geometry.coordinates,
      },
      properties: {},
    }))
  );

  const featureCollection = {
    type: "FeatureCollection",
    features: features,
  };

  return featureCollection;
}
