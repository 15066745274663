import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoadingTailSpin } from "store/Loader";
import Cookies from "universal-cookie";
import {
  convertKeysToSnakeCase,
  convertKeysToCamelCase,
} from "utils/caseConvertor";


const failedRequests: any[] = [];
export const requestInterceptor = (request: any) => {

  if (request.isRetry) {
    failedRequests.push(request);
  }

  if (request.url.includes("dynamic-forms") && request.url.includes("fields")) {

    return request;
  } 
  else {
    if (request.data) {
      // request.data = convertKeysToSnakeCase(request.data);
    }

    if (request.params) {
      // request.params = convertKeysToSnakeCase(request.params);
    }

    return request;
  }
};

export const responseInterceptor = (response: any) => {
  if (response.data) {
    response.data = convertKeysToCamelCase(response.data);
  }
  return response;
};

export const errorHandler = (error: any) => {
  return Promise.reject(error);
};
