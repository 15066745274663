import { SET_USER_SETTINGS, SET_PEOFILE_USER_SETTINGS } from "./action-types";

// Define a type to represent user-specific settings
export interface IUserSettings {
  dateFormat: string;
  language: string;
  preferredCoordinate: string;
  preferredTemperature: string;
  preferredUnit: string;
  timezone: string;
}

interface UserSettingsState {
  profileSettings: IUserSettings;
  userSettings: IUserSettings;
}

const initialState: UserSettingsState = {
  profileSettings: {
    dateFormat: "",
    language: "",
    preferredCoordinate: "",
    preferredTemperature: "",
    preferredUnit: "",
    timezone: "",
  },
  userSettings: {
    dateFormat: "",
    language: "",
    preferredCoordinate: "",
    preferredTemperature: "",
    preferredUnit: "",
    timezone: "",
  },
};

const userProfileSettingsReducer = (
  state = initialState,
  action: any
): UserSettingsState => {
  const currentTimezone = action?.payload?.timezone
    ? action?.payload?.timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;

  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,

        userSettings: { ...action.payload, timezone: currentTimezone },
      };
    case SET_PEOFILE_USER_SETTINGS:
      return {
        ...state,
        profileSettings: { ...action.payload, timezone: currentTimezone },
      };
    default:
      return state;
  }
};

export default userProfileSettingsReducer;
