import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const InformationIcon = ({
  fill = "#F2AA00",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}
  >
    <path
      fill={"#F2AA00" || fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99992 1.66732C4.0544 1.66732 1.66659 4.05513 1.66659 7.00065C1.66659 9.94617 4.0544 12.334 6.99992 12.334C9.94544 12.334 12.3333 9.94617 12.3333 7.00065C12.3333 4.05513 9.94544 1.66732 6.99992 1.66732ZM0.333252 7.00065C0.333252 3.31875 3.31802 0.333984 6.99992 0.333984C10.6818 0.333984 13.6666 3.31875 13.6666 7.00065C13.6666 10.6825 10.6818 13.6673 6.99992 13.6673C3.31802 13.6673 0.333252 10.6825 0.333252 7.00065ZM6.33325 4.33398C6.33325 3.96579 6.63173 3.66732 6.99992 3.66732H7.00659C7.37478 3.66732 7.67325 3.96579 7.67325 4.33398C7.67325 4.70217 7.37478 5.00065 7.00659 5.00065H6.99992C6.63173 5.00065 6.33325 4.70217 6.33325 4.33398ZM5.66659 7.00065C5.66659 6.63246 5.96506 6.33398 6.33325 6.33398H6.99992C7.36811 6.33398 7.66659 6.63246 7.66659 7.00065V9.00065C8.03478 9.00065 8.33325 9.29913 8.33325 9.66732C8.33325 10.0355 8.03478 10.334 7.66659 10.334H6.99992C6.63173 10.334 6.33325 10.0355 6.33325 9.66732V7.66732C5.96506 7.66732 5.66659 7.36884 5.66659 7.00065Z"
    />
  </svg>
);
export default InformationIcon;
