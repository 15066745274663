import React, { useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
    </>
  );
};
