import { apiClient } from "../config";

async function getCategoriesDropdown(searchQuery?: string,page?: number) {
  const params = {
    searchString: searchQuery,
    page: page,
  };

  const res = await apiClient.get(`/admin/categories/dropdown`, { params });
  return res.data;
}

export default getCategoriesDropdown;
