export const SHOW_ADD_RASTER_LAYER_SCREEN = "SHOW_ADD_RASTER_LAYER_SCREEN";
export const SHOW_ADD_VECTOR_LAYER_SCREEN = "SHOW_ADD_VECTOR_LAYER_SCREEN";
export const SHOW_ADD_REGION_LAYER_SCREEN = "SHOW_ADD_REGION_LAYER_SCREEN";

export const SHOW_EDIT_REGION_LAYER_SCREEN = "SHOW_EDIT_REGION_LAYER_SCREEN";

export const SHOW_EDIT_SHAPE_LAYER_SCREEN = "SHOW_EDIT_SHAPE_LAYER_SCREEN";
export const SHOW_ADD_SHAPES_SCREEN = "SHOW_ADD_SHAPES_SCREEN";
export const SHOW_ALL_LAYERS_LISTING_SCREEN = "SHOW_ALL_LAYERS_LISTING_SCREEN";
export const SHOW_EDIT_RASTER_LAYER_SCREEN = "SHOW_EDIT_RASTER_LAYER_SCREEN";
export const SHOW_VIEW_RASTER_LAYER_SCREEN = "SHOW_VIEW_RASTER_LAYER_SCREEN";
export const SHOW_VIEW_VECTOR_LAYER_SCREEN = "SHOW_VIEW_VECTOR_LAYER_SCREEN";
export const SHOW_EDIT_POINT_LAYER_SCREEN = "SHOW_EDIT_POINT_LAYER_SCREEN";
export const SHOW_EDIT_VECTOR_LAYER_SCREEN = "SHOW_EDIT_VECTOR_LAYER_SCREEN";
export const SAVE_ALL_LAYERS_FETCHED_DATA = "SAVE_ALL_LAYERS_FETCHED_DATA";

export const DO_FETCH_ALL_LAYERS_DATA = "DO_FETCH_ALL_LAYERS_DATA";

export const LAYERS_DATA_TYPE = "LAYERS_DATA_TYPE";

export const DISPLAY_RASTER_LAYER_ON_MAP = "DISPLAY_RASTER_LAYER_ON_MAP";
export const DISPLAY_DEFAULT_MAP = "DISPLAY_DEFAULT_MAP";
export const DISPLAY_REGION_LAYER_ON_MAP = "DISPLAY_REGION_LAYER_ON_MAP";
export const DISPLAY_SHAPE_LAYER_ON_MAP = "DISPLAY_SHAPE_LAYER_ON_MAP";
export const DISPLAY_POINT_LAYER_ON_MAP = "DISPLAY_POINT_LAYER_ON_MAP";
export const DISPLAY_POINT_LAYER_BY_PICK_ON_MAP = "DISPLAY_POINT_LAYER_BY_PICK_ON_MAP";

export const UPDATE_SELECTED_SHAPE = "UPDATE_SELECTED_SHAPE";

export const UPDATE_DRAW_SHAPES_COORDINATES = "UPDATE_DRAW_SHAPES_COORDINATES";

export const SHOW_VIEW_REGION_LAYER_SCREEN = "SHOW_VIEW_REGION_LAYER_SCREEN";
export const SHOW_VIEW_SHAPE_LAYER_SCREEN = "SHOW_VIEW_SHAPE_LAYER_SCREEN";
export const SHOW_ADD_POINTS_LAYER_SCREEN = "SHOW_ADD_POINTS_LAYER_SCREEN";

export const SHOW_VIEW_POINT_LAYER_SCREEN = "SHOW_VIEW_POINT_LAYER_SCREEN";

export const UPDATE_SELECTED_LAYERS_ITEMS_DATA = "UPDATE_SELECTED_LAYERS_ITEMS_DATA";
export const UPDATE_ZOOM_TO_SELECTION_ITEM = "UPDATE_ZOOM_TO_SELECTION_ITEM";

export const DO_RESET_MAP = "DO_RESET_MAP";
export const REMOVE_ALL_DRAW_SHAPES = "REMOVE_ALL_DRAW_SHAPES";

export const SHOW_LOADER_LAYER = "SHOW_LOADER_LAYER";

export const SHOW_SNAPING_CONTROL = "SHOW_SNAPING_CONTROL";

export const SET_SELECTED_LAYER_TO_REGION_SELECT_FORM = "SET_SELECTED_LAYER_TO_REGION_SELECT_FORM";
export const SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM = "SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM";
export const SET_SELECTED_POINT_TO_POINT_SELECT_FORM = "SET_SELECTED_POINT_TO_POINT_SELECT_FORM";
export const CLEAR_SELECTED_LAYERS_ITEMS_DATA = "CLEAR_SELECTED_LAYERS_ITEMS_DATA"

export const REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN = "REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN";
export const COMMUNITY_CENTER_POINT = "COMMUNITY_CENTER_POINT"