import React, { useState } from "react";
/************ hooks ************/
import useDateFormatter from "hooks/formatDateWithPattern";
import { EyeIcon, EyeOffIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Field } from "formik";
import { conditionalityForObservations } from "utils/conditionalityForObservations";

interface TextFieldProps {
  label: string;
  type:
    | "text"
    | "number"
    | "password"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "date"
    | "datetime-local"
    | "month"
    | "range"
    | "search"
    | "tel"
    | "time"
    | "url"
    | "week"
    | "color";
  error?: any;
  name: string;
  editMode?: boolean;
  value?: any;
  data?: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  setFieldValue?: any;
  placeholder?: any;
  rows?: number;
  multiple?: any;
  disabled?: any;
  max?: any;
  ref?: any;
  id?: any;
  inputClassName?: string;
  className?: string;
  accept?: string;
  min?: number;
  step?: number | string;
  required?: boolean;
  pattern?: string;
  size?: number;
  maxLength?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  fieldAs?: "text" | "select" | "date" | "password" | "textarea";
  setFieldWidth?: any;
  visibleIf?: any;
  enableIf?: any;
  setValueIf?: any;
  setValueExpression?: any;
  requiredIf?: any;
  answers?: any;
  message?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  type = "text",
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  rows = 4,
  setFieldValue,
  placeholder,
  multiple,
  disabled = false,
  max,
  ref,
  id,
  inputClassName,
  className,
  accept,
  min,
  step,
  required,
  pattern,
  size,
  maxLength,
  readOnly = false,
  autoFocus = false,
  fieldAs,
  setFieldWidth,
  visibleIf,
  enableIf,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  message,
  ...restProps
}) => {
  const isVisible =
    typeof visibleIf === "string"
      ? conditionalityForObservations(visibleIf, answers, "visibleIf")
      : true;

  const isEnabled =
    typeof enableIf === "string"
      ? conditionalityForObservations(enableIf, answers, "enableIf")
      : false;
  if (!isVisible) {
    return;
  }
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { formatDate } = useDateFormatter();

  const isPasswordInput = type === "password";

  return (
    <div className="flex flex-col flex-grow w-full rounded-lg">
      <div className="relative flex flex-col py-2">
        <p
          className={`w-full pb-1 text-sm font-medium text-left capitalize font-Overpass  dark:text-caption ${
            editMode ? "text-textMid dark:text-caption " : "text-secondaryMidLight dark:text-caption "
          }`}
        >
          {label || name} {required && <span>*</span>}
        </p>
        {editMode ? (
          <>
            <div
              className={`flex justify-start items-center focus:outline-none  py-2 rounded bg-white border ${
                setFieldWidth ? " w-[50%]" : "w-full"
              } ${
                touched && error ? "border-accent_1Dark"  : "border-lineDark dark:border-lineLight"
              }`}
            >
              <Field
                disabled={isEnabled ? isEnabled : disabled}
                as={fieldAs}
                rows={rows}
                type={showPassword ? "text" : type}
                className={
                  "w-full h-full focus:outline-none resize-none font-Overpass gap-1.5 px-3 text-textMid dark:text-textMain text-[15px] placeholder:text-textLightExtra placeholder:font-medium placeholder:font-Overpass dark:bg-secondaryLight dark:border-lineLight " +
                  (inputClassName ?? "")
                }
                name={name}
                max={max}
                onChange={handleChange}
                placeholder={placeholder}
                ref={ref}
                id={id}
                onBlur={handleBlur}
                value={value}
                {...restProps}
              />
              {isPasswordInput && (
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="focus:outline-none"
                >
                  {showPassword ? (
                    <EyeIcon className="w-6 h-6" />
                  ) : (
                    <EyeOffIcon className="w-6 h-6" />
                  )}
                </button>
              )}
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
              {message && (!error) && <span className='text-sm text-textMidLight font-Overpass mt-1 dark:text-caption '>{message}</span>}
            </div>
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <p className="w-32 max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-secondaryMid dark:text-textMain">
                {type === "date"
                  ? formatDate(value === undefined ? " " : value)
                  : value}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default TextField;
