import { apiClient } from "../config";

async function getMostObservedSpecies(queryParams: any) {
  const res = await apiClient.get(
    `/admin/dashboard/widget/most-observed-species`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getMostObservedSpecies;
