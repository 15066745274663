import React from "react";
// External Tools
import { useLocation, useNavigate } from "react-router-dom";
// Store Utils
import { useDispatch } from "react-redux";
import { usersStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({}) => {
  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const TabsData = [
    "all",
    "active",
    "inactive",
    "virtual",
    "confirmation_pending",
    "invited",
    "re_invited",
    "suspended",
    "deleted",
  ];

  const handleLink = (linkName: string) => {
    if (usersFilter.status === linkName) {
      return;
    }
    dispatch(usersStatusAction(linkName));
  };

  return (
    <>
      {TabsData.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${
              usersFilter.status === item
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
            }`}
            onClick={() => handleLink(item)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${
                  usersFilter.status === item
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                }`}
              >
                {returnTitle(item)}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

const returnTitle = (item: string) => {
  if (item === "confirmation_pending") {
    return "Unconfirmed";
  } else if (item === "re_invited") {
    return "Re-invited";
  } else {
    return sentenceCase(item);
  }
};
