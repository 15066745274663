import PlusCircleIcon from "assets/icons/HeroIcons/PlusCircleIcon";
import React from "react";
import PlaceholderAvatar from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";

interface UserProps {
  communityData: any;
  setSelectedCommunity: any;
  ConnectCommunity: any;
}

export const CommunitiesList: React.FC<UserProps> = ({
  communityData,
  setSelectedCommunity,
  ConnectCommunity,
}) => {
  return (
    <div
      className="group relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2"
      // onClick={() => setSelectedCommunity(communityData)}
    >
      <img
        src={
          communityData?.image ? communityData?.image?.thumb : PlaceholderAvatar
        }
        alt=""
        height="100"
        width="100"
        className="object-cover Img_user_Data"
        style={{ flexShrink: 0 }}
      />
      <div className="flex items-center justify-start flex-grow">
        <div className="flex items-center justify-start w-full pl-1 rounded-lg">
          <p className="max-w-[520px] text-left break-words group-hover:dark:text-primary dark:text-textMain">{`${communityData.name}`}</p>
        </div>
      </div>
      <button type="button" onClick={() => ConnectCommunity(communityData)}>
        <PlusCircleIcon />
      </button>
    </div>
  );
};
