import { generatingLayerId } from "../utils";
import { __changeColorOfZoomedShapeLayer__ } from "../workerMehods";
import { useEffect } from "react";

const useCustomizeColorOfHigilightedLayer = (
  map: any,
  isMapFullyLoaded: any,
  selectedGeographies: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string
) => {
  useEffect(() => {
    if (!map || !isMapFullyLoaded || !selectedGeographies) return;
    selectedGeographies.forEach((layer: any) => {
      const item = { item: layer, itemType: "shapepicker", status: true };

      const isItemHighlighted = selectedGeographies.find(
        (answerLayer: any) => answerLayer.id == item.item.id
      );

      if (isItemHighlighted) {

        if (item.item.type === "point") {
          const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);
          map.setLayoutProperty(layerId, 'icon-image', "custom-marker-2");
        } else {
          // change the color of selected layer item
          __changeColorOfZoomedShapeLayer__(item, map, LAYER_SELECTED_COLOR);
        }

      }
      else {
        // change the color of unselected layer item
        __changeColorOfZoomedShapeLayer__(item, map, LAYER_DEFAULT_COLOR);
      }
    });
  }, [selectedGeographies]);
  return null;
};

export { useCustomizeColorOfHigilightedLayer };
