import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


export function addTimeAndSaveToLocalStorage(identifier: any, timeString: any) {
  if (timeString === "00:00") {
    console.log("Time is 00:00, not saving to local storage.");
    return;
  }

  let now = moment();

  const [minutes, seconds] = timeString.split(":").map(Number);

  now.add(minutes, "minutes");
  now.add(seconds, "seconds");

  let dateTimeString = now.format();

  let uniqueKey = `time_${identifier}`;

  localStorage.setItem(uniqueKey, dateTimeString);
}

export function getDifferenceBetweenStoredAndCurrentTime(identifier: any) {
  let uniqueKey = `time_${identifier}`;

  let storedDateTimeString = localStorage.getItem(uniqueKey);
  if (!storedDateTimeString) {
    return null;
  }

  let storedTime = moment(storedDateTimeString);
  let currentTime = moment();

  let difference = Math.abs(currentTime.diff(storedTime));

  let duration = moment.duration(difference);
  let totalSeconds = duration.asSeconds();

  return Math.floor(totalSeconds);
}
