import apiClient from "services/apiClient";

async function addReportPermissions(reportId: number, data: any) {
  const url = `/admin/reports/${reportId}/add-permission`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default addReportPermissions;
