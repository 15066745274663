import { useFormikContext } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { DayCheckbox } from "../DayCheckbox";
import { IReportSettingsFormValues } from "../..";
import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { MaterialUiSelect } from "./Select";
import { camelCase, sentenceCase } from "change-case";

export const RepeatSettings: React.FC = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<IReportSettingsFormValues>();

  const [monthDropdownOptions, setMonthDropdownOptions] = useState<
    {
      type: string;
      dayOfWeek: number;
      weekOfMonth: number;
      dayOfTheWeek: string;
      dayOfTheMonth: number;
      title: string;
      key: string;
    }[]
  >([]);
  const weekdays = [
    { name: "Mon", value: "mon" },
    { name: "Tue", value: "tue" },
    { name: "Wed", value: "wed" },
    { name: "Thu", value: "thu" },
    { name: "Fri", value: "fri" },
    { name: "Sat", value: "sat" },
    { name: "Sun", value: "sun" },
  ];
  const handleDayCheckboxChange = (dayValue: any) => {
    const updatedValues = values.repeatDays.includes(dayValue)
      ? values.repeatDays.filter((value: any) => value !== dayValue)
      : [...values.repeatDays, dayValue];

    setFieldValue("repeatDays", updatedValues);
  };

  useEffect(() => {
    const givenDate = moment(values.startRunDate);
    setMonthDropdownOptions(generateRecurringOptions(givenDate));
  }, [values.startRunDate]);
  return (
    <>
      <div className="flex items-center w-full mb-2 ">
        <div className="w-[360px] ">
          <TextInput
            height={38}
            label="Every"
            type="number"
            name="repeatDaysCount"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values?.repeatDaysCount}
            error={errors?.repeatDaysCount}
            touched={touched?.repeatDaysCount}
            min={1}
          />
        </div>

        <div className="w-[360px] mt-4 ml-3">
          <p
            className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${touched?.repeatPeriod && errors?.repeatPeriod
                ? "text-accent_1Dark dark:text-accent_1Dark"
                : ""
              }`}
          >
            {""}
          </p>
          <MaterialUiSelect
            options={[
              { label: "Weeks", value: "weeks" },
              { label: "Months", value: "months" },
              { value: "years", label: "Years" },
            ]}
            labelKey="label"
            valueKey="value"
            handleChange={(item: any) => {
              if (item.value !== "months") {
              } else if (item.value !== "weeks") {
                setFieldValue("repeatDays", []);
              }
              setFieldValue("repeatPeriod", item.value);
            }}
            // width={100}
            initialValue={values?.repeatPeriod}
            error={errors.repeatPeriod}
          />
          {/* <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {errors?.repeatDays && (
              <p className="flex-grow text-xs text-left text-accent_1Dark">
                {errors?.repeatDays}
              </p>
            )}
          </div> */}
        </div>
      </div>

      {values.repeatPeriod === "weeks" && (
        <div className="mb-2">
          <p className="text-secondaryMid font-Overpass dark:text-textMain">
            Repeat on
          </p>

          <div className="flex flex-wrap ml-3">
            {weekdays?.map((day, index: number) => (
              <div key={index} className="py-1 pr-1">
                <DayCheckbox
                  day={day}
                  checked={values?.repeatDays?.includes(day.value)}
                  handleChange={() => handleDayCheckboxChange(day.value)}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {errors?.repeatDays && (
              <p className="flex-grow text-xs text-left text-accent_1Dark">
                {errors?.repeatDays}
              </p>
            )}
          </div>
        </div>
      )}

      {values.repeatPeriod === "months" && (
        <div className="mb-2">
          <div className="w-[340px]">
            <MaterialUiSelect
              options={monthDropdownOptions}
              labelKey="title"
              valueKey="key"
              // error={otherQueryFilterErrors?.operator}
              // initialValue={values?.otherQueryFilter[index]?.operator}
              handleChange={(value: {
                type: string;
                dayOfWeek: number;
                weekOfMonth: number;
                dayOfTheWeek: string;
                dayOfTheMonth: number;
                title: string;
                key: string;
                isLastOccurrence: boolean;
              }) => {
                setFieldValue("occuranceAccordingToMonth", {
                  dayOfWeek: value?.dayOfWeek,
                  weekOfMonth: value?.weekOfMonth,
                  dayOfMonth: value?.dayOfTheMonth,
                  type: value?.type,
                  monthlyOccurrenceTitle: value?.title,
                  isLastOccurrence: value.isLastOccurrence,
                });
              }}
              // width={100}
              initialValue={generateKey(
                values.occuranceAccordingToMonth.type,
                values.occuranceAccordingToMonth.dayOfWeek,
                values.occuranceAccordingToMonth.weekOfMonth,
                values.occuranceAccordingToMonth.dayOfMonth,
                values.occuranceAccordingToMonth.isLastOccurrence
              )}
              error={errors.occuranceAccordingToMonth}
            />
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {errors?.repeatDays && (
              <p className="flex-grow text-xs text-left text-accent_1Dark">
                {errors?.repeatDays}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

// const getOrdinalWord = (n: number): string => {
//   const ordinalWords = ["First", "Second", "Third", "Fourth", "Fifth"];
//   return n > 0 && n <= ordinalWords.length ? ordinalWords[n - 1] : n.toString();
// };

// const countDayOccurrencesInMonth = (
//   date: Moment,
//   dayOfWeek: number
// ): number => {
//   const startOfMonth = date.clone().startOf("month");
//   const endOfMonth = date.clone().endOf("month");
//   let dayCount = 0;

//   for (
//     let day = startOfMonth;
//     day.isSameOrBefore(endOfMonth);
//     day.add(1, "day")
//   ) {
//     if (day.day() === dayOfWeek) {
//       dayCount++;
//     }
//   }

//   return dayCount;
// };

// const generateRecurringOptions = (
//   date: Moment
// ): {
//   type: string;
//   dayOfWeek: number;
//   weekOfMonth: number;
//   dayOfTheWeek: string;
//   dayOfTheMonth: number;
//   title: string;
//   key: string;
// }[] => {
//   const currentDayOfMonth = date.date();
//   const currentDayOfWeek = date.day();
//   const currentDayName = date.format("dddd");
//   const startOfMonth = date.clone().startOf("month");
//   const endOfMonth = date.clone().endOf("month");
//   const options: {
//     type: string;
//     dayOfWeek: number;
//     weekOfMonth: number;
//     dayOfTheWeek: string;
//     dayOfTheMonth: number;
//     title: string;
//     key: string;
//   }[] = [];

//   let dayCount = 0;
//   let currentDayIndex = 0;
//   const occurrences: Moment[] = [];
//   for (
//     let day = startOfMonth;
//     day.isSameOrBefore(endOfMonth);
//     day.add(1, "day")
//   ) {
//     if (day.day() === currentDayOfWeek) {
//       dayCount++;
//       occurrences.push(day.clone());
//       if (day.date() === currentDayOfMonth) {
//         currentDayIndex = dayCount;
//       }
//     }
//   }

//   if (currentDayIndex === dayCount) {
//     options.push({
//       key: `day-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//       type: "day",
//       dayOfWeek: currentDayOfWeek,
//       weekOfMonth: getWeekNumberOfMonth(date),
//       dayOfTheWeek: currentDayName,
//       dayOfTheMonth: currentDayOfMonth,
//       title: `Monthly on day ${currentDayOfMonth}`,
//     });

//     options.push({
//       key: `last-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//       type: "week",
//       dayOfWeek: currentDayOfWeek,
//       weekOfMonth: getWeekNumberOfMonth(date),
//       dayOfTheWeek: currentDayName,
//       dayOfTheMonth: currentDayOfMonth,
//       title: `Monthly on last ${currentDayName}`,
//     });
//     return options;
//   }

//   options.push({
//     type: "day",
//     key: `day-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//     dayOfWeek: currentDayOfWeek,
//     weekOfMonth: getWeekNumberOfMonth(date),
//     dayOfTheWeek: currentDayName,
//     dayOfTheMonth: currentDayOfMonth,
//     title: `Monthly on day ${currentDayOfMonth}`,
//   });

//   for (let i = 0; i < occurrences.length; i++) {
//     const occurrence = occurrences[i];
//     const weekNumberOfMonth = getWeekNumberOfMonth(occurrence);
//     const dayOfTheMonth = occurrence.date();
//     const title =
//       i + 1 === dayCount
//         ? `Monthly on last ${currentDayName}`
//         : `Monthly on ${getOrdinalWord(i + 1)} ${currentDayName}`;

//     options.push({
//       type: "week",
//       key: `week-${currentDayOfWeek}-${weekNumberOfMonth}`,
//       dayOfWeek: currentDayOfWeek,
//       weekOfMonth: weekNumberOfMonth,
//       dayOfTheWeek: currentDayName,
//       dayOfTheMonth: dayOfTheMonth,
//       title: title,
//     });
//   }

//   return options;
// };

// function getWeekNumberOfMonth(date: Moment): number {
//   return Math.ceil(date.date() / 7);
// }

//  Converts an occurrence number to an ordinal word
const getOrdinalWord = (n: number): string => {
  const ordinalWords = ["first", "second", "third", "fourth", "fifth"];
  return ordinalWords[n];
};

// Counts how many times a specific day of the week occurs in a month
const countDayOccurrencesInMonth = (
  date: Moment,
  dayOfWeek: number
): number => {
  const startOfMonth = date.clone().startOf("month");
  const endOfMonth = date.clone().endOf("month");
  let dayCount = 0;
  for (
    let day = startOfMonth;
    day.isSameOrBefore(endOfMonth);
    day.add(1, "day")
  ) {
    if (day.day() === dayOfWeek) {
      dayCount++;
    }
  }
  return dayCount;
};

// Returns the week number of the month for a specific date
const getWeekNumberOfMonth = (date: Moment): number => {
  return Math.ceil(date.date() / 7);
};

// Generates recurring options starting from the current or a specified date in a month
// const generateRecurringOptions = (
//   date: Moment
// ): Array<{
//   type: string;
//   key: string;
//   dayOfWeek: number;
//   weekOfMonth: number;
//   dayOfTheWeek: string;
//   dayOfTheMonth: number;
//   title: string;
// }> => {
//   const currentDayOfMonth = date.date();
//   console.log("currentDayOfMonth", currentDayOfMonth);
//   const currentDayOfWeek = date.day();
//   const currentDayName = sentenceCase(date.format("dddd") ?? "");
//   const startOfMonth = date.clone().startOf("month");
//   const endOfMonth = date.clone().endOf("month");
//   let options = [];

//   let occurrences = [];
//   let foundCurrent = false;
//   let occurrenceIndex = 0;

//   // Collect all occurrences of the current day of the week within the month
//   for (
//     let day = startOfMonth;
//     day.isSameOrBefore(endOfMonth);
//     day.add(1, "day")
//   ) {
//     if (day.day() === currentDayOfWeek) {
//       if (day.date() === currentDayOfMonth) {
//         foundCurrent = true; // Mark that current day has been found
//         occurrenceIndex = occurrences.length; // Store index of the current day
//       }
//       occurrences.push(day.clone()); // Collect all occurrences
//     }
//   }

//   // If today is the last occurrence
//   if (occurrenceIndex === occurrences.length - 1) {
//     options.push(
//       {
//         type: "day",
//         key: `day-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//         dayOfWeek: currentDayOfWeek,
//         weekOfMonth: getWeekNumberOfMonth(date),
//         dayOfTheWeek: currentDayName,
//         dayOfTheMonth: currentDayOfMonth,
//         title: `Monthly on day ${currentDayOfMonth}`,
//       },
//       {
//         type: "week",
//         key: `week-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//         dayOfWeek: currentDayOfWeek,
//         weekOfMonth: getWeekNumberOfMonth(date),
//         dayOfTheWeek: currentDayName,
//         dayOfTheMonth: currentDayOfMonth,
//         title: `Monthly on last ${currentDayName}`,
//       }
//     );
//   } else {
//     options.push({
//       type: "day",
//       key: `day-${currentDayOfWeek}-${getWeekNumberOfMonth(date)}-${currentDayOfMonth}`,
//       dayOfWeek: currentDayOfWeek,
//       weekOfMonth: getWeekNumberOfMonth(date),
//       dayOfTheWeek: currentDayName,
//       dayOfTheMonth: currentDayOfMonth,
//       title: `Monthly on day ${currentDayOfMonth}`,
//     });
//     for (let i = occurrenceIndex; i < occurrences.length; i++) {
//       const occurrence = occurrences[i];
//       const weekNumberOfMonth = getWeekNumberOfMonth(occurrence);
//       const title = `Monthly on ${getOrdinalWord(i + 1)} ${currentDayName}`;
//       options.push({
//         type: "week",
//         key: `week-${currentDayOfWeek}-${weekNumberOfMonth}-${occurrence.date()}`,
//         dayOfWeek: currentDayOfWeek,
//         weekOfMonth: weekNumberOfMonth,
//         dayOfTheWeek: currentDayName,
//         dayOfTheMonth: occurrence.date(),
//         title: title,
//       });
//     }
//   }

//   return options;
// };

const generateRecurringOptions = (date: Moment) => {
  if (!date.isValid()) {
    return []
  }
  const currentDayOfMonth = date.date();
  const currentDayOfWeek = date.day();
  const currentDayName = sentenceCase(date.format("dddd") ?? "");
  let options = [];

  const occurrences = getOccurrencesOfDay(date);
  const startOfMonth = date.clone().startOf("month");
  const endOfMonth = date.clone().endOf("month");

  const occurrenceIndex = occurrences.findIndex(
    (occurrence) => occurrence.date() === currentDayOfMonth
  );

  const isLastOccurrence = occurrenceIndex === occurrences.length - 1;

  options.push({
    type: "day",
    key: generateKey(
      "day",
      currentDayOfWeek,
      getWeekNumberOfMonth(date),
      currentDayOfMonth,
      isLastOccurrence
    ),
    dayOfWeek: currentDayOfWeek,
    weekOfMonth: getWeekNumberOfMonth(date),
    dayOfTheWeek: currentDayName,
    dayOfTheMonth: currentDayOfMonth,
    isLastOccurrence: isLastOccurrence,
    title: `Monthly on day ${currentDayOfMonth}`,
  });

  if (occurrenceIndex !== 4) {
    options.push({
      type: "week",
      key: generateKey(
        "week",
        currentDayOfWeek,
        getWeekNumberOfMonth(date),
        currentDayOfMonth,
        false
      ),
      dayOfWeek: currentDayOfWeek,
      weekOfMonth: getWeekNumberOfMonth(date),
      dayOfTheWeek: currentDayName,
      dayOfTheMonth: currentDayOfMonth,
      isLastOccurrence: false,
      title: `Monthly on ${getOrdinalWord(occurrenceIndex)} ${currentDayName}`,
    });
  }

  if (occurrenceIndex === occurrences.length - 1) {
    options.push({
      type: "week",
      key: generateKey(
        "week",
        currentDayOfWeek,
        getWeekNumberOfMonth(date),
        currentDayOfMonth,
        isLastOccurrence
      ),
      isLastOccurrence: isLastOccurrence,
      dayOfWeek: currentDayOfWeek,
      weekOfMonth: getWeekNumberOfMonth(date),
      dayOfTheWeek: currentDayName,
      dayOfTheMonth: currentDayOfMonth,
      title: `Monthly on last ${currentDayName}`,
    });
  }

  return options;
};

function generateKey(
  type: string,
  currentDayOfWeek: number,
  weekNumberOfMonth: number,
  dayOfMonth: number,
  isLastOccurrence: boolean
) {
  return `${type}-${currentDayOfWeek}-${weekNumberOfMonth}-${dayOfMonth}${isLastOccurrence ? "-last" : ""}`;
}

const getOccurrencesOfDay = (date: Moment) => {
  const occurrences = [];
  const currentDayOfWeek = date.day();
  const startOfMonth = date.clone().startOf("month");
  const endOfMonth = date.clone().endOf("month");

  for (
    let day = startOfMonth;
    day.isSameOrBefore(endOfMonth);
    day.add(1, "day")
  ) {
    if (day.day() === currentDayOfWeek) {
      occurrences.push(day.clone());
    }
  }

  return occurrences;
};
