import React from "react";
import { FormikProps, FormikValues } from "formik";
import { InputFields } from "./components/InputFields";
import { SubmissionButtons } from "../NotificationSettings/components/SubmissionButtons";

interface GeneralInformationProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  return (
    <div className="flex flex-col items-start justify-start w-full pb-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
      <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 bg-white">
        <div className="w-full px-1">
          <InputFields
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </div>

        <SubmissionButtons
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          activeStep={activeStep}
          values={values}
          isFormLoading={isFormLoading}
          isLastStep={isLastStep}
        />
      </div>
    </div>
  );
};
