import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";
import PopOverMenu from "view/components/PopOverMenu";
import { TableHeadActionsProps } from "../../types";
import ActionDropDownMenu from "Components/ActionDropDownMenu";

export const TableHeadActions = ({
  DirectoryActions,
}: TableHeadActionsProps) => {
  return (
    <div className="flex items-center justify-start flex-grow svg-container dark:bg-secondaryLight">
      <div className="flex justify-center  items-center flex-grow relative py-0.5 w-[80px]">
        <PopOverMenu icon={<PerimeterIcon />}>
          <ActionDropDownMenu actions={DirectoryActions} />
        </PopOverMenu>
      </div>
    </div>
  );
};
