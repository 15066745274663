import React, { useEffect } from "react";

const useMapMoveOnCoordinatesChange = (map: any, lng: number | null, lat: number | null) => {
  useEffect(() => {
    if (map.current) {
      map.current.flyTo({ 
        center: [lng ?? -145.6433003 , lat ?? 65.0710178],
      });

    }
  }, [lng, lat]);
  return null;
};

export {useMapMoveOnCoordinatesChange};
