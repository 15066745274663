import { Link } from "react-router-dom";
import { ColumnProps } from "../../types";
import { RenderCellContent } from "../index";

export const Column = ({ cell, columnVisibility, rowData }: ColumnProps) => {
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;

  if (isColumnVisible) {
    return (
      <td key={cell.id} className="px-4">
        <RenderCellContent
          columnDef={cell.column.columnDef}
          cellValue={cell.getValue()}
        />
      </td>
    );
  }
};
