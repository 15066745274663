import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const MapMarkerKpiIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M15.447 3.106a1 1 0 0 0-.894 0L9 5.882 4.894 3.829A2 2 0 0 0 2 5.618V11a1 1 0 1 0 2 0V5.618l4 2V9a1 1 0 0 0 2 0V7.618l4-2v11.324a1 1 0 0 0 .474.851l.526.325 4.106 2.053A2 2 0 0 0 22 18.382V7.618a2 2 0 0 0-1.106-1.789l-5.447-2.723ZM16 5.618l4 2v10.764l-4-2V5.618Z"
      clipRule="evenodd"
    />
    <path fill="#6F747B" d="M9 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M12.904 16.846a4.86 4.86 0 0 1-1.369 2.505l-2.121 2.076a2.038 2.038 0 0 1-2.828 0L4.464 19.35a4.86 4.86 0 0 1-1.368-2.505 4.794 4.794 0 0 1 .285-2.826 4.917 4.917 0 0 1 1.841-2.196A5.079 5.079 0 0 1 8 11c.989 0 1.955.287 2.778.824a4.916 4.916 0 0 1 1.841 2.196c.379.894.478 1.877.285 2.826Zm-6.57-3.395A3.047 3.047 0 0 1 8 12.957c.593 0 1.173.172 1.667.494.493.323.877.781 1.104 1.318.227.536.287 1.126.171 1.696a2.916 2.916 0 0 1-.82 1.502L8 20.043l-2.122-2.076a2.916 2.916 0 0 1-.82-1.502 2.877 2.877 0 0 1 .17-1.696 2.95 2.95 0 0 1 1.105-1.318Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MapMarkerKpiIcon;
