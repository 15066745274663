import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  OrganizationKpis,
  Profile,
  MissionStatement,
  CommunitiesListingCard,
  ProgramListingCard,
  OrganizationTags,
  AdminsListingCard,
} from "Components/OrganizationProfile";
import { useSelector } from "react-redux";
import { RootState } from "store";

import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IOrganization } from "store/organizationProfile";
import { fetchOrganizationProfileAction, organizationResetProfileAction } from "store/organizationProfile/reducer.actions";
import usePermissions from "hooks/usePermissions";

export const OrganizationProfile = () => {
  const { organizationId } = useParams();
  const { tags } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );

  useLayoutEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganizationProfileAction(organizationId));
    }
  }, [organizationId]);

  const dynamicCommunityLinks = [
    { path: `/organizations/list`, label: `Organizations` },
    {
      path: `/organizations/${organizationId}/profile`,
      label: organizationProfile?.name,
    },
  ];
  useEffect(() => {
    return () => {
      dispatch(organizationResetProfileAction());
    }
  },[])

  return (
    <div className="w-full">
      <div className="pt-4 mb-2">
        <DirectoryBreadCrumb links={dynamicCommunityLinks} />
      </div>
      <div className="relative flex flex-col items-start justify-start">
        <div className="w-full pb-6 bg-bgWhite dark:bg-secondaryLight dark:border-l dark:shadow-xl dark:border-lineLight">
          <Profile />
        </div>
        <div className="grid w-full grid-cols-3 gap-4 py-6 ">
          <div className={`lg:col-span-3 xl:col-span-1`}>
            <div className={`lg:order-1`}>
              <MissionStatement />
              <OrganizationKpis />
              <OrganizationTags />
            </div>
          </div>
          <div className=" lg:col-span-3 xl:col-span-2 flex flex-col p-0.5 rounded-lg bg-white">
            <div className="grid grid-cols-2 gap-4 mb-[16px]">
              <ProgramListingCard />
              <CommunitiesListingCard />
            </div>
            <div className="max-h-[385px]  w-[50%] rounded-lg overflow-hidden">
              <AdminsListingCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
