import { Dispatch } from "redux";
import { ERROR_LOADING_CATEGORIES, GET_ALL_CATEGORIES, RESET_DATA, START_LOADING_CATEGORIES, STOP_LOADING_CATEGORIES } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchCategoriesAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_CATEGORIES });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.categoriesFilter
      );

      const { data } =
        await apiLibrary.SpeciesAndObjects.getAllCategories(queryString);
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({ type: ERROR_LOADING_CATEGORIES });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_CATEGORIES });
      // dispatch(loadingState(false))
    }
  };
