function toggleCustomPropertyGridSidebar(
    doShow: boolean,
    cb?: CallableFunction
  ) {
    const customSidebar: HTMLElement | null = document.getElementById(
      "customPropertyGridSidebar"
    );
  
    if (customSidebar) {
      if (doShow) {
        customSidebar.style.display = "block";
        
      } else {
        customSidebar.style.display = "none";
      }
    }
    cb && cb();
  }

  export default toggleCustomPropertyGridSidebar;