import React from 'react';
import { Helmet } from 'react-helmet';
import { getCurrentBranding } from 'utils/customBranding';

type HeadProps = {
  title?: string;
  description?: string;
};

const Head: React.FC<HeadProps> = ({ title }) => {
  const currentBrand = getCurrentBranding();

  const brandLogos: any = {
    isn: '/isn-favicon.ico',
    sign: '/sign-favicon.ico',
  };

  const brandNames: any = {
    isn: 'ISN - Indigenous Sentinels Network',
    sign: 'SIGN - Seacoast Indigenous Guardians Network',
  };
  const CURRENT_BRAND_LOGO = brandLogos[currentBrand];
  const CURRENT_BRAND_NAME = brandNames[currentBrand];
  return (
    <Helmet>
      <link rel='icon' href={CURRENT_BRAND_LOGO} type='image/x-icon' />
      <link
        rel='apple-touch-icon'
        href={CURRENT_BRAND_LOGO}
        type='image/x-icon'
      />
      <title>{`${title ? `${title} - ` : ''}${CURRENT_BRAND_NAME}`}</title>
    </Helmet>
  );
};

export default Head;
