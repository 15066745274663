import getTypesDropdown from "./getTypesDropdown";
import getCategoriesDropdown from "./getCategoriesDropdown";
import getObservsersByProgramDropdown from "./getObservsersByProgramDropdown";
import getSpeciesDropdown from "./getSpeciesDropdown";
import getObjectsDropdown from "./getObjectsDropdown";
import getTagsDropdown from "./getTagsDropdown";
const dropdowns = {
  getTypesDropdown,
  getObjectsDropdown,
  getCategoriesDropdown,
  getSpeciesDropdown,
  getObservsersByProgramDropdown,
  getTagsDropdown,
};

export default dropdowns;
