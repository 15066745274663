import { GET_REPORTS_SUMMARY } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchReportsSummaryProfileAction =
  (reportId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } = await apiLibrary.Reports.getSummaryReports(reportId);

      dispatch({
        type: GET_REPORTS_SUMMARY,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };
export const reportsSummaryResetAction = () => {
  return {
    type: "RESET_REPORTS_SUMMARY_DATA",
  };
};
