import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { capitalCase, sentenceCase } from "change-case";
import { MaterialUiSelect } from "view/components/Select";
import { TrashIcon } from "assets/icons/HeroIcons";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface UniqueProperties {
  [key: string]: boolean;
}

interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;  
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
interface DataType {
  [key: string]: any; // Adjust 'any' to be more specific as needed
}
export const ManageLayersPropertiesModal = ({
  isMoDalOpen,
  setIsModalOpen,
  option,
  onAddSelection,
}: {
  isMoDalOpen: boolean;
  setIsModalOpen: any;
  option: OptionType;
  onAddSelection: (option: OptionType) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueProperties, setUniqueProperties] = useState<UniqueProperties>(
    option?.uniqueProps
  );

  useEffect(() => {
    if (option.uniqueProps) {
      setUniqueProperties(option.uniqueProps);
    }
  }, [option.uniqueProps]);

  const handleSelectChange = (property: string) => {
    setUniqueProperties((prev: UniqueProperties) => ({
      ...prev,
      [property]: true,
    }));
  };

  const handleRemoveProperty = (property: string) => {
    setUniqueProperties((prev: UniqueProperties) => ({
      ...prev,
      [property]: false,
    }));
  };

  const falsePropertyObjects = () => {
    return Object.entries(uniqueProperties)
      .filter(([, isTrue]) => !isTrue)
      .map(([key]) => ({ value: key, label: capitalCase(key ?? "") }));
  };

  const truePropertyObjects = () => {
    return Object.entries(uniqueProperties)
      .filter(([, isTrue]) => isTrue)
      .map(([key]) => ({ value: key, label: capitalCase(key ?? "") }));
  };
  const filteredData = truePropertyObjects();

  const falsePropertiesData = falsePropertyObjects();
  const updateProperties = () => {
    const updatedOption: OptionType = { ...option };
    updatedOption.uniqueProps = uniqueProperties;
    onAddSelection(updatedOption);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 100);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isMoDalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[680px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between pb-2 items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {option.name ?? "Layer"}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex flex-col items-start w-full py-1">
              <p className="mb-2 dark:text-textMain">Select Attribute</p>
              <div className="flex justify-start items-center w-full relative gap-1.5   rounded bg-white ">
                <MaterialUiSelect
                  options={falsePropertiesData}
                  labelKey="label"
                  valueKey="value"
                  placeholder="Please Select An Attribute"
                  error={""}
                  initialValue={null}
                  handleChange={(value: any) => {
                    handleSelectChange(value.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full max-h-[200px] overflow-auto">
              {filteredData.map((item: any) => {
                return (
                  <div className="flex justify-start items-center w-full mb-1 px-2 pb-1 bg-white border border-[#d6d6d6]">
                    <div className="relative flex items-center justify-start flex-grow p-2">
                      <p className="flex-grow w-96 text-base text-left text-[#161616]">
                        {item.label}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleRemoveProperty(item.value);
                      }}
                      className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 p-2 rounded-[100px]"
                    >
                       <TrashIcon fill="#6F747B" />
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={updateProperties}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
