import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  className?: string;
}

export const ExclamationCircleIcon = ({
  fill = "#6F747B",
  isActive,
  className,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "flex-grow-0 flex-shrink-0 w-6 h-6 relative"}
    {...restProps}>
    <path
      fill={fill}
      className={className || `dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      fillRule="evenodd"
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10-5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1Zm-1 9a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ExclamationCircleIcon;
