export interface INotifications {
  id: number;
  description: string;
  title: string;
  profileImage: string;
  isRead: boolean;
  createdAt: string;
  redirectLink: string;
}
export interface NotificationsData {
  notificationsCenter: INotifications[];
  unreadCount: number;
  totalItems: number;
  totalPages: number;
}
export interface IfetchNotifications {
  data: NotificationsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialNotificationsData: IfetchNotifications = {
  data: {
    notificationsCenter: [],
    unreadCount: 0,
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
export default initialNotificationsData;
