import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import BpCheckbox from "view/components/CheckBox";
import "./ReportsColumnModal.scss";
import DragDotsIcon from "assets/icons/HeroIcons/DragDotsIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import CloseIcon from "./CloseIcon";

import { ColumnDef, ColumnOrderState } from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "view/components/Button";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  IDirectoryState,
  initialReportsDirectoryOrder,
} from "store/directoriesOrders/initialState";
import { useDispatch } from "react-redux";
import {
  reportsDirectoryColumnsOrderAction,
  reportsDirectoryColumnsVisibilityAction,
} from "store/directoriesOrders/reducer.actions";
import { useParams } from "react-router-dom";

interface ReportsColumnModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  columnOrder: string[];
  setColumnOrder: any;
  columns: any[];
  columnVisibility: any;
  setColumnVisibility: (visibility: any) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
};

const ReportsColumnModal: React.FC<ReportsColumnModalProps> = ({
  isOpen,
  setIsOpen,
  isLoading,
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
}) => {
  const dispatch = useDispatch();
  const { reportsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const [tableColumnVisibility, setTableColumnVisibility] = useState<any>();
  const [tableColumnOrder, setTableColumnOrder] = useState<ColumnOrderState>(
    []
  );
  const [tableColumnsConfig, setTableColumnsConfig] = useState<
    ColumnDef<any>[]
  >([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();

  useEffect(() => {
    setTableColumnsConfig(columns);
    setTableColumnOrder(reportsDirectory.columnOrder);
    setTableColumnVisibility(reportsDirectory.columnVisibility);

    const modifiedColumns = columns.filter((column: any) => {
      if (programId) {
        if (column.id === "programName") {
          return false;
        }
      } else if (communityId) {
        if (column.id === "communityName") {
          return false;
        }
      }

      return true;
    });
    setSearchResults(modifiedColumns);
  }, [isOpen]);

  const handleClose = () => setIsOpen(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
  });
  const initialValues = {
    firstName: "",
  };
  const handleSubmit = (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    // Handle form submission
    setSubmitting(false);
  };

  const handleToggleVisibility = (status: boolean, columnId: string) => {
    if (columnId !== "reportName")
      setTableColumnVisibility((prevVisibility: any) => ({
        ...prevVisibility,
        [columnId]: status,
      }));
  };
  const UpdateColumns = () => {
    dispatch(reportsDirectoryColumnsOrderAction(tableColumnOrder));
    dispatch(
      reportsDirectoryColumnsVisibilityAction(tableColumnVisibility)
    );
    setIsOpen(false);
  };
  const handleClearAll = () => {
    const modifiedColumns =
      initialReportsDirectoryOrder.columnOrder.filter((column: any) => {
        if (programId) {
          if (column === "programName") {
            return false;
          }
        } else if (communityId) {
          if (column === "communityName") {
            return false;
          }
        }

        return true;
      });
    setTableColumnOrder(modifiedColumns);
    setTableColumnVisibility(
      initialReportsDirectoryOrder.columnVisibility
    );
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value.trim() !== "") {
      const results = tableColumnsConfig.filter((column: any) =>
        column.header.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else if (value.trim() === "") {
      setSearchResults(tableColumnsConfig);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark:bg-secondaryLight ">
          <div className="flex flex-col justify-start items-start w-[656px] rounded-lg bg-bgWhite dark:bg-secondaryLight">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                    Edit Table Columns
                  </p>
                </div>
                <button onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <div className="w-full">
                    <Form>
                      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
                        <TextInput
                          label=""
                          type="ColumnInput"
                          name="Galadrial"
                          usercolumn="userColumn"
                          placeholder="Search Fields"
                          value={searchValue}
                          handleChange={handleSearchInputChange}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>

              <div className="w-full max-h-[60vh] overflow-y-auto flex items-start justify-start gap-4">
                <div className="w-1/2 flex flex-col items-start justify-start rounded-lg">
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 ">
                    <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
                      <div className="flex justify-start items-center flex-grow relative py-0.5 font-medium">
                         <p className="text-textMid dark:text-textMain">Available Metrics</p>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-center flex-grow-0 flex-shrink-0 py-2">
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
                          <button
                            className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary"
                            onClick={handleClearAll}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full h-[440px] overflow-hidden px-2 py-3 rounded-lg border border-lineMid overflow-y-auto">
                    <div className="flex flex-col w-full rounded-lg">
                      {searchValue !== "" && searchResults.length === 0 ? (
                        <p className="text-sm text-gray-500 dark:text-textMain">
                          No columns found.
                        </p>
                      ) : (
                        searchResults.map((column: any) => {
                          const isVisible =
                            tableColumnVisibility[column?.id] ?? true;
                          if (column?.id !== "id" && column?.id !== "select") {
                            return (
                              <div
                                className="flex items-center justify-start gap-3 px-1 rounded-lg"
                                key={column.id}
                              >
                                <div className="flex items-center justify-center">
                                  <BpCheckbox
                                    onChange={(e: any) =>
                                      handleToggleVisibility(
                                        e.target.checked,
                                        column.id
                                      )
                                    }
                                    checked={isVisible}
                                    label={
                                      <div className="flex items-center justify-start flex-grow">
                                        <div className="flex justify-start items-center flex-grow relative py-0.5">
                                          <p className="dark:text-textMain">{column.header}</p>
                                        </div>
                                      </div>
                                    }
                                    labelPlacement="end"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-1/2 flex items-start justify-start rounded-lg">
                  <div className="flex flex-col items-start justify-start flex-grow">
                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
                      <div className="flex justify-start items-center flex-grow gap-4 px-3 py-2 rounded-lg mt-[1px]">
                        <div className="flex justify-start items-center flex-grow relative py-0.5 font-medium">
                          <p className="text-textMid dark:text-textMain">Current Columns</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border border-lineMid mt-[5px] rounded-lg">
                      <ColumnSelector
                        columnOrder={tableColumnOrder}
                        setColumnOrder={setTableColumnOrder}
                        columns={tableColumnsConfig}
                        columnVisibility={tableColumnVisibility}
                        handleToggleVisibility={handleToggleVisibility}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />
                <Button
                  type="submit"
                  text="Update Columns"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={UpdateColumns}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default ReportsColumnModal;

const ItemTypes = {
  COLUMN: "column",
};

interface ColumnSelectorProps {
  columnOrder: string[];
  setColumnOrder: any;
  columns: any;
  columnVisibility: Record<string, boolean>;
  handleToggleVisibility: any;
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  handleToggleVisibility,
}) => {
  const Column: React.FC<{ columnId: string; index: number }> = ({
    columnId,
    index,
  }) => {
    const column = columns.find((col: any) => col.id === columnId);

    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.COLUMN,
      item: { columnId, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.COLUMN,
      drop: (item: { columnId: string; index: number }) => {
        const { columnId, index: dragIndex } = item;
        const dropIndex = index;

        if (dragIndex === dropIndex) return;

        setColumnOrder((prevOrder: any) => {
          const newOrder = Array.from(prevOrder);
          newOrder.splice(dragIndex, 1);
          newOrder.splice(dropIndex, 0, columnId);
          return newOrder;
        });
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }));

    const opacity = isDragging ? 0.5 : 1;
    const cursor = isDragging ? "grab" : "pointer";
    return (
      <div ref={drop}>
        <div ref={drag} style={{ opacity, cursor, width: "100%" }}>
          <div
            className="relative flex items-center justify-start gap-2 px-2 py-2 rounded-lg"
            key={columnId}
          >
            <DragDotsIcon
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="#2C3236"
            />
            <div className="flex items-center justify-between flex-grow">
              <div className="flex justify-start items-center w-full py-0.5">
                 <p className="dark:text-textMain">{column && column.header}</p>
              </div>
              <button
                className="mr-1"
                onClick={(e) => handleToggleVisibility(false, columnId)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full " style={{ overflow: "hidden" }}>
      <DndProvider backend={HTML5Backend}>
        <div className="w-full  h-[424px] my-2 overflow-y-auto">
          {columnOrder.map((columnId, index) => {
            const isVisible = columnVisibility[columnId] ?? true;
            if (isVisible && columnId !== "id" && columnId !== "select") {
              return (
                <Column key={columnId} columnId={columnId} index={index} />
              );
            }
          })}
        </div>
      </DndProvider>
    </div>
  );
};
