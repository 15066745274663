import { apiClient } from "../config";

async function getAllLayersForAdmins(params: any) {
  let url = `/admin/geographies/map-list`;

  const res = await apiClient.get(url, { params });

  return res.data;
}

export default getAllLayersForAdmins;
