import React from "react";

interface BtnProps {
  handleClick: () => void;
  handleTitleChange: Boolean;
}

export const InviteButton: React.FC<BtnProps> = ({
  handleClick,
  handleTitleChange,
}) => {
  return (
    <div className="w-full flex justify-end">
        <p className="text-sm font-semibold text-primary pr-2 cursor-pointer" onClick={handleClick}>
          {handleTitleChange ? "Choose from registered" : "Invite someone new"}
        </p>
    </div>
  );
};
