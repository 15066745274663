import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import { useInitializeMap, usePaintLayers } from "./hooks";
import { useParams } from "react-router-dom";
// import apiLibrary from "services/api";
// import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
// import { fetchCommunityGeographies } from "store/communityGeography";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IFetchCommunityGeographyData } from "store/communityGeography/initialState";
import mapboxgl from "mapbox-gl";
import calculateMultiEntityZoomLevel from "utils/calculateMultiEntityZoomLevel";
import { point, featureCollection } from "@turf/turf";

const CommunityProfileMap = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );

  const { communityId } = useParams();
  const [markers, setMarkers] = useState([]);

  // useEffect(() => {
  //   if (communityId) {
  //     dispatch(fetchCommunityGeographies(communityId));
  //   }
  // }, [communityId]);
  const mapContainer = useRef<null | HTMLDivElement>(null);

  // const regionLayers = useCallback(processRegions(data.communityRegions), [
  //   data.communityRegions,
  // ]);

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
    communityProfile.location
  );

  useEffect(() => {
    if (
      communityProfile.observations &&
      communityProfile.observations.length !== 0
    ) {
      const validObservations =
        communityProfile.observations?.filter((obs) =>
          obs.locations?.some((loc) => loc && loc.geometries != null)
        ) ?? [];

      if (isMapFullyLoaded && validObservations.length > 0) {
        createMarkers(validObservations, map, setMarkers);

        const markers = validObservations.map((item: any) => {
          const coordinates =
            item.locations?.[0]?.geometries?.[0]?.coordinates ?? [];
          const mapData = coordToGeoJSON(coordinates);
          return { id: item.id, name: item.name, mapData };
        });

        const calculation = calculateMultiEntityZoomLevel(markers);
        if (calculation?.bounds && calculation.maxZoom) {
          map.fitBounds(calculation.bounds, {
            padding: 50,
            maxZoom: 14,
            duration: 2000,
          });
        }
      }
    }
  }, [isMapFullyLoaded, communityProfile.observations]);
  // // painting regions layers of selected community
  // usePaintLayers(
  //  regionLayers,
  //   map,
  //   isMapFullyLoaded
  // );

  return (
    <section className="  h-full w-[100%] relative ">
      <div ref={mapContainer} className="w-full h-full"></div>
      {/* {stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : stateIs === "Idle" ? (
       
      ) : (
        <></>
      )} */}
    </section>
  );
};

export { CommunityProfileMap };

function createMarkers(data: any, map: any, setMarkers: any) {
  const newMarkers: any = [];
  data.forEach((dataEle: any) => {
    const { locations } = dataEle;
    locations.forEach((location: any) => {
      if (!location) {
        return;
      }

      const feature = location?.geometries[0];

      if (feature?.type === "Point" && feature?.coordinates) {
        // creating new marker
        const marker = new mapboxgl.Marker({ draggable: false })
          .setLngLat(feature.coordinates)
          .addTo(map);

        newMarkers.push(marker);
      }
    });
  });

  setMarkers(newMarkers);
}

function coordToGeoJSON(coord: [number, number], properties?: any) {
  // Create a point feature using Turf.js
  const pointFeature = point(coord);

  if (properties) {
    pointFeature.properties = properties;
  }

  // Create a feature collection with the point feature
  const geoJSONFeatureCollection = featureCollection([pointFeature]);

  return geoJSONFeatureCollection;
}

// const processRegions = (data: any) => {
//   if (data === null || data === undefined) return [];
//   const processedData = data.map((region: any) => {
//     const updatedRegion = { ...region };
//     updatedRegion.itemType = "region";
//     return updatedRegion;
//   });
//   return processedData;
// };
