import React, { useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import "./Statuses.scss";
import { useDispatch } from "react-redux";
import { qualityAssuranceObservationsFilterStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import { filtersInitialState } from "store/filters/initialState";
import { sentenceCase } from "change-case";

interface statusesProps {}
export const Statuses: React.FC<statusesProps> = () => {
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const dispatch = useDispatch();

  const [dropDownValues, setDropDownValues] = useState([
    "completed",
    "incompleted",
    "archived",
    "in_review",
  ]);
  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedValue: any
  ) => {
    dispatch(qualityAssuranceObservationsFilterStatusAction(selectedValue));
  };
  return (
    <div className="bg-bgWhite max-h-[490px] w-[315px] overflow-hidden shadow-xl rounded-xl flex flex-col justify-start items-start relative overflow-y-scroll border border-lineMid">
      <div className="w-full">
        <div className="flex flex-col justify-start items-center w-full relative px-1.5">
          {dropDownValues?.map((dropDown: any, index: any) => (
            <div
              key={index}
              className="flex items-center justify-start w-full px-1 rounded-lg"
            >
              <BpCheckbox
                checked={qualityAssuranceObservationsFilter.filterStatus.value.some(
                  (item: any) => item === dropDown
                )}
                label={
                  <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5 capitalize">
                    <p>
                      {" "}
                      {dropDown === "in_review"
                        ? sentenceCase(dropDown)
                        : ellipsize(`${dropDown}`, 35)}{" "}
                    </p>
                  </div>
                }
                labelPlacement="end"
                onChange={(e) => handleSelection(e, dropDown)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
