import { Header, ImportFormStepper } from "Components/Imports/AddImport";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetImportStepperAction } from "store/addImportStepper/reducer.actions";

const AddImport = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetImportStepperAction());
    };
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center justify-start w-full ">
      <div className="flex justify-center w-full mb-10">
        <ImportFormStepper />
      </div>
    </div>
  );
};

export default AddImport;