import { apiClient } from "../config";
async function getManagersDropdown(programId: any, searchQuery?: string) {
  const params = {
    searchString: searchQuery,
    limit: 100,
  };

  const response = await apiClient.get(
    `/admin/programs/${programId}/managers-dropdown`,
    {
      params,
    }
  );
  return response.data;
}
export default getManagersDropdown;
