import { createStore, applyMiddleware, Store, AnyAction } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["directoriesOrders", "auth", "userProfileSettings", "darkMode"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

let store: Store<RootState, AnyAction>;

if (process.env.NODE_ENV === "development") {
  const { composeWithDevTools } = require("redux-devtools-extension");
  store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(thunk as ThunkMiddleware<RootState, AnyAction>)
    )
  );
} else {
  store = createStore(
    persistedReducer,
    applyMiddleware(thunk as ThunkMiddleware<RootState, AnyAction>)
  );
}

export const persistor = persistStore(store);

export default store;
