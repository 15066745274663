import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import React from "react";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

export const InviteReviewer = ({
  errors,
  handleChange,
  handleBlur,
  values,
  touched,
  isSubmitting,
}: any) => {
  return (
    <>
      <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 mt-[-10px]">
        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="Email*"
            type="email"
            placeholder="Email"
            name="email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values?.email}
            error={errors?.email}
            touched={touched?.email}
            disabled={isSubmitting}
            height={41}
          />
        </div>
      </div>
      <div className="flex items-start self-stretch justify-start mt-[-20px] gap-4">
        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="First Name*"
            type="text"
            placeholder="First name"
            name="firstName"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.firstName}
            error={errors?.firstName}
            touched={touched.firstName}
            disabled={isSubmitting}
            height={41}
          />
        </div>
        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="Last Name*"
            type="text"
            placeholder="Last name"
            name="lastName"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.lastName}
            error={errors?.lastName}
            touched={touched.lastName}
            disabled={isSubmitting}
            height={41}
          />
        </div>
      </div>
    </>
  );
};
