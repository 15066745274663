import React from "react";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import BpCheckbox from "view/components/CheckBox";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import useDateRange from "hooks/useDateRange";
import Button from "view/components/Button";
import { openProcessedReportModalAction } from "store/modals";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { runReportDataAction } from "store/reportsRun";
import usePermissions from "hooks/usePermissions";
import {
  getEndOfDayInLocalTimeZoneToUtc,
  getMidnightInLocalTimeZoneToUtc,
} from "utils/getMidnightOrLocalDayeEndTime";
import { formatTheDates } from "utils/formatTheDates";
import { formatTheTime } from "utils/formatTheTime";
import { TIME_FORMATS } from "constants/timeFormats";
import { runProcessedReports } from "store/reportsRun/reducer.actions";
import { runReportsDateRangeAction, runReportsResetFiltersAction } from "store/filters/reducer.actions";

interface DateFilterProps {
  from: string;
  to: string;
  reportId: any;
  setShowDropdown: any;
  showDropdown: any;
  handleDateChange: (startDate: any, endDate: any) => void;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  from,
  to,
  reportId,
  setShowDropdown,
  showDropdown,
  handleDateChange,
}) => {
  const dispatch = useDispatch();
  const { reports } = usePermissions();
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { getDateOptionForRunReports, calculateDateForRunReport } = useDateRange();
  const formatDate = (date: any) => moment(date).format(DATE_FORMATS.DEFAULT);
  const handleCheckboxChange = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      const { startDate, endDate } = calculateDateForRunReport(value);
      handleDateChange(startDate, endDate);
    } else {
      handleDateChange("", "");
    }
  };

  const handleDateInputChange = (
    value: string,
    field: "startDate" | "endDate"
  ) => {
    const selectedDate = new Date(value);
    const formattedDate = formatDate(selectedDate);

    if (field === "startDate") {
      if (formattedDate <= to || !to) {
        // Check if the start date is less than or equal to the end date (or if end date is empty).
        handleDateChange(formattedDate, to);
      } else {
        // Display an error message or handle the error in another way.
        console.error("Start date cannot be greater than end date.");
      }
    } else {
      if (from <= formattedDate || !from) {
        // Check if the end date is greater than or equal to the start date (or if start date is empty).
        handleDateChange(from, formattedDate);
      } else {
        // Display an error message or handle the error in another way.
        console.error("End date cannot be less than start date.");
      }
    }
  };

  const checkboxLabels = [
    "Today",
    "Yesterday",
    "This week",
    "Last week",
    "This month",
    "Last month",
    "Last 30 days",
    "Last 90 days",
    "Last 12 months",
    "This year",
    "Last year",
  ];
  const selectedOption = getDateOptionForRunReports(from, to);

  const startOfTheDay = getAdjustedStartOfDay(from, currentTimezone);

  const endOfTheDay = getAdjustedEndOfDay(to, currentTimezone);

  const startTime = formatTheTime(startOfTheDay, TIME_FORMATS.HOURS_MINUTES_SECONDS, {
    utc: false,
  });

  const endTime = formatTheTime(endOfTheDay, TIME_FORMATS.HOURS_MINUTES_SECONDS, {
    utc: false,
  })

  const handleRunReport = async () => {
    try {
      dispatch(runReportsDateRangeAction("date", from, to));
      dispatch(runProcessedReports(true));
      const { data } = await apiLibrary.Reports.createManualReportVersion(
        reportId,
        formatTheDates(startOfTheDay, { utc: false }),
        formatTheDates(endOfTheDay, { utc: false }),
        startTime,
        endTime
      );
      dispatch(runReportDataAction(data?.id));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      handleDateChange("", "");
      setShowDropdown(null);
    }
  };

  return (
    <div className="flex py-2 pl-2 shadow-xl bg-bgWhite dark:bg-secondaryLight rounded-xl">
      <div className="w-[255px] flex flex-col justify-start items-start">
        {checkboxLabels?.map((label) => (
          <div
            key={label}
            className="flex items-center justify-start pl-1 rounded-lg"
          >
            <BpCheckbox
              label={label}
              value={label}
              labelPlacement="end"
              checked={label === selectedOption}
              onChange={handleCheckboxChange}
            />
          </div>
        ))}
      </div>
      <div className="border-r-0 border-b-0 border-t-0 border-l border-lineMid w-[255px] flex flex-col justify-start items-start p-3">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <InputField
              type="date"
              isForFilters={true}
              label="Start Date"
              placeholder="YYYY/MM/DD"
              value={from}
              onChange={(event: any) =>
                handleDateInputChange(event, "startDate")
              }
              max={moment()}
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <InputField
              type="date"
              isForFilters={true}
              label="End Date"
              placeholder="YYYY/MM/DD"
              value={to}
              onChange={(event: any) => handleDateInputChange(event, "endDate")}
              max={moment()}
            />
          </div>
        </div>

        <div className="flex justify-end w-full mt-3">
          <Button
            disabled={
              from === "" || to === "" || !reports.canCreateManualVersionReports
            }
            type="button"
            text="Run Report"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="filled"
            onClick={handleRunReport}
          />
        </div>
      </div>
    </div>
  );
};



function getAdjustedStartOfDay(date: string, timeZone: string) {
  const isToday = moment.tz(timeZone).isSame(date, 'day');
  return isToday
    ? moment().utc().format("YYYY-MM-DD HH:mm:ss")
    : getMidnightInLocalTimeZoneToUtc(date, timeZone);
}

function getAdjustedEndOfDay(date: string, timeZone: string) {
  const isToday = moment.tz(timeZone).isSame(date, 'day');
  return isToday
    ? moment().utc().format("YYYY-MM-DD HH:mm:ss")
    : getEndOfDayInLocalTimeZoneToUtc(date, timeZone);
}