import React, { useEffect, useLayoutEffect, useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import ObservationColumnModal from "view/components/Modals/Observations/ObservationsColumnModal";
import { IfetchQualityAssuranceObservations } from "store/qualityAssurance/initialState";
import useRoles from "hooks/roles";
import { filtersInitialState } from "store/filters/initialState";
import { isAnyQualityAssuranceObservationsFilterApplied } from "../AppliedFilters";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { isExternalReviewer } = useRoles();
  // Getting programsFilter state from Redux store
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const { data } = useSelector<RootState, IfetchQualityAssuranceObservations>(
    (state) => state?.allQualityAssuranceObservations
  );
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };


  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyQualityAssuranceObservationsFilterApplied(filtersData.filters);
      } else {
        return isAnyQualityAssuranceObservationsFilterApplied(qualityAssuranceObservationsFilter);
      }
    });
  }, []);

  // Initializing event listener
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isExternalReviewer={isExternalReviewer()}
          isCloseDiv={isCloseDiv}
        />
        {!isExternalReviewer() && <FiltersList isCloseDiv={isCloseDiv} />}
      </div>
    </>
  );
};
