import {
  ERROR_LOADING_ANALYTICS_REPORTS,
  GET_ALL_ANALYTICS_REPORTS,
  RESET_DATA,
  START_LOADING_ANALYTICS_REPORTS,
  STOP_LOADING_ANALYTICS_REPORTS,
} from "./action-types";
import initialState from "./initialState";
export const analyticsReportsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_ANALYTICS_REPORTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_ANALYTICS_REPORTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_ANALYTICS_REPORTS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_ANALYTICS_REPORTS:
      const reportsAnalytics = action?.payload;
      return {
        ...state,
        data: {
          graphData: reportsAnalytics.graphData,
          tableData: reportsAnalytics.tableData.reports,
          // totalItems: action.payload.totalItems,
          totalPages: reportsAnalytics.tableData.totalPages,
          // currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
