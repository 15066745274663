import React, { useRef } from "react";
import { useInitializeMap, usePaintLayersOnMap } from "./hooks";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR = "#fbb03b";
const Map = ({ data }: any) => {
  const mapContainer = useRef<any>(null);

  // initializing the map
  
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // paint layers on map
  usePaintLayersOnMap(data, map, isMapFullyLoaded, LAYER_DEFAULT_COLOR);

  return <div className="w-full h-[400px]" ref={mapContainer} />;
};

export default Map;
