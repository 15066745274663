import { Row, Table } from "@tanstack/react-table";
import { TColumnVisibility } from "hooks/useTable";

function getAllRowsData(
  table: Table<any>,
  columnOrder: string[],
  columnVisibility: TColumnVisibility,
  columns: any[]
) {
  const data: Row<any>[] = []; // Define data as an empty array
  table?.getRowModel()?.rows?.forEach((row: any) => {    
    const rowValues: any = {}; // Define rowValues as an empty object
    columnOrder?.forEach((columnId: string) => {
      const isVisible = columnVisibility[columnId] ?? true;
      const isColumnVisible =
        isVisible && columns.find((column: any) => column.id === columnId);

      if (isColumnVisible && row.getIsSelected() && columnId !== "select") {
        // Access row values using optional chaining and nullish coalescing
        const value = row.original?.[columnId] ?? row.values?.[columnId] ?? "";
        rowValues[columnId] = value;
      }
    });
    if (row.getIsSelected()) {
      data.push(rowValues);
    }
  });
  return data;
}

export default getAllRowsData;
