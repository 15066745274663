import { apiClient } from "../config";

async function getAllSpecies(queryParams: any) {
  const res = await apiClient.get(`/admin/species`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllSpecies;
