import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box, Stack } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import NotificationNoData from "../imgs/NotificationNoData";
import { TailSpin } from "react-loader-spinner";

export default function DashboardNotifications() {
  const {
    dashboard: { notifications },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchNotifications());
  }, [dashboardFilter]);
  return (
    <Box height={"100%"}>
      <DashboardCardHeading
        text="Notifications"
        widgetId="notifications"
        viewAllLInk={"/custom-notifications/list"}
      />

      {notifications.stateIs == "Idle" && notifications.data.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <NotificationNoData />
        </div>
      ) : notifications.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <List className="w-full py-0">
          {notifications.data.map((notification, index) => {
            return (
              <ListItem
                alignItems="flex-start"
                sx={{
                  p: 0,
                  mb: 1.25,
                }}
              >
                <ListItemAvatar
                  sx={{ mt: 0, minWidth: "36px" }}
                  className="mr-2.5"
                >
                  <Avatar
                    alt={notification.name}
                    src={notification?.personImage?.original}
                    sx={{
                      width: "36px",
                      height: "36px",
                    }}
                    className="shadow"
                  />
                </ListItemAvatar>
                <Stack flexDirection={"column"} className="w-[320px]">
                  <p className="w-full mb-1 mr-1 overflow-hidden text-sm font-medium leading-4 text-black font-Overpass dark:text-textMain overflow-ellipsis">
                    {notification.name}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: notification.description,
                    }}
                    className="w-full mr-1 overflow-hidden text-sm font-normal leading-5 text-textNeutralGray dark:text-caption font-Overpass overflow-ellipsis line-clamp-2"
                  ></p>
                </Stack>
                <p className="ml-auto min-w-fit  text-textNeutralGray dark:text-caption text-sm font-normal font-['Inter'] text-right leading-4">
                  {notification.duration}
                </p>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
}
