import {
  ERROR_LOADING_FILE_DATA,
  GET_IMPORTED_FILE_DATA,
  RESET_DATA,
  START_LOADING_FILE_DATA,
  STOP_LOADING_FILE_DATA,
} from "./action-types";
import initialState, { ImportsFile } from "./initialState";
export const importsFileDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_FILE_DATA:
      return {
        ...state,
        stateIs: "Pending",
      };
    case ERROR_LOADING_FILE_DATA:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_FILE_DATA:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_IMPORTED_FILE_DATA:
      const importedFileData = action.payload.importedFileData.map(
        (item: any) => {
          return item;
        }
      );
      return {
        ...state,
        data: {
          fields: action.payload.fields,
          importedFileData,
          fileName: action.payload.fileName,
          totalItems: action.payload.total,
          totalPages: action.payload.totalPages,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
