import { __changeColorOfZoomedShapeLayer__ } from "../workerMehods";
import { useEffect } from "react";

const useCustomizeColorOfHigilightedLayer = (
  map: any,
  isMapFullyLoaded: any,
  currentQuestionEle: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string,
  answer: any = [],
) => {
  useEffect(() => {
    if (
      !map ||
      !isMapFullyLoaded ||
      !currentQuestionEle ||
      !currentQuestionEle.selectedShapes
    )
      return;
    currentQuestionEle.selectedShapes.forEach((layer: any) => {
      const item = { item: layer, itemType: layer.type, status: true };

      const isItemHighlighted = answer?.find(
        (answerLayer: any) => answerLayer.id == item.item.id
      );

      if (isItemHighlighted) {
        // change the color of selected layer item
        __changeColorOfZoomedShapeLayer__(item, map, LAYER_SELECTED_COLOR);
      } 
      else {
        // change the color of unselected layer item
        __changeColorOfZoomedShapeLayer__(item, map, LAYER_DEFAULT_COLOR);
      }
    });
  }, [answer]);
  return null;
};

export { useCustomizeColorOfHigilightedLayer };
