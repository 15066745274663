import { Dispatch } from "redux";
import { GET_IMPORT_PROPERTY_DROPDOWN, RESET_DATA } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchImportPropertyDropdownAction =
  (importId: number | null) => async (dispatch: Dispatch, getState: any) => {
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.importsPropertyDropdownSearchAction
      );
      const { data, message } = await apiLibrary.Imports.getPropertyDropdownOptions(importId, params);
      dispatch({
        type: GET_IMPORT_PROPERTY_DROPDOWN,
        payload: {data, message},
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the imports:", error);
      // dispatch(fetchError(error))
    } finally {
      dispatch(setLoadingProgress(100));
    }
  };

export const resetImportsAction = () => {
  return {
    type: RESET_DATA,
  };
};
