import React from "react";

import { Step } from "hooks/useReportsStepper";

interface StepBreadcrumbProps {
  steps: any;
  moveToSpecificStep: (step: Step) => void;
}

export const BreadCrumbs: React.FC<StepBreadcrumbProps> = ({
  steps,
  moveToSpecificStep,
}) => {
  return (
    <div className="flex items-center mt-3 mb-4">
      {steps.length > 0 &&
        steps?.map((step: Step, index: number) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.52827 2.86177C5.78862 2.60142 6.21073 2.60142 6.47108 2.86177L11.1377 7.52843C11.2628 7.65346 11.333 7.82303 11.333 7.99984C11.333 8.17665 11.2628 8.34622 11.1377 8.47124L6.47108 13.1379C6.21073 13.3983 5.78862 13.3983 5.52827 13.1379C5.26792 12.8776 5.26792 12.4554 5.52827 12.1951L9.72353 7.99984L5.52827 3.80458C5.26792 3.54423 5.26792 3.12212 5.52827 2.86177Z"
                  fill="#54595F"
                />
              </svg>
            )}

            <button
              onClick={() => moveToSpecificStep(step)}
              className="flex items-center space-x-2 text-textMid hover:text-primaryDark"
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1.5 py-1 rounded-lg">
                <RenderStepperIcon step={step} index={index + 1} />
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-0.5 pb-1">
                  <p
                    className={`flex-grow-0 flex-shrink-0 text-sm text-center ${step.needsReview ? "text-[#b3261e]" : "text-secondaryMid"} ${
                      step.isActive ? "dark:text-textMain" : "dark:text-caption"
                    }`}
                  >
                    {step.title}
                  </p>
                </div>
              </div>
            </button>
          </React.Fragment>
        ))}
    </div>
  );
};

const RenderStepperIcon: React.FC<{ step: Step; index: number }> = ({
  step,
  index,
}) => {
  if (step.needsReview) {
    return (
      <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1 rounded-lg">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
          preserveAspectRatio="none"
        >
          <path
            d="M14.8 4.61286L21.501 15.7739C22.464 17.3769 21.991 19.4859 20.444 20.4839C19.9248 20.8201 19.3196 20.9992 18.701 20.9999H5.298C3.477 20.9999 2 19.4699 2 17.5809C2 16.9419 2.173 16.3169 2.498 15.7739L9.2 4.61286C10.162 3.00986 12.196 2.51886 13.743 3.51686C14.171 3.79286 14.533 4.16786 14.8 4.61286ZM12 16.9999C12.2652 16.9999 12.5196 16.8945 12.7071 16.707C12.8946 16.5194 13 16.2651 13 15.9999C13 15.7346 12.8946 15.4803 12.7071 15.2928C12.5196 15.1052 12.2652 14.9999 12 14.9999C11.7348 14.9999 11.4804 15.1052 11.2929 15.2928C11.1054 15.4803 11 15.7346 11 15.9999C11 16.2651 11.1054 16.5194 11.2929 16.707C11.4804 16.8945 11.7348 16.9999 12 16.9999ZM12 7.99986C11.7348 7.99986 11.4804 8.10522 11.2929 8.29275C11.1054 8.48029 11 8.73464 11 8.99986V12.9999C11 13.2651 11.1054 13.5194 11.2929 13.707C11.4804 13.8945 11.7348 13.9999 12 13.9999C12.2652 13.9999 12.5196 13.8945 12.7071 13.707C12.8946 13.5194 13 13.2651 13 12.9999V8.99986C13 8.73464 12.8946 8.48029 12.7071 8.29275C12.5196 8.10522 12.2652 7.99986 12 7.99986Z"
            fill="#B3261E"
          />
        </svg>
      </div>
    );
  } else if (step.isActive) {
    return (
      <div className="flex justify-center items-center  rounded-lg h-[30px] w-[30px] bg-primaryMid dark:bg-primaryMidLight ">
        <p className="text-xs font-medium text-center text-bgWhite">{index}</p>
      </div>
    );
  } else if (step.isSubmitted) {
    return (
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-1 rounded-lg bg-primary/[0.08]">
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1384 4.19526C13.3987 4.45561 13.3987 4.87772 13.1384 5.13807L6.47173 11.8047C6.21138 12.0651 5.78927 12.0651 5.52892 11.8047L2.86225 9.13807C2.6019 8.87772 2.6019 8.45561 2.86225 8.19526C3.1226 7.93491 3.54471 7.93491 3.80506 8.19526L6.00033 10.3905L12.1956 4.19526C12.4559 3.93491 12.878 3.93491 13.1384 4.19526Z"
            fill="#2C3236"
          />
        </svg>
      </div>
    );
  } else if (!step.isActive && !step.isSubmitted) {
    return (
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 p-1 rounded-lg dark:bg-primary_40 opacity-40">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-secondaryMid dark:text-textMain ">
            {index}
          </p>
        </div>
      </div>
    );
  }
};
