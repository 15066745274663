import { apiClient } from "../config";

async function editProtocol(id: any, data: any) {
  
  const url = `/admin/protocols/${id}`;
  const res = await apiClient.put(url, data);
  return res.data;
}

export default editProtocol;
