import React from "react";
import { Step } from "store/addImportStepper/initialState";
import Button from "view/components/Button";
import { useDispatch } from "react-redux";
import FileUploadSuccessIcon from "assets/placeholders/uploadSuccessIcon";
import { useNavigate } from "react-router-dom";

interface ImportDoneProps {
  activeStep: any;
  isLastStep: any;
  // steps: Step[];
  moveToPreviousStep:()=>void;
  moveToNextStep:()=>void;
}

export const ImportDone: React.FC<ImportDoneProps> = ({
  activeStep,
  isLastStep,
  // steps,
  moveToPreviousStep,
  moveToNextStep,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResults = () => {
    const id = localStorage.getItem("importId");
    const defaultPath = `/imports`; // Default path when 'id' is not found

    if (id) {
      const targetPath = `/imports/view-data/${id}`;
      navigate(targetPath);
    } else {
      navigate(defaultPath);
    }

    localStorage.removeItem("importId");
  };

  return (
    <div className="py-4">
      <div className="relative w-full gap-4 bg-white rounded-lg">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-2">
            <FileUploadSuccessIcon width={180} height={180} />
          </div>

          <h5 className="text-lg font-semibold font-Overpass text-textMid dark:text-textMain">
            You are all done! Your data was imported successfully
          </h5>
          <p className="text-sm text-textMidLight font-Overpass dark:text-caption ">
            Want to check imports result that was already processed? Click the
            button below to navigate to all processed imports.
          </p>

          <div className="flex items-center gap-2 mt-4 see-results-btn">
            <Button
              type="submit"
              onClick={handleResults}
              text="See Results"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2"
              width="35"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
