import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { GET_OBSERVATIONS, GET_OBSERVATIONS_STATE } from "../action-types";
import { ObservationDuration } from "./types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- OBSERVATIONS
 * @param communityIds
 * @param organizationIds
 * @param programIds
 * @returns
 */
export const fetchObservations =
  (duration: ObservationDuration) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_OBSERVATIONS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.datePeriod) {
        Object.assign(params, {
          start_date: params.datePeriod.from,
        });
        delete params.datePeriod;
      }

      // APPEND DURATION IN QUERY
      Object.assign(params, {
        duration,
      });
      const data = await apiLibrary.Dashboard.widgets.getObservations(params);
      dispatch({
        type: GET_OBSERVATIONS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_OBSERVATIONS_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log("An error occurred while fetching the observations:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
