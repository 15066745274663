import { GET_ORGANIZATION_PROFILE, RESET_PROFILE_ACTION } from "./action-types";
import { initialOrganizationData } from "./initialState";

const organizationReducer = (
  state = initialOrganizationData,
  action: any
): any => {
  switch (action.type) {
    case GET_ORGANIZATION_PROFILE:
      return action.payload;
    case RESET_PROFILE_ACTION:
      return initialOrganizationData;
    default:
      return state;
  }
};

export default organizationReducer;
