import React, { useEffect, useLayoutEffect } from "react";
// Components
import {
  Filters,
  Header,
  Pagination,
  Table,
  BarGraph,
} from "Components/ReportsAnalytics";
// Hooks
import { ReportsAnalyticsTable } from "hooks/useTable";

// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TableProps } from "Components/Programs/Table/types";
import { filtersInitialState } from "store/filters/initialState";
import { fetchReportsAnalyticsAction } from "store/reportsAnalytics";
import { useLocation, useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { setReportsAnalyticsAction } from "store/filters/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const ReportsAnalytics = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const location = useLocation();

  // Accessing the state
  const { editMode } = location.state || {};
  useLayoutEffect(() => {
    if (!editMode) {
      dispatch(setReportsAnalyticsAction());
    }
  }, [editMode]);

  const { data } = useSelector<RootState, any>(
    (state) => state?.reportsAnalytics
  );
  const { reportsAnalyticsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const { reportsId } = useParams<any>();

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ReportsAnalyticsTable(data?.tableData);
  useEffect(() => {
    dispatch(fetchReportsAnalyticsAction(reportsId));
    saveFiltersDataToLocalStorage(reportsAnalyticsFilter);
  }, [reportsAnalyticsFilter]);

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-6 ">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>
        <div className="w-full mb-8">
          <BarGraph
            graphData={data?.graphData}
            isDateFilter={reportsAnalyticsFilter?.date?.applied}
          />
        </div>
        <RenderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default ReportsAnalytics;

const RenderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, any>(
    (state) => state?.reportsAnalytics
  );

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs == "Idle" && data?.tableData?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
