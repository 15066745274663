import { apiClient } from "../config";

async function updateContactPrefrences(payload: any) {
  const data = {
    person: {
      contact_preference: payload,
    },
  };
  const res = await apiClient.put(`/admin/account-info`, data);
  return res.data;
}

export default updateContactPrefrences;
