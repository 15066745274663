import { HeaderBarProps } from "../../types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";


const HeaderBar = ({
  data,
  isOpen,
  setIsOpen,
  handleToggleDropdown,
  isCloseDiv
}: HeaderBarProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-caption">
            {data?.totalItems} Items
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
