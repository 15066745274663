import React from "react";
import CustomCheckbox from "view/components/CheckBox";
import { getBrandPlaceholder } from "utils/customBranding";

interface AdminProps {
    adminData: any;
    toggleAdminPermission: any;
}

export const Admin: React.FC<AdminProps> = ({ adminData, toggleAdminPermission }) => {

    return (
        <div className="w-full user-list">
            <div className="flex justify-between items-center gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
                <div
                    onClick={() => toggleAdminPermission(adminData, !adminData.obsImportPermission)}
                    className="relative flex items-center justify-between cursor-pointer"
                >
                    <CustomCheckbox
                        name="notify"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            toggleAdminPermission(adminData, !adminData.obsImportPermission);
                        }}
                        checked={adminData?.obsImportPermission}
                        labelPlacement="end"
                        labelClassName="text-left text-textMidLight font-Overpass font-medium leading-6 text-base mt-1"
                    />
                    <img
                        src={
                            adminData?.personImage?.file?.thumb ? adminData?.personImage?.file?.thumb : getBrandPlaceholder("userProfile")
                        }
                        alt=""
                        height="32px"
                        width="32px"
                        className="object-cover mx-2 Img_user_Data"
                        style={{ flexShrink: 0 }}
                    />
                    <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${adminData?.name}`}</p>
                </div>
            </div>
        </div>
    );
};
