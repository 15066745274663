import {
  GET_ADDITIONAL_INFORMATION,
  GET_ALL_PROFILE_ROLES,
  RESET_DATA,
} from "./action-types";
import {
  IProfileRoles,
  initialState,
  profileRoleAdditionalInfoIntitialState,
} from "./initialState";
export const profileRolesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_PROFILE_ROLES:
      return {
        ...state,
        data: {
          communityRoles: action.payload?.communityRoles,
          organizationRoles: action.payload?.organizationRoles,
          programsRoles: action.payload?.programsRoles,
        },
        message: "",
      };

    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
export const profileAdditionalInformationReducer = (
  state = profileRoleAdditionalInfoIntitialState,
  action: any
) => {
  switch (action.type) {
    case GET_ADDITIONAL_INFORMATION:
      return {
        ...state,
        data: action.payload,
        message: ""
      };

    case RESET_DATA:
      return profileRoleAdditionalInfoIntitialState;

    default:
      return state;
  }
};
