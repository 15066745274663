import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { formatTheTime } from "utils/formatTheTime";
import { TIME_FORMATS } from "constants/timeFormats";

interface CustomTimePickerProps {
  initialValue: string;
  handleChange: (value: string | null) => void;
  min: string;
  max: string;
  className?: string;
  touched?: boolean;
  disabled?: boolean;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  initialValue,
  handleChange,
  min,
  max,
  className,
  disabled,
  ...restProps
}) => {
  const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(
    moment(initialValue, TIME_FORMATS.HOURS_MINUTES_AMPM)
  );

  useEffect(() => {
    if (
      initialValue &&
      moment(initialValue, TIME_FORMATS.HOURS_MINUTES_AMPM).isValid()
    ) {
      setSelectedTime(moment(initialValue, TIME_FORMATS.HOURS_MINUTES_AMPM));
    } else {
      setSelectedTime(null);
    }
  }, [initialValue]);

  const handleTimeChange = (time: moment.Moment | null) => {
    if (time && time.isValid()) {
      const formattedTime = formatTheTime(
        time.toISOString(),
        TIME_FORMATS.HOURS_MINUTES_AMPM
      );
      setSelectedTime(time);
      handleChange(formattedTime);
    } else {
      setSelectedTime(null);
      handleChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...restProps}
        value={selectedTime}
        onChange={handleTimeChange}
        maxTime={max ? moment(max, TIME_FORMATS.FULL_TIME) : undefined}
        minTime={min ? moment(min, TIME_FORMATS.FULL_TIME) : undefined}
        ampm={true}
        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
        disabled={disabled}
        className={className}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
