import usePermissions from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { RootState } from "store";
import { setLoadingProgress } from "store/loadingBar";
import { getUserProfileAction } from "store/userProfile";
import { UserProfileState } from "store/userProfile/initialState";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";
import { MaterialUiSelect } from "view/pages/MyProfile/Components/Inputs/Select";

const ChangePrimaryPhoneNumber: React.FC<{
  refreshNewData: (data: any) => void;
}> = ({ refreshNewData }) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<any>("");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const verifiedPhoneNumbers = userProfile.secondaryPhoneNumber.filter(
    (item: any) => item.isVerified
  );

  const { personId } = useParams();
  const { profile } = usePermissions();

  const handlePrimaryChange = async () => {
    setIsLoading(true);
    if (updatedPhoneNumber && updatedPhoneNumber?.isPrimary) {
      setIsLoading(false);
      return;
    }
    try {
      dispatch(setLoadingProgress(getRandomProgress()));
      if (
        updatedPhoneNumber &&
        updatedPhoneNumber?.isVerified &&
        profile.canEditPrimaryEmailPhoneProfile
      ) {
        const res = await apiLibrary.userProfile.changePrimaryEmailOrPhone(
          {
            phone_or_email: updatedPhoneNumber?.phoneNumber,
          },
          personId
        );

        refreshNewData(res.data);
        Toasts.success(res.message);
        setUpdatedPhoneNumber("");
      }
    } catch (error: any) {
      // Handle the error appropriately
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  useEffect(() => {
    if (!updatedPhoneNumber) {
      setUpdatedPhoneNumber(
        verifiedPhoneNumbers?.find((email: any) => email.isPrimary)
      );
    }
  }, []);

  return (
    <div className="flex items-center ">
      <div className=" flex flex-col w-[300px]">
        <p
          className={`flex-grow pb-2 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption`}
        >
          Update Primary Phone Number
        </p>
        <MaterialUiSelect
          options={verifiedPhoneNumbers}
          labelKey="phoneNumber"
          valueKey="phoneNumber"
          initialValue={
            updatedPhoneNumber
              ? updatedPhoneNumber.phoneNumber
              : verifiedPhoneNumbers?.find((number: any) => number.isPrimary)
                  ?.phoneNumber
          }
          handleChange={(value: any) => {
            setUpdatedPhoneNumber(value);
          }}
          width={300}
        />
      </div>

      <button
        disabled={
          isLoading ||
          (updatedPhoneNumber && updatedPhoneNumber?.isPrimary) ||
          !profile.canEditPrimaryEmailPhoneProfile
        }
        type="button"
        className=" border  border-lineMid disabled:cursor-not-allowed dark:border-lineLight rounded h-[40px] w-[70px] ml-3 mt-7 bg-primary text-textMain dark:text-caption"
        onClick={handlePrimaryChange}
      >
        Save
      </button>
    </div>
  );
};

export default ChangePrimaryPhoneNumber;
