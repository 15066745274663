import { Dispatch } from "redux";
import {
  ERROR_LOADING_BEHAVIORS,
  GET_ALL_BEHAVIORS,
  RESET_DATA,
  START_LOADING_BEHAVIORS,
  STOP_LOADING_BEHAVIORS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchBehaviorsAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_BEHAVIORS });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.behaviorsFilter
      );

      const { data } =
        await apiLibrary.BehaviorsAndConditions.getAllBehaviors(queryString);
      dispatch({
        type: GET_ALL_BEHAVIORS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({ type: ERROR_LOADING_BEHAVIORS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_BEHAVIORS });
      // dispatch(loadingState(false))
    }
  };
