export interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
}

export interface Stepper {
  steps: Step[];
  currentReportId: any;
  reportName: string;
  regions: any;
}

export const reportStepNames = {
  reportName: "reportName",
  permissions: "permissions",
  reportSettings: "reportSettings",
  exporting: "exporting",
  geospatialFiltering: "geospatialFiltering",
  filtering: "filtering",
  columnsConfiguration: "columnsConfiguration",
};

const initialState: Stepper = {
  steps: [
    {
      title: "Name",
      step: reportStepNames.reportName,
      isActive: true,
      isSubmitted: false,
      // isVisible: false,
    },
    {
      title: "Permissions",
      step: reportStepNames.permissions,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Report Settings",
      step: reportStepNames.reportSettings,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Exporting",
      step: reportStepNames.exporting,
      isActive: false,
      isSubmitted: false,
    },

    {
      title: "Geospatial query building",
      step: reportStepNames.geospatialFiltering,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Columns Configuration",
      step: reportStepNames.columnsConfiguration,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Filtering",
      step: reportStepNames.filtering,
      isActive: false,
      isSubmitted: false,
    },
  ],
  currentReportId: null,
  reportName: "",
  regions: [
    {
      name: "",
      value: "",
      type: "",
    },
  ],
};

export default initialState;
