function __paintShapeByGeoJsonOnMap__(
  map: any,
  draw: any,
  sourceId: string,
  layerId: string,
  geoJson: any,
  defaultColor: any
) {
  if (!map || !sourceId || !layerId || !geoJson) {
    return;
  }

  const isGeojsonUrl = typeof geoJson === "string";

  // adding geojson source
  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  if (isGeojsonUrl) {
    const fileGeoJsonLayerClor = "purple";

    map.addLayer({
      id: layerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": fileGeoJsonLayerClor,
        "fill-opacity": 0.6,
      },
    });

    // map.on("sourcedata", (e: any) => {
    //   if (e.sourceId === sourceId && e.isSourceLoaded) {
    //     const source = map.getSource(sourceId);
    //     const features = source._data.features;
    //     console.log("Geojson of a layer which has the file url: ", source.serialize().data);
    //     // const featureCount = features.length;

    //     const isGeoJsonHasLineString = features?.find(
    //       (f: any) =>
    //         f.geometry?.type === "LineString" ||
    //         f.geometry?.type === "MultiLineString"
    //     );
    //     // design and register the added geojson source layer

    //   }
    // });
  } else {
    const isGeoJsonHasLineString = geoJson?.features?.find(
      (f: any) =>
        f.geometry?.type === "LineString" ||
        f.geometry?.type === "MultiLineString"
    );

    // // design and register the added geojson source layer
    map.addLayer({
      id: layerId,
      type: isGeoJsonHasLineString ? "line" : "fill",
      source: sourceId,
      layout: {},
      paint: {
        [isGeoJsonHasLineString ? "line-color" : "fill-color"]: defaultColor,
        [isGeoJsonHasLineString ? "line-opacity" : "fill-opacity"]: 0.6,
      },
    });

    // Listen for the 'sourcedata' event to ensure the data is loaded
    // map.on('sourcedata', (e:any) => {
    //   if (e.sourceId === sourceId && e.isSourceLoaded) {
    //     const source = map.getSource(sourceId);
    //     const features = source._data.features;
    //     const featureCount = features.length;
    //     console.log("geojson of source data: ",features)
    //     console.log(`Number of features in the GeoJSON: ${featureCount}`);
    //   }
    // });
  }
}

export { __paintShapeByGeoJsonOnMap__ };
