import React from "react";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { sentenceCase, camelCase } from "change-case";
import useDateFormatter from "hooks/formatDateWithPattern";

interface IProps {
  filter: any;
  dateFilter: any;
  reportSetting: any;
  repeatedDateFilter: any;
}

export const SummaryFiltering: React.FC<IProps> = ({
  filter,
  dateFilter,
  reportSetting,
  repeatedDateFilter,
}) => {
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  const { formatDate, FormatDateTime, formatTime } = useDateFormatter();

  return (
    <div className="pt-2 pb-4 border-b border-lineMid">
      <Accordion
        className="dark:bg-secondaryLight "
        style={{ boxShadow: "none" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex items-center justify-between w-full">
              <p className="text-secondaryMid dark:text-textMain ">Filtering</p>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: "-20px" }}>
          <Typography>
            <p className="text-sm text-secondaryMidLight dark:text-caption">
              Time Query Builder
            </p>
            {!reportSetting.isRepeat ? (
              dateFilter?.map((el: any, index: number) => {
                return (
                  <div
                    className="flex items-center justify-between px-4 py-2"
                    key={index}
                  >
                    <p className="text-sm mt-[-3px] dark:text-textMain">
                      {el.dateType === "observer_date"
                        ? "Submission Local Date"
                        : "Submission Date"}
                    </p>
                    <div className="flex">
                      <p className="text-sm mt-[-3px] ml-6 dark:text-caption">
                        {el?.condition}
                      </p>
                      <p className="text-sm mt-[-3px] ml-6 dark:text-textMain">
                        {index === 0
                          ? formatDate(el?.startDate)
                          : formatDate(el?.endDate)}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : repeatedDateFilter?.periodType === "was_during_the_past" ? (
              <div className="flex items-center justify-between px-4 py-2">
                <p className="text-sm mt-[-3px] dark:text-textMain flex">
                  {repeatedDateFilter?.periodType === "was_during_the_past" &&
                    "Observation Date"}{" "}
                  ( {repeatedDateFilter?.period} )
                </p>
                <div className="flex">
                  <p className="text-sm mt-[-3px] ml-6 dark:text-textMain">
                    {repeatedDateFilter?.periodDuration}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between px-4 py-2">
                <p className="text-sm mt-[-3px] dark:text-textMain">
                  {repeatedDateFilter?.date && "Observation Date"}
                </p>
                <div className="flex">
                  <p className="text-sm mt-[-3px] ml-6 dark:text-textMain">
                    {formatDate(repeatedDateFilter?.date)}
                  </p>
                </div>
              </div>
            )}
            <p
              className={`text-secondaryMidLight text-sm dark:text-caption ${dateFilter.length === 0 ? "mt-2" : ""}`}
            >
              Query
            </p>
            {filter?.map((el: any) => {
              return (
                <div className="flex items-center justify-between px-4 py-2">
                  <p className="text-sm mt-[-3px] dark:text-textMain">
                    {sentenceCase(camelCase(el?.column ?? ""))}
                  </p>
                  <div className="flex">
                    <p className="text-sm mt-[-3px] ml-6 dark:text-caption">
                      {el?.condition}
                    </p>
                    <p className="text-sm mt-[-3px] ml-6 dark:text-textMain">
                      {el.type === "datetime-local"
                        ? FormatDateTime(el?.value?.value)
                        : el.type === "time"
                          ? formatTime(el?.value?.value)
                          : el.type === "date"
                            ? formatDate(el?.value?.value)
                            : Array.isArray(el?.value)
                              ? el?.value
                                  ?.map((item: any) => item.text)
                                  .join(", ")
                              : el?.value?.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
