import { Dispatch } from "redux";
import { ERROR_LOADING_POLL_AND_SURVEY_DETAILS, GET_POLL_AND_SURVEY_DETAILS, RESET_DATA, START_LOADING_POLL_AND_SURVEY_DETAILS, STOP_LOADING_POLL_AND_SURVEY_DETAILS,  } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchSurveyAndPollResultAction =
  (id: number) => async (dispatch: Dispatch, getState: any) => {
    dispatch({type: START_LOADING_POLL_AND_SURVEY_DETAILS});
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.pollAndSurveyResultsFilter
      );
      const { data } = await apiLibrary.pollsAndSurveys.getPollAndSurveyResults(id, queryString);
      dispatch({
        type: GET_POLL_AND_SURVEY_DETAILS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching survey and poll details:", error);
      dispatch({type: ERROR_LOADING_POLL_AND_SURVEY_DETAILS})
      // dispatch(fetchError(error))
    } finally {
      dispatch({type: STOP_LOADING_POLL_AND_SURVEY_DETAILS})
      // dispatch(loadingState(false))
    }
  };

  export const pollsAndSurveyResetResultAction = () => {
    return {
      type: RESET_DATA
    }
  }
