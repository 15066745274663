export interface ISummary {
  id: number;
  description: string;
  title: string;
  status: string;
  communities: SummaryData[];
  programs: SummaryData[];
  roles: SummaryData[];
  createdBy: string;
  insertedAt: string;
  urlLink: string;
  recipients: SummaryRecipients[];
  recipientsCount: number;
  remindersHistory: SummaryHistory[];
  notifyingChannels: SummaryNotifying;
}

interface SummaryNotifying {
  push: boolean;
  email: boolean;
  toast: boolean;
  inApp: boolean
}
interface SummaryRecipients {
  firstName: string;
  lastName: string;
  personId: number;
  personImage: {
    original: null | string;
    thumb: null | string;
  }
}
interface SummaryData {
  id: number;
  name: string;
}

interface SummaryHistory {
  insertedAt: string;
  status: string;
}

const initialNotificationsSummaryData: ISummary = {
  id: 0,
  description: "",
  title: "",
  status: "",
  communities: [],
  programs: [],
  roles: [],
  createdBy: "",
  insertedAt: "",
  urlLink: "",
  recipients: [],
  recipientsCount: 0,
  remindersHistory: [],
  notifyingChannels: {
    push: false,
    email: false,
    toast: false,
    inApp: false,
  }
// You can set a default status value here
};

export { initialNotificationsSummaryData };
