import { AddConfigurationBtnProps } from "../../types";

const ConfigurationBtn = ({ handleClick }: AddConfigurationBtnProps) => {
  return (
    <button
      onClick={handleClick}
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition dark:hover:bg-primary dark:border-borderSilverBlue"
    >
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
        <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center dark:text-primaryOutlined text-primary dark:group-hover:text-primary">
          Configuration
        </p>
      </div>
    </button>
  );
};

export { ConfigurationBtn };
