import React from "react";
// Components
import {
  Statuses
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  observationsResetFiltersAction,
  observationsResetFiltersExceptCommunityAction,
  observationsResetFiltersExceptProgramAction,
  reportsProfileResetFiltersAction,
} from "store/filters/reducer.actions";
import {
  filtersInitialState,
  observationsDirectoryFilterState,
  reportsDirectoryFilterState,
  reportsProfileDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting programsFilter state from Redux store
  const { reportProfileFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting organizationId from URL parameters
  const { reportsId } = useParams<{
    reportsId: any;
  }>();
  const statusLabels = ["processing", "ready"];

  // Function to check if any filter is applied
  const isAnyFilterApplied = (
    reportProfileFilter: reportsProfileDirectoryFilterState
  ): boolean => {
    const {
      status,
    } = reportProfileFilter;
    const applied = (status?.applied && statusLabels.includes(status.value));
    return applied;
  };

  const clearAllFilters = () => {
    if (reportsId) {
      dispatch(reportsProfileResetFiltersAction());
    }
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyFilterApplied(reportProfileFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
       )} 
            <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Statuses />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;
