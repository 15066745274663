import React, { useEffect, useRef, useState } from 'react';
import { RenderCellContentProps } from '../../types';
import ellipsize from 'ellipsize';
import useDateFormatter from 'hooks/formatDateWithPattern';
import { Statuses } from 'Components/ViewImportsData/Statuses';
import ObserversDropdown from '../ObserversDropdown';
import {
  CheveronDownIcon,
  ExclamationCircleIcon,
} from 'assets/icons/HeroIcons';
import { Link } from 'react-router-dom';
import { getBrandPlaceholder } from 'utils/customBranding';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
  programId,
  id,
  observers,
  rowData,
}) => {
  const { formatDate } = useDateFormatter();

  const [loading, setLoading] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  const [showObservers, setShowObservers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Click outside handler
  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowObservers(false);
    }
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (
    typeof cellValue === 'object' &&
    cellValue !== null &&
    columnDef.id === 'programName'
  ) {
    const img = cellValue.programImage
      ? cellValue?.programImage.original
      : null;
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div className='flex justify-start w-[250px] items-center relative'>
          <img
            src={img || getBrandPlaceholder("listingProfile")}
            alt=''
            height='100'
            width='100'
            className='object-cover mx-2 Img_user_Data'
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: 'wrap' }}
            className=' flex flex-col w-full justify-start items-start relative gap-0.5'
          >
            <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark'>
              {cellValue?.name}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'communityName') {
    if (cellValue === undefined) {
      return <></>;
    }
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div className={`relative flex items-start py-1 px-3`}>
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {cellValue}
          </p>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'observationCode') {
    if (cellValue === undefined) {
      return <></>;
    }
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div className={`relative flex items-start py-1 px-3`}>
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {cellValue?.toString() === 'failed'
              ? `[Failed]`
              : cellValue?.toString()}
          </p>
        </div>
      </Link>
    );
  } else if (
    columnDef.id === 'observer' ||
    columnDef.id === 'observerName' ||
    columnDef.id === 'observer1' ||
    columnDef.id === 'personId' ||
    columnDef.id === 'observer1'
  ) {
    const observerData = observers?.find((observer: any) => {
      return observer?.id === cellValue;
    });
    return (
      <div className='relative w-[200px] py-1 px-3' ref={ref}>
        <div
          className='flex items-center gap-3'
          onClick={handleClick}
          aria-controls='simple-menu'
          aria-haspopup='true'
        >
          <div className='flex items-center gap-2'>
            {(typeof cellValue === 'string' || cellValue === null) && (
              <ExclamationCircleIcon
                className='w-5 h-5 fill-!accent_1Dark'
                fill='#BF200B'
              />
            )}
            <p className='text-sm text-textMid cursor-pointer'>
              {cellValue
                ? typeof cellValue === 'string'
                  ? cellValue
                  : observerData?.name
                : 'Not Selected'}
            </p>
          </div>
          <button>
            <CheveronDownIcon height={15} width={15} />
          </button>
        </div>
        <div className='w-[300px]'>
          <ObserversDropdown
            observers={observers}
            id={id}
            handleClose={handleClose}
            anchorEl={anchorEl}
          />
        </div>
      </div>
    );
  }

  // else if (cellValue !== null && columnDef.id === "observer") {

  //   return (
  //     <div className="relative w-[200px] py-1 px-3" ref={ref}>
  //       <div
  //         className="flex items-center gap-3"
  //         onClick={handleClick}
  //         aria-controls="simple-menu"
  //         aria-haspopup="true"
  //       >
  //         <div className="flex items-center gap-2">
  //           {cellValue?.toString() === "failed" && <ExclamationCircleIcon className="w-5 h-5 fill-!accent_1Dark" fill="#BF200B" />}
  //           <p className="text-sm text-textMid">
  //             {cellValue && cellValue?.toString() !== "failed" ? cellValue?.toString() : "Virtual Observer"}
  //           </p>
  //         </div>

  //         <button>
  //           <CheveronDownIcon height={15} width={15} />
  //         </button>
  //       </div>

  //       <div className="w-[300px]">
  //         <ObserversDropdown
  //           observers={observers}
  //           id={id}
  //           handleClose={handleClose}
  //           anchorEl={anchorEl}
  //         />
  //       </div>
  //     </div>
  //   );
  // }
  else if (cellValue !== null && columnDef.id === 'observationDate') {
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {cellValue?.toString() === 'failed'
              ? `[Failed]`
              : formatDate(cellValue?.toString())}
          </p>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'protocol') {
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {ellipsize(`${cellValue.name}`, 50)}
          </p>
        </div>
      </Link>
    );
  } else if (
    (columnDef.id === 'importStatus' ||
      columnDef.id === 'observationStatus' ||
      columnDef.id === 'status') &&
    cellValue !== null
  ) {
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div className='flex items-start w-[100px] flex-grow px-3 py-1 status'>
          <Statuses status={cellValue} />
        </div>
      </Link>
    );
  } else if (cellValue !== null) {
    return (
      <Link
        to={
          rowData?.importStatus === 'imported'
            ? `/observations/${rowData?.observationId}/profile`
            : ''
        }
      >
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {typeof cellValue === 'undefined'
              ? ''
              : cellValue?.toString() === 'failed'
                ? `[Failed]`
                : ellipsize(`${cellValue}`, 50)}
          </p>
        </div>
      </Link>
    );
  } else {
    return null;
  }
};
