import { SocialAppleIcon, SocialMs365Icon } from "assets/icons/SocialIcons";
import {
  ConnectFacebook,
  ConnectGoogle,
  ConnectApple,
} from "Components/Profile/Account/components/AttachSocialAccounts";

export const LinkedSocialAccounts = () => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 bg-white">
      <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pt-6 pl-8 pr-8 xl:pl-12">
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <div className="relative flex flex-col items-start justify-center flex-grow py-1">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
              Linked Accounts
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 px-8 pt-4 pb-12 border-t-0 border-l-0 border-r-0 xl:px-12 border-lineMid">
        <div className="relative flex items-start justify-start flex-grow-0 flex-shrink-0 gap-1 h-9">
          <ConnectFacebook />
          <ConnectGoogle />
          {/* <SocialButton icon={SocialMs365Icon} text="Disconnected" /> */}
          <ConnectApple />
        </div>
      </div>
    </div>
  );
};
