import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const FormsIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <g filter="url(#filter0_d_6535_1108410)">
      <circle cx="25" cy="24" r="19" fill="#FFF8DF" />
    </g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M28.4111 14.375H17.4111C17.2288 14.375 17.0539 14.4474 16.925 14.5764C16.7961 14.7053 16.7236 14.8802 16.7236 15.0625V30.1875C16.7247 31.0988 17.0872 31.9726 17.7317 32.617C18.3761 33.2614 19.2498 33.6239 20.1611 33.625H31.1611C31.3435 33.625 31.5183 33.5526 31.6473 33.4236C31.7762 33.2947 31.8486 33.1198 31.8486 32.9375V17.8125C31.8475 16.9012 31.485 16.0274 30.8406 15.383C30.1962 14.7386 29.3225 14.3761 28.4111 14.375ZM27.7236 28.8125H20.8486C20.6663 28.8125 20.4914 28.7401 20.3625 28.6111C20.2336 28.4822 20.1611 28.3073 20.1611 28.125C20.1611 27.9427 20.2336 27.7678 20.3625 27.6389C20.4914 27.5099 20.6663 27.4375 20.8486 27.4375H27.7236C27.906 27.4375 28.0808 27.5099 28.2098 27.6389C28.3387 27.7678 28.4111 27.9427 28.4111 28.125C28.4111 28.3073 28.3387 28.4822 28.2098 28.6111C28.0808 28.7401 27.906 28.8125 27.7236 28.8125ZM27.7236 24.6875H20.8486C20.6663 24.6875 20.4914 24.6151 20.3625 24.4861C20.2336 24.3572 20.1611 24.1823 20.1611 24C20.1611 23.8177 20.2336 23.6428 20.3625 23.5139C20.4914 23.3849 20.6663 23.3125 20.8486 23.3125H27.7236C27.906 23.3125 28.0808 23.3849 28.2098 23.5139C28.3387 23.6428 28.4111 23.8177 28.4111 24C28.4111 24.1823 28.3387 24.3572 28.2098 24.4861C28.0808 24.6151 27.906 24.6875 27.7236 24.6875ZM27.7236 20.5625H20.8486C20.6663 20.5625 20.4914 20.4901 20.3625 20.3611C20.2336 20.2322 20.1611 20.0573 20.1611 19.875C20.1611 19.6927 20.2336 19.5178 20.3625 19.3889C20.4914 19.2599 20.6663 19.1875 20.8486 19.1875H27.7236C27.906 19.1875 28.0808 19.2599 28.2098 19.3889C28.3387 19.5178 28.4111 19.6927 28.4111 19.875C28.4111 20.0573 28.3387 20.2322 28.2098 20.3611C28.0808 20.4901 27.906 20.5625 27.7236 20.5625Z"
      fill="#EBCD8B"
    />
    <defs>
      <filter
        id="filter0_d_6535_1108410"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6535_1108410"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6535_1108410"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default FormsIcon;
