export interface INotificationsRecipients {
  recipientName: {
    firstName: string;
    lastName: string;
    personImage: {
      original: string;
      thumb: string;
    };
    personId: number;
  };
  reminderId: number;
  notificationCenter: boolean;
  toast: boolean;
  push: boolean;
  email: string;
  dateRecieved: string;
}

export interface NotificationsRecipientsData {
  members: INotificationsRecipients[];
  status: string | null;
  fields: string[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchNotificationsRecipients {
  data: NotificationsRecipientsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialNotificationRecipientsState: IfetchNotificationsRecipients = {
  data: {
    members: [],
    fields: [],
    status: "",
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialNotificationRecipientsState;
