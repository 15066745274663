import { apiClient } from "../config";

async function deleteReviewers(
  programId: any,
  personId: any,
  permanentDelete: any
) {
  const response = await apiClient.delete(
    `/admin/programs/${programId}/manager/${personId}`,
    {
      params: {
        permanentDelete: permanentDelete,
      },
    }
  );

  return response.data;
}

export default deleteReviewers;
