import usePermissions from "hooks/usePermissions";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import { RootState } from "store";
import {
  updateAllCommunityLayers,
  updateAllCommunityLayersFetchingProgress,
} from "store/surveyJS";

const useFetchAllCommunityLayers = () => {
  const dispatch = useDispatch();
  const { formId } = useParams();
  const { allCommunityLayers } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const { dynamicForms } = usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      if (!formId || (allCommunityLayers && allCommunityLayers?.length != 0))
        return;

      dispatch(updateAllCommunityLayersFetchingProgress(false));
      try {
        if (!dynamicForms.canViewDynamicFormGeographies) {
          return;
        }
        const response = await surveyJsApi.default.fetchLayers(formId, "shape");

        dispatch(updateAllCommunityLayers(response.data));
      } catch (err) {
      } finally {
        dispatch(updateAllCommunityLayersFetchingProgress(true));
      }
    };

    fetchData();

    return () => {
      dispatch(updateAllCommunityLayers([]));
    };
  }, [formId, dispatch]);

  return null;
};

export { useFetchAllCommunityLayers };
