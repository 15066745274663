export const baseURL = process.env.REACT_APP_API_URL || "";
export const apiUrl = process.env.REACT_APP_API_URL || "";
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
export const googleClientSecret =
  process.env.REACT_APP_GOOGLE_CLIENT_SECRET || "";
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || "";
export const port = process.env.REACT_APP_PORT || "";
export const domainUrl = process.env.REACT_APP_DOMAIN_URL || "";
export const emailConfirmationBaseUrl =
  process.env.REACT_APP_EMAIL_CONFIRMATION_BASE_URL || "";
export const mailerApiKey = process.env.REACT_APP_MAILER_API_KEY || "";
export const mailerUsername = process.env.REACT_APP_MAILER_USERNAME || "";
export const mailerDomain = process.env.REACT_APP_MAILER_DOMAIN || "";
export const twilioSid = process.env.REACT_APP_TWILIO_SID || "";
export const twilioAuthToken = process.env.REACT_APP_TWILIO_AUTH_TOKEN || "";
export const twilioResourceSid =
  process.env.REACT_APP_TWILIO_RESOURCE_SID || "";
export const twilioPhoneNo = process.env.REACT_APP_TWILIO_PHONE_NO || "";
export const facebookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID || "";
export const facebookClientSecret =
  process.env.REACT_APP_FACEBOOK_CLIENT_SECRET || "";
export const bucket = process.env.REACT_APP_BUCKET || "";
export const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID || "";
export const awsSecretAccessKey =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "";
export const googleGeoCodeApiKey =
  process.env.REACT_APP_GOOGLE_GEOCODE_KEY || "";
export const currentEnvironment = process.env.REACT_APP_CURRENT_ENV || "";
export const sentryDNS = process.env.REACT_APP_SENTRY_DNS_URL || "";
export const reactVersioningEndpoint = process.env.REACT_APP_VERSIONING_ENDPOINT || "";
export const hotJarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID || "";
export const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION || "";
export const observerAppUrl = process.env.REACT_APP_OBSERVER_APP_URL||""

// export const googleGeoCodeApiKey =
// process.env.REACT_APP_GOOGLE_GEOCODE_KEY || "";
// export const googleGeoCodeApiKey =
// process.env.REACT_APP_GOOGLE_GEOCODE_KEY || "";
