import { GET_REPORTS_SUMMARY } from "./action-types";
import { initialReportsSummaryData } from "./initialState";

const reportsSummaryReducer = (
  state = initialReportsSummaryData,
  action: any
): any => {
  switch (action.type) {
    case GET_REPORTS_SUMMARY:
      return action.payload;
    case "RESET_REPORTS_SUMMARY_DATA":
      return initialReportsSummaryData;
    default:
      return state;
  }
};

export default reportsSummaryReducer;
