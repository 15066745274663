import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

// assets
import locationMarkerImage from "assets/images/location-marker.svg";
import selectedLocationMarkerImage from "assets/images/selected-location-marker.svg";
import { __deletePaintedLayers__ } from "../workerMehods";

const useCreateMarkers = (data: any, map: any, isMapFullyLoaded: any) => {
  const [currentClickedMarker, setCurrentClickedMarker] = useState<any>(null);
  const [currentSelectedCommunityId, setCurrentClickedCommunityId] = useState<
    string | null
  >(null);
  const createdMarkersRef = useRef<Map<any, mapboxgl.Marker>>(new Map());

  useEffect(() => {
   
  }, [data]);

  useEffect(() => {
    if (!data || !map || !isMapFullyLoaded) return;
    // removing existing layers
    __deletePaintedLayers__(map,"region");

    // removing existing markers
    createdMarkersRef.current.forEach((marker) => marker.remove());
    createdMarkersRef.current.clear();

    data.forEach((dataEle: any) => {
      const { location, communityId } = dataEle;
      // verifying that is marker already created
      if (!location || createdMarkersRef.current.has(location?.id)) {
        return;
      }
      const feature = location?.mapData?.features[0]?.geometry;
// console.log(feature,'featurefeaturefeaturefeaturefeaturefeature');

      if (feature?.type === "Point" && feature?.coordinates) {
        // creating element for new marker
        const markerEle = createMarkerElement(locationMarkerImage);

        // creating new marker
        const marker = new mapboxgl.Marker(markerEle, { draggable: false })
          .setLngLat(feature.coordinates)
          .addTo(map);

        // registering click event on marker
        marker.getElement().addEventListener("click", () => {
          setCurrentClickedMarker({ id: location.id, marker });
          setCurrentClickedCommunityId(communityId);
        });

        // appending created marker to record list
        createdMarkersRef.current.set(location.id, marker);
      }
    });
  }, [data, map, isMapFullyLoaded]);

  // handle click on marker
  useEffect(() => {
    if (currentClickedMarker) {
      createdMarkersRef.current.forEach((marker, id) => {
        updateMarkerElement(
          marker,
          currentClickedMarker.id === id
            ? selectedLocationMarkerImage
            : locationMarkerImage
        );
      });
    }
  }, [currentClickedMarker]);

  return currentSelectedCommunityId;
};

export { useCreateMarkers };

function createMarkerElement(imageSrc: string) {
  const el = document.createElement("div");
  el.className = "marker";
  el.style.backgroundImage = `url(${imageSrc})`;
  el.style.width = "28px";
  el.style.height = "38px";
  el.style.backgroundSize = "100%";
  return el;
}

function updateMarkerElement(marker: mapboxgl.Marker, imageSrc: string) {
  const element = marker.getElement();
  element.style.backgroundImage = `url(${imageSrc})`;
}
