import ObservationsIcon from "../../imgs/ObservationsIcon";
import SpeciesIcon from "../../imgs/SpeciesIcon";
import ProgramsIcon from "../../imgs/ProgramsIcon";
import FormsIcon from "../../imgs/FormsIcon";
import UsersIcon from "../../imgs/UsersIcon";
import CommunitiesIcon from "../../imgs/CommunitiesIcon";

const WidgetIcon = (props: { widgetName: string }) => {
  switch (props.widgetName.toLowerCase()) {
    case "observations":
      return <ObservationsIcon />;
    case "species":
      return <SpeciesIcon />;
    case "forms":
      return <FormsIcon />;
    case "programs":
      return <ProgramsIcon />;
    case "users":
      return <UsersIcon />;
    case "communities":
      return <CommunitiesIcon />;
    default:
      return <div />;
  }
};

export default WidgetIcon;
