import apiClient from 'services/apiClient';

async function getUniversalImages(fileType: string, appName: string) {
  const params = {
    setting: fileType,
    appName,
  };
  const res = await apiClient.get(`/admin/universal-settings-images/`, {
    params,
  });
  return res.data;
}

export default getUniversalImages;
