import Modal from "@mui/material/Modal";
import { Box, RadioGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeMapSettngsProgramModalAction } from "store/modals/reducer.actions";
import { Form, Formik } from "formik";
import { XCloseIcon } from "assets/icons/HeroIcons";
import { RadioBtn } from "Components/RadioBtn";
import Button from "view/components/Button";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { GET_PROGRAM_PROFILE } from "store/programProfile/action-types";
import CustomCheckbox from "view/components/CheckBox";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

const MapSettingsModal = () => {
  const { mapSettingsProgramProfileModal } = useSelector(
    (state: RootState) => state.modals
  );
  const programProfile = useSelector(
    (state: RootState) => state?.programProfile
  );
  const { programs } = usePermissions();
  const dispatch = useDispatch();

  const initialValues = {
    mapType: programProfile?.mapType || "markers",
  };

  // handlers
  const handleClose = () => {
    dispatch(closeMapSettngsProgramModalAction());
  };
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!programs.canEditProgram) {
      return;
    }
    setSubmitting(true);
    try {
      const response = await apiLibrary.Programs.editProgram(
        mapSettingsProgramProfileModal.data,
        values
      );

      dispatch({
        type: GET_PROGRAM_PROFILE,
        payload: response.data,
      });

      Toasts.success("Program updated successfully");

      handleClose();
    } catch (error: any) {
      Toasts.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={mapSettingsProgramProfileModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight w-[655px] p-6 ">
        <Formik
          initialValues={initialValues}
          //   validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5 mb-4 ">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain font-Overpass">
                    Map Settings
                  </p>
                </div>
                <button onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>

              <div className="mb-5">
                <RadioGroup
                  name="mapType"
                  onChange={handleChange}
                  defaultValue={values?.mapType ?? "markers"}
                >
                  <RadioBtn
                    value="markers"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-start justify-start pl-2 ">
                          <div className="flex flex-col justify-start items-start gap-0.5 py-2 font-Overpass">
                            <h5 className=" text-base font-medium text-left text-secondaryMid dark:text-textMain">
                              Show observation locations
                            </h5>
                            <p className="text-sm dark:text-textMain">
                              On the map, the admin will see location markers
                              for the 30 most recent program observations with
                              available locations.
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                  <RadioBtn
                    value="cluster_without_numbers"
                    checked={
                      values?.mapType === "cluster_without_numbers" ||
                      values?.mapType === "cluster_with_numbers"
                    }
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-start justify-start pl-2 ">
                          <div className="flex flex-col justify-start items-start gap-0.5 py-2 font-Overpass">
                            <h5 className=" text-base font-medium text-left text-secondaryMid dark:text-textMain">
                              Show a community-clustered map{" "}
                            </h5>
                            <p className="text-sm dark:text-textMain">
                              On the map, the admin will see scaled circles with
                              or without numbers representing the observations
                              based on the community location of observers.
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </RadioGroup>
                {(values.mapType === "cluster_without_numbers" ||
                  values.mapType === "cluster_with_numbers") && (
                  <div className="ml-10">
                    <CustomCheckbox
                      name="mapType"
                      defaultChecked={
                        values?.mapType === "cluster_with_numbers"
                      }
                      onChange={(event) => {
                        const isChecked = event.target.checked;

                        if (isChecked) {
                          setFieldValue("mapType", "cluster_with_numbers");
                        } else {
                          setFieldValue("mapType", "cluster_without_numbers");
                        }
                      }}
                      // indeterminate={isFormSectionAllChecked? false: isFormSectionSomeChecked}
                      label={
                        <p className="text-secondaryMid font-Overpass dark:text-textMain ">
                          Display number of observations
                        </p>
                      }
                      labelPlacement="end"
                    />
                  </div>
                )}
              </div>

              <div className="flex justify-end gap-2 ">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting || !programs.canEditProgram}
                  text="Save"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default MapSettingsModal;
