import { Dispatch } from "redux";
import { ERROR_LOADING_IMPORTS, GET_ALL_ADMINS, GET_ALL_IMPORTS, RESET_DATA, START_LOADING_IMPORTS, STOP_LOADING_IMPORTS } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

const dummyData = {
  fields: [
    "file_name",
    "data_received_from",
    "status",
    "no_of_observations",
    "imported_by",
    "imported_date"
  ],
  imports: [
    {
      id: 1,
      fileName: "import1.csv",
      dataReceivedFrom: "source1",
      status: "success",
      noOfObservations: 100,
      importedBy: "user123",
      importedDate: "2022/01/01"
    },
    {
      id: 2,
      fileName: "import2.csv",
      dataReceivedFrom: "source2",
      status: "pending",
      noOfObservations: 150,
      importedBy: "user456",
      importedDate: "2022/01/02"
    },
    {
      id: 3,
      fileName: "import3.csv",
      dataReceivedFrom: "source3",
      status: "failed",
      noOfObservations: 50,
      importedBy: "user789",
      importedDate: "2022/01/03"
    }
  ],
  totalPages: 1,
  totalItems: 3
};


export const fetchImportsAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_IMPORTS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.importsFilter
      );
      const { data } = await apiLibrary.Imports.getAllImports(params);

      if (params.status === "permission") {
        dispatch({
          type: GET_ALL_ADMINS,
          payload: data,
        });
      }
      else {
        dispatch({
          type: GET_ALL_IMPORTS,
          payload: data,
        });
      }

    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      dispatch({ type: ERROR_LOADING_IMPORTS });
      console.log("An error occurred while fetching the imports:", error);
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_IMPORTS });
      // dispatch(setLoadingProgress(100));
    }
  };

export const resetImportsAction = () => {
  return {
    type: RESET_DATA,
  };
};
