import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import { Filters, Header, Tabs, Table, Pagination } from "Components/Manager";
import TextInputField from "view/components/InputField";
// Hooks
import { ManagerTable } from "hooks/useTable";
// Assets
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import {
  managerResetFilter,
  managerSearchAction,
  setManagersFiltersAction,
} from "store/filters/reducer.actions";
import { TableProps } from "Components/Manager/Table/types";
import { IfetchManager } from "store/managers/initialState";
import { fetchManagerAction } from "store/managers";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import {
  resetManagersDirectoryOrderAction,
  updateColumnsVisibilityOfManagersByStatus,
} from "store/directoriesOrders/reducer.actions";
import {
  getFiltersDataFromLocalStorage,
  saveFiltersDataToLocalStorage,
} from "utils/cacheOrRetriveFiltersDate";
import SearchBar from "./SearchBar";

export const Management = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programId } = useParams();

  useLayoutEffect(() => {
    if (programId) {
      const filtersData = getFiltersDataFromLocalStorage();

      dispatch(
        updateColumnsVisibilityOfManagersByStatus(filtersData?.filters.type)
      );
      dispatch(setManagersFiltersAction());
    }
  }, [programId]);

  // console.log("filtersData?.filters.status =>", filtersData?.filters.status)

  const { data } = useSelector<RootState, IfetchManager>(
    (state) => state?.allManagers
  );
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ManagerTable(data?.managers);

  useEffect(() => {
    if (programId) {
      dispatch(fetchManagerAction(parseInt(programId)));
      saveFiltersDataToLocalStorage(managerFilter);
    }
  }, [managerFilter]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        managerFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };
    if (showInput) {
      const inputField = document.querySelector(
        ".getInput"
      ) as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
    }
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [managerFilter.searchString, showInput]);
  useEffect(() => {
    return () => {
      dispatch(managerSearchAction(""));
      // dispatch(managerResetFilter());
      // dispatch(resetManagersDirectoryOrderAction());
    };
  }, []);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center w-full flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="relative flex items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-2">
              <Filters />
              <SearchBar />
            </div>
          </div>
        </div>

        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default Management;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, IfetchManager>(
    (state) => state?.allManagers
  );
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs === "Idle" && data?.managers?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
