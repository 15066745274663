import { camelToSnake } from "utils/caseConvertor";

interface WidgetTitles {
  [key: string]: string;
}

const originalWidgetTitles: WidgetTitles = {
  totalCounts:
    "This widget will display six relevant key performance indicators (KPIs); number of observation, number of forms, number of species observed, number of active users, number of participating communities, number of active programs.",
  qaProgress:
    "This pie chart shows the distribution of observations categorized as unassigned, in review, or completed, shown as percentages. The total number of observations considered is derived from those with statuses either in review or completed.",
  activeUsers:
    "This widget displays the top five most active users, determined by the number of observations logged by each user. The user with the most observations logged is ranked as the most active.",
  notifications:
    "This widget displays the user's four most recent notifications. Each card includes the notification avatar photo, date received, and the notification text.",
  qaNotifications:
    "This widget displays the user's four most recent quality assurance notifications (observer has made edits, new observations for review, edit requests). Each card includes the notification avatar photo, reception date received, and the notification text.",
  reportNotifications:
    "This widget displays the user's four most recent report notifications (processing updates, setting updates, download and view updates, and new reports shared with you). Each card includes the notification avatar photo, date received, and the notification text.",
  observationsByLocation:
    "This widget visualizes observations by location on a map, with each observation pinpointed based on its GPS coordinates. Proximity and map zoom level will create clusters of observations. Each cluster is represented by a circle on the map, indicating the number of observations within it.",
  observationsByRegion:
    "This widget presents the six regions with the highest observation frequencies. The map outlines these regions, with the circles inside them representing observation counts. The graph also displays the observations per region, with bars indicating their frequencies, proportional to the total number of observations.",
  observations:
    "This widget illustrates the number of observations made per predefined period of time, whether per day, week, month, or year. Each bar in the graph shows the number of observations made during the defined time period.",
  cumulativeObservations:
    "This widget illustrates the accumulation of observations over time, whether per day, week, month, or year. Each bar in the graph features two stacked parts: the bottom portion represents the cumulative total of observations up to the previous time period, while the top portion shows the number of observations made during the latest time period, providing a visual representation of the total observations up to that point in time.",
  mostObservedObjects:
    "This horizontal bar chart illustrates the frequency of observations recorded for specific objects, focusing on the top five most frequently observed objects. The overall width of the chart reflects the total number of observations, while each bar represents the percentage of observations attributed to a particular object.",
  mostObservedSpecies:
    "This horizontal bar chart illustrates the frequency of observations recorded for specific species, focusing on the top five most frequently observed species. The overall width of the chart reflects the total number of observations, while each bar represents the percentage of observations attributed to a particular species.",
  form: "This segmented Horizontal Bar Chart illustrates the utilization of the top five forms for logging observations, with accompanying percentages indicating the proportion of observations logged using each form. Additionally, the chart also highlights the percentage of observations logged using forms outside of these top five.",
  program:
    "This segmented Horizontal Bar Chart displays the distribution of observations across the five programs with the highest observation frequencies, along with the corresponding percentages. Additionally, it highlights the percentage of observations logged in programs outside of these top five.",
  community:
    "This segmented Horizontal Bar Chart displays the distribution of observations across the five communities with the highest observation frequencies, along with the corresponding percentages. Additionally, it highlights the percentage of observations logged by members of communities outside of these top five.",
};

// Convert keys to snake_case using Object.fromEntries and map
const widgetTitles: WidgetTitles = Object.fromEntries(
  Object.entries(originalWidgetTitles).map(([key, value]) => [
    camelToSnake(key),
    value,
  ])
);

export { widgetTitles };
