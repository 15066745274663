import { apiClient } from "../config";

async function getChatDetail(id: number, queryParams: any) {
  const res = await apiClient.get(`/admin/conversations/${id}`, {
    params: queryParams,
  })
  return res.data;
}

export default getChatDetail;
