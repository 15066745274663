import {
  ERROR_LOADING_BEHAVIORS,
  GET_ALL_BEHAVIORS,
  RESET_DATA,
  START_LOADING_BEHAVIORS,
  STOP_LOADING_BEHAVIORS,
} from "./action-types";
import initialState from "./initialState";

export const behaviorsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_BEHAVIORS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_BEHAVIORS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_BEHAVIORS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_BEHAVIORS:
      const behaviors = action.payload.behaviors.map((item: any) => {
        const { behaviorName, id, types, dateAdded, status } = item;
        const data = {
          id: id,
          behaviorName: {
            behaviorName: behaviorName,
            id: id,
            status: status,
          },
          dateAdded: dateAdded,
          types: types,
        };

        return data;
      });

      return {
        ...state,
        data: {
          behaviors: behaviors,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
