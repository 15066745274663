import React from "react";
// Components
import { Date } from "./Components";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { reportsAnalyticsResetFiltersAction, runReportsResetFiltersAction } from "store/filters/reducer.actions";
import {
  filtersInitialState,
  reportsAnalyticsDirectoryFilterState,
  reportsRunReportsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting reportsFilter state from Redux store
  const { reportsRunReportsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const dispatch = useDispatch();

  // Function to check if any filter is applied
  const isAnyFilterApplied = (
    reportsRunReportsFilter: reportsRunReportsDirectoryFilterState
  ): boolean => {
    const { date } = reportsRunReportsFilter;
    const applied = date?.applied;
    return applied;
  };

  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Date
            name="date"
            from={reportsRunReportsFilter?.date?.from}
            to={reportsRunReportsFilter?.date?.to}
            isApplied={reportsRunReportsFilter?.date?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;
