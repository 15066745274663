import { DefaultMap } from "./components";

const MapBox = () => {
  return (
    <section className="h-[94vh] w-[100%]  relative geography-map ">
      <DefaultMap />
    </section>
  );
};

export default MapBox;
