import React from "react";
// Components
import {
  Date,
  SelectedCreatedBy,
  SelectedArchivedBy,
  SelectedPrograms,
  SelectedForms,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  protocolsResetFilterExceptFormsAction,
  protocolsResetFilterExceptProgramsAction,
  protocolsResetFiltersAction,
} from "store/filters/reducer.actions";
import { protocolsDirectoryFilterState } from "store/filters/initialState";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting protocolsFilter state from Redux store
  const { protocolsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting programId from URL parameters
  const { programId, formId } = useParams<{
    programId: string;
    formId: string;
  }>();

  const clearAllFilters = () => {
    // if (programId) {
    //   dispatch(protocolsResetFilterExceptProgramsAction());
    // } else if (formId) {
    //   dispatch(protocolsResetFilterExceptFormsAction());
    // } else {
    //   dispatch(protocolsResetFiltersAction());
    // }

    dispatch(protocolsResetFiltersAction());

  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyProtocolsFilterApplied(protocolsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start "
      >
        <div className="flex flex-wrap gap-2">
          <SelectedPrograms />
          <SelectedForms />
          <SelectedCreatedBy />
          {protocolsFilter.status === "archived" && <SelectedArchivedBy />}

          <Date
            name="created"
            from={protocolsFilter.created.from}
            to={protocolsFilter.created.to}
            isApplied={protocolsFilter.created?.applied}
          />
          {protocolsFilter.status === "archived" && (
            <Date
              name="archived"
              from={protocolsFilter.archived.from}
              to={protocolsFilter.archived.to}
              isApplied={protocolsFilter.archived?.applied}
            />
          )}
          {protocolsFilter.status !== "archived" && (
            <Date
              name="updated"
              from={protocolsFilter.updated.from}
              to={protocolsFilter.updated.to}
              isApplied={protocolsFilter.updated?.applied}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

// Function to check if any filter is applied
export function isAnyProtocolsFilterApplied(
  protocolsFilter: protocolsDirectoryFilterState
) {
  const {
    programIds,
    formIds,
    createdByIds,
    updatedByIds,
    deletedByIds,
    archivedByIds,
    created,
    updated,
    deleted,
    archived,
  } = protocolsFilter;
  const applied =
    created?.applied ||
    archived?.applied ||
    updated?.applied ||
    archivedByIds?.applied ||
    createdByIds?.applied ||
    formIds?.applied ||
    deleted?.applied ||
    updatedByIds?.applied ||
    deletedByIds?.applied ||
    programIds?.applied;
  return applied;
}
