import { apiClient } from "../config";

async function fetchLayers(formId: any,type?:string) {
  const res = await apiClient.get(`/admin/dynamic-forms/${formId}/geographies`,{
    params:{type}
  });

  return res.data;
}

export default fetchLayers;
