import React, { useEffect } from "react";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import { sentenceCase } from "change-case";
import { useFormikContext } from "formik";

interface Step {
  label: string;
  onClick: () => void;
  isActive: boolean;
  icon?: React.ComponentType<{ height: number; width: number }>; // Icon component with height and width props
}

interface StepBreadcrumbProps {
  steps: Step[];
  initialValues: any;
}

export const BreadCrumbs: React.FC<StepBreadcrumbProps> = ({
  steps,
  initialValues,
}) => {
  const formik = useFormikContext();
  useEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);
  // Form Validation on Breadcrumb Click
  const handleStepClick = (stepIndex: number) => {
    if (formik) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          steps[stepIndex].onClick(); // Move to the clicked step
        } else {
          formik.setErrors(errors);
        }
      });
    } else {
      console.log("Formik context not found");
    }
  };

  return (
    <div className="flex items-center mt-3 mb-4">
      {steps?.map((step, index) => (
        <React.Fragment key={index}>
          {index > 0 && <CheveronRightIcon height={22} width={22} />}
          <button
            onClick={() => handleStepClick(index)}
            className="flex items-center space-x-2 text-textMid hover:text-primaryDark"
          >
            <span
              className={`text-xs mr-1 rounded-lg  px-2 py-1 ${
                step.isActive
                  ? "text-textWhite font-bold dark:text-textMain bg-primaryMid"
                  : "hover:text-primaryDark dark:text-caption bg-primary_8"
              }`}
            >
              {index + 1}
            </span>
            {step.icon && <step.icon height={22} width={22} />}{" "}
            {/* If you have icons for each step */}
            <p
              className={
                step.isActive
                  ? "text-textMid dark:text-textMain "
                  : "dark:text-caption "
              }
            >
              {sentenceCase(step.label)}
            </p>
          </button>
        </React.Fragment>
      ))}
    </div>
  );
};
