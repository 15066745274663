import getAllNotifications from "./getAllNotifications";
import readAllNotifications from "./readAllNotification";
import readNotification from "./readNotification";

const NotificationCenter = {
  getAllNotifications,
  readAllNotifications,
  readNotification
};

export default NotificationCenter;
