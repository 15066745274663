import { ERROR_LOADING_IMPORT_STATUS_DETAIL, GET_IMPORT_STATUS_DETAIL, START_LOADING_IMPORT_STATUS_DETAIL, STOP_LOADING_IMPORT_STATUS_DETAIL } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { openImportStatusDetailModalAction } from "store/modals";
import { Toasts } from "view/components/Toasts";

export const fetchImportStatusDetailAction =
  (importStatus: any) => async (dispatch: Dispatch , getState: any) => {
    dispatch({ type: START_LOADING_IMPORT_STATUS_DETAIL });
    try {
      const { data } = await apiLibrary.Imports.getImportStatusDetail(importStatus.id);
      // localStorage.setItem("importId", data.program.id);
      dispatch({
        type: GET_IMPORT_STATUS_DETAIL,
        payload: data,
      });
      if (importStatus.path === "directory") {
        dispatch(openImportStatusDetailModalAction(importStatus.id));
      }
      dispatch({ type: ERROR_LOADING_IMPORT_STATUS_DETAIL });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch({ type: STOP_LOADING_IMPORT_STATUS_DETAIL });
    }
  };
