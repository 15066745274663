import { ERROR_LOADING_NOTIFICATIONS, GET_ALL_NOTIFICATIONS, RESET_DATA, START_LOADING_NOTIFICATIONS, STOP_LOADING_NOTIFICATIONS } from "./action-types";
import initialRemindersData, { IReminders } from "./initialState";
export const notificationsReducer = (state = initialRemindersData, action: any) => {
  switch (action.type) {
    case START_LOADING_NOTIFICATIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

      case ERROR_LOADING_NOTIFICATIONS:
        return {
          ...state,
          stateIs: "Error",
        };

    case STOP_LOADING_NOTIFICATIONS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_NOTIFICATIONS:
      const reminder = action.payload?.notifications?.map(
        (item: IReminders) => {
          const {
            id,
            notificationName,
            targetBy,
            totalUsersReceived,
            notificationCenter,
            toast,
            push,
            email,
            createdBy,
            scheduledFor,
            status
          } = item;
          const data = {
            id,
            notificationName: {
              notificationName,
              id,
            },
            targetBy,
            totalUsersReceived,
            notificationCenter,
            toast,
            push,
            email,
            createdBy,
            scheduledFor,
            status
          };
          return data;
        }
      );

      return {
        ...state,
        data: {
          reminders: reminder,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialRemindersData;
    }
    default:
      return state;
  }
};
