
import { apiClient } from "../config";

async function editLayer(communityId: string,geographyId:string, data: any) {
  const url = `/admin/communities/${communityId}/geography/${geographyId}
  `;
  const res = await apiClient.put(url, data);
  return res.data;
}

export default editLayer;
