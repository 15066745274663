import React, { useState } from "react";
/************ Assets ************/
import { CheveronDownIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Checkbox, ListItemText, Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import { sentenceCase } from "change-case";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 200,
        },
    },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
    const { open, onClick } = props;

    return (
        <CheveronDownIcon
            height={24}
            width={24}
            onClick={onClick}
            className={` ${open ? "transform rotate-180" : ""}`}
            preserveAspectRatio="xMidYMid meet"
            style={{ cursor: "pointer" }}
        />
    );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 8px;
  },
`;

interface RolesDropdownProps {
    value?: any;
    data?: any[];
    handleChange?: any;
}

export const RolesDropdown: React.FC<RolesDropdownProps> = ({
    value,
    data,
    handleChange,
}) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    // Function to filter and return only names
    const filterNamesByValue = (roles: any[], value: any[]) => {
        const filteredRoles = roles?.filter((item: any) => value.includes(item.value));
        return filteredRoles.map((item: any) => item.name).join(", ");
    };

    return (
        <FormControl className="w-full">
            <MaterialUiSelect
                className={`w-full !font-Overpass  text-textMid text-xs dark:bg-[transparent]`}
                displayEmpty
                value={value}
                onChange={handleChange}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                renderValue={() => {
                    return <p className={`text-sm text-secondaryMid ${value.length < 1 && "opacity-60"}`}>{value && data && filterNamesByValue(data, value) || "Select Role"}</p>;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={CustomIcon}
                open={open}
                onClick={handleToggle}
                input={<CustomOutlinedInput />}
                classes={{
                    root: 'dark:bg-secondaryLight',
                }}
                sx={{
                    "& .MuiSelect-select": {
                        whiteSpace: "normal !important"
                    }
                }}
                multiple
            >
                <MenuItem
                    disabled
                    className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-inputText "
                >
                    Select Role
                </MenuItem>

                {data && data?.length > 0 ? (
                    data?.map((option: any) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:!text-inputText w-full flex gap-2 items-center"
                            disabled={option.value === "program_admin" }
                        >
                            <Checkbox checked={value.indexOf(option.value) > -1} style={{ padding: 0 }} />
                            {option.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem
                        disabled
                        className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-inputText "
                    >
                        No data
                    </MenuItem>
                )}
            </MaterialUiSelect>
        </FormControl>
    );
};

export default RolesDropdown;
