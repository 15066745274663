import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import apiLibrary from "services/api";
import "./theme.css"
interface SurveysFormProps {
  handleSubmit: any;
  surveyDetails: any;
  setSurveyResult: any;
  setSubmitError: any;
}

export const SurveyForm: React.FC<SurveysFormProps> = ({
  handleSubmit,
  surveyDetails,
}) => {
  if (!surveyDetails) {
    return <p>Survey details not available.</p>;
  }

  const survey = new Model(surveyDetails.surveyFields);

  survey.onUploadFiles.add(async function (_: any, options: any) {
    try {
      const uploadPromises = options.files.map(async (file: any) => {
        const { data } = await apiLibrary.file.surveyFileUpload(
          file,
          false,
          "public"
        );
        return {
          file: file,
          content: data.file.original,
          questionType: options.questionType,
        };
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      options.callback(uploadedFiles);
    } catch (error: any) {
      const errorMessage = error.response.data.message ?? error.message;
      options.callback([], [errorMessage]);
    }
  });
  return (
    <Survey
      model={survey}
      showCompletedPage={false}
      onComplete={(survey: any) => handleSubmit(survey.data)}
    />
  );
};
