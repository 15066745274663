interface phoneVerificationModalState {
  isOpen: boolean;
  value: string;
}
interface emailVerificationModalState {
  isOpen: boolean;
  value: string;
}

interface ConfirmationModalState {
  isOpen: boolean;
  message: string;
}

// Initial state for the verification modal
interface DeleteAccountModalState {
  isOpen: boolean;
  data: any;
}

interface DeleteAccountRequestModalState {
  isOpen: boolean;
}

interface ResetPasswordModalState {
  isOpen: boolean;
  data: any;
}

interface OrganizationProfileDetailModalState {
  isOpen: boolean;
  data: any;
}

const OrganizationProfileDetailModalInitialState: OrganizationProfileDetailModalState =
  {
    isOpen: false,
    data: "",
  };

const phoneVerificationModalInitialState: phoneVerificationModalState = {
  isOpen: false,
  value: "",
};

const emailVerificationModalInitialState: emailVerificationModalState = {
  isOpen: false,
  value: "",
};

const confirmationModalInitialState: ConfirmationModalState = {
  isOpen: false,
  message: "",
};

const deleteAccountModalInitialState: DeleteAccountModalState = {
  isOpen: false,
  data: null,
};

const deleteAccountRequestModalInitialState: DeleteAccountRequestModalState = {
  isOpen: false,
};

const resetPasswordModalInitialState: ResetPasswordModalState = {
  isOpen: false,
  data: null,
};

interface ReinviteUserModalState {
  isOpen: boolean;
  data: any;
}

const reinviteUserModalInitialState: ReinviteUserModalState = {
  isOpen: false,
  data: null,
};

interface ConfirmationAssignAsAdminModalState {
  isOpen: boolean;
  data: any;
}

const confirmationAssignAsAdminModalInitialState: ConfirmationAssignAsAdminModalState =
  {
    isOpen: false,
    data: null,
  };

interface RemovePersonFromCommunityModalState {
  isOpen: boolean;
  data: any;
}

const removePersonFromCommunityModalInitialState: RemovePersonFromCommunityModalState =
  {
    isOpen: false,
    data: null,
  };
interface CreatePasswordModalState {
  isOpen: boolean;
  data: any;
}

const createPasswordModalInitialState: CreatePasswordModalState = {
  isOpen: false,
  data: null,
};

interface DeactivateUserModalState {
  isOpen: boolean;
  data: any;
}

const deactivateUserModalInitialState: DeactivateUserModalState = {
  isOpen: false,
  data: null,
};
interface restorePersonModalState {
  isOpen: boolean;
  data: any;
}

const restorePersonModalInitialState: restorePersonModalState = {
  isOpen: false,
  data: null,
};

interface CreateCommunityModalState {
  isOpen: boolean;
  data: any;
}

const CreateCommunityModalInitialState: CreateCommunityModalState = {
  isOpen: false,
  data: null,
};

interface EditCommunityModalState {
  isOpen: boolean;
  data: any;
}

const EditCommunityModalInitialState: EditCommunityModalState = {
  isOpen: false,
  data: null,
};

interface communityAccessModalState {
  isOpen: boolean;
  data: any;
  access: any;
}

const communityAccessModalInitialState: communityAccessModalState = {
  isOpen: false,
  data: null,
  access: "",
};

interface archiveOrRestoreCommunityModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreCommunityModalInitialState: archiveOrRestoreCommunityModalState =
  {
    isOpen: false,
    data: null,
  };

interface archiveOrPublishOrganizationModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrPublishOrganizationModalInitialState: archiveOrPublishOrganizationModalState =
  {
    isOpen: false,
    data: null,
  };

interface deleteACommunityModalState {
  isOpen: boolean;
  data: any;
}

const deleteACommunityModalInitialState: deleteACommunityModalState = {
  isOpen: false,
  data: null,
};
interface assignAnAdminToCommunityModalState {
  isOpen: boolean;
  data: any;
}

const assignAnAdminToCommunityModalInitialState: assignAnAdminToCommunityModalState =
  {
    isOpen: false,
    data: null,
  };

interface addMemberstoCommunityModalState {
  isOpen: boolean;
  data: any;
}

const addMemberstoCommunityModalInitialState: addMemberstoCommunityModalState =
  {
    isOpen: false,
    data: null,
  };

interface createOrganizationModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const createOrganizationModalInitialState: createOrganizationModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface addExistingOrganizationsModalState {
  isOpen: boolean;
  data: any;
}

const addExistingOrganizationsModalInitialState: addExistingOrganizationsModalState =
  {
    isOpen: false,
    data: null,
  };

interface addExistingCommunityModalState {
  isOpen: boolean;
  data: any;
}

const addExistingCommunityModalInitialState: addExistingCommunityModalState = {
  isOpen: false,
  data: null,
};

interface addEditDuplicateModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
  duplicateMode: boolean;
}

const addEditDuplicateProtocolModalInitialState: addEditDuplicateModalState = {
  isOpen: false,
  data: null,
  editMode: false,
  duplicateMode: false,
};

interface manageConnectionProtocolModalState {
  isOpen: boolean;
  data: any;
}

const manageConnectionProtocolModalInitialState: manageConnectionProtocolModalState =
  {
    isOpen: false,
    data: null,
  };

interface archiveOrRestoreFormState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreFormInitialState: archiveOrRestoreFormState = {
  isOpen: false,
  data: null,
};
interface deleteFormModalState {
  isOpen: boolean;
  data: any;
}

const deleteFormModalInitialState: deleteFormModalState = {
  isOpen: false,
  data: null,
};

interface formDetailsModalState {
  isOpen: boolean;
  data: any;
}

const formDetailsModalInitialState: formDetailsModalState = {
  isOpen: false,
  data: null,
};

interface ImportSummaryModalState {
  isOpen: boolean;
  data: any;
}

const importSummaryModalInitialState: ImportSummaryModalState = {
  isOpen: false,
  data: null,
};

interface ImportStatusDetailModalState {
  isOpen: boolean;
  data: any;
}

const importStatusDetailModalInitialState: ImportStatusDetailModalState = {
  isOpen: false,
  data: null,
};

interface ImportInfoModalState {
  isOpen: boolean;
  data: any;
}

const importInfoModalInitialState: ImportInfoModalState = {
  isOpen: false,
  data: null,
};

interface addPersonModalState {
  isOpen: boolean;
}

const addPersonModalInitialState: addPersonModalState = {
  isOpen: false,
};

interface AddProgramModalState {
  isOpen: boolean;
  data: any;
}

const AddProgramModalInitialState: AddProgramModalState = {
  isOpen: false,
  data: null,
};
interface AddFileModalState {
  isOpen: boolean;
  data: any;
}

const AddFileModalInitialState: AddFileModalState = {
  isOpen: false,
  data: null,
};

interface archiveOrRestoreProgramModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreProgramModalInitialState: archiveOrRestoreProgramModalState =
  {
    isOpen: false,
    data: null,
  };

interface completeProgramModalState {
  isOpen: boolean;
  data: any;
}

const completeProgramModalInitialState: completeProgramModalState = {
  isOpen: false,
  data: null,
};
interface addExistingCommunitiesOrOrganizationsToProgramModalState {
  isOpen: boolean;
  data: any;
}

const addExistingCommunitiesOrOrganizationsToProgramModalInitialState: addExistingCommunitiesOrOrganizationsToProgramModalState =
  {
    isOpen: false,
    data: null,
  };
interface EditProgramModalState {
  isOpen: boolean;
  data: any;
}

const EditProgramModalInitialState: EditProgramModalState = {
  isOpen: false,
  data: null,
};
interface archiveOrRestoreProgramModalState {
  isOpen: boolean;
  data: any;
}

interface assignAnAdminToProgramModalState {
  isOpen: boolean;
  data: any;
}
const SignOutModalInitialState: signOutModalState = {
  isOpen: false,
  data: null,
};

interface signOutModalState {
  isOpen: boolean;
  data: any;
}

const assignAnAdminToProgramModalInitialState: assignAnAdminToProgramModalState =
  {
    isOpen: false,
    data: null,
  };
interface RemoveFromProgramModalState {
  isOpen: boolean;
  data: any;
}

const removeFromProgramModalInitialState: RemoveFromProgramModalState = {
  isOpen: false,
  data: null,
};
interface RemoveCommunityFromOrganizationModalState {
  isOpen: boolean;
  data: any;
}

const removeCommunityFromOrganizationModalInitialState: RemoveCommunityFromOrganizationModalState =
  {
    isOpen: false,
    data: null,
  };

interface manageProgramMembersModalState {
  isOpen: boolean;
  data: any;
}

const manageProgramMembersModalInitialState: manageProgramMembersModalState = {
  isOpen: false,
  data: null,
};

interface mapSettingsProgramProfileModalState {
  isOpen: boolean;
  data: any;
}

const mapSettingsProgramProfileModal: mapSettingsProgramProfileModalState = {
  isOpen: false,
  data: null,
};

interface addEditTemplateFormModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
  templateMode: boolean;
}

const addEditTemplateFormModalInitialState: addEditTemplateFormModalState = {
  isOpen: false,
  data: null,
  editMode: false,
  templateMode: false,
};

interface videoLightBoxModalState {
  isOpen: boolean;
  data: any;
}

const videoLightBoxModalInitialState: videoLightBoxModalState = {
  isOpen: false,
  data: null,
};

interface imageLightBoxModalState {
  isOpen: boolean;
  data: any;
}

const imageLightBoxModalInitialState: imageLightBoxModalState = {
  isOpen: false,
  data: null,
};

interface imageVideoLightBoxModalState {
  isOpen: boolean;
  data: any;
}

const imageVideoLightBoxModalInitialState: imageVideoLightBoxModalState = {
  isOpen: false,
  data: null,
};

interface deleteFileModalState {
  isOpen: boolean;
  data: any;
}

const deleteFileModalInitialState: deleteFileModalState = {
  isOpen: false,
  data: null,
};

interface archiveOrRestoreProtocolState {
  isOpen: boolean;
  data: any;
}
const archiveOrRestoreProtocolInitialState: archiveOrRestoreProtocolState = {
  isOpen: false,
  data: null,
};

interface createOrEditDataAccessPolicyState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const createOrEditDataAccessPolicyInitialState: createOrEditDataAccessPolicyState =
  {
    isOpen: false,
    data: null,
    editMode: false,
  };

interface archiveOrRestoreObservationModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreObservationModalInitialState: archiveOrRestoreObservationModalState =
  {
    isOpen: false,
    data: null,
  };
interface RemoveManagerModalState {
  isOpen: boolean;
  data: any;
}

const removeManagerModalInitialState: RemoveManagerModalState = {
  isOpen: false,
  data: null,
};
interface DeleteManagerModalState {
  isOpen: boolean;
  data: any;
}

const deleteManagerModalInitialState: DeleteManagerModalState = {
  isOpen: false,
  data: null,
};
interface inviteDataEndViewerModalState {
  isOpen: boolean;
  data: any;
}
const inviteDataEndViewerModalInitialState: inviteDataEndViewerModalState = {
  isOpen: false,
  data: null,
};
interface RemoveNotificationRecipientsModalState {
  isOpen: boolean;
  data: any;
}

const removeNotificationRecipientsModalInitialState: RemoveNotificationRecipientsModalState =
  {
    isOpen: false,
    data: null,
  };

interface NotificationsSummaryModalState {
  isOpen: boolean;
  data: any;
}

const opennotifiationsSummaryModalInitialState: NotificationsSummaryModalState =
  {
    isOpen: false,
    data: null,
  };

interface ReportsSummaryModalState {
  isOpen: boolean;
  data: any;
}

interface ReportsMapModalState {
  isOpen: boolean;
  data: any;
}

const openReportsMapModalInitialState: ReportsMapModalState = {
  isOpen: false,
  data: null,
};

interface ReportsSummaryModalState {
  isOpen: boolean;
  data: any;
}

const openReportsSummaryModalInitialState: ReportsSummaryModalState = {
  isOpen: false,
  data: null,
};

interface ReportsMapModalState {
  isOpen: boolean;
  data: any;
}

interface addManagerModalState {
  isOpen: boolean;
  data?: any;
}

const addManagerModalInitialState: addManagerModalState = {
  isOpen: false,
  data: null,
};

interface inviteExternalReviewerQualityAssuranceObservationModalState {
  isOpen: boolean;
  data: any;
}

const inviteExternalReviewerQualityAssuranceObservationModalInitialState: inviteExternalReviewerQualityAssuranceObservationModalState =
  {
    isOpen: false,
    data: null,
  };

interface archiveOrRestoreQualityAssuranceObservationModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreQualityAssuranceObservationModalInitialState: archiveOrRestoreQualityAssuranceObservationModalState =
  {
    isOpen: false,
    data: null,
  };

interface reiewerDetailModalState {
  isOpen: boolean;
  data?: any;
}

const reiewerDetailModalInitialState: reiewerDetailModalState = {
  isOpen: false,
  data: null,
};
interface messageActionsModalState {
  isArchive: boolean;
  isDelete: boolean;
  data: any;
}

const messageActionsModalInitialState: messageActionsModalState = {
  isArchive: false,
  isDelete: false,
  data: null,
};

interface archiveOrRestoreCategoryModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreCategoryModalInitialState: archiveOrRestoreCategoryModalState =
  {
    isOpen: false,
    data: null,
  };

interface addOrEditCategoryModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditCategoryModalInitialState: addOrEditCategoryModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreBehaviorsModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreBehaviorsModalInitialState: archiveOrRestoreBehaviorsModalState =
  {
    isOpen: false,
    data: null,
  };

interface addOrEditBehaviorsModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditBehaviorsModalInitialState: addOrEditBehaviorsModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreConditionsModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreConditionsModalInitialState: archiveOrRestoreConditionsModalState =
  {
    isOpen: false,
    data: null,
  };

interface addOrEditConditionsModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditConditionsModalInitialState: addOrEditConditionsModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreTypeModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreTypeModalInitialState: archiveOrRestoreTypeModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditTypeModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditTypeModalInitialState: addOrEditTypeModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};


interface archiveOrRestoreMarkerGroupModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreMarkerGroupModalInitialState: archiveOrRestoreMarkerGroupModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditMarkerGroupModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditMarkerGroupModalInitialState: addOrEditMarkerGroupModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreMarkerStylesModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreMarkerStylesModalInitialState: archiveOrRestoreMarkerStylesModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditMarkerStylesModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditMarkerStylesModalInitialState: addOrEditMarkerStylesModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface addOrEditSpecieModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditSpecieModalInitialState: addOrEditSpecieModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreSpecieModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreSpecieModalInitialState: archiveOrRestoreSpecieModalState =
  {
    isOpen: false,
    data: null,
  };

interface reUseSpeciesListModalState {
  isOpen: boolean;
  data: any;
}

const reUseSpeciesListModalInitialState: reUseSpeciesListModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditObjectModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditObjectModalInitialState: addOrEditObjectModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestoreObjectModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreObjectModalInitialState: archiveOrRestoreObjectModalState =
  {
    isOpen: false,
    data: null,
  };

interface reUseObjectsListModalState {
  isOpen: boolean;
  data: any;
}

const reUseObjectsListModalInitialState: reUseObjectsListModalState = {
  isOpen: false,
  data: null,
};

//

interface deleteTagModalState {
  isOpen: boolean;
  data: any;
}

const deleteTagModalInitialState: deleteTagModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditTagModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditTagModalInitialState: addOrEditTagModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface editCommunityTagsModalState {
  isOpen: boolean;
  data: any;
}

const editCommunityTagsModalInitialState: editCommunityTagsModalState = {
  isOpen: false,
  data: null,
};

interface editOrganizationTagsModalState {
  isOpen: boolean;
  data: any;
}

const editOrganizationTagsModalInitialState: editOrganizationTagsModalState = {
  isOpen: false,
  data: null,
};

interface editProgramTagsModalState {
  isOpen: boolean;
  data: any;
}

const editProgramTagsModalInitialState: editProgramTagsModalState = {
  isOpen: false,
  data: null,
};

interface addExistingSpeciesOrObjectsToCommunityModalState {
  isOpen: boolean;
  data: any;
}

const addExistingSpeciesOrObjectsToCommunityModalInitialState: addExistingSpeciesOrObjectsToCommunityModalState =
  {
    isOpen: false,
    data: null,
  };

interface pauseResumeDeleteNotificationModalState {
  isOpen: boolean;
  data: any;
}

const pauseResumeDeleteNotificationModalInitialState: pauseResumeDeleteNotificationModalState =
  {
    isOpen: false,
    data: null,
  };

interface archiveOrRestoreGeographyLayerModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestoreGeographyLayerModalInitialState: archiveOrRestoreGeographyLayerModalState =
  {
    isOpen: false,
    data: null,
  };

interface archiveRestoreDeleteReportModalState {
  isOpen: boolean;
  data: any;
}

const archiveRestoreDeleteReportModalInitialState: archiveRestoreDeleteReportModalState =
  {
    isOpen: false,
    data: null,
  };

interface processedReportModalState {
  isOpen: boolean;
  data: any;
}

const processedReportModalInitialState: processedReportModalState = {
  isOpen: false,
  data: null,
};

interface addEditDuplicateRoleModalState {
  isOpen: boolean;
  data: any;
}

const addEditDuplicateRoleModalInitialState: addEditDuplicateRoleModalState = {
  isOpen: false,
  data: null,
};

interface editPermissionModalState {
  isOpen: boolean;
  data: any;
}

const editPermissionModalInitialState: editPermissionModalState = {
  isOpen: false,
  data: null,
};

interface deleteRoleModalState {
  isOpen: boolean;
  data: any;
}

const deleteRoleModalInitialState: deleteRoleModalState = {
  isOpen: false,
  data: null,
};

interface downloadOptionsImportModalState {
  isOpen: boolean;
  data: any;
}

const downloadOptionsImportModalInitialState: downloadOptionsImportModalState =
  {
    isOpen: false,
    data: null,
  };

interface dashboardAlertModalState {
  isOpen: boolean;
  isWelcomeAlertOpen: boolean | undefined;
  data: any;
}

const dashboardAlertModalInitialState: dashboardAlertModalState = {
  isOpen: false,
  isWelcomeAlertOpen: undefined,
  data: null,
};
interface removeProgramModalState {
  isOpen: boolean;
  data: any;
}

const removeProgramModalInitialState: removeProgramModalState = {
  isOpen: false,
  data: null,
};

interface addOrEditSurveyFormModalState {
  isOpen: boolean;
  data: any;
  editMode: boolean;
}

const addOrEditSurveyFormModalInitialState: addOrEditSurveyFormModalState = {
  isOpen: false,
  data: null,
  editMode: false,
};

interface archiveOrRestorePollsAndSurveyModalState {
  isOpen: boolean;
  data: any;
}

const archiveOrRestorePollsAndSurveyModalInitialState: archiveOrRestorePollsAndSurveyModalState =
  {
    isOpen: false,
    data: null,
  };
interface deletePollsAndSurveyModalState {
  isOpen: boolean;
  data: any;
}

const deletePollsAndSurveyModalInitialState: deletePollsAndSurveyModalState = {
  isOpen: false,
  data: null,
};
interface successfulMessageModalState {
  isOpen: boolean;
  data: any;
}

const successfulMessageInitialState: successfulMessageModalState = {
  isOpen: false,
  data: null,
};

interface configurationDashboardModalState {
  isOpen: boolean;
  data: any;
}

const configurationDashboardModalInitialState: configurationDashboardModalState =
  {
    isOpen: false,
    data: null,
  };

interface downladPollsAndSurveysResultByVersionModalState {
  isOpen: boolean;
  data: any;
}

const downladPollsAndSurveysResultByVersionModalInitialState: downladPollsAndSurveysResultByVersionModalState =
  {
    isOpen: false,
    data: null,
  };

interface successfullInviteMessageModalState {
  isOpen: boolean;
  data?: any;
}

const successfullInviteMessageModalInitialState: successfullInviteMessageModalState =
  {
    isOpen: false,
    data: null,
  };
interface registeredExternalReviewerModalState {
  isOpen: boolean;
  data?: any;
}

const registeredExternalReviewerModalInitialState: registeredExternalReviewerModalState =
  {
    isOpen: false,
    data: null,
  };
interface contactInfoModalState {
  isOpen: boolean;
  data: any;
  status?: any;
}

const contactInfoModalInitialState: contactInfoModalState = {
  isOpen: false,
  data: null,
  status: null,
};

interface addEditContactInfoModalState {
  isOpen: boolean;
  data: any;
}

const addEditContactInfoModalInitialState: addEditContactInfoModalState = {
  isOpen: false,
  data: null,
};
interface NotificationsCenterModalState {
  isOpen: boolean;
  data: any;
}

const NotificationsCenterModalStateModalInitialState: NotificationsCenterModalState =
  {
    isOpen: false,
    data: null,
  };

interface pauseResumeProgramModalState {
  isOpen: boolean;
  data: any;
}

const pauseResumeProgramModalInitialState: pauseResumeProgramModalState = {
  isOpen: false,
  data: null,
};

interface activeSeasonsHistoryModalState {
  isOpen: boolean;
  data: any;
}

const activeSeasonsHistoryModalInitialState: activeSeasonsHistoryModalState = {
  isOpen: false,
  data: null,
};

interface reportVersionDataModalState {
  isOpen: boolean;
  data: any;
}

const reportVersionDataModalInitialState: reportVersionDataModalState = {
  isOpen: false,
  data: null,
};

interface notifyAboutNewVersionModalState {
  isOpen: boolean;
  data: any;
}

const notifyAboutNewVersionModalInitialState: notifyAboutNewVersionModalState =
  {
    isOpen: false,
    data: null,
  };

interface undoAndArchiveImportModalState {
  isOpen: boolean;
  data: any;
}

const undoAndArchiveImportModalInitialState: undoAndArchiveImportModalState = {
  isOpen: false,
  data: null,
};

interface pollsAndSurveysExpiryDateModalState {
  isOpen: boolean;
  data: any;
}

const pollsAndSurveysExpiryDateModalInitialState: pollsAndSurveysExpiryDateModalState =
  {
    isOpen: false,
    data: null,
  };

interface exifViewerModalState {
  isOpen: boolean;
  data: any;
}

const exifViewerModalInitialState: exifViewerModalState = {
  isOpen: false,
  data: null,
};

interface assignProgramAdminPermissionToImportModalState {
  isOpen: boolean;
  data: any;
}

const assignProgramAdminPermissionToImportModalInitialState: assignProgramAdminPermissionToImportModalState =
  {
    isOpen: false,
    data: null,
  };

interface manageLayersPropertiesModalState {
  isOpen: boolean;
  data: any;
}

const manageLayersPropertiesModalInitialState: manageLayersPropertiesModalState =
  {
    isOpen: true,
    data: null,
  };

export interface ModalInitialState {
  phoneVerificationModal: phoneVerificationModalState;
  emailVerificationModal: emailVerificationModalState;
  confirmationModal: ConfirmationModalState;
  deleteAccountModal: DeleteAccountModalState;
  deleteAccountRequestModal: DeleteAccountRequestModalState;
  resetPasswordModal: ResetPasswordModalState;
  createPasswordModal: CreatePasswordModalState;
  OrganizationProfileDetailModal: OrganizationProfileDetailModalState;
  reinviteUserModal: ReinviteUserModalState;
  removePersonFromCommunityModal: RemovePersonFromCommunityModalState;
  deactivateUserModal: DeactivateUserModalState;
  restorePersonModal: DeactivateUserModalState;
  createCommunityModal: CreateCommunityModalState;
  editCommunityModal: EditCommunityModalState;
  communityAccessModal: communityAccessModalState;
  archiveOrRestoreCommunityModal: archiveOrRestoreCommunityModalState;
  archiveOrPublishOrganizationModal: archiveOrPublishOrganizationModalState;
  deleteACommunityModal: deleteACommunityModalState;
  assignAnAdminToCommunityModal: assignAnAdminToCommunityModalState;
  addMemberstoCommunityModal: addMemberstoCommunityModalState;
  createOrganizationModal: createOrganizationModalState;
  addExistingOrganizationsModal: addExistingOrganizationsModalState;
  addExistingCommunityModal: addExistingCommunityModalState;
  addEditTemplateFormModal: addEditTemplateFormModalState;
  formDetailsModal: formDetailsModalState;
  addPersonModal: addPersonModalState;
  addFileModal: AddFileModalState;
  archiveOrRestoreFormModal: archiveOrRestoreFormState;
  deleteFormModal: deleteFormModalState;
  addProgramModal: AddProgramModalState;
  editProgramModal: EditProgramModalState;
  completeProgramModal: completeProgramModalState;
  archiveOrRestoreProgramModal: archiveOrRestoreProgramModalState;
  assignAnAdminToProgramModal: assignAnAdminToProgramModalState;
  removeFromProgramModal: RemoveFromProgramModalState;
  removeCommunityFromOrganizationModal: RemoveCommunityFromOrganizationModalState;

  addExistingCommunitiesOrOrganizationsToProgramModal: addExistingCommunitiesOrOrganizationsToProgramModalState;

  signOutModal: signOutModalState;
  manageProgramMembersModal: manageProgramMembersModalState;
  videoLightBoxModal: videoLightBoxModalState;
  imageLightBoxModal: imageLightBoxModalState;
  imageVideoLightBoxModal: imageVideoLightBoxModalState;
  deleteFileModal: deleteFileModalState;
  addEditDuplicateProtocolModal: addEditDuplicateModalState;
  manageConnectioProtocolModal: manageConnectionProtocolModalState;
  archiveOrRestoreProtocolModal: archiveOrRestoreProtocolState;
  createOrEditDataAccessPolicyModal: createOrEditDataAccessPolicyState;
  archiveOrRestoreObservationModal: archiveOrRestoreObservationModalState;
  removeManagerModal: RemoveManagerModalState;
  addManagerModal: addManagerModalState;
  archiveOrRestoreQualityAssuranceObservationModal: archiveOrRestoreQualityAssuranceObservationModalState;
  inviteExternalReviewerQualityAssuranceObservationModal: inviteExternalReviewerQualityAssuranceObservationModalState;
  archiveOrRestoreCategoryModal: archiveOrRestoreCategoryModalState;

  addOrEditCategoryModal: addOrEditCategoryModalState;

  archiveOrRestoreBehaviorsModal: archiveOrRestoreBehaviorsModalState;

  addOrEditBehaviorsModal: addOrEditBehaviorsModalState;

  archiveOrRestoreConditionsModal: archiveOrRestoreConditionsModalState;

  addOrEditConditionsModal: addOrEditConditionsModalState;

  archiveOrRestoreTypeModal: archiveOrRestoreTypeModalState;
  addOrEditTypeModal: addOrEditTypeModalState;

  archiveOrRestoreMarkerGroupModal: archiveOrRestoreMarkerGroupModalState;
  addOrEditMarkerGroupModal: addOrEditMarkerGroupModalState;

  archiveOrRestoreMarkerStylesModal: archiveOrRestoreMarkerStylesModalState;
  addOrEditMarkerStylesModal: addOrEditMarkerStylesModalState;

  reviewerDetailModal: reiewerDetailModalState;

  messageActionsModal: messageActionsModalState;
  addOrEditSpecieModal: addOrEditSpecieModalState;
  archiveOrRestoreSpecieModal: archiveOrRestoreSpecieModalState;
  reUseSpeciesListModal: reUseSpeciesListModalState;

  addOrEditObjectModal: addOrEditObjectModalState;
  archiveOrRestoreObjectModal: archiveOrRestoreObjectModalState;
  reUseObjectsListModal: reUseSpeciesListModalState;

  addOrEditTagModal: addOrEditTagModalState;
  deleteTagModal: deleteTagModalState;
  removeNotificationRecipientsModal: RemoveNotificationRecipientsModalState;
  addExistingSpeciesOrObjectsToCommunityModal: addExistingSpeciesOrObjectsToCommunityModalState;
  openNotificationsSummaryModal: NotificationsSummaryModalState;
  openReportsSummaryModal: ReportsSummaryModalState;
  openReportsMapModal: ReportsSummaryModalState;

  pauseResumeDeleteNotificationModal: pauseResumeDeleteNotificationModalState;
  archiveOrRestoreGeographyLayerModal: archiveOrRestoreGeographyLayerModalState;
  archiveRestoreDeleteReportModal: archiveRestoreDeleteReportModalState;

  importSummaryModal: ImportSummaryModalState;
  importStatusDetailModal: ImportStatusDetailModalState;
  importInfoModal: ImportInfoModalState;
  editCommunityTagsModal: editCommunityTagsModalState;
  editOrganizationTagsModal: editOrganizationTagsModalState;

  editProgramTagsModal: editProgramTagsModalState;
  processedReportModal: processedReportModalState;
  addEditDuplicateRoleModal: addEditDuplicateRoleModalState;
  editPermissionModal: editPermissionModalState;
  deleteRoleModal: deleteRoleModalState;
  downloadOptionsImportModal: downloadOptionsImportModalState;
  dashboardAlertConfirmationModal: dashboardAlertModalState;
  archiveOrRestorePollsAndSurveyModal: archiveOrRestorePollsAndSurveyModalState;
  deletePollsAndSurveyModal: deletePollsAndSurveyModalState;
  addOrEditSurveyFormModal: addOrEditSurveyFormModalState;
  removeProgramModal: removeProgramModalState;
  successMessageModal: successfulMessageModalState;
  configurationDashboardModal: configurationDashboardModalState;
  downladPollsAndSurveysResultByVersionModal: downladPollsAndSurveysResultByVersionModalState;
  inviteDataEndViewerModal: inviteDataEndViewerModalState;
  deleteManagerModal: DeleteManagerModalState;
  successfullInviteMessageModal: successfullInviteMessageModalState;
  inviteRegisteredExternalReviewerModal: registeredExternalReviewerModalState;
  contactInfoModal: contactInfoModalState;
  addEditContactInfoModal: addEditContactInfoModalState;
  pauseResumeProgramModal: pauseResumeProgramModalState;
  activeSeasonsHistoryModal: activeSeasonsHistoryModalState;
  reportVersionDataModal: reportVersionDataModalState;
  notificationsCenterModal: NotificationsCenterModalState;
  mapSettingsProgramProfileModal: mapSettingsProgramProfileModalState;
  confirmationAssignAsAdminModal: ConfirmationAssignAsAdminModalState;
  notifyAboutNewVersionModal: notifyAboutNewVersionModalState;
  undoAndArchiveImportModal: undoAndArchiveImportModalState;
  pollsAndSurveysExpiryDateModal: pollsAndSurveysExpiryDateModalState;
  assignProgramAdminPermissionToImportModal: assignProgramAdminPermissionToImportModalState;
  exifViewerModal: exifViewerModalState;
  manageLayersPropertiesModal: manageLayersPropertiesModalState;
}

// Combined initial state
export const modalsInitialStates: ModalInitialState = {
  phoneVerificationModal: phoneVerificationModalInitialState,
  emailVerificationModal: emailVerificationModalInitialState,
  confirmationModal: confirmationModalInitialState,
  deleteAccountModal: deleteAccountModalInitialState,
  deleteAccountRequestModal: deleteAccountRequestModalInitialState,
  resetPasswordModal: resetPasswordModalInitialState,
  createPasswordModal: createPasswordModalInitialState,
  reinviteUserModal: reinviteUserModalInitialState,
  deactivateUserModal: deactivateUserModalInitialState,
  restorePersonModal: restorePersonModalInitialState,
  createCommunityModal: CreateCommunityModalInitialState,
  editCommunityModal: EditCommunityModalInitialState,
  removePersonFromCommunityModal: removePersonFromCommunityModalInitialState,
  communityAccessModal: communityAccessModalInitialState,
  archiveOrRestoreCommunityModal: archiveOrRestoreCommunityModalInitialState,
  archiveOrPublishOrganizationModal:
    archiveOrPublishOrganizationModalInitialState,
  assignAnAdminToCommunityModal: assignAnAdminToCommunityModalInitialState,
  deleteACommunityModal: deleteACommunityModalInitialState,
  addMemberstoCommunityModal: addMemberstoCommunityModalInitialState,
  createOrganizationModal: createOrganizationModalInitialState,
  OrganizationProfileDetailModal: OrganizationProfileDetailModalInitialState,
  addExistingOrganizationsModal: addExistingOrganizationsModalInitialState,
  addExistingCommunityModal: addExistingCommunityModalInitialState,
  addEditTemplateFormModal: addEditTemplateFormModalInitialState,
  formDetailsModal: formDetailsModalInitialState,
  addPersonModal: addPersonModalInitialState,
  archiveOrRestoreFormModal: archiveOrRestoreFormInitialState,
  deleteFormModal: deleteFormModalInitialState,
  addProgramModal: AddProgramModalInitialState,
  addFileModal: AddFileModalInitialState,
  archiveOrRestoreProgramModal: archiveOrRestoreProgramModalInitialState,
  assignAnAdminToProgramModal: assignAnAdminToProgramModalInitialState,
  editProgramModal: EditProgramModalInitialState,
  completeProgramModal: completeProgramModalInitialState,
  addExistingCommunitiesOrOrganizationsToProgramModal:
    addExistingCommunitiesOrOrganizationsToProgramModalInitialState,
  removeFromProgramModal: removeFromProgramModalInitialState,
  signOutModal: SignOutModalInitialState,
  manageProgramMembersModal: manageProgramMembersModalInitialState,
  mapSettingsProgramProfileModal: mapSettingsProgramProfileModal,
  videoLightBoxModal: videoLightBoxModalInitialState,

  imageLightBoxModal: imageLightBoxModalInitialState,
  imageVideoLightBoxModal: imageVideoLightBoxModalInitialState,
  deleteFileModal: deleteFileModalInitialState,
  addEditDuplicateProtocolModal: addEditDuplicateProtocolModalInitialState,
  manageConnectioProtocolModal: manageConnectionProtocolModalInitialState,
  archiveOrRestoreProtocolModal: archiveOrRestoreProtocolInitialState,

  createOrEditDataAccessPolicyModal: createOrEditDataAccessPolicyInitialState,

  archiveOrRestoreObservationModal:
    archiveOrRestoreObservationModalInitialState,

  messageActionsModal: messageActionsModalInitialState,
  removeManagerModal: removeManagerModalInitialState,
  addManagerModal: addManagerModalInitialState,

  archiveOrRestoreQualityAssuranceObservationModal:
    archiveOrRestoreQualityAssuranceObservationModalInitialState,

  inviteExternalReviewerQualityAssuranceObservationModal:
    inviteExternalReviewerQualityAssuranceObservationModalInitialState,
  reviewerDetailModal: reiewerDetailModalInitialState,
  archiveOrRestoreCategoryModal: archiveOrRestoreCategoryModalInitialState,
  addOrEditCategoryModal: addOrEditCategoryModalInitialState,

  archiveOrRestoreBehaviorsModal: archiveOrRestoreBehaviorsModalInitialState,
  addOrEditBehaviorsModal: addOrEditBehaviorsModalInitialState,

  archiveOrRestoreConditionsModal: archiveOrRestoreConditionsModalInitialState,
  addOrEditConditionsModal: addOrEditConditionsModalInitialState,

  archiveOrRestoreTypeModal: archiveOrRestoreTypeModalInitialState,
  addOrEditTypeModal: addOrEditTypeModalInitialState,


  archiveOrRestoreMarkerGroupModal: archiveOrRestoreMarkerGroupModalInitialState,
  addOrEditMarkerGroupModal: addOrEditMarkerGroupModalInitialState,



  archiveOrRestoreMarkerStylesModal: archiveOrRestoreMarkerStylesModalInitialState,
  addOrEditMarkerStylesModal: addOrEditMarkerStylesModalInitialState,



  addOrEditSpecieModal: addOrEditSpecieModalInitialState,
  archiveOrRestoreSpecieModal: archiveOrRestoreSpecieModalInitialState,
  reUseSpeciesListModal: reUseSpeciesListModalInitialState,
  addOrEditObjectModal: addOrEditObjectModalInitialState,
  archiveOrRestoreObjectModal: archiveOrRestoreObjectModalInitialState,
  reUseObjectsListModal: reUseObjectsListModalInitialState,
  deleteTagModal: deleteTagModalInitialState,
  addOrEditTagModal: addOrEditTagModalInitialState,
  removeNotificationRecipientsModal:
    removeNotificationRecipientsModalInitialState,

  addExistingSpeciesOrObjectsToCommunityModal:
    addExistingSpeciesOrObjectsToCommunityModalInitialState,
  openNotificationsSummaryModal: opennotifiationsSummaryModalInitialState,
  pauseResumeDeleteNotificationModal:
    pauseResumeDeleteNotificationModalInitialState,

  archiveOrRestoreGeographyLayerModal:
    archiveOrRestoreGeographyLayerModalInitialState,
  archiveRestoreDeleteReportModal: archiveRestoreDeleteReportModalInitialState,
  openReportsSummaryModal: openReportsSummaryModalInitialState,
  openReportsMapModal: openReportsMapModalInitialState,

  importSummaryModal: importSummaryModalInitialState,
  importInfoModal: importInfoModalInitialState,
  editCommunityTagsModal: editCommunityTagsModalInitialState,
  editOrganizationTagsModal: editOrganizationTagsModalInitialState,

  editProgramTagsModal: editProgramTagsModalInitialState,
  processedReportModal: processedReportModalInitialState,
  addEditDuplicateRoleModal: addEditDuplicateRoleModalInitialState,
  editPermissionModal: editPermissionModalInitialState,
  deleteRoleModal: deleteRoleModalInitialState,
  importStatusDetailModal: importStatusDetailModalInitialState,
  downloadOptionsImportModal: downloadOptionsImportModalInitialState,
  dashboardAlertConfirmationModal: dashboardAlertModalInitialState,
  archiveOrRestorePollsAndSurveyModal:
    archiveOrRestorePollsAndSurveyModalInitialState,
  deletePollsAndSurveyModal: deletePollsAndSurveyModalInitialState,
  addOrEditSurveyFormModal: addOrEditSurveyFormModalInitialState,
  removeProgramModal: removeProgramModalInitialState,
  removeCommunityFromOrganizationModal:
    removeCommunityFromOrganizationModalInitialState,
  successMessageModal: successfulMessageInitialState,
  configurationDashboardModal: configurationDashboardModalInitialState,
  downladPollsAndSurveysResultByVersionModal:
    downladPollsAndSurveysResultByVersionModalInitialState,
  inviteDataEndViewerModal: inviteDataEndViewerModalInitialState,
  deleteManagerModal: deleteManagerModalInitialState,
  successfullInviteMessageModal: successfullInviteMessageModalInitialState,
  inviteRegisteredExternalReviewerModal:
    registeredExternalReviewerModalInitialState,
  contactInfoModal: contactInfoModalInitialState,
  addEditContactInfoModal: addEditContactInfoModalInitialState,
  pauseResumeProgramModal: pauseResumeProgramModalInitialState,
  activeSeasonsHistoryModal: activeSeasonsHistoryModalInitialState,
  reportVersionDataModal: reportVersionDataModalInitialState,
  notificationsCenterModal: NotificationsCenterModalStateModalInitialState,
  confirmationAssignAsAdminModal: confirmationAssignAsAdminModalInitialState,
  notifyAboutNewVersionModal: notifyAboutNewVersionModalInitialState,
  undoAndArchiveImportModal: undoAndArchiveImportModalInitialState,
  pollsAndSurveysExpiryDateModal: pollsAndSurveysExpiryDateModalInitialState,
  assignProgramAdminPermissionToImportModal:
    assignProgramAdminPermissionToImportModalInitialState,
  exifViewerModal: exifViewerModalInitialState,
  manageLayersPropertiesModal: manageLayersPropertiesModalInitialState,
};
