import {
  ERROR_LOADING_ADMIN_TRACKING,
  GET_ALL_ADMIN_TRACKING,
  RESET_DATA,
  START_LOADING_ADMIN_TRACKING,
  STOP_LOADING_ADMIN_TRACKING,
} from "./action-types";
import initialState, {
  IAdminTracking,
  IFetchAdminTracking,
} from "./initialState";

export const adminTrackingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_ADMIN_TRACKING:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_ADMIN_TRACKING:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_ADMIN_TRACKING:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_ADMIN_TRACKING:
      const adminTracking = action.payload.adminTracking.map(
        (item: IAdminTracking) => {
          const {
            id,
            action,
            actionType,
            adminName,
            dateAdded,
            entityName,
            profileImage,
          } = item;
          const data = {
            id: id,
            adminName: {
              name: adminName,
              id: id,
              profileImage,
            },
            action: {
              action,
              actionType,
            },
            dateAdded,
            entityName,
          };
          return data;
        }
      );

      return {
        ...state,
        data: {
          adminTracking: adminTracking,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
