import React, { useLayoutEffect, useState } from "react";
import { CheckIcon } from "assets/icons/HeroIcons";
import { useDispatch } from "react-redux";
import { reportsVersionLocationsAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { sentenceCase } from "change-case";
import { useNavigate, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const Locations: React.FC<any> = ({ name }) => {
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleCheckboxChange = (e: any) => {
    if (e.name) {
      dispatch(reportsVersionLocationsAction(e.name));
    } else {
      dispatch(reportsVersionLocationsAction(""));
    }
  };
  const locations = [
    {
      id: 2,
      name: "Observation Locations",
    },
    {
      id: 3,
      name: "Community Locations",
    },
  ];
  //   const locations = ["Observation Locations", "Community Locations"];
  return (
    <div className="bg-bgWhite dark:bg-secondaryLight  min-w-[315px]  shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid dark:border-lineLight py-2">
      {locations.map((label, index) => (
        <div
          key={index}
          className="flex items-center justify-start rounded-lg py-1 "
        >
          <div className="flex items-center justify-start">
            <div
              className="relative flex items-center justify-start relative"
              onClick={() => handleCheckboxChange(label)}
            >
              {reportsVersionFilter.locations === label.name ? (
                <>
                <div className="w-[25px]">
                  <CheckIcon className="absolute top-0 left-[2px]"/>
                </div>
                  <p className="ml-[5px] ">
                    {sentenceCase(label.name)}
                  </p>
                </>
              ) : (
                <p className="ml-[30px]">{sentenceCase(label.name)}</p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
