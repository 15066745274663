import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeArchiveOrRestoreObservationModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  observationsStatusAction,
  qualityAssuranceObservationsFilterStatusAction,
  qualityAssuranceObservationsStatusAction,
} from "store/filters";
import { fetchObservationsAction } from "store/observations";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchObservationProfileAction } from "store/observationProfile/reducer.actions";
import { fetchObservationsActivitiesAction } from "store/observationsActivities";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

interface ArchiveOrRestoreObservationModalProps {}

export const ArchiveOrRestoreObservationModal: React.FC<
  ArchiveOrRestoreObservationModalProps
> = () => {
  const { archiveOrRestoreObservationModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeArchiveOrRestoreObservationModalAction());
  };
  const navigate = useNavigate();
  const { communityId, programId, observationId } = useParams();

  const archiveOrRestoreObservationClick = async () => {
    setIsLoading(true);

    try {
      const res = await apiLibrary.Observations.changeObservationStatus(
        archiveOrRestoreObservationModal?.data?.id,
        archiveOrRestoreObservationModal.data?.isArchive
          ? "archived"
          : "restore"
      );

      Toasts.success(res.message);

      if (archiveOrRestoreObservationModal.data.path !== "directory") {
        dispatch(fetchObservationProfileAction(observationId));
        dispatch(fetchObservationsActivitiesAction(observationId));
      } else {
        dispatch(fetchObservationsAction(communityId, programId));
        dispatch(observationsStatusAction(res.data?.status));
      }
      handleClose();
      if (archiveOrRestoreObservationModal?.data.path === "profilePage") {
        if (archiveOrRestoreObservationModal?.data?.isDirectoryPath) {
          // dispatch(qualityAssuranceObservationsStatusAction(res?.data?.status));
          // navigate("/quality-assurance/list");
        } else {
          dispatch(observationsStatusAction(res?.data?.status));
          // navigate("/observations/list");
        }
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={archiveOrRestoreObservationModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {archiveOrRestoreObservationModal.data?.isArchive
                    ? "Archive Observation"
                    : "Restore Observation"}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              {!archiveOrRestoreObservationModal.data?.isArchive ? (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to restore this observation?
                </p>
              ) : (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to archive this observation? You can
                  always restore this observation at a later point in time in
                  order to resume the observation.
                </p>
              )}
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text={
                  archiveOrRestoreObservationModal.data?.isArchive
                    ? "Yes, Archive"
                    : "Yes, Restore"
                  // archiveOrRestoreObservationModal?.data?.status ===
                  //   "archived" ||
                  // archiveOrRestoreObservationModal?.data?.status ===
                  //   "incompleted"
                  //   ? "Yes, Restore"
                  //   : "Yes, Archive"
                }
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={archiveOrRestoreObservationClick}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
