import React from 'react';

interface TextHighlighterProps {
  text: string;
  highlight: string;
}

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const TextHighlighter: React.FC<TextHighlighterProps> = ({
  text,
  highlight,
}) => {
  const detectURLsAndFormatMessage = (textPiece: string) => {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    if (urlRegex.test(textPiece)) {
      // If the piece of text is a URL, process it accordingly
      return (
        <a href={textPiece} className="text-primaryMid" target="_blank" rel="noopener noreferrer">
          {textPiece.replace(/https$/, '')} {/* Remove 'https' from the end */}
        </a>
      );
    } else {
      // If not a URL, return the text as is
      return textPiece;
    }
  };

  if (!highlight || !text) {
    // If there's no highlight specified, process the entire text for URLs
    return <span>{detectURLsAndFormatMessage(text)}</span>;
  }

  const escapedHighlight = escapeRegExp(highlight);
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          // Highlighted text also needs to be checked for URLs
          <span key={index} className="bg-bgHighlight px-1 py-[2px] rounded">
            {detectURLsAndFormatMessage(part)}
          </span>
        ) : (
          // Non-highlighted text, still check for URLs
          <span key={index}>{detectURLsAndFormatMessage(part)}</span>
        )
      )}
    </span>
  );
};

export default TextHighlighter;
