import getAllImports from "./getAllImports";
import getImportSummary from "./getImportSummary";
import fileUpload from "./fileUpload";
import importUpload from "./importUpload";
import getPropertyDropdownOptions from "./getPropertyDropdownOptions";
import updateColumnMapping from "./updateColumnMapping";
import importData from "./importData";
import getImportedFileData from "./getImportedFileData";
import updateObserver from "./updateObserver";
import getImportStatusDetail from "./getImportStatusDetail"
import changeImportStatus from "./changeImportStatus"
import getProgramAdmins from "./getProgramAdmins"
import grantPermissionToImport from "./grantPermissionToImport";

const Imports = {
  getAllImports,
  getImportSummary,
  fileUpload,
  importUpload,
  getPropertyDropdownOptions,
  updateColumnMapping,
  importData,
  getImportedFileData,
  updateObserver,
  getImportStatusDetail,
  changeImportStatus,
  getProgramAdmins,
  grantPermissionToImport
};

export default Imports;
