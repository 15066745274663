import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { AddFileBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { openAddFileModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";

const generateDynamicBreadcrumbLinks = (programId: any, name: any) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },

        {
          path: `/programs/${programId}/files`,
          label: `Files`,
        },
      ];

    default:
      return [];
  }
};
export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    status: "",
  });
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programId } = useParams<{
    programId: string;
  }>();
  const { programs, file } = usePermissions();

  const organizationsLinks = generateDynamicBreadcrumbLinks(
    programId ?? "",
    BreadcrumbData?.name ?? ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    }
  }, [programId]);

  // displaying models based on communityId
  const handleAddOrganizationModel = () => {
    if (programId) {
      dispatch(openAddFileModalAction({ programId, path: "directory" }));
    }
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={organizationsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              programId={programId ?? ""}
              name={BreadcrumbData?.name}
            />
            {BreadcrumbData.status !== "completed" && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                {programs.canCreateFiles && file.canCreateFile && (
                  <AddFileBtn handleAddFileModal={handleAddOrganizationModel} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
