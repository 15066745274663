import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const SwitchUserIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M16.4 10.8a3 3 0 1 0-4.8 0 4.01 4.01 0 0 0-1.372 1.867 1 1 0 1 0 1.886.666 2.001 2.001 0 0 1 3.772 0 1 1 0 1 0 1.886-.666A4.01 4.01 0 0 0 16.4 10.8ZM14 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M19 2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h10ZM8 5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V5Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      d="M5 22a3 3 0 0 1-3-3v-8a1 1 0 1 1 2 0v8a1 1 0 0 0 1 1h8a1 1 0 1 1 0 2H5Z"
    />
  </svg>
);
export default SwitchUserIcon;
