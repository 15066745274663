import * as React from "react";
import { SVGProps, memo } from "react";
const ListingPlaceholder = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
        <path d="M38.9332 40.1071C18.7732 60.2671 11.7332 108.8 13.6532 145.387C14.7199 165.867 19.5199 202.774 39.5732 218.88C57.2799 233.28 92.9066 240.214 128 240.214C163.093 240.214 198.72 233.067 216.427 218.88C236.48 202.774 241.28 165.547 242.347 145.387C244.267 108.8 237.227 60.0538 217.067 40.1071C184.64 7.68044 71.3599 7.68044 38.9332 40.1071Z" fill="#11A3E2" />
        <path d="M191.999 93.334H106.666C104.544 93.334 102.509 92.4911 101.009 90.9908C99.5089 89.4905 98.666 87.4557 98.666 85.334C98.666 83.2123 99.5089 81.1774 101.009 79.6771C102.509 78.1768 104.544 77.334 106.666 77.334H191.999C194.121 77.334 196.156 78.1768 197.656 79.6771C199.157 81.1774 199.999 83.2123 199.999 85.334C199.999 87.4557 199.157 89.4905 197.656 90.9908C196.156 92.4911 194.121 93.334 191.999 93.334Z" fill="#D4E8F2" />
        <path d="M74.6667 96.0013C80.5577 96.0013 85.3333 91.2257 85.3333 85.3346C85.3333 79.4436 80.5577 74.668 74.6667 74.668C68.7756 74.668 64 79.4436 64 85.3346C64 91.2257 68.7756 96.0013 74.6667 96.0013Z" fill="#D4E8F2" />
        <path d="M191.999 136.002H106.666C104.544 136.002 102.509 135.159 101.009 133.659C99.5089 132.159 98.666 130.124 98.666 128.002C98.666 125.88 99.5089 123.845 101.009 122.345C102.509 120.845 104.544 120.002 106.666 120.002H191.999C194.121 120.002 196.156 120.845 197.656 122.345C199.157 123.845 199.999 125.88 199.999 128.002C199.999 130.124 199.157 132.159 197.656 133.659C196.156 135.159 194.121 136.002 191.999 136.002Z" fill="#D4E8F2" />
        <path d="M74.6667 138.667C80.5577 138.667 85.3333 133.892 85.3333 128.001C85.3333 122.11 80.5577 117.334 74.6667 117.334C68.7756 117.334 64 122.11 64 128.001C64 133.892 68.7756 138.667 74.6667 138.667Z" fill="#D4E8F2" />
        <path d="M191.999 178.668H106.666C104.544 178.668 102.509 177.825 101.009 176.325C99.5089 174.825 98.666 172.79 98.666 170.668C98.666 168.546 99.5089 166.511 101.009 165.011C102.509 163.511 104.544 162.668 106.666 162.668H191.999C194.121 162.668 196.156 163.511 197.656 165.011C199.157 166.511 199.999 168.546 199.999 170.668C199.999 172.79 199.157 174.825 197.656 176.325C196.156 177.825 194.121 178.668 191.999 178.668Z" fill="#D4E8F2" />
        <path d="M74.6667 181.335C80.5577 181.335 85.3333 176.56 85.3333 170.669C85.3333 164.778 80.5577 160.002 74.6667 160.002C68.7756 160.002 64 164.778 64 170.669C64 176.56 68.7756 181.335 74.6667 181.335Z" fill="#D4E8F2" />
    </svg>
);
export default ListingPlaceholder;
