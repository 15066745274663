import React from "react";
import { EmailBox, PhoneBox } from "./components";
import { updateAuthDataAction } from "store/auth/reducer.actions";
import { useDispatch } from "react-redux";
import { getUserProfileAction } from "store/userProfile";
import Roles from "hooks/roles";

interface DynamicEmailAndPhoneContainerProps {
  values: any;
  errors: any;
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  setFieldValue: any;
  updatePersonData: any;
  isLoading: boolean;
}

const DynamicEmailAndPhoneContainer: React.FC<
  DynamicEmailAndPhoneContainerProps
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  updatePersonData,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const { isMyProfile } = Roles();

  function refreshNewData(data: any) {
    if (isMyProfile(data?.personId)) {
      dispatch(updateAuthDataAction(data));
    }
    dispatch(getUserProfileAction(data));
  }
  return (
    <div>
      <EmailBox
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        updatePersonData={updatePersonData}
        isLoading={isLoading}
        refreshNewData={refreshNewData}
      />
      <PhoneBox
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        updatePersonData={updatePersonData}
        isLoading={isLoading}
        refreshNewData={refreshNewData}
      />
    </div>
  );
};

export { DynamicEmailAndPhoneContainer };
