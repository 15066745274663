import React from "react";
import { Box, Grid } from "@mui/material";
import { ObservationsByRegionMap } from "./components/Mapbox";
import DashboardCardHeading from "../DashboardCardHeading";
import RegionsObservationsLinearProgress from "./components/LinearProgress";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { TailSpin } from "react-loader-spinner";

const ObservationsByRegion = () => {
  const {
    dashboard: { observationsByRegion },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchObservationsByRegion());
  }, [dashboardFilter]);

  return (
    <Box className={`dark:bg-secondaryLight`}>
      <DashboardCardHeading
        text="Observations By Region"
        widgetId="observations_by_region"
      />

      <Grid container spacing={2}>
        {observationsByRegion.stateIs === "Pending" ? (
          <div className="flex items-center justify-center w-full h-full loader">
            <TailSpin
              height="50"
              width="50"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <>
            <Grid item xs={9}>
              <ObservationsByRegionMap />
            </Grid>
            <Grid item xs={3}>
              <RegionsObservationsLinearProgress
                data={observationsByRegion.data.regions}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ObservationsByRegion;
