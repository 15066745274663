import React from "react";
import TextInputField from "view/components/InputField";
import BpCheckbox from "view/components/CheckBox";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  IProfileRoleAdditionalInfo,
  IProfileRoleAdditionalInfoData,
} from "store/profileRoles/initialState";
import { sentenceCase } from "change-case";

const ProfileRolesAdditionalInformation = () => {
  const { data } = useSelector<RootState, IProfileRoleAdditionalInfoData>(
    (state) => state.profileAdditionalInfo
  );
  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full pt-6 pl-8 pr-8 xl:pl-12">
        <p className="text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
          Observer Roles
        </p>
      </div>
      <div className="flex flex-col xl:w-[90%] w-[89%] xl:mx-10 mr-10 my-5 gap-5">
        {data?.map((role: IProfileRoleAdditionalInfo, index: number) => {
          return (
            <div key={index} className="flex justify-between">
              <div className="flex w-[30%]">
                <div className="flex items-center justify-start flex-grow pl-3">
                  <div className="flex justify-start items-center flex-grow relative py-0.5">
                    <p className="flex-grow w-1/2 text-[15px] text-left text-secondaryMid dark:text-caption ">
                      {sentenceCase(role?.type ?? "")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex xl:w-[60%] w-[70%] gap-4">
                <TextInputField
                  placeholder="Code"
                  value={role?.code || ""}
                  className="pointer-events-none"
                />
                <TextInputField
                  placeholder="Permit #"
                  value={role.permitNumber || ""}
                  className="pointer-events-none"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileRolesAdditionalInformation;
