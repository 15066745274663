import React, { useCallback } from 'react'
import contactsPlaceholder from "assets/images/Placeholders/avatar-contacts.png";
import ellipsize from 'ellipsize';
import { getBrandPlaceholder } from 'utils/customBranding';


interface ContactTypeProps {
    title: any;
    handleClick: () => void,
    users: string[];
    archived: boolean;
}

const ContactType: React.FC<ContactTypeProps> = ({ handleClick, title, users, archived }) => {

    const renderUsers = useCallback(() => {
        if (users?.length <= 4) {
            return users.join(', ');
        } else {
            const displayedUsers = users?.slice(0, 3).join(', ');
            return `${displayedUsers}, ...`;
        }
    }, [users]);


    return (
        <div className="flex justify-between gap-3 py-1 items-center mb-2 cursor-pointer px-2" onClick={handleClick}>
            <div className="w-9 h-9 rounded-full">
                <img
                    src={archived ? contactsPlaceholder : getBrandPlaceholder("messageArchive")}
                    alt="Img"
                    className="w-full h-full rounded-full"
                />
            </div>
            <div className="flex-1">
                <h2 className="font-semibold text-secondary font-Overpass dark:text-textMain">
                    {title}
                </h2>
                <p className="rounded text-secondaryMidLight text-sm max-w-max font-Overpass dark:text-caption">
                    {/* {msg.lastMessageText || ""} */}
                    {ellipsize(users ? renderUsers() : 'loading...',30)}
                </p>
            </div>
        </div>
    )
}

export default ContactType;