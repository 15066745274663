import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import { sentenceCase } from "change-case";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface LinkItem {
  path: string;
  label: string;
  icon?: React.ComponentType<{ height: number; width: number }>; // Icon component with height and width props
}

interface BreadcrumbProps {
  links: LinkItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ links }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center mb-4">
      {links.length > 0 && ( // Check if there are any links before rendering the Home button
        <button
        onClick={() => navigate(-1)}
          className="flex items-center mr-2 space-x-2 text-textMid hover:underline"
        >
          <ArrowLeftIcon height={22} width={22} /> {/* Left icon */}
        </button>
      )}
      {links.map((link, index) => (
        <React.Fragment key={index}>
          {index > 0 && <CheveronRightIcon height={22} width={22} />}
          <Link
            to={link.path}
            className="flex items-center space-x-2 text-textMid hover:underline dark:text-inputText"
          >
            {link.icon && <link.icon height={22} width={22} />}{" "}
            {/* If you have icons for each link */}
            {/* <p className="text-sm">{sentenceCase(link.label)}</p> */}
            <p className="text-sm">{link.label}</p>
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};

