interface ProfileImage {
  id: number;
  file: {
    large: string;
    original: string;
    thumb: string;
  };
  path: string;
}

interface CreatedBy {
  id: number;
  name: string;
  profileImage: ProfileImage; 
}

export interface Igeography {
  id: number;
  
  description: string;
  createdBy: CreatedBy; 
  dateAdded: string; 
  layerName: string; 
}

export interface geographiesData {
  geographies: Igeography[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchGeographies {
  data: geographiesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IfetchGeographies = {
  data: {
    geographies: [],
    fields: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
