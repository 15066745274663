import { apiClient } from "../config";

async function getAllConnectedCommunities(id: any) {
  const url = "admin/organizations/communities";
  const res = await apiClient.get(url, {
    params: {
      id: id,
      limit: 10000,
      connected: true,
    },
  });
  return res.data;
}

export default getAllConnectedCommunities;
