import { Dispatch } from "redux";
import { ERROR_LOADING_ORGANIZATIONS, GET_ALL_ORGZANIZATIONS, RESET_DATA, START_LOADING_ORGANIZATIONS, STOP_LOADING_ORGANIZATIONS } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchOrganizationsAction =
  (communityId?: any, programId?: any) =>
  async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_ORGANIZATIONS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.organizationsFilter
      );
      const query = appendCommunityOrProgramIdInParams(
        communityId,
        programId,
        params
      );

      const { data } =
        await apiLibrary.OrganizationDirectory.getAllOrganizations(query);
      dispatch({
        type: GET_ALL_ORGZANIZATIONS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      // dispatch(fetchError(error))
      dispatch({ type: ERROR_LOADING_ORGANIZATIONS });
    } finally {
      dispatch({ type: STOP_LOADING_ORGANIZATIONS });
      // dispatch(setLoadingProgress(100));
      // dispatch(loadingState(false))
    }
  };

const appendCommunityOrProgramIdInParams = (
  communityId: any,
  programId: any,
  params: any
) => {
  let updatedParams = { ...params };

  if (communityId) {
    updatedParams.communityIds = communityId.toString();
  } else if (programId) {
    updatedParams.programIds = programId.toString();
  }

  return updatedParams;
};
