import { Ifetch } from "../initialState";

export interface IDashboardConfiguration {
  index: number;
  widget_name?: string;
  widgetName?: string;
  _id?: string;
  startDate?: string;
  type?: string;
}

export interface IfetchAllDashboardConfiguration extends Ifetch {
  data: IDashboardConfiguration[];
  postStateIs: "Idle" | "Pending" | "Error";
}

const dashboardConfigurationInitialState: IfetchAllDashboardConfiguration = {
  data: [],
  message: "",
  stateIs: "Pending",
  postStateIs: "Idle",
};
export default dashboardConfigurationInitialState;
