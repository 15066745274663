import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, AddSpecieBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import {
  openAddExistingCommunityModalAction,
  openAddExistingSpeciesOrObjectsToCommunityModalAction,
  openAddOrEditSpecieModalAction,
} from "store/modals";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (
  communityId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/species`,
          label: `Species`,
        },
      ];

    default:
      return [{ path: `/species/list`, label: `Species` }];
  }
};
export const Header = () => {
  const navigate = useNavigate();

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { speciesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { species } = usePermissions();
  const { communities } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    communityId ?? "",
    BreadcrumbData?.name ?? "",
    speciesFilter.status
  );

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        });
    }
  }, [communityId]);

  // Handlers

  // displaying models based on communityId
  const handleSpecieModel = () => {
    if (communityId) {
      dispatch(
        openAddExistingSpeciesOrObjectsToCommunityModalAction({
          id: communityId,
          entity: "specie",
        })
      );
      // setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);
    } else {
      dispatch(
        openAddOrEditSpecieModalAction({
          alternativeName: "",
          commonName: "",
          description: "",
          images: [],
          isStar: null,
          scientificName: "",
          typeId: null,
          path: "",
        })
      );
    }
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewBtn = () => {
    dispatch(
      openAddOrEditSpecieModalAction({
        alternativeName: "",
        commonName: "",
        description: "",
        images: [],
        isStar: null,
        scientificName: "",
        typeId: null,
        path: "",
      })
    );
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    if (communityId) {
      dispatch(
        openAddExistingSpeciesOrObjectsToCommunityModalAction({
          id: communityId,
          entity: "species",
        })
      );
      setIsShowExistingOptionsModal(false);
    }
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              communityId={
                communityId ? communityId : programId ? programId : ""
              }
              name={BreadcrumbData?.name}
              parentName={
                communityId ? "Communities" : programId ? "Programs" : ""
              }
            />

            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {communityId &&
                communities.canViewEntityDropdownCommunities &&
                communities.canCreateSpeciesCommunities && (
                  <AddSpecieBtn
                    handleAddSpecieModel={handleSpecieModel}
                    label={"Connect Species"}
                  />
                )}
              {!communityId && species.canCreateSpecies && (
                <AddSpecieBtn
                  handleAddSpecieModel={handleSpecieModel}
                  label={"Add Species"}
                />
              )}
              {/* <OptionsModal
                  isOpen={IsShowExistingOptionsModal}
                  handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                  handleAddSpecieModel={handleSpecieModel}
                  handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
                /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
