import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const DoubleChatIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={restProps.width || 24}
    height={restProps.height || 24}
    viewBox="0 0 14 14"
    className="relative flex-grow-0 flex-shrink-0"
    fill={fill}
    {...restProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.333008 2C0.333008 0.895431 1.22844 0 2.33301 0H8.99967C10.1042 0 10.9997 0.895431 10.9997 2V2.66667H11.6663C12.7709 2.66667 13.6663 3.5621 13.6663 4.66667V8.66667C13.6663 9.77124 12.7709 10.6667 11.6663 10.6667H10.9997V12.6667C10.9997 12.9363 10.8372 13.1794 10.5881 13.2826C10.339 13.3858 10.0523 13.3287 9.8616 13.1381L7.3902 10.6667H4.99967C4.69116 10.6667 4.39862 10.5965 4.13752 10.4716C3.94686 10.6621 3.66024 10.7191 3.41122 10.6159C3.1621 10.5127 2.99967 10.2696 2.99967 10V8H2.33301C1.22844 8 0.333008 7.10457 0.333008 6V2ZM5.27582 9.33333H7.66634C7.84315 9.33333 8.01272 9.40357 8.13775 9.5286L9.66634 11.0572V10C9.66634 9.63181 9.96482 9.33333 10.333 9.33333H11.6663C12.0345 9.33333 12.333 9.03486 12.333 8.66667V4.66667C12.333 4.29848 12.0345 4 11.6663 4H10.9997V6C10.9997 7.10457 10.1042 8 8.99967 8H6.60915L5.27582 9.33333ZM9.66634 2C9.66634 1.63181 9.36786 1.33333 8.99967 1.33333H2.33301C1.96482 1.33333 1.66634 1.63181 1.66634 2V6C1.66634 6.36819 1.96482 6.66667 2.33301 6.66667H3.66634C4.03453 6.66667 4.33301 6.96514 4.33301 7.33333V8.39052L5.8616 6.86193C5.98663 6.7369 6.1562 6.66667 6.33301 6.66667H8.99967C9.36786 6.66667 9.66634 6.36819 9.66634 6V2Z"
      fill={fill}
    />
  </svg>
);
export default DoubleChatIcon;
