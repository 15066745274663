import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeExifViewerModalAction,
  openImageLightBoxModalAction,
  openVideoLightBoxModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { capitalCase } from "capital-case";
import Map from "./Map";
import { TailSpin } from "react-loader-spinner";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

interface ExifViewerModalProps {}

export const ExifViewerModal: React.FC<ExifViewerModalProps> = () => {
  const { exifViewerModal } = useSelector((state: RootState) => state.modals);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [gpsLocationDetails, setGpsLocationDetails] = useState<{
    latitude: any;
    longitude: any;
    showMap: boolean;
  }>({
    latitude: null,
    longitude: null,
    showMap: false,
  });
  const [exifData, setExifData] = useState({});
  const handleClose = () => {
    dispatch(closeExifViewerModalAction());
  };
  const navigate = useNavigate();

  const getExifDataOfMediaFiles = async (url: string) => {
    setIsLoading(true);

    try {
      const { message, data } = await apiLibrary.Observations.getExifData(url);
      if (data.gpsLongitude && data.gpsLatitude) {
        const longitude = convertCoordinateToDecimal(data.gpsLongitude);
        const latitude = convertCoordinateToDecimal(data.gpsLatitude);

        if (longitude || latitude !== "invalidCoordinates") {
          setGpsLocationDetails((pre: any) => {
            return {
              latitude: latitude,
              longitude: longitude,
              showMap: true,
            };
          });
        }
      }
      setExifData(data);
      Toasts.success("Exif data retirived Successfully");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (exifViewerModal.data.fileUrl) {
      getExifDataOfMediaFiles(exifViewerModal.data.fileUrl);
    }
  }, [exifViewerModal.data]);
  const items = Array.from({ length: 1000 }, (_, i) => i + 1);
  return (
    <Modal
      open={exifViewerModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col items-start justify-start w-[1000px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch  py-0.5">
              <div className="relative flex items-center self-stretch justify-start ">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {/* {exifViewerModal.data?.isArchive
                    ? "Archive Observation"
                    : "Restore Observation"} */}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>

            {isLoading ? (
              <div className="flex flex-wrap justify-center items-center overflow-y-auto h-[285px] w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <div className="flex flex-col items-start self-stretch w-full gap-2 ">
                <div className="relative flex items-start self-stretch justify-start gap-3">
                  <div className="w-[40%]">
                    {exifViewerModal.data.dataType === "image" && (
                      <img
                        onClick={() => {
                          dispatch(
                            openImageLightBoxModalAction({
                              width: 1080,
                              filePath: exifViewerModal.data.fileUrl,
                            })
                          );
                        }}
                        src={exifViewerModal.data.fileUrl}
                        className="self-stretch h-[200px]  w-full cursor-pointer rounded-lg object-cover"
                      />
                    )}

                    {exifViewerModal.data.dataType === "video" && (
                      <img
                      
                        onClick={() => {
                          dispatch(
                            openVideoLightBoxModalAction({
                              filePath: exifViewerModal.data.fileUrl,
                              fileType: exifViewerModal.data.fileType,
                            })
                          );
                        }}
                        src={videoThumbnailImg}
                        className="self-stretch h-[200px]  w-full cursor-pointer rounded-lg object-cover"
                      />
                    )}
                  </div>

                  <div className="flex flex-wrap justify-between overflow-y-auto max-h-[200px] w-[60%]">
                    {Object.entries(exifData).map(([key, value], index) => (
                      <div
                        key={index}
                        className={`  ${index % 2 === 0 ? "w-[100%]" : "w-[100%]"}`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-start w-[250px] min-h-12 bg-[#ebf2f6] border border-[#d1d1d1] rounded-tl px-3">
                            <p className="text-sm font-semibold text-left text-[#202123]">
                              {capitalCase(key ?? "")}
                            </p>
                          </div>
                          <div className="flex items-center pl-3 justify-start  w-[250px] flex-grow min-h-12 bg-white border border-[#d1d1d1] rounded-tr">
                            <p className="text-sm text-center text-black">
                              {typeof value === "string" ||
                              typeof value === "number"
                                ? value
                                : typeof value === "undefined"
                                  ? ""
                                  : JSON.stringify(value)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {gpsLocationDetails.showMap && (
              <div className="w-full col-span-12">
                <Map
                  coordiantes={[
                    gpsLocationDetails.longitude,
                    gpsLocationDetails.latitude,
                  ]}
                  editMode={false}
                />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

function convertCoordinateToDecimal(coordinate: any) {
  coordinate = coordinate.trim();
  if (isDecimalDegrees(coordinate)) {
    return parseFloat(coordinate);
  } else if (isDMS(coordinate)) {
    return convertDMSToDecimal(coordinate);
  } else if (isDDM(coordinate)) {
    return convertDDMToDecimal(coordinate);
  } else {
    return "invalidCoordinates";
  }
}

function isDecimalDegrees(coordinate: any) {
  const decimalRegex = /^-?\d+(\.\d+)?$/;
  return decimalRegex.test(coordinate.trim());
}

function isDMS(coordinate: any) {
  const dmsRegex =
    /^\d{1,3}\s*(°|deg)\s*\d{1,2}'\s*\d{1,2}(\.\d+)?\"\s*([NSEW])?$/i;
  return dmsRegex.test(coordinate.trim());
}

function isDDM(coordinate: any) {
  const ddmRegex = /^\d{1,3}\s*(°|deg)\s*\d{1,2}(\.\d+)?'\s*([NSEW])?$/i;
  return ddmRegex.test(coordinate.trim());
}

function convertDDMToDecimal(ddmString: string) {
  const ddmRegex = /(\d{1,3})\s*(°|deg)\s*(\d{1,2}(\.\d+)?)'\s*([NSEW])?/i;
  const matches = ddmString.match(ddmRegex);

  if (!matches) {
    return "invalidCoordinates";
  }

  const degrees = parseInt(matches[1], 10);
  const decimalMinutes = parseFloat(matches[3]);
  const direction = matches[5] ? matches[5].toUpperCase() : null;

  let decimalDegrees = degrees + decimalMinutes / 60;

  if (direction === "S" || direction === "W") {
    decimalDegrees *= -1;
  }

  return decimalDegrees;
}

function convertDMSToDecimal(dmsString: string) {
  const dmsRegex =
    /(\d{1,3})\s*(°|deg)\s*(\d{1,2})'\s*(\d{1,2}(\.\d+)?)\"\s*([NSEW])/i;
  const matches = dmsString.match(dmsRegex);

  if (!matches) {
    return "invalidCoordinates";
  }

  const degrees = parseInt(matches[1], 10);
  const minutes = parseInt(matches[3], 10);
  const seconds = parseFloat(matches[4]);
  const direction = matches[6].toUpperCase();

  let decimalDegrees = degrees + minutes / 60 + seconds / 3600;

  if (direction === "S" || direction === "W") {
    decimalDegrees *= -1;
  }

  return decimalDegrees;
}
