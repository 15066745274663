import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Filters,
  GridCard,
  Header,
  Pagination,
  Table,
  Tabs,
} from "Components/Organizations";
import { TableProps } from "Components/Organizations/Table/types";
import { OrganizationsTable } from "hooks/useTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { fetchOrganizationsAction } from "store/oranizations";
import { IfetchOrganizations } from "store/oranizations/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
import { orgzanizationsResetFiltersAction } from "store/filters";
import { setOrgzanizationsFiltersAction } from "store/filters/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Organizations = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setOrgzanizationsFiltersAction());
  }, []);

  const { data } = useSelector<RootState, IfetchOrganizations>(
    (state) => state?.allOrganizations
  );
  const { communityId, programId } = useParams();

  const { organizationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { organizations } = usePermissions();

  const isRowSelected = (rowId: string) => selectedRows.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = OrganizationsTable(data?.organizations);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        organizationsFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [organizationsFilter.searchString]);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };

  useEffect(() => {
    dispatch(fetchOrganizationsAction(communityId, programId));
    saveFiltersDataToLocalStorage(organizationsFilter);
  }, [organizationsFilter]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(orgzanizationsResetFiltersAction());
  //   };
  // }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
            </div>
          </div>
        </div>

        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          isRowSelected={isRowSelected}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          setSelectedRows={setSelectedRows}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default Organizations;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  isRowSelected,
  selectAll,
  setSelectAll,
  setSelectedRows,
  setSorting,
  sorting,
}) => {
  const { organizationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );
  const { data, stateIs } = useSelector<RootState, IfetchOrganizations>(
    (state) => state?.allOrganizations
  );
  const { primary } = useSelector((state: any) => state.theme.colors);
  if (organizationsFilter.viewType === "grid") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
        {stateIs == "Idle" && data?.organizations?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color={primary}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <GridCard />
        )}
        <div
          className={`w-full bg-bgWhite flex-grow-0 flex-shrink-0 ${stateIs === "Idle" && data?.organizations?.length === 0
              ? ""
              : "mt-2"
            }`}
        >
          <Pagination />
        </div>
      </div>
    );
  } else if (organizationsFilter.viewType === "list") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
        {stateIs == "Idle" && data?.organizations?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color={primary}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <Table
            flexRender={flexRender}
            table={table}
            columnOrder={columnOrder}
            columns={columns}
            columnVisibility={columnVisibility}
            isRowSelected={isRowSelected}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            setSelectedRows={setSelectedRows}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
        <Pagination />
      </div>
    );
  } else if (organizationsFilter.viewType === "map") {
    return <div>Map</div>;
  } else {
    return null;
  }
};
