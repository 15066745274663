import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { HARVEST_QUESTIONS_NAME } from '../../../panelsQuestionsName';

const useGetCurrentQuestionState = (questionId:any,name:any) => {
    const {pointPickers,harvestPanelMapQuestions,geographicalPanelMapQuestions} = useSelector((state:RootState)=>state.surveyJS);
    const [currentQuestion,setCurrentQuestion] = useState();


    useEffect(()=>{

      let currentQues = pointPickers.find((s:any)=>s.id == questionId);

      if(!currentQues){
        if(harvestPanelMapQuestions?.id){
          if(name === HARVEST_QUESTIONS_NAME.ValuedFeautres){
            currentQues = harvestPanelMapQuestions.retrievalPointPicker;
          }
          else if(name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint){
            currentQues = harvestPanelMapQuestions.struckPointPicker;
          }
        }
        if(name === "VantagePoint" && geographicalPanelMapQuestions?.id){
          currentQues = geographicalPanelMapQuestions.pointPicker
        };
      };
  
    setCurrentQuestion(currentQues);
        
      },[questionId,pointPickers,harvestPanelMapQuestions,geographicalPanelMapQuestions])

    return currentQuestion;
}

export { useGetCurrentQuestionState};