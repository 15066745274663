import getAllRecipients from "./getAllRecipients";
import getAllContactPersons from "./getAllContactPersons";
import createConversation from "./createConversation";
import getChatDetail from "./getChatDetail";
import sendMessage from "./sendMessage";
import deleteConversation from "./deleteConversation";
import updateConversationStatus from "./updateConversationStatus";
import updateMuteStatus from "./updateMuteStatus";
import exportChat from "./exportChat";

const Messages = {
  getAllRecipients,
  getAllContactPersons,
  createConversation,
  getChatDetail,
  sendMessage,
  deleteConversation,
  updateConversationStatus,
  updateMuteStatus,
  exportChat
};

export default Messages;
