import usePermissions from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import getCommunityMapGeographies from "services/api/Communities/getCommunityMapGeographies";

const useFetchCommunityRegionLayers = (
  currentSelectedCommunity: string | null
) => {
  const [layers, setLayers] = useState<null | any[]>(null);
  const { communities } = usePermissions();

  useEffect(() => {
    if (currentSelectedCommunity && communities.canViewRegionsCommunities) {
      // fetcing communit regions data
      getCommunityMapGeographies(currentSelectedCommunity)
        .then(({ data }: any) => {
          setLayers(data.communityRegions);
        })
        .catch((err: any) => {});
    }
  }, [currentSelectedCommunity]);

  return layers;
};

export { useFetchCommunityRegionLayers };
