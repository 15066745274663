import AppleIcon from "assets/images/Auth/AppleIcon.png";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import AppleSignin from "react-apple-signin-auth";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { loginAction } from "store/auth";
import Cookies from "universal-cookie";
import { convertKeysToSnakeCase } from "utils/caseConvertor";
import { Toasts } from "view/components/Toasts";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";

interface AppleAuthorization {
  code: string;
  id_token: string;
}

interface AppleAuthResponse {
  authorization: AppleAuthorization;
}

interface AppleIdTokenPayload extends JwtPayload {
  nonce: string;
  c_hash: string;
  email: string;
  email_verified: string;
  auth_time: number;
  nonce_supported: boolean;
}

export const Apple = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const responseApple = async (response: AppleAuthResponse) => {
    const { id_token } = response.authorization;
    const decoded = jwtDecode<AppleIdTokenPayload>(id_token);

    try {
      // dispatch(setLoadingProgress(getRandomProgress()));
      const res = await apiLibrary.Auth.socialLogin(
        convertKeysToSnakeCase({
          channel: "apple",
          email: decoded.email,
          accessToken: id_token,
          id: decoded.email,
        })
      );

      const refreshTokenExpiry = moment().add(365, "days").toDate();

      cookies.set("token", res.data.accessToken, {
        path: "/",
      });

      cookies.set("refreshToken", res.data.refreshToken, {
        path: "/",
        expires: refreshTokenExpiry,
      });

      Toasts.success(res.message);
      dispatch(loginAction(res.data));
      window.location.reload();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
     
    }finally{
      // dispatch(setLoadingProgress(100));
    }
  };
  return (
    <>
      <AppleSignin
        authOptions={{
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID ?? "",
          scope: "email name",
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL ?? "",
          state: "",
          nonce: "nonce",
          usePopup: true,
        }}
        uiType="dark"
        className="apple-auth-btn"
        noDefaultStyle={false}
        buttonExtraChildren="Continue with Apple"
        onSuccess={(response: AppleAuthResponse) => {
          responseApple(response);
        }}
        onError={(error: any) => console.error(error)}
        skipScript={false}
        iconProps={{ style: { marginTop: "10px" } }}
        render={(props: any) => (
          <button
            {...props}
            type="button"
            className="flex items-center justify-center flex-grow relative h-10 w-[86px] rounded-3xl border-2 bg-bgWhite dark:bg-secondaryLight border-textLink hover:bg-primaryExtraLight dark:hover:bg-primary dark:border-lineLight dark:hover:border-textLink"
            aria-label="Apple"
          >
            {" "}
            <img src={AppleIcon} alt="" />
          </button>
        )}
      />
    </>
  );
};
