import { useEffect, useRef, useState } from "react";
import {
  __changeColorOfMarker__,
  __changeColorOfZoomedShapeLayer__,
  __deleteAllLocalDrawShapes__,
  __resetColorOfSelectedLayersItems__,
  __resetColorOfSelectedMarkers__,
} from "./workerMehods";
import {
  useHandleShapeChangeMode,
  useInitializeCustomShapesControlsDropdown,
  useInitializeMap,
} from "./hooks";
import { FILTERS_OPTIONS } from "../../../..";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export function Map({
  selectedOption,
  selectedGeographies = [],
  updateSelectedShapeRegions,
  reportId,
  values,
  setFeatures,
  editData,
  drawRef:draw
}: {
  selectedOption: any;
  selectedGeographies: any[];
  updateSelectedShapeRegions: (regions: any[]) => void;
  reportId?: any;
  values: any;
  setFeatures:any;
  editData:any;
  drawRef:any
}) {
  const mapContainer = useRef<any>(null);

  const [selectedDrawShape, setSelectedDrawShape] = useState("");
  const [doUpdateDrawFeature,setDoUpdateDrawFeature] = useState(false);

  // initialize the map on first rendering
  const { isMapFullyLoaded, map, drawRef,zoom }: any =
    useInitializeMap(mapContainer,setDoUpdateDrawFeature);

  // assigning the draw reference to parent ref for removing all drawn shapes by clicking on 'clear all' button
  useEffect(()=>{
    if(isMapFullyLoaded){
      draw.current = drawRef.current;
    }
  },[isMapFullyLoaded])

  // initialize the custom shapes controls dropdown
  useInitializeCustomShapesControlsDropdown(
    values,
    isMapFullyLoaded,
    map,
    selectedDrawShape,
    setSelectedDrawShape
  );

  // update the selected shape for drawing
  useHandleShapeChangeMode(
    selectedDrawShape,
    drawRef.current,
    isMapFullyLoaded,
    zoom
  );

  useEffect(() => {
    if (!doUpdateDrawFeature) return;

    const geometryCollection = drawRef.current?.getAll();

    if (geometryCollection) {
      setFeatures(geometryCollection.features);
    }

    setDoUpdateDrawFeature(false);
  }, [doUpdateDrawFeature]);

  // apply editable data
  useEffect(()=>{
    const regions = editData && editData[0]?.filter?.regions;
    if(regions && isMapFullyLoaded && drawRef.current){
      const geometery = {
        features:[],
        type:"FeatureCollection"
      };

      geometery.features = regions.map((r:any)=>{
        if(r.mapData){
         return r.mapData.features
        };
        return r;
      }).flat();
      drawRef.current.set(geometery)
      setFeatures(geometery.features);
    }
  },[editData,isMapFullyLoaded])

  return (
    <div className="mt-3">
      <p className="mb-4 text-sm font-Overpass text-textMidLight dark:text-textMain">
        {getNoteText(selectedOption)}
      </p>
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "400px",
        }}
      ></div>
    </div>
  );
}

function getNoteText(type: string) {
  switch (type) {
    case FILTERS_OPTIONS.CHOOSE_FROM_EXISTING:
      return "Select shapes on the map to built your filter.";

    case FILTERS_OPTIONS.DRAW_ON_MAP:
      return "Draw an area on the map to built your filter";

    default:
      return undefined;
  }
}
