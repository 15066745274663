import { apiClient } from "../config";

async function verifyEmailOrPhone(emailOrphone: any, personId: any) {
  const res = await apiClient.post(`/admin/verify-email-phone/${personId}`, {
    phone_or_email: emailOrphone,
  });
  return res.data;
}

export default verifyEmailOrPhone;
