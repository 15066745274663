import getAllMarkerGroups from "./getAllMarkerGroups";
import archiveOrRestoreMarkerGroup from "./archiveOrRestoreMarkerGroup";
import addMarkerGroup from "./addMarkerGroup";
import editMarkerGroup from "./editMarkerGroup";
import getMarkerGroupsDropdown from "./getMarkerGroupsDropdown";


import getAllMarkerStyles from "./getAllMarkerStyles";
import archiveOrRestoreMarkerStyle from "./archiveOrRestoreMarkerStyle";
import addMarkerStyle from "./addMarkerStyle";
import editMarkerStyle from "./editMarkerStyle";
import getMarkerStylesDropdown from "./getMarkerStylesDropdown";



const MarkerGroupsAndStyles = {
  getAllMarkerGroups,
  archiveOrRestoreMarkerGroup,
  addMarkerGroup,
  editMarkerGroup,
  getMarkerGroupsDropdown,

  getAllMarkerStyles,
  archiveOrRestoreMarkerStyle,
  addMarkerStyle,
  editMarkerStyle,
  getMarkerStylesDropdown,
};

export default MarkerGroupsAndStyles;
