import React from "react";
// Components
import {
  Date,
  Search,
  SelectedPrograms,
  SelectedCommunities,
  SelectedForms,
  SelectedCreatedBy,
  SelectedOrganizations,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { reportsResetFiltersAction } from "store/filters/reducer.actions";
import {
  filtersInitialState,
  reportsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting reportsFilter state from Redux store
  const { reportsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  const clearAllFilters = () => {
    dispatch(reportsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyReportsFilterApplied(reportsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Search />
          <SelectedPrograms />
          <SelectedCommunities />
          <SelectedOrganizations />
          <SelectedForms />
          <SelectedCreatedBy />
          <Date
            name="addedDate"
            from={reportsFilter.addedDate.from}
            to={reportsFilter.addedDate.to}
            isApplied={reportsFilter.addedDate?.applied}
          />{" "}
          <Date
            name="updatedDate"
            from={reportsFilter.updatedDate.from}
            to={reportsFilter.updatedDate.to}
            isApplied={reportsFilter.updatedDate?.applied}
          />{" "}
          <Date
            name="lastRun"
            from={reportsFilter.lastRun.from}
            to={reportsFilter.lastRun.to}
            isApplied={reportsFilter.lastRun?.applied}
          />
          <Date
            name="nextRun"
            from={reportsFilter.nextRun.from}
            to={reportsFilter.nextRun.to}
            isApplied={reportsFilter.nextRun?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

// Function to check if any filter is applied
export function isAnyReportsFilterApplied(
  reportsFilter: reportsDirectoryFilterState
): boolean {
  const {
    searchBy,
    searchString,
    programIds,
    communityIds,
    organizationIds,
    formIds,
    createdByIds,
    addedDate,
    updatedDate,
    lastRun,
    nextRun,
  } = reportsFilter;
  const applied =
    (searchBy && searchString !== "") ||
    programIds?.applied ||
    communityIds?.applied ||
    organizationIds?.applied ||
    formIds?.applied ||
    createdByIds?.applied ||
    addedDate?.applied ||
    updatedDate?.applied ||
    lastRun?.applied ||
    nextRun?.applied;
  return applied;
}
