import React, { useEffect } from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { joinRequestStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { joinRequestDirectoryChangeColumnOrderAction } from "store/directoriesOrders/reducer.actions";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({}) => {
  const { joinRequestFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  const TabsData = ["requested", "declined"];
  useEffect(() => {
    if (joinRequestFilter.status) {
      dispatch(
        joinRequestDirectoryChangeColumnOrderAction(joinRequestFilter.status)
      );
    }
  }, [joinRequestFilter.status]);

  //handlers
  const handleLink = (linkName: string) => {
    dispatch(joinRequestStatusAction(linkName));
  };

  return (
    <>
      {TabsData.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${
              joinRequestFilter.status === item
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
            }`}
            onClick={() => handleLink(item)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${
                  joinRequestFilter.status === item
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                }`}
              >
                {item}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
