import React, { useEffect, useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import { DropdownMenu } from "view/components/DropdownMenu";
import { Form, Formik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";
import TextInputField from "view/components/InputField";
import { DropdownMenuRoles } from "view/components/DropdownMenuRoles";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import {
  fetchAdditionalInformationAction,
  fetchProfileRolesAction,
} from "store/profileRoles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileRoles } from "store/profileRoles/initialState";
import ProfileRolesListing from "./ProfileRolesListing";
import ProfileRolesAdditionalInformation from "./ProfileRolesAdditionalInformation";
import { useParams } from "react-router-dom";

interface RolesProps {}

export const Roles: React.FC<RolesProps> = ({}) => {
  const { personId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [isDivActive, setIsDivActive] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [RolesDropdown, setRolesDropdown] = useState(false);
  const { data } = useSelector<RootState, IProfileRoles>(
    (state) => state?.profileRoles
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchProfileRolesAction(personId));
    dispatch(fetchAdditionalInformationAction(personId));
  }, []);

  const validationSchema = Yup.object().shape({
    communityName: Yup.string().required("Community Name is required"),
    roles: Yup.string().required("Roles are required"),
  });
  const initialValues = {
    communityName: "",
    role: "",
  };
  const handleSubmit = (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    // Handle form submission
    setSubmitting(false);
  };

  const setFormFieldsValue = () => {
    setEditMode(true);
    setIsDivActive(!isDivActive);
  };

  return (
    <>
      <div className="flex flex-col justify-start items-start flex-grow overflow-hidden border-t-0 border-r-0 border-b-0 border-l border-lineMid dark:bg-secondaryLight dark:border-l-lineLight">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <div className="w-full dark:bg-secondaryLight">
              <Form>
                <div className="flex flex-col justify-start items-start w-full bg-white">
                  <div className="flex flex-col justify-center items-start xl:pl-12 pl-8 pr-8 w-full">
                    <p className=" text-lg font-semibold text-left text-secondaryMid pt-6 pb-4 dark:text-textMain">
                      Roles
                    </p>
                  </div>
                  <div className="h-[45vh] overflow-y-auto w-full">
                    <ProfileRolesListing />
                  </div>
                </div>
                <div className="dark:border-lineLight border-b border-lineMid mt-6"></div>
                <div>
                  <ProfileRolesAdditionalInformation />
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
