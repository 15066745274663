import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_QA_NOTIFICATIONS,
  GET_QA_NOTIFICATIONS_STATE,
} from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- QA NOTIFICATIONS
 * @returns
 */
export const fetchQANotifications =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_QA_NOTIFICATIONS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();

      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.datePeriod) {
        delete params.datePeriod;
      }
      const { data } =
        await apiLibrary.Dashboard.widgets.getQANotifications(params);
      dispatch({
        type: GET_QA_NOTIFICATIONS,
        payload: { data },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_QA_NOTIFICATIONS_STATE,
        payload: {
          stateIs: "Error",
        },
      });

      console.log(
        "An error occurred while fetching the QA notifications:",
        error
      );
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
