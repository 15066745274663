import getAllManagers from './getAllManagers';
import removeManagerFromProgram from "./removeManagerFromProgram";
import getManagersDropdown from "./getManagersDropdown";
import addManagersToProgram from "./addManagersToProgram";
import extendsReviewerDate from "./extendsReviewerDate";
import inviteDataEndViewer from "./inviteDataEndViewer";
import reinviteDataEndViewer from "./reinviteDataEndViewer";
import getAllExternalEndDataViewersDropdown from "./getAllExternalEndDataViewersDropdown";
import deleteReviewers from "./deleteReviewers";
import updateManagerRole from "./updateManagerRole";
import updateEndDataViewer from "./updateEndDataViewer";

const Managers = {
    getAllManagers,
    removeManagerFromProgram,
    getManagersDropdown,
    addManagersToProgram,
    extendsReviewerDate,
    inviteDataEndViewer,
    reinviteDataEndViewer,
    getAllExternalEndDataViewersDropdown,
    deleteReviewers,
    updateManagerRole,
    updateEndDataViewer
};

export default Managers;
