import {
  MOVE_TO_NEXT_STEP,
  MOVE_TO_PREVIOUS_STEP,
  RESET_REPORT_STEPPER,
  SET_REPORT_ID,
  MOVE_TO_SPECIFIC_STEP,
  SET_REPORT_NAME,
  SET_REGION_COLUMNS,
} from "./action-types";

export const moveToNextStepAction = () => {
  return {
    type: MOVE_TO_NEXT_STEP,
  };
};

export const moveToPreviousStepAction = () => {
  return {
    type: MOVE_TO_PREVIOUS_STEP,
  };
};

export const setReportIdAction = (reportId: number) => {
  return {
    type: SET_REPORT_ID,
    payload: reportId,
  };
};

export const resetReportStepperAction = () => {
  return {
    type: RESET_REPORT_STEPPER,
  };
};

export const moveToSpecificStepAction = (index: number) => {
  return {
    type: MOVE_TO_SPECIFIC_STEP,
    payload: index,
  };
};


export const setReportNameAction = (reportName: string) => {
  return {
    type: SET_REPORT_NAME,
    payload: reportName,
  };
};


export const setRegionColumnsAction = (regions: any) => {
  return {
    type: SET_REGION_COLUMNS,
    payload: regions,
  };
};


