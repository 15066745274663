export interface Step {
  title: string;
  step: string;
  heading: string;
  isActive: boolean;
  isSubmitted: boolean;
}

export interface Stepper {
  steps: Step[];
}

const initialState: Stepper = {
  steps: [
    {
      title: "Upload File",
      step: "uploadFile",
      heading: "Import from a file",
      isActive: true,
      isSubmitted: false,
    },
    {
      title: "Column Mapping",
      step: "columnMapping",
      heading: "Map Fields",
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Import",
      step: "importInProgress",
      heading: "",
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Done",
      step: "importDone",
      heading: "",
      isActive: false,
      isSubmitted: false,
    },
  ],
};

export default initialState;
