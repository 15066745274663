import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import { Header, Tabs, Pagination, Table } from "Components/Behaviors";
// Hooks
import { BehaviorsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchBehaviors } from "store/behaviors/initialState";
import { fetchBehaviorsAction } from "store/behaviors";
import { Filters } from "Components/Behaviors/Filters";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { behaviorsResetFiltersAction, setBehaviorsFiltersAction } from "store/filters/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Behaviors = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();


  useLayoutEffect(() => {
    dispatch(setBehaviorsFiltersAction());
  }, []);
  
  const { data, stateIs } = useSelector<RootState, IfetchBehaviors>(
    (state) => state?.allBehaviors
  );
  const { behaviorsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setSorting,
    sorting,
  } = BehaviorsTable(data?.behaviors);

  useEffect(() => {
    dispatch(fetchBehaviorsAction());
    saveFiltersDataToLocalStorage(behaviorsFilter);
  }, [behaviorsFilter]);
  
  // useEffect(() => {
  //   return () => {
  //     dispatch(behaviorsResetFiltersAction());
  //   };
  // }, []);
  
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-[95.5%] w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columns={columnsConfig}
                columnVisibility={columnVisibility}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs === "Idle" && data?.behaviors.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
