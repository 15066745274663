import { apiClient } from "../config";

async function reinviteDataEndViewer(programId: any, data: any) {
  const personData = {
    endDataViewerId: data
  };
  const response = await apiClient.post(
    `/admin/programs/${programId}/reinvite-external-end-data-viewer`,
    personData
  );

  return response.data;
}

export default reinviteDataEndViewer;
