import {
  GET_DATA_ACCESS_POLICY,
  RESET_DATA_ACCESS_POLICY,
} from "./action-types";

import { initialState } from "./initialState";

export const dataAccessPolicyReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case GET_DATA_ACCESS_POLICY:
      return {
        ...action.payload
      };
    case RESET_DATA_ACCESS_POLICY:
      return initialState;
    default:
      return state;
  }
};
