import { apiClient } from "../config";

async function getProgramAdmins(searchString?: string, page?: number, limit?: number) {

    const queryParams = {
        searchString,
        page,
        limit
    }
    
    const res = await apiClient.get(`/admin/imports/program-admins`, {
        params: queryParams,
    });
    return res.data;
}

export default getProgramAdmins;
