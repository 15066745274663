import { HeaderBarProps } from "../../types";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import TextInputField from "view/components/InputField";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { tagsSearchAction } from "store/filters";
import { filtersInitialState } from "store/filters/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";

const HeaderBar = ({ data, isCloseDiv }: HeaderBarProps) => {
  const [showInput, setShowInput] = useState(false);


  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { tagsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  ); useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        tagsFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [tagsFilter.searchString]);
  // useEffect(() => {
  //   return () => {
  //     dispatch(tagsSearchAction(""));
  //   };
  // }, []);
  useEffect(() => {
    if (showInput) {
      const inputField = document.querySelector(
        ".getInput"
      ) as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showInput]);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };

  useLayoutEffect(() => {
    if (tagsFilter.searchString !== "") {
      setShowInput(true)
    }

  }, [tagsFilter.searchString])

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3 pr-5">
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-caption ">
            {data.totalItems} Items
          </p>
        </div>
      </div>

      <div className="flex items-center justify-end flex-grow h-8 gap-2">
        <div className="flex items-center justify-end flex-grow h-8 gap-2">
          <div className="flex border-2 border-t-0 border-b border-l-0 border-r-0 border-lineMid py-[12px] relative">
            <div onClick={toggleInputVisibility} className="cursor-pointer">
              <SearchIcon
                className={` ${showInput ? "mt-[6px]" : "mt-[-12px]"
                  } absolute `}
              />
            </div>

            <div
              className={`${showInput ? "block" : "hidden"
                } ml-7 border-2 border-t-0 border-l-0 border-r-0 border-b-primaryMid  pb-2`}
              ref={inputRef}
            >
              <TextInputField
                value={tagsFilter.searchString}
                className="bg-bgBluish w-[250px] getInput mt-1 dark:bg-[transparent] dark:text-inputText"
                placeholder="Search"
                type="search"
                fieldAs="searchData"
                onChange={(e: any) =>
                  dispatch(tagsSearchAction(e.target.value))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
