import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Styling
import "./Communities.scss";
// types
import { TableProps } from "./types";

export const KEYS_TO_BE_REMOVED = ["id"];

export const Table: React.FC<TableProps> = ({
  table,
  columnVisibility,
  flexRender,
  sorting,
}) => {
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort();
  return (
    <div className="w-full">
      <table className="w-full overflow-auto">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        />
      </table>
    </div>
  );
};
