import { useState } from "react";
import usePermissions from "./usePermissions";
import { useParams } from "react-router-dom";
// import initialState, { Stepper, Step } from './initialState';
export interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
  hasAccess: boolean;
  needsReview: boolean;
  isUpdated: boolean;
}

export interface Stepper {
  steps: Step[];
  currentReportId: any;
  reportName: string;
}

export const reportStepNames = {
  reportName: "reportName",
  permissions: "permissions",
  reportSettings: "reportSettings",
  exporting: "exporting",
  geospatialFiltering: "geospatialFiltering",
  filtering: "filtering",
  columnsConfiguration: "columnsConfiguration",
};

export const useReportsStepper = () => {
  const { reportsId } = useParams();

  const initialState: Stepper = useSteps();

  const [stepper, setStepper] = useState<Stepper>(initialState);

  const stepsImpact = {
    reportName: [
      "permissions",
      "geospatialFiltering",
      "columnsConfiguration",
      "filtering",
    ],
    permissions: [],
    reportSettings: ["filtering"],
    exporting: [],
    geospatialFiltering: ["filtering"],
    columnsConfiguration: ["filtering"],
    filtering: [],
  };

  const stepSequence = [
    "reportName",
    "permissions",
    "reportSettings",
    "exporting",
    "geospatialFiltering",
    "columnsConfiguration",
    "filtering",
  ];

  const isStepForward = (targetStep: any, currentStep: string) => {
    if (targetStep.needsReview && !targetStep.isSubmitted) {
      return true;
    }
    const currentIndex = stepSequence.indexOf(currentStep);
    const targetIndex = stepSequence.indexOf(targetStep.step);
    return targetIndex > currentIndex;
  };

  type CurrentStep = keyof typeof stepsImpact;

  function checkIfReviewNeeded(
    updatedStep: Step,
    currentStep: Step,
    affectedSteps: any[]
  ) {
    if (updatedStep.needsReview) {
      return isStepForward(updatedStep, currentStep.step);
    } else if (currentStep.isUpdated) {
      if (updatedStep.needsReview) {
        return isStepForward(updatedStep, currentStep.step);
      } else {
        return affectedSteps.includes(updatedStep.step);
      }
    } else {
      return false;
    }
  }

  const moveToNextStep = (currentStep: CurrentStep) => {
    setStepper((prev) => {
      const currentIndex = prev.steps.findIndex(
        (step) => step.step === currentStep
      );
      const currentFullStep: any = prev.steps.find(
        (step) => step.step === currentStep
      );
      const nextIndex =
        currentIndex < prev.steps.length - 1 ? currentIndex + 1 : currentIndex;
      const affectedSteps: any = stepsImpact[currentStep] || [];
      return {
        ...prev,
        steps: prev.steps.map((step, index) => {
          const updatedStep = {
            ...step,
            isSubmitted: step.isSubmitted
              ? step.isSubmitted
              : step.step === currentStep,
          };

          const needsReview = checkIfReviewNeeded(
            updatedStep,
            currentFullStep,
            affectedSteps
          );

          return {
            ...updatedStep,
            isActive: index === nextIndex,
            needsReview: reportsId ? needsReview : false,
          };
        }),
      };
    });
  };

  const moveToPreviousStep = () => {
    setStepper((prev) => {
      const currentIndex = prev.steps.findIndex((step) => step.isActive);
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;

      return {
        ...prev,
        steps: prev.steps.map((step, index) => ({
          ...step,
          isActive: index === prevIndex,
        })),
      };
    });
  };

  const moveToSpecificStep = (stepName: string) => {
    setStepper((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => ({
        ...step,
        isActive: step.step === stepName,
      })),
    }));
  };

  const setReportId = (reportId: string | number) => {
    setStepper((prev) => ({
      ...prev,
      currentReportId: reportId,
    }));
  };

  const setReportName = (name: string) => {
    setStepper((prev) => ({
      ...prev,
      reportName: name,
    }));
  };

  function setAnythingUpdatedThisStep(currentStep: string, isUpdated: boolean) {
    setStepper((prev) => {
      // const currentIndex = prev.steps.findIndex(
      //   (step) => step.step === currentStep
      // );
      // const nextIndex =
      //   currentIndex < prev.steps.length - 1 ? currentIndex + 1 : currentIndex;
      // const affectedSteps: any = stepsImpact[currentStep] || [];
      return {
        ...prev,
        steps: prev.steps.map((step, index) => {
          // const updatedStep = {
          //   ...step,
          //   isSubmitted: step.isSubmitted
          //     ? step.isSubmitted
          //     : step.step === currentStep,
          // };
          // const needsReview = updatedStep.needsReview
          //   ? isStepForward(updatedStep, currentStep)
          //   : affectedSteps.includes(updatedStep.step);

          return {
            ...step,
            isUpdated: step.step === currentStep ? isUpdated : step.isUpdated,
          };
        }),
      };
    });
  }
  const resetStepper = () => {
    setStepper(initialState);
  };

  return {
    stepper,
    moveToNextStep,
    moveToPreviousStep,
    moveToSpecificStep,
    setReportId,
    setReportName,
    resetStepper,
    setAnythingUpdatedThisStep,
  };
};

export default useReportsStepper;

function useSteps() {
  const { reports } = usePermissions();
  const [stepper, setStepper] = useState(() => {
    const steps = [
      {
        title: "Name",
        step: reportStepNames.reportName,
        isActive: true,
        isSubmitted: false,
        hasAccess: reports.canCreateReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Permissions",
        step: reportStepNames.permissions,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewAdminsReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Report Settings",
        step: reportStepNames.reportSettings,
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: reports.canCreateSettingReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Exporting",
        step: reportStepNames.exporting,
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: reports.canEditExportPermissionReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Geospatial query building",
        step: reportStepNames.geospatialFiltering,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewGeoSpatialColumnsReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Columns Configuration",
        step: reportStepNames.columnsConfiguration,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewColumnsReports,
        needsReview: false,
        isUpdated: false,
      },
      {
        title: "Filtering",
        step: reportStepNames.filtering,
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: reports.canCreateFilteringReports,
        needsReview: false,
        isUpdated: false,
      },
    ];

    const accessedSteps = steps.filter((step) => step.hasAccess);

    return {
      steps: accessedSteps,
      currentReportId: null,
      reportName: "",
    };
  });

  return stepper;
}
