import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  __changeColorOfMarker__,
  __changeColorOfZoomedShapeLayer__,
  __deleteAllLocalDrawShapes__,
  __resetColorOfSelectedLayersItems__,
  __resetColorOfSelectedMarkers__,
} from "./workerMehods";
import { useInitializeMap } from "./hooks";
import { FILTERS_OPTIONS } from "../../../../";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { ISummaryReports } from "store/reportsSummary";
import { usePaintAllLayersOnMap } from "./hooks/usePaintAllLayersOnMap";
import { useHandleClickOnLayer } from "./hooks/useHandleClickOnLayer";
import { useCustomizeColorOfHigilightedLayer } from "./hooks/useCustomizeColorOfHigilightedLayer";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR = "#FFA500";

export const SHAPES = {
  SQUARE: "Square",
  CIRCLE: "Circle",
  POLYGON: "Polygon",
  LINE: "Line",
};

export function Map({
  selectedOption,
  selectedGeographies = [],
  updateSelectedShapeRegions,
  reportId,
  reportSummary,
}: {
  selectedOption: any;
  selectedGeographies: any[];
  updateSelectedShapeRegions: (regions: any[]) => void;
  reportId?: any;
  reportSummary: any;
}) {
  const [reportGeographies, setReportGeographies] = useState<any>([]);
  const mapContainer = useRef<any>(null);

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [zoom, setZoom] = useState(3.6);
  const [selectedDrawShape, setSelectedDrawShape] = useState(SHAPES.SQUARE);

  // initialize the map on first rendering
  const { map, drawRef, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
    lat,
    lng,
    zoom,
    setZoom
  );

  useEffect(() => {
    geoGraphies(parseInt(reportId));
  }, [reportSummary]);

  const geoGraphies = async (reportId: number) => {
    const communityIds = extractIdsFromProgramsOrganizationsFormAndCommunities(
      reportSummary.communitiesFilter
    );
    const formIds = extractIdsFromProgramsOrganizationsFormAndCommunities(
      reportSummary.formsFilter
    );
    const organizationIds =
      extractIdsFromProgramsOrganizationsFormAndCommunities(
        reportSummary.organizationsFilter
      );
    const programIds = extractIdsFromProgramsOrganizationsFormAndCommunities(
      reportSummary.programsFilter
    );

    try {
  
      const allGeographies = await getAllReportsGeographies(
        communityIds,
        formIds,
        organizationIds,
        programIds
      );


      setReportGeographies(allGeographies);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const [clickedLayer, setClickedLayer] = usePaintAllLayersOnMap(
    reportGeographies,
    selectedGeographies,
    map.current,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR
  );

  // handle click on layer
  useHandleClickOnLayer(
    clickedLayer,
    setClickedLayer,
    selectedGeographies,
    updateSelectedShapeRegions,
    true
  );

  // change the color of highlighted layer
  useCustomizeColorOfHigilightedLayer(
    map.current,
    isMapFullyLoaded,
    selectedGeographies,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR
  );

  return (
    <div className="mt-3">
      <p className="mb-4 text-sm font-Overpass text-textMidLight dark:text-textMain">
        {getNoteText(selectedOption)}
      </p>
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "400px",
        }}
      ></div>
    </div>
  );
}

function getNoteText(type: string) {
  switch (type) {
    case FILTERS_OPTIONS.CHOOSE_FROM_EXISTING:
      return "Select shapes on the map to built your filter.";

    case FILTERS_OPTIONS.DRAW_ON_MAP:
      return "Draw an area on the map to built your filter";

    default:
      return undefined;
  }
}

async function makeApiRequest(paramKey: any, id: any, retries = 5) {
  let attempts = 0;
  while (attempts < retries) {
    try {
      const response =
        await apiLibrary.Reports.getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds(
          681,
          { [paramKey]: id }
        );
      return response.data; // Return response data directly
    } catch (error: any) {
      attempts++;
      if (attempts === retries) {
        console.error(
          `Failed request for ${paramKey} with ID ${id} after ${retries} retries`,
          error.message
        );
        throw error; // Rethrow the error if all retries fail
      }
      console.log(
        `Retrying request for ${paramKey} with ID ${id}: Attempt ${attempts}`
      );
    }
  }
}

async function processEntity(entityKey: any, ids: any[]) {
  const geographies = [];
  for (let id of ids) {
    try {
      const result = await makeApiRequest(entityKey, id);
      if (result.geographies) {
        geographies.push(...result.geographies);
      }
    } catch (error: any) {
      // results.push({
      //   error: `Failed to process ${entityKey} with ID ${id}`,
      //   details: error.message,
      // }); // Optionally store errors
    }
  }
  return geographies; // Return the array of results
}

async function processAllEntities(
  communityIds: any[],
  formIds: any[],
  organizationIds: any[],
  programIds: any[]
) {
  const allGeographies = [];
  allGeographies.push(...(await processEntity("community_ids", communityIds)));
  allGeographies.push(
    ...(await processEntity("organization_ids", organizationIds))
  );
  allGeographies.push(...(await processEntity("program_ids", programIds)));
  allGeographies.push(...(await processEntity("form_Ids", formIds)));
  return allGeographies;
}

async function getAllReportsGeographies(
  communityIds: any[],
  formIds: any[],
  organizationIds: any[],
  programIds: any[]
) {
  try {
    const allResults = await processAllEntities(
      communityIds,
      formIds,
      organizationIds,
      programIds
    );
    const uniqueItems = _.uniqBy(allResults, "id");

    console.log("uniqueItems", uniqueItems);
    return uniqueItems;
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

function extractIdsFromProgramsOrganizationsFormAndCommunities(data: any[]) {
  const values = data?.map((entity: any) => entity.id);
  return values;
}
