import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeImportSummaryAction, closeUndoAndArchiveImportModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchImportsAction } from "store/imports";
import { importsStatusAction } from "store/filters";

const style: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#ffff",
};

interface UndoAndArchiveImportModalProps { }

export const UndoAndArchiveImportModal: React.FC<UndoAndArchiveImportModalProps> = () => {
    const { undoAndArchiveImportModal } = useSelector(
        (state: RootState) => state.modals
    );
    const [isLoading, setIsLoading] = useState(false);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const handleClose = () => {
        dispatch(closeUndoAndArchiveImportModalAction());
    };

    const changeImportStatus = async () => {
        setIsLoading(true);
        try {
            const res = await apiLibrary.Imports.changeImportStatus(
                undoAndArchiveImportModal?.data?.id
            );
            Toasts.success(res.message);
            dispatch(importsStatusAction("archived"))
            handleClose();
            dispatch(closeImportSummaryAction());
            dispatch(fetchImportsAction());
        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            open={undoAndArchiveImportModal?.isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            disableAutoFocus={true}
            aria-describedby="modal-modal-description"
            className="border-none"
        >
            <Box sx={style} className="dark:bg-secondaryLight ">
                <div className="flex flex-col justify-start items-start w-[480px]  overflow-y-auto rounded-lg">
                    <div
                        className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                        style={{
                            boxShadow:
                                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                        }}
                    >
                        <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                                    Undo and Archive Import
                                </p>
                            </div>
                            <button title="close" onClick={handleClose} type="button">
                                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                            </button>
                        </div>
                        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                            <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                                Are you sure you want to undo and archive this import? Any successfully imported observations will be deleted. This cannot be undone, although at any point in time you can decide to import the observations again as long as you have the original file available to you.
                            </p>
                        </div>

                        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                            <Button
                                type="button"
                                text="Cancel"
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="px-5 py-2"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="outlined"
                                onClick={handleClose}
                            />

                            <Button
                                disabled={isLoading}
                                type="button"
                                text={
                                    "Confirm"
                                }
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="px-5 py-2"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="filled"
                                onClick={changeImportStatus}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
