import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import {
  ERROR_LOADING_POLL_AND_SURVEY_DETAILS,
  GET_POLL_AND_SURVEY_DETAILS,
  RESET_DATA,
  START_LOADING_POLL_AND_SURVEY_DETAILS,
  STOP_LOADING_POLL_AND_SURVEY_DETAILS,
} from "./action-types";
import initialState from "./initialState";
export const pollAndSurveyResultsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case START_LOADING_POLL_AND_SURVEY_DETAILS:
      return {
        ...state,
        stateIs: "Pending",
      };
    case ERROR_LOADING_POLL_AND_SURVEY_DETAILS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_POLL_AND_SURVEY_DETAILS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_POLL_AND_SURVEY_DETAILS:
      const data = action.payload.pop();
      const updatedResponses = data.responses.map((item: any) => {
        if (item.hasOwnProperty("responseDateTime")) {
          const newitem = { ...item };
          newitem.responseDateTime = moment(item.responseDateTime).format(
            "DD MMM YYYY h:mm A"
          );
          return newitem;
        } else {
          return item;
        }
      });
      return {
        ...state,
        data: {
          ...data,
          questions: data?.questions,
          responses: updatedResponses,
          totalItems: data?.total,
          totalPages: data?.totalPages,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
