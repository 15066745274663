import { apiClient } from "../config";

async function removeSpeciesOrObjectFromCommunity(
  communityId: any,
  entity: any,
  id: any
) {
  const res = await apiClient.delete(
    `/admin/communities/${communityId}/entity`,
    {
      params: {
        entityType: entity,
        entityId: id,
      },
    }
  );
  return res.data;
}

export default removeSpeciesOrObjectFromCommunity;
