import { Serializer } from "survey-core";
import {panelsTitle,panelsName} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { RIVER_CONDITION_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";
import { RIVER_CONDITION_QUESTIONS_NAME } from "../panelsQuestionsName";


export default (panelsJson:any)=>{ return {
  name: panelsName.RIVER_CONDITION,
  title: panelsTitle.RIVER_CONDITION,
  iconName: "icon-river",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.RIVER_CONDITION}</h1>`,
    },
    {
      type: "dropdown",
      name: RIVER_CONDITION_QUESTIONS_NAME.RiverLevel,
      title: "River Level",
      choices: RIVER_CONDITION_QUESTIONS_CHOICES.RiverLevel,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: RIVER_CONDITION_QUESTIONS_NAME.WaterClarity,
      title: "Water Clarity",
      choices: RIVER_CONDITION_QUESTIONS_CHOICES.WaterClarity,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: RIVER_CONDITION_QUESTIONS_NAME.StreamTemperature,
      title: "Stream Temperature,Celsius",
      placeholder: 5,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: RIVER_CONDITION_QUESTIONS_NAME.TemperatureType,
      title: "Celsius",
      placeholder: 5,
      startWithNewLine: false,
      titleLocation: "hidden",
      choices: RIVER_CONDITION_QUESTIONS_CHOICES.TemperatureType,
      isRequired: true,
    },
  ],
  ...panelSettings(panelsName.RIVER_CONDITION, RIVER_CONDITION_QUESTIONS_NAME, Serializer)
}};

export const riverIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<g clip-path="url(#clip0_5591_26441)">
<path d="M3 7C6 5 9 5 12 7C15 9 18 9 21 7" stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 17C6 15 9 15 12 17C15 19 18 19 21 17" stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 12C6 10 9 10 12 12C15 14 18 14 21 12" stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5591_26441">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`;
