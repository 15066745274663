import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Button from "view/components/Button";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeDeleteAccountRequestModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: '10px',
  backgroundColor: "#ffff",    
};


export const RequestDeletionModal = () => {
  const { deleteAccountRequestModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeDeleteAccountRequestModalAction());
  };
  const requestAccountDeletion = async () => {
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const res = await apiLibrary.userProfile.deleteAccountRequest();
      Toasts.success(res.message);
      handleClose();
      // navigate(`/dashoard/users/list`);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message
      Toasts.error(errorMsg)
    } finally {
      setIsLoading(false)
      dispatch(setLoadingProgress(100));
    }
  };
  return (
    <Modal
      open={deleteAccountRequestModal.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex items-center justify-center w-full ">
          <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                    Request Deletion
                  </p>
                </div>
                <button onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                <p className="flex-grow w-[432px] text-sm text-left text-secondaryMid">
                  You can Request Deletion of your account by clicking the
                  button below. This will send a request to the Super Admin, who
                  will then contact you about further steps for your account
                  deletion.
                </p>
              </div>
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={isLoading}
                  type="button"
                  text="Request"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={requestAccountDeletion}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
