import {
  ERROR_LOADING_ROLES_PERMISSIONS,
  GET_ALL_ROLES,
  RESET_DATA,
  START_LOADING_ROLES_PERMISSIONS,
  STOP_LOADING_ROLES_PERMISSIONS,
} from "./action-types";
import initialState, { IRoles } from "./initialState";
export const rolesPermissionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_ROLES_PERMISSIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_ROLES_PERMISSIONS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_ROLES_PERMISSIONS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_ROLES:
      const roles = action.payload?.roles?.map((item: IRoles) => {
        const { description, id, editable, roleName, type, users } = item;
        const data = {
          description,
          id,
          editable,
          roleName: {
            roleName,
            id,
          },
          type,
          users,
        };
        return data;
      });

      return {
        ...state,
        data: {
          roles: roles,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
