import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_CONFIGURATION,
  GET_CONFIGURATION_STATE,
  RESET_DATA,
  SAVE_CONFIGURATION_STATE,
} from "../action-types";
import { IDashboardConfiguration } from "./initialState";

/**
 * FETCH DASHBOARD CONFIGURATIONS -- GET
 * @returns
 */
export const fetchDashboardConfiguration = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_CONFIGURATION_STATE,
    payload: {
      stateIs: "Pending",
    },
  });
  try {
    let response = await apiLibrary.Dashboard.configuration.getConfiguration();
    response = {
      ...response,
      data:
        response.data?.map((item: IDashboardConfiguration) => {
          const { index, widgetName } = item;
          return {
            _id: widgetName,
            index,
            widgetName,
          };
        }) || [],
    };

    dispatch({
      type: GET_CONFIGURATION,
      payload: response,
    });
    return response;
  } catch (error: any) {
    const errorMsg = error?.response?.data?.message ?? error?.message;
    Toasts.error(errorMsg);
    dispatch({
      type: GET_CONFIGURATION_STATE,
      payload: {
        stateIs: "Error",
      },
    });
    console.log(
      "An error occurred while fetching the dashboard configuration:",
      error
    );
    return false;
    // dispatch(fetchError(error))
  } finally {
    // dispatch(loadingState(false))
  }
};

/**
 *  DASHBOARD CONFIGURATIONS -- SAVE
 * @returns
 */
export const saveDashboardConfiguration =
  (requestPayload: IDashboardConfiguration[]) => async (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_CONFIGURATION_STATE,
      payload: {
        postStateIs: "Pending",
      },
    });
    try {
      const response =
        await apiLibrary.Dashboard.configuration.saveConfiguration(
          requestPayload
        );
      dispatch({
        type: SAVE_CONFIGURATION_STATE,
        payload: {
          postStateIs: "Idle",
        },
      });
      if (response?.message) Toasts.success(response?.message);

      return response;
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: SAVE_CONFIGURATION_STATE,
        payload: {
          postStateIs: "Error",
        },
      });
      console.log(
        "An error occurred while updating the dashboard configuration:",
        error
      );
      return false;

      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
