import getAllReports from "./getAllReports";
import getAllProfileReports from "./getAllProfileReports";
import getAllAnalyticsReports from "./getAllAnalyticsReports";
import archiveOrRestoreReport from "./archiveOrRestoreReport";
import deleteReportVersion from "./deleteReportVersion";
import deleteReports from "./deleteReports";
import getSummaryReports from "./getSummaryReports";
import AddReportApis from "./AddReportApis";
import getAdminsByReportIdCommunityFormsOrganizationAndProgramIds from "./getAdminsByReportIdCommunityFormsOrganizationAndProgramIds";
import downloadReports from "./downloadReports";
import getResultVersion from "./getResultVersion";
import getReportsAnalyticsFilterDropdown from "./getReportsAnalyticsFilterDropdown";
import createManualReportVersion from "./createManualReportVersion";
import getProcessedReportVersion from "./getProcessedReportVersion";
import getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds from "./getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds";
import getReportsFilteringDropdowns from "./getReportsFilteringDropdowns";
import getAnalyticsDropdown from "./getAnalyticsDropdown";

const Reports = {
  getAllReports,
  getAllProfileReports,
  getAllAnalyticsReports,
  archiveOrRestoreReport,
  deleteReportVersion,
  deleteReports,
  getSummaryReports,
  downloadReports,
  getResultVersion,
  getReportsAnalyticsFilterDropdown,
  AddReportApis,
  getAdminsByReportIdCommunityFormsOrganizationAndProgramIds,
  createManualReportVersion,
  getProcessedReportVersion,
  getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds,
  getReportsFilteringDropdowns,
  getAnalyticsDropdown,
};

export default Reports;
