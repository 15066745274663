import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { formatTheTime } from "utils/formatTheTime";
import { TIME_FORMATS } from "constants/timeFormats";

interface CustomDateTimePickerProps {
  initialValue: string;
  handleChange: (value: any) => void;
  min: string;
  max: string;
  className?: any;
  touched?: any;
  disabled?: any;
  format?: any;
  ampm?: any;
  sx?: any;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
  initialValue,
  handleChange,
  max,
  min,
  className,
  touched,
  disabled,
  format,
  ampm,
  sx,
  ...restProps
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleDateChange = (date: any) => {
    if (date && moment(date).isValid()) {
      const utcDate = moment(date).toISOString();
      const formattedDate = formatTheTime(utcDate, TIME_FORMATS.FULL_DATE_TIME);
      setSelectedDate(date);
      handleChange(formattedDate);
    } else {
      setSelectedDate(null);
      handleChange(null);
    }
  };

  useEffect(() => {
    const date = moment(initialValue, moment.ISO_8601);
    if (date.isValid()) {
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  }, [initialValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        {...restProps}
        value={selectedDate}
        onChange={handleDateChange}
        maxDate={max ? moment(max) : undefined}
        minDate={min ? moment(min) : undefined}
        disabled={disabled}
        className={className}
        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
        format={format}
        sx={sx}
        ampm={ampm}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
