import React, { useEffect, useState } from "react";
/***** Components *****/
import SignInImg from "assets/images/Auth/SignInImg.png";
import Button from "view/components/Button";
import FormInput from "../Components/Inputs/formInput";
import FormHeader from "../Components/FormHeader";
import AuthLayout from "../Components/AuthLayout";
import { Toasts } from "view/components/Toasts";
/***** API services *****/
import apiLibrary from "services/api";
/***** External tools *****/
import { Form, Formik } from "formik";
import * as Yup from "yup";
/***** Store utils *****/
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetAuthDataAction } from "store/auth/reducer.actions";
import { CreateNewPasswordValidationSchema } from "utils/validationSchemas";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import Head from "view/components/Head";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder, getCurrentBranding } from "utils/customBranding";

interface VerifyPagePageImage {
  value: null | string; // Assuming the value can be a string or null
  description: string;
  key: string;
  fileId: number;
  filePath: string;
}

export const CreatePassword = () => {
  // Getting the dispatch function from Redux
  const dispatch = useDispatch();
  // Getting the navigation function from React Router
  const navigate = useNavigate();
  // Get the auth state from the Redux store
  const auth = useSelector((state: RootState) => state.auth);
  const currentBrand = getCurrentBranding();
  const { newPassword } = usePermissions();
  // Use useEffect to handle auto-generation redirection
  useEffect(() => {
    if (!auth.autoGeneratePass) {
      navigate("/auth/signIn");
    }
  }, [auth]);

  const [verificationFormImage, setVerifyPageFormImage] =
    useState<VerifyPagePageImage>({
      value: null,
      description: "",
      key: "",
      fileId: 0,
      filePath: "",
    });

  const [isLoading, setLoading] = useState(false);

  // Defining initial form values
  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  /**
   * Handles the submission of the create password form.
   *
   * @param {Object} values - Form values containing the new password.
   * @param {Object} options - Options object containing setSubmitting function.
   * @param {Function} options.setSubmitting - Function to set the submitting state.
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!newPassword.canCreateNewPassword) {
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      // Calling the API to create a new password
      const res = await apiLibrary.Auth.createNewPassword({
        password: values.password,
      });
      Toasts.success(res.message);
      setSubmitting(false);
      // Dispatching an action to reset auth-related data
      dispatch(resetAuthDataAction());

      navigate("/auth/signIn");
    } catch (error: any) {
      // Handling errors appropriately
      console.error("Error occurred:", error);
      const errorMsg = error.response.data.message ?? error.message;
      Toasts.error(errorMsg);
      setSubmitting(false);
    } finally {
      dispatch(setLoadingProgress(100));
    }
  };

  const getVerifyPageFormImage = async () => {
    setLoading(true);
    try {
      const { data } = await apiLibrary.stystemSettings.getUniversalImages(
        "verification_page_image",
        currentBrand
      );

      setVerifyPageFormImage(data.verificationPageImage);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getVerifyPageFormImage();
  }, []);

  return (
    <div className="cursor-default">
      <AuthLayout backgroundImage={verificationFormImage.filePath}>
        <Head title="Create Password" />
        <Formik
          initialValues={initialValues}
          validationSchema={CreateNewPasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }: any) => (
            <Form className="h-full">
              <div className="w-[370px] xl:w-[509px] px-8 xl:px-12">
                <FormHeader />
                <div className="flex flex-col gap-4">
                  <p className="text-xl font-semibold text-secondaryMid dark:text-textMain">
                    Create A New Password
                  </p>
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4 py-1.5 rounded-lg">
                    <div className="relative flex-grow-0 flex-shrink-0 rounded-full w-9 h-9">
                      <img
                        className="rounded-full"
                        src={
                          auth?.person?.personImage?.file?.thumb
                            ? auth?.person?.personImage?.file?.thumb
                            : getBrandPlaceholder("profile")
                        }
                      />
                    </div>
                    <div className="relative flex flex-col items-start justify-center flex-grow">
                      <p className="self-stretch flex-grow-0 flex-shrink-0  text-[15px] font-semibold text-left text-textMid dark:text-caption ">
                        {`${auth?.person?.firstName} ${auth?.person?.lastName}`}
                      </p>
                    </div>
                  </div>
                  {/* <FormInput
                  disabled={isSubmitting}
                  label="Temporary Password"
                  type="password"
                  name="temporaryPassword"
                  id="temporaryPassword"
                  placeholder="Temporary Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.temporaryPassword}
                  error={errors.temporaryPassword}
                  touched={touched.temporaryPassword}
                />
                */}

                  <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left w-ful dark:text-caption text-textMidLight">
                    Use 8 or more characters.
                  </p>
                  {/* inputForm */}
                  <FormInput
                    disabled={isSubmitting}
                    label="New Password"
                    type="password"
                    name="password"
                    id="EmailOrPhone"
                    placeholder="New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                  />
                  {/* inputForm */}
                  <FormInput
                    disabled={isSubmitting}
                    label="Repeat New Password"
                    type="password"
                    placeholder="Repeat New Password"
                    name="repeatPassword"
                    id="repeatPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repeatPassword}
                    error={errors.repeatPassword}
                    touched={touched.repeatPassword}
                  />
                  {/* Submit Button */}
                  <Button
                    type="submit"
                    text="Create Password"
                    filledColor="primary"
                    textColor="textWhite"
                    className="p-2 px-5"
                    width="35"
                    height="10"
                    fontStyle="font-semibold"
                    variant="filled"
                    disabled={isSubmitting || !newPassword.canCreateNewPassword}
                  />
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pt-3.5">
                    <p className="flex-grow ml-1 text-sm text-left">
                      <span className="flex-grow text-sm text-left text-textLight dark:text-caption ">
                        By registering, you agree to our
                      </span>
                      <span className="flex-grow ml-1 text-sm text-left text-primary dark:text-textLink">
                        Terms of Service{" "}
                      </span>
                      <span className="flex-grow ml-1 text-sm text-left text-textLight dark:text-caption ">
                        and you acknowledge that you have read and understood
                        our{" "}
                      </span>
                      <span className="flex-grow text-sm text-left text-primary dark:text-textLink">
                        Privacy Policy
                      </span>
                      <span className="flex-grow ml-1 text-sm text-left text-textLight dark:text-caption ">
                        .
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </div>
  );
};
