import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  fetchProgramProfileAction,
  resetProgramProfileAction,
} from "store/programProfile/reducer.actions";
import {
  ProgramKpis,
  PrograpProfileMap,
  Profile,
  MissionStatement,
  FilesListingCard,
  CommunitiesListingCard,
  OrganizationsListingCard,
  ProgramTags,
} from "Components/ProgramProfile";
import { useSelector } from "react-redux";
import { RootState } from "store";

import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IProgram } from "store/programProfile/initialState";
import usePermissions from "hooks/usePermissions";
import { ProgramProtocols } from "Components/ProgramProfile/ProgramProtocols";

export const ProgramProfile = () => {
  const { programId } = useParams();
  const { tags } = usePermissions();
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useEffect(() => {
    if (programId) {
      dispatch(fetchProgramProfileAction(programId));
    }
    return () => {
      dispatch(resetProgramProfileAction());
    };
  }, []);

  const dynamicCommunityLinks = [
    { path: `/programs/list`, label: `Programs` },
    {
      path: `/programs/${programProfile.id}/profile`,
      label: programProfile?.name,
    },
  ];

  return (
    <div className="w-full">
      <div className="pt-4 mb-2">
        <DirectoryBreadCrumb links={dynamicCommunityLinks} />
      </div>
      <div className="relative flex flex-col items-start justify-start">
        <div className="w-full pb-6 bg-bgWhite dark:bg-secondaryLight dark:border-l dark:shadow-xl dark:border-lineLight">
          <Profile />
        </div>
        <div className="grid w-full grid-cols-3 gap-4 py-6 ">
          <div className={`lg:col-span-3 xl:col-span-1`}>
            <div className={`${programProfile?.isPublic ? "" : "lg:order-1"}`}>
              <MissionStatement />
              <ProgramKpis />
              <ProgramProtocols />
              <ProgramTags />
            </div>
          </div>
          <div className=" lg:col-span-3 xl:col-span-2 flex flex-col p-0.5 rounded-lg bg-white">
            <div className="grid grid-cols-2 gap-4 mb-[16px]">
              <OrganizationsListingCard />
              <CommunitiesListingCard />
            </div>

            <div className="h-[385px] rounded-lg overflow-hidden">
              {/* <PrograpProfileMap Marklocation={programProfile?.location} /> */}
              <PrograpProfileMap />
            </div>
            <div className="mt-4 or">
              <FilesListingCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
