import sortObjectByOrder from "utils/SortObjectByOrder";
import {
  ERROR_LOADING_DYNAMIC_FORMS,
  GET_ALL_FORMS,
  RESET_DATA,
  START_LOADING_DYNAMIC_FORMS,
  STOP_LOADING_DYNAMIC_FORMS,
} from "./action-types";
import initialState from "./initialState";
import { formsDirectoryOrder } from "utils/directoryColumnsOrders";

export const dynamicFormsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_DYNAMIC_FORMS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_DYNAMIC_FORMS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_DYNAMIC_FORMS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_FORMS:
      const forms = action.payload.dynamicForms.map((item: any) => {
        const {
          id,
          status,
          formName,
          picture,
          dateUpdated,
          dateAdded,
          dateArchived,
          programName,
          createdBy,
          archivedBy,
          protocol,
          description,
          numberOfObservations,
          programStatus,
          allowAnonObs,
        } = item;

        const data = {
          id: id,
          formName: {
            formName,
            id,
            picture,
            status,
            description,
          },
          dateAdded,
          dateUpdated,
          dateArchived,
          programName,
          createdBy,
          archivedBy,
          protocol,
          numberOfObservations,
          programStatus,
          allowAnonObs,
        };

        return data;
      });

      return {
        ...state,
        data: {
          dynamicForms: forms,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          programStatus: action.payload.programStatus,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
