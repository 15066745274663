import { Dispatch } from "redux";
import {
  ERROR_LOADING_OBSERVATIONS,
  GET_ALL_OBSERVATIONS,
  RESET_DATA,
  START_LOADING_OBSERVATIONS,
  STOP_LOADING_OBSERVATIONS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchObservationsAction =
  (communityId?: any, programId?: any, kpiState: any = null) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_OBSERVATIONS });
    try {
      const currentState = getState();

      const params = generateQueryStringForUsers(
        currentState.Filters.observationsFilter
      );

      const query = appendCommunityOrProgramIdInParams(
        communityId,
        programId,
        kpiState,
        params
      );

      const { data } = await apiLibrary.Observations.getAllObservations(query);
      dispatch({
        type: GET_ALL_OBSERVATIONS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({ type: ERROR_LOADING_OBSERVATIONS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_OBSERVATIONS });
      // dispatch(loadingState(false))
    }
  };

interface kpiState {
  redirectFromKPI?: boolean;
  speciesObserved?: boolean;
}

const appendCommunityOrProgramIdInParams = (
  communityId: string | null,
  programId: string | null,
  kpiState: kpiState | null,
  params: Record<string, any>
) => {
  let updatedParams = { ...params };

  if (communityId) {
    updatedParams.communityIds = communityId;

    if (kpiState) {
      if (kpiState.redirectFromKPI) {
        updatedParams.speciesCommunityKpi = communityId;
      } else {
        updatedParams.objectsCommunityKpi = communityId;
      }
    }
  } else if (programId) {
    updatedParams.programIds = programId;
  }

  if (kpiState && kpiState.speciesObserved) {
    updatedParams.specieObserved = true;
  }

  return updatedParams;
};
