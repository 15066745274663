import apiClient from "services/apiClient";

const getAllNotificationsStatus = async (
    id: any,
) => {
  const res = await apiClient.get(
    `/admin/reminders/${id}/status-history`
  );
  return res.data;
};

export default getAllNotificationsStatus;
