import React from "react";
// Components
import {  SelectedGroups } from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { markerStylesResetFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { SelectedCommunities } from "./Components/SelectedCommunities";

const AppliedFilters = () => {
  // Getting markerStylesFilter state from Redux store
  const { markerStylesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  const clearAllFilters = () => {
    dispatch(markerStylesResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyMarkerStylesFilterApplied(markerStylesFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <SelectedCommunities />
          <SelectedGroups />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

// Function to check if any filter is applied
export function isAnyMarkerStylesFilterApplied(markerStylesFilter: any): boolean {
  const { markerGroup, communityIds } = markerStylesFilter;
  const applied = markerGroup?.applied || communityIds?.applied;

  return applied;
}
