import { Dispatch } from "redux";
import {
  GET_ALL_ROLES,
  RESET_DATA,
  START_LOADING_ROLES_PERMISSIONS,
  STOP_LOADING_ROLES_PERMISSIONS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchRolesPermissionsAction =
  () => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_ROLES_PERMISSIONS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.rolesPermissionsFilter
      );

      const { data } =
        await apiLibrary.RolesPermissions.getAllRoles(queryString);
      dispatch({
        type: GET_ALL_ROLES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the roles:", error);
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_ROLES_PERMISSIONS });
      // dispatch(setLoadingProgress(100));
    }
  };
