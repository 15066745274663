import { Dispatch, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import LayersListControl from "../features/LayersListControl";
import DeleteControl from "../features/DeleteControl";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  SimpleSelectMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from "Components/Geography/MapBox/lib";

import MapMarkerIcon from "assets/images/map-marker.png";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export const useInitializeMap = (
  mapContainer: any,
  customControlId: string,
  deleteControlId?: string,
  questionType?: string,
  editMode?: boolean,
  drawMultiple?: boolean,
  enableSnapping?: boolean,
  setDrawFeatures?: Dispatch<any>
) => {
  const [map, setMap] = useState<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);
  const [draw, setDraw] = useState<any>();

  useEffect(() => {
    // if (map || !mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL ?? "",
      center: [-145.6433003, 65.0710178],
      zoom: 2,
      renderWorldCopies: false,
      minZoom: 0.999,
      // @ts-ignore
      projection: {
        name: "mercator",
      },
    });

    // adding full screen control
    initializeMap.addControl(new mapboxgl.FullscreenControl());

    // adding custom control for layers listing
    const customControl = new LayersListControl(customControlId);

    initializeMap.addControl(customControl, "top-right");


    // adding custom delete control
    if (deleteControlId) {
      const customDeleteControl = new DeleteControl(deleteControlId);

      initializeMap.addControl(customDeleteControl, "top-right");
    }

    // adding zooming control
    initializeMap.addControl(
      new mapboxgl.NavigationControl({
        visualizePitch: true,
      }),
      "bottom-right"
    );

    if (questionType === questionTypes.drawPolygon) {
      const drawRef = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        modes: {
          ...MapboxDraw.modes,
          draw_polygon: SnapPolygonMode,
        },
         // styles: SnapModeDrawStyles,
        styles: [
          // ACTIVE (being drawn)
          // line stroke

          {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // polygon fill
          {
            id: "gl-draw-polygon-fill",
            type: "fill",
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
              "fill-color": "#D20C0C",
              "fill-outline-color": "#D20C0C",
              "fill-opacity": 0.2,
            },
          },
          // polygon mid points
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#fbb03b",
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: ["all", ["==", "$type", "Polygon"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 1,
            },
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 5,
              "circle-color": "#FFF",
            },
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 3,
              "circle-color": "#D20C0C",
            },
          },
          {
            id: "gl-draw-line-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF", // Blue color for inactive
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Polygon fill when inactive
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            paint: {
              "fill-color": "#007FFF", // Blue for inactive polygons
              "fill-outline-color": "#007FFF",
              "fill-opacity": 0.2, // Adjust opacity as needed
            },
          },
          // Polygon outline stroke when inactive
          {
            id: "gl-draw-polygon-stroke-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF",
              // "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Vertex points when inactive
          {
            id: "gl-draw-polygon-and-line-vertex-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "active", "true"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#007FFF",
            },
          },
        ],
        userProperties: true,
        //@ts-ignore
        snap: true,
        snapOptions: {
          snapPx: 15, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
        },
        guides: false,
      });

      initializeMap.addControl(drawRef);

      setDraw(drawRef);
    }
    if (questionType === questionTypes.dropPin) {
      const drawRef = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: true,
          trash: true,
        },
        // @ts-ignore
        modes: Object.assign(MapboxDraw.modes, {
          draw_point: SnapPointMode,
          // Include other modes if necessary
        }),
         // styles: SnapModeDrawStyles,
        styles: [
          // ACTIVE (being drawn)
          // line stroke

          {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // polygon fill
          {
            id: "gl-draw-polygon-fill",
            type: "fill",
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
              "fill-color": "#D20C0C",
              "fill-outline-color": "#D20C0C",
              "fill-opacity": 0.2,
            },
          },
          // polygon mid points
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#fbb03b",
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: ["all", ["==", "$type", "Polygon"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 1,
            },
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 5,
              "circle-color": "#FFF",
            },
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 3,
              "circle-color": "#D20C0C",
            },
          },
          {
            id: "gl-draw-line-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF", // Blue color for inactive
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Polygon fill when inactive
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            paint: {
              "fill-color": "#007FFF", // Blue for inactive polygons
              "fill-outline-color": "#007FFF",
              "fill-opacity": 0.2, // Adjust opacity as needed
            },
          },
          // Polygon outline stroke when inactive
          {
            id: "gl-draw-polygon-stroke-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF",
              // "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Vertex points when inactive
          {
            id: "gl-draw-polygon-and-line-vertex-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "active", "true"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#007FFF",
            },
          },
        ],
        userProperties: true,
        //@ts-ignore
        snap: true,
        snapOptions: {
          snapPx: 15, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
        },
        guides: false,
      });

      initializeMap.addControl(drawRef);

      setDraw(drawRef);
    }

    initializeMap.on("load", () => {
      setIsMapFullyLoaded(true);
      initializeMap.loadImage(MapMarkerIcon, (error: any, image: any) => {
        if (error) {
          return console.log(error);
        }
        initializeMap.addImage("CustomMapMarker", image);
      });
    });

    setMap(initializeMap);

    return () => {
      setIsMapFullyLoaded(false);
    };
  }, []);

  const handleMapClick = (event: any) => {
          
    const features = map.queryRenderedFeatures(event.point);

    const allFeatures = draw.getAll();
    
    if(allFeatures.features.length) {
      
      // Check if any of the clicked features are from the Draw control
      allFeatures?.features?.forEach((feature: any) => {              
          if (feature.id && features.find((f: any) => f.properties.id === feature.id)) {
              new mapboxgl.Popup()
                .setLngLat(event.lngLat)
                .setHTML(`<p class="break-all">${feature.properties.name || ""}</p>`)
                .addTo(map);
          }
      });
    }
  }

  useEffect(() => {
    if (map && isMapFullyLoaded && draw && !editMode) {
      map.on("click", handleMapClick);
    }
    return () => {
      if (map) {
        map.off("click", handleMapClick);
      }
    };
  }, [editMode, map, draw, isMapFullyLoaded]);

  useEffect(() => {    
    if (map && isMapFullyLoaded && draw && setDrawFeatures) {
      // adding draw contorl
      if (
        questionType === questionTypes.drawPolygon ||
        questionType === questionTypes.dropPin
      ) {
        map.on("draw.selectionchange", (event: any) => {
          if (event.features.length > 0) {
            const feature = event.features[0];
            if (feature.properties.isEditable === false) {
              draw.changeMode("simple_select");
            }
          }
        });

        map.on("draw.modechange", (event: any) => {
          const selectedFeatures: any = draw.getSelected();
          if (selectedFeatures.length > 0) {
            const feature = selectedFeatures[0];
            if (feature.properties.isEditable === false) {
              if (draw.getMode() !== "simple_select") {
                draw.changeMode("simple_select");
              }
            }
          }
        });

        map.on("draw.create", () => {
          !drawMultiple && checkShapeLimit(draw);

          updateGeoJsonData(draw, setDrawFeatures);
        });

        map.on("draw.delete", () => {
          updateGeoJsonData(draw, setDrawFeatures);
        });

        map.on("draw.update", () => {
          updateGeoJsonData(draw, setDrawFeatures);
        });
      }
    
    }
    // if(map && isMapFullyLoaded){
    //   if(editMode){
    //     map.getCanvas().style.cursor = "grab";
    //   }
    //   else{
    //     map.getCanvas().style.cursor = "zoom-in";
    //   }
    // }
  }, [map, isMapFullyLoaded, editMode, draw]);

  return { map, isMapFullyLoaded, draw };
};

function checkShapeLimit(draw: any) {
  const features = draw
    .getAll()
    .features.filter(
      (f: any) =>
        !f.id.includes("global") &&
        !f.id.includes("VERTICAL_GUIDE") &&
        !f.id.includes("HORIZONTAL_GUIDE")
    );

  if (features.length > 1) {
    // Delete the oldest shape
    const oldestFeatureId = features[0].id;
    draw.delete([oldestFeatureId]);
  }
}
function updateGeoJsonData(draw: any, updateFeatures: Dispatch<any>) {
  updateFeatures(true);
}
