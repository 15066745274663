import { panelsJson, updatePanelsJson } from "..";
import { panelsName } from "../panelsTitle";
import {
  GENERAL_INFO_QUESTIONS_NAME,
  HARVEST_QUESTIONS_NAME,
  SAMPLE_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
} from "../panelsQuestionsName";

import { baseURL } from "config";
import { pascalCase } from "change-case";
import axios from "axios";

export default function panelSettings(
  panelName: string,
  questions: any,
  Serializer: any
) {
  // Helper function to update visibility and required state for all questions
  function updateAllQuestions(
    question: any,
    propertyPrefix: string,
    newValue: boolean
  ) {
    Object.values(questions).forEach((qName: any) => {
      const propName = `${propertyPrefix}${qName}`;
      question[propName] = newValue;
      if (propertyPrefix === "show") {
        changeQuestionVisibility(question, qName, newValue);
      } else if (propertyPrefix === "required") {
        changeQuestionRequiredOrNot(question, qName, newValue);
      }
    });
  }

  function changeQuestionVisibility(
    question: any,
    name: string,
    visible: boolean
  ) {
    const panelQuestion = question.contentPanel.getQuestionByName(name);
    if (panelQuestion) {
      panelQuestion.visible = visible;


      // handling the visibility of type description of the map type questions
      if (panelName === panelsName.GEOGRAPHICAL_INFORMATION) {
        let questionTypeEleName;
        switch (name) {
          case "Region":
            questionTypeEleName = "RegionType";
            break;
          case "View":
            questionTypeEleName = "ViewType";
            break;
          case "VantagePoint":
            questionTypeEleName = "VantagePointType";
            break;
        }
        if (questionTypeEleName) {
          const questionTypeEle =
            question.contentPanel.getQuestionByName(questionTypeEleName);

          if (questionTypeEle) questionTypeEle.visible = visible;
        }
      }
      else if(panelName === panelsName.HARVEST){
        let questionTypeEleName;
        switch(name){
          case HARVEST_QUESTIONS_NAME.RetrievalLocation:
            questionTypeEleName = HARVEST_QUESTIONS_NAME.RetrievalLocation+"Type";
            break;
          case HARVEST_QUESTIONS_NAME.ValuedFeautres:
            questionTypeEleName = HARVEST_QUESTIONS_NAME.ValuedFeautres+"Type";
            break;
          case HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion:
            questionTypeEleName = HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion+"Type"
            break;
          case HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint:
            questionTypeEleName = HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint+"Type";
            break;
        }
        if (questionTypeEleName) {
          const questionTypeEle =
            question.contentPanel.getQuestionByName(questionTypeEleName);

          if (questionTypeEle) questionTypeEle.visible = visible;
        }
      }
    }
    updatePanelJson(question);
  }

  function changeQuestionRequiredOrNot(
    question: any,
    name: string,
    required: boolean
  ) {
    const panelQuestion = question.contentPanel.getQuestionByName(name);
    if (panelQuestion) {
      panelQuestion.isRequired = required;
    }
    updatePanelJson(question);
  }

  function updatePanelJson(question: any) {
    const questionsJSON = question.contentPanel.elementsValue.map((e: any) => ({
      ...e.jsonObj,
      isRequired: e.isRequired,
      visible: e.isVisible,
    }));

    const panelJson = {
      type: "paneldynamic",
      panelType: "static",
      name: panelName,
      templateElements: questionsJSON,
    };

    const panelIndex = panelsJson.findIndex((p: any) => p.name === panelName);
    if (panelIndex >= 0) {
      panelsJson[panelIndex] = panelJson;
    } else {
      panelsJson.push(panelJson);
    }
    updatePanelsJson(panelsJson);
  }

  return {
    onInit() {
      Serializer.addProperty(panelName, {
        name: `showAll:boolean`,
        default: true,
        displayName: "Show All",
        category: "general",
      });

      Object.values(questions).forEach((name: any) => {
        const displayName = name === HARVEST_QUESTIONS_NAME.ValuedFeautres ? "Retrieval Location Point" : name === HARVEST_QUESTIONS_NAME.RetrievalLocation ? "Retrieval Location Region" : camelToSpace(pascalCase(name)) || "";

        Serializer.addProperty(panelName, {
          name: `show${name}:boolean`,
          default: true,
          displayName: `${displayName}`,
          category: "general",
        });
      });

      Serializer.addProperty(panelName, {
        name: `requiredAll:boolean`,
        default: true,
        displayName: "Require All",
        category: "general",
      });

      Object.values(questions).forEach((name: any) => {
        const displayName = name === HARVEST_QUESTIONS_NAME.ValuedFeautres ? "Retrieval Location Point" : name === HARVEST_QUESTIONS_NAME.RetrievalLocation ? "Retrieval Location Region" : camelToSpace(pascalCase(name)) || "";

        Serializer.addProperty(panelName, {
          name: `required${name}:boolean`,
          default: true,
          displayName: `${displayName}`,
          category: "general",
        });
      });

      // Additional properties like _id based on specific panels
      if (
        panelName === panelsName.HARVEST ||
        panelName === panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        Serializer.addProperty(panelName, {
          name: "_id",
          type: "string",
          visible: false, // Usually, IDs are not meant to be changed or visible in UI
        });
      }

      // Hide certain properties from the panel settings
      propertiesToBeHiddeFromPanels().forEach((name) => {
        Serializer.addProperty(panelName, {
          name: name,
          visible: false,
        });
      });
    },

    onLoaded(question: any) {
      Object.values(questions).forEach((name: any) => {
        changeQuestionVisibility(question, name, question[`show${name}`]);
        changeQuestionRequiredOrNot(
          question,
          name,
          question[`required${name}`]
        );
      });
    },

    onPropertyChanged(question: any, propertyName: string) {
      if (propertyName === "showAll") {
        updateAllQuestions(question, "show", question["showAll"]);
      } else if (propertyName === "requiredAll") {
        updateAllQuestions(question, "required", question["requiredAll"]);
      } else {
        const propertyPrefix = propertyName?.substring(
          0,
          // @ts-ignore
          propertyName.indexOf(propertyName.match(/[A-Z]/))
        );
        const questionName = propertyName?.substring(
          // @ts-ignore
          propertyName.indexOf(propertyName.match(/[A-Z]/))
        );
        if (propertyPrefix === "show") {
          changeQuestionVisibility(
            question,
            questionName,
            question[propertyName]
          );
        } else if (propertyPrefix === "required") {
          changeQuestionRequiredOrNot(
            question,
            questionName,
            question[propertyName]
          );
        }
      }
    },
    onCreated(question: any) {
      switch (panelName) {
        case panelsName.SPECIES_DETAIL:
          updateSpeciesPanel(question);
          break;

        case panelsName.SAMPLE:
          updateDropdownChoices(
            question,
            SAMPLE_QUESTIONS_NAME.Sampler,
            updateChoicesByUrl("observers")
          );
          break;

        case panelsName.GENERAL_INFORMATION:
          updateGeneralInfoPanel(question);
          break;
      }

      if(panelName === panelsName.GEOGRAPHICAL_INFORMATION){
        question.title = "Geographical Information"
      }
      else if (panelName === panelsName.HARVEST){
        question.title = "Harvest";
      };

      // question.titleLocation = "hidden"; // Example of hiding the title
      // You can apply more configuration logic specific to the created panel here
    },
  };
}

function updateSpeciesPanel(question: any) {
  const dropdownMappings = [
    {
      name: SPECIES_DETAILS_QUESTIONS_NAME.ObjectName,
      choicesByUrl: updateChoicesByUrl("objects"),
    },
    {
      name: SPECIES_DETAILS_QUESTIONS_NAME.SpecieName,
      choicesByUrl: updateChoicesByUrl("species"),
    },
    {
      name: SPECIES_DETAILS_QUESTIONS_NAME.Category,
      choicesByUrl: updateChoicesByUrl("categories"),
    },
    {
      name: SPECIES_DETAILS_QUESTIONS_NAME.Type,
      choicesByUrl: updateChoicesByUrl("types"),
    },
  ];

  dropdownMappings.forEach((mapping) => {
    updateDropdownChoices(question, mapping.name, mapping.choicesByUrl);
  });
}

function updateGeneralInfoPanel(question: any) {
  const dropdownMappings = [
    {
      name: GENERAL_INFO_QUESTIONS_NAME.DataEntry,
      choicesByUrl: updateChoicesByUrl("observers"),
    },
    {
      name: GENERAL_INFO_QUESTIONS_NAME.Observers,
      choicesByUrl: updateChoicesByUrl("observers"),
    },
    {
      name: GENERAL_INFO_QUESTIONS_NAME.Reported,
      choicesByUrl: updateChoicesByUrl("observers"),
    },
  ];

  dropdownMappings.forEach((mapping) => {
    updateDropdownChoices(question, mapping.name, mapping.choicesByUrl);
  });
}

async function updateDropdownChoices(
  question: any,
  dropdownQuestionName: any,
  newChoices: any
) {
  const dropdownQuestion =
    question.contentPanel.getQuestionByName(dropdownQuestionName);

  if (
    (dropdownQuestion && dropdownQuestion?.getType() === "dropdown") ||
    dropdownQuestion?.getType() === "tagbox"
  ) {
    const response = await axios.get(newChoices.url);
    const updatedChoices = response.data.map((item: any) => ({
      value: item.value,
      text: item.text,
    }));


    dropdownQuestion.jsonObj.choices = updatedChoices;
    dropdownQuestion.choicesByUrl.propertyHash = newChoices;

    const elementIndex = question.customQuestion.json.elementsJSON.findIndex(
      (element: any) => element.name === dropdownQuestionName
    );

    if (elementIndex !== -1) {
      question.customQuestion.json.elementsJSON[elementIndex].choicesByUrl =
        newChoices;
      question.customQuestion.json.elementsJSON[elementIndex].choices =
        updatedChoices;
    }
  }
}

function updateChoicesByUrl(key: string) {
  const programId = localStorage.getItem("formProgramId");

  if (programId) {
    return {
      url: `${baseURL}/common/dropdown/${key}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
      valueName: "value",
      titleName: "text",
    };
  }
  return {
    url: "",
    valueName: "value",
    titleName: "",
  };
}

function propertiesToBeHiddeFromPanels(): string[] {
  return [
    "title",
    "readOnly",
    "name",
    "showCommentArea",
    "visible",
    "closeOnSelect",
    "searchEnabled",
    "allowClear",
    "hideSelectedItems",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "errorLocation",
    "indent",
    "width",
    "minWidth",
    //"colCount",
    "valueName",
    "maxWidth",
    "defaultValue",
    "correctAnswer",
    "useDisplayValuesInDynamicTexts",
    "clearIfInvisible",
    "requiredErrorText",
    "validators",
    "valuePropertyName",
    "questionTitleLocation",
    "questionErrorLocation",
    "questionsOrder",
    "navigationButtonsVisibility",
    "defaultValueExpression",
    "innerIndent",
    "showNumber",
    "showQuestionNumbers",
    "questionStartIndex",
    "defaultPanelValue",
    "keyName",
    "keyDuplicationError",
    "defaultValueFromLastPanel",
    // "description",
    "visible",
    "isRequired",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "resetValueIf",
    "setValueIf",
    "setValueExpression",
  ];
}
function updatePropertyDisplayName(
  Serializer: any,
  panelName: any,
  propertyName: any,
  newDisplayName: any
) {
  // Find the property in the Serializer and update its displayName
  let property = Serializer.findProperty(panelName, propertyName);
  if (property) {
    property.displayName = newDisplayName;

    Serializer.refreshProperties();
  }
}

function camelToSpace(str: string) {
  return str.replace(/([A-Z])/g, " $1").trim();
}
