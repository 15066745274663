import assignRole from "./assignRole";
import editRole from "./editRole";
import getAllRoles from "./getAllRoles";
import deleteRole from "./deleteRole";
import editRoleEditable from "./editRoleEditable";
import createDuplicateRole from "./createDuplicateRole";
import getAllPermissions from "./getAllPermissions";
import editPermission from "./editPermission";

const RolesPermissions = {
  assignRole,
  editRole,
  getAllRoles,
  deleteRole,
  editRoleEditable,
  createDuplicateRole,
  getAllPermissions,
  editPermission,
};
export default RolesPermissions;
