import React, { useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeReportVersionDataModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import NoRecordFound from "view/components/NoRecordFound";
import { Table } from "Components/ReportVersionData";
import { ReportVersionDataTable } from "hooks/useTable";

const style: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#ffff",
};


const parseGroups = (data: string): any => {
    const groups: { group: string, answer: string }[] = [];

    // Trim the entire input string
    data = data.trim();

    // Remove quotation marks at the start and end if they exist
    if (data.startsWith('"') && data.endsWith('"')) {
        data = data.slice(1, -1);
    }

    // Regular expression to match groups and their values
    const regex = /group#\d+:\s*\[[^\]]+\]|group#\d+:\s*[^,]+/g;
    const entries = data.match(regex);

    if (entries) {
        entries.forEach(entry => {
            const [key, value] = entry.split(':').map(part => part.trim());

            let answer: string;

            // Check if the value is an array in string format
            if (value.startsWith('[') && value.endsWith(']')) {
                // Remove the brackets and join the items by comma
                answer = value.slice(1, -1).split(',').map(item => item.trim()).join(', ');
            } else {
                answer = value;
            }

            groups.push({ group: key, answer: answer });
        });

        return groups
    }
    else {
        return data;
    }
};

interface ReportVersionDataModalProps { }

export const ReportVersionDataModal: React.FC<
    ReportVersionDataModalProps
> = () => {

    const { reportVersionDataModal } = useSelector(
        (state: RootState) => state.modals
    );

    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const groupsData = useMemo(() => {
        if (reportVersionDataModal?.data?.group) {
            return parseGroups(reportVersionDataModal?.data.group)
        }
        return []
    }, [reportVersionDataModal?.data])

    const {
        flexRender,
        columnVisibility,
        columnsConfig,
        table,
        setColumnsConfig,
        setColumnVisibility,
        setSorting,
        sorting,
    } = ReportVersionDataTable(groupsData);

    const handleClose = () => {
        dispatch(closeReportVersionDataModalAction());
    };

    return (
        <Modal
            open={reportVersionDataModal?.isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            disableAutoFocus={true}
            aria-describedby="modal-modal-description"
            className="border-none"
        >
            <Box sx={style} className="dark:bg-secondaryLight ">
                <div className="flex flex-col justify-start w-[700px] items-start rounded-lg">
                    <div
                        className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                        style={{
                            boxShadow:
                                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                        }}
                    >
                        <div className="w-full flex justify-end pt-3 px-3">
                            <button onClick={handleClose} type="button">
                                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                            </button>
                        </div>

                        <div className="pb-6 px-6 flex flex-col items-start self-stretch justify-start ">
                            <div className="w-full flex justify-between items-center mt-[-10px] mb-4 ml-2">
                                <p className="text-xl font-semibold text-left text-textMid dark:text-textMain whitespace-nowrap">
                                    Column: {reportVersionDataModal?.data?.question}
                                </p>
                            </div>

                            <div className="flex flex-col items-start self-stretch justify-start overflow-x-hidden overflow-y-auto px-1 min-h-[45vh] max-h-[60vh] w-full dark:bg-secondaryLight bg-bgWhite">
                                {groupsData?.length === 0 ? (
                                    <NoRecordFound />
                                ) : typeof groupsData === "string" ?
                                    <div className="p-2 break-words w-full">
                                        <p>{groupsData}</p>
                                    </div>
                                    :
                                    <Table
                                        flexRender={flexRender}
                                        table={table}
                                        columns={columnsConfig}
                                        columnVisibility={columnVisibility}
                                        setSorting={setSorting}
                                        sorting={sorting}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
