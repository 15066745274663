import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import {
  APPEND_NEW_MESSAGE_IN_CONVERSATION,
  GET_CONTACT_PERSONS,
  GET_RECIPIENTS,
  RESET_CONTACT_PERSONS,
  RESET_RECIPIENTS,
  RESET_UNREAD_COUNTER,
  START_LOADING_RECIPIENTS,
  STOP_LOADING_RECIPIENTS,
  ADD_CONTACT_FROM_ORGANIZATIONS,
} from "./action-types";
import {
  IContactPerson,
  IRecipient,
  initialContactPersonsState,
  initialRecipientsState,
} from "./initialState";

// Recipients Reducer
export const recipientsReducer = (
  state = initialRecipientsState,
  action: any
) => {
  switch (action.type) {
    case START_LOADING_RECIPIENTS:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING_RECIPIENTS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_RECIPIENTS:
      const recipients = action.payload.conversations.map(
        (item: IRecipient) => {
          const {
            archived,
            firstName,
            id,
            lastMessageAt,
            lastMessageText,
            lastMessageType,
            lastName,
            muted,
            personImage,
            unreadCounter,
            userId,
            conversationCreatedAt,
          } = item;

          const data = {
            archived,
            firstName,
            id,
            lastMessageAt,
            lastMessageText,
            lastMessageType,
            lastName,
            muted,
            personImage,
            unreadCounter,
            userId,
            conversationCreatedAt,
          };

          return data;
        }
      );

      // Assuming 'messages' is an array of IMessage
      const sortedRecipients = recipients.sort((a: any, b: any) => {
        const dateA = a.last_message_at || a.conversation_created_at;
        const dateB = b.last_message_at || b.conversation_created_at;

        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });

      return {
        ...state,
        data: {
          recipients: sortedRecipients,
          appsInfo: action.payload.appsInfo || null,
          contacts: action.payload.contacts || null,
          archived: action.payload.archived || null,
        },
        message: "",
      };

    case APPEND_NEW_MESSAGE_IN_CONVERSATION: {
      const { message, conversationId, data, lastMessageAt, fileName, messageType, } = action.payload;

      // Find the conversation and update it
      const updatedRecipients = state.data.recipients.reduce<IRecipient[]>(
        (acc, currentMsg) => {
          if (currentMsg.id === conversationId) {
            acc.unshift({
              ...currentMsg,
              unreadCounter: 0,
              lastMessageText: message,
              lastMessageType: messageType,
              lastMessageAt: lastMessageAt,
              fileName: fileName,
              messageType: messageType,
            });
          } else {
            acc.push(currentMsg);
          }
          return acc;
        },
        []
      );

      // Check if conversationId is not found in any currentMsg.id
      if (!state.data.recipients.some((msg) => msg.id === conversationId)) {
        updatedRecipients.unshift({
          ...data,
        });
      }

      // Construct the new state with the conversation moved to the top
      return {
        ...state,
        data: {
          ...state.data,
          recipients: updatedRecipients,
          contacts: action.payload.contacts || state.data.contacts,
          archived: action.payload.archived || state.data.archived,
        },
      };
    }

    case RESET_UNREAD_COUNTER: {
      const { conversationId } = action.payload;

      // Find the conversation and update it
      const updatedRecipients = state.data.recipients.map((recipient) => {
        if (recipient.id === conversationId) {
          // Reset the unread counter
          recipient.unreadCounter = 0;
        }
        return recipient;
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipients: updatedRecipients,
          contacts: action.payload.contacts || state.data.contacts,
          archived: action.payload.archived || state.data.archived,
        },
      };
    }

    case RESET_RECIPIENTS: {
      return initialRecipientsState;
    }
    default:
      return state;
  }
};

// Contact Persons Reducer
export const contactPersonsReducer = (
  state = initialContactPersonsState,
  action: any
) => {
  switch (action.type) {
    case GET_CONTACT_PERSONS:
      const contactPersons = action?.payload?.contactPersons?.map((item: IContactPerson) => {
        const { id, firstName, lastName, personImage } = item;

        const data = {
          id,
          firstName,
          lastName,
          personImage,
        };

        return data;
      });

      return {
        ...state,
        data: {
          contactPersons,
        },
        message: "",
      };

    case RESET_CONTACT_PERSONS: {
      return initialContactPersonsState;
    }
    default:
      return state;
  }
};
