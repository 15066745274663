import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { identifyFileType } from "utils/IdentifyFileType";
import { Toasts } from "view/components/Toasts";

// File Upload Interface props
interface FileUploadProps {
  values: any;
  acceptedFileTypes: any;
  isSubmitting?: boolean;
  editMode?: boolean;
  errors: any;
  setFieldValue: any;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  values,
  acceptedFileTypes,
  isSubmitting,
  editMode,
  errors,
  setFieldValue,
}) => {
  const FILES_LIMIT = 3;

  // const handleOnDropFiles = useCallback(
  //   (acceptedFiles: any) => {
  //     const shouldStarFirstFile = values?.images?.every(
  //       (file: any) => !file.isStar
  //     );

  //     const newFiles = acceptedFiles.map((file: any, index: any) => ({
  //       isStar: shouldStarFirstFile && index === 0,
  //       file,
  //     }));

  //     if (acceptedFiles.length === FILES_LIMIT) {
  //       setFieldValue("images", newFiles);
  //     } else {
  //       setFieldValue("images", [...values?.images, ...newFiles]);
  //     }
  //   },

  //   [values?.images]
  // );

  // const handleOnDropFiles = useCallback(
  //   (acceptedFiles: any) => {
  //     const shouldStarFirstFile = values?.images?.every((file: any) => !file.isStar);

  //     const newFiles = acceptedFiles.map((file: any, index: any) => ({
  //       isStar: shouldStarFirstFile && index === 0,
  //       file,
  //     }));

  //     let combinedFiles = [...(values?.images || []), ...newFiles];

  //     // Check if the combined array length is greater than 2
  //     if (combinedFiles.length > 2) {
  //       // Iterate over newFiles and replace existing images starting from the end
  //       newFiles.forEach((newFile: any, index: any) => {
  //         if (combinedFiles.length - 2 - index >= 0) {
  //           combinedFiles[combinedFiles.length - 2 - index] = newFile;
  //         } else {
  //           combinedFiles.unshift(newFile); // Add to the beginning if no more images to replace
  //         }
  //       });

  //       // If the resulting array is longer than the limit, trim it to the limit
  //       if (combinedFiles.length > FILES_LIMIT) {
  //         combinedFiles = combinedFiles.slice(0, FILES_LIMIT);
  //       }
  //     }

  //     setFieldValue("images", combinedFiles);
  //   },
  //   [values?.images, setFieldValue]
  // );

  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      const shouldStarFirstFile = values?.images?.every(
        (file: any) => !file.isStar
      );

      const newFiles = acceptedFiles.map((file: any, index: any) => ({
        isStar: shouldStarFirstFile && index === 0,
        file,
      }));

      let combinedFiles = [...(values?.images || [])];

      if (combinedFiles.length < 3) {
        combinedFiles = combinedFiles.concat(newFiles).slice(0, 3);
      } else {
        switch (acceptedFiles.length) {
          case 3:
            // Replace all existing images with the three new files
            combinedFiles = newFiles;
            break;
          case 2:
            // Replace the last two images with the two new files
            combinedFiles.splice(-2, 2, ...newFiles);
            break;
          case 1:
            // Replace the last image with the new file
            combinedFiles[combinedFiles.length - 1] = newFiles[0];
            break;
          default:
            // Additional logic if needed for more than 3 new files
            break;
        }
      }

      setFieldValue("images", combinedFiles);
    },
    [values?.images, setFieldValue]
  );

  const handleDeleteFile = (fileIndex: number) => {
    const updatedFiles = values.images.filter(
      (_: any, index: number) => index !== fileIndex
    );

    // Check if the deleted file is currently starred or if no file is starred
    const isDeletedFileStarred = values.images[fileIndex].isStar;
    const isAnyFileStarred = updatedFiles.some((file: any) => file.isStar);

    // Star the previous image if the deleted file is starred and no other file is starred
    if (isDeletedFileStarred && !isAnyFileStarred && updatedFiles.length > 0) {
      const newStarIndex = Math.max(fileIndex - 1, 0);
      updatedFiles[newStarIndex] = {
        ...updatedFiles[newStarIndex],
        isStar: true,
      };
    }

    setFieldValue("images", updatedFiles);
  };

  const handleUpdateFile = (newFile: any, fileIndex: number) => {
    const updatedFiles = values.images.map((file: any, index: any) =>
      index === fileIndex ? newFile : file
    );

    setFieldValue("images", updatedFiles);
  };

  const handleStarImage = (id: number) => {
    setFieldValue(
      "images",
      values.images.map((fileObj: any, index: number) =>
        index === id
          ? { ...fileObj, isStar: true }
          : { ...fileObj, isStar: false }
      )
    );
  };
  return (
    <div
      className={`w-full flex flex-col justify-start items-start gap-2 ${
        isSubmitting ? "pointer-events-none" : ""
      } `}
    >
      <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
        {({ getRootProps, getInputProps }) => (
          <>
            <div
              className={`w-full flex flex-col justify-center items-center cursor-pointer px-4 py-2 rounded-lg bg-bgWhite border  dark:bg-secondaryLight ${
                errors !== undefined
                  ? "border-accent_1Dark "
                  : "border-lineDark dark:border-lineLight"
              }  border-dashed`}
            >
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="w-full">
                    <div className="ml-[150px]">
                      <CloudUploadIcon />
                    </div>
                    <p
                      className={`${
                        errors !== undefined
                          ? "text-accent_1Dark "
                          : "dark:text-textMain"
                      }`}
                    >
                      Drag and drop your files here or click in this area
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {errors && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {errors}
                </p>
              )}
            </div>
          </>
        )}
      </Dropzone>
      <div className="relative flex flex-col items-start self-stretch justify-start ">
        <p className="self-stretch w-full text-sm text-left text-textMidLight dark:text-textMain ">
          Visit{" "}
          <a
            className="font-medium text-primary"
            href="https://www.iloveimg.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.iloveimg.com
          </a>{" "}
          to crop your image. For the best result on all devices, use a file of{" "}
          <span className="font-medium">10 MB</span> or less.
        </p>
      </div>
      {values?.images?.length > 0 && (
        <div className="flex flex-wrap justify-start w-full ">
          {values?.images?.map((f: any, index: number) => (
            <FileBox
              handleStarImage={handleStarImage}
              img={f}
              editMode={editMode}
              key={index.toString()}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              acceptedFileTypes={acceptedFileTypes}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const FileBox = ({
  editMode,
  img,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
  handleStarImage,
  index,
}: any) => {
  return (
    <div className="flex flex-col justify-start gap-1 w-[32%]  mr-2">
      <div className={`flex  justify-between`}>
        <button
          className="gap-1"
          type="button"
          onClick={() => handleStarImage(index)}
        >
          {img.isStar ? (
            <svg
              width={22}
              height={21}
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.09725 1.61627C9.696 -0.226259 12.3027 -0.226253 12.9014 1.61627L14.4203 6.29018L19.3348 6.29036C21.2721 6.29044 22.0776 8.76954 20.5103 9.90835L16.5345 12.7972L18.053 17.4712C18.6516 19.3138 16.5428 20.8459 14.9754 19.7072L10.9993 16.8187L7.02332 19.7072C5.45591 20.8459 3.34707 19.3138 3.94568 17.4712L5.46416 12.7972L1.48836 9.90835C-0.0789658 8.76953 0.726547 6.29044 2.66391 6.29036L7.57841 6.29018L9.09725 1.61627ZM10.9993 2.23437L9.4805 6.90828C9.21274 7.73227 8.44488 8.29014 7.57849 8.29018L2.66399 8.29036L6.63979 11.1792C7.34071 11.6885 7.634 12.5911 7.3663 13.4151L5.84781 18.0892L9.82383 15.2006C10.5248 14.6914 11.4739 14.6914 12.1749 15.2006L16.1509 18.0892L14.6324 13.4151C14.3647 12.5911 14.658 11.6885 15.3589 11.1792L19.3347 8.29036L14.4202 8.29018C13.5538 8.29014 12.7859 7.73226 12.5182 6.90828L10.9993 2.23437Z"
                fill="#F2AA00"
              />
              <path
                d="M9.4805 6.90828L10.9993 2.23437L12.5182 6.90828C12.7859 7.73226 13.5538 8.29014 14.4202 8.29018L19.3347 8.29036L15.3589 11.1792C14.658 11.6885 14.3647 12.5911 14.6324 13.4151L16.1509 18.0892L12.1749 15.2006C11.4739 14.6914 10.5248 14.6914 9.82383 15.2006L5.84781 18.0892L7.3663 13.4151C7.634 12.5911 7.34071 11.6885 6.63979 11.1792L2.66399 8.29036L7.57849 8.29018C8.44488 8.29014 9.21274 7.73227 9.4805 6.90828Z"
                fill="#F2AA00"
              />
            </svg>
          ) : (
            <svg
              width={22}
              height={21}
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.09725 1.61627C9.696 -0.226259 12.3027 -0.226253 12.9014 1.61627L14.4203 6.29018L19.3348 6.29036C21.2721 6.29044 22.0776 8.76954 20.5103 9.90835L16.5345 12.7972L18.053 17.4712C18.6516 19.3138 16.5428 20.8459 14.9754 19.7072L10.9993 16.8187L7.02332 19.7072C5.45591 20.8459 3.34707 19.3138 3.94568 17.4712L5.46416 12.7972L1.48836 9.90835C-0.0789658 8.76953 0.726547 6.29044 2.66391 6.29036L7.57841 6.29018L9.09725 1.61627ZM10.9993 2.23437L9.4805 6.90828C9.21274 7.73227 8.44488 8.29014 7.57849 8.29018L2.66399 8.29036L6.63979 11.1792C7.34071 11.6885 7.634 12.5911 7.3663 13.4151L5.84781 18.0892L9.82383 15.2006C10.5248 14.6914 11.4739 14.6914 12.1749 15.2006L16.1509 18.0892L14.6324 13.4151C14.3647 12.5911 14.658 11.6885 15.3589 11.1792L19.3347 8.29036L14.4202 8.29018C13.5538 8.29014 12.7859 7.73226 12.5182 6.90828L10.9993 2.23437Z"
                fill="#2C3236"
              />
            </svg>
          )}
        </button>

        <button
          className="gap-1"
          type="button"
          onClick={() => handleDeleteFile(index)}
        >
          <TrashIcon />
        </button>
      </div>
      <div className="gap-1 p-1 border border-dashed rounded-lg bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-full">
          <Dropzone
            onDrop={(acceptedFiles) =>
              handleUpdateFile(
                { file: acceptedFiles[0], isStar: img.isStar },
                index
              )
            }
            multiple={false}
            accept={acceptedFileTypes}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone cursor-pointer" })}>
                <input {...getInputProps()} />
                <div className="absolute items-center rounded-full right-1 bg-bgWhite w-7 h-7 dark:bg-secondaryLight">
                  <CameraIcon className="ml-0.5 mt-0.5 justify-start items-center w-6 h-6" />
                </div>
              </div>
            )}
          </Dropzone>
          <div className="w-full border">
            <img
              src={img?.file?.path ? URL.createObjectURL(img.file) : img?.file}
              alt=""
              className={`w-full h-[100px] mx-auto  object-cover
              `}
            />
          </div>
          <div className="relative w-full" style={{ wordBreak: "break-word" }}>
            <p className="w-full text-center break-words dark:text-textMain ">
              {img.name ? img.name : img.file?.path}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
