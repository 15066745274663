import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";

interface PrimaryContactsProps {
  editMode: boolean;
}

const PrimaryContactDetails: React.FC<PrimaryContactsProps> = ({
  editMode,
}) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  return (
    <motion.div
      animate={{
        translateY: editMode ? 50 : 0,
      }}
      className={`grid ${
        editMode
          ? "xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1"
          : "xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1"
      } gap-4 w-full`}
    >
      <Contact title="Primary Email" contact={userProfile.user?.email} />
      <Contact title="Primary Phone" contact={userProfile.user?.phoneNumber} />
    </motion.div>
  );
};

export { PrimaryContactDetails };
const Contact = ({
  title,
  contact,
}: {
  title: string;
  contact: string | null;
}) => {
  return (
    <div className="flex flex-col flex-grow w-full">
      <div className="flex flex-col relative gap-0.5 py-2 w-full">
        <p className="text-sm text-left capitalize text-textMidLight dark:text-textMain">
          {title}
        </p>
        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
          <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left text-secondary dark:text-inputText">
            {contact}
          </p>
        </div>
      </div>
    </div>
  );
};
