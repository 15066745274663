import apiClient from "services/apiClient";

async function createDataAccessPolicy(data: any, id: any) {
  const url = `/admin/programs/${id}/data-access-policy`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default createDataAccessPolicy;
