import { GET_SPECIE_PROFILE, RESET_SPECIE_PROFILE } from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchSpecieProfileAction =
  (specieId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.SpeciesAndObjects.getSpecieProfile(specieId);

      dispatch({
        type: GET_SPECIE_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/species/list";
    }
  };
export const resetSpeciesProfileAction = () => {
  return {
    type: RESET_SPECIE_PROFILE,
  };
};
