import React, { useState, useEffect } from "react";
import ReactApexCharts from "react-apexcharts";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

export interface BarGraphProps {
  graphData: any;
  isDateFilter: boolean;
}
export interface GraphDataItem {
  date: string;
  downloads: number;
  views: number;
}

const generateDateRange = (data: { date: string, downloads: number, views: number }[]) => {
  // Extract dates from the data and sort them
  const sortedDates = data
    .map(item => item.date)
    .sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());

  const dateRange: string[] = [...sortedDates];

  // Add dates around the existing ones to make the total number at least 8
  const start = moment(sortedDates[0]); // First date
  const end = moment(sortedDates[sortedDates.length - 1]); // Last date

  // Ensure there are at least 8 dates
  while (dateRange.length < 8) {
    if (dateRange.length < 8) {
      // Add date before the first date
      start.subtract(1, 'days');
      dateRange.unshift(start.format('YYYY-MM-DD'));
    }

    if (dateRange.length < 8) {
      // Add date after the last date
      end.add(1, 'days');
      dateRange.push(end.format('YYYY-MM-DD'));
    }
  }

  // Create a map of views and downloads based on the provided data
  const dataMap: Record<string, { views: number, downloads: number }> = {};

  data.forEach(item => {
    dataMap[item.date] = { views: item.views || 0, downloads: item.downloads || 0 };
  });

  // For each date in the range, if there's no data, set views and downloads to 0
  const views: number[] = [];
  const downloads: number[] = [];

  dateRange.forEach(date => {
    if (dataMap[date]) {
      views.push(dataMap[date].views);
      downloads.push(dataMap[date].downloads);
    } else {
      views.push(0);
      downloads.push(0);
    }
  });

  // Return the full range of dates along with the corresponding views and downloads
  return { dateRange, views, downloads };
};

export const BarGraph: React.FC<BarGraphProps> = ({
  graphData,
  isDateFilter,
}) => {

  // Generate an array of date ranges between startDate and endDate
  const { dateRange, views, downloads } = generateDateRange(graphData);

  // Get the highest value in views
  // Calculate the maximum sum of views and downloads for the stacked bar
  const maxStackedValue = Math.max(
    ...views.map((view, index) => view + downloads[index])
  );

  const options: any = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
        tools: {
          zoom: false,
        },
      },
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 30,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 25
      },
    },
    xaxis: {
      type: "datetime",
      // categories: dateRanges, // Convert dates to timestamp
      categories: dateRange, // Use the generated date range
      labels: {
        // formatter: (value: number) => moment(value).format("MMM DD, yyyy"), // Format to readable date
        format: "MMM dd, yyyy",
        offsetY: 10, // Moves labels down by 10 pixels
      },
    },
    yaxis: {
      show: true,
      opposite: true,
      max: maxStackedValue, // Set max to highest value in views
      min: 0,
      tickAmount: maxStackedValue <= 5 ? maxStackedValue : 5, // Dynamically set tickAmount based on max value
      labels: {
        formatter: function (value: any) {
          return parseInt(value, 10); // Convert values to integers
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0A7BAC", "#F89755"],
  };

  const series = [
    {
      name: "Views",
      type: "column",
      data: views,
    },
    {
      name: "Downloads",
      type: "column",
      data: downloads,
    },
  ];

  return (
    <div id="chart" className="w-full" >
      {graphData?.length > 0 && (
        <ReactApexCharts
          options={options}
          series={series}
          type="bar"
          height={380}
        />
      )}
    </div >
  );
};
