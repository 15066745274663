import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const EyeIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M3.511 12a9.004 9.004 0 0 0 16.976 0 9.004 9.004 0 0 0-16.976 0Zm-2.008-.3C2.904 7.238 7.073 4 11.999 4c4.927 0 9.095 3.238 10.496 7.7.062.196.062.405 0 .6C21.096 16.762 16.927 20 12 20c-4.927 0-9.095-3.238-10.496-7.7a1 1 0 0 1 0-.6ZM11.999 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EyeIcon;
