import { useRef, useState } from "react";
import { useInitializeMap } from "../../hooks";
import {
  useApplyFitboundsToTheMap,
  useCustomizeColorOfHigilightedPoint,
  useGetCurrentQuestionState,
  useHandleClickOnPoint,
  useHandleMapMovement,
  useHandleMovementOfMapCenterPoint,
  usePaintAllPointsOnMap,
} from "./hooks";


const LAYER_DEFAULT_COLOR ="#FFA500" ;
const LAYER_SELECTED_COLOR ="#FF0000";

const Map = ({ questionId, name }: any) => {
  const mapContainer = useRef<any>(null);
  const [markers, setMarkers] = useState<any>([]);
  const currentQuestionEle: any = useGetCurrentQuestionState(questionId, name);

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // getting question state from global states


  // paint all points on the map
  const [clickedPoint, setClickedPoint] = usePaintAllPointsOnMap(
    currentQuestionEle,
    map,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers
  );

  // handle click on point
  useHandleClickOnPoint(clickedPoint, setClickedPoint, currentQuestionEle,name);

  // change the color of highlighted point
  useCustomizeColorOfHigilightedPoint(
    map,
    isMapFullyLoaded,
    currentQuestionEle,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers,
    setClickedPoint
  );

  // handle map center point property
  useHandleMovementOfMapCenterPoint(currentQuestionEle, map);

  // handle movement of map
  useHandleMapMovement(currentQuestionEle,map);
  
  useApplyFitboundsToTheMap(currentQuestionEle?.allCommunityMarkers ?? [],map, isMapFullyLoaded)

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
