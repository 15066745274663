import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeArchiveOrRestoreObservationModalAction,
  closeRemoveManagerModalAction,
  closeReviewerDetailsModal,
  closeSuccessfullInviteMessageModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { observationsStatusAction } from "store/filters";
import { IfetchManager } from "store/managers/initialState";
import { managerTypeAction } from "store/filters/reducer.actions";
import SelectInput from "./components/SelectInput";
import { fetchManagerAction } from "store/managers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import usePermissions from "hooks/usePermissions";
import { updateColumnsVisibilityOfManagersByStatus } from "store/directoriesOrders/reducer.actions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

interface IProps {}

export const SuccessfullInvitationMessageModal: React.FC<IProps> = () => {
  const { successfullInviteMessageModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { programId } = useParams<{
    programId: any;
  }>();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeSuccessfullInviteMessageModalAction());
  };
  const handleSubmit = async () => {
    // dispatch(fetchManagerAction(parseInt(programId)));
    // dispatch(managerTypeAction("external_data_end_viewer"));
    // dispatch(
    //   updateColumnsVisibilityOfManagersByStatus("external_data_end_viewer")
    // );
    handleClose();
    dispatch(fetchManagerAction(parseInt(programId)));
  };
  return (
    <Modal
      open={successfullInviteMessageModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  An email has been sent to sign in
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="w-full">
              <p className="text-secondaryMid text-sm leading-[18px] font-normal">
                An email has been sent to the{" "}
                {successfullInviteMessageModal.data} for them to create a
                password and sign in.
              </p>
              <p className="text-secondaryMid text-sm leading-[18px] font-normal mt-3">
                They won't see the report until you or another admin gives them
                permission to view or download it. To do this, go to the report,
                and adjust the permissions.
              </p>
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text={"Got it"}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
