import moment from "moment";
import {
  GET_CHAT_DETAILS,
  RESET_CHAT_DETAILS,
  APPEND_NEW_CHAT,
  START_LOADING_CHATS,
  STOP_LOADING_CHATS,
  GET_SEARCHED_CHAT_DETAILS,
} from "./action-types";
import { DATE_FORMATS } from "constants/dateFormats";

import initialState, { IChat } from "./initialState";
export const chatDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_CHATS:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING_CHATS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_CHAT_DETAILS:
      const chats = action.payload.messages
        .concat(state.data.chats)
        .sort((a: any, b: any) => b.messageDate - a.messageDate);
      const updatedChats = chats.map((item: IChat, index: number) => {
        const currentDate = new Date(item.messageDate);
        const prevDate =
          index > 0 ? new Date(chats[index - 1].messageDate) : null;
        const dateChanged =
          prevDate && currentDate.toDateString() !== prevDate.toDateString();

        return {
          ...item,
          dateChanged: dateChanged || index === 0, // Set dateChanged to true for the first message of each date
        };
      });

      const totalPages = Math.ceil(updatedChats.length / 20);
      return {
        ...state,
        data: {
          ...state.data,
          chats: updatedChats,
          receiver: action.payload.receiver,
          sender: action.payload.sender,
          totalItems: action.payload.totalItems,
          totalPages: totalPages,
          allowMessage: action.payload.allowMessage,
        },
        message: "",
      };

    case GET_SEARCHED_CHAT_DETAILS:
      const searchedChats = action.payload.messages.sort(
        (a: any, b: any) => b.messageDate - a.messageDate
      );
      const updatedSearchedChats = searchedChats.map(
        (item: IChat, index: number) => {
          const currentDate = new Date(item.messageDate);
          const prevDate =
            index > 0 ? new Date(searchedChats[index - 1].messageDate) : null;
          const dateChanged =
            prevDate && currentDate.toDateString() !== prevDate.toDateString();

          return {
            ...item,
            dateChanged: dateChanged || index === 0, // Set dateChanged to true for the first message of each date
          };
        }
      );

      const totalChatPages = Math.ceil(updatedSearchedChats.length / 20);
      return {
        ...state,
        data: {
          ...state.data,
          chats: updatedSearchedChats,
          receiver: action.payload.receiver,
          sender: action.payload.sender,
          totalItems: action.payload.totalItems,
          totalPages: totalChatPages,
          allowMessage: action.payload.allowMessage,
        },
        message: "",
      };

    case RESET_CHAT_DETAILS: {
      return initialState;
    }
    case APPEND_NEW_CHAT: {
      const updatedChat = [...state.data.chats, action.payload].map(
        (item: IChat, index: number) => {
          const currentDate = new Date(item.messageDate);
          const prevDate =
            index > 0
              ? new Date(
                  [...state.data.chats, action.payload][index - 1].messageDate
                )
              : null;
          const dateChanged =
            prevDate && currentDate.toDateString() !== prevDate.toDateString();

          return {
            ...item,
            dateChanged,
          };
        }
      );

      return {
        ...state,
        data: {
          ...state.data,
          chats: updatedChat.sort(
            (a: any, b: any) => b.messageDate - a.messageDate
          ),
        },
        message: "",
      };
    }

    default:
      return state;
  }
};
