import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const BinocularsKpiIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 3C3.89543 3 3 3.89543 3 5V6.26756C2.4022 6.61337 2 7.25972 2 8V16C2 16.7403 2.4022 17.3866 3 17.7324V19C3 20.1046 3.89543 21 5 21H8C9.10457 21 10 20.1046 10 19V17.7324C10.5978 17.3866 11 16.7403 11 16V15H13V16C13 16.7403 13.4022 17.3866 14 17.7324V19C14 20.1046 14.8954 21 16 21H19C20.1046 21 21 20.1046 21 19V17.7324C21.5978 17.3866 22 16.7403 22 16V8C22 7.25972 21.5978 6.61337 21 6.26756V5C21 3.89543 20.1046 3 19 3H16C14.8954 3 14 3.89543 14 5V6.26756C13.4022 6.61337 13 7.25972 13 8V9H11V8C11 7.25972 10.5978 6.61337 10 6.26756V5C10 3.89543 9.10457 3 8 3H5ZM11 11V13H13V11H11ZM9 8L8.58579 7.58579C8.21071 7.21071 8 6.70201 8 6.17157V5H5V6.17157C5 6.70201 4.78929 7.21071 4.41421 7.58579L4 8V16H9V8ZM15 16H20V8L19.5858 7.58579C19.2107 7.21071 19 6.70201 19 6.17157V5H16V6.17157C16 6.70201 15.7893 7.21071 15.4142 7.58579L15 8V16ZM8 18H5V19H8V18ZM16 18H19V19H16V18Z"
    />
  </svg>
);
export default BinocularsKpiIcon;
