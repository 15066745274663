import LinkDisconnectIcon from "assets/icons/HeroIcons/LinkDisconnectIcon";
import { FormikProps, FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { FileUpload } from "../components/fileUpload";
import SearchInput from "../components/SearchInput";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";
import { MaterialUiSelect } from "view/components/Select";

// Program Interface
interface TypeItem {
  value: string;
  label: string;
}

// Interface For Modal Form extending Formik values
interface SpecieModalFormProps extends FormikProps<FormikValues> {
  handleClose: () => void;
  acceptedFileTypes: any;
  editMode: boolean;
  typesList: TypeItem[];
  handleTypeSearch: (search: string) => void;
}

const SpecieModalForm: React.FC<SpecieModalFormProps> = ({
  handleClose,
  setFieldValue,
  handleChange,
  values,
  handleBlur,
  errors,
  touched,
  acceptedFileTypes,
  editMode,
  typesList,
  isSubmitting,
  handleTypeSearch,
}) => {
  // States
  const [inputValue, setInputValue] = useState("");
  const { addOrEditSpecieModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { types } = usePermissions();
  return (
    <div className="p-5 w-[700px] rounded-lg overflow-y-auto bg-bgWhite dark:bg-secondaryLight">
      <div className="relative flex flex-col items-start justify-start gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
        <div className="flex justify-between items-start self-stretch py-0.5">
          <div className="relative flex items-center justify-start">
            <p className="flex-grow text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
              {editMode ? "Edit Species" : "Add Species"}
            </p>
          </div>
          <button onClick={handleClose} type="button">
            <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
          </button>
        </div>
      </div>
      <div className="w-full overflow-y-auto px-1 py-2 max-h-[70vh]">
        <FileUpload
          values={values}
          editMode={editMode}
          acceptedFileTypes={acceptedFileTypes}
          isSubmitting={isSubmitting}
          errors={errors.images}
          setFieldValue={setFieldValue}
        />
        <TextInput
          label="Common Name *"
          type="text"
          placeholder="enter common name"
          name="commonName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.commonName}
          error={errors?.commonName}
          touched={touched.commonName}
        />
        <TextInput
          label="Scientific name"
          type="text"
          placeholder="enter scientific name"
          name="scientificName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.scientificName}
          error={errors?.scientificName}
          touched={touched.scientificName}
        />
        <TextInput
          label="Alternative names"
          type="text"
          placeholder="enter an alternative name"
          name="alternativeName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.alternativeName}
          error={errors?.alternativeName}
          touched={touched.alternativeName}
        />

        <div className="w-full ">
          <p
            className={`flex-grow pb-1 text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
              touched?.apps && errors?.apps
                ? "text-accent_1Dark dark:text-accent_1Dark"
                : ""
            }`}
          >
            Preferred Name
          </p>
          <MaterialUiSelect
            options={[
              {
                value: "common_name",
                label: "Common Name",
              },
              {
                value: "scientific_name",
                label: "Scientific Name",
              },
              {
                value: "alternative_name",
                label: "Alternate Name",
              },
            ]}
            labelKey="label"
            valueKey="value"
            handleChange={(item: any) => {
              setFieldValue("preferredName", item.value);
            }}
            placeholder={"Select Preferred Name"}
            initialValue={values?.preferredName}
            error={errors?.preferredName}
            helperText={"Select the preferred name for this species to display in forms and observations."}
          />
        </div>

        {types.canViewDropdown && (
          <SearchInput
            isDisable={false}
            inputValue={inputValue}
            name="typeId"
            label="Type *"
            onInputChange={(value: string) => {
              setInputValue(value);
              handleTypeSearch(value);
            }}
            options={typesList}
            defaultValue={{ value: "", label: "" }}
            isMulti={false}
            value={values?.typeId?.value ? [values?.typeId] : []}
            onChange={(selectedOption: any) => {
              setFieldValue("typeId", selectedOption);
            }}
            placeholder="Select A Species Type"
            touched={touched.typeId}
            error={errors?.typeId}
          />
        )}

        <div>
          <TextInput
            label="Description"
            type="text"
            rows={4}
            fieldAs="textarea"
            placeholder="Add your description..."
            name="description"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.description}
            error={errors?.description}
            touched={touched.description}
          />
        </div>

        {!(errors?.description && touched?.description) && (
          <div className="py-0.5">
            <p className={`text-xs text-left mt-[-10px] text-secondaryLight`}>
              6000 Max Characters
            </p>
          </div>
        )}
      </div>
      <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-2">
        <Button
          type="button"
          text="Cancel"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="w-24 h-11"
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={handleClose}
        />
        <Button
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          text="Save"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="w-24 h-11"
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="filled"
        />
      </div>
    </div>
  );
};

export default SpecieModalForm;
