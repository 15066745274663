import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { usersSearchAction } from "store/filters";
import { filtersInitialState } from "store/filters/initialState";

export const Search = React.memo(() => {
    const { usersFilter } = useSelector<RootState,filtersInitialState>((state) => state.Filters);
    const dispatch = useDispatch();
  
    const isStatusApplied =
      usersFilter.searchBy && usersFilter.searchString !== "";
    const resetFilter = () => {
      dispatch(usersSearchAction("", ""));
    };
  
    if (isStatusApplied) {
      return (
        <div className="flex justify-between px-2  items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]">
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm text-center capitalize text-textMid dark:text-inputText ">
              {sentenceCase(usersFilter.searchBy)}: {usersFilter.searchString}
              {/* {usersFilter.searchBy} : {usersFilter.searchString} */}
            </p>
          </div>
          <ClearFilters handleClick={resetFilter} />
        </div>
      );
    } else {
      return null; // or fallback JSX if needed
    }
  });