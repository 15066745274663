import apiClient from "services/apiClient";

interface ReviwerBody {
  personId: number;
}

async function updateReviewer(id: number, body: ReviwerBody) {
  const url = `/admin/observations/${id}/attach-reviewer`;
  const res = await apiClient.put(url, body);
  return res.data;
}

export default updateReviewer;
