import Box from "@mui/material/Box";
import LinearProgressWithLabelAndCount from "../../LinearProgressWithLabel";
import { IObservationByRegion } from "store/dashboard/observationsByRegion/initialState";
import RegionNoData from "../../imgs/RegionNoData";

interface IRegionsObservationsLinearProgress {
  data: any[];
}

const RegionsObservationsLinearProgress = (
  props: IRegionsObservationsLinearProgress
) => {
  return (
    <Box height={"100%"}>
      {props.data.length > 0 ? (
        props.data.map((item: IObservationByRegion, index) => {
          return (
            <Box
              sx={{ flexGrow: 1, mb: 1, "&:not(:last-child)": { mb: "20px" } }}
              key={index}
            >
              <LinearProgressWithLabelAndCount
                label={item.regionName || "N/A"}
                value={item.count}
              />
            </Box>
          );
        })
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <RegionNoData />
        </div>
      )}
    </Box>
  );
};

export default RegionsObservationsLinearProgress;
