import { DATE_FORMATS } from "constants/dateFormats";
import moment from "moment-timezone";

interface FormatDateOptions {
  utc?: boolean;
  timezone?: string;
  local?: boolean;
  useCurrentTime?: boolean;
  past?: boolean;
  future?: boolean;
  dayCount?: number;
  time?: string | null;
}

/**
 * Formats a given date string into a specified format.
 * @param {string | null} dateStr - The date string to format.
 * @param {FormatDateOptions} options - Additional options for formatting.
 * @param {string} format - The desired format.
 * @returns {string} - The formatted date string.
 */
export function formatTheDates(
  dateStr: string | null,
  options: FormatDateOptions = {},
  format: string = DATE_FORMATS.DEFAULT
): any {
  if (options.useCurrentTime && !dateStr) {
    dateStr = moment().toISOString();
  } else if (options.future && !dateStr) {
    dateStr = moment().add(options.dayCount, "days").toISOString();
  } else if (options.past && !dateStr) {
    dateStr = moment().subtract(options.dayCount, "days").toISOString();
  }

  // let date = moment(
  //   dateStr,
  //   [...Object.values(DATE_FORMATS), moment.ISO_8601],
  //   true
  // );

  const currentTime = moment().format("HH:mm:ss");

  const dateTimeStr = `${moment(dateStr).format(DATE_FORMATS.DEFAULT)}T${options.time ? options.time : currentTime}`;

  let date = moment(
    dateTimeStr,
    [...Object.values(DATE_FORMATS), moment.ISO_8601],
    true
  );

  if (!date.isValid()) {
    console.error("Invalid date:", dateStr);
    return dateStr;
  }

  if (options.utc) {
    date = date.utc();
  } else if (options.timezone) {
    date = date.tz(options.timezone);
  } else if (options.local) {
    date = date.local();
  }

  return date.format(format);
}
