import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "view/components/Button";

interface AccessDeniedProps {
    title?: string;
    link: string;
    btnText?: string;
}

const AccessDenied: React.FC<AccessDeniedProps> = ({ title, link, btnText }) => {
    const navigate = useNavigate();

    // Handlers
    const handleBtnClick = () => {
        navigate(link);
    };
    return (
        <div className="w-full flex justify-center mt-20">
            <div className="flex flex-col items-center px-10 md:px-14 py-6 bg-bgWhite bg-opacity-40  dark:bg-secondaryLight rounded-2xl shadow-xl">
                <p className="text-2xl lg:text-[32px] font-bold text-center text-textNormal dark:text-textMain pb-4">
                    {title}
                </p>

                {/* <Button
                    type="button"
                    text={btnText}
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                    iconPosition="before"
                    onClick={handleBtnClick}
                /> */}
                <p className="text-center">
                    You do not have permission to access this page. If you believe this is a mistake, please contact the site administrator for assistance.
                </p>
                {/* <p className=" text-textLink text-center font-semibold pt-5">
                    Link to documentation
                </p> */}
            </div>
        </div>
    );
};

export default AccessDenied;
