import { useCallback } from 'react';

// Define types for the function arguments and return values
type Direction = 'N' | 'S' | 'E' | 'W';

type RenderFormat = 'DDMS' | 'DDMM' | 'DMSD' | 'DMMD';

interface DMSResult {
    degrees: number;
    minutes: number;
    seconds: number;
    direction: Direction;
}

const formatDecimal = (value: number): string => {
    const rounded = Math.round(value * 1000) / 1000; // Round to three decimal places
    return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(3);
};


// Hook definition with TypeScript
function useLocationFormatter() {
    const convertDMS2DDWithDirection = useCallback((degrees: string, direction: Direction, minutes: string, seconds: string = "0"): number => {
        seconds = seconds || "0"; // Default to "0" if seconds is falsy
        let decimalDegrees = Number(degrees) + (Number(minutes) / 60) + (Number(seconds) / 3600);

        if (direction === 'S' || direction === 'W') {
            decimalDegrees = -decimalDegrees;  // Negative for southern and western hemispheres
        }

        return decimalDegrees;
    }, []);

    const convertDD2DMSWithDirection = useCallback((decimalDegrees: number, isLongitude: boolean, renderFormat?: RenderFormat): string => {
        const direction: Direction = isLongitude ? (decimalDegrees >= 0 ? 'E' : 'W') : (decimalDegrees >= 0 ? 'N' : 'S');
        decimalDegrees = Math.abs(decimalDegrees);

        const degrees = Math.floor(decimalDegrees);
        const minutesFromDecimal = (decimalDegrees - degrees) * 60;
        const minutes = Math.floor(minutesFromDecimal);
        const seconds = Math.round((minutesFromDecimal - minutes) * 60);  // Rounding seconds

        if (renderFormat === "DDMS") {
            return `${direction} ${degrees}° ${minutes}' ${seconds}"`;
        }

        return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
    }, []);

    const convertDD2DMMWithDirection = useCallback((decimalDegrees: number, isLongitude: boolean, renderFormat?: RenderFormat): string => {
        const direction: Direction = isLongitude ? (decimalDegrees >= 0 ? 'E' : 'W') : (decimalDegrees >= 0 ? 'N' : 'S');
        decimalDegrees = Math.abs(decimalDegrees);

        const degrees = Math.floor(decimalDegrees);
        const minutesFromDecimal = (decimalDegrees - degrees) * 60;

        if (renderFormat === "DDMM") {
            return `${direction} ${degrees}° ${formatDecimal(minutesFromDecimal)}'`;
        }

        return `${degrees}° ${formatDecimal(minutesFromDecimal)}' ${direction}`;
    }, []);

    const formatLocation = useCallback((value: string, format: 'DMS' | 'DD' | 'DMM', isLongitude: boolean = false, renderFormat?: RenderFormat): string | number => {
        if (format === 'DD') {
            const [degrees, minutes, seconds, direction] = value.split(/[^\d\w.]+/).filter(Boolean) as [string, string, string, Direction];
            return convertDMS2DDWithDirection(degrees, direction, minutes, seconds);
        } else if (format === 'DMS') {
            return convertDD2DMSWithDirection(Number(value), isLongitude, renderFormat);
        } else if (format === 'DMM') {
            return convertDD2DMMWithDirection(Number(value), isLongitude, renderFormat);
        }
        throw new Error('Invalid format specified');
    }, [convertDMS2DDWithDirection, convertDD2DMSWithDirection, convertDD2DMMWithDirection]);

    return { formatLocation };
}

export default useLocationFormatter;
