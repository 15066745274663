import React from "react";
import InformationCircleIcon from "assets/icons/HeroIcons/InformationCircleIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { toast, ToastPosition } from "react-toastify";
interface ToastProps {
  message: string;
}

const Success: React.FC<ToastProps> = ({ message }) => {
  const handleCloseToast = () => {
    toast.dismiss();
  };

  return (
    <div
      id="successToast"
      className="flex justify-start items-center w-full relative gap-4 px-2  py-5 bg-tertiaryLight   "
      style={{ boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.12)" }}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#009F88"
      />
      <div className="flex justify-start items-center px-2 flex-grow relative py-0.5">
        <p
          id="successToastMessage"
          className="flex-grow w-24 text-[15px] text-left text-secondaryMid toaster_messages_be capitalize"
        >
          {message}
        </p>
      </div>
      <button
        type="button"
        onClick={handleCloseToast}
        className="border-none outline-none "
        title="Close"
      >
        <XCloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#2C3236"
          preserveAspectRatio="xMidYMid meet"
        />
      </button>
    </div>
  );
};

const Error: React.FC<ToastProps> = ({ message }) => {
  const handleCloseToast = () => {
    toast.dismiss();
  };

  return (
    <div
      id="errorToast"
      className="flex justify-start items-center w-full  relative gap-4 px-2  py-5 bg-accent_1Light"
      style={{ boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.12)" }}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#BF200B"
      />
      <div className="flex justify-start items-center px-2 flex-grow relative py-0.5">
        <p
          id="errorToastMessage"
          className="flex-grow w-24 text-[15px] text-left text-secondaryMid toaster_messages_be capitalize"
        >
          {message}
        </p>
      </div>

      <button
        type="button"
        onClick={handleCloseToast}
        className="border-none outline-none "
        title="Close"
      >
        <XCloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#2C3236"
          preserveAspectRatio="xMidYMid meet"
        />
      </button>
    </div>
  );
};

const Warning: React.FC<ToastProps> = ({ message }) => {
  const handleCloseToast = () => {
    toast.dismiss();
  };

  return (
    <div
      id="warningToast"
      className="flex justify-start items-center  w-full  relative  px-4  py-5 bg-[#fdf2d9]"
      style={{ boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.12)" }}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#F2AA00"
      />
      <div className="flex justify-start items-center px-2 flex-grow relative py-0.5">
        <p
          id="successToastMessage"
          className="flex-grow w-24 text-[15px] text-left text-secondaryMid toaster_messages_be capitalize"
        >
          {message}
        </p>
      </div>
      <button
        type="button"
        onClick={handleCloseToast}
        className="border-none outline-none "
        title="Close"
      >
        <XCloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#2C3236"
          preserveAspectRatio="xMidYMid meet"
        />
      </button>
    </div>
  );
};

const Info: React.FC<ToastProps> = ({ message }) => {
  const handleCloseToast = () => {
    toast.dismiss();
  };

  return (
    <div
      id="infoToast"
      className="relative flex items-center justify-start w-full gap-4 px-2 py-5 bg-primaryLight"
      style={{ boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.12)" }}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#0A7BAC"
      />
      <div className="flex justify-start items-center px-2 flex-grow relative py-0.5">
        <p
          id="successToastMessage"
          className="flex-grow w-24 text-[15px] text-left text-secondaryMid toaster_messages_be capitalize"
        >
          {message}
        </p>
      </div>

      <button
        type="button"
        onClick={handleCloseToast}
        className="border-none outline-none "
        title="Close"
      >
        <XCloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#2C3236"
          preserveAspectRatio="xMidYMid meet"
        />
      </button>
    </div>
  );
};

const Secondary: React.FC<ToastProps> = ({ message }) => {
  const handleCloseToast = () => {
    toast.dismiss();
  };

  return (
    <div
      id="secondaryToast"
      className="relative flex items-center justify-start w-full gap-4 px-2 py-5 bg-primaryLight toaster_messages_be"
      style={{ boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.12)" }}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#2C3236"
      />
      <div className="flex justify-start items-center px-2 flex-grow relative py-0.5">
        <p
          id="secondaryToastMessage"
          className="flex-grow w-24 text-[15px] text-left text-secondaryMid capitalize"
        >
          {message}
        </p>
      </div>

      <button
        type="button"
        onClick={handleCloseToast}
        className="border-none outline-none "
        title="Close"
      >
        <XCloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#2C3236"
          preserveAspectRatio="xMidYMid meet"
        />
      </button>
    </div>
  );
};

const Copy: React.FC<ToastProps> = ({ message }) => {
  return (
    <div
      id="copyToast"
      className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-2 px-4 py-2 overflow-hidden border-bgWhite py-5rounded bg-bgBlack_60"
    >
      <p
        id="copyToastMessage"
        className="flex-grow-0 flex-shrink-0 w-full text-base font-medium text-center text-textWhite toaster_messages_be"
      >
        {message}
      </p>
    </div>
  );
};

interface ToastProps {
  message: string;
}

const showToast = (
  Component: React.FC<ToastProps>,
  message: string,
  position?: ToastPosition | undefined
) => {
  toast(<Component message={message} />, { position: position || "top-right" });
};

const toasts = {
  success: (message: string, position?: ToastPosition | undefined) =>
    showToast(Success, message, position),
  error: (message: string, position?: ToastPosition | undefined) =>
    showToast(Error, message, position),
  warning: (message: string, position?: ToastPosition | undefined) =>
    showToast(Warning, message, position),
  info: (message: string, position?: ToastPosition | undefined) =>
    showToast(Info, message, position),
  secondary: (message: string, position?: ToastPosition | undefined) =>
    showToast(Secondary, message, position),
  copy: (message: string, position?: ToastPosition | undefined) =>
    showToast(Copy, message, position),
  loading: (message: string, position?: ToastPosition | undefined) =>
    showToast(Info, message, position),
};

export { toasts as Toasts };
