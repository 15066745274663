import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeRemoveManagerModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useParams } from "react-router-dom";
import { managerTypeAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

interface IProps {}

export const RemoveMangerModal: React.FC<IProps> = () => {
  const { removeManagerModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const personId = removeManagerModal.data?.personId;

  const { programId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeRemoveManagerModalAction());
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await apiLibrary.Managers.deleteReviewers(
        programId,
        personId,
        false
      );
      dispatch(managerTypeAction("internal"));
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={removeManagerModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain capitalize">
                  Remove from program
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              <p className="flex-grow w-[432px] text-sm">
                {managerFilter.type === "external_data_end_viewer"
                  ? "Are you sure you want to remove this user from the end data viewer of this program? They will no longer have access to any report on the system"
                  : "Are you sure you want to remove this manager from the program? This action will revoke their access to the program while keeping them as a user in the system. "}
              </p>
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text={"Remove Role"}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 capitalize"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
