import React from "react";
import { TailSpin } from "react-loader-spinner";

interface ButtonProps {
  text: any;
  variant?: "filled" | "outlined";
  filledColor?: string;
  outlinedColor?: string;
  textColor?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  fontStyle?: string;
  isLoading?: boolean;
  icon?: React.ReactNode; // New prop for the icon component
  iconPosition?: "before" | "after"; // New prop for the icon position
}

const Button: React.FC<ButtonProps> = ({
  text,
  variant = "filled",
  filledColor = "primary",
  outlinedColor = "secondary",
  textColor = "text-white",
  height = "10",
  width = "30",
  onClick,
  type = "button",
  disabled = false,
  className,
  fontStyle,
  isLoading,
  icon,
  iconPosition = "before", // Default to "before"
  ...rest
}) => {
  return (
    <>
      {variant === "filled" && (
        <button
          type={type}
          className={`flex justify-center disabled:cursor-not-allowed disabled:opacity-50 items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-2 rounded-3xl border-2 bg-${filledColor} hover:bg-primaryMid text-${textColor} border-${filledColor} ${
            disabled ? "opacity-50 cursor-not-allowed" : ""
          } ${fontStyle} transition-all h-${height} w-${width} ${className}`}
          onClick={onClick}
          disabled={disabled}
          {...rest}
        >
          {icon && iconPosition === "before" && icon}{" "}
          {/* Render icon before text */}
          {isLoading && isLoading === true ? (
            <TailSpin
              height="25"
              width="25"
              color="#fff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          ) : (
            text
          )}
          {icon && iconPosition === "after" && icon}{" "}
          {/* Render icon after text */}
        </button>
      )}

      {variant === "outlined" && (
        <button
          type={type}
          className={`flex justify-center items-center flex-grow-0 dark:border-borderSilverBlue dark:text-textMain dark:hover:bg-primary hover:bg-primaryExtraLight flex-shrink-0 gap-1.5 px-5 py-2 rounded-3xl border-2 border-${outlinedColor} text-${outlinedColor} hover:text-white transition-all ${
            disabled ? "opacity-50 cursor-not-allowed" : ""
          } ${fontStyle} h-${height} w-${width} ${className}`}
          onClick={onClick}
          disabled={disabled}
          {...rest}
        >
          {icon && iconPosition === "before" && icon}{" "}
          {/* Render icon before text */}
          {text}
          {icon && iconPosition === "after" && icon}{" "}
          {/* Render icon after text */}
        </button>
      )}
    </>
  );
};

export default Button;
