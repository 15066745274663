import React from "react";
import CustomCheckbox from "view/components/CheckBox";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import usePermissions from "hooks/usePermissions";

export const PermissionCheckbox = ({
  values,
  setFieldValue,
  permissionsData,
}: any) => {
  const actions = ["archive", "create", "delete", "edit", "view"];
  const { permissions } = usePermissions();
  const changeLabels: Record<string, string> = {
    archive: "archive",
    create: "create",
    delete: "delete",
    edit: "update",
    view: "read",
  };

  const handleSelectAll = (category: string, checked: boolean) => {
    actions.forEach((action) => {
       if (permissionsData[category]?.hasOwnProperty(action)) {
        setFieldValue(`${category}.${action}`, checked);
      }
    });
  };

  return (
    <div className="w-full flex flex-col items-start self-stretch justify-start">
      {Object.keys(permissionsData ?? {}).map((category) => {
        // Check if all actions are present in permissionsData[category]
        const allActionsPresent = actions.every((action) =>
          permissionsData[category]?.hasOwnProperty(action)
        );

        return (
          <div key={category} className="w-full mb-8">
            <p className="text-base text-secondary font-semibold font-Overpass capitalize dark:text-textMain">
              {category}
              <span className="text-sm text-secondaryMidLight font-normal ml-4 dark:text-textMain">
                Admin Settings
              </span>
            </p>
            <div className="w-full flex justify-between items-center mt-4 ">
              {/* Select All Checkbox or Icon */}
              {allActionsPresent ? (
                <div className="w-full flex justify-start mr-3">
                  <CustomCheckbox
                    name={`selectAll.${category}`}
                    checked={Object.values(values?.[category] ?? {}).every(
                      Boolean
                    )}
                    disabled={!permissions.canEditPermissions}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleSelectAll(category, event.target.checked);
                    }}
                    label={
                      <p className="w-full flex text-secondaryMid font-Overpass capitalize dark:text-textMain">
                        Full Access
                      </p>
                    }
                    labelPlacement="end"
                  />
                </div>
              ) : (
                <div className="w-full flex justify-start mr-3">
                  <XCloseIcon fill="#BF200B" />
                  <p className="text-secondaryMid font-Overpass capitalize ml-3 dark:text-textMain">
                    Full Access
                  </p>
                </div>
              )}

              {actions?.map((action) => (
                <React.Fragment key={action}>
                  {permissionsData[category]?.hasOwnProperty(action) ? (
                    <div className="w-full flex justify-start">
                      <CustomCheckbox
                        name={`${category}.${action}`}
                        checked={values?.[category]?.[action] ?? false}
                        disabled={!permissions.canEditPermissions}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setFieldValue(
                            `${category}.${action}`,
                            event.target.checked
                          );
                        }}
                        label={
                          <p className="text-secondaryMid font-Overpass capitalize dark:text-textMain">
                            {changeLabels[action]}
                          </p>
                        }
                        labelPlacement="end"
                      />
                    </div>
                  ) : (
                    <div className="w-full flex justify-start">
                      <XCloseIcon fill="#BF200B" />
                      <p className="text-secondaryMid font-Overpass capitalize ml-3 dark:text-textMain">
                        {changeLabels[action]}
                      </p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
