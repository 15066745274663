import { useSelector } from "react-redux";
import { HeaderBarProps } from "../../types";
import SearchBar from "../SearchBar";
import { fetchImportsFile } from "store/importsViewData/initialState";
import { RootState } from "store";
import { useState } from "react";
import FiltersList from "Components/ViewImportsData/FiltersList";
import FiltersBtn from "../FiltersBtn";

const HeaderBar = ({
  isOpen,
  setIsOpen,
  handleToggleDropdown,
  isCloseDiv,
}: HeaderBarProps) => {
  // const [isCloseDiv, setIsCloseDiv] = useState(false);

  const { data } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );

  return (
    <div className="flex gap-x-5 w-full justify-between items-center">
      <div className="flex gap-5 items-center justify-start py-5 rounded-lg">
        <div className="w-full pt-1.5 pb-2">
          <p className="text-sm text-secondaryMid dark:text-caption">
            {data?.totalItems} Items
          </p>
        </div>
      </div>
      <FiltersList isCloseDiv={isCloseDiv} />
      <div className="flex items-center justify-end flex-grow h-8 gap-2">
        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
          <SearchBar />
        </div>
        {/* <div>
          <FiltersBtn
            handleToggleDropdown={handleToggleDropdown}
            isCloseDiv={isCloseDiv}
          />
        </div> */}
      </div>
    </div>
  );
};

export default HeaderBar;
