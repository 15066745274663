import {
  ERROR_LOADING_SPECIES,
  GET_ALL_SPECIES,
  RESET_DATA,
  START_LOADING_SPECIES,
  STOP_LOADING_SPECIES,
} from "./action-types";
import initialState, { ISpecie } from "./initialState";

export const speciesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_SPECIES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_SPECIES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_SPECIES:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_SPECIES:
      const species = action.payload.species.map((item: ISpecie) => {
        const {
          alternativeName,
          commonName,
          dateAdded,
          id,
          image,
          scientificName,
          type,
          preferredName
        } = item;
        const data = {
          alternativeNames: alternativeName,
          commonName: {
            name: commonName,
            id: id
          },
          dateAdded,
          id,
          image,
          scientificName,
          type: type,
          preferredName: preferredName
        };
        return data;
      });

      return {
        ...state,
        data: {
          species: species,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
