interface Answers {
  [key: string]: string | number;
}
export function conditionalityForObservations(
  expression: string,
  answers: any,
  type?: string
): boolean {
  if (type === "enableIf") {
    return false;
  } else {
    return true;
  }
  if (!answers) {
    console.error("Answers object is undefined");
    return false;
  }

  if (typeof expression !== "string") {
    console.error("Expression is not a string");
    return false;
  }

  const extractNumber = (str: string) => {
    const matches = str.match(/\d+/); // Finds digits in the string
    return matches ? parseInt(matches[0], 10) : null;
  };

  const replaceVariables = (expr: string): string =>
    expr.replace(/\{([^}]+)\}/g, (match, variable) => {
      // Remove the part before and including the dot, if present
      const variableName = variable.split(".").pop();

      if (
        variableName &&
        variableName in answers &&
        answers[variableName] !== undefined
      ) {
        const value = answers[variableName];
        return typeof value === "number" ? value.toString() : `'${value}'`;
      } else {
        return "false";
      }
    });

  const handleComparison = (expr: string): boolean => {
    const parts = expr.split(/\s*([=!<>]+)\s*/).map((s) => s.trim());
    if (parts.length < 3) return false;

    const [left, operator, right] = parts;

    // Extract numbers if the string contains a format like 'Item 9'
    const leftNum = extractNumber(left);
    const rightNum = extractNumber(right);

    // Determine if comparison should be numeric or string-based
    const leftVal = leftNum !== null ? leftNum : left;
    const rightVal = rightNum !== null ? rightNum : right;

    switch (operator) {
      case "=":
        return leftVal === rightVal;
      case "!=":
      case "<>":
        return leftVal !== rightVal;
      case "<":
        return leftVal < rightVal;
      case "<=":
        return leftVal <= rightVal;
      case ">":
        return leftVal > rightVal;
      case ">=":
        return leftVal >= rightVal;
      default:
        return false;
    }
  };

  const handleAnyOf = (expr: string): boolean => {
    const parts = expr.split(" anyof ");
    if (parts.length === 2) {
      const variableValue = parts[0].replace(/['"]/g, "");
      let options = parts[1].replace(/'/g, '"');
      let arrayOptions;
      try {
        arrayOptions = JSON.parse(options);
      } catch (e) {
        console.error("Failed to parse options as JSON:", e);
        return false;
      }
      return arrayOptions.includes(variableValue);
    }
    return false;
  };

  function recursiveEvaluate(expr: string): boolean {
    expr = replaceVariables(expr);

    while (/\(([^()]+)\)/.test(expr)) {
      expr = expr.replace(/\(([^()]+)\)/g, (match, subExpr) => {
        const result = recursiveEvaluate(subExpr);
        return result ? "true" : "false";
      });
    }

    if (expr.includes(" anyof ")) {
      return handleAnyOf(expr);
    }

    return expr.split(/\s+(and|or)\s+/).reduce((acc, part, index, parts) => {
      if (part === "and" || part === "or") {
        return acc;
      }
      if (index === 0 || parts[index - 1] === "and") {
        return index === 0
          ? handleComparison(part)
          : acc && handleComparison(part);
      } else {
        return acc || handleComparison(part);
      }
    }, true);
  }
  if (type === "enableIf") {
    return !recursiveEvaluate(expression);
  }
  return recursiveEvaluate(expression);
}
