import { apiClient } from "../config";

async function getAllProtocols(queryParams: any) {
  const res = await apiClient.get(`/admin/protocols`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllProtocols;
