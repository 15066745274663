import React from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { reportsStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import useDateFormatter from "hooks/formatDateWithPattern";

interface TabsProps { }

export const ReportsCreatedDate: React.FC<TabsProps> = ({ }) => {
  const reportsProfile = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );
  const { FormatDateTime } = useDateFormatter();
  return (
    <>
      <div>
        <p className="text-sm font-Overpass"> <span className="text-secondaryMidLight dark:text-caption"> Created: {reportsProfile?.data.dateAdded ? FormatDateTime(reportsProfile?.data.dateAdded) : ""}</span> <span className="font-semibold dark:text-inputText"> Recurrence: {reportsProfile?.data.recurrenceSettings}</span></p>
      </div>
    </>
  );
};
