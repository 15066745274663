import React from 'react';
import Header from './Header';
import ProfileMessage from './Activities';
import usePermissions from 'hooks/usePermissions';

const ObservationsActivities = ({ handleExpandAllPanels, canEdit }: any) => {
  const { observations } = usePermissions();
  return (
    <div className='w-full h-full bg-BgWhite'>
      <Header />
      <ProfileMessage
        handleExpandAllPanels={handleExpandAllPanels}
        canEdit={canEdit}
      />
    </div>
  );
};

export default ObservationsActivities;
