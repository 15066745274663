import React, { useEffect } from "react";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import { sentenceCase } from "change-case";
import { moveToSpecificStepAction } from "store/addReportStepper/reducer.actions";
import { useDispatch } from "react-redux";

interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
}

interface StepBreadcrumbProps {
  steps: any;
}

export const BreadCrumbs: React.FC<StepBreadcrumbProps> = ({ steps }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex items-center mt-3 mb-4">
      {steps.length > 0 &&
        steps?.map((step: Step, index: number) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <svg
                width={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.52827 2.86177C5.78862 2.60142 6.21073 2.60142 6.47108 2.86177L11.1377 7.52843C11.2628 7.65346 11.333 7.82303 11.333 7.99984C11.333 8.17665 11.2628 8.34622 11.1377 8.47124L6.47108 13.1379C6.21073 13.3983 5.78862 13.3983 5.52827 13.1379C5.26792 12.8776 5.26792 12.4554 5.52827 12.1951L9.72353 7.99984L5.52827 3.80458C5.26792 3.54423 5.26792 3.12212 5.52827 2.86177Z"
                  fill="#54595F"
                />
              </svg>
            )}

            <button
              // onClick={() => dispatch(moveToSpecificStepAction(index))}
              className="flex items-center space-x-2 text-textMid hover:text-primaryDark"
            >
              <div className="flex justify-center items-center relative gap-1.5 py-1 rounded-lg">
                <RenderStepperIcon step={step} index={index + 1} />
                <div className="flex justify-center items-center relative p-0">
                  <p className={`text-sm text-center font-Overpass  ${step.isActive ? "text-secondaryMid dark:text-textMain " : "text-textMidLight dark:text-caption "}`}>
                    {step.title}
                  </p>
                </div>
              </div>
            </button>
          </React.Fragment>
        ))}
    </div>
  );
};

const RenderStepperIcon: React.FC<{ step: Step; index: number }> = ({
  step,
  index,
}) => {
  if (step.isActive) {
    return (
      <div className="flex justify-center items-center  rounded-lg h-[30px] w-[30px] bg-primaryMid">
        <p className="text-xs font-medium text-center text-bgWhite">{index}</p>
      </div>
    );
  } else if (step.isSubmitted) {
    return (
      <div className="flex justify-center items-center flex-grow-0 h-[30px] w-[30px] flex-shrink-0 relative p-1 rounded-lg bg-primary/[0.08]">
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1384 4.19526C13.3987 4.45561 13.3987 4.87772 13.1384 5.13807L6.47173 11.8047C6.21138 12.0651 5.78927 12.0651 5.52892 11.8047L2.86225 9.13807C2.6019 8.87772 2.6019 8.45561 2.86225 8.19526C3.1226 7.93491 3.54471 7.93491 3.80506 8.19526L6.00033 10.3905L12.1956 4.19526C12.4559 3.93491 12.878 3.93491 13.1384 4.19526Z"
            fill="#2C3236"
          />
        </svg>
      </div>
    );
  } else if (!step.isActive && !step.isSubmitted) {
    return (
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 p-1 rounded-lg opacity-40 mb-[5px]">
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-secondaryMid dark:text-caption">
            {index}
          </p>
        </div>
      </div>
    );
  }
};
