import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateCurrentQuestion } from "store/surveyJS";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import questionTypes from "../questionTypes";

const useIdentifyCurrentQuestion = () => {
  const dispatch = useDispatch();
  const { shapePickers,pointPickers,drawPolygons,dropPins, selectedQuestionElement } = useSelector<
    RootState,
    I_InitialStateOfSurveyJS
  >((state) => state.surveyJS);

  useEffect(() => {
    if (selectedQuestionElement ) {
      const questionId = selectedQuestionElement.rdx_id;
      const questionType = selectedQuestionElement.type;
      let questionState;

      if(questionType === questionTypes.shapePicker && shapePickers && shapePickers.length > 0){
        questionState = shapePickers.find((s: any) => s.id === questionId);

      }
      else if(questionType === questionTypes.pointPicker && pointPickers && pointPickers.length > 0){
        questionState = pointPickers.find((s: any) => s.id === questionId);

      }
      else if(questionType === questionTypes.drawPolygon && drawPolygons && drawPolygons.length > 0){
        questionState = drawPolygons.find((s: any) => s.id === questionId);
        
      }
      else if(questionType === questionTypes.dropPin && dropPins && dropPins.length > 0){
        questionState = dropPins.find((s: any) => s.id === questionId);
        
      }

      if (questionState) {
        dispatch(updateCurrentQuestion(questionState));
      }
    }
  }, [shapePickers, selectedQuestionElement]);

  return null;
};

export { useIdentifyCurrentQuestion };
