import { HBGModule } from "store/dashboard/horizontalBarGraph/types";

export const getHBGStateAndName = (name: string, state: any) => {
  let obj: { name: string; stateObj: any; color: string } = {
    name: "",
    stateObj: {},
    color: "",
  };
  if (name === HBGModule.community) {
    obj = {
      name: "communities",
      stateObj: state.communities,
      color: "#BF200B",
    };
  } else if (name === HBGModule.form) {
    obj = {
      name: "forms",
      stateObj: state.forms,
      color: "#DD9D05",
    };
  } else {
    obj = {
      name: "programs",
      stateObj: state.programs,
      color: "#E5660B",
    };
  }
  return obj;
};
