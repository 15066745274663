import React, { useEffect, useLayoutEffect, useState } from "react";
// Components
import { Filters, Header, Tabs, Pagination, Table } from "Components/Users";
import TextInputField from "view/components/InputField";
// Hooks
import { UserTable } from "hooks/useTable";
// Assets
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchUsersAction } from "store/users/reducer.actions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IfetchUsers } from "store/users/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { useLocation, useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
import { usersResetFiltersAction } from "store/filters";
import {
  setUsersFiltersAction,
  usersOpenAccessAction,
} from "store/filters/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const UserPage = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { users } = usePermissions();

  const location = useLocation();
  const state = location.state;

  useLayoutEffect(() => {
    dispatch(setUsersFiltersAction());
  }, []);

  const { data, stateIs } = useSelector<RootState, IfetchUsers>(
    (state) => state?.allUsers
  );

  const [entityShowOpenAccess, setEntityShowOpenAccess] = useState<any>();
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    table,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = UserTable(data?.users);

  useEffect(() => {
    dispatch(fetchUsersAction(communityId, programId, organizationId, state));
    saveFiltersDataToLocalStorage(usersFilter);
  }, [usersFilter, communityId, programId]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(usersResetFiltersAction());
  //   };
  // }, []);
  const getOpenAccessData = (data?: any) => {
    if (data) {
      setEntityShowOpenAccess(data);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs == "Idle" && data?.users?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              selectedRows={selectedRows}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <div className="w-full">
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
