import {
  ERROR_LOADING_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS,
  STOP_LOADING_NOTIFICATIONS,
} from "./action-types";
import initialState, { INotifications } from "./initialState";
export const notificationCenterReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case START_LOADING_NOTIFICATIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_NOTIFICATIONS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_NOTIFICATIONS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_NOTIFICATIONS:
      const notification = action.payload?.notifications?.map(
        (item: INotifications) => {
          const {
            id,
            title,
            description,
            profileImage,
            isRead,
            createdAt,
            redirectLink,
          } = item;
          const data = {
            id,
            title,
            description,
            profileImage,
            isRead,
            createdAt,
            redirectLink,
          };
          return data;
        }
      );

      return {
        ...state,
        data: {
          notificationsCenter: notification,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          unreadCount: action.payload.unreadCount,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
