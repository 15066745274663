import React, { useEffect } from "react";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import TextInputField from "view/components/InputField";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { geographySearchAction } from "store/filters";

const SearchBar = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
         (state) => state?.Filters
        );
        useEffect(() => {
          const handleOutsideClick = (event: MouseEvent) => {
            if (
              inputRef.current &&
              !inputRef.current.contains(event.target as Node) &&
              geographyFilter.searchString === ""
            ) {
              setShowInput(false);
            }
          };
      
          document.addEventListener("click", handleOutsideClick);
      
          return () => {
            document.removeEventListener("click", handleOutsideClick);
          };
        }, [geographyFilter.searchString]);
      
        useEffect(() => {
          return () => {
            dispatch(geographySearchAction(""));
          };
        }, []);
        useEffect(() => {
          if (showInput) {
            const inputField = document.querySelector(
              ".getInput"
            ) as HTMLInputElement;
            if (inputField) {
              inputField.focus();
            }
          }
        }, [showInput]);
  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };
  return (
    <div className="flex py-[12px]">
      <div onClick={toggleInputVisibility} className="cursor-pointer  mr-7">
        <SearchIcon
          className={` ${showInput ? "mt-[5px]" : "mt-[-12px]"} absolute `}
        />
      </div>
      <div
        className={`${
          showInput ? "block" : "hidden"
        } border-2 border-t-0 border-l-0 border-r-0 border-b-primaryMid  pb-2`}
        ref={inputRef}
      >
        <TextInputField
          className="bg-bgBluish w-[250px] getInput mt-1 dark:bg-[transparent] dark:text-inputText"
          placeholder="Search"
          type="search"
          fieldAs="searchData"
          value={geographyFilter.searchString}
          onChange={(e: any) => {
            dispatch(geographySearchAction(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
