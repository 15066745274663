import {
  APPEND_NEW_MESSAGE_IN_CONVERSATION,
  GET_CONTACT_PERSONS,
  GET_RECIPIENTS,
  RESET_CONTACT_PERSONS,
  RESET_RECIPIENTS,
  RESET_UNREAD_COUNTER,
  START_LOADING_RECIPIENTS,
  STOP_LOADING_RECIPIENTS,
} from './action-types';
import { Dispatch } from 'redux';
import apiLibrary from 'services/api';
import { generateQueryStringForUsers } from 'utils/convertFiltersToQueryString';
import { Toasts } from 'view/components/Toasts';
import { IRecipient } from './initialState';

export const fetchRecipientsAction =
  (appType?: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({ type: START_LOADING_RECIPIENTS });
      const currentState = getState();
      const queryString = currentState.Filters.messageStatusFilter?.archived;
      const params = {
        appName: appType,
        archived: queryString,
      };
      const { data } = await apiLibrary.Messages.getAllRecipients(params);
      dispatch({
        type: GET_RECIPIENTS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_RECIPIENTS,
      });
      console.log('An error occurred while fetching the messages:', error);
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_RECIPIENTS });
    }
  };

export const fetchContactPersonsAction =
  (appId?: string) => async (dispatch: Dispatch, getState: any) => {
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.messageContactPersonSearchFilter
      );

      const params = {
        appName: appId,
        ...currentState.Filters.messageContactPersonSearchFilter,
      };

      const { data } = await apiLibrary.Messages.getAllContactPersons(params);
      dispatch({
        type: GET_CONTACT_PERSONS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_CONTACT_PERSONS,
      });
      console.log('An error occurred while fetching contact persons:', error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };

export const appendNewMessageInThreads = (message: {
  file?: any;
  message?: string;
  conversationId?: number;
  lastMessageAt?: string;
  data?: IRecipient;
  fileName?: string;
  messageType?: string;
}): {
  type: string;
  payload: {
    message?: string;
    conversationId?: number;
    data?: IRecipient;
    fileName?: string;
    messageType?: string;
  };
} => {
  return {
    type: APPEND_NEW_MESSAGE_IN_CONVERSATION,
    payload: message,
  };
};

export const resetUnreadCounter = (message: {
  conversationId: number;
}): { type: string; payload: { conversationId: number } } => {
  return {
    type: RESET_UNREAD_COUNTER,
    payload: message,
  };
};
