// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddImportBtn, PageHeading } from "./components";

// Store Utils
import { useDispatch } from "react-redux";
import { openAddOrEditCategoryModalAction, openAssignProgramAdminPermissionToImportModalAction } from "store/modals";
import { useNavigate } from "react-router-dom";
import usePermissions from "hooks/usePermissions";
import { filtersInitialState } from "store/filters/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { AssignAdminBtn } from "./components/AssignAdminBtn";

// Generating URLs

const generateDynamicBreadcrumbLinks = () => {
  switch (true) {
    default:
      return [
        { path: `/imports/list`, label: `Imports` }
      ];
  }
};
export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dynamicImportLinks = generateDynamicBreadcrumbLinks();
  const { importsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const handleAddImport = () => {
    localStorage.removeItem("importId");
    navigate("/imports/add");
  };

  const { imports } = usePermissions()

  const handleAssignAdmin = () => {
    dispatch(openAssignProgramAdminPermissionToImportModalAction())
  }

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicImportLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            {(imports.canCreateImports && importsFilter.status !== "permission") && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                <AddImportBtn
                  handleAddImport={handleAddImport}
                  label={"New Import"}
                />
              </div>
            )}
            {importsFilter?.status === "permission" && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                <AssignAdminBtn
                  handleAssignAdmin={handleAssignAdmin}
                  label={"Assign"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
