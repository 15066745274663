import { Ifetch } from "../initialState";

export interface ITitlesCountAndLinks {
  communities: {
    title: string;
    count: number;
    link: string;
  };
  forms: {
    title: string;
    count: number;
    link: string;
  };
  observations: {
    title: string;
    count: number;
    link: string;
  };
  programs: {
    title: string;
    count: number;
    link: string;
  };
  species: {
    title: string;
    count: number;
    link: string;
  };
  users: {
    title: string;
    count: number;
    link: string;
  };
}

const initialCounts = {
  communities: {
    title: "Communities",
    count: 0,
    link: "",
  },
  forms: {
    title: "Forms",
    count: 0,
    link: "",
  },
  observations: {
    title: "Observations",
    count: 0,
    link: "",
  },
  programs: {
    title: "Programs",
    count: 0,
    link: "",
  },
  species: {
    title: "Species",
    count: 0,
    link: "",
  },
  users: {
    title: "Users",
    count: 0,
    link: "",
  },
};

export interface IfetchTotalCounts extends Ifetch {
  data: ITitlesCountAndLinks;
}

const titlesCountAndLinksInitialState: IfetchTotalCounts = {
  data: initialCounts,
  message: "",
  stateIs: "Pending",
};

export default titlesCountAndLinksInitialState;
