import { useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
// import { DrawShapesDropdown, ToggleButton } from "../components";

import MagnetIcon from "assets/images/magnet.svg";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useInitializeCustomControlForSnapping = (map: any, draw: any) => {
  const [isSnapOn, setSnapOn] = useState(false);
  const { showSnapingControl } = useSelector(
    (state: RootState) => state.geography
  );

  useEffect(() => {
    const container = document.getElementById("customControlForSnapping");
    const snapButton = document.getElementById("snapeButton");

    if(showSnapingControl && map){
      ReactDOM.render(
        <>
          <SnapButton
            handleClick={toggleDropdownListener}
            isSnapOn={isSnapOn}
            showSnapingControl={showSnapingControl}
          />
        </>,
        document.getElementById("customControlForSnapping")
      );
      // draw.options.snap = false;
    }

    if (container && showSnapingControl && map && snapButton){
      // container.style.display = "block"
      snapButton.style.display = "block";
    }
    else if( container && !showSnapingControl && map && snapButton){
      snapButton.style.display = "none";
      if(draw){
        draw.options.snap = false;
      }
      setSnapOn(false);
    }

    // if (showSnapingControl && map) {
    //   if (container) {
    //     container.style.display = "block";
    //     draw.options.snap = false;
    //   }
    // } else {
    //   const container = document.getElementById("customControlForSnapping");
    //   if (container) {
    //     container.style.display = "none";
    //     setSnapOn(false);
    //     console.log("removing the snapping control");
    //     ReactDOM.unmountComponentAtNode(container);
    //   }
    // }

    return () => {
      const container = document.getElementById("customControlForSnapping");
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
    };
  }, [map, isSnapOn, draw, showSnapingControl]);

  const toggleDropdownListener = () => {
    if (draw) {
      draw.options.snap = !isSnapOn;
      setSnapOn(!isSnapOn);
    }
  };

  return null;
};

export { useInitializeCustomControlForSnapping };

const SnapButton = ({ handleClick, isSnapOn, showSnapingControl }: any) => {
  if (showSnapingControl) {
    return (
      <div id="snapeButton">
        <button
          onClick={handleClick}
          type="button"
          className="rounded"
          style={{
            backgroundColor: isSnapOn ? "#00000036" : "transparent ",
          }}
        >
          <img src={MagnetIcon} className="mx-auto -rotate-90 w-[21px]" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};
