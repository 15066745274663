import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
}

const CircleIcon = ({ fill = "#005C89", ...restProps }: IconProps) => (
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M31.5 12C31.5 15.121 29.8144 17.9847 27.0137 20.0853C24.2128 22.1859 20.3206 23.5 16 23.5C11.6794 23.5 7.78716 22.1859 4.98629 20.0853C2.18557 17.9847 0.5 15.121 0.5 12C0.5 8.87895 2.18557 6.01526 4.98629 3.91472C7.78716 1.81406 11.6794 0.5 16 0.5C20.3206 0.5 24.2128 1.81406 27.0137 3.91472C29.8144 6.01526 31.5 8.87895 31.5 12Z"
      fill="#005C89"
      fill-opacity="0.08"
      className="dark:fill-caption"
      stroke="#2C3236"
      stroke-linejoin="round"
    />
  </svg>
);
export default CircleIcon;
