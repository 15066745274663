import { useCallback, useRef } from "react";
import mapboxgl from "mapbox-gl";

import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchCommunities } from "store/communities/initialState";
import {
  useCreateMarkers,
  useFetchCommunityRegionLayers,
  useInitializeMap,
  usePaintLayers,
} from "./hooks";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export default function CommunityDirectoryMap() {
  const mapContainer = useRef<null | HTMLDivElement>(null);

  const { data } = useSelector<RootState, IfetchCommunities>(
    (state) => state?.allCommunities
  );
    console.log("communities data: ",data);
  const centerCoordinatesDataOfCommunities = useCallback(
    parseLocationFromCommunities(data?.communities),
    [data]
  );

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // creating markers for individual community
  const currentSelectedCommunity = useCreateMarkers(
    centerCoordinatesDataOfCommunities,
    map,
    isMapFullyLoaded
  );

  // fetching region layer of selected community
  const regionLayers = useFetchCommunityRegionLayers(currentSelectedCommunity);

  // painting regions layers of selected community
  usePaintLayers(regionLayers, map, isMapFullyLoaded);

  return (
    <section className="bg-primaryDark  h-[60vh] w-[100%] relative ">
      <div ref={mapContainer} className="h-full w-full"></div>
    </section>
  );
}

// utils
function parseLocationFromCommunities(data: any) {
  console.log("communities in data: ",data);
  const locations = data?.flatMap((item: any) =>
    item.location !== null
      ? { location: item.location, communityId: item.communityName.id }
      : {}
  );
  console.log("locations are: ",locations);
  return locations ?? [];
}
