import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useHandleClickOnPoint = (
  clickedPoint: any,
  setClickedPoint: any,
  currentQuestionEle: any,
  questionName: any,
  setCurrentQuestionEle: any,
  editMode: boolean,
  answer: any = [],
  selectedPoints: any,
) => {
  useEffect(() => {
    if (!editMode) {
      setClickedPoint(null);
      return;
    }

    if (clickedPoint) {
      const pointId = clickedPoint.id;

      const alreadyExistedAnswer: any = answer?.find((p: any) => p.id === pointId);

      if (!alreadyExistedAnswer) {
        const pointAns = selectedPoints.find((p: any) => p.id === clickedPoint.id);
        setCurrentQuestionEle(questionName, [
          ...answer ?? [],
          pointAns,
        ]);
      } else {
        setCurrentQuestionEle(
          questionName,
          answer.filter(
            (answerPoint: any) => answerPoint.id !== alreadyExistedAnswer.id
          )
        );
      }
      // reset current clicked marker to null
      setClickedPoint(null);
    }
  }, [clickedPoint]);

  return null;
};

export { useHandleClickOnPoint };
