import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { pollsAndSurveysProgramsAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

export const SelectedPrograms: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { pollsAndSurveysFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

const resetFilter = (program: any) => {
  dispatch(pollsAndSurveysProgramsAction(program));
};

  if (pollsAndSurveysFilter.programIds?.applied) {
    return pollsAndSurveysFilter.programIds.value.map(
      (item: any, index: number) => (
        <div
          key={index}
          className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
        >
          <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
            <p className="flex-shrink-0 w-full text-sm break-all text-start text-textMid dark:text-inputText">
              {item.name}
            </p>
          </div>
          <ClearFilters handleClick={() => resetFilter(item)} />
        </div>
      )
    );
  } else {
    return null;
  }
};
