import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface ImageThumbnailProps {
  url: string;
  userId: number;
  sendTime: string;
  handlePreviewFiles: () => void;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  url,
  userId,
  sendTime,
  handlePreviewFiles,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  return (
    <div
    onClick={handlePreviewFiles}
      className={`max-w-[246px] max-h-[246px] p-2 rounded-md cursor-pointer relative ${userId === auth.id ? ' bg-bgChatMessage dark:bg-primary_8 ' : '  bg-bgWhite dark:bg-bgtetriary  '}`}
    >
      <img src={url} alt='Image Thumbnail' className='w-full h-full rounded' />
      <span className='absolute bottom-3 right-5 text-xs text-textWhite dark:text-caption'>
        {sendTime}
      </span>
    </div>
  );
};

export default ImageThumbnail;
