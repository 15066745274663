// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router";

// import ErrorImage from "../../assets/images/500.png";

// export default function ErrorBoundary({ children }: any) {
//   const [hasError, setHasError] = useState(false);
//   const location = useLocation();
//   useEffect(() => {
//     if (hasError) {
//       setHasError(false);
//     }
//   }, [location.key]);
//   return (
//     <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
//       {children}
//     </ErrorBoundaryInner>
//   );
// }

// interface ErrorBoundaryInnerProps {
//   hasError?: boolean;
//   setHasError?: any;
//   children?: any;
// }
// class ErrorBoundaryInner extends React.Component<any, ErrorBoundaryInnerProps> {
//   constructor(props: any) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(_error: any) {
//     return { hasError: true };
//   }

//   componentDidUpdate(prevProps: any, _previousState: any) {
//     if (!this.props.hasError && prevProps.hasError) {
//       this.setState({ hasError: false });
//     }
//   }

//   componentDidCatch(_error: any, _errorInfo: any) {
//     this.props.setHasError(true);
//   }

//   render() {
//     return this.state.hasError ? (
//       <div className="flex items-center justify-center h-full">
//           <img src={ErrorImage} />
//           {/* @ts-ignore */}
//           {/* <p className="capitalize dark:text-textMain">{this.state.error.message}</p> */}
//         </div>
//     ) : (
//       this.props.children
//     );
//   }
// }

// interface MyFallbackComponentProps {
//   error: Error;
//   componentStack?: string;
//   resetErrorBoundary: () => void;
// }

// const MyFallbackComponent: React.FC<MyFallbackComponentProps> = ({
//   error,
//   resetErrorBoundary,
// }) => {
//   return (
//     <div role="alert">
//       <img src={ErrorImage} alt="Error occurred" />
//       <p>An error occurred: {error.toString()}</p>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   );
// };

// interface ErrorBoundaryWrapperProps {
//   children: React.ReactNode;
// }
// type FallbackRender = (props: MyFallbackComponentProps) => React.ReactElement;
// interface ErrorBoundaryWrapperProps {
//   children: React.ReactNode;
// }

// const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({
//   children,
// }) => {
//   const fallback: FallbackRender = ({ error, resetErrorBoundary }) => (
//     <MyFallbackComponent
//       error={error}
//       resetErrorBoundary={resetErrorBoundary}
//     />
//   );
//   return (
//     <ErrorBoundary fallback={fallback} showDialog>
//       {children}
//     </ErrorBoundary>
//   );
// };

import React, { useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import BackgroundImage from "assets/images/404.png";
import Head from "view/components/Head";
import Button from "view/components/Button";
import { useNavigate } from "react-router-dom";
interface MyFallbackComponentProps {
  error: Error;
  componentStack: string;
  eventId: string;
  resetErrorBoundary: () => void;
}

const MyFallbackComponent: React.FC<MyFallbackComponentProps> = ({
  error,
  componentStack,
  eventId,
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();

  // const handleReset = () => {
  //   const errorReset = JSON.parse(
  //     localStorage.getItem("errorReset") || "false"
  //   );

  //   if (error) {
  //     if (errorReset) {
  //       localStorage.removeItem("errorReset");
  //       window.location.href = "/";
  //     } else {
  //       localStorage.setItem("errorReset", "true");
  //       resetErrorBoundary();
  //     }
  //   } else {
  //     localStorage.setItem("errorReset", "false");
  //   }
  // };

  const hasPreviousUrlFromSameDomain = () => {
    const currentUrl = window.location.href;
    const previousUrl = document.referrer;

    return (
      previousUrl && new URL(previousUrl).origin === new URL(currentUrl).origin
    );
  };

  const handleReset = () => {
    const errorReset = JSON.parse(
      localStorage.getItem("errorReset") || "false"
    );

    if (error) {
      if (errorReset) {
        localStorage.removeItem("errorReset");

        // if (hasPreviousUrlFromSameDomain()) {
        //   console.log("document.referrer;", document.referrer);
        //   window.location.href = document.referrer;
        //   return;
        // }
        window.location.href = "/";
      } else {
        localStorage.setItem("errorReset", "true");
        resetErrorBoundary();
      }
    } else {
      localStorage.setItem("errorReset", "false");
    }
  };

  const errorReset = JSON.parse(localStorage.getItem("errorReset") || "false");

  const renderButtonText = () => {
    if (errorReset) {

      return "Go to home page";
      if (hasPreviousUrlFromSameDomain()) {
        return "Go back to previous page";
      } else {
        return " Back to home page";
      }
    }
    return "Try again";
  };

  console.log("error", error)
  return (
    <div
      className="relative flex justify-between w-full h-screen"
      style={{
        backgroundImage: `url('${BackgroundImage}')`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
      }}
    >
      <div className="absolute transform -translate-x-1/2 left-1/2">
        <div className="flex justify-center w-full mt-20">
          <div className="flex flex-col items-center px-10 py-6 shadow-xl md:px-14 bg-bgWhite bg-opacity-40 dark:bg-secondaryLight rounded-2xl">
            <p className="text-2xl lg:text-[32px] font-bold text-center text-textNormal dark:text-textMain pb-4">
              {`An error occurred: ${error.toString()}`}
            </p>

            <Button
              type="button"
              text={renderButtonText()}
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2"
              width="35"
              height="13"
              fontStyle="font-semibold"
              variant="filled"
              iconPosition="before"
              onClick={handleReset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
interface ErrorBoundaryWrapperProps {
  children: React.ReactNode;
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack, eventId, resetError }) => (
        <MyFallbackComponent
          error={error}
          componentStack={componentStack}
          eventId={eventId}
          resetErrorBoundary={resetError}
        />
      )}
      showDialog
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
