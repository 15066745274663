import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;

}

export const MembersKpiIcon = ({ fill, isActive, ...restProps }: IconProps) => (
    <svg width="17" height="16" viewBox="0 0 17 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
        {...restProps}>
        <path
            fill={fill}
            className={`dark:${isActive
                ? "fill-primary"
                : "fill-caption group-hover:dark:fill-primary"
                }`}
            fillRule="evenodd" fill-rule="evenodd" clip-rule="evenodd" d="M6.50065 2.66536C5.39608 2.66536 4.50065 3.5608 4.50065 4.66536C4.50065 5.76993 5.39608 6.66536 6.50065 6.66536C7.095 6.66536 7.62879 6.40611 7.99511 5.99453C7.99855 5.99054 8.00204 5.98658 8.00559 5.98266C8.31383 5.6308 8.50065 5.1699 8.50065 4.66536C8.50065 4.16082 8.31382 3.69991 8.00557 3.34804C8.00204 3.34414 7.99856 3.3402 7.99513 3.33623C7.62881 2.92463 7.09501 2.66536 6.50065 2.66536ZM8.50072 1.9985C7.9436 1.58001 7.25109 1.33203 6.50065 1.33203C4.6597 1.33203 3.16732 2.82442 3.16732 4.66536C3.16732 6.50631 4.6597 7.9987 6.50065 7.9987C7.25109 7.9987 7.9436 7.75071 8.50072 7.33222C9.05755 7.75031 9.75033 7.9987 10.5007 7.9987C12.3416 7.9987 13.834 6.50631 13.834 4.66536C13.834 2.82442 12.3416 1.33203 10.5007 1.33203C9.75033 1.33203 9.05755 1.58042 8.50072 1.9985ZM9.38985 3.00182C9.67235 3.4914 9.83398 4.05951 9.83398 4.66536C9.83398 5.27122 9.67235 5.83933 9.38985 6.32891C9.70775 6.54174 10.0896 6.66536 10.5007 6.66536C11.6052 6.66536 12.5007 5.76993 12.5007 4.66536C12.5007 3.56079 11.6052 2.66536 10.5007 2.66536C10.0896 2.66536 9.70775 2.78899 9.38985 3.00182ZM6.50065 9.9987C4.6597 9.9987 3.16732 11.4911 3.16732 13.332H9.83398C9.83398 11.4911 8.3416 9.9987 6.50065 9.9987ZM11.1673 13.332H13.834C13.834 11.4911 12.3416 9.9987 10.5007 9.9987C10.2719 9.9987 10.0487 10.0217 9.83334 10.0654C10.6585 10.9071 11.1673 12.0602 11.1673 13.332ZM8.50084 9.11454C7.89462 8.82653 7.21646 8.66536 6.50065 8.66536C3.92332 8.66536 1.83398 10.7547 1.83398 13.332V13.9987C1.83398 14.3669 2.13246 14.6654 2.50065 14.6654H14.5007C14.8688 14.6654 15.1673 14.3669 15.1673 13.9987V13.332C15.1673 10.7547 13.078 8.66536 10.5007 8.66536C9.78598 8.66536 9.10751 8.82644 8.50084 9.11454Z"
        // fill="#2C3236"
        />
    </svg>
);
export default MembersKpiIcon;

