import { snakeCase } from "change-case";
import { apiClient } from "../config";

async function getUsersFilterDropdowns(
  page: number,
  entity: any,
  search?: string,
  programId?: any,
  communityId?: any
) {
  // const queryString = search ? `&search_string=${search}` : "";

  const params = {
    page: page,
    entity: snakeCase(entity),
    searchString: search,
    ...(communityId ? { communityId } : {}),
    ...(programId ? { programId } : {}),
  };
  const res = await apiClient.get(`/admin/users/drop-down`, {
    params,
  });
  return res.data;
}

export default getUsersFilterDropdowns;
