
import React, { useEffect } from 'react'
import questionTypes from '../questionTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import appendElementToArray from '../utils/appendElementToArray';
import { updateDrawPolygons, updateDropPins, updateGetGpsDatas, updatePointPickers, updateShapePickers } from 'store/surveyJS';
import { useDispatch } from 'react-redux';

const useHandleStateOfMapTypeQuestionsOnAdded = () => {
  const { shapePickers, pointPickers, drawPolygons, dropPins, getGpsDatas, addedQuestionState } = useSelector((state: RootState) => state.surveyJS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addedQuestionState) {
      switch (addedQuestionState.type) {
        case questionTypes.shapePicker:
          const shapePickersState = shapePickers ?? [];

          appendElementToArray(addedQuestionState, shapePickersState);

          dispatch(updateShapePickers([...shapePickersState]));
          break;

        case questionTypes.pointPicker:
          const pointPickersState = pointPickers ?? [];

          appendElementToArray(addedQuestionState, pointPickersState);

          dispatch(updatePointPickers([...pointPickersState]));
          break;

        case questionTypes.drawPolygon:
          const drawPolygonsState = drawPolygons ?? [];

          appendElementToArray(addedQuestionState, drawPolygonsState);

          dispatch(updateDrawPolygons([...drawPolygonsState]));
          break;

        case questionTypes.dropPin:
          const dropPinsState = dropPins ?? [];

          appendElementToArray(addedQuestionState, dropPinsState);

          dispatch(updateDropPins([...dropPinsState]));
          break;

        case questionTypes.getGpsData:
          const getGpsDatasState = getGpsDatas ?? [];
          console.log("getGpsDatasState", getGpsDatasState)
          appendElementToArray(addedQuestionState, getGpsDatasState);

          dispatch(updateGetGpsDatas([...getGpsDatasState]));
          break;
      }
    }
  }, [addedQuestionState]);

  return null;
}

export { useHandleStateOfMapTypeQuestionsOnAdded }