export const panelsTitle =  {
  RIVER_CONDITION: "River Conditions",
  GPS_INFO:"GPS Info",
  SPECIES_DETAIL:"Species Details",
  COUNT_DETAILS:"Count Details",
  SIGHTING_DETAIL:"Sighting Details",
  WEATHER_CONDITIONS:"Weather Conditions",
  GENERAL_INFORMATION:"General Information",
  GEOGRAPHICAL_INFO:"Geographical Information",
  HARVEST:"Harvest",
  SAMPLE:"Sample"
};
export const panelsName = {
  COUNT_DETAILS:"count_detail",
  GENERAL_INFORMATION:"general_information",
  GEOGRAPHICAL_INFORMATION:"count_geographical_information",
  GPS_INFORMATION:"gps_information",
  HARVEST:"harvest",
  RIVER_CONDITION:"river_condition",
  SIGHTING_DETAIL:"sighting_detail",
  SPECIES_DETAIL:"species_detail",
  WEATHER_CONDITION:"weather_condition",
  SAMPLE:"sample",
  ENVIRONMENTAL_CONDITIONS:"environmental_condition"
}
