import { useEffect } from "react";
import Map from "./Map";
import Sidebar from "./Sidebar";
import HeaderBar from "./Header";
import { displaySurveyView } from "store/surveyJS";
import { useDispatch } from "react-redux";

const Geography = ({ dataSourcesData }: { dataSourcesData: any }): any => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(displaySurveyView());
    };
  }, []);

  return (
    <main
      className="flex flex-col w-full overflow-hidden"
      style={{ height: "calc(99vh)" }}
    >
      <HeaderBar />
      <div className="flex justify-between w-full h-full ">
        <Map />
        <Sidebar dataSourcesData={dataSourcesData} />
      </div>
    </main>
  );
};

export { Geography };
