import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormikProps, FormikValues } from "formik";
import { RootState } from "store";
import { DarkMode } from "store/darkMode/initialState";
import { useSelector } from "react-redux";

interface TextEditorProps extends FormikProps<FormikValues> {
  errors: any;
}

export const TextEditor: React.FC<TextEditorProps> = React.memo(
  ({ values, setFieldValue, errors, touched, handleChange }) => {
    const handleEditorChange = (content: any, editor: any) => {
      setFieldValue("policyContent", content);
    };
    const { isDarkMode } = useSelector<RootState, DarkMode>(
      (state) => state?.darkMode
    );

    return (
      <>
        {/* <Editor
          value={values?.policyContent}
          init={{
            selector:"textarea",
            min_height: 300,
            height: 300,
            menubar: false,
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "fontsize",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
              "subscript",
            ],
            toolbar:
              "undo redo | formatselect fontsize | backcolor | bold italic |anchor| alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent " +
              "superscript subscript ",
            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
            placeholder: "Add your description....",
          }}
          onEditorChange={handleEditorChange}
        />
        
        */}

        <Editor
          value={values?.policyContent}
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          init={{
            min_height: 300,
            height: 300,
            menubar: false,
            contextmenu: false,
            skin: isDarkMode ? 'oxide-dark' : 'oxide',
            content_css: isDarkMode ? 'dark' : 'default',
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "image",
              "charmap",
              "preview",
              "searchreplace",
              "visualblocks",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
              "anchor",
              "link"
            ],
            // toolbar:
            //   "undo redo | formatselect fontsize | backcolor | bold italic | alignleft aligncenter " +
            //   "alignright alignjustify | bullist numlist outdent indent  ",

            toolbar:
              "undo redo | formatselect fontsize | backcolor | bold italic | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | anchor link",

            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
            placeholder: "Add your description....",

            // link_context_toolbar: true, // Enable link context toolbar
            // default_link_target: "_blank",  // Set default link target
          }}
          onEditorChange={handleEditorChange}
        />

        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {touched.policyContent && errors.policyContent && (
            <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark mb-2">
              {errors.policyContent}
            </p>
          )}
        </div>
      </>
    );
  }
);
