// Define an interface for the community data
export interface ICommunity {
  accessibleForSuperAdmin: boolean;
  communityAdmins: {
    id: number;
    name: string;
    profileImage: {
      file: null | string;
      id: null | number;
      path: null | string;
    };
  }[];
  communityName: string;
  communityRegions: null | any[];
  dateAdded: string;
  id: number;
  location: {
    coordinates: [number, number];
    crs: {
      properties: {
        name: string;
      };
      type: string;
    };
    type: string;
  };
  members: number;
  organizations: any[];
  profileImage: null | string;
  programs: number;
  status: string;
  type: string;
  locationData: any;
  openAccess: boolean;
}

export interface communitiesData {
  communities: ICommunity[];
  fields: string[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchCommunities {
  data: communitiesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchCommunities = {
  data: {
    communities: [],
    fields: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
