import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IOrganization } from "store/organizationProfile/initialState";
import { useNavigate } from "react-router-dom";
import { openCreateOrganizationModalAction } from "store/modals";
import { useDispatch } from "react-redux";
import { getBrandPlaceholder } from "utils/customBranding";

export const AdminsListingCard = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editOrganization = () => {
    const {
      address,
      missionStatement,
      name,
      organizationImage,
      organizationAdmins,
      organizationCommunities,
      organizationTags,
      id,
      coverImage,
    } = organizationProfile;

    const payload = {
      communityIds: organizationCommunities,
      missionStatement: missionStatement,
      name: name,
      organizationImageId: organizationImage.id,
      coverImageId: coverImage?.id ?? null,
      coverImage: coverImage?.original,
      tagIds:
        organizationTags?.length > 0
          ? organizationTags?.map((item: any) => {
            return { value: item.id, label: item.name };
          })
          : [],
      adminIds: organizationAdmins?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          profileImage: item?.profileImage,
        };
      }),
      profileImage: organizationImage.file.original,
      id,
      openAtStep: 2,
    };
    dispatch(openCreateOrganizationModalAction(payload, true));
  };

  const handleNavigate = (admin: any) => {
    navigate("/messages", { state: { user: admin, organizationProfile } });
  };
  return (
    <div className="flex flex-col w-full rounded-lg shadow-md lg:p-4 xl:p-6 bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight hover:shadow-xl">
      <div className="flex justify-between w-full mb-1">
        <p className="text-base font-semibold dark:text-textMain">{`${organizationProfile?.noOfAdmins} ${organizationProfile.noOfAdmins > 1 ? "Admins" : "Admin"
          }`}</p>


        {!organizationProfile.openAccess && <button
          onClick={() => editOrganization()}
          className="flex-grow-0 flex-shrink-0 "
        >
          <p className="text-sm font-semibold text-primary dark:text-primaryMidLight hover:text-primaryMid">
            Manage All
          </p>
        </button>}

      </div>

      {organizationProfile?.organizationAdmins
        .slice(0, 5)
        ?.map((admin: any, index: number) => {
          return (
            <div key={index} className="flex mt-4 align-center">
              <div className="w-8">
                <div className="w-8 h-8 mt-2 rounded-full">
                  <img
                    src={
                      admin?.profileImage?.file?.thumb
                        ? admin?.profileImage?.file?.thumb
                        : getBrandPlaceholder("listingProfile")
                    }
                    alt={admin?.name}
                    className="w-full rounded-full"
                  />
                </div>
              </div>
              <div className="ml-4">
                <p className="text-[15px] break-words break-all dark:text-textMain">
                  {admin?.name}
                </p>
                <button
                  className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative py-[3px]"
                  onClick={() => handleNavigate(admin)}
                >
                  <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-primary">
                    Send a message
                  </p>
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};
