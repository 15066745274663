import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_HORIZONTAL_BAR_GRAPH,
  GET_HORIZONTAL_BAR_GRAPH_STATE,
  RESET_DATA,
} from "../action-types";
import { appendCommunityIdsAndOrganizationIdsAndProgramIdsInParams } from "../reducer.actions";
import { HBGModule } from "./types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- HORIZONTAL BAR GRAPH
 * @param moduleName
 * @returns
 */
export const fetchHorizontalBarGraph =
  (moduleName: HBGModule) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_HORIZONTAL_BAR_GRAPH_STATE,
      payload: {
        name: moduleName,
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();

      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      // APPENDING MODULE NAME
      Object.assign(params, {
        module: moduleName,
      });

      const { data } =
        await apiLibrary.Dashboard.widgets.getHorizontalBarGraph(params);
      dispatch({
        type: GET_HORIZONTAL_BAR_GRAPH,
        payload: { name: moduleName, data },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_HORIZONTAL_BAR_GRAPH_STATE,
        payload: {
          name: moduleName,
          stateIs: "Error",
        },
      });
      console.log(
        "An error occurred while fetching the horizontal bar graph:",
        error
      );
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
