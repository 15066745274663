import Roles from "hooks/roles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import apiLibrary from "services/api";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import {
  profileSettingsAction,
  userProfileSettingsAction,
} from "store/userProfilesettings";
import { Toasts } from "view/components/Toasts";
import SelectInput from "../SelectInput";
import usePermissions from "hooks/usePermissions";

export const LanguageSelector = () => {
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const { isMyProfile } = Roles();
  const { profile } = usePermissions();

  // Getting user profile from Redux state
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  // const Languages = ["English", "German", "French"];
  const Languages = ["English"];

  const dispatch = useDispatch();

  // Function to update user profile settings
  const updateUserProfileSettings = async (e: any) => {
    if (!profile.canEditSettingsProfile) {
      return;
    }
    try {
      const { name, value } = e.target;
      const res = await apiLibrary.Settings.updateUserProfileSettings(
        name,
        value
      );
      // Updating Redux state with new settings
      if (isMyProfile(userProfile.personId)) {
        dispatch(userProfileSettingsAction(res.data));
      }
      dispatch(profileSettingsAction(res.data));

      dispatch(profileSettingsAction(res.data));
      Toasts.success(res.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("An error occurred:", error);
    }
  };

  const isProfileVisible = isMyProfile(userProfile.personId);

  return (
    <div className="flex flex-col w-full gap-1">
      <p className="text-sm font-medium capitalize text-secondaryMid dark:text-textMain">
        Language
      </p>
      {isProfileVisible ? (
        <SelectInput
          name="language"
          updateUserProfileSettings={updateUserProfileSettings}
          value={profileSettings.language}
          disabled={!profile.canEditSettingsProfile}
          data={Languages}
        />
      ) : (
        <p className="capitalize dark:text-textMain">
          {profileSettings.language}
        </p>
      )}
    </div>
  );
};
