import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
    closeImportInfoModalAction,
    closeImportStatusDetailModalAction,
} from "store/modals/reducer.actions";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { ImportStatusDetail } from "store/importStatusDetail";
import { ImportStatusDetailData } from "store/importStatusDetail/initialState";
import { sentenceCase } from "change-case";
import { ImportInfoData } from "store/importInfo/initialState";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


export const ImportInfoModal = () => {
    const { importInfoModal } = useSelector((state: RootState) => state.modals);
    const navigate = useNavigate();
    const { data } = useSelector<RootState, ImportInfoData>(
        (state) => state?.importInfo
    );

    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const openModal = () => {
        // setModalOpen(formDetailsModal?.isOpen);
    };
    const handleClose = () => {
        dispatch(closeImportInfoModalAction());
    };

    return (
        <SwipeableDrawer
            anchor="right"
            open={importInfoModal?.isOpen}
            onClose={handleClose}
            onOpen={openModal}
        >
            <Box sx={{ width: 480, height: "100%" }} role="presentation" onKeyDown={handleClose}>
                <div
                    className="flex flex-col items-start justify-start w-full h-full bg-white dark:bg-secondary"
                >
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 py-4 pl-3 pr-6 bg-white">
                        <div className="relative flex items-center self-stretch justify-start gap-2 px-3 py-2 rounded-lg">
                            <button
                                onClick={handleClose}
                                className="flex items-center mr-2 space-x-2 text-textMid hover:underline"
                            >
                                <ArrowLeftIcon height={22} width={22} />
                            </button>

                            <div className="flex items-center justify-start flex-grow">
                                <div className="flex justify-start items-center text-secondaryMid flex-grow relative py-0.5">
                                    <h3 className="text-xl font-semibold capitalize text-textMid font-Overpass dark:text-textMain">
                                        Import Info
                                    </h3>
                                </div>

                                <button onClick={handleClose} className="mb-1">
                                    {" "}
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                            className="dark:fill-textMain fill-[#2C3236]"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6 px-3 py-4">
                            <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
                                <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5 pb-2">

                                    <div className="grid grid-cols-2 gap-x-10 gap-y-4 w-full">

                                        <div className="col-span-2">
                                            <p className="text-sm text-left font-Overpass text-textMidLight dark:text-caption dark:text-caption">
                                                File Name
                                            </p>
                                            <p className="text-md font-semibold font-Overpass text-left text-secondary dark:text-inputText">
                                                {data?.fileName}
                                            </p>
                                        </div>

                                        <div className="col-span-2">
                                            <p className="text-sm text-left font-Overpass text-textMidLight dark:text-caption">
                                                Data Received From
                                            </p>
                                            <p className="text-md font-regular capitalize font-Overpass text-left text-secondary dark:text-inputText">
                                                {data?.dataReceivedFrom}
                                            </p>
                                        </div>

                                        <div className="col-span-2">
                                            <p className="text-sm text-left font-Overpass text-textMidLight dark:text-caption">
                                                Imported By
                                            </p>
                                            <p className="text-md font-regular font-Overpass text-left text-secondary dark:text-inputText">
                                                {data?.importedBy?.name}
                                            </p>
                                        </div>

                                        <div className="col-span-2">
                                            <p className="text-sm text-left font-Overpass text-textMidLight dark:text-caption">
                                                Imported Date
                                            </p>
                                            <p className="text-md font-regular font-Overpass text-left text-secondary dark:text-inputText">
                                                {moment.utc(data?.importDate).format('DD MMM YYYY, hh:mm A')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </SwipeableDrawer>
    );
};
