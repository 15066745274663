import * as React from 'react';
export const VideoPlayIcon = () => (
  <svg
    width='65'
    height='65'
    viewBox='0 0 65 65'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M32.454 56.1893C45.5627 56.1893 56.1893 45.5627 56.1893 32.454C56.1893 19.3454 45.5627 8.71875 32.454 8.71875C19.3454 8.71875 8.71875 19.3454 8.71875 32.454C8.71875 45.5627 19.3454 56.1893 32.454 56.1893Z'
      fill='#D9D9D9'
      fillOpacity='0.66'
    />
    <path
      d='M43.5608 30.0966L26.4872 21.5572C26.0045 21.3157 25.4681 21.2016 24.9289 21.2258C24.3897 21.2501 23.8657 21.4118 23.4066 21.6956C22.9476 21.9795 22.5687 22.376 22.3061 22.8475C22.0435 23.3191 21.9059 23.8499 21.9063 24.3896V40.5191C21.9059 41.0588 22.0435 41.5897 22.3061 42.0612C22.5687 42.5327 22.9476 42.9292 23.4066 43.2131C23.8657 43.4969 24.3897 43.6586 24.9289 43.6829C25.4681 43.7071 26.0045 43.593 26.4872 43.3515L43.5608 34.8121C43.9982 34.5928 44.3661 34.2562 44.6231 33.8398C44.8802 33.4234 45.0163 32.9437 45.0163 32.4544C45.0163 31.965 44.8802 31.4853 44.6231 31.0689C44.3661 30.6526 43.9982 30.3159 43.5608 30.0966Z'
      fill='white'
    />
  </svg>
);
export default VideoPlayIcon;
