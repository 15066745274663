import { Link } from 'react-router-dom';
import { ColumnProps } from '../../types';
import { RenderCellContent } from '../index';

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  programId,
  rowData,
}: ColumnProps) => {
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;

  if (isColumnVisible && cell.column.columnDef.id !== 'select') {
    return (
      <td key={cell.id} className={`px-4 ${hoverEffect}`}>
        {cell.column.columnDef?.id === "protocolName" ?
          <Link to={`/protocols/${rowData?.original?.protocolName?.id}/profile`}>
            <RenderCellContent
              columnDef={cell.column.columnDef}
              cellValue={cell.getValue()}
              programId={programId}
            />
          </Link>
          :
          <RenderCellContent
            columnDef={cell.column.columnDef}
            cellValue={cell.getValue()}
            programId={programId}
          />}
      </td>
    );
  } else {
    return (
      <td
        key={cell.id}
        className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }
};
