import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface AudioThumbnailProps {
  url: string;
  userId: number;
  sendTime?: string;
  handlePreviewFiles: () => void;
}

const AudioThumbnail: React.FC<AudioThumbnailProps> = ({
  url,
  userId,
  sendTime,
  handlePreviewFiles,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  return (
    <div
      className={`flex flex-col p-4 rounded-md ${userId === auth.id ? ' bg-bgChatMessage dark:bg-primary_8 ' : 'text-secondaryMidLight dark:text-textMain  bg-bgWhite dark:bg-bgtetriary  '}  `}
    >
      <audio controls>
        <source src={url} type='audio/mp3' />
        Your browser does not support the audio element.
      </audio>
      <span className='ml-auto pt-2 text-xs text-textLight dark:text-caption'>
        {sendTime}
      </span>
    </div>
  );
};

export default AudioThumbnail;
