import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  Date,
  Members,
  SelectedCommunities,
  SelectedPrograms,
  SelectedTags,
} from "./Components";
import { useParams } from "react-router-dom";
import {
  orgzanizationsResetFiltersAction,
  orgzanizationsResetFiltersExceptCommunityAction,
  orgzanizationsResetFiltersExceptProgramAction,
} from "store/filters/reducer.actions";
import { useDispatch } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  const { organizationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const clearAllFilters = () => {
    // if (communityId) {
    //   dispatch(orgzanizationsResetFiltersExceptCommunityAction());
    // } else if (programId) {
    //   dispatch(orgzanizationsResetFiltersExceptProgramAction());
    // } else {
    // }

    dispatch(orgzanizationsResetFiltersAction());
  };
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyOrganizationFilterApplied(organizationsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary dark:text-primaryMid">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div className="flex max-w-[90%] items-center justify-start flex-wrap">
        <div className="flex flex-wrap gap-2">
          <SelectedTags />
          {/* <Members
            name="programIds"
            min={organizationsFilter.programIds.min}
            max={organizationsFilter.programIds.max}
            isApplied={organizationsFilter.programIds?.applied}
          /> */}
          <SelectedPrograms />
          <SelectedCommunities />

          <Members
            name="members"
            min={organizationsFilter.members.min}
            max={organizationsFilter.members.max}
            isApplied={organizationsFilter.members?.applied}
          />

          <Date
            name="dateAdded"
            from={organizationsFilter.dateAdded.from}
            to={organizationsFilter.dateAdded.to}
            isApplied={organizationsFilter.dateAdded?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

export function isAnyOrganizationFilterApplied(
  organizationsFilter: any
): boolean {
  const {
    searchBy,
    searchString,
    dateAdded,
    programIds,
    members,
    communityIds,
    tagIds,
  } = organizationsFilter;
  const applied =
    (searchBy && searchString !== "") ||
    dateAdded?.applied ||
    programIds?.applied ||
    members?.applied ||
    communityIds?.applied ||
    tagIds?.applied;
  return applied;
}
