import { ClearFilters } from "Components/Users/ClearFilters";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { communitiesTypeAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { sentenceCase } from "change-case";

export const Type = React.memo(() => {
  const { communitiesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  const statusLabels = ["open", "private"];
  const isTypeApplied = communitiesFilter.type?.applied;
  const isTypeValueValid = statusLabels.includes(communitiesFilter.type.value);
  const resetFilter = () => {
    dispatch(communitiesTypeAction(""));
  };

  if (isTypeApplied && isTypeValueValid) {
    return (
      <div className="flex flex-wrap justify-center items-center relative gap-1 px-2 capitalize  rounded-lg bg-primary/[0.08]">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">
            {sentenceCase(communitiesFilter.type.value)}
          </p>
        </div>
        <ClearFilters handleClick={resetFilter} />
      </div>
    );
  } else {
    return null; // or fallback JSX if needed
  }
});
