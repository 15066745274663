export const TIME_FORMATS = {
  FULL_TIME: "HH:mm:ss",
  HOURS_MINUTES: "HH:mm",
  HOURS_MINUTES_AMPM: "hh:mm A",
  FULL_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
  ISO_8601: "YYYY-MM-DDTHH:mm:ssZ",
  FRIENDLY_TIME: "h:mm:ss a",
  HOURS_MINUTES_SECONDS: "HH:mm:ss",
  HOURS_MINUTES_SECONDS_AMPM: "hh:mm:ss A",
  HOURS_MINUTES_24H: "HH:mm",
  HOURS_12H: "hh A",
  HOURS_MINUTES_NO_LEADING_ZERO: "H:mm",
  HOURS_MINUTES_AMPM_NO_LEADING_ZERO: "h:mm A",
  FULL_DATE_TIME_WITH_DAY: "dddd, YYYY-MM-DD HH:mm:ss",
  FRIENDLY_DATE_TIME: "dddd, MMMM Do YYYY, h:mm:ss a",
  TIME_WITH_ZONE: "HH:mm:ss Z",
  FULL_DATE_TIME_WITH_ZONE: "YYYY-MM-DD HH:mm:ss Z",
  FULL_DATE_TIME_ISO_8601: "YYYY-MM-DDTHH:mm:ss.SSSZ",
  RFC_2822: "ddd, DD MMM YYYY HH:mm:ss ZZ",
  SHORT_TIME: "h:mm A",
  TIME_24H_WITHOUT_SECONDS: "HH:mm",
  TIME_12H_WITHOUT_SECONDS: "hh:mm A",
};
