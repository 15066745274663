import { apiClient } from "../config";

async function getImportedFileData(id: number, queryParams: any) {
  const res = await apiClient.get(`/admin/imports/${id}/view-data`, {
    params: queryParams,
  });
  return res.data;
}

export default getImportedFileData;
