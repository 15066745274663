import React, { ChangeEvent } from "react";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { CustomSelectProps, Option } from "../../types";


const CustomSelector: React.FC<CustomSelectProps> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <div className="relative inline-block w-full cursor-pointer">
      <select
        title="select"
        className="block w-full py-1 pr-8 leading-tight border-none dark:border-lineLight bg-[transparent] appearance-none cursor-pointer focus:outline-none focus:border-none"
        value={value}
        onChange={onChange}
      >
        {options.map((option: Option) => (
          <option
            className="text-center cursor-auto dark:bg-secondaryLight"
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <CheveronDownIcon height={20} width={20} />
      </div>
    </div>
  );
};

export { CustomSelector };