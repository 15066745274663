import FacebookIcon from "assets/images/Auth/FacebookIcon.png";
import { facebookAppId } from "config";
import React from "react";
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toasts } from "view/components/Toasts";

import apiLibrary from "services/api";
import { loginAction } from "store/auth";
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
import Cookies from "universal-cookie";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const FacebookLoginButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const responseFacebook = async (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {
    if ("name" in response) {
      const { id, name, email, picture, accessToken } =
        response as ReactFacebookLoginInfo & { accessToken: string };

      try {
        // dispatch(setLoadingProgress(getRandomProgress()));
        const res = await apiLibrary.Auth.socialLogin(
          convertKeysToSnakeCase({
            accessToken,
            channel: "facebook",
          })
        );

        const refreshTokenExpiry = moment().add(365, "days").toDate();

        // Save token and refresh token to cookies with expiry
        cookies.set("token", res.data.accessToken, {
          path: "/",
        });

        cookies.set("refreshToken", res.data.refreshToken, {
          path: "/",
          expires: refreshTokenExpiry,
        });

        Toasts.success(res.message);
        dispatch(loginAction(res.data));
        window.location.reload();
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      } finally {
        // dispatch(setLoadingProgress(100));
      }
    } else {
      console.error("Facebook login failed:", response);
    }
  };

  const appId = facebookAppId;
  return (
    <FacebookLogin
      appId={appId}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      render={(renderProps: any) => (
        <button
          onClick={renderProps.onClick}
          type="button"
          className="flex items-center justify-center flex-grow relative h-10 w-[86px] rounded-3xl border-2 bg-bgWhite dark:bg-secondaryLight border-textLink hover:bg-primaryExtraLight dark:hover:bg-primary dark:border-lineLight dark:hover:border-textLink"
          aria-label="Facebook"
        >
          <img src={FacebookIcon} alt="" />
        </button>
      )}
    />
  );
};
