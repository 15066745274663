import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import Head from "view/components/Head";
import { IformDetails } from "store/formDetails";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchFormDatails } from "store/formDetails/initialState";
import CustomToolTip from "view/components/ToolTip";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { InformationCircleIcon } from "assets/icons/HeroIcons";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { displaySurveyView } from "store/surveyJS";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const generateDynamicUserLinks = (formDetails: IformDetails) => {
  return [
    { path: `/forms/list`, label: `Forms` },
    { path: `/forms/${formDetails.id}/edit`, label: `Edit` },
    { path: ``, label: formDetails?.name },
  ];
};

const HeaderBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { formDetails } = useSelector<RootState, IfetchFormDatails>(
    (state) => state.formSurveyJsDetails
  );

  const { geography, displayView } = useSelector<
    RootState,
    I_InitialStateOfSurveyJS
  >((state) => state.surveyJS);
  const dynamicUsersLinks = generateDynamicUserLinks(formDetails);
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  // handlers
  const goBackToAllLayersScreen = () => {
    if (displayView === "geography") {
      dispatch(displaySurveyView());
    } else if (displayView === "survey") {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="w-full pt-4 pb-6">
      <Head title="Geography" />
      <DirectoryBreadCrumb
        links={dynamicUsersLinks}
        handleClick={goBackToAllLayersScreen}
      />
      <div className="flex flex-col justify-start items-start flex-grow py-0.5">
        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
          <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
            Indicate form area for offline use{" "}
            <CustomToolTip
              title=" To enable observers to use the map components of your form when
                they are using the app offline you need to indicate in which
                area your form needs to be available for offline use. You can
                select, upload or draw one or multiple areas. It is advised to
                try and limit the area as much as possible as large areas
                available for offline use may cause long data downloading times
                for the observers. You can define multiple separate areas, but
                you can only use one method to define them."
              showTooltip={showTooltip}
              toggleTooltip={toggleTooltip}
            >
              <IconButton onClick={toggleTooltip}>
                <InformationCircleIcon className="w-5 h-5 fill-[#475569]" />
              </IconButton>
            </CustomToolTip>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
