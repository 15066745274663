import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { importsViewDataSortingAction, observationsSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Observations.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { openDownloadOptionsImportModalAction } from "store/modals";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { fetchImportsFile } from "store/importsViewData/initialState";

export const KEYS_TO_BE_REMOVED = ["id"];

const generatingDirectoryActions = (columns: any, rowsData: any) => {

  const { importId } = useParams<{
    importId: string;
  }>();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const data = {
    columns,
    rowsData,
    importId
  }
  return [
    {
      label: "Download Options",
      onClick: () => {
        dispatch(openDownloadOptionsImportModalAction(data));
      },
      divider: false,
      isVisible: true,
    },
  ];
};

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();
  // const { observationsActions } = useActions();
  const DirectoryActions = () => {
    const filteredKeys = filterKeys(columnVisibility, columnOrder);
    return generatingDirectoryActions(
      filteredKeys,
      getAllRowsData(table, columnOrder, columnVisibility, columns)
    );
  };

  // const getImportsViewDataActions = (data: any) => {
  //   const modalData = {
  //     status: data?.status,
  //     id: data?.id,
  //     // path: "directory",
  //   };

  //   return observationsActions(modalData);
  // };

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(importsViewDataSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(importsViewDataSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(importsViewDataSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          DirectoryActions={DirectoryActions()}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        // getImportsFileDataActions={getImportsFileDataActions}
        />
      </table>
    </div>
  );
};

export function filterKeys(
  obj: Record<string, boolean>,
  keys: string[]
): string[] {
  // console.log('keys =>', keys);
  // return[];

  return keys
    .filter((key) => obj[key] && key !== "select")
    .map((key) => snakeCase(key));
}