import apiClient from "services/apiClient";

async function addReportFiltering(reportId: number, data: any) {
  const url = `/admin/reports/${reportId}/filtering`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default addReportFiltering;
