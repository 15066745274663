import { apiClient } from "../config";

async function getClusteredMap(queryParams: any) {
  const res = await apiClient.get(`/admin/dashboard/widget/clustered-map`, {
    params: queryParams,
  });
  return res.data;
}

export default getClusteredMap;
