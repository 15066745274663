import { generatingLayerId } from "../utils";
import { __paintRasterLayerOnMap__ } from "../workerMehods";

interface MapData {
  snippet: string;
  x: number;
  y: number;
  z: number;
}

interface LayerItem {
  type: string;
  id: number;
  mapData?: MapData;
  geoFilePath?: string;
  status: boolean;
}

function handlePaintingOfRasterLayersOnMap(
  item: LayerItem,
  map: any,
  cb?: CallableFunction
) {
  const { sourceId, layerId } = generatingLayerId(item.type, item.id);

  // Stop the layer painting if it's already painted
  if (map.getLayer(layerId)) {
    // If layer item is unchecked, then unpaint it from the map
    if (item.status === false) {
      map.removeLayer(layerId);
      map.removeSource(sourceId);
    }
  } else {
    // If layer item is unchecked or it doesn't have geojson data, then block its painting on the map
    const layerSinppet = item?.mapData;
    if (item.status === false || !layerSinppet) {
      return;
    }

    // Register click event listener on layer
    if (cb) {
      map.on("click", layerId, () => cb(item));
    }

    const snippet = replaceTileCoordinates(item);

    // Paint the layer
    __paintRasterLayerOnMap__(map, sourceId, layerId, snippet);
  }
}

export { handlePaintingOfRasterLayersOnMap };

function replaceTileCoordinates(data: any) {
  const { mapData } = data || {};
  const { z, x, y, snippet } = mapData || {};

  if (
    snippet &&
    snippet.includes("{z}") &&
    snippet.includes("{x}") &&
    snippet.includes("{y}")
  ) {
    const replacedSnippet = snippet
      .replace(/{z}/, z)
      .replace(/{x}/, x)
      .replace(/{y}/, y);
    return replacedSnippet;
  } else {
    return snippet || null;
  }
}
