import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeNotificationsSummaryModalAction } from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import usePermissions from "hooks/usePermissions";

interface IProps {
  notificationStatus: any;
  id: number;
}

export const SummaryNotificationsStatus: React.FC<IProps> = ({
  notificationStatus,
  id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  const { reminders } = usePermissions();
  const lastFiveNotifications = notificationStatus?.slice(-5);

  const handleClick = (notificationId: any) => {
    navigate(`/custom-notifications/${notificationId}/status`);
    dispatch(closeNotificationsSummaryModalAction());
  };
  return (
    <div className="py-4 border-b border-lineMid dark:bg-secondaryLight">
      <Accordion
        className="dark:bg-secondaryLight "
        style={{ boxShadow: "none" }}
        defaultExpanded
      >
        <AccordionSummary
          className="dark:bg-secondaryLight "
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex justify-between items-center w-full">
              <p className="text-secondaryMid dark:text-textMain ">
                Notifications status
              </p>
              {reminders.canViewStatusHistoryReminders && (
                <button
                  className="flex items-center"
                  onClick={() => handleClick(id)}
                >
                  <p className="mr-6 text-textLink">See All</p>
                </button>
              )}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="dark:bg-secondaryLight ">
          <Typography>
            <div className="mt-4">
              {lastFiveNotifications?.map((item: any) => {
                return (
                  <div className="flex justify-between items-center px-3 py-2">
                    <p className="text-sm dark:text-textMain">
                      {item?.insertedAt}
                    </p>
                    <p
                      className={`text-xs text-left  break-all py-1 px-2 rounded-lg ${
                        item?.status === "active"
                          ? "text-tertiary bg-tertiaryLight "
                          : "text-secondaryLight bg-brandHoverLight"
                      }`}
                    >
                      {sentenceCase(item?.status ?? "")}
                    </p>
                  </div>
                );
              })}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
