import * as actionTypes from "./action-types";
import initialState from "./initialState";

export const runReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.VERSION_REPORT:
      return {
        ...state,
        reportsVersion: action.payload,
      };
    default:
      return state;
  }
};
