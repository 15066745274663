import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";
import { generatingLayerId } from "../mapUtils";
import {
  __drawShapeByGeoJsonOnMap__,
  __paintShapeByGeoJsonOnMap__,
} from "../mapWorkerMehods";
import { convertGeoJSON } from "utils/convertFeatureCollection";

function handlePaintingOfVectorRegionShapeLayersOnMap(
  item: any,
  map: any,
  draw: any,
  defaultColor: any,
  cb?: CallableFunction,
  popups?: any,
  setPopups?: any
) {
  if (item.clickable) {
    handlePaintingOfClickableRegionShapeLayersOnMap(
      item,
      map,
      draw,
      defaultColor,
      cb
    );
    return;
  };


  const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);

  // stop the layer painting if its already painted
  if (map.getLayer(layerId)) {
    // if layer item is unchecked then unpaint it from the map
    if (item.status === false) {
      map.removeLayer(layerId);
      map.removeSource(sourceId);

      const popup = popups?.find((p: any) => p.id === item.item.id);
      if (popup && setPopups) {
        popup.item.remove();

        const updatedPopups = popups?.filter((p: any) => p.id !== popup.id);

        setPopups((prev: any) => [...updatedPopups]);
      }
    }
  } else {
    // if layer item is unchecked or it doesn't has geojson data then blocks its painting on map
    const geoJson =
    convertGeoJSON(item.item?.mapData)?? item.item?.geoFilePath   ;
    // const geoJson =
    // item.item?.geoFilePath ?? convertGeoJSON(item.item?.mapData)  ;
    if (item.status === false || !geoJson) {
      return;
    }

    // draw the layer
    if (item.draw) {
      __drawShapeByGeoJsonOnMap__(draw, item.item.id, item.item.mapData);

      return;
    }

    const isGeojsonUrl = typeof geoJson === "string";

    // register click event listener on layer
    if (cb && !item.clickable && !isGeojsonUrl) {
      map.on("click", layerId, (event: any) => {
        cb(item);
      });
    }


    // paint the layer
    __paintShapeByGeoJsonOnMap__(
      map,
      draw,
      sourceId,
      layerId,
      geoJson,
      defaultColor
    );
  }
}

export { handlePaintingOfVectorRegionShapeLayersOnMap };

const handlePaintingOfClickableRegionShapeLayersOnMap = (
  item: any,
  map: any,
  draw: any,
  defaultColor: any,
  cb?: CallableFunction
) => {
  const allLayers = map.getStyle().layers;

  const alreadyPaintedLayers = allLayers.filter((l: any) =>
    l.id.includes(item.item.id)
  );

  if (alreadyPaintedLayers.length > 0) {
    if (item.status === false) {
      alreadyPaintedLayers.forEach((l: any) => {
        map.removeLayer(l.id);
        map.removeSource(l.source);
      });
    }
  } else {
    if(item.status !== true) return;
    const allFeatures = item.item.mapData.features.map((f: any) => ({
      features: [f],
      type: "FeatureCollection",
    }));

    allFeatures.forEach((geojson: any) => {
      const uniqueId = generateUniqueId(item.item.id + ".");
      const layerId = uniqueId + ".layer";
      const sourceId = uniqueId;

      geojson.features[0].id = layerId;

      __paintShapeByGeoJsonOnMap__(
        map,
        draw,
        sourceId,
        layerId,
        geojson,
        defaultColor
      );

      if (cb) {
        map.on("click", layerId, (event: any) => {
          cb(geojson);
        });
      }
    });
  }
};
