import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";

// Generating URLs

const generateDynamicBreadcrumbLinks = (
  programId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/member-requests`,
          label: `Join Requests`,
        },
      ];

    default:
      return [{ path: `/programs/list`, label: `Programs` }];
  }
};
export const Header = () => {
  const navigate = useNavigate();

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const { joinRequestFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { programId } = useParams<{
    programId: string;
  }>();

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    programId ?? "",
    BreadcrumbData?.name ?? "",
    joinRequestFilter.status
  );

  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data: any) => {
          setBreadcrumbData(data);
        })
        .catch((error: any) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    }
  }, [programId]);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              programId={programId}
              name={BreadcrumbData?.name}
              parentName={"Programs"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// controllers
async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
