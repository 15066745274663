import { snakeCase } from "change-case";
import { apiClient } from "../config";

async function getAnalyticsDropdown(
  id: number,
  page: number,
  entity: any,
  search?: string
) {
  // const queryString = search ? `&search_string=${search}` : "";
  const params = {
    page: page,
    entity: snakeCase(entity),
    searchString: search,
  };
  const res = await apiClient.get(`/admin/reports/${id}/analytics-dropdown`, {
    params,
  });
  return res.data;
}

export default getAnalyticsDropdown;
