import { apiClient } from "../config";

async function getTypesDropdown(searchQuery?: string,page?: number) {
  const params = {
    searchString: searchQuery,
    page,
  };

  const res = await apiClient.get(`/admin/types/dropdown`, { params });
  return res.data;
}

export default getTypesDropdown;
