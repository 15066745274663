import { sentenceCase } from "change-case";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { communitiesTypeAction } from "store/filters/reducer.actions";
import BpCheckbox from "view/components/CheckBox";

interface TypeProps {
  data: any;
}

export const Type: React.FC<TypeProps> = ({ data }) => {
  const dispatch = useDispatch();

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(communitiesTypeAction(value));
    } else {
      dispatch(communitiesTypeAction(""));
    }
  };
  const types = ["open", "private"];
  return (
    <div
      className="w-[315px] py-2 bg-bgWhite shadow-xl rounded-xl flex flex-col justify-start items-start
 dark:bg-secondaryLight "
    >
      {types.map((label, index) => (
        <div key={index} className="flex items-center justify-start rounded-lg">
          <div className="flex items-center justify-start">
            <div className="relative flex items-center justify-start ">
              <BpCheckbox
                checked={data?.value === label}
                onChange={handleCheckboxChange}
                value={label.trim()}
              />
              <p className="ml-2">{sentenceCase(label)}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
