
import { Dispatch } from "redux";
import { ERROR_LOADING_PROGRAMS, GET_ALL_PROGRAMS, RESET_DATA, START_LOADING_PROGRAMS, STOP_LOADING_PROGRAMS } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchProgramsAction =
  (communityId?: any, organizationId?: any) =>
  async (dispatch: Dispatch, getState: any) => {
    
    // dispatch(loadingState(true))
    dispatch({type: START_LOADING_PROGRAMS})
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.programsFilter
      );


      const query = appendCommunityOrOrganizationIdInParams(
        communityId,
        organizationId,
        params
      );
      
      const { data } = await apiLibrary.Programs.getAllPrograms(query);
      dispatch({
        type: GET_ALL_PROGRAMS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      // dispatch(fetchError(error))
      dispatch({type: ERROR_LOADING_PROGRAMS})
    } finally {
      dispatch({ type: STOP_LOADING_PROGRAMS});
      // dispatch(setLoadingProgress(100));
      // dispatch(loadingState(false))
    }
  };

const appendCommunityOrOrganizationIdInParams = (
  communityId: any,
  organizationId: any,
  params: any
) => {
  let updatedParams = { ...params };

  if (communityId) {
    updatedParams.communityIds = communityId.toString();
  } else if (organizationId) {
    updatedParams.organizationIds = organizationId.toString();
  }

  return updatedParams;
};
