import React from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import { sentenceCase } from "change-case";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const getStatusClass = (status: string) => {
    switch (status) {
      case "ready":
        return "bg-tertiaryLight text-tertiary";
      case "processing":
        return "bg-primaryExtraLight text-primary";
      default:
        return "bg-brandHover";
    }
  };

  if (columnDef.id === "processingStatus" && cellValue !== null) {
    return (
      <div className="flex justify-start items-center ml-2">
        <p className="text-sm">
          <span
            className={`pt-1 pb-2 px-2 rounded-lg lowercase ${getStatusClass(cellValue)}`}
          >
            {sentenceCase(cellValue)}
          </span>
        </p>
      </div>
    );
  } else if (
    cellValue === null &&
    (columnDef.id === "observationsProcessed" ||
      columnDef.id === "viewed" ||
      columnDef.id === "downloaded" ||
      columnDef.id === "dateAdded")
  ) {
    return (
      <div>
        <p>-</p>
      </div>
    );
  } else if (
    cellValue !== null &&
    (columnDef.id === "observationsProcessed" ||
      columnDef.id === "viewed" ||
      columnDef.id === "downloaded")
  ) {
    return (
      <div
        className={`relative flex items-center justify-start flex-grow py-1 px-3`}
      >
        <p
          className={`text-sm text-center text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div className="relative flex items-center justify-start flex-grow py-1 px-[8px]">
        <p className="text-sm text-center text-textMid group-hover:text-primaryDark whitespace-nowrap">
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "runDateTime") {
    return (
      <div className="relative flex items-center justify-start flex-grow py-1 px-[8px]">
        <p className="text-sm text-center text-textMid group-hover:text-primaryDark whitespace-nowrap">
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div className={`relative flex items-start flex-grow py-1 px-3`}>
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
