import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { programsSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Communities.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { fetchFilesAction } from "store/files/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useParams } from "react-router-dom";

export const KEYS_TO_BE_REMOVED = ["id"];

export const Table: React.FC<TableProps> = ({
  table,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const { programId } = useParams<{
    programId: string;
  }>();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(fetchFilesAction(programId, "program", "", "asc", columnsId));
        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(fetchFilesAction(programId, "program", "", "desc", columnsId));
        return [{ columnId, type: "desc" }];
      } else {
        dispatch(fetchFilesAction(programId, "program"));
        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden overflow-x-scroll">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        />
      </table>
    </div>
  );
};
