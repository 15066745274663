import { ShapePickerState } from "../stateConstructors";
import mergeId from "../utils/mergeId";

function convertJsonOfUpComingShapePicker(question: any) {
  if (!question) {
    return new ShapePickerState();
  }

  const {
    centerPoint,
    selectMultiple,
    selectedLayers,
    selectedShapes,
    id,
    title,
    type,
    name,
    description,
    isRequired,
    zoomLevel,
  } = question;

  const selectedLayersIdToHighlight = selectedShapes?.map((s: any) => {
    return {
      ...s,
      id: mergeId(id, s.id),
    };
  });
  const mapCenterPoint = {
    longitude: centerPoint && centerPoint[0],
    latitude: centerPoint && centerPoint[1],
  };

  const structuredData = {
    _id: id,
    name,
    title,
    type,
    description,
    isRequired,
    selectedLayersIdToHighlight,
    mapCenterPoint,
    zoomLevel: zoomLevel,
    ableToCheckMultiple: selectMultiple,
    selectedDefaultLayers: selectedLayers,
  };

  return structuredData;
}

export { convertJsonOfUpComingShapePicker };
