import React from "react";
import { FormikProps, FormikValues } from "formik";
import { InputFields } from "./components/InputFields";
import { SubmissionButtons } from "./components/SubmissionButtons";

interface NotifyingChannelsProps extends FormikProps<FormikValues> {
  handleClose: any;
  handleGoBackBtn: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const NotifyingChannels: React.FC<NotifyingChannelsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleClose,
  handleGoBackBtn,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  return (
    <div className="flex flex-col justify-start items-start w-full bg-bgWhite pb-4 rounded-lg dark:bg-secondaryLight">
      <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 bg-bgWhite rounded-lg dark:bg-secondaryLight">
        <div className="px-1 w-full">
          <InputFields
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </div>

        <SubmissionButtons
          handleClose={handleClose}
          handleGoBackBtn={handleGoBackBtn}
          handleSubmit={handleSubmit}
          activeStep={activeStep}
          values={values}
          isFormLoading={isFormLoading}
          isLastStep={isLastStep}
        />
      </div>
    </div>
  );
};
