import { GET_ORGANIZATION_PROFILE,RESET_PROFILE_ACTION } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchOrganizationProfileAction =
  (organizationId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.OrganizationDirectory.getOrganizationProfile(
          organizationId
        );

      dispatch({
        type: GET_ORGANIZATION_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/organizations/list";
    }
  };

  export const organizationResetProfileAction = () => {
    return {
      type: RESET_PROFILE_ACTION
    }
  }
