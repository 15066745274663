import { Box, Modal } from "@mui/material";
import React from "react";
import Button from "view/components/Button";
import { Toasts } from "view/components/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  closeChatArchiveModal,
  closeChatDeleteModal,
  closeSignOutModal,
} from "store/modals/reducer.actions";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { IChatDetails } from "store/messagesDetail/initialState";
import apiLibrary from "services/api";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchRecipientsAction } from "store/messages";
import { filtersInitialState } from "store/filters/initialState";
import { messagesStatusAction } from "store/filters";
import { IfetchRecipients } from "store/messages/initialState";
import { GET_RECIPIENTS } from "store/messages/action-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 480,
  bgcolor: "background.paper",
};

const MessageActionsModal = () => {
  // STATES
  const [isLoading, setIsLoading] = React.useState(false);

  // SELECTORS
  const { messageActionsModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { data } = useSelector<RootState, IChatDetails>(
    (state) => state?.chatDetails
  );
  const allRecipients = useSelector<RootState, IfetchRecipients>(
    (state) => state?.allRecipients
  );
  const { messageStatusFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // CLOSE MODAL DELETE/ARCHIVE
  const handleClose = () => {
    if (messageActionsModal.isDelete) {
      dispatch(closeChatDeleteModal());
    } else if (messageActionsModal.isArchive) {
      dispatch(closeChatArchiveModal());
    }
  };

  // SUBMIT HANDLER
  const submitHandler = async () => {
    setIsLoading(true);

    try {
      if (messageActionsModal.isDelete) {
        const { message } = await apiLibrary.Messages.deleteConversation(
          messageActionsModal.data?.id
        );
        // Removing recipient's conversations
        const updatedRecipients = allRecipients.data.recipients.filter((recipient: any) => recipient.id !== messageActionsModal.data?.id);

        const updatedData = {
          ...allRecipients.data,
          conversations: updatedRecipients,
        };

        dispatch({
          type: GET_RECIPIENTS,
          payload: updatedData,
        });

        Toasts.success(message);
      } else if (messageActionsModal.isArchive) {
        const queryParams = {
          status: messageStatusFilter.archived ? "restore" : "archive",
        };

        const { message } = await apiLibrary.Messages.updateConversationStatus(
          messageActionsModal.data?.id,
          queryParams
        );
        Toasts.success(message);
        dispatch(
          messagesStatusAction(messageStatusFilter.archived ? false : true)
        );
        dispatch(fetchRecipientsAction());

      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  // Render Description
  const renderDescription = () => {
    switch (true) {
      case messageActionsModal.isDelete:
        return (
          <>
            Delete the chat with{" "}
            <span className="font-bold">
              {data?.receiver.firstName + " " + data?.receiver.lastName}
            </span>{" "}
            permanently?
          </>
        );
      case messageActionsModal.isArchive:
        return (
          <>
            {messageStatusFilter.archived ? "Restore" : "Archive"} the chat with{" "}
            <span className="font-bold">
              {data?.receiver.firstName + " " + data?.receiver.lastName}
            </span>
            ?
          </>
        );
      default:
        return <></>;
    }
  };

  const getActionStatus = () => {
    if (messageActionsModal?.isDelete) {
      return "Remove";
    } else if (messageStatusFilter.archived) {
      return "Restore";
    } else {
      return "Archive";
    }
  };

  return (
    <>
      <Modal
        open={messageActionsModal.isDelete || messageActionsModal.isArchive}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark:bg-secondaryLight ">
          <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 p-6 bg-white rounded-lg"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="flex  justify-between pb-2 items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5 mb-2">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain capitalize">
                    {messageActionsModal?.isDelete ? "Remove chat" : messageStatusFilter.archived ? "Restore" : "Archive chat"}
                  </p>
                </div>
                <button onClick={handleClose} type="button">
                  <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                </button>
              </div>
              <div className="mb-5">
                <p className="w-[410px] text-sm text-left text-secondaryMid dark:text-textMain">
                  <span className="text-sm text-left break-all text-secondaryMid   dark:text-caption">
                    {renderDescription()}
                  </span>
                </p>
              </div>

              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 h-11"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={isLoading}
                  type="button"
                  text={getActionStatus()}
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 h-11 border border-primary"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={submitHandler}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MessageActionsModal;
