import React, { useState } from "react";

export interface Action {
  label: string;
  onClick?: (data?: any) => void;
  divider?: boolean;
  isVisible: boolean;
  isActive?: boolean;
  disabled?: boolean;
  Icon?: any;
  iconProps?: any;
}

interface ActionDropDownMenuProps {
  actions: Action[];
  IconData?: boolean;
  data?: any;
}

const ActionDropDownMenu: React.FC<ActionDropDownMenuProps> = ({
  actions,
  data,
  IconData,
}) => {
  const handleActionClick = (action: Action) => {
    if (typeof action.onClick === "function") {
      if (data) {
        action.onClick(data);
      } else {
        action.onClick();
      }
    }
  };
  return (
    <div className="flex items-start justify-start bg-white dark:bg-secondaryLight rounded">
      <div
        className="flex flex-col items-start justify-start flex-grow py-2 rounded"
        style={{
          boxShadow:
            "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
        }}
      >
        {actions?.map((action, index) => {
          if (action?.isVisible) {
            return (
              <React.Fragment key={index}>
                <button
                  onClick={() => handleActionClick(action)}
                  className={`flex justify-start hover:bg-bgBluish_2 group items-center  self-stretch flex-grow-0 flex-shrink-0  py-0.5 ${action.isActive && "bg-bgBluish_2"}`}
                >
                  <div className="flex items-center justify-start flex-grow">
                    <div className="relative flex items-center justify-start flex-grow ">
                      <div className={`pl-1  ${IconData ? "w-6" : ""}`}>
                        {" "}
                        {action.Icon &&
                          (action.iconProps ? (
                            <action.Icon {...action.iconProps} />
                          ) : (
                            <action.Icon />
                          ))}
                      </div>
                      <button className="px-2 whitespace-nowrap ">
                        <p className="text-secondaryMid dark:text-inputText dark:group-hover:text-secondary font-Overpass capitalize">
                          {action?.label}
                        </p>
                      </button>
                    </div>
                  </div>
                </button>
                {action?.divider && (
                  <div className="w-full h-[1px] bg-lineMid my-1">
                    <div className="flex-grow " />
                  </div>
                )}
              </React.Fragment>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ActionDropDownMenu;
