import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { dashboardWidgetConstants } from "constants/dashboardWidgetConstants";

import observationsImg from "./imgs/observations.svg";
import observationsBarImg from "./imgs/observationsByRegion.svg";
import notificationsImg from "./imgs/notifications.svg";
import qaNotificationsImg from "./imgs/qaNotifications.svg";
import programsImg from "./imgs/programs.svg";
import communitiesImg from "./imgs/communities.svg";
import formsImg from "./imgs/forms.svg";
import mostObservedObjectsImg from "./imgs/mostObservedObjects.svg";
import mostObservedSpeciesImg from "./imgs/mostObservedSpecies.svg";
import activeUsersImg from "./imgs/activeUsers.svg";
import regionsImg from "./imgs/regions.svg";
import qaProgressImg from "./imgs/qaProgress.svg";
import cumulativeObservationsImg from "./imgs/cumulativeObservations.svg";
import "./style.scss";
import usePermissions from "hooks/usePermissions";
import CustomToolTip from "view/components/ToolTip";
import { widgetTitles } from "Components/Dashboard/widgetTitles";

interface ISideBarWidgets {
  onListChange?: (props: any) => void;
  selectedWidgets: any[];
  loading?: boolean;
}

interface IDataChild {
  index: number;
  _id: string;
  widgetName: string;
  title: string;
  imgSrc: string | undefined;
}
interface ISidebarWidgetsData {
  heading: string;
  hasAccess: boolean;
  _id: string;
  grid?: number;
  isNotification?: true;
  children: IDataChild[];
}

const SideBarWidgets = (props: ISideBarWidgets) => {
  const outerSortableRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = React.useState<ISidebarWidgetsData[]>([]);
  const { dashboard } = usePermissions();
  const [widgetsData, setWidgetsData] = useState<any>([]);

  useEffect(() => {
    if (dashboard) {
      const updatedWidgetsData = getSidebarWidgetsData(dashboard);
      setWidgetsData(updatedWidgetsData);
    }
  }, []);
  // MAKE WIDGETS SORTABLE
  React.useEffect(() => {
    generateSortableElements();
  }, [data]);

  // FILTER SELECTED WIDGETS
  React.useEffect(() => {
    if (!props.loading) filterSelectedWidgets();
  }, [props.selectedWidgets]);

  // FILTERING ALREADY SELECTED WIDGETS
  const filterSelectedWidgets = (deletedElementId?: string) => {
    let filteredWidgets: ISidebarWidgetsData[] = [];
    const selectedWidgetsKeys = props.selectedWidgets.map((item) => item._id);

    if (deletedElementId) selectedWidgetsKeys.push(deletedElementId);
    // console.log("selectedWidgetsKeys:>", selectedWidgetsKeys);

    let isNotificationWidgetsExists = false;
    widgetsData
      .filter((item: any) => item.hasAccess)
      .map((item: any) => {
        const children = item.children.filter(
          (childItem: any) => !selectedWidgetsKeys.includes(childItem._id)
        );

        if (children.length || item._id === "notificationsContainer") {
          filteredWidgets.push({
            ...item,
            children,
          });
          if (item.isNotification) isNotificationWidgetsExists = true;
        }
      });
    if (!isNotificationWidgetsExists) {
      filteredWidgets = filteredWidgets.filter(
        (item) => item._id !== "notificationsContainer"
      );
    }

    setData(filteredWidgets);
  };

  // GENERATE SORTABLE ELEMENTS
  const generateSortableElements = () => {
    if (outerSortableRef.current) {
      // Nested sortable lists
      const nestedLists =
        outerSortableRef.current.getElementsByClassName("nested-list");

      // Initialize Sortable for each nested list
      Array.from(nestedLists).forEach((nestedList) => {
        new Sortable(nestedList as HTMLDivElement, {
          group: { name: "shared", put: false },
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          sort: false,
        });
      });
    }
  };

  return (
    <div className="h-full p-5 overflow-y-auto border-r sidebarContainer border-r-borderLightGray dark:border-r-lineLight bg-bgWhite dark:bg-secondaryLight">
      <Grid ref={outerSortableRef} id="sideBar" container spacing={1}>
        {data
          .filter((item) => item.hasAccess)
          .map((item, mainIndex) => {
            const isNotificationContainer =
              item._id === "notificationsContainer";
            return (
              <Grid
                item
                xs={item.grid || 12}
                key={`${item._id}-${mainIndex}`}
                data-id={`${item._id}-${mainIndex}`}
                mt={mainIndex === 0 || item.isNotification ? 0 : 4}
              >
                {!item.isNotification && (
                  <h6 className="mb-2 text-lg font-bold leading-5 break-words dark:text-textMain text-textMid font-overpass">
                    {item.heading}
                  </h6>
                )}
                <div className="nested-list">
                  {item.children.map((chItem, index) => {
                    const title = widgetTitles[chItem._id] || "";
                    return (

                      <CustomToolTip
                      title={title}
                      key={`${chItem._id}--${index}`}
                    >
                      <div id={`${chItem._id}--${index}`}>
                        <div className="mb-0">
                          <img src={chItem.imgSrc} alt={chItem.title} />
                          <p className="mt-2 text-base font-medium leading-4 break-words dark:text-caption text-textMidLight font-Overpass">
                            {chItem.title}
                          </p>
                        </div>
                      </div>
                    </CustomToolTip>
                      // <WidgetItem
                      //   imgSrc={chItem.imgSrc}
                      //   title={chItem.title}
                      //   toolTipTitle={title}
                      //   key={`${chItem._id}--${index}`}
                      // />
                    );
                  })}
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default SideBarWidgets;

interface WidgetItemProps {
  imgSrc: string | undefined;
  title: string;
  toolTipTitle: string;
  key: any;
}

export const WidgetItem: React.FC<WidgetItemProps> = ({
  imgSrc,
  title,
  toolTipTitle,
  key,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <CustomToolTip
      title={toolTipTitle}
      showTooltip={showTooltip}
      toggleTooltip={toggleTooltip}
      // key={`${chItem._id}--${index}`}
    >
      <div className="mb-0" onClick={toggleTooltip} id={key}>
        <img src={imgSrc} alt={title} />
        <p className="mt-2 text-base font-medium leading-4 break-words dark:text-caption text-textMidLight font-Overpass">
          {title}
        </p>
      </div>
    </CustomToolTip>
  );
};

function getSidebarWidgetsData(dashboardPermissions: any) {
  return [
    {
      heading: "Observations",
      _id: "observations",
      hasAccess: dashboardPermissions.canViewObservations,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.observations.VALUE,
          widgetName: dashboardWidgetConstants.observations.VALUE,
          title: "Chart bar by year",
          imgSrc: observationsBarImg,
        },
      ],
    },
    {
      heading: "Totals",
      _id: "totals",
      hasAccess: true,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.totalCounts.VALUE,
          widgetName: dashboardWidgetConstants.totalCounts.VALUE,
          title: "KPI",
          imgSrc: observationsImg,
        },
      ],
    },
    {
      heading: "QC",
      _id: "qc",
      hasAccess: true,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.qaProgress.VALUE,
          widgetName: dashboardWidgetConstants.qaProgress.VALUE,
          title: "Pie chart",
          imgSrc: qaProgressImg,
        },
      ],
    },
    {
      heading: "Most active users",
      _id: "mostActiveUsers",
      hasAccess: dashboardPermissions.canViewActiveUsers,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.activeUsers.VALUE,
          widgetName: dashboardWidgetConstants.activeUsers.VALUE,
          title: "By observations",
          imgSrc: activeUsersImg,
        },
      ],
    },
    {
      heading: "Notifications",
      _id: "notificationsContainer",
      hasAccess: dashboardPermissions.canViewNotifications,
      children: [],
    },
    {
      heading: "Notifications",
      isNotification: true,
      _id: "notificationsContainer1",
      grid: 6,

      hasAccess: dashboardPermissions.canViewNotifications,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.notifications.VALUE,
          widgetName: dashboardWidgetConstants.notifications.VALUE,
          title: "Recent Notifications",
          imgSrc: notificationsImg,
        },
      ],
    },
    {
      heading: "Notifications",
      isNotification: true,
      _id: "notificationsContainer2",
      grid: 6,
      hasAccess: dashboardPermissions.canViewQANotifications,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.qaNotifications.VALUE,
          widgetName: dashboardWidgetConstants.qaNotifications.VALUE,
          title: "QA Notifications",
          imgSrc: qaNotificationsImg,
        },
      ],
    },
    {
      heading: "Notifications",
      isNotification: true,
      _id: "notificationsContainer3",
      grid: 6,
      hasAccess: dashboardPermissions.canViewReportNotifications,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.reportsNotifications.VALUE,
          widgetName: dashboardWidgetConstants.reportsNotifications.VALUE,
          title: "Report Notifications",
          imgSrc: qaNotificationsImg,
        },
      ],
    },
    {
      heading: "Regions",
      _id: "regionsContainer",
      hasAccess: dashboardPermissions.canViewObservationsByRegion,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.observationsByRegion.VALUE,
          widgetName: dashboardWidgetConstants.observationsByRegion.VALUE,
          title: "Regions",
          imgSrc: regionsImg,
        },
      ],
    },

    {
      heading: "Locations",
      _id: "locationsContainer",
      hasAccess: dashboardPermissions.canViewClusteredMap,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.observationsByLocation.VALUE,
          widgetName: dashboardWidgetConstants.observationsByLocation.VALUE,
          title: "Locations",
          imgSrc: regionsImg,
        },
      ],
    },

    {
      heading: "Forms",
      _id: "horizontalBarGraph1",
      hasAccess: dashboardPermissions.canViewHorizontalBarGraph,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.form.VALUE,
          widgetName: dashboardWidgetConstants.form.VALUE,
          title: "Bar diagram",
          imgSrc: formsImg,
        },
      ],
    },
    {
      heading: "Programs",
      _id: "horizontalBarGraph2",
      hasAccess: dashboardPermissions.canViewHorizontalBarGraph,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.program.VALUE,
          widgetName: dashboardWidgetConstants.program.VALUE,
          title: "Bar diagram",
          imgSrc: programsImg,
        },
      ],
    },
    {
      heading: "Communities",
      _id: "horizontalBarGraph3",
      hasAccess: dashboardPermissions.canViewHorizontalBarGraph,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.community.VALUE,
          widgetName: dashboardWidgetConstants.community.VALUE,
          title: "Bar diagram",
          imgSrc: communitiesImg,
        },
      ],
    },
    {
      heading: "Most observed objects",
      _id: "mostObservedObjects",
      hasAccess: dashboardPermissions.canViewMostObservedObjects,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.mostObservedObjects.VALUE,
          widgetName: dashboardWidgetConstants.mostObservedObjects.VALUE,
          title: "Most observed",
          imgSrc: mostObservedObjectsImg,
        },
      ],
    },
    {
      heading: "Most observed species",
      _id: "mostObservedSpecies",
      hasAccess: dashboardPermissions.canViewMostObservedSpecies,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.mostObservedSpecies.VALUE,
          widgetName: dashboardWidgetConstants.mostObservedSpecies.VALUE,
          title: "Most observed",
          imgSrc: mostObservedSpeciesImg,
        },
      ],
    },

    {
      heading: "Cumulative observations",
      _id: "cumulativeObservations",
      hasAccess: dashboardPermissions.canViewCumulativeObservations,
      children: [
        {
          index: 0,
          _id: dashboardWidgetConstants.cumulativeObservations.VALUE,
          widgetName: dashboardWidgetConstants.cumulativeObservations.VALUE,
          title: "Cumulative - year",
          imgSrc: cumulativeObservationsImg,
        },
      ],
    },
  ];
}
