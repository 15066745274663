import React, { useState } from "react";
import FiltersList from "../FiltersList";
// types
import { FiltersProps } from "./types";

export const Filters: React.FC<FiltersProps> = ({}) => {
  const [isCloseDiv, setIsCloseDiv] = useState(true);

  // Initializing event listener
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
    </>
  );
};
