import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeCreateOrEditDataAccessPolicyModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TextEditor } from "Components/Programs/DataAccessPolicy";
import { useNavigate, useParams } from "react-router-dom";
import { IProgram } from "store/programProfile/initialState";
import { fetchDataAccessPolicyAction } from "store/DataAccessPolicy/reducer.actions";
import { FileUpload } from "./components/fileUpload";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

interface DataAccessPolicyModalProps {}

const currentValidationSchema = Yup.object().shape({
  policyContent: Yup.string().required("Policy content is required"),
});

export const CreateOrEditDataAccessPolicyModal: React.FC<
  DataAccessPolicyModalProps
> = ({}) => {
  const { createOrEditDataAccessPolicyModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [files, setFiles] = useState<File[]>([]);
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const { programs } = usePermissions();
  const navigate = useNavigate();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [showAlert, setShowAlert] = useState(true);
  const handleClose = () => {
    dispatch(closeCreateOrEditDataAccessPolicyModalAction());
    setFiles([]);
  };
  // Set files when component mounts
  useEffect(() => {
    if (
      createOrEditDataAccessPolicyModal.isOpen &&
      createOrEditDataAccessPolicyModal.editMode
    ) {
      setShowAlert(true);
      setFiles(createOrEditDataAccessPolicyModal?.data?.attachments);
    }
  }, [createOrEditDataAccessPolicyModal.isOpen]);

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (
      (!programs.canCreateDataAccessPolicy &&
        !createOrEditDataAccessPolicyModal.editMode) ||
      (!programs.canEditDataAccessPolicy &&
        createOrEditDataAccessPolicyModal.editMode)
    ) {
      return;
    }
    // Add a new Data access policy
    try {
      const formValues = {
        ...values,
        fileIds: [],
      };

      if (files?.length > 0) {
        const uploadFilePromises = files.map(async (file: any) => {
          try {
            if (!file.id) {
              const res = await apiLibrary.file.fileUpload(
                file,
                false,
                "public"
              );
              formValues.fileIds.push(res.data.id);
            } else {
              // Push the existing id to the array
              formValues.fileIds.push(file.id);
            }
          } catch (error: any) {
            // Handle upload errors
            console.error("Error uploading file:", error);
            // Clear fileIds array on error
            formValues.fileIds = [];
          }
        });

        // Wait for all file uploads to complete
        await Promise.all(uploadFilePromises);
      }

      if (!createOrEditDataAccessPolicyModal.editMode) {
        const { data, message } =
          await apiLibrary.DataAccessPolicy.createDataAccessPolicy(
            formValues,
            programProfile?.id
          );
        Toasts.success(message);
        navigate(
          `/programs/${programProfile?.id}/dataAccessPolicy/${data?.id}`
        );
      } else {
        delete formValues.attachments;
        const { data, message } =
          await apiLibrary.DataAccessPolicy.editDataAccessPolicy(
            formValues,
            programProfile?.id
          );
        Toasts.success(message);
        dispatch(fetchDataAccessPolicyAction(programProfile?.id));
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      console.log("errror");
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  // FILES CONFIG
  const FILES_LIMIT = 8;
  const ACCEPTED_FILES_TYPES = {
    "image/jpeg": [".jpg", ".jpeg", ".png", ".webp"],
    "audio/mpeg": [".mp3"], // for MP3 audio files
    "audio/wav": [".wav"], // for WAV audio files
    "audio/aac": [".aac"], // for AAC audio files
  };

  // Dropzone file drag and upload functions
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any, rejectedFiles: any) => {
      // Define accepted MIME types
      const acceptedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/webp", // Image formats
        "audio/mpeg",
        "audio/wav",
        "audio/aac",
        "audio/mp3", // Audio formats
      ];

      // Check if all accepted files match the accepted MIME types
      const allFilesAccepted = acceptedFiles.every((file: any) =>
        acceptedMimeTypes.includes(file.type)
      );

      if (!allFilesAccepted) {
        // Display an error if any file's MIME type is not accepted
        Toasts.error("Please upload only image and audio files.");
        return; // Stop further processing
      }

      // Proceed if there are no rejected files and the files count is within the limit
      if (rejectedFiles.length > 0) {
        Toasts.error(
          "Some files were not accepted. Please upload only image and audio files."
        );
      } else if (acceptedFiles.length > FILES_LIMIT) {
        Toasts.error("Files must be less than or equal to 8.");
      } else if (acceptedFiles.length === FILES_LIMIT) {
        // If files are equal to FILES_LIMIT, replace the old ones
        setFiles([...acceptedFiles]);
      } else if (acceptedFiles.length + files?.length > FILES_LIMIT) {
        // If the total number of files exceeds FILES_LIMIT
        Toasts.error("Total files must be less than or equal to 8.");
      } else {
        // Concatenate the new files with the old ones
        setFiles((prevFiles: any) => [...(prevFiles || []), ...acceptedFiles]);
      }
    },
    [files]
  );

  const handleDeleteFile = (index: number) => {
    const updatedFiles = files.filter((_, fileIndex) => index !== fileIndex);
    setFiles([...updatedFiles]);
  };

  const handleUpdateFile = (newFile: any, index: number) => {
    // Check if the new file is already added
    const isFileAlreadyAdded = files.some((f: any) => f.path === newFile.path);
    if (isFileAlreadyAdded) {
      return; // Skip the update if the file is already in the list
    }

    const updatedFiles = [...files];
    if (index >= 0 && index < updatedFiles.length) {
      updatedFiles[index] = newFile; // Update the file at the specified index
      setFiles([...updatedFiles]);
    }
  };

  return (
    <Modal
      open={createOrEditDataAccessPolicyModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={createOrEditDataAccessPolicyModal.data}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <div className="w-[900px] rounded">
                <div className="relative flex flex-col items-start justify-start gap-4 p-6 w-full rounded-lg bg-bgWhite dark:bg-secondaryLight">
                  <div className="flex  justify-between items-start w-full py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                        {createOrEditDataAccessPolicyModal.editMode
                          ? "Edit"
                          : "Create"}{" "}
                        Data Access Policy
                      </p>
                    </div>
                    <button onClick={handleClose} type="button">
                      <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                    </button>
                  </div>

                  <div className="overflow-y-auto px-1 h-[60vh] w-full">
                    {createOrEditDataAccessPolicyModal.editMode &&
                      showAlert && (
                        <div
                          className="flex justify-start items-center w-full relative gap-3 px-6 py-3 mb-3 rounded-lg bg-[#fdf2d9]"
                          style={{
                            boxShadow: "0px 1px 0px 0 rgba(2,13,36,0.06)",
                          }}
                        >
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8ZM10 12C10 11.4477 10.4477 11 11 11H12C12.5523 11 13 11.4477 13 12V15C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H12C11.4477 17 11 16.5523 11 16V13C10.4477 13 10 12.5523 10 12Z"
                              fill="#F2AA00"
                            />
                          </svg>
                          <div className="flex justify-start items-center flex-grow relative py-0.5">
                            <p className="flex-grow w-[80%] text-sm text-left text-secondaryMid">
                              Please remember that we are working with
                              communities that have strict protocols regarding
                              the handling of their data. Bear in mind that
                              observers may not be notified when changes are
                              made. Therefore, it is essential to be careful
                              while updating and avoid sharing data without
                              their consent or giving credit where it is due.
                            </p>
                          </div>
                          <button
                            className="outline-none border-none"
                            type="button"
                            onClick={() => setShowAlert(false)}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.5312 3.52827C3.79155 3.26792 4.21366 3.26792 4.47401 3.52827L8.0026 7.05687L11.5312 3.52827C11.7915 3.26792 12.2137 3.26792 12.474 3.52827C12.7344 3.78862 12.7344 4.21073 12.474 4.47108L8.94541 7.99968L12.474 11.5283C12.7344 11.7886 12.7344 12.2107 12.474 12.4711C12.2137 12.7314 11.7915 12.7314 11.5312 12.4711L8.0026 8.94248L4.47401 12.4711C4.21366 12.7314 3.79155 12.7314 3.5312 12.4711C3.27085 12.2107 3.27085 11.7886 3.5312 11.5283L7.0598 7.99968L3.5312 4.47108C3.27085 4.21073 3.27085 3.78862 3.5312 3.52827Z"
                                fill="#2C3236"
                              />
                            </svg>
                          </button>
                        </div>
                      )}

                    <TextEditor {...formikProps} />
                    <FileUpload
                      handleOnDropFiles={handleOnDropFiles}
                      handleDeleteFile={handleDeleteFile}
                      handleUpdateFile={handleUpdateFile}
                      files={files}
                      editMode={createOrEditDataAccessPolicyModal?.editMode}
                      acceptedFileTypes={ACCEPTED_FILES_TYPES}
                      isSubmitting={formikProps?.isSubmitting}
                    />
                  </div>
                </div>

                <div className="flex justify-end gap-2 pb-4 pr-4">
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    disabled={
                      !formikProps.values.policyContent ||
                      (createOrEditDataAccessPolicyModal.editMode &&
                        !programs.canEditDataAccessPolicy) ||
                      (!createOrEditDataAccessPolicyModal.editMode &&
                        !programs.canCreateDataAccessPolicy)
                    }
                    text="Save"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
