import { useState } from "react";

import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import Map from "./Map";

type QuestionElemet = {
  title?: string;
  name: string;
  isRequired: boolean;
  id: string;
  selectedShapes: any[];
  selectedLayers: any[];
  answer: any[];
  dropMultiple: boolean;
  description?: string;
  type: string;
  enableSnapping?: boolean;
};
interface I_DropPin {
  editMode: boolean;
  element: QuestionElemet;
  answer: any[];
  setFieldValue: any;
  eleName: string;
  required?: boolean;
  error?: any;
  touched?: any;
  label?: string;
  panel?: any;
}

const DropPin = ({
  editMode,
  element,
  answer = [],
  setFieldValue,
  eleName,
  required,
  error,
  touched,
  label,
  panel,
}: I_DropPin) => {
  const [markers, setMarkers] = useState<any>([]);
  const [doShowMap, setDoShowMap] = useState(false);

  // handlers
  const handleLayerNameInputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    layerId: string | number
  ) => {
    const value = e.target.value;

    const updatedAnswers = answer.map((ans: any, index: number) => {
      if (ans.id == layerId) {
        ans.name = value;
      }

      return ans;
    });

    setFieldValue(eleName, updatedAnswers);
  };
  const handleClickOnLayerRemoveBtn = (layerId: any) => {
    const updatedAnswers = answer.filter(
      (ans: any, index: any) => ans.id !== layerId
    );

    // draw.delete([layerId]);
    const marker: any = markers.find((m: any) => m.id === layerId);

    if (marker) {
      marker.reference.remove();

      setMarkers((prev: any) => prev.filter((m: any) => m.id !== layerId));
    }

    setFieldValue(eleName, updatedAnswers);
  };

  return (
    <div className="p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight" id={`${element.name}${panel?.sectionId}`}>
      <div className="flex justify-between items-center text-textAnswer">
        <Name
          editMode={editMode}
          label={element.title}
          name={element.name}
          required={element.isRequired}
          drawMultiple={element.dropMultiple}
        />
        <div className="flex-1 basis-1/2 flex justify-end">
          <CustomizedSwitches
            label="View map"
            onChange={(e: any) => setDoShowMap(e.target.checked)}
            labelClassName="text-secondary"
          />
        </div>
      </div>
      <ListView list={answer} hideList={editMode} />
      {editMode && (
        <div className="flex flex-col w-full gap-2 px-3 py-6">
          {/* {answer?.length > 0 && (
            <h3 className="mb-1 text-base font-semibold text-textMid font-Overpass">
              Drop A Pin
            </h3>
          )} */}
          {answer &&
            Array.isArray(answer) &&
            answer?.map((layer: any) => {
              return (
                <MarkerEditBar
                  key={layer.id}
                  defaultValue={layer.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleLayerNameInputOnChange(e, layer.id)
                  }
                  onRemove={() => handleClickOnLayerRemoveBtn(layer.id)}
                />
              );
            })}
        </div>
      )}
      {doShowMap && (
        <Map
          element={element}
          answer={answer}
          eleName={eleName}
          setFieldValue={setFieldValue}
          markers={markers}
          setMarkers={setMarkers}
          editMode={editMode}
        />
      )}

      <Error error={error} />
    </div>
  );
};

export { DropPin };

const Name = ({ editMode, label, name, required, drawMultiple }: any) => {
  return (
    <div>
      <p
        className={`w-full text-md font-medium text-left capitalize font-Overpass ${editMode
          ? "text-primary dark:text-caption font-medium "
          : "text-primary dark:text-caption font-regular "
          }`}
      >
        {label || name} {required && <span>*</span>}
      </p>
      <p
        className={`w-full pb-1 text-xs text-left capitalize font-Overpass ${editMode
          ? "text-primary dark:text-caption font-medium "
          : "text-primary dark:text-caption font-regular "
          }`}
      >
        {drawMultiple
          ? "(NOTE: You can drop multiple points)"
          : "(NOTE: You can drop a single point)"}
      </p>
    </div>
  );
};

const MarkerEditBar = ({ defaultValue, onChange, onRemove }: any) => {
  return (
    <div className="flex w-full">
      <input
        className="flex-1 px-3 py-2 mr-4 text-base border rounded outline-none border-lineDark text-textNormal font-Overpass dark:text-caption "
        placeholder="Region name"
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <button type="button" onClick={onRemove}>
        <TrashIcon />
      </button>
    </div>
  );
};

const Error = ({ error }: any) => {
  return (
    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
      {error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};
const ListView = ({ list, hideList }: any) => {
  if (Array.isArray(list) && !hideList) {
    return (
      <ul className="list-inside list-disc pb-1">
        {list.map((item: any, index: number) => {
          return (
            <li key={item.id} className="font-Overpass text-textAnswer">
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }
  return !hideList && <p className="text-textNoSelection font-normal font-Overpass text-[15px] italic">
    (No Selection)
  </p>;
};
