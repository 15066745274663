import React, { useCallback, useEffect, useState } from "react";
import apiLibrary from "services/api";
import MultiSelect from "view/components/Multiselect";
import { Toasts } from "view/components/Toasts";

export const AddOrganizations = ({
  handleAddOrganizationsOnChange,
  values,
  data=[],
  handleEntitiesSearch
}: any) => {
  const [inputValue, setInputValue] = useState("");
 
  return (
    <div className="flex items-center py-2">
      <p  className="w-[10%] dark:text-textMain">Organizations</p>
      <div className="flex justify-start items-center w-[90%] relative gap-1.5 pl-3  rounded bg-white border border-lineDark">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
            fill="#9B9FA5"
          />
        </svg>
        <div className="flex flex-col items-start justify-start w-full">
          <MultiSelect
            inputValue={inputValue}
            onInputChange={(value: string) => {
              setInputValue(value);
              handleEntitiesSearch(value);
            }}
            options={data}
            defaultValue={{ value: "", label: "" }}
            isMulti={true}
            closeOnSelect={false}
            value={values?.organizationIds}
            onChange={(option: any) => {
              handleAddOrganizationsOnChange(option);
            }}
          />
        </div>
      </div>
    </div>
  );
};
