import { useEffect } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "Components/Geography/MapBox/components/DefaultMap/handlers";

const usePaintLayersOnMap = (
  layers: any,
  map: any,
  isMapFullyLoaded: any,
  color: string
) => {
  useEffect(() => {
    if (layers && layers.length > 0 && map && isMapFullyLoaded) {
      layers.forEach((layer: any) => {
        const item = { item: layer, itemType: "regions", status: true };

        // paint layers on map
        handlePaintingOfVectorRegionShapeLayersOnMap(item, map, {}, color);
      });
    }
  }, [map, isMapFullyLoaded, layers]);

  return null;
};

export { usePaintLayersOnMap };
