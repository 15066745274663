interface Data {
  [key: string]: any;
}

export const getFilteredOrCompleteProperties = (
  data: Data,
  trueProperties: Data | null
): Data => {
  if (trueProperties === null || Object.keys(trueProperties).length === 0) {
    return data;
  }

  return Object.entries(data)
    .filter(([key]) => trueProperties[key])
    .reduce((acc: Data, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
};
