import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_OBSERVATIONS_BY_REGION,
  GET_OBSERVATIONS_BY_REGION_STATE,
} from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- OBSERVATIONS BY REGION
 * @returns
 */
export const fetchObservationsByRegion =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_OBSERVATIONS_BY_REGION_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );
      // console.log("params", params);

      if (params.organizationIds) {
        delete params.organizationIds;
      }
      if (params.datePeriod) {
        Object.assign(params, {
          date_from: params.datePeriod.from,
          date_to: params.datePeriod.to,
        });
        delete params.datePeriod;
      }

      const { data } =
        await apiLibrary.Dashboard.widgets.getObservationsByRegion(params);
      dispatch({
        type: GET_OBSERVATIONS_BY_REGION,
        payload: { data },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_OBSERVATIONS_BY_REGION_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log(
        "An error occurred while fetching the observations by region:",
        error
      );
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };

const dummyData = {
  regions: [
    {
      count: 1,
      map_data: {
        geometries: [
          {
            coordinates: [
              [
                [14.6312746, 37.72080065],
                [14.6312746, 37.617674817],
                [14.831727822, 37.617674817],
                [14.831727822, 37.72080065],
                [14.6312746, 37.72080065],
              ],
            ],
            type: "Polygon",
          },
        ],
        type: "GeometryCollection",
      },
      region_name: "Region1",
    },
  ],
  total_observations: 3,
};
