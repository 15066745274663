import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeArchiveOrRestoreProgramModalAction, closeSuccessMessageModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { programsStatusAction } from "store/filters";
import { useNavigate } from "react-router-dom";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

interface SuccessfulMessageModalProps { }

export const SuccessfulMessageModal: React.FC<
  SuccessfulMessageModalProps
> = () => {
  const { successMessageModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeSuccessMessageModalAction());
  };
  return (
    <Modal
      open={successMessageModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[500px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-3 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="capitalize font-semibold text-lg text-secondaryMid dark:text-textMain font-Overpass">{successMessageModal?.data?.title || "Your invitation was successfully sent"}</p>
              </div>
              <button onClick={handleClose} type="button" className="absolute right-3 top-3">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
              </button>
            </div>
            <div className="w-full flex flex-wrap">
              <p className="text-sm text-secondaryMid dark:text-caption font-Overpass">{successMessageModal?.data?.description || "The invitation was successfully sent to the external reviewer. Once the reviewer opens the invitation you will receive a notification."}</p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
