// assets
import InofIcon from "assets/images/information-circle.svg";

const InfoBar = ({ message }: { message: string }) => {
  return (
    <div className="flex items-center gap-2 text-sm font-Overpass text-textMidLight dark:text-caption">
      <img src={InofIcon} alt="info" className="mb-[3px]" />
      <p className="capitalize dark:text-textMain">{message}</p>
    </div>
  );
};

export { InfoBar };
