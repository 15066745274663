import {
  getUserSuccess,
  updateContactPrefrences,
  getMyProfileSuccess,
  updateSocialaccounts,
} from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { updateAuthData } from "store/auth/action-types";
import { Toasts } from "view/components/Toasts";

export const fetchUserProfileAction =
  (personId: string | undefined, isMyProfile: boolean) =>
  async (dispatch: Dispatch, getState: any) => {
    const currentState = getState();
    try {
      const { data } = await apiLibrary.userProfile.getUserProfile(
        personId !== undefined ? parseInt(personId) : undefined
      );

      dispatch({
        type: getUserSuccess,
        payload: data,
      });

      if (isMyProfile) {
        dispatch({ type: updateAuthData, payload: data });
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      // window.location.href = `/profile/${currentState.auth.id}/person`;
      console.log("An error occurred while fetching the user profile:", error);
    }
  };
export const getUserProfileAction = (res: any) => {
  return {
    type: getUserSuccess,
    payload: res,
  };
};

export const getMyProfileSuccessAction = (res: any) => {
  return {
    type: getMyProfileSuccess,
    payload: res,
  };
};

export const updateContactPrefrencesAction = (res: any) => {
  return {
    type: updateContactPrefrences,
    payload: res,
  };
};

export const updateSocialAccountsAction = (res: any) => {
  return {
    type: updateSocialaccounts,
    payload: res,
  };
};

export const resetData = (res?: any) => {
  return {
    type: "RESET_USER_DATA",
    payload: res,
  };
};
