import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const AdjustmentsHorizIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M21 6a1 1 0 0 1-1 1h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1 0-2h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 0 1 1 1Zm0 6a1 1 0 0 1-1 1h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 1 1 0-2h9.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 0 1 1 1Zm0 6a1 1 0 0 1-1 1h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 1 1 0-2h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 0 1 1 1Zm-4-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM9 6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm0 12a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default AdjustmentsHorizIcon;
