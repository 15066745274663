import Head from "view/components/Head";

interface IProps {
  reportsName: any;
}
const PageHeading: React.FC<IProps> = ({
  reportsName,
}) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={`Reports ${reportsName}`} />
      <div className="relative flex items-start justify-start">
        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {reportsName}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
