import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeDeactivateUserModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import CustomCheckbox from "view/components/CheckBox";
import Button from "view/components/Button";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchUserProfileAction } from "store/userProfile/reducer.actions";
import { usersStatusAction } from "store/filters/reducer.actions";
import Roles from "hooks/roles";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

export const DeactivateUser = () => {
  const { deactivateUserModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isLoading, setIsLoading] = useState(false);
  const { isMyProfile } = Roles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { users } = usePermissions();
  const [notifyUser, setnotifyUser] = useState(false);
  const suspendUser = async () => {
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      // Make the API call to suspend the user
      const res = await apiLibrary.userProfile.changeUserStatus(
        deactivateUserModal.data.userId,
        notifyUser,
        "suspended"
      );
      Toasts.success(res.message);
      if (deactivateUserModal.data.path === "directory") {
        dispatch(usersStatusAction("suspended"));
      } else if (
        deactivateUserModal.data.path === "userProfile" &&
        users.canViewUsersProfile
      ) {
        dispatch(
          fetchUserProfileAction(
            deactivateUserModal.data.personId,
            isMyProfile(deactivateUserModal.data.personId)
          )
        );
      }
      handleClose();
    } catch (error: any) {
      Toasts.success(error.response.data.message);
      // Handle any errors that occur during the API call
      console.error("Error suspending user:", error);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const handleClose = () => {
    dispatch(closeDeactivateUserModalAction());
  };

  return (
    <Modal
      open={deactivateUserModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between pb-2 items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Suspend Account
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div>
              <p className="w-[410px] text-sm text-left text-secondaryMid dark:text-textMain">
                <span className="text-sm text-left break-all text-secondaryMid dark:text-caption">
                  Are you sure you want to deactivate this user. They will no
                  longer have access to their account
                </span>
              </p>
              <p className="mt-1">
                <span className="text-sm text-left text-secondaryMid dark:text-caption">
                  This action can be undone.
                </span>
              </p>
            </div>
            <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 rounded-lg">
              <CustomCheckbox
                onChange={(e) => setnotifyUser(e.target.checked)}
                value={notifyUser}
                label={
                  <div className="flex items-center justify-start flex-grow pl-2">
                    <div className="flex justify-start items-center flex-grow relative py-0.5">
                      <p className="flex-grow w-[576px] text-[15px] text-left text-secondaryMid dark:text-caption">
                        Notify User
                      </p>
                    </div>
                  </div>
                }
                labelPlacement="end"
              />
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text="Confirm"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={suspendUser}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
