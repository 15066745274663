// import React, { useEffect, useState } from "react";
// import BpCheckbox from "view/components/CheckBox";
// import "./Types.scss";
// import apiLibrary from "services/api";
// import InputField from "view/components/InputField";
// import { useDispatch } from "react-redux";
// import { conditionsTypeAction } from "store/filters/reducer.actions";
// import { useSelector } from "react-redux";
// import { RootState } from "store";
// import ellipsize from "ellipsize";
// import { filtersInitialState } from "store/filters/initialState";
// import _ from "lodash";

// export const Types = () => {
//   const { conditionsFilter } = useSelector<RootState, filtersInitialState>(
//     (state) => state.Filters
//   );
//   const [searchString, setSearchString] = useState("");

//   const dispatch = useDispatch();

//   const [dropDownValues, setDropDownValues] = useState<any[]>([]);

//   const handleSelection = (
//     e: React.ChangeEvent<HTMLInputElement>,
//     selectedValue: any    
//   ) => {
//     dispatch(conditionsTypeAction(selectedValue));
//   };

//   const fetchOrgDropdownData = async (search?: string) => {
//     try {
//       const typesData =
//         await apiLibrary.SpeciesAndObjects.getTypesDropdown(search);
//       setDropDownValues(
//         typesData.data.map((type: any) => {
//           return {
//             name: type.typeName,
//             id: type.id,
//           };
//         })
//       );
//     } catch (error: any) {
//       setDropDownValues([])
//       // Handle error
//       console.error("Failed to fetch organization dropdown values:", error);
//     }
//   };

//   const debouncedFunction = _.debounce(fetchOrgDropdownData, 500);

//   useEffect(() => {
//     debouncedFunction(searchString);
//   }, [searchString]);
//   return (
//     <div className="bg-bgWhite  w-[315px] overflow-hidden shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid">
//       <div className="w-full">
//         <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
//           <InputField
//             placeholder="Search"
//             type="Search"
//             value={searchString}
//             onChange={(e: any) => setSearchString(e.target.value)}
//           />
//         </div>
//         <div className="flex flex-col max-h-[490px] overflow-y-scroll justify-start items-center w-full relative px-1.5">
//           {dropDownValues.map((dropDown: any, index: any) => (
//             <div
//               key={index}
//               className="flex items-center justify-start w-full px-1 rounded-lg"
//             >
//               <BpCheckbox
//                 checked={conditionsFilter.typeIds.value.some(
//                   (item: any) => item.id === dropDown.id
//                 )}
//                 label={
//                   <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
//                     <p className="dark:text-inputText">{ellipsize(`${dropDown.name}`, 25)}</p> 
//                   </div>
//                 }
//                 labelPlacement="end"
//                 onChange={(e) => handleSelection(e, dropDown)}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };






import React, { useEffect, useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import "./Types.scss";
import apiLibrary from "services/api";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import { behaviorsTypeAction, conditionsTypeAction, objectsTypesAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import InfiniteScroll from "react-infinite-scroll-component";
import { filtersInitialState } from "store/filters/initialState";
import _ from "lodash";
import { TailSpin } from "react-loader-spinner";

export const Types = () => {
  const { conditionsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const [searchString, setSearchString] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const [dropDownValues, setDropDownValues] = useState<any[]>([]);

  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedValue: any
  ) => {
    dispatch(conditionsTypeAction(selectedValue));
  };

  const fetchOrgDropdownData = async (
    isLoadMore: boolean,
    page: number = 1,
    search?: string
  ) => {
    setIsLoading(true);
    try {
      const { data } = await apiLibrary.SpeciesAndObjects.getTypesDropdown(
        search,
        page
      );
      const typesData = data.map((item: any) => {
        return { id: item.id, name: item.typeName };
      });

      if (isLoadMore) {
        setDropDownValues((pre: any[]) => {
          return [...pre, ...typesData];
        });
      } else {
        setDropDownValues(typesData);
      }
      setHasMoreData(data.hasMore);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      console.error("Failed to fetch program dropdown values:", error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOrgDropdownData(false);
  }, []);
  const handleSearchEntities = (searchString: string): void => {
    setPage((pre: number) => {
      fetchOrgDropdownData(false, 1, searchString);
      return 1;
    });
  };

  const debouncedSearch = _.debounce(handleSearchEntities, 100);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchOrgDropdownData(true, page, searchString);
      return page;
    });
  };

  return (
    <div className="bg-bgWhite  w-[315px] overflow-hidden shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid">
      <div className="w-full">
        <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
          <InputField
            placeholder="Search"
            type="Search"
            value={searchString}
            onChange={(e: any) => {
              setSearchString(e.target.value);
              debouncedSearch(e.target.value);
            }}
          />
        </div>
        <div className="pl-1.5 bg-bgWhite dark:bg-secondaryLight">
          <InfiniteScroll
            dataLength={dropDownValues.length}
            next={handleLoadMore}
            hasMore={hasMoreData}
            height={400}
            loader={
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            }
            pullDownToRefresh={true}
            refreshFunction={() => { }}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh &#8595;
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh &#8593;
              </h3>
            }
          >

            {dropDownValues.length === 0 && !isLoading && (
              <div className="w-full flex items-center justify-center py-32 text-textMid font-semibold dark:text-inputText">
                No Record Found
              </div>
            )}

            {hasMoreData && isLoading && (
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            )}

            {dropDownValues.map((dropDown: any, index: any) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-start w-full px-1 rounded-lg"
                >
                  <BpCheckbox
                    checked={conditionsFilter.typeIds.value.some(
                      (item: any) => item.id === dropDown.id
                    )}
                    label={
                      <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
                        <p className="dark:text-inputText">
                          {ellipsize(`${dropDown.name}`, 30)}
                        </p>
                      </div>
                    }
                    labelPlacement="end"
                    onChange={(e) => handleSelection(e, dropDown)}
                  />
                </div>
              )
            }

            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
