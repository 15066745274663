import { ColumnProps } from "../../types";
import { RenderCellContent } from "../index";

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  isExternalReviewer,
  observationId,
  programManagers,
  rowData,
}: ColumnProps) => {
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;

  if (isColumnVisible && cell.column.columnDef.id !== "select") {
    return (
      <td key={cell.id} className="px-4">
        <RenderCellContent
          rowData={rowData}
          columnDef={cell.column.columnDef}
          cellValue={cell.getValue()}
          observationId={observationId}
          programManagers={programManagers}
        />
      </td>
    );
  } else {
    return (
      <td
        key={cell.id}
        className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
      >
        {!isExternalReviewer ? (
          <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
        ) : (
          <></>
        )}
      </td>
    );
  }
};
