import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
}

export const CameraIcon = ({
  fill,
  isActive,
  width,
  height,
  ...restProps
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M10.07 5a1 1 0 0 0-.832.445l-.812 1.22A3 3 0 0 1 5.93 8H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-.93a3 3 0 0 1-2.496-1.336l-.812-1.219A1 1 0 0 0 13.93 5h-3.86Zm-2.496-.664A3 3 0 0 1 10.07 3h3.86a3 3 0 0 1 2.496 1.336l.812 1.219A1 1 0 0 0 18.07 6H19a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h.93a1 1 0 0 0 .832-.445l.812-1.22ZM12 11a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CameraIcon;
