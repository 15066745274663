import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  Date,
  SelectedForms,
  SelectedCommunities,
  SelectedOrganizations,
  SelectedPrograms,
} from "./Components";
import { useDispatch } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { dashboardResetFiltersAction } from "store/filters";

const AppliedFilters = () => {
  const { dashboardFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();



  const clearAllFilters = () => {
    dispatch(dashboardResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyDashboardFilterApplied(dashboardFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap justify-start flex-shrink-0"
      >
        <div className="flex flex-wrap gap-2">
          <SelectedPrograms />
          <SelectedForms />
          <SelectedCommunities />
          <SelectedOrganizations />

          <Date
            name="datePeriod"
            from={dashboardFilter.datePeriod.from}
            to={dashboardFilter.datePeriod.to}
            isApplied={dashboardFilter.datePeriod?.applied}
          />
        </div>
      </div>{" "}
    </div>
  );
};

export default AppliedFilters;


export function isAnyDashboardFilterApplied (dashboardFilter: any): boolean {
  const { datePeriod, formIds, programIds, organizationIds, communityIds } =
    dashboardFilter;

  const applied =
    datePeriod?.applied ||
    formIds?.applied ||
    organizationIds?.applied ||
    programIds?.applied ||
    communityIds?.applied;
  return applied;
};