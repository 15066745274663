import { apiClient } from "../config";

async function getAllAnalyticsReports(queryParams: any, id: any) {
  const res = await apiClient.get(`/admin/reports/${id}/analytics`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllAnalyticsReports;
