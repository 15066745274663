import React, { useEffect, useState } from 'react';
import { RenderCellContentProps } from '../../types';
import ellipsize from 'ellipsize';

import useDateFormatter from 'hooks/formatDateWithPattern';
import { Statuses } from 'Components/Observations/Statuses';
import { getBrandPlaceholder } from 'utils/customBranding';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
  rowData,
}) => {
  const { formatDate } = useDateFormatter();
  const [changeColor, setChangeColor] = useState<any>();

  const StatusColor = () => {
    switch (rowData.original.flag) {
      case "red":
        setChangeColor("bg-accent_1Light text-accent_1Dark");
        break;
      case 'green':
        setChangeColor('bg-tertiaryLight  text-tertiary');
        break;
      case 'blue':
        setChangeColor('bg-primaryExtraLight text-textLink');
        break;
      case 'grey':
        setChangeColor('bg-brandHover text-secondaryMid');
        break;
      case 'orange':
        setChangeColor('bg-accent_1LightExtraMid text-accent_tertiary_red');
        break;
      default:
        setChangeColor('bg-primaryExtraLight text-textLink');
        break;
    }
  };

  useEffect(() => {
    StatusColor();
  }, [rowData.original.flag]);

  if (
    typeof cellValue === 'object' &&
    cellValue !== null &&
    (columnDef.id === 'programName' ||
      columnDef.id === 'observer' ||
      columnDef.id === 'communityName')
  ) {
    return (
      <div className='flex justify-start w-[250px] items-center relative group'>
        <img
          src={
            cellValue?.image?.thumb
              ? cellValue?.image?.thumb
              : getBrandPlaceholder("listingProfile")
          }
          alt=''
          height='100'
          width='100'
          className='object-cover mx-2 Img_user_Data'
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: 'wrap' }}
          className=' flex flex-col w-full justify-start items-start'
        >
          <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark py-2'>
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (
    typeof cellValue === 'object' &&
    cellValue !== null &&
    columnDef.id === 'formName'
  ) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue?.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'observationCode') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p className='text-sm text-left whitespace-nowrap text-textMid group-hover:text-primaryDark'>
          {ellipsize(`${cellValue.observationCode}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'oldCode') {
    return (
      <div
        className='relative flex items-start flex-grow py-1 px-3 justify-start
        '
      >
        <p className='text-sm text-left whitespace-nowrap text-textMid group-hover:text-primaryDark'>
          {`${cellValue}`}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'submissionDate') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left whitespace-nowrap text-textMid group-hover:text-primaryDark`}
        >
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'protocol') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p className={`text-sm text-left whitespace-nowrap text-textMid group-hover:text-primaryDark`}>
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'type') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <div
          className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded ${
            cellValue === 'Public' ? 'bg-[#e3f8e6]' : 'bg-primaryExtraLight'
          }  `}
        >
          <div className='relative flex items-center justify-center flex-grow-0 flex-shrink-0 py-1'>
            <p
              className={`flex-grow-0 flex-shrink-0 text-xs text-center ${
                cellValue === 'Public' ? 'text-[#739f50]' : 'text-primaryDark'
              } `}
            >
              {}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (columnDef.id === 'status' && cellValue !== null) {
    return (
      <div className='px-[6px] w-[120px] status'>
        <Statuses status={cellValue} changeColor={changeColor} />
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p className={`text-sm text-left  whitespace-nowrap text-textMid group-hover:text-primaryDark`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
