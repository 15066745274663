import { apiClient } from "../config";

async function getSingleLayer(
  communityId: string,
  layerId: string
) {
  const res = await apiClient.get(
    `/admin/communities/${communityId}/geography/${layerId}`
  );
  return res.data;
}

export default getSingleLayer;
