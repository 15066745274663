import { apiClient } from "../config";

async function getAllQualityAssuranceObservationsForExternalReviewer(
  queryParams: any
) {
  const res = await apiClient.get(`/admin/qa/reviewer-observations`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllQualityAssuranceObservationsForExternalReviewer;
