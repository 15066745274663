import React, { useState } from "react";
import { Field } from "formik";
import { EyeOffIcon, EyeIcon } from "assets/icons/HeroIcons";
import classnames from "classnames";
interface FormInputProps {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  id: string;
  error?: any;
  touched?: any;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  name,
  placeholder,
  id,
  error,
  onChange,
  onBlur,
  touched,
  value,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordInput = type === "password";

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-1">
          <div className="flex items-center">
            <p
              className={`flex-grow w-full text-secondaryMid dark:text-caption text-sm font-medium text-left capitalize ${
                touched && error ? "text-accent_1Dark" : ""
              }`}
            >
              {label}
            </p>
          </div>
          <div
            className={classnames(
              "flex",
              "justify-start",
              "items-center",
              "w-full",
              "focus:outline-none",
              "gap-1.5",
              "rounded",
              "bg-bgWhite",
              "dark:bg-secondaryLight ",
              "border",
              {
                "pr-3": isPasswordInput,
                "border-accent_1Dark dark:border-accent_1Dark": touched && error,
                "border-lineDark dark:border-lineLight": !touched || !error,
              }
            )}
          >
            <Field
              type={showPassword ? "text" : type}
              id={id}
              disabled={disabled}
              name={name}
              placeholder={placeholder}
              className={` flex text-textNormal items-center px-3 placeholder-textLightExtra dark:text-inputText flex-grow relative pt-0.5 border-0 p-2 gap-1 w-90 h-10 bg-white rounded focus:outline-none focus:ring-0 ${
                touched && error ? "text-accent_1Dark" : ""
              }`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {isPasswordInput && (
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="focus:outline-none"
              >
                {showPassword ? (
                  <EyeIcon className="dark:text-caption h-6 w-6" />
                ) : (
                  <EyeOffIcon className="dark:text-caption h-6 w-6" />
                )}
              </button>
            )}
          </div>
          {touched && error && (
            <p className="text-xs capitalize leading-4 flex items-center text-accent_1Dark">
              {error}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FormInput;
