import { RESET_MODE, TOGGLE_DARK_MODE } from "./action-types";
import { initialState } from "./initialState";

const darkModeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TOGGLE_DARK_MODE:
            return {
                ...state,
                isDarkMode: !state.isDarkMode,
            };
        case RESET_MODE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default darkModeReducer;