import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import { FILTERS_OPTIONS } from "../..";

const Filters = ({ handleFiltersOnChange, defaultValue }: any) => {
  return (
    <RadioGroup
      aria-labelledby="geospatial-filter-group"
      value={defaultValue}
      name="geospatial-filter"
      onChange={handleFiltersOnChange}
    >
      <div className="flex items-center justify-between">
        <RadioBtn
          checked={defaultValue === FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY}
          value={FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY}
          className="w-[33%]"
          labelContent={
            <Label
              isActive={defaultValue === FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY}
              text="No geospatial boundary"
            />
          }
        />
        <RadioBtn
          checked={defaultValue === FILTERS_OPTIONS.CHOOSE_FROM_EXISTING}
          value={FILTERS_OPTIONS.CHOOSE_FROM_EXISTING}
          className="w-[33%]"
          labelContent={<Label isActive={defaultValue === FILTERS_OPTIONS.CHOOSE_FROM_EXISTING} text="Choose from existing" />}
        />
        <RadioBtn
          checked={defaultValue === FILTERS_OPTIONS.DRAW_ON_MAP}
          value={FILTERS_OPTIONS.DRAW_ON_MAP}
          className="w-[33%]"
          labelContent={<Label isActive={defaultValue === FILTERS_OPTIONS.DRAW_ON_MAP} text="Draw on the map" />}
        />
      </div>
    </RadioGroup>
  );
};

export { Filters };

const Label = ({ text, isActive }: { text: string; isActive: boolean }) => {
  return (
    <p
      className={`font-Overpass text-[15px] capitalize text-secondaryMid -mb-[5px] ${
        isActive ? "dark:text-textMain" : "dark:text-caption"
      } `}
    >
      {text}
    </p>
  );
};
