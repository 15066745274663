// Components
import { useEffect } from "react";
import {
  CreateRasterLayer,
  CreateRegionLayer,
  CreateShapes,
  CreateVectorLayer,
  EditRasterLayer,
  ViewRasterLayerDetail,
  ViewRegionLayerDetail,
  ViewVectorLayerDetail,
  EditRegion,
  EditShapeLayer,
  CreatePoints,
  ViewShapeLayerDetail,
  ViewPointLayerDetail,
  EditPointLayer,
} from "./components";
import { LayersListing } from "./components";
// Store utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { screens } from "store/geography/initialState";
import { useDispatch } from "react-redux";
import {
  displayDefaultMap,
  doFetchAllLayers,
  openAllLayersListingScreen,
  updateDrawShapeCoordinates,
  updateSelectedShape,
} from "store/geography";

import { EditVectorLayer } from "./components/EditVectorLayer";

const SideBar = () => {
  const { activeSidebarScreen, showLoaderLayer } = useSelector(
    (state: RootState) => state.geography
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(openAllLayersListingScreen());
    };
  }, []);

  // reset map settings on navigating to all layers screen
  useEffect(() => {
    if (activeSidebarScreen === screens.ALL_LAYERS) {
      dispatch(displayDefaultMap());
      dispatch(updateDrawShapeCoordinates(null));
      dispatch(updateSelectedShape(""));
      dispatch(doFetchAllLayers(true));
    }
  }, [activeSidebarScreen]);

  function _renderScreen() {
    switch (activeSidebarScreen) {
      case screens.ALL_LAYERS:
        return <LayersListing />;

      case screens.ADD_RASTER_LAYER:
        return <CreateRasterLayer />;

      case screens.ADD_VECTOR_LAYER:
        return <CreateVectorLayer />;

      case screens.ADD_REGION_LAYER:
        return <CreateRegionLayer />;

      case screens.EDIT_RASTER_LAYER:
        return <EditRasterLayer />;

      case screens.EDIT_VECTOR_LAYER:
        return <EditVectorLayer />;

      case screens.EDIT_VECTOR_LAYER:
        return <EditVectorLayer />;

      case screens.EDIT_SHAPE_LAYER:
        return <EditShapeLayer />;

      case screens.VIEW_RASTER_LAYER:
        return <ViewRasterLayerDetail />;

      case screens.EDIT_REGION_LAYER:
        return <EditRegion />;

      case screens.VIEW_VECTOR_LAYER:
        return <ViewVectorLayerDetail />;

      case screens.VIEW_REGION_LAYER:
        return <ViewRegionLayerDetail />;

      case screens.ADD_SHAPE:
        return <CreateShapes />;

      case screens.VIEW_SHAPE_LAYER:
        return <ViewShapeLayerDetail />;

      case screens.ADD_POINTS_LAYER:
        return <CreatePoints />;

      case screens.VIEW_POINT_LAYER:
        return <ViewPointLayerDetail />;

      case screens.EDIT_POINT_LAYER:
        return <EditPointLayer />;

      default:
        return <h1>Unknown</h1>;
    }
  }

  return (
    <section className="basis-1/4 md:min-w-[270px] xl:min-w-[430px] 2xl:min-w-[440px] bg-bgWhite dark:bg-secondaryLight font-Overpass px-6 py-4 relative overflow-hidden h-[94vh]">
      {_renderScreen()}
    </section>
  );
};

export default SideBar;
