import getAllBehaviors from "./getAllBehaviors";
import archiveOrRestoreBehaviors from "./archiveOrRestoreBehavior";
import addBehaviors from "./addBehavior";
import editBehaviors from "./editBehavior";
import getAllConditions from "./getAllConditions";
import editCondition from "./editConditions";
import addCondition from "./addConditions";
import archiveOrRestoreConditions from "./archiveOrRestoreConditions";

const BehaviorsAndConditions = {
  getAllBehaviors,
  archiveOrRestoreBehaviors,
  addBehaviors,
  editBehaviors,
  getAllConditions,
  archiveOrRestoreConditions,
  addCondition,
  editCondition,
};

export default BehaviorsAndConditions;
