// import React, { useEffect, useState } from "react";
// import { ObservationStatuses } from "./Components/Observers";
// import Select, { SingleValue, components } from "react-select";
// import { ThunkDispatch } from "redux-thunk";
// import { AnyAction } from "redux";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { filtersInitialState } from "store/filters/initialState";
// import { RootState } from "store";
// import {
//   importsViewDataStatusAction,
//   pollAndSurveyResultsVersionAction,
// } from "store/filters";
// import { CheveronDownIcon } from "assets/icons/HeroIcons";
// import apiLibrary from "services/api";
// import { useParams } from "react-router-dom";

// interface OptionType {
//   value: string;
//   label: string;
// }

// const FiltersList = ({ isCloseDiv }: any) => {
//   const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
//   const { surveyAndPolls } = usePermissions();
//   const [surveyVersions, setSurveyVersions] = useState<any[]>([]);
//   const { pollId } = useParams();
//   const { pollAndSurveyResultsFilter } = useSelector<
//     RootState,
//     filtersInitialState
//   >((state) => state.Filters);
//   const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

//   const getSurveyVersions = async (id: any) => {
//     try {
//       const { data } =
//         await apiLibrary.pollsAndSurveys.getPollAndSurveyVersions(id);
//       const sortedData = sortVersions(data);
//       const dataa = sortedData.map((item: any) => {
//         return { label: item.name, value: item.id };
//       });

//       setSelectedOption(dataa[0]);
//       setSurveyVersions(dataa);
//     } catch (error) {
//       console.error("Error fetching survey versions:", error);
//     }
//   };

//   useEffect(() => {
//     if (pollId !== undefined) {
//       getSurveyVersions(pollId);
//     }
//   }, []);

//   const handleChange = (option: SingleValue<OptionType>) => {
//     setSelectedOption(option as OptionType);
//     dispatch(pollAndSurveyResultsVersionAction(option?.value ?? ""));
//   };

//   return (
//     <div>
//       {surveyAndPolls.canViewVersionSurveyAndPolls && (
//         <div className="relative flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
//           <div className="z-[99]">
//             <Select
//               value={selectedOption}
//               onChange={handleChange}
//               options={surveyVersions}
//               isSearchable={false}
//               components={{ DropdownIndicator: CustomDropdownIndicator }}
//               styles={{
//                 container: (provided) => ({
//                   position: "relative",
//                   zIndex: 999,
//                   border: "none",
//                 }),
//                 control: (provided) => ({
//                   ...provided,
//                   fontWeight: "500",
//                   background: "none",
//                   cursor: "pointer",
//                   fontSize: "14px",
//                   textTransform: "capitalize",
//                   borderRadius: "0.5rem",
//                   borderColor: "#E6E8E9",
//                 }),
//                 menu: (provided) => ({
//                   ...provided,
//                   minWidth: "180px",
//                 }),
//                 menuList: (provided) => ({
//                   ...provided,
//                   textTransform: "capitalize",
//                   fontSize: "14px",
//                 }),
//                 indicatorsContainer: () => ({
//                   padding: 0,

//                   "&.indicatorContainer": {
//                     padding: 0,
//                   },
//                 }),
//                 valueContainer: (provided) => ({
//                   ...provided,
//                   display: "flex",
//                   paddingRight: 0,
//                 }),
//                 option: (provided, state) => ({
//                   ...provided,
//                   cursor: "pointer",
//                   color: state.isSelected ? "#005C89" : "#2C3236",
//                   backgroundColor: state.isSelected ? "#DBE8EE" : "transparent",
//                   fontWeight: state.isSelected ? "500" : "400",
//                 }),
//               }}
//             />
//           </div>
//         </div>
//       )}
//       {/* <AppliedFilters /> */}
//     </div>
//   );
// };

// export default FiltersList;
// const CustomDropdownIndicator = (props: any) => {
//   return (
//     <components.DropdownIndicator {...props}>
//       <CheveronDownIcon
//         style={{
//           transform: props.selectProps.menuIsOpen
//             ? "rotate(180deg)"
//             : "rotate(0deg)",
//           transition: "transform 0.2s",
//           position: "relative",
//           height: "18px",
//           width: "18px",
//         }}
//       />
//     </components.DropdownIndicator>
//   );
// };

// export function sortVersions(versions: any[]) {
//   if (!Array.isArray(versions)) {
//     return [];
//   }
//   return versions.sort((a, b) => {
//     const versionA = parseInt(a.name.split("-")[1]);
//     const versionB = parseInt(b.name.split("-")[1]);
//     return versionB - versionA;
//   });
// }

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { DateFilter } from "./Components/Date";
import { Versions } from "./Components";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import AppliedFilters from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

interface IFiltersListing {
  label: string;
  name: string;
  dropdownComponent?: React.ReactNode;
  isVisible: boolean;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const { pollAndSurveyResultsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [rightOffsetWidth, setRightOffsetWidth] = useState(false);

  const handleMouseEnter = (dropdownName: string, event: any) => {
    if (
      showDropdown === dropdownName &&
      !isDatePickerElementAndButtonLabel(event.target, dropdownName)
    ) {
      setShowDropdown(null);
    } else {
      setShowDropdown(dropdownName);
    }
  };

  function isDatePickerElementAndButtonLabel(
    target: any,
    dropdownName?: string | null
  ) {
    if (!dropdownName) {
      return false;
    }

    const selector = `#${dropdownName}id, .MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root`; // Correct the selector string by removing the trailing comma
    return target?.closest(`${selector}`);
  }

  const List: IFiltersListing[] = [
    // {
    //   label: "Versions",
    //   name: "Versions",
    //   isVisible: surveyAndPolls.canViewVersionSurveyAndPolls,
    //   dropdownComponent: <Versions />,
    // },

    {
      label: "Response Date",
      name: "response_date",
      isVisible: true,
      dropdownComponent: (
        <DateFilter
          name="responseDate"
          from={pollAndSurveyResultsFilter?.responseDate?.from}
          to={pollAndSurveyResultsFilter?.responseDate?.to}
        />
      ),
    },
  ];

  useEffect(() => {
    if (showDropdown) {
      const currentDropdownMenu = document.querySelector(
        `#${showDropdown}id .menu`
      );
      const btn = document.querySelector(`[data-dropdown="${showDropdown}"]`);
      if (currentDropdownMenu && btn) {
        const dropdownRect = currentDropdownMenu.getBoundingClientRect();
        const btnwidth = btn.getBoundingClientRect();

        const btnwidthoffset = window.innerWidth - btnwidth.right;
        const setDicDirection = dropdownRect.width - btnwidth.width;

        const getRightOffset = btnwidthoffset - setDicDirection;
        setRightOffsetWidth(getRightOffset < 0);
      }
    }
  }, [showDropdown]);

  function handleClickOutside(event: any) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isDatePickerElement(event.target)
    ) {
      setShowDropdown(null);
    }
  }
  function isDatePickerElement(target: any) {
    return target.closest(
      `.MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root, .btn`
    ); // Added MuiIconButton-root
  }

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <div
      className={`flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 ${
        isCloseDiv ? "h-min-[40px]" : "h-[0px] opacity-0"
      } transition-h duration-300 ease-in-out`}
    >
      <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
      <Versions />
        
        {List.filter((item) => item.isVisible).map((item, index) => (
          <div
            key={index}
            data-dropdown={item.name}
            onClick={(event) => handleMouseEnter(item.name, event)}
            id={item.name + "index"}
            className="btn flex justify-center items-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg border cursor-pointer hover:border-primaryMid hover:bg-primary/[0.08]  border-lineMid"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-secondaryMid dark:text-primaryOutlined ">
                {item.label}
              </p>
            </div>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              style={{
                transform: `rotate(${
                  showDropdown === item.name ? "180deg" : "0"
                })`,
                transition: "transform 0.3s ease",
              }}
            />
            {showDropdown === item.name && (
              <div
                id={item.name + "id"}
                className={`absolute ${
                  !rightOffsetWidth ? "left-0" : "right-0"
                }  z-30 top-full ring-offset-8`}
              >
                <div
                  className={`${
                    item.dropdownComponent && showDropdown === item.name
                      ? "visible"
                      : "invisible"
                  } menu `}
                  ref={dropdownRef}
                >
                  {item.dropdownComponent}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <AppliedFilters />
    </div>
  );
};

export default FiltersList;
