import { useEffect } from "react";
import { __paintShapeByGeoJsonOnMap__ } from "../workerMehods";
import { generatingLayerId } from "../utils";
import { handlePaintingOfRasterLayersOnMap } from "../handlers/handlePaintingOfRasterLayersOnMap";
import { handleCreationOfMarkerOnMap } from "../handlers/handleCreationOfMarkerOnMap";
import { convertGeoJSON } from "utils/convertFeatureCollection";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR ="#FFA500";

const usePaintLayers = (
  regionData: any[],
  map: any,
  isMapFullyLoaded: boolean
) => {
  useEffect(() => {
    if (!regionData || !map || !isMapFullyLoaded) return;

    regionData.forEach((dataItem: any, index: number) => {
      switch (true) {
        case dataItem.itemType === "raster":
          handlePaintingOfRasterLayersOnMap(dataItem, map);
          // rasterItems.push(item);
          break;

        case dataItem.itemType === "vector" ||
          dataItem.itemType === "region" ||
          dataItem.itemType === "shape":
          const { sourceId, layerId } = generatingLayerId(
            dataItem.itemType,
            index
          );

         const geoJson = convertGeoJSON(dataItem.mapData) 

          if (geoJson && !map.getLayer(layerId) && !map.getSource(sourceId)) {
            // paint the layer

            __paintShapeByGeoJsonOnMap__(
              map,
              sourceId,
              layerId,
              geoJson,
              LAYER_DEFAULT_COLOR
            );
          }
          break;

        case dataItem.itemType === "point":
          handleCreationOfMarkerOnMap(dataItem, map, mapboxgl);
          break;
      }
    });
  }, [regionData, map, isMapFullyLoaded]);

  return 0;
};

export { usePaintLayers };
