export const screens = {
  ALL_LAYERS: "ALL_LAYERS",
  ADD_RASTER_LAYER: "ADD_RASTER_LAYER",
  ADD_VECTOR_LAYER: "ADD_VECTOR_LAYER",
  ADD_REGION_LAYER: "ADD_REGION_LAYER",
  EDIT_RASTER_LAYER: "EDIT_RASTER_LAYER",
  EDIT_VECTOR_LAYER: "EDIT_VECTOR_LAYER",
  EDIT_SHAPE_LAYER: "EDIT_SHAPE_LAYER",
  VIEW_RASTER_LAYER: "VIEW_RASTER_LAYER",
  VIEW_VECTOR_LAYER: "VIEW_VECTOR_LAYER",
  VIEW_REGION_LAYER: "VIEW_REGION_LAYER",
  EDIT_REGION_LAYER: "EDIT_REGION_LAYER",
  VIEW_SHAPE_LAYER: "VIEW_SHAPE_LAYER",
  ADD_SHAPE: "ADD_SHAPE",
  ADD_POINTS_LAYER: "ADD_POINTS_LAYER",
  ADD_POINT_BY_PICK_ON_MAP: "ADD_POINT_BY_PICK_ON_MAP",
  VIEW_POINT_LAYER: "VIEW_POINT_LAYER",
  EDIT_POINT_LAYER: "EDIT_POINT_LAYER",
};

export const shapeTypes = {
  CIRCLE: "circle",
  POLYGON: "polygon",
  LINE: "line",
  RECTANGLE: "rectangle",
  POINT: "point",
};

type T_ActiveSidebarScreen =
  | typeof screens.ALL_LAYERS
  | typeof screens.ADD_RASTER_LAYER
  | typeof screens.ADD_VECTOR_LAYER
  | typeof screens.ADD_REGION_LAYER
  | typeof screens.EDIT_RASTER_LAYER
  | typeof screens.VIEW_RASTER_LAYER
  | typeof screens.ADD_SHAPE
  | typeof screens.EDIT_VECTOR_LAYER
  | typeof screens.EDIT_SHAPE_LAYER
  | typeof screens.VIEW_VECTOR_LAYER
  | typeof screens.EDIT_REGION_LAYER
  | typeof screens.VIEW_REGION_LAYER
  | typeof screens.ADD_POINTS_LAYER
  | typeof screens.VIEW_SHAPE_LAYER
  | typeof screens.VIEW_POINT_LAYER
  | typeof screens.EDIT_POINT_LAYER;

type T_CurrentSelectedShape =
  | typeof shapeTypes.CIRCLE
  | typeof shapeTypes.RECTANGLE
  | typeof shapeTypes.POLYGON
  | typeof shapeTypes.POINT
  | typeof shapeTypes.LINE;

export interface I_InitialStateOfGeography {
  activeSidebarScreen: T_ActiveSidebarScreen;
  editRasterLayerData?: any;
  viewRasterLayerData?: any;
  editVectorLayerData?: any;
  viewVectorLayerData?: any;
  viewRegionLayerData?: any;
  editRegionData?: any;
  editShapeData?: any;
  currentLayerToDisplayOnMap?: any;
  viewShapeLayerData?: any;
  currentSelectedShape?: T_CurrentSelectedShape;
  viewPointLayerData?: any;
  editPointLayerData?: any;
  shapeCoordinates?: any;
  selectedLayersItems?: any[];
  selectedItemToZoom?: any;
  doRemoveAllDrawShapes?: boolean;
  doResetMap?: boolean;
  allLayers?: any[];
  doFetchAllLayersData: boolean;
  showLoaderLayer: boolean;
  layersDataType: "archive" | "active";
  showSnapingControl: boolean;
  selectedRegionLayerForRegionSelectForm: any;
  selectedShapeLayerForShapeSelectForm: any;
  selectedPointLayerForPointSelectForm: any;
  isRemoveMarkerOfPickOnMapScreen:boolean;
  communityCenterpoint:any;
}

const initialStateOfGeography: I_InitialStateOfGeography = {
  activeSidebarScreen: screens.ALL_LAYERS,
  selectedLayersItems: [],
  allLayers: [],
  doResetMap: true,
  doRemoveAllDrawShapes: false,
  doFetchAllLayersData: true,
  showLoaderLayer: true,
  layersDataType: "active",
  showSnapingControl: false,
  selectedRegionLayerForRegionSelectForm: null,
  selectedShapeLayerForShapeSelectForm: null,
  selectedPointLayerForPointSelectForm: null,
  isRemoveMarkerOfPickOnMapScreen:false,
  communityCenterpoint:null
};

export default initialStateOfGeography;
