import { useSelector } from "react-redux";
import { HeaderBarProps } from "../../types";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import TextInputField from "view/components/InputField";
import { notificationsRecipientsSearchAction } from "store/filters/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
// import FiltersList from "../FiltersList";
import { useEffect, useRef, useState } from "react";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import FiltersList from "Components/NotificationRecipients/FiltersList";
import AppliedFilters from "../../../AppliedFilters";

const HeaderBar = ({
  data,
  isOpen,
  setIsOpen,
  handleToggleDropdown,
  isCloseDiv
}: HeaderBarProps) => {
  const [showInput, setShowInput] = useState(false);
  // const [isCloseDiv, setIsCloseDiv] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { notificationsRecipientsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        notificationsRecipientsFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };
    if (showInput) {
      const inputField = document.querySelector(
        ".getInput"
      ) as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [notificationsRecipientsFilter.searchString, showInput]);
  useEffect(() => {
    return () => {
      dispatch(notificationsRecipientsSearchAction(""));
    };
  }, []);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };
  return (
    <>
      <div className="flex justify-between gap-3">
        <div className="flex items-center relative px-4 pb-2">
          <p className="text-sm text-center text-secondaryMid dark:text-caption ">
            {data.totalItems} Items
          </p>
        </div>
        <div className="px-4">
          <FiltersList isCloseDiv={isCloseDiv} />
        </div>
        <div className="flex items-center justify-end flex-grow h-8 gap-2">
          <div className="flex border-2 relative border-t-0 border-b border-l-0 border-r-0 border-lineMid py-[12px]">
            <div
              className={`${
                showInput ? "block" : "hidden"
              } ml-7 border-2 border-t-0 border-l-0 border-r-0 border-b-primaryMid  pb-2`}
              ref={inputRef}
            >
              <TextInputField
                className="bg-bgBluish w-[250px] getInput mt-1 dark:bg-[transparent] dark:text-inputText"
                placeholder="Search"
                type="search"
                fieldAs="searchData"
                value={notificationsRecipientsFilter.searchString}
                onChange={(e: any) => {
                  dispatch(notificationsRecipientsSearchAction(e.target.value));
                }}
              />
            </div>
            <div onClick={toggleInputVisibility} className="cursor-pointer">
              <SearchIcon
                className={` ${showInput ? "mt-[6px]" : "mt-[6px]"}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
      <AppliedFilters />
      </div>
    </>
  );
};

export default HeaderBar;
