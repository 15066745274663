import { apiClient } from "../config";
async function changeUserStatus(userId: any, notifyUser: any, staus: string) {
  const res = await apiClient.put(`/admin/users/change-status`, {
    notifyUser: notifyUser,
    status: staus,
    userId: userId,
  });
  return res.data;
}

export default changeUserStatus;
