import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { observationsFormAction } from "store/filters/reducer.actions";

export const SelectedForms: React.FC = () => {
    const dispatch = useDispatch();
    const { observationsFilter } = useSelector<RootState,filtersInitialState>((state) => state.Filters);
  
    const { formId } = useParams<{
      formId: string;
    }>();
  
    const resetFilter = (form: any) => {
      dispatch(observationsFormAction(form));
    };
  
    if (observationsFilter.formIds?.applied) {
      return (
        <div className="flex flex-wrap items-center justify-start gap-2 ">
          {observationsFilter.formIds.value.map((item: any, index: number) => (
             <div
        key={index}
        className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="break-all w-full flex-shrink-0 text-sm text-start text-textMid dark:text-inputText capitalize">
            {item.name}
          </p>
        </div>
              {!formId && (
                <ClearFilters handleClick={() => resetFilter(item)} />
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };