import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";

export const RecentActiveMember = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );

  return (
    <div className="w-full px-10">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        <div className="flex justify-start items-center flex-grow gap-2 mt-9 py-[18px] border-t border-r-0 border-b-0 border-l-0 border-lineMid">
          <div className="flex flex-col justify-start items-start flex-grow relative pt-1.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 ml-10 text-base font-semibold text-left text-secondaryMid dark:text-textMain">
              Recent Active Members
            </p>
          </div>
          <div className="flex items-center justify-end flex-grow-0 flex-shrink-0 gap-8">
            <div className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
              {communityProfile.recentActiveMembers?.map(
                (items: any, index: any) => (
                  <div
                    key={index}
                    className="relative flex-grow-0 flex-shrink-0 rounded-full w-9 h-9"
                  >

                    {items.profileImage && items.profileImage.thumb ? (
                      <div>
                        <img
                          className="w-9 h-9 rounded-full"
                          src={items?.profileImage.thumb}
                          alt=""
                        />
                      </div>
                    ) : (
                      <ListImagePlaceholder className="w-9 h-9 rounded-full" />
                    )}
                  </div>
                )
              )}
              {communityProfile.recentActiveMembers?.length < 1 && <p className="text-secondaryMidLight dark:text-textMain">No Active Members</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
