import { extractDrawId } from "../utils";

function __deleteDrawShape__(draw: any, drawId?: string) {
    const shapes = draw.getAll();
  
    if (shapes.features) {
      const filteredFeatures = shapes.features.filter(
        (f: any) => extractDrawId(f.id) != drawId
      );
      shapes.features = filteredFeatures;
  
      draw.set(shapes);
    }
  }

  export {__deleteDrawShape__};