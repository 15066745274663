import { apiClient } from "../config";
interface RequestParams {
  searchString: string | undefined;
  page: number;
  connected: boolean;
}

async function getOrganizationsByCommunity(
  id: number,
  connected: boolean = false,
  page: number,
  searchString?: string
) {
  const params: any = {
    connected,
  };

  if (page && page > 0) {
    params.page = page;
  }
  if (searchString !== "") {
    params.searchString = searchString;
  }

  // const res = await apiClient.get(`/admin/communities/${id}/organizations`, {
  //   params,
  // });
  const res = await apiClient.get(`/admin/communities/${id}/organizations`, {
    params,
  });
  return res.data;
}

export default getOrganizationsByCommunity;
