import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import { useDispatch } from "react-redux";
import { userFormsObservationsAction } from "store/filters";
import formatValue from "utils/formatValues";

interface FormsObservationsProps {
  name: string;
  min: number;
  max: number;
  isApplied: boolean;
}

export const FormsObservations: React.FC<
  FormsObservationsProps
> = ({ name, min, max, isApplied }) => {
  const dispatch = useDispatch();

  const resetFilter = () => {
    dispatch(userFormsObservationsAction(name, 0, 1000, false));
  };

  if (isApplied) {
    const appliedFilters = (
      <div className= "flex justify-between px-2 items-center gap-1 flex-grow-0 flex-shrink-0 relative rounded-lg bg-primary/[0.08]">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">
            {sentenceCase(name)}: {formatValue(min)} - {formatValue(max)}
          </p>
        </div>
        <ClearFilters handleClick={resetFilter} />
      </div>
    );

    return appliedFilters;
  } else {
    return null;
  }
};
