import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddEditDuplicateRoleModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRolesPermissionsAction } from "store/rolesPermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

interface AddEditDuplicateModalProps {}

const currentValidationSchema = Yup.object().shape({
  title: Yup.string()
    .max(60, "Role name at the most 60 characters.")
    .required("Role Name is required"),
  description: Yup.string()
    .max(6000, "Description at the most 6000 characters.")
    .required("Description is required"),
});
export const AddEditDuplicateRoleModal: React.FC<
  AddEditDuplicateModalProps
> = ({}) => {
  const { addEditDuplicateRoleModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const modalInitialState = {
    title: addEditDuplicateRoleModal?.data?.roleName || "",
    description: addEditDuplicateRoleModal?.data?.description || "",
  };

  const roleType = addEditDuplicateRoleModal.data.roleType;

  const handleClose = () => {
    dispatch(closeAddEditDuplicateRoleModalAction());
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const { title, description } = values;

      if (roleType === "duplicate") {
        const data = {
          role: addEditDuplicateRoleModal?.data?.id,
          duplicateName: title,
          description: description,
        };

        const res = await apiLibrary.RolesPermissions.createDuplicateRole(data);
        Toasts.success(res.message);
      } else {
        const res = await apiLibrary.RolesPermissions.editRole(
          addEditDuplicateRoleModal?.data?.id,
          {
            title,
            description,
          }
        );
        Toasts.success(res.message);
      }

      dispatch(fetchRolesPermissionsAction());
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={addEditDuplicateRoleModal.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={modalInitialState}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="w-[100%]  rounded">
                <div className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                  <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                        {roleType === "duplicate"
                          ? "Duplicate Role"
                          : "Edit Role"}
                      </p>
                    </div>
                    <button onClick={handleClose} type="button">
                      <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                    </button>
                  </div>

                  <div className="overflow-y-auto px-1 max-h-[60vh]">
                    {roleType === "duplicate" ? (
                      <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                        All permissions for the new role are identical to those
                        of the source role. You can make changes to them at a
                        later time.
                      </p>
                    ) : (
                      <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                        After creating/editing a role, use the "Edit
                        permissions" option in the table to assign permissions.
                      </p>
                    )}

                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                      <div className="w-full mb-4">
                        <TextInput
                          label="Role Name*"
                          type="text"
                          placeholder="Program Admin"
                          name="title"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.title}
                          error={errors?.title}
                          touched={touched.title}
                        />
                        <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                          60 Max Characters
                        </p>
                      </div>

                      <div className="w-full mb-4">
                        <TextInput
                          label="Description*"
                          type="text"
                          rows={3}
                          fieldAs="textarea"
                          placeholder="Description"
                          name="description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          error={errors?.description}
                          touched={touched.description}
                        />
                        <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                          6000 Max Characters
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end gap-2 pb-4 pr-4">
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="w-24 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    text="Save"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="w-24 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
