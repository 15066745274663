import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  closeInviteExternalReviewerQualityAssuranceObservationModalAction,
  openSuccessMessageModalAction,
} from "store/modals/reducer.actions";
import { Toasts } from "view/components/Toasts";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import InputField from "view/components/InputField";
import Button from "view/components/Button";
import {
  AddReviewerName,
  Header,
  InviteButton,
  InviteReviewer,
} from "./components";
import apiLibrary from "services/api";
import { formatTheDates } from "utils/formatTheDates";
import usePermissions from "hooks/usePermissions";
import { fetchObservationProfileAction } from "store/observationProfile/reducer.actions";
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import moment from "moment";
import { fetchObservationsActivitiesAction } from "store/observationsActivities";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // width: "35%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#ffff",
};

interface InviteExternalReviewerQualityAssuranceModalProps { }

const registrationValidationSchema = Yup.object().shape({
  reviewerId: Yup.object().shape({
    value: Yup.string().required("Reviewer name is required"),
    label: Yup.ref("value"),
  }),
  accessUntil: Yup.date().required("Date is required"),
});

const invitationValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  accessUntil: Yup.date().required("Date is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

const initialValues = {
  reviewerId: "",
  accessUntil: "",
  email: "",
  firstName: "",
  lastName: "",
};

export const InviteExternalReviewerQualityAssuranceModal: React.FC<
  InviteExternalReviewerQualityAssuranceModalProps
> = () => {
  const { inviteExternalReviewerQualityAssuranceObservationModal } =
    useSelector((state: RootState) => state.modals);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [reviewersList, setReviewersList] = useState([]);
  const [inviteExternalReviewer, setInviteExternalReviewer] = useState(false);
  const formikRef = useRef<any>();
  const { qa, observations } = usePermissions();
  const { observationId } = useParams();

  const fetchAllReviewers = useCallback(
    async (searchQuery?: string) => {
      const id = observationId || inviteExternalReviewerQualityAssuranceObservationModal.data?.id;
      try {
        const res =
          await apiLibrary.QualityAssurance.getExternalReviewers(searchQuery, id);
        setReviewersList(
          res?.data.externalReviewers?.map((reviewer: any) => ({
            label: `${reviewer?.firstName}  ${reviewer?.lastName}`,
            value: reviewer?.id,
          }))
        );
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching reviewers:", error);
      }
    },
    [inviteExternalReviewerQualityAssuranceObservationModal]
  );

  useEffect(() => {
    if (
      inviteExternalReviewerQualityAssuranceObservationModal.isOpen &&
      qa.canViewExternalReviewer
    ) {
      fetchAllReviewers();
    }
  }, []);

  const handleAddReviewerSearch = (query: string) => {
    if (qa.canViewExternalReviewer) {
      fetchAllReviewers(query);
    }
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const formValues = {
        ...values,
        accessUntil: formatTheDates(values.accessUntil, { utc: true }),
        reviewerId: values.reviewerId?.value,
      };

      if (!inviteExternalReviewer) {
        const res = await apiLibrary.QualityAssurance.inviteExternalReviewer(
          inviteExternalReviewerQualityAssuranceObservationModal?.data?.id,
          formValues
        );
        Toasts.success(res.message);
        handleClose();
        dispatch(openSuccessMessageModalAction());
        if (observationId) {
          dispatch(fetchObservationProfileAction(observationId));
          dispatch(fetchObservationsActivitiesAction(observationId));
        }
      } else {
        const res = await apiLibrary.QualityAssurance.addExternalReviewer(
          inviteExternalReviewerQualityAssuranceObservationModal?.data?.id,
          formValues
        );
        Toasts.success(res.message);
        handleClose();
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleInviteExternalReviewer = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm(); // Resetting form directly if current exists
    }
    setInviteExternalReviewer((prevState) => !prevState);
  };

  const handleClose = () => {
    dispatch(
      closeInviteExternalReviewerQualityAssuranceObservationModalAction()
    );
  };

  return (
    <Modal
      open={inviteExternalReviewerQualityAssuranceObservationModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={
            inviteExternalReviewer
              ? invitationValidationSchema
              : registrationValidationSchema
          }
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                <div
                  className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <Header
                    handleClose={handleClose}
                    handleTitleChange={inviteExternalReviewer}
                  />
                  {observations.canCreateExternalReviewer && (
                    <InviteButton
                      handleClick={handleInviteExternalReviewer}
                      handleTitleChange={inviteExternalReviewer}
                    />
                  )}

                  <div className="flex flex-col w-full px-1 pb-4">
                    <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-4 mb-6">
                      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-2">
                        {!inviteExternalReviewer ? (
                          <div className="flex items-start items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
                            <div className="flex w-[296px] flex-col justify-start items-start flex-grow ">
                              <AddReviewerName
                                handleAddReviewerNameOnChange={(option: any) =>
                                  setFieldValue("reviewerId", option)
                                }
                                errors={errors}
                                touched={touched}
                                reviewers={reviewersList}
                                values={values}
                                handleAddReviewerSearch={
                                  handleAddReviewerSearch
                                }
                              />
                            </div>

                            <div className="flex w-[296px] flex-col justify-start items-start flex-grow mt-2">
                              <InputField
                                label="Access Until*"
                                type="date"
                                name="accessUntil"
                                onChange={(values: any) =>
                                  setFieldValue("accessUntil", values)
                                }
                                onBlur={handleBlur}
                                className={"w-full"}
                                value={values.accessUntil}
                                error={errors?.accessUntil}
                                touched={touched.accessUntil}
                                min={moment()}
                              />
                            </div>
                          </div>
                        ) : (
                          <InviteReviewer
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-auto">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        variant="outlined"
                        onClick={handleClose}
                      />
                      <Button
                        type="submit"
                        disabled={
                          isSubmitting ||
                            (inviteExternalReviewer &&
                              !observations.canCreateExternalReviewer)
                            ? true
                            : !inviteExternalReviewer &&
                              !observations.canEditInviteExternalReviewer
                              ? true
                              : false
                        }
                        text="Invite"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
