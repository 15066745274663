import Plus2Icon from "assets/icons/HeroIcons/Plus2Icon";
import { CreateBtnProps } from "../../types";

const CreateBtn = ({ handleClickOnCreateNewBtn }: CreateBtnProps) => {
  return (
    <button
      onClick={handleClickOnCreateNewBtn}
      className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-1 rounded-lg hover:bg-primary/[0.2]"
    >
      <Plus2Icon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
      <div className="flex items-center justify-start flex-grow">
        <div className="flex justify-start items-center flex-grow relative py-0.5">
          <p className="flex-grow w-[172px] text-[15px] text-left text-textMid dark:text-caption">
            Create New
          </p>
        </div>
      </div>
    </button>
  );
};

export { CreateBtn };
