import { SVGProps } from "react"
export const CheckBoxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect width={18} height={18} x={3} y={3} fill="#005C89" rx={2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.707 7.293a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L10 13.586l6.293-6.293a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default CheckBoxChecked
