import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { DateFilter } from "./Components/Date";
import { Communities, Programs, Range, Tags } from "./Components";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import AppliedFilters from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

interface IFiltersListing {
  label: string;
  name: string;
  dropdownComponent?: React.ReactNode;
  isVisible: boolean;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const { organizationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { tags } = usePermissions();

  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [rightOffsetWidth, setRightOffsetWidth] = useState(false);
  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  // const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const handleMouseEnter = (dropdownName: string, event: any) => {
    if (
      showDropdown === dropdownName &&
      !isDatePickerElementAndButtonLabel(event.target, dropdownName)
    ) {
      setShowDropdown(null);
    } else {
      setShowDropdown(dropdownName);
    }
  };

  function isDatePickerElementAndButtonLabel(
    target: any,
    dropdownName?: string | null
  ) {
    if (!dropdownName) {
      return false;
    }

    const selector = `#${dropdownName}id, .MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root`; // Correct the selector string by removing the trailing comma
    return target?.closest(`${selector}`);
  }

  const List: IFiltersListing[] = [
    {
      label: "Tags",
      name: "tags",
      isVisible: tags.canViewDropdownTag,
      dropdownComponent: <Tags />,
    },
    {
      label: "Programs",
      name: "programs",
      isVisible: !programId,
      dropdownComponent: <Programs name="programs" />,
    },
    {
      label: "Communities",
      name: "communities",
      isVisible: !communityId,
      dropdownComponent: <Communities name="communities" />,
    },

    {
      label: "Members",
      name: "members",
      isVisible: true,
      dropdownComponent: (
        <Range
          title="Members"
          name="members"
          data={organizationsFilter.members}
        />
      ),
    },

    {
      label: "Date Added",
      name: "date_added",
      isVisible: true,
      dropdownComponent: (
        <DateFilter
          name="dateAdded"
          from={organizationsFilter?.dateAdded?.from}
          to={organizationsFilter?.dateAdded?.to}
        />
      ),
    },
  ];
  useEffect(() => {
    if (showDropdown) {
      const currentDropdownMenu = document.querySelector(
        `#${showDropdown}id .menu`
      );
      const btn = document.querySelector(`[data-dropdown="${showDropdown}"]`);
      if (currentDropdownMenu && btn) {
        const dropdownRect = currentDropdownMenu.getBoundingClientRect();
        const btnwidth = btn.getBoundingClientRect();

        const btnwidthoffset = window.innerWidth - btnwidth.right;
        const setDicDirection = dropdownRect.width - btnwidth.width;

        const getRightOffset = btnwidthoffset - setDicDirection;
        setRightOffsetWidth(getRightOffset < 0);
      }
    }
  }, [showDropdown]);

  function handleClickOutside(event: any) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isDatePickerElement(event.target)
    ) {
      setShowDropdown(null);
    }
  }
  function isDatePickerElement(target: any) {
    return target.closest(
      `.MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root, .btn`
    ); // Added MuiIconButton-root
  }

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <div
      className={`flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 ${
        isCloseDiv ? "h-min-[40px]" : "h-[0px] opacity-0"
      } transition-h duration-300 ease-in-out`}
    >
      <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        {List.filter((item) => item.isVisible).map((item, index) => (
          <div
            key={index}
            data-dropdown={item.name}
            onClick={(event) => handleMouseEnter(item.name, event)}
            id={item.name + "index"}
            className="btn flex justify-center items-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg border cursor-pointer hover:border-primaryMid hover:bg-primary/[0.08]  border-lineMid"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
              <p
                className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-secondaryMid 
 dark:text-primaryOutlined "
              >
                {item.label}
              </p>
            </div>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              style={{
                transform: `rotate(${
                  showDropdown === item.name ? "180deg" : "0"
                })`,
                transition: "transform 0.3s ease",
              }}
            />
            {showDropdown === item.name && (
              <div
                id={item.name + "id"}
                className={`absolute ${
                  !rightOffsetWidth ? "left-0" : "right-0"
                }  z-30 top-full ring-offset-8`}
              >
                <div
                  className={`${
                    item.dropdownComponent && showDropdown === item.name
                      ? "visible"
                      : "invisible"
                  } menu `}
                  ref={dropdownRef}
                >
                  {item.dropdownComponent}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <AppliedFilters />
    </div>
  );
};

export default FiltersList;
