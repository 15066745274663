import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
}

const PolygonIcon = ({ fill = "#005C89", ...restProps }: IconProps) => (
    <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 8.61803C0.5 8.42865 0.607001 8.25552 0.776393 8.17082L15.5789 0.769546C15.7714 0.673299 16.0039 0.711027 16.1561 0.863207L31.3536 16.0607C31.4473 16.1544 31.5 16.2816 31.5 16.4142V23C31.5 23.2761 31.2761 23.5 31 23.5H1C0.723858 23.5 0.5 23.2761 0.5 23V8.61803Z"
            fill="#005C89"
            stroke="#2C3236"
            fill-opacity="0.08"
            stroke-linejoin="round"
        />
    </svg>

);
export default PolygonIcon;
