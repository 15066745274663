import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    (columnDef.id === "categoryName" || columnDef.id === "typeName")
  ) {
    return (
      <div className="flex justify-start w-[50%] items-center relative">
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3  w-[50%] ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
