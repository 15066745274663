import { Dispatch } from "redux";
import {
  ERROR_LOADING_MARKER_STYLES,
  GET_ALL_MARKER_STYLES,
  RESET_DATA,
  START_LOADING_MARKER_STYLES,
  STOP_LOADING_MARKER_STYLES,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchMarkerStylesAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_MARKER_STYLES });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.markerStylesFilter
      );

      const { data } =
        await apiLibrary.markerGroupsAndStyles.getAllMarkerStyles(queryString);
      dispatch({
        type: GET_ALL_MARKER_STYLES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching marker styles:", error);
      dispatch({ type: ERROR_LOADING_MARKER_STYLES });
    } finally {
      dispatch({ type: STOP_LOADING_MARKER_STYLES });
    }
  };
