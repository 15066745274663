import { apiClient } from "../config";

async function getImportSummary(id: string, queryParams?: any) {
  const res = await apiClient.get(`/admin/imports/${id}/summary`, {
    params: queryParams,
  });
  return res.data;
}

export default getImportSummary;
