import { motion } from "framer-motion";

import React from "react";
import { SecondaryEmails } from "./SecondaryEmails";
import { SecondaryPhones } from "./SecondaryPhones";

export const SecondaryContactDetails = ({
  editMode,
}: {
  editMode: boolean;
}) => {
  return (
    <motion.div
      animate={{
        translateY: editMode ? 100 : 0,
      }}
      className="flex justify-between w-full"
    >
      <SecondaryEmails />
      <SecondaryPhones />
    </motion.div>
  );
};
