import separateColorAndOpacity from "utils/separateColorAndOpacity";

function __paintShapeByGeoJsonOnMap__(
  map: any,
  sourceId: string,
  layerId: string,
  geoJson: any,
  defaultColor: any,
  defaultBorderColor: any,
) {
  if (!map || !sourceId || !layerId || !geoJson) {
    return;
  }

  // adding geojson source
  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  const isGeoJsonHasLineString = geoJson && geoJson?.features?.find(
    (f: any) =>
      f.geometry.type === "LineString" || f.geometry.type === "MultiLineString"
  );

  const {color,opacity} = separateColorAndOpacity(defaultColor);
  const {color: borderColor,opacity: borderOpacity} = separateColorAndOpacity(defaultBorderColor);
 

  // // design and register the added geojson source layer
  map.addLayer({
    id: layerId,
    type: isGeoJsonHasLineString ? "line" : "fill",
    source: sourceId,
    layout: {},
    paint: {
      [isGeoJsonHasLineString ? "line-color" : "fill-color"]: color,
      [isGeoJsonHasLineString ? "line-opacity" : "fill-opacity"]: opacity,
    },
  });

  if (!isGeoJsonHasLineString) {
    map.addLayer({
      id: `${layerId}-border`,
      type: "line",
      source: sourceId,
      layout: {},
      paint: {
        "line-color": borderColor || "#000000", 
        "line-width": 1, 
        "line-opacity": borderOpacity,
      },
    });
  }

}

export { __paintShapeByGeoJsonOnMap__ };
