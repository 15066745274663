function getCenterCoordinatesForPointLayers(layer: any) {
  const geojsonData = layer ? layer.item?.mapData : null;

  if (!geojsonData) {
    return;
  }

  if (geojsonData.type === "FeatureCollection") {
    // Calculate centroid for FeatureCollection
    const allCoordinates = geojsonData.features
      .filter(
        (feature: any) => feature.geometry && feature.geometry.type === "Point"
      )
      .map((feature: any) => feature.geometry.coordinates);

    if (allCoordinates.length === 0) return;

    const totalCoordinates = allCoordinates.reduce(
      (accumulator: any, currentVal: any) => {
        return [accumulator[0] + currentVal[0], accumulator[1] + currentVal[1]];
      },
      [0, 0]
    );

    return [
      totalCoordinates[0] / allCoordinates.length,
      totalCoordinates[1] / allCoordinates.length,
    ];
  } else if (
    geojsonData.type === "Feature" &&
    geojsonData.geometry.type === "Point"
  ) {
    // Return coordinates for single Feature
    return geojsonData.geometry.coordinates;
  }

  return;
}

export { getCenterCoordinatesForPointLayers };
