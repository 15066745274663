import React from "react";
import { NotificationFormStepper } from "Components/AddEditNotification";
import { Header } from "Components/AddEditNotification/Header";

export const AddEditNotification = () => {
  return (
    <div className="flex flex-col items-center justify-start w-full ">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
      </div>
      <div className="flex justify-center w-full mb-10">
        <NotificationFormStepper />
      </div>
    </div>
  );
};
