// themeReducer.ts

import { SET_THEME } from "./action-types";
import { initialThemeState, ThemeState } from "./initialState";

// Define the reducer
const themeReducer = (
    state: ThemeState = initialThemeState,
    action: { type: string; payload: typeof initialThemeState.colors }
) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                colors: action.payload,  // Update the colors in the theme state
            };
        default:
            return state;
    }
};

export default themeReducer;
