import React, { useEffect, useState } from "react";
// Components
import { Toasts } from "view/components/Toasts";
import { ConfigurationBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import DashboardConfigurationModal from "../Configuration";
import { Breadcrumb } from "Components/Users";
import WelcomeAlert from "../WelcomeAlert";
import usePermissions from "hooks/usePermissions";
import { openConfigurationModalAction } from "store/modals";

export const Header = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();

  /**
   * TOGGLE CONFIGURATION MODAL
   */

  const toggleConfigurationModal = React.useCallback(() => {
    setIsOpen((oldState) => !oldState);
    dispatch(openConfigurationModalAction());
  }, []);

  const generateDashboardLinks = () => {
    switch (true) {
      default:
        return [{ path: `/`, label: `Home` }];
    }
  };
  const dashboardLinks = generateDashboardLinks();
  const { dashboard } = usePermissions();
  return (
    <>
      <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex items-start self-stretch justify-start flex-grow">
          <div className="flex flex-col items-start justify-start flex-grow gap-2">
            <Breadcrumb links={dashboardLinks} />

            <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <PageHeading name="Home" />
              {dashboard.canViewDashboardConfiguration && (
                <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                  <ConfigurationBtn handleClick={toggleConfigurationModal} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <DashboardConfigurationModal
          isOpen={isOpen}
          handleClose={toggleConfigurationModal}
        />
      )}

      <WelcomeAlert toggleConfigurationModal={toggleConfigurationModal} />
    </>
  );
};
