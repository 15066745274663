import { useEffect, useState } from "react";
// Components
import {
  CurrentPageInputField,
  GoToBackBtn,
  GoToNextBtn,
  ItemsPerPageSelector,
  MoveToFirstPageBtn,
  MoveToLastPageBtn,
} from "./components";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  notificationsLimitAction,
  notificationsPaginationAction,
  rolesPermissionsLimitAction,
  rolesPermissionsPaginationAction,
} from "store/filters/reducer.actions";
// types
import { PaginationProps, handleChangeOnCurrentPageInputField } from "./types";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchNotifications } from "store/notifications/initialState";

export const Pagination: React.FC<PaginationProps> = ({}) => {
  const [pageInput, setPageInput] = useState("");
  const { rolesPermissionsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const { data } = useSelector<RootState, IfetchNotifications>(
    (state) => state?.allRolesPermissions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setPageInput(rolesPermissionsFilter.page.toString());
  }, [rolesPermissionsFilter.page]);

  // Handlers
  const handleChangeItemsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    dispatch(rolesPermissionsLimitAction(parseInt(value)));
  };
  const handleClickOnMoveToFirstPageBtn = () => {
    dispatch(rolesPermissionsPaginationAction(1));
  };
  const handleClickOnGoToBackBtn = () => {
    dispatch(
      rolesPermissionsPaginationAction(
        rolesPermissionsFilter.page > 1 ? rolesPermissionsFilter.page - 1 : 1
      )
    );
  };
  const handleClickOnGoToNextBtn = () => {
    dispatch(
      rolesPermissionsPaginationAction(
        rolesPermissionsFilter.page < data.totalPages
          ? rolesPermissionsFilter.page + 1
          : data.totalPages
      )
    );
  };
  const handleClickOnMoveToLastPageBtn = () => {
    dispatch(rolesPermissionsPaginationAction(data.totalPages));
  };
  const handleChangeOnCurrentPageInputField: handleChangeOnCurrentPageInputField =
    (e) => {
      const value = e.target.value;
      setPageInput((prevValue: any) => {
        if (value === "" || !isNaN(parseInt(value))) {
          if (parseInt(value) <= data.totalPages && parseInt(value) > 0) {
            if (!isNaN(parseInt(value))) {
              dispatch(rolesPermissionsPaginationAction(parseInt(value)));
            } else {
              dispatch(rolesPermissionsPaginationAction(1));
            }

            return value;
          }
        }
        return "";
      });
    };
  const handleBlurOnCurrentPageInputField = () => {
    setPageInput(rolesPermissionsFilter.page.toString());
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 px-6 pt-8 pb-10 bg-white rounded-bl-lg rounded-br-lg">
      <div className="flex items-center justify-start flex-grow">
        <ItemsPerPageSelector
          handleChangeItemsPerPage={handleChangeItemsPerPage}
          limit={rolesPermissionsFilter.limit}
        />
        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-2">
          <MoveToFirstPageBtn
            page={rolesPermissionsFilter.page}
            handleClickOnBtn={handleClickOnMoveToFirstPageBtn}
          />
          <CurrentPageInputField
            pageInput={pageInput}
            handleChangeOnInput={handleChangeOnCurrentPageInputField}
            handleBlurOnInput={handleBlurOnCurrentPageInputField}
          />
          <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-3 py-1 rounded-lg">
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-0.5">
              <p className="flex-grow-0 flex-shrink-0 text-[15px] text-center text-textMid dark:text-caption ">
                of {data.totalPages}
              </p>
            </div>
          </div>
          <GoToBackBtn
            page={rolesPermissionsFilter.page}
            handleClickOnBtn={handleClickOnGoToBackBtn}
          />
          <GoToNextBtn
            page={rolesPermissionsFilter.page}
            totalPages={data.totalPages}
            handleClickOnBtn={handleClickOnGoToNextBtn}
          />

          <MoveToLastPageBtn
            page={rolesPermissionsFilter.page}
            totalPages={data.totalPages}
            handleClickOnBtn={handleClickOnMoveToLastPageBtn}
          />
        </div>
      </div>
    </div>
  );
};
