import { apiClient } from "../config";

async function getObservationsByRegion(queryParams: any) {
  const res = await apiClient.get(
    `/admin/dashboard/widget/observations-by-region`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getObservationsByRegion;
