import { apiClient } from "../config";

async function getManagersDropdown(
  searchString: string,
  programId: string,
  page?: number
) {
  const params = {
    page,
    searchString,
  };

  const response = await apiClient.get(
    `/admin/programs/${programId}/managers-dropdown`,
    {
      params,
    }
  );

  return response.data;
}

export default getManagersDropdown;
