import { useEffect } from "react";

import { __changeColorOfZoomedShapeLayer__ } from "../../../mapWorkerMehods";

import mapboxgl from "mapbox-gl";
import pointIconRed from "assets/images/location-marker-red.png";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useCustomizeColorOfHigilightedPoint = (
  map: any,
  isMapFullyLoaded: any,
  currentQuestionEle: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string,
  markers: any,
  setMarkers: any,
  setClickedPoint: any
) => {
  useEffect(() => {
    if (currentQuestionEle && markers && map && isMapFullyLoaded) {
      const newMarkers = markers.map((markerData: any) => {
        const isMarkerSelected =
          currentQuestionEle.selectedPointsIdToHighlight?.find(
            (marker: any) => marker.id == markerData.id
          );

        const markerColor = isMarkerSelected
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        const coordinates = markerData.marker.getLngLat();

        // Remove the old marker
        markerData.marker.remove();

        
        const markerElement = document.createElement("div");
        markerElement.className = "custom-marker-point-picker";



      markerElement.innerHTML = `
            <div class="marker-content-point-picker" style="
       width: 50px; 
       height: 50px; 
       border-radius: 50%; 
       border: 3px solid ${markerColor}; 
      
       overflow: hidden;
        ">
             <img src="${markerData?.data?.markerStyle?.styleImage??pointIconRed}" alt="Marker Image" class="marker-image" style="width: 100%; height: 100%; object-fit: cover;" />
          </div>
           `;
      
        const newMarker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map);

        newMarker
          .getElement()
          .addEventListener("click", () => setClickedPoint(markerData.data));

        markerData.marker = newMarker;
        return markerData;
      });

      setMarkers(newMarkers);
    }
  }, [currentQuestionEle?.selectedPointsIdToHighlight]);

  return null;
};

export { useCustomizeColorOfHigilightedPoint };
