import { useDispatch } from "react-redux";
import {
  displayRasterLayerOnMap,
  doFetchAllLayers,
  openAddRasterLayerScreen,
  openEditRasterLayerScreen,
  openViewRasterLayerScreen,
  updateSelectedItemToZoom,
  updateSelectedLayersItems,
} from "store/geography";
import { LayerBox } from "../index";
import { CurrentOpenToggleType, T_RasterLayersData } from "../LayersListing";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";

interface I_RasterLayerBox {
  data: T_RasterLayersData[] | undefined;
  actionsList: {
    label: string;
  }[];
  global?: boolean;
  setEntityType?: Dispatch<SetStateAction<string | null>>;
  setPage?: Dispatch<SetStateAction<any>>;
  entityType?: null | string;
  page?: any;
  totalPages?: number;
  totalItems?: number;
  loading: boolean;
  currentOpenToggle: CurrentOpenToggleType;
  // setCurrentOpenToggle: Dispatch<SetStateAction<CurrentOpenToggleType>>;
}

const RasterLayerBox = ({
  data,
  actionsList,
  global,
  setEntityType,
  setPage,
  entityType,
  totalPages,
  page,
  totalItems,
  loading,
  currentOpenToggle,
  // setCurrentOpenToggle,
}: I_RasterLayerBox) => {
  const [isScrollEndEventTriggered,setIsScrollEndEventTriggered] = useState(false);
  const [doShowDataList,setDoShowDataList] = useState(false);
  const { selectedLayersItems, layersDataType } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const {communities} = usePermissions();

  const heading = `${(!totalItems || totalItems === 0 )? "" : totalItems} Raster Layers`;

  useEffect(()=>{
    const doShow =
    !global && currentOpenToggle === "raster"
      ? true
      : global && currentOpenToggle === "publicRaster"
        ? true
        : false;
    
    setDoShowDataList(doShow);
  },[global,currentOpenToggle])

  useEffect(()=>{
    if(isScrollEndEventTriggered){
      if (
        setPage &&
        setEntityType &&
        totalPages &&
        page 
        && totalPages >= page[global ? "publicRaster" : "raster"] + 1
      ) {
        const key = global ? "publicRaster" : "raster";
  
        setPage((prev: any) => ({
          ...prev,
          [key]: prev[key] + 1,
        }));
  
        if (entityType !== key) {
          setEntityType(key);
        }
      }

      setIsScrollEndEventTriggered(false);
    }
  },[isScrollEndEventTriggered])

  // handlers
  const updateLayerStatusAndFetch = (
    communityId: any,
    dataItemId: any,
    archive: any
  ) => {
    const updateFunction = communityId
      ? apiLibrary.geography.updateLayerStatus(communityId, dataItemId, archive)
      : apiLibrary.geography.updateGlobalLayerStatus(dataItemId, archive);

    return updateFunction.then(() => {
      dispatch(doFetchAllLayers(true));
      const layersItems = selectedLayersItems || [];
      const itemIndex = layersItems.findIndex(
        (itm: any) => itm.item.id === dataItemId
      );

      if (itemIndex !== -1) {
        layersItems[itemIndex].status = false;
        dispatch(updateSelectedLayersItems([...layersItems]));
      }
    });
  };
  const handleClickOnMenuAction = async (action: any, dataItem: any) => {
    const currentAction = action.label.toLowerCase();
    switch (currentAction) {
      case "edit":
        // open edit screen of raster layer
        dispatch(openEditRasterLayerScreen({dataItem,isGlobal:global}));
        break;

      case "view information":
        // open detail screen of raster layer
        dispatch(openViewRasterLayerScreen(dataItem));
        break;

      case "zoom to selection":
        // display raster layer on map
        // dispatch(
        //   updateSelectedItemToZoom({ item: dataItem, itemType: "raster" })
        // );
        // dispatch(displayRasterLayerOnMap(dataItem));
        break;

      case "archive":
        updateLayerStatusAndFetch(communityId, dataItem.id, true);
        break;
      case "restore":
        updateLayerStatusAndFetch(communityId, dataItem.id, false);

        break;
    }
  };
  const handleClickOnAddButton = () => {
    // open add screen of raster layer
    dispatch(openAddRasterLayerScreen());
  };
  const handleClickOnCheckBox = async (e: any, item: any) => {
    const isChecked = e.target.checked;
    const layersItems = selectedLayersItems ?? [];
    const itemIndex = layersItems.findIndex(
      (itm: any) => itm.item.id === item.id
    );
    const alreadyExistedItem = layersItems[itemIndex];
    let upadatedItems;

    if (isChecked) {
      if (alreadyExistedItem) {
        // update item status to true
        alreadyExistedItem.status = true;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
      } else {
        // add item
        if (communityId && !global) {
          await apiLibrary.geography
            .getSingleLayer(communityId, item.id)
            .then((data) => {
              const newItem = {
                item: data.data,
                itemType: "raster",
                status: true,
              };
              upadatedItems = [...layersItems, newItem];
            })
            .catch((error) => {
              Toasts.error(error.message);
            });
        }else{
          await apiLibrary.geography
          .getSingleGlobalLayer(item.id)
          .then((data) => {
            const newItem = {
              item: data.data,
              itemType: "raster",
              status: true,
            };
            upadatedItems = [...layersItems, newItem];
          })
          .catch((error) => {
            Toasts.error(error.message);
          });
        }
      }
    } else {
      // update item status to false on unchecking the checkbox
      alreadyExistedItem.status = false;

      layersItems[itemIndex] = alreadyExistedItem;
      upadatedItems = layersItems;
    }

    dispatch(updateSelectedLayersItems([...upadatedItems]));
  };
  const handleOnScrollEnd = () => {
    setIsScrollEndEventTriggered(true);
  };
  const handleClickOnTogglerBtn = () => {
    setDoShowDataList(!doShowDataList);
    // if (currentOpenToggle) {
    //   if (!global && currentOpenToggle !== "raster") {
    //     setCurrentOpenToggle("raster");
    //   } 
    //   else if (global && currentOpenToggle !== "publicRaster") {
    //     setCurrentOpenToggle("publicRaster");
    //   }
    // }
  };

  return (
    <LayerBox
      layerType="raster"
      heading={heading}
      dataList={global || communities.canViewGeographyCommunities ? data : null}
      actionList={actionsList}
      hideZoomFeature={true}
      hideAddBtn={global}
      loading={loading}
      isGlobal={global}
      doShowDataList={doShowDataList}
      handleClickOnMenuAction={handleClickOnMenuAction}
      handleClickOnAddButton={handleClickOnAddButton}
      handleClickOnCheckBox={handleClickOnCheckBox}
      handleClickOnTogglerBtn={handleClickOnTogglerBtn}
      handleOnScrollEnd={handleOnScrollEnd}
    />
  );
};

export { RasterLayerBox };
