import { apiClient } from "../config";

async function getActiveUsers(queryParams: any) {
  const res = await apiClient.get(`/admin/dashboard/widget/active-users`, {
    params: queryParams,
  });
  return res.data;
}

export default getActiveUsers;
