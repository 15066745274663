import * as actionTypes from "./action-types";

export const runReportDataAction = (versionId: any) => {
  return {
    type: actionTypes.REPORTS_VERIONS_ID_DATA,
    payload: versionId,
  };
};

export const runProcessedReports = (data: any) => {
  return {
    type: actionTypes.RUN_PROCESSED_REPORT,
    payload: data,
  };
};
export const resetRunReports = () => {
  return {
    type: actionTypes.RESET_RUN_REPORTS,
    // payload: data,
  };
};
