import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import { useInitializeMap, usePaintLayers } from "./hooks";
import { Box } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";

const ObservationsByRegionMap = () => {
  const mapContainer = useRef<null | HTMLDivElement>(null);

  const {
    dashboard: { observationsByRegion },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const regionLayers = useCallback(
    processRegions(observationsByRegion.data.regions),
    [observationsByRegion.data.regions]
  );

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // painting regions layers of selected community
  usePaintLayers(regionLayers, map, isMapFullyLoaded);

  return (
    <Box
      sx={{
        width: "100%",
        height: "312px",
        bgcolor: lightGreen[50],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
      }}
    >
      <div ref={mapContainer} className="w-full h-full"></div>
    </Box>
  );
};

export { ObservationsByRegionMap };

const processRegions = (data: any) => {
  if (data === null) return [];
  const processedData = data.map((region: any) => {
    const updatedRegion = { ...region };
    updatedRegion.itemType = "region";
    return updatedRegion;
  });
  return processedData;
};
