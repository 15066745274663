import { apiClient } from '../config';

async function getAllOrganizations(searchQuery?: string, appName?: string) {
  const params = {
    searchString: searchQuery,
    appName: appName,
  };
  const res = await apiClient.get(`admin/programs/organizations-dropdown`, {
    params,
  });
  return res.data;
}

export default getAllOrganizations;
