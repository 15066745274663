import React from "react";
import ellipsize from "ellipsize";
import { RenderCellContentProps } from "../../types";

const isArray = Array.isArray;

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (cellValue && typeof cellValue === "object") {
    if (columnDef.id === "conditionName" && cellValue.conditionName) {
      return (
        <p className="w-full text-sm text-left capitalize break-words text-textMid">
          {ellipsize(`${cellValue.conditionName}`, 60)}
        </p>
      );
    } else if (columnDef.id === "types") {
      const types = isArray(cellValue)
        ? cellValue.map((item: any) => item.typeName).join(", ")
        : "";
      return (
        <p className="w-full text-sm text-left capitalize break-words text-textMid ">
          {ellipsize(types, 60)}
        </p>
      );
    }
  } else if (cellValue) {
    return (
      <p className="text-sm text-left text-textMid whitespace-nowrap ">
        {ellipsize(`${cellValue}`, 60)}
      </p>
    );
  }

  return null;
};
