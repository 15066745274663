import { Dispatch, useEffect, useState } from "react";
// components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// API services
import apiLibrary from "services/api";
// Store utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  displayRasterLayerOnMap,
  openAllLayersListingScreen,
} from "store/geography";
// Third party services
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
// Icons
import { ArrowLeftIcon, UploadIcon } from "assets/icons/HeroIcons";

import Dropzone from "react-dropzone";
import uploadIcon from "assets/icons/upload.svg";
import Head from "view/components/Head";

// Types
interface I_EditRasterLayer {
  setActiveScreen: Dispatch<string>;
}

const MAXIMUM_FILE_SIZE = 200; // kbs

// Schema
const editRasterLayerScheme = Yup.object().shape({
  name: Yup.string()
    .max(255, "255 max characters")
    .required("Layer name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(255, "255 max characters"),
  // legendFileId: Yup.string(),
  // type: Yup.string().required("Type is required"),
  // mapData: Yup.object().shape({
  //   snippet: Yup.string().required("Url is required"),
  //   // x: Yup.number(),
  //   // y: Yup.number(),
  //   // z: Yup.number(),
  // }),
});

const EditRasterLayer = () => {
  const dispatch = useDispatch();
  const { editRasterLayerData } = useSelector(
    (state: RootState) => state.geography
  );

  const {dataItem:data,isGlobal} = editRasterLayerData

  const { communityId } = useParams();
  const [MapData, setMapData] = useState<any>({});

  const initialValues = {
    name: data?.name,
    description: data?.description,
    type: "raster",
  };

  console.log({editRasterLayerData})

  useEffect(() => {
    setMapData({
      id: data?.id,
      type: data?.type,
    });
  }, [data]);

  // handlers
  const handleOnChangeFields = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const { name, value } = e.target;
    setFieldValue("mapData." + name, value);
    setMapData({
      ...MapData,
      mapData: {
        ...MapData.mapData,
        [name]: value,
      },
    });
  };
  const handleSubmitForm = async (values: any, { setSubmitting }: any) => {
    // values.mapData.x = parseInt(values.mapData.x);
    // values.mapData.y = parseInt(values.mapData.y);
    // values.mapData.z = parseInt(values.mapData.z);
    delete values.legendFilePath;

    try {
      // if (communityId && data?.id) {
      //   await apiLibrary.geography.editLayer(communityId, data.id, values);

      //   Toasts.success(`Raster layer updated successfully`);

      //   // navigate to all layers screen
      //   dispatch(openAllLayersListingScreen());
      // }

      if (communityId && !isGlobal) {
        await apiLibrary.geography.editLayer(communityId, data.id, values);
      } else {
        await apiLibrary.geography.editLayerForAdmin(data.id, values);
      }
      Toasts.success(`Raster layer updated successfully`);

      // navigate to all layers screen
      dispatch(openAllLayersListingScreen());
    } catch (err: any) {
      Toasts.error(err.message);
    } finally {
      setSubmitting(true);
    }
  };
  // const handleClickOnPreview = () => {
  //   if (!MapData?.mapData?.snippet) {
  //     return;
  //   }

  //   dispatch(displayRasterLayerOnMap({ ...MapData, type: "raster" }));
  // };
  // const handleFileUpload = async (
  //   files: any,
  //   setFieldValue: any,
  //   setFieldError: any
  // ) => {
  //   const file = files?.[0];

  //   if (file) {
  //     try {
  //       const fileSize = file.size / 1024; // convert bytes to kb
  //       if (fileSize > MAXIMUM_FILE_SIZE) {
  //         return setFieldError(
  //           "legendFileId",
  //           "File size must be less than or equal to 200kb!"
  //         );
  //       }

  //       const uploadResponse = await apiLibrary.file.fileUpload(
  //         file,
  //         false,
  //         "public"
  //       );

  //       setFieldValue("legendFileId", uploadResponse.data.id);
  //     } catch (error: any) {
  //       const errMsg = error?.response?.data?.message ?? error?.message;
  //       Toasts.error(errMsg);
  //     }
  //   }
  // };
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };

  return (
    <div>
      <Head title="Geography: Edit Raster Layer" />
      <Breadcrumb
        label="Edit Raster Layer"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={editRasterLayerScheme}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col items-start justify-start ">
              <TextInput
                label="Layer Name*"
                type="text"
                placeholder="Layer name"
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched?.name}
              />
            </div>
            <div className="w-full mb-4">
              <TextInput
                label="Description*"
                type="text"
                rows={3}
                fieldAs="textarea"
                placeholder="Description"
                name="description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
                error={errors?.description}
                touched={touched?.description}
              />
              {!errors?.description && (
                <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                  255 max characters
                </p>
              )}
            </div>
            {/* <div className="flex flex-col items-start justify-start ">
              <TextInput
                label="Snippet*"
                type="url"
                placeholder="URL"
                name="snippet"
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleOnChangeFields(e, setFieldValue)
                }
                handleBlur={handleBlur}
                value={values.mapData.snippet}
                error={errors?.mapData?.snippet}
                touched={touched.mapData?.snippet}
              />
            </div> */}
            {/* <div className="flex items-center gap-2">
             
              <div className="flex flex-col items-center justify-start w-1/4 ">
                <Button
                  type="button"
                  text="Preview"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClickOnPreview}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start mb-6">
              <FileUpload
                handleOnFileUpload={(files: any) =>
                  handleFileUpload(files, setFieldValue, setFieldError)
                }
                error={errors?.legendFileId}
                touched={touched?.legendFileId}
                editMode={true}
                fileUrl={values?.legendFilePath}
              />
            </div> */}
            <div className="flex justify-between gap-2">
              <Button
                type="reset"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.t%]"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={goBackToAllLayersScreen}
              />
              <Button
                type="submit"
                text="Save"
                disabled={isSubmitting}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { EditRasterLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};
const FileUpload = ({ handleOnFileUpload, error, touched, fileUrl }: any) => {
  return (
    <div className="w-full py-2">
      <label className="pb-1 text-sm text-textMid dark:text-caption">
        Legend
      </label>
      <Dropzone onDrop={handleOnFileUpload} multiple={false}>
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          let displayContent;

          if (acceptedFiles.length > 0) {
            displayContent = acceptedFiles[0].name;
          } else if (fileUrl) {
            displayContent = fileUrl;
          } else {
            displayContent = "Upload an image for the legend of the map";
          }

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${error && touched
                    ? "border-accent_1Dark"
                    : "border-lineDark dark:border-lineLight"
                  } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p className="text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain">
                  {displayContent}
                </p>
                {displayContent !== fileUrl && <UploadIcon />}
              </div>
            </div>
          );
        }}
      </Dropzone>
      {error ? (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      ) : (
        <p className="flex-grow pt-1 text-xs text-left text-textMidLight dark:text-textMain">
          Use an image that’s at least 200 x 200 pixels and 200KB or less.
        </p>
      )}
    </div>
  );
};