import { apiClient } from "../config";

async function updateLayerStatusForAdmins(
  geographyId: string,
  isArchived: boolean
) {
  const url = `/admin/geographies/${geographyId}/archive`;
  const res = await apiClient.put(url, { isArchived });
  return res.data;
}

export default updateLayerStatusForAdmins;
