import React, { useEffect, useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import {
  closeNotificationsSummaryModalAction,
  closeOrganizationsProfileDetailModalAction,
} from "store/modals/reducer.actions";
import { Link, useNavigate } from "react-router-dom";
import { fetchOrganizationProfileAction } from "store/organizationProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import { SummaryHeaders } from "./components/SummaryHeaders";
import { SummaryDescriptions } from "./components/SummaryDescriptions";
import { SummaryLink } from "./components/SummaryLink";
import { SummaryNotificationsStatus } from "./components/SummaryNotificationsStatus";
import { SummaryNotificationsChannel } from "./components/SummaryNotificationsChannel";
import { SummaryRecipients } from "./components/SummaryRecipients";
import {
  fetchNotificationSummaryProfileAction,
  resetNotificationSummaryAction,
} from "store/notificationSummary/reducer.actions";
import { ISummary } from "store/notificationSummary";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import Head from "view/components/Head";

export const NotificationsSummary = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { openNotificationsSummaryModal } = useSelector(
    (state: RootState) => state.modals
  );
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const notificationSummary = useSelector<RootState, ISummary>(
    (state) => state?.notificationSummary
  );
  const { reminders } = usePermissions();

  useLayoutEffect(() => {
    if (openNotificationsSummaryModal.data) {
      dispatch(
        fetchNotificationSummaryProfileAction(
          openNotificationsSummaryModal.data
        )
      );
    }
  }, [openNotificationsSummaryModal.data]);

  const openModal = () => {
    setModalOpen(openNotificationsSummaryModal?.isOpen);
  };
  const handleClose = () => {
    dispatch(closeNotificationsSummaryModalAction());
  };

  const handleNavigate = () => {
    navigate(`/custom-notifications/${notificationSummary?.id}/edit`, {
      state: {
        editMode: true,
      },
    });
    dispatch(closeNotificationsSummaryModalAction());
  };
  // const handleBackNavigate = () => {
  //   navigate(`/notifications/list`)
  // }
  useEffect(() => {
    return () => {
      dispatch(resetNotificationSummaryAction());
    };
  }, []);
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={openNotificationsSummaryModal?.isOpen}
        onClose={handleClose}
        onOpen={openModal}
      >
        <Head
          title={
            openNotificationsSummaryModal?.isOpen
              ? `Notifications - Notification Summary`
              : ""
          }
        />
        <Box sx={{ width: 656 }} role="presentation" onKeyDown={handleClose}>
          <div className="flex flex-col justify-start items-start w-[656px] pl-3 pr-6 py-4 bg-bgWhite dark:bg-secondaryLight ">
            <div className="relative flex items-center self-stretch justify-between flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg ">
              <div className="flex justify-center items-center">
                <ArrowLeftIcon
                  height={22}
                  width={22}
                  onClick={handleClose}
                  className="cursor-pointer"
                />
                <div className="flex items-center justify-start flex-grow">
                  <div className="flex justify-start items-center flex-grow relative py-0.5 cursor-default ml-3 dark:text-textMain ">
                    Custom Notification Summary
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                {reminders.canEditReminders && notificationSummary.status !== "completed" && (
                  <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={handleNavigate}
                  >
                    <PencilAltIcon width={24} height={24} fill="#54595F" />
                    <div className="mr-8 px-1 pt-1.5 pb-2">
                      <p className="text-sm text-textMid hover:text-primary  dark:text-caption ">
                        Edit
                      </p>
                    </div>
                  </div>
                )}
                <XCloseIcon
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="#2C3236"
                  onClick={handleClose}
                  className="cursor-pointer hover:text-primaryDark"
                />
              </div>
            </div>
            <div className="pl-4 pr-2 w-full">
              <SummaryHeaders
                id={notificationSummary.id}
                name={notificationSummary.title}
                createdBy={notificationSummary.createdBy}
                dateCreated={notificationSummary.insertedAt}
                notificationStatus={notificationSummary.status}
              />
              <SummaryDescriptions
                description={notificationSummary.description}
              />
              <SummaryLink link={notificationSummary.urlLink} />

              {/* <Restricted
                permissions={reminders}
                requiredPermission="canViewStatusHistoryReminders"
              > */}
              <SummaryNotificationsStatus
                id={notificationSummary.id}
                notificationStatus={notificationSummary.remindersHistory}
              />
              {/* </Restricted> */}

              <SummaryNotificationsChannel
                notifyingChannel={notificationSummary.notifyingChannels}
              />
              <SummaryRecipients
                id={notificationSummary.id}
                recipients={notificationSummary.recipients}
                recipientsCount={notificationSummary.recipientsCount}
                roles={notificationSummary.roles}
                programs={notificationSummary.programs}
                communities={notificationSummary.communities}
              />
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};
