import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import { AddExistingBtnProps } from "../../types";

const SearchToAddExistingBtn = ({
  handleClickOnAddExistingBtn,
}: AddExistingBtnProps) => {
  return (
    <button
      onClick={handleClickOnAddExistingBtn}
      className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg hover:bg-primary/[0.2]"
    >
      <SearchIcon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
      <div className="flex items-center justify-start flex-grow">
        <div className="flex justify-start items-center flex-grow relative py-0.5">
          <p className="flex-grow w-[172px] text-[15px] text-left text-textMid">
            Search To Add Existing
          </p>
        </div>
      </div>
    </button>
  );
};

export { SearchToAddExistingBtn };
