function __deletePaintedLayers__(map: any, prefix: any) {
  const layers = map.getStyle().layers;

  for (let index = layers.length - 1; index >= 0; index--) {
    const layer = layers[index];

    if (layer.id.includes(prefix)) {
      map.removeLayer(layer.id);

      if (map.getSource(layer.source)) {
        map.removeSource(layer.source);
      }
    } else {
      break;
    }
  }
}
export { __deletePaintedLayers__ };
