import React from "react";
// Components
import { ReportVersion } from "./Components/ReportVersion";

import { useNavigate, useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  observationsResetFiltersAction,
  observationsResetFiltersExceptCommunityAction,
  observationsResetFiltersExceptProgramAction,
  reportsProfileResetFiltersAction,
  reportsVersionResetFiltersAction,
} from "store/filters/reducer.actions";
import {
  filtersInitialState,
  observationsDirectoryFilterState,
  reportsDirectoryFilterState,
  reportsProfileDirectoryFilterState,
  reportsVersionFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting programsFilter state from Redux store
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Getting organizationId from URL parameters
  const { reportsId } = useParams<{
    reportsId: any;
  }>();
  const statusLabels = ["processing", "ready"];

  const clearAllFilters = () => {
    if (reportsId) {
      dispatch(reportsVersionResetFiltersAction());
    }
  };
  return (
    <div className="flex flex-wrap items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
      {isAnyReportsVersionFilterApplied(reportsVersionFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear all filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%", overflowX: "auto" }}
        className="flex items-center justify-start"
      >
        <div className="flex gap-2">
          <ReportVersion />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

// Function to check if any filter is applied
export function isAnyReportsVersionFilterApplied(
  reportsVersionFilter: reportsVersionFilterState
): any {
  const { versionIds, locations } = reportsVersionFilter;
  const applied = versionIds?.applied || locations?.applied;
  return applied;
}
