

import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;
}

export const CollapseAllIcon = ({
    fill = "#6F747B",
    isActive,
    ...restProps
}: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 7 10" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.87574 0.327382C7.04142 0.493058 7.04142 0.761671 6.87574 0.927347L3.90607 3.89702C3.82651 3.97658 3.7186 4.02128 3.60609 4.02128C3.49357 4.02128 3.38566 3.97658 3.3061 3.89702L0.336429 0.927347C0.170753 0.761671 0.170753 0.493058 0.336429 0.327382C0.502104 0.161706 0.770718 0.161706 0.936394 0.327382L3.60609 2.99707L6.27578 0.327382C6.44145 0.161706 6.71007 0.161706 6.87574 0.327382Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.124257 9.66871C-0.0414189 9.50304 -0.041419 9.23442 0.124257 9.06875L3.09393 6.09907C3.17349 6.01951 3.2814 5.97482 3.39391 5.97482C3.50643 5.97481 3.61434 6.01951 3.6939 6.09907L6.66357 9.06875C6.82925 9.23442 6.82925 9.50304 6.66357 9.66871C6.4979 9.83439 6.22928 9.83439 6.06361 9.66871L3.39391 6.99902L0.724222 9.66871C0.558546 9.83439 0.289933 9.83439 0.124257 9.66871Z" fill="white" />
    </svg>
);

export default CollapseAllIcon;
