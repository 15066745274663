import * as React from "react";
import type { SVGProps } from "react";
interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}
export const LinkDisconnectIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24           }
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L3.70711 2.29289Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1213 4.87868C17.9497 3.70711 16.0503 3.70711 14.8787 4.87868L13.7791 5.97829C13.3885 6.36881 12.7554 6.36881 12.3649 5.97829C11.9743 5.58777 11.9743 4.9546 12.3649 4.56408L13.4645 3.46447C15.4171 1.51184 18.5829 1.51184 20.5355 3.46447C22.4882 5.41709 22.4882 8.58291 20.5355 10.5355L17.6527 13.4183L16.2385 12.0041L19.1213 9.12132C20.2929 7.94975 20.2929 6.05025 19.1213 4.87868ZM7.75736 12L4.87868 14.8787C3.70711 16.0503 3.70711 17.9497 4.87868 19.1213C6.05025 20.2929 7.94975 20.2929 9.12132 19.1213L10.2229 18.0198C10.6134 17.6292 11.2466 17.6292 11.6371 18.0198C12.0276 18.4103 12.0276 19.0434 11.6371 19.434L10.5355 20.5355C8.58291 22.4882 5.41709 22.4882 3.46447 20.5355C1.51184 18.5829 1.51184 15.4171 3.46447 13.4645L6.34315 10.5858L7.75736 12Z"
      fill={fill}
    />
    <path
      d="M15.4081 15.3834C15.8126 15.1609 16.1927 14.8783 16.5355 14.5355L17.6527 13.4183L16.2385 12.0041L15.1213 13.1213C14.8317 13.4109 14.4976 13.629 14.141 13.7754L15.4081 15.3834Z"
      fill={fill}
    />
    <path
      d="M7.75736 12L8.87868 10.8787C9.20617 10.5512 9.59055 10.3152 10 10.1708L8.5 8.66844C8.12981 8.88241 7.78118 9.14775 7.46447 9.46447L6.34315 10.5858L7.75736 12Z"
      fill={fill}
    />
  </svg>
);
export default LinkDisconnectIcon;
