import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Stepper } from "store/addReportStepper/initialState";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

export const chooseFromExistingOperators = [
  {
    name: "and",
    value: "and",
  },
  {
    name: "or",
    value: "or",
  },
];

interface RegionFilterItem {
  column: string;
  condition: string;
  operator: string;
  regions: any[];
}

interface RegionFilterItemProps {
  index: number;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleBlur: any;
  removeFilterItem: () => void;
}

export const RegionFilterItem: React.FC<RegionFilterItemProps> = ({
  index,
  errors,
  values,
  touched,
  handleBlur,
  setFieldValue,
  removeFilterItem,
}) => {
  const { regions } = useSelector<RootState, Stepper>(
    (state) => state?.reportStepper
  );
  const regionFilterErrors: any = errors?.regionFilter
    ? errors.regionFilter[index]
    : {
      column: null,
      condition: null,
      operator: null,
      regions: null,
    };

  const regionFilterTouched = regionFilterErrors || {
    column: false,
    condition: false,
    operator: false,
    regions: false,
  };
  return (
    <div key={index} className="pb-1">
      <div className="flex items-center w-full gap-4 mb-2">
        <div>


          {index > 0 ? (
            <TextInput
              fieldAs="select"
              label=" "
              type="select"
              name={``}
              data={chooseFromExistingOperators}
              handleChange={(event: any) => {
                const { value } = event.target;
                setFieldValue(`regionFilter[${index}].operator`, value);
              }}
              handleBlur={handleBlur}
              value={values?.regionFilter[index]?.operator}
              error={regionFilterErrors?.operator}
              touched={regionFilterTouched}
            />
          ) : (
            <div className=" dark:text-textMain">
              <p className="mt-[5px]">Where</p>
            </div>
          )}
        </div>
        <div className="w-full">
          <TextInput
            fieldAs="select"
            label=" "
            type="select"
            name={`regionFilter[${index}]?.column`}
            data={regions}
            handleChange={(event: any) => {
              const { value } = event.target;
              setFieldValue(`regionFilter[${index}].column`, value);
            }}
            handleBlur={handleBlur}
            value={values?.regionFilter[index]?.column?.name || null}
            error={regionFilterErrors?.column?.name}
            touched={regionFilterTouched}
          />
        </div>

        <div className="relative flex flex-col w-full">
          <div className="flex justify-start items-center min-h-[40px] gap-1.5 px-3 py-1 rounded bg-textMain dark:bg-secondaryLight dark:border dark:border-lineLight  ">
            <div className="flex flex-col items-start justify-start overflow-hidden">
              <div className="flex items-center justify-start gap-8 w-80">
                <p className="dark:text-textMain ">inside the drawn area</p>
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center absolute top-[41px] left-0">
            {regionFilterErrors?.regions && (
              <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
                {regionFilterErrors?.regions}
              </p>
            )}
          </div>
        </div>
        <button type="button" onClick={removeFilterItem} title="Remove filter">
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default RegionFilterItem;
