import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  closeContactInfoModalAction,
  openAddEditContactInfoModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import ContactInfoImg from "assets/images/contact-info.svg";
import { MailIcon, PhoneIcon, GlobeAltIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { useParams } from "react-router-dom";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface ContactInfoModalProps {}

export const ContactInfoModal: React.FC<ContactInfoModalProps> = () => {
  const { contactInfoModal } = useSelector((state: RootState) => state?.modals);
  const dispatch = useDispatch();
  const { programs, organizations, communities } = usePermissions();
  const { organizationId, programId } = useParams();

  const handleClose = () => {
    dispatch(closeContactInfoModalAction());
  };

  const handleBtnClick = () => {
    dispatch(closeContactInfoModalAction());
    dispatch(openAddEditContactInfoModalAction());
  };

  return (
    <Modal
      open={contactInfoModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[520px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="w-full flex  justify-between items-start">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
                  Contacts
                </p>
              </div>
              <button onClick={handleClose} type="button" className="p-1">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>

            {checkIfEmptyFields(contactInfoModal?.data) ? (
              <div className="w-full flex flex-col justify-end items-center my-6 h-[50vh]">
                <img src={ContactInfoImg} />
                <p className="text-lg font-semibold break-words break-all text-secondaryMid dark:text-textMain mt-7">
                  There are no contacts yet
                </p>
                <p className="mt-2 mb-8 text-sm font-normal break-words break-all text-secondaryMid dark:text-textMain">
                  To add contact information, please click on the button below.
                </p>
                {contactInfoModal.status !== "completed" &&
                  (organizationId
                    ? organizations.canCreateContactInfo
                    : programId
                      ? programs.canCreateContactInfo
                      : communities.canCreateContactInfo) && (
                    <Button
                      type="button"
                      text="Add Contact Information"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleBtnClick}
                    />
                  )}
              </div>
            ) : (
              <div className="w-full max-h-[450px] overflow-y-auto">
                <p className="flex-grow w-full pr-5 text-sm text-left break-all text-textMid dark:text-caption">
                  {contactInfoModal?.data?.description}
                </p>

                <ContactInfo
                  items={contactInfoModal.data?.emails}
                  icon={<MailIcon fill="#2C3236" />}
                />

                <ContactInfo
                  items={contactInfoModal.data?.phones}
                  icon={<PhoneIcon fill="#2C3236" />}
                />

                <ContactInfo
                  title="Links"
                  items={contactInfoModal.data?.links}
                  icon={<GlobeAltIcon fill="#2C3236" />}
                />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

interface ContactItem {
  title: string;
  email: string;
  links: string;
  phoneNumber: string;
}

interface ContactInfoProps {
  title?: string;
  items: ContactItem[];
  icon: JSX.Element;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ title, items, icon }) => {
  const hasLinks = items?.some((item) => item?.links);
  return (
    <div className="mt-4">
      {hasLinks && (
        <p className="mb-4 text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
          {title}
        </p>
      )}

      {items?.map((item, index) => (
        <div key={index} className="flex items-center mb-3">
          {icon}
          <div className="ml-4">
            <p className="text-[15px] break-words break-all font-normal text-secondaryMid dark:text-caption">
              {item?.title}
            </p>
            {item?.links ? (
              <a
                href={item.links}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-primary dark:text-primaryMidLight hover:text-primaryMid"
              >
                {item.links}
              </a>
            ) : (
              <p className="text-xs text-primary dark:text-primaryMidLight">
                {item?.email || item?.phoneNumber}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactInfo;

function checkIfEmptyFields(data: any) {
  if (data !== null) {
    const isDescriptionEmpty = data.description.trim() === "";
    const isEmailsEmpty = data.emails.length === 0;
    const isPhonesEmpty = data.phones.length === 0;
    const isLinksEmpty = data.links.length === 0;
    return isDescriptionEmpty && isEmailsEmpty && isPhonesEmpty && isLinksEmpty;
  } else {
    return true;
  }
}
