import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "categoryName"
  ) {
    return (
      <p className="text-sm w-[200px] text-left break-all text-textMid capitalize dark:group-hover:text-secondary">
         {ellipsize(`${cellValue?.name}`,60) }{" "}
      </p>
    );
  } else if (cellValue !== null) {
    return (
      <p className={`text-sm text-left text-textMid whitespace-nowrap dark:group-hover:text-secondary`}>
        {ellipsize(`${cellValue}`, 60)}
      </p>
    );
  } else {
    return null;
  }
};
