import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import "../../../Table/Reports.scss";
import usePermissions from "hooks/usePermissions";

export const TableBody = ({
  table,
  columnVisibility,
  getReportsActions,
  flexRender,
}: TableBodyProps) => {
  const { reports } = usePermissions();

  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className={`Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight ${reports.canViewReports && "group"}`}
            style={{ height: "66px" }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  rowData={row}
                />
              )
            )}
            <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
              <RowsActions row={row} getReportsActions={getReportsActions} />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
