import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { closeImportSummaryAction, openUndoAndArchiveImportModalAction } from "store/modals/reducer.actions";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import { fetchImportSummaryAction } from "store/importSummary/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IImportSummaryData } from "store/importSummary/initialState";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { ImportSummaryTable } from "hooks/useTable";
import { Table } from "Components/importSummary/Table";
import { filtersInitialState } from "store/filters/initialState";
import { Pagination } from "Components/importSummary/Pagination";
import { useNavigate } from "react-router-dom";
import { importsSummaryDataResetAction } from "store/filters/reducer.actions";
import { ClickAwayListener } from "@mui/material";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import Head from "view/components/Head";
import { CheveronDownIcon, CheveronUpIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { Toasts } from "view/components/Toasts";

export const ImportSummaryModal = () => {
  const { importSummaryModal } = useSelector(
    (state: RootState) => state.modals
  );
  const navigate = useNavigate();
  const drawerRef = useRef<HTMLDivElement>(null);
  const [showFullText, setShowFullText] = useState(false);
  const { data, stateIs } = useSelector<RootState, IImportSummaryData>(
    (state) => state?.importSummary
  );
  const { imports } = usePermissions();

  const { importsSummaryDataFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const openModal = () => {
    // setModalOpen(formDetailsModal?.isOpen);
  };
  const handleClose = () => {
    dispatch(closeImportSummaryAction());
  };

  useEffect(() => {
    dispatch(
      fetchImportSummaryAction({
        id: importSummaryModal?.data,
        path: "",
      })
    );
  }, [importsSummaryDataFilter]);

  useEffect(() => {
    return () => {
      dispatch(importsSummaryDataResetAction());
    };
  }, []);

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setSorting,
    sorting,
  } = ImportSummaryTable(data?.columnMapping);

  const handleViewData = () => {
    const id = importSummaryModal?.data;
    const defaultPath = `/imports`; // Default path when 'id' is not found

    if (id) {
      const targetPath = `/imports/view-data/${id}`;
      navigate(targetPath);
    } else {
      navigate(defaultPath);
    }
    dispatch(closeImportSummaryAction());
  };

  const maxChars = 600;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleShowUndoAndArchiveModal = () => {
    dispatch(openUndoAndArchiveImportModalAction(data))
  }

  const isImported = data?.status && data?.status === "imported"

  const handleDownloadFile = () => {
    const filePath = data.filePath;

    // Basic validation: Check if the status is "archived" and the filePath exists
    if (data.status === "archived" && filePath) {
      try {
        // Create an invisible anchor element and trigger the download
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', ''); // For cross-browser compatibility, provide a default filename if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up by removing the anchor element

        // Show success message
        Toasts.success('File downloaded successfully.');
      } catch (error) {
        console.error('Error downloading file:', error);
        Toasts.error('An error occurred while trying to download the file.');
      }
    } else {
      // Show error message if validation fails
      Toasts.error('The file is not available or the status is incorrect.');
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={importSummaryModal?.isOpen}
      onClose={handleClose}
      onOpen={openModal}
    >
      <Head
        title={importSummaryModal?.isOpen ? "Imports - Imports Summary" : ""}
      />
      <Box sx={{ width: 720 }} role="presentation" onKeyDown={handleClose}>
        <div
          className="flex flex-col items-start justify-start w-full h-full bg-white dark:bg-secondary"
          style={{
            boxShadow:
              "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
          }}
        >
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 py-4 pl-3 pr-6 bg-white">
            <div className="relative flex items-center self-stretch justify-start gap-2 px-3 py-2 rounded-lg">
              <button
                onClick={handleClose}
                className="flex items-center mr-2 space-x-2 text-textMid hover:underline"
              >
                <ArrowLeftIcon height={22} width={22} />
              </button>

              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center text-secondaryMid flex-grow relative py-0.5">
                  <h3 className="text-xl font-semibold capitalize text-textMid font-Overpass dark:text-textMain">
                    Imports Summary
                  </h3>
                </div>
              </div>

              <div className="flex items-center justify-end flex-grow">
                {isImported && (
                  <button onClick={handleShowUndoAndArchiveModal} className="px-5 mr-3 py-1.5 cursor-pointer text-sm font-medium text-primary font-Overpass rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary transition">
                    Undo and Archive Import
                  </button>
                )}

                {(imports.canViewViewDataImports && isImported) && (
                  <button onClick={handleViewData} className="px-5 mr-5 cursor-pointer py-1.5 font-medium text-primary text-sm font-Overpass rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary transition">
                    View Data
                  </button>
                )}

                {!isImported &&
                  <button onClick={handleDownloadFile} className="px-5 mr-5 cursor-pointer py-1.5 font-medium text-primary text-sm font-Overpass rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary transition">
                    Download File
                  </button>
                }

                <button onClick={handleClose}>
                  {" "}
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                      // fill="#2C3236"
                      className="dark:fill-textMain fill-[#2C3236]"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6 px-3 py-4">
              <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink- gap-6">
                <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5 pb-2 w-full">
                  <div className="grid grid-cols-2 gap-x-10 gap-y-4 w-full break-words">
                    <div className="col-span-2">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        File name
                      </p>
                      <p className="text-lg font-semibold font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.fileName}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Program
                      </p>
                      <p className="text-md font-regular font-Overpass text-left text-textMid dark:text-inputText break-words">
                        {data?.program?.name}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Form
                      </p>
                      <p className="text-md font-regular font-Overpass text-left text-textMid dark:text-inputText break-words">
                        {data?.dynamicForm?.name}
                      </p>
                    </div>

                    <div className="col-span-2">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Total Observations
                      </p>
                      <p className="text-sm font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.totalObservations}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Succesful Import
                      </p>
                      <p className="text-sm font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.successfulImports}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Failed Import
                      </p>
                      <p className="text-sm font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.failedImport}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Imported By
                      </p>
                      <p className="text-sm font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.importedBy?.name}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Import Date
                      </p>
                      <p className="text-sm font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {moment
                          .utc(data?.importedDate)
                          .format("DD MMM YYYY, hh:mm A")}
                      </p>
                    </div>

                    <div className="col-span-2">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Data Received From
                      </p>
                      <p className="text-md font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {data?.dataReceivedFrom}
                      </p>
                    </div>

                    <div className="col-span-2">
                      <p className="text-sm text-left font-Overpass text-textMid dark:text-caption">
                        Comment
                      </p>
                      <p className="text-md font-regular font-Overpass text-left text-textMid dark:text-inputText">
                        {showFullText
                          ? data?.comment || ""
                          : (data?.comment || "").slice(0, maxChars) +
                          ((data?.comment || "").length > maxChars
                            ? "..."
                            : "")}
                      </p>
                      {data?.comment?.length > maxChars && (
                        <button
                          onClick={toggleText}
                          className="flex items-center mb-[3px]"
                        >
                          <h5 className="pr-1 text-sm font-semibold text-secondaryMid dark:text-paleSkyBlue">
                            {showFullText ? "See Less" : "See More"}
                          </h5>
                          {showFullText ? (
                            <CheveronUpIcon height={16} />
                          ) : (
                            <CheveronDownIcon height={16} />
                          )}
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite my-5">
                    {stateIs == "Idle" && data?.columnMapping?.length === 0 ? (
                      <NoRecordFound />
                    ) : stateIs === "Pending" ? (
                      <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                        <TailSpin
                          height="50"
                          width="50"
                          color="#005C89"
                          ariaLabel="tail-spin-loading"
                          radius="2"
                          wrapperStyle={{}}
                          wrapperClass="tailspin-loader"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Table
                        flexRender={flexRender}
                        table={table}
                        columnOrder={columnOrder}
                        columns={columnsConfig}
                        columnVisibility={columnVisibility}
                        setSorting={setSorting}
                        sorting={sorting}
                      />
                    )}
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </SwipeableDrawer>
  );
};
