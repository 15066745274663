import React, { useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import ObservationColumnModal from "view/components/Modals/Observations/ObservationsColumnModal";
import { IfetchObservations } from "store/observations/initialState";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import AppliedFilters from "../AppliedFilters";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(true);
  const reportsProfile = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

  return (
    <>
      <div className="flex items-start justify-start gap-6 py-2">
        <HeaderBar
          data={reportsProfile?.data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      <div className="flex justify-start items-start w-full flex-wrap">
        <AppliedFilters />
      </div>
    </>
  );
};
