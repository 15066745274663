import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 8,
}));

const LinearProgressWithLabelAndCount = (
  props: LinearProgressProps & {
    value: number;
    label: string;
    barColor?: string;
    barBgColor?: string;
  }
) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-1">
        <p className="w-full text-sm font-normal leading-5 text-left text-gray-700 break-words dark:text-textMain font-Overpass">
          {props.label}
        </p>
        <p className="w-full text-sm font-normal leading-5 text-right text-gray-700 break-words dark:text-caption font-Overpass">
          {Math.round(props.value)}
        </p>
      </div>
      <div>
        <BorderLinearProgress
          variant="determinate"
          {...props}
          sx={{
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: props?.barBgColor || "#CDE7FF",
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 8,
              backgroundColor: props.barColor || "#11A3E2",
            },
          }}
        />
      </div>
    </div>
  );
};

export default LinearProgressWithLabelAndCount;
