export interface ProgramManagers {
  id: number;
  name: string;
}

export interface IQualityAssuranceObservation {
  communityName: {
    image: {
      original: string;
      thumb: string;
    };
    id: number;
    name: string;
  };
  flag: any;
  dateUpdated: string;
  flaggedSections: number;
  id: number;
  observationCode: string;
  observer: {
    id: number;
    name: string;
    image: {
      original: string;
      thumb: string;
    };
  };
  programName: {
    id: number;
    name: string;
    image: {
      original: string;
      thumb: string;
    };
  };
  reviewer: {
    id: number;
    name: string;
  };
  programManagers: ProgramManagers[];

  status: string;
  unreadMessages: number;
  requireQa: boolean;
}

export interface IQualityAssuranceObservationsData {
  externalReviewerAccessUntil: string;
  observations: IQualityAssuranceObservation[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchQualityAssuranceObservations {
  data: IQualityAssuranceObservationsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialQualityAssuranceObservationsData: IfetchQualityAssuranceObservations =
  {
    data: {
      externalReviewerAccessUntil: "",
      observations: [],
      totalItems: 0,
      totalPages: 0,
    },
    message: "",
    stateIs: "Idle",
  };

export default initialQualityAssuranceObservationsData;
