import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column } from "../index";
import { useNavigate } from "react-router-dom";
import "../../../Table/Reports.scss";

export const TableBody = ({
  table,
  columnVisibility,
  flexRender,
}: TableBodyProps) => {
  const navigate = useNavigate();
  const openReportDetails = (row: any) => {
    // navigate(`/reports/${row.id}/profile`);
  };
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className="Close w-full item_data"
            style={{ height: "66px" }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  openReportDetails={() => openReportDetails(row?.original)}
                />
              )
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
