import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const PaperClipIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="m12.883 4.874-6.418 6.59a5 5 0 1 0 7.07 7.071h.001l6.258-6.243a1 1 0 0 1 1.412 1.416L14.95 19.95a7 7 0 0 1-9.905-9.895l6.42-6.59a5 5 0 0 1 7.075 7.066l-6.419 6.59A3 3 0 0 1 7.88 12.88l6.585-6.586a1 1 0 1 1 1.415 1.414l-6.586 6.586a1 1 0 0 0 1.41 1.418l6.418-6.59a3 3 0 0 0-4.238-4.247Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PaperClipIcon;
