import { Ifetch } from "../initialState";

export interface IMostObservedSpecies {
  count: number;
  name: string;
}

export interface IfetchMostObservedSpecies extends Ifetch {
  data: IMostObservedSpecies[];
}

const mostObservedSpeciesInitialState: IfetchMostObservedSpecies = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default mostObservedSpeciesInitialState;
