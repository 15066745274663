import { apiClient } from "../config";

async function getOrganizationDropdown(search?: string) {
  const res = await apiClient.get(
    `/admin/organizations/dropdown${
      search !== undefined ? `?search_string=${search} ` : ""
    }`
  );
  return res.data;
}

export default getOrganizationDropdown;
