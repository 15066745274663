import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  
}

export const PencilAltIcon = ({ fill, isActive, ...restProps }: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      
      d="M19.707 4.293a1 1 0 0 0-1.414 0L10 12.586V14h1.414l8.293-8.293a1 1 0 0 0 0-1.414ZM16.88 2.879A3 3 0 1 1 21.12 7.12l-8.585 8.586a1 1 0 0 1-.708.293H9a1 1 0 0 1-1-1v-2.828a1 1 0 0 1 .293-.708l8.586-8.585ZM6 6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-5a1 1 0 1 1 2 0v5a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h5a1 1 0 1 1 0 2H6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PencilAltIcon;
