import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;

}

export const FolderFileIcon = ({ fill, isActive, ...restProps }: IconProps) => (
    <svg width="17" height="16" viewBox="0 0 17 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
        {...restProps}>
        <path
            fill={fill}
            className={`dark:${isActive
                ? "fill-primary"
                : "fill-caption group-hover:dark:fill-primary"
                }`}
            fill-rule="evenodd" clip-rule="evenodd" d="M3.99902 4.0013C3.63083 4.0013 3.33236 4.29978 3.33236 4.66797V11.3346C3.33236 11.7028 3.63083 12.0013 3.99902 12.0013H13.3324C13.7005 12.0013 13.999 11.7028 13.999 11.3346V6.0013C13.999 5.63311 13.7005 5.33464 13.3324 5.33464H9.33236C9.15555 5.33464 8.98598 5.2644 8.86095 5.13937L7.72288 4.0013H3.99902ZM1.99902 4.66797C1.99902 3.5634 2.89445 2.66797 3.99902 2.66797H7.99902C8.17583 2.66797 8.3454 2.73821 8.47043 2.86323L9.6085 4.0013H13.3324C14.4369 4.0013 15.3324 4.89673 15.3324 6.0013V11.3346C15.3324 12.4392 14.4369 13.3346 13.3324 13.3346H3.99902C2.89445 13.3346 1.99902 12.4392 1.99902 11.3346V4.66797Z"
        // fill="#2C3236" 
        />
    </svg>
);
export default FolderFileIcon;