import { ColumnProps } from "../../types";
import { RenderCellContent } from "../index";

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  openObservationDetails,
}: ColumnProps) => {
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;

  if (isColumnVisible && cell.column.columnDef.id !== "select") {
    if (cell.column.columnDef.id === "observationCommunityLatitude" || cell.column.columnDef.id === "observationCommunityLongitude") {
      return;
    }
    else {
      return (
        <td onClick={openObservationDetails} key={cell.id} className="px-4">
          <RenderCellContent
            columnDef={cell.column.columnDef}
            cellValue={cell.getValue()}
          />
        </td>
      );
    }
  } else {
    return (
      <td key={cell.id} className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }
};
