import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { DateFilter } from "./Components/Date";
import {
  Statuses,
  Programs,
  Communities,
  Observers,
  Reviewers,
} from "./Components";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import AppliedFilters from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";

interface IFiltersListing {
  label: string;
  dropdownComponent?: React.ReactNode;
  name: string;
  isVisible: boolean;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [rightOffsetWidth, setRightOffsetWidth] = useState(false);

  const handleMouseEnter = (dropdownName: string, event: any) => {
    if (
      showDropdown === dropdownName &&
      !isDatePickerElementAndButtonLabel(event.target, dropdownName)
    ) {
      setShowDropdown(null);
    } else {
      setShowDropdown(dropdownName);
    }
  };

  function isDatePickerElementAndButtonLabel(
    target: any,
    dropdownName?: string | null
  ) {
    if (!dropdownName) {
      return false;
    }

    const selector = `#${dropdownName}id, .MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root`; // Correct the selector string by removing the trailing comma
    return target?.closest(`${selector}`);
  }

  const List: IFiltersListing[] = [
    {
      label: "Status",
      name: "status",
      isVisible: true,
      dropdownComponent: <Statuses />,
    },

    {
      label: "Observer",
      name: "observer",
      isVisible: true,
      dropdownComponent: <Observers name="users" />,
    },

    {
      label: "Program Name",
      name: "programName",
      isVisible: !programId,
      dropdownComponent: <Programs name="programs" />,
    },
    {
      label: "Community Name",
      name: "communityName",
      isVisible: !communityId,
      dropdownComponent: <Communities name="communities" />,
    },
    {
      label: "Reviewer",
      name: "reviewer",
      isVisible: true,
      dropdownComponent: <Reviewers name="users" />,
    },

    {
      label: "Date Updated",
      name: "dateUpdated",
      isVisible: true,
      dropdownComponent: (
        <DateFilter
          name="date"
          from={qualityAssuranceObservationsFilter?.dateUpdated?.from}
          to={qualityAssuranceObservationsFilter?.dateUpdated?.to}
        />
      ),
    },
  ];

  useEffect(() => {
    if (showDropdown) {
      const currentDropdownMenu = document.querySelector(
        `#${showDropdown}id .menu`
      );

      const btn = document.querySelector(`[data-dropdown="${showDropdown}"]`);

      if (currentDropdownMenu && btn) {
        const dropdownRect = currentDropdownMenu.getBoundingClientRect();
        const btnwidth = btn.getBoundingClientRect();

        const btnwidthoffset = window.innerWidth - btnwidth.right;
        const setDicDirection = dropdownRect.width - btnwidth.width;

        const getRightOffset = btnwidthoffset - setDicDirection;
        setRightOffsetWidth(getRightOffset < 0);
      }
    }
  }, [showDropdown]);

  function handleClickOutside(event: any) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isDatePickerElement(event.target)
    ) {
      setShowDropdown(null);
    }
  }
  function isDatePickerElement(target: any) {
    return target.closest(
      `.MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root, .btn`
    ); // Added MuiIconButton-root
  }

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div
      className={`flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 ${
        isCloseDiv ? "h-min-[40px]" : "h-[0px] opacity-0"
      } transition-h duration-300 ease-in-out`}
    >
      <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        {List?.filter((item) => item.isVisible).map((item, index) => (
          <div
            key={index}
            data-dropdown={item.name}
            onClick={(event) => handleMouseEnter(item.name,event)}
            id={item.name + "index"}
            className="btn flex justify-center items-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg border cursor-pointer hover:border-primaryMid hover:bg-primary/[0.08]  border-lineMid"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
              <p
                className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-secondaryMid 
 dark:text-primaryOutlined"
              >
                {item.label}
              </p>
            </div>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              style={{
                transform: `rotate(${
                  showDropdown === item.name ? "180deg" : "0"
                })`,
                transition: "transform 0.3s ease",
              }}
            />
            {showDropdown === item.name && (
              <div
              id={item.name + "id"}
              className={`absolute ${
                !rightOffsetWidth ? "left-0" : "right-0"
              }  z-30 top-full ring-offset-8`}
            >
              <div
                className={`${
                  item.dropdownComponent && showDropdown === item.name
                    ? "visible"
                    : "invisible"
                } menu `}
                ref={dropdownRef}
              >
                {item.dropdownComponent}
              </div>
            </div>
            )}
          </div>
        ))}
      </div>
      <AppliedFilters />
    </div>
  );
};

export default FiltersList;
