import { Ifetch } from "../initialState";

export interface IMostObservedObjects {
  count: number;
  name: string;
}

export interface IfetchMostObservedObjects extends Ifetch {
  data: IMostObservedObjects[];
}

const mostObservedObjectsInitialState: IfetchMostObservedObjects = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default mostObservedObjectsInitialState;
