import apiClient from "services/apiClient";

async function addReportGeospatialFilter(reportId: number, data: any) {
  const url = `/admin/reports/${reportId}/geospatial-filtering`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default addReportGeospatialFilter;
