import Head from "view/components/Head";
import { PageHeadingProps } from "../../types";

const PageHeading = ({ id, name, image, parentName }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={parentName + " " + (id !== "" ? `${name}: Management` : "Management")} />
      <div className="relative flex justify-start">
        <img src={image} alt="" className=" rounded-full h-9 w-9 object cover" />
        <p className="break-all ml-2 w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {id !== "" ? <>{name}: Management</> : "Management"}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
