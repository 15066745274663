import apiClient from "services/apiClient";

const getAllRecipients = async (
    query: any,
) => {
  const res = await apiClient.get(
    `/admin/reminders/${query.notificationId}/members`,{
        params: query
    }
  );
  return res.data;
};

export default getAllRecipients;
