import Hotjar from "@hotjar/browser";
import { hotJarSiteId, hotjarVersion } from "config";
import { IAuthResponseData } from "store/auth/initialState";

function initHotJar(auth: IAuthResponseData) {
  Hotjar.init(Number(hotJarSiteId), Number(hotjarVersion));

  Hotjar.identify(auth.id.toString(), {
    name: `${auth.person.firstName} ${auth.person.lastName}`,
    email: auth.email,
  });
}

export default initHotJar;
