import apiClient from "services/apiClient";

const getAllObservationsActivities = async (
  observationId: any,
  queryParams?: any
) => {
  const res = await apiClient.get(
    `/admin/observations/${observationId}/activities`,
    {
      params: queryParams,
    });

  return res.data;
};

export default getAllObservationsActivities;
