import * as React from "react";
import MostObservedObjects from "./components/MostObservedObjects";
import ActiveUsers from "./components/ActiveUsers";
import DashboardNotifications from "./components/Notifications";
import ObservationsByRegion from "./components/ObservationsByRegion";
import DashboardObservations from "./components/Observations";
import QAProgress from "./components/QAProgress";
import TotalCounts from "./components/Widgets";
import ReportsNotifications from "./components/ReportsNotifications";
import HorizontalBarGraph from "./components/HorizontalBarGraph";
import { HBGModule } from "store/dashboard/horizontalBarGraph/types";
import { MostObservedModuleName } from "./components/MostObservedObjects/types";
import CumulativeObservations from "./components/CumulativeObservations";
import QANotifications from "./components/QANotifications";
import ObservationByLocation from "./components/ObservationsByLocation";
import { dashboardWidgetConstants } from "constants/dashboardWidgetConstants";

interface IDashboardWidgets {
  widgetName: string;
  dashboardPermissions: any;
}

const DashboardWidgets = (props: IDashboardWidgets) => {

  switch (props.widgetName) {
    case dashboardWidgetConstants.observationsByRegion.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewObservationsByRegion,
        _id: dashboardWidgetConstants.observationsByRegion.VALUE,
        grid: 8,
        component: <ObservationsByRegion />,
      };
    case dashboardWidgetConstants.notifications.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewNotifications,
        _id: dashboardWidgetConstants.notifications.VALUE,
        grid: 4,
        component: <DashboardNotifications />,
      };

    case dashboardWidgetConstants.observations.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewObservations,
        _id: dashboardWidgetConstants.observations.VALUE,
        grid: 8,
        component: <DashboardObservations />,
      };
    case dashboardWidgetConstants.qaProgress.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewQAProgress,
        _id: dashboardWidgetConstants.qaProgress.VALUE,
        grid: 4,
        component: <QAProgress />,
      };
    case dashboardWidgetConstants.qaNotifications.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewQANotifications,
        _id: dashboardWidgetConstants.qaNotifications.VALUE,
        grid: 4,
        component: <QANotifications />,
      };
    case dashboardWidgetConstants.totalCounts.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewKPIs,
        _id: dashboardWidgetConstants.totalCounts.VALUE,
        grid: 12,
        component: <TotalCounts />,
      };

    case dashboardWidgetConstants.activeUsers.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewActiveUsers,
        _id: dashboardWidgetConstants.activeUsers.VALUE,
        grid: 4,
        component: <ActiveUsers />,
      };
    case dashboardWidgetConstants.mostObservedObjects.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewMostObservedObjects,
        _id: dashboardWidgetConstants.mostObservedObjects.VALUE,
        grid: 8,
        component: (
          <MostObservedObjects
            barColor="#11A3E2"
            moduleName={MostObservedModuleName.objects}
          />
        ),
      };
    case dashboardWidgetConstants.community.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewHorizontalBarGraph,
        _id: dashboardWidgetConstants.community.VALUE,
        grid: 4,
        component: (
          <HorizontalBarGraph
            moduleName={HBGModule.community}
            name="Community"
            widgetId="community"

          />
        ),
      };
    case dashboardWidgetConstants.form.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewHorizontalBarGraph,
        _id: dashboardWidgetConstants.form.VALUE,
        grid: 4,
        component: (
          <HorizontalBarGraph moduleName={HBGModule.form} name="Form" 
          widgetId="form"
          />
        ),
      };
    case dashboardWidgetConstants.program.VALUE:
      return {
        hasAccess: props.dashboardPermissions?.canViewHorizontalBarGraph,
        _id: dashboardWidgetConstants.program.VALUE,
        grid: 4,
        component: (
          <HorizontalBarGraph moduleName={HBGModule.program} name="Program" widgetId="program"/>
        ),
      };
    case dashboardWidgetConstants.reportsNotifications.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewReportNotifications,
        _id: dashboardWidgetConstants.reportsNotifications.VALUE,
        grid: 4,
        component: <ReportsNotifications />,
      };
    case dashboardWidgetConstants.mostObservedSpecies.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewMostObservedSpecies,
        _id: dashboardWidgetConstants.mostObservedSpecies.VALUE,
        grid: 8,
        component: (
          <MostObservedObjects
            barColor="#009F88"
            moduleName={MostObservedModuleName.species}
          />
        ),
      };

    case dashboardWidgetConstants.cumulativeObservations.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewCumulativeObservations,
        _id: dashboardWidgetConstants.cumulativeObservations.VALUE,
        grid: 8,
        component: <CumulativeObservations />,
      };

    case dashboardWidgetConstants.observationsByLocation.VALUE:
      return {
        hasAccess: props.dashboardPermissions.canViewClusteredMap,
        _id: dashboardWidgetConstants.observationsByLocation.VALUE,
        grid: 8,
        component: <ObservationByLocation />,
      };
    default:
      return {
        hasAccess: true,
        _id: "noComponent",
        grid: 12,
        component: <div />,
        noWidgetFound: true,
      };
  }
};

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   borderRadius: 8,
//   border: "0.5px solid #EAEAEA",
//   boxShadow: "0px 22.58px 15.66px 0px rgba(173, 173, 173, 0.06)",
//   height: "388px",
//   overflow: "hidden",
// }));

interface IContent extends IDashboardWidgets {}

const Content = (props: IContent) => {
  const { _id, grid, component, noWidgetFound, hasAccess } = DashboardWidgets({
    widgetName: props.widgetName,
    dashboardPermissions: props.dashboardPermissions,
  });
  const wrappedComponent =
    _id === dashboardWidgetConstants.totalCounts.VALUE ? (
      component
    ) : (
      <div
        className={
          " bg-bgWhite p-4 pb-6 rounded-lg border border-borderLightGray  dark:border-lineLight h-[388px] overflow-hidden dark:bg-secondaryLight"
        }
      >
        {component}
      </div>
    );

  return {
    _id,
    grid,
    component: wrappedComponent,
    noWidgetFound,
    hasAccess,
  };
};
export default Content;
