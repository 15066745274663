import React, { ChangeEvent, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
} from "@reach/combobox";
import LocationMarkerIcon from "assets/icons/HeroIcons/LocationMarkerIcon";

interface GooglePlacesAutocompleteProps {
  editMode?: boolean;
  inputValue?: string;
  label?: string;
  error?: any;
  placeholder?: any;
  onBlur?: any;
  hanndleAddressSelect: any;
  handleLatitudeAndLongitude?: any;
  location?: any;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  editMode = false,
  inputValue,
  label = "",
  error,
  placeholder,
  onBlur,
  hanndleAddressSelect,
  handleLatitudeAndLongitude,
  location,
}) => {

  const {
    ready,
    value,
    suggestions: { status, data },
    clearSuggestions,
    setValue,
  } = usePlacesAutocomplete({ callbackName: "initMap" });

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === "") {
      handleLatitudeAndLongitude(0, 0);
    }
    setValue(e.target.value);
  };

  function reverseGeocode(latitude: any, longitude: any) {
    const geocoder = new (window as any).google.maps.Geocoder();
    var latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

    geocoder.geocode(
      { location: latlng },
      function (results: any, status: any) {
        if (status === "OK") {
          if (results[0]) {
            var address = results[0].formatted_address;
            setValue(address, false);
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      }
    );
  }
  const handleSelect = (val: string): void => {
    const geocoder = new (window as any).google.maps.Geocoder();
    geocoder.geocode({ address: val }, (results: any, status: any) => {
      if (status === "OK") {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        handleLatitudeAndLongitude(longitude, latitude);
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

    // Clear suggestions and set the selected value
    clearSuggestions();
    setValue(val, false);
    hanndleAddressSelect(val);
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map(({ place_id, description }: any) => (
      <div
        key={place_id}
        onClick={() => handleSelect(description)}
        className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 cursor-pointer hover:text-primary hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight dark:hover:text-primary"
      >
        <div className="flex justify-start  font-semibold  items-center flex-grow relative py-0.5 dark:text-caption dark:hover:text-primary">
          {description}
        </div>
      </div>
    ));

    return <>{suggestions}</>;
  };

  useEffect(() => {
    if (editMode && inputValue) {
      setValue(inputValue, false);
    }
    if (location?.lat && location?.long) {
      reverseGeocode(location.lat, location.long);
    }
  }, [editMode, setValue, inputValue, location]);

  return (
    <Combobox
      style={{ width: "100%" }}
      onSelect={handleSelect}
      aria-labelledby="demo"
    >
      <div className="flex flex-col flex-grow w-full rounded-lg">
        <div className="relative flex flex-col">
          {label && (
            <p className="w-full pb-1 text-sm font-medium text-left capitalize text-textMid dark:text-textMain">
              {label}
            </p>
          )}
          <>
            <div
              className={`flex items-center border rounded bg-white ${error
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
                }`}
            >
              <button type="button" className="pl-2 focus:outline-none">
                <LocationMarkerIcon />
              </button>
              <ComboboxInput
                className={`dark:text-textMain flex-1 justify-start items-center w-full focus:outline-none gap-1.5 px-3 py-2 rounded bg-bgWhite dark:bg-secondaryLight`}
                placeholder={placeholder}
                value={value}
                onChange={handleInput}
                disabled={!ready}
              />
            </div>

            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {typeof error === "object" ? JSON.stringify(error) : error}
                </p>
              )}
            </div>
          </>
        </div>
      </div>
      {status === "OK" && (
        <ComboboxPopover style={{ zIndex: 9999 }}>
          <ComboboxList>{renderSuggestions()}</ComboboxList>
        </ComboboxPopover>
      )}
    </Combobox>
  );
};

export default GooglePlacesAutocomplete;
