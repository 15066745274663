function togglePropertyGridSidebar(doShow: boolean) {

  const generalSidebar: HTMLElement | null = document.querySelector(
    ".svc-side-bar__container-content .spg-root-modern"
  );
  const searchBar: HTMLElement | null = document.querySelector(".spg-container_search");
  
  if (generalSidebar) {
    generalSidebar.style.display = doShow ? "block" : "none";
  }
  if(searchBar){
    searchBar.style.display = doShow ? "block" : "none";
  }
}

export default togglePropertyGridSidebar;
