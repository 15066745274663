import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, AddCommunityBtn, PageHeading } from "./components";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import {
  openAddExistingCommunitiesOrOrganizationsToProgramModalAction,
  openAddExistingCommunityModalAction,
  openCreateCommunityModalAction,
} from "store/modals/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import {
  openAddRasterLayerScreen,
  openAddVectorLayerScreen,
} from "store/geography";
import { geographyViewTypeAction } from "store/filters";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/geography/list`, label: `Geography` }];
  }
};
export const Header = () => {
  const navigate = useNavigate();
  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { geographies } = usePermissions();
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  // const { geographies } = usePermissions()
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    geographyFilter.type
  );

  // Handlers

  // displaying models based on organizationId
  const handleAddCommunityModel = () => {
    setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewRasterLayerBtn = () => {
    dispatch(openAddRasterLayerScreen());
    dispatch(geographyViewTypeAction("map"));
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    dispatch(openAddVectorLayerScreen());

    dispatch(geographyViewTypeAction("map"));
    setIsShowExistingOptionsModal(false);
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {geographies.canCreateGeographies && (
                <AddCommunityBtn
                  handleAddCommunityModel={handleAddCommunityModel}
                  label={"Add Layer"}
                />
              )}

              <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewRasterLayerBtn}
                handleAddCommunityModel={handleAddCommunityModel}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
