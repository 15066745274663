import { Dispatch } from "redux";
import {
  GET_ALL_ANALYTICS_REPORTS,
  RESET_DATA,
  START_LOADING_ANALYTICS_REPORTS,
  STOP_LOADING_ANALYTICS_REPORTS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchReportsAnalyticsAction =
  (id: any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))

    dispatch({ type: START_LOADING_ANALYTICS_REPORTS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.reportsAnalyticsFilter
      );

      const { data } = await apiLibrary.Reports.getAllAnalyticsReports(
        queryString,
        id
      );
      dispatch({
        type: GET_ALL_ANALYTICS_REPORTS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log(
        "An error occurred while fetching the report analytics:",
        error
      );
    } finally {
      dispatch({ type: STOP_LOADING_ANALYTICS_REPORTS });
      // dispatch(setLoadingProgress(100));
    }
  };
