import React from "react";
import { snakeToCamel } from "utils/caseConvertor";
import {
  TextField,
  Dropdown,
  MultiselectDropdown,
  RadioButtonGroup,
} from "../../Components";

interface CountDetailsProps {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  handleUpdateStatus: any;
}

const CountDetails: React.FC<CountDetailsProps> = ({
  panel,
  editMode,
  formikProps,
  handleUpdateStatus
}) => {

  const onChange = (eleName: string, value: any, name: string) => {
    formikProps.setFieldValue(eleName, value)
    handleUpdateStatus(name, panel.sectionId)
  }

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mb-5">
        {panelElements.map((element: any, index: number) => {
          const { name, title, choices, fieldAs, rows, value, placeholder, inputType, type, isRequired = false, visible = true, choicesByUrl, defaultValue } = element;
          const eleName = [panelName, element.name].join(".");
          const error = formikProps.errors[panelName]?.[element.name];
          const touched = formikProps.touched[panelName]?.[name];
          if (visible === false || type === "html" || defaultValue) {
            return;
          }
          switch (element.type) {
            case "text":
              return (
                <div className={`${(name === "MinimumCount" || name === "MaximumCount") ? "col-span-6" : "col-span-12"}`}>
                  <TextField
                    key={index}
                    label={title}
                    type={inputType || type}
                    name={name}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) =>
                      onChange(eleName, value, name)

                    }
                    handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
                    fieldAs={fieldAs}
                    rows={rows}
                    editMode={editMode}
                    placeholder={placeholder || `Enter ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />

                </div>
              );
            case "dropdown":
              return (
                <div className="col-span-12">
                  <Dropdown
                    key={index}
                    label={title}
                    name={name}
                    choicesByUrl={choicesByUrl}
                    choices={choices}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(item: any) =>
                      onChange(eleName, item, name)
                    }
                    handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
                    editMode={editMode}
                    placeholder={placeholder || `Select ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );
            default:
              return null; // Or render a default component for unknown types
          }
        })}
      </div>
    </>
  );
};

export default CountDetails;
