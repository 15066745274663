import { useEffect } from "react";

import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";

const useDrawAllGlobalLayersOnMap = (
  layers: any,
  map: any,
  isMapFullyLoaded: boolean,
  draw: any,
  isEditMode: boolean = false
) => {
  useEffect(() => {
    if (isEditMode && layers && map && isMapFullyLoaded) {
      const drewLayers = draw.getAll();

      const layersFeatures = layers
        ?.map((l: any) => {
          if (l.status) {
            const features = l.mapData?.features?.map((f: any) => {
              f.id = generateUniqueId("global#", "#" + l.id);
              f.properties = {
                ...(f.properties ?? {}),
                isEditable: false,
              };
              return f;
            });

            return features;
          }
        })
        ?.flat()
        .filter((f: any) => f);

      drewLayers.features = drewLayers.features.filter(
        (f: any) => !f.id.includes("global")
      );
      drewLayers.features = [...drewLayers.features, ...layersFeatures];

      draw.deleteAll();
      draw.set(drewLayers);

      // Handle clicks on drawn objects
    } else if (map && isMapFullyLoaded && draw) {
      const drewLayers: any = draw.getAll();
      drewLayers.features = drewLayers.features.filter(
        (f: any) => !f.id.includes("global")
      );
      draw.set(drewLayers);
    }
  }, [map, isMapFullyLoaded, layers, isEditMode]);

  return null;
};

export { useDrawAllGlobalLayersOnMap };
