import React, { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

interface CustomAccordionProps {
  children: ReactNode;
  expandIcon: ReactNode;
  detailsClassName?: string;
  summaryClassName?: string;
  heading?: any;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  children,
  expandIcon,
  detailsClassName,
  summaryClassName,
  heading,
}) => {
  return (
    <Accordion
      style={{ boxShadow: "none" }}
      className="px-6 !mt-0 !rounded-lg  dark:bg-secondaryLight"
      defaultExpanded
      
    >
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={`${summaryClassName} dark:bg-secondaryLight`}
      >
        {heading && <p className="text-xl font-semibold capitalize font-Overpass text-left text-textMid dark:text-textMain">
          {heading}
        </p>}
      </AccordionSummary>
      <AccordionDetails className={`${detailsClassName}  dark:bg-secondaryLight`}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
