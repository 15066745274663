import { apiClient } from "../config";

async function updateEndDataViewer(personId: any, data: any) {
  const personData = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName
  };

  const response = await apiClient.put(
    `/admin/users/${personId}`,
    personData
  );

  return response.data;
}

export default updateEndDataViewer;
