import { Dispatch } from "redux";
import { ERROR_LOADING_FILE_DATA, GET_IMPORTED_FILE_DATA, RESET_DATA, START_LOADING_FILE_DATA, STOP_LOADING_FILE_DATA } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchImportsFileDataAction =
  (id: number) => async (dispatch: Dispatch, getState: any) => {
    dispatch({type: START_LOADING_FILE_DATA});
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.importsViewDataFilter
      );
      const { data } = await apiLibrary.Imports.getImportedFileData(id, queryString);
      dispatch({
        type: GET_IMPORTED_FILE_DATA,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({type: ERROR_LOADING_FILE_DATA})
      // dispatch(fetchError(error))
    } finally {
      dispatch({type: STOP_LOADING_FILE_DATA})
      // dispatch(loadingState(false))
    }
  };
