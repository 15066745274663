import { apiClient } from "../config";

async function getAllTypes(queryParams: any) {
  const res = await apiClient.get(`/admin/types`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllTypes;
