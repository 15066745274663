// Components
import Button from "view/components/Button";
import { Toasts } from "view/components/Toasts";

// Store utils
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
// Third party services
import Dropzone from "react-dropzone";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import handleShapeAndKMLTypeFiles from "Components/Geography/SideBar/utils/handleShapeAndKMLTypeFiles";

import usePermissions from "hooks/usePermissions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import structureFormJSON from "../../../../SurveyJs/utils/structureFormJSON";
import apiLibrary from "services/api";
import { dynamicFormsStatusAction } from "store/filters";
import { getSelectedShapesAndDefaultLayers } from "..";
import {
  removeUploadedItemAction,
  setUploadedLayersAction,
} from "store/surveyJS/reducer.actions";
import { IFeatureCollection } from "utils/convertFeatureCollection";
import _ from "lodash";

interface GeoJSONError {
  name: string | null;
  featureCollection: string | null;
}
interface GeoJSONItem {
  name: string;
  error?: string | null;
  featureCollection: any[];
}

interface I_InitialValues {
  geojson: GeoJSONItem[];
}

const validationSchema = Yup.object().shape({
  geojson: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("File Name is required"),
        featureCollection: Yup.object().shape({
          type: Yup.string()
            .required("FeatureCollection type is required")
            .oneOf(["FeatureCollection"], "Type must be 'FeatureCollection'"),
          collectionId: Yup.string().required(
            "A unique collectionId is required"
          ),
          features: Yup.array()
            .required("Features are required")
            .test(
              "is-max-one-item",
              "The uploaded file should contain only one feature.",
              function (features) {
                return features.length === 1;
              }
            )
            .test(
              "is-polygon-or-multipolygon",
              "The feature must be either 'Polygon' or 'MultiPolygon'.",
              function (features) {
                return features.every(function (feature) {
                  const isPolygonOrMultiPolygon =
                    feature.geometry.type === "Polygon" ||
                    feature.geometry.type === "MultiPolygon";
                  return isPolygonOrMultiPolygon;
                });
              }
            ),
        }),
      })
    )
    .required("Upload  files  is required"),
});

interface UploadFormInterface {
  height: string;
  dataSourcesData: any;
  setIsUploadFormEditDataApplied: Dispatch<SetStateAction<boolean>>;
  isUploadFormEditDataApplied: boolean;
}

const UploadForm = ({
  height,
  setIsUploadFormEditDataApplied,
  dataSourcesData,
  isUploadFormEditDataApplied,
}: UploadFormInterface) => {
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const initialValues: I_InitialValues = {
    geojson: [],
  };
  const { formJson, geography } = useSelector<
    RootState,
    I_InitialStateOfSurveyJS
  >((state) => state.surveyJS);
  const surveyState = useSelector((state: RootState) => state.surveyJS);
  const { formDetails } = useSelector(
    (state: RootState) => state.formSurveyJsDetails
  );

  const { selectedUploadedItemTobehighlight } = geography;
  const { communities, dynamicForms } = usePermissions();

  const navigate = useNavigate();

  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    try {
      const mapData = convertValuesToTheFeatureCollection(values);
      if (mapData.features && mapData.features.length === 0) {
        Toasts.error("Upload at least one file for form area");
        return;
      }
      console.log("mapData", mapData);
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        form_area: mapData,
        form_area_mode: "upload",
        form_area_file_name: values.geojson.name,
        status: status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitFormWithoutFormArea = async (
    setSubmitting: Dispatch<SetStateAction<boolean>> | any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    setSubmitting(true);
    try {
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;
      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleMultipleFilesUpload = async (
    files: File[],
    push: any,
    setFieldError: any,
    values: I_InitialValues
  ) => {
    files.forEach(async (file: any, index: any) => {
      const fileIndex = values.geojson.length + index;
      const collectionId = generateUniqueNumberId();

      try {
        const mapData = await handleShapeAndKMLTypeFiles(file);

        const updatedFeatures = mapData.features.map((item: any) => {
          const properties = item.properties ? { ...item.properties } : {};
          properties.fileName = file.name;
          properties.collectionId = collectionId;
          properties.isUploaded = true;
          return { ...item, properties };
        });

        const uploadedFile = {
          name: file.name,
          featureCollection: {
            ...mapData,
            features: updatedFeatures,
            collectionId: collectionId,
          },
        };
        push(uploadedFile);
      } catch (error: any) {
        const errorMessage =
          error.code === "CRS_MISSING"
            ? error.details
            : error?.response?.data ?? error?.message;

        const uploadedFile = {
          name: file.name,
          error: errorMessage,
          featureCollection: null,
        };
        push(uploadedFile);
        setTimeout(() => {
          setFieldError(
            `geojson[${fileIndex}].featureCollection`,
            errorMessage
          );
        }, 400);
      }
    });

    // const uploadResults = await Promise.allSettled(uploadPromises);

    // console.log(
    //   "uploadResults",
    //   uploadResults.forEach((item: any, key: number) => {
    //     console.log("item", item);
    //   })
    // );
    // const failedUploads = uploadResults.filter(
    //   (result) =>
    //     result.status === "rejected" || result.value.status === "rejected"
    // );

    // if (failedUploads.length > 0) {
    //   let errorMessages: any = [];

    //   failedUploads.forEach((failedUpload: any, index: number) => {
    //     console.log("failedUploads", failedUpload);
    //     // const { reason, error, failedFiles, fieldIndex } = failedUpload.value;
    //     // const name = failedFiles?.name;

    //     // const errorMessage = error?.message;
    //     // const detailMessage = error?.details;

    //     // if (reason === "CRS_MISSING") {
    //     //   setFieldError(
    //     //     `regions[${fieldIndex}].featureCollection`,
    //     //     detailMessage
    //     //   );
    //     // }
    //   });

    //   if (errorMessages.length > 0) {
    //     Toasts.error(errorMessages.join(", "));
    //   }
    //   return;
    // }

    // console.log("failedUploads", failedUploads);
    // // Process successful uploads
    // const successfulPayload = uploadResults
    //   .filter(
    //     (result: any) =>
    //       result.status === "fulfilled" && result.value.status === "fulfilled"
    //   )
    //   .map((result: any) => result.value.uploadedFile);

    // console.log("successfulPayload", successfulPayload);
    // let updatedGeoJsons: any[] = [];

    // try {
    //   const filesData = await Promise.all(
    //     files.map((file) => handleShapeAndKMLTypeFiles(file))
    //   );

    //   updatedGeoJsons = files.map((file, index) => {
    //     const mapData = filesData[index];

    //     const collectionId = generateUniqueNumberId();
    //     const updatedFeatures = mapData.features.map((item: any) => {
    //       const properties = item.properties ? { ...item.properties } : {};
    //       properties.fileName = file.name;
    //       properties.collectionId = collectionId;
    //       properties.isUploaded = true;
    //       return { ...item, properties };
    //     });

    //     return {
    //       name: file.name,
    //       featureCollection: {
    //         ...mapData,
    //         features: updatedFeatures,
    //         collectionId: collectionId,
    //       },
    //     };
    //   });

    //   setFieldValue("geojson", updatedGeoJsons);
    // } catch (error: any) {
    //   // Error handling with optional chaining and nullish coalescing
    //   const errorMsg =
    //     error?.response?.data?.message ?? error?.message ?? "An error occurred";
    //   Toasts.error(errorMsg);
    // } finally {
    //   // setSubmitting(false); // Uncomment or adjust according to your application's context
    // }
  };

  const handleSingleFilesUpload = async (
    files: File[],
    fileIndex: number,
    setFieldValue: any,
    setFieldError: any,
    item: any
  ) => {
    if (item?.featureCollection?.collectionId) {
      dispatch(
        removeUploadedItemAction([item?.featureCollection?.collectionId])
      );
    }

    files.forEach(async (file: any, index: any) => {
      const collectionId = generateUniqueNumberId();

      try {
        const mapData = await handleShapeAndKMLTypeFiles(file);

        const updatedFeatures = mapData.features.map((item: any) => {
          const properties = item.properties ? { ...item.properties } : {};
          properties.fileName = file.name;
          properties.collectionId = collectionId;
          properties.isUploaded = true;
          return { ...item, properties };
        });

        const uploadedFile = {
          name: file.name,
          error: null,
          featureCollection: {
            ...mapData,
            features: updatedFeatures,
            collectionId: collectionId,
          },
        };
        setFieldValue(`geojson[${fileIndex}]`, uploadedFile);
      } catch (error: any) {
        const errorMessage =
          error.code === "CRS_MISSING"
            ? error.details
            : error?.response?.data ?? error?.message;

        const uploadedFile = {
          name: file.name,
          error: errorMessage,
          featureCollection: null,
        };
        setFieldValue(`geojson[${fileIndex}]`, uploadedFile);
        setTimeout(() => {
          setFieldError(
            `geojson[${fileIndex}].featureCollection`,
            errorMessage
          );
        }, 500);
      }
    });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        validateOnChange={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldError,
          setSubmitting,
        }) => {
          useEffect(() => {
            if (
              formDetails.formArea &&
              !isUploadFormEditDataApplied &&
              formDetails.formAreaMode === "upload"
            ) {
              const valuesss = convertFeatureCollectionToValues(
                formDetails.formArea
              );
              setFieldValue("geojson", valuesss);
            } else {
              setFieldValue("geojson", []);
            }
          }, []);

          useEffect(() => {
            dispatch(setUploadedLayersAction(values.geojson));
          }, [values]);
          return (
            <Form>
              <div
                className="pb-12"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <p className="text-[15px] text-textMidLight italic mb-6 dark:text-textMain ">
                  Upload one or multiple files containing the polygons to
                  specify the area in which this form should be available to the
                  observers when using the app offline. Beware that each file
                  should only contain one polygon.
                </p>
                <div className="pt-3 overflow-y-auto">
                  <FieldArray name="geojson">
                    {({ push, remove }) => (
                      <div className="w-full">
                        <FilesDragAndDrop
                          push={(files: File[]) => {
                            handleMultipleFilesUpload(
                              files,
                              push,
                              setFieldError,
                              values
                            );
                          }}
                          remove={() => {
                            // setFieldValue("geojson", null);
                          }}
                        />

                        {values?.geojson?.map((item: any, index) => {
                          // Simplify error handling\
                          const geojsonErrors: any = (errors.geojson &&
                            errors.geojson[index]) || {
                            name: "",
                            featureCollection: "",
                          };
                          return (
                            <div key={index}>
                              <div className="pb-1">
                                <div className="relative flex w-full">
                                  <div
                                    className={`w-[93%] flex flex-col items-start `}
                                  >
                                    <FileUpload
                                      handleOnFileUpload={(files: File[]) => {
                                        handleSingleFilesUpload(
                                          files,
                                          index,
                                          setFieldValue,
                                          setFieldError,
                                          item
                                        );
                                      }}
                                      error={geojsonErrors}
                                      touched={true}
                                      values={item}
                                      crsError={item.error}
                                      selectedUploadedItemTobehighlight={
                                        selectedUploadedItemTobehighlight
                                      }
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="absolute right-0 flex items-center justify-center top-4 rounded-3xl"
                                    onClick={() => {
                                      remove(index);
                                      if (
                                        item?.featureCollection?.collectionId
                                      ) {
                                        dispatch(
                                          removeUploadedItemAction([
                                            item.featureCollection.collectionId,
                                          ])
                                        );
                                      }
                                    }}
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                </div>
                <div className="flex justify-between gap-2 pt-2 pb-2 absolute  bottom-[-15px] w-full p-4 bg-bgWhite">
                  <Button
                    type="button"
                    text="Skip"
                    disabled={
                      isSubmitting ||
                      formJson.status === "published" ||
                      !dynamicForms.canEditDynamicFormFields
                    }
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 w-[48.5%]"
                    width="[48.t%]"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={() =>
                      handleSubmitFormWithoutFormArea(setSubmitting)
                    }
                  />
                  <Button
                    type="submit"
                    text="Save"
                    disabled={
                      isSubmitting || !dynamicForms.canEditDynamicFormFields
                    }
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 w-[48.5%]"
                    width="[48.5%]"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { UploadForm };

const FileUpload = ({
  handleOnFileUpload,
  error,
  touched,
  values,
  crsError = null,
  selectedUploadedItemTobehighlight,
}: any) => {
  const featureError =
    crsError !== null
      ? crsError
      : typeof error?.featureCollection === "string"
        ? error.featureCollection
        : error?.featureCollection?.features ?? null;
  const isItemHighlghted =
    selectedUploadedItemTobehighlight ===
    values?.featureCollection?.collectionId;
  return (
    <div className="w-full py-2">
      <Dropzone
        onDrop={handleOnFileUpload}
        multiple={true}
        accept={ACCEPTED_FILES_TYPES}
      >
        {({ getRootProps, getInputProps }) => {
          const fileName = values
            ? values.name
            : "Upload GeoJson, Shape or Zipped Shapefiles, or KML File ";

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={` ${
                  isItemHighlghted
                    ? "border-2 border-primary"
                    : featureError
                      ? "border border-accent_1Dark"
                      : "border border-lineDark dark:border-lineLight"
                } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p
                  className={`text-[17px]  ${isItemHighlghted ? "text-primary" : "text-textLightExtra"} overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain`}
                >
                  {fileName}
                </p>
                <CloudUploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {featureError && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {featureError}
        </p>
      )}
    </div>
  );
};

const ACCEPTED_FILES_TYPES = {
  "application/geo+json": [".geojson"],
  "application/vnd.google-earth.kml+xml": [".kml"],
  "application/octet-stream": [".shp"],
  "application/zip": [".zip"],
};

const FilesDragAndDrop = ({ push, remove }: any) => {
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      push(acceptedFiles);
    },
    [push]
  );

  return (
    <Dropzone onDrop={handleOnDropFiles} accept={ACCEPTED_FILES_TYPES}>
      {({ getRootProps, getInputProps }) => (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="flex flex-col items-center self-stretch justify-center flex-grow-0 flex-shrink-0 px-4 py-2 bg-white border border-dashed rounded-lg hover:cursor-pointer border-lineDark"
          >
            <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <input {...getInputProps()} />
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg ">
                <CloudUploadIcon />
              </div>
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                <div className="flex justify-center items-center flex-grow  px-1 pt-1.5 pb-2">
                  <p className="flex-grow w-[368px] text-sm text-center text-textMid dark:text-textMain">
                    Drag and drop your GeoJSON , Shape Or Kml files here or
                    click in this area
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dropzone>
  );
};

function generateUniqueNumberId() {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000);
  const uniqueId = timestamp * 1000 + randomNum;
  return uniqueId;
}

function convertValuesToTheFeatureCollection(values: I_InitialValues) {
  const updatedFeatures = values.geojson.reduce((acc: any, item: any) => {
    acc.push(item.featureCollection.features[0]);
    return acc;
  }, []);

  return {
    type: "FeatureCollection",
    features: updatedFeatures,
  };
}

// function convertValuesToTheFeatureCollection(values: I_InitialValues) {
//   const updatedFeatures = values.geojson.reduce((acc: any, item: any) => {
//     acc.push(...item.featureCollection.features);
//     return acc;
//   }, []);

//   return {
//     type: "FeatureCollection",
//     features: updatedFeatures,
//   };
// }

function convertFeatureCollectionToValues(
  featureCollection: IFeatureCollection
) {
  const updatedFeatures = featureCollection?.features?.map((item) => {
    const createdFeatureCollection = {
      type: "FeatureCollection",
      collectionId: item.properties.collectionId,
      features: [item],
    };
    const updatedItem = {
      name: item.properties.fileName ?? "",
      featureCollection: createdFeatureCollection,
    };

    return updatedItem;
  });
  return updatedFeatures.length > 0 ? updatedFeatures : [];
}

// function convertFeatureCollectionToValues(
//   featureCollection: IFeatureCollection
// ) {
//   const groupedFeatures = _.groupBy(featureCollection.features, (feature) => {
//     return feature.properties.collectionId;
//   });

//   const updatedFeatureCollections = Object.entries(groupedFeatures).map(
//     ([collectionId, features]) => {
//       const createdFeatureCollection = {
//         type: "FeatureCollection",
//         collectionId: collectionId,
//         features: features,
//       };

//       const updatedItem = {
//         name: features[0].properties.fileName ?? "",
//         featureCollection: createdFeatureCollection,
//       };

//       return updatedItem;
//     }
//   );

//   return updatedFeatureCollections;
// }
