import getQAProgress from "./getQAProgress";
import getActiveUsers from "./getActiveUsers";
import getTotalCounts from "./getTotalCounts";
import getClusteredMap from "./getClusteredMap";
import getObservations from "./getObservations";
import getNotifications from "./getNotifications";
import getQANotifications from "./getQANotifications";
import getDashboardConfiguration from "./getConfiguration";
import getHorizontalBarGraph from "./getHorizontalBarGraph";
import getMostObservedObjects from "./getMostObservedObjects";
import getMostObservedSpecies from "./getMostObservedSpecies";
import getReportNotifications from "./getReportNotifications";
import getObservationsByRegion from "./getObservationsByRegion";
import getCumulativeObservations from "./getCumulativeObservations";
import saveDashboardConfiguration from "./saveConfiguration";

const Dashboard = {
  configuration: {
    getConfiguration: getDashboardConfiguration,
    saveConfiguration: saveDashboardConfiguration,
  },
  widgets: {
    getTotalCounts,
    getQAProgress,
    getActiveUsers,
    getClusteredMap,
    getObservations,
    getNotifications,
    getQANotifications,
    getHorizontalBarGraph,
    getMostObservedObjects,
    getMostObservedSpecies,
    getReportNotifications,
    getObservationsByRegion,
    getCumulativeObservations,
  },
};
export default Dashboard;
