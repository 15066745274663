import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeImageLightBoxModalAction } from "store/modals/reducer.actions";
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";

export function ImageLightBox() {
  const [imageSlides, setImageSlides] = useState<
    {
      src: string;
      width: number;
      height: number;
      srcSet: { src: string; width: number; height: number }[];
    }[]
  >([]);

  const { imageLightBoxModal } = useSelector(
    (state: RootState) => state.modals
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeImageLightBoxModalAction());
  };

  function generateSlideWithCustomPath(
    width: number,
    height: number,
    filePath: string
  ): {
    src: string;
    width: number;
    height: number;
    srcSet: { src: string; width: number; height: number }[];
  }[] {
    const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
    return [
      {
        src: filePath,
        width: width * 10 || 3840,
        height: height  || 2560,
        srcSet: breakpoints.map((breakpoint: number) => {
          const breakpointHeight = Math.round((height / width) * breakpoint);
          return {
            src: filePath,
            width: breakpoint,
            height: breakpointHeight,
          };
        }),
      },
    ];
  }

  useEffect(() => {
    if (imageLightBoxModal.isOpen) {
      const { width, height, filePath } = imageLightBoxModal.data;

      const slides = generateSlideWithCustomPath(width, height, filePath);

      setImageSlides(slides);
    }
  }, [imageLightBoxModal.isOpen, imageLightBoxModal.data]);

  return (
    <>
      <Lightbox
        open={imageLightBoxModal.isOpen}
        close={handleClose}
        slides={imageSlides}
        plugins={[]}
        // styles={{
        //   touchAction: "none",
        // }}
        render={{
          iconPrev: () => null,
          iconNext: () => null,
        }}
      />
    </>
  );
}
