import React, { useState } from "react";

interface SeeMoreTextProps {
  text: string;
}

export const SeeMoreText: React.FC<SeeMoreTextProps> = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const maxChars = 250;

  return (
    <>
      <div className="flex align-center gap-1 justify-items-start">
        <p className="text-sm font-normal text-secondaryMid dark:text-textMain ">Organizations: </p>
        <p className="break-all text-sm font-normal text-secondaryMid dark:text-textMain ">
          {` ${showFullText ? text : text?.slice(0, maxChars)}`}
        </p>
      </div>

      {text?.length > maxChars && (
        <button
          onClick={toggleText}
          className="flex items-center pr-1 text-sm font-semibold underline text-secondaryMid dark:text-textMain"
        >
          {showFullText ? "See less" : "See more"}
        </button>
      )}
    </>
  );
};
