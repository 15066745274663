import React from "react";
import Button from "view/components/Button";
import CloseIcon from "../Configuration/CloseIcon";
import { useDispatch } from "react-redux";
import {
  closeDashboardAlertConfirmationModal,
  closeDashboardAlertModal,
  openDashboardAlertConfirmationModal,
} from "store/modals";
import { RootState } from "store";
import { useSelector } from "react-redux";

interface IProps {
  toggleConfigurationModal: () => void;
}

const WelcomeAlert = (props: IProps) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);

  const { dashboardAlertConfirmationModal } = useSelector(
    (state: RootState) => state.modals
  );

  const confirmAlert = () => {
    dispatch(openDashboardAlertConfirmationModal());
  };

  // CLOSE ALERT AND OPEN MODAL
  const toggleConfigurationModal = () => {
    dispatch(closeDashboardAlertModal());
    props.toggleConfigurationModal();
  };

  // SHOW AND HIDE ALERT WITH LOCAL STORAGE
  React.useEffect(() => {
    const dashboardWelcomeAlert = localStorage.getItem("dashboardWelcomeAlert");
    if (dashboardWelcomeAlert !== "false") {
      // SHOW ALERT
      setTimeout(() => {
        setVisible(true);
      }, 500);
    }
  }, []);

  // CLOSING ALERT BOX AFTER CONFIRMATION
  React.useEffect(() => {
    if (dashboardAlertConfirmationModal.isWelcomeAlertOpen === false) {
      setVisible(false);
    }
  }, [dashboardAlertConfirmationModal.isWelcomeAlertOpen]);

  if (visible) {
    return (
      <div
        className={`my-5 w-full dashboard-widget px-4 py-5 rounded-lg bg-bgWhite transition-all  dark:bg-secondaryLight dark:border dark:border-lineLight ${
          visible ? "visible" : "hidden"
        }`}
      >
        <div className="w-full flex items-center justify-between">
          <div className="w-full flex flex-col mr-2">
            <h6 className="mb-2 text-lg font-semibold leading-6 dark:text-textMain font-Overpass">
              Welcome to your personal dashboard!
            </h6>
            <p className="w-full font-Overpass dark:text-caption text-[13px] font-normal leading-4">
              Click on this widget (or the configurations button) to set up your
              own dashboard, making sure you only see the information that is
              relevant to you!
            </p>
          </div>
          <div className=" w-3/4 flex items-center justify-end">
            <Button
              type="button"
              text="Set up my own dashboard"
              filledColor="primary"
              textColor="textWhite"
              className="h-10 p-2 px-5 mr-5 font-semibold hover:bg-primaryMid"
              variant="filled"
              onClick={toggleConfigurationModal}
            />
            <button onClick={confirmAlert}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default WelcomeAlert;
