export interface IAdminTracking {
  id: number;
  action: string;
  adminName: string;
  entityName: string;
  actionType: string;
  dateAdded: string;
  profileImage:string,
}

export interface IAdminTrackingData {
  fields: any[];
  totalPages: number;
  totalItems: number;
  adminTracking: IAdminTracking[];
}

export interface IFetchAdminTracking {
  data: IAdminTrackingData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IFetchAdminTracking = {
  data: {
    fields: [],
    totalPages: 0,
    totalItems: 0,
    adminTracking: [],
  },
  message: "Data retrieved successfully.",
  stateIs: "Idle",
};

export default initialState;
