import React from "react";
import { camelCase } from "change-case";
import { sentenceCase } from "change-case";

interface StatusProps {
  status: string;
}

interface StatusStyle {
  name: string;
  backgroundColor: string;
  textColor: string;
}

const getStatusStyle = (status: string): StatusStyle => {
  const lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    case "active":
      return {
        name: "Active",
        backgroundColor: "bg-tertiaryExtraLight",
        textColor: "text-tertiary",
      };
    case "paused":
      return {
        name: "Paused",
        backgroundColor:
          "bg-secondaryMid bg-opacity-10 dark:bg-secondaryMidLight",
        textColor: "text-secondaryMid dark:text-caption",
      };
    case "completed":
      return {
        name: "Completed",
        backgroundColor: "bg-tertiaryExtraLight",
        textColor: "text-tertiary",
      };
    case "reinvited":
      return {
        name: "Re-invited",
        backgroundColor: "bg-bgHighMidlight",
        textColor: "text-primary",
      };
    case "invited":
      return {
        name: "Invited",
        backgroundColor: "bg-bgHighMidlight",
        textColor: "text-primary",
      };
    case "inactive":
      return {
        name: "Inactive",
        backgroundColor: "bg-secondaryMidLight bg-opacity-10",
        textColor: "text-secondaryMidLight",
      };
    case "deleted":
      return {
        name: "Deleted",
        backgroundColor: "bg-accent_2Light",
        textColor: "text-accent_2",
      };
    case "suspended":
      return {
        name: "Suspended",
        backgroundColor: "bg-accent_2Light",
        textColor: "text-accent_2",
      };
    case "archived":
      return {
        name: "Archived",
        backgroundColor: "bg-secondary_25",
        textColor: "text-textMid",
      };
    case "confirmationpending":
      return {
        name: "Unconfirmed",
        backgroundColor: "bg-bgGreyish",
        textColor: "text-textMid",
      };
    case "virtual":
      return {
        name: "Virtual",
        backgroundColor:
          "bg-secondaryMid bg-opacity-10 dark:bg-bgHighMidlight ",
        textColor: "text-secondaryMid",
      };

    case "draft":
      return {
        name: "Draft",
        backgroundColor: "bg-accent_1Light",
        textColor: "text-accent_1Dark",
      };
    default:
      return {
        name: sentenceCase(status),
        backgroundColor: "bg-secondaryMid bg-opacity-10",
        textColor: "text-secondaryMid",
      };
  }
};

const StatusContainer: React.FC<{
  status: StatusStyle;
  children?: React.ReactNode;
}> = ({ status, children }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1.5 py-0.5 rounded ${status.backgroundColor}`}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 py-1">
          <p
            className={`flex-grow-0 flex-shrink-0 text-xs text-center ${status.textColor}`}
          >
            {children}
          </p>
        </div>
      </div>
    </div>
  );
};

const Status: React.FC<StatusProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  const camelCaseStatus = camelCase(status);
  const statusStyle = getStatusStyle(camelCaseStatus);

  return (
    <StatusContainer status={statusStyle}>{statusStyle.name}</StatusContainer>
  );
};

export default Status;
