import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { PageHeading } from "./components";

import apiLibrary from "services/api";
import { useNavigate } from "react-router-dom";
import { pollsAndSurveysStatusAction } from "store/filters/reducer.actions";
import { useDispatch } from "react-redux";
import usePermissions from "hooks/usePermissions";
import { openPollsAndSurveysExpiryDateModal } from "store/modals/reducer.actions";

const generateDynamicUserLinks = (surveyDetails: any) => {
  return [
    { path: `/polls-and-surveys/list`, label: `Survey` },
    { path: `/polls-and-surveys/${surveyDetails?.id}/edit`, label: `Edit` },
    { path: ``, label: surveyDetails?.name },
  ];
};

export const Header = ({ creator, surveyDetails }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const dynamicUsersLinks = generateDynamicUserLinks(surveyDetails);

  // handle Click on Publish button
  const handleClickOnPublishOrSaveAsDraftsBtn = async (status: string) => {
    let jsonData = { ...creator.JSON };

    // if (jsonData) {
    //   delete jsonData.logoPosition;
    // }

    // const isError = validation(jsonData);

    // if (isError) {
    //   return;
    // }
    if (status === "published") {
      dispatch(
        openPollsAndSurveysExpiryDateModal({
          surveyAndPollsId: surveyDetails.id,
          payload: {
            survey_fields: jsonData,
            status: status,
          },
          tokenValidTill: surveyDetails.tokenValidTill,
        })
      );
      return;
    }
    setLoading(true);
    try {
      const res = await apiLibrary.pollsAndSurveys.updateSurveyFields(
        surveyDetails.id,
        {
          survey_fields: jsonData,
          status: status,
        }
      );

      Toasts.success(res.message);
      dispatch(pollsAndSurveysStatusAction(status));
      navigate(-1);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };
  const { surveyAndPolls } = usePermissions();

  return (
    <div className="w-full pt-4 pb-6 ">
      <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex items-start self-stretch justify-start flex-grow">
          <div className="flex flex-col items-start justify-start flex-grow gap-2">
            <Breadcrumb links={dynamicUsersLinks} />
            <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <PageHeading communityId={""} name={surveyDetails.name} />

              {/* {surveyAndPolls.canEditFieldsSurveyAndPolls && ( */}
              <div className="relative flex items-center justify-end gap-2 ">
                <button
                  onClick={() => handleClickOnPublishOrSaveAsDraftsBtn("draft")}
                  disabled={!surveyAndPolls.canEditFieldsSurveyAndPolls}
                  className={`${
                    loading ? "opacity-50 cursor-progress" : ""
                  } flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary  hover:bg-primaryExtraLight cursor-pointer`}
                >
                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
                    <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary">
                      Save as Draft
                    </p>
                  </div>
                </button>
                <button
                  onClick={() =>
                    handleClickOnPublishOrSaveAsDraftsBtn("published")
                  }
                  disabled={!surveyAndPolls.canEditFieldsSurveyAndPolls}
                  className={`${
                    loading ? "opacity-50 cursor-progress" : ""
                  } flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary bg-primary hover:bg-primaryDark cursor-pointer`}
                >
                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
                    <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-textWhite">
                      Publish
                    </p>
                  </div>
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function validation(jsonData: any) {
  let isError = false;
  if (!jsonData.pages) {
    Toasts.error("Please add at least one question");
    isError = true;
  } else if (!jsonData?.pages[0]?.elements) {
    Toasts.error("Please add at least one question");
    isError = true;
  } else {
    // Iterate through each element within the first page
    for (const element of jsonData.pages[0]?.elements) {
      if (element.type === "panel") {
        // Check if "panel" has elements
        if (!element.elements || element.elements.length === 0) {
          Toasts.error(
            `Panel with name "${element.name}" should have at least one element.`
          );

          isError = true;
        }
      } else if (element.type === "paneldynamic") {
        // Check if "paneldynamic" has template elements
        if (
          !element.templateElements ||
          element.templateElements.length === 0
        ) {
          Toasts.error(
            `Dynamic panel with name "${element.name}" should have at least one question.`
          );

          isError = true;
        }
      }
    }
  }

  return isError;
}
