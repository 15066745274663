import ViewBoardsIcon from "assets/icons/HeroIcons/ViewBoardsIcon";
import { ColumnBtnProps } from "../../types";

const ColumnBtn = ({ isOpen, setIsOpen }: ColumnBtnProps) => {
  return (
    <button
      onClick={() => setIsOpen(true)}
      className={`relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded-lg ${
        isOpen ? "bg-primary/[0.08]" : "hover:bg-primary/[0.08]"
      } `}
    >
      <ViewBoardsIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="#2C3236"
      />
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid dark:text-inputText">
          Columns
        </p>
      </div>
    </button>
  );
};

export default ColumnBtn;
