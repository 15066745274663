import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { GET_QA_PROGRESS, GET_QA_PROGRESS_STATE } from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- QA PROGRESS
 * @returns
 */
export const fetchQAProgress =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_QA_PROGRESS_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.datePeriod) {
        Object.assign(params, {
          date_from: params.datePeriod.from,
          date_to: params.datePeriod.to,
        });
        delete params.datePeriod;
      }
      const { data } = await apiLibrary.Dashboard.widgets.getQAProgress(params);
      dispatch({
        type: GET_QA_PROGRESS,
        payload: {
          data: {
            unAssigned: {
              ...dummyData.unAssigned,
              value: data?.unAssigned || 0,
            },
            inReview: { ...dummyData.inReview, value: data?.inReview || 0 },
            completed: { ...dummyData.completed, value: data?.completed || 0 },
          },
        },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_QA_PROGRESS_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log("An error occurred while fetching the qa progress:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };

const dummyData = {
  inReview: { id: 3, value: 50, label: "In review", color: "#009F88" },
  completed: { id: 2, value: 30, label: "Completed", color: "#F89755" },
  unAssigned: { id: 1, value: 20, label: "Unassigned", color: "#E9E9E9" },
};
