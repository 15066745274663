import { GET_COMMUNITY_PROFILE, RESET_COMMUNITY_PROFILE } from "./action-types";
import { initialState } from "./initialState";

const communityReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case GET_COMMUNITY_PROFILE:
      return action.payload;
    case RESET_COMMUNITY_PROFILE:
      return initialState;
    default:
      return state;
  }
};

export default communityReducer;
