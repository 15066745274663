export interface IAppInfo {
  id: string;
  name: string;
  image: {
    large: string;
    original: string;
    thumb: string;
  };
  unreadCount: number;
}

export interface IRecipient {
  archived: boolean;
  firstName: string;
  id: number;
  lastMessageAt: string;
  lastMessageText: string;
  lastMessageType: string;
  lastName: string;
  muted: boolean;
  personImage: string;
  unreadCounter: number;
  userId: number;
  conversationCreatedAt: string;
  appName: string;
  fileName: string;
  messageType: string;
}
export interface IRecipientData {
  appsInfo: IAppInfo[];
  recipients: IRecipient[];
  contacts: string[];
  archived: string[];
}
export interface IfetchRecipients {
  data: IRecipientData;
  isLoading: boolean;
  message: string;
}

export const initialRecipientsState: IfetchRecipients = {
  data: {
    appsInfo: [],
    recipients: [],
    contacts: [],
    archived: [],
  },
  isLoading: false,
  message: '',
};

// Contact Persons Initial State
export interface IContactPerson {
  id: number;
  firstName: string;
  lastName: string;
  personImage: string;
}

export interface IContactPersonData {
  contactPersons: IContactPerson[];
  totalPages: number;
  totalItems: number;
}

export interface IfetchContactPersons {
  data: IContactPersonData;
  message: string;
}

export const initialContactPersonsState: IfetchContactPersons = {
  data: {
    contactPersons: [],
    totalPages: 1,
    totalItems: 0,
  },

  message: '',
};
