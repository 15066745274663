import React, { useEffect, useState } from "react";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import BpCheckbox from "view/components/CheckBox";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import useDateRange from "hooks/useDateRange";
import { reportsDateAddedUpdatedLastNextAction } from "store/filters/reducer.actions";
import { formatTheDates } from "utils/formatTheDates";

interface NextReportsFilterProps {
  name: any;
  from: string;
  to: string;
}

export const NextReportsFilter: React.FC<NextReportsFilterProps> = ({
  from,
  to,
  name,
}) => {
  const dispatch = useDispatch();
  const [checkLabelsState, setCheckLabelState] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>();
  const {
    getDateOption,
    calculateDate,
    getFutureDateOption,
    calculateFutureDate,
  } = useDateRange();
  const formatDate = (date: any) => moment(date).format(DATE_FORMATS.DEFAULT);
  const handleCheckboxChange = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      let dates =
        name === "nextRun" ? calculateFutureDate(value) : calculateDate(value);
      handleDateChange(dates.startDate, dates.endDate);
    } else {
      handleDateChange("", "");
    }
  };

  const handleDateInputChange = (
    value: string,
    field: "startDate" | "endDate"
  ) => {
    const selectedDate = new Date(value);
    const formattedDate = formatDate(selectedDate);

    if (field === "startDate") {
      if (formattedDate <= to || !to) {
        // Check if the start date is less than or equal to the end date (or if end date is empty).
        handleDateChange(formattedDate, to);
      } else {
        // Display an error message or handle the error in another way.
        console.error("Start date cannot be greater than end date.");
      }
    } else {
      if (from <= formattedDate || !from) {
        // Check if the end date is greater than or equal to the start date (or if start date is empty).
        handleDateChange(from, formattedDate);
      } else {
        // Display an error message or handle the error in another way.
        console.error("End date cannot be less than start date.");
      }
    }
  };
  const handleDateChange = (startDate: any, endDate: any) => {
    dispatch(reportsDateAddedUpdatedLastNextAction(name, startDate, endDate));
  };
  useEffect(() => {
    const checkboxLabelsNext = [
      "Tomorrow",
      "This week",
      "Next week",
      "This month",
      "Next month",
      "Next 30 days",
      "Next 90 days",
      "Next 12 months",
      "This year",
      "Next year",
    ];

    const checkboxLabels = [
      "Today",
      "Yesterday",
      "This week",
      "Last week",
      "This month",
      "Last month",
      "Last 30 days",
      "Last 90 days",
      "Last 12 months",
      "This year",
      "Last year",
    ];
    setCheckLabelState(
      name === "nextRun" ? checkboxLabelsNext : checkboxLabels
    );

    const option =
      name === "nextRun"
        ? getFutureDateOption(from, to)
        : getDateOption(from, to);
    setSelectedOption(option);
  }, [name, from, to]);

  return (
    <div className="flex py-2 pl-2 shadow-xl bg-bgWhite dark:bg-secondaryLight rounded-xl">
      <div className="w-[255px] flex flex-col justify-start items-start">
        {checkLabelsState?.map((label: any) => (
          <div
            key={label}
            className="flex items-center justify-start pl-1 capitalize rounded-lg"
          >
            <BpCheckbox
              label={label}
              value={label}
              labelPlacement="end"
              checked={label === selectedOption}
              onChange={handleCheckboxChange}
            />
          </div>
        ))}
      </div>
      <div className="border-r-0 border-b-0 border-t-0 border-l border-lineMid w-[255px] flex flex-col justify-start items-start p-3">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <InputField
              type="date"
              label="Start Date"
              className={"uppercase z-40"}
              placeholder="YYYY/MM/DD"
              value={from}
              onChange={(event: any) =>
                handleDateInputChange(event, "startDate")
              }
              min={formatTheDates(null, {
                future: true,
                dayCount: 1,
                utc: true,
              })}
              max={to ? formatTheDates(to) : null}
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <InputField
              type="date"
              label="End Date"
              className={"uppercase  z-40"}
              placeholder="YYYY/MM/DD"
              value={to}
              min={
                from
                  ? formatTheDates(from)
                  : formatTheDates(null, {
                      future: true,
                      dayCount: 1,
                      utc: true,
                    })
              }
              onChange={(event: any) => handleDateInputChange(event, "endDate")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
