import React from "react";
import {
  Filters,
  Header,
  Pagination,
  Table,
} from "Components/NotificationRecipients";
import { NotificationsRecipientsTable, ProtocolsTable } from "hooks/useTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { notificationsRecipientsResetFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchNotificationsRecipients } from "store/notificationsRecipients/initialState";
import { fetchNotificationsRecipientsAction } from "store/notificationsRecipients";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";

export const NotificationRecipients = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { notificationId } = useParams();
  const { data, stateIs } = useSelector<
    RootState,
    IfetchNotificationsRecipients
  >((state) => state?.notificationRecipients);
  const { notificationsRecipientsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setSelectAll,
    setSelectedRows,
    setSorting,
    sorting,
  } = NotificationsRecipientsTable(data?.members);

  // Initializing event listener
  useEffect(() => {
    return () => {
      dispatch(notificationsRecipientsResetFiltersAction());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchNotificationsRecipientsAction(notificationId));
  }, [notificationsRecipientsFilter]);

  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 py-5">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters columns={columnsConfig} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs === "Idle" && data?.members?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              isRowSelected={isRowSelected}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              setSelectedRows={setSelectedRows}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
