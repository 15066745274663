import { apiClient } from "../config";

async function getSurveyDetails(id: string) {

  const res = await apiClient.get(`/admin/survey-and-polls/${id}`);

  return res.data;
}

export default getSurveyDetails;
