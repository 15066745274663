import { CustomSelector } from "../index";
import { ItemsPerPageSelectorProps } from "../../types";

export const ItemsPerPageSelector = ({
  limit,
  handleChangeItemsPerPage,
}: ItemsPerPageSelectorProps) => {
  return (
    <div className="flex items-center justify-start flex-grow">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-20">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
          <div className="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 gap-1.5 pl-4 px-1 rounded dark:bg-bgtetriary dark:text-inputText dark:border-lineLight border-2 border-lineDark relative pt-0.5">
            <CustomSelector
              options={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                // { value: 500, label: "500" },
                // { value: 1000, label: "1000" },
              ]}
              value={limit}
              onChange={handleChangeItemsPerPage}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-3 py-1 rounded-lg">
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-[15px] text-center text-textMid dark:text-caption ">
            Items Per Page
          </p>
        </div>
      </div>
    </div>
  );
};
