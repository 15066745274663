import { useEffect } from "react";
import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";

const useDrawShapesOnMap = (draw: any, answer: any,doShapesEditable:boolean) => {


  useEffect(() => {
    if ( answer && Array.isArray(answer) && draw) {
        draw.deleteAll();
      answer.forEach((ans: any) => {
        const id = ans.id ?? generateUniqueId("draw#");        
        __drawShapeByGeoJsonOnMap__(draw, id, ans.name, ans.mapData, doShapesEditable);
      });
    }
  }, [draw,doShapesEditable]);

  return null;
};

export { useDrawShapesOnMap };

function __drawShapeByGeoJsonOnMap__(draw: any, drawId: any, name: string, geoJson: any, doShapesEditable:boolean) {
  if (!draw || !drawId || !geoJson) {
    return;
  }
  // get previous drawn shapes features and append new into it
  const currentFeatures = draw.getAll();

  const comingFeatures = geoJson?.features?.map((f: any,index:number) => {
    
    
    if (!f.type) {
      f.type = "Feature";
    };
    if (!f.properties) {
      f.properties = {};
    };
    if (!f.id) {
      f.id =
        `feature#${Math.random().toString(36).substr(2, 9)}#${drawId}`;
    };

    f.properties['isEditable'] = doShapesEditable;
    f.properties['name'] = name ?? `Polygon ${index}`;
    return f;
  });

  const isFeatureIdPresent = (feature: any, currentFeatures: any) => {
    return currentFeatures.some(
      (currentFeature: any) => currentFeature.id === feature.id
    );
  };

  const uniqueFeatures = comingFeatures.filter(
    (feature: any) => !isFeatureIdPresent(feature, currentFeatures.features)
  );

  currentFeatures.features = [...currentFeatures.features, ...uniqueFeatures];


  draw.set(currentFeatures);
}

export { __drawShapeByGeoJsonOnMap__ };
