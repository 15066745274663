import PlusCircleIcon from "assets/icons/HeroIcons/PlusCircleIcon";
import React from "react";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";

interface UserProps {
  userData: any;
  setSelectedUser: any;
  addAdmin: any;
  // handleClick?: any;
}

export const Users: React.FC<UserProps> = ({
  userData,
  setSelectedUser,
  addAdmin,
}) => {
  return (
    <div className="overflow-y-auto user-list max-h-12">
      <div className="flex justify-between items-center relative gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
        <div
          onClick={() => addAdmin(userData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          {userData.personImage !== null ? (
            <img
              className="w-8 h-8 rounded-full"
              src={userData?.personImage}
              alt=""
            />
          ) : (
            <ListImagePlaceholder />
          )}
          <p className="ml-3 dark:text-textMain">{`${userData.name}`}</p>
        </div>
        <button type="button" onClick={() => addAdmin(userData)}>
          <PlusCircleIcon />
        </button>
      </div>
    </div>
  );
};
