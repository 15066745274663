import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useNavigate, useParams } from "react-router-dom";

import { sentenceCase } from "change-case";
import { Toasts } from "view/components/Toasts";

const generateDynamicLinks = (notificationId: any, name: any, label?: any) => {
  switch (true) {
    case Boolean(notificationId):
      return [
        { path: `/custom-notifications/list`, label: `Custom notifications` },
        {
          path: `/custom-notifications/${notificationId}/status`,
          label: name,
        },
        {
          path: `/custom-notifications/${notificationId}/status`,
          label: `Custom notifications status`,
        },
      ];

    default:
      return [
        { path: `/custom-notifications/list`, label: `Notifications` },
          
      ];
  }
};

export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    title: "",
    id: "",
    showAddButton: false,
  });
  const navigate = useNavigate();
  const { notificationId } = useParams<{
    notificationId: string;
  }>();

  const dynamicLinks = generateDynamicLinks(
    notificationId ?? "",
    BreadcrumbData?.title ?? "",
  );

  useEffect(() => {
    if (notificationId) {
      getNotificationsStatusDetails(notificationId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          // navigate("/notifications/list");
        });
    }
  }, [notificationId]);
  // Handlers
  const handleBackButton = () => {
    navigate("/custom-notifications/list");
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              notificationId={notificationId ? notificationId : ""}
              name={BreadcrumbData?.title}
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <div
                onClick={handleBackButton}
                className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition"
              >
                <div className="flex justify-center items-center py-1">
                  <button className="text-base font-semibold text-center text-primary">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

async function getNotificationsStatusDetails(notificationId: string) {
  try {
    const { data } =
      await apiLibrary.NotificationsSummary.getDetailsofNotificationsummary(
        notificationId ? notificationId : ""
      );
    return data;
  } catch (error: any) {
    throw error;
  }
}