import { Ifetch } from "../initialState";

export interface ICumulativeObservations {
  day?: string;
  week?: string;
  month?: string;
  year?: string;
  todayCount?: number;
  yesterdayCount?: number;
  currentWeekCount?: number;
  lastWeekCount?: number;
  currentMonthCount?: number;
  lastMonthCount?: number;
  currentYearCount?: number;
  lastYearCount?: number;
}

export interface IfetchCumulativeObservations extends Ifetch {
  data: ICumulativeObservations[];
  maxYAxix: number;
  totalCount: number;
}

const cumulativeObservationsInitialState: IfetchCumulativeObservations = {
  data: [],
  message: "",
  maxYAxix: 0,
  totalCount: 0,
  stateIs: "Pending",
};
export default cumulativeObservationsInitialState;
