import React, { useEffect, useState } from "react";

const useFetchReportsVersionsRegionLayers = (currentSelectedCommunity: string | null, sliceData?: any) => {
  const [layers, setLayers] = useState<null | any[]>(null);
  useEffect(() => {
    if (currentSelectedCommunity) {
      sliceData.map((el: any,index: any) => {
        if(index === currentSelectedCommunity){
          setLayers([
              {
                  color: null,
                  id: index,
                  mapData: {
                      features: [
                          {
                              geometry: {
                                  "coordinates": [
                                      [Number(el.longitude), Number(el.latitude)]
                                  ],
                                  type: "Point"
                              },
                              properties: {},
                              type: "Feature"
                          }
                      ],
                      type: "FeatureCollection"
                  },
                  type: "region"
              },
          ])
        }
      })
    };
  }, [currentSelectedCommunity]);

  return layers;
};

export { useFetchReportsVersionsRegionLayers };
