import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import GeographyListView from "./List";
import GeographyMap from "./Map";
import { useEffect } from "react";
import { geographyViewTypeAction } from "store/filters";
import { useDispatch } from "react-redux";
import usePermissions from "hooks/usePermissions";

export const AdminGeography = () => {
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(geographyViewTypeAction("map"));
    };
  }, []);
  if (geographyFilter.viewType === "map") {
    return <GeographyMap />;
  } else {
    return <GeographyListView />;
  }
};

export default AdminGeography;
