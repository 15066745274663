export interface IProgram {
  communitiesCount: number;
  communityName: string[];
  connectedForms: number;
  dateAdded: string;
  endDate: string;
  image: {
    file: {
      original: string;
      thumb: string;
    };
    id: number;
    path: string;
  };
  type: string;
  members: number;
  numberOfObservations: number;
  organizationName: string[];
  organizationsCount: number;
  programCode: string;
  programId: number;
  programName: string;
  protocol: number;
  startDate: string;
  status: string;
  timeframe: string;
  pausedSince: string;
  openAccess: boolean;
  dataAccessPolicy: string;
  canAddAdmin: boolean;
}

export interface IprogramsData {
  programs: IProgram[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchPrograms {
  data: IprogramsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialProgramsData: IfetchPrograms = {
  data: {
    programs: [],
    totalItems: 0,
    totalPages: 0,
  },

  message: "",
  stateIs: "Idle",
};

export default initialProgramsData;
