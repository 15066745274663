import { Radio } from "@mui/material";

interface PrimaryAddressTogglerProps {
  onClick: () => void;
  currentAddressIndex: number;
  address: any;
}

export const PrimaryAddressToggler: React.FC<PrimaryAddressTogglerProps> = ({
  onClick,
  currentAddressIndex,
  address,
}) => {
  return (
    <Radio
      sx={{
        "&.Mui-checked": { color: "#024C6F" },
        "&:hover": { backgroundColor: "transparent" },
        "&:focus": {
          backgroundColor: "none",
          boxShadow: "none",
        },
      }}
      disabled={address.address === ""}
      onClick={onClick}
      id={`primary-${currentAddressIndex}`}
      checked={address.isPrimary}
      className="w-5 h-5 border-gray-300 disabled:cursor-not-allowed focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 focus:outline-none focus:ring-0"
    />
  );
};
