import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

interface FileUploadProps {
  label: string;
  error?: any;
  name: string;
  editMode?: boolean;
  value?: any;
  data: any[];
  touched?: any;
  handleOnDropFiles: any;
  acceptedFileTypes?: any;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  label,
  error,
  name,
  editMode = true,
  value,
  data,
  touched,
  handleOnDropFiles,
  acceptedFileTypes,
}) => {
  return (
    <div className="flex flex-col flex-grow w-full rounded-lg">
      <div className="relative flex flex-col py-2">
        <p
          className={`w-full pb-1 text-sm font-medium text-left capitalize ${
            editMode ? "text-textMid dark:text-caption " : "text-secondaryMidLight dark:text-caption "
          }`}
        >
          {label}
        </p>
        {editMode ? (
          <>
            <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className={`w-full flex flex-col justify-center items-center px-4 py-2 h-36 rounded-lg bg-bgWhite border border-lineDark border-dashed`}
                >
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                        <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                          <div className="flex justify-center items-center flex-grow relative px-1 pt-0.5 pb-1">
                            <p className="flex-grow w-[1400px] text-sm text-center text-[#6f747b]">
                              Drag and drop a file here or click the button
                              below and choose a file to upload
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
                        >
                          <div className="flex justify-center items-center flex-grow relative px-1 pt-1.5 pb-2">
                            <p className="flex-grow w-[1400px] text-sm text-center text-secondaryMid">
                              Choose file
                            </p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-secondary dark:text-textMain ">
              {value}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default FileUpload;
