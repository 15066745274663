import { TableBodyProps } from '../../types';
import parseArray from 'utils/parseArray';
import { KEYS_TO_BE_REMOVED } from '../..';
import { Cell } from '@tanstack/react-table';
import { Column, RowsActions } from '../index';
import '../../../Table/Observations.scss';

export const TableBody = ({
  table,
  columnVisibility,
  getObservationActions,
  flexRender,
}: TableBodyProps) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className='cursor-pointer Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group'
            style={{ height: '66px' }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect='item_hover_effect'
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  rowData={row}
                />
              )
            )}
            <td className='whitespace-nowrap sticky right-0 dark:bg-bgtetriary z-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight'>
              <RowsActions
                row={row}
                getObservationActions={getObservationActions}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
