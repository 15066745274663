import React, { useState } from "react";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProtocol } from "store/protocolProfile/initialState";

export const ReadMore = () => {
  const protocolProfile = useSelector<RootState, IProtocol>(
    (state) => state?.protocolProfile
  );
  const [showFullText, setShowFullText] = useState(false);

  const maxChars = 300;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };


  return (
    <div className="rounded-lg p-4 px-12">
      <p className="text-base text-secondaryMid"></p>

      <p className="font-normal pl-2 w-full break-words text-secondaryMid dark:text-textMain">
        {showFullText ? protocolProfile?.protocolDescription : protocolProfile?.protocolDescription?.slice(0, maxChars)}
      </p>

      {protocolProfile?.protocolDescription?.length > maxChars && (
        <button onClick={toggleText} className="flex items-center mt-2">
          <h5 className="pr-1 font-semibold text-secondaryMid  dark:text-textMain ">
            {showFullText ? "Read Less" : "Read More"}
          </h5>
          {showFullText ? (
            <CheveronUpIcon height={16} />
          ) : (
            <CheveronDownIcon height={16} />
          )}
        </button>
      )}
    </div>
  );
};