import { apiClient } from "../config";
async function getProfileroles(personId: any) {
  const params = {
    personId: personId,
  };
  const res = await apiClient.get("/admin/profile/roles", {
    params,
  });
  return res.data;
}
export default getProfileroles;
