import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
}

const RectangleIcon = ({ fill = "#005C89", ...restProps }: IconProps) => (
    <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
    >
        <rect
            x="0.5"
            y="0.5"
            width="31"
            height="23"
            rx="0.5"
            fill="#005C89"
            fill-opacity="0.08"
className="dark:fill-caption"
            stroke="#2C3236"
            stroke-linejoin="round"
        />
    </svg>
);
export default RectangleIcon;
