import useDateFormatter from "hooks/formatDateWithPattern";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import usePermissions from "hooks/usePermissions";
import { Link, useNavigate } from "react-router-dom";
import { getBrandPlaceholder } from "../../../../../utils/customBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormatter();
  const { users } = usePermissions();

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "adminName"
  ) {
    return (
      <div className="flex justify-start items-center relative w-[230px]">
        <img
          src={
            cellValue?.profileImage
              ? cellValue?.profileImage
              : getBrandPlaceholder("userProfile")
          }
          alt=""
          height="100"
          width="100"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "action"
  ) {
    if (cellValue.actionType === "assigned") {
      return (
        <div className="relative flex items-center justify-start gap-2 ">
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative w-5 h-5"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.66667 10C1.66667 5.39751 5.39751 1.66667 10 1.66667C14.6025 1.66667 18.3333 5.39751 18.3333 10C18.3333 14.6025 14.6025 18.3333 10 18.3333C5.39751 18.3333 1.66667 14.6025 1.66667 10ZM10 3.33334C8.23189 3.33334 6.5362 4.03572 5.28596 5.28596C4.03572 6.5362 3.33334 8.23189 3.33334 10C3.33334 11.7681 4.03572 13.4638 5.28596 14.7141C6.5362 15.9643 8.23189 16.6667 10 16.6667C11.7681 16.6667 13.4638 15.9643 14.7141 14.7141C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23189 15.9643 6.5362 14.7141 5.28596C13.4638 4.03572 11.7681 3.33334 10 3.33334Z"
              fill="#4ECB71"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8333 5.83333C10.8333 5.61232 10.7455 5.40036 10.5893 5.24408C10.433 5.0878 10.221 5 10 5C9.77899 5 9.56702 5.0878 9.41074 5.24408C9.25446 5.40036 9.16667 5.61232 9.16667 5.83333V9.16667H5.83333C5.61232 9.16667 5.40036 9.25446 5.24408 9.41074C5.0878 9.56702 5 9.77899 5 10C5 10.221 5.0878 10.433 5.24408 10.5893C5.40036 10.7455 5.61232 10.8333 5.83333 10.8333H9.16667V14.1667C9.16667 14.3877 9.25446 14.5996 9.41074 14.7559C9.56702 14.9122 9.77899 15 10 15C10.221 15 10.433 14.9122 10.5893 14.7559C10.7455 14.5996 10.8333 14.3877 10.8333 14.1667V10.8333H14.1667C14.3877 10.8333 14.5996 10.7455 14.7559 10.5893C14.9122 10.433 15 10.221 15 10C15 9.77899 14.9122 9.56702 14.7559 9.41074C14.5996 9.25446 14.3877 9.16667 14.1667 9.16667H10.8333V5.83333Z"
              fill="#4ECB71"
            />
          </svg>
          <p className="  text-sm text-left text-[#4ecb71]">
            {cellValue.action}
          </p>
        </div>
      );
    } else if (cellValue.actionType === "removed") {
      return (
        <div className="relative flex items-center justify-start gap-2 ">
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative w-5 h-5"
            preserveAspectRatio="xMidYMid meet"
          >
            <g clip-path="url(#clip0_1_6596)">
              <path
                d="M6.66666 9.16666C6.44565 9.16666 6.23369 9.25445 6.07741 9.41073C5.92113 9.56701 5.83333 9.77898 5.83333 9.99999C5.83333 10.221 5.92113 10.433 6.07741 10.5892C6.23369 10.7455 6.44565 10.8333 6.66666 10.8333H13.3333C13.5543 10.8333 13.7663 10.7455 13.9226 10.5892C14.0789 10.433 14.1667 10.221 14.1667 9.99999C14.1667 9.77898 14.0789 9.56701 13.9226 9.41073C13.7663 9.25445 13.5543 9.16666 13.3333 9.16666H6.66666Z"
                fill="#F24E1E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.1667 10C19.1667 15.0625 15.0625 19.1667 10 19.1667C4.9375 19.1667 0.833328 15.0625 0.833328 10C0.833328 4.9375 4.9375 0.833328 10 0.833328C15.0625 0.833328 19.1667 4.9375 19.1667 10ZM17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01508 17.5 8.03981 17.306 7.12987 16.9291C6.21993 16.5522 5.39313 15.9997 4.69669 15.3033C4.00025 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.49999 10.9849 2.49999 10C2.5 8.01087 3.29017 6.10322 4.69669 4.69669C6.10322 3.29017 8.01087 2.5 10 2.5C11.9891 2.5 13.8968 3.29017 15.3033 4.69669C16.7098 6.10322 17.5 8.01087 17.5 10Z"
                fill="#F24E1E"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_6596">
                <rect width={20} height={20} fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="  text-sm text-left text-[#f24e1e]">
            {cellValue.action}
          </p>
        </div>
      );
    }
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1  ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1   w-[50%] ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
