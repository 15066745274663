import { RowsSortingBtnProps } from "../../types";
import DownArrowIcon from "assets/icons/CustomIcons/DownArrowIcon";

export const RowsSortingBtn = ({
  header,
  isSorted,
  direction,
}: RowsSortingBtnProps) => {
  if (header.column.getCanSort()) {
    return (
      <button
        title="table-sorting"
        className="flex items-start justify-start flex-grow-0 flex-shrink-0 z-30 py-1 rounded-sm absolute left-[100%] "
      >
        <div className="flex flex-col">
          <DownArrowIcon
            style={{
              transform: "rotate(180deg) ",
              display: direction === "asc" ? "block" : direction === "desc" ? "none" : "block"
            }}
            className="mb-[-1px]"
          />
          <DownArrowIcon
            style={{
              display: direction === "asc" ? "none" : direction === "desc" ? "block" : "block"
            }}
            className="mt-[-1px]"
          />
        </div>
      </button>
    );
  }
  return <></>;
};
