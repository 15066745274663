import React from "react";
import CheckBoxCheck from "assets/images/Placeholders/checkboxCheck.png";
import CheckBox from "assets/images/Placeholders/checkbox.png";

interface IProps {
  allowExport: any;
}

export const ExportReport: React.FC<IProps> = ({ allowExport }) => {
  return (
    <div className="py-4 px-4">
      <div className="flex mb-2">
        <img src={allowExport === true ? CheckBoxCheck : CheckBox} alt="" />
        <p className="ml-4 dark:text-textMain ">
          Export the report to your local device{" "}
        </p>
      </div>
      <p className="text-sm text-textMidLight py-2 dark:text-caption">
        The system will prepare your export as you go through the process of
        configuring your report, making your waiting time at actual time of
        downloading much shorter.
      </p>
      <p className="text-sm text-textMidLight py-2 dark:text-caption">
        For shorter in-system loading times, do not indicate you want to export
        the results. Once you have your configuration all set as desired you can
        come back here to indicate that you would like to export the results.
      </p>
    </div>
  );
};
