import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { SPECIES_DETAILS_QUESTIONS_NAME } from "../panelsQuestionsName";
import { SPECIES_DETAILS_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

import { Serializer } from "survey-core";
const currentState = window.history.state;

export default {
  name: panelsName.SPECIES_DETAIL,
  title: panelsTitle.SPECIES_DETAIL,
  iconName: "icon-special-details",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.SPECIES_DETAIL}</h1>`,
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.Category,
      title: "Category",
      isRequired: true,
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.Type,
      title: "Type",
      isRequired: true,
      // choicesByUrl: updateChoicesByUrl("types"),
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.ObjectName,
      title: "Object Name",
      isRequired: true,
      // choicesByUrl: updateChoicesByUrl("objects", currentState?.usr?.programId),
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.SpecieName,
      title: "Species Name",
      isRequired: true,
      // choicesByUrl: updateChoicesByUrl("species", currentState?.usr?.programId),
      choicesByUrl: {
        url: ``,
        valueName: "",
        titleName: "",
      },
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.PresenceAbsence,
      title: "Presence/Absence",
      choices: SPECIES_DETAILS_QUESTIONS_CHOICES.PresenceAbsence,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.IdConfidence,
      title: "ID Confidence",
      choices: SPECIES_DETAILS_QUESTIONS_CHOICES.IdConfidence,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.Sex,
      title: "Sex",
      choices: SPECIES_DETAILS_QUESTIONS_CHOICES.Sex,
      isRequired: true,
    },
    {
      type: "dropdown",
      name: SPECIES_DETAILS_QUESTIONS_NAME.AgeClass,
      title: "Age Class",
      choices: SPECIES_DETAILS_QUESTIONS_CHOICES.AgeClass,
      isRequired: true,
      startWithNewLine: false,
    },
  ],
  ...panelSettings(
    panelsName.SPECIES_DETAIL,
    SPECIES_DETAILS_QUESTIONS_NAME,
    Serializer
  ),
};

export const  speciesDetailIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44771 20 7 20H17C17.5523 20 18 19.5523 18 19L18 9.41421L12.5858 4H7ZM4 5C4 3.34315 5.34315 2 7 2H12.5858C13.1162 2 13.6249 2.21071 14 2.58579L19.4142 8C19.7893 8.37507 20 8.88378 20 9.41421V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V5ZM8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H9C8.44772 13 8 12.5523 8 12ZM8 16C8 15.4477 8.44772 15 9 15H15C15.5523 15 16 15.4477 16 16C16 16.5523 15.5523 17 15 17H9C8.44772 17 8 16.5523 8 16Z" fill="#909090"/>
</svg>`;

function updateChoicesByUrl(key: string, programId?: number) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/").filter((segment) => segment);
  const id = pathSegments.find((segment: any) => !isNaN(segment));
  return {
    url: `https://api.isn.landscape.network/api/common/dropdown/${key}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${id}`,
    valueName: "value",
    titleName: "text",
  };
}
