import React, { useLayoutEffect, useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import "./Programs.scss";
import apiLibrary from "services/api";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import { filtersInitialState } from "store/filters/initialState";
import { dashboardProgramsAction } from "store/filters";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import _ from "lodash";

interface ProgramsProps {
  name: string;
}
export const Programs: React.FC<ProgramsProps> = ({ name }) => {
  const { dashboardFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch = useDispatch();

  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedValue: any // Change the parameter name here
  ) => {
    dispatch(dashboardProgramsAction(selectedValue));
  };

  const [dropDownValues, setDropDownValues] = useState<any[]>([]);
  const [searchString, setSearchString] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);

  const fetchOrgDropdownData = async (
    isLoadMore: boolean,
    page: number = 1,
    entity: any,
    search?: string
  ) => {
    setIsLoading(true);
    try {
      const orgData = await apiLibrary.userProfile.getUsersFilterDropdowns(
        page,
        entity,
        search
      );
      if (isLoadMore) {
        setDropDownValues((pre: any[]) => {
          return [...pre, ...orgData.data[name]];
        });
      } else {
        setDropDownValues(orgData.data[name]);
      }
      setHasMoreData(orgData.data.hasMore);
      setTotalPages(orgData.data.totalPages);
      setHasMoreData(orgData.data.totalPages > page);
    } catch (error: any) {
      // Handle error
      console.error("Failed to fetch program dropdown values:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useLayoutEffect(() => {
    fetchOrgDropdownData(false, 1, name);
  }, []);

  const handleSearchEntities = (searchString: string): void => {
    setPage((pre: number) => {
      fetchOrgDropdownData(false, 1, name, searchString);
      return 1;
    });
  };

  const debouncedSearch = _.debounce(handleSearchEntities, 100);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchOrgDropdownData(true, page, name, searchString);
      return page;
    });
  };

  return (
    <div className="bg-bgWhite dark:bg-secondaryLight  min-w-[315px]  shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid dark:border-lineLight">
      <div className="w-full">
        <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
          <InputField
            placeholder="Search"
            type="Search"
            onChange={(e: any) => {
              setSearchString(e.target.value);
              debouncedSearch(e.target.value);
            }}
          />
        </div>
        <div className="pl-1.5 bg-bgWhite dark:bg-secondaryLight">
          <InfiniteScroll
            dataLength={dropDownValues.length}
            next={handleLoadMore}
            hasMore={hasMoreData}
            height={400}
            loader={
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            }
            pullDownToRefresh={true}
            refreshFunction={() => {}}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh &#8595;
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh &#8593;
              </h3>
            }
          >
            {dropDownValues.map((dropDown: any, index: any) => (
              <div
                key={index}
                className="flex items-center justify-start w-full px-1 rounded-lg"
              >
                <BpCheckbox
                  checked={dashboardFilter.programIds.value.some(
                    (item: any) => item.id === dropDown.id
                  )}
                  label={
                    <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
                      <p className="dark:text-inputText">{ellipsize(`${dropDown.name}`, 25)}</p> 
                    </div>
                  }
                  labelPlacement="end"
                  onChange={(e) => handleSelection(e, dropDown)}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
