import { FormikHandlers } from "formik";
import { UserProfileState } from "store/userProfile/initialState";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

interface FormFieldsProps {
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  editMode: boolean;
  userProfile: UserProfileState;
  touched: any;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  values: any;
  disabled?: boolean;
}

export const FormFields: React.FC<FormFieldsProps> = ({
  setFieldValue,
  errors,
  editMode,
  userProfile,
  touched,
  handleChange,
  handleBlur,
  values,
  disabled,
  
}) => (
  <div className="flex flex-col w-full gap-2 xl:px-12 px-8 pt-4">
    <div className="grid gap-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1">
      <div className="flex flex-col flex-grow w-full rounded-lg">
        <TextInput
          disabled={disabled}
          label="First name"
          placeholder="First name"
          type="text"
          name="firstName"
          setFieldValue={setFieldValue}
          error={errors?.firstName}
          editMode={editMode}
          value={values?.firstName}
          touched={touched.firstName}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </div>
      <div className="flex flex-col flex-grow w-full rounded-lg">
        <TextInput
          disabled={disabled}
          label="Last name"
          type="text"
          name="lastName"
          placeholder="Last name"
          setFieldValue={setFieldValue}
          error={errors?.lastName}
          editMode={editMode}
          value={values.lastName}
          touched={touched.lastName}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </div>
    </div>
    <div className="grid gap-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1">
      <div className="flex flex-col flex-grow">
        <TextInput
          label="Email"
          placeholder="Email"
          type="email"
          name="email"
          setFieldValue={setFieldValue}
          error={errors?.email}
          editMode={editMode}
          value={values?.email}
          touched={touched?.email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={true}
        />
      </div>
      <div className="flex flex-col flex-grow rounded-lg">
        <TextInput
          label="Phone"
          placeholder="Phone"
          type="text"
          name="phoneNumber"
          setFieldValue={setFieldValue}
          error={errors?.phoneNumber}
          editMode={editMode}
          value={values.phoneNumber}
          touched={touched.phoneNumber}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={true}
        />
      </div>
    </div>
  </div>
);
