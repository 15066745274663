import apiClient from "services/apiClient";

interface IParams {
  programIds?: number;
  formIds?: number;
  organizationIds?: number;
  communityIds?: number;
  sortBy?: string;
  sortType?: string;
  page?: number;
  limit?: number;
}

async function getAdminsByReportIdCommunityFormsOrganizationAndProgramIds(
  id: any,
  paramsData: any
) {
  const params: IParams = {
    ...paramsData,
  };
  const res = await apiClient.get(`/admin/reports/${id}/admins`, {
    params,
  });
  return res.data;
}

export default getAdminsByReportIdCommunityFormsOrganizationAndProgramIds;
