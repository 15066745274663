import * as React from "react";
import type { SVGProps } from "react";
const SocialGoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    className="absolute left-[10px]"
    fill="none"
    {...props}>
    <path
      fill="#FBBC05"
      d="M.205 10.105a9 9 0 0 1 .937-5.238l3.04 2.258a5.316 5.316 0 0 0 0 3.75l-3.04 2.257a9 9 0 0 1-.937-3.027Z"
    />
    <path
      fill="#EB4335"
      d="M9.158 3.682a5.317 5.317 0 0 0-4.976 3.443l-3.04-2.258A9 9 0 0 1 15.09 2.25l-2.617 2.617a5.25 5.25 0 0 0-3.315-1.185Z"
    />
    <path
      fill="#34A853"
      d="M5.398 12.76a5.317 5.317 0 0 0 3.76 1.558c1.315 0 2.46-.352 3.315-1.008l2.617 2.385C13.51 17.2 11.385 18 9.158 18a9 9 0 0 1-8.016-4.867l3.04-2.258a5.316 5.316 0 0 0 1.216 1.885Z"
    />
    <path
      fill="#4285F4"
      d="M9.158 7.365h8.385c.13.536.198 1.084.202 1.635 0 2.895-1.043 5.161-2.655 6.696l-2.617-2.386c.768-.59 1.302-1.425 1.515-2.472h-4.83V7.365Z"
    />
  </svg>
);

export default SocialGoogleIcon;
