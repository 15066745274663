import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

// assets
import locationMarkerImage from "assets/images/location-marker.svg";
import selectedLocationMarkerImage from "assets/images/selected-location-marker.svg";
import { __deletePaintedLayers__ } from "../workerMehods";
import { featureCollection, point } from "turf";
import { generatingLayerId } from "../utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { AnyIfEmpty } from "react-redux";
import "./Popup.scss";
import useDateFormatter from "hooks/formatDateWithPattern";
import pointIcon1 from "assets/icons/MapIcons/locationMarker.png";
import registerImageOnMap from "utils/registerImageOnMap";
import rectangleIcon from "assets/icons/MapIcons/rectangle-tooltip-icon.png";

const useCreateMarkers = (data: any, map: any, isMapFullyLoaded: any) => {
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { extractDate } = useDateFormatter();
  const [currentSelectedCommunityId, setCurrentClickedCommunityId] = useState<
    string | null
  >(null);
  const popupRef = useRef<mapboxgl.Popup | null>(null);

  useEffect(() => {
    if (!data || !map || !isMapFullyLoaded) return;
    removeAllCustomLayers(map);

    if (data.length > 0) {
      if (reportsVersionFilter.locations === "Community Locations") {
        loadCluster(data, map);
      } else if (reportsVersionFilter.locations === "Observation Locations") {
        loadLocationMarkers(data, map, "showPopUps");
      }
    }
  }, [data, map, isMapFullyLoaded, reportsVersionFilter]);

  async function loadLocationMarkers(data: any, map: any, popUp?: any) {
    if (!map.hasImage("custom-marker")) {
      await registerImageOnMap(map, pointIcon1, "custom-marker");
    }
    if (!map.hasImage("cluster-count-bg")) {
      await registerImageOnMap(map, rectangleIcon, "cluster-count-bg");
    }

    const sourceId = "earthquakes";

    if (map.getSource(sourceId)) {
      map.getSource(sourceId).setData(createClustersFeatureCollection(data));
    } else {
      map.addSource(sourceId, {
        type: "geojson",
        data: createClustersFeatureCollection(data),
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: sourceId,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#11A3E2",
            100,
            "#42BBAC",
            999,
            "#BF200B",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            15,
            100,
            20,
            999,
            30,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: sourceId,
        filter: ["has", "point_count"],
        layout: {
          "text-field": [
            "case",
            [">=", ["get", "point_count"], 1000],
            ["concat", ["to-string", ["/", ["get", "point_count"], 1000]], "k"],
            ["to-string", ["get", "point_count"]],
          ],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#FFFFFF",
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: sourceId,
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": "custom-marker",
          "icon-size": 0.95,
          "icon-allow-overlap": true,
        },
      });

      map.on(
        "click",
        "clusters",
        (
          e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
          }
        ) => {
          const features: any = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          const clusterId = features[0]?.properties?.["cluster_id"] as
            | number
            | undefined;

          if (clusterId !== undefined) {
            const source = map.getSource(
              "earthquakes"
            ) as mapboxgl.GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              const coordinates = features[0].geometry.coordinates;
              if (coordinates) {
                map.easeTo({
                  center: coordinates,
                  zoom: zoom,
                });
              }
            });
          }
        }
      );

      map.on("click", "unclustered-point", (e: any) => {
        const obsCode = e.features[0].properties.obsCode;
        const dateCreated = e.features[0].properties.dateCreated;
        console.log(dateCreated, "dateCreateddateCreated");

        const coordinates = e.features[0].geometry.coordinates.slice();
        if (obsCode) {
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          if (popupRef.current) {
            popupRef.current.remove();
            popupRef.current = null;
          }
          const popup = new mapboxgl.Popup({
            closeOnClick: true,
            className: "main-popup",
            closeButton: false,
          })
            .setLngLat(coordinates)
            .setHTML(
              `<div class='flex flex-col items-center justify-center'><p class='obsCode-heading text-textNormal'>${obsCode}</p>
    <p class="text-secondaryMidLight date-created">Created ${extractDate(dateCreated)}</p>
    </div>`
            )
            .addTo(map);
          popupRef.current = popup;
        }
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }

  function loadCluster(data: any, map: any) {
    const sourceId = "earthquakes";

    if (map.getSource(sourceId)) {
      // If the source already exists, update its data
      map
        .getSource(sourceId)
        .setData(createClustersFeatureCollectionForObservations(data));
    } else {
      // If the source doesn't exist, add it to the map
      map.addSource(sourceId, {
        type: "geojson",
        data: createClustersFeatureCollectionForObservations(data),
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: sourceId,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#11A3E2",
            100,
            "#42BBAC",
            999,
            "#BF200B",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            15,
            100,
            20,
            999,
            30,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: sourceId,
        filter: ["has", "point_count"],
        layout: {
          "text-field": [
            "case",
            [">=", ["get", "point_count"], 1000],
            ["concat", ["to-string", ["/", ["get", "point_count"], 1000]], "k"],
            ["to-string", ["get", "point_count"]],
          ],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#FFFFFF",
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: sourceId,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.on("click", "clusters", (e: any) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource(sourceId)
          .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });
      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }

  return currentSelectedCommunityId;
};

export { useCreateMarkers };

function removeAllCustomLayers(map: any) {
  const layers = map.getStyle().layers;
  const customLayers = [
    "clusters",
    "cluster-count",
    "unclustered-point",
    "circles",
    "symbol",
    "region",
  ];
  const customSourceId = "earthquakes";

  // Remove custom layers if they exist
  customLayers.forEach((layerId) => {
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
  });

  // Remove the custom source if it exists
  if (map.getSource(customSourceId)) {
    map.removeSource(customSourceId);
  }
}

const createClustersFeatureCollection = (data: any[]) => {
  const features = data
    .filter((item) => item.locationLong && item.locationLat)
    .map((item: any) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Number(item.locationLong), Number(item.locationLat)],
        },
        properties: {
          obsCode: item.obsCode,
          dateCreated: item.observationDate,
        }, // You can add any additional properties here if needed
      };
    });

  return {
    type: "FeatureCollection",
    features: features,
  };
};
const createClustersFeatureCollectionForObservations = (data: any[]) => {
  const features = data
    ?.filter(
      (item) =>
        item.observationCommunityLongitude && item.observationCommunityLatitude
    )
    .map((item: any) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            Number(item.observationCommunityLongitude),
            Number(item.observationCommunityLatitude),
          ],
        },
        properties: {}, // You can add any additional properties here if needed
      };
    });

  return {
    type: "FeatureCollection",
    features: features,
  };
};
