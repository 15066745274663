import React from 'react';
import { RenderCellContentProps } from '../../types';
import { Link } from 'react-router-dom';
import ellipsize from 'ellipsize';
import useDateFormatter from 'hooks/formatDateWithPattern';
import usePermissions from 'hooks/usePermissions';
import { getBrandPlaceholder } from 'utils/customBranding';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { programs } = usePermissions();
  const { formatDate } = useDateFormatter();
  if (
    typeof cellValue === 'object' &&
    cellValue !== null &&
    columnDef.id === 'programName'
  ) {
    return (
      <Link
        to={
          programs.canViewProgram
            ? `/programs/${cellValue?.programId}/profile`
            : ''
        }
        className={`${programs.canViewProgram ? 'cursor-pointer' : 'cursor-default'}`}
      >
        <div className='flex justify-start w-[350px] items-center relative group'>
          <img
            src={
              cellValue?.image.path ? cellValue?.image.path : getBrandPlaceholder("listingProfile")
            }
            alt=''
            height='100'
            width='100'
            className='object-cover mx-2 Img_user_Data'
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: 'wrap' }}
            className=' flex flex-col w-full justify-start items-start relative'
          >
            <p
              className={`text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark py-2 }`}
            >
              {`${cellValue?.programName}`}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'dataAccessPolicy') {
    return (
      <div className={`flex justify-start items-center px-3 py-1 rounded`}>
        <p
          className={`text-xs text-left font-Overpass capitalize px-2 py-1 rounded
    ${cellValue === 'Attached' ? 'bg-tertiaryExtraLight dark:bg-primary_50 text-tertiaryMid' : 'bg-[#ffeded] dark:bg-primary_40 text-[#e72121]'} dark:text-primaryExtraLight`}
        >
          {cellValue}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'timeframe') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid dark:text-textMain whitespace-nowrap`}
        >
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'protocol') {
    return (
      <div
        className={`relative flex items-start  py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid dark:text-textMain whitespace-nowrap`}
        >
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'type') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <div
          className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded ${
            cellValue === 'Public'
              ? 'bg-[#e3f8e6] dark:bg-primary_50'
              : 'bg-primaryExtraLight dark:bg-primary_40'
          }  `}
        >
          <div className='relative flex items-center justify-center flex-grow-0 flex-shrink-0 py-1'>
            <p
              className={`flex-grow-0 flex-shrink-0 text-xs text-center font-Overpass ${
                cellValue === 'Public' ? 'text-lightGreen' : 'text-primaryDark'
              } dark:text-primaryExtraLight`}
            >
              {cellValue}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'pausedSince') {
    return (
      <div className='relative flex items-start flex-grow px-3 py-1'>
        <p className='text-sm text-left text-textMid dark:text-caption '>
          {formatDate(cellValue)}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid dark:text-textMain whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
