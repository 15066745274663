import React from "react";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import BpCheckbox from "view/components/CheckBox";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import useDateRange from "hooks/useDateRange";
import { reportsAnalyticsDateAction, reportsDateAddedUpdatedLastNextAction } from "store/filters/reducer.actions";
import { formatTheDates } from "utils/formatTheDates";

interface DateFilterProps {
  name: any;
  from: string;
  to: string;
}

export const DateFilter: React.FC<DateFilterProps> = ({ from, to, name }) => {
  const dispatch = useDispatch();
  const { getDateOption, calculateDate } = useDateRange();
  const handleDateChange = (startDate: any, endDate: any) => {
    dispatch(reportsAnalyticsDateAction(startDate, endDate));
  };

  
  const handleDateInputChange = (value: any, field: any) => {
    const selectedDate = moment(value);
    const formattedDate = selectedDate.toISOString();

    if (field === "startDate") {
      if (!to || selectedDate.isSameOrBefore(moment(to))) {
        handleDateChange(formatTheDates(formattedDate), to);
      } else {
        console.error("Start date cannot be greater than end date.");
      }
    } else {
      if (!from || selectedDate.isSameOrAfter(formatTheDates(from))) {
        handleDateChange(from, formatTheDates(formattedDate));
      } else {
        console.error("End date cannot be less than start date.");
      }
    }
  };

  const handleCheckboxChange = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      const { startDate, endDate } = calculateDate(value);
      handleDateChange(startDate, endDate);
    } else {
      handleDateChange("", "");
    }
  };

  const checkboxLabels = [
    "Today",
    "Yesterday",
    "This week",
    "Last week",
    "This month",
    "Last month",
    "Last 30 days",
    "Last 90 days",
    "Last 12 months",
    "This year",
    "Last year",
  ];

  const selectedOption = getDateOption(from, to);

  return (
    <div className="flex py-2 pl-2 shadow-xl bg-bgWhite dark:bg-secondaryLight rounded-xl">
      <div className="w-[255px] flex flex-col justify-start items-start">
        {checkboxLabels.map((label) => (
          <div
            key={label}
            className="flex items-center justify-start pl-1 rounded-lg"
          >
            <BpCheckbox
              labelClassName="capitalize dark:text-inputText"
              label={label}
              value={label}
              labelPlacement="end"
              checked={label === selectedOption}
              onChange={handleCheckboxChange}
            />
          </div>
        ))}
      </div>
      <div className="border-r-0 border-b-0 border-t-0 border-l border-lineMid dark:border-lineLight w-[255px] flex flex-col justify-start items-start p-3">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <InputField
              type="date"
              label="Start Date"
              className={
                "uppercase dark:bg-secondaryLight dark:text-textMain dark:border-lineLight"
              }
isForFilters={true}
              placeholder="YYYY/MM/DD"
              value={moment(from)}
              max={
                to
                  ? formatTheDates(to)
                  : formatTheDates(null, { useCurrentTime: true, utc: false })
              }
              onChange={(event: any) =>
                handleDateInputChange(event, "startDate")
              }
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <InputField
              type="date"
              label="End Date"
              className={
                "uppercase dark:bg-secondaryLight dark:text-textMain  dark:border-lineLight"
              }
isForFilters={true}
              placeholder="YYYY/MM/DD"
              value={moment(to)}
              min={
                from
                  ? formatTheDates(from)
                  : formatTheDates(null, { useCurrentTime: true, utc: false })
              }
              max={formatTheDates(null, { useCurrentTime: true, utc: false })}
              onChange={(event: any) => handleDateInputChange(event, "endDate")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
