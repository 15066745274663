import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import {
  observationsProgramsAction,
  reportsProfileResetFiltersAction,
  reportsVersionResetFiltersAction,
  reportsVersionsAction,
} from "store/filters/reducer.actions";

export const ReportVersion: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const resetFilter = () => {
    dispatch(reportsVersionsAction(""));
    localStorage.removeItem("isApplied");
  };

  if (reportsVersionFilter.versionIds?.applied) {
    return (
      <div
        className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="flex-shrink-0 w-full text-sm capitalize break-all text-start text-textMid dark:text-inputText">
            {reportsVersionFilter.versionIds.value}
          </p>
        </div>
        <ClearFilters handleClick={() => resetFilter()} />
      </div>
    );
  } else {
    return <></>;
  }
};
