import { apiClient } from "../config";

async function getAllPrograms(queryParams: any) {
  const res = await apiClient.get(`/admin/programs`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllPrograms;
