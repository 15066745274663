import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SpecieImages.scss";
import { Attachment } from "store/specieProfile/initialState";
import { getBrandPlaceholder } from "utils/customBranding";

interface SpecieImagesProps {
  images: Attachment[];
}

export const SpecieImages: React.FC<SpecieImagesProps> = ({ images }) => {
  const sortedImages = images?.sort(
    (a, b) => Number(b.isStar) - Number(a.isStar)
  );

  if (sortedImages?.length <= 0) {
    return (
      <div className="slide h-[400px]">
        <img
          alt="sample_file"
          height="100%"
          className="h-full"
          width="100%"
          src={getBrandPlaceholder("moduleCover")}
        />
      </div>
    );
  }
  return (
    <div className="box">
      <Carousel
        className=""
        showIndicators
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <button className="nav_btn nav_btn_right" onClick={clickHandler}>
              <svg
                width={9}
                height={16}
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8.70711 7.29289C8.89464 7.48043 9 7.73478 9 8C9 8.26522 8.89464 8.51957 8.70711 8.70711L1.70711 15.7071C1.31658 16.0976 0.683418 16.0976 0.292894 15.7071C-0.0976305 15.3166 -0.0976305 14.6834 0.292894 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                  fill="#2C3236"
                />
              </svg>
            </button>
          )
        }
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <button onClick={clickHandler} className="nav_btn nav_btn_left">
              <svg
                width={9}
                height={16}
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L2.41421 8L8.70711 14.2929C9.09763 14.6834 9.09763 15.3166 8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893Z"
                  fill="#2C3236"
                />
              </svg>
            </button>
          )
        }
        renderIndicator={(clickHandler, isSelected, index) => (
          <li
            onClick={clickHandler}
            className={`ind ${isSelected ? "active" : ""}`}
            key={index}
            role="button"
          />
        )}
        swipeable
      >
        {sortedImages?.map((image, index) => (
          <div className="slide h-[400px]" key={index}>
            <img
              alt="sample_file"
              height="100%"
              className="h-full"
              width="100%"
              src={image.file.large}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SpecieImages;
