import React from "react";
// Components
import {
  Date,
  MembersObservationsForms,
  Search,
  SelectedOrganizations,
  SelectedCommunities,
  SelectedTags,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  programsResetFiltersAction,
  programsResetFiltersExceptCommunityAction,
  programsResetFiltersExceptOrganizationAction,
} from "store/filters/reducer.actions";
import {
  filtersInitialState,
  programsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting programsFilter state from Redux store
  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting organizationId from URL parameters
  const { organizationId, communityId } = useParams<{
    organizationId: string;
    communityId: string;
  }>();

  // Function to check if any filter is applied

  const clearAllFilters = () => {
    dispatch(programsResetFiltersAction());
    // if (organizationId) {
    //   dispatch(programsResetFiltersExceptOrganizationAction());
    // } else if (communityId) {
    //   dispatch(programsResetFiltersExceptCommunityAction());
    // } else {
    //   dispatch(programsResetFiltersAction());
    // }
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isProgramFiltersApplied(programsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Search />

          <MembersObservationsForms
            name="members"
            min={programsFilter.members.min}
            max={programsFilter.members.max}
            isApplied={programsFilter.members?.applied}
          />
          <MembersObservationsForms
            name="numberOfObservations"
            min={programsFilter.numberOfObservations.min}
            max={programsFilter.numberOfObservations.max}
            isApplied={programsFilter.numberOfObservations?.applied}
          />

          <MembersObservationsForms
            name="connectedForms"
            min={programsFilter.connectedForms.min}
            max={programsFilter.connectedForms.max}
            isApplied={programsFilter.connectedForms?.applied}
          />

          <Date
            name="date"
            from={programsFilter.date.from}
            to={programsFilter.date.to}
            isApplied={programsFilter.date?.applied}
          />
          <SelectedOrganizations />
          <SelectedCommunities />
          <SelectedTags />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

export function isProgramFiltersApplied(
  programsFilter: programsDirectoryFilterState
): boolean {
  const {
    searchBy,
    searchString,
    date,
    communityIds,
    organizationIds,
    connectedForms,
    numberOfObservations,
    members,
    tagIds,
  } = programsFilter;
  const applied =
    (searchBy && searchString !== "") ||
    date?.applied ||
    members?.applied ||
    tagIds?.applied ||
    numberOfObservations?.applied ||
    organizationIds?.applied ||
    communityIds?.applied ||
    connectedForms?.applied;
  return applied;
}
