import apiClient from "services/apiClient";

async function updateNotification(id: any, status: any) {
  const res = await apiClient.put(`/admin/reminders/${id}`, {
    status,
  });
  return res.data;
}

export default updateNotification;
