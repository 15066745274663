import React from "react";

interface I_ViewLayerDetail {
  data: any;
}

const ViewLayerDetail = ({ data }: I_ViewLayerDetail) => {
  return (
    <ul className="flex flex-col gap-4 pt-4 w-full">
      {data &&
        data?.map((item: any, index: number) => {
            const [key,value] = item;
            if(value){
                return (
                  <DetailItem
                    key={index.toString()}
                    heading={key}
                    description={value}
                  />
                );
            } 
        })}
    </ul>
  );
};

export { ViewLayerDetail };

const DetailItem = ({ heading, description }: any) => {
  return (
    <li>
      <h4 className="text-textMidLight text-[15px] capitalize font-bold">{heading}</h4>
      <p className="text-[15px] text-secondary " style={{wordBreak:"break-all"}}>{description}</p>
    </li>
  );
};
