import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Dropzone, { useDropzone } from "react-dropzone";
import Button from "view/components/Button";
import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import { Box, Modal } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddFileModalAction } from "store/modals/reducer.actions";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { fetchFilesAction } from "store/files/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useParams } from "react-router-dom";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import { identifyFileType } from "utils/IdentifyFileType";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
  width: "656px",
};

const FILES_LIMIT = 4;
const ACCEPTED_FILES_TYPES = {
  "image/jpeg": [".jpg", ".jpeg", ".png"],
  "video/mp4": [".mp4"],
  "audio/mp3": [".mp3","mpeg"],
  "application/pdf": [".pdf"],
};

const AddFileModal = () => {
  const [files, setFiles] = useState<any>([]);
  const { addFileModal } = useSelector((state: RootState) => state.modals);
  const [isLoading, setIsLoading] = useState(false);
  const { programId } = useParams<{
    programId: string;
  }>();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleOnDropFiles = useCallback(
    (acceptedFiles: any[]) => {
      if (acceptedFiles.length > FILES_LIMIT) {
        return Toasts.error("Files must be less than or equal to 4");
      } else if (acceptedFiles.length + files.length > FILES_LIMIT) {
        return Toasts.error("Total files must be less than or equal to 4");
      }
      const validImageTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "video/mp4",
        "audio/mp3",
        "audio/mpeg",
        "application/pdf",
      ];

      const areFilesValid = acceptedFiles.every((file) =>
        validImageTypes.includes(file.type)
      );
      if (!areFilesValid) {
        return Toasts.error(
          "Please select a valid file (jpeg/jpg/png/mp4/pdf/mp3)"
        );
      }

      if (acceptedFiles.length === FILES_LIMIT) {
        setFiles([...acceptedFiles]);
      } else {
        setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
      }
    },
    [files]
  );

  const handleDeleteFile = (id: string) => {
    let deleted = false; // Flag to keep track of whether a file with the same path is deleted
    const updatedFiles = files.filter((f: any) => {
      if (f.path && f.path === id) {
        if (!deleted) {
          deleted = true; // Mark as deleted
          return false; // Remove the first file with the same path
        }
      }
      return true;
    });
    setFiles([...updatedFiles]);
  };

  const handleUpdateFile = (newFile: any, id: string) => {
    // if new file already added then skipping the updation of file
    const isFileAlreadAdded = Boolean(
      files.find((f: any) => f.path === newFile.path)
    );

    if (isFileAlreadAdded) {
      return;
    }

    const updatedFiles = [...files];

    // verifiying file existance
    const fileIndex = updatedFiles.findIndex((file: any) => file.path === id);

    if (fileIndex >= 0) {
      updatedFiles[fileIndex] = newFile;

      setFiles([...updatedFiles]);
    }
  };

  const handleClose = () => {
    setFiles([]);
    dispatch(closeAddFileModalAction());
  };

  const handleSubmission = async () => {
    setIsLoading(true);
    try {
      const res = await apiLibrary.Files.filesUpload(
        addFileModal.data.programId,
        files
      );
      Toasts.success(res.message);
      handleClose();
      if (addFileModal.data.path === "profile") {
        dispatch(fetchProgramProfileAction(addFileModal.data.programId));
      } else {
        dispatch(fetchFilesAction(programId, "program"));
      }
      setFiles([]);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };
console.log(files,'files');

  return (
    <Modal
      open={addFileModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="relative flex items-center justify-start w-full p-4 rounded-lg cursor-default bg-bgWhite dark:bg-secondaryLight">
          <div className="flex flex-col w-full">
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 mb-4 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Attach Files
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div
              className={`flex flex-col items-start justify-start w-full gap-6 ${
                isLoading ? "pointer-events-none" : ""
              } `}
            >
              <Dropzone
                onDrop={handleOnDropFiles}
                accept={ACCEPTED_FILES_TYPES}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="w-full flex flex-col justify-center items-center px-4 py-2 rounded-lg bg-bgWhite border border-lineDark border-dashed dark:bg-secondaryLight">
                    <section>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="w-full">
                          <div className="ml-[150px]">
                            <CloudUploadIcon />
                          </div>
                          <p className="dark:text-caption">
                            Or drag and drop your files here or click in this
                            area
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </Dropzone>
              <p className="text-sm text-secondaryMidLight mt-[-25px] dark:text-caption">
                You can upload files with the following extensions only
                PDF,JPG,JPEG,MP3 and MP4. Each file should be no larger than 25 MB
                and you can upload 4 files at once.
              </p>
              {files.length > 0 && (
                <div className="flex flex-wrap mb-6">
                  {files.map((f: any, index: number) => (
                    <FileBox
                      img={f}
                      handleDeleteFile={handleDeleteFile}
                      handleUpdateFile={handleUpdateFile}
                      acceptedFileTypes={ACCEPTED_FILES_TYPES}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-4">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />
              <Button
                disabled={isLoading || files?.length === 0}
                type="button"
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={handleSubmission}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddFileModal;

const FileBox = ({
  img,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
}: any) => {
  const [thumbnail, setThumbnail] = useState(img);

  const fileType = identifyFileType(img.type.split("/")[1]);
  useEffect(() => {
    let newThumbnail: any;

    console.log("fileType: " + fileType);
    switch (fileType) {
      case "video":
        newThumbnail = videoThumbnailImg;
        break;
      case "mpeg":
        newThumbnail = videoThumbnailImg;
        break;
      case "mp3":
        newThumbnail = videoThumbnailImg;
        break;
      case "image":
        newThumbnail = URL.createObjectURL(img);
        break;
      case "pdf":
        newThumbnail = pdfThumbnailImg;
        break;
      default:
        newThumbnail = null;
    }
    setThumbnail(newThumbnail);

    return () => {};
  }, [img, videoThumbnailImg, pdfThumbnailImg]);
  console.log(thumbnail);
  return (
    <div className="flex flex-col justify-start items-end gap-1 w-[146px] h-[190px] mr-2">
      <div>
        <button
          className="gap-1"
          type="button"
          onClick={() => handleDeleteFile(img?.id ? img?.id : img?.path)}
        >
          <TrashIcon />
        </button>
      </div>
      <div className="gap-1 p-1 border border-dashed rounded-lg bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-full">
          <Dropzone
            onDrop={(acceptedFiles) =>
              handleUpdateFile(acceptedFiles[0], img?.path)
            }
            multiple={false}
            accept={acceptedFileTypes}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="absolute items-center rounded-full right-1 bg-bgWhite w-7 h-7 dark:bg-secondaryLight">
                  <CameraIcon className="ml-0.5 mt-0.5 justify-start items-center w-6 h-6" />
                </div>
              </div>
            )}
          </Dropzone>
          <div className="">
            <img
              src={thumbnail}
              alt=""
              className={`w-[138px] h-[80px] mx-auto ${
                fileType == "pdf" ? "object-contain" : "object-cover"
              }`}
            />
          </div>
          <div className="relative w-full" style={{ wordBreak: "break-word" }}>
            <p className="w-full text-center break-words dark:text-textMain ">
              {img?.path}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
