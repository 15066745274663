import { ERROR_LOADING_GEOGRAPHIES, GET_ALL_GEOGRAPHIES, RESET_DATA, START_LOADING_GEOGRAPHIES, STOP_LOADING_GEOGRAPHIES } from "./action-types";
import initialState, { Igeography } from "./initialState";

export const adminGeographyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_GEOGRAPHIES:
      return {
        ...state,
        stateIs: "Pending",
      };

      case ERROR_LOADING_GEOGRAPHIES:
        return {
          ...state,
          stateIs: "Error",
        };

    case STOP_LOADING_GEOGRAPHIES:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_GEOGRAPHIES:
      const geographies = action.payload.geographies.map((item: Igeography) => {
        const { createdBy, dateAdded, description, id, layerName } = item;
        const data = {
          createdBy: {
            name: createdBy.name,
            image: createdBy.profileImage?.file?.thumb ?? "",
          },
          dateAdded,
          id,
          layerName: {
            id: id,
            layerName: layerName,
          },
          description,
        };

        return data;
      });

      return {
        ...state,
        data: {
          geographies: geographies,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
