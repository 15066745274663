import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const EyeOffIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M2.292 2.293a1 1 0 0 1 1.414 0l3.04 3.04A10.955 10.955 0 0 1 12 4c4.927 0 9.095 3.238 10.496 7.7.062.196.062.405 0 .6a11.014 11.014 0 0 1-3.551 5.231l2.762 2.762a1 1 0 0 1-1.414 1.414l-18-18a1 1 0 0 1 0-1.414ZM17.52 16.108A9.022 9.022 0 0 0 20.487 12 9.004 9.004 0 0 0 8.235 6.822l1.732 1.732a4 4 0 0 1 5.478 5.478l2.076 2.076Zm-3.59-3.59a2 2 0 0 0-2.45-2.45l2.45 2.45ZM4.624 8.174a1 1 0 0 1 .194 1.4A8.975 8.975 0 0 0 3.51 12a9.004 9.004 0 0 0 10.176 5.842 1 1 0 0 1 .373 1.965c-.668.127-1.357.193-2.06.193-4.928 0-9.096-3.238-10.497-7.7a1 1 0 0 1 0-.6 10.97 10.97 0 0 1 1.72-3.332 1 1 0 0 1 1.4-.194Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EyeOffIcon;
