import React, { useState, useEffect } from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { TailSpin } from "react-loader-spinner";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "./components";

import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { ElementFactory, Serializer } from "survey-core";

import {
  registerCustomQuestionsAndPanels,
  resetAppliedAndReomvedProperties,
  unRegisterCustomPanels,
  unRegisterCustomQuestions,
} from "view/pages/Forms/EditFormDetails/Components/SurveyJs";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import { camelCase } from "change-case";
import questionsPropertiesToBeRemove from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionsPropertiesToBeRemove";

type SurveyDetails = {
  surveyFields?: any;
};

function reseteQuestions() {
  ElementFactory.Instance.unregisterElement("audio");
  ElementFactory.Instance.unregisterElement("geocoder");
}

// questionsPropertiesToBeRemove.forEach((question: any) => {
//   const { name, properties } = question;
//   properties.forEach((property: string) => {
//     if (Serializer.getProperty(name, property)) {
//       Serializer.getProperty(name, property).visible = true;
//     }
//   });
// });

export const CreateOrEditSurvey: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [creator, setCreator] = useState<SurveyCreator | null>(null);
  const [surveyDetails, setSurveyDetails] = useState<any>();
  const { surveyId } = useParams<{ surveyId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    unRegisterCustomQuestions();
    resetAppliedAndReomvedProperties();
    unRegisterCustomPanels();
    return () => {
      registerCustomQuestionsAndPanels();
    };
  }, []);

  useEffect(() => {
    reseteQuestions();
    const loadSurveyDetails = async () => {
      if (!surveyId) {
        setIsLoading(false);
        return;
      }
      try {
        const { data }: { data: SurveyDetails } =
          await apiLibrary.pollsAndSurveys.getSurveyDetails(surveyId);
        const newCreator = new SurveyCreator({
          showLogicTab: true,
          showJSONEditorTab: false,
        });
        newCreator.JSON = data.surveyFields || {};

        setSurveyDetails(data);
        setCreator(newCreator);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
        navigate("/polls-and-surveys/list");
      } finally {
        setIsLoading(false);
      }
    };

    loadSurveyDetails();
  }, [surveyId]);

  let selectedQuestion: any = null;

  useEffect(() => {
    if (!creator) return;

    creator.onSelectedElementChanged.add((sender: any, options: any) => {
      selectedQuestion = options.newSelectedElement;
    });

    creator.onModified.add(async function (sender: any, options: any) {
      if (options.type === "PROPERTY_CHANGED") {
        if (
          options.name === "choices" ||
          options.name === "text" ||
          options.name === "value"
        ) {
          if (options.target.getType() === "itemvalue") {
            const item = options.target;
  
            item.value = camelCase(item.text);
            item.text = item.text;
          }
        }
      }
      if (options.type === "PROPERTY_CHANGED" && options.name === "title") {
        const name = camelCase(options.newValue);
        options.target.name = name;
      }
    });

    creator.onUploadFile.add(async function (_: any, options: any) {
      try {
        const { data } = await apiLibrary.file.fileUpload(
          options.files[0],
          false,
          "public"
        );
        options.callback("success", data.file.original);
      } catch (error: any) {
        options.callback("error");
      }
    });
  }, [creator]);
  
  if (isLoading) {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <TailSpin
          height={50}
          width={50}
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius={2}
          visible={true}
        />
      </div>
    );
  }

  return creator ? (
    <>
      <Header creator={creator} surveyDetails={surveyDetails} />
      <SurveyCreatorComponent creator={creator} />
    </>
  ) : null;
};

export default CreateOrEditSurvey;
