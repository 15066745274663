import React, { useLayoutEffect, useState } from "react";
// Components
import { CommunitiesColumnModal } from "view/components/Modals/Community/CommunitiesColumnModal";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import { IFetchMarkerStyles } from "store/markerStyles/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { isAnyMarkerStylesFilterApplied } from "../AppliedFilters";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);

  // Getting markerStylesFilter state from Redux store
  const { markerStylesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { data } = useSelector<RootState, IFetchMarkerStyles>(
    (state) => state?.allMarkerStyles
  );

  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

 
  useLayoutEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyMarkerStylesFilterApplied(filtersData.filters);
      } else {
        return isAnyMarkerStylesFilterApplied(markerStylesFilter);
      }
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
    </>
  );
};
