import { Dispatch } from "redux";
import { GET_ALL_REPORTS_PROFILE,REPORTS_VERSIONS, RESET_DATA, START_LOADING_REPORTS_PROFILE, STOP_LOADING_REPORTS_PROFILE } from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const fetchProfileReportsAction =
  (id:any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_REPORTS_PROFILE });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.reportProfileFilter
      );
      const { data } = await apiLibrary.Reports.getAllProfileReports(queryString,id);
      dispatch({
        type: GET_ALL_REPORTS_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the report detail:", error);
    } finally {
      dispatch({ type: STOP_LOADING_REPORTS_PROFILE });
      // dispatch(setLoadingProgress(100));
    }
  };

  export const reportsProfileResetAction  = () => {
    return {
      type: RESET_DATA,
    }
  }
