import apiClient from "services/apiClient";

async function connectCommunitiesToProgram(programId: any, data: any) {
  const url = `/admin/programs/${programId}/communities`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default connectCommunitiesToProgram;
