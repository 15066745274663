interface UserProfile {
  addresses: Address[];
  contactPreference: string | null;
  dateOfBirth: string;
  firstName: string;
  gender: string;
  id: number;
  lastName: string;
  personImage: PersonImage;
  personImageId: number;
  prefix: string;
  secondaryEmail: SecondaryEmail[];
  secondaryPhoneNumber: SecondaryPhoneNumber[];
  suffix: string;
  user: User;
  usedApps: {
    id: string;
    title: string;
  }[];
  [key: string]: any;
  stateIs: "Idle" | "Pending" | "Error";
}

interface Address {
  address: string;
  apartment: string;
  city: string;
  country: string;
  id: number;
  insertedAt: string;
  isPrimary: boolean;
  personId: number;
  state: string;
  updatedAt: string;
  zipCode: string;
}

interface PersonImage {
  file: {
    original: string;
    thumb: string;
  };
}

interface SecondaryEmail {
  email: string;
  isVerified: boolean;
}

interface SecondaryPhoneNumber {
  isVerified: boolean;
  countryCode: string;
  phoneNumber: string;
}

interface User {
  email: string;
  id: number;
  phoneNumber: string | null;
}

export const initialStateUserProfile: UserProfile = {
  addresses: [],
  contactPreference: null,
  dateOfBirth: "",
  firstName: "",
  gender: "",
  id: 0,
  lastName: "",
  personImage: {
    file: {
      original: "",
      thumb: "",
    },
  },
  personImageId: 0,
  prefix: "",
  secondaryEmail: [],
  secondaryPhoneNumber: [],
  suffix: "",
  usedApps: [],
  user: {
    email: "",
    id: 0,
    phoneNumber: null,
  },
  stateIs: "Idle",
};

export type UserProfileState = typeof initialStateUserProfile;
