import ViewListIcon from "assets/icons/HeroIcons/ViewListIcon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { observationsViewTypeAction } from "store/filters/reducer.actions";

const ListBtn = () => {
  const { observationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const dispatch = useDispatch();
  return (
    <button
      title="ListBtn"
      onClick={() => dispatch(observationsViewTypeAction("list"))}
      className={`relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 px-1 rounded-lg ${
        observationsFilter.viewType === "list"
          ? "bg-primary/[0.08]"
          : "hover:bg-primary/[0.08]"
      } `}
    >
      <ViewListIcon width={24} height={24} viewBox="0 0 24 24" fill="#2C3236" />
    </button>
  );
};

export default ListBtn;
