import React, { useEffect, useState } from "react";
// Components
import { Toasts } from "view/components/Toasts";
// External Components
// Store Utils
import { useDispatch } from "react-redux";
// API Services
//
// External Utils
import { UploadFile } from "./components/UploadFile";
import { BreadCrumbs } from "./components/BreadCrumbs";
import CustomAccordion from "./components/CustomAccordian";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
// import { Stepper, Step } from "store/addImportStepper/initialState";
import { ColumnMapping } from "./components/ColumnMapping";
import { ImportProgress } from "./components/ImportProgress";
import { ImportDone } from "./components/ImportDone";
import useImportsStepper, { Step } from "hooks/useImportsStepper";
import { Header } from "Components/Imports/AddImport";

interface ImportFormStepperProps { }

export const ImportFormStepper: React.FC<ImportFormStepperProps> = () => {
  const {
    steps,
    moveToSpecificStep,
    resetStepper,
    moveToPreviousStep,
    moveToNextStep,
  } = useImportsStepper();

  // Keeping files golablly within steppers
  const [files, setFiles] = useState<File[]>([]);

  // const [activeStep, setActiveStep] = useState<any>(steps[0]);

  const [activeStep, setActiveStep] = useState<any>({
    title: "Name",
    step: "reportName",
    isActive: true,
    isSubmitted: false,
    hasAccess: false,
  });

  useEffect(() => {
    if (steps && steps.length > 0) {
      const currentActiveStep = steps.find((step: Step) => step.isActive);

      if (currentActiveStep) {
        setActiveStep(currentActiveStep);
      }
    }
  }, [steps]);

  useEffect(() => {
    // Cleanup function to remove importId from local storage when component unmounts
    return () => {
      localStorage.removeItem("importId");
    };
  }, []);

  const isLastStep = activeStep.step === "columnsConfiguration";

  function _renderStepContent() {
    switch (activeStep.step) {
      case "uploadFile":
        return (
          <UploadFile
            activeStep={activeStep}
            steps={steps}
            isLastStep={isLastStep}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={moveToNextStep}
            files={files}
            setFiles={setFiles}
          />
        );
      case "columnMapping":
        return (
          <ColumnMapping
            activeStep={activeStep}
            // steps={steps}
            isLastStep={isLastStep}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={moveToNextStep}
          />
        );
      case "importInProgress":
        return (
          <ImportProgress
            activeStep={activeStep}
            // steps={steps}
            isLastStep={isLastStep}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={moveToNextStep}
          />
        );
      case "importDone":
        return (
          <ImportDone
            activeStep={activeStep}
            // steps={steps}
            isLastStep={isLastStep}
            moveToPreviousStep={moveToPreviousStep}
            moveToNextStep={moveToNextStep}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header steps={steps} />
      </div>
      <div className="px-5">
        <BreadCrumbs steps={steps} />
        <>
          {activeStep.step === "importInProgress" ||
            activeStep.step === "importDone" ? (
            <div className="bg-bgWhite rounded-lg py-6">{_renderStepContent()}</div>
          ) : (
            <>
              <CustomAccordion
                expandIcon={
                  <CheveronUpIcon height={16} width={16} fill="#2C3236" />
                }
                detailsClassName="mt-[-15px]"
                summaryClassName="YOUR_CLASS_NAME_HERE"
                heading={activeStep.heading}
              >
                <div className="bg-white rounded-lg">{_renderStepContent()}</div>
              </CustomAccordion>
            </>
          )}
        </>
      </div>
    </div>
  );
};
