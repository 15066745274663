import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import CheckBoxCheck from "assets/images/Placeholders/checkboxCheck.png";
import CheckBox from "assets/images/Placeholders/checkbox.png";

interface IProps {
  notifyingChannel: any;
}

export const SummaryNotificationsChannel: React.FC<IProps> = ({
  notifyingChannel,
}) => {
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  return (
    <div className="border-b border-lineMid dark:bg-secondaryLight  py-4">
      <Accordion className="dark:bg-secondaryLight "  style={{ boxShadow: "none" }} defaultExpanded >
        <AccordionSummary
          className="dark:bg-secondaryLight "
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex justify-between items-center">
              <p className="dark:text-textMain ">Notifying channels</p>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="mt-[-15px] dark:bg-secondaryLight">
          <Typography>
            <div className="mt-2">
              <p className="text-sm text-textMidLight dark:text-caption ">
                How would you like to send the notification? You can select a
                few options.
              </p>
            </div>
            <div className="flex px-3 mt-5 py-2">
              <div className="flex items-center w-1/2 relative">
                <img
                  src={notifyingChannel.toast ? CheckBoxCheck : CheckBox}
                  alt=""
                  className={`${
                    !notifyingChannel.toast ? "mt-[-8px] ml-1" : ""
                  } absolute left-0`}
                />
                <p
                  className={`text-sm ml-9 mt-[-8px] ${
                    notifyingChannel.toast
                      ? "dark:text-textMain"
                      : "dark:text-caption "
                  }`}
                >
                  Toast
                </p>
              </div>
              <div className="flex items-center w-1/2 relative">
                <img
                  src={notifyingChannel.push ? CheckBoxCheck : CheckBox}
                  alt=""
                  className={`${
                    !notifyingChannel.push ? "mt-[-8px] ml-1" : ""
                  } absolute left-0`}
                />
                <p
                  className={`text-sm ml-9 mt-[-8px] ${
                    notifyingChannel.push
                      ? "dark:text-textMain"
                      : "dark:text-caption "
                  }`}
                >
                  Push
                </p>
              </div>
              <div className="flex items-center w-1/2 relative">
                <img
                  src={notifyingChannel.email ? CheckBoxCheck : CheckBox}
                  alt=""
                  className={`${
                    !notifyingChannel.email ? "mt-[-8px] ml-1" : ""
                  }  absolute left-0`}
                />
                <p
                  className={`text-sm ml-9 mt-[-8px] ${
                    notifyingChannel.email
                      ? "dark:text-textMain"
                      : "dark:text-caption "
                  }`}
                >
                  Email
                </p>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
