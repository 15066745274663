import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";

interface AdminProps {
  userData: any;
  setSelectedUser: any;
  unAssignAdmin: any;
}

const Admins: React.FC<AdminProps> = ({
  userData,
  unAssignAdmin,
  setSelectedUser,
}) => {
  return (
      <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">      <div
        onClick={() => unAssignAdmin(userData)}
        className="flex items-center flex-grow w-full cursor-pointer"
      >
        <div
          // onClick={() => setSelectedUser(userData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          {userData.personImage !== null ? (
            <img
              className="w-8 h-8 rounded-full"
              src={userData?.personImage}
              alt=""
            />
          ) : (
            <ListImagePlaceholder />
          )}
          {/* <p className="ml-3 dark:text-textMain">{`${userData.name}`}</p> */}
          <p className="ml-3 dark:text-textMain">{`${userData.name}`}</p>
        </div>
      </div>
      <button type="button" className="flex-shrink-0">
        <XCloseSmallControlIcon onClick={() => unAssignAdmin(userData)} />
      </button>
    </div>
  );
};

export default Admins;
