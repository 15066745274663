import { apiClient } from "../config";

async function archiveOrRestorePollsAndSurvey(id: string, status: any) {
  const res = await apiClient.put(`/admin/survey-and-polls/${id}/status`, null, {
    params: {
      status: status,
    },
  });
  return res.data;
}

export default archiveOrRestorePollsAndSurvey;
