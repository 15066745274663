import moment from "moment";
import {
  APPEND_NEW_COMMENT,
  GET_ALL_ACTIVITIES,
  GET_ALL_PAGINATED_ACTIVITIES,
  RESET_DATA,
  START_LOADING_ACTIVITIES,
  STOP_LOADING_ACTIVITIES,
} from "./action-types";
import initialState from "./initialState";

export const observationsActivitiesReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    // Loading states
    case START_LOADING_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING_ACTIVITIES:
      return {
        ...state,
        isLoading: false,
      };

    case GET_ALL_ACTIVITIES:
      return {
        ...state,
        data: {
          ...state.data,
          activities: action.payload.activities.reverse(),
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
        },
        message: "",
      };

    case GET_ALL_PAGINATED_ACTIVITIES:
      const activities = action.payload.activities
        .reverse()
        .concat(state.data.activities);
      return {
        ...state,
        data: {
          ...state.data,
          activities: activities,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
        },
        message: "",
      };

    case RESET_DATA:
      return initialState;

    case APPEND_NEW_COMMENT: {
      return {
        ...state,
        data: {
          ...state.data,
          activities: [...state.data.activities, action.payload],
        },
        message: "",
      };
    }

    default:
      return state;
  }
};
