import React, { useEffect, useState } from "react";
// Components
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import { IfetchProtocols } from "store/protocols/initialState";
import { ProtocolColumnModal } from "view/components/Modals/Protocols/ProtocolColumnModal";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { isAnyProtocolsFilterApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";

export const Filters: React.FC<FiltersProps> = ({ columns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { data } = useSelector<RootState, IfetchProtocols>(
    (state) => state?.allProtocols
  );

  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };
  const { protocolsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyProtocolsFilterApplied(filtersData.filters);
      } else {
        return isAnyProtocolsFilterApplied(protocolsFilter);
      }
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      {isOpen && (
        <ProtocolColumnModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columns={columns.filter((item: any) => item.id !== "select")}
        />
      )}
    </>
  );
};
