import React, { useCallback } from "react";
import { UserProfileState } from "store/userProfile/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { FieldInputProps } from "formik";
import { DeletePhoneBtn } from "./DeletePhoneBtn";
import { PhoneVerificationBtn } from "./PhoneVerificationBtn";
interface PhoneCreatorProps {
  remove: any;
  currentPhoneIndex: number;
  contact: any;
  handleChange: FieldInputProps<any>["onChange"];
  handleBlur: FieldInputProps<any>["onBlur"];
  touched?: any;
  errors?: any;
  setFieldValue: any;
  values?: any;
  refreshNewData: (data: any) => void;
}

const PhoneCreator: React.FC<PhoneCreatorProps> = ({
  remove,
  currentPhoneIndex,
  contact,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
  setFieldValue,
  refreshNewData,
}) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const phoneNumberValue =
    values.secondaryPhoneNumber[currentPhoneIndex].phoneNumber;
  const isPhoneExist = userProfile.secondaryPhoneNumber.find(
    (item: any) => item.phoneNumber === phoneNumberValue
  );

  const fieldErrors = errors.secondaryPhoneNumber?.[currentPhoneIndex];
  const fieldTouched = touched.secondaryPhoneNumber?.[currentPhoneIndex];

  const checkEmailValidationError = useCallback(() => {
    if (fieldErrors && fieldTouched) {
      return fieldErrors.phoneNumber;
    }
    return null;
  }, [fieldErrors, fieldTouched]);

  return (
    <div className="w-full">
      {contact.isPrimary ? (
        <div className="p-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <p className="text-md font-semibold text-secondary dark:text-caption">
                {contact.phoneNumber}
              </p>
              <span className="mx-2 dark:text-caption">-</span>
              <p className="text-sm font-semibold text-[#1B7F38]">Primary</p>
            </div>

            {!userProfile.virtual && (
              <DeletePhoneBtn
                isPhoneExist={isPhoneExist}
                currentPhoneIndex={currentPhoneIndex}
                remove={remove}
                refreshNewData={refreshNewData}
              />
            )}
          </div>

          <p className="text-sm text-[#636c76] dark:text-caption">
            This number will be used for account-related notifications and can
            also be used for password resets.
          </p>
        </div>
      ) : (
        <div className="border-t border-lineMid dark:border-lineLight p-4">
          {contact.isVerified ? (
            <div className="flex justify-between items-center">
              <p className="text-md font-semibold text-secondary dark:text-caption">
                {values.secondaryPhoneNumber &&
                  values.secondaryPhoneNumber[currentPhoneIndex]?.phoneNumber}
              </p>

              {!contact.isPrimary && !userProfile.virtual && (
                <DeletePhoneBtn
                  isPhoneExist={isPhoneExist}
                  currentPhoneIndex={currentPhoneIndex}
                  remove={remove}
                  refreshNewData={refreshNewData}
                />
              )}
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb-2">
                <p className="text-md font-semibold text-secondary dark:text-caption">
                  {values.secondaryPhoneNumber &&
                    values.secondaryPhoneNumber[currentPhoneIndex]?.phoneNumber}
                </p>
                {!contact.isPrimary && !userProfile.virtual && (
                  <DeletePhoneBtn
                    isPhoneExist={isPhoneExist}
                    currentPhoneIndex={currentPhoneIndex}
                    remove={remove}
                    refreshNewData={refreshNewData}
                  />
                )}
              </div>

              <ul className="list-disc px-6">
                <div className=" flex items-center">
                  <li className="text-sm font-semibold text-[#9A6700]">
                    Unverified
                  </li>
                  {!userProfile.virtual && (
                    <PhoneVerificationBtn
                      errMsg={checkEmailValidationError()}
                      contact={contact}
                    />
                  )}
                </div>
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PhoneCreator;
