import React, { useState } from "react";

interface SeeMoreTextProps {
  text: string;
}

export const SeeMoreText: React.FC<SeeMoreTextProps> = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const maxChars = 10;
  // const truncatedText = text?.length > maxChars ? text?.slice(0, maxChars) : text;
  // const lastChar = truncatedText?.slice(-1);
  // const shouldRemoveLastComma = lastChar === ",";


  return (
    <>
      <p className="text-sm leading-tight capitalize dark:text-textMain">
      {showFullText
          ? text
          : text?.slice(0, maxChars)}
      </p>

      {text?.length > maxChars && (
        <button
          onClick={toggleText}
          className="flex items-center text-sm text-textLink font-semibold underline pr-1"
        >
          {showFullText ? "See Less" : "See More"}
        </button>
      )}
    </>
  );
};
