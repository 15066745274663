// Define an interface for the category data
export interface IType {
  typeName: string;
  categoryName: string;
  categoryId: number;
  id: number;
}

export interface ITypesData {
  types: IType[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchTypes {
  data: ITypesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchTypes = {
  data: {
    types: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
