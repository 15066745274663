import React, { useEffect } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { InputFields } from "./components/InputFields";
import * as Yup from "yup";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";

import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { ISummaryReports } from "store/reportsSummary";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import useReportsStepper, { Step } from "hooks/useReportsStepper";
import usePermissions from "hooks/usePermissions";
import { checkIsAnythingUpdatedThisStep } from "../..";

interface PermissionsProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

interface PersonData {
  name: string;
  role: string;
  personId: number;
  lastActive: string;
  personImage: {
    large: string;
    original: string;
    thumb: string;
  };
  isSelected: boolean;
  canView: boolean;
  canDownload: boolean;
  isCreator: boolean;
}

export interface PersonState {
  data: PersonData[];
}

export const Permissions: React.FC<PermissionsProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  setAnythingUpdatedThisStep,
}) => {
  const formInitialValues: PersonState = {
    data: [],
  };
  const personDataSchema = Yup.object({
    canDownload: Yup.boolean().required(),
    canView: Yup.boolean().required(),
    personId: Yup.number().required(),
  });

  const permissionsSchema = Yup.object({
    data: Yup.array()
      .of(personDataSchema)
      .required("Permissions for admin is required"),
  });

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {

    if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
      moveToNextStep();
      return;
    }
    let updatedData = { ...values };
    updatedData.data = values.data
      .filter((item: any) => item.isSelected)
      .map((admin: any) => {
        return {
          personId: admin.personId,
          canView: admin.canView,
          canDownload: admin.canDownload,
          // isSelected: admin.isSelected,
        };
      });

    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.addReportPermissions(
          reportSummary.id,
          updatedData
        );
      moveToNextStep();
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={permissionsSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        isValid,
        dirty,
      }) => {
        useEffect(() => {
          setAnythingUpdatedThisStep("permissions", dirty);
        }, [dirty]);
        return (
          <Form>
            <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
              <InputFields
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                values={values}
                reportId={reportId}
                reportSummary={reportSummary}
              />
              <SubmissionButtons
                isValid={isValid}
                isSubmitting={isSubmitting || values.data.length === 0}
                handleGoBackBtn={() => moveToPreviousStep()}
                values={values}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
}: any) => {
  const { reports } = usePermissions();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        disabled={isSubmitting}
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="before"
        onClick={handleGoBackBtn}
      />
      <Button
        disabled={
          isSubmitting || !isValid || !reports.canCreateAddPermissionReports
        }
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};
