import React, { useLayoutEffect } from "react";
import { Filters, Header, Pagination, Table, Tabs } from "Components/Protocols";
import { ProtocolsTable } from "hooks/useTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import {
  resetProtocolsDirectoryOrderAction,
  updateColumnsVisibilityOfProtocolsById,
  updateProtocolsColumnsVisibilityByStatus,
} from "store/directoriesOrders/reducer.actions";
import { fetchProtocolsAction } from "store/protocols";
import {
  protocolsResetFiltersAction,
  setProtocolsFiltersAction,
} from "store/filters/reducer.actions";
import { IfetchProtocols } from "store/protocols/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Protocols = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, IfetchProtocols>(
    (state) => state?.allProtocols
  );
  const { primary } = useSelector((state: any) => state.theme.colors);

  useLayoutEffect(() => {
    dispatch(setProtocolsFiltersAction());
  }, []);

  const { protocols } = usePermissions();
  const { programId } = useParams();
  const { protocolsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );
  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setSelectAll,
    setSelectedRows,
    setSorting,
    sorting,
  } = ProtocolsTable(data?.protocols);

  useEffect(() => {
    dispatch(fetchProtocolsAction(programId));
    dispatch(updateProtocolsColumnsVisibilityByStatus(protocolsFilter.status));
    saveFiltersDataToLocalStorage(protocolsFilter);
  }, [protocolsFilter]);
  useEffect(() => {
    if (programId) {
      dispatch(updateColumnsVisibilityOfProtocolsById(programId));
    } else {
      dispatch(resetProtocolsDirectoryOrderAction());
    }
  }, []);

  // useEffect(() => {
  //   return () => {
  //     dispatch(protocolsResetFiltersAction());
  //   };
  // }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters columns={columnsConfig} />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs == "Idle" && data?.protocols?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color={primary}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              isRowSelected={isRowSelected}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              setSelectedRows={setSelectedRows}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default Protocols;
