import {
  ERROR_LOADING_MARKER_STYLES,
  GET_ALL_MARKER_STYLES,
  RESET_DATA,
  START_LOADING_MARKER_STYLES,
  STOP_LOADING_MARKER_STYLES,
} from "./action-types";
import initialState, { MarkerStyle } from "./initialState";

export const markerStylesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_MARKER_STYLES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_MARKER_STYLES:
      return {
        ...state,
        stateIs: "Error",
        message: action.payload?.message || "Error loading marker styles",
      };

    case STOP_LOADING_MARKER_STYLES:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_MARKER_STYLES:
      const markerStyles = action.payload.markerStyles.map(
        (style: MarkerStyle) => ({
          id: style.id,
          name: {
            id: style.id,
            name: style.name,
            image: style.markerStyleImage.thumb,
          },
          group: style.group,
          markerStyleId: style.markerStyleId,
          community: style.community,
          styleImage: {
            large: style.markerStyleImage.large,
            original: style.markerStyleImage.original,
            thumb: style.markerStyleImage.thumb,
            imageId: style.markerStyleImage.imageId,
          },
        })
      );

      return {
        ...state,
        data: {
          markerStyles: markerStyles,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
