import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import {
  GET_OBSERVATION_BY_LOCATION,
  GET_OBSERVATION_BY_LOCATION_STATE,
} from "../action-types";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

/**
 * FETCH DASHBOARD WIDGETS -- CLUSTERED MAP
 * @returns
 */
export const fetchObservationByLocation =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: GET_OBSERVATION_BY_LOCATION_STATE,
      payload: {
        stateIs: "Pending",
      },
    });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.dashboardFilter
      );

      if (params.organizationIds) {
        delete params.organizationIds;
      }
      if (params.datePeriod) {
        Object.assign(params, {
          date_from: params.datePeriod.from,
          date_to: params.datePeriod.to,
        });
        delete params.datePeriod;
      }
      const { data } =
        await apiLibrary.Dashboard.widgets.getClusteredMap(params);

      dispatch({
        type: GET_OBSERVATION_BY_LOCATION,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: GET_OBSERVATION_BY_LOCATION_STATE,
        payload: {
          stateIs: "Error",
        },
      });
      console.log("An error occurred while fetching the clustered map:", error);
      // dispatch(fetchError(error))
    } finally {
      // dispatch(loadingState(false))
    }
  };
const dummyData = [
  {
    coordinates: [74.324166412, 31.569415731],
    type: "Point",
  },
  {
    coordinates: [74.326076778, 31.568347584],
    type: "Point",
  },
  {
    coordinates: [74.325181294, 31.567075963],
    type: "Point",
  },
];
