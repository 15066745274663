import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import MapIcon from "assets/icons/HeroIcons/MapIcon";
import { useDispatch } from "react-redux";
import { openReportsMapModalAction } from "store/modals";

interface IProps {
  geospatialFilter: any;
}

export const SummaryGeospatialFiltering: React.FC<IProps> = ({
  geospatialFilter,
}) => {
  const coordinates = geospatialFilter.map(
    (item: any) => item?.filter?.regions[0]?.mapData
  );

  const dispatch = useDispatch();
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  const handleClick = (data: any) => {
    dispatch(openReportsMapModalAction(data));
  };
  return (
    <div className="py-4 border-b border-lineMid">
      <Accordion
        className="dark:bg-secondaryLight "
        style={{ boxShadow: "none" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex justify-between items-center w-full">
              <p className="text-secondaryMid dark:text-textMain ">
                Geospatial Filtering Drawn On The Map
              </p>
              <button
                className="flex items-center pr-5"
                onClick={() => handleClick(coordinates)}
              >
                <MapIcon fill="#005C89" />{" "}
                <p className="ml-1 text-textLink flex text-sm font-semibold">
                  Show Map
                </p>
              </button>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: "-20px" }}>
          <Typography>
            {geospatialFilter.map((el: any, index: number) => {
              const { operator, columnTitle, condition, type } = el?.filter;
              return (
                <div
                  key={index}
                  className="w-full flex justify-between gap-2 py-2 px-4"
                >
                  <p className="text-sm dark:text-textMain break-words">
                    {`${operator}  ${columnTitle}`}
                  </p>

                  <div className="flex gap-4">
                    <p className="text-sm dark:text-textMain">{condition}</p>
                    <p className="text-sm dark:text-textMain">{type}</p>
                  </div>
                </div>
              );
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
