import mapboxgl from "mapbox-gl";
import registerImageOnMap from "utils/registerImageOnMap";
import separateColorAndOpacity from "utils/separateColorAndOpacity";
import pointIconRed from "assets/images/location-marker-red.png";

async function __paintShapeByGeoJsonOnMap__(
  map: any,
  draw: any,
  sourceId: string,
  layerId: string,
  geoJson: any,
  defaultColor: any,
  defaultBorderColor: any,
  shapeType: any,
  item: any
) {
  if (!map || !sourceId || !layerId || !geoJson) {
    return;
  }
  // adding geojson source
  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  const isGeoJsonHasLineString =
    geoJson &&
    geoJson?.features?.find(
      (f: any) =>
        f.geometry.type === "LineString" ||
        f.geometry.type === "MultiLineString"
    );

  const { color, opacity } = separateColorAndOpacity(defaultColor);
  const { color: borderColor, opacity: borderOpacity } =
    separateColorAndOpacity(defaultBorderColor);

  // design and register the added geojson source layer

  if (shapeType === "point") {
    if (item.markerStyle.markerStyleId) {
      if (!map.hasImage(item.markerStyle.markerStyleId)) {
        // const imageee = await loadImageWithFetch(
        //   item.markerStyle.styleImage
        // );

        await registerImageOnMap(
          map,
          item.markerStyle.styleImage,
          item.markerStyle.markerStyleId
        );
      }
    } else {
      if (!map.hasImage("marker-red")) {
        await registerImageOnMap(map, pointIconRed, "marker-red");
      }
    }
    map.addLayer({
      id: layerId,
      type: "symbol",
      source: sourceId,
      layout: {
        "icon-image": item.markerStyle.markerStyleId ?? "marker-red",
        "icon-size": 0.5,
      },
    });
  } else {
    map.addLayer({
      id: layerId,
      type: isGeoJsonHasLineString ? "line" : "fill",
      source: sourceId,
      layout: {},
      paint: {
        [isGeoJsonHasLineString ? "line-color" : "fill-color"]: color,
        [isGeoJsonHasLineString ? "line-opacity" : "fill-opacity"]: opacity,
      },
    });

    if (!isGeoJsonHasLineString) {
      map.addLayer({
        id: `${layerId}-border`,
        type: "line",
        source: sourceId,
        layout: {},
        paint: {
          "line-color": borderColor || "#000000",
          "line-width": 1,
          "line-opacity": borderOpacity,
        },
      });
    }
  }
}

export { __paintShapeByGeoJsonOnMap__ };
