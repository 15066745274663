import { useEffect } from "react";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateShapePickers } from "store/surveyJS";
import { useDispatch } from "react-redux";

const useHandleMovementOfMapCenterPoint = (
  currentQuestionEle: any,
  map: any
) => {
  const { shapePickers, surveyActiveTab } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyActiveTab === "test") {
      return;
    }

    if (
      currentQuestionEle &&
      currentQuestionEle?.mapCenterPoint &&
      currentQuestionEle.mapCenterPoint.doFly &&
      map
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      if (!longitude || !latitude) {
        return;
      }

      const coordinates = [parseFloat(longitude), parseFloat(latitude)];

      map.flyTo({
        center: coordinates,
        zoom: currentQuestionEle.zoomLevel ?? 2,
      });

      // stop the map moving on inputs change to set map center point

      const updatedCurrentQuestionEle = { ...currentQuestionEle };
      updatedCurrentQuestionEle.mapCenterPoint.doFly = false;

      updateElementByIdFromArray(
        shapePickers,
        updatedCurrentQuestionEle,
        (updatedShapePickers: any) => {
          dispatch(updateShapePickers(updatedShapePickers));
        }
      );
    }
  }, [currentQuestionEle, map]);
  return null;
};

export { useHandleMovementOfMapCenterPoint };
