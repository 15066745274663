import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";

import CustomCheckbox from "view/components/CheckBox";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ISummaryReports } from "store/reportsSummary";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { checkIsAnythingUpdatedThisStep } from "../..";
import { Step } from "hooks/useReportsStepper";

interface ReportExportingProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}
export interface IReportExportingFormValues {
  allowExport: boolean;
}

export const ReportExporting: React.FC<ReportExportingProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  setAnythingUpdatedThisStep,
}) => {
  const dispatch = useDispatch();
  const { reports } = usePermissions();
  const [initialState, setinitialState] = useState<IReportExportingFormValues>({
    allowExport: false,
  });

  const reportSettingsSchema = Yup.object().shape({
    allowExport: Yup.boolean().required("Is repeat is required"),
  });

  useEffect(() => {
    setinitialState((pre: IReportExportingFormValues) => {
      const updatedData = {
        allowExport: reportSummary.allowExport,
      };

      return updatedData;
    });
  }, [reportSummary]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    
    if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
      moveToNextStep();
      return;
    }
    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.addReportExporting(
          reportSummary.id,
          values
        );
      moveToNextStep();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={reportSettingsSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(FormikProps) => {
        useEffect(() => {
          setAnythingUpdatedThisStep("exporting", FormikProps.dirty);
        }, [FormikProps.dirty]);
        return (
          <Form>
            <div className="relative flex flex-col items-start justify-start flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
              <div className="flex flex-col items-start justify-start flex-shrink-0 gap-4 overflow-hidden">
                <div className="flex flex-col items-start justify-start flex-shrink-0">
                  <div className="flex flex-col items-start justify-start flex-shrink-0 gap-6 pb-4">
                    <div className="flex items-center justify-start flex-shrink-0 rounded-lg">
                      <CustomCheckbox
                        name="allowExport"
                        disabled={!reports.canEditExportPermissionReports}
                        checked={FormikProps.values.allowExport}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          FormikProps.setFieldValue(
                            "allowExport",
                            event.target.checked
                          );
                        }}
                        label={
                          <div className="flex items-center justify-start flex-grow ">
                            <div className="flex justify-start items-center flex-grow relative py-0.5">
                              <p className="flex-grow w-full text-[15px] text-left text-secondaryMid dark:text-inputText ">
                                Do you want to export the report to your local
                                device?
                              </p>
                            </div>
                          </div>
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start   flex-shrink-0 relative pt-1.5">
                      <p className="flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-caption ">
                        <span className="flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-caption ">
                          If you indicate right now that you want to export your
                          report, the system will prepare your export as you go
                          through the process of configuring your report, making
                          your waiting time at actual time of downloading much
                          shorter.{" "}
                        </span>
                        <br />
                        <span className="flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-caption ">
                          Still figuring out what to include in the report? For
                          shorter in-system loading times, do not indicate you
                          want to export the results. Once you have your
                          configuration all set as desired you can come back
                          here to indicate that you would like to export the
                          results.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <SubmissionButtons
                isValid={FormikProps.isValid}
                isSubmitting={FormikProps.isSubmitting}
                handleGoBackBtn={() => moveToPreviousStep()}
                values={FormikProps.values}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
}: any) => {
  const { reports } = usePermissions();
  return (
    <div className="flex items-center flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="before"
        onClick={handleGoBackBtn}
      />
      <Button
        disabled={isSubmitting || !reports.canEditExportPermissionReports}
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};
