export const SAVE_CONFIGURATION_STATE = "SAVE_CONFIGURATION_STATE";
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_CONFIGURATION_STATE = "GET_CONFIGURATION_STATE";
export const GET_TOTAL_COUNTS = "GET_TOTAL_COUNTS";
export const GET_TOTAL_COUNTS_STATE = "GET_TOTAL_COUNTS_STATE";
export const GET_QA_PROGRESS = "GET_QA_PROGRESS";
export const GET_QA_PROGRESS_STATE = "GET_QA_PROGRESS_STATE";
export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS";
export const GET_ACTIVE_USERS_STATE = "GET_ACTIVE_USERS_STATE";
export const GET_OBSERVATIONS = "GET_OBSERVATIONS";
export const GET_OBSERVATIONS_STATE = "GET_OBSERVATIONS_STATE";
export const GET_OBSERVATION_BY_LOCATION = "GET_OBSERVATION_BY_LOCATION";
export const GET_OBSERVATION_BY_LOCATION_STATE = "GET_OBSERVATION_BY_LOCATION_STATE";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_STATE = "GET_NOTIFICATIONS_STATE";
export const GET_QA_NOTIFICATIONS = "GET_QA_NOTIFICATIONS";
export const GET_QA_NOTIFICATIONS_STATE = "GET_QA_NOTIFICATIONS_STATE";
export const GET_HORIZONTAL_BAR_GRAPH = "GET_HORIZONTAL_BAR_GRAPH";
export const GET_HORIZONTAL_BAR_GRAPH_STATE = "GET_HORIZONTAL_BAR_GRAPH_STATE";
export const GET_REPORT_NOTIFICATIONS = "GET_REPORT_NOTIFICATIONS";
export const GET_REPORT_NOTIFICATIONS_STATE = "GET_REPORT_NOTIFICATIONS_STATE";
export const GET_MOST_OBSERVED_OBJECTS = "GET_MOST_OBSERVED_OBJECTS";
export const GET_MOST_OBSERVED_OBJECTS_STATE =
  "GET_MOST_OBSERVED_OBJECTS_STATE";
export const GET_MOST_OBSERVED_SPECIES = "GET_MOST_OBSERVED_SPECIES";
export const GET_MOST_OBSERVED_SPECIES_STATE =
  "GET_MOST_OBSERVED_SPECIES_STATE";
export const GET_CUMULATIVE_OBSERVATIONS = "GET_CUMULATIVE_OBSERVATIONS";
export const GET_CUMULATIVE_OBSERVATIONS_STATE =
  "GET_CUMULATIVE_OBSERVATIONS_STATE";
export const GET_OBSERVATIONS_BY_REGION = "GET_OBSERVATIONS_BY_REGION";
export const GET_OBSERVATIONS_BY_REGION_STATE =
  "GET_OBSERVATIONS_BY_REGION_STATE";

export const RESET_DATA = "RESET_DATA";
