import React, { useCallback, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { CustomizedSwitches } from "./CustomizedSwitches";
import Button from "view/components/Button";
import { PencilAltIcon } from "assets/icons/HeroIcons";
import { Toasts } from "view/components/Toasts";
import { sentenceCase } from "change-case";
import { useSelector } from "react-redux";
import { UserProfileState } from "store/userProfile/initialState";
import { RootState } from "store";
import usePermissions from "hooks/usePermissions";

// Define types for your state and any other structures you use
interface NotificationPreference {
  [key: string]: boolean;
}

interface NotificationCategory {
  [key: string]: NotificationPreference;
}

interface NotificationsState {
  notifyBy: NotificationPreference;
  notificationPreferences: {
    [category: string]: NotificationCategory;
  };
}

export const Notification = () => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const [notifications, setNotifications] = useState<NotificationsState>({
    notifyBy: {},
    notificationPreferences: {},
  });
  const { profile } = usePermissions();
  const [editMode, setEditMode] = useState(false);

  const fetchUserNotifications = useCallback(async () => {
    try {
      const res =
        await apiLibrary.notificationsProfilePreferences.getUserNotificationPreferences(
          userProfile.user.id
        );
      setNotifications(res.data);
    } catch (error) {
      console.error(error);
      // Handle error
    }
  }, []);

  useLayoutEffect(() => {
    fetchUserNotifications();
  }, [fetchUserNotifications]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    fetchUserNotifications();
    setEditMode(false);
  };

  const handleSaveClick = async () => {
    try {
      const response =
        await apiLibrary.notificationsProfilePreferences.userprofilenotifications(
          notifications
        );
      Toasts.success(response.message);
      setEditMode(false);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const handleSwitchToggle = (
    category: string,
    notificationKey: string,
    notifyKey: string
  ) => {
    setNotifications((prevNotifications: NotificationsState) => {
      const newState = {
        ...prevNotifications,
        notificationPreferences: {
          ...prevNotifications.notificationPreferences,
        },
      };

      newState.notificationPreferences[category] = {
        ...newState.notificationPreferences[category],
      };
      newState.notificationPreferences[category][notificationKey] = {
        ...newState.notificationPreferences[category][notificationKey],
        [notifyKey]:
          !newState.notificationPreferences[category][notificationKey][
            notifyKey
          ],
      };

      const anyFalse = Object.values(newState.notificationPreferences).some(
        (category) =>
          Object.values(category).some((pref) => pref[notifyKey] === false)
      );
      if (anyFalse) {
        newState.notifyBy[notifyKey] = false;
      } else {
        newState.notifyBy[notifyKey] = true;
      }

      return newState;
    });
  };

  const toggleNotificationMethod = (
    key: keyof NotificationPreference,
    newValue: boolean
  ) => {
    setNotifications(
      (prevNotifications: NotificationsState): NotificationsState => {
        const newNotifyBy: NotificationPreference = {
          ...prevNotifications.notifyBy,
          [key]: newValue,
        };

        const newNotificationPreferences: {
          [category: string]: NotificationCategory;
        } = Object.entries(prevNotifications.notificationPreferences).reduce(
          (acc, [category, notifications]) => {
            const updatedNotifications: NotificationCategory = Object.entries(
              notifications
            ).reduce((acc2, [notificationKey, notificationValue]) => {
              const updatedPreference: NotificationPreference = {
                ...notificationValue,
              };
              if (updatedPreference.hasOwnProperty(key)) {
                updatedPreference[key] = newValue;
              }
              acc2[notificationKey] = updatedPreference;
              return acc2;
            }, {} as NotificationCategory);

            acc[category] = updatedNotifications;
            return acc;
          },
          {} as { [category: string]: NotificationCategory }
        );

        return {
          ...prevNotifications,
          notifyBy: newNotifyBy,
          notificationPreferences: newNotificationPreferences,
        };
      }
    );
  };

  const eliminatedKeys = [
    "newAdminAddedToCommunityForObserver",
    "newAdminAddedToCommunityForAdmin",
    "adminRemovedFromCommunityForObserver",
    "adminRemovedFromCommunityForAdmin",
    "newAdminAddedToProgramForObserver",
    "newAdminAddedToProgramForAdmin",
    "adminRemovedFromProgramForObserver",
    "adminRemovedFromProgramForAdmin",
  ];

  return (
    <div className="w-full px-12 pt-6 border-lineMid border-l border-r-0 border-b-0 border-t-0 dark:bg-secondaryLight dark:border-lineLight">
      <div className="flex items-center justify-between w-full mb-8">
        <h1 className="font-semibold text-left text-secondaryMid  dark:text-textMain">
          Notification Preferences
        </h1>
        {/* {profile.canEditNotificationProfile && ( */}
        <>
          {!editMode ? (
            profile.canEditNotificationProfile && (
              <button onClick={handleEditClick} className="flex">
                <PencilAltIcon className="mr-2" fill="#54595F" />
                <p className="text-sm text-center text-textMid dark:text-inputText hover:text-primary ">
                  Edit
                </p>
              </button>
            )
          ) : (
            <div className="relative right-0 flex gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleCancelClick}
              />
              <Button
                type="button"
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 border-2 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={handleSaveClick}
              />
            </div>
          )}
        </>
        {/* )} */}
      </div>

      <div className="grid w-full grid-cols-7 px-4 py-3 mb-2 rounded-lg bg-primaryLight">
        <p className="text-base font-semibold text-textMid col-span-4">
          Notify me when...
        </p>
        <p className="text-sm font-medium col-span-2">Notification Center</p>
        <p className="text-sm font-medium col-span-1">Email</p>
      </div>

      <div className="max-h-[85vh] overflow-y-auto">
        <div className="grid w-full grid-cols-7 px-4 pt-3">
          <p className="text-sm text-textMid dark:text-textMain col-span-4">
            Turn On All Notifications
          </p>
          <div className="col-span-2 ml-2">
            <CustomizedSwitches
              checked={notifications.notifyBy.notifyByCenter}
              onChange={(e: any) => {
                const { checked } = e.target;
                toggleNotificationMethod("notifyByCenter", checked);
              }}
              disabled={!editMode}
            />
          </div>
          <div className="col-span-1 ml-2">
            <CustomizedSwitches
              checked={notifications.notifyBy.notifyByEmail}
              onChange={(e: any) => {
                const { checked } = e.target;
                toggleNotificationMethod("notifyByEmail", checked);
              }}
              disabled={!editMode}
            />
          </div>
        </div>

        {Object.entries(notifications?.notificationPreferences || {}).map(
          ([category, notifications]: [string, any], index) => (
            <div key={category}>
              <p className="px-4 pb-3 mt-6 text-base font-semibold capitalize text-textMid dark:text-textMain">
                {sentenceCase(category)}
              </p>
              {Object.entries(notifications || {}).map(
                (
                  [notificationKey, notificationValue]: [string, any],
                  index
                ) => {
                  if (eliminatedKeys.includes(notificationKey)) {
                    return;
                  }
                  return (
                    <div
                      className={`grid grid-cols-7 items-center mb-0 px-4 py-3 rounded-lg dark:text-textMain ${
                        index % 2 === 0
                          ? "bg-bgHighMidlight dark:text-textMid"
                          : ""
                      }`}
                    >
                      <p
                        className={`ext-sm text-textMid dark:text-textMain col-span-4 ${
                          index % 2 === 0 ? " dark:text-textMid" : ""
                        }`}
                      >
                        {sentenceCase(notificationKey)}
                      </p>

                      <div className="grid grid-cols-3 col-span-3">
                        {(() => {
                          // Filtering and collecting the entries you need
                          const entries = Object.entries(
                            notificationValue || {}
                          ).filter(([notifyKey]) =>
                            ["notifyByCenter", "notifyByEmail"].includes(
                              notifyKey
                            )
                          );

                          // Swapping the entries based on their keys
                          const sortedEntries = entries.sort(
                            ([keyA], [keyB]) => {
                              if (
                                keyA === "notifyByEmail" &&
                                keyB === "notifyByCenter"
                              ) {
                                return 1; // `notifyByEmail` should come after `notifyByCenter`
                              }
                              if (
                                keyA === "notifyByCenter" &&
                                keyB === "notifyByEmail"
                              ) {
                                return -1; // `notifyByCenter` should come before `notifyByEmail`
                              }
                              return 0;
                            }
                          );

                          // Mapping the sorted entries to render them
                          return sortedEntries.map(
                            ([notifyKey, notifyValue], index: number) => (
                              <div
                                key={notifyKey}
                                className={`flex items-center w-full ml-2 col-span-${index === 0 ? "2" : "1"}`}
                              >
                                <CustomizedSwitches
                                  checked={notifyValue}
                                  onChange={() =>
                                    handleSwitchToggle(
                                      category,
                                      notificationKey,
                                      notifyKey
                                    )
                                  }
                                  disabled={!editMode}
                                />
                              </div>
                            )
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};
