import { ERROR_LOADING_OBSERVATIONS, GET_ALL_OBSERVATIONS, RESET_DATA, START_LOADING_OBSERVATIONS, STOP_LOADING_OBSERVATIONS } from "./action-types";
import initialState, { IObservation } from "./initialState";
export const observationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_OBSERVATIONS:
      const observations = action.payload.observations.map(
        (item: IObservation) => {
          const {
            communityName,
            dateEdited,
            id,
            observationCode,
            submissionDate,
            protocolId,
            observer,
            programName,
            status,
            form,
            oldCode,
            programStatus,
            flag
          } = item;

          const data = {

            communityName,
            dateEdited,
            id,
            observationCode: {
              observationCode,
              id
            },
            submissionDate,
            protocolId,
            observer,
            programName,
            status,
            formName: form,
            oldCode,
            programStatus,
            flag
          };

          return data;
        }
      );

      return {
        ...state,
        data: {
          observations,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
