import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { reportsAnalyticsSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Reports.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { filterKeys } from "Components/Users/Table";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";

export const KEYS_TO_BE_REMOVED = ["id"];

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(reportsAnalyticsSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(reportsAnalyticsSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(reportsAnalyticsSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
        />
      </table>
    </div>
  );
};
