import React from "react";
import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { ICommunity } from "store/communityProfile";
import { RootState } from "store";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useDispatch } from "react-redux";
import { openEditCommunityTagsModalAction } from "store/modals/reducer.actions";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import { Box } from "@mui/material";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";

export const CommunityTags = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const dispatch = useDispatch();
  const { communities, tags } = usePermissions();
  return (
    <div
      className="flex flex-col justify-start w-[100%] items-start    overflow-hidden rounded-lg bg-bgWhite dark:bg-secondaryLight"
      style={{
        boxShadow:
          "0px 0px 1px 0 rgba(2,13,36,0.12), 0px 1px 2px 0 rgba(2,13,36,0.1)",
      }}
    >
      <div className="flex flex-col items-start justify-start w-full gap-6 px-3 py-4">
        <div className="flex flex-col items-start justify-start w-full ">
          <div className="relative flex items-center justify-between w-full pb-2 ">
            <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
              Tags
            </p>
            {communities.canEditTagsCommunities && (
              <button
                title="edit"
                onClick={() =>
                  dispatch(
                    openEditCommunityTagsModalAction({
                      tagIds: (communityProfile.communityTags || []).map(
                        (p: any) => ({
                          label: p?.name,
                          value: p?.id,
                        })
                      ),
                    })
                  )
                }
                type="button"
              >
                <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl">
                  <PencilAltIcon />
                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
                    <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-secondaryMid dark:text-textMain">
                      Edit
                    </p>
                  </div>
                </div>
              </button>
            )}
          </div>
          <div className="flex flex-wrap items-center justify-start gap-2">
            {communityProfile.communityTags?.map((tag: any, index: number) => {
              return (
                <Tooltip
                  title={
                    tag?.description
                    // <ToolTipComponent />
                  }
                  key={index}
                  arrow
                  placement="top"
                  sx={{
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: "red",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.3)",
                      fontSize: "1rem",
                    },
                  }}
                >
                  <div className="flex justify-center items-center gap-1 p-1 rounded-lg bg-primary/[0.08] dark:bg-primary_40">
                    <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
                      <p className="text-sm text-center text-secondaryMid dark:text-textWhite">
                        {tag.name}
                      </p>
                    </div>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ToolTipComponent = () => {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <div
        className="flex flex-col justify-start items-center w-[280px] relative space-y-[-4px] rounded-lg"
        style={{
          filter:
            "drop-shadow(0px 2px 8px rgba(2,13,36,0.14)) drop-shadow(0px 2px 24px rgba(2,13,36,0.08))",
        }}
      >
        <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
              <p className="flex-grow w-[232px] text-sm text-left text-secondaryMid">
                Our research supports sustainable management and conservation of
                Alaska marine species.
              </p>
            </div>
          </div>
          <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 flex-grow-0 flex-shrink-0 w-10 h-10 left-60"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L20 18.5858L25.2929 13.2929C25.6834 12.9024 26.3166 12.9024 26.7071 13.2929C27.0976 13.6834 27.0976 14.3166 26.7071 14.7071L21.4142 20L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L20 21.4142L14.7071 26.7071C14.3166 27.0976 13.6834 27.0976 13.2929 26.7071C12.9024 26.3166 12.9024 25.6834 13.2929 25.2929L18.5858 20L13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929Z"
              fill="#2C3236"
            />
          </svg>
        </div>
      </div>
    </Box>
  );
};
