import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { resetMap } from "store/geography";

const useResetMapOnScreenChange = (map: any, drawRef: any) => {
  const { selectedLayersItems, activeSidebarScreen } = useSelector(
    (state: RootState) => state.geography
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(activeSidebarScreen){
    }
    if (
      ((selectedLayersItems && selectedLayersItems.length > 0) ||
        (drawRef)) &&
      map.current &&
      map.current.isStyleLoaded() &&
      activeSidebarScreen
    ) {
      
      dispatch(resetMap(true));
    }
  }, [activeSidebarScreen]);

  return null;
};

export { useResetMapOnScreenChange };
