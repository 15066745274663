import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";

import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { setDoResetMap, updateSelectedLayersItems } from "store/surveyJS";
import { setDrawShapeGeometry } from "store/surveyJS/reducer.actions";

const useResetMapOnRequest = (
  map: any,
  drawRef: any,
  isMapFUllyLoaded: boolean
) => {
  const { geography } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );

  const { doResetMap, selectedLayersItems } = geography;

  const dispatch = useDispatch();

  useEffect(() => {
    if (doResetMap && map.current && isMapFUllyLoaded) {
      // remove drawn layers from map
      if (selectedLayersItems) {
        const allPaintedLayers = map.current
          ?.getStyle()
          ?.layers.filter((l: any) => l.id.includes("layer"));
        if (allPaintedLayers?.length > 0) {
          allPaintedLayers.forEach((l: any) => {
            map.current.removeLayer(l.id);
            map.current.removeSource(l.source);
          });
        }

        dispatch(updateSelectedLayersItems(null));
      }
      // remove drawn shapes from map
      if (drawRef) {
        drawRef.deleteAll();
        dispatch(setDrawShapeGeometry(null));
      }
    }

    // reset resetMap state
    if (doResetMap) {
      dispatch(setDoResetMap(false));
    }
  }, [doResetMap]);

  return null;
};

export { useResetMapOnRequest };
