import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeVideoLightBoxModalAction } from "store/modals/reducer.actions";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import VideoThumbnail from "./VideoThumbnail.jpeg";
export function VideoLightBox() {
  const [videoSlides, setVideoSlides] = useState<
    {
      type: "video";
      width: number;
      height: number;
      poster: string;
      sources: { src: string; type: string }[];
    }[]
  >([]);

  const { videoLightBoxModal } = useSelector(
    (state: RootState) => state.modals
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeVideoLightBoxModalAction());
  };

  function generateSlideWithCustomPath(
    filePath: string,
    fileType: string
  ): {
    type: "video";
    width: number;
    height: number;
    poster: string;
    sources: { src: string; type: string }[];
  }[] {
    return [
      {
        type: "video",
        width: 3840,
            height: 2560,
        poster: VideoThumbnail,
        sources: [
          {
            src: filePath,
            type: `video/${fileType?.toLowerCase()}`,
          },
        ],
      },
    ];
  }

  useEffect(() => {
    if (videoLightBoxModal.isOpen) {
      const { filePath, fileType } = videoLightBoxModal.data;
      const slides = generateSlideWithCustomPath(filePath, fileType);
      setVideoSlides(slides);
    }
  }, [videoLightBoxModal.isOpen, videoLightBoxModal.data]);

  return (
    <Lightbox
      open={videoLightBoxModal.isOpen}
      close={handleClose}
      slides={videoSlides}
      plugins={[Video]}
      render={{
        iconPrev: () => null,
        iconNext: () => null,
      }}
      video={{
        controls: true,
        playsInline: true,
        autoPlay: false,
        loop: false,
        muted: false,
        disablePictureInPicture: false,
        disableRemotePlayback: false,
        controlsList: "",
        crossOrigin: "",
        preload: "",
      }}
    />
  );
}
