import { apiClient } from "../config";

async function assignRole(roleId: any, userId: any) {
  const url = `/admin/assign-roles`;
  const res = await apiClient.put(url, {
    roleId: roleId,
    userId: userId,
  });
  return res.data;
}

export default assignRole;
