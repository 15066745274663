import HeaderBar from "Components/Geography/HeaderBar";
import MapBox from "Components/Geography/MapBox";
import SideBar from "Components/Geography/SideBar";

export const GeographyMap = () => {
  return (
    <main
      className="flex flex-col w-full overflow-hidden"
      style={{ height: "calc(99vh)" }}
    >
      <HeaderBar />
      <div className="flex justify-between w-full h-full ">
        <MapBox />
        <SideBar />
      </div>
    </main>
  );
};

export default GeographyMap;
