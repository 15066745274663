import React from "react";
// Components
import {
  Date,
  Search,
  SelectedPrograms,
  SelectedCommunities,
  SelectedObservers,
  SelectedForms,
  SelectedSpecies
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  observationsResetFiltersAction,
  observationsResetFiltersExceptCommunityAction,
  observationsResetFiltersExceptProgramAction,
} from "store/filters/reducer.actions";
import {
  filtersInitialState,
  observationsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting programsFilter state from Redux store
  const { observationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting organizationId from URL parameters
  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();

 

  const clearAllFilters = () => {
    // if (programId) {
    //   dispatch(observationsResetFiltersExceptProgramAction());
    // } else if (communityId) {
    //   dispatch(observationsResetFiltersExceptCommunityAction());
    // } else {
    //   dispatch(observationsResetFiltersAction());
    // }
    dispatch(observationsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyObservationsFilterApplied(observationsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
            <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Search />
          <SelectedObservers />
          <SelectedPrograms />
          <SelectedCommunities />
          <Date
            name="date"
            from={observationsFilter.dateAdded.from}
            to={observationsFilter.dateAdded.to}
            isApplied={observationsFilter.dateAdded?.applied}
          />
          <SelectedForms />
          <SelectedSpecies />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;



 // Function to check if any filter is applied
export function isAnyObservationsFilterApplied (
  observationsFilter: observationsDirectoryFilterState
): boolean  {
  const {
    searchBy,
    searchString,
    dateAdded,
    communityIds,
    programIds,
    observerIds,
    formIds,
    specieIds
  } = observationsFilter;
  const applied =
    (searchBy && searchString !== "") ||
    dateAdded?.applied ||
    programIds?.applied ||
    observerIds?.applied ||
    communityIds?.applied ||
    formIds?.applied ||
    specieIds?.applied;
  return applied;
};