import React from "react";
import AttachFilePlaceholder from "assets/images/Placeholders/attach-files.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import { IProtocol } from "store/protocolProfile/initialState";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { Toasts } from "view/components/Toasts";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import { identifyFileType } from "utils/IdentifyFileType";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import {
  openImageLightBoxModalAction,
  openVideoLightBoxModalAction,
} from "store/modals";

export const FilesListingCard = () => {
  const protocolProfile = useSelector<RootState, IProtocol>(
    (state) => state?.protocolProfile
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleDownload = async (url: any, fileName: any, type: any) => {
    dispatch(setLoadingProgress(getRandomProgress()));
    if (type === "pdf" || type === "PDF") {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName; // Specify the desired file name

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link element
        document.body.removeChild(link);
        Toasts.success(`${fileName} Is Downloaded Successfully`);
      } catch (error: any) {
        console.error("Error downloading file:", error);
        Toasts.success(`Failed To Download  ${fileName} `);
      } finally {
        dispatch(setLoadingProgress(100));
      }
    } else {
      try {
        const response = await fetch(url.original);
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName; // Specify the desired file name

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link element
        document.body.removeChild(link);
        Toasts.success(`${fileName} Is Downloaded Successfully`);
      } catch (error: any) {
        console.error("Error downloading file:", error);
        Toasts.success(`Failed To Download  ${fileName} `);
      } finally {
        dispatch(setLoadingProgress(100));
      }
    }
  };
  function getFileType(filePath: any, type: any) {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff|JPG|PNG|JPEG|GIF|BMP)$/)) {
      dispatch(
        openImageLightBoxModalAction({
          width: 1080,
          height: 1620,
          filePath: filePath.original,
        })
      );
    } else if (type === "pdf" || type === "PDF") {
      window.open(filePath, "_blank");
    } else if (type.match(/(mp4|avi|mkv|wmv|flv|mov)$/)) {
      dispatch(
        openVideoLightBoxModalAction({
          filePath: filePath.original,
          fileType: type,
        })
      );
    } else if (type === "mp3") {
      window.open(filePath.original, "_blank");
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      // Handle document files
    } else if (type.match(/(txt|csv)$/)) {
      // Handle text files
    } else if (type.match(/(zip|rar|7z)$/)) {
      // Handle archive files
    } else {
      return "unknown";
    }
  }
  const protocolFilesActions = (link: any, fileName: string, type: any) => {
    return [
      {
        label: "Download",
        onClick: () => {
          handleDownload(link, fileName, type);
        },
        divider: false,
        isVisible: true,
      },
      {
        label: "Copy link",
        onClick: () => {
          copyLinkToClipboard(link, type);
        },
        divider: false,
        isVisible: true,
      },
    ];
  };

  const copyLinkToClipboard = async (link: any, type: any) => {
    if (type === "pdf" || type === "PDF") {
      try {
        await navigator.clipboard.writeText(link);
        Toasts.copy("Link copied to clipboard", "bottom-center");
      } catch (error: any) {
        Toasts.error("Failed to copy link to clipboard");
      }
    } else {
      try {
        await navigator.clipboard.writeText(link.original);
        Toasts.copy("Link copied to clipboard", "bottom-center");
      } catch (error: any) {
        Toasts.error("Failed to copy link to clipboard");
      }
    }
  };

  return (
    <div className="flex flex-col p-6 px-12 rounded-lg">
      <div className="flex items-center justify-between mb-1">
        <p className="pl-3 text-base font-semibold text-secondaryMid dark:text-textMain">
          {`${protocolProfile?.totalFiles} Attached Files`}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-6">
        {protocolProfile?.files?.map((file) => {
          const fileType = identifyFileType(file.type);
          return (
            <div
              key={file?.id}
              className="flex items-center justify-between w-full px-2 rounded-lg hover:bg-primaryExtraLight"
            >
              <div
                className="flex items-center w-full"
                onClick={() => {
                  getFileType(file.file, file.type);
                }}
              >
                <div
                  className={`rounded-full w-9 h-9 shrink-0  ${
                    fileType !== "pdf" ? "overflow-hidden" : ""
                  }`}
                >
                  <img
                    src={
                      fileType == "video"
                        ? videoThumbnailImg
                        : fileType == "pdf"
                        ? pdfThumbnailImg
                        : file?.file?.thumb
                    }
                    alt={file?.name}
                    className={`w-full h-full ${
                      fileType == "image" ? "object-cover" : ""
                    }`}
                  />
                </div>
                <div className="w-full ml-4">
                  <p className="text-[15px] break-all w-full pt-1 dark:text-textMain">
                    {file?.name}
                  </p>
                  <div className="flex items-center">
                    <span className="text-[12px] dark:text-caption ">
                      {file?.fileSize}
                    </span>
                    <DotCentralIcon />
                    <span className="text-xs dark:text-caption ">Uploaded</span>
                    <span className="text-[12px] dark:text-caption  ml-[3px]">
                      {file?.uploadedDate
                        ? `${moment(file?.uploadedDate).format("DD MMM YYYY")} `
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="items-center ">
                <PopOverMenu icon={<DotsVerticalIcon />}>
                  <ActionDropDownMenu
                    actions={protocolFilesActions(
                      file?.file,
                      file?.name,
                      file.type
                    )}
                  />
                </PopOverMenu>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
