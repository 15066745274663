import React, { useLayoutEffect } from "react";
import {
  Filters,
  Header,
  Pagination,
  Table,
  Tabs,
} from "Components/DynamicForms";
import { DynamicFormsTable } from "hooks/useTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { updateColumnsVisibilityByStatus } from "store/directoriesOrders/reducer.actions";
import { fetchDynamicFormsAction } from "store/dynamicForms";
import { IfetchDynamicForms } from "store/dynamicForms/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { useLocation, useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import { fetchFormDetailsAction } from "store/formDetails/reducer.actions";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { setDynamicFormsFiltersAction } from "store/filters/reducer.actions";

export const Forms = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formId = queryParams.get("formId");
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { primary } = useSelector((state: any) => state.theme.colors);

  useLayoutEffect(() => {
    dispatch(setDynamicFormsFiltersAction());
  }, []);
  const { data, stateIs } = useSelector<RootState, IfetchDynamicForms>(
    (state) => state?.allforms
  );
  const { dynamicFormsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { dynamicForms } = usePermissions();

  const { programId, communityId } = useParams();
  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = DynamicFormsTable(data?.dynamicForms);

  useEffect(() => {
    if (
      dynamicFormsFilter.dateUpdated.from === "Invalid date" ||
      dynamicFormsFilter.dateUpdated.to === "Invalid date"
    ) {
      return;
    }
    dispatch(fetchDynamicFormsAction(communityId, programId));
    dispatch(updateColumnsVisibilityByStatus(dynamicFormsFilter.status));
    saveFiltersDataToLocalStorage(dynamicFormsFilter);
  }, [dynamicFormsFilter]);

  useEffect(() => {
    if (formId && parseInt(formId)) {
      const parsedFormId = parseInt(formId);
      dispatch(
        fetchFormDetailsAction({
          id: parsedFormId,
          path: "directory",
        })
      );
    }
  }, [formId]);

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters columns={columnsConfig} />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs == "Idle" && data?.dynamicForms?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color={primary}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              isRowSelected={isRowSelected}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              setSelectedRows={setSelectedRows}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default Forms;
