import React, { useEffect } from "react";

const useHandleControlsForEditMode = (
  isMapFullyLoaded: boolean,
  editMode: boolean
) => {
  console.log("editMode",editMode)
  useEffect(() => {
    if (document && isMapFullyLoaded) {
      const drawPolygonControl = document.querySelectorAll(
        "button.mapbox-gl-draw_polygon"
      ) as NodeList;
      const trashControls = document.querySelectorAll(
        "button.mapbox-gl-draw_trash"
      ) as NodeList;
      const dropPinControl = document.querySelectorAll(
        "button.mapbox-gl-draw_point"
      ) as NodeList;

      
        if(drawPolygonControl){
          Array.from(drawPolygonControl).forEach((control:any)=>{
            control.style.display = editMode ? "block" : "none";
          });
        };
        if(trashControls){
          Array.from(trashControls).forEach((control:any)=>{
            control.style.display = editMode ? "block" : "none";
          });
        };
        if(dropPinControl){
          Array.from(dropPinControl).forEach((control:any)=>{
            control.style.display = editMode ? "block" : "none";
          });        };
        
    }
  }, [editMode, isMapFullyLoaded]);

  return null;
};

export { useHandleControlsForEditMode };
