import createLayer from "./createLayer";
import editLayer from "./editLayer";
import getAllLayers from "./getAllLayers";
import updateLayerStatus from "./updateLayerStatus";
import getVectorLayersByCommunityId from "./getVectorLayersByCommunityId";
import getGlobalVectorLayers from "./getGlobalVectorLayers";
import getAllLayersForAdmins from "./getAllLayersForAdmins";
import updateLayerStatusForAdmins from "./updateLayerStatusForAdmins";
import editLayerForAdmin from "./editLayerForAdmin";
import createLayerForAdmin from "./createLayerForAdmin";
import getAllLayersList from "./getAllLayersList";
import updateGlobalLayerStatus from "./updateGlobalLayerStatus";
import getSingleLayer from "./getSingleLayer";
import getSingleGlobalLayer from "./getSingleGlobalLayer";

const geography = {
  createLayer,
  editLayer,
  getAllLayers,
  updateLayerStatus,
  getVectorLayersByCommunityId,
  getGlobalVectorLayers,
  getAllLayersForAdmins,
  updateLayerStatusForAdmins,
  editLayerForAdmin,
  createLayerForAdmin,
  getAllLayersList,
  updateGlobalLayerStatus,
  getSingleLayer,
  getSingleGlobalLayer
};

export default geography;
