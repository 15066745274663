import { debounce } from 'lodash';

// Define a generic function type
type GenericFunction = (...args: any[]) => any;

/**
 * Creates a debounced function that delays invoking the provided function 
 * until after the specified wait time has elapsed since the last time 
 * the debounced function was invoked.
 *
 * @param func - The function to debounce.
 * @param wait - The number of milliseconds to delay.
 * @return - The new debounced function.
 */
export function createDebouncedFunction<T extends GenericFunction>(func: T, wait: number): T {
  return debounce(func, wait) as unknown as T;
}
