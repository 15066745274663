import { ERROR_LOADING_IMPORT_SUMMARY, GET_IMPORT_SUMMARY, START_LOADING_IMPORT_SUMMARY, STOP_LOADING_IMPORT_SUMMARY } from "./action-types";
import { initialState } from "./initialState";

const importSummaryReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case START_LOADING_IMPORT_SUMMARY:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_IMPORT_SUMMARY:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_IMPORT_SUMMARY:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_IMPORT_SUMMARY:
      return {
        ...state,
        data: action.payload,
        totalPages: action.payload.totalPages,
        message: "",
        stateIs: "Idle",
      };

      return;
    case "RESET_COMMUNITY_DATA":
      return initialState;
    default:
      return state;
  }
};

export default importSummaryReducer;
