import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { closeCreateOrganizationModalAction } from 'store/modals/reducer.actions';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import { useNavigate, useParams } from 'react-router-dom';
import { OrganizationForm } from './components/OrganizationForm';
import AddAdmin from './components/AddAdmin';
import { AddCommunity } from './components/AddCommunity';
import { fetchOrganizationsAction } from 'store/oranizations';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchOrganizationProfileAction } from 'store/organizationProfile/reducer.actions';
import organizations from 'services/api/organizations';
import usePermissions from 'hooks/usePermissions';
import { getAppName } from 'utils/customBranding';
import useRoles from 'hooks/roles';

const style: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  // width: "35%",
  transform: 'translate(-50%, -50%)',
  borderRadius: '15px',
  backgroundColor: '#ffff',
};

interface CreateOrganizationModalProps { }

export const CreateOrganizationModal: React.FC<
  CreateOrganizationModalProps
> = () => {
  const { createOrganizationModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [organizationId, setOrganizationId] = useState('');

  const steps = ['Community Details', 'Add Admins', 'Add Organizations'];
  const navigate = useNavigate();
  const { organizations } = usePermissions();
  const { communityId, programId } = useParams<{
    communityId: string;
    programId: string;
  }>();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { isSuperAdmin } = useRoles();
  // Store the validation schema for each step separately
  const createOrganizationValidationSchema = [
    Yup.object().shape({
      missionStatement: Yup.string()
        .nullable()
        .max(6000, '6000 Max Characters'),
      name: Yup.string()
        .required('Organization Name is required.')
        .max(255, 'Organization Name cannot be more than 255 characters.'),
      appName: Yup.object().shape({
        value: Yup.string().required('App Is Required'),
        label: Yup.string().required('App Is Required'),
      }),
      organizationImageId: Yup.number().nullable(),
      profileImage: Yup.string().nullable(),
      tagIds: Yup.array(),
    }),
    Yup.object().shape({
      communityIds: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number().required('Community ID is required'),
          })
        )
        .required('Community IDs are required'),
    }),
    Yup.object().shape({
      adminIds: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required('Admin ID is required'),
        })
      ),
    }),
  ];

  const currentValidationSchema =
    createOrganizationValidationSchema[createOrganizationModal.editMode
      ? createOrganizationModal.data.openAtStep
      : activeStep];

  const handleClose = () => {
    dispatch(closeCreateOrganizationModalAction());
    setActiveStep(0);
    setInitialValues({
      communityIds: [],
      missionStatement: '',
      name: '',
      organizationImageId: null,
      coverImageId: null,
      tagIds: [],
      adminIds: [],
      profileImage: null,
      coverImage: null,
      appName: getAppName(isSuperAdmin()),
    });
  };

  const [initialValues, setInitialValues] = useState({
    communityIds: [],
    missionStatement: '',
    name: '',
    organizationImageId: null,
    coverImageId: null,
    tagIds: [],
    adminIds: [],
    profileImage: null,
    coverImage: null,
    appName: getAppName(isSuperAdmin()),
  });

  const isLastStep = activeStep === steps.length - 2;

  const updateOrganization = async (id: any, updatedValues: any) => {
    const response = await apiLibrary.OrganizationDirectory.updateOrganization(
      id,
      updatedValues
    );
    return response.message;
  };

  const createOrganization = async (values: any) => {
    const response =
      await apiLibrary.OrganizationDirectory.createOrgaznization(values);
    return { id: response.data.id, message: response.message };
  };

  const addAdminsToOrganization = async (
    organizationId: string,
    adminIds: string[]
  ) => {
    await apiLibrary.OrganizationDirectory.addAdminsToOrganization(
      createOrganizationModal.editMode
        ? createOrganizationModal?.data?.id
        : organizationId,
      adminIds
    );
    return 'Added admins to the org';
  };

  const _submitForm = async (
    values: any,
    {
      setSubmitting,
      validateForm,
    }: { setSubmitting: (isSubmitting: boolean) => void; validateForm: any }
  ) => {
    try {
      const { data, editMode } = createOrganizationModal;
      const { id } = data;
      const { appName, ...restValues } = values;
      const updatedValues = {
        ...restValues,
        communityIds: values.communityIds.map((community: any) => community.id),
        tagIds: values.tagIds.map((item: any) => item.value),
      };

      //  Only add appName if not in edit mode
      if (!createOrganizationModal?.editMode) {
        updatedValues.appName = appName?.value;
      }

      let successMessage = '';

      if (editMode && data.openAtStep !== 2) {
        successMessage = await updateOrganization(id, updatedValues);
        dispatch(fetchOrganizationProfileAction(data.id));
        handleClose();
      } else {
        if (activeStep === 1) {
          const { id: createdId, message } =
            await createOrganization(updatedValues);
          setOrganizationId(createdId);
          successMessage = message;
          if (values.communityIds.length > 0 && organizations.canCreateAdmins) {
            setActiveStep((prevStep: any) => prevStep + 1);
          } else {
            dispatch(fetchOrganizationsAction(communityId, programId));
            handleClose();
          }
        } else if (
          activeStep === 2 &&
          values.communityIds.length > 0 &&
          organizations.canCreateAdmins
        ) {
          successMessage = await addAdminsToOrganization(
            organizationId,
            values.adminIds.map((item: any) => item.id)
          );
          setActiveStep(0);
          navigate(`/organizations/${organizationId}/profile`);
          handleClose();
        } else if (
          data.openAtStep &&
          values.communityIds.length > 0 &&
          organizations.canCreateAdmins
        ) {
          successMessage = await addAdminsToOrganization(
            organizationId,
            values.adminIds.map((item: any) => item.id)
          );
          dispatch(fetchOrganizationProfileAction(data.id));
          setActiveStep(0);
          handleClose();
        } else {
          dispatch(fetchOrganizationProfileAction(data.id));
          setActiveStep(0);
          handleClose();
        }
      }
      if (successMessage !== '') {
        Toasts.success(successMessage);
      }

      // dispatch(fetchOrganizationsAction(communityId, programId));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  function _handleSubmit(
    values: any,
    actions: FormikHelpers<any> | any // Use FormikHelpers<any> or 'any' for compatibility
  ) {
    if (createOrganizationModal.editMode) {
      _submitForm(values, actions);
    } else {
      if (activeStep !== 0) {
        _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  function _renderStepContent(step: number, formikProps: FormikProps<any>) {
    switch (step) {
      case 0:
        return (
          <OrganizationForm
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 1:
        return (
          <AddCommunity
            {...formikProps}
            handleClose={handleClose}
            {...formikProps}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 2:
        return (
          <AddAdmin
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
            organizationId={organizationId}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  return (
    <Modal
      open={createOrganizationModal.isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      disableAutoFocus={true}
      aria-describedby='modal-modal-description'
      className='border-none'
    >
      <Box sx={style} className='dark:bg-secondaryLight '>
        <Formik
          initialValues={createOrganizationModal.editMode ? createOrganizationModal?.data : initialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={false}
        >
          {(formikProps) => (
            <Form>
              <div className='flex flex-col justify-start items-start w-[700px] rounded-lg'>
                <div
                  className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight'
                  style={{
                    boxShadow:
                      '0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)',
                  }}
                >
                  {_renderStepContent(
                    createOrganizationModal.editMode
                      ? createOrganizationModal.data.openAtStep
                      : activeStep,
                    formikProps
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
