import addReport from "./addReport";
import addReportExporting from "./addReportExporting";
import addReportFiltering from "./addReportFiltering";
import addReportGeospatialFilter from "./addReportGeospatialFilter";
import addReportPermissions from "./addReportPermissions";
import addReportSettings from "./addReportSettings";
import choseReportColumns from "./choseReportColumns";
import getReportColumns from "./getReportColumns";
import updateReport from "./updateReport";
import getReportGeospatialColumns from "./getReportGeospatialColumns";

const AddReportApis = {
  addReport,
  addReportPermissions,
  addReportSettings,
  updateReport,
  addReportExporting,
  getReportColumns,
  choseReportColumns,
  addReportGeospatialFilter,
  addReportFiltering,
  getReportGeospatialColumns
};

export default AddReportApis;
