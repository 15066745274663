import { Ifetch } from "../initialState";

interface IInnerObj {
  id: number;
  label: string;
  color: string;
  value: number;
}

const innerObj = {
  id: 1,
  label: "",
  color: "",
  value: 0,
};
export interface IQAProgress {
  completed: IInnerObj;
  inReview: IInnerObj;
  unAssigned: IInnerObj;
}

const QAProgress = {
  completed: innerObj,
  inReview: innerObj,
  unAssigned: innerObj,
};

export interface IfetchQAProgress extends Ifetch {
  data: IQAProgress;
}

const qaProgressInitialState: IfetchQAProgress = {
  data: QAProgress,
  message: "",
  stateIs: "Pending",
};
export default qaProgressInitialState;
