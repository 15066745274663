import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { closeImageVideoLightBoxModalAction } from 'store/modals/reducer.actions';
import Lightbox, {
  IconButton,
  createIcon,
  useLightboxState,
} from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/plugins/thumbnails.css'; // Import custom thumbnail component
import VideoThumbnail from './VideoThumbnail.jpeg';
import { DownloadIcon, PlayIcon } from 'assets/icons/HeroIcons';

interface ImageSlide {
  src: string;
  width: number;
  height: number;
  fileId: number;
  type: string;
  srcSet: { src: string; width: number; height: number }[];
}

interface VideoSlide {
  type: string;
  width: number;
  height: number;
  fileId: number;
  poster: string;
  sources: { src: string; type: string }[];
}

type Slide = ImageSlide | VideoSlide;

export function ImageVideoLightBox() {
  const [mediaSlides, setMediaSlides] = useState<any[]>([]);
  const { imageVideoLightBoxModal } = useSelector(
    (state: RootState) => state.modals
  );

  const [selectedFileIndex, setSelectedFileIndex] = useState(0);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeImageVideoLightBoxModalAction());
  };

  function generateImageSlides(
    filePaths: { url: string; fileId: number }[],
    width: number = 3840,
    height: number = 2560
  ): ImageSlide[] {
    const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
    return filePaths?.map(({ url, fileId }) => ({
      src: url,
      width: width,
      height: height,
      fileId: fileId,
      type: 'custom-image-slide',
      srcSet: breakpoints?.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: url,
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    }));
  }

  function generateVideoSlides(
    filePaths: { url: string; type: string; fileId: number }[],
    width: number = 3840,
    height: number = 2560
  ): VideoSlide[] {
    return filePaths?.map(({ url, type, fileId }) => ({
      type: 'custom-video-slide',
      width: width,
      height: height,
      fileId: fileId,
      poster: VideoThumbnail,
      sources: [
        {
          src: url,
          type: `video/${type?.toLowerCase()}`,
        },
      ],
    }));
  }

  const videoExtensions: string[] = [
    'mp4',
    'webm',
    'ogg',
    'avi',
    'mov',
    'mkv',
    'flv',
    'wmv',
    '3gp',
    'mpeg',
    'ts',
    'divx',
    'xvid',
  ];

  const isVideoType = (type: string) =>
    videoExtensions.includes(type.toLowerCase());

  useEffect(() => {
    if (imageVideoLightBoxModal.isOpen && imageVideoLightBoxModal.data) {
      const { data } = imageVideoLightBoxModal;
      const slides = [...mediaSlides];

      data?.urls?.forEach(
        (
          {
            url,
            type,
            fileId,
          }: {
            url: string;
            type: string;
            fileId: number;
          },
          index: number
        ) => {
          if (fileId === data?.fileId) {
            setSelectedFileIndex(index);
          }

          if (isVideoType(type)) {
            slides.push(...generateVideoSlides([{ url, type, fileId }]));
          } else {
            slides.push(...generateImageSlides([{ url, fileId }]));
          }
        }
      );
      setMediaSlides(slides);
    }
  }, [imageVideoLightBoxModal.isOpen, imageVideoLightBoxModal.data]);

  const hasMultipleSlides = mediaSlides?.length > 1;

  const handleDownloadClick = async () => {
    const downloadUrl =
      mediaSlides[selectedFileIndex]?.src ||
      mediaSlides[selectedFileIndex]?.sources?.[0]?.src;

    if (!downloadUrl) {
      console.error('No download URL available');
      return;
    }

    try {
      const response = await fetch(downloadUrl);

      if (!response.ok) {
        console.error('Fetch error:', response.status, response.statusText);
        return;
      }

      const filename = decodeURIComponent(
        downloadUrl.split('/').pop().split('?')[0]
      );

      const blob = await response.blob();
      const link = document.createElement('a');
      const blobUrl = URL.createObjectURL(blob);

      link.href = blobUrl;
      link.download = filename || 'file';
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const DownloadBtnIcon = createIcon(
    'DownloadBtnIcon',
    <DownloadIcon fill='#cccccc' />
  );

  function DownloadButton() {
    const { currentSlide, currentIndex } = useLightboxState();
    setSelectedFileIndex(currentIndex);

    return (
      <IconButton
        label='My button'
        disabled={!currentSlide}
        icon={DownloadBtnIcon}
        onClick={handleDownloadClick}
      />
    );
  }

  return (
    <Lightbox
      toolbar={{
        buttons: [<DownloadButton key='my-button' />, 'close'],
      }}
      index={selectedFileIndex}
      open={imageVideoLightBoxModal.isOpen}
      close={handleClose}
      slides={mediaSlides}
      plugins={hasMultipleSlides ? [Video, Thumbnails] : [Video]}
      render={{
        iconPrev: hasMultipleSlides ? undefined : () => null,
        iconNext: hasMultipleSlides ? undefined : () => null,
        slide: ({ slide }: any) => {
          return (
            <div>
              {slide.type === 'custom-video-slide' ? (
                <video
                  src={slide?.sources[0]?.src}
                  preload='auto'
                  width='800'
                  height='500'
                  controls
                />
              ) : (
                <img
                  src={slide?.src}
                  srcSet={slide?.srcSet
                    ?.map(({ src, width }: any) => `${src} ${width}w`)
                    .join(', ')}
                  alt='Image Thumbnail'
                />
              )}
            </div>
          );
        },
        thumbnail: ({ slide }: any) => {
          return (
            <div>
              {slide.type === 'custom-video-slide' ? (
                <div className='relative w-full h-full'>
                  <video
                    src={slide?.sources[0]?.src}
                    preload='auto'
                    className='w-full h-full object-cover'
                    muted
                    playsInline
                  />
                  <PlayIcon
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    fill='#fff'
                  />
                </div>
              ) : (
                <img
                  src={slide?.src}
                  alt='Image Thumbnail'
                  className=' w-full h-full'
                />
              )}
            </div>
          );
        },
      }}
      video={{
        controls: true,
        playsInline: true,
        autoPlay: false,
        loop: false,
        muted: false,
        disablePictureInPicture: false,
        disableRemotePlayback: false,
        controlsList: '',
        crossOrigin: '',
        preload: '',
      }}
    />
  );
}
