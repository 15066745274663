import { Ifetch } from "../initialState";

export interface INotifications {
  description: string;
  duration: string;
  name: string;
  id: number;
  personId: number;
  personImage: {
    original: string;
    thumb: string;
  } | null;
}

export interface IfetchNotifications extends Ifetch {
  data: INotifications[];
}

const notificationsInitialState: IfetchNotifications = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default notificationsInitialState;
