import { ClearFilters } from "Components/Users/ClearFilters";
import { sentenceCase } from "change-case";
import { useDispatch } from "react-redux";
import { pollsAndSurveysResponsesAction } from "store/filters/reducer.actions";
import formatValue from "utils/formatValues";

interface ResponsesProps {
  name: string;
  min: number;
  max: number;
  isApplied: boolean;
}

export const Responses: React.FC<ResponsesProps> = ({
  name,
  min,
  max,
  isApplied,
}) => {
  const dispatch = useDispatch();

  const resetFilter = () => {
    dispatch(pollsAndSurveysResponsesAction(name, 0, 10000, false));
  };

  if (isApplied) {
    const appliedFilters = (
      <div
        className="flex justify-between px-2  items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center capitalize text-textMid dark:text-inputText ">
            {sentenceCase(name)}: {formatValue(min)} - {formatValue(max)}
          </p>
        </div>
        <ClearFilters handleClick={resetFilter} />
      </div>
    );

    return appliedFilters;
  } else {
    return null;
  }
};
