import apiClient from "services/apiClient";

async function duplicateProtocol(data: any) {
  const url = `/admin/protocols`;

  const res = await apiClient.post(url, data);

  return res.data;
}

export default duplicateProtocol;
