import {
  SAVE_ALL_LAYERS_FETCHED_DATA,
  SHOW_ADD_RASTER_LAYER_SCREEN,
  SHOW_ADD_REGION_LAYER_SCREEN,
  SHOW_ADD_SHAPES_SCREEN,
  SHOW_ADD_VECTOR_LAYER_SCREEN,
  SHOW_ALL_LAYERS_LISTING_SCREEN,
  SHOW_EDIT_RASTER_LAYER_SCREEN,
  SHOW_VIEW_RASTER_LAYER_SCREEN,
  SHOW_EDIT_VECTOR_LAYER_SCREEN,
  SHOW_VIEW_VECTOR_LAYER_SCREEN,
  SHOW_EDIT_REGION_LAYER_SCREEN,
  SHOW_EDIT_SHAPE_LAYER_SCREEN,
  DISPLAY_RASTER_LAYER_ON_MAP,
  DISPLAY_DEFAULT_MAP,
  DISPLAY_REGION_LAYER_ON_MAP,
  UPDATE_SELECTED_SHAPE,
  SHOW_VIEW_POINT_LAYER_SCREEN,
  SHOW_EDIT_POINT_LAYER_SCREEN,
  SHOW_VIEW_REGION_LAYER_SCREEN,
  SHOW_VIEW_SHAPE_LAYER_SCREEN,
  SHOW_ADD_POINTS_LAYER_SCREEN,
  UPDATE_DRAW_SHAPES_COORDINATES,
  DISPLAY_SHAPE_LAYER_ON_MAP,
  DISPLAY_POINT_LAYER_ON_MAP,
  UPDATE_SELECTED_LAYERS_ITEMS_DATA,
  UPDATE_ZOOM_TO_SELECTION_ITEM,
  DO_RESET_MAP,
  REMOVE_ALL_DRAW_SHAPES,
  DO_FETCH_ALL_LAYERS_DATA,
  SHOW_LOADER_LAYER,
  LAYERS_DATA_TYPE,
  DISPLAY_POINT_LAYER_BY_PICK_ON_MAP,
  SHOW_SNAPING_CONTROL,
  SET_SELECTED_LAYER_TO_REGION_SELECT_FORM,
  SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM,
  SET_SELECTED_POINT_TO_POINT_SELECT_FORM,
  CLEAR_SELECTED_LAYERS_ITEMS_DATA,
  REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN,
  COMMUNITY_CENTER_POINT
} from "./action-types";

import initialStateOfGeography, {
  I_InitialStateOfGeography,
  screens,
} from "./initialState";

export const geographyReducer = (
  state: I_InitialStateOfGeography = initialStateOfGeography,
  action: any
) => {
  switch (action.type) {
    case SHOW_ALL_LAYERS_LISTING_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ALL_LAYERS,
      };

    case SHOW_ADD_RASTER_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ADD_RASTER_LAYER,
      };

    case SHOW_ADD_VECTOR_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ADD_VECTOR_LAYER,
      };

    case SHOW_ADD_REGION_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ADD_REGION_LAYER,
      };

    case SHOW_EDIT_RASTER_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.EDIT_RASTER_LAYER,
        editRasterLayerData: action.payload,
      };

    case SHOW_EDIT_VECTOR_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.EDIT_VECTOR_LAYER,
        editVectorLayerData: action.payload,
      };
    case SHOW_EDIT_REGION_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.EDIT_REGION_LAYER,
        editRegionData: action.payload,
      };

    case SHOW_EDIT_SHAPE_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.EDIT_SHAPE_LAYER,
        editShapeData: action.payload,
      };

    case SHOW_VIEW_RASTER_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.VIEW_RASTER_LAYER,
        viewRasterLayerData: action.payload,
      };

    case SHOW_VIEW_VECTOR_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.VIEW_VECTOR_LAYER,
        viewVectorLayerData: action.payload,
      };
    case SHOW_VIEW_REGION_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.VIEW_REGION_LAYER,
        viewRegionLayerData: action.payload,
      };

    case SHOW_VIEW_SHAPE_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.VIEW_SHAPE_LAYER,
        viewShapeLayerData: action.payload,
      };

    case SAVE_ALL_LAYERS_FETCHED_DATA:
      return {
        ...state,
        allLayers: action.payload,
      };

    case SHOW_ADD_SHAPES_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ADD_SHAPE,
      };

    case DISPLAY_RASTER_LAYER_ON_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: action.payload,
      };

    case DISPLAY_DEFAULT_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: undefined,
      };

    case DISPLAY_REGION_LAYER_ON_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: action.payload,
      };

    case DISPLAY_SHAPE_LAYER_ON_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: action.payload,
      };

    case DISPLAY_POINT_LAYER_ON_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: action.payload,
      };

    case DISPLAY_POINT_LAYER_BY_PICK_ON_MAP:
      return {
        ...state,
        currentLayerToDisplayOnMap: action.payload,
      };

    case UPDATE_SELECTED_SHAPE:
      return {
        ...state,
        currentSelectedShape: action.payload,
      };

    case SHOW_ADD_POINTS_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.ADD_POINTS_LAYER,
      };
    case SHOW_VIEW_POINT_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.VIEW_POINT_LAYER,
        viewPointLayerData: action.payload,
      };
    case SHOW_EDIT_POINT_LAYER_SCREEN:
      return {
        ...state,
        activeSidebarScreen: screens.EDIT_POINT_LAYER,
        editPointLayerData: action.payload,
      };

    case UPDATE_DRAW_SHAPES_COORDINATES:
      return {
        ...state,
        shapeCoordinates: action.payload,
      };

    case UPDATE_SELECTED_LAYERS_ITEMS_DATA:
      return {
        ...state,
        selectedLayersItems: action.payload,
      };
    case CLEAR_SELECTED_LAYERS_ITEMS_DATA:
      return {
        ...state,
        selectedLayersItems: [],
      };

    case UPDATE_ZOOM_TO_SELECTION_ITEM:
      return {
        ...state,
        selectedItemToZoom: action.payload,
      };

    case DO_RESET_MAP:
      return {
        ...state,
        doResetMap: action.payload,
       
      };

    case REMOVE_ALL_DRAW_SHAPES:
      return {
        ...state,
        doRemoveAllDrawShapes: action.payload,
      };

    case DO_FETCH_ALL_LAYERS_DATA:
      return {
        ...state,
        doFetchAllLayersData: action.payload,
      };

    case SHOW_LOADER_LAYER:
      return {
        ...state,
        showLoaderLayer: action.payload,
      };
    case LAYERS_DATA_TYPE:
      return {
        ...state,
        layersDataType: action.payload,
      };
    case SHOW_SNAPING_CONTROL:
      return {
        ...state,
        showSnapingControl: action.payload,
      };

    case SET_SELECTED_LAYER_TO_REGION_SELECT_FORM:
      return {
        ...state,
        selectedRegionLayerForRegionSelectForm: action.payload,
      };

    case SET_SELECTED_LAYER_TO_SHAPE_SELECT_FORM:
      return {
        ...state,
        selectedShapeLayerForShapeSelectForm: action.payload,
      };
    case SET_SELECTED_POINT_TO_POINT_SELECT_FORM:
      return {
        ...state,
        selectedPointLayerForPointSelectForm: action.payload,
      };

    case REMOVE_MARKER_OF_PICK_ON_MAP_SCREEN:
      return{
        ...state,
        isRemoveMarkerOfPickOnMapScreen:action.payload
      }

      case COMMUNITY_CENTER_POINT:
        return{
          ...state,
          communityCenterpoint:action.payload
        }

    default:
      return state;
  }
};
