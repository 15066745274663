import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { addCategorySchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddOrEditCategoryModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { fetchCategoriesAction } from "store/categories";
import * as Yup from "yup";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */
export const AddOrEditCategoryModal = () => {
  // State variables

  const { addOrEditCategoryModal } = useSelector(
    (state: RootState) => state.modals
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [formInitialValues, setFormInitialValues] = useState<any>({
    categoryName: "",
    categoryCode: "",
  });

  // Initialize formInitialValues when in editMode or templateMode
  useEffect(() => {
    if (addOrEditCategoryModal.editMode) {
      const { categoryCode, categoryName } = addOrEditCategoryModal?.data;
      setFormInitialValues({
        categoryCode: categoryCode,
        categoryName: categoryName,
      });
    }
  }, [addOrEditCategoryModal?.data, addOrEditCategoryModal.editMode]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const formValues = {
        ...values,
      };

      if (addOrEditCategoryModal.editMode && addOrEditCategoryModal.data.id) {
        // Edit an existing form
        const { data, message } =
          await apiLibrary.SpeciesAndObjects.editCategory(
            addOrEditCategoryModal.data.id,
            formValues
          );

        Toasts.success(message);
        handleClose();
        dispatch(fetchCategoriesAction());
      } else {
        // Add a new form
        const { data, message } =
          await apiLibrary.SpeciesAndObjects.addCategory(formValues);
        Toasts.success(message);
        handleClose();
        dispatch(fetchCategoriesAction());
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeAddOrEditCategoryModalAction());
    setFormInitialValues({
      categoryName: "",
      categoryCode: "",
    });
  };

  return (
    <Modal
      open={addOrEditCategoryModal.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={formInitialValues}
          validationSchema={addCategorySchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px]  max-h-[700px] overflow-y-auto rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid capitalize dark:text-textMain">
                        {addOrEditCategoryModal.editMode
                          ? "Edit Category"
                          : "Add Category"}
                      </p>
                      <button title="close" onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <TextInput
                        label="Category name *"
                        type="text"
                        name="categoryName"
                        setFieldValue={setFieldValue}
                        error={errors?.categoryName}
                        touched={touched.categoryName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Category Name"
                        value={values.categoryName}
                      />
                      {!(errors?.categoryName && touched?.categoryName) && (
                        <div className="py-0.5">
                          <p
                            className={`text-xs text-left mt-[-10px] text-secondaryLight`}
                          >
                            60 Max Characters
                          </p>
                        </div>
                      )}
                    </div>

                    {/* <div className="py-0.5">
                        <p
                          className={`text-xs text-left mt-[-13px] ${
                            errors?.categoryName && touched?.categoryName
                              ? "text-accent_1Dark"
                              : "text-secondaryMidLight"
                          }`}
                        >
                          {errors?.categoryName && touched?.categoryName ? (
                            <>{errors.categoryName}</>
                          ) : (
                            <div className="mt-[8px]">60 Max Characters</div>
                          )}
                        </p>
                      </div> */}
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <TextInput
                        label="Category code *"
                        type="text"
                        name="categoryCode"
                        setFieldValue={setFieldValue}
                        error={errors?.categoryCode}
                        touched={touched.categoryCode}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Category code"
                        value={values.categoryCode}
                      />
                    </div>
                  </div>
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 h-11"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />

                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 h-11"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
