import { SurveyQuestionElementBase } from "survey-react-ui";

export const audioProperties = [];

export default class SurveyAudioQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }

  renderInput() {
    return <audio controls></audio>;
  }

  renderElement() {
    return (
      <div style={{ display: "flex", justifyContent: "space-between"}}>
        {this.renderInput()}
      </div>
    );
  }
}
