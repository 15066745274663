interface IObjectType {
  id: number;
  name: string;
}

export interface IObject {
  id: number;
  type: IObjectType;
  description: string;
  communityId: number | null;
  dateAdded: string;
  image: string;
  objectName: string;
}

export interface IObjectData {
  objects: IObject[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchObjects {
  data: IObjectData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialObjectState: IfetchObjects = {
  data: {
    objects: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialObjectState;
