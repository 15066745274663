import React from "react";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";

const useRoles = () => {
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);

  const IsUserLoggedIn = () => {
    if (auth.accessToken) {
      const decoded = jwt_decode(auth.accessToken);
      return decoded ? true : false;
    } else {
      return false;
    }
  };

  const SuperAdmin = () => {
    if (auth.accessToken) {
      const decoded = jwt_decode(auth.accessToken);
      return true;
      //   decoded.role === "superadmin";
    } else {
      return false;
    }
  };

  const Admin = () => {
    if (auth.accessToken) {
      const decoded = jwt_decode(auth.accessToken);
      return "";
      //   decoded.role === "customer";
    } else {
      return false;
    }
  };
  const ActivatedRoutes = () => {
    return location.pathname;
  };

  const Logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const UserDetails = () => {
    if (auth.accessToken) {
      const decoded = jwt_decode(auth.accessToken);
      return decoded;
    } else {
      return false;
    }
  };
  const isMyProfile = (personId: any) => {
    return auth?.person?.personId === parseInt(personId);
  };

  const isExternalReviewer = () => {
    return auth.roles.includes("external_reviewer");
  };
  const isInternalEndDataViewer = () => {
    return auth.roles.includes("internal_data_end_viewer");
  };
  const isInternalReviewer = () => {
    const isUserSuperAdmin = auth?.roles?.includes("super_admin");
    const isAdmin = auth?.roles?.includes("admin");
    const isUserInternalReviewer = auth?.roles?.includes("internal_reviewer");

    if (isUserSuperAdmin || isAdmin) {
      return true;
    } else if (isUserInternalReviewer) {
      return false;
    }
  };
  const isInternalReviewerCommunityAdmin = () => {
    const isUserSuperAdmin = auth?.roles?.includes("super_admin");
    const isAdmin = auth?.roles?.includes("admin");
    const isUserInternalReviewer = auth?.roles?.includes("internal_reviewer");
    const isCommunityAdmin = auth?.roles.includes("community_admin");
    const isOrganizationsAdmin = auth?.roles.includes("organization_admin");

    if (
      isUserSuperAdmin ||
      isAdmin ||
      isCommunityAdmin ||
      isOrganizationsAdmin
    ) {
      return true;
    } else if (isUserInternalReviewer) {
      return false;
    }
  };

  const isUserInternalReviewer = () => {
    const isUserInternalReviewer = auth?.roles?.includes("internal_reviewer");
    return isUserInternalReviewer;
  };

  const isAdminUser = () => {
    const isUserSuperAdmin = auth?.roles?.includes("super_admin");
    const isAdmin = auth?.roles?.includes("admin");
    const isProgramAdmin = auth?.roles?.includes("program_admin");
    if (isUserSuperAdmin || isAdmin || isProgramAdmin) {
      return true;
    }
  };

  const isExternalDataEndReviewer = () => {
    return auth.roles.includes("external_data_end_viewer");
  };

  const isSuperAdmin = () => {
    return auth?.roles?.includes("super_admin");
  };

  const isOrganizationAdmin = () => {
    const hasOrgAdmin = auth?.roles.includes("organization_admin");
    const hasCommAdmin = auth?.roles.includes("community_admin");
    const hasProgramAdmin = auth?.roles.includes("program_admin");
    const isUserSuperAdmin = auth?.roles?.includes("super_admin");
    const isAdmin = auth?.roles?.includes("admin");

    if (hasProgramAdmin || isUserSuperAdmin || isAdmin ) {
      return true;
    }

    return !hasCommAdmin && !hasProgramAdmin && hasOrgAdmin ? false : undefined;
  };
  return {
    IsUserLoggedIn,
    ActivatedRoutes,
    Logout,
    SuperAdmin,
    UserDetails,
    isMyProfile,
    isExternalReviewer,
    isExternalDataEndReviewer,
    isInternalEndDataViewer,
    isInternalReviewer,
    isUserInternalReviewer,
    isAdminUser,
    isSuperAdmin,
    isInternalReviewerCommunityAdmin,
    isOrganizationAdmin,
  };
};

export default useRoles;
