// Define an interface for the tag data
export interface ITag {
  id: number;
  tagName: string;
  description: string;
  taggedEntities: number; // Number of tagged entities
}

export interface ITagsData {
  tags: ITag[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchTags {
  data: ITagsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object for tags
const initialTagsState: IfetchTags = {
  data: {
    tags: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialTagsState;
