import { apiClient } from "../config";

async function changeFormStatus(id: string, status: any) {
  const res = await apiClient.put(`/admin/dynamic-forms/${id}/status`, null, {
    params: {
      status: status,
    },
  });
  return res.data;
}

export default changeFormStatus;
