import {
  ERROR_LOADING_FILES,
  GET_ALL_FILES,
  START_LOADING_FILES,
  STOP_LOADING_FILES,
  RESET_FILES_FILTER_ACTIONS,
} from "./action-types";
import { initialState } from "./initialState";

export const filesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case START_LOADING_FILES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_FILES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_FILES:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_FILES:
      const files = action.payload.files.map((item: any) => {
        const { dateUpdated, fileName, fileSize, fileType, id, path } = item;
        const data = {
          id: id,
          fileName: {
            fileName,
            id,
            path,
            fileType,
          },
          fileType,
          fileSize,
          dateUpdated,
        };
        return data;
      });

      return {
        ...state,
        data: {
          files,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_FILES_FILTER_ACTIONS:
      return {
        ...state,
        data: {
          ...initialState.data,
        },
      };
    // return action.payload;
    default:
      return state;
  }
};
