import React, { useState } from "react";
/************ Assets ************/
import { CheckIcon, CheveronDownIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 200,
        },
    },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
    const { open, onClick } = props;

    return (
        <CheveronDownIcon
            height={15}
            width={15}
            onClick={onClick}
            className={`flex-shrink-0  ${open ? "transform rotate-180" : ""}`}
            preserveAspectRatio="xMidYMid meet"
            style={{ cursor: "pointer" }}
        />
    );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 8px;
  },
`;

interface AdminsDropdownProps {
    placeholder?: any;
    value?: any;
    data?: any[];
    handleChange?: any;
}

export const AdminsDropdown: React.FC<AdminsDropdownProps> = ({
    placeholder,
    value,
    data,
    handleChange,
}) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <FormControl className="w-full">
            <MaterialUiSelect
                className={`w-full !font-Overpass  text-textMid text-xs dark:bg-[transparent]`}
                displayEmpty
                value={value}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                renderValue={() => {
                    return <p className="text-Overpass">{placeholder}</p>;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={CustomIcon}
                open={open}
                onClick={handleToggle}
                input={<CustomOutlinedInput />}
                classes={{
                    root: 'dark:bg-secondaryLight',
                }}
                sx={{
                    ".MuiSelect-select": {
                        paddingRight: "15px !important",
                        paddingLeft: 0,
                        width: "auto"
                    }
                }}
            >
                <MenuItem
                    disabled
                    className="menu_items !text-sm !font-Overpass dark:bg-secondaryLight dark:text-inputText !px-2"
                >
                    <div className="w-6 pr-4"></div>
                    Sort By
                </MenuItem>
                {data && data?.length > 0 ? (
                    data?.map((option: any) => (
                        <MenuItem
                            key={typeof option === "object" ? option?.value : option}
                            value={typeof option === "object" ? option?.value : option}
                            onClick={() => handleChange(option)}
                            className={`menu_items !text-sm !font-Overpass dark:bg-secondaryLight dark:!text-inputText w-full !px-2 ${option.value === value.value ? "!bg-primaryLight !text-primary" : ""} `}
                        >
                            {<div className="w-6 pr-4">
                                {option.value === value.value &&
                                    <CheckIcon className="fill-primary stroke-primary" width={18} height={18} />
                                }
                            </div>}

                            {typeof option === "object" ? option?.name : option}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem
                        disabled
                        className="menu_items !text-sm !font-Overpass dark:bg-secondaryLight dark:text-inputText !px-2"
                    >
                        No data
                    </MenuItem>
                )}
            </MaterialUiSelect>
        </FormControl>
    );
};

export default AdminsDropdown;
