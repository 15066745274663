import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const LocationMarkerIcon = ({
  fill,
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M16.95 6.05a7 7 0 1 0-9.9 9.9l4.244 4.243a.998.998 0 0 0 1.413 0l4.243-4.243a7 7 0 0 0 0-9.9ZM5.636 4.636a9 9 0 0 1 12.728 12.728l-4.243 4.243a2.998 2.998 0 0 1-4.241 0l-4.244-4.243a9 9 0 0 1 0-12.728ZM12 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LocationMarkerIcon;
