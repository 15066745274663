import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";

interface ConnectedOrganizationsProps {
  organizationData: any;
  setSelectedOrganization: any;
  disconnectOrganization: any;
}

const ConnectedOrganizations: React.FC<ConnectedOrganizationsProps> = ({
  organizationData,
  disconnectOrganization,
  setSelectedOrganization,
}) => {
  return (
    <div>
      <div className="flex justify-between items-center gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
        {/* <div
          onClick={() => setSelectedOrganization(organizationData)}
          className="flex items-center flex-grow w-full cursor-pointer"
        >
          <div className="w-8 h-8 ">
            {organizationData?.organizationImage !== null ? (
              <img
                className="w-6 h-6 rounded-full"
                src={organizationData?.organizationImage.original}
                alt=""
              />
            ) : (
              <ListImagePlaceholder />
            )}
          </div>
          <p className="ml-3 dark:text-textMain">{organizationData.name}</p>
        </div> */}
        <div
          onClick={() => setSelectedOrganization(organizationData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          {organizationData?.organizationImage !== null ? (
            <img
              className="w-8 h-8 rounded-full"
              src={organizationData?.organizationImage.original}
              alt=""
            />
          ) : (
            <ListImagePlaceholder />
          )}
          <p className="ml-3 dark:text-textMain">{organizationData.name}</p>
        </div>
        <button type="button" className="flex-shrink-0">
          <XCloseSmallControlIcon
            onClick={() => disconnectOrganization(organizationData)}
          />
        </button>
      </div>
    </div>
  );
};

export default ConnectedOrganizations;
