import React from "react";
import { useLocation, useParams } from "react-router-dom";
import HomeIcon from "assets/icons/HeroIcons/HomeIcon";
import FolderListIcon from "assets/icons/HeroIcons/FolderListIcon";
import DocumentListIcon from "assets/icons/HeroIcons/DocumentListIcon";
import DocumentReportIcon from "assets/icons/HeroIcons/DocumentReportIcon";
import FlagIcon from "assets/icons/HeroIcons/FlagIcon";
import ServerIcon from "assets/icons/HeroIcons/ServerIcon";
import UseRoles from "hooks/roles";
import BinocularsIcon from "assets/icons/HeroIcons/BinocularsIcon";
import ChatIcon from "assets/icons/HeroIcons/ChatIcon";
import CogIcon from "assets/icons/HeroIcons/CogIcon";
import usePermissions from "hooks/usePermissions";
import { Tags } from "Components/Communities/FiltersList/Components";
import { BellIcon } from "assets/icons/HeroIcons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { IAuthResponseData } from "store/auth/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";

export interface IRoute {
  link?: string;
  icon?: JSX.Element | string;
  text?: string;
  isVisible?: boolean;
  hasChildren?: boolean;
  children: IRoute[];
  divider?: boolean;
  DownLink?: boolean;
  isActive?: boolean;
}

const SideBarRoutesForEndDataViewer = () => {
  const location = useLocation();
  const { reports } = usePermissions();
  const currentUrl = location.pathname;
  const routes: IRoute[] = [
    {
      link: "/reports/list",
      icon: <DocumentReportIcon fill="#2C3236" width={24} height={24} />,
      text: "Reports",
      isVisible: reports.canViewReports,
      hasChildren: false,
      children: [],
    },
  ];

  const visibleLinks = routes
    .filter((link) => link.isVisible)
    .map((item) => {
      if (item.hasChildren) {
        const children = item.children
          .filter((link) => link.isVisible)
          .map((child) => {
            const isActive =
              child?.link?.split("/")[1] === currentUrl?.split("/")[1];
            return {
              ...child,
              isActive: isActive,
            };
          });
        return {
          ...item,
          children,
          isActive: children.some((child) => child.isActive),
        };
      } else {
        const isActive =
          item?.link?.split("/")[1] === currentUrl?.split("/")[1];
        return {
          ...item,
          isActive,
        };
      }
    });
  return visibleLinks;
};

const SideBarRoutes = () => {
  const { id } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { isExternalReviewer, isExternalDataEndReviewer } = UseRoles();
  const auth = useSelector<RootState, IAuthResponseData>((state) => state.auth);
  const {
    permissions,
    tags,
    roles,
    universalSettings,
    universalSettingsImages,
    surveyAndPolls,
    reports,
    reminders,
    notifications,
    dashboard,
    conversations,
    geographies,
    species,
    objects,
    behaviors,
    conditions,
    imports,
    types,
    categories,
    qa,
    observations,
    programs,
    dynamicForms,
    protocols,
    organizations,
    communities,
    users,
    trackings, markerGroups, markerStyles
  } = usePermissions();

  const routes: IRoute[] = [
    {
      link: "/",
      icon: <HomeIcon fill="#2C3236" width={24} height={24} />,
      text: "Home",
      isVisible:
        !isExternalReviewer() && dashboard.canViewDashboardConfiguration,
      hasChildren: false,
      children: [],
    },
    {
      link: "/observations/list",
      icon: <BinocularsIcon fill="#2C3236" width={24} height={24} />,
      text: "Observations",
      isVisible:
        !isExternalDataEndReviewer() &&
        !isExternalReviewer() &&
        observations.canViewObservations,
      hasChildren: false,
      children: [],
    },
    {
      link: "/programs/list",
      icon: <FolderListIcon fill="#2C3236" width={24} height={24} />,
      text: "Programs",
      isVisible: !isExternalReviewer() && programs.canViewProgramDirectory,
      hasChildren: false,
      children: [],
    },
    {
      link: "/quality-assurance/list",
      icon: <FlagIcon fill="#2C3236" width={24} height={24} />,
      text: "QA",
      isVisible: qa.canViewQa || qa.canViewReviewerObservations,
      hasChildren: false,
      children: [],
    },
    {
      link: "/forms/list",
      icon: (
        <DocumentListIcon
          fill="#2C3236"
          className="dark:fill-primaryMid"
          width={16}
          height={20}
        />
      ),
      text: "Forms",
      isVisible:
        !isExternalReviewer() &&
        dynamicForms.canViewDirectoryFromSideBarDynamicForm,
      hasChildren: false,
      children: [],
    },
    {
      link: "/reports/list",
      icon: <DocumentReportIcon fill="#2C3236" width={24} height={24} />,
      text: "Reports",
      isVisible:
        isExternalDataEndReviewer() ||
        (!isExternalReviewer() && reports.canViewReports),
      hasChildren: false,
      children: [],
    },
    {
      link: "/messages",
      icon: <ChatIcon fill="#2C3236" width={24} height={24} />,
      text: "Messages",
      isVisible: !isExternalReviewer() && conversations.canViewConversations,
      hasChildren: false,
      children: [],
    },
    {
      link: "/admin-settings",
      icon: <ServerIcon fill="#2C3236" width={24} height={24} />,
      text: "Admin Settings",
      isVisible: !isExternalReviewer() && !isExternalDataEndReviewer(),
      hasChildren: true,
      children: [
        {
          link: "/users/list",
          icon: "",
          text: "People",
          isVisible: users.canViewUsersInSideBar && users.canViewUsers,
          children: [],
        },
        {
          link: "/organizations/list",
          icon: "",
          text: "Organizations",
          isVisible: organizations.canViewOrganizationDirectory,
          children: [],
        },
        {
          link: "/community/list",
          icon: "",
          text: "Communities",
          isVisible: communities.canViewSideBarCommunities,
          children: [],
        },
        {
          link: "/geography",
          icon: "",
          text: "Geography",
          isVisible: checkUserRoles(auth),
          hasChildren: true,
          children: [
            {
              link: "/geography",
              icon: "",
              text: "Layers",
              isVisible: geographies.canViewGeographiesInSideBar,
              children: [],
            },
            {
              link: "/marker-styles",
              icon: "",
              text: "Marker Styles",
              isVisible: markerStyles.canViewMarkerStylesInSideBar,
              children: [],
            },
            {
              link: "/marker-groups",
              icon: "",
              text: "Marker Groups",
              isVisible: markerGroups.canViewMarkerGroupsInSideBar,
              children: [],
            },
          ],
        },
        {
          link: "/category/list",
          icon: "",
          text: "Categories",
          isVisible: categories.canViewCategories,
          children: [],
        },
        {
          link: "/types/list",
          icon: "",
          text: "Types",
          isVisible: types.canViewType,
          children: [],
        },
        {
          link: "/species/list",
          icon: "",
          text: "Species",
          isVisible: species.canViewSpecies,
          children: [],
        },

        {
          link: "/object/list",
          icon: "",
          text: "Objects",
          isVisible: objects.canViewObjects,
          children: [],
        },
        {
          link: "/behaviors/list",
          icon: "",
          text: "Behaviors",
          isVisible: behaviors.canViewBehaviors,
          children: [],
        },
        {
          link: "/conditions/list",
          icon: "",
          text: "Conditions",
          isVisible: conditions.canViewConditions,
          children: [],
        },
        {
          link: "/protocols/list",
          icon: "",
          text: "Protocols",
          isVisible: protocols.canViewSideBarProtocol,
          children: [],
        },
        {
          link: "/custom-notifications/list",
          icon: "",
          text: "Custom Notifications",
          isVisible: reminders.canViewViewReminders,
          children: [],
          DownLink: true,
        },
        {
          link: "/imports/list",
          icon: "",
          text: "Imports",
          isVisible: imports.canViewImports,
          children: [],
          DownLink: true,
        },
        {
          link: "/polls-and-surveys/list",
          icon: "",
          text: "Polls & Surveys",
          isVisible: surveyAndPolls.canViewSurveyAndPolls,
          children: [],
        },

        {
          link: "/admin-tracking/list",
          icon: "",
          text: "Admin Tracking",
          isVisible: trackings.canViewAdminTracking,
          children: [],
        },
      ],
    },

    {
      link: "/admin-settings",
      icon: <CogIcon fill="#2C3236" width={24} height={24} />,
      text: "System Settings",
      isVisible:
        !isExternalReviewer() &&
        !isExternalDataEndReviewer() &&
        (roles.canViewRole ||
          tags.canViewTag ||
          universalSettings.canViewUniversalSettings),
      hasChildren: true,
      children: [
        {
          link: "/roles-permissions/list",
          icon: "",
          text: "Roles and Permissions",
          isVisible: roles.canViewRole,
          children: [],
        },
        {
          link: "/tagging/list",
          icon: "",
          text: "Tagging",
          isVisible: tags.canViewTag,
          children: [],
        },
        {
          link: "/system-settings",
          icon: "",
          text: "Settings",
          isVisible: universalSettings.canViewUniversalSettings,
          children: [],
        },
      ],
    },
  ];

  const getActiveState = (link: string, currentUrl: string): boolean =>
    link?.split("/")[1] === currentUrl?.split("/")[1];

  const processChildren = (children: IRoute[], currentUrl: string): IRoute[] =>
    children
      .filter((child: IRoute) => child.isVisible)
      .map((child: IRoute) => ({
        ...child,
        isActive: getActiveState(child.link ?? "", currentUrl),
        children: child.hasChildren
          ? processChildren(child.children, currentUrl)
          : [],
      }));

  const visibleLinks = (routes: IRoute[], currentUrl: string): IRoute[] =>
    routes
      .filter((route: IRoute) => route.isVisible)
      .map((route: IRoute) => {
        const children = route.hasChildren
          ? processChildren(route.children, currentUrl)
          : [];
        const isActive =
          children.some((child: IRoute) => child.isActive) ||
          getActiveState(route.link ?? "", currentUrl);

        return {
          ...route,
          children,
          isActive,
        };
      });

  return visibleLinks(routes, currentUrl);
};
export { SideBarRoutes, SideBarRoutesForEndDataViewer };



function checkUserRoles(auth: IAuthResponseData) {
  const isUserSuperAdmin = auth?.roles?.includes("super_admin");
  const isAdmin = auth?.roles?.includes("admin");
  const isUserCommunityAdmin = auth?.roles?.includes("community_admin");


  if (isUserSuperAdmin || isAdmin || isUserCommunityAdmin) {
    return true;
  } else {
    return false;
  }
  return false;
}