import { apiClient } from "../config";

async function removePersonFromCommunity(communityId: any, personId: any) {
  const res = await apiClient.delete(
    `/admin/communities/${communityId}/member?person_id=${personId}`
  );
  return res.data;
}

export default removePersonFromCommunity;
