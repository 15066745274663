import * as React from 'react';
import type { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
}
export const DocumentPreviewIcon = ({
  fill = 'none',
  className,
  width,
  height,
  ...restProps
}: IconProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
    fill={fill}
    className={className}
  >
    <path
      d='M12.5013 18.9596H7.5013C2.9763 18.9596 1.04297 17.0263 1.04297 12.5013V7.5013C1.04297 2.9763 2.9763 1.04297 7.5013 1.04297H11.668C12.0096 1.04297 12.293 1.3263 12.293 1.66797C12.293 2.00964 12.0096 2.29297 11.668 2.29297H7.5013C3.65964 2.29297 2.29297 3.65964 2.29297 7.5013V12.5013C2.29297 16.343 3.65964 17.7096 7.5013 17.7096H12.5013C16.343 17.7096 17.7096 16.343 17.7096 12.5013V8.33464C17.7096 7.99297 17.993 7.70964 18.3346 7.70964C18.6763 7.70964 18.9596 7.99297 18.9596 8.33464V12.5013C18.9596 17.0263 17.0263 18.9596 12.5013 18.9596Z'
      className='fill-[#353535] dark:fill-caption'
    />
    <path
      d='M18.3346 8.95841H15.0013C12.1513 8.95841 11.043 7.85007 11.043 5.00007V1.66674C11.043 1.41674 11.193 1.18341 11.4263 1.09174C11.6596 0.991739 11.9263 1.05007 12.1096 1.22507L18.7763 7.89174C18.9513 8.06674 19.0096 8.34174 18.9096 8.57507C18.8096 8.8084 18.5846 8.95841 18.3346 8.95841ZM12.293 3.17507V5.00007C12.293 7.15007 12.8513 7.70841 15.0013 7.70841H16.8263L12.293 3.17507Z'
      className='fill-[#353535] dark:fill-caption'
    />
    <path
      d='M10.832 11.457H5.83203C5.49036 11.457 5.20703 11.1737 5.20703 10.832C5.20703 10.4904 5.49036 10.207 5.83203 10.207H10.832C11.1737 10.207 11.457 10.4904 11.457 10.832C11.457 11.1737 11.1737 11.457 10.832 11.457Z'
      className='fill-[#353535] dark:fill-caption'
    />
    <path
      d='M9.16536 14.793H5.83203C5.49036 14.793 5.20703 14.5096 5.20703 14.168C5.20703 13.8263 5.49036 13.543 5.83203 13.543H9.16536C9.50703 13.543 9.79036 13.8263 9.79036 14.168C9.79036 14.5096 9.50703 14.793 9.16536 14.793Z'
      className='fill-[#353535] dark:fill-caption'
    />
  </svg>
);
export default DocumentPreviewIcon;
