import {
  GET_PROGRAM_PROFILE,
  RESET_PROGRAM_PROFILE,
  PROGRAM_PROFILE_LOADING_STATE,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchProgramProfileAction =
  (programId: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch(loadingProgramProfile());
    try {
      const { data } = await apiLibrary.Programs.getProgramProfile(programId);

      dispatch({
        type: GET_PROGRAM_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/programs/list";
    }
  };
export const resetProgramProfileAction = () => {
  return {
    type: RESET_PROGRAM_PROFILE,
  };
};

export const loadingProgramProfile = () => {
  return {
    type: PROGRAM_PROFILE_LOADING_STATE,
  };
};
