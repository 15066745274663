import { useSelector } from "react-redux";
import {
  DrawPolygonSidebar,
  DropPinSidebar,
  PointPickerSidebar,
  ShapePickerSidebar,
  GetGpsDataSidebar,
} from "../index";
import questionTypes from "../../questionTypes";
import { panelsName } from "../../panelsTitle";

import { RootState } from "store";

const CustomPropertyGridSidebar = () => {
  const { selectedQuestionElement } = useSelector(
    (state: RootState) => state.surveyJS
  );

  function _renderSidebar(element: any) {
    if (element) {
      const questionType = element?.isPanel
        ? element?.name
        : element?.getType();

      switch (questionType) {
        case questionTypes.shapePicker:
          return <ShapePickerSidebar />;

        case questionTypes.pointPicker:
          return <PointPickerSidebar />;

        case questionTypes.drawPolygon:
          return <DrawPolygonSidebar />;

        case questionTypes.dropPin:
          return <DropPinSidebar />;
        case questionTypes.getGpsData:
          return <GetGpsDataSidebar />;

        case panelsName.HARVEST:
          return (
            <>
              <ShapePickerSidebar
                heading="Retrieval Location (Region)"
                variant="retrieval"
              />
              <PointPickerSidebar
                heading="Retrieval Location (Point)"
                variant="retrieval"
              />
              <ShapePickerSidebar
                heading="Strcuk & Lost Location (Region)"
                variant="struck"
              />
              <PointPickerSidebar
                heading="Struck & Lost Location (Point)"
                variant="struck"
              />
            </>
          );

        case panelsName.GEOGRAPHICAL_INFORMATION:
          return (
            <>
              <ShapePickerSidebar heading="Region" variant="region" />
              <ShapePickerSidebar heading="View" variant="view" />
              <PointPickerSidebar
                heading="Vantage Point"
                blockMultiSelect={true}
              />
            </>
          );
      }
    }
  }

  return (
    <section
      id="customPropertyGridSidebar"
      className="w-full"
      style={{ display: "none" }}
    >
      {_renderSidebar(selectedQuestionElement)}
    </section>
  );
};

export { CustomPropertyGridSidebar };
