import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
} from "Components/MarkerStyles";
import TextInputField from "view/components/InputField";
// Hooks
import { MarkerStylesTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchMarkerStylesAction } from "store/markerStyles";
import { IFetchMarkerStyles } from "store/markerStyles/initialState";
import { TableProps } from "Components/MarkerStyles/Table/types";
import {
  setMarkerStylesFiltersAction,
  markerStylesResetFiltersAction,
} from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const MarkerStyles = () => {

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IFetchMarkerStyles>(
    (state) => state?.allMarkerStyles
  );

  const { markerStyles } = usePermissions()
  useLayoutEffect(() => {
    dispatch(setMarkerStylesFiltersAction());
  }, []);

  const { markerStylesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  // const { markerStyles } = usePermissions();

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = MarkerStylesTable(data?.markerStyles);

  useEffect(() => {
    if (markerStyles.canView) {
      dispatch(fetchMarkerStylesAction());

    }

    saveFiltersDataToLocalStorage(markerStylesFilter);
  }, [markerStylesFilter]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(markerStylesResetFiltersAction());
  //   };
  // }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>

        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default MarkerStyles;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, IFetchMarkerStyles>(
    (state) => state?.allMarkerStyles
  );
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs == "Idle" && data?.markerStyles?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
