import React from "react";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import profileImg from "assets/images/Auth/community-circleImg.png";
import { openOrganizationsProfileDetailModalAction } from "store/modals/reducer.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchOrganizations } from "store/oranizations/initialState";
import { Link } from "react-router-dom";
import useRoles from "hooks/roles";
import usePermissions from "hooks/usePermissions";

export const GridCard = () => {
  const dispatch = useDispatch();
  const { data } = useSelector<RootState, IfetchOrganizations>(
    (state) => state?.allOrganizations
  );
  const { isUserInternalReviewer } = useRoles();
  const { organizations } = usePermissions();

  return (
    <>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-3">
        {data.organizations.map((items: any, index: number) => {
          const tagArray =
            items.tags && typeof items.tags === "string"
              ? items.tags.split(", ").map((tag: any) => tag.trim())
              : [];

          const navigateTo = organizations.canViewOrganization &&
            !isUserInternalReviewer() &&
            organizations.canViewOrganizationDirectory
            ? `/organizations/${items?.id}/profile`
            : ""
          return (
            <div
              key={index}
              className="flex flex-wrap w-full h-[150px] bg-bgWhite dark:bg-bgtetriary overflow-hidden rounded"
            >
              <div className="cursor-default flex flex-col justify-start rounded-lg dark:bg-bgtetriary w-full">
                <div className="flex gap-3 p-3 dark:bg-bgtetriary w-full rounded-lg ">
                  <div className="flex flex-col items-start justify-start w-full gap-2 ">
                    <div className="flex w-full">
                      <div className="w-[40px] h-[40px] rounded-full">
                        <Link
                          to={navigateTo}
                          className="w-full h-[136px] "
                        >
                          <img
                            src={
                              items.name.organizationImage.path
                                ? items.name.organizationImage.path
                                : profileImg
                            }
                            alt={items.name}
                            className="object-cover w-8 h-8 rounded-full"
                          />
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={navigateTo}
                          className="w-full h-[136px] "
                        >
                          <p
                            style={{ overflowWrap: "anywhere" }}
                            className="text-base font-medium text-left capitalize cursor-pointer text-secondary dark:text-textMain"
                          >
                            {items.name.name}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="flex items-start justify-start w-full gap-3">
                      <div className="flex flex-wrap flex-col justify-start w-full mt-[-5px] gap-1">
                        <div className="flex items-center justify-start gap-1">
                          <p
                            style={{ overflowWrap: "anywhere" }}
                            className="text-secondaryMidLight dark:text-caption "
                          >
                            {items.types}
                          </p>
                          <p className="text-xs text-left text-textMid dark:text-caption ">
                            {items.programs} Programs
                          </p>
                          <DotCentralIcon />
                          <p className="text-xs text-left text-textMid dark:text-caption ">
                            {items.members} Members
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-start gap-1">
                      {tagArray.slice(0, 6).map((tag: any, index: any) => (
                        <div
                          key={index}
                          className="flex justify-center items-center gap-1 p-1 rounded bg-primary/[0.08] dark:bg-primary/40"
                        >
                          <span className="text-xs text-center text-textMid dark:text-textMain">
                            {tag}
                          </span>
                        </div>
                      ))}
                      {tagArray.length > 6 && <span className="dark:text-textMain">...</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default GridCard;
