import Head from "view/components/Head";
import { PageHeadingProps } from "../../types";

const PageHeading = ({ organizationId, name, parentName }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={parentName + " " + (organizationId !== "" ? `${name}: Communities` : "Communities")} />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {organizationId !== "" ? <>{name}: Communities</> : "Communities"}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
