import { apiClient } from "../config";
import { IDashboardConfiguration } from "store/dashboard/configurations/initialState";

async function saveDashboardConfiguration(
  requestPayload: IDashboardConfiguration[]
) {
  const res = await apiClient.post(`/admin/dashboard/configuration`, {
    data: requestPayload,
  });
  return res.data;
}

export default saveDashboardConfiguration;
