import { useEffect } from "react";
import pointIconRed from "assets/images/location-marker-red.png";

import { __changeColorOfZoomedShapeLayer__ } from "Components/Geography/MapBox/components/DefaultMap/workerMehods";

import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useCustomizeColorOfHigilightedPoint = (
  map: any,
  isMapFullyLoaded: any,
  currentQuestionEle: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string,
  markers: any,
  setMarkers: any,
  setClickedPoint: any,
  answer: any
) => {
  useEffect(() => {
    if (currentQuestionEle && markers && map && isMapFullyLoaded) {
      const newMarkers = markers.map((markerData: any) => {
        const isMarkerSelected = answer?.find(
          (answeredMaraker: any) => answeredMaraker.id == markerData.id
        );

        const color = isMarkerSelected
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        // get coordinates of marker
        const coordinates = markerData.marker.getLngLat();

        // Remove the old marker
        markerData.marker.remove();

        // // Create a new marker with the new color
        // const newMarker = new mapboxgl.Marker({ color })
        //   .setLngLat(coordinates)
        //   .addTo(map);

        const markerElement = document.createElement("div");
        markerElement.className = "custom-marker-point-picker";

        markerElement.innerHTML = `
   <div class="marker-content-point-picker" style="
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    border: 3px solid ${color}; 
   
    overflow: hidden;
  ">
    <img src="${markerData?.data?.markerStyle?.styleImage??pointIconRed}" alt="Marker Image" class="marker-image" style="width: 100%; height: 100%; object-fit: cover;" />
  </div>
`;

        const newMarker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map);

        newMarker
          .getElement()
          .addEventListener("click", () => setClickedPoint(markerData));

        markerData.marker = newMarker;

        return markerData;
      });

      setMarkers(newMarkers);
    }
  }, [answer]);

  return null;
};

export { useCustomizeColorOfHigilightedPoint };
