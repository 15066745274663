import { SVGProps } from "react";

interface FileUploadPlaceholderIconProps extends SVGProps<SVGSVGElement> {
    width?: number | string;
    height?: number | string;
}

const FileUploadPlaceholderIcon: React.FC<FileUploadPlaceholderIconProps> = ({
    width = 256,
    height = 256,
    ...props
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 256 256" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M161.312 220.012C160.92 220.756 160.52 221.48 160.104 222.18C159.744 222.788 159.092 223.16 158.384 223.16C143.036 223.16 52.204 223.16 26.804 223.16C26.104 223.16 25.452 222.792 25.092 222.192C24.728 221.588 24.712 220.84 25.04 220.22C25.06 220.188 25.076 220.152 25.092 220.12C37.64 192.876 38.596 145.46 38.596 145.46C38.596 145.444 38.596 145.432 38.596 145.42V34.84C38.596 33.736 39.492 32.84 40.596 32.84H120.46C121.564 32.84 122.46 31.944 122.46 30.84C122.46 29.736 121.564 28.84 120.46 28.84C120.46 28.84 57.28 28.84 40.596 28.84C37.284 28.84 34.596 31.524 34.596 34.84V145.4C34.576 146.424 33.6 192.016 21.488 218.38C20.52 220.252 20.588 222.464 21.66 224.248C22.744 226.052 24.696 227.16 26.8 227.16H158.384C160.504 227.16 162.464 226.044 163.544 224.224L164.852 221.876C165.368 220.896 164.992 219.688 164.016 219.172C163.036 218.66 161.828 219.036 161.312 220.012Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M175.86 83.468C176.652 83.78 177.552 83.56 178.108 82.916C178.664 82.276 178.752 81.352 178.332 80.616C167.048 60.888 151.588 43.772 132.004 29.232C131.336 28.736 130.432 28.708 129.732 29.156C129.036 29.6 128.684 30.436 128.856 31.248C131.804 45.332 133.252 59.584 131.824 74.156C131.628 75.992 132.304 77.832 133.652 79.112C135.004 80.396 136.872 80.98 138.712 80.692C151.54 78.708 164.052 78.804 175.86 83.468Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5959 110.128H109.02C110.124 110.128 111.02 109.232 111.02 108.128C111.02 107.024 110.124 106.128 109.02 106.128H56.5959C55.4919 106.128 54.5959 107.024 54.5959 108.128C54.5959 109.232 55.4919 110.128 56.5959 110.128Z" fill="#6F747B" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5959 130.128H93.8479C94.9519 130.128 95.8479 129.232 95.8479 128.128C95.8479 127.024 94.9519 126.128 93.8479 126.128H56.5959C55.4919 126.128 54.5959 127.024 54.5959 128.128C54.5959 129.232 55.4919 130.128 56.5959 130.128Z" fill="#6F747B" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5959 150.128H93.8479C94.9519 150.128 95.8479 149.232 95.8479 148.128C95.8479 147.024 94.9519 146.128 93.8479 146.128H56.5959C55.4919 146.128 54.5959 147.024 54.5959 148.128C54.5959 149.232 55.4919 150.128 56.5959 150.128Z" fill="#6F747B" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5959 170.128H93.8479C94.9519 170.128 95.8479 169.232 95.8479 168.128C95.8479 167.024 94.9519 166.128 93.8479 166.128H56.5959C55.4919 166.128 54.5959 167.024 54.5959 168.128C54.5959 169.232 55.4919 170.128 56.5959 170.128Z" fill="#6F747B" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M221.472 115.016C229.188 124.868 233.792 137.272 233.792 150.744C233.792 182.756 207.804 208.744 175.792 208.744C143.78 208.744 117.792 182.756 117.792 150.744C117.792 118.732 143.78 92.744 175.792 92.744C190.088 92.744 203.18 97.928 213.296 106.516C214.136 107.228 215.4 107.128 216.116 106.284C216.828 105.444 216.728 104.18 215.884 103.468C205.072 94.284 191.072 88.744 175.792 88.744C141.572 88.744 113.792 116.524 113.792 150.744C113.792 184.964 141.572 212.744 175.792 212.744C210.008 212.744 237.792 184.964 237.792 150.744C237.792 136.344 232.868 123.08 224.62 112.552C223.94 111.68 222.68 111.528 221.812 112.208C220.944 112.892 220.788 114.148 221.472 115.016Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M143.416 141.544C148.56 122.352 168.292 110.824 187.488 115.968C206.68 121.108 218.084 140.864 212.944 160.056C207.8 179.248 188.044 190.656 168.852 185.512C166.72 184.94 164.524 186.208 163.952 188.34C163.38 190.476 164.648 192.668 166.78 193.24C190.236 199.524 214.384 185.584 220.668 162.128C226.956 138.672 213.012 114.524 189.556 108.24C166.1 101.956 141.976 116.016 135.692 139.472C135.12 141.604 136.388 143.8 138.52 144.372C140.652 144.944 142.848 143.676 143.416 141.544Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M129.712 127.272L135.86 142.044C136.708 144.084 139.052 145.052 141.092 144.2L155.864 138.052C157.9 137.204 158.868 134.86 158.02 132.824C157.168 130.784 154.828 129.82 152.788 130.668L141.708 135.28L137.1 124.2C136.252 122.16 133.908 121.196 131.868 122.044C129.832 122.892 128.864 125.236 129.712 127.272Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M173.792 130.744V157.824L160.488 169.224C159.652 169.944 159.556 171.208 160.272 172.044C160.992 172.884 162.256 172.98 163.092 172.264L177.092 160.264C177.536 159.884 177.792 159.328 177.792 158.744V130.744C177.792 129.64 176.896 128.744 175.792 128.744C174.688 128.744 173.792 129.64 173.792 130.744Z" fill="#6F747B" />
    </svg>
);

export default FileUploadPlaceholderIcon;
