import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  height?: any;
  width?: any;
  className?: any;
}

export const UserAvatarIcon = ({ fill = "#6F747B",height,width,className, ...restProps }: IconProps) => (
  <svg
    width={height || 32}
    height={width || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={` w-7 h-7 ${className}`}
    preserveAspectRatio="xMidYMid meet"
  >
    <circle cx={12} cy={12} r={12} fill="#99BED0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0003 5.3335C10.1594 5.3335 8.66699 6.82588 8.66699 8.66683C8.66699 10.5078 10.1594 12.0002 12.0003 12.0002C13.8413 12.0002 15.3337 10.5078 15.3337 8.66683C15.3337 6.82588 13.8413 5.3335 12.0003 5.3335ZM10.0003 8.66683C10.0003 7.56226 10.8958 6.66683 12.0003 6.66683C13.1049 6.66683 14.0003 7.56226 14.0003 8.66683C14.0003 9.7714 13.1049 10.6668 12.0003 10.6668C10.8958 10.6668 10.0003 9.7714 10.0003 8.66683Z"
      fill="white"
    />
    <path
      d="M8.00033 18.0002C8.00033 15.791 9.79119 14.0002 12.0003 14.0002C14.2095 14.0002 16.0003 15.791 16.0003 18.0002C16.0003 18.3684 16.2988 18.6668 16.667 18.6668C17.0352 18.6668 17.3337 18.3684 17.3337 18.0002C17.3337 15.0546 14.9458 12.6668 12.0003 12.6668C9.05481 12.6668 6.66699 15.0546 6.66699 18.0002C6.66699 18.3684 6.96547 18.6668 7.33366 18.6668C7.70185 18.6668 8.00033 18.3684 8.00033 18.0002Z"
      fill="white"
    />
  </svg>
);
export default UserAvatarIcon;
