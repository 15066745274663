interface CreatedBy {
  id: number;
  name: string;
  image: any;
}

export interface Poll {
  archivedBy: any;
  token: string;
  createdBy: CreatedBy;
  programName: CreatedBy;
  dateCreated: string;
  dateUpdated: string;
  description: string;
  id: number;
  surveyName: string;
  picture: any;
  numberOfResponses: number;
  status: string;
  surveyAndPollVersionId: number;
  expiryDate:string;
}

export interface PollsData {
  fields: string[];
  polls: Poll[];
  totalItems: number;
  totalPages: number;
}

enum FetchPollState {
  Idle = "Idle",
  Pending = "Pending",
  Error = "Error",
}

export interface IFetchPolls {
  data: PollsData;
  message: string;
  stateIs: FetchPollState;
}

// Create an initial state object
const initialPollsState: IFetchPolls = {
  data: {
    fields: [],
    polls: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: FetchPollState.Idle,
};

export default initialPollsState;
