import React, { useEffect, useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { closeOrganizationsProfileDetailModalAction } from "store/modals/reducer.actions";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Header from "./Header";
import AdminOrganizations from "./AdminOrganizations";
import OrganizationCommunities from "./OrganizationCommunities";
import OrganizationsPrograms from "./OrganizationsPrograms";
import { Link } from "react-router-dom";
import { fetchOrganizationProfileAction } from "store/organizationProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IOrganization } from "store/organizationProfile/initialState";

export const OrganizationProfileDetails = () => {
  const { OrganizationProfileDetailModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isModalOpen, setModalOpen] = React.useState(false);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );

  useLayoutEffect(() => {
    if (OrganizationProfileDetailModal.data) {
      dispatch(
        fetchOrganizationProfileAction(OrganizationProfileDetailModal.data)
      );
    }
  }, [OrganizationProfileDetailModal.data]);

  const openModal = () => {
    setModalOpen(OrganizationProfileDetailModal?.isOpen);
  };
  const handleClose = () => {
    dispatch(closeOrganizationsProfileDetailModalAction());
  };
  const OrganizationPagesLink = [
    {
      title: "Communities",
      link: `/organizations/${OrganizationProfileDetailModal?.data}/communities`,
    },
    {
      title: "Programs",
      link: `/organizations/${OrganizationProfileDetailModal?.data}/programs`,
    },
  ];
  return (
    <div className="">
      <SwipeableDrawer
        anchor="right"
        open={OrganizationProfileDetailModal?.isOpen}
        onClose={handleClose}
        onOpen={openModal}

      >
        <Box
          sx={{ width: 656 }}
          className="dark:bg-secondaryLight h-full"
          role="presentation"
          onKeyDown={handleClose}
        >
          <div className="flex flex-col justify-start items-start w-[656px] h-full pl-3 pr-6 py-4 bg-bgWhite dark:bg-secondaryLight ">
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg hover:bg-primary/[0.2]">
              <XCloseIcon
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="#2C3236"
                onClick={handleClose}
                className="cursor-pointer hover:text-primaryDark"
              />

              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center flex-grow relative py-0.5 cursor-default">
                  <p className="font-medium  text-secondaryMid dark:text-textMain">
                    Organization Details
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6 px-3 py-4">
              <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0">
                <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-8">
                  <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                    {OrganizationPagesLink?.map((link: any, index: number) => (
                      <Link
                        onClick={handleClose}
                        key={index}
                        to={link.link}
                        className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg bg-primary/[0.08] h-[32px] mr-1 hover:bg-primary/[0.2] dark:bg-primary_40"
                      >
                        <div className="h-[32px] flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2 rounded">
                          <button className="flex-grow-0 flex-shrink-0 p-1 text-sm text-center text-secondaryMid dark:text-textMain">
                            {link.title}
                          </button>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <Header OrganizationData={organizationProfile} />
              <AdminOrganizations
                OrganizationAdmins={organizationProfile.organizationAdmins}
              />
              <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <div className="flex flex-col justify-center items-start flex-grow gap-1.5 pb-2">
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                    <p className="self-stretch flex-grow-0 flex-shrink-0 text-base font-semibold text-left cursor-default text-secondaryMid dark:text-textMain">
                      Organization Tags
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-start gap-1">
                    {organizationProfile?.organizationTags?.map(
                      (tag: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-center items-center flex-shrink-0 gap-1 p-1 rounded bg-primary/[0.08] h-[32px] mr-1 hover:bg-primary/[0.2] dark:bg-primary_40"
                          >
                            <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
                              <p className="flex-grow-0 flex-shrink-0 text-xs text-center cursor-default text-secondaryMid dark:text-textMain">
                                {tag.name}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
                <OrganizationCommunities
                  OrganizationData={organizationProfile.organizationCommunities}
                />
                <OrganizationsPrograms
                  OrganizationData={organizationProfile.programs}
                />
              </div>
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};
