import { GET_OBJECT_PROFILE, RESET_OBJECT_PROFILE } from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchObjectProfileAction =
  (objectId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.SpeciesAndObjects.getObjectProfile(objectId);

      dispatch({
        type: GET_OBJECT_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/object/list";
    }
  };
export const resetObjectProfileAction = () => {
  return {
    type: RESET_OBJECT_PROFILE,
  };
};
