import { apiClient } from "../config";
interface RequestParams {
  searchString: string | undefined;
  page: number;
  connected: boolean;
}

async function getMembersByCommunity(
  id: number,
  connected: boolean = false,
  searchString: string = "",
  page: number
) {
  const params: RequestParams = {
    searchString: searchString,
    page: page,
    connected,
  };

  if (page && page > 0) {
    params.page = page;
  }

  const res = await apiClient.get(`/admin/communities/${id}/user-members`, {
    params,
  });

  return res.data;
}

export default getMembersByCommunity;
