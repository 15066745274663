import * as React from 'react';
import type { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const NewChatIcon = ({
  fill = '#005C89',
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width='63'
    height='62'
    viewBox='0 0 63 62'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    <g filter='url(#filter0_d_5495_224534)'>
      <rect
        x='17.2656'
        y='13.5625'
        width='21.8836'
        height='20.3915'
        fill='white'
      />
      <path
        fill={fill}
        className={`dark:${
          isActive
            ? 'fill-primary'
            : 'fill-caption group-hover:dark:fill-primary'
        }`}
        d='M16.7244 39.3333L8 46.1875V9.95833C8 9.43895 8.20632 8.94084 8.57358 8.57358C8.94084 8.20632 9.43895 8 9.95833 8H45.2083C45.7277 8 46.2258 8.20632 46.5931 8.57358C46.9603 8.94084 47.1667 9.43895 47.1667 9.95833V37.375C47.1667 37.8944 46.9603 38.3925 46.5931 38.7598C46.2258 39.127 45.7277 39.3333 45.2083 39.3333H16.7244ZM25.625 21.7083H19.75V25.625H25.625V31.5H29.5417V25.625H35.4167V21.7083H29.5417V15.8333H25.625V21.7083Z'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_5495_224534'
        x='0.4'
        y='0.4'
        width='62.368'
        height='61.3875'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='4' dy='4' />
        <feGaussianBlur stdDeviation='5.8' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_5495_224534'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_5495_224534'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
export default NewChatIcon;
