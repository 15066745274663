import apiClient from "services/apiClient";

async function getSpeciesDropdown(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
    limit:1000000
  };

  const res = await apiClient.get(`/admin/species/dropdown`, { params });
  return res.data;
}

export default getSpeciesDropdown;
