export function identifyFileType(extension: string) {
  const images = ["jpg", "jpeg", "png", "webp"];
  const videos = ["mp4", "mov", "avi", "wmv"];
  const audios = ["mp3", "wav", "webm"]

  if (extension) {
    switch (true) {
      case images.includes(extension):
        return "image";

      case videos.includes(extension):
        return "video";

      case audios.includes(extension):
        return "audio";

      case extension === ".pdf":
        return "pdf";

      case extension === "vnd.ms-excel":
        return "xls";

      case extension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";

      default:
        return extension;
    }
  }

  return "unknown";
}

export function getFileExtension(fileName: string) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}