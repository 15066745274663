import { ColumnProps } from '../../types';
import { RenderCellContent } from '../index';

export const Column = ({
  cell,
  columnVisibility,
  importStatusDetail,
  programId,
  id,
  observers,
  rowData,
}: ColumnProps) => {
  const columnId = cell?.column?.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell?.column?.columnDef !== undefined;

  const observerTypes = [
    'observer',
    'observerName',
    'observer1',
    'personId',
    'observer1',
  ];

  const showImportStatusDetail = (cell: any) => {
    if (!observerTypes.includes(cell.column?.id)) {
      importStatusDetail();
    }
  };
  if (isColumnVisible && cell?.column?.columnDef?.id !== 'select') {
    return (
      <td
        key={cell.id}
        className='px-4'
        onClick={() =>
          rowData?.importStatus !== 'imported' && showImportStatusDetail(cell)
        }
      >
        <RenderCellContent
          columnDef={cell.column.columnDef}
          cellValue={cell.getValue()}
          programId={programId}
          id={id}
          observers={observers}
          rowData={rowData}
        />
      </td>
    );
  }
  // else {
  //   return (
  //     <td
  //       key={cell.id}
  //       className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
  //     >
  //       {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
  //     </td>
  //   );
  // }
};
