import { Dispatch } from "redux";
import {
  ERROR_LOADING_NOTIFICATIONS_STATUS,
  GET_ALL_NOTIFICATIONS_STATUS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS_STATUS,
  STOP_LOADING_NOTIFICATIONS_STATUS,
} from "./action-types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

export const fetchNotificationsStatusAction =
  (notificationId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_NOTIFICATIONS_STATUS });
    try {
      const { data } =
        await apiLibrary.NotificationsStatus.getAllNotificationsStatus(
          notificationId
        );
      dispatch({
        type: GET_ALL_NOTIFICATIONS_STATUS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the protocols:", error);
      dispatch({ type: ERROR_LOADING_NOTIFICATIONS_STATUS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_NOTIFICATIONS_STATUS });
      // dispatch(loadingState(false))
    }
  };
