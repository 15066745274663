import { apiClient } from "../config";

async function removeEntityFromOrganization(
  id: any,
  entity: string,
  communityId: any
) {
  const url = `/admin/organizations/${id}/entity?entity_type=${entity}&entity_id=${communityId}`;
  const res = await apiClient.delete(url);
  return res.data;
}

export default removeEntityFromOrganization;
