import { apiClient } from "../config";

async function getMostObservedObjects(queryParams: any) {
  const res = await apiClient.get(
    `/admin/dashboard/widget/most-observed-objects`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getMostObservedObjects;
