import React from "react";
// Components
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import { IfetchRoles } from "store/roles/initialState";

export const Filters: React.FC<FiltersProps> = ({ columns }) => {
  const { data } = useSelector<RootState, IfetchRoles>(
    (state) => state?.allRolesPermissions
  );

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar data={data} />
      </div>
    </>
  );
};
