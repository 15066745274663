import React, { useEffect } from "react";

const useHandleShapeChangeMode = (
  mode: string,
  draw: any,
  isMapFullyLoaded: boolean,
  zoom: number
) => {
  useEffect(() => {
    if (isMapFullyLoaded && draw) {
      switch (mode) {
        case "rectangle":
          draw.changeMode("draw_rectangle");
          break;

        case "circle":
          draw.changeMode("draw_circle", {
            initialRadiusInKm: calculateRadiusByZoom(zoom) / 1000,
          });
          break;

        case "polygon":
          draw.changeMode("draw_polygon");
          break;

        case "line":
          draw.changeMode("draw_line_string");
          break;

        case "point":
          draw.changeMode("draw_point");
          break;
      }
    }
  }, [mode]);
  return 0;
};

export {useHandleShapeChangeMode};

function calculateRadiusByZoom(zoom: number) {
  const baseRadius = 500;
  const zoomLevel10 = 15;
  return baseRadius * Math.pow(2, zoomLevel10 - zoom);
}
