import { TableBodyProps } from '../../types';
import parseArray from 'utils/parseArray';
import { KEYS_TO_BE_REMOVED } from '../..';
import { Cell } from '@tanstack/react-table';
import { Column, RowsActions } from '../index';

export const TableBody = ({
  table,
  columnVisibility,
  getObjectsActions,
  flexRender,
}: TableBodyProps) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className='cursor-pointer Close w-full item_data dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group border-y group'
          style={{ height: '66px' }}
        >
          {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
            (cell: Cell<any, any>) => (
              <Column
                hoverEffect='item_hover_effect'
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
                rowData={row}
              />
            )
          )}
          <td className='whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight'>
            <RowsActions row={row} getObjectsActions={getObjectsActions} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
