function __deleteAllLocalDrawShapes__(draw: any) {
    const shapes = draw.getAll();
  
    if (shapes.features) {
      // NOTE: "feature" in id's string indactes this drawing is belongs to external source
      const updatedFeatures = shapes.features.filter((f: any) =>
        f.id.includes("feature")
      );
      shapes.features = updatedFeatures;
      draw.set(shapes);
    }
  };

export {__deleteAllLocalDrawShapes__};