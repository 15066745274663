import { TableBodyProps } from "../../types";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchFormDetailsAction } from "store/formDetails/reducer.actions";
import { useLocation, useNavigate } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

export const TableBody = ({
  table,
  columnVisibility,
  getDynamicFormsActions,
  flexRender,
}: TableBodyProps) => {
  const { dynamicForms } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const formId = queryParams.get("formId");

  const navigate = useNavigate();
  const openFormDetails = (row: any) => {
    if (!dynamicForms.canViewDynamicForm) return;

    if (formId && parseInt(formId) === parseInt(row?.original?.formName.id)) {
      dispatch(
        fetchFormDetailsAction({
          id: row?.original?.formName.id,
          path: "directory",
        })
      );
    } else {

      const currentPath = window.location.pathname;
      const queryParam = `formId=${encodeURIComponent(
        row?.original?.formName.id
      )}`;
      const newPath = `${currentPath}?${queryParam}`;
      navigate(newPath);
    }
  };

  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className="w-full cursor-pointer Close item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {row.getVisibleCells().map((cell: Cell<any, any>) => (
            <Column
              hoverEffect="item_hover_effect"
              flexRender={flexRender}
              formDetails={() => openFormDetails(row)}
              cell={cell}
              columnVisibility={columnVisibility}
            />
          ))}
          <td className="sticky right-0 whitespace-nowrap dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions
              row={row}
              getDynamicFormsActions={getDynamicFormsActions}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
