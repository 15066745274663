import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddConditionBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditConditionsModalAction } from "store/modals";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/conditions/list`, label: `Conditions` }];
  }
};
export const Header = () => {
  const { conditionsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { conditions } = usePermissions();
  const dispatch = useDispatch();
  const dynamicConditionsLinks = generateDynamicBreadcrumbLinks(
    conditionsFilter.status
  );

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicConditionsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {conditions.canCreateConditions && (
                <AddConditionBtn
                  handleAddConditionModel={() =>
                    dispatch(openAddOrEditConditionsModalAction())
                  }
                  label={"Add Condition"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
