import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DirectModeOverride,
  DragCircleMode,
  DrawRectangleMode,
  SimpleSelectMode,
  SnapLineMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from "../lib";
import { Dispatch, useEffect, useRef, useState } from "react";

import mapboxgl from "mapbox-gl";
import CustomShapesDrawControls from "../features/CustomShapesDrawControls";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useInitializeMap = (mapContainer: any, setDoUpdateDrawFeature: any) => {
  const map = useRef<any>(null);
  const drawRef = useRef<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);
  const [zoom, setZoom] = useState<null | number>(null);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL ?? "",
      center: [-145.6433003, 65.0710178],
      renderWorldCopies: false,
      minZoom: 0.999,
      // @ts-ignore
      projection: {
        name: "mercator",
      },
      zoom: 2,
    });

    // initializing all controls of drawing shapes
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: { trash: true },
      modes: {
        ...MapboxDraw.modes,
        draw_point: SnapPointMode,
        draw_polygon: SnapPolygonMode,
        draw_circle: CircleMode,
        direct_select: DirectModeOverride,
        drag_circle: DragCircleMode,
        simple_select: SimpleSelectMode,
        draw_line_string: SnapLineMode,
        draw_rectangle: DrawRectangleMode,
      },

      // Styling guides
       // styles: SnapModeDrawStyles,
        styles: [
          // ACTIVE (being drawn)
          // line stroke

          {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // polygon fill
          {
            id: "gl-draw-polygon-fill",
            type: "fill",
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
              "fill-color": "#D20C0C",
              "fill-outline-color": "#D20C0C",
              "fill-opacity": 0.2,
            },
          },
          // polygon mid points
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#fbb03b",
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: ["all", ["==", "$type", "Polygon"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 1,
            },
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 5,
              "circle-color": "#FFF",
            },
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 3,
              "circle-color": "#D20C0C",
            },
          },
          {
            id: "gl-draw-line-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF", // Blue color for inactive
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Polygon fill when inactive
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            paint: {
              "fill-color": "#007FFF", // Blue for inactive polygons
              "fill-outline-color": "#007FFF",
              "fill-opacity": 0.2, // Adjust opacity as needed
            },
          },
          // Polygon outline stroke when inactive
          {
            id: "gl-draw-polygon-stroke-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF",
              // "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Vertex points when inactive
          {
            id: "gl-draw-polygon-and-line-vertex-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "active", "true"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#007FFF",
            },
          },
        ],
      userProperties: true,
      // Config snapping features
      // @ts-ignore
      snap: true,
      snapOptions: {
        snapPx: 15, // defaults to 15
        snapToMidPoints: true, // defaults to false
        snapVertexPriorityDistance: 0.0025, // defaults to 1.25
      },
      guides: false,
    });

    map.current.addControl(drawRef.current);

    // adding zoom and navigation controls
    const nav = new mapboxgl.NavigationControl();
    map.current.addControl(nav, "bottom-right");

    // adding full screen control
    map.current.addControl(new mapboxgl.FullscreenControl());

    // adding custom control for layers listing
    const customControl = new CustomShapesDrawControls();
    map.current.addControl(customControl, "top-right");

    map.current.on("load", () => setIsMapFullyLoaded(true));
    map.current.on("zoom", () => setZoom(map.current.getZoom()));

    map.current.on("draw.create", () => {
      setDoUpdateDrawFeature(true);
    });
    map.current.on("draw.delete", () => {
      setDoUpdateDrawFeature(true);
    });
    map.current.on("draw.update", () => {
      setDoUpdateDrawFeature(true);
    });
  }, []);

  return { map, drawRef, isMapFullyLoaded, zoom };
};

export { useInitializeMap };
