export const ArrowDownloadIcon = ({}) => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={"hover:fill-primary"}
  >
    <path
   
      d='M4.64342 7.5L7.50056 10.5229L10.3577 7.52929M7.50056 2.5V10.3571M3.21484 12.5H11.7863'
      stroke='#727272'
      stroke-width='0.7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
export default ArrowDownloadIcon;
