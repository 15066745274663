import questionTypes from "../questionTypes";
import {
  convertJsonOfUpComingPointPicker,
  convertJsonOfUpComingShapePicker,
} from "./index";

function convertUpComingJsonOfGeographicalPanel(ele: any) {
  const elements = ele.elements ? ele.elements : ele.templateElements ?? [];
  let element = {
    // ...ele,
    type: "panel",
    name: ele.name,
    titleLocation: ele.titleLocation,
    _id: ele?._id ?? ele?.id ?? ele.Id,
    elements: elements,
    regionTypeShapePicker: convertJsonOfUpComingShapePicker(
      elements.find(
        (e: any) => e.type === questionTypes.shapePicker && e.name === "Region"
      )
    ),
    viewTypeShapePicker: convertJsonOfUpComingShapePicker(
      elements.find(
        (e: any) => e.type === questionTypes.shapePicker && e.name === "View"
      )
    ),
    pointPicker: convertJsonOfUpComingPointPicker(
      elements.find((e: any) => e.type === questionTypes.pointPicker)
    ),
  };
  // delete ele.type;
  // delete ele.id;
  // delete ele.elements;
  delete ele?.templateElements;
  // delete ele.panelType;

  element = { ...ele, ...element };

  return element;
}

export { convertUpComingJsonOfGeographicalPanel };
