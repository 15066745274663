import { SVGProps } from "react";
export const PerimeterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={4}
    fill="none"
    {...props}
  >
    <path
      fill="#2C3236"
      className="dark:fill-caption"
      fillRule="evenodd"
      d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7 0a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7 0a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PerimeterIcon;
