import generateUniqueId from "generate-unique-id";
import parseId from "../utils/parseId";
import splitCamelCaseToString from "../utils/splitCamelCaseToString";
import { v4 as uuidv4 } from "uuid";
import { uniqueIdGenertorInNumber } from "../utils/structureFormJSON";

function convertJsonOfGetGpsData(question: any, data: any) {
  const { mapCenterPoint, id, title, description, isRequired, zoomLevel } =
    data;

  const structuredData = {
    id,
    name: question.name,
    title: title ?? splitCamelCaseToString(question.name),
    type: question.type,
    description,
    isRequired,
    center_point: [mapCenterPoint.longitude, mapCenterPoint.latitude],
    elementId: uuidv4(),
    questionId: uniqueIdGenertorInNumber(),
    zoomLevel: zoomLevel,
  };

  return structuredData;
}

export { convertJsonOfGetGpsData };
