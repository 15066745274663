import apiClient from "services/apiClient";

async function archiveOrRestoreReport(id: any, status: any) {
  const res = await apiClient.put(`/admin/reports/${id}/archive`, {
    status,
  });
  return res.data;
}

export default archiveOrRestoreReport;
