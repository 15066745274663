import React, { ReactNode, useEffect, useRef, useState } from "react";

interface Props {
  children: ReactNode;
  handleOnScrollEnd?: any;
  loading: boolean;
  height?:string;
}

const InfiniteScroll = ({ children, handleOnScrollEnd, loading,height }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;

      if (!container) return;

      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 10 
          && !loading
      ) {
        handleOnScrollEnd && handleOnScrollEnd();
      }
    };

    containerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  return (
    <div style={{ maxHeight: height ?? "300px", overflowY: "auto" }} ref={containerRef}>
      {children}
    </div>
  );
};

export default InfiniteScroll;
