import {
  MOVE_TO_NEXT_STEP,
  MOVE_TO_PREVIOUS_STEP,
  RESET_IMPORT_STEPPER,
  SET_IMPORT_ID,
  MOVE_TO_SPECIFIC_STEP
} from "./action-types";
import initialState, { Step, Stepper } from "./initialState";

export const addImportStepperReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MOVE_TO_NEXT_STEP:
      const currentIndex = state.steps.findIndex((step: Step) => step.isActive);

      if (currentIndex !== -1) {
        const nextIndex = currentIndex + 1;

        if (nextIndex < state.steps.length) {
          const updatedSteps = state.steps.map((step: Step, index) => ({
            ...step,
            isActive: index === nextIndex,
            isSubmitted: index === currentIndex ? true : step.isSubmitted,
          }));

          return {
            ...state,
            steps: updatedSteps,
          };
        }
      }

      return state;
    case MOVE_TO_PREVIOUS_STEP:
      const prevIndex = state.steps.findIndex((step: Step) => step.isActive);

      if (prevIndex !== -1) {
        const newIndex = prevIndex - 1;

        if (newIndex >= 0) {
          const updatedSteps = state.steps.map((step: Step, index) => ({
            ...step,
            isActive: index === newIndex,
            isSubmitted: index === prevIndex ? true : step.isSubmitted,
          }));

          return {
            ...state,
            steps: updatedSteps,
          };
        }
      }

      return state;


    case MOVE_TO_SPECIFIC_STEP:
      const targetIndex = action.payload;

      // If targetIndex is within the bounds of the steps array
      if (targetIndex >= 0 && targetIndex < state.steps.length) {
        const updatedSteps = state.steps.map((step, index) => ({
          ...step,
          isActive: index === targetIndex,
          // Preserve the existing isSubmitted property
          isSubmitted: step.isSubmitted,
        }));

        // Optionally, you can also update other properties in the state
        return {
          ...state,
          steps: updatedSteps,
        };
      }

      // If targetIndex is out of bounds, return the current state
      return state;

    case SET_IMPORT_ID:
      return {
        ...state,
        reportId: action.payload,
      };

    case RESET_IMPORT_STEPPER:
      return initialState;
    default:
      return state;
  }
};
