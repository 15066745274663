import { apiClient } from "../config";

async function editCommunityTags(communityId: any, data: any) {
  const res = await apiClient.put(
    `/admin/communities/${communityId}/tags`,
    data
  );
  return res.data;
}

export default editCommunityTags;
