import { Ifetch } from "../initialState";

export interface IObservations {
  count: number;
  day?: string;
  week?: string;
  month?: string;
  year?: string;
}

export interface IfetchObservations extends Ifetch {
  data: IObservations[];
  maxYAxix: number;
  totalCount: number;
}

const observationsInitialState: IfetchObservations = {
  data: [],
  message: "",
  maxYAxix: 0,
  totalCount: 0,
  stateIs: "Pending",
};
export default observationsInitialState;
