import React from 'react';
import { useDispatch } from 'react-redux';
import { openImageVideoLightBoxModalAction } from 'store/modals';
import VideoThumbnail from './components/VideoThumbnail';
import ImageThumbnail from './components/ImageThumbnail';
import DocumentThumbnail from './components/DocumentThumbnail';
import AudioThumbnail from './components/AudioThumbnail';
import axios from 'axios';
import { PaperClipIcon } from 'assets/icons/HeroIcons';

interface MediaThumbnailsProps {
  message?: {
    file?: {
      original?: string;
    };
    messageType?: string;
    fileName?: string;
    messageSendTime?: string;
    userId: number;
    fileId: number;
    fileSize: number;
  };
  chats: any[];
}

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
  message,
  chats,
}) => {
  const dispatch = useDispatch();
  const fileName = message?.fileName || '';
  const fileUrl = message?.file?.original || '';
  const fileSize = message?.fileSize || 0;
  const fileType = getFileType(message?.messageType || '');
  const messageType = message?.messageType || '';
  const messageSendTime = message?.messageSendTime || '';
  const fileId = message?.fileId || 0;
  const userId = message?.userId || 0;

  function getFileType(type: string): string {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      return 'image';
    } else if (type.match(/(mp3|mpeg)$/)) {
      return 'audio';
    } else if (type.match(/(mp4|mpeg|avi|mkv|wmv|flv|mov|webm|quicktime)$/)) {
      return 'video';
    } else if (type === 'pdf') {
      return 'pdf';
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      return 'doc';
    } else if (type.match(/(txt|csv)$/)) {
      return 'txt';
    } else if (type.match(/(zip|rar|7z)$/)) {
      return 'archive';
    } else if (type === 'geojson') {
      return 'geojson';
    } else {
      return 'unknown';
    }
  }

  function previewFileType(filePath: string, type: string, fileId: number) {
    if (!filePath) return;
    switch (type) {
      case 'image':
      case 'video':
        const urls = collectMediaUrls(chats);
        dispatch(openImageVideoLightBoxModalAction({ urls, fileId }));
        break;

      case 'pdf':
      case 'txt':
      case 'archive':
      case 'geojson':
        window.open(filePath, '_blank');
        break;
      case 'doc':
        const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(filePath)}`;
        window.open(googleDocsViewerUrl, '_blank');
        break;

      default:
        break;
    }
  }

  const handlePreviewFiles = () => {
    previewFileType(fileUrl, fileType, fileId);
  };

  const renderContent = () => {
    const props = {
      url: fileUrl,
      userId,
      sendTime: messageSendTime,
      handlePreviewFiles: handlePreviewFiles,
    };
    switch (fileType) {
      case 'video':
        return <VideoThumbnail {...props} />;
      case 'image':
        return <ImageThumbnail {...props} />;
      case 'pdf':
      case 'doc':
      case 'txt':
      case 'archive':
      case 'geojson':
        return (
          <DocumentThumbnail
            url={fileUrl}
            userId={userId}
            type={messageType}
            sendTime={messageSendTime}
            fileSize={fileSize}
            title={fileName}
            handlePreviewFiles={handlePreviewFiles}
          />
        );
      case 'audio':
        return <AudioThumbnail {...props} />;
      default:
        return <PaperClipIcon />;
    }
  };

  return <div>{renderContent()}</div>;
};

export default MediaThumbnails;

const collectMediaUrls = (chatsData: any[]) => {
  const urls = chatsData?.reduce(
    (acc: { url: string; type: string; fileId: number }[], file: any) => {
      if (file && file?.file && file?.file?.original) {
        const url = file?.file?.original;
        let type = '';
        const messageType = file.messageType.toLowerCase();
        const imageTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'heif', 'heic'];
        const videoTypes = [
          'mp4',
          'webm',
          'ogg',
          'avi',
          'mov',
          'mkv',
          'flv',
          'wmv',
          '3gp',
          'mpeg',
          'ts',
          'divx',
          'xvid',
          'quicktime',
        ];
        if (imageTypes.includes(messageType)) {
          type = messageType;
        } else if (videoTypes.includes(messageType)) {
          type = messageType;
        }
        if (type) {
          acc.push({ url, type, fileId: file.fileId });
        }
      } else {
        console.error('File or file.original is missing in:', file);
      }
      return acc;
    },
    []
  );
  return urls;
};
