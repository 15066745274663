import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  height?: any;
  width?: any;
  isActive?: boolean;
}

export const MapIcon = ({
  fill = "#6F747B",
  isActive,
  height,
  width,
  ...restProps
}: IconProps) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M14.553 3.106a1 1 0 0 1 .894 0l5.447 2.723A2 2 0 0 1 22 7.618v10.764a2 2 0 0 1-2.894 1.789L15 18.118l-5.553 2.776a1 1 0 0 1-.894 0l-5.447-2.723A2 2 0 0 1 2 16.382V5.618a2 2 0 0 1 2.894-1.789L9 5.882l5.553-2.776ZM8 7.618l-4-2v10.764l4 2V7.618Zm2 10.764 4-2V5.618l-4 2v10.764Zm6-12.764v10.764l4 2V7.618l-4-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MapIcon;
