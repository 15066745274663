import getAllPolls from "./getAllPolls";
import createSurveyForm from "./createSurveyForm";
import archiveOrRestorePollsAndSurvey from "./archiveOrRestorePollsAndSurvey";
import deletePollsAndSurvey from "./deletePollsAndSurvey";
import editSurvey from "./editSurvey";
import getSurveyDetails from "./getSurveyDetails";
import updateSurveyFields from "./updateSurveyFields";
import submitSurvey from "./submitSurvey";
import getPollAndSurveyResults from "./getPollAndSurveyResults";
import getSurveyDetailsByToken from "./getSurveyDetailsByToken";
import getPollAndSurveyVersions from "./getPollAndSurveyVersions";

const pollsAndSurveys = {
  getAllPolls,
  createSurveyForm,
  archiveOrRestorePollsAndSurvey,
  deletePollsAndSurvey,
  getSurveyDetails,
  editSurvey,
  updateSurveyFields,
  submitSurvey,
  getPollAndSurveyResults,
  getSurveyDetailsByToken,
  getPollAndSurveyVersions
};

export default pollsAndSurveys;
