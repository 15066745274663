const ClearAllFilterBar = ({ handleClickOnClearAllBtn }: any) => {
  return (
    <div className="mt-[18px] flex justify-between items-center">
      <h2 className="font-Overpass text-[17px] font-normal text-secondaryMid dark:text-textMain">
        Geospatial boundary filtering
      </h2>
      <button
        type="button"
        className="text-sm font-semibold font-Overpass text-primaryDark"
        onClick={handleClickOnClearAllBtn}
      >
        Clear All
      </button>
    </div>
  );
};

export { ClearAllFilterBar };
