import { apiClient } from "../config";

async function getSingleGlobalLayer(
  layerId: string
) {
  const res = await apiClient.get(
    `/admin/geographies/${layerId}`
  );
  return res.data;
}

export default getSingleGlobalLayer;
