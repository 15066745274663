import { useState } from "react";
import usePermissions from "./usePermissions";

export interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
  hasAccess: boolean;
}

export default function useImportsStepper(): {
  steps: Step[];
  moveToNextStep: () => void;
  moveToPreviousStep: () => void;
  moveToSpecificStep: (stepName: string) => void;
  resetStepper: () => void;
} {
  const initialState: Step[] = useSteps();

  const [steps, setStepper] = useState<Step[]>(initialState);

  const moveToNextStep = () => {
    setStepper((prev: Step[]) => {
      const currentIndex = prev.findIndex((step) => step.isActive);
      const nextIndex =
        currentIndex < prev.length - 1 ? currentIndex + 1 : currentIndex;

      return prev.map((step, index) => ({
        ...step,
        isActive: index === nextIndex,
        isSubmitted: index <= nextIndex,
      }));
    });
  };

  const moveToPreviousStep = () => {
    setStepper((prev: Step[]) => {
      const currentIndex = prev.findIndex((step) => step.isActive);
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;

      return prev.map((step, index) => ({
        ...step,
        isActive: index === prevIndex,
      }));
    });
  };

  const moveToSpecificStep = (stepName: string): void => {
    setStepper((prev: Step[]) => {
      return prev.map((step) => ({
        ...step,
        isActive: step.step === stepName,
      }));
    });
  };

  const resetStepper = () => {
    setStepper(initialState);
  };

  return {
    steps,
    moveToNextStep,
    moveToPreviousStep,
    moveToSpecificStep,
    resetStepper,
  };
}

function useSteps(): Step[] {
  const { imports } = usePermissions();

  const [stepper, setStepper] = useState(() => {
    const steps = [
      {
        title: "Upload File",
        step: "uploadFile",
        heading: "Import from a file",
        isActive: true,
        isSubmitted: false,
        // hasAccess: true,
        hasAccess: imports.canCreateImportImports,
      },
      {
        title: "Column Mapping",
        step: "columnMapping",
        heading: "Map Fields",
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: imports.canEditColumnMappingImports,
      },
      {
        title: "Import",
        step: "importInProgress",
        heading: "",
        isActive: false,
        isSubmitted: false,
        hasAccess: imports.canCreateImports,
      },
      {
        title: "Done",
        step: "importDone",
        heading: "",
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
      },
    ];

    const accessedSteps = steps.filter((step) => step.hasAccess);

    return accessedSteps;
  });

  return stepper;
}
