import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { Stepper, Step } from "store/addReportStepper/initialState";
import * as Yup from "yup";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import {
  DateTimePicker,
  EndsSettings,
  ReportTimeSelection,
  RepeatSettings,
} from "./components";

import { ISummaryReports } from "store/reportsSummary";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { formatTheDates } from "utils/formatTheDates";
import { formatTheTime } from "utils/formatTheTime";
import { TIME_FORMATS } from "constants/timeFormats";

interface ReportSettingsProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}
export interface IReportSettingsFormValues {
  endAfter: number | null;
  endOnDate: string;
  isRepeat: boolean;
  now: boolean;
  repeatDays: string[];
  repeatDaysCount: number;
  repeatPeriod: "" | "weeks" | "months" | "years";
  startRunDate: string;
  startRunTime: string;
  endOnDateIsSelected: boolean;
  endAfterIsSelected: boolean;
  neverStop: boolean;
  occuranceAccordingToMonth: {
    dayOfWeek: number;
    weekOfMonth: number;
    dayOfMonth: number;
    type: string;
    monthlyOccurrenceTitle: string;
    isLastOccurrence: boolean;
  };
}

export const ReportSettings: React.FC<ReportSettingsProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  setAnythingUpdatedThisStep,
}) => {
  const [initialState, setinitialState] = useState<any>({
    endAfter: 4,
    endOnDate: "",
    isRepeat: false,
    now: true,
    repeatDays: [],
    repeatDaysCount: 1,
    repeatPeriod: "",
    startRunDate: "",
    startRunTime: "00:00:00",
    endAfterIsSelected: false,
    endOnDateIsSelected: true,
    neverStop: false,
    occuranceAccordingToMonth: {
      dayOfWeek: null,
      weekOfMonth: null,
      dayOfMonth: null,
      type: null,
      monthlyOccurrenceTitle: null,
    },
  });

  useEffect(() => {
    const { reportSetting } = reportSummary;
    const updatedData = {
      endAfter: reportSetting.endAfter ?? null,
      endOnDate: reportSetting.endOnDate ?? "",
      isRepeat: !!reportSetting.now || !!reportSetting.isRepeat,
      now: !reportSetting.isRepeat && (reportSetting.now ?? true),
      repeatDays: reportSetting.repeatDays ?? [],
      repeatDaysCount: reportSetting.repeatDaysCount ?? 1,
      repeatPeriod: reportSetting.repeatPeriod ?? "",
      startRunDate: reportSetting.startRunDate ?? "",
      startRunTime: reportSetting.startRunTime ?? "00:00:00",
      endOnDateIsSelected: reportSetting.endOnDate !== null,
      endAfterIsSelected: reportSetting.endAfter !== null,
      neverStop: reportSetting.neverStop ?? false,
      occuranceAccordingToMonth: {
        dayOfWeek: reportSetting.dayOfWeek ?? null,
        weekOfMonth: reportSetting.weekOfMonth ?? null,
        dayOfMonth: reportSetting.dayOfMonth ?? null,
        type: reportSetting.type ?? null,
        monthlyOccurrenceTitle: reportSetting.monthlyOccurrenceTitle ?? "",
        isLastOccurrence: reportSetting.isLastOccurrence ?? false,
      },
      // dayOfWeek: null,
    };

    setinitialState(updatedData);
  }, [reportSummary]);

  const reportSettingsSchema = Yup.object().shape({
    endAfter: Yup.number()
      .integer()
      .nullable()
      .test("endAfter", "End after is required", function (value) {
        const { isRepeat, endAfterIsSelected } = this.parent;
        if (isRepeat && endAfterIsSelected) {
          return !!value;
        }
        return true;
      }),
    isRepeat: Yup.boolean().required("Is repeat is required"),
    now: Yup.boolean().required("Now is required"),

    endOnDateIsSelected: Yup.boolean(),

    endOnDate: Yup.string()
      .nullable()
      .test("endOnDateRequired", "End on date is required", function (value) {
        const { isRepeat, endOnDateIsSelected, endAfterIsSelected } =
          this.parent;
        if (isRepeat && endOnDateIsSelected && !endAfterIsSelected) {
          return !!value;
        }
        return true;
      }),

    repeatDays: Yup.array().test(
      "repeatDaysRequired",
      "Repeat Days Are Required",
      function (value) {
        const { now, isRepeat, repeatPeriod } = this.parent;
        if (repeatPeriod !== "weeks") {
          return true;
        }

        if (!now && isRepeat && (value === null || value?.length === 0)) {
          return false;
        }

        return true;
      }
    ),

    repeatDaysCount: Yup.number()
      .nullable()
      .test(
        "repeatDaysCountRequired",
        "Repeat days count is required",
        function (value) {
          const { now } = this.parent;

          if (!now && (value === null || value === undefined)) {
            return false;
          }

          return true;
        }
      ),

    repeatPeriod: Yup.string()
      .nullable()
      .test(
        "repeatPeriodRequired",
        "Repeat Period is required",
        function (value) {
          const { now } = this.parent;

          if (!now && value === "") {
            return false;
          }

          return true;
        }
      ),
    startRunDate: Yup.string()
      .nullable()
      .test("startRunDate", "Start run date is required", function (value) {
        const { now } = this.parent;

        if (!now && (!value || value === "")) {
          return false;
        }

        return true;
      }),

    startRunTime: Yup.string()
      .nullable()
      .test("startRunTime", "Start run time is required", function (value) {
        const { now } = this.parent;

        if (!now && (!value || value === "")) {
          return false;
        }

        return true;
      }),

    occuranceAccordingToMonth: Yup.object().test(
      "occuranceAccordingToMonth",
      "Occurrence according to the month is required",
      function (value: any) {
        const { now, isRepeat, repeatPeriod } = this.parent;

        if (repeatPeriod !== "months") {
          return true;
        }
        const isValidValue =
          value &&
          value.dayOfWeek === null &&
          value.weekOfMonth === null &&
          value.type === null &&
          value.dayOfMonth === null;
        if ((!now && isRepeat && value === null) || isValidValue) {
          return false;
        }
        return true;
      }
    ),
  });

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: IReportSettingsFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const updatedValues: any = {
      ...values,
      endOnDate: formatTheDates(values.endOnDate, { utc: true }),
      startRunDate: formatTheDates(values.startRunDate, {
        utc: true,
        time: values.startRunTime,
      }),
      startRunTime: formatTheTime(values.startRunTime, TIME_FORMATS.FULL_TIME, {
        utc: true,
      }),
    };
    updatedValues.dayOfWeek = values.occuranceAccordingToMonth.dayOfWeek;
    updatedValues.weekOfMonth = values.occuranceAccordingToMonth.weekOfMonth;
    updatedValues.dayOfMonth = values.occuranceAccordingToMonth.dayOfMonth;
    updatedValues.type = values.occuranceAccordingToMonth.type;
    updatedValues.monthlyOccurrenceTitle =
      values.occuranceAccordingToMonth.monthlyOccurrenceTitle;
    updatedValues.isLastOccurrence =
      values.occuranceAccordingToMonth.isLastOccurrence;
    delete updatedValues.occuranceAccordingToMonth;

    try {
      const { data } = await apiLibrary.Reports.AddReportApis.addReportSettings(
        reportSummary.id,
        updatedValues
      );
      await fetchReportsSummary(reportSummary.id);
      moveToNextStep();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={reportSettingsSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(FormikProps) => {
        useEffect(() => {
          setAnythingUpdatedThisStep("reportSettings", FormikProps.dirty);
        }, [FormikProps.dirty]);
        return (
          <Form>
            <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                <div className="mb-2">
                  <p className="text-sm text-textMidLight font-Overpass dark:text-textMain">
                    Adjust time period for creating this report. You can create
                    one-time event or create repeating event. If you select more
                    then 6 months, the processing time may be long.
                  </p>
                </div>
                <ReportTimeSelection />

                {!FormikProps.values.now && (
                  <>
                    <DateTimePicker />

                    {FormikProps.values.isRepeat && (
                      <>
                        <RepeatSettings />
                        <EndsSettings />
                      </>
                    )}
                  </>
                )}
              </div>
              <SubmissionButtons
                isValid={FormikProps.isValid}
                isSubmitting={FormikProps.isSubmitting}
                handleGoBackBtn={() => moveToPreviousStep()}
                values={FormikProps.values}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
}: any) => {
  const { reports } = usePermissions();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="before"
        onClick={handleGoBackBtn}
      />
      <Button
        disabled={isSubmitting || !reports.canCreateSettingReports}
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        icon={<ArrowRightIcon fill="#005C89" />}
        iconPosition="after"
      />
    </div>
  );
};
