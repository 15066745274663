import React from "react";
// Components
import { PageHeading, AddReportBtn } from "./components";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
// Store Utils
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import { RunReportBtn } from "./components/RunReportBtn";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import { useDispatch } from "react-redux";
import {
  openArchiveRestoreDeleteReportsModalAction,
  openReportsSummaryModalAction,
} from "store/modals";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import useRoles from "hooks/roles";

export const Header = () => {
  const reportsProfile = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );
  const { reports } = usePermissions();
  const { isExternalDataEndReviewer } = useRoles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reportsId } = useParams<{
    reportsId: any;
  }>();
  const id = reportsId;
  const dynamicReportProfileLinks = [
    { path: `/reports/list`, label: `Reports` },
    // {
    //   path: `/reports/${reportsId}/profile`,
    //   label: "Report profile",
    // },
    {
      path: `/reports/${reportsId}/profile`,
      label: reportsProfile?.data?.reportName,
    },
  ];

  const actions = [
    {
      label: "Report Summary",
      onClick: () => {
        dispatch(openReportsSummaryModalAction(id));
      },
      divider: false,
      isVisible: reports.canViewSummaryReports,
    },
    {
      label: "Edit",
      onClick: () => {
        navigate(`/reports/${id}/edit`);
      },
      divider: false,
      isVisible: !isExternalDataEndReviewer() && reports.canEditReports && reportsProfile.data.status !== "archived",
    },
    {
      label: "Delete",
      onClick: () => {
        dispatch(
          openArchiveRestoreDeleteReportsModalAction({
            id,
            status: "deleted",
            path: "profilePage",
          })
        );
      },
      divider: false,
      isVisible: reports.canDeleteReports && !isExternalDataEndReviewer(),
    },
  ];

  const handleAnalyticsReport = () => {
    navigate(`/reports/${reportsId}/analytics`);
  };
  const handleRunReport = () => {
    navigate(`/reports/${reportsId}/run-report`);
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <DirectoryBreadCrumb links={dynamicReportProfileLinks} />
          <div className="flex items-start justify-between w-full">
            <PageHeading reportsName={reportsProfile?.data.reportName} />
            <div className="flex gap-2">
              {reports.canViewAnalyticsReports && reportsProfile.data.status !== "archived" && (
                <AddReportBtn handleAddReport={handleAnalyticsReport} />
              )}
              {reports.canViewProcessedVersionReports && reportsProfile.data.status !== "archived" &&  (
                <RunReportBtn handleRunReport={handleRunReport} />
              )}
              <div className="flex justify-end items-center flex-grow relative py-0.5">
                {(reports.canViewSummaryReports ||
                  reports.canEditReports ||
                  reports.canDeleteReports) && (
                  <>
                    {actions.some(
                      (action: any) => action.isVisible === true
                    ) ? (
                      <PopOverMenu icon={<DotsVerticalIcon className="" />}>
                        <ActionDropDownMenu data="data" actions={actions} />
                      </PopOverMenu>
                    ) : (
                      <DotsVerticalIcon className="mt-3" />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
