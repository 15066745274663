import { Dispatch } from "redux";
import {
  APPEND_NEW_COMMENT,
  GET_ALL_ACTIVITIES,
  GET_ALL_PAGINATED_ACTIVITIES,
  RESET_DATA,
  START_LOADING_ACTIVITIES,
  STOP_LOADING_ACTIVITIES,
} from "./action-types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";

export const fetchObservationsActivitiesAction =
  (observationId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_ACTIVITIES });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.observationActivityFilter
      );
      const { data } =
        await apiLibrary.Observations.getAllObservationsActivities(
          observationId,
          queryString
        );
      dispatch({
        type: GET_ALL_ACTIVITIES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch({ type: STOP_LOADING_ACTIVITIES });
    }
  };

export const resetObservationsActivitiesAction = () => {
  return {
    type: RESET_DATA,
  };
};

export const fetchPaginatedObservationsActivitiesAction =
  (observationId: any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.observationActivityFilter
      );
      const { data } =
        await apiLibrary.Observations.getAllObservationsActivities(
          observationId,
          queryString
        );
      dispatch({
        type: GET_ALL_PAGINATED_ACTIVITIES,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

export const appendNewComment = (comment: any) => {
  return {
    type: APPEND_NEW_COMMENT,
    payload: comment,
  };
};
