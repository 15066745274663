import React, { useCallback, useEffect, useState } from 'react';
// Components
import { Toasts } from 'view/components/Toasts';
import { AddProgram } from './components/AddProgram';
import AddAdmin from './components/AddAdmin';
// External Components
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
// Store Utils
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { closeAddProgramModalAction } from 'store/modals/reducer.actions';
// API Services
import apiLibrary from 'services/api';
// External Utils
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment, { utc } from 'moment';
import usePermissions from 'hooks/usePermissions';
import { formatTheDates } from 'utils/formatTheDates';
import { formatTheTime } from 'utils/formatTheTime';
import { TIME_FORMATS } from 'constants/timeFormats';
import {
  getEndOfDayInLocalTimeZoneToUtc,
  getMidnightInLocalTimeZoneToUtc,
} from 'utils/getMidnightOrLocalDayeEndTime';
import useRoles from 'hooks/roles';
import { getAppName } from 'utils/customBranding';

const style: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '15px',

  backgroundColor: '#ffff',
};

interface AddProgramModalProps { }

export const AddProgramModal: React.FC<AddProgramModalProps> = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [organizations, setOrganizations] = useState<any>([]);
  const steps = ['Program Details', 'Add Admins'];
  const { addProgramModal } = useSelector((state: RootState) => state.modals);
  const dispatch = useDispatch();
  const { programs } = usePermissions();
  const { isSuperAdmin } = useRoles();
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const createProgramValidationSchema = [
    Yup.object().shape({
      coverImageId: Yup.number(),
      description: Yup.string().max(6000, '6000 Max Characters'),
      name: Yup.string()
        .required('Program Name is required')
        .max(255, 'Program name can be 255 characters'),
      appName: Yup.object().shape({
        value: Yup.string().required('App Is Required'),
        label: Yup.string().required('App Is Required'),
      }),
      profileImageId: Yup.string(),
      code: Yup.string()
        .required('Program Code is required')
        .min(4, 'Program Code must be 4 character')
        .max(4, 'Program Code must be 4 character'),
      startDate: Yup.mixed()
        .required('Start Date Is Required')
        .test(
          'startDate',
          'Start date must be before end date',
          function (value) {
            const endDate = this.parent.endDate;
            if (endDate && value) {
              return moment(value).isBefore(endDate);
            }
            return true;
          }
        ),
      endDate: Yup.mixed().test(
        'endDate',
        'End date must be after start date',
        function (value) {
          const startDate = this.parent.startDate;
          if (startDate && value) {
            return moment(value).isAfter(startDate);
          }
          return true;
        }
      ),
      isAvailableToAllObservers: Yup.boolean(),
      requireQa: Yup.boolean(),
      isPublic: Yup.boolean(),

      leadingOrganizationId: Yup.object().shape({
        value: Yup.string().required('Organization is Required'),
      }),
    }),
    Yup.object().shape({
      adminIds: !programs.canViewAdminsDropdown
        ? Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
          })
        )
        : Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.number().required('Admin ID is required'),
            })
          )
          .required('Admin IDs are required'),
    }),
  ];
  const currentValidationSchema = createProgramValidationSchema[activeStep];

  const isLastStep = activeStep === steps.length - 1;

  const [initialState, setInitialState] = useState({
    adminIds: [],
    coverImageId: '',
    description: '',
    name: '',
    leadingOrganizationId: '',
    profileImageId: '',
    profileImage: '',
    code: '',
    startDate: '',
    endDate: '',
    isGlobalForSkipper: false,
    requireQa: false,
    isPublic: false,
    timeZone: '',
    appName: getAppName(isSuperAdmin())

  });

  useEffect(() => {
    if (
      addProgramModal.data &&
      addProgramModal.data.organizationName !== null &&
      addProgramModal.data.organizationId !== null
    ) {
      setInitialState((pre: any) => {
        return {
          ...pre,
          leadingOrganizationId: {
            label: addProgramModal.data.organizationName,
            value: addProgramModal.data.organizationId,
          },
        };
      });
    }
  }, []);

  const fetchLeadOrganizations = useCallback(
    (appName?: string, searchQuery?: string) => {
      if (programs.canViewOrganizationsDropdown) {
        apiLibrary.Programs.getAllOrganizations(searchQuery, appName)
          .then(({ data }) => {
            if (data && data.organizations) {
              setOrganizations(
                data.organizations.map((o: any) => {
                  return { label: o.name, value: o?.id };
                })
              );
            }
            else {
              setOrganizations([]);
            }
          })
          .catch((err) => {
            Toasts.error(err.message);
          });
      }
    },
    [initialState?.appName?.value]
  );

  const handleClose = () => {
    dispatch(closeAddProgramModalAction());
    setActiveStep(0);
  };

  const handleLeadOrganizationSearch = (query: string, appName?: string) => {
    fetchLeadOrganizations(appName, query);
  };

  useEffect(() => {
    fetchLeadOrganizations(initialState?.appName?.value);
  }, [fetchLeadOrganizations])

  async function _submitForm(values: any, actions: any) {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setIsLoading(true);

    try {
      const {
        coverImageId,
        profileImageId,
        name,
        leadingOrganizationId,
        adminIds,
        code,
        startDate,
        endDate,
        requireQa,
        isPublic,
        description,
        appName,
        isGlobalForSkipper
      } = values;
      const startOfTheDay = getMidnightInLocalTimeZoneToUtc(
        startDate,
        profileSettings.timezone ? profileSettings.timezone : currentTimezone
      );
      const endOfTheDay = getEndOfDayInLocalTimeZoneToUtc(
        endDate,
        profileSettings.timezone ? profileSettings.timezone : currentTimezone
      );
      const res = await apiLibrary.Programs.createProgram({
        coverImageId,
        profileImageId,
        name,
        code,
        startDate: formatTheDates(startOfTheDay, { utc: false }),
        endDate: formatTheDates(endOfTheDay, { utc: false }),
        startTime: formatTheTime(
          startOfTheDay,
          TIME_FORMATS.HOURS_MINUTES_24H,
          { utc: false }
        ),
        endTime: formatTheTime(endOfTheDay, TIME_FORMATS.HOURS_MINUTES_24H, {
          utc: false,
        }),
        requireQa,
        isPublic,
        description,
        adminIds: adminIds?.map((admin: any) => {
          return admin.id;
        }),
        leadingOrganizationId: leadingOrganizationId.value,
        appName: appName?.value,
        isGlobalForSkipper
      });
      Toasts.success(res.message);
      handleClose();
      navigate(`/programs/${res?.data?.id}/profile`);
      setActiveStep(0);
    } catch (error: any) {
      // Handle any errors that occur during the API call
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }

  function handleGoBackBtn() {
    setActiveStep((step) => step - 1);
  }

  function _handleSubmit(
    values: any,
    actions: FormikHelpers<any> | any // Use FormikHelpers<any> or 'any' for compatibility
  ) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      if (!programs.canViewAdminsDropdown && activeStep === 0) {
        _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  function _renderStepContent(step: number, formikProps: FormikProps<any>) {
    switch (step) {
      case 0:
        return (
          <AddProgram
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
            organizations={organizations}
            fetchLeadOrganizations={fetchLeadOrganizations}
            handleLeadOrganizationSearch={handleLeadOrganizationSearch}

          />
        );
      case 1:
        return (
          <>
            <AddAdmin
              {...formikProps}
              handleClose={handleClose}
              handleGoBackBtn={handleGoBackBtn}
              isLastStep={isLastStep}
              activeStep={activeStep}
              isFormLoading={isLoading}
            />
          </>
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <Modal
      open={addProgramModal.isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      disableAutoFocus={true}
      aria-describedby='modal-modal-description'
      className='border-none'
    >
      <Box sx={style} className='dark:bg-secondaryLight '>
        <Formik
          initialValues={initialState}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={false}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form className='rounded-lg'>
              <div className='flex flex-col justify-start items-start w-[700px] rounded-lg'>
                <div
                  className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight'
                  style={{
                    boxShadow:
                      '0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)',
                  }}
                >
                  {_renderStepContent(activeStep, formikProps)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
