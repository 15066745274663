// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading } from "./components";

// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import { filtersInitialState } from "store/filters/initialState";
import useDateFormatter from "hooks/formatDateWithPattern";
import { sentenceCase } from "change-case";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toasts } from "view/components/Toasts";
import { IfetchQualityAssuranceObservations } from "store/qualityAssurance/initialState";

const generateDynamicLinks = (
  programId: any,
  communityId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/quality-assurance`,
          label: `${name}`,
        },
      ];

    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/quality-assurance`,
          label: `Quality Assurance`,
        },
      ];
    default:
      return [{ path: `/quality-assurance/list`, label: `Quality Assurance` }];
  }
};
interface IProps {
  isExternalReviewer?: any;
}

export const Header: React.FC<IProps> = ({ isExternalReviewer }) => {
  const navigate = useNavigate();
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();
  const dynamicLinks = generateDynamicLinks(
    programId ?? "",
    communityId ?? "",
    BreadcrumbData?.name ?? "",
    qualityAssuranceObservationsFilter.status
  );
  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    } else if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/community/list");
        });
    }

    return () => {};
  }, [programId]);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          {isExternalReviewer ? (
            <ExternalReviewerHeader />
          ) : (
            <>
              <Breadcrumb links={dynamicLinks} />
              <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                <PageHeading
                  id={BreadcrumbData.id}
                  name={BreadcrumbData.name}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ExternalReviewerHeader = () => {
  const { daysUntil } = useDateFormatter();
  const auth = useSelector((state: RootState) => state.auth);
  const { data } = useSelector<RootState, IfetchQualityAssuranceObservations>(
    (state) => state?.allQualityAssuranceObservations
  );

  return (
    <div className="flex">
      <div className="flex flex-col items-start justify-start">
        <div>
          <p className="text-sm text-semibold text-textMidLight">
            External Reviewer
          </p>
        </div>
        <div className="flex">
          {auth?.person?.personImage ? (
            <div>
              <img
                className="w-5 h-5 rounded-full"
                src={auth?.person?.personImage.file.thumb}
                alt=""
              />
            </div>
          ) : (
            <UserAvatarIcon />
          )}
          <p className="ml-2 mt-[-1px] text-sm">
            {auth.person?.firstName} {auth.person?.lastName}
          </p>
        </div>
      </div>
      <div className={`w-56 ml-[250px]`}>
        <p className="text-sm text-semibold text-textMidLight">
          Access Days Left
        </p>
        <p className="text-sm mt-[2px] capitalize">
          {data?.externalReviewerAccessUntil !== "" &&
            daysUntil(data?.externalReviewerAccessUntil)}
        </p>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
