import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const OfficeBuildingKpiIcon = ({
  fill,
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
    {...restProps}>
    <path
      fill={fill || "#6F747B"}
      className={`dark:${isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
        }`}
      fillRule="evenodd"
      d="M4 5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v15h1a1 1 0 1 1 0 2H3a1 1 0 0 1 0-2h1V5Zm2 15h3v-4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4h3V5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v15Zm7 0v-4h-2v4h2ZM8 7a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Zm-5 4a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default OfficeBuildingKpiIcon;
