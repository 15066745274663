import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";

interface CustomDropdown {
  isOpenDropdown: boolean;
  setIsOpenDropdown: (isOpen: boolean) => void;
  menuList: any[];
  handleClickOnMenuItem?: any;
  toggleIcon?: any;
  tooltip?: string;
  TabComponent?: any;
  openFromLeft?: boolean;
}

export const CustomDropdown: React.FC<CustomDropdown> = ({
  isOpenDropdown,
  setIsOpenDropdown,
  menuList,
  handleClickOnMenuItem,
  toggleIcon,
  tooltip,
  TabComponent,
  openFromLeft,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (isOpenDropdown) {
      setAnchorEl(document.getElementById(`account-menu-button`));
    }
  }, [isOpenDropdown, setIsOpenDropdown]);

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setIsOpenDropdown(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {TabComponent ? (
        <TabComponent handleOnClick={handleClick} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            padding: 0,
          }}
        >
          <button
            className="disabled:cursor-not-allowed"
            disabled={menuList.every((item) => item.isVisible === false)}
            onClick={handleClick}
          >
            {toggleIcon ? (
              toggleIcon
            ) : (
              <PerimeterIcon className="h-6 text-sm rotate-90" />
            )}
          </button>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // overflow: 'visible',
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            width: 200,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: openFromLeft ? "left" : "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: openFromLeft ? "left" : "right",
          vertical: "bottom",
        }}
      >
        {menuList
          .filter((item: any) => item.isVisible)
          .map((item: any, index: number) => {
            if (item.divider) {
              return <Divider key={index.toString()} />;
            }
            return (
              <MenuItem
                className="text-xs dark:bg-secondaryLight dark:text-textMain "
                onClick={() => {
                  handleClose();
                  handleClickOnMenuItem(item);
                }}
                key={index.toString()}
                style={{ fontSize: "14px", fontFamily: "Overpass" }}
              >
                {item.label}
              </MenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
};
