import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, PageHeading, AddDynamicFormBtn } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
// import {
//   communitiesOrganizationsAction,
//   communitiesResetFiltersAction,
// } from "store/filters/reducer.actions";
import {
  openAddFormModalAction,
  // openAddExistingCommunityModalAction,
  openCreateCommunityModalAction,
} from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import {
  dynamicFormsProgramsAction,
  dynamicFormsResetFiltersAction,
} from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";
import { IfetchDynamicForms } from "store/dynamicForms/initialState";

const generateDynamicLinks = (
  programId: any,
  communityId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/forms`,
          label: `Forms`,
        },
      ];

    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/forms`,
          label: `Forms`,
        },
      ];
    default:
      return [{ path: `/forms/list`, label: `Forms` }];
  }
};

export const Header = () => {
  const { dynamicForms } = usePermissions();
  const { data, stateIs } = useSelector<RootState, IfetchDynamicForms>(
    (state) => state?.allforms
  );
  const { dynamicFormsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();

  const dynamicLinks = generateDynamicLinks(
    programId ?? "",
    communityId ?? "",
    BreadcrumbData?.name ?? "",
    dynamicFormsFilter.status
  );

  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    } else if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/community/list");
        });
    }
  }, [programId]);

  // Handlers

  const handleAddDynamicFormModel = () => {
    dispatch(openAddFormModalAction());
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              organizationId={BreadcrumbData.id ? BreadcrumbData.id : ""}
              name={BreadcrumbData?.name}
              parentName={
                programId ? "Program" : communityId ? "Community" : ""
              }
            />

            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {!communityId &&
                data.programStatus !== "completed" &&
                dynamicForms.canCreateDynamicForm && (
                  <AddDynamicFormBtn
                    handleAddDynamicFormModel={handleAddDynamicFormModel}
                  />
                )}

              {/* <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                 handleAddDynamicFormModel={ handleAddDynamicFormModel}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
