import mapboxgl from "mapbox-gl";

class DeleteControl implements mapboxgl.IControl {
  private map?: mapboxgl.Map;
  private container: HTMLElement;

  constructor(customControlId:any) {
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this.container.id = "DeleteControlContainer"+customControlId;
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;

    this.container.style.position = "relative";
    
    return this.container;
  }

  public onRemove(): void {
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    this.map = undefined;
  }
}

export default DeleteControl;