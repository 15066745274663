function appendElementToArray(
  element: { id: string; [key: string]: any },
  list: { id: string; [key: string]: any }[]
) {
  const isElementAlreadyAppended = list.some((ele) => ele.id === element.id);

  if (!isElementAlreadyAppended) {
    list.push(element);
  }

  return list;
}
export default appendElementToArray;
