import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "store";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useDispatch } from "react-redux";
import { openEditProgramTagsModalAction } from "store/modals/reducer.actions";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import { IProgram } from "store/programProfile/initialState";
import Tooltip from "@mui/material/Tooltip";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
export const ProgramTags = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const { programs, tags, organizations } = usePermissions();
  const dispatch = useDispatch();

  return (
    <div className=" bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight mt-3 rounded-lg shadow-md hover:shadow-2xl px-3  mb-[16px]">
      <div className="flex flex-col items-start justify-start w-full gap-6 py-4">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="relative flex items-center justify-between w-full pb-2 ">
            <p className="pb-2 ml-3 text-base font-semibold text-secondaryMid dark:text-textMain">
              Tags
            </p>
            { programs.canEditTags && (
                <button
                  title="edit"
                  onClick={() =>
                    dispatch(
                      openEditProgramTagsModalAction({
                        tagIds: (programProfile.programTags || []).map(
                          (p: any) => ({
                            label: p?.name,
                            value: p?.id,
                          })
                        ),
                      })
                    )
                  }
                  type="button"
                >
                  <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl">
                    <PencilAltIcon />
                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-[#2c3236] dark:text-textMain">
                        Edit
                      </p>
                    </div>
                  </div>
                </button>
              )}
          </div>
          <div className="flex flex-wrap items-center justify-start gap-2">
            {programProfile.programTags?.map((tag: any, index: number) => {
              return (
                <>
                  <Tooltip title={tag.name} key={index} arrow placement="top">
                    <div className="flex justify-center items-center gap-1 p-1 rounded-lg bg-[#005c89]/[0.08] dark:bg-primary_40">
                      <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
                        <p className="text-sm text-center text-[#2c3236] dark:text-textMain">
                          {tag.name}
                        </p>
                      </div>
                    </div>
                  </Tooltip>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
