import filesUpload from "./filesUpload";
// import filesDownload from "./filesDownload";
import getAllFiles from "./getAllFiles";

const Files = {
  filesUpload,
  //   filesDownload,
  getAllFiles,
};

export default Files;
