import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";

export const ContactPreference = () => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  return (
    <div className="grid w-full gap-4 px-8 xl:px-12 xl:grid-cols-2 lg:grid-cols-1">
      <div className="flex flex-col items-start justify-start flex-grow w-full">
        <div className="flex flex-col relative gap-0.5">
          <p className="text-sm text-left capitalize text-textMidLight dark:text-textMain">
            Contact Preference
          </p>
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 w-full">
            <p className="text-[15px] text-left w-full text-secondary dark:text-inputText">
              {userProfile?.contactPreference === "email"
                ? userProfile?.user.email
                : userProfile?.user.phoneNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
