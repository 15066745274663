import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { addTypeSchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddOrEditTypeModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { fetchTypesAction } from "store/types";
import MultiSelect from "view/components/Multiselect";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */
export const AddOrEditTypeModal = () => {
  // State variables

  const { addOrEditTypeModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { categories } = usePermissions();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [formInitialValues, setFormInitialValues] = useState<any>({
    typeName: "",
    categoryId: "",
  });
  const [allCategories, setAllCategories] = useState([]);

  // Initialize formInitialValues when in editMode or templateMode
  useEffect(() => {
    if (addOrEditTypeModal.editMode) {
      const { categoryId, typeName } = addOrEditTypeModal?.data;
      setFormInitialValues({
        categoryId: categoryId,
        typeName: typeName,
      });
    }
  }, [addOrEditTypeModal?.data, addOrEditTypeModal.editMode]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const formValues = {
        ...values,
        categoryId: values.categoryId.value,
      };

      if (addOrEditTypeModal.editMode && addOrEditTypeModal.data.id) {
        // Edit an existing form
        const { data, message } = await apiLibrary.SpeciesAndObjects.editType(
          addOrEditTypeModal.data.id,
          formValues
        );

        Toasts.success(message);
        handleClose();
        dispatch(fetchTypesAction());
      } else {
        // Add a new form
        const { data, message } =
          await apiLibrary.SpeciesAndObjects.addType(formValues);
        Toasts.success(message);
        handleClose();
        dispatch(fetchTypesAction());
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeAddOrEditTypeModalAction());
    setFormInitialValues({
      typeName: "",
      categoryId: "",
    });
  };

  const fetchAllCategories = useCallback(
    async (searchQuery?: string) => {
      if (categories.canViewDropdownCategories) {
        try {
          const res =
            await apiLibrary.SpeciesAndObjects.getCategoriesDropdown(
              searchQuery
            );
          setAllCategories(
            res.data.map((category: any) => ({
              label: category?.categoryName,
              value: category?.id,
            }))
          );
        } catch (error: any) {
          // Handle API errors
          console.error("Error fetching categories:", error);
        }
      }
    },
    [allCategories]
  );

  const handleAddCategorySearch = (query: string) => {
    fetchAllCategories(query);
  };

  // Fetch all programs on component mount
  useEffect(() => {
    /**
     * Fetches all programs from the API.
     * @example
     * fetchAllCategories();
     */

    if (addOrEditTypeModal.isOpen) {
      fetchAllCategories();
    }
  }, [addOrEditTypeModal.isOpen]);
  return (
    <Modal
      open={addOrEditTypeModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={formInitialValues}
          validationSchema={addTypeSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px]  max-h-[700px] overflow-y-auto rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid capitalize dark:text-textMain">
                        {addOrEditTypeModal.editMode ? "Edit type" : "Add type"}
                      </p>
                      <button title="close" onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <TextInput
                        label="Type name *"
                        type="text"
                        name="typeName"
                        setFieldValue={setFieldValue}
                        error={errors?.typeName}
                        touched={touched.typeName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Type Name"
                        value={values.typeName}
                      />
                      {!(errors?.typeName && touched?.typeName) && (
                        <div className="py-0.5">
                          <p
                            className={`text-xs text-left mt-[-10px] text-secondaryLight`}
                          >
                            60 Max Characters
                          </p>
                        </div>
                      )}
                    </div>
                    {/* {categories.canViewDropdownCategories && ( */}
                    <div className="flex flex-col items-start w-full justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <AddCategoryName
                        handleAddCategoryNameOnChange={(option: any) =>
                          setFieldValue("categoryId", option)
                        }
                        errors={errors}
                        touched={touched}
                        categories={allCategories}
                        values={values}
                        handleAddCategorySearch={handleAddCategorySearch}
                        isEditMode={addOrEditTypeModal.editMode}
                      />
                    </div>
                    {/* )} */}
                  </div>
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 h-11"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />

                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 h-11"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const AddCategoryName = ({
  touched,
  errors,
  handleAddCategoryNameOnChange,
  categories,
  values,
  handleAddCategorySearch,
  isEditMode,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const { categoryId } = values;
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
            <p
              className={`flex-grow pb-2 w-full text-sm font-medium text-left capitalize dark:text-caption ${
                touched.categoryId && errors.categoryId
                  ? "text-accent_1Dark"
                  : ""
              }`}
            >
              Category Name *
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch relative gap-1.5 px-3 rounded bg-white border w-full ${
              touched.categoryId && errors.categoryId
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
            }`}
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                fill="#9B9FA5"
              />
            </svg>
            <div className="flex flex-col items-start justify-start flex-grow w-full">
              <div className="w-[600px]">
                <MultiSelect
                  inputValue={inputValue}
                  className={"flex-wrap"}
                  onInputChange={(value: string) => {
                    setInputValue(value);
                    handleAddCategorySearch(value);
                  }}
                  name="categoryId"
                  options={categories}
                  defaultValue={{ value: "", label: "" }}
                  isMulti={false}
                  value={[categoryId]}
                  isDisable={false}
                  transformY="translateY(-130%)"
                  onChange={
                    (option: any) => {
                      handleAddCategoryNameOnChange(option);
                      // setSelectedOption(option);
                    }
                    // handleCommunityChange(
                    //   selectedOptions,
                    //   setFieldValue
                    // )
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched.categoryId && errors.categoryId && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark capitalize">
                {errors?.categoryId}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
