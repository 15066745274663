import React from "react";
// External Tools
// Store Utils
import { useDispatch } from "react-redux";
import { geographyTypeAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({}) => {
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  // const TabsData = ["raster", "vector", "archived"];
  const TabsData = [
    {
      label: "Raster Layer",
      value: "raster",
    },
    {
      label: "Vector Layer",
      value: "vector",
    },
    {
      label: "Archived",
      value: "archived",
    },
  ];
  //handlers
  const handleLink = (linkName: string) => {
    dispatch(geographyTypeAction(linkName));
  };

  return (
    <>
      {TabsData.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${
              geographyFilter.type === item.value
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
            }`}
            onClick={() => handleLink(item.value)}
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
              <p
                className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${
                  geographyFilter.type === item
                    ? " dark:text-textMain "
                    : "dark:text-caption "
                }`}
              >
                {/* {sentenceCase(item)} */}
                {item.label}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
