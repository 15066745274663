import { generatingLayerId } from "../utils";

function __changeColorOfZoomedShapeLayer__(
  layer: any,
  map: any,
  zoomedColor: any
) {
  if (!layer || !map) {
    return;
  }
  const { layerId } = generatingLayerId(layer.itemType, layer.item.id);

  if (map.getLayer(layerId)) {
    const layerFeatures = map.queryRenderedFeatures({
      layers: [layerId],
    });
    // console.log("layer features: ",layerFeatures,map.getLayer(layerId))
    if(layerFeatures[0]){
      const featureType = layerFeatures[0].geometry.type;
      const isLayerHasLineString = featureType === "LineString" || featureType === "MultiLineString";

      console.log("featureType",featureType.geometry)
      console.log({isLayerHasLineString,featureType:featureType.source,features:layerFeatures[0]});

      map.setPaintProperty(
        layerId,
        isLayerHasLineString ? "line-color" : "fill-color",
        zoomedColor
      );
      map.setPaintProperty(
        layerId,
        isLayerHasLineString ? "line-opacity" : "fill-opacity",
        0.4
      );
    }
  }
}

export { __changeColorOfZoomedShapeLayer__ };