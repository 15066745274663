import getAllTags from "./getAllTags";
import deleteAtag from "./deleteATag";
import getUniversalSettings from "./getUniversalSettings";
import updateUniversalImages from "./updateUniversalImages";
import { updateUniversalSettings } from "./updateUniversalSettings";
import getUniversalImages from "./getUniversalImages";
const stystemSettings = {
  getAllTags,
  deleteAtag,
  getUniversalSettings,
  updateUniversalImages,
  updateUniversalSettings,
  getUniversalImages
};

export default stystemSettings;
