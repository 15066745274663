import * as turf from "@turf/turf";

export function calculateMultiEntityZoomLevel(data: any[]) {
  if (!data) {
    return null;
  }

  const bbox = turf.bbox(createFeatureCollection(data));

  // Convert bbox to a Polygon to calculate its area
  const bboxPolygon = turf.bboxPolygon(bbox);
  const area = turf.area(bboxPolygon);

  // Define a heuristic to determine the zoom level
  let zoomLevel;
  if (area > 1000000) {
    // Greater than 1 km²
    zoomLevel = 10;
  } else if (area > 100000) {
    // Greater than 0.1 km²
    zoomLevel = 12;
  } else if (area > 10000) {
    // Greater than 10000 m²
    zoomLevel = 14;
  } else {
    zoomLevel = 15;
  }

  const bounds = [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]],
  ];

  return {
    bounds,
    maxZoom: zoomLevel,
  };
}






function createFeatureCollection(data: any[]) {
  const features = data.map((item: any) => {
    const geometry: any = {
      type: item.locations[0].geometries[0].type,
      coordinates: item.locations[0].geometries[0].coordinates
    };

    return turf.feature(geometry, {
      id: item.id,
      name: item.name
    });
  });

  return turf.featureCollection(features);
}

