import React from "react";
import { Grid } from "@mui/material";
import Widget from "./components/Widget";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";

const DashboardWidgets = () => {
  const {
    dashboard: { totalCounts },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchTotalCounts());
  }, [dashboardFilter]);

  return (
    <Grid container spacing={2}>
      {Object.entries(totalCounts.data).map(([key, category], index) => {
        const { title, count, link } = category;
        return (
          <Grid item xs={2} key={index}>
            <Widget title={title} count={count} redirectLink={link} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DashboardWidgets;
