import { apiClient } from "../config";
interface RequestParams {
  searchString: string | undefined;
  entity: string;
  page: number;
  limit: number;
  connected: boolean;
}

async function getCommunitiesAndOrganizationsByProgram(
  id: number,
  entity: string,
  connected: boolean = false,
  searchString?: string,
  page?: number
) {
  const params: any = {
    entity,
    connected,
  };

  if (page && page > 0) {
    params.page = page;
  }
  if (searchString !== "") {
    params.searchString = searchString;
  }

  const res = await apiClient.get(`/admin/programs/${id}/entity-dropdown`, {
    params,
  });
  return res.data;
}

export default getCommunitiesAndOrganizationsByProgram;
