import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, AddObjectBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import {
  openAddExistingCommunitiesOrOrganizationsToProgramModalAction,
  openAddExistingCommunityModalAction,
  openAddExistingSpeciesOrObjectsToCommunityModalAction,
  openAddOrEditObjectModalAction,
} from "store/modals";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (
  communityId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/objects`,
          label: `Objects`,
        },
      ];

    default:
      return [
        { path: `/object/list`, label: `Objects` },
          
      ];
  }
};
export const Header = () => {
  const navigate = useNavigate();

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { objectsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const {communities} = usePermissions()

  const {objects} = usePermissions()
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
   const { communityId, programId  ,organizationId} = useParams<{
    communityId: string;
    programId: string;
    organizationId:string
  }>();

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    communityId ?? "",
    BreadcrumbData?.name ?? "",
    objectsFilter.status
  );

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        });
    }
  }, [communityId]);

  // Handlers

  // displaying models based on communityId
  const handleObjectModel = () => {
    if (communityId) {
      dispatch(
        openAddExistingSpeciesOrObjectsToCommunityModalAction({
          id: communityId,
          entity: "object",
        })
      );
    } else {
      dispatch(
        openAddOrEditObjectModalAction({
          name: "",
          description: "",
          images: [],
          isStar: null,
          typeId: null,
        })
      );
    }
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewBtn = () => {
    dispatch(
      openAddOrEditObjectModalAction({
        name: "",
        description: "",
        images: [],
        isStar: null,
        typeId: null,
      })
    );
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    dispatch(
      openAddExistingCommunityModalAction({
        communityId,
        entity: "organization",
      })
    );
    setIsShowExistingOptionsModal(false);
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              communityId={
                communityId ? communityId : programId ? programId : ""
              }
              name={BreadcrumbData?.name}
              parentName={communityId ? "Communities" : programId ? "Programs" : ""}
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {communityId && communities.canViewEntityDropdownCommunities && communities.canCreateObjectsCommunities && (
                <AddObjectBtn
                  handleAddObjectModel={handleObjectModel}
                  label={"Connect Objects"}
                />
              )}
              {!communityId && objects.canCreateObjects && (
                <AddObjectBtn
                  handleAddObjectModel={handleObjectModel}
                  label={"Add Object"}
                />
              )}

              <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                handleAddObjectModel={handleObjectModel}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
