import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { addConditionSchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddOrEditConditionsModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { fetchConditionsAction } from "store/conditions";
import MultiSelect from "view/components/Multiselect";
import { SearchIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */
export const AddOrEditConditionsModal = () => {
  // State variables

  const { addOrEditConditionsModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { types } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [formInitialValues, setFormInitialValues] = useState<any>({
    conditionName: "",
    typeIds: [],
  });
  const [allTypes, setAllTypes] = useState([]);

  // Initialize formInitialValues when in editMode or templateMode
  useEffect(() => {
    if (addOrEditConditionsModal.editMode) {
      const { types, conditionName } = addOrEditConditionsModal?.data;
      const updatedTypes = types?.map((type: any) => {
        return { label: type.typeName, value: type.id };
      });
      setFormInitialValues({
        typeIds: updatedTypes ?? [],
        conditionName: conditionName,
      });
    }
  }, [addOrEditConditionsModal?.data, addOrEditConditionsModal.editMode]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const formValues = {
        ...values,
        typeIds: values.typeIds.map((item: any) => item.value),
      };

      if (
        addOrEditConditionsModal.editMode &&
        addOrEditConditionsModal.data.id
      ) {
        // Edit an existing form
        const { data, message } =
          await apiLibrary.BehaviorsAndConditions.editCondition(
            addOrEditConditionsModal.data.id,
            formValues
          );

        Toasts.success(message);
        handleClose();
        dispatch(fetchConditionsAction());
      } else {
        // Add a new form
        const { data, message } =
          await apiLibrary.BehaviorsAndConditions.addCondition(formValues);
        Toasts.success(message);
        handleClose();
        dispatch(fetchConditionsAction());
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeAddOrEditConditionsModalAction());
    setFormInitialValues({
      conditionName: "",
      typeIds: [],
    });
  };
  const fetchAllTypes = useCallback(
    async (existingTypes: any[], searchQuery?: string) => {
      if (types.canViewDropdown) {
        try {
          const response =
            await apiLibrary.SpeciesAndObjects.getTypesDropdown(searchQuery);
          const fetchedTypes = response.data.map((type: any) => ({
            label: type.typeName,
            value: type.id,
          }));

          // Filter out the types that are already present in existingTypes
          const newTypes = fetchedTypes.filter(
            (fetchedType: any) =>
              !existingTypes.some(
                (existingType: any) => existingType.value === fetchedType.value
              )
          );

          setAllTypes(newTypes);
        } catch (error) {
          console.error("Error fetching types:", error);
        }
      }
    },
    []
  );

  return (
    <Modal
      open={addOrEditConditionsModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={formInitialValues}
          validationSchema={addConditionSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            useEffect(() => {
              /**
               * Fetches all programs from the API.
               * @example
               * fetchAllTypes();
               */

              if (addOrEditConditionsModal.isOpen) {
                fetchAllTypes(values.typeIds);
              }
            }, [addOrEditConditionsModal.isOpen]);
            const handleAddTypeSearch = (query: string) => {
              fetchAllTypes(values.typeIds, query);
            };

            return (
              <Form>
                <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px]  max-h-[700px] overflow-y-auto rounded-lg">
                  <div
                    className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                    style={{
                      boxShadow:
                        "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                    }}
                  >
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid capitalize dark:text-textMain">
                          {addOrEditConditionsModal.editMode
                            ? "Edit Condition"
                            : "Add Condition"}
                        </p>
                        <button
                          title="close"
                          onClick={handleClose}
                          type="button"
                        >
                          <XCloseIcon
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                          />
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <TextInput
                          label="Condition name*"
                          type="text"
                          name="conditionName"
                          setFieldValue={setFieldValue}
                          error={errors?.conditionName}
                          touched={touched.conditionName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Condition Name"
                          value={values.conditionName}
                          helperText={"60 Max Characters"}
                        />
                      </div>

                      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-2">
                        <AddTypes
                          handleAddTypesOnChange={(option: any) => {
                            setFieldValue("typeIds", option);
                          }}
                          errors={errors}
                          touched={touched}
                          types={allTypes}
                          values={values}
                          handleAddTypeSearch={handleAddTypeSearch}
                          isEditMode={false}
                        />
                      </div>
                    </div>
                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="outlined"
                        onClick={handleClose}
                      />

                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        text="Save"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

const AddTypes = ({
  touched,
  errors,
  handleAddTypesOnChange,
  types,
  values,
  handleAddTypeSearch,
  isEditMode,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const { typeIds } = values;
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
            <p className="flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ">
              Types*
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
              touched.typeIds && errors.typeIds
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
            }`}
          >
            <SearchIcon />
            <div className="flex flex-col items-start justify-start flex-grow w-full dark:bg-secondaryLight ">
              <MultiSelect
                placeholder="Search Types"
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleAddTypeSearch(value);
                }}
                options={types}
                defaultValue={{ value: "", label: "" }}
                isMulti={true}
                closeOnSelect={false}
                value={typeIds}
                isDisable={isEditMode}
                transformY="translateY(-130%)"
                onChange={(option: any) => {
                  handleAddTypesOnChange(option);
                }}
              />
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched.typeIds && errors.typeIds && (
              <p className="flex-grow w-[1/2] text-xs capitalize text-left text-accent_1Dark">
                {errors?.typeIds}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
