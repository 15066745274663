import React from "react";
import Box from "@mui/material/Box";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { ObservationDuration } from "store/dashboard/observations/types";
import DashboardCardHeading from "../DashboardCardHeading";
import { filtersInitialState } from "store/filters/initialState";
import ReactApexChart from "react-apexcharts";
import { ICumulativeObservations } from "store/dashboard/cumulativeObservations/initialState";
import { ObservationDurationConstants } from "constants/observationDurationConstants";
import MostObservedNoData from "../imgs/MostObservedNoData";
import { DarkMode } from "store/darkMode/initialState";
import { TailSpin } from "react-loader-spinner";

export default function CumulativeObservations() {
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  const {
    dashboard: { cumulativeObservations },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const [duration, setDuration] = React.useState<ObservationDuration>(
    ObservationDurationConstants.PER_YEAR.VALUE
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const toggleDuration = (newDuration: ObservationDuration) =>
    setDuration(newDuration);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchCumulativeObservations(duration));
  }, [dashboardFilter, duration]);

  return (
    <Box width={"100%"} height={"100%"}>
      <DashboardCardHeading
        text="Cumulative Observations"
        widgetId="cumulative_observations"
        showChip={!!cumulativeObservations.totalCount}
        chipLabel={cumulativeObservations.totalCount}
        durationFilter={true}
        toggleDuration={toggleDuration}
      />

      {cumulativeObservations.stateIs == "Idle" &&
        cumulativeObservations.data?.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <MostObservedNoData />
        </div>
      ) : cumulativeObservations.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <ReactApexChart
          height={300}
          series={getSeries(cumulativeObservations.data)}
          type="bar"
          options={{
            yaxis: {
              opposite: true,
              labels: {
                style: {
                  fontFamily: "overpass",
                  colors: isDarkMode ? ["#F2F2F2"] : ["black"],
                },
              },
            },
            xaxis: {
              categories: cumulativeObservations.data.map(
                (item) => item?.day || item?.week || item?.month || item?.year
              ),
              labels: {
                rotate: -45, // Set a fixed rotation
                rotateAlways: true, // Always rotate labels
                style: {
                  fontFamily: "overpass",
                  colors: cumulativeObservations.data
                    .map(
                      (item) =>
                        item?.day || item?.week || item?.month || item?.year
                    )
                    .map(() => {
                      return isDarkMode ? "#F2F2F2" : "black";
                    }),
                },
              },
            },
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
            },

            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 8,
                columnWidth: 30,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#42BBAC", "#11A3E2"],
            legend: {
              show: false,
            },
            tooltip: {
              style: {
                fontFamily: "overpass",
              },
            },
          }}
        />
      )}
    </Box>
  );
}

const getSeries = (seriesData: ICumulativeObservations[]) => {
  return [
    {
      name: "Past",
      data: seriesData.map(
        (item) =>
          (item?.yesterdayCount as number) ||
          (item?.lastWeekCount as number) ||
          (item?.lastMonthCount as number) ||
          (item?.lastYearCount as number) ||
          0
      ),

      // data: [35, 45, 45, 60, 85],
    },
    {
      name: "New",
      data: seriesData.map(
        (item) =>
          (item?.todayCount as number) ||
          (item?.currentWeekCount as number) ||
          (item?.currentMonthCount as number) ||
          (item?.currentYearCount as number) ||
          0
      ),
      // data: [40, 50, 55, 70, 65],
    },

  ];
};
