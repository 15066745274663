import { Ifetch } from "../initialState";

export interface IQANotifications {
  description: string;
  duration: string;
  reportId: number;
  image: {
    large: string;
    original: string;
    thumb: string;
  } | null;
}

export interface IfetchQANotifications extends Ifetch {
  data: IQANotifications[];
}

const qaNotificationsInitialState: IfetchQANotifications = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default qaNotificationsInitialState;
