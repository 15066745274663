import React from 'react';
import VideoThumbnail from './components/VideoThumbnail';
import ImageThumbnail from './components/ImageThumbnail';
import DocumentThumbnail from './components/DocumentThumbnail';
import AudioThumbnail from './components/AudioThumbnail';
import { PaperClipIcon, XCircleIcon } from 'assets/icons/HeroIcons';

interface MediaThumbnailsProps {
  attachment?: any;
  onRemove: () => void;
  disabled: boolean;
}

const AttachmentPreview: React.FC<MediaThumbnailsProps> = ({
  attachment,
  onRemove,
  disabled,
}) => {
  const fileType = getFileType(attachment?.type || '');
  const isDoc =
    fileType === 'doc' ||
    fileType === 'archive' ||
    fileType === 'geojson' ||
    fileType === 'txt';
  const fileUrl =
    !isDoc && attachment && attachment instanceof File
      ? URL.createObjectURL(attachment)
      : attachment?.file?.original || '';

  function getFileType(type: string): string {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      return 'image';
    } else if (type.match(/(mp3|mpeg)$/)) {
      return 'audio';
    } else if (type.match(/(mp4|mpeg|avi|mkv|wmv|flv|mov|webm|quicktime)$/)) {
      return 'video';
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx|msword|pdf)$/)) {
      return 'doc';
    } else if (type.match(/(txt|csv)$/)) {
      return 'txt';
    } else if (type.match(/(zip|rar|7z)$/)) {
      return 'archive';
    } else if (type === 'geojson') {
      return 'geojson';
    } else {
      return 'unknown';
    }
  }
  const renderContent = () => {
      switch (fileType) {
      case 'video':
        return <VideoThumbnail url={fileUrl} />;
      case 'image':
        return <ImageThumbnail url={fileUrl} />;
      case 'doc':
      case 'txt':
      case 'archive':
      case 'geojson':
        return <DocumentThumbnail url={fileUrl} fileName={attachment?.name} />;
      case 'audio':
        return <AudioThumbnail url={fileUrl} />;
      default:
        return <PaperClipIcon />;
    }
  };

  return (
    <div
      className={`relative ${disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer'}`}
    >
      {renderContent()}
      {!disabled && (
        <XCircleIcon
          onClick={onRemove}
          className='absolute right-[-6px] top-[-6px] rounded-full bg-bgWhite cursor-pointer'
        />
      )}
    </div>
  );
};

export default AttachmentPreview;
