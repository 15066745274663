import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface DayCheckboxProps {
  day: { name: string; value: string };
  checked: boolean;
  handleChange: () => void;
}

export const DayCheckbox: React.FC<DayCheckboxProps> = ({
  day,
  checked,
  handleChange,
}) => {
  return (
    <>
      <FormControlLabel
        className={`w-10 h-8 rounded-lg flex justify-center items-center dark:bg-primary_40 ${checked ? 'bg-blue-700 text-white' : 'bg-[#005C8914] text-black'}`}
        control={
          <Checkbox
            sx={{
              "&.MuiCheckbox-root": {
                display: "none",
              },
            }}
            checked={checked}
            onChange={handleChange}
            inputProps={{
              "aria-label": "Day checkbox",
            }}
          />
        }
         label={<p className="dark:text-textMain">{day?.name}</p>}
      />
    </>
  );
};
