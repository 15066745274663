import  { useEffect } from 'react'
import questionTypes from '../questionTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateDeletedQuestionEle, updateShapePickers } from 'store/surveyJS';
import { useDispatch } from 'react-redux';

const useHandleDeleteOfQuestion = () => {
    const {shapePickers,deletedQuestionElement} = useSelector((state:RootState)=>state.surveyJS);
    const dispatch = useDispatch();

    useEffect(() => {
        // reset highlighted layers property on delete of shape picker question

        if (deletedQuestionElement) {

          const questionType = deletedQuestionElement?.getType();
          const questionId = deletedQuestionElement.rdx_id;
    
          if (questionType === questionTypes.shapePicker) {
            const updatedShapePickers = shapePickers.filter(
              (s: any) => s.id !== questionId
            );

            dispatch(updateShapePickers([...updatedShapePickers]));

          } 
          else if (questionType === questionTypes.pointPicker) {
            // dispatch(
            //   updatePointPicker({
            //     ...pointPicker,
            //     selectedLayersIdToHighlight: null,
            //     selectedDefaultLayers: [],
            //     mapCenterPoint: {},
            //     ableToSelectMultiple: false,
            //   })
            // );
          } 
          else if (questionType === questionTypes.drawPolygon) {
            // dispatch(
            //   updateDrawPolygon({
            //     ...drawPolygon,
            //     selectedDefaultLayers: [],
            //     mapCenterPoint: {},
            //     ableToSelectMultiple: false,
            //     snapping: false,
            //   })
            // );
          } 
          else if (questionType === questionTypes.dropPin) {
            // dispatch(
            //   updateDropPin({
            //     ...dropPin,
            //     selectedDefaultLayers: [],
            //     mapCenterPoint: {},
            //     ableToSelectMultiple: false,
            //     snapping: false,
            //   })
            // );
          };
    
          dispatch(updateDeletedQuestionEle(null));
        };
      }, [deletedQuestionElement]);

  return null;
};

export {useHandleDeleteOfQuestion};