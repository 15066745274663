import React, { useRef, useState } from "react";
/************ Assets ************/
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";

interface IProps {
  icon: React.ElementType;
  text: string;
  isConnected?: boolean;
  onClick?: any;
  disabled?: any;
  itemHover?: boolean;
  className?: any;
}
const SocialButton: React.FC<IProps> = ({
  icon: Icon,
  text,
  onClick,
  disabled,
  isConnected,
  className,
  itemHover,
}) => {
  const [showContent, setShowContent] = useState(false);
  console.log(itemHover, "itemHoveritemHoveritemHover");

  const handleMouseEnter = () => {
    if (itemHover) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  };

  const handleMouseLeave = () => {
    setShowContent(false);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`flex ${className} h-9 bg-primary dark:bg-primaryLight w-10 hover:flex-grow transition-all duration-500 overflow-hidden relative flex-initial ${
        isConnected ? "bg-primaryLight" : "bg-primary/[0.08]"
      } items-center justify-center flex-grow-0 flex-shrink-0 gap-2 rounded-lg ${
        showContent ? "w-[180px] justify-between" : "w-10 justify-center"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`flex justify-center items-center w-full flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg relative ${
          showContent ? "pl-1 " : ""
        }`}
      >
        <Icon />
        <div
          className={`w-full flex justify-around items-center ${
            showContent ? "" : "hidden"
          }`}
        >
          <p className="ml-7 ">{text}</p>
          <XCloseIcon />
        </div>
      </div>
    </button>
  );
};

export default SocialButton;
