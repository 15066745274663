import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeArchiveOrRestoreObservationModalAction, closeRemoveManagerModalAction, closeReviewerDetailsModal } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { observationsStatusAction } from "store/filters";
import { IfetchManager } from "store/managers/initialState";
import { managerTypeAction } from "store/filters/reducer.actions";
import SelectInput from "./components/SelectInput";
import { fetchManagerAction } from "store/managers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import usePermissions from "hooks/usePermissions";


const style: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    // width: auto,
    backgroundColor: "#ffff",
};

interface IProps { }

export const RiviewerDetailsModal: React.FC<
    IProps
> = () => {
    const [selectedDay, setSelectedDay] = useState('7 days')
    const { reviewerDetailModal } = useSelector(
        (state: RootState) => state.modals
    );
    const personId = reviewerDetailModal.data?.personId
    const { programId } :any = useParams();
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => {
        dispatch(closeReviewerDetailsModal());
        setSelectedDay('7 days')
    };
    const {observations} = usePermissions();
    const handleSelectChange = (event: any) => {
        setSelectedDay(event.target.value);
        // Additional logic when the selection changes can be added here
      };

    const handleSubmit = async () => {
        setIsLoading(true);
        // setSelectedDay('7 days')
        try {
            const fetch = await apiLibrary.Managers.extendsReviewerDate(personId, selectedDay)
            handleClose();
            Toasts.success(fetch.message);
            dispatch(fetchManagerAction(programId));
        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };
    const DaysAccess = [
        "1 days",
        "3 days",
        "5 days",
        "7 days",
        "14 days",
        "28 days", 
        "30 days"
    ]

    return (
        <Modal
            open={reviewerDetailModal?.isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="dark:bg-secondaryLight ">
                <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
                    <div
                        className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                        style={{
                            boxShadow:
                                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                        }}
                    >
                        <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                                    Reviewer details
                                </p>
                            </div>
                            <button onClick={handleClose} type="button">
                                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                            </button>
                        </div>
                        <div className="self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                            <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain text-xl font-semibold">
                                {reviewerDetailModal?.data?.name}
                            </p>
                            <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMainLight">
                                {reviewerDetailModal?.data?.email}
                            </p>
                        </div>
                        <div className="w-full">
                            <p className=" text-sm"> Access for</p>
                            <SelectInput
                                name="dataAccess"
                                handleSelectChange={handleSelectChange}
                                value={selectedDay}
                                data={DaysAccess}
                                disabled={!observations.canEditReviewerAccessFor}
                            />
                        </div>

                        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                            <Button
                                type="button"
                                text="Cancel"
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="px-5 py-2"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="outlined"
                                onClick={handleClose}
                            />

                            <Button
                                disabled={isLoading}
                                type="button"
                                text={"Save Changes"}
                                filledColor="primary"
                                outlinedColor="primary"
                                textColor="textWhite"
                                className="px-5 py-2"
                                width="35"
                                height="13"
                                fontStyle="font-semibold"
                                variant="filled"
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
