import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;
}
export const DocumentsListIcon = ({ fill = "#6F747B", isActive, ...restProps }: IconProps) => (
    <svg
      width={16}
      height={20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
      {...restProps}
    >
      <path
        fill={fill}
        className={`dark:${
          isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
        }`}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H13C13.5523 18 14 17.5523 14 17L14 7.41421L8.58579 2H3ZM0 3C0 1.34315 1.34315 0 3 0H8.58579C9.11622 0 9.62493 0.210713 10 0.585787L15.4142 6C15.7893 6.37507 16 6.88378 16 7.41421V17C16 18.6569 14.6569 20 13 20H3C1.34315 20 0 18.6569 0 17V3Z"
      />
    </svg>
  );
export default DocumentsListIcon;