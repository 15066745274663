import useDocumentTitle from "hooks/useDocumentTitle";
import { PageHeadingProps } from "../../types";
import Head from "view/components/Head";

const PageHeading = ({ id, name, parentName }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={id !== "" ? `${parentName} ${name}: Observations` : "Observations"} />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="flex-grow break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {id !== "" ? <>{name}: Observations</> : "Observations"}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
