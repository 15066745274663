import { GET_ALL_ROLES, RESET_DATA } from "./action-types";
import initialState, { IRoles } from "./initialState";
export const rolesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_ROLES:
      const roles = action.payload?.roles?.map(
        (item: IRoles) => {
          const {
            roles
          } = item;
          const data = {
           roles
          };
          return data;
        }
      );

      return {
        ...state,
        data: {
          roles: roles,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
