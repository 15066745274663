import React from "react";
import Button from "view/components/Button";

const CancelButton = ({ onClick }: any) => {
  return (
    <Button
      type="button"
      text="Cancel"
      filledColor="primary"
      outlinedColor="primary"
      textColor="textWhite"
      className="px-5 py-2"
      width="35"
      height="13"
      fontStyle="font-semibold"
      variant="outlined"
      onClick={onClick}
    />
  );
};

export { CancelButton };
