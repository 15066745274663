import Auth from "./auth";
import userProfile from "./Users";
import file from "./file";
import Settings from "./Settings";
import notificationsProfilePreferences from "./notificationsProfilePreferences";
import organizations from "./organizations";
import Communities from "./Communities";
import OrganizationDirectory from "./OrganizationDirectory";
import Forms from "./forms";
import Programs from "./programs";
import Files from "./files";
import Protocols from "./protocols";
import geography from "./geography";
import DataAccessPolicy from "./dataAccessPolicy";
import Observations from "./observations";
import Managers from "./managers";
import Messages from "./messages";
import QualityAssurance from "./qualityAssurance";
import SpeciesAndObjects from "./speciesAndObjects";
import Notifications from "./notifications";
import NotificationsRecipients from "./notificationsRecipients";
import NotificationsStatus from "./notificationsStatus";
import NotificationsSummary from "./notificationSummary";
import surveyJs from "./surveyJS";
import Tagging from "./tagging";
import dropdowns from "./dropdowns";
import Reports from "./reports";
import profileRoles from "./profileRoles";
import Dashboard from "./dashboard";
import Imports from "./imports";
import stystemSettings from "./systemSettings";
import RolesPermissions from "./rolesPermissions";
import pollsAndSurveys from "./pollsAndSurveys";
import NotificationCenter from "./notificationCenter";
import BehaviorsAndConditions from "./BehaviorAndConditions";
import ContactInformation from "./contactInfo"
import adminTracking from "./adminTracking";
import markerGroupsAndStyles from "./markerGroupsAndStyles";
import joinRequests from "./joinRequests";

const apiLibrary = {
  Auth,
  userProfile,
  file,
  Settings,
  notificationsProfilePreferences,
  organizations,
  Communities,
  OrganizationDirectory,
  Forms,
  Protocols,
  Programs,
  Files,
  DataAccessPolicy,
  geography,
  Observations,
  Managers,
  Messages,
  QualityAssurance,
  SpeciesAndObjects,
  Notifications,
  NotificationsRecipients,
  NotificationsStatus,
  NotificationsSummary,
  surveyJs,
  Tagging,
  dropdowns,
  Reports,
  profileRoles,
  Dashboard,
  Imports,
  stystemSettings,
  RolesPermissions,
  pollsAndSurveys,
  NotificationCenter,
  BehaviorsAndConditions,
  ContactInformation,
  adminTracking,
  markerGroupsAndStyles,
  joinRequests
};

export default apiLibrary;
