import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userFormsObservationsAction } from "store/filters";
import RangeSlider from "view/components/RangeSlider";

interface RangeProps {
  title: string;
  name: string;
  data: any;
}

export const Range: React.FC<RangeProps> = ({ title, name, data }) => {
  const dispatch = useDispatch();
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10000);

  const handleChange = (newValue: [number, number]) => {
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleMinValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || 0;
    if (newValue <= maxValue) {
      setMinValue(newValue);
    }
  };

  const handleMaxValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || 1;
    if (newValue >= minValue && newValue <= 10000) {
      setMaxValue(newValue);
    }
  };

  const preventNonNumeric = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];
    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setMaxValue(data.max);
    setMinValue(data.min);
  }, [data]);

  const applyFilters = () => {
    dispatch(userFormsObservationsAction(name, minValue, maxValue, true));
  };
  return (
    <>
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 p-4 bg-bgWhite dark:bg-secondaryLight z-1000">
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pb-1.5">
          <p  className="self-stretch flex-grow-0 flex-shrink-0 text-base font-semibold text-left w-44 text-textMid dark:text-textMain">
            {title}
          </p>
        </div>
        <div className="flex items-end self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <div className="flex flex-col items-start justify-start flex-grow">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1.5 px-3 py-1 rounded bg-white border border-lineDark">
                <div className="flex justify-start items-center flex-grow relative pt-0.5">
                  <input
                    type="number"
                    name="min"
                    min={0}
                    value={minValue}
                    onChange={handleMinValueChange}
                    onKeyDown={preventNonNumeric}
                    className="border-none outline-none w-9 dark:bg-secondaryLight dark:text-inputText"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start flex-grow">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1.5 px-3 py-1 rounded bg-white border border-lineDark">
                <div className="flex justify-start items-center flex-grow relative pt-0.5">
                  <input
                    type="number"
                    name="max"
                    min={0}
                    value={maxValue}
                    onChange={handleMaxValueChange}
                    onKeyDown={preventNonNumeric}
                    className="border-none outline-none w-9 dark:bg-secondaryLight dark:text-inputText"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={applyFilters}
            className="flex items-center px-5 py-1 border border-primaryLight40 hover:border-1 bg-bgWhite hover:border-primary hover:bg-primaryExtraLight rounded-3xl"
          >
            <p className="text-sm font-semibold text-primary">Ok</p>
          </button>
        </div>
        <div className="w-full px-4 mt-3">
          <RangeSlider value={[minValue, maxValue]} onChange={handleChange} />
        </div>
      </div>
    </>
  );
};
