// ManageMembers.tsx
import React from "react";
import { useDispatch } from "react-redux";
import { openManageProgramMembersModalAction } from "store/modals/reducer.actions";

interface ManageMembersProps {
  BreadcrumbData: any; // Adjust the type as needed
}

export const ManageMembers: React.FC<ManageMembersProps> = ({
  BreadcrumbData,
}) => {
  const dispatch = useDispatch();

  return (
    <button
      onClick={() => {
        dispatch(
          openManageProgramMembersModalAction({
            programId: BreadcrumbData?.id,
            name: BreadcrumbData?.name,
            profileImage: BreadcrumbData?.profileImage,
          })
        );
      }}
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition"
    >
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px]">
        <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary">
          Manage Members
        </p>
      </div>
    </button>
  );
};
