import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { panelsName } from "../../../panelsTitle";
import updateElementByIdFromArray from "../../../utils/updateElementByIdFromArray";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updatePointPickers,
} from "store/surveyJS";

const useHandleMapMovement = (currentQuestionEle: any, map: any) => {
  const [isCenterPointSetup, setIsCenterPointSetup] = useState(false);
  const [retrievalPointPicker, setRetrievalPointPicker] = useState(false);
  const [struckPointPicker, setStruckPointPicker] = useState(false);

  const {
    pointPickers,
    selectedQuestionElement,
    geographicalPanelMapQuestions,
    harvestPanelMapQuestions,
    surveyActiveTab,
  } = useSelector((state: RootState) => state.surveyJS);
  const selectedQuestionType = selectedQuestionElement?.isPanel
    ? selectedQuestionElement.name
    : selectedQuestionElement?.getType();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      !isCenterPointSetup &&
      (selectedQuestionType === panelsName.GEOGRAPHICAL_INFORMATION ||
        selectedQuestionType === panelsName.HARVEST)
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,

        });
      }
      // update map center values
      // else {
      //   map.on("load", () => {
      //     switch (selectedQuestionType) {
      //       case panelsName.GEOGRAPHICAL_INFORMATION:
      //         updateGeographicalInfoPanelCenterPoint(
      //           currentQuestionEle,
      //           map,
      //           geographicalPanelMapQuestions,
      //           dispatch
      //         );
      //         break;

      //       case panelsName.HARVEST:
      //         updateHarvestPanelCenterPoint(
      //           currentQuestionEle,
      //           map,
      //           harvestPanelMapQuestions,
      //           dispatch
      //         );
      //         break;

      //       default:

      //     }
      //   });
      // }

      setIsCenterPointSetup(true);
    }
  }, [
    currentQuestionEle,
    map,
    selectedQuestionElement,
    geographicalPanelMapQuestions,
    harvestPanelMapQuestions,
  ]);

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      !isCenterPointSetup &&
      selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION &&
      selectedQuestionType !== panelsName.HARVEST
    ) {
      const { longitude, latitude } = currentQuestionEle.mapCenterPoint;

      // set map center according to coming data
      if (longitude && latitude) {
        map.jumpTo({
          center: [longitude, latitude],
          zoom: currentQuestionEle?.zoomLevel ?? 2,

        });
      }
      // update map center values
      else {
        map.on("load", () => {
          updatePointPickerCenterPoint(
            currentQuestionEle,
            map,
            selectedQuestionType,
            pointPickers,
            dispatch
          );
        });
      }

      setIsCenterPointSetup(true);
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      (selectedQuestionType === panelsName.GEOGRAPHICAL_INFORMATION ||
        selectedQuestionType === panelsName.HARVEST)
    ) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        switch (selectedQuestionType) {
          case panelsName.GEOGRAPHICAL_INFORMATION:
            updateGeographicalInfoPanelCenterPoint(
              currentQuestionEle,
              map,
              geographicalPanelMapQuestions,
              dispatch
            );
            break;

          case panelsName.HARVEST:
            updateHarvestPanelCenterPoint(
              currentQuestionEle,
              map,
              harvestPanelMapQuestions,
              dispatch,
              setRetrievalPointPicker,
              setStruckPointPicker
            );
            break;

          default:
        }
      });
    }
  }, [
    currentQuestionEle,
    map,
    selectedQuestionElement,
    geographicalPanelMapQuestions,
    harvestPanelMapQuestions,
  ]);

  useEffect(() => {
    if (
      currentQuestionEle &&
      map &&
      selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION &&
      selectedQuestionType !== panelsName.HARVEST
    ) {
      map.on("moveend", () => {
        if (surveyActiveTab === "test") {
          return;
        }
        updatePointPickerCenterPoint(
          currentQuestionEle,
          map,
          selectedQuestionType,
          pointPickers,
          dispatch
        );
      });
    }
  }, [currentQuestionEle, map, selectedQuestionElement]);

  useEffect(() => {
    if (retrievalPointPicker) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          retrievalPointPicker,
        })
      );
    }
  }, [retrievalPointPicker]);

  useEffect(() => {
    if (struckPointPicker) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          struckPointPicker,
        })
      );
    }
  }, [struckPointPicker]);

  return null;
};

function updatePointPickerCenterPoint(
  currentQuestionEle: any,
  map: any,
  selectedQuestionType: any,
  pointPickers: any,
  dispatch: any
) {
  const updatedCurrentPointPickers = { ...currentQuestionEle };

  // update current element
  updatedCurrentPointPickers.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };
  updatedCurrentPointPickers.zoomLevel = map.getZoom();

  if (
    selectedQuestionType !== panelsName.HARVEST &&
    selectedQuestionType !== panelsName.GEOGRAPHICAL_INFORMATION
  ) {
    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPickers,
      (updatedpointPickers: any) => {
        dispatch(updatePointPickers(updatedpointPickers));
      }
    );
  }
}
function updateGeographicalInfoPanelCenterPoint(
  currentQuestionEle: any,
  map: any,
  geographicalPanelMapQuestions: any,
  dispatch: any
) {
  const updatedCurrentPointPicker = { ...currentQuestionEle };

  // update current element
  updatedCurrentPointPicker.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };
  updatedCurrentPointPicker.zoomLevel = map.getZoom();

  dispatch(
    updateAddedGeographicalPanelMapQuestions({
      ...geographicalPanelMapQuestions,
      pointPicker: updatedCurrentPointPicker,
    })
  );
}
function updateHarvestPanelCenterPoint(
  currentQuestionEle: any,
  map: any,
  harvestPanel: any,
  dispatch: any,
  setRetrievalPointPicker: any,
  setStruckPointPicker: any
) {
  const updatedCurrentPointPicker = { ...currentQuestionEle };

  // update current element
  updatedCurrentPointPicker.mapCenterPoint = {
    latitude: map.getCenter().lat,
    longitude: map.getCenter().lng,
  };

  updatedCurrentPointPicker.zoomLevel = map.getZoom();

  const variant =
    currentQuestionEle.id === harvestPanel.retrievalPointPicker.id
      ? "retrieval"
      : "struck";

  if (variant === "retrieval") {
    setRetrievalPointPicker(updatedCurrentPointPicker);
  } else {
    setStruckPointPicker(updatedCurrentPointPicker);
  }

  // dispatch(
  //   updateAddedHarvestPanelMapQuestions({
  //     ...harvestPanel,
  //     pointPicker: updatedCurrentPointPicker,
  //   })
  // );
}

export { useHandleMapMovement };
