import { values } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import apiClient from "services/apiClient";
import { conditionalityForObservations } from "utils/conditionalityForObservations";
import mapToDropdownValues from "utils/mapDropdownValues";

const customStyles = () => {
  return {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      padding: "0px",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      border: "none",
      boxShadow: null,
      outline: "none",
      fontFamily: "Overpass, sans-serif",
      "&:hover": {
        cursor: "text",
      },
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: "Overpass, sans-serif",
      fontWeight: "regular",
      color: "#A4B3C1",
    }),
    menu: (provided: any) => {
      return {
        ...provided,
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        minHeight: "100px", // Set a minimum height for the menu
        overflowY: "auto",
        zIndex: 9999,
      };
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#ebf2f6" : null,
        color: state.isFocused ? "#005C89" : null,
        fontFamily: "Overpass, sans-serif",
        "&:hover": {
          backgroundColor: state.isFocused ? "#ebf2f6" : "#f8f9fa",
          cursor: "pointer",
        },
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
      };
    },
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        cursor: "pointer",
        "& > *:nth-child(3)": {
          display: "none",
        },
      };
    },
    indicatorSeparator: (provided: any) => {
      return {
        ...provided,
        display: "none",
      };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        backgroundColor: "#f1f5f8",
        borderRadius: "8px",
        display: "inline-flex",
        gap: "0.5rem",
        alignItems: "center",
        padding: "4px 8px",
      };
    },
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: null,
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      svg: {
        fill: "black",
      },
    }),
  };
};

interface MultiselectDropdownProps {
  name: string;
  label: string;
  error?: any;
  editMode?: boolean;
  value?: any;
  data: string[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  placeholder?: any;
  search?: boolean;
  required?: boolean;
  choicesByUrl?: {
    url: string;
    valueName: string;
    titleName: string;
  };
  visibleIf?: any;
  enableIf?: any;
  setValueIf?: any;
  setValueExpression?: any;
  requiredIf?: any;
  answers?: any;
  panel?: any;
  element?: any;
}

export const MultiselectDropdown: React.FC<MultiselectDropdownProps> = ({
  label,
  name,
  error,
  editMode = true,
  value,
  data,
  handleChange,
  touched,
  placeholder,
  search,
  required,
  choicesByUrl = null,
  handleBlur,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  panel,
  element
}) => {
  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }
  // Creating Options
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const options = useMemo(() => {
    const allOptions =
      choicesByUrl !== null
        ? fetchedOptions
        : data?.map((item) => ({
          value: getItemValue(item, null),
          label: getItemLabel(item, null),
        }));

    return allOptions;
  }, [data, fetchedOptions]);

  const getDropdownOptions = async (choicesByUrl: {
    url: string;
    valueName: string;
    titleName: string;
  }) => {
    try {
      const { data } = await apiClient.get(choicesByUrl.url);
      const dropdownOptios = mapToDropdownValues(
        data,
        choicesByUrl.valueName,
        choicesByUrl?.titleName,
        "value",
        "label"
      );

      setFetchedOptions(dropdownOptios);
    } catch (error: any) {
      console.error("Error fetching dropdown options:", error);
      setFetchedOptions([]);
    }
  };

  useEffect(() => {
    if (choicesByUrl && choicesByUrl.url && editMode) {
      getDropdownOptions(choicesByUrl);
    } else {
      setFetchedOptions([]);
    }
  }, [choicesByUrl, editMode]);

  const formatWithCommas = useCallback(
    (value: any) => {
      if (!value) return "";

      let values = value
        .map((item: any) => {
          return commaSeparatedValues(item, choicesByUrl);
        })
        .join(", ");
      return values;
    },
    [value, choicesByUrl]
  );

  const parseValues = (value: any) => {
    const values =
      choicesByUrl === null
        ?
        typeof value === "object" ?
          value?.map((item: any) => ({
            value: getItemValue(item, null),
            label: getItemLabel(item, null),
          }))
          : null
        : mapToDropdownValues(
          value,
          choicesByUrl?.valueName,
          choicesByUrl?.titleName,
          "value",
          "label"
        );
    return values;
  };

  return (
    <div className="flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight p-4" id={`${element?.name}${panel?.sectionId}`}>
      <div className="relative flex flex-col py-2">
        {editMode ? (
          <p className="w-full pb-1 text-md font-medium text-left capitalize font-Overpass text-primary dark:text-caption ">
            {label || name} {required && <span>*</span>}
          </p>
        ) : (
          <p className="w-full pb-1 text-md text-left capitalize font-Overpass font-regular text-primary dark:text-caption">
            {label || name} {required && <span>*</span>}
          </p>
        )}

        {editMode ? (
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-bgWhite dark:bg-secondaryLight border ${touched && error ? "border-accent_1Dark" : "border-lineDark dark:border-lineLight"
              }`}
          >
            {search && (
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                  fill="#9B9FA5"
                />
              </svg>
            )}
            <Select
              classNamePrefix="my-react-select"
              className={` my-react-select-container`}
              options={options}
              isMulti={true}
              value={parseValues(value)}
              onChange={(option: any) => {
                const values = convertValuesLabelsToValueText(
                  option,
                  choicesByUrl
                );
                handleChange(values);
              }}
              onBlur={handleBlur}
              placeholder={placeholder}
              name={name}
              isSearchable={true}
              // styles={{
              //   control: (base, state) => ({
              //     ...base,
              //     "*": {
              //       boxShadow: "none !important",
              //     },
              //   })
              // }}
              styles={customStyles()}
              isClearable
              required={required}
            />
          </div>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              {(value && value.length > 0) ?
                <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-textAnswer dark:text-textMain ">
                  {value?.length > 0 && typeof value === "object"
                    ? parseValues(value)
                      .map((obj: any) => obj.label)
                      .join(", ")
                    : value}
                </p>
                :
                <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
                  (No Selection)
                </p>}
            </div>
          </>
        )}
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {touched && error && (
            <p className="flex-grow text-xs text-left text-accent_1Dark">
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default MultiselectDropdown;

function getItemLabel(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.titleName] : item?.text;
  }
  return item;
}

function getItemValue(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.valueName] : item?.value;
  }
  return item;
}

const convertValuesLabelsToValueText = (option: any, choicesByUrl: any) => {
  const updatedOptions = option.map((item: any) => {
    return convertToValueAndText(item, choicesByUrl);
  });
  return updatedOptions;
};

const convertToValueAndText = (item: any, choicesByUrl: any) => {
  if (item && typeof item === "object") {
    return { value: item?.value, text: item.label };
  }
  return item;
};

const commaSeparatedValues = (value: any, choicesByUrl: any) => {
  // console.log("hellow world", value);
  if (value && typeof value === "object") {
    return choicesByUrl !== null ? value[choicesByUrl.text] : value?.text;
  }
  return value;
};
