import React from "react";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import Button from "view/components/Button";
import { dashboardActions } from "store/dashboard";
import Sortable, { SortableEvent } from "sortablejs";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { Box, Grid, Modal, Stack, Typography } from "@mui/material";

import Content from "../Content";
import { IDashboardConfigurationProps } from "./types";
import SideBarWidgets from "./components/SideBarWidgets";
import { IDashboardConfiguration } from "store/dashboard/configurations/initialState";
import { dashboardWidgetConstants } from "constants/dashboardWidgetConstants";
import { ObservationDuration } from "store/dashboard/observations/types";
import CloseIcon from "./CloseIcon";
import DragIcon from "./DragIcon";
import { XCloseIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import {
  closeConfigurationModalAction,
  openConfigurationModalAction,
} from "store/modals";

const DashboardConfigurationModal = (props: IDashboardConfigurationProps) => {
  const [data, setData] = React.useState<{ data: any[]; loading: boolean }>({
    data: [],
    loading: true,
  });
  const { dashboard } = usePermissions();
  const latestDataRef = React.useRef(data);
  const [elementAdded, setElementAdded] = React.useState<boolean>(false);
  const [deleteElement, setDeleteElement] = React.useState<boolean>(false);

  const { configuration } = useSelector<RootState, IDashboardWidgets>(
    (state) => state.dashboard
  );

  const isDisabled =
    configuration.postStateIs === "Pending" ||
    configuration.stateIs == "Pending";

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  //GET REQUEST
  React.useEffect(() => {
    getData();
  }, []);

  // UPDATE STATE
  const getData = async () => {
    const result = await dispatch(
      dashboardActions.fetchDashboardConfiguration()
    );
    if (result) {
      setData({ data: result.data, loading: false });
    }
  };

  const onListSort = (props: any) => {
    if (props.gridRef.current) {
      const newData = [...props.gridRef.current.children]
        .filter((i) => i instanceof HTMLElement)
        .map((i) => (i as HTMLElement).dataset.id as string)
        .map((id) =>
          latestDataRef.current.data.find((item) => item._id === id)
        );
      setData((oldData) => {
        return { ...oldData, data: newData };
      });
    }
  };

  const onAddNewElement = (event: SortableEvent) => {
    const movedElementId = event.item.id.split("-")[0]; // The moved element
    // console.log(event);
    const newWidget: IDashboardConfiguration = {
      index: latestDataRef.current.data.length + 1,
      _id: movedElementId,
      widgetName: movedElementId,
    };

    if (
      movedElementId === dashboardWidgetConstants.observations.VALUE ||
      movedElementId === dashboardWidgetConstants.cumulativeObservations.VALUE
    ) {
      Object.assign(newWidget, {
        start_date: "1947-04-22",
        type: ObservationDuration.PER_DAY,
      });
    }
    let newDataArr = { refData: [...latestDataRef.current.data] };
    newDataArr.refData.splice(event.newIndex as number, 0, newWidget);

    setData((oldData) => {
      return { ...oldData, data: newDataArr.refData };
    });
    setElementAdded(!elementAdded);
  };

  const onDeleteElement = (id: string) => {
    setData((oldData) => {
      return {
        ...oldData,
        data: oldData.data.filter((item) => item._id !== id),
      };
    });
    setElementAdded(!elementAdded);
    setDeleteElement(!deleteElement);
  };

  React.useEffect(() => {
    latestDataRef.current = data;
  }, [data]);

  /**
   * CLOSE MODAL
   */
  const handleClose = () => {
    props.handleClose();
    dispatch(closeConfigurationModalAction());
  };

  /**
   * UPDATE MODAL CONFIGURATION
   */
  const updateConfiguration = async () => {
    const requestPayload: IDashboardConfiguration[] = data.data.map(
      (item, index) => {
        return {
          ...item,
          index: index + 1,
          widget_name: item.widgetName,
        };
      }
    );
    const result = await dispatch(
      dashboardActions.saveDashboardConfiguration(requestPayload)
    );
    if (result) {
      dispatch(dashboardActions.fetchDashboardConfiguration());
      handleClose();
    }
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="flex px-[50px] border-none pt-[104px] pb-2 items-center justify-center overflow-y-scroll outline-none outline-offset-0"
    >
      <div className="relative w-full h-full overflow-hidden bg-bgWhiteSmoke rounded-[18px] shadow-lg p-0 dark:bg-secondaryLight dashboard-widgets">
        <Grid container>
          <Grid item xs={3}>
            {/* {!data.loading && ( */}
            <SideBarWidgets
              selectedWidgets={data.data}
              loading={data.loading}
            />
            {/* )} */}
          </Grid>
          <Grid item xs={9}>
            <div className="flex flex-col py-0">
              <div className="z-10 flex flex-row items-center justify-start w-full h-24 px-5 overflow-hidden ">
                <h1 className="text-textCharcoal text-[32px]  font-Overpass font-bold dark:text-textMain capitalize leading-10 break-words">
                  configure your dashboard!
                </h1>

                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 ml-auto mr-6"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                  disabled={isDisabled}
                />
                <Button
                  type="submit"
                  text="Save Changes"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 mr-5"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={updateConfiguration}
                  disabled={
                    isDisabled || !dashboard.canCreateDashboardConfiguration
                  }
                  isLoading={configuration.postStateIs === "Pending"}
                />
                <button onClick={handleClose}>
                  <CloseIcon />
                </button>
              </div>
              <CustomGrid
                data={data.data}
                onListSort={onListSort}
                elementAdded={elementAdded}
                onAddNewElement={onAddNewElement}
                onDeleteElement={onDeleteElement}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default DashboardConfigurationModal;

interface ICustomGrid {
  elementAdded: boolean;
  data: any[];
  onListSort: (props: any) => void;
  onAddNewElement: (props: SortableEvent) => void;
  onDeleteElement: (idToDelete: string) => void;
}
const CustomGrid = (props: ICustomGrid) => {
  const gridRef = React.useRef<HTMLDivElement>(null);
  const sortableJsRef = React.useRef<Sortable | null>(null);
  const { dashboard } = usePermissions();

  const onListSort = () => {
    props.onListSort({ gridRef });
  };

  const onAddItem = (event: SortableEvent) => {
    if (gridRef.current) {
      // Find the element with the specified id
      const elementToDelete = gridRef.current.querySelector(
        `[id="${event.item.id}"]`
      );

      if (elementToDelete) {
        // Remove the element from the DOM
        elementToDelete.remove();
      }
    }
    props.onAddNewElement(event);
  };

  const onDeleteItem = (idToDelete: string) => {
    props.onDeleteElement(idToDelete);
  };

  React.useEffect(() => {
    if (gridRef.current) {
      generateSortableElements();
    }
  }, [props.elementAdded]);

  const generateSortableElements = () => {
    if (gridRef.current) {
      sortableJsRef.current = new Sortable(gridRef.current, {
        handle: ".handle",
        animation: 150,
        onEnd: onListSort,
        onAdd: onAddItem,
        group: {
          name: "shared",
          pull: false,
        },
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      ref={gridRef}
      id="content"
      sx={{
        height: "calc(100vh - 242px)",
        maxHeight: "calc(100vh - 242px)",
        overflow: "auto",
      }}
      p={5}
      pt={0}
    >
      {props.data.map((item) => {
        const { _id, grid, component } = Content({
          widgetName: item._id,
          dashboardPermissions: dashboard,
        });
        return (
          <Grid key={item._id} data-id={item._id} item xs={grid}>
            <div className="flex flex-row justify-between p-1 border border-solid rounded-lg dark:bg-secondaryLight dark:border-lineLight border-borderLightGray bg-bgBluish shadow-box">
              <button className="handle">
                <DragIcon />
              </button>
              <button onClick={() => onDeleteItem(item._id)}>
                <XCloseIcon />
              </button>
            </div>
            {component}
          </Grid>
        );
      })}
    </Grid>
  );
};
