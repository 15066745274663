import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { setLoadingProgress } from "store/loadingBar";
import { openEmailVerificationModalAction } from "store/modals/reducer.actions";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";

interface EmailVerificationBtnProps {
  contact: {
    isVerified: boolean;
    email: string;
  };
  errMsg: string | null;
}

export const EmailVerificationBtn: React.FC<EmailVerificationBtnProps> = ({
  contact,
  errMsg,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { personId } = useParams();
  const { verifyEmailPhone, confirmEmailPhone } = usePermissions();

  const dispatch = useDispatch();

  const verifyEmail = async () => {
    if (!verifyEmailPhone.canCreateVerifyEmailPhone) {
      return;
    }
    try {
      setIsLoading(true);
      dispatch(setLoadingProgress(getRandomProgress()));
      const res = await apiLibrary.userProfile.verifyEmailOrPhone(
        contact.email,
        personId
      );
      if (confirmEmailPhone.canCreateConfirmEmailPhone) {
        dispatch(
          openEmailVerificationModalAction({
            isOpen: true,
            value: contact.email,
          })
        );
      }
      Toasts.success(res.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;

      if (
        errorMsg === "Code already sent." &&
        confirmEmailPhone.canCreateConfirmEmailPhone
      ) {
        dispatch(
          openEmailVerificationModalAction({
            isOpen: true,
            value: contact.email,
          })
        );
      } else {
        Toasts.error(errorMsg);
      }
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  if (!contact.isVerified && contact.email !== "" && !errMsg) {
    return (
      verifyEmailPhone.canCreateVerifyEmailPhone && (
        <button
          disabled={isLoading || !verifyEmailPhone.canCreateVerifyEmailPhone}
          onClick={verifyEmail}
          type="button"
          className="ml-2 flex justify-center disabled:cursor-not-allowed items-center pt-1.5 pb-2 outline-none"
        >
          <p className="text-sm text-center font-semibold text-primary hover:underline">
            Verify
          </p>
        </button>
      )
    );
  } else {
    return null;
  }
};
