import React, { useLayoutEffect } from "react";
import {
  Filters,
  Header,
  Pagination,
  Table,
} from "Components/RolesPermissions";
import { NotificationsTable, RolesPermissionsTable } from "hooks/useTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchRoles } from "store/rolesPermissions/initialState";
import { fetchRolesPermissionsAction } from "store/rolesPermissions";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { TableProps } from "Components/Programs/Table/types";
import { rolesPermissionResetFilterActions, setRolesPermissionsFiltersAction } from "store/filters/reducer.actions";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const RolesPermissions = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IfetchRoles>(
    (state) => state.allRolesPermissions
  );
  const { rolesPermissionsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);

  const { roles } = usePermissions();

  useLayoutEffect(() => {
    dispatch(setRolesPermissionsFiltersAction());
  }, []);

  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setSelectAll,
    setSelectedRows,
    setSorting,
    sorting,
  } = RolesPermissionsTable(data?.roles);

  useEffect(() => {
    dispatch(fetchRolesPermissionsAction());
    saveFiltersDataToLocalStorage(rolesPermissionsFilter);
  }, [rolesPermissionsFilter]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(rolesPermissionResetFilterActions());
  //   };
  // }, []);
  
  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters columns={columnsConfig} />
            </div>
          </div>
        </div>

        <RenderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};

export default RolesPermissions;

const RenderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, IfetchRoles>(
    (state) => state?.allRolesPermissions
  );

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs == "Idle" && data?.roles?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
