import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

export const InputFields: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue
}) => {
  // export const InputFields = ({
  //   handleChange,
  //   handleBlur,
  //   values,
  //   errors,
  //   touched,
  // }: any) => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="mb-2">
        <p className="text-sm text-textMidLight dark:text-caption ">
          Give a title and write notification message
        </p>
      </div>
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
        <TextInput
          label="Notification Title*"
          type="text"
          placeholder="Notification title"
          name="title"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.title}
          error={errors?.title}
          touched={true}
        />
      </div>

      <div className="w-full mb-4">
        <TextInput
          label="Message*"
          type="text"
          rows={3}
          fieldAs="textarea"
          placeholder="Add message..."
          name="description"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.description}
          error={errors?.description}
          touched={true}
          helperText={"1024 Max Characters"}
        />
      </div>
      <div className="w-full">
        <TextInput
          label="Url Link"
          type="text"
          placeholder="Url link"
          name="urlLink"
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values?.urlLink}
          error={errors?.urlLink}
          touched={true}
        />
      </div>
    </div>
  );
};
