import React, { useLayoutEffect, useState } from "react";
// Components
import UserColumnModal from "view/components/Modals/Users/UserColumnModal";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { IfetchUsers } from "store/users/initialState";
import { FiltersProps } from "./types";
import { isAnyUsersFilterApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { data } = useSelector<RootState, IfetchUsers>(
    (state) => state?.allUsers
  );

  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

 


  useLayoutEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyUsersFilterApplied(filtersData.filters);
      } else {
        return isAnyUsersFilterApplied(usersFilter);
      }
    });
  }, []);
  
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      {isOpen && (
        <UserColumnModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columnOrder={columnOrder}
          columns={columns.filter((item: any) => item.id !== "select")}
          columnVisibility={columnVisibility}
        />
      )}
    </>
  );
};
