import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;
}

export const PrivacyPolicyIcon = ({
    fill = "#6F747B",
    isActive,
    ...restProps
}: IconProps) => (

    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
        {...restProps}>
        <path d="M12 22.607C11.7024 22.6067 11.4098 22.5303 11.15 22.385L7.78 20.513C6.10454 19.5815 4.70845 18.2192 3.73619 16.567C2.76393 14.9149 2.25083 13.033 2.25 11.116V5.91998C2.24995 5.4876 2.41024 5.07057 2.69988 4.74954C2.98951 4.4285 3.3879 4.22628 3.818 4.18198C5.15883 4.03986 6.47845 3.7415 7.75 3.29298C8.91583 2.88087 10.0342 2.34515 11.086 1.69498C11.3611 1.5266 11.6774 1.4375 12 1.4375C12.3226 1.4375 12.6389 1.5266 12.914 1.69498C13.9659 2.34497 15.0842 2.88068 16.25 3.29298C17.5216 3.7415 18.8412 4.03986 20.182 4.18198C20.6121 4.22628 21.0105 4.4285 21.3001 4.74954C21.5898 5.07057 21.75 5.4876 21.75 5.91998V11.116C21.7492 13.033 21.2361 14.9149 20.2638 16.567C19.2916 18.2192 17.8955 19.5815 16.22 20.513L12.85 22.386C12.5902 22.531 12.2976 22.6071 12 22.607ZM12 2.93698C11.956 2.93659 11.9127 2.94835 11.875 2.97098C10.732 3.67744 9.51675 4.25975 8.25 4.70798C6.86825 5.19566 5.43416 5.51987 3.977 5.67398C3.9151 5.67924 3.85743 5.70754 3.8154 5.75327C3.77337 5.79901 3.75003 5.85886 3.75 5.92098V11.117C3.75054 12.7665 4.19192 14.3859 5.02846 15.8076C5.865 17.2292 7.06629 18.4015 8.508 19.203L11.878 21.075C11.9149 21.0957 11.9566 21.1066 11.999 21.1066C12.0414 21.1066 12.0831 21.0957 12.12 21.075L15.492 19.203C16.9337 18.4015 18.135 17.2292 18.9715 15.8076C19.8081 14.3859 20.2495 12.7665 20.25 11.117V5.91998C20.25 5.85786 20.2266 5.79801 20.1846 5.75227C20.1426 5.70654 20.0849 5.67824 20.023 5.67298C18.5658 5.51887 17.1317 5.19466 15.75 4.70698C14.4833 4.25938 13.2681 3.67775 12.125 2.97198C12.0874 2.94881 12.0441 2.93668 12 2.93698Z" fill="black" />
        <path d="M11.5 14.25C11.3163 14.2501 11.1389 14.1825 11.002 14.06L8.752 12.06C8.61249 11.9256 8.5304 11.7424 8.52293 11.5488C8.51545 11.3552 8.58318 11.1662 8.71192 11.0215C8.84066 10.8767 9.02042 10.7874 9.21356 10.7722C9.40669 10.757 9.59821 10.8171 9.748 10.94L11.452 12.454L14.703 8.98698C14.7699 8.91347 14.8508 8.854 14.9409 8.81203C15.0311 8.77006 15.1286 8.74642 15.228 8.74251C15.3273 8.73859 15.4265 8.75446 15.5196 8.7892C15.6128 8.82394 15.6981 8.87685 15.7706 8.94486C15.8431 9.01287 15.9014 9.09463 15.942 9.18536C15.9827 9.2761 16.0049 9.37401 16.0073 9.4734C16.0098 9.57279 15.9925 9.67168 15.9563 9.76431C15.9202 9.85694 15.8661 9.94147 15.797 10.013L12.047 14.013C11.977 14.0879 11.8923 14.1476 11.7982 14.1884C11.7041 14.2292 11.6026 14.2501 11.5 14.25Z" fill="black" />
        <path d="M12 22.607C11.7024 22.6067 11.4098 22.5303 11.15 22.385L7.78 20.513C6.10454 19.5815 4.70845 18.2192 3.73619 16.567C2.76393 14.9149 2.25083 13.033 2.25 11.116V5.91998C2.24995 5.4876 2.41024 5.07057 2.69988 4.74954C2.98951 4.4285 3.3879 4.22628 3.818 4.18198C5.15883 4.03986 6.47845 3.7415 7.75 3.29298C8.91583 2.88087 10.0342 2.34515 11.086 1.69498C11.3611 1.5266 11.6774 1.4375 12 1.4375C12.3226 1.4375 12.6389 1.5266 12.914 1.69498C13.9659 2.34497 15.0842 2.88068 16.25 3.29298C17.5216 3.7415 18.8412 4.03986 20.182 4.18198C20.6121 4.22628 21.0105 4.4285 21.3001 4.74954C21.5898 5.07057 21.75 5.4876 21.75 5.91998V11.116C21.7492 13.033 21.2361 14.9149 20.2638 16.567C19.2916 18.2192 17.8955 19.5815 16.22 20.513L12.85 22.386C12.5902 22.531 12.2976 22.6071 12 22.607ZM12 2.93698C11.956 2.93659 11.9127 2.94835 11.875 2.97098C10.732 3.67744 9.51675 4.25975 8.25 4.70798C6.86825 5.19566 5.43416 5.51987 3.977 5.67398C3.9151 5.67924 3.85743 5.70754 3.8154 5.75327C3.77337 5.79901 3.75003 5.85886 3.75 5.92098V11.117C3.75054 12.7665 4.19192 14.3859 5.02846 15.8076C5.865 17.2292 7.06629 18.4015 8.508 19.203L11.878 21.075C11.9149 21.0957 11.9566 21.1066 11.999 21.1066C12.0414 21.1066 12.0831 21.0957 12.12 21.075L15.492 19.203C16.9337 18.4015 18.135 17.2292 18.9715 15.8076C19.8081 14.3859 20.2495 12.7665 20.25 11.117V5.91998C20.25 5.85786 20.2266 5.79801 20.1846 5.75227C20.1426 5.70654 20.0849 5.67824 20.023 5.67298C18.5658 5.51887 17.1317 5.19466 15.75 4.70698C14.4833 4.25938 13.2681 3.67775 12.125 2.97198C12.0874 2.94881 12.0441 2.93668 12 2.93698Z" stroke="black" stroke-width="0.3" />
        <path d="M11.5 14.25C11.3163 14.2501 11.1389 14.1825 11.002 14.06L8.752 12.06C8.61249 11.9256 8.5304 11.7424 8.52293 11.5488C8.51545 11.3552 8.58318 11.1662 8.71192 11.0215C8.84066 10.8767 9.02042 10.7874 9.21356 10.7722C9.40669 10.757 9.59821 10.8171 9.748 10.94L11.452 12.454L14.703 8.98698C14.7699 8.91347 14.8508 8.854 14.9409 8.81203C15.0311 8.77006 15.1286 8.74642 15.228 8.74251C15.3273 8.73859 15.4265 8.75446 15.5196 8.7892C15.6128 8.82394 15.6981 8.87685 15.7706 8.94486C15.8431 9.01287 15.9014 9.09463 15.942 9.18536C15.9827 9.2761 16.0049 9.37401 16.0073 9.4734C16.0098 9.57279 15.9925 9.67168 15.9563 9.76431C15.9202 9.85694 15.8661 9.94147 15.797 10.013L12.047 14.013C11.977 14.0879 11.8923 14.1476 11.7982 14.1884C11.7041 14.2292 11.6026 14.2501 11.5 14.25Z" stroke="black" stroke-width="0.3" />
    </svg>

);
export default PrivacyPolicyIcon;
