import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import pointIconRed from "assets/images/location-marker-red.png";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";


const usePaintAllPointsOnMap = (
  currentQuestionEle: any,
  map: any,
  isMapFullyLoaded: any,
  LAYER_SELECTED_COLOR: any,
  LAYER_DEFAULT_COLOR: any,
  markers: any,
  setMarkers: any
) => {
  const [clickedPoint, setClickedPoint] = useState<any>(null);

  useEffect(() => {
    if (
      map &&
      isMapFullyLoaded &&
      currentQuestionEle &&
      currentQuestionEle.allCommunityMarkers &&
      currentQuestionEle.allCommunityMarkers.length > 0
    ) {
      const newMarkers: any = [];

      currentQuestionEle.allCommunityMarkers.forEach((markerData: any) => {

        const coordinates =
          markerData.mapData?.features[0]?.geometry?.coordinates;

        const isMarkerHighlighted =
          currentQuestionEle.selectedPointsIdToHighlight?.some(
            (marker: any) => marker.id === markerData.id
          );

        const markerColor = isMarkerHighlighted
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        if (coordinates) {
          const [longitude, latitude] = coordinates;
          const markerElement = document.createElement("div");
          markerElement.className = "custom-marker-point-picker";
          
          markerElement.innerHTML = `
   <div class="marker-content-point-picker" style="
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    border: 3px solid ${markerColor}; 
   
    overflow: hidden;
  ">
    <img src="${markerData?.markerStyle?.styleImage??pointIconRed}" alt="Marker Image" class="marker-image" style="width: 100%; height: 100%; object-fit: cover;" />
  </div>
`;

          const marker = new mapboxgl.Marker(markerElement)
            .setLngLat([longitude, latitude])
            .addTo(map);
          marker.getElement().addEventListener("click", (e) => {
            setClickedPoint(markerData);
          });

          newMarkers.push({ marker, id: markerData.id, data: markerData });
        }
      });

      setMarkers([...newMarkers]);
    }
  }, [map, currentQuestionEle?.allCommunityMarkers, isMapFullyLoaded]);

  return [clickedPoint, setClickedPoint, markers, setMarkers];
};

export { usePaintAllPointsOnMap };
