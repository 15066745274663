import { Dispatch, useLayoutEffect, useState } from "react";
// components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// API services
import apiLibrary from "services/api";
// Store utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen } from "store/geography";
// Third party services
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import Head from "view/components/Head";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import MarkerGroupsDropDown from "../MarkerGroupsDropDown";
import MarkerStylesSelector from "../MarkerStylesSelector";

// Schema
const editVectorLayerSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "255 max characters")
    .required("Layer name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(255, "255 max characters"),
  color: Yup.string().required("Fill Color is required"),
  borderColor: Yup.string().required("Border Color is required"),
  shapeType: Yup.string().required("Shape Type is Required"),
  markerStyleVersionId: Yup.number()
    .nullable(),
  markerStyleId: Yup.number()
    .nullable()
    .test("markerStyleId", "Marker Style is required ", function (value) {
      const { shapeType } = this.parent;

      if (shapeType !== "point") {
        return true;
      }

      return value != null;
    }),
  markerGroupId: Yup.mixed()
    .nullable()
    .test("markerGroupId", "Marker Group is required ", function (value) {
      const { shapeType } = this.parent;

      if (shapeType !== "point") {
        return true;
      }

      return value != null;
    }),
});

const EditVectorLayer = () => {
  const dispatch = useDispatch();
  const { editVectorLayerData } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();


  const { dataItem: data, isGlobal } = editVectorLayerData ?? {};



  const initialValues = {
    name: data?.name,
    description: data?.description,
    color: data?.color ?? "#FF0000",
    borderColor: data?.borderColor ?? "#FF0000",
    shapeType: data?.shapeType,
    markerStyleId: data?.markerStyle?.id ?? null,
    markerStyleVersionId: data?.markerStyle?.markerStyleVersionId ?? null,
    markerGroupId: checkObject(data?.markerGroup) !== null ? {
      value: data?.markerGroup?.id,
      label: data?.markerGroup?.name,
    } : null,
  };

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    const updatedValue = { ...values, markerGroupId: values.markerGroupId?.value }
    try {
      if (!willColorBeVisibleOnMap(values.color)) {
        setFieldError(
          "color",
          "Please select a fill color that is easily visible"
        );
        return;
      }
      if (!willColorBeVisibleOnMap(values.borderColor)) {
        setFieldError(
          "borderColor",
          "Please select a border color that is easily visible"
        );
        return;
      }

      if (communityId && !isGlobal) {
        await apiLibrary.geography.editLayer(communityId, data.id, updatedValue);
      } else {
        await apiLibrary.geography.editLayerForAdmin(data.id, updatedValue);
      }
      Toasts.success(`Vector layer updated successfully`);

      // navigate to all layers screen
      dispatch(openAllLayersListingScreen());
    } catch (err: any) {
      Toasts.error(err.message);
    } finally {
      setSubmitting(true);
    }
  };

  return (
    <div>
      <Head title="Geography: Edit Vector Layer" />
      <Breadcrumb
        label="Edit Vector Layer"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={editVectorLayerSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label="Layer Name*"
                  type="text"
                  placeholder="Layer name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors?.name}
                  touched={touched?.name}
                />
              </div>
              <div className="w-full mb-4">
                <TextInput
                  label="Description*"
                  type="text"
                  rows={3}
                  fieldAs="textarea"
                  placeholder="Description"
                  name="description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  error={errors?.description}
                  touched={touched?.description}
                />
                {!errors?.description && (
                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                    255 max characters
                  </p>
                )}
              </div>
              {data?.shapeType === "point" ? <div className="flex flex-col items-start justify-start w-full mb-2">
                <MarkerGroupsDropDown
                  error={errors?.markerGroupId}
                  handleGroupSelectorOnChange={(Option: any) => {
                    setFieldValue("markerGroupId", Option);
                    setFieldValue("markerStyleId", null);
                    setFieldValue("markerStyleVersionId", null);

                  }}
                  communityId={communityId}
                  title={"Choose Group *"}
                  value={values?.markerGroupId}
                />
                <MarkerStylesSelector
                  communityId={communityId}
                  groupId={values?.markerGroupId}
                  error={errors?.markerStyleId}
                  handleStyleSelectorOnChange={(Option: any) => {
                    setFieldValue("markerStyleId", Option.id);
                    setFieldValue("markerStyleVersionId", Option.markerStyleVersionId);
                  }}
                  title={"Choose Style *"}
                  value={values?.markerStyleId}
                />
              </div> : (
                <>
                  <div className="w-full mb-4">
                    <div>
                      <p
                        className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${touched && errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : ""
                          }`}
                      >
                        Select Fill Color
                      </p>
                      <ColorPicker
                        handlePicker={(color: string) =>
                          setFieldValue("color", color)
                        }
                        color={values.color}
                      />
                    </div>
                    {errors?.color && (
                      <p
                        className={`flex-grow text-xs text-left   ${errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : "text-textMidLight dark:text-textMain"
                          } `}
                      >
                        {/* @ts-ignore */}
                        {errors.color}
                      </p>
                    )}
                  </div>
                  {data.shapeType !== "linestring" && (
                    <div className="w-full mb-4">
                      <div>
                        <p
                          className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${touched && errors?.borderColor
                            ? "text-accent_1Dark dark:text-accent_1Dark"
                            : ""
                            }`}
                        >
                          Select Border Color
                        </p>
                        <ColorPicker
                          handlePicker={(color: string) =>
                            setFieldValue("borderColor", color)
                          }
                          color={values.borderColor}
                        />
                      </div>
                      {errors?.borderColor && (
                        <p
                          className={`flex-grow text-xs text-left   ${errors?.borderColor
                            ? "text-accent_1Dark dark:text-accent_1Dark"
                            : "text-textMidLight dark:text-textMain"
                            } `}
                        >
                          {/* @ts-ignore */}
                          {errors.borderColor}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="flex justify-between gap-2">
                <Button
                  type="reset"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.t%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={goBackToAllLayersScreen}
                />
                <Button
                  type="submit"
                  text="Save"
                  disabled={isSubmitting}
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.5%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { EditVectorLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};




interface MyObject {
  id: string | null | undefined;
  name: string | null | undefined;
}
function checkObject(obj: MyObject | null | undefined): MyObject | null {
  if (
    obj === null ||
    obj === undefined ||
    obj.id === null ||
    obj.id === undefined ||
    obj.id === "" ||
    obj.name === null ||
    obj.name === undefined ||
    obj.name === ""
  ) {
    return null;
  }
  return obj;
}
