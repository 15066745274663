import React from "react";
import { FormikProps, FormikValues } from "formik";
import { FormSumbmissionBtns } from "./components/FormSumbmissionBtns";
import { InputFields } from "./components/InputFields";

interface TargetProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
  handleGoBackBtn: any;
  step: number;
}

export const Target: React.FC<TargetProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
  handleGoBackBtn,
  setFieldTouched,
  step,
}) => {
  return (
    <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
      <InputFields
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        step={step}
        values={values}
        
      />
      <FormSumbmissionBtns
        handleGoBackBtn={handleGoBackBtn}
        handleSubmit={handleSubmit}
        activeStep={activeStep}
        values={values}
        isFormLoading={isFormLoading}
        isLastStep={isLastStep}
      />
    </div>
  );
};
