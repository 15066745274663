import { MoveToFirstPageBtnProps } from "../../types";

export const MoveToFirstPageBtn = ({
  page,
  handleClickOnBtn,
}: MoveToFirstPageBtnProps) => {
  return (
    <button
      disabled={page === 1}
      className="hover:bg-primaryLight flex justify-center disabled:opacity-50 disabled:cursor-not-allowed items-center flex-grow-0 flex-shrink-0 w-[88px] gap-1 px-3  py-1 rounded-lg border-2 border-primary bg-bgWhite dark:bg-secondaryLight dark:border-lineLight"
      onClick={handleClickOnBtn}
    >
      <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 ">
        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary dark:text-textMain">
          First
        </p>
      </div>
    </button>
  );
};
