import { TableBodyProps } from '../../types';
import parseArray from 'utils/parseArray';
import { Cell } from '@tanstack/react-table';
import { Column } from '../index';
import '../../../Table/Observations.scss';
import { fetchImportStatusDetailAction } from 'store/importStatusDetail/reducer.actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { fetchImportsFile } from 'store/importsViewData/initialState';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import usePermissions from 'hooks/usePermissions';
import { useEffect, useState } from 'react';
import { Toasts } from 'view/components/Toasts';
import apiClient from 'services/apiClient';

export const TableBody = ({
  table,
  columnVisibility,

  flexRender,
}: TableBodyProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { data } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );

  const [observers, setObservers] = useState([]);
  const { imports } = usePermissions();

  const showImportStatusDetail = (row: any) => {
    if (imports.canViewStatusDetailImports) {
      dispatch(
        fetchImportStatusDetailAction({
          id: row?.id,
          path: 'directory',
        })
      );
    }
  };

  const fetchObservers = async (programId: number | null) => {
    const params: any = {
      entity: 'observer',
      connected: true,
      limit: 10000,
    };

    if (programId) {
      try {
        const { data } = await apiClient.get(
          `/admin/programs/${programId}/entity-dropdown`,
          {
            params,
          }
        );
        setObservers(data.data.entities);
      } catch (error: any) {
        const errorMsg =
          error?.response?.data?.message ??
          error?.message ??
          'Error fetching reviewers';
        Toasts.error(errorMsg);
      }
    }
  };

  useEffect(() => {
    let programId = data?.importedFileData[0].programName?.id;
    if (programId) {
      fetchObservers(programId);
    }
  }, []);

  const hasFailedImport = data?.importedFileData?.some(
    (item: any) => item?.importStatus?.toLowerCase() === 'failed'
  );

  return (
    <tbody>
      {table.getRowModel().rows?.map((row: any, index: number) => {
        let programId = data?.importedFileData?.find(
          (item) => item?.id === row?.original?.id
        )?.programName?.id;
        return (
          <tr
            key={index}
            className={` Close w-full item_data group border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight`}
            style={{ height: '66px' }}
          >
            {parseArray(row?.getVisibleCells(), ['']).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect='item_hover_effect'
                  flexRender={flexRender}
                  importStatusDetail={() =>
                    showImportStatusDetail(row.original)
                  }
                  cell={cell}
                  columnVisibility={columnVisibility}
                  programId={programId !== undefined ? programId : null}
                  id={row.original.id}
                  observers={observers}
                  rowData={row.original}
                />
              )
            )}
            {hasFailedImport && (
              <td className='whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight'>
                {/* <RowsActions
                row={row}
                getImportsFileDataActions={getImportsFileDataActions}
              /> */}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
