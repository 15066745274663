import { apiClient } from "../config";

async function getAllNotifications(queryParams: any) {
  const res = await apiClient.get(`/admin/reminders`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllNotifications;
  