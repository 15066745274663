import React from 'react';
import DocumentPreviewIcon from 'assets/icons/HeroIcons/DocumentPreviewIcon';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import ArrowDownloadIcon from 'assets/icons/HeroIcons/ArrowDownloadIcon';
import axios from 'axios';

interface DocumentThumbnailProps {
  url: string;
  userId: number;
  type: string;
  title: string;
  sendTime: string;
  fileSize: number;
  handlePreviewFiles: () => void;
}

const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({
  url,
  userId,
  type,
  title,
  sendTime,
  fileSize,
  handlePreviewFiles,
}) => {
  const auth = useSelector((state: RootState) => state.auth);

  const downloadDocumentFile = async (
    filePath: string,
    fileName: string,
    messageType: string
  ) => {
    try {
      const response = await axios({
        url: filePath,
        method: 'GET',
        responseType: 'blob',
      });
      const fullFileName = `${fileName}.${messageType}`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fullFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading File:', error);
    }
  };

  return (
    <div
      className={`flex gap-3 p-4 rounded-md  ${userId === auth.id ? 'text-secondaryMidLight dark:text-textMain bg-bgChatMessage dark:bg-primary_8 ' : 'text-secondaryMidLight dark:text-textMain  bg-bgWhite dark:bg-bgtetriary  '}  `}
    >
      <DocumentPreviewIcon />

      <div className='flex flex-col'>
        <div className='flex justify-between items-center gap-2'>
          <p
            className='text-sm text-[#353535] hover:text-primary dark:text-textMain font-medium font-Overpass cursor-pointer'
            onClick={handlePreviewFiles}
          >
            {title}.{type}{' '}
          </p>
          <div
            className='cursor-pointer'
            onClick={() => downloadDocumentFile(url, title, type)}
          >
            <ArrowDownloadIcon />
          </div>
        </div>

        <div className='flex justify-between items-center gap-3'>
          <span className='text-xs text-[#989692] dark:text-caption'>
            {fileSize}
          </span>

          <span className='ml-auto pt-2 text-xs text-textLight dark:text-caption'>
            {sendTime}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DocumentThumbnail;
