import { ArrowLeftIcon } from 'assets/icons/HeroIcons';
import React from 'react'
import Button from "view/components/Button";

interface IProps {
  handleGoBackBtn: () => void;
  handleSubmit: () => void;
  activeStep: number;
  values: any;
  isFormLoading: boolean;
  isLastStep: boolean;
} 

export const FormSumbmissionBtns: React.FC<IProps> = ({
  handleGoBackBtn,
  handleSubmit,
  activeStep,
  values,
  isFormLoading,
  isLastStep,
}) => {
  return (
    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill="#005C89" />}
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleGoBackBtn}
      />
      <Button
        type="button"
        onClick={handleSubmit}
        disabled={
          (activeStep === 1 && values.adminIds.length === 0) ||
          (activeStep === 2 && values.organizationIds.length === 0) ||
          isFormLoading
        }
        text="Save"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="filled"
      />
    </div>
  )
}
