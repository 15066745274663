import { Dispatch, useState } from "react";
// Store utils
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen } from "store/geography";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import { SelectForm, UploadForm, DrawPoints } from "./Components";
import {
  displayPickPointOnMap,
  displayPointLayerOnMap,
  showSnapingControl,
} from "store/geography/reducer.actions";
import Head from "view/components/Head";

const TABS = {
  SELECT: "SELECT",
  UPLOAD: "UPLOAD",
  PICK_ON_MAP: "PICK ON MAP",
};

const CreatePoints = () => {
  const [activeTab, setActiveTab] = useState(TABS.SELECT);
  const dispatch = useDispatch();

  const formHeight = calculateFormHeight();

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(showSnapingControl(false));
    dispatch(openAllLayersListingScreen());
  };
  const handleClickOnTab = (item: any) => {
    setActiveTab(item);

    if (item === TABS.PICK_ON_MAP) {
      dispatch(displayPickPointOnMap({}));
      dispatch(showSnapingControl(true));
    } else {
      dispatch(showSnapingControl(false));
      dispatch(displayPointLayerOnMap({}));
    }
  };

  function calculateFormHeight(){
    const containerPadding = 32;
    const headBarHeight = 24 + 24; 
    const breadcrumbHeight = 48 + 32;
    const formButtonsHeight = 52 + 8;
    const allElementsHeight =
      containerPadding +
      headBarHeight +
      breadcrumbHeight +
      formButtonsHeight * 2;

    return `calc(100vh - ${allElementsHeight}px)`;
  };

  return (
    <div className="h-full">
      <Head title="Geography: Add Points" />
      <Breadcrumb
        label="Add Points"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <div className="mb-8">
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          handleOnClick={handleClickOnTab}
        />
      </div>
      {activeTab === TABS.SELECT && <SelectForm height={formHeight} />}
      {activeTab === TABS.UPLOAD && <UploadForm height={formHeight} />}
      {activeTab === TABS.PICK_ON_MAP && <DrawPoints height={formHeight} />}
    </div>
  );
};

export { CreatePoints };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};
const Tabs = ({ tabs, activeTab, handleOnClick }: any) => {
  return (
    <div className="flex border-b border-lineMid dark:border-lineLight ">
      {tabs &&
        Object.keys(tabs)?.map((tab: any) => {
          return (
            <button
              key={tab}
              className={`py-3 flex-1 px-4 text-sm text-secondary  ${activeTab === tabs[tab]
                ? "border-b-2 border-primaryMid font-semibold dark:text-primaryMid"
                : "font-medium hover:border-b hover:border-lineMid dark:text-textMain"
                } `}
              onClick={() => handleOnClick(tabs[tab])}
            >
              {tabs[tab]}
            </button>
          );
        })}
    </div>
  );
};
