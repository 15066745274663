import { apiClient } from "../config";

async function getPollAndSurveyResults(id: number, queryParams: any) {
  const res = await apiClient.get(`/admin/survey-and-polls/${id}/result`, {
    params: queryParams,
  });
  return res.data;
}

export default getPollAndSurveyResults;
