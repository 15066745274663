import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const WarningIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path d="M13.4165 6H11.9165V14.25H13.4165V6Z" fill="#F89755" />
    <path
      d="M12.0415 16.6896C12.2265 16.566 12.444 16.5 12.6665 16.5C12.9649 16.5 13.251 16.6185 13.462 16.8295C13.673 17.0405 13.7915 17.3266 13.7915 17.625C13.7915 17.8475 13.7255 18.065 13.6019 18.25C13.4783 18.435 13.3026 18.5792 13.097 18.6644C12.8915 18.7495 12.6653 18.7718 12.447 18.7284C12.2288 18.685 12.0283 18.5778 11.871 18.4205C11.7137 18.2632 11.6065 18.0627 11.5631 17.8445C11.5197 17.6262 11.542 17.4 11.6271 17.1945C11.7123 16.9889 11.8565 16.8132 12.0415 16.6896Z"
      fill="#F89755"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.83302 3.26957C8.55974 2.11581 10.5898 1.5 12.6665 1.5C15.4513 1.5 18.122 2.60625 20.0911 4.57538C22.0603 6.54451 23.1665 9.21523 23.1665 12C23.1665 14.0767 22.5507 16.1068 21.3969 17.8335C20.2432 19.5602 18.6033 20.906 16.6847 21.7007C14.7661 22.4955 12.6549 22.7034 10.6181 22.2982C8.58126 21.8931 6.71034 20.8931 5.24189 19.4246C3.77344 17.9562 2.77341 16.0852 2.36826 14.0484C1.96312 12.0116 2.17105 9.90045 2.96577 7.98182C3.76049 6.0632 5.1063 4.42332 6.83302 3.26957ZM7.66638 19.4832C9.14642 20.4722 10.8865 21 12.6665 21C15.0535 21 17.3426 20.0518 19.0305 18.364C20.7183 16.6761 21.6665 14.3869 21.6665 12C21.6665 10.22 21.1387 8.47991 20.1497 6.99987C19.1608 5.51983 17.7552 4.36627 16.1107 3.68508C14.4661 3.0039 12.6565 2.82567 10.9107 3.17293C9.16487 3.5202 7.56122 4.37737 6.30255 5.63604C5.04387 6.89471 4.18671 8.49836 3.83944 10.2442C3.49217 11.99 3.6704 13.7996 4.35159 15.4442C5.03278 17.0887 6.18633 18.4943 7.66638 19.4832Z"
      fill="#F89755"
    />
  </svg>
);
export default WarningIcon;

{
  /* <svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M13.4165 6H11.9165V14.25H13.4165V6Z" fill="#F89755" />
  <path
    d="M12.0415 16.6896C12.2265 16.566 12.444 16.5 12.6665 16.5C12.9649 16.5 13.251 16.6185 13.462 16.8295C13.673 17.0405 13.7915 17.3266 13.7915 17.625C13.7915 17.8475 13.7255 18.065 13.6019 18.25C13.4783 18.435 13.3026 18.5792 13.097 18.6644C12.8915 18.7495 12.6653 18.7718 12.447 18.7284C12.2288 18.685 12.0283 18.5778 11.871 18.4205C11.7137 18.2632 11.6065 18.0627 11.5631 17.8445C11.5197 17.6262 11.542 17.4 11.6271 17.1945C11.7123 16.9889 11.8565 16.8132 12.0415 16.6896Z"
    fill="#F89755"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.83302 3.26957C8.55974 2.11581 10.5898 1.5 12.6665 1.5C15.4513 1.5 18.122 2.60625 20.0911 4.57538C22.0603 6.54451 23.1665 9.21523 23.1665 12C23.1665 14.0767 22.5507 16.1068 21.3969 17.8335C20.2432 19.5602 18.6033 20.906 16.6847 21.7007C14.7661 22.4955 12.6549 22.7034 10.6181 22.2982C8.58126 21.8931 6.71034 20.8931 5.24189 19.4246C3.77344 17.9562 2.77341 16.0852 2.36826 14.0484C1.96312 12.0116 2.17105 9.90045 2.96577 7.98182C3.76049 6.0632 5.1063 4.42332 6.83302 3.26957ZM7.66638 19.4832C9.14642 20.4722 10.8865 21 12.6665 21C15.0535 21 17.3426 20.0518 19.0305 18.364C20.7183 16.6761 21.6665 14.3869 21.6665 12C21.6665 10.22 21.1387 8.47991 20.1497 6.99987C19.1608 5.51983 17.7552 4.36627 16.1107 3.68508C14.4661 3.0039 12.6565 2.82567 10.9107 3.17293C9.16487 3.5202 7.56122 4.37737 6.30255 5.63604C5.04387 6.89471 4.18671 8.49836 3.83944 10.2442C3.49217 11.99 3.6704 13.7996 4.35159 15.4442C5.03278 17.0887 6.18633 18.4943 7.66638 19.4832Z"
    fill="#F89755"
  />
</svg>; */
}
