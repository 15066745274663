function separateColorAndOpacity(hexColorWithOpacity: any) {
  // Extract the color part (first six characters)
  const color = hexColorWithOpacity?.substring(0, 7);

  // Extract the opacity part (last two characters) and convert from hex to decimal
  const opacityHex = hexColorWithOpacity?.substring(7);
  const opacityDecimal = parseInt(opacityHex, 16) / 255;

  return { color, opacity: isNaN(opacityDecimal) ? 1 : opacityDecimal };
}
export default separateColorAndOpacity;