import {
  MOVE_TO_NEXT_STEP,
  MOVE_TO_PREVIOUS_STEP,
  RESET_IMPORT_STEPPER,
  SET_IMPORT_ID,
  MOVE_TO_SPECIFIC_STEP,
} from "./action-types";

export const moveToNextStepAction = () => {
  return {
    type: MOVE_TO_NEXT_STEP,
  };
};

export const moveToPreviousStepAction = () => {
  return {
    type: MOVE_TO_PREVIOUS_STEP,
  };
};

export const setReportIdAction = (reportId: number) => {
  return {
    type: SET_IMPORT_ID,
    payload: reportId,
  };
};

export const resetImportStepperAction = () => {
  return {
    type: RESET_IMPORT_STEPPER,
  };
};

export const moveToSpecificStepAction = (index: number) => {
  return {
    type: MOVE_TO_SPECIFIC_STEP,
    payload: index,
  };
};
