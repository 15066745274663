import apiClient from "services/apiClient";

export async function updateUniversalSettings(
  setting: any,
  gracePeriodValue: any,
  reInvitePeriodValue: any
) {
  const params = {
    setting: setting,
    gracePeriodValue: gracePeriodValue,
    reInvitePeriodValue: reInvitePeriodValue,
  };

  const url = `/admin/universal-settings`;

  const res = await apiClient.put(url, null, { params });
  return res.data;
}
