import React, { useEffect, useLayoutEffect, useMemo } from "react";
// Components
import { Header, Pagination, Table, Tabs } from "Components/Imports";
// Hooks
import { ImportsTable, ProgramAdminsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import { FetchImports, Import } from "store/imports/initialState";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { fetchImportsAction } from "store/imports";
import { Filters } from "Components/Imports/Filters";
import { importsFilterReset, setImportsFiltersAction } from "store/filters/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Imports = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, FetchImports>(
    (state) => state?.allImports
  );
  const { importsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  useLayoutEffect(() => {
    dispatch(setImportsFiltersAction());
  }, []);

  const isRowSelected = (rowId: string) => selectedRows?.includes(rowId);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setSorting,
    sorting,
    selectAll,
    selectedRows,
    setSelectAll,
    setSelectedRows,
  } = importsFilter.status === "permission" ? ProgramAdminsTable(data?.admins) : ImportsTable(data?.imports);

  useEffect(() => {
    dispatch(fetchImportsAction());
    saveFiltersDataToLocalStorage(importsFilter);
  }, [importsFilter]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(importsFilterReset());
  //   };
  // }, []);
  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columns={columnsConfig}
                columnVisibility={columnVisibility}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs == "Idle" && (data?.imports?.length === 0 && data?.admins.length === 0) ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              isRowSelected={isRowSelected}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              setSelectedRows={setSelectedRows}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
