export const getCurrentBranding = () => {
    const currentBranding = process.env.REACT_APP_BRAND_NAME ?? '';
    return currentBranding.toLocaleLowerCase(); // No need to compare here, just return the app name
};

// Theme mapping for different apps
const themes = {
    sign: {
        primaryDark: "#8D2A25",
        primary: "#8D2A25",
        primaryMid: "#8D2A25",
        primaryExtraLight: "#F6EDDD",
        primaryMidLight: "#8D2A25",
        bgBluish_2: "#F6EDDD",
        textLink: "#8D2A25",
        bgHighMidlight: "#ededed3b"
    },
    default: {
        primaryDark: "#024C6F",
        primary: "#005C89",
        primaryMid: "#0A7BAC",
        primaryExtraLight: "#EBF2F6",
        primaryMidLight: "#11A3E2",
        bgBluish_2: "#E6EFF4",
        textLink: "#005C89",
        bgHighMidlight: "#F2F7F9"
    },
    // Add other themes here for future apps
};

// Shared colors
const sharedColors = {
    primaryLight: "#DBE8EE",
    primary_8: "#005C8914",
    primary_5: "#005C890D",
    primary_40: "#005C8966",
    primaryLight40: "#99BED0",
    primary_50: "#00817066",
    bgPrimaryLight: "#F8F8F8",
    primaryLight71: "#0071C3",
    secondary: "#202123",
    secondaryMid: "#2C3236",
    secondaryMidLight: "#54595F",
    secondaryLight: "#6F747B",
    brandHover: "#E9E9E9",
    brandHoverLight: "#F2F2F2",
    secondary_10: "#2021231A",
    secondary_6: "#2021230F",
    secondary_25: "#20212340",
    brandHover25: "#C7C7C8",
    brandHover40: "#A6A6A7",
    tertiaryDark: "#2A6D66",
    tertiary: "#008170",
    tertiaryMid: "#009F88",
    tertiaryMidLight: "#42BBAC",
    tertiaryLight: "#DBEDEB",
    tertiaryExtraLight: "#EDF6F5",
    tertiaryMid_14: "#00817024",
    tertiaryMid_7: "#00817012",
    accent_1Dark: "#BF200B",
    accent_1: "#E83B25",
    accent_1Light: "#FBE3E0",
    accent_1ExtraLight: "#FDF1F0",
    accent_1_14: "#E83B2524",
    accent_1_7: "#E83B2512",
    accent_2: "#E56D17",
    accent_2Mid: "#F89755",
    accent_2Light: "#FBE9DC",
    accent_2LightExtra: "#FEF6F1",
    accent_3: "#F2AA00",
    accent_3Mid: "#FFDD8B",
    accent_3Light: "#FDF2D9",
    accent_3LightExtra: "#FFFAF0",
    accent_1LightExtraMid: "#FBEADE",
    accent_tertiary_red: "#E5660B",

    // Text Colors
    textNormal: "#202123",
    textMid: "#2C3236",
    textMidDark: "#121212",
    textMidLight: "#54595F",
    textLight: "#6F747B",
    textLightExtra: "#9B9FA5",
    textWhite: "#FFFFFF",
    textNeutralGray: "#7F7F7F",
    textCharcoal: "#202020",
    textAnswer: "#7B7B7B",
    textNoSelection: "#BF200B",

    // Line Colors
    lineLight: "#F3F5F6",
    lineMid: "#E6E8E9",
    lineDark: "#BDC0C4",
    lineDarkExtra: "#9B9FA5",
    lineLightExtra: "#D6D6D6",
    lineMidExtra: "#F5F5F5",

    // Background Colors
    bgBluish: "#F3F5F7",
    bgGreyish: "#DCDEE0",
    bgBlack_75: "#000000B3",
    bgBlack_60: "#00000099",
    bgBlack_40: "#00000066",
    bgGray: "#E5E5E5",
    bgWhite: "#ffffff",
    bgLightGrey: "#F3F5F7",
    bgChatMessage: "#E6FAF8",
    bgHighlight: "#FDE047",
    bgWhiteSmoke: "#FDFDFD",

    // Border Colors
    borderLightGray: "#EAEAEA",
    borderSilverBlue: "#A4B3C1",

    // Dark Mode Text Colors
    textMain: "#F2F2F2",
    caption: "#A4B3C1",
    inputText: "#D3DFEA",
    paleSkyBlue: "#E8EFF4",
    lightGreen: "#739f50",

    primaryOutlined: "#E8EFF4",

    // @ts-ignore
    //Dark Mode Line Colors
    lineLight: "#2F343C",

    //Dark Mode Background colors
    bgtetriary: "#252A31",
    // @ts-ignore
    secondaryLight: "#21262D",
    bgMain: "#191D23",
};

// Get the current theme based on the app branding
const currentTheme = getCurrentBranding();

// Merge shared colors with the selected theme (fallback to 'default' if the theme is not found)
const customBrandingColors = {
    ...sharedColors,
    // @ts-ignore
    ...(themes[currentTheme] || themes.default),
};

export default customBrandingColors;
