import React from "react";
import CheckIcon from "assets/icons/HeroIcons/CheckIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import InputMark from "assets/icons/HeroIcons/InputMark";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "roleName"
  ) {
    return (
      <div className="flex justify-start min-w-[150px] max-w-[350px] items-center relative">
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="w-full text-sm text-left break-words text-textMid">
            {cellValue?.roleName}
          </p>
        </div>
      </div>
    );
  } else if (
    typeof cellValue === "object" &&
    cellValue === null &&
    columnDef.id === "description"
  ) {
    return (
      <div className="flex justify-start min-w-[150px] max-w-[500px] items-center relative">
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          {cellValue !== null && (
            <p className="w-full text-sm text-left break-words text-textMid">
              {ellipsize(`${cellValue}`, 160)}
            </p>
          )}
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "editable") {
    return (
      <div className="relative flex items-start flex-grow py-1 px-3">
        {cellValue === true ? (
          <CheckIcon fill="#009F88" />
        ) : cellValue === null ? (
          <InputMark fill="#AFB1B3" />
        ) : (
          <XCloseIcon fill="#BF200B" />
        )}
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
