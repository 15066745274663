import { apiClient } from "../config";

async function getAllQualityAssuranceObservations(queryParams: any) {
  const res = await apiClient.get(`/admin/qa`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllQualityAssuranceObservations;
