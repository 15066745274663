import CustomCheckbox from "view/components/CheckBox";

export const InputFields = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  handleAddOrganizationSelector,
  organizations,
  handleLeadOrganizationSearch,
}: any) => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="mb-2">
        <p className="text-sm text-textMidLight font-Overpass dark:text-textMain">
          How would you like to send the notification? You can select multiple
          options.
        </p>
      </div>
      <div>
        <CustomCheckbox
          name="toast"
          checked={values.toast}
          onChange={handleChange}
          label={
            <p className="text-secondaryMid font-Overpass dark:text-textMain">
              Toast notification
            </p>
          }
          labelPlacement="end"
        />
      </div>

      <div>
        <CustomCheckbox
          name="push"
          checked={values.push}
          onChange={handleChange}
          label={
            <p className="text-secondaryMid font-Overpass dark:text-textMain">Push notification</p>
          }
          labelPlacement="end"
        />
      </div>

      <div>
        <CustomCheckbox
          name="email"
          checked={values.email}
          onChange={handleChange}
          label={<p className="text-secondaryMid font-Overpass dark:text-textMain">Email</p>}
          labelPlacement="end"
        />
      </div>
    </div>
  );
};
