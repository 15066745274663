export interface IRoles {
  description: string;
  id: string;
  editable: boolean;
  roleName: string;
  type: string;
  users: number;
}
export interface RolesData {
  roles: IRoles[];
  totalItems: number;
  totalPages: number;
}
export interface IfetchRoles {
  data: RolesData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialRolesData: IfetchRoles = {
  data: {
    roles: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
export default initialRolesData;
