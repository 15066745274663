import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";

interface SecondaryPhonesProps {}

const SecondaryPhones: React.FC<SecondaryPhonesProps> = ({}) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  return (
    <div className="w-1/2">
      {userProfile.secondaryPhoneNumber
        .filter((item: any) => item?.isPrimary === false)
        .map((item: any, index: number) => (
          <Contact key={index} contact={item} />
        ))}
    </div>
  );
};

interface ContactProps {
  contact: any;
}

const Contact: React.FC<ContactProps> = ({ contact }) => {
  return (
    <div className="flex flex-col flex-grow w-full pl-2">
      <div className="flex flex-col relative gap-0.5 py-2 w-full">
        <p className="text-sm text-left capitalize text-textMidLight">
          Secondary Phone
        </p>
        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
          <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left text-secondary">
            {contact.phoneNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

export { SecondaryPhones };
