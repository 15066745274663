export interface IFormDetails {
  name: string;
  programId: number | null;
  formAreaVectorLayerIds: number[];
  formAreaFileName: string;
  formAreaShapeType: string;
  formAreaMode: string;
  formArea: any;
  formFields: any;
  id: any;
}

const formDetails: IFormDetails = {
  name: "",
  id: null,
  programId: null,
  formAreaVectorLayerIds: [],
  formAreaFileName: "",
  formAreaShapeType: "",
  formAreaMode: "",
  formArea: null,
  formFields: {},
};
export interface IfetchSurveyJsFormDatails {
  formDetails: IFormDetails;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IfetchSurveyJsFormDatails = {
  formDetails: formDetails,
  message: "",
  stateIs: "Pending",
};

export { initialState };
