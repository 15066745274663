import { ERROR_LOADING_IMPORT_INFO, GET_IMPORT_INFO, START_LOADING_IMPORT_INFO, STOP_LOADING_IMPORT_INFO } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { openImportInfoModalAction, openImportStatusDetailModalAction } from "store/modals";
import { Toasts } from "view/components/Toasts";

export const fetchImportInfoAction =
  (observationId: number) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_IMPORT_INFO });
    try {
      const { data } = await apiLibrary.Observations.getImportInfo(observationId);
      dispatch({
        type: GET_IMPORT_INFO,
        payload: data,
      });
      dispatch(openImportInfoModalAction(observationId));
      dispatch({ type: ERROR_LOADING_IMPORT_INFO });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch({ type: STOP_LOADING_IMPORT_INFO });
    }
  };
