import { apiClient } from '../config';

async function getAllCommunities(search: any, page: number, appName?: string) {
  const res = await apiClient.get(`/admin/organizations/communities`, {
    params: {
      searchString: search,
      page: page,
      appName: appName,
    },
  });
  return res.data;
}

export default getAllCommunities;
