import { SVGProps } from "react";

interface FileUploadSuccessIcon extends SVGProps<SVGSVGElement> {
    width?: number | string;
    height?: number | string;
}

const FileUploadSuccessIcon: React.FC<FileUploadSuccessIcon> = ({
    width = 256,
    height = 256,
    ...props
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 256 256" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M206.84 92.092V118.6C206.84 119.704 207.736 120.6 208.84 120.6C209.944 120.6 210.84 119.704 210.84 118.6V92.092C210.84 90.988 209.944 90.092 208.84 90.092C207.736 90.092 206.84 90.988 206.84 92.092Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M195.42 216.124C194.476 218.28 193.452 220.316 192.34 222.192C191.98 222.792 191.332 223.156 190.632 223.156C175.292 223.16 84.4479 223.16 59.0479 223.16C58.3479 223.16 57.6959 222.792 57.3359 222.192C56.9719 221.588 56.9559 220.84 57.2839 220.22C57.3039 220.188 57.3199 220.152 57.3359 220.12C69.8839 192.876 70.8399 145.46 70.8399 145.46V122.532C70.8399 121.428 69.9439 120.532 68.8399 120.532C67.7359 120.532 66.8399 121.428 66.8399 122.532V145.4C66.8199 146.424 65.8439 192.016 53.7319 218.38C52.7639 220.252 52.8319 222.464 53.9039 224.248C54.9879 226.052 56.9399 227.16 59.0439 227.16C84.4439 227.16 175.296 227.16 190.632 227.156C192.736 227.156 194.684 226.056 195.768 224.256C195.772 224.252 195.772 224.244 195.776 224.24C196.968 222.228 198.072 220.044 199.084 217.732C199.528 216.72 199.068 215.54 198.056 215.096C197.044 214.652 195.864 215.112 195.42 216.124Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M70.8398 43.796V34.84C70.8398 33.736 71.7358 32.84 72.8398 32.84H153.088C154.192 32.84 155.088 31.944 155.088 30.84C155.088 29.736 154.192 28.84 153.088 28.84C153.088 28.84 89.5718 28.84 72.8398 28.84C69.5278 28.84 66.8398 31.524 66.8398 34.84V43.796C66.8398 44.9 67.7358 45.796 68.8398 45.796C69.9438 45.796 70.8398 44.9 70.8398 43.796Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M163.088 28.84H163.052C162.68 28.84 162.304 28.944 161.976 29.156C161.28 29.6 160.928 30.436 161.1 31.248C164.048 45.332 165.496 59.584 164.068 74.156C163.872 75.992 164.548 77.832 165.896 79.112C167.016 80.176 168.488 80.76 170.008 80.764H170.092C170.38 80.76 170.668 80.736 170.956 80.692C175.964 79.916 180.928 79.46 185.82 79.468H186.112C193.64 79.508 201 80.664 208.104 83.468C208.34 83.56 208.584 83.604 208.828 83.608H208.864C209.424 83.6 209.968 83.356 210.352 82.916C210.908 82.276 210.996 81.352 210.576 80.616C199.292 60.888 183.832 43.772 164.248 29.232C163.904 28.976 163.496 28.844 163.088 28.84Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M210.648 126C187.464 126 168.648 144.82 168.648 168C168.648 191.18 187.464 210 210.648 210C233.828 210 252.648 191.18 252.648 168C252.648 144.82 233.828 126 210.648 126ZM210.648 130C231.62 130 248.648 147.028 248.648 168C248.648 188.972 231.62 206 210.648 206C189.672 206 172.648 188.972 172.648 168C172.648 147.028 189.672 130 210.648 130Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M231.215 152.781C232.257 153.823 232.257 155.511 231.215 156.552L204.549 183.219C203.507 184.261 201.819 184.261 200.777 183.219L187.444 169.886C186.403 168.844 186.403 167.156 187.444 166.115C188.486 165.073 190.174 165.073 191.215 166.115L202.663 177.562L227.444 152.781C228.486 151.74 230.174 151.74 231.215 152.781Z" fill="#0A7BAC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M90.552 91.26L98.752 87.944C101.02 87.028 102.504 84.828 102.504 82.38C102.504 79.936 101.016 77.736 98.752 76.82C96.232 75.8 93.408 74.66 90.56 73.508C78.996 68.836 69.824 59.668 65.148 48.104L61.828 39.896C60.912 37.628 58.712 36.144 56.264 36.144C53.82 36.144 51.62 37.628 50.704 39.896L47.396 48.08C42.724 59.644 33.556 68.816 21.992 73.492C19.132 74.652 16.296 75.796 13.772 76.82C11.504 77.736 10.02 79.936 10.02 82.38C10.02 84.828 11.504 87.028 13.772 87.944C16.3 88.964 19.136 90.112 21.996 91.272C33.56 95.948 42.728 105.12 47.4 116.684L50.704 124.864C51.62 127.132 53.82 128.616 56.264 128.616C58.712 128.616 60.912 127.132 61.828 124.864C62.848 122.348 63.992 119.52 65.144 116.668C69.816 105.104 78.988 95.932 90.552 91.26ZM97.252 84.236L89.052 87.552C76.484 92.632 66.516 102.6 61.436 115.168C60.28 118.02 59.14 120.848 58.12 123.368C57.816 124.12 57.08 124.616 56.268 124.616C55.452 124.616 54.716 124.12 54.412 123.364L51.108 115.188C46.028 102.616 36.064 92.648 23.496 87.564L15.272 84.236C14.516 83.928 14.02 83.196 14.02 82.38C14.02 81.568 14.516 80.832 15.272 80.528L23.492 77.204C36.06 72.12 46.028 62.148 51.104 49.58L54.412 41.396C54.716 40.64 55.452 40.144 56.268 40.144C57.08 40.144 57.816 40.636 58.12 41.392L61.44 49.604C66.524 62.172 76.492 72.14 89.06 77.216L97.252 80.528C98.008 80.832 98.504 81.568 98.504 82.38C98.504 83.196 98.008 83.932 97.252 84.236Z" fill="#2C3236" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M138.404 155.128C140.34 154.348 141.604 152.468 141.604 150.38C141.604 148.296 140.336 146.416 138.404 145.636C138.4 145.632 138.4 145.632 138.396 145.632C128.036 141.444 119.816 133.228 115.624 122.864C115.624 122.86 115.62 122.852 115.616 122.848C114.836 120.912 112.956 119.644 110.872 119.644C108.784 119.648 106.904 120.912 106.124 122.848C101.936 133.212 93.7202 141.432 83.3562 145.624C83.3522 145.628 83.3442 145.628 83.3402 145.632C81.4042 146.412 80.1362 148.292 80.1362 150.38C80.1362 152.468 81.4042 154.348 83.3402 155.132C83.3482 155.136 83.3562 155.136 83.3642 155.14C93.7242 159.332 101.936 167.548 106.124 177.912C106.124 177.916 106.124 177.92 106.128 177.92C106.908 179.856 108.784 181.12 110.872 181.12C112.956 181.12 114.832 179.856 115.616 177.924C115.616 177.916 115.62 177.912 115.62 177.908C119.812 167.54 128.028 159.32 138.396 155.132L138.404 155.128ZM136.904 151.42L136.896 151.424C125.524 156.02 116.508 165.036 111.912 176.408L111.904 176.424C111.736 176.844 111.324 177.12 110.872 177.12C110.416 177.12 110.008 176.844 109.836 176.424L109.832 176.412C105.24 165.048 96.2282 156.032 84.8642 151.432L84.8402 151.424C84.4162 151.252 84.1362 150.84 84.1362 150.38C84.1362 149.924 84.4162 149.512 84.8402 149.34L84.8562 149.332C96.2242 144.736 105.24 135.716 109.832 124.348C110.004 123.924 110.416 123.644 110.872 123.644C111.328 123.644 111.74 123.924 111.908 124.344L111.916 124.364C116.512 135.732 125.532 144.748 136.9 149.34L136.904 149.344C137.328 149.516 137.604 149.924 137.604 150.38C137.604 150.84 137.328 151.248 136.904 151.42Z" fill="#2C3236" />
    </svg>

);

export default FileUploadSuccessIcon;
