import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const DragIcon = ({ fill = "#6F747B", isActive, ...restProps }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    className="dark:fill-lineLight"
  >
    <path
      d="M21.1232 12.5107L18.9609 14.8986C17.9732 15.9906 16.2797 14.5068 17.2928 13.388L17.9673 12.6429H12.1253V18.2973L12.8703 17.6228C13.9715 16.624 15.4851 18.2912 14.381 19.2909L11.9941 21.4521C11.7204 21.6954 11.367 21.8299 11.0008 21.8301C10.6345 21.8303 10.2809 21.6962 10.0069 21.4532L7.619 19.2909C6.51341 18.2899 8.02929 16.6247 9.12964 17.6228L9.8747 18.2973V12.6429H4.03265L4.70723 13.388C5.72406 14.511 4.01675 15.9795 3.0391 14.8986L0.877877 12.5118C0.634643 12.238 0.500203 11.8846 0.5 11.5184C0.499798 11.1522 0.633847 10.7986 0.876778 10.5246L3.0391 8.13669C4.03667 7.03389 5.70654 8.54362 4.70723 9.64731L4.03265 10.3924H9.8747V4.36286L9.12964 5.03742C8.02869 6.03594 6.51464 4.36914 7.619 3.36928L10.0058 1.20811C10.2796 0.964784 10.633 0.830281 10.9992 0.830078C11.3655 0.829876 11.7191 0.963988 11.993 1.20701L14.381 3.36926C15.505 4.38692 13.9485 6.01471 12.8703 5.0374L12.1253 4.36284V10.3924H17.9673L17.2928 9.6473C16.2947 8.54501 17.9623 7.03181 18.9609 8.13668L21.1221 10.5235C21.3653 10.7972 21.4998 11.1507 21.5 11.5169C21.5002 11.8831 21.3661 12.2366 21.1232 12.5107Z"
      // fill="black"
      className="dark:fill-lineLight"
    />
  </svg>
);
export default DragIcon;
