const mapToDropdownValues = (
  data: any,
  valueKey: any,
  textKey: any,
  changedValue: any,
  changedTitle: any
) => {
  return data?.map((item: any) => ({
    [changedTitle]: item[textKey],
    [changedValue]: item[valueKey],
  }));
};

export default mapToDropdownValues;
