import getAllQualityAssuranceObservations from "./getAllQualityAssuranceObservations";
import getManagersDropdown from "./getManagersDropdown";
import attachReviewer from "./attachReviewer";
import addExternalReviewer from "./addExternalReviewer";
import inviteExternalReviewer from "./inviteExternalReviewer";
import adminObservationSectionReview from "./adminObservationSectionReview";
import adminObservationReview from "./adminObservationReview";
import adminObservationRequestConsent from "./adminObservationRequestConsent";
import getExternalReviewers from "./getExternalReviewers";
import getAllQualityAssuranceObservationsForExternalReviewer from "./getAllQualityAssuranceObservationsForExternalReviewer";

const QualityAssurance = {
  getAllQualityAssuranceObservations,
  getManagersDropdown,
  attachReviewer,
  addExternalReviewer,
  inviteExternalReviewer,
  adminObservationSectionReview,
  adminObservationReview,
  adminObservationRequestConsent,
  getAllQualityAssuranceObservationsForExternalReviewer,
  getExternalReviewers,
};

export default QualityAssurance;
