import React, { useEffect, useMemo } from "react";
import { AddPrograms } from "./AddPrograms";
import { AddCommunities } from "./AddCommunities";
import { AddForms } from "./AddForms";
import { AddOrganizations } from "./AddOrganizations";
import { InformationCircleIcon } from "assets/icons/HeroIcons";
import { FileUpload } from "./FileUploader";
import { Dropdown, TextField } from "view/pages/imports/addImport/Components";
import { fetchProgramsAction } from "store/programs";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProgram, IfetchPrograms } from "store/programs/initialState";
import { programsSearchAction } from "store/filters";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchDynamicForms } from "store/dynamicForms/initialState";
import { fetchDynamicFormsAction } from "store/dynamicForms";
import CustomCheckbox from "view/components/CheckBox";
import { resetDynamicForms } from "store/forms/reducer.actions";
import usePermissions from "hooks/usePermissions";

interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  handleOnDropFiles: (files: File[]) => void;
  handleDeleteFile: (index: number) => void;
  handleUpdateFile: (file: File, index: number) => void;
  files: File[];
  acceptedFileTypes: any;
  isSubmitting?: boolean;
}

export const InputFields: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  handleOnDropFiles,
  handleDeleteFile,
  handleUpdateFile,
  files,
  acceptedFileTypes,
  isSubmitting,
}) => {
  const { data } = useSelector<RootState, IfetchPrograms>(
    (state) => state?.allprograms
  );
  const { imports } = usePermissions();

  const forms = useSelector<RootState, IfetchDynamicForms>(
    (state) => state?.allforms
  );

  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );

  // Fetching all programs
  useEffect(() => {
    dispatch(fetchProgramsAction());
  }, [programsFilter.searchString]);

  useEffect(() => {
    if (values.programId) {
      dispatch(resetDynamicForms());
      dispatch(fetchDynamicFormsAction(null, values.programId));
    }
  }, [values.programId]);

  useEffect(() => {
    if (files.length > 0) {
      const filename = files[0].name.split(".")[0];
      setFieldValue("fileName", filename);
    }
  }, [files]);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // Getting program name and Id
  const programOptions = useMemo(() => {
    const options = data?.programs?.map((program: any) => {
      return {
        label: program.programName?.programName,
        value: program.programName?.programId,
      };
    });
    return options;
  }, [data]);

  // Getting form name and Id
  const formOptions = useMemo(() => {
    const options = forms?.data?.dynamicForms?.map((form: any) => {
      return {
        label: form.formName?.formName,
        value: form.formName?.id,
      };
    });
    return options;
  }, [forms]);

  // Handling program search
  const handleSearch = (value: string) => {
    dispatch(programsSearchAction(value));
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="flex flex-col w-full py-1">
        {imports.canCreateUploadImports && (
        <div className="flex items-start gap-2 mb-3 info-message">
          <InformationCircleIcon className="w-5 h-5 fill-accent_3" />
          <div className="flex justify-start items-center flex-grow relative">
            <p className="w-auto text-[14px] text-left text-textMidLight font-Overpass dark:text-caption ">
              This tool allows you to import observation data from a file from
              your computer to the ISN app. Please remember to include a header
              row in your file for accurate column mapping.
            </p>
          </div>
        </div>
        )}

        <div className="mb-3 file-upload">
          <div>
            <TextField
              label="File Name"
              type="text"
              placeholder="Enter File Name"
              name="fileName"
              value={values?.fileName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              required={true}
              error={errors?.fileName}
              touched={touched.fileName}
            />
          </div>
          {imports.canCreateUploadImports && (
            <FileUpload
              handleOnDropFiles={handleOnDropFiles}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              files={files}
              acceptedFileTypes={acceptedFileTypes}
              isSubmitting={isSubmitting}
            />
          )}
        </div>

        <div className="">
          <Dropdown
            label="Program"
            name="programId"
            options={programOptions}
            value={values["programId"]}
            handleChange={(selected: any) => {
              setFieldValue("programId", selected.value);
              setFieldValue("formId", null);
            }}
            handleBlur={handleBlur}
            placeholder="Select program name"
            required={true}
            error={errors?.programId}
            touched={touched?.programId}
            isDisabled={false}
            onSearch={handleSearch}
          />
        </div>

        {values.programId && (
          <div className="">
            <Dropdown
              label="Form"
              name="formId"
              options={formOptions}
              value={values["formId"]}
              handleChange={(selected: any) =>
                setFieldValue("formId", selected.value)
              }
              handleBlur={handleBlur}
              placeholder="Select dynamic form"
              required={true}
              error={errors?.formId}
              touched={touched?.formId}
              isDisabled={false}
            />
          </div>
        )}

        <div className="flex flex-col w-full">
          <TextField
            label="Data Received From"
            type="text"
            placeholder="Data received from"
            name="recievedFrom"
            value={values?.recievedFrom}
            handleChange={handleChange}
            handleBlur={handleBlur}
            required={false}
            error={errors?.recievedFrom}
            touched={touched.recievedFrom}
            message="150 Max Characters"
          />
        </div>

        <div className="flex flex-col w-full">
          <TextField
            label="Comment"
            type="text"
            fieldAs="textarea"
            placeholder="Add a comment..."
            name="comment"
            rows={3}
            value={values?.comment}
            handleChange={handleChange}
            handleBlur={handleBlur}
            required={false}
            error={errors?.comment}
            touched={touched.comment}
            message="6000 Max Characters"
          />
          {/* {!errors?.name && (
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 ">
              <p className="flex-grow w-[608px] text-xs text-left text-textMidLight dark:text-caption ">
                255 max characters
              </p>
            </div>
          )} */}
        </div>

        {values.filterByEntity && (
          <>
            <AddPrograms
              handleAddProgramsOnChange={(options: any) => {
                setFieldValue("programIds", options);
              }}
              values={values}
            />
            <AddForms
              handleAddFormsOnChange={(options: any) => {
                setFieldValue("formIds", options);
              }}
              values={values}
            />
            <AddOrganizations
              handleAddOrganizationsOnChange={(options: any) => {
                setFieldValue("organizationIds", options);
              }}
              values={values}
            />

            <AddCommunities
              handleAddCommunitiesOnChange={(options: any) => {
                setFieldValue("communityIds", options);
              }}
              values={values}
            />
          </>
        )}
      </div>
    </div>
  );
};
