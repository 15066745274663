import { fetchQAProgress } from "./qaProgress/reducer.actions";
import { fetchActiveUsers } from "./activeUsers/reducer.actions";
import { fetchTotalCounts } from "./totalCounts/reducer.actions";
import { fetchObservations } from "./observations/reducer.actions";
import { fetchObservationByLocation } from "./observationByLocation/reducer.actions";
import { fetchNotifications } from "./notifications/reducer.actions";
import { fetchQANotifications } from "./qaNotifications/reducer.actions";
import { fetchHorizontalBarGraph } from "./horizontalBarGraph/reducer.actions";
import { fetchReportNotifications } from "./reportNotifications/reducer.actions";
import { fetchMostObservedObjects } from "./mostObservedObjects/reducer.actions";
import { fetchMostObservedSpecies } from "./mostObservedSpecies/reducer.actions";
import { fetchObservationsByRegion } from "./observationsByRegion/reducer.actions";
import { fetchCumulativeObservations } from "./cumulativeObservations/reducer.actions";
import {
  fetchDashboardConfiguration,
  saveDashboardConfiguration,
} from "./configurations/reducer.actions";
export const dashboardActions = {
  fetchQAProgress,
  fetchActiveUsers,
  fetchTotalCounts,
  fetchObservations,
  fetchObservationByLocation,
  fetchNotifications,
  fetchQANotifications,
  fetchHorizontalBarGraph,
  fetchReportNotifications,
  fetchMostObservedObjects,
  fetchMostObservedSpecies,
  fetchObservationsByRegion,
  fetchCumulativeObservations,
  fetchDashboardConfiguration,
  saveDashboardConfiguration,
};

export const appendCommunityIdsAndOrganizationIdsAndProgramIdsInParams = (
  communityIds: any,
  organizationIds: any,
  programIds: any
) => {
  let updatedParams: any = {};

  if (communityIds) {
    updatedParams.communityIds = communityIds;
  }
  if (organizationIds) {
    updatedParams.organizationIds = organizationIds;
  }

  if (programIds) {
    updatedParams.programIds = programIds;
  }

  return updatedParams;
};
