import { apiClient } from "../config";

async function getAllCategories(queryParams: any) {
  const res = await apiClient.get(`/admin/categories`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllCategories;
