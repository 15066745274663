import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const FileBackupIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width="1472"
    height="256"
    viewBox="0 0 1472 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M769.312 220.012C768.92 220.756 768.52 221.48 768.104 222.18C767.744 222.788 767.092 223.16 766.384 223.16C751.036 223.16 660.204 223.16 634.804 223.16C634.104 223.16 633.452 222.792 633.092 222.192C632.728 221.588 632.712 220.84 633.04 220.22C633.06 220.188 633.076 220.152 633.092 220.12C645.64 192.876 646.596 145.46 646.596 145.46C646.596 145.444 646.596 145.432 646.596 145.42V34.8398C646.596 33.7358 647.492 32.8398 648.596 32.8398H728.46C729.564 32.8398 730.46 31.9438 730.46 30.8398C730.46 29.7358 729.564 28.8398 728.46 28.8398C728.46 28.8398 665.28 28.8398 648.596 28.8398C645.284 28.8398 642.596 31.5238 642.596 34.8398V145.4C642.576 146.424 641.6 192.016 629.488 218.38C628.52 220.252 628.588 222.464 629.66 224.248C630.744 226.052 632.696 227.16 634.8 227.16H766.384C768.504 227.16 770.464 226.044 771.544 224.224L772.852 221.876C773.368 220.896 772.992 219.688 772.016 219.172C771.036 218.66 769.828 219.036 769.312 220.012Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M783.86 83.4679C784.652 83.7799 785.552 83.5599 786.108 82.9159C786.664 82.2759 786.752 81.3519 786.332 80.6159C775.048 60.8879 759.588 43.7719 740.004 29.2319C739.336 28.7359 738.432 28.7079 737.732 29.1559C737.036 29.5999 736.684 30.4359 736.856 31.2479C739.804 45.3319 741.252 59.5839 739.824 74.1559C739.628 75.9919 740.304 77.8319 741.652 79.1119C743.004 80.3959 744.872 80.9799 746.712 80.6919C759.54 78.7079 772.052 78.8039 783.86 83.4679Z"
      fill="#0A7BAC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M664.596 110.128H717.02C718.124 110.128 719.02 109.232 719.02 108.128C719.02 107.024 718.124 106.128 717.02 106.128H664.596C663.492 106.128 662.596 107.024 662.596 108.128C662.596 109.232 663.492 110.128 664.596 110.128Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M664.596 130.128H701.848C702.952 130.128 703.848 129.232 703.848 128.128C703.848 127.024 702.952 126.128 701.848 126.128H664.596C663.492 126.128 662.596 127.024 662.596 128.128C662.596 129.232 663.492 130.128 664.596 130.128Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M664.596 150.128H701.848C702.952 150.128 703.848 149.232 703.848 148.128C703.848 147.024 702.952 146.128 701.848 146.128H664.596C663.492 146.128 662.596 147.024 662.596 148.128C662.596 149.232 663.492 150.128 664.596 150.128Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M664.596 170.128H701.848C702.952 170.128 703.848 169.232 703.848 168.128C703.848 167.024 702.952 166.128 701.848 166.128H664.596C663.492 166.128 662.596 167.024 662.596 168.128C662.596 169.232 663.492 170.128 664.596 170.128Z"
      fill="#6F747B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M829.471 115.016C837.187 124.868 841.791 137.272 841.791 150.744C841.791 182.756 815.803 208.744 783.791 208.744C751.779 208.744 725.791 182.756 725.791 150.744C725.791 118.732 751.779 92.7441 783.791 92.7441C798.087 92.7441 811.179 97.9281 821.295 106.516C822.135 107.228 823.399 107.128 824.115 106.284C824.827 105.444 824.727 104.18 823.883 103.468C813.071 94.2841 799.071 88.7441 783.791 88.7441C749.571 88.7441 721.791 116.524 721.791 150.744C721.791 184.964 749.571 212.744 783.791 212.744C818.007 212.744 845.791 184.964 845.791 150.744C845.791 136.344 840.867 123.08 832.619 112.552C831.939 111.68 830.679 111.528 829.811 112.208C828.943 112.892 828.787 114.148 829.471 115.016Z"
      fill="#2C3236"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M751.416 141.544C756.56 122.352 776.292 110.824 795.488 115.968C814.68 121.108 826.084 140.864 820.944 160.056C815.8 179.248 796.044 190.656 776.852 185.512C774.72 184.94 772.524 186.208 771.952 188.34C771.38 190.476 772.648 192.668 774.78 193.24C798.236 199.524 822.384 185.584 828.668 162.128C834.956 138.672 821.012 114.524 797.556 108.24C774.1 101.956 749.976 116.016 743.692 139.472C743.12 141.604 744.388 143.8 746.52 144.372C748.652 144.944 750.848 143.676 751.416 141.544Z"
      fill="#0A7BAC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M737.712 127.272L743.86 142.044C744.708 144.084 747.052 145.052 749.092 144.2L763.864 138.052C765.9 137.204 766.868 134.86 766.02 132.824C765.168 130.784 762.828 129.82 760.788 130.668L749.708 135.28L745.1 124.2C744.252 122.16 741.908 121.196 739.868 122.044C737.832 122.892 736.864 125.236 737.712 127.272Z"
      fill="#0A7BAC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M781.793 130.744V157.824L768.489 169.224C767.653 169.944 767.557 171.208 768.273 172.044C768.993 172.884 770.257 172.98 771.093 172.264L785.093 160.264C785.537 159.884 785.793 159.328 785.793 158.744V130.744C785.793 129.64 784.897 128.744 783.793 128.744C782.689 128.744 781.793 129.64 781.793 130.744Z"
      fill="#6F747B"
    />
  </svg>
);
export default FileBackupIcon;
