import { apiClient } from "../config";

async function getAllProgramsForForms(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
    module: "dynamic_form",
    entity: "programs",
  };
  
  const res = await apiClient.get(`/admin/users/drop-down`, { params });
  return res.data;
}

export default getAllProgramsForForms;
