import { googleClientId } from "config";
import { loadGapiInsideDOM } from "gapi-script";
import React, { useEffect } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toasts } from "view/components/Toasts";

import apiLibrary from "services/api";
import { loginAction } from "store/auth";
import { convertKeysToSnakeCase } from "utils/caseConvertor";

import GoogleImg from "assets/images/Auth/googeIcon.png";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import Cookies from "universal-cookie";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";

export const GoogleLoginButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    (async () => {
      await loadGapiInsideDOM();
    })();
  });

  const responseGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("profileObj" in response) {
      const accessToken = (response as GoogleLoginResponse).accessToken;
      try {
        // dispatch(setLoadingProgress(getRandomProgress()));
        const res = await apiLibrary.Auth.socialLogin(
          convertKeysToSnakeCase({
            accessToken,
            channel: "google",
          })
        );

        const refreshTokenExpiry = moment().add(365, "days").toDate();

        // Save token and refresh token to cookies with expiry
        cookies.set("token", res.data.accessToken, {
          path: "/",
        });

        cookies.set("refreshToken", res.data.refreshToken, {
          path: "/",
          expires: refreshTokenExpiry,
        });

        Toasts.success(res.message);
        dispatch(loginAction(res.data));
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      } finally {
        dispatch(setLoadingProgress(100));
      }
    } else {
      console.error("Google login failed:", response);
    }
  };
  return (
    <GoogleLogin
      clientId={googleClientId}
      redirectUri={process.env.REACT_APP_GOOGLE_REDIRECT_URL}
      render={(renderProps: any) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          type="button"
          className="flex items-center justify-center flex-grow relative h-10 w-[86px] rounded-3xl border-2 bg-bgWhite dark:bg-secondaryLight border-textLink hover:bg-primaryExtraLight dark:hover:bg-primary dark:border-lineLight dark:hover:border-textLink"
          aria-label="Google"
        >
          <img src={GoogleImg} alt="" />
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};
