import PlusCircleIcon from "assets/icons/HeroIcons/PlusCircleIcon";
import React from "react";
import { getBrandPlaceholder } from "utils/customBranding";

interface UserProps {
  userData: any;
  addAdmin: any;
  setSelectedUser: any;
}

export const Users: React.FC<UserProps> = ({
  userData,
  addAdmin,
  setSelectedUser,
}) => {
  return (
    <div className="w-full user-list">
      <div className="flex justify-between items-center gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
        <div
          onClick={() => setSelectedUser(userData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          <img
            src={
              userData?.personImage?.thumb
                ? userData?.personImage?.thumb
                : getBrandPlaceholder("userProfile")
            }
            alt=""
            height="32px"
            width="32px"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${userData.name}`}</p>
        </div>
        <button type="button" onClick={() => addAdmin(userData)}>
          <PlusCircleIcon />
        </button>
      </div>
    </div>
  );
};
