import { Ifetch } from "../initialState";

export interface IHorizontalBarGraph {
  name: string;
  percent: number;
}

const HorizontalBarGraph = {
  name: "",
  percent: 0,
};

export interface IfetchHorizontalBarGraph extends Ifetch {
  data: IHorizontalBarGraph[];
}

const horizontalBarGraphInitialState: IfetchHorizontalBarGraph = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default horizontalBarGraphInitialState;
