import {
  GET_DATA_ACCESS_POLICY,
  RESET_DATA_ACCESS_POLICY,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchDataAccessPolicyAction =
  (programId: any) => async (dispatch: Dispatch) => {
    try {
      const { data } =
        await apiLibrary.DataAccessPolicy.getDataAccessPolicy(programId);
   
      dispatch({
        type: GET_DATA_ACCESS_POLICY,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };
export const resetDataAccessPolicyAction = () => {
  return {
    type: RESET_DATA_ACCESS_POLICY,
  };
};
