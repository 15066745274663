import {
  ERROR_LOADING_JOIN_REQUESTS,
  GET_ALL_JOIN_REQUESTS,
  RESET_DATA,
  START_LOADING_JOIN_REQUESTS,
  STOP_LOADING_JOIN_REQUESTS,
} from "./action-types";
import initialState, { IJoinRequests } from "./initialState";

export const joinRequestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_JOIN_REQUESTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_JOIN_REQUESTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_JOIN_REQUESTS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_JOIN_REQUESTS:
      const requests = action.payload.requests?.map((item: IJoinRequests) => {
        const {
          id,
          name,
          image,
          lastActive,
          observations,
          status,
          memberSince,
          requestedOn,
          declinedOn,
        } = item;
        const data = {
          name: {
            id: id,
            personName: name,
            image: image,
            observations: observations,
          },
          lastActive: lastActive,
          status: status,
          memberSince: memberSince,
          requestedOn: requestedOn,
          requests: id,
          declinedOn: declinedOn,
        };
        return data;
      });

      return {
        ...state,
        data: {
          requests: requests,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
