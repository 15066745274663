import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { observationsSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Observations.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { filterKeys } from "Components/Users/Table";
import { qualityAssuranceObservationsSortingAction } from "store/filters/reducer.actions";

export const KEYS_TO_BE_REMOVED = ["id"];

const generatingDirectoryActions = (columns: any, rowsData: any) => {
  const fileDownload = async () => {
    if (rowsData.length === 0) {
      Toasts.error(
        "Please select at least one quality assurance observation from the table"
      );
      return;
    }

    try {
      const res = await apiLibrary.file.fileDownload(
        "qa",
        columns,
        rowsData?.map((item: any) => {
          return item.observationCode.id;
        })
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `qualityassuranceobservations_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return [
    {
      label: "Export Details",
      onClick: () => fileDownload(),
      divider: false,
      isVisible: true,
    },
  ];
};

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
  isExternalReviewer,
}) => {
  const dispatch = useDispatch();
  const { qualityAssuranceObservationsActions } = useActions();
  const DirectoryActions = () => {
    const filteredKeys = filterKeys(columnVisibility, columnOrder);
    return generatingDirectoryActions(
      filteredKeys,
      getAllRowsData(table, columnOrder, columnVisibility, columns)
    );
  };

  const getQualityAssuranceObservationsActions = (data: any) => {
    const modalData = {
      status: data?.status,
      id: data?.id,
      programId: data?.programName?.id,
      requireQa: data?.requireQa
    };

    return qualityAssuranceObservationsActions(modalData);
  };

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(qualityAssuranceObservationsSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(qualityAssuranceObservationsSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(qualityAssuranceObservationsSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          DirectoryActions={DirectoryActions()}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
          isExternalReviewer={isExternalReviewer}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
          getObservationActions={getQualityAssuranceObservationsActions}
          isExternalReviewer={isExternalReviewer}
        />
      </table>
    </div>
  );
};
