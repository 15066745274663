import { Formik } from "formik";
import * as Yup from "yup";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import CustomCheckbox from "view/components/CheckBox";
import splitString from "utils/splitString";
// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import {
  openAllLayersListingScreen,
  resetMap,
  updateDrawShapeCoordinates,
  updateSelectedLayersItems,
  updateSelectedShape,
} from "store/geography";
import { useDispatch } from "react-redux";
import { shapeTypes } from "store/geography/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  removeAllDrawShapes,
  removeMarkerOfPickOnMapScreen,
  showSnapingControl,
} from "store/geography/reducer.actions";
import {
  EntityType,
  useFetchGlobalAndCommunityVectorLayers,
} from "Components/Geography/SideBar/hooks/useFetchGlobalAndCommunityVectorLayers";
import ReferenceVectorLayersList from "../../ReferenceVectorLayersList";
import usePermissions from "hooks/usePermissions";
import MarkerGroupsDropDown from "../../MarkerGroupsDropDown";
import MarkerStylesSelector from "../../MarkerStylesSelector";

const initialValues = {
  description: "",
  mapData: {
    lng: 0,
    lat: 0,
  },
  vectorLayers: [],
  name: "",

  type: "point",
  markerStyleId: null,
  markerStyleVersionId: null,
  markerGroupId: null,
};

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, "255 max characters")
    .required("Description is required"),
  mapData: Yup.object().shape({
    lng: Yup.number()
      // .min(-180, "Longitude must be at least -180")
      // .max(180, "Longitude cannot be more than 180")
      .required("Longitude is required"),
    lat: Yup.number()
      // .min(-90, "Latitude must be at least -90")
      // .max(90, "Latitude cannot be more than 90")
      .required("Latitude is required"),
  }),
  markerStyleVersionId: Yup.number()
    .nullable(),
  vectorLayers: Yup.array(),
  name: Yup.string().required("Name is required"),
  markerStyleId: Yup.number()
    .nullable()
    .test("markerStyleId", "Marker Style is required ", function (value) {
      // const { shapeType } = this.parent;

      // if (shapeType !== "point") {
      //   return true;
      // }

      return value != null;
    }),
  markerGroupId: Yup.mixed()
    .nullable()
    .test("markerGroupId", "Marker Group is required ", function (value) {
      // const { shapeType } = this.parent;

      // if (shapeType !== "point") {
      //   return true;
      // }

      return value != null;
    }),
});

interface DrawPointsInterface {
  height: string;
}

export const DrawPoints = ({ height }: DrawPointsInterface) => {
  const [page, setPage] = useState({
    vector: 1,
    publicVector: 1,
  });
  const { communities } = usePermissions();
  const [entityType, setEntityType] = useState<EntityType>(null);
  const [currentOpenToggle, setCurrentOpenToggle] = useState<
    "vector" | "publicVector" | null
  >(null);
  const {
    communityLayersLoading,
    communityVectorLayers,
    globalLayersLoading,
    globalVectorLayers,
  } = useFetchGlobalAndCommunityVectorLayers({
    vectorLayerType: "point",
    entityType,
    currentOpenToggle,
    setCurrentOpenToggle,
    page,
  });

  const { communityId } = useParams();
  const dispatch = useDispatch();
  const { shapeCoordinates } = useSelector(
    (state: RootState) => state.geography
  );
  const [coordinates, setCoordinates] = useState({
    lng: "",
    lat: "",
  });

  useEffect(() => {
    // reset selected layers
    dispatch(resetMap(true));

    return () => {
      dispatch(showSnapingControl(false));
    };
  }, []);

  useEffect(() => {
    if (shapeCoordinates) {
      const coordinates = shapeCoordinates;
      if (!coordinates) return;

      setCoordinates({
        lng: coordinates[0] ?? "",
        lat: coordinates[1] ?? "",
      });
    }
  }, [shapeCoordinates]);

  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    const payload = { ...values };

    payload.mapData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [values.mapData.lng, values.mapData.lat],
            type: "Point",
          },
        },
      ],
    };
    payload.markerGroupId = values.markerGroupId.value;
    delete payload.vectorLayers;

    if (!values.lat || values.lng) {
      setFieldError("mapData", "Pointer ");
    }

    try {
      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, payload);
        Toasts.success(`Point created successfully`);
        // navigate to all layers screen
        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(true);
    }
  };
  const handleClickOnPickOnMapBtn = () => {
    dispatch(removeMarkerOfPickOnMapScreen(true));
    dispatch(removeAllDrawShapes({ remove: true, variant: "local_draw" }));

    dispatch(updateSelectedShape(shapeTypes.POINT));
  };
  const handleOnChangeLngLat = (e: any) => {
    const value = e.target.value ? Number(e.target.value) : "";
    console.log({ value, original: e.target.value });
    const name = e.target.name;
    setCoordinates({ ...coordinates, [name]: value });
  };
  const handleOnKeyUp = (e: any) => {
    if (coordinates.lat && coordinates.lng) {
      dispatch(updateDrawShapeCoordinates([coordinates.lng, coordinates.lat]));
    }
  };
  const handleSelectVectorLayer = (
    e: any,
    item: any,
    values: any,
    setFieldValue: any
  ) => {
    const layerId = e.target.value;
    const isLayerIdExist = values.vectorLayers.find(
      (layer: any) => layer === parseInt(layerId)
    );

    setFieldValue(
      "vectorLayers",
      isLayerIdExist
        ? values.vectorLayers.filter(
          (layer: any) => layer !== parseInt(layerId)
        )
        : [...values.vectorLayers, parseInt(layerId)]
    );
  };
  const handleClickOnTogglerBtn = (type: EntityType) => {
    if (currentOpenToggle !== type) setCurrentOpenToggle(type);
  };
  const handleScrollEnd = (type: EntityType) => {
    const totalPages =
      type === "vector"
        ? communityVectorLayers.totalPages
        : globalVectorLayers.totalPages;
    if (page && type && totalPages >= page[type] + 1) {
      setPage((prev: any) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));

      if (entityType !== type) {
        setEntityType(type);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => {
        useEffect(() => {
          if (shapeCoordinates) {
            const coordinates = shapeCoordinates;
            if (!coordinates) return;
            setFieldValue("mapData.lng", coordinates[0]);
            setFieldValue("mapData.lat", coordinates[1]);
          }
        }, [shapeCoordinates]);

        return (
          <form onSubmit={handleSubmit}>
            <div
              className="overflow-x-hidden overflow-y-auto"
              style={{ height }}
            >
              <div className="flex justify-between mt-1">
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                  <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[408px] text-sm text-left text-textMidLight dark:text-textMain">
                      Drag the map around to find the location you want to
                      choose. Then click a button to fill up the coordinates
                      fields with the coordinates of where the marker is at that
                      moment.
                    </p>
                  </div>
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-[95%] gap-2">
                    <div className="flex flex-col items-start justify-start flex-grow w-[100px]">
                      <TextInput
                        label="Latitude*"
                        type="number"
                        placeholder="Enter latitude"
                        name="lat"
                        handleChange={(e: any) => {
                          const value = Number(e.target.value);
                          if (!isNaN(value) && value >= -90 && value <= 90) {
                            setFieldValue("mapData.lat", value);
                            handleOnChangeLngLat(e);
                          }
                          // Additional handling for invalid values
                        }}
                        // @ts-ignore
                        onKeyUp={handleOnKeyUp}
                        handleBlur={handleBlur}
                        // value={values.mapData.lat}
                        value={coordinates?.lat}
                        error={errors?.mapData?.lat}
                        touched={touched?.mapData}
                        // min={-90}
                        // max={90}
                        step="any"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start flex-grow w-[100px]">
                      <TextInput
                        label="Longitude*"
                        type="number"
                        placeholder="Enter longitude "
                        name="lng"
                        handleChange={(e: any) => {
                          setFieldValue("mapData.lng", Number(e.target.value));
                          // handleChange(e);
                          handleOnChangeLngLat(e);
                        }}
                        handleBlur={handleBlur}
                        // @ts-ignore
                        onKeyUp={handleOnKeyUp}
                        // value={values?.mapData?.lng}
                        value={coordinates?.lng}
                        // @ts-ignore
                        error={errors?.mapData?.lng}
                        touched={touched?.mapData}
                        // min={-180}
                        // max={180}
                        step="any"
                      />
                    </div>
                    <Button
                      type="button"
                      text="Pick on Map"
                      disabled={isSubmitting}
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2 mt-5"
                      width="[190px]"
                      height="10"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClickOnPickOnMapBtn}
                    />
                  </div>
                </div>
              </div>

              <p className="text-[15px] text-textMidLight italic my-4 dark:text-textMain ">
                Turn on the vector layers to see them on the map. Click the
                snapping icon to enabel snapping for this layer.
              </p>
              <div>
                <ReferenceVectorLayersList
                  selectLayersHandler={(e: any, item: any) =>
                    handleSelectVectorLayer(e, item, values, setFieldValue)
                  }
                  layerIds={values.vectorLayers}
                  layers={globalVectorLayers.vector}
                  heading={"Snapping to Global layers"}
                  message="Create vector layers first from the Geography"
                  loading={globalLayersLoading}
                  type="publicVector"
                  newItemType="vector"
                  layerRenderingMode="draw"
                  handleScrollEnd={handleScrollEnd}
                  doShowDataList={currentOpenToggle === "publicVector"}
                  handleClickOnTogglerBtn={handleClickOnTogglerBtn}
                />
                <ReferenceVectorLayersList
                  selectLayersHandler={(e: any, item: any) =>
                    handleSelectVectorLayer(e, item, values, setFieldValue)
                  }
                  layerIds={values.vectorLayers}
                  layers={communityVectorLayers.vector}
                  heading={"Snapping to Community layers"}
                  message="Create vector layers first from the Listing section"
                  loading={communityLayersLoading}
                  type="vector"
                  newItemType="vector"
                  layerRenderingMode="draw"
                  handleScrollEnd={handleScrollEnd}
                  doShowDataList={currentOpenToggle === "vector"}
                  handleClickOnTogglerBtn={handleClickOnTogglerBtn}
                />

                {errors.vectorLayers && (
                  <p className="flex-grow text-xs text-left text-accent_1Dark">
                    {errors.vectorLayers}
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label="Point Name*"
                  type="text"
                  placeholder="Point name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors?.name}
                  touched={touched?.name}
                />
              </div>

              <div className="w-full">
                <TextInput
                  label="Description*"
                  type="text"
                  rows={3}
                  fieldAs="textarea"
                  placeholder="Description"
                  name="description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  error={errors?.description}
                  touched={touched?.description}
                />

                {!errors?.description && (
                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                    255 max characters
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full mb-2">
                <MarkerGroupsDropDown
                  error={errors?.markerGroupId}
                  handleGroupSelectorOnChange={(Option: any) => {
                    setFieldValue("markerGroupId", Option);
                    setFieldValue("markerStyleId", null);
                    setFieldValue("markerStyleVersionId", null);

                  }}
                  title={"Marker Group"}
                  value={values?.markerGroupId}
                  communityId={communityId}
                />
                <MarkerStylesSelector
                  communityId={communityId}
                  groupId={values?.markerGroupId}
                  error={errors?.markerStyleId}
                  handleStyleSelectorOnChange={(Option: any) => {
                    setFieldValue("markerStyleId", Option.id);
                    setFieldValue("markerStyleVersionId", Option.markerStyleVersionId);
                  }}
                  title={"Marker Style"}
                  value={values?.markerStyleId}
                />
              </div>
            </div>

            <div className="flex justify-between gap-2 pt-2 pb-2">
              <Button
                disabled={isSubmitting}
                type="reset"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.t%]"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={() => dispatch(openAllLayersListingScreen())}
              />
              <Button
                type="submit"
                text="Save"
                disabled={
                  isSubmitting || !communities.canCreateGeographyCommunities
                }
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
