import React, { useCallback } from "react";
import { UserProfileState } from "store/userProfile/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { FieldInputProps } from "formik";
import { PrimaryEmailToggler } from "./PrimaryEmailToggler";
import { EmailInputField } from "./EmailInputField";
import { EmailVerificationIcon } from "./EmailVerificationIcon";
import { DeleteEmailBtn } from "./DeleteEmailBtn";
import { EmailVerificationBtn } from "./EmailVerificationBtn";
interface EmailCreatorProps {
  remove: any;
  currentEmailIndex: number;
  contact: any;
  handleChange: FieldInputProps<any>["onChange"];
  handleBlur: FieldInputProps<any>["onBlur"];
  touched?: any;
  errors?: any;
  values?: any;
  refreshNewData: (data: any) => void;
}

const EmailCreator: React.FC<EmailCreatorProps> = ({
  remove,
  currentEmailIndex,
  contact,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
  refreshNewData,
}) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const emailValue = values.secondaryEmail[currentEmailIndex].email;
  const isEmailExist = userProfile.secondaryEmail.find(
    (item: any) => item.email === emailValue
  );

  const fieldErrors = errors.secondaryEmail?.[currentEmailIndex];
  const fieldTouched = touched.secondaryEmail?.[currentEmailIndex];

  const checkEmailValidationError = useCallback(() => {
    if (fieldErrors && fieldTouched) {
      return fieldErrors.email;
    }
    return null;
  }, [fieldErrors, fieldTouched]);

  return (
      <div className="w-full">
        {contact.isPrimary ? (
          <div className="p-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <p className="text-md font-semibold text-secondary dark:text-caption">
                  {contact.email}
                </p>
                <span className="mx-2 dark:text-caption">-</span>
                <p className="text-sm font-semibold text-[#1B7F38]">Primary</p>
              </div>

              {!userProfile.virtual  && (
                <DeleteEmailBtn
                  isEmailExist={isEmailExist}
                  currentEmailIndex={currentEmailIndex}
                  remove={remove}
                  refreshNewData={refreshNewData}
                />
              )}
            </div>

            <p className="text-sm text-[#636c76] dark:text-caption">
              This email will be used for account-related notifications and can
              also be used for password resets.
            </p>
          </div>
        ) : (
          <div className="border-t border-lineMid dark:border-lineLight p-4">
            {contact.isVerified ? (
              <div className="flex justify-between items-center">
                <p className="text-md font-semibold text-secondary dark:text-caption">
                  {contact.email}
                </p>

                {!contact.isPrimary && !userProfile.virtual && (
                  <DeleteEmailBtn
                    isEmailExist={isEmailExist}
                    currentEmailIndex={currentEmailIndex}
                    remove={remove}
                    refreshNewData={refreshNewData}
                  />
                )}
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-md font-semibold text-secondary dark:text-caption">
                    {contact.email}
                  </p>
                  {!contact.isPrimary && !userProfile.virtual && (
                    <DeleteEmailBtn
                      isEmailExist={isEmailExist}
                      currentEmailIndex={currentEmailIndex}
                      remove={remove}
                      refreshNewData={refreshNewData}
                    />
                  )}
                </div>

                <ul className="list-disc px-6">
                  <div className=" flex items-center">
                    <li className="text-sm font-semibold text-[#9A6700]">
                      Unverified
                    </li>
                    {!userProfile.virtual && (
                      <EmailVerificationBtn
                        errMsg={checkEmailValidationError()}
                        contact={contact}
                      />
                    )}
                  </div>
                </ul>
              </>
            )}
          </div>
        )}
      </div>
  );
};

export default EmailCreator;
