import { apiClient } from "../config";

async function getallUsers(searchString: string) {
  let url = "/admin/communities/user-members";

  // Append the search_string query parameter if the search string is not empty
  if (searchString.trim() !== "") {
    url += `?search_string=${encodeURIComponent(searchString)}`;
  }
  const res = await apiClient.get(url);
  return res.data;
}

export default getallUsers;
