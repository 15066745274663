import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "store";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useDispatch } from "react-redux";
import { openEditProgramTagsModalAction } from "store/modals/reducer.actions";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import { IProgram } from "store/programProfile/initialState";
import Tooltip from "@mui/material/Tooltip";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import { useNavigate } from "react-router-dom";
export const ProgramProtocols = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const navigate = useNavigate();
  const { programs, tags } = usePermissions();
  const dispatch = useDispatch();
  const handleClick = (id: number) => {
    navigate(`/protocols/${id}/profile`);
  };
  return (
    <div className=" bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight mt-3 rounded-lg shadow-md hover:shadow-2xl px-3  mb-[16px]">
      <div className="flex flex-col  w-full justify-start items-start py-4">
        <div className="flex flex-col justify-start items-start w-full">
          <div className="relative flex items-center  justify-between w-full ">
            <p className="pb-2 text-base font-semibold text-secondaryMid dark:text-textMain ml-3">
              Connected Protocol
            </p>
          </div>
          <div className="flex flex-wrap justify-start items-center mb-3 cursor-pointer">
            <p
              className="text-secondaryMid text-sm font-medium ml-3"
              onClick={() => handleClick(programProfile.protocol.protocolId)}
            >
              {programProfile.protocol?.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
