import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  fetchCommunityProfileAction,
  resetCommunityProfileAction,
} from "store/communityProfile/reducer.actions";
import {
  CommunityKpis,
  CommunityTags,
  CommunityProfileMap,
  Profile,
  RecentActiveMember,
  MissionStatement,
} from "Components/CommunityProfile/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import usePermissions from "hooks/usePermissions";

export const CommunityProfile = () => {
  const { communityId } = useParams();
  const { tags } = usePermissions();
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const communities = usePermissions();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  useEffect(() => {
    dispatch(fetchCommunityProfileAction(communityId ? communityId : ""));
    return () => {
      dispatch(resetCommunityProfileAction());
    };
  }, []);

  const dynamicCommunityLinks = [
    { path: `/community/list`, label: `Communities` },
    {
      path: `/community/${communityProfile.id}/profile`,
      label: communityProfile?.name,
    },
  ];

  return (
    <>
      <div className="w-full">
        <div className="mb-2 pt-4">
          <DirectoryBreadCrumb links={dynamicCommunityLinks} />
        </div>
        <div className="relative flex flex-col items-start justify-start w-full">
          <div className="w-full pb-6 bg-bgWhite dark:bg-secondaryLight dark:border-l dark:shadow-xl dark:border-lineLight">
            <Profile />
            <div className="w-full bg-bgWhite dark:bg-secondaryLight">
              <RecentActiveMember />
            </div>
          </div>
          <div className="w-full flex  gap-4   py-6 ">
            <div className={`w-[50%]`}>
              <MissionStatement />
              <CommunityKpis />
            </div>
            <div className=" w-[50%]">
              <div className="h-[385px] rounded-lg overflow-hidden">
                <CommunityProfileMap />
              </div>
                <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 py-2 md:flex-col xl:flex-row">
                  <CommunityTags />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
