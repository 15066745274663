import apiClient from "services/apiClient";

interface observationTimeZoneBody {
    useLocalTimeZone: boolean;
}

async function updateObservationTimeZoneToggle(observationId: number, body: observationTimeZoneBody) {
    const url = `/admin/observations/${observationId}/update-time-zone-toggle`;
    const res = await apiClient.put(url, body);
    return res;
}

export default updateObservationTimeZoneToggle;
