export const userDirectoryColumnsOrder = [
  "select",
  "personId",
  "name",
  "lastActive",
  "status",
  "programs",
  "forms",
  "communities",
  "observations",
  "memberSince",
];
export const communityDirectoryOrder = [
  "communityName",
  "organizations",
  "type",
  "programs",
  "members",
  "dateAdded",
];
export const organizationDirectoryOrder = [
  "name",
  "tags",
  "programs",
  "communities",
  "members",
  "dateAdded",
];

export const formsDirectoryOrder = [
  "formName",
  "program",
  "createdBy",
  "archivedBy",
  "protocol",
  "numberOfObservations",
  "dateAdded",
  "dateUpdated",
  "dateArchived",
];

export const protocolsDirectoryOrder = [
  "archivedBy",
  "createdBy",
  "dateAdded",
  "dateUpdated",
  "formName",
  "program",
  "protocol",
  "numberOfObservations",
  "dateArchived",
];
