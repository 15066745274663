import { apiClient } from "../config";
async function getAllExternalEndDataViewersDropdown(
  searchQuery?: string,
  programId?: any
) {
  const params = {
    searchString: searchQuery,
    programId: programId,
    limit: 100,
  };

  const response = await apiClient.get(
    `/admin/programs/external-end-data-viewers`,
    {
      params,
    }
  );
  return response.data;
}
export default getAllExternalEndDataViewersDropdown;
