import React from "react";
import KpiCard from "./KpiCard";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProgram } from "store/programProfile/initialState";
import { useNavigate } from "react-router-dom";
import MembersKpiIcon from "assets/icons/HeroIcons/MembersKpiIcon";
import CommunityKpiIcon from "assets/icons/HeroIcons/CommunityKpiIcon";
import OfficeBuildingKpiIcon from "assets/icons/HeroIcons/OfficeBuildingKpiIcon";
import DocumentsListIcon from "assets/icons/HeroIcons/DocumentsListIcon";
import CollectionKpiIcon from "assets/icons/HeroIcons/CollectionKpiIcon";
import BinocularsKpiIcon from "assets/icons/HeroIcons/BinocularsKpiIcon";


export const ProgramKpis = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );

  const navigate = useNavigate();


  return (
    <div className="flex flex-col items-start justify-start flex-grow gap-4">
      <div
        className={`grid w-full gap-3 justify-evenly lg:grid-cols-3 xl:grid-cols-2`}
      >
        <KpiCard
          icon={<BinocularsKpiIcon fill="#2C3236" />}
          title="Total Observations"
          count={programProfile.observationsCount}
          redirectLinkAction={() => { navigate(`/programs/${programProfile.id}/observations`) }}
        />

        <KpiCard
          icon={<CollectionKpiIcon fill="#2C3236" />}
          title="Species Observed"
          count={programProfile.speciesObservedCount}
          redirectLinkAction={() => {
            const state = { speciesObserved: true };
            navigate(`/programs/${programProfile.id}/observations`, { state })
          }}

        />
        <KpiCard
          icon={<CommunityKpiIcon fill="#2C3236" />}
          title="Communities"
          count={programProfile.communitiesCount}
          redirectLinkAction={() => { navigate(`/programs/${programProfile.id}/communities`) }}

        />
        <KpiCard
          icon={<OfficeBuildingKpiIcon fill="#2C3236" />}
          title="Organizations"
          count={programProfile.organizationsCount}
          redirectLinkAction={() => { navigate(`/programs/${programProfile.id}/organizations`) }}

        />
        <KpiCard
          icon={<MembersKpiIcon />}
          title="Members"
          count={programProfile.membersCount}
          redirectLinkAction={() => { navigate(`/programs/${programProfile.id}/users`) }}
        />
        <KpiCard
          icon={<DocumentsListIcon fill="#2C3236" />}
          title="Forms"
          count={programProfile.formsCount}
          redirectLinkAction={() => { navigate(`/programs/${programProfile.id}/forms`) }}

        />
      </div>
    </div>
  );
};
