import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { reportsCreatedByAction } from "store/filters/reducer.actions";

export const SelectedCreatedBy: React.FC = () => {
  const dispatch = useDispatch();
  const { reportsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const resetFilter = (person: any) => {
    dispatch(reportsCreatedByAction(person));
  };

  if (reportsFilter?.createdByIds?.applied) {
    return reportsFilter?.createdByIds?.value.map(
      (item: any, index: number) => (
 <div
        key={index}
        className="flex justify-between px-2 max-w-[900px] items-center gap-1 flex-grow-0 flex-shrink-0 relative  
 rounded-lg bg-primary/[0.08]"
      >
        <div className="flex justify-center items-center relative px-1 pt-1.5 pb-2">
          <p className="break-all w-full flex-shrink-0 text-sm text-start text-textMid dark:text-inputText capitalize">
            {item.name}
          </p>
        </div>

          <ClearFilters handleClick={() => resetFilter(item)} />
        </div>
      )
    );
  } else {
    return null;
  }
};
