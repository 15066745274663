import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const ProgramsIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <g filter="url(#filter0_d_6535_1108470)">
      <circle cx="25" cy="24" r="19" fill="#D6FFF9" />
    </g>
    <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_12905_15352)">
      <path
        d="M28.709 21.0202C28.8757 21.1868 29.0701 21.2738 29.2923 21.281C29.5145 21.2882 29.709 21.2082 29.8757 21.041L31.0423 19.8743C31.209 19.7077 31.2923 19.5099 31.2923 19.281C31.2923 19.0521 31.209 18.8541 31.0423 18.6868C30.8895 18.5341 30.6951 18.4577 30.459 18.4577C30.2229 18.4577 30.0284 18.5341 29.8757 18.6868L28.709 19.8535C28.5562 20.0063 28.4798 20.1974 28.4798 20.4268C28.4798 20.6563 28.5562 20.8541 28.709 21.0202ZM17.5007 32.3327C17.0423 32.3327 16.6498 32.1694 16.3232 31.8427C15.9965 31.516 15.8334 31.1238 15.834 30.666V18.9993C15.834 18.541 15.9973 18.1485 16.324 17.8218C16.6507 17.4952 17.0429 17.3321 17.5007 17.3327H24.1673V23.9993C24.1673 24.4577 24.3307 24.8502 24.6573 25.1769C24.984 25.5035 25.3762 25.6666 25.834 25.666H30.834V30.666C30.834 31.1244 30.6707 31.5169 30.344 31.8435C30.0173 32.1702 29.6251 32.3332 29.1673 32.3327H17.5007ZM30.0007 23.9993C29.584 23.9993 29.1743 23.9368 28.7715 23.8118C28.3687 23.6868 27.9937 23.5063 27.6465 23.2702L27.334 23.5619C27.1673 23.7146 26.9693 23.791 26.7398 23.791C26.5104 23.791 26.3195 23.7146 26.1673 23.5619C26.0145 23.4091 25.9382 23.2146 25.9382 22.9785C25.9382 22.7424 26.0145 22.548 26.1673 22.3952L26.5007 22.0619C26.2784 21.7285 26.1118 21.3743 26.0007 20.9993C25.8895 20.6243 25.834 20.2355 25.834 19.8327C25.834 18.6799 26.2404 17.6974 27.0532 16.8852C27.8659 16.073 28.8484 15.6666 30.0007 15.666H34.1673V19.8327C34.1673 20.9855 33.7609 21.9682 32.9482 22.781C32.1354 23.5938 31.1529 23.9999 30.0007 23.9993ZM19.1673 28.9994H20.834V23.166H19.1673V28.9994ZM22.5007 28.9994H24.1673V20.666H22.5007V28.9994ZM25.834 28.9994H27.5007V25.666H25.834V28.9994Z"
        fill="#009F88"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6535_1108470"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6535_1108470"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6535_1108470"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default ProgramsIcon;
