import { ClearFilters } from "Components/Users/ClearFilters";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { notificationsMemberAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

export const SelectedMembers: React.FC = () => {
  const dispatch = useDispatch();
  const { notificationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const resetFilter = (person: any) => {
    dispatch(notificationsMemberAction(person));
  };

  if (notificationsFilter.memberIds?.applied) {
    return notificationsFilter.memberIds.value.map(
      (item: any, index: number) => (
        <div
          key={index}
          className="flex justify-between px-2 max-w-[900px] items-center gap-1 relative rounded-lg bg-primary/[0.08]"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">
              {item.name}
            </p>
          </div>
          <ClearFilters handleClick={() => resetFilter(item)} />
        </div>
      )
    );
  } else {
    return null;
  }
};
