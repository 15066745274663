import apiClient from "services/apiClient";

interface IParams {
  programIds?: number;
  formIds?: number;
  organizationIds?: number;
  communityIds?: number;
  sortBy?: string;
  sortType?: string;
  page?: number;
  limit?: number;
}

async function getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds(
  id: any,
  paramsData: any
) {
  const params: IParams = {
    ...paramsData,
    // limit: 100000,
  };
  const res = await apiClient.get(`/admin/geographies/reports-geographies`, {
    params,
  });
  return res.data;
}

export default getGeographiesByReportIdCommunityFormsOrganizationAndProgramIds;
