import { SET_LOADING_PROGRESS } from "./action-types";


const initialState = {
  progress: 0,
};

export const loadingBarReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_LOADING_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
};


