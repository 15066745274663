import React from "react";
// Components


import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  notificationsRecipientsResetFilterExceptProgramsAction,
  protocolsResetFilterExceptFormsAction,
  protocolsResetFilterExceptProgramsAction,
  protocolsResetFiltersAction,
} from "store/filters/reducer.actions";
import { notificationRecipientsDirectoryFilterState, protocolsDirectoryFilterState } from "store/filters/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { DateRecieved } from "./Components/DateRecieved";

const AppliedFilters = () => {
  // Getting protocolsFilter state from Redux store
  const { notificationsRecipientsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting programId from URL parameters
  const { notificationId } = useParams()

  // Function to check if any filter is applied
  const isAnyFilterApplied = (
    notificationsRecipientsFilter: notificationRecipientsDirectoryFilterState
  ) => {
    if (notificationsRecipientsFilter.dateRecieved.date !== "") {
      const {
        dateRecieved
      } = notificationsRecipientsFilter;
      const applied =
        dateRecieved
      return applied;
    }
  };

  const clearAllFilters = () => {
    dispatch(notificationsRecipientsResetFilterExceptProgramsAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyFilterApplied(notificationsRecipientsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start "
      >
        <div className="flex flex-wrap gap-2">
          <DateRecieved
            date={notificationsRecipientsFilter.dateRecieved.date}
            name="recievedDate"
            isApplied={notificationsRecipientsFilter.dateRecieved?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;
