import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { InputFields } from "./components/InputFields";
import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import Button from "view/components/Button";
import apiLibrary from "services/api";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IImportSummaryData } from "store/importSummary/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";
import apiClient from "services/apiClient";
import { ArrowRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";

interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
}

interface UploadFileProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  files: File[];
  setFiles: any;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
}

interface IFormInitialState {
  fileName: string;
  programId: any;
  formId: any;
  recievedFrom: string;
  comment: string;
}
interface UploadResponseType {
  id?: number;
}
interface ImportDataType {
  comment: string;
  dataReceivedFrom: string;
  dynamicFormId: number | null;
  fileId: number;
  fileName: string;
  programId: number | null;
}

interface ImportResponse {
  data: any; // Adjust according to your actual data structure
  message: string;
}

export const UploadFile: React.FC<UploadFileProps> = ({
  activeStep,
  isLastStep,
  steps,
  files,
  setFiles,
  moveToPreviousStep,
  moveToNextStep,
}) => {
  const navigate = useNavigate();

  const importId = localStorage.getItem("importId");
  const { imports } = usePermissions();

  const getStepperData = async () => {
    if (importId) {
      try {
        const { data } = await apiClient.get(
          `/admin/imports/${importId}/summary`
        );
        if (data) {
          setFormInitialValues({
            fileName: data?.data?.fileName,
            recievedFrom: data?.data?.dataReceivedFrom,
            comment: data?.data?.comment,
            programId: data?.data?.program?.id,
            formId: data?.data?.dynamicForm?.id,
          });
        }
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      }
    }
  };

  useEffect(() => {
    getStepperData();
  }, [activeStep]);

  // Stepper 1 initial values
  const [formInitialValues, setFormInitialValues] = useState<IFormInitialState>(
    {
      fileName: "",
      programId: null,
      formId: null,
      recievedFrom: "",
      comment: "",
    }
  );

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */

  const handleSubmit = async (
    values: IFormInitialState,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ): Promise<void> => {
    try {
      setSubmitting(true);

      if (files.length === 0) {
        throw new Error("Please upload file to proceed");
      }

      const uploadResponse = await uploadFile(files[0]);
      if (!uploadResponse.id) {
        throw new Error("Failed to get file ID from upload response");
      }

      const importData: ImportDataType = {
        comment: values.comment,
        dataReceivedFrom: values.recievedFrom,
        dynamicFormId: values.formId ? Number(values.formId) : null,
        fileId: uploadResponse.id,
        fileName: values.fileName,
        programId: Number(values.programId),
      };

      const importResponse = await importDataFunc(importData);

      handleSuccessfulImport(importResponse);
    } catch (error: any) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  async function uploadFile(file: File): Promise<UploadResponseType> {
    const { data } = await apiLibrary.Imports.fileUpload(file);
    return data;
  }

  async function importDataFunc(
    importData: ImportDataType
  ): Promise<ImportResponse> {
    const response = await apiLibrary.Imports.importUpload(importData);
    return response;
  }

  function handleSuccessfulImport(response: ImportResponse): void {
    // Store the import ID in local storage
    const importId = response.data.id;
    localStorage.setItem("importId", importId.toString());
    moveToNextStep();
  }

  function handleError(error: any): void {
    const errorMsg = error?.response?.data?.message ?? error.message;
    Toasts.error(errorMsg);
    console.error(error);
  }

  // Upload File Schema
  const UploadFileSchema = Yup.object().shape({
    fileName: Yup.string()
      .required("File Name Is Required")
      .max(255, "max 255 Characters Long"),

    programId: Yup.number().required("Program Is Required"),
    formId: Yup.number().required("Form Is Required"),
    recievedFrom: Yup.string().max(150, "Max 150 Characters Long").nullable(),
    comment: Yup.string().max(6000, "Max 6000 Characters Long").nullable(),
  });

  // FILES CONFIG
  const FILES_LIMIT = 1;
  const MAXIMUM_FILE_SIZE = 100; // in MB
  const ACCEPTED_FILES_TYPES = [".csv", ".xls", ".xlsx"];

  // Dropzone file drag and upload functions
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any[]) => {
      // Check for file size and type
      const filteredFiles = acceptedFiles.filter((file: any) => {
        const fileSizeMB = file.size / 1024 / 1024;
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        return (
          fileSizeMB <= MAXIMUM_FILE_SIZE &&
          ACCEPTED_FILES_TYPES.includes(fileExtension)
        );
      });

      // Update state with the first filtered file only
      if (filteredFiles.length > 0) {
        setFiles([filteredFiles[0]]);
      }
    },
    [setFiles]
  );

  const handleDeleteFile = (index: number) => {
    setFiles((files: File[]) =>
      files.filter((_, fileIndex) => fileIndex !== index)
    );
  };

  const handleUpdateFile = (newFile: any, fileIndex: number) => {
    // Check if new file already exists in the array
    const isFileAlreadyAdded = files.some(
      (file) => file.name === newFile.name && file.size === newFile.size
    );
    if (isFileAlreadyAdded) {
      return; // Skip the update if the file is already added
    }

    // Update the file at the specified index
    if (fileIndex >= 0 && fileIndex < files.length) {
      const updatedFiles = [...files];
      updatedFiles[fileIndex] = newFile;
      setFiles(updatedFiles);
    }
  };

  // Handle file drop
  const onDrop = useCallback((acceptedFiles: any) => {
    // Handle dropped files, like uploading or processing
    const fileSize = acceptedFiles[0].size / 1024 / 1024; // convert bytes to mb
    if (fileSize > MAXIMUM_FILE_SIZE) {
      Toasts.error("File size must be less than 10 mb!");
      acceptedFiles.splice(0, acceptedFiles.length); // Clear the accepted files
      return;
    }
  }, []);

  const handleCancelBtn = () => {
    navigate("/imports");
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={UploadFileSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        isValid,
      }) => (
        <Form>
          <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
            <InputFields
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              handleOnDropFiles={handleOnDropFiles}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              files={files}
              acceptedFileTypes={ACCEPTED_FILES_TYPES}
            />

            <SubmissionButtons
              isValid={isValid}
              isSubmitting={isSubmitting}
              values={values}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const SubmissionButtons = ({ handleSubmit, isSubmitting, isValid }: any) => {
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        disabled={isSubmitting || !isValid}
        type="submit"
        onClick={handleSubmit}
        text="Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        icon={
          <ArrowRightIcon
            width={18}
            className="text-primary stroke-primary dark:stroke-textWhite mt-[2px]"
          />
        }
        iconPosition="after"
      />
    </div>
  );
};
