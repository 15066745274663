import React from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { categoriesSortingAction, importsSortingAction, importsSummaryDataSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import exportDataToCsv from "utils/exportDataToCsv";
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./Communities.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { filterKeys } from "Components/Users/Table";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { useSelector } from "react-redux";
import { fetchImportsFile } from "store/importsViewData/initialState";

export const KEYS_TO_BE_REMOVED = ["id"];
// const generatingDirectoryActions = (columns: any, rowsData: any) => {
//   const fileDownload = async () => {
//     try {
//       const res = await apiLibrary.file.fileDownload("categories", columns, []);

//       // Convert the response to a Blob
//       const blob = new Blob([res], { type: "application/octet-stream" });

//       // Create a link element
//       const link = document.createElement("a");

//       // Set the href attribute to a URL created from the Blob
//       link.href = window.URL.createObjectURL(blob);

//       // Set the download attribute to specify the file name
//       link.download = `categories_${new Date().toISOString()}.csv`;

//       // Append the link to the body
//       document.body.appendChild(link);

//       // Programmatically click the link to trigger the download
//       link.click();

//       // Remove the link from the DOM
//       document.body.removeChild(link);

//       Toasts.success("Download complete");
//     } catch (error: any) {
//       const errorMsg = error?.response?.data?.message ?? error?.message;
//       Toasts.error(errorMsg);
//     }
//   };

//   return [
//     {
//       label: "Export Details",
//       onClick: () => fileDownload(),
//       divider: false,
//       isVisible: true,
//     },
//   ];
// };

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  sorting,
}) => {
  const dispatch = useDispatch();
  const { importActions } = useActions();
  const { importsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // const DirectoryActions = () => {
  //   const filteredKeys = filterKeys(columnVisibility, columnOrder);
  //   return generatingDirectoryActions(
  //     filteredKeys,
  //     getAllRowsData(table, columnOrder, columnVisibility, columns)
  //   );
  // };

  // const getImportActions = (data: any) => {
  //   const modalData = {
  //   };
  //   return importActions(modalData);
  // };

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(importsSummaryDataSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(importsSummaryDataSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(importsSummaryDataSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);
  return (
    <div className="w-full overflow-hidden max-h-[80vh] overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          flexRender={flexRender}
          table={table}
          columnVisibility={columnVisibility}
          // getImportActions={getImportActions}
        />
      </table>
    </div>
  );
};
