import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { RecievedDate } from "./Components";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import AppliedFilters from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";

interface IFiltersListing {
  label: string;
  name: string;
  dropdownComponent?: React.ReactNode;
  isVisible: boolean;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const { notificationsRecipientsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const { notificationId } = useParams<{
    notificationId: any;
  }>();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [rightOffsetWidth, setRightOffsetWidth] = useState(false);

  const handleMouseEnter = (dropdownName: string) => {
    setShowDropdown(dropdownName);
    setIsDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(null);
    setIsDropdownVisible(false);
  };

  const List: IFiltersListing[] = [
    {
      label: "Recieved date filter",
      name: "recieved_date_filter",
      isVisible: notificationId,
      dropdownComponent: (
        <RecievedDate
          name="dateRecieved"
          dateRecieved={notificationsRecipientsFilter.dateRecieved?.date}
        />
      ),
    },
  ];
  useEffect(() => {
    if (showDropdown) {
      const currentDropdownMenu = document.querySelector(
        `#${showDropdown}id .menu`
      );
      const btn = document.querySelector(`[data-dropdown="${showDropdown}"]`);
      if (currentDropdownMenu && btn) {
        const dropdownRect = currentDropdownMenu.getBoundingClientRect();
        const btnwidth = btn.getBoundingClientRect();

        const btnwidthoffset = window.innerWidth - btnwidth.right;
        const setDicDirection = dropdownRect.width - btnwidth.width;

        const getRightOffset = btnwidthoffset - setDicDirection;
        setRightOffsetWidth(getRightOffset < 0);
      }
    }
  }, [showDropdown]);  
 useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isDatePickerElement(event.target)) {
        setShowDropdown(null);
      }
    }
    function isDatePickerElement(target: any) {
      return target.closest('.MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root'); // Added MuiIconButton-root
    }
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <>
    <div
      className={`flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 ${isCloseDiv ? "h-min-[40px]" : "h-[0px] opacity-0"
        } transition-h duration-300 ease-in-out`}
    >
      <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        {List.filter((item) => item.isVisible).map((item, index) => (
          <div
            key={index}
            data-dropdown={item.name}
            onClick={() => handleMouseEnter(item.name)}
            id={item.name + "index"}
            className="btn flex justify-center items-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg border cursor-pointer hover:border-primaryMid hover:bg-primary/[0.08]  border-lineMid"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-secondaryMid 
 dark:text-primaryOutlined">
                {item.label}
              </p>
            </div>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              style={{
                transform: `rotate(${showDropdown === item.label ? "180deg" : "0"
                  })`,
                transition: "transform 0.3s ease",
              }}
            />
            {showDropdown === item.name && (
              <div id={item.name + "id"}>
                <div
                  className={`${isDropdownVisible &&
                      item.dropdownComponent &&
                      showDropdown === item.name
                      ? "visible"
                      : "invisible"
                    } menu absolute ${!rightOffsetWidth ? "left-0" : "right-0"
                    }  z-30 top-full ring-offset-8`}
                    ref={dropdownRef}
                >
                  {item.dropdownComponent}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    {/* <AppliedFilters /> */}
    </>
  );
};

export default FiltersList;
