import CopyIcon from "assets/icons/HeroIcons/CopyIcon";
import LinkIcon from "assets/icons/HeroIcons/LinkIcon";
import React from "react";
import { Toasts } from "view/components/Toasts";

interface IProps {
  link: string;
}

export const SummaryLink: React.FC<IProps> = ({ link }) => {
  const copyLinkToClipboard = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
      Toasts.copy("Link copied to clipboard", "bottom-center");
    } catch (error: any) {
      Toasts.error("Failed to copy link to clipboard");
    }
  };
  return (
    <div className="mt-3 py-2 border-b border-lineMid dark:bg-secondaryLight">
      {link ? (
        <div className="py-3 flex justify-between">
          <LinkIcon fill="#2C3236" />
          <p className="w-4/5 text-sm break-all dark:text-primaryMidLight">
            {link}
          </p>
          <div
            onClick={() => copyLinkToClipboard(link)}
            className="cursor-pointer"
          >
            <CopyIcon fill="#2C3236" />
          </div>
        </div>
      ) : (
        <p className="text-sm ">No notification attached</p>
      )}
    </div>
  );
};
