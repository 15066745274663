import { apiClient } from "../config";

async function addExistingObjectsToCommunity(id: any, data: any) {
  const res = await apiClient.post(
    `/admin/communities/${id}/objects
  `,
    data
  );
  return res.data;
}

export default addExistingObjectsToCommunity;
