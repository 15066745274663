import * as turf from "@turf/turf";

function calculateMultiEntityZoomLevel(layers: any[], map?: any) {
  if (!layers || layers.length === 0) {
    return;
  }

  // Initialize an empty FeatureCollection to combine all features
  let combinedFeatureCollection: any = [];
  layers.forEach((layer) => {
    const geoJson = layer ? layer?.mapData : null;
    if (
      geoJson &&
      layer.type !== "raster" &&
      geoJson !== null &&
      geoJson.features !== undefined
    ) {
      combinedFeatureCollection.push(geoJson.features);
    }
  });

  // Calculate the bounding box for the combined features
  const featureCollection = turf.featureCollection(
    combinedFeatureCollection.flat(1)
  );

  const bbox = turf.bbox(featureCollection);

  // Convert bbox to a Polygon to calculate its area
  const bboxPolygon = turf.bboxPolygon(bbox);
  const area = turf.area(bboxPolygon);

  // Define a heuristic to determine the zoom level
  let zoomLevel;
  if (area > 1000000) {
    // Greater than 1 km²
    zoomLevel = 10;
  } else if (area > 100000) {
    // Greater than 0.1 km²
    zoomLevel = 12;
  } else if (area > 10000) {
    // Greater than 10000 m²
    zoomLevel = 14;
  } else {
    zoomLevel = 15;
  }

  const bounds = [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]],
  ];

  return {
    bounds,
    maxZoom: zoomLevel,
  };
}

export default calculateMultiEntityZoomLevel;
