import apiClient from "services/apiClient";

async function reUseSpeciesAndObjectsList(id: any, entity: any, data: any) {
  
  const url = `/admin/communities/${id}/reuse-list?entity=${entity}`;
  const res = await apiClient.post(url, data);
  return res.data;
}

export default reUseSpeciesAndObjectsList;
