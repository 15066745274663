import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const FolderListIcon = ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
  >
    <path
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 6C4.44772 6 4 6.44772 4 7V17C4 17.5523 4.44772 18 5 18H19C19.5523 18 20 17.5523 20 17V9C20 8.44772 19.5523 8 19 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711L10.5858 6H5ZM2 7C2 5.34315 3.34315 4 5 4H11C11.2652 4 11.5196 4.10536 11.7071 4.29289L13.4142 6H19C20.6569 6 22 7.34315 22 9V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7Z"
    />
  </svg>
);
export default FolderListIcon;
