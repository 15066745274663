import {
  ERROR_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
  GET_ALL_QUALITY_ASSURANCE_OBSERVATIONS,
  RESET_DATA,
  START_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
  STOP_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
} from "./action-types";
import initialState, { IQualityAssuranceObservation } from "./initialState";
export const qualityAssuranceObservationsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case START_LOADING_QUALITY_ASSURANCE_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_QUALITY_ASSURANCE_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_QUALITY_ASSURANCE_OBSERVATIONS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_QUALITY_ASSURANCE_OBSERVATIONS:
      const observations = action.payload.observations.map((item: any) => {
        const {
          observationCode,
          status,
          unreadMessages,
          flaggedSections,
          observer,
          programName,
          communityName,
          reviewer,
          dateUpdated,
          programManagers,
          id,
          flag,
          requireQa,
        } = item;

        const data = {
          observationCode: {
            observationCode,
            id,
          },
          status,
          unreadMessages,
          flaggedSections,
          observer,
          programName,
          communityName,
          reviewer,
          dateUpdated,
          programManagers,
          id,
          flag,
          requireQa,
        };

        return data;
      });

      return {
        ...state,
        data: {
          externalReviewerAccessUntil:
            action.payload.externalReviewerAccessUntil,
          observations,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
