import { apiClient } from "../config";

async function getAllTags(queryParams: any) {
  const res = await apiClient.get(`/admin/tags`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllTags;
