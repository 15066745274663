import { type } from "os";

export interface IRoles {
roles: RolesArrayData[];
}

export interface RolesArrayData {
  id: string;
  type: string;
  description: string;
  title: string;
  users: number;
  is_editable: boolean
}

export interface RolesData {
  roles: IRoles[];
  totalItems: number;
  totalPages: number;
}
export interface IfetchRoles {
  data: RolesData;
  message: string;
  // stateIs: "Idle" | "Pending" | "Error";
}
const initialNotificationsData: IfetchRoles = {
  data: {
    roles: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  // stateIs: "Idle",
};
export default initialNotificationsData;