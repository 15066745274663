import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

const useCreateMarker = (
  map: mapboxgl.Map,  // Using specific type for better type safety
  isMapFullyLoaded: boolean,
  coordinates: {
    lat: number,
    lng: number
  } // Assuming coordinates is always an array of two numbers
) => {
  // Store the marker in a ref so that it persists between renders
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  useEffect(() => {
    if (map && isMapFullyLoaded && coordinates && coordinates.lat && coordinates.lng) {

      // Initialize the marker only if it doesn't exist
      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker().setLngLat([coordinates.lng, coordinates.lat]).addTo(map);
      } else {
        // If the marker already exists, just update its position
        markerRef.current.setLngLat([coordinates.lng, coordinates.lat]);
      }

      // Fly the map to the new [coordinates.lng , coordinates.lat]
      map.flyTo({
        center: [coordinates.lng, coordinates.lat],
        essential: true  // This ensures the camera moves smoothly to the new location
      });
    }

  }, [map, isMapFullyLoaded, coordinates]);

  return null;
};

export { useCreateMarker };
