interface MyObject {
  [key: string]: any;
}

function sortObjectByOrder(obj: MyObject, order: string[]): MyObject {
  const sortedObj: MyObject = {};
  const sortedKeys: string[] = [];
  const remainingKeys: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (order.includes(key)) {
      sortedKeys.push(key);
    } else {
      remainingKeys.push(key);
    }
  });

  sortedKeys
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .forEach((key) => {
      sortedObj[key] = obj[key];
    });

  remainingKeys.forEach((key) => {
    sortedObj[key] = obj[key];
  });

  return sortedObj;
}

export default sortObjectByOrder;