import { WarningIcon } from "assets/icons/HeroIcons";
import { camelCase } from "change-case";
import { sentenceCase } from "change-case";
import React from "react";

interface StatusProps {
  status: string;
  changeColor?: string;
}

const CompletedStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center  capitalize">
            {status.toLowerCase() === "completed" ? "complete" : status}
          </p>
        </div>
      </div>
    </div>
  );
};

const InCompletedStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid capitalize">
            {status.toLowerCase() === "incompleted" ? "incomplete" : status}
          </p>
        </div>
      </div>
    </div>
  );
};

const InReviewStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center  capitalize">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const ArchivedStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center  capitalize">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const DraftStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center  capitalize">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};
const RequestToEditStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div>
          <WarningIcon className="h-5 w-5" />
        </div>{" "}
        <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 pb-0.5">
          <p className="flex-grow-0 flex-shrink-0 text-xs text-center  capitalize">
            {status}
          </p>
        </div>
      </div>
    </div>
  );
};

const UnknownStatus: React.FC<StatusProps> = ({ status, changeColor }) => {
  return (
    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
      <div
        className={`flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1 py-0.5 rounded ${changeColor} `}
      >
        <div className="relative flex items-center justify-center py-1 w-full">
          <p className="text-xs text-center  w-full capitalize">{status}</p>
        </div>
      </div>
    </div>
  );
};
export const Statuses: React.FC<StatusProps> = ({ status, changeColor }) => {
  if (status === null && status === undefined) {
    return null;
  }

  const camelCaseStatus = camelCase(status);
  switch (camelCaseStatus) {
    case "completed":
    case "complete":
    case "imported":
      return (
        <CompletedStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    case "incompleted":
    case "incomplete":
      return (
        <InCompletedStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    case "inReview":
      return (
        <InReviewStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    case "draft":
      return (
        <DraftStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    case "archived":
      return (
        <ArchivedStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    case "requestToEdit":
      return (
        <RequestToEditStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
    default:
      return (
        <UnknownStatus
          status={sentenceCase(camelCaseStatus)}
          changeColor={changeColor}
        />
      );
  }
};
