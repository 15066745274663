import { useEffect } from "react";
import { __paintShapeByGeoJsonOnMap__ } from "../workerMehods";
import { generatingLayerId } from "../utils";
import { handlePaintingOfRasterLayersOnMap } from "../handlers/handlePaintingOfRasterLayersOnMap";
import { handleCreationOfMarkerOnMap } from "../handlers/handleCreationOfMarkerOnMap";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { convertGeoJSON } from "utils/convertFeatureCollection";

import * as turf from "@turf/turf";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR ="#FFA500";

const usePaintLayers = (
  regionData: any[],
  map: any,
  isMapFullyLoaded: boolean
) => {
  useEffect(() => {
    if (!regionData || !map || !isMapFullyLoaded) return;

    regionData.forEach((dataItem: any, index: number) => {
      switch (true) {
        case dataItem.itemType === "raster":
          handlePaintingOfRasterLayersOnMap(dataItem, map);
          // rasterItems.push(item);
          break;

        case dataItem.itemType === "vector" ||
          dataItem.itemType === "region" ||
          dataItem.itemType === "shape":
          const { sourceId, layerId } = generatingLayerId(
            dataItem.itemType,
            index
          );

         const geoJson = convertGeoJSON(dataItem.mapData) 

          if (geoJson && !map.getLayer(layerId) && !map.getSource(sourceId)) {
            // paint the layer

            __paintShapeByGeoJsonOnMap__(
              map,
              sourceId,
              layerId,
              geoJson,
              dataItem.color || LAYER_DEFAULT_COLOR,
              dataItem.borderColor
            );
          }
          break;

        case dataItem.itemType === "point":
          handleCreationOfMarkerOnMap(dataItem, map, mapboxgl);
          break;
      }
    });

    const calculation = calculateMultiEntityZoomLevel(regionData,map);
    
    if(calculation?.bounds && calculation?.maxZoom){
      map.fitBounds({
        ...calculation.bounds,
        maxZoom:calculation.maxZoom
      })
    }

    function calculateMultiEntityZoomLevel(layers: any[], map: any) {
      if (!layers || layers.length === 0) {
        return;
      }
    
      // Initialize an empty FeatureCollection to combine all features
      let combinedFeatureCollection:any = [];
    
      layers.forEach((layer) => {
        const geoJson = layer ? layer?.mapData : null;
        if (geoJson && layer.type !== "raster" ) {
          combinedFeatureCollection.push(geoJson.features);
        }
      });
    
      // Calculate the bounding box for the combined features
      const featureCollection = turf.featureCollection(combinedFeatureCollection.flat(1))
      const bbox = turf.bbox(featureCollection);
    
      // Convert bbox to a Polygon to calculate its area
      const bboxPolygon = turf.bboxPolygon(bbox);
      const area = turf.area(bboxPolygon);
    
      // Define a heuristic to determine the zoom level
      let zoomLevel;
      if (area > 1000000) {
        // Greater than 1 km²
        zoomLevel = 10;
      } else if (area > 100000) {
        // Greater than 0.1 km²
        zoomLevel = 12;
      } else if (area > 10000) {
        // Greater than 10000 m²
        zoomLevel = 14;
      } else {
        zoomLevel = 15;
      }
    
      const bounds = [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ];
    
      return {
        bounds,
        maxZoom: zoomLevel,
      };
    }
  }, [regionData, map, isMapFullyLoaded]);

  return 0;
};

export { usePaintLayers };
