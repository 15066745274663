import { apiClient } from "../config";

async function changeProtocolStatus(id: string, status: any) {
  const url = `/admin/protocols/${id}/archive`;
  const res = await apiClient.put(url, {
    status: status,
  });
  return res.data;
}

export default changeProtocolStatus;
