import React from 'react'

interface IProps {
  description: string;
}

export const SummaryDescriptions: React.FC<IProps> = ({
  description,
}) => {
  const lines = description.split('\n');
  const paragraphElements = [];

  for (let i = 0; i < lines.length; i += 1) {
    paragraphElements.push(
      <p key={i}>
        {lines.slice(i, i + 1).join('\n')}
      </p>
    );
  }
  return (
    <>
      <div className='border border-primaryLight py-3 mt-4 rounded-lg px-5 bg-bgHighMidlight dark:bg-secondaryLight'>
      <p className="capitalize dark:text-textMain">{paragraphElements}</p>
      </div>
    </>
  )
}
