import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateGetGpsDatas } from "store/surveyJS";

import {
  AbleToSelectMultiple,
  Description,
  MapCenterPoint,
  Required,
  SelectLayers,
  Snapping,
  Title,
} from "./components";
// import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";

const GetGpsDataSidebar = () => {
  const { selectedQuestionElement, getGpsDatas } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const [currentGetGpsData, setCurrentGetGpsData] = useState<any>({});

  const dispatch = useDispatch();

  // // structuring data of default points according to dropdown component
  // const defaultLayersSelectedOptions = structuringLayersData(
  //   currentGetGpsData.selectedDefaultLayers,
  //   "options"
  // );
  // const defaultLayersOptions = removeMatchedEleFromTwoArrays(
  //   structuringLayersData(currentGetGpsData.allGlobalCommunityLayers, "options"),
  //   defaultLayersSelectedOptions,
  //   "label"
  // );

  useEffect(() => {
    if (selectedQuestionElement) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentGetGpsData]);

  // recognize and set current point picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;
    if (questionId) {
      const ele = getGpsDatas.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentGetGpsData({ ...ele, title, description });
      }
    }
  }, [selectedQuestionElement, getGpsDatas]);

  // handlers
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentGetGpsData = { ...currentGetGpsData };

    updatedCurrentGetGpsData.mapCenterPoint = {
      ...updatedCurrentGetGpsData.mapCenterPoint,
      doFly: false,
      [name]: value,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentGetGpsData,
      (updatedGetGpsDatas: any) => {
        setCurrentGetGpsData(updatedCurrentGetGpsData);
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  };
  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentGetGpsData = { ...currentGetGpsData };

    // update current element
    updatedCurrentGetGpsData.mapCenterPoint = {
      ...updatedCurrentGetGpsData.mapCenterPoint,
      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentGetGpsData,
      (updatedGetGpsDatas: any) => {
        setCurrentGetGpsData(updatedCurrentGetGpsData);
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  };
  // const handleAbleToSelectMultipleCheckboxOnChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const isChecked = e.target.checked;

  //   // update current element
  //   const updatedCurrentGetGpsData = { ...currentGetGpsData };

  //   updatedCurrentGetGpsData.ableToCheckMultiple = isChecked;

  //   // update current element state into global elements
  //   updateElementByIdFromArray(
  //     getGpsDatas,
  //     updatedCurrentGetGpsData,
  //     (updatedGetGpsDatas: any) => {
  //       setCurrentGetGpsData(updatedCurrentGetGpsData);
  //       dispatch(updateGetGpsDatas(updatedGetGpsDatas));
  //     }
  //   );
  // };
  // const handleEnableSnappingCheckboxOnChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const isChecked = e.target.checked;

  //   // update current element
  //   const updatedCurrentGetGpsData = { ...currentGetGpsData };

  //   updatedCurrentGetGpsData.enableSnapping = isChecked;

  //   // update current element state into global elements
  //   updateElementByIdFromArray(
  //     getGpsDatas,
  //     updatedCurrentGetGpsData,
  //     (updatedGetGpsDatas: any) => {
  //       setCurrentGetGpsData(updatedCurrentGetGpsData);
  //       dispatch(updateGetGpsDatas(updatedGetGpsDatas));
  //     }
  //   );
  // };
  // const handleDefaultLayersOnSelection = (option: any) => {
  //   if (
  //     !JSON.stringify(currentGetGpsData.selectedDefaultLayers).includes(
  //       option.label
  //     )
  //   ) {
  //     // update current element
  //     const updatedCurrentGetGpsData = { ...currentGetGpsData };

  //     updatedCurrentGetGpsData.selectedDefaultLayers = [
  //       ...updatedCurrentGetGpsData.selectedDefaultLayers,
  //       ...structuringLayersData(
  //         [option],
  //         "original",
  //         currentGetGpsData.allGlobalCommunityLayers
  //       ),
  //     ];

  //     // update current element state into global elements
  //     updateElementByIdFromArray(
  //       getGpsDatas,
  //       updatedCurrentGetGpsData,
  //       (updatedGetGpsDatas: any) => {
  //         setCurrentGetGpsData(updatedCurrentGetGpsData);
  //         dispatch(updateGetGpsDatas(updatedGetGpsDatas));
  //       }
  //     );
  //   }
  // };
  // const handleDefaultLayersOnRemove = (option: any) => {
  //   // update current element
  //   const updatedCurrentGetGpsData = { ...currentGetGpsData };

  //   updatedCurrentGetGpsData.selectedDefaultLayers =
  //     currentGetGpsData.selectedDefaultLayers.filter(
  //       (l: any) => l.name !== option.label
  //     );

  //   // update current element state into global elements
  //   updateElementByIdFromArray(
  //     getGpsDatas,
  //     updatedCurrentGetGpsData,
  //     (updatedGetGpsDatas: any) => {
  //       setCurrentGetGpsData(updatedCurrentGetGpsData);
  //       dispatch(updateGetGpsDatas(updatedGetGpsDatas));
  //     }
  //   );
  // };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentGetGpsData = { ...currentGetGpsData };

    updatedCurrentGetGpsData.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentGetGpsData,
      (updatedGetGpsDatas: any) => {
        setCurrentGetGpsData(updatedCurrentGetGpsData);
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentGetGpsData = { ...currentGetGpsData };

    updatedCurrentGetGpsData.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentGetGpsData,
      (updatedGetGpsDatas: any) => {
        setCurrentGetGpsData(updatedCurrentGetGpsData);
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentGetGpsData = { ...currentGetGpsData };

    updatedCurrentGetGpsData.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      getGpsDatas,
      updatedCurrentGetGpsData,
      (updatedGetGpsDatas: any) => {
        setCurrentGetGpsData(updatedCurrentGetGpsData);
        dispatch(updateGetGpsDatas(updatedGetGpsDatas));
      }
    );
  };
  return (
    <div className="flex flex-col py-[40px] px-[32px] bg-[#F3F5F7]  w-full h-full gap-8">
      <Title
        handleTitleOnChange={handleTitleOnChange}
        value={currentGetGpsData.title}
      />
      <Description
        handleDescriptionOnChange={handleDescriptionOnChange}
        value={currentGetGpsData.description}
      />
      <Required
        handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
        checked={currentGetGpsData.isRequired}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentGetGpsData.mapCenterPoint}
      />
      {/* <div>
        <AbleToSelectMultiple
          handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
          ableToCheckMultiple={currentGetGpsData.ableToCheckMultiple}
        />
        <Snapping
          enableSnapping={currentGetGpsData.enableSnapping}
          handleCheckboxOnChange={handleEnableSnappingCheckboxOnChange}
        />
      </div>
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      /> */}
    </div>
  );
};

export { GetGpsDataSidebar };

function structuringLayersData(
  data: any,
  converter: "original" | "options",
  originalData?: any
) {
  let structureData = [];

  if (converter === "options" && data) {
    structureData = data.map((l: any) => ({ value: l.id, label: l.name }));
  } else if (converter === "original" && originalData) {
    structureData = originalData.filter((l: any) =>
      JSON.stringify(data).includes(l.id.toString())
    );
  }

  return structureData;
}
