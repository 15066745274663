import _ from "lodash";
import {
  getUserSuccess,
  updateContactPrefrences,
  updateSocialaccounts,
} from "./action-types";
import { initialStateUserProfile } from "./initialState";

const userProfileReducer = (
  state = initialStateUserProfile,
  action: any
): any => {
  switch (action.type) {
    case getUserSuccess: {
      const { user, secondaryEmail, secondaryPhoneNumber, addresses } =
        action.payload;

      const primaryEmail = user.email
        ? {
            email: user.email,
            isVerified: true,
            isPrimary: true,
          }
        : null;

      const primaryPhoneNumber = user.phoneNumber
        ? {
            phoneNumber: user.phoneNumber,
            isVerified: true,
            isPrimary: true,
          }
        : null;

      const emails = _.concat(
        primaryEmail ? [{ ...primaryEmail, isPrimary: true }] : [],
        _.orderBy(
          secondaryEmail.map((email: any) => ({ ...email, isPrimary: false })),
          [(email) => !email.isPrimary, (email) => !email.isVerified]
        )
      );
      const phoneNumbers = _.concat(
        primaryPhoneNumber ? [{ ...primaryPhoneNumber, isPrimary: true }] : [],
        _.orderBy(
          secondaryPhoneNumber.map((phoneNumber: any) => ({
            ...phoneNumber,
            isPrimary: false,
          })),
          [
            (phoneNumber) => !phoneNumber.isPrimary,
            (phoneNumber) => !phoneNumber.isVerified,
          ]
        )
      );
      return {
        ...action.payload,
        addresses: _.orderBy(
          addresses,
          ["isPrimary", "verified"],
          ["desc", "desc"]
        ),

        secondaryEmail: emails,
        secondaryPhoneNumber: phoneNumbers,
      };
    }
    case updateContactPrefrences: {
      const profile = { ...state, contactPreference: action.payload };
      return profile;
    }
    case updateSocialaccounts: {
      const userProfile = { ...state, socialAccounts: action.payload };
      return userProfile;
    }

    case "RESET_USER_DATA":
      return initialStateUserProfile;
    default:
      return state;
  }
};

export default userProfileReducer;
