// Components
import { SelectedCategories } from "./Components";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { typesResetFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting typesFilter state from Redux store
  const { typesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();



  const clearAllFilters = () => {
    dispatch(typesResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyTypesFilterApplied(typesFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <SelectedCategories />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;



  // Function to check if any filter is applied
  export function isAnyTypesFilterApplied (typesFilter: any): boolean  {
    const { categoryIds } = typesFilter;
    const applied = categoryIds?.applied;
    return applied;
  };