import { apiClient } from "../config";

async function archiveOrganization(id: any, status: any) {
  const url = `/admin/organizations/${id}/archive`;
  const res = await apiClient.put(url, {
    status: status,
  });
  return res.data;
}

export default archiveOrganization;
