import { apiClient } from "../config";

async function getAllLayers(
  communityId: string,
  params: any
) {
  let url = `/admin/communities/${communityId}/geography-map-list`;

  const res = await apiClient.get(url, { params });
  return res.data;
}

export default getAllLayers;
