import ErrorBoundary from "Components/ErrorBoundary";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RootState } from "store";
import { DarkMode } from "store/darkMode/initialState";
import { SideNav } from "store/sideBar/initialState";
import Modals from "view/components/Modals";
import SideBar from "view/components/SideBar";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = () => {
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );
  const { isCollapsed } = useSelector<RootState, SideNav>(
    (state) => state?.sideBar
  );
  return (
    <div
      className={`flex w-full min-h-[100vh] ${isDarkMode && "dark:bg-bgMain "}`}
    >
      <div className="flex h-full fixed z-50">
        <SideBar />
      </div>
      <div
        className={`flex flex-col flex-1 items-center justify-start px-5 overflow-x-hidden overflow-y-auto bg-bgLightGrey dark:bg-bgMain  
      transition-all
       ${isCollapsed ? "ml-[80px]" : "ml-[235px]"}
      `}
      >
        <ErrorBoundary>
          <Modals />
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Layout;
