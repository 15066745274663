import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const ChatAlt2Icon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
    {...restProps}>
    <path
      fill={fill}
className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M2 6a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v1h1a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3h-1v3a1 1 0 0 1-1.707.707L12.586 19H9a2.99 2.99 0 0 1-1.293-.293A1 1 0 0 1 6 18v-3H5a3 3 0 0 1-3-3V6Zm7.414 11H13a1 1 0 0 1 .707.293L16 19.586V18a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-1v3a3 3 0 0 1-3 3h-3.586l-2 2ZM16 6a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v1.586l2.293-2.293A1 1 0 0 1 11 13h4a1 1 0 0 0 1-1V6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ChatAlt2Icon;
