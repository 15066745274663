import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Toasts } from "view/components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddEditDuplicateProtocolModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import { protocolSchema } from "utils/validationSchemas";
import { fetchProtocolsAction } from "store/protocols";
import ProtocolModalForm from "./protocolModalForm";
import { fetchProtocolProfileAction } from "store/protocolProfile/reducer.actions";
import * as Yup from "yup";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};
interface FormValues {
  title: any;
  description: any;
  formId?: any;
  programId?: any;
  file_ids: number[]; // Assuming file IDs are numbers
  notify?: boolean;
}

interface FormItem {
  value: string;
  label: string;
}

interface FormItem {
  value: string;
  label: string;
}

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */

export const AddEditDuplicateProtocolModal = () => {
  // State variables

  const { addEditDuplicateProtocolModal } = useSelector(
    (state: RootState) => state.modals
  );

  const { programId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);
  const [formList, setFormsList] = useState<FormItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [programList, setProgramList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To track if there's more data

  const fetchProtocolPrograms = async (page: number, searchQuery?: string) => {
    setLoading(true);
    try {
      const res: any = await apiLibrary.Protocols.getAllPrograms(
        searchQuery,
        page
      );
      const programs = res.data?.programs?.map((program: any) => {
        return {
          value: program.id,
          label: program.name,
        };
      });
      setProgramList((prevPrograms: any) => {
        const existingProgramIds = new Set(
          prevPrograms.map((program: any) => program.value)
        );
        const uniquePrograms = programs.filter(
          (newProgram: any) => !existingProgramIds.has(newProgram.value)
        );

        return [...prevPrograms, ...uniquePrograms];
      });

      // Check if more data is available (based on your API response)
      if (res.data.totalPages > page) {
        setHasMore(true); // More pages available
      } else {
        setHasMore(false); // No more pages
      }
    } catch (error: any) {
      // Handle API errors
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProgramSearch = (query: string) => {
    setSearchString(query);
  };

  // get forms by program
  const fetchProtocolFormsByPrograms = useCallback(
    async (programId: number, searchString?: string) => {
      try {
        const res = await apiLibrary.Protocols.getFormsByPrograms(
          programId,
          searchString
        );
        const forms = res.data?.forms?.map((form: any) => {
          return {
            value: form.formId,
            label: form.formName,
          };
        });
        setFormsList(forms);
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching forms:", error);
      }
    },
    [formList]
  );

  // Fetch Protocol Profile and Programs on component mount
  useEffect(() => {
    if (
      (addEditDuplicateProtocolModal.isOpen &&
        addEditDuplicateProtocolModal.editMode) ||
      addEditDuplicateProtocolModal.duplicateMode
    ) {
      setFiles(addEditDuplicateProtocolModal?.data.files);
    }
    if (
      addEditDuplicateProtocolModal.isOpen &&
      addEditDuplicateProtocolModal.data.programId.value !== ""
    ) {
      fetchProtocolFormsByPrograms(
        addEditDuplicateProtocolModal.data.programId.value
      );
    }
  }, [addEditDuplicateProtocolModal.isOpen]);

  useEffect(() => {
    fetchProtocolPrograms(page, searchString);
  }, [page, searchString]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */

  // FILES CONFIG
  const FILES_LIMIT = 50;
  const MAX_FILE_SIZE_MB = 25;
  const MAX_TOTAL_SIZE_MB = 5;
  const ACCEPTED_FILES_TYPES = {
    "image/jpeg": [".jpg", ".jpeg", ".png"],
    "video/mp4": [".mp4", ".mp3"],
    "application/pdf": [".pdf"],
  };

  // SUBMIT HANDLER
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setIsLoading(true);
    try {
      const formValues: FormValues = {
        title: values.title,
        description: values.description,
        file_ids: [],
      };

      if (values.programId) {
        formValues.programId = values.programId.value;
      }

      if (values?.formId?.length) {
        const attachedForm = values?.formId?.find((form: any) => form?.isInherit === false);
        formValues.formId = attachedForm ? attachedForm.id : values.formId[0]?.value;
      }

      if (files.length > 0) {
        const uploadFilePromises = files.map(async (file: any) => {
          try {
            if (!file.id) {
              const res = await apiLibrary.file.fileUpload(file);
              formValues.file_ids.push(res.data.id);
            } else {
              // Push the existing id to the array
              formValues.file_ids.push(file.id);
            }
          } catch (error: any) {
            // Handle upload errors
            console.error("Error uploading file:", error);
            // Clear fileIds array on error
            formValues.file_ids = [];
          }
        });

        // Wait for all file uploads to complete
        await Promise.all(uploadFilePromises);
      }

      if (
        addEditDuplicateProtocolModal.editMode &&
        addEditDuplicateProtocolModal.data.id
      ) {
        formValues.notify = values?.notify;

        const { data, message } = await apiLibrary.Protocols.editProtocol(
          addEditDuplicateProtocolModal.data.id,
          formValues
        );
        if (addEditDuplicateProtocolModal.data.path === "directory") {
          dispatch(fetchProtocolsAction(programId));
        } else if (addEditDuplicateProtocolModal.data.path === "profilePage") {
          dispatch(fetchProtocolProfileAction(data.id));
        }
        Toasts.success(message);
        handleClose();
      } else if (
        addEditDuplicateProtocolModal.duplicateMode &&
        addEditDuplicateProtocolModal.data.id
      ) {
        // Duplicate Protocol
        // formValues.file_ids = []
        const { data, message } =
          await apiLibrary.Protocols.duplicateProtocol(formValues);
        Toasts.success(message);
        handleClose();
        navigate(`/protocols/${data.id}/profile`);
      } else {
        // Add a new Protocol
        if (formValues.formId === "") {
          delete formValues.formId;
        }
        const { data, message } =
          await apiLibrary.Protocols.createProtocol(formValues);
        Toasts.success(message);
        handleClose();
        navigate(`/protocols/${data.id}/profile`);
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  // HANDLE MODAL CLOSE
  const handleClose = () => {
    if (!isLoading) {
      dispatch(closeAddEditDuplicateProtocolModalAction());
      setFiles([]);
    }
  };

  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      let totalSize = 0;
      const updatedFiles = [...files];

      for (const file of acceptedFiles) {
        const fileExtension = file.path
          .slice(file.path.lastIndexOf("."))
          .toLowerCase();

        // Check if the file's extension is not in the list of accepted extensions
        const isExtensionAccepted = Object.values(ACCEPTED_FILES_TYPES).some(
          (extArray) => extArray.includes(fileExtension)
        );
        if (!isExtensionAccepted) {
          continue;
        }

        const fileSizeMB = file.size / (1024 * 1024);

        if (fileSizeMB > MAX_FILE_SIZE_MB) {
          return Toasts.error(
            `File ${file.name} exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB`
          );
        }

        // totalSize += fileSizeMB;

        // if (totalSize > MAX_TOTAL_SIZE_MB) {
        //   return Toasts.error(
        //     `Total files must be less than or equal to ${MAX_TOTAL_SIZE_MB} MB`
        //   );
        // }

        if (updatedFiles.length + 1 > FILES_LIMIT) {
          return Toasts.error(
            `Total files must be less than or equal to ${FILES_LIMIT}`
          );
        }

        // Add the new file to the updated files array
        updatedFiles.push(file);
      }

      // Update the state with the new files
      setFiles(updatedFiles);
    },
    [files]
  );

  const handleDeleteFile = (id: string) => {
    let deleted = false; // Flag to keep track of whether a file with the same path is deleted
    const updatedFiles = files.filter((f: any) => {
      if ((f.id && f.id === id) || (f.path && f.path === id)) {
        if (!deleted) {
          deleted = true; // Mark as deleted
          return false; // Remove the first file with the same path
        }
      }
      return true;
    });
    setFiles([...updatedFiles]);
  };

  const handleUpdateFile = (newFile: any, id: string) => {
    // if new file already added then skipping the updation of file
    const isFileAlreadAdded = Boolean(
      files.find((f: any) => f.path === newFile.path)
    );
    if (isFileAlreadAdded) {
      return;
    }
    const updatedFiles = [...files];
    // verifiying file existance
    const fileIndex = updatedFiles.findIndex((file: any) => file.path === id);
    if (fileIndex >= 0) {
      updatedFiles[fileIndex] = newFile;
      setFiles([...updatedFiles]);
    }
  };

  // Handle file drop
  const onDrop = useCallback((acceptedFiles: any) => {
    // Handle dropped files, like uploading or processing
    const fileSize = acceptedFiles[0].size / 1024 / 1024; // convert bytes to mb
    if (fileSize > MAXIMUM_FILE_SIZE) {
      Toasts.error("File size must be less than 10 mb!");
      acceptedFiles.splice(0, acceptedFiles.length); // Clear the accepted files
      return;
    }
  }, []);

  // Load more options when the dropdown reaches the bottom
  const handleMenuScrollToBottom = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Modal
      open={addEditDuplicateProtocolModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
      sx={{
        ".MuiBox-root": {
          border: "none",
          outline: "none",
        },
      }}
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={addEditDuplicateProtocolModal.data}
          validationSchema={protocolSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <ProtocolModalForm
                {...formikProps}
                handleClose={handleClose}
                handleOnDropFiles={handleOnDropFiles}
                handleDeleteFile={handleDeleteFile}
                handleUpdateFile={handleUpdateFile}
                files={files}
                acceptedFileTypes={ACCEPTED_FILES_TYPES}
                onFileDrop={onDrop}
                editMode={addEditDuplicateProtocolModal.editMode}
                duplicateMode={addEditDuplicateProtocolModal.duplicateMode}
                programList={programList}
                formsList={formList}
                handleProgramSearch={handleProgramSearch}
                fetchProtocolFormsByPrograms={fetchProtocolFormsByPrograms}
                handleMenuScrollToBottom={handleMenuScrollToBottom}
                setFormsList={setFormsList}
                loading={loading}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
