import React, { useEffect, useState } from "react";
import TextInput from "view/components/InputField";
import UserGroupIcon from "assets/icons/HeroIcons/UserGroupIcon";
import communityPlaceholder from "assets/images/Placeholders/avatar-allcommunityplaceholder.png";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";

// Define the interface for CommunitiesProps (if required)
interface CommunitiesProps {
  communitiesResponse: any;
  setCommunitiesResponse: any;
  setSelectedCommunityId: any;
  selectedCommunityId: any;
}

export const Communities: React.FC<CommunitiesProps> = ({
  communitiesResponse,
  setCommunitiesResponse,
  setSelectedCommunityId,
  selectedCommunityId,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [searchCommunity, setSearchCommunity] = useState("");
  const { programId } = useParams();
  const getProgramsCommunities = async (
    programId: number,
    searchString?: any,
    sortBy?: string,
    sortType?: string
  ) => {
    try {
      const response = await apiLibrary.Programs.getProgramsCommunities(
        programId,
        sortBy,
        sortType,
        searchString
      );
      setCommunitiesResponse(response.data); // Assuming response.data is the array of communities
    } catch (error:any) {
      setCommunitiesResponse({
        allCommunityMembersCount: {
          selectedMembersCount: 0,
          totalMembersCount: 0,
        },
        communities: [],
        totalItems: 0,
      });

      console.error("Error fetching communities:", error);
    }
  };

  useEffect(() => {
    if (programId) {
      getProgramsCommunities(parseInt(programId));
    }
  }, [programId]);

  const searchCommunities = (value: string) => {
    if (programId) {
      setSearchCommunity((prevState) => {
        getProgramsCommunities(parseInt(programId), value);
        return value;
      });
    }
  };
  return (
    <div className="flex flex-col items-start justify-start w-full h-full rounded-lg cursor-default">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 h-full gap-4 p-6 bg-white border rounded-lg bg-bgWhite border-lineMid dark:bg-secondaryLight">
        <TextInput
          value={searchCommunity}
          onChange={(e: any) => searchCommunities(e.target?.value)}
          type="Search"
          placeholder="Search Among Existing Communities"
        />

        <p className="dark:text-textMain">
          Can't find the community you want to add? Create a new one from the
          communities directory.
        </p>
        <div className="w-full px-1 ">
          <div className="flex justify-between w-full gap-4 items-between">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="w-[100%] flex">
                <div className="w-[80%] flex gap-4">
                  <div>
                    <UserGroupIcon />
                  </div>
                  <div>
                    <p className="text-[17px] font-semibold text-secondaryMid dark:text-textMain">
                      {communitiesResponse?.totalItems} Communities
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-1 py-2 overflow-y-auto px-2 h-[400px] max-h-[31vh]">
                {communitiesResponse?.communities?.map(
                  (community: any, index: number) => {
                    return (
                      <>
                        <div
                          className={`relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-2 px-2 my-1 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2] ${
                            community.id === selectedCommunityId
                              ? "bg-primaryExtraLight dark:bg-primary/[0.3]"
                              : ""
                          }`}
                          onClick={() => setSelectedCommunityId(community?.id)}
                        >
                          <img
                            src={
                              community?.profileImage
                                ? community.profileImage
                                : communityPlaceholder
                            }
                            alt=""
                            height="100"
                            width="100"
                            className="object-cover Img_user_Data"
                            style={{ flexShrink: 0 }}
                          />
                          <div className="flex items-center justify-start flex-grow">
                            <div className="flex flex-col justify-start w-full gap-2 py-2 pl-1 rounded-lg i">
                              <p className="w-[260px] text-left break-words dark:text-textMain">{`${community.name}`}</p>
                              <span className="text-[12px] text-secondaryMid dark:text-textMain">
                                {community?.selectedMembersCount} of{" "}
                                {community?.totalMembersCount}
                              </span>
                            </div>
                          </div>
                          <svg
                            width={14}
                            height={12}
                            viewBox="0 0 14 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0"
                            preserveAspectRatio="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.19526 0.861888C7.93491 1.12224 7.93491 1.54435 8.19526 1.8047L11.7239 5.33329L1.33333 5.33329C0.965143 5.33329 0.666666 5.63177 0.666666 5.99996C0.666666 6.36815 0.965143 6.66663 1.33333 6.66663H11.7239L8.19526 10.1952C7.93491 10.4556 7.93491 10.8777 8.19526 11.138C8.45561 11.3984 8.87772 11.3984 9.13807 11.138L13.8047 6.47136C14.0651 6.21101 14.0651 5.78891 13.8047 5.52856L9.13807 0.861888C8.87772 0.601539 8.45561 0.601539 8.19526 0.861888Z"
                              className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                            />
                          </svg>
                        </div>
                        {/* <div
                        onClick={() => setSelectedCommunityId(community?.id)}
                        key={index}
                        className={`relative flex justify-between items-center w-full py-1 px-2 cursor-pointer rounded-lg hover:bg-primaryExtraLight ${
                          community.id === selectedCommunityId
                            ? "bg-primaryExtraLight"
                            : ""
                        }`}
                      >
                        <div className="flex ">
                          <div className="mt-2 rounded-full w-7 h-7">
                            <img
                              src={
                                community?.profileImage
                                  ? community.profileImage
                                  : communityPlaceholder
                              }
                              alt="placeholder"
                              className="rounded-full w-7 h-7"
                            />
                          </div>
                          <div className="px-3">
                            <p className="text-[15px] text-secondaryMid">
                              {community.name}
                            </p>
                            <span className="text-[12px] text-secondaryMid">
                              {community?.selectedMembersCount} of{" "}
                              {community?.totalMembersCount}
                            </span>
                          </div>
                        </div>

                        <div className="right-2">
                          <CheveronRightIcon />
                        </div>
                      </div> */}
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
