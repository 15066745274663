import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, AddCommunityBtn, PageHeading } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import {
  openAddExistingCommunitiesOrOrganizationsToProgramModalAction,
  openAddExistingCommunityModalAction,
  openCreateCommunityModalAction,
} from "store/modals/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import { IfetchCommunities } from "store/communities/initialState";

// Generating URLs

const generateDynamicBreadcrumbLinks = (
  organizationId: any,
  programId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(organizationId):
      return [
        { path: `/organizations/list`, label: `Organizations` },
        {
          path: `/organizations/${organizationId}/profile`,
          label: name,
        },
        {
          path: `/organizations/${organizationId}/communities`,
          label: `Communities`,
        },
      ];
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/communities`,
          label: `Commuities`,
        },
      ];

    default:
      return [{ path: `/community/list`, label: `Communities` }];
  }
};
export const Header = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communitiesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { communities, organizations } = usePermissions();
  const { organizationId, programId } = useParams<{
    organizationId: string;
    programId: string;
  }>();

  const [breadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  const [isShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);

  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    organizationId ?? "",
    programId ?? "",
    breadcrumbData?.name ?? "",
    communitiesFilter.status
  );
  useEffect(() => {
    const fetchData = async (
      id: string,
      getDetailsFn: (id: string) => Promise<any>
    ) => {
      try {
        const data = await getDetailsFn(id);
        setBreadcrumbData({
          ...data,
          showAddButton: data.status !== "completed",
        });
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errMsg);
        navigate(
          `/${
            id === organizationId
              ? "dashboard/organizations/list"
              : "dashboard/programs/list"
          }`
        );
      }
    };

    if (organizationId) {
      fetchData(organizationId, getOrganizationDetails);
    } else if (programId) {
      fetchData(programId, getProgramDetails);
    }
  }, [organizationId, programId]);

  const handleAddCommunityModel = () => {
    const action = organizationId
      ? openAddExistingCommunityModalAction({
          organizationId,
          entity: "organization",
          community: breadcrumbData.name,
        })
      : openAddExistingCommunitiesOrOrganizationsToProgramModalAction({
          id: programId as string,
          entity: "community",
          community: breadcrumbData.name,
        });

    dispatch(action);
    setIsShowExistingOptionsModal(false);
  };

  const handleClickOnCreateNewBtn = () => {
    if (programId || organizationId) {
      setIsShowExistingOptionsModal(!isShowExistingOptionsModal);
    } else {
      dispatch(openCreateCommunityModalAction());
    }
  };

  const handleClickOnAddExistingBtn = () => {
    const action = openAddExistingCommunityModalAction({
      organizationId,
      entity: "organization",
    });
    dispatch(action);
    setIsShowExistingOptionsModal(false);
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              organizationId={
                organizationId ? organizationId : programId ? programId : ""
              }
              name={breadcrumbData?.name}
              parentName={
                organizationId ? "Organization" : programId ? "Program" : ""
              }
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <RenderAddCommunityBtn breadcrumbData={breadcrumbData} />
              {/* <OptionsModal
                isOpen={isShowExistingOptionsModal}
                handleClickOnCreateNewBtn={() => {
                  dispatch(openCreateCommunityModalAction());
                  setIsShowExistingOptionsModal(false);
                }}
                handleAddCommunityModel={handleAddCommunityModel}
                handleClickOnAddExistingBtn={handleAddCommunityModel}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderAddCommunityBtn = ({ breadcrumbData }: { breadcrumbData: any }) => {
  const { organizationId, programId } = useParams();
  const { communities, organizations } = usePermissions();
  const { programs } = usePermissions();
  const dispatch = useDispatch();

  if (organizationId) {
    // if (!organizations.canEditOrganization) return <></>;
    return (
      <>
        {!breadcrumbData.openAccess && organizations.canEditOrganization && (
          <AddCommunityBtn
            handleAddCommunityModel={() => {
              dispatch(
                openAddExistingCommunityModalAction({
                  organizationId,
                  entity: "organization",
                  breadcrumbData: breadcrumbData,
                })
              );
            }}
            label={"Connect  Communities"}
          />
        )}
      </>
    );
  } else if (
    programId &&
    programs.canCreateCommunity &&
    programs.canViewEntityDropdown
  ) {
    if (breadcrumbData.showAddButton && !breadcrumbData.openAccess)
      return (
        <>
          {/* {programs.canCreateCommunity && programs.canViewEntityDropdown && ( */}
          <AddCommunityBtn
            handleAddCommunityModel={() => {
              dispatch(
                openAddExistingCommunitiesOrOrganizationsToProgramModalAction({
                  id: programId as string,
                  entity: "community",
                  breadcrumbData: breadcrumbData,
                })
              );
            }}
            label={"Connect  Communities"}
          />
          {/* )} */}
        </>
      );
  } else {
    return (
      <>
        {communities.canCreateCommunities && !programId && (
          <AddCommunityBtn
            handleAddCommunityModel={() => {
              dispatch(openCreateCommunityModalAction());
            }}
            label={"Add Community"}
          />
        )}
      </>
    );
  }
};

// controllers
async function getOrganizationDetails(organizationId: string) {
  try {
    const { data } =
      await apiLibrary.OrganizationDirectory.getOrganizationProfile(
        organizationId ? organizationId : ""
      );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
