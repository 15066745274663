import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useNavigate } from "react-router-dom";
import "../../../Table/Observations.scss";
import usePermissions from "hooks/usePermissions";

export const TableBody = ({
  table,
  columnVisibility,
  getReportsProfileActions,
  flexRender,
}: TableBodyProps) => {
  const navigate = useNavigate();
  const { reports } = usePermissions();
  const openReportsResultDetails = (row: any) => {
    if (reports.canViewResultReports && row.processingStatus === "ready") {
      navigate(`/reports/${row.reportId}/report-version/${row.version}/result`);
    }
  };
  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => {
        return (
          <tr
            key={index}
            className={`w-full ${reports.canViewResultReports && row?.original?.processingStatus === "ready" && "group"} Close item_data`}
            style={{ height: "66px" }}
          >
            {parseArray(row.getVisibleCells(), KEYS_TO_BE_REMOVED).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  rowData={row}
                />
              )
            )}
            <td className="whitespace-nowrap sticky right-0 dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
              <RowsActions
                row={row}
                getReportsProfileActions={getReportsProfileActions}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
