import { RenderCellContentProps } from '../../types';
import ellipsize from 'ellipsize';
import useDateFormatter from 'hooks/formatDateWithPattern';
import { getBrandPlaceholder } from 'utils/customBranding';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  if (columnDef.id === 'image') {
    return (
      <div className='flex justify-start  items-center relative'>
          <img
            src={cellValue || getBrandPlaceholder('moduleProfile')}
            alt=''
            height='48px'
            width='64px'
            className='object-cover'
            style={{ flexShrink: 0 }}
          />
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'dateAdded') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'objectName') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'type') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
