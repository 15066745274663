import { apiClient } from "../config";

async function confirmEmailOrPhone(payload: any, personId: any) {
  const res = await apiClient.post(
    `/admin/confirm-email-phone/${personId}`,
    payload
  );
  return res.data;
}

export default confirmEmailOrPhone;
