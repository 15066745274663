import { SET_PEOFILE_USER_SETTINGS  , SET_USER_SETTINGS} from "./action-types";
import { SettingsState } from "./initialState";

export const userProfileSettingsAction = (settings: SettingsState) => {
  return {
    type: SET_USER_SETTINGS,
    payload: settings,
  };
};

export const profileSettingsAction = (settings: SettingsState) => {
  return {
    type: SET_PEOFILE_USER_SETTINGS,
    payload: settings,
  };
};
