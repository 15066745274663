import React from "react";
import PlaceholderAdmin from "assets/images/Placeholders/admin_placeholder.png";

interface AdminOrganizaitonsProps {
  OrganizationAdmins: any;
}
const AdminOrganizations: React.FC<AdminOrganizaitonsProps> = ({
  OrganizationAdmins,
}) => {

  return (
    <>
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col justify-center items-start flex-grow gap-1.5 pb-2">
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[596px] text-base font-semibold text-left text-secondaryMid dark:text-textMain cursor-default">
              Admins
            </p>
          </div>
          <div className="flex flex-wrap gap-[4px] mt-1">
            {OrganizationAdmins.length > 0 && OrganizationAdmins?.map((data: any, index: any) => {
              if (data?.profileImage?.file) {
                return (
                  <>
                    <div className="relative flex-grow-0 flex-shrink-0 rounded-full w-9 h-9">
                      <img
                        src={data?.profileImage?.file?.thumb || data?.profileImage?.file?.original || data?.profileImage?.file?.large}
                        alt=""
                        className="rounded-full w-full h-full object-cover"
                      />
                    </div>
                  </>
                );
              } else {
                return (
                  <div className="relative flex-grow-0 flex-shrink-0 rounded-full w-9 h-9">
                    <svg
                      width={36}
                      height={36}
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute left-[-1px] top-[-1px]"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <circle cx={18} cy={18} r={18} fill="#E83B25" />
                    </svg>
                    <p className="w-9 absolute text-center top-0.5 text-lg text-white dark:text-textMain">
                      {data?.name?.charAt(0)?.toUpperCase()}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminOrganizations;
