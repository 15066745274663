import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ObjectImages, EditButton } from "Components/ObjectProfile/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IObject } from "store/objectProfile/initialState";
import useDateFormatter from "hooks/formatDateWithPattern";
import {
  fetchObjectProfileAction,
  resetObjectProfileAction,
} from "store/objectProfile/reducer.actions";
import { openAddOrEditObjectModalAction } from "store/modals";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { SpecieImages } from "Components/SpecieProfile/components";
import Button from "view/components/Button";
import ellipsize from "ellipsize";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import Head from "view/components/Head";

interface BreadcrumbLink {
  path: string;
  label: string;
}

const generateDynamicBreadcrumbLinks = (
  communityId: string | number,
  objectId: string | number,
  communityName: string,
  objectName: string
): BreadcrumbLink[] => {
  if (communityId) {
    return [
      { path: `/community/list`, label: `Communities` },
      {
        path: `/community/${communityId}/profile`,
        label: communityName,
      },
      { path: `/community/${communityId}/objects`, label: `Objects` },
      {
        path: `/community/${communityId}/object/${objectId}/profile`,
        label: objectName,
      },
    ];
  } else {
    return [
      { path: `/object/list`, label: `Objects` },
      { path: `/object/${objectId}/profile`, label: objectName },
    ];
  }
};

export const ObjectProfile = () => {
  const navigate = useNavigate();
  const { communityId, objectId } = useParams();
  const { formatDate } = useDateFormatter();
  const [showFullText, setShowFullText] = useState(false);
  const { objects } = usePermissions();
  const maxChars = 800;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const objectProfile = useSelector<RootState, IObject>(
    (state) => state.objectProfile
  );

  // const { objects } = usePermissions();
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const [breadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then(setBreadcrumbData)
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        });
    }
    dispatch(fetchObjectProfileAction(objectId || ""));
  }, [communityId, objectId, dispatch, navigate]);

  const dynamicCommunityLinks = useMemo(
    () =>
      generateDynamicBreadcrumbLinks(
        communityId ?? "",
        objectId ?? "",
        breadcrumbData?.name ?? "",
        objectProfile.objectName
      ),
    [communityId, objectId, breadcrumbData, objectProfile.objectName]
  );
  const editObject = async () => {
    const objectData = {
      name: objectProfile.objectName,
      description: objectProfile.description,
      images: objectProfile.attachments?.map((attachment: any) => {
        const image = {
          file: attachment.file.thumb,
          isStar: attachment.isStar,
          id: attachment.id,
          name: attachment.title,
        };
        return image;
      }),
      isStar: objectProfile?.attachments?.find((image: any) => image.isStar)
        ?.id,
      typeId: {
        label: objectProfile.typeName,
        value: objectProfile.typeId,
      },
      id: objectProfile.id,
      path: "profilePage",
    };
    dispatch(openAddOrEditObjectModalAction(objectData, true));
  };

  //handles
  const handleOnCLick = () => {
    navigate("/object/list");
  };
  useEffect(() => {
    return () => {
      dispatch(resetObjectProfileAction());
    };
  }, []);
  return (
    <div className="w-full pt-5">
      <Head title={`Objects ${objectProfile.objectName}`} />
      <div className="mb-3">
        <DirectoryBreadCrumb links={dynamicCommunityLinks} />
      </div>

      <div className="flex justify-between w-full py-2">
        <p className="text-2xl font-semibold dark:text-textMain ">
          {ellipsize(objectProfile.objectName, 70)}
        </p>
        <Button
          type="button"
          text="Back"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="px-5 py-2"
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={handleOnCLick}
        />
      </div>
      <div className="py-5">
        <div className="flex items-start rounded-lg gap-4 px-8 py-4 bg-bgWhite w-full max-h-[75vh] overflow-y-auto dark:bg-secondaryLight">
          <div className="flex flex-col items-start justify-start gap-4">
            <SpecieImages images={objectProfile.attachments ?? []} />
          </div>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden p-0.5 rounded-sm bg-white">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
              <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1.5 pt-1.5 pb-2">
                <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0">
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                    <div className="relative flex flex-col items-start justify-center flex-grow py-1">
                      <p className="self-stretch flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                        {objectProfile.objectName}
                      </p>
                    </div>
                    {objects.canEditObjects && (
                      <EditButton onClick={editObject} />
                    )}
                  </div>
                </div>

                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
                  <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid dark:text-caption ">
                    Type:
                  </p>
                  <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1.5 py-0.5 rounded bg-primaryExtraLight  dark:bg-primary_40">
                    <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 py-1">
                      <p className="flex-grow-0 flex-shrink-0 text-xs text-center capitalize text-primary dark:text-textMain">
                        {objectProfile.typeName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
                  <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid dark:text-caption ">
                    Date Added:
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid dark:text-textMain">
                    {formatDate(objectProfile.dateAdded)}
                  </p>
                </div>
                <div className="flex flex-col justify-start items-start w-full relative py-[3px] max-h-[320px] overflow-y-auto">
                  <p className="flex-grow w-full text-sm text-left break-words text-secondaryMid dark:text-textMain ">
                    {showFullText
                      ? objectProfile?.description
                      : objectProfile?.description?.slice(0, maxChars)}
                  </p>
                  {objectProfile?.description?.length > maxChars && (
                    <button onClick={toggleText} className="flex items-center">
                      <h5 className="pr-1 font-semibold text-secondaryMid dark:text-textMain ">
                        {showFullText ? "Read Less" : "Read More"}
                      </h5>
                      {showFullText ? (
                        <CheveronUpIcon height={16} />
                      ) : (
                        <CheveronDownIcon height={16} />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
