import React from "react";
import coverImage from "assets/images/Auth/img-placeholder.png";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import "./Gridcard.scss";
import { IfetchObservations } from "store/observations/initialState";
import { Statuses } from "Components/Observations/Statuses";
import DotsHorizontalIcon from "assets/icons/HeroIcons/DotsHorizontalIcon";
import ellipsize from "ellipsize";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import useActions from "hooks/useActions";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

export const GridCard = () => {
  const { data } = useSelector<RootState, IfetchObservations>(
    (state) => state?.allObservations
  );
  const { observationsActions } = useActions();
  const { programId, communityId } = useParams();

  const getObservationsActions = (data: any) => {
    const modalData = {
      status: data?.status,
      id: data?.id,
    };
    return observationsActions(modalData);
  };
  return (
    <div className="grid w-full gap-3 lg:grid-cols-2 xl:grid-cols-3">
      {data?.observations?.map((item: any, index: number) => {
        
        return (
          <div
            key={index}
            className="flex rounded-lg shadow-sm bg-bgWhite hover:shadow-lg"
          >
            <div className="flex flex-col items-start justify-start flex-grow w-full p-3 rounded shadow-sm hover:shadow-lg">
              <div className="flex items-center justify-between w-full">
                <div className="flex w-full gap-3">
                  <Link to={`/observations/${item?.id}/profile`}>
                    <p className="text-left text-textMid font-medium w-full">
                      {ellipsize(`${item?.observationCode?.observationCode}`, 15)}
                    </p>
                  </Link>

                  <div className="items-center rounded bg-primaryExtraLight">
                    <p className="text-sm text-primaryMid ">
                      <Statuses status={item?.status} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <PopOverMenu icon={<DotsHorizontalIcon />} style={true}>
                    <ActionDropDownMenu
                      actions={getObservationsActions(item)}
                    />
                  </PopOverMenu>
                </div>
              </div>
              <Link to={`/observations/${item?.id}/profile`}>
                <div className="w-full">
                  <p className="py-1 break-words text-secondaryMid">
                    {!programId && item?.programName?.name && (
                      <>{ellipsize(`${item.programName.name}`, 30)}</>
                    )}
                  </p>
                </div>
              </Link>

              <Link to={`/forms/${item?.id}/profile`}>
                <div className="w-full">
                  <p className="py-1 break-words text-secondaryMid">
                    {programId && item?.form?.name && (
                      <>{ellipsize(`${item.form.name}`, 30)}</>
                    )}
                  </p>
                </div>
              </Link>

              <Link to={`/observations/${item?.id}/profile`}>
                {!communityId && (
                  <div className="flex items-center justify-start py-1">
                    <img
                      src={
                        item?.communityName?.image?.thumb
                          ? item?.communityName?.image?.thumb
                          : coverImage
                      }
                      alt={item?.communityName?.name}
                      className="object-cover w-6 h-6 rounded-full"
                    />
                    <p className="ml-2 text-sm text-secondaryMidLight">
                      {item?.communityName?.name &&
                        ellipsize(`${item?.communityName?.name}`, 30)}
                    </p>
                  </div>
                )}
              </Link>

              <div className="w-full flex justify-between items-center gap-0.5 py-1">
                <Link to={`/observations/${item?.id}/profile`}>
                  <div className="flex items-center gap-0.5">
                    {" "}
                    <img
                      src={
                        item?.observer?.image?.thumb
                          ? item?.observer?.image?.thumb
                          : coverImage
                      }
                      alt={item?.observer?.name}
                      className="object-cover w-6 h-6 rounded-full"
                    />
                    <p className="ml-[6px] text-sm text-secondaryMidLight">
                      {item?.observer?.name
                        ? ellipsize(`${item?.observer?.name}`, 30)
                        : ""}
                    </p>
                  </div>
                </Link>

                <div>
                  <p className="text-[12px] text-secondaryMidLight">
                    {item?.submissionDate
                      ? `Created ${moment(item?.submissionDate).format(
                          "DD MMM YYYY"
                        )} `
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
