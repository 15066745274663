import { ERROR_LOADING_IMPORT_SUMMARY, GET_IMPORT_SUMMARY, START_LOADING_IMPORT_SUMMARY, STOP_LOADING_IMPORT_SUMMARY } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { openImportSummaryAction } from "store/modals";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

// const data = {
//   data: {
//     columnMapping: [
//       {
//         mapToField: "Observation Code",
//         columnNameFromFile: "Observation Code",
//         sampleFileContent: "THGD-1",
//         id: 1,
//       },
//       {
//         mapToField: "Status",
//         columnNameFromFile: "Status",
//         sampleFileContent: "In Review",
//         id: 2,
//       },
//       {
//         mapToField: "Observer",
//         columnNameFromFile: "Observer",
//         sampleFileContent: "Aaron Lestenkof",
//         id: 3,
//       }
//       // You can add more items to the "columnMapping" array with realistic data
//     ],
//     comment: "This data involves actions performed in the system.",
//     dataReceivedFrom: "Received from external partners",
//     dynamicForm: {
//       formType: "custom",
//       fields: [
//         {
//           fieldName: "action_description",
//           fieldType: "text",
//           required: true,
//         },
//         // Add more fields as needed
//       ],
//     },
//     failedImport: 0,
//     fileName: "sample_data.csv",
//     id: 45,
//     importedBy: {
//       id: 1,
//       name: "John Doe",
//     },
//     importedDate: "2023-11-08",
//     program: {
//       id: 3,
//       name: "Optimizing Collaborative E-Markets",
//     },
//     successfulImports: 1,
//     totalObservations: 500,
//   },
//   message: "Data retrieved successfully",
// };

export const fetchImportSummaryAction =
  (importSummary: any) => async (dispatch: Dispatch , getState: any) => {
    dispatch({ type: START_LOADING_IMPORT_SUMMARY });
    try {
      const currentState = getState();
      const params = generateQueryStringForUsers(
        currentState.Filters.importsSummaryDataFilter
      );
      const { data } = await apiLibrary.Imports.getImportSummary(importSummary.id, params);
      // localStorage.setItem("importId", data.program.id);
      dispatch({
        type: GET_IMPORT_SUMMARY,
        payload: data,
      });
      if (importSummary.path === "directory") {
        dispatch(openImportSummaryAction(importSummary.id));
      }
      dispatch({ type: ERROR_LOADING_IMPORT_SUMMARY });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch({ type: STOP_LOADING_IMPORT_SUMMARY });
    }
  };
