import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    className?: string;
}

export const DocumentImportIcon = ({
    fill = "#2C3236",
    className = "fill-[#2C3236] dark:fill-caption group-hover:fill-primary transition-all",
    ...restProps
}: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
        <path d="M0.666992 2.33334C0.666992 1.22877 1.56242 0.333344 2.66699 0.333344H6.39085C6.74447 0.333344 7.08361 0.473819 7.33366 0.723868L10.9431 4.33334C11.1932 4.58339 11.3337 4.92253 11.3337 5.27615V7.66668C11.3337 8.03487 11.0352 8.33334 10.667 8.33334C10.2988 8.33334 10.0003 8.03487 10.0003 7.66668V5.27615L6.39085 1.66668H2.66699C2.2988 1.66668 2.00033 1.96515 2.00033 2.33334V11.6667C2.00033 12.0349 2.2988 12.3333 2.66699 12.3333H4.00033C4.36852 12.3333 4.66699 12.6318 4.66699 13C4.66699 13.3682 4.36852 13.6667 4.00033 13.6667H2.66699C1.56242 13.6667 0.666992 12.7712 0.666992 11.6667V2.33334Z" className={className} />
        <path d="M6.94313 9.66668L7.80506 8.80475C8.06541 8.5444 8.06541 8.12229 7.80506 7.86194C7.54471 7.60159 7.1226 7.60159 6.86225 7.86194L4.86225 9.86194C4.6019 10.1223 4.6019 10.5444 4.86225 10.8047L6.86225 12.8047C7.1226 13.0651 7.54471 13.0651 7.80506 12.8047C8.06541 12.5444 8.06541 12.1223 7.80506 11.8619L6.94313 11L12.0003 11C12.3685 11 12.667 10.7015 12.667 10.3333C12.667 9.96515 12.3685 9.66668 12.0003 9.66668L6.94313 9.66668Z" className={className} />
    </svg>
);
export default DocumentImportIcon;





