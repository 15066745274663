import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { handlePaintingOfVectorRegionShapeLayersOnMap } from "../handlers";
import calculateMultiEntityZoomLevel from "utils/calculateMultiEntityZoomLevel";
import { generatingLayerId } from "Components/Communities/MapBox/utils";

const usePaintAllLayersOnMap = (
  currentQuestionEle: any,
  answer: any,
  map: any,
  isMapFullyLoaded: any,
  LAYER_SELECTED_COLOR: any,
  LAYER_DEFAULT_COLOR: any,
  popups: any[],
  setPopups: Dispatch<SetStateAction<any[]>>,
  setNewPopup: Dispatch<SetStateAction<any[]>>,
  isEditMode: boolean
) => {
  const [clickedLayer, setClickedLayer] = useState<any>(null);

  useEffect(() => {
    if (
      currentQuestionEle &&
      currentQuestionEle.selectedShapes &&
      currentQuestionEle.selectedShapes.length > 0 &&
      map &&
      isMapFullyLoaded
    ) {
      // removeCustomPaintedLayers(map.getStyle().layers, map);
      // painting the shapes on map
      currentQuestionEle.selectedShapes.forEach((layer: any) => {
        const item: any = {
          item: layer,
          itemType: layer.type,
          status: true,
          clickable: isEditMode ? true : false,
        };

        const isItemHighlighted = answer?.find((answerLayer: any) => {
          return answerLayer.id == item.item.id;
        });

        // paint layers on map
        if (isItemHighlighted) {
          handlePaintingOfVectorRegionShapeLayersOnMap(
            item,
            map,
            {},
            isItemHighlighted ? LAYER_SELECTED_COLOR : LAYER_DEFAULT_COLOR,
            (layerItem: any) => setClickedLayer(layerItem),
            popups,
            setPopups,
            setNewPopup
          );
        }
      });

      // fiting the map zoom level according to the painted shapes
      const calculation = calculateMultiEntityZoomLevel(answer, map);

      if (calculation?.bounds && calculation?.maxZoom) {
        map.fitBounds({
          ...calculation.bounds,
          maxZoom: calculation.maxZoom,
        });
      }
    }
  }, [map, isMapFullyLoaded, currentQuestionEle, isEditMode]);

  return [clickedLayer, setClickedLayer];
};

export { usePaintAllLayersOnMap };

function removeCustomPaintedLayers(layers: any[], map: any) {
  for (let index = layers.length - 1; index >= 0; index--) {
    const layer = layers[index];

    if (!layer.id.includes("-layer")) {
      break;
    }

    map.removeLayer(layer.id);
    if (map.getSource(layer.source)) {
      map.removeSource(layer.source);
    }
  }
}
