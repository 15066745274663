import { GET_OBJECT_PROFILE, RESET_OBJECT_PROFILE } from "./action-types";

import { initialState } from "./initialState";

export const objectProfileReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case GET_OBJECT_PROFILE:
      return {
        ...action.payload,
        isLoading: false,
      };
    case RESET_OBJECT_PROFILE:
      return initialState;
    default:
      return state;
  }
};
