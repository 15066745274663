import {
  Banner,
  ProfileActions,
  ProfileImage,
  ProfileNavigationLinks,
} from "./components";

export const Profile = () => {
  return (
    <>
      <Banner />
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-6 pt-6 bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="flex items-center justify-between w-full gap-6 px-9">
          <ProfileImage />
          <ProfileActions />
        </div>
        <ProfileNavigationLinks />
      </div>
    </>
  );
};
