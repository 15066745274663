import numeral from "numeral";

const formatValue = (value: any) => {
  if (value >= 1000) {
    return numeral(value).format("0.0a");
  } else {
    return value.toString();
  }
};

export default formatValue;