import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeActiveSeasonsHistoryModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Table } from "Components/ProgramStatusHistory";
import { IfetchProgramStatusHistory } from "store/programStatusHistory/initialState";
import { ProgramStatusHistoryTable } from "hooks/useTable";
import { fetchProgramStatusHistoryAction } from "store/programStatusHistory/reducer.actions";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import DownloadIcon from "assets/icons/HeroIcons/DownloadIcon";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface ActiveSeasonsHistoryModalProps {}

export const ActiveSeasonsHistoryModal: React.FC<
  ActiveSeasonsHistoryModalProps
> = () => {
  const { activeSeasonsHistoryModal } = useSelector(
    (state: RootState) => state.modals
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  useEffect(() => {
    dispatch(fetchProgramStatusHistoryAction(activeSeasonsHistoryModal?.data));
  }, []);

  const { data, stateIs } = useSelector<RootState, IfetchProgramStatusHistory>(
    (state) => state?.programStatusHistory
  );

  const {
    flexRender,
    columnVisibility,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ProgramStatusHistoryTable(data?.history);

  const handleClose = () => {
    dispatch(closeActiveSeasonsHistoryModalAction());
  };

  const handleDownload = async () => {
    if (data?.history.length === 0) {
      Toasts.error("No Record Found");
      return;
    }
    try {
      const res = await apiLibrary.file.fileDownload(
        "program_status_history",
        data?.fields,
        [],
        activeSeasonsHistoryModal?.data
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `program_status_history_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return (
    <Modal
      open={activeSeasonsHistoryModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start w-[700px] items-start rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="w-full flex justify-end pt-3 px-3">
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>

            <div className="pb-6 px-6 flex flex-col items-start self-stretch justify-start ">
              <div className="w-full flex justify-between items-center mt-[-10px] mb-2">
                <p className="text-xl font-semibold text-left text-textMid dark:text-textMain whitespace-nowrap">
                  History of Active Seasons
                </p>

                <button
                  onClick={handleDownload}
                  className="flex items-center justify-center pr-2 gap-3 text-sm dark:text-textMain"
                >
                  <DownloadIcon fill="#2C3236" width={24} height={24} />
                  Download
                </button>
              </div>

              <div className="flex flex-col items-start self-stretch justify-start overflow-x-hidden overflow-y-auto px-1 min-h-[45vh] max-h-[60vh] w-full dark:bg-secondaryLight bg-bgWhite">
                {stateIs == "Idle" && data?.history?.length === 0 ? (
                  <NoRecordFound />
                ) : stateIs === "Pending" ? (
                  <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                    <TailSpin
                      height="50"
                      width="50"
                      color="#005C89"
                      ariaLabel="tail-spin-loading"
                      radius="2"
                      wrapperStyle={{}}
                      wrapperClass="tailspin-loader"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Table
                    flexRender={flexRender}
                    table={table}
                    columns={columnsConfig}
                    columnVisibility={columnVisibility}
                    setSorting={setSorting}
                    sorting={sorting}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
