import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
  className?: string;
}

export const ArrowDownIcon = ({
  fill = "#6F747B",
  isActive,
  className,
  ...restProps
}: IconProps) => (
  <svg
    {...restProps}
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-4 h-4 arrow_icon"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 1.33398C8.36821 1.33398 8.66669 1.63246 8.66669 2.00065V12.3912L12.1953 8.86258C12.4556 8.60223 12.8777 8.60223 13.1381 8.86258C13.3984 9.12293 13.3984 9.54504 13.1381 9.80539L8.47142 14.4721C8.3464 14.5971 8.17683 14.6673 8.00002 14.6673C7.82321 14.6673 7.65364 14.5971 7.52862 14.4721L2.86195 9.80539C2.6016 9.54504 2.6016 9.12293 2.86195 8.86258C3.1223 8.60223 3.54441 8.60223 3.80476 8.86258L7.33335 12.3912L7.33335 2.00065C7.33335 1.63246 7.63183 1.33398 8.00002 1.33398Z"
      className={className}
    />
  </svg>
);
export default ArrowDownIcon;
