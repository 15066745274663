interface GeoJSONPoint {
  type: "Point";
  coordinates: [number, number];
}

interface GeoJSONMultiPoint {
  type: "MultiPoint";
  coordinates: [number, number][];
}

interface GeoJSONLineString {
  type: "LineString";
  coordinates: [number, number][];
}

interface GeoJSONMultiLineString {
  type: "MultiLineString";
  coordinates: [number, number][][];
}

interface GeoJSONPolygon {
  type: "Polygon";
  coordinates: [number, number][][]; 
}

interface GeoJSONMultiPolygon {
  type: "MultiPolygon";
  coordinates: [number, number][][][];
}

interface GeoJSONGeometryCollection {
  type: "GeometryCollection";
  geometries: GeoJSONGeometry[];
}

type GeoJSONGeometry =
  | GeoJSONPoint
  | GeoJSONMultiPoint
  | GeoJSONLineString
  | GeoJSONMultiLineString
  | GeoJSONPolygon
  | GeoJSONMultiPolygon
  | GeoJSONGeometryCollection;

interface Feature {
  type: "Feature";
  geometry: GeoJSONGeometry;
  properties: any;
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
}

interface FeatureCollection {
  type: "FeatureCollection";
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
  features: Feature[];
}

export function validateGeoJSON(input: FeatureCollection | Feature): boolean {
  if (input === null) return false;

  if (input.type === "FeatureCollection") {
    return input.features.every(validateFeature);
  } else if (input.type === "Feature") {
    return validateFeature(input);
  } else {
    return false;
  }
}

function validateFeature(feature: Feature): boolean {
  return validateGeometry(feature.geometry);
}

function validateGeometry(geometry: GeoJSONGeometry): boolean {
  switch (geometry.type) {
    case "Point":
      return validateCoordinates(geometry.coordinates);

    case "MultiPoint":
      return geometry.coordinates.every((item: any) => {
        return validateCoordinates(item);
      });

    case "LineString":
      return geometry.coordinates.every((item: any) => {
        return validateCoordinates(item);
      });

    case "MultiLineString":
      return geometry.coordinates.every((ring: any[]) =>
        ring.every((item: any) => {
          return validateCoordinates(item);
        })
      );

    case "Polygon":
      return geometry.coordinates.every((ring: any[]) =>
        ring.every((item: any) => {
          return validateCoordinates(item);
        })
      );

    case "MultiPolygon":
      return geometry.coordinates.every((polygon: any[][]) =>
        polygon.every((ring: any[]) =>
          ring.every((item: any) => {
            return validateCoordinates(item);
          })
        )
      );

    case "GeometryCollection":
      return geometry.geometries.every((item: any) => validateGeometry(item));

    default:
      return false;
  }
}

function validateCoordinates(coords: [number, number]): boolean {
  return (
    coords[0] >= -180 && coords[0] <= 180 && coords[1] >= -90 && coords[1] <= 90
  );
}