import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const UsersIcon = ({ fill = "#2C3236", isActive, ...restProps }: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M9 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm3-1a5 5 0 1 0 0 8 5 5 0 1 0 0-8Zm1.334 1.505C13.758 5.239 14 6.09 14 7s-.242 1.76-.666 2.495a3 3 0 1 0 0-4.99ZM9 15a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5Zm7 5h4a5 5 0 0 0-6.001-4.9A6.977 6.977 0 0 1 16 20Zm-4-6.326A7 7 0 0 0 2 20v1a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-1a7 7 0 0 0-10-6.326Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UsersIcon;
