export const GET_RECIPIENTS = "GET_RECIPIENTS";
export const RESET_RECIPIENTS = "RESET_RECIPIENTS";
export const APPEND_NEW_MESSAGE_IN_CONVERSATION = "APPEND_NEW_MESSAGE_IN_CONVERSATION"

// Contact Persons
export const GET_CONTACT_PERSONS = "GET_CONTACT_PERSONS";
export const RESET_CONTACT_PERSONS = "RESET_CONTACT_PERSONS";
export const RESET_UNREAD_COUNTER = "RESET_UNREAD_COUNTER";

// LOADING
export const START_LOADING_RECIPIENTS = 'START_LOADING_RECIPIENTS';
export const STOP_LOADING_RECIPIENTS = 'STOP_LOADING_RECIPIENTS';
export const ADD_CONTACT_FROM_ORGANIZATIONS = 'ADD_CONTACT_FROM_ORGANIZATIONS';