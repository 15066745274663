import { TableBodyProps } from "../../types";
import parseArray from "utils/parseArray";
import { KEYS_TO_BE_REMOVED } from "../..";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useNavigate } from "react-router-dom";
import "../../../Table/Observations.scss";
import { fetchImportStatusDetailAction } from "store/importStatusDetail/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { fetchImportsFile } from "store/importsViewData/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const TableBody = ({
  table,
  columnVisibility,
  // getImportsFileDataActions,
  flexRender,
}: TableBodyProps) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { data, stateIs } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );

  return (
    <tbody>
      {table.getRowModel().rows?.map((row: any, index: number) => {
        let programId = data?.importedFileData?.find(
          (item) => item?.id === row?.original?.id
        )?.programName?.id;
        return (
          <tr
            key={index}
            className="w-full cursor-pointer Close item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
            style={{ height: "66px" }}
          >
            {parseArray(row?.getVisibleCells(), [""]).map(
              (cell: Cell<any, any>) => (
                <Column
                  hoverEffect="item_hover_effect"
                  flexRender={flexRender}
                  cell={cell}
                  columnVisibility={columnVisibility}
                  programId={programId !== undefined ? programId : null}
                  id={row.original.id}
                />
              )
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
