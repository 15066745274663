import { panelsTitle, panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import panelSettings from "./panelSettings";

import { GPS_INFO_QUESTIONS_NAME } from "../panelsQuestionsName";
import { GPS_INFO_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";

import { Serializer } from "survey-core";

export default {
  name: panelsName.GPS_INFORMATION,
  title: panelsTitle.GPS_INFO,
  iconName: "icon-gps",
  elementsJSON: [
    {
      type: "html",
      html: `<h1 class="panels-title">${panelsTitle.GPS_INFO}</h1>`,
    },
    {
      type: "radiogroup",
      name: GPS_INFO_QUESTIONS_NAME.CoordinatesFormat,
      title: "Coordinates Format",
      choices: GPS_INFO_QUESTIONS_CHOICES.CoordinatesFormat,
      isRequired: true,
    },
    {
      type: "html",
      html: `<div>
      <button class="get-gps-data-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13604 4.63604C9.65076 1.12132 15.3492 1.12132 18.864 4.63604C22.3787 8.15076 22.3787 13.8492 18.864 17.364L14.6209 21.607C13.4496 22.7783 11.5514 22.7793 10.3795 21.6074L6.13604 17.364C2.62132 13.8492 2.62132 8.15076 6.13604 4.63604ZM12.5 14C14.1569 14 15.5 12.6569 15.5 11C15.5 9.34315 14.1569 8 12.5 8C10.8431 8 9.5 9.34315 9.5 11C9.5 12.6569 10.8431 14 12.5 14Z" fill="white"/>
      </svg>Get GPS data</button>
      </div>`,
    },
    {
      type: "text",
      inputType:"number",
      name: GPS_INFO_QUESTIONS_NAME.Latitude,
      title: "Latitude",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "text",
      inputType:"number",
      name: GPS_INFO_QUESTIONS_NAME.Longitude,
      title: "Longitude",
      placeholder: "Type",
      startWithNewLine: false,
      isRequired: true,
    },
    {
      type: "html",
      html: `<div>
      <button class="pick-on-map-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_2167_236744)">
        <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 2V4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 20V22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 12H22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 12H4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2167_236744">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>Pick on Map</button>
      
      </div>`,
      startWithNewLine: false,
    },
    {
      type: "radiogroup",
      name: GPS_INFO_QUESTIONS_NAME.AltitudeFormat,
      title: "Altitude Format",
      choices: GPS_INFO_QUESTIONS_CHOICES.AltitudeFormat,
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: GPS_INFO_QUESTIONS_NAME.Altitude,
      title: "Altitude",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: GPS_INFO_QUESTIONS_NAME.LocationAccuracy,
      title: "Location Accuracy",
      placeholder: "Type",
      isRequired: true,
    },
    {
      type: "text",
      inputType: "number",
      name: GPS_INFO_QUESTIONS_NAME.AltitudeAccuracy,
      title: "Altitude Accuracy",
      placeholder: "Type",
      isRequired: true,
    },
  ],
  ...panelSettings(
    panelsName.GPS_INFORMATION,
    GPS_INFO_QUESTIONS_NAME,
    Serializer
  ),
};

export const gpsIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4472 3.10557C15.1657 2.96481 14.8343 2.96481 14.5528 3.10557L9 5.88197L4.89443 3.82918C3.56462 3.16428 2 4.13127 2 5.61803V11C2 11.5523 2.44772 12 3 12C3.55228 12 4 11.5523 4 11V5.61803L8 7.61803V9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9V7.61803L14 5.61803V16.9425C14 17.289 14.1795 17.6109 14.4743 17.7931L15 18.118L19.1056 20.1708C20.4354 20.8357 22 19.8687 22 18.382V7.61803C22 6.86049 21.572 6.16796 20.8944 5.82918L15.4472 3.10557ZM16 5.61803L20 7.61803V18.382L16 16.382V5.61803Z" fill="#909090"/>
<path d="M8.54935 12.1409C8.7364 11.953 9.03967 11.953 9.22672 12.1409L11.8597 14.7856C12.0468 14.9735 12.0468 15.2781 11.8597 15.466C11.6422 15.6845 11.379 15.8764 11.0944 16.084C10.6395 16.4156 10.1302 16.787 9.66605 17.3698L9.2446 19.063C9.20249 19.2322 9.11539 19.3867 8.99262 19.51L7.6495 20.8591C7.46245 21.047 7.15918 21.047 6.97213 20.8591L5.7663 19.6479L3.70712 21.7071C3.3166 22.0976 2.68343 22.0976 2.29291 21.7071C1.90238 21.3166 1.90238 20.6834 2.29291 20.2929L4.35522 18.2306L3.1403 17.0103C2.95325 16.8224 2.95325 16.5178 3.1403 16.3299L4.48343 14.9808C4.6062 14.8575 4.76002 14.7701 4.92846 14.7278L6.61425 14.3044C7.23292 13.8303 7.61199 13.3235 7.95214 12.8687C8.15114 12.6026 8.33681 12.3544 8.54935 12.1409Z" fill="#909090"/>
</svg>`;

const getGpsDataBtnIcon = ``;
