import getAllForms from "./getAllForms";
import getAllPrograms from "./getAllPrograms";
import addNewForm from "./addNewForm";
import getFormDetails from "./getFormDetails";
import changeFormStatus from "./changeFormStatus";
import editForm from "./editForm";
import updateFormFields from "./updateFormFields";
import useFormAsTemplate from "./useFormAsTemplate";
import deleteForm from "./deleteForm";
import archiveOrRestoreForm from "./archiveOrRestoreForm";
import getAllProgramsForForms from "./getAllProgramsForForms";
import getSurveyJsFormDetails from "./getSurveyJsFormDetails";
const Forms = {
  getAllForms,
  getAllPrograms,
  addNewForm,
  editForm,
  getFormDetails,
  changeFormStatus,
  useFormAsTemplate,
  updateFormFields,
  deleteForm,
  getAllProgramsForForms,
  archiveOrRestoreForm,
  getSurveyJsFormDetails
};

export default Forms;
