// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, AddReportBtn } from "./components";
// Store Utils
import { useNavigate } from "react-router-dom";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import useRoles from "hooks/roles";

const generateDynamicReportsLinks = () => {
  return [{ path: `/reports/list`, label: `Reports` }];
};

export const Header = () => {
  const navigate = useNavigate();
  const { reports } = usePermissions();
  const { isExternalDataEndReviewer } = useRoles();

  const handleAddReport = () => {
    navigate("/reports/add");
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={generateDynamicReportsLinks()} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            {!isExternalDataEndReviewer() && reports.canCreateReports && (
              <AddReportBtn handleAddReport={handleAddReport} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
