import { apiClient } from "../config";

async function getAllJoinRequests(queryParams: any) {
  const programId = Number(queryParams.programId);
  const res = await apiClient.get(
    `/admin/programs/${programId}/join-requests`,
    {
      params: queryParams,
    }
  );
  return res.data;
}

export default getAllJoinRequests;
