import { GET_PROTOCOL_PROFILE, RESET_PROTOCOL_PROFILE } from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
export const fetchProtocolProfileAction =
  (protocolId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.Protocols.getProtocolProfile(protocolId);

      dispatch({
        type: GET_PROTOCOL_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/protocols/list";
    }
  };
export const resetProtocolProfileAction = () => {
  return {
    type: RESET_PROTOCOL_PROFILE,
  };
};
