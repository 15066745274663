import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

import { point, featureCollection } from "@turf/turf";
import * as turf from '@turf/turf';

import { generatingLayerId } from "../utils";
import { calculateMultiEntityZoomLevel } from "../workerMehods";

const useCreateMarkers = (
  data: any,
  map: any,
  isMapFullyLoaded: any,
  mapType: string
) => {

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (!map || !isMapFullyLoaded) return;
    // removing already created markers from
    if (markers.length > 0) {
      markers.forEach((m: any) => m.remove());
      setMarkers(() => []);
    }

    // reseting the already created clusters
    removeAllCustomLayers(map);

    if (data === null) {
      map.flyTo({
        center: [-156.44636531857395, 62.40265300551266],
        zoom: 2.6339272096365725,
      });
      return
    };

    // creating markers
    if (mapType === "markers") {
      createMarkers(data, map, setMarkers);
    } else if (
      mapType === "cluster_with_numbers" ||
      mapType === "cluster_without_numbers"
    ) {
      const isWithNumbers = mapType === "cluster_with_numbers";
      // creating clusters
      createClusters(data, map, isWithNumbers);
    }

    if (data && data.length !== 0) {
      const calculation = calculateMultiEntityZoomLevel(data);


      if (calculation && calculation?.bounds && calculation?.maxZoom) {
        map.fitBounds(calculation.bounds, {
          padding: 40,
          maxZoom: 14,
          duration: 2000
        });
      }
    } else {
      
      map.flyTo({
        center: [-156.44636531857395, 62.40265300551266],
        zoom: 2.6339272096365725,
      });

    }


  }, [data, map, isMapFullyLoaded, mapType]);

  return null;
};

export { useCreateMarkers };

function coordToGeoJSON(coord: [number, number], properties?: any) {
  // Create a point feature using Turf.js
  const pointFeature = point(coord);

  if (properties) {
    pointFeature.properties = properties;
  }

  // Create a feature collection with the point feature
  const geoJSONFeatureCollection = featureCollection([pointFeature]);

  return geoJSONFeatureCollection;
}
function generateProperties(count: number) {
  if (count < 100) {
    return {
      radius: 20,
      textSize: 14,
      color: "#11A3E2",
    };
  } else if (count < 1000) {
    return {
      radius: 40,
      textSize: 18,
      color: "#42BBAC",
    };
  } else {
    return {
      radius: 60,
      textSize: 20,
      color: "#BF200B",
    };
  }
}
function formatNumber(num: any) {
  if (num < 1000) {
    return num.toString(); // Return the number as is if less than 1000
  }
  const units = ["k", "m", "b", "t"]; // Units for thousands, millions, billions, trillions
  let unitIndex = -1; // Index for units array

  // Reduce the number and increase the unit index until the number is small enough
  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
  }

  // Format the number to one decimal place if not an integer
  if (num % 1 !== 0) {
    num = num.toFixed(1);
  } else {
    num = Math.floor(num);
  }

  // Return the formatted number with the appropriate unit
  return num + units[unitIndex];
}

function createMarkers(data: any, map: any, setMarkers: any) {
  const newMarkers: any = [];
  data.forEach((dataEle: any) => {
    const { locations } = dataEle;
    locations.forEach((location: any) => {
      if (!location) {
        return;
      }

      const feature = location?.geometries[0];

      if (feature?.type === "Point" && feature?.coordinates) {
        // creating new marker
        const marker = new mapboxgl.Marker({ draggable: false })
          .setLngLat(feature.coordinates)
          .addTo(map);

        newMarkers.push(marker);
      }
    });
  });

  setMarkers(newMarkers);
}
function createClusters(data: any, map: any, isWithNumbers: boolean) {
  data.forEach((ele: any, index: number) => {
    const location = ele.locations;

    if (location[0]) {
      const featureCollection = coordToGeoJSON(
        ele.locations[0]?.geometries[0].coordinates,
        { count: formatNumber(ele.observationsCount) }
      );

      const { sourceId, layerId } = generatingLayerId("point", index);
      const { radius, textSize, color } = generateProperties(
        ele.observationsCount
      );

      // Add a source for circle and text
      map.addSource(sourceId, {
        type: "geojson",
        data: featureCollection,
      });

      // Add a circle layer
      map.addLayer({
        id: layerId,
        type: "circle",
        source: sourceId,
        paint: {
          "circle-radius": radius, // Radius of the circle
          "circle-color": color, // Color of the circle
        },
      });

      // Add a symbol layer for displaying numbers
      isWithNumbers &&
        map.addLayer({
          id: layerId + "#text",
          type: "symbol",
          source: sourceId,
          layout: {
            "text-field": ["get", "count"], // Use the 'number' property from the source
            "text-size": textSize,
          },
          paint: {
            "text-color": "#ffffff", // Color of the text
          },
        });
    }
  });
}
function removeAllCustomLayers(map: any) {
  const allLayers = map.getStyle().layers;

  for (let index = allLayers.length - 1; index > 0; index--) {
    const element = allLayers[index];

    if (!element.id.includes("point#")) {
      break;
    }

    map.removeLayer(element.id);
    map.removeSource(element.source);
  }
}
