import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddBehaviorBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditBehaviorsModalAction } from "store/modals";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/behaviors/list`, label: `Behaviors` }];
  }
};
export const Header = () => {
  const { behaviorsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { behaviors } = usePermissions();
  const dispatch = useDispatch();
  const dynamicBehaviorsLinks = generateDynamicBreadcrumbLinks(
    behaviorsFilter.status
  );

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicBehaviorsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {behaviors.canCreateBehaviors && (
                <AddBehaviorBtn
                  handleAddBehaviorModel={() =>
                    dispatch(openAddOrEditBehaviorsModalAction())
                  }
                  label={"Add Behavior"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
