import { useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
// import { DrawShapesDropdown, ToggleButton } from "../components";

import LegendIcon from "assets/images/legend.svg";
import CloseIcon from "view/components/Modals/OrganizationColumnModal/CloseIcon";

const useInitializeCustomControlForLayerListing = (
  map: any,
  selectedLayersItems: any
) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if (map) {
      const layersList = selectedLayersItems
        ? selectedLayersItems.filter(
            (l: any) => (l.itemType === "vector" || l.itemType === "raster") && l.status === true
          )
        : null;

      ReactDOM.render(
        <>
          <ToggleButton handleClick={toggleDropdownListener} />
          <RasterAndVectorLayersListingDropdown
            isVisible={isDropdownVisible}
            handleClickOnCloseBtn={toggleDropdownListener}
            data={layersList}
          />
        </>,
        document.getElementById("customControlForLayersListing")
      );
    }

    return () => {
      const container = document.getElementById(
        "customDrawShapesControlContainer"
      );
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
    };
  }, [map, isDropdownVisible, selectedLayersItems]);

  const toggleDropdownListener = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return null;
};

export { useInitializeCustomControlForLayerListing };

const ToggleButton = ({ handleClick }: any) => {
  return (
    <button onClick={handleClick} type="button">
      <img src={LegendIcon} className="mx-auto" />
    </button>
  );
};

const RasterAndVectorLayersListingDropdown = ({
  isVisible,
  handleClickOnCloseBtn,
  data,
}: any) => {
  return (
    <div
      className=" bg-bgWhite h-[592px] w-[320px] absolute top-0 right-[38px] rounded shadow-md "
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <div className="h-full w-full p-6 relative">
        <div
          className="absolute top-4 right-4 cursor-pointer flex justify-center items-center"
          onClick={handleClickOnCloseBtn}
        >
          <CloseIcon className=" mx-auto" width={24} height={24} />
        </div>
        <h2 className="font-Overpass text-xl font-semibold text-secondaryMid mb-4">
          Legend
        </h2>
        {data ? (
          <ul className="overflow-y-auto h-[95%] scroll">
            {data.map((l: any) => {
              return (
                <li className="mb-3" key={l.item?.id?.toString()}>
                  <h3 className="font-Overpass text-[15px] font-normal text-secondaryMid mb-2">
                    {l.item.name}
                  </h3>
                  <img src={l?.item?.legendFilePath} />
                </li>
              );
            })}
          </ul>
        ) : (<div className="h-[90%] w-full flex justify-center items-center">
          <h1 className="font-Overpass text-xl font-semibold text-secondaryMid">There is no legend!</h1>
        </div>)}
      </div>
    </div>
  );
};

