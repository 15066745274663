import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const TrashIcon = ({ fill, isActive, ...restProps }: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
    {...restProps}
  >
    <path
      fill={fill}
     className={`dark:${
        isActive
          ? "fill-primary"
          : "fill-caption group-hover:dark:fill-primary"
      }`}
      fillRule="evenodd"
      d="M8 4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-.069l-.8 11.214A3 3 0 0 1 16.137 22H7.862a3 3 0 0 1-2.992-2.786L4.069 8H4a1 1 0 0 1 0-2h4V4Zm2 2h4V4h-4v2ZM6.074 8l.79 11.071a1 1 0 0 0 .998.929h8.276a1 1 0 0 0 .997-.929L17.926 8H6.074ZM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TrashIcon;
