export const dashboardWidgetConstants = {
  totalCounts: { VALUE: "total_counts" },
  qaProgress: { VALUE: "qa_progress" },
  activeUsers: { VALUE: "active_users" },
  observations: { VALUE: "observations" },
  observationsByLocation: { VALUE: "observations_by_location" },
  notifications: { VALUE: "notifications" },
  qaNotifications: {
    VALUE: "qa_notifications",
  },
  program: {
    VALUE: "program",
  },
  community: {
    VALUE: "community",
  },
  form: {
    VALUE: "form",
  },
  reportsNotifications: {
    VALUE: "report_notifications",
  },
  mostObservedObjects: {
    VALUE: "most_observed_objects",
  },
  mostObservedSpecies: {
    VALUE: "most_observed_species",
  },
  observationsByRegion: {
    VALUE: "observations_by_region",
  },
  cumulativeObservations: {
    VALUE: "cumulative_observations",
  },
};