import { PointPickerState } from "../stateConstructors";
import mergeId from "../utils/mergeId";

function convertJsonOfUpComingPointPicker(question: any) {
  if(!question){
    return new PointPickerState();
  }

    const {
      centerPoint,
      selectMultiple,
      selectedLayers,
      selectedPoints,
      id,
      title,
      type,
      name,
      description,
      isRequired,
      zoomLevel
    } = question;
  
    const selectedPointsIdToHighlight = selectedPoints?.map((s: any) => {
      return {
        ...s,
        id: mergeId(id, s.id),
      };
    });
    const mapCenterPoint = {
      longitude: centerPoint && centerPoint[0],
      latitude: centerPoint && centerPoint[1],
    };
  
    const structuredData = {
      _id: id,
      name,
      title,
      type,
      description,
      isRequired,
      selectedPointsIdToHighlight,
      mapCenterPoint,
      zoomLevel:zoomLevel,
      ableToCheckMultiple: selectMultiple,
      selectedDefaultLayers: selectedLayers,
    };
  
    return structuredData;
  };

export {convertJsonOfUpComingPointPicker};