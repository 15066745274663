import { pascalCase } from "change-case";
import parseId from "../utils/parseId";
import splitCamelCaseToString from "../utils/splitCamelCaseToString";
import { v4 as uuidv4 } from "uuid";
import generateUniqueId from "generate-unique-id";
import { uniqueIdGenertorInNumber } from "../utils/structureFormJSON";

function convertJsonOfShapePicker(question: any, data: any) {
  const {
    allCommunityLayers,
    selectedLayersIdToHighlight,
    mapCenterPoint,
    selectedDefaultLayers,
    id,
    ableToCheckMultiple,
    name,
    title,
    description,
    isRequired,
    zoomLevel,
  } = data;

  const parseIdToNumber = (id: any) => Number(parseId(id, "-"));

  const selectedShapes = selectedLayersIdToHighlight.map((item: any) => {
    return {
      ...item,
      id: parseIdToNumber(item.id),
    };
  });
  const selectedLayers = selectedDefaultLayers.map((layer: any) => ({
    ...layer,
    id: parseIdToNumber(layer.id),
  }));

  const structuredData = {
    id,
    name: question.name,
    title: title ?? splitCamelCaseToString(question.name),
    type: question.type,
    description,
    isRequired,
    selected_shapes: selectedShapes,
    center_point: [mapCenterPoint.longitude, mapCenterPoint.latitude],
    select_multiple: ableToCheckMultiple,
    selected_layers: selectedLayers,
    zoomLevel: zoomLevel,
    elementId: uuidv4(),
    questionId: uniqueIdGenertorInNumber(),
    selected_options: selectedShapes.map((s: any) => s.id),
    selected_layers_ids: selectedLayers.map((l: any) => l.id),
  };

  return structuredData;
}

export { convertJsonOfShapePicker };
