import { TableHeadProps } from "../../types";
import { ColumnHeading } from "../index";

export const TableHead = ({
  table,
  sorting,
  flexRender,
  handleClickOnColumnHead,
  setHoveredHeader,
  hoveredHeader,
}: TableHeadProps) => {
  return (
    <thead className="table_row_header w-full sticky top-0 h-[48px] z-20  dark:bg-bgtetriary">
      <tr
        className="w-full cursor-pointer dark:border-none"
        style={{ height: "49px" }}
      >
        {table.getHeaderGroups().map((headerGroup: any) => {
          return (
            <ColumnHeading
              headerGroup={headerGroup}
              sorting={sorting}
              flexRender={flexRender}
              handleClickOnHead={handleClickOnColumnHead}
              setHoveredHeader={setHoveredHeader}
              hoveredHeader={hoveredHeader}
            />
          );
        })}
      </tr>
    </thead>
  );
};
