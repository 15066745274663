import { Dispatch } from "redux";
import {
  ERROR_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
  GET_ALL_QUALITY_ASSURANCE_OBSERVATIONS,
  RESET_DATA,
  START_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
  STOP_LOADING_QUALITY_ASSURANCE_OBSERVATIONS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchQualityAssuranceObservationsAction =
  (communityId?: any, programId?: any) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_QUALITY_ASSURANCE_OBSERVATIONS });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.qualityAssuranceObservationsFilter
      );

      const query = appendCommunityAndProgramIdInParams(
        communityId,
        programId,
        queryString
      );

      const isExternalReviewer =
        currentState?.auth?.roles?.includes("external_reviewer");
      if (isExternalReviewer) {
        const { data } =
          await apiLibrary.QualityAssurance.getAllQualityAssuranceObservationsForExternalReviewer(
            query
          );
        dispatch({
          type: GET_ALL_QUALITY_ASSURANCE_OBSERVATIONS,
          payload: data,
        });
      } else {
        const { data } =
          await apiLibrary.QualityAssurance.getAllQualityAssuranceObservations(
            query
          );
        dispatch({
          type: GET_ALL_QUALITY_ASSURANCE_OBSERVATIONS,
          payload: data,
        });
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log(
        "An error occurred while fetching the quality assurance observations:",
        error
      );
      dispatch({ type: ERROR_LOADING_QUALITY_ASSURANCE_OBSERVATIONS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_QUALITY_ASSURANCE_OBSERVATIONS });
      // dispatch(loadingState(false))
    }
  };

const appendCommunityAndProgramIdInParams = (
  communityId: any,
  programId: any,
  params: any
) => {
  let updatedParams = { ...params };

  if (programId) {
    updatedParams.programIds = programId.toString();
  }
  if (communityId) {
    updatedParams.communityIds = communityId.toString();
  }

  return updatedParams;
};
