function convertJsonOfUpComingDrawPolygon(question: any) {
    const {
      centerPoint,
      drawMultiple,
      enableSnapping,
      selectedLayers,
      id,
      title,
      type,
      name,
      description,
      isRequired,
      zoomLevel

    } = question;
  
    const mapCenterPoint = {
      longitude: centerPoint && centerPoint[0],
      latitude: centerPoint && centerPoint[1],
    };
  
    const structuredData = {
      _id: id,
      name,
      title,
      type,
      description,
      isRequired,
      mapCenterPoint,
      zoomLevel:zoomLevel,

      ableToCheckMultiple: drawMultiple,
      enableSnapping,
      selectedDefaultLayers: selectedLayers,
    };
  
    return structuredData;
  };
  
export {convertJsonOfUpComingDrawPolygon};