import { apiClient } from "../config";

async function getAllCommunities(queryParams: any) {
  
  const res = await apiClient.get(`/admin/communities`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllCommunities;
