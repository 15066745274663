import { apiClient } from "../config";

async function editCommunity(id: any, data: any) {

  const url = `/admin/communities/${id}`;
  const res = await apiClient.put(url, data);
  return res.data;
}

export default editCommunity;
