interface IFileDetails {
  large: string;
  original: string;
  thumb: string;
}

// Define an interface for the attachment
export interface IAttachment {
  file: IFileDetails;
  id: number;
  isStar: boolean;
  path: string;
  title: string;
}

// Define the main interface for the entire object
export interface IObject {
  attachments: IAttachment[];
  dateAdded: string;
  description: string;
  id: number;
  objectName: string;
  typeId: number;
  typeName: string;
}

export const initialState: IObject = {
  id: 0,
  typeId: 0,
  typeName: "",
  description: "",
  dateAdded: "",

  attachments: [],
  objectName: "",
};
