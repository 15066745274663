import React from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { NotificationBtns, PageHeading } from "./components";

// Store Utils
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Generate links dynamically

const generateDynamicBreadcrumbLinks = (notificationId: any) => {
  switch (true) {
    case Boolean(notificationId):
      return [
        { path: `/custom-notifications/list`, label: `Custom notifications` },
        {
          path: `/custom-notifications/${notificationId}/edit`,
          label: `Edit custom notification`,
        },
      ];
    default:
      return [
        { path: `/custom-notifications/list`, label: `Custom notifications` },
        { path: `/custom-notifications/add`, label: `Add custom notification` },
      ];
  }
};
interface HeadersProps {
  values?: any;
  handleSubmitEditMode: any;
}
export const Header : React.FC<HeadersProps> = ({values, handleSubmitEditMode}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  // Get Notification ID
  const { notificationId } = useParams<{
    notificationId: string;
  }>();
  const { state } = location;
  const notificationLinks = generateDynamicBreadcrumbLinks(
    notificationId ?? ""
  );

  // Handlers
  const handleCloseNotification = () => {
    navigate("/custom-notifications/list");
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 p-5 bg-bgWhite dark:bg-secondaryLight">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={notificationLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading notificationId={notificationId} />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <NotificationBtns
                handleCloseNotification={handleCloseNotification}
                state={state}
                values={values}
                handleSubmitEditMode={handleSubmitEditMode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
