import { apiClient } from "../config";

async function forgotPassword(phoneOrEmail: any, password?: string) {
  const requestData = {
    phone_or_email: phoneOrEmail,
    password: password,
  };

  const res = await apiClient.post(`/admin/forgot-password`, requestData);
  return res.data;
}

export default forgotPassword;
