import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { reUseSpeciesAndObjectsSchema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeReUseObjectsListModalAction } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import MultiSelect from "view/components/Multiselect";
import { fetchObjectsAction } from "store/objects";
import { useParams } from "react-router-dom";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */
export const ReuseObjectsListModal = () => {
  // State variables

  const { reUseObjectsListModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communityId } = useParams();

  const [allCommunities, setAllCommunities] = useState([]);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [formInitialValues, setFormInitialValues] = useState<any>({
    communityId: [],
  });

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const { data, message } =
        await apiLibrary.SpeciesAndObjects.reUseSpeciesAndObjectsList(
          communityId,
          "objects",
          {
            communityIds: values.communityId.map(
              (community: any) => community.value
            ),
          }
        );

      Toasts.success(message);

      dispatch(fetchObjectsAction(communityId));
      handleClose();
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeReUseObjectsListModalAction());
  };

  const fetchAllCommunities = useCallback(
    async (searchQuery?: string) => {
      try {
        const res = await apiLibrary.userProfile.getUsersFilterDropdowns(1,
          "communities",
          searchQuery
        );
        setAllCommunities(
          res.data.communities.map((community: any) => ({
            label: community?.name,
            value: community?.id,
          }))
        );
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching categories:", error);
      }
    },
    [allCommunities]
  );

  const handleAddCategorySearch = (query: string) => {
    fetchAllCommunities(query);
  };

  // Fetch all programs on component mount
  useEffect(() => {
    /**
     * Fetches all programs from the API.
     * @example
     * fetchAllCommunities();
     */

    if (reUseObjectsListModal.isOpen) {
      fetchAllCommunities();
    }
  }, [reUseObjectsListModal.isOpen]);
  return (
    <Modal
      open={reUseObjectsListModal.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={formInitialValues}
          validationSchema={reUseSpeciesAndObjectsSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[80vw] max-w-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid">
                        Reuse Objects List
                      </p>
                      <button title="close" onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
                    <p className="flex-grow w-[608px] text-sm text-left text-secondaryMid">
                      Select the community for which you want to use this
                      Objects list.
                    </p>
                    <ACommunityName
                      handleACommunityNameOnChange={(option: any) => {
                        console.log(option,'options data');
                        setFieldValue("communityId", option);
                      }}
                      errors={errors}
                      touched={touched}
                      categories={allCommunities}
                      values={values}
                      handleAddCategorySearch={handleAddCategorySearch}
                      isEditMode={false}
                      closeOnSelect={true}
                    />
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[608px] relative gap-1">
                      <p className="flex-grow w-[608px] text-sm text-left text-textMidLight">
                        Can't find the community you want to add? Create a new
                        one from the community directory.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />

                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const ACommunityName = ({
  touched,
  errors,
  handleACommunityNameOnChange,
  categories,
  values,
  handleAddCategorySearch,
  isEditMode,
  closeOnSelect,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const { communityId } = values;

  console.log(errors);
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
            <p className="flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ">
              Community Name
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
              touched.communityId && errors.communityId
                ? "border-accent_1Dark"
                : "border-lineDark dark:border-lineLight"
            }`}
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                fill="#9B9FA5"
              />
            </svg>
            <div className="flex flex-col items-start justify-start flex-grow w-full">
              <MultiSelect
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleAddCategorySearch(value);
                }}
                options={categories}
                defaultValue={{ value: "", label: "" }}
                isMulti={true}
                value={communityId}
                isDisable={false}
                onChange={
                  (option: any) => {
                    handleACommunityNameOnChange(option);
                    // setSelectedOption(option);
                  }
                  // handleCommunityChange(
                  //   selectedOptions,
                  //   setFieldValue
                  // )
                }
                closeOnSelect={closeOnSelect}
              />
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched.communityId && errors.communityId && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {errors?.communityId?.value}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
