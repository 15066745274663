import apiClient from "services/apiClient";

async function getTagsDropdown(searchQuery?: string, page?: number) {
  const params = {
    searchString: searchQuery,
    page: page,
  };

  const res = await apiClient.get(`/admin/tags/dropdown`, { params });
  return res.data;
}

export default getTagsDropdown;
