interface File {
  large: string;
  original: string;
  thumb: string;
}

interface Image {
  id: number;
  file: File;
}

interface ImportedBy {
  id: number;
  name: string;
  image: Image;
}

export interface Import {
  id: number;
  fileName: string;
  dataReceivedFrom: string;
  status: string;
  numberOfObservations: number;
  importedBy: ImportedBy;
  importedDate: string;
}

export interface Admin {
  id: number;
  name: string;
  personId: number;
  personImage?: string | null;
  programs: string[];
  date: string;
}

export interface ImportsData {
  fields: string[];
  imports: Import[];
  admins: Admin[];
  totalItems: number;
  totalPages: number;
}

enum FetchImportState {
  Idle = "Idle",
  Pending = "Pending",
  Error = "Error"
}

export interface FetchImports {
  data: ImportsData;
  message: string;
  stateIs: FetchImportState;
}

// Create an initial state object
const initialImportsState: FetchImports = {
  data: {
    fields: [],
    imports: [],
    admins: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: FetchImportState.Idle,
};

export default initialImportsState;
