interface FileType {
  large: string;
  original: string;
  thumb: string;
}

export interface Attachment {
  file: FileType;
  id: number;
  isStar: boolean;
  path: string;
  title: string;
}

interface Type {
  typeId: number;
  typeName: string;
}

export interface ISpecie {
  id: number;
  type: Type;
  description: string;
  dateAdded: string;
  alternativeName: string;
  commonName: string;
  scientificName: string;
  attachments: Attachment[];
  preferredName: string;
}

export const initialState: ISpecie = {
  id: 0,
  type: {
    typeId: 0,
    typeName: "",
  },
  description: "",
  dateAdded: "",
  alternativeName: "",
  commonName: "",
  scientificName: "",
  attachments: [],
  preferredName: ""
};
