function registerCustomPropertyGridSidebar(setCustomSidebarContainer: any) {
  const generalSidebar: HTMLElement | null = document.querySelector(  
    ".svc-side-bar__container-content"
  );
  if (generalSidebar) {
    setCustomSidebarContainer(generalSidebar);
  }
}

export default registerCustomPropertyGridSidebar;
