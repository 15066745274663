import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

const useCreateMarker = (
  map: mapboxgl.Map,  // Using specific type for better type safety
  isMapFullyLoaded: boolean,
  coordinates: [number, number]  // Assuming coordinates is always an array of two numbers
) => {
  // Store the marker in a ref so that it persists between renders
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  useEffect(() => {
    if (map && isMapFullyLoaded && coordinates && coordinates[0] && coordinates[1]) {

      // Initialize the marker only if it doesn't exist
      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker().setLngLat(coordinates).addTo(map);
      } else {
        // If the marker already exists, just update its position
        markerRef.current.setLngLat(coordinates);
      }

      // Fly the map to the new coordinates
      map.flyTo({
        center: coordinates,
        essential: true  // This ensures the camera moves smoothly to the new location
      });
    }

  }, [map, isMapFullyLoaded, coordinates]);

  return null;
};

export { useCreateMarker };
