import { WarningIcon } from "assets/icons/HeroIcons";
import React, { useEffect, useState } from "react";

interface StatusProps {
  title: string;
  flag: string;
}

const capitalizeWords = (inputString: any) => {
  return inputString
    .split("_")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const Status: React.FC<StatusProps> = ({ title, flag }) => {
  const [changeColor, setChangeColor] = useState<any>();
  const StatusColor = () => {
    switch (flag) {
      case "red":
        setChangeColor(" bg-accent_1Light text-accent_1Dark");
        break;
      case "green":
        setChangeColor("bg-tertiaryLight  text-tertiary");
        break;
      case "blue":
        setChangeColor("bg-primaryExtraLight text-textLink");
        break;
      case "grey":
        setChangeColor("bg-brandHover text-secondaryMid");
        break;
      case "orange":
        setChangeColor("bg-accent_1LightExtraMid text-accent_tertiary_red");
        break;
      case "#FFA500":
        setChangeColor("bg-accent_1LightExtraMid text-accent_tertiary_red");
        break;
      default:
        setChangeColor("bg-primaryExtraLight text-textLink");
        break;
    }
  };
  useEffect(() => {
    StatusColor();
  }, [flag]);
  // let statusClass = "status-wrapper px-2 py-1 ml-2 rounded capitalize";

  // switch (title) {
  //   case "completed":
  //     statusClass += " bg-tertiaryLight text-tertiaryDark";
  //     break;
  //   case "draft":
  //     statusClass += " bg-brandHoverLight text-secondaryMidLight";
  //     break;
  //   case "in_review":
  //     statusClass += " bg-primaryExtraLight text-primaryMid";
  //     break;
  //   case "archived":
  //     statusClass += " bg-brandHover text-secondaryMid";
  //     break;
  //   case "request_to_edit":
  //     statusClass += " bg-brandHover text-secondaryMid";
  //     break;
  //   default:
  //     statusClass += " bg-brandHover text-secondaryMid";
  //     break;
  // }
  const updatedStatus =
    title.toLowerCase() === "completed"
      ? "complete"
      : title.toLowerCase() === "incompleted"
        ? "incomplete"
        : title;

  const capitalizedTitle = capitalizeWords(updatedStatus);
  return (
    <div className="flex items-center">
      <p
        className={`text-[12px] status-wrapper px-2 py-1 rounded-md capitalize flex justify-between ml-2 ${changeColor} `}
      >
        {capitalizedTitle === "Request To Edit" && (
          <div className="mr-1">
            <WarningIcon className="h-5 w-5" />
          </div>
        )}
        {capitalizedTitle}
      </p>
    </div>
  );
};

export default Status;
