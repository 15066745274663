function __paintRasterLayerOnMap__(
  map: any,
  sourceId: string,
  layerId: string,
  layerSinppet: any
) {
  if (!map || !sourceId || !layerId || !layerSinppet) {
    return;
  }

  map.addSource(sourceId, {
    type: "raster",
    tiles: [layerSinppet],
    tileSize: 256,
  });
  map.addLayer(
    {
      id: layerId,
      type: "raster",
      source: sourceId,
      paint: {},
    },
    "building"
  );
 
}

export { __paintRasterLayerOnMap__ };
