import { useDispatch } from "react-redux";
import { usersSearchAction } from "store/filters";
import InputField from "view/components/InputField";

interface NameProps {
  data: any;
}

export const Name: React.FC<NameProps> = ({ data }) => {
  const dispatch = useDispatch();
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(usersSearchAction(value, name));
   
  };
  return (
    <div
      className="bg-bgWhite shadow-xl flex flex-col justify-start items-start w-[315px] py-2 rounded-lg dark:bg-secondaryLight"
      style={{
        boxShadow:
          "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
      }}
    >
      <div className="w-[315px]">
        <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
          <InputField
            onChange={handleChange}
            placeholder="Search"
            type="Search"
            name="name"
            value={data.searchBy === "name" ? data?.searchString : ""}
          />
        </div>

        <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 pl-3 pr-3 ">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                <div className="w-full mb-4">
                  <InputField
                    onChange={handleChange}
                    label="First Name"
                    name="first_name"
                    placeholder="First Name"
                    type="text"
                    value={
                      data.searchBy === "first_name"
                        ? data?.searchString
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                <div className="w-full mb-4">
                  <InputField
                    onChange={handleChange}
                    label="Last Name"
                    name="last_name"
                    placeholder="Last Name"
                    type="text"
                    value={
                      data.searchBy === "last_name"
                        ? data?.searchString
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 h-[336px] absolute left-[214px] top-0 px-[5px] py-2">
            <div className="relative flex flex-col items-start justify-start flex-grow">
              <div className="relative flex-grow w-2">
                <div className="w-2 h-[107px] absolute left-[-1px] top-[-1px] rounded bg-black/40" />
              </div>
              <div className="relative flex-grow w-2 opacity-0">
                <div className="w-2 h-[107px] absolute left-[-1px] top-[-1px] rounded bg-black/40" />
              </div>
              <div className="relative flex-grow w-2 opacity-0">
                <div className="w-2 h-[107px] absolute left-[-1px] top-[-1px] rounded bg-black/40" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

