import { TOGGLE_SIDEBAR } from "./action-types";
import { Dispatch } from "redux";

export const toggleSidebar =
  (isOpen: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: isOpen,
    });
  };
