import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
  GridCard,
} from "Components/Communities";

// Hooks
import { CommunitiesTable } from "hooks/useTable";
// Assets

// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchCommunitiesAction } from "store/communities";
import { IfetchCommunities } from "store/communities/initialState";
import { TableProps } from "Components/Communities/Table/types";
import {
  communitiesResetFiltersAction,
  setCommunitiesFiltersAction,
} from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";

import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import CommunityDirectoryMap from "Components/Communities/MapBox";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Communities = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setCommunitiesFiltersAction());
  }, []);

  const { organizationId, programId } = useParams();
  const { data } = useSelector<RootState, IfetchCommunities>(
    (state) => state?.allCommunities
  );

  const { communitiesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = CommunitiesTable(data?.communities);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        communitiesFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      // dispatch(communitiesResetFiltersAction());
    };
  }, [communitiesFilter.searchString]);

  useEffect(() => {
    if (showInput && inputRef.current) {
      // Find the input element inside the ref'd div and focus on it
      const input = inputRef.current.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  }, [showInput]);

  // Reset filters if component unmount
  // useEffect(() => {
  //   return () => {
  //     dispatch(communitiesResetFiltersAction());
  //   };
  // }, []);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };

  useEffect(() => {
    dispatch(fetchCommunitiesAction(organizationId, programId));
    saveFiltersDataToLocalStorage(communitiesFilter);
  }, [communitiesFilter]);

  
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mb-2">
            <Filters
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              setColumnsConfig={setColumnsConfig}
            />
          </div>
        </div>
      </div>
      <div className="w-full min-h-[40vh]">
        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default Communities;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { communitiesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { data, stateIs } = useSelector<RootState, IfetchCommunities>(
    (state) => state?.allCommunities
  );

  function _renderView(type: any) {
    switch (type) {
      case "grid":
        return (
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
            {stateIs === "Idle" && data?.communities?.length === 0 ? (
              <NoRecordFound />
            ) : stateIs === "Pending" ? (
              <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <GridCard />
            )}
            <div
              className={`bg-bgWhite dark:bg-secondaryLight w-full ${
                stateIs === "Idle" && data?.communities?.length === 0
                  ? ""
                  : "mt-2"
              }`}
            >
              <Pagination />
            </div>
          </div>
        );

      case "list":
        return (
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
            {stateIs == "Idle" && data?.communities?.length === 0 ? (
              <NoRecordFound />
            ) : stateIs === "Pending" ? (
              <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <Table
                flexRender={flexRender}
                table={table}
                columnOrder={columnOrder}
                columns={columns}
                columnVisibility={columnVisibility}
                setSorting={setSorting}
                sorting={sorting}
              />
            )}
            <Pagination />
          </div>
        );

      case "map":
        return (
          <div className="h-[100%] w-full ">
            <CommunityDirectoryMap />
            <Pagination />
          </div>
        );

      default:
        return <></>;
    }
  }

  return _renderView(communitiesFilter.viewType);
};
