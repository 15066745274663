import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import React from "react";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import InputField from "view/components/InputField";

export const InviteReviewer = ({
  errors,
  handleChange,
  handleBlur,
  values,
  touched,
  setFieldValue,
}: any) => {
  return (
    <>
      <div className="flex items-start self-stretch justify-start items-center flex-grow-0 flex-shrink-0 gap-4">
        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="Email*"
            type="email"
            placeholder="Email"
            name="email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values?.email}
            error={errors?.email}
            touched={touched?.email}
            height={41}
          />
        </div>

        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <InputField
            label="Access Until*"
            type="date"
            name="accessUntil"
            onChange={(values: any) => setFieldValue("accessUntil", values)}
            onBlur={handleBlur}
            className={'w-full'}
            value={values?.accessUntil}
            error={errors?.accessUntil}
            touched={touched.accessUntil}
            min={moment().format(DATE_FORMATS.DEFAULT)}
          />
        </div>
      </div>

      <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="First Name*"
            type="text"
            placeholder="Last name"
            name="firstName"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.firstName}
            error={errors?.firstName}
            touched={touched.firstName}
            height={41}
          />
        </div>

        <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
          <TextInput
            label="Last Name*"
            type="text"
            placeholder="Last name"
            name="lastName"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.lastName}
            error={errors?.lastName}
            touched={touched.lastName}
            height={41}
          />
        </div>
      </div>
    </>
  );
};
