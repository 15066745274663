import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, FormControlLabel, Radio } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeCommunityAccessModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { communitiesStatusAction } from "store/filters";
import { fetchCommunityProfileAction } from "store/communityProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface CommunityAccessModalProps {}

export const CommunityAccessModal: React.FC<CommunityAccessModalProps> = () => {
  const { communityAccessModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communityId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const handleClose = () => {
    dispatch(closeCommunityAccessModalAction());
  };

  const exitCommunity = async () => {
    setIsLoading(true);
    try {
      const res = await apiLibrary.Communities.exitCommunity(
        communityAccessModal.data.id,
        communityAccessModal.access
      );

      if (communityAccessModal.data.path === "profilePage") {
        dispatch(
          communitiesStatusAction(
            communityAccessModal.access === "exit" ? "inaccessible" : "active"
          )
        );
        navigate("/community/list");
        // dispatch(fetchCommunityProfileAction(communityId ? communityId : ""));
      } else {
        dispatch(
          communitiesStatusAction(
            communityAccessModal.access === "exit" ? "inaccessible" : "active"
          )
        );
      }
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={communityAccessModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px]  overflow-y-auto rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {communityAccessModal.access === "exit"
                    ? "Exit Community"
                    : "Regain Access"}
                </p>
              </div>
              <button title="close" onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              {communityAccessModal.access === "exit" ? (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                  Are you sure you want to exit this community? This means you
                  will not have access to the community profile nor the ability
                  to view community details.
                </p>
              ) : (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  <span className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                    If you want to regain access click the Regain access button
                    below.{" "}
                  </span>
                  <br />
                  <span className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                    The Community Admin will be notified.
                  </span>
                </p>
              )}
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text={
                  communityAccessModal.access === "exit"
                    ? "Confirm"
                    : "Regain Access"
                }
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={exitCommunity}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
