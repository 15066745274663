import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  Date,
  FormsObservations,
  Search,
  SelectedCommunities,
} from "./Components";
import { useParams } from "react-router-dom";
import {
  usersResetFiltersAction,
  usersResetFiltersExceptCommunityAction,
  usersResetFiltersExceptProgramAction,
} from "store/filters/reducer.actions";
import { useDispatch } from "react-redux";
import { SelectedPrograms } from "./Components/SelectedPrograms";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const clearAllFilters = () => {
    // if (communityId) {
    //   dispatch(usersResetFiltersExceptCommunityAction());
    // } else if (programId) {
    //   dispatch(usersResetFiltersExceptProgramAction());
    // } else {
    //   dispatch(usersResetFiltersAction());
    // }

    dispatch(usersResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyUsersFilterApplied(usersFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap justify-start flex-shrink-0"
      >
        <div className="flex flex-wrap gap-2">
          <Date
            name="lastActive"
            from={usersFilter.lastActive.from}
            to={usersFilter.lastActive.to}
            isApplied={usersFilter.lastActive?.applied}
          />
          {/* <Status /> */}
          <Search />
          <SelectedPrograms />
          <FormsObservations
            name="forms"
            min={usersFilter.forms.min}
            max={usersFilter.forms.max}
            isApplied={usersFilter.forms?.applied}
          />
          <SelectedCommunities />
          <FormsObservations
            name="observations"
            min={usersFilter.observations.min}
            max={usersFilter.observations.max}
            isApplied={usersFilter.observations?.applied}
          />
          <Date
            name="memberSince"
            from={usersFilter.memberSince.from}
            to={usersFilter.memberSince.to}
            isApplied={usersFilter.memberSince?.applied}
          />
        </div>
      </div>{" "}
    </div>
  );
};

export default AppliedFilters;

export function isAnyUsersFilterApplied(usersFilter: any): boolean {
  const {
    status,
    searchBy,
    searchString,
    lastActive,
    memberSince,
    forms,
    programIds,
    observations,
    communityIds,
  } = usersFilter;
  const applied =
    (searchBy && searchString !== "") ||
    lastActive?.applied ||
    memberSince?.applied ||
    forms?.applied ||
    programIds?.applied ||
    observations?.applied ||
    communityIds?.applied ||
    (status?.applied &&
      ["Active", "Inactive", "Reinvited", "Archived", "Suspended"].includes(
        status.value
      ));
  return applied;
}
