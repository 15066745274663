import { extractDrawId } from "../utils";
import { __deleteDrawShape__, __drawShapeByGeoJsonOnMap__ } from "../workerMehods";
import { convertGeoJSON } from "utils/convertFeatureCollection";
function handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap(draw:any,item:any){
    const drawShapes = draw.getAll();
  
    const alreadyExistDrawShape: any = drawShapes.features.find(
      (s: any) => item.item.id == extractDrawId(s.id)
    );
  
    // stop the layer drawing if its already drawn
    if (alreadyExistDrawShape) {
      // if layer item is unchecked then remove its drawing from the map
      if (item.status === false) {
        __deleteDrawShape__(
          draw,
          extractDrawId(alreadyExistDrawShape.id)
        );
      } 
      else {
        __drawShapeByGeoJsonOnMap__(
          draw,
          item.item.id,
          item.item.mapData
        );
      }
    } 
    else {
      // if layer item is unchecked or it doesn't has geojson data then blocks its drawing on map
        const geoJson = convertGeoJSON(item.item?.mapData) ?? item.item?.geoFilePath;
  
      if (item.status === false || !geoJson) {
        return;
      }
  
      // draw the layer
      __drawShapeByGeoJsonOnMap__(
        draw,
        item.item.id,
        item.item.mapData
      );
    }
  };

export {handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap};