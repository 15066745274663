import { apiClient } from "../config";

async function getAllObjects(queryParams: any) {
  const res = await apiClient.get(`/admin/objects`, {
    params: queryParams,
  })
  return res.data;
}

export default getAllObjects;
