export interface ImportStatusDetail {
  reason: string,
  errors: any[],
  observationCode: string,
  importStatus: string,
}

export interface ImportStatusDetailData {
  data: ImportStatusDetail,
  message: string,
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: ImportStatusDetailData = {
  data: {
    reason: "",
    errors: [],
    observationCode: "",
    importStatus: "",
  },
  message: "",
  stateIs: "Idle",
};

export { initialState };
