import { apiClient } from "../config";

async function getMarkerGroupsDropdown(searchQuery?: string, page?: number) {
  const params = {
    searchString: searchQuery,
    page: page,
  };

  const res = await apiClient.get(`/admin/marker-groups/dropdown`, { params });
  return res.data;
}

export default getMarkerGroupsDropdown;
