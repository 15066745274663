import { SVGProps } from "react"
export const CheckBoxUnchecked = (props: SVGProps<SVGSVGElement>) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <rect
      width={16}
      height={16}
      x={4}
      y={4}
      stroke="#6F747B"
      strokeWidth={2}
      rx={1}
    />
  </svg>
)
export default CheckBoxUnchecked
