import React from "react";
// External Tools
import { useLocation, useNavigate } from "react-router-dom";
// Store Utils
import { useDispatch } from "react-redux";
import { categoriesStatusAction, importsStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";

interface TabsProps { }

export const Tabs: React.FC<TabsProps> = ({ }) => {
  const { importsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  const isAdmin = auth?.roles?.includes("super_admin");

  const TabsData = [{
    label: "imports",
    value: "imported",
    isVisible: true
  },
  {
    label: "archive",
    value: "archived",
    isVisible: true
  },
  {
    label: "permissions",
    value: "permission",
    isVisible: isAdmin
  }
  ];

  //handlers
  const handleLink = (linkName: string) => {
    dispatch(importsStatusAction(linkName));
  };

  return (
    <>
      {TabsData.map((item: any, index: number) => {
        if (item.isVisible)
          return (
            <div
              key={index}
              className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${importsFilter.status === item.value
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
                }`}
              onClick={() => handleLink(item.value)}
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
                <p className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${importsFilter.status === item.value
                  ? " dark:text-textMain "
                  : "dark:text-caption "
                  }`}>
                  {item.label}
                </p>
              </div>
            </div>
          );
      })}
    </>
  );
};
