import {
  ERROR_LOADING_TYPES,
  GET_ALL_TYPES,
  RESET_DATA,
  START_LOADING_TYPES,
  STOP_LOADING_TYPES,
} from "./action-types";
import initialState from "./initialState";

export const typesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_TYPES:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_TYPES:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_TYPES:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_TYPES:
      const types = action.payload.types.map((item: any) => {
        const { categoryName, id, typeName, categoryId } = item;
        const data = {
          id: categoryId,
          categoryName: {
            name: categoryName,
            id: categoryId,
          },
          typeName: {
            name: typeName,
            id: id,
          },
        };
        return data;
      });

      return {
        ...state,
        data: {
          types: types,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
