import Head from "view/components/Head";
import { PageHeadingProps } from "../../types";

const PageHeading = ({ communityId, name, parentName }: PageHeadingProps) => {
  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={parentName + " " + (communityId !== "" ? `${name}: Objects` : "Objects")} />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <p className="flex-grow w-full break-all text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {communityId !== "" ? <>{name}: Objects</> : "Objects"}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
