import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import Roles from "hooks/roles";
import usePermissions from "hooks/usePermissions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import apiLibrary from "services/api";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import {
  profileSettingsAction,
  userProfileSettingsAction,
} from "store/userProfilesettings";
import { Toasts } from "view/components/Toasts";

export const TempUnitSwitcher = () => {
  const dispatch = useDispatch();
  const { isMyProfile } = Roles();

  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const {profile} = usePermissions();
  // Getting user profile from Redux state
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  // Function to update user profile settings
  const updateUserProfileSettings = async (e: any) => {
    if(!profile.canEditSettingsProfile){
      return;
    }
    try {
      const { name, value } = e.target;
      const res = await apiLibrary.Settings.updateUserProfileSettings(
        name,
        value
      );
      // Updating Redux state with new settings
      if (isMyProfile(userProfile.personId)) {
        dispatch(userProfileSettingsAction(res.data));
      }
      dispatch(profileSettingsAction(res.data));

      Toasts.success(res.message);
    } catch (error: any) {
     const errorMsg = error?.response?.data?.message ?? error.message
      Toasts.error(errorMsg)
      console.error("An error occurred:", error);
    }
  };

  const isProfileVisible =
    isMyProfile(userProfile.personId)
  return (
    <div>
      <RadioGroup
        onChange={updateUserProfileSettings}
        aria-labelledby="demo-radio-buttons-group-label"
        name="preferred_temperature"
        defaultValue={profileSettings.preferredTemperature}
      >
        <p className="py-4 text-base font-semibold text-secondaryMid dark:text-textMain">
          Preferred Temperature Measurement
        </p>
        {isProfileVisible ? (
          <>
            <p className="pl-3 dark:text-textMain">
              <RadioBtn
                value="Fahrenheit"
                disabled={!profile.canEditSettingsProfile}
                labelContent={
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                  
                    }}
                  >
                    Fahrenheit
                  </span>
                }
              />
            </p>

            <p className="pl-3 dark:text-textMain">
              <RadioBtn
                value="Celsius"
                disabled={!profile.canEditSettingsProfile}
                labelContent={
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                  
                    }}
                  >
                    Celsius
                  </span>
                }
              />
            </p>
          </>
        ) : (
          <p className="capitalize dark:text-textMain">{profileSettings.preferredTemperature}</p>
        )}
      </RadioGroup>
    </div>
  );
};
