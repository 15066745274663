import {
  GET_PROGRAM_STATUS_HISTORY,
  START_LOADING_PROGRAM_STATUS_HISTORY,
  STOP_LOADIND_PROGRAM_STATUS_HISTORY,
  ERROR_LOADING_PROGRAM_STATUS_HISTORY,
} from "./action-types";
import { initialState } from "./initialState";

export const programStatusHistoryReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case START_LOADING_PROGRAM_STATUS_HISTORY:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_PROGRAM_STATUS_HISTORY:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADIND_PROGRAM_STATUS_HISTORY:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_PROGRAM_STATUS_HISTORY:
      const history = action.payload?.history?.map((item: any) => {
        const { date, seasonStatus, updatedBy } = item;
        const data = {
          date,
          seasonStatus,
          updatedByAdmin: {
            updatedBy,
          },
        };
        return data;
      });

      return {
        ...state,
        data: {
          history,
          fields: action.payload?.fields
        },
        message: "",
        stateIs: "Idle",
      };
    default:
      return state;
  }
};
