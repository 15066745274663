import apiClient from "services/apiClient";

async function getCategoriesDropdown(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
  };

  const res = await apiClient.get(`/admin/categories/dropdown`, { params });
  return res.data;
}

export default getCategoriesDropdown;
