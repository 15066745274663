import { Ifetch } from "../initialState";

interface IGeometry {
  coordinates: any[];
  type: string;
}

export interface IObservationByRegion {
  count: number;
  mapData: {
    geometries: IGeometry[];
    type: string;
  };
  regionName: string | null;
}

export interface IObservationsByRegion {
  regions: IObservationByRegion[];
  totalObservations: number;
}
const initialObject: IObservationsByRegion = {
  regions: [
    // {
    //   count: 0,
    //   regionName: null,
    //   mapData: {
    //     geometries: [
    //       {
    //         coordinates: [[]],
    //         type: "",
    //       },
    //     ],
    //     type: "",
    //   },
    // },
  ],
  totalObservations: 0,
};

export interface IfetchObservationsByRegion extends Ifetch {
  data: IObservationsByRegion;
}

const observationsByRegionInitialState: IfetchObservationsByRegion = {
  data: initialObject,
  message: "",
  stateIs: "Pending",
};
export default observationsByRegionInitialState;
