import { apiClient } from "../config";

async function removeFromProgram(programId: any, entity: any, id: any) {
  console.log(programId,entity, id,'data');
  
  const res = await apiClient.delete(`/admin/programs/${programId}/entity`, {
    params: {
      entityType: entity,
      entityId: id,
    },
  });
  return res.data;
}

export default removeFromProgram;
