import React from "react";

export interface IPersonData {
  name: string;
  role: string;
  personId: number;
  lastActive: string;
  personImage: {
    large: string;
    original: string;
    thumb: string;
  };
  isSelected: boolean;
  canView: boolean;
  canDownload: boolean;
  isCreator: boolean;
}

interface ActionDropdownMenuForReportProps {
  data: IPersonData;
  handleCanViewAndDownloadSettings: (person: IPersonData) => void;
  handleRemoveMember: (data: IPersonData) => void;
}

const getColorClass = (data: IPersonData) => {
  if (data.isSelected) {
    return data.canDownload
      ? "text-primary "
      : "text-textMid dark:text-caption ";
  } else {
    return "text-secondaryMid dark:text-caption ";
  }
};

const ActionDropdownMenuForReport: React.FC<
  ActionDropdownMenuForReportProps
> = ({ data, handleCanViewAndDownloadSettings, handleRemoveMember }) => {
  const handleTogglePermission = (permission: keyof IPersonData) => {
    let updatedData;

    if (permission === "canDownload" && !data.canDownload && !data.canView) {
      updatedData = {
        ...data,
        canView: true,
        canDownload: true,
      };
    } else if (permission === "canDownload" && data.canDownload) {
      updatedData = {
        ...data,
        canDownload: false,
      };
    } else if (permission === "canView" && data.canView) {
      updatedData = {
        ...data,
        canView: false,
        canDownload: false,
      };
    } else {
      updatedData = {
        ...data,
        [permission]: !data[permission],
      };
    }

    handleCanViewAndDownloadSettings(updatedData);
  };

  return (
    <div
      className="flex flex-col  items-start justify-start py-2 rounded dark:bg-secondaryLight "
      style={{
        boxShadow:
          "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
      }}
    >
      <button
        type="button"
        onClick={() => handleTogglePermission("canView")}
        className={`flex justify-start items-center disabled:cursor-not-allowed cursor-pointer outline-none dark:bg-secondaryLight ${
          !data.isSelected &&
          "hover:bg-primaryLight dark:hover:bg-primaryLight "
        } w-full flex-shrink-0 gap-2 px-2 py-0.5 ${
          data.isSelected
            ? data.canView && "bg-primaryLight dark:bg-secondaryLight"
            : "bg-gray-300 dark:bg-textMain"
        }`}
        disabled={!data.isSelected}
      >
        <div className="relative flex items-center justify-start flex-shrink-0">
          {data.canView && (
            <span>
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-shrink-0 w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1384 4.19526C13.3987 4.45561 13.3987 4.87772 13.1384 5.13807L6.47173 11.8047C6.21138 12.0651 5.78927 12.0651 5.52892 11.8047L2.86225 9.13807C2.6019 8.87772 2.6019 8.45561 2.86225 8.19526C3.1226 7.93491 3.54471 7.93491 3.80506 8.19526L6.00033 10.3905L12.1956 4.19526C12.4559 3.93491 12.878 3.93491 13.1384 4.19526Z"
                  fill="#005C89"
                />
              </svg>
            </span>
          )}
        </div>
        <div className="flex justify-start items-center relative py-0.5">
          <p className={`w-[120px] text-sm text-left ${getColorClass(data)}`}>
            Can View
          </p>
        </div>
      </button>

      <button
        type="button"
        onClick={() => handleTogglePermission("canDownload")}
        className={`w-full flex justify-start items-center disabled:cursor-not-allowed cursor-pointer outline-none dark:bg-secondaryLight ${
          !data.isSelected && "hover:bg-primaryLight dark:hover:bg-primaryLight"
        } flex-shrink-0 gap-2 px-2 py-0.5 ${
          data.isSelected
            ? data.canDownload && "bg-primaryLight"
            : "bg-gray-300 dark:bg-textMain"
        }`}
        disabled={!data.isSelected}
      >
        <div className="relative flex items-center justify-start flex-shrink-0">
          {data.canDownload && (
            <span>
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-shrink-0 w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1384 4.19526C13.3987 4.45561 13.3987 4.87772 13.1384 5.13807L6.47173 11.8047C6.21138 12.0651 5.78927 12.0651 5.52892 11.8047L2.86225 9.13807C2.6019 8.87772 2.6019 8.45561 2.86225 8.19526C3.1226 7.93491 3.54471 7.93491 3.80506 8.19526L6.00033 10.3905L12.1956 4.19526C12.4559 3.93491 12.878 3.93491 13.1384 4.19526Z"
                  fill="#005C89"
                />
              </svg>
            </span>
          )}
        </div>
        <div className="flex justify-start items-center relative py-0.5">
          <p className={`w-[120px] text-sm text-left ${getColorClass(data)}`}>
            Can Download
          </p>
        </div>
      </button>

      <div className="flex  w-full justify-start items-center flex-shrink-0 relative gap-2 pt-3.5 pb-[13px]">
        <div className="w-full h-px bg-lineMid border-lineMid dark:bg-secondaryMidLight dark:border-secondaryMidLight" />
      </div>

      <button
        type="button"
        onClick={() => handleRemoveMember(data)}
        className={`w-full flex justify-start items-center disabled:cursor-not-allowed outline-none cursor-pointer dark:bg-secondaryLight ${
          !data.isSelected && "hover:bg-primaryLight dark:hover:bg-primaryLight"
        } flex-shrink-0 gap-2 px-2 py-0. ${
          !data.isSelected && "bg-gray-300 dark:bg-textMain"
        }`}
        disabled={!data.isSelected}
      >
        <div className="relative flex items-center justify-start flex-shrink-0"></div>
        <div className="flex justify-start items-center relative py-0.5">
          <p
            className={`w-[120px] text-sm text-left ${
              data.isSelected
                ? "text-textMid dark:text-textMain "
                : "text-secondaryMid dark:text-caption"
            }`}
          >
            Remove
          </p>
        </div>
      </button>
    </div>
  );
};

export default ActionDropdownMenuForReport;
