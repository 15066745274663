import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import RadioGroup from "@mui/material/RadioGroup";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { addPersonSchema } from "utils/validationSchemas";
import {
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
import Camera1Icon from "assets/icons/HeroIcons/Camera1Icon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useNavigate, useParams } from "react-router-dom";
import { Toasts } from "view/components/Toasts";
import { RadioBtn } from "Components/RadioBtn";
import { colseAddPersonModalAction } from "store/modals/reducer.actions";
import { RootState } from "store";
import { useSelector } from "react-redux";
import InputField from "view/components/InputField";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";
import { CountryCode } from "libphonenumber-js";
import GooglePlacesAutocomplete from "Components/AddressInput";
import Button from "view/components/Button";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { DarkMode } from "store/darkMode/initialState";
import { formatTheDates } from "utils/formatTheDates";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";
const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

export const MAXIMUM_FILE_SIZE = 10;

export const AddPersonModal = () => {
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  const dispatch = useDispatch();
  const { communities, users } = usePermissions();
  const { addPersonModal } = useSelector((state: RootState) => state.modals);

  const [userImage, setUserImage] = useState<any>(null);
  const tenYearsAgo = moment()
    .subtract(10, "years")
    .format(DATE_FORMATS.DEFAULT);

  const { communityId } = useParams<{
    communityId: string;
  }>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const [addPersonInitialState, setaddPersonInitialState] = useState<any>({
    contactPreference: "Email",
    firstName: "",
    gender: "",
    lastName: "",
    prefix: "",
    email: "",
    phoneNumber: "",
    suffix: "",
    address: "",
    dateOfBirth: "",
    countryCode: "US",
  });
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!users.canCreatePerson) {
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const formValues = {
        ...values,
        dateOfBirth: formatTheDates(values.dateOfBirth, { utc: true }),
        secondaryEmail: values.email ? [
          {
            email: values.email,
            isVerified: false,
          },
        ] : undefined,
        secondaryPhoneNumber: values.phoneNumber ? [
          {
            phoneNumber: values.phoneNumber,
            countryCode: values.countryCode,
            isVerified: false,
          },
        ] : undefined,
        addresses: [
          {
            address: values.address,
            isPrimary: true,
          },
        ],
      };

      if (userImage) {
        const uploadResponse = await apiLibrary.file.fileUpload(userImage);
        formValues.personImageId = uploadResponse.data.id;
      }
      if (communityId && communities.canCreateMemberCommunities) {
        const { data, message } =
          await apiLibrary.Communities.addCommunityMember(
            parseInt(communityId),
            convertKeysToSnakeCase(formValues)
          );
        Toasts.success(message);
        handleClose();
        if (users.canViewUsersProfile) {
          navigate(`/profile/${data.personId}/account`);
        }
      } else {
        const { data, message } = await apiLibrary.userProfile.addPerson(
          convertKeysToSnakeCase(formValues)
        );
        Toasts.success(message);
        handleClose();
        if (users.canViewUsersProfile) {
          navigate(`/profile/${data.personId}/account`);
        }
      }
    } catch (error: any) {
      // Handle the error appropriately
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (!validImageTypes.includes(file.type)) {
        return Toasts.error("Please select a valid image file (jpeg/jpg/png)");
      }
      const fileSize = file.size / 1024 / 1024; // convert bytes to mb
      if (fileSize > MAXIMUM_FILE_SIZE) {
        return Toasts.error("File size must be less than 10 mb!");
      }
      setUserImage(file);
    }
  };
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClose = () => {
    setUserImage(null);
    dispatch(colseAddPersonModalAction());
  };

  return (
    <>
      <Modal
        open={addPersonModal.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark:bg-secondaryLight ">
          <Formik
            initialValues={addPersonInitialState}
            validationSchema={addPersonSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              setTouched
            }) => {

              // Disable conditions for each radio button
              const bothDisabled = !(values.email && values.phoneNumber);

              return (
                <Form>
                  <div className="flex flex-col justify-start items-start w-[700px]  rounded-lg bg-bgWhite dark:bg-secondaryLight">
                    <div
                      className="relative flex flex-col items-start self-stretch justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight"
                      style={{
                        boxShadow:
                          "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                      }}
                    >
                      <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5 ">
                        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                            Add Person
                          </p>
                        </div>
                        <button onClick={handleClose} type="button">
                          <XCloseIcon
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                          />
                        </button>
                      </div>

                      <div className="overflow-y-auto max-h-[70vh] w-full px-1">
                        <div className="flex flex-col items-center justify-center w-full">
                          <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pb-2">
                            <div className="relative rounded-full w-28 h-28">
                              <input
                                // disabled={isLoading}
                                type="file"
                                className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                                multiple={false}
                                ref={fileInputRef}
                                onChange={handleImageUpload}
                                accept="image/jpeg, image/jpg, image/png"
                              />

                              <img
                                className="w-full h-full rounded-full"
                                src={
                                  userImage !== null
                                    ? URL.createObjectURL(userImage)
                                    : getBrandPlaceholder("userProfile")
                                }
                                alt="User Profile"
                              />
                              <button
                                type="button"
                                className="flex bg-bgWhite disabled:cursor-not-allowed justify-start items-start absolute left-[90px] top-[76px] gap-2 p-0.5 rounded-3xl bg-white"
                                onClick={handleClick}
                              >
                                <Camera1Icon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-center font-normal text-secondary min-h-[28px]">
                            {values.firstName} {values.lastName}
                          </p>
                        </div>
                        <div className="w-full">
                          <div className="flex w-full gap-4">
                            <div className="flex flex-col flex-grow w-1/2 rounded-lg ">
                              <TextInput
                                label="First name"
                                type="text"
                                placeholder={"First Name"}
                                name="firstName"
                                setFieldValue={setFieldValue}
                                error={errors?.firstName}
                                touched={touched.firstName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className="flex flex-col flex-grow w-1/2 rounded-lg ">
                              <TextInput
                                label="Last name"
                                placeholder={"Last Name"}
                                type="text"
                                name="lastName"
                                setFieldValue={setFieldValue}
                                error={errors?.lastName}
                                touched={touched.lastName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="w-1/2">
                              <TextInput
                                height={37}
                                label="Email"
                                type="email"
                                placeholder={"Email"}
                                name="email"
                                setFieldValue={setFieldValue}
                                error={errors?.email}
                                touched={touched.email}
                                handleChange={(e: any) => {
                                  const value = e.target.value;
                                  setFieldValue("email", value)
                                  if (value && !values.phoneNumber) {
                                    setFieldValue("contactPreference", "Email");
                                  }
                                  else if (value === "" && values.phoneNumber) {
                                    setFieldValue("contactPreference", "phone_number");
                                  }
                                }}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className="w-1/2">
                              {/* <TextInput
                              label="Phone Number*"
                              type="text"
                              name="phoneNumber"
                              placeholder={"Phone"}
                              setFieldValue={setFieldValue}
                              error={errors?.phoneNumber}
                              touched={touched.phoneNumber}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            /> */}

                              <TextInput
                                label="Phone Number"
                                height={37}
                                type="text"
                                fieldAs="phone"
                                name="phoneNumber"
                                placeholder={"Phone"}
                                setFieldValue={setFieldValue}
                                countryChange={(country: CountryCode) => {
                                  setFieldValue("countryCode", country);
                                }}
                                error={errors?.phoneNumber}
                                touched={touched.phoneNumber}
                                handleChange={(value: any) => {
                                  // handleChange(value)
                                  setFieldValue("phoneNumber", value)
                                  if (value && !values.email) {
                                    setFieldValue("contactPreference", "phone_number");
                                  }
                                  else if (value === "" && values.email) {
                                    setFieldValue("contactPreference", "Email");
                                  }
                                }}
                                handleBlur={handleBlur}
                                value={values?.phoneNumber}
                                isEmptyError={errors.phoneNumber === ""}
                              />
                            </div>
                          </div>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={values.contactPreference}
                            onChange={(e: any) =>
                              setFieldValue("contactPreference", e.target.value)
                            }
                            name="contactPreference"
                          >
                            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                              <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
                                <div className="flex items-center justify-start flex-grow py-2">
                                  <div className="relative flex items-center justify-start flex-grow">
                                    <p className="flex-grow text-base font-semibold text-left text-secondaryMid dark:text-textMain">
                                      Contact Preference
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex w-full gap-2 ">
                                <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
                                  <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                                      <RadioBtn
                                        value="Email"
                                        disabled={bothDisabled}
                                        checked={values.contactPreference === "Email"}
                                        labelContent={
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "400",
                                              color: "#2C3236",
                                            }}

                                          >
                                            <div className="flex items-center justify-start flex-grow pl-2">
                                              <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                                                <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-secondaryMidLight dark:text-textMain">
                                                  Email
                                                </p>
                                              </div>
                                            </div>
                                          </span>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
                                  <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                                      <RadioBtn
                                        value="phone_number"
                                        disabled={bothDisabled}
                                        checked={values.contactPreference === "phone_number"}
                                        labelContent={
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "400",
                                              color: "#2C3236",
                                            }}
                                          >
                                            <div className="flex items-center justify-start flex-grow pl-2">
                                              <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                                                <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-secondaryMidLight dark:text-textMain">
                                                  Phone
                                                </p>
                                              </div>
                                            </div>
                                          </span>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RadioGroup>
                          <GooglePlacesAutocomplete
                            label="Address"
                            placeholder={"Address"}
                            error={errors?.address}
                            onBlur={handleBlur}
                            hanndleAddressSelect={(address: any) => {
                              setFieldValue("address", address);
                            }}
                            handleLatitudeAndLongitude={(
                              longitude: any,
                              latitude: any
                            ) => { }}
                          />

                          <div className="flex gap-4">
                            <div className="flex flex-col flex-grow">
                              <TextInput
                                label="Prefix"
                                type="text"
                                placeholder={"Prefix"}
                                name="prefix"
                                setFieldValue={setFieldValue}
                                error={errors?.prefix}
                                touched={touched.prefix}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className="flex flex-col flex-grow rounded-lg">
                              <TextInput
                                label="Suffix"
                                type="text"
                                name="suffix"
                                placeholder={"Suffix"}
                                setFieldValue={setFieldValue}
                                error={errors?.suffix}
                                touched={touched.suffix}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="flex flex-col justify-center itmes-center flex-grow w-full rounded-lg mb-[1px]">
                              <InputField
                                type="date"
                                label="Birthday"
                                placeholder="YYYY/MM/DD"
                                value={values.dateOfBirth}
                                className={"w-full py-[-2px]"}
                                name="dateOfBirth"
                                error={errors?.dateOfBirth}
                                onChange={(values: any) =>
                                  setFieldValue("dateOfBirth", values)
                                }
                                onBlur={handleBlur}
                                max={tenYearsAgo}
                              />
                            </div>
                            <div className="flex flex-col flex-grow w-full rounded-lg">
                              <TextInput
                                height={38}
                                label="Gender"
                                type="select"
                                placeholder={"Gender"}
                                name="gender"
                                setFieldValue={setFieldValue}
                                error={errors?.gender}
                                // data={["male", "female"]}
                                touched={touched.gender}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                        <Button
                          type="button"
                          text="Cancel"
                          filledColor="primary"
                          outlinedColor="primary"
                          textColor="textWhite"
                          className="w-24 h-11"
                          width="35"
                          height="13"
                          fontStyle="font-semibold"
                          variant="outlined"
                          onClick={handleClose}
                        />
                        <Button
                          disabled={isSubmitting || !users.canCreatePerson}
                          type="submit"
                          text="Save"
                          filledColor="primary"
                          outlinedColor="primary"
                          textColor="textWhite"
                          className="w-24 h-11"
                          width="35"
                          height="13"
                          fontStyle="font-semibold"
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
