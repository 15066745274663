import axios from "axios";
import { baseURL } from "config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

/**
 * Function to get the authentication token from cookies
 */
const getAuthToken = () => cookies.get("token") || "";

async function fileUpload(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post(`${baseURL}/admin/imports/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return res.data;
}

export default fileUpload;
