import React, { useEffect, useState } from "react";
// Components
import { Toasts } from "view/components/Toasts";
import { GeneralInformation } from "./components/GeneralInformation";
// External Components
import { Form, Formik, FormikProps, FormikHelpers } from "formik";
// Store Utils
import { useDispatch } from "react-redux";
import {
  closeAddProgramModalAction,
  closeSuccessMessageModalAction,
  openSuccessMessageModalAction,
} from "store/modals/reducer.actions";
// API Services
import apiLibrary from "services/api";
// External Utils
import * as Yup from "yup";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NotificationSettings } from "./components/NotificationSettings";
import { NotifyingChannels } from "./components/NotifyingChannels";
import { Target } from "./components/Target";
import { BreadCrumbs } from "./components/BreadCrumbs";
import CustomAccordion from "./components/CustomAccordian";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchNotificationsAction } from "store/notifications";
import usePermissions from "hooks/usePermissions";
import { Header } from "Components/AddEditNotification/Header";
import { formatTheDates } from "utils/formatTheDates";
import { TIME_FORMATS } from "constants/timeFormats";
import { formatTheTime } from "utils/formatTheTime";
interface NotificationFormStepperProps {}
export const NotificationFormStepper: React.FC<
  NotificationFormStepperProps
> = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [editDataValues, setEditDataValues] = useState<any>(null);
  const [notificationData, setNotificationData] = useState<any>(null);
  const { reminders } = usePermissions();
  const { notificationId } = useParams<{
    notificationId: string;
  }>();
  const { state } = location;
  const steps = [
    "Notification content",
    "Notification settings",
    "Notifying channels",
    "Target",
  ];
  const stepsWithoutPermissions = [
    "Notification content",
    "Notification settings",
    "Notifying channels",
  ];
  const createsValidationSchema = [
    Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .max(255, "max 255 characters long"),
      description: Yup.string()
        .required("Message is required")
        .max(1024, "1024 max characters"),
      urlLink: Yup.string().url(" Invalid URL"),
    }),

    Yup.object().shape({
      occurrence: Yup.object().shape({
        now: Yup.boolean(),

        endsOnDateIsSelected: Yup.boolean(),

        startDate: Yup.string().test(
          "startDate",
          "Start date is required",
          function (value) {
            const { now } = this.parent;
            if (!now) {
              return !!value;
            }
            return true;
          }
        ),
        startTime: Yup.string().test(
          "startTime",
          "Start time is required",
          function (value) {
            const { now } = this.parent;
            if (!now) {
              return !!value;
            }
            return true;
          }
        ),
        repeat: Yup.boolean(),
        every: Yup.number()
          .test("every", "Repeat count is required", function (value) {
            const { repeat } = this.parent;
            if (repeat) {
              return !!value;
            }
            return true;
          })
          .positive("Repeat count must be a positive number"),
        repeatUnit: Yup.string()
          .oneOf(["days", "weeks", "months", "years"])
          .when("repeat", (repeat, schema) => {
            return repeat
              ? schema.required("Repeat period is required")
              : schema;
          }),

        repeatOn: Yup.mixed().when("repeat", ([repeat]) =>
          repeat === true
            ? Yup.array()
                .of(Yup.string())
                .min(1, "Select a day")
                .required("Day is required")
            : Yup.mixed().notRequired()
        ),
        endsOnDate: Yup.string().test(
          "endsOnDateRequired",
          "Ends on date is required",
          function (value) {
            const { repeat, endsOnDateIsSelected, endsAfterTimesIsSelected } =
              this.parent;
            if (repeat && endsOnDateIsSelected && !endsAfterTimesIsSelected) {
              return !!value;
            }
            return true;
          }
        ),
        endsAfterTimes: Yup.number()
          .integer()
          .nullable()
          .test(
            "endsAfterTimes",
            "Ends after time is required",
            function (value) {
              const { repeat, endsAfterTimesIsSelected } = this.parent;
              if (repeat && endsAfterTimesIsSelected) {
                return !!value;
              }
              return true;
            }
          ),
      }),
    }),

    Yup.object().shape({
      toast: Yup.boolean(),
      push: Yup.boolean(),
      email: Yup.boolean(),
    }),

    Yup.object().shape({
      targetPerson: Yup.object().shape({
        roles: Yup.array(),
        programIds: Yup.array(),
        communityIds: Yup.array(),
        personIds: reminders.canViewTargetMembersReminders
          ? Yup.array()
              .of(Yup.number())
              .min(1, "Select a member")
              .required("Member is required")
          : Yup.array().of(Yup.number()),
        appName: Yup.string().required("App is required"),
      }),
    }),
  ];
  const currentValidationSchema = createsValidationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const stepsBreadcrumb = steps?.map((stepLabel, index) => ({
    label: stepLabel,
    onClick: () => setActiveStep(index),
    isActive: activeStep === index,
  }));
  const stepsBreadcrumbWithoutPermissoins = stepsWithoutPermissions?.map(
    (stepLabel, index) => ({
      label: stepLabel,
      onClick: () => setActiveStep(index),
      isActive: activeStep === index,
    })
  );

  const initialValues = reStructureNotificationData({}, "response");

  // Re render the notification data using its ID
  useEffect(() => {
    if (notificationData) {
      setEditDataValues(
        reStructureNotificationData(notificationData, "response")
      );
    }
  }, [notificationData]);

  // Fetch the notification data using its ID
  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const response =
          await apiLibrary.NotificationsSummary.getDetailsofNotificationsummary(
            notificationId
          );
        setNotificationData(response?.data);
      } catch (error: any) {
        // Handle error
      }
    };

    if (notificationId) {
      fetchNotificationData();
    }
  }, [notificationId]);

  async function _submitForm(values: any, actions?: any) {
    const notificationData = reStructureNotificationData(values, "request");
    setIsLoading(true);
    try {
      if (state?.editMode) {
        const res = await apiLibrary.Notifications.editNotification(
          notificationId,
          notificationData
        );
        Toasts.success(res.message);
      } else {
        const res =
          await apiLibrary.Notifications.createNotification(notificationData);
        const notificationSuccessMsg = {
          title: "Notification saved successfully!",
          description: "Your notification has been successfully saved.",
        };
        dispatch(openSuccessMessageModalAction(notificationSuccessMsg));
        // Toasts.success(res.message);
      }
      const delay = state?.editMode ? 0 : 3000;
      // Dispatch closeSuccessMessageModalAction after a delay
      setTimeout(() => {
        dispatch(closeSuccessMessageModalAction());
        dispatch(fetchNotificationsAction());
        handleClose();
        navigate(`/custom-notifications/list`);
        setActiveStep(0);
      }, delay); // Adjust the delay as needed (e.g., 3000 milliseconds for 3 seconds)
    } catch (error: any) {
      // Handle any errors that occur during the API call
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }
  const handleSubmitEditMode = (values: any) => {
    _submitForm(values);
  };
  function _handleSubmit(values: any, actions: FormikHelpers<any> | any) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      if (activeStep === 2 && !reminders.canViewTargetMembersReminders) {
        _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  function handleGoBackBtn() {
    setActiveStep((step) => step - 1);
  }

  const handleClose = () => {
    dispatch(closeAddProgramModalAction());
    setActiveStep(0);
  };

  function _renderStepContent(step: number, formikProps: FormikProps<any>) {
    switch (step) {
      case 0:
        return (
          <GeneralInformation
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 1:
        return (
          <NotificationSettings
            {...formikProps}
            handleClose={handleClose}
            handleGoBackBtn={handleGoBackBtn}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 2:
        return (
          <NotifyingChannels
            {...formikProps}
            handleClose={handleClose}
            handleGoBackBtn={handleGoBackBtn}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 3:
        return (
          <Target
            {...formikProps}
            handleClose={handleClose}
            handleGoBackBtn={handleGoBackBtn}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
            values={formikProps.values}
            step={step}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  return (
    <div className="flex flex-col w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={_handleSubmit}
        validateOnChange={false}
      >
        {(formikProps) => (
          <>
            <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3 px-1">
              <Header
                values={formikProps.values}
                handleSubmitEditMode={handleSubmitEditMode}
              />
            </div>
            <div className="px-5">
              <BreadCrumbs
                steps={
                  reminders.canViewTargetMembersReminders
                    ? stepsBreadcrumb
                    : stepsBreadcrumbWithoutPermissoins
                }
                initialValues={editDataValues}
              />
              <CustomAccordion
                expandIcon={
                  <CheveronUpIcon height={16} width={16} fill="#2C3236" />
                }
                detailsClassName="mt-[-15px]"
                summaryClassName="YOUR_CLASS_NAME_HERE"
                heading={steps[activeStep]}
              >
                <Form>
                  <div className="bg-white rounded-lg dark:bg-secondaryLight">
                    {_renderStepContent(activeStep, formikProps)}
                  </div>
                </Form>
              </CustomAccordion>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

//Helper Method To Re-Structure Notification Data
const getRolesProgramsCommunitiesValues = (data: any[]) => {
  const values = data?.map((item: any) => item.value);
  return values;
};

//Helper Method To Re-Structure Roles Programs and Communities Data
const reStructureRolesProgramsCommunities = (data: any[]) => {
  const values = data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));
  return values;
};

function reStructureNotificationData(data: any, payloadType: string) {
  const personIds = data?.recipients?.map((role: any) => role?.personId);
  if (payloadType === "response") {
    // Structure data for response payload
    return {
      title: data?.title ?? "",
      description: data?.description ?? "",
      urlLink: data?.urlLink ?? "",
      toast: data?.notifyingChannels?.toast ?? true,
      push: data?.notifyingChannels?.push ?? true,
      email: data?.notifyingChannels?.email ?? true,
      notificationCenter: true,
      occurrence: {
        now: data?.occurrence?.now ?? true,
        startDate: data?.occurrence?.startDate ?? "",
        startTime: data?.occurrence?.startTime ?? "",
        repeat: data?.occurrence?.repeat ?? false,
        every: data?.occurrence?.every ?? 2,
        repeatOn: data?.occurrence?.repeatOn ?? [],
        repeatUnit: data?.occurrence?.repeatUnit ?? "days",
        endsOnDate: data?.occurrence?.endsOnDate ?? "",
        endsAfterTimes: data?.occurrence?.endsAfterTimes ?? 4,
        endsAfterTimesIsSelected: true,
        endsOnDateIsSelected: false,
      },
      targetPerson: {
        communityIds:
          reStructureRolesProgramsCommunities(data?.communities) ?? [],
        personIds: personIds ?? [],
        programIds: reStructureRolesProgramsCommunities(data?.programs) ?? [],
        roles: reStructureRolesProgramsCommunities(data?.roles) ?? [],
        appName: data.appName ?? "",
      },
    };
  } else if (payloadType === "request") {
    const {
      title,
      description,
      urlLink,
      toast,
      push,
      email,
      notificationCenter,
      occurrence,
      targetPerson,
    } = data;

    const {
      now,
      startDate,
      startTime,
      repeat,
      every,
      repeatUnit,
      repeatOn,
      endsOnDate,
      endsAfterTimes,
    } = occurrence;

    const { communityIds, personIds, programIds, roles, appName } =
      targetPerson;

    if (now === true) {
      return {
        title,
        description,
        urlLink,
        toast,
        push,
        email,
        notificationCenter,
        occurrence: {
          now,
        },
        targetPerson: {
          communityIds: getRolesProgramsCommunitiesValues(communityIds),
          personIds,
          programIds: getRolesProgramsCommunitiesValues(programIds),
          roles: getRolesProgramsCommunitiesValues(roles),
          appName: appName,
        },
      };
    } else {
      return {
        title,
        description,
        urlLink,
        toast,
        push,
        email,
        notificationCenter,
        occurrence: {
          now,
          startDate: formatTheDates(startDate, { utc: true, time: startTime }),
          // startTime,
          startTime: formatTheTime(startTime, TIME_FORMATS.FULL_TIME, {
            utc: true,
          }),
          repeat,
          every,
          repeatUnit,
          repeatOn,
          endsOnDate: formatTheDates(endsOnDate, { utc: true }),
          endsAfterTimes,
        },
        targetPerson: {
          communityIds: getRolesProgramsCommunitiesValues(communityIds),
          personIds,
          programIds: getRolesProgramsCommunitiesValues(programIds),
          roles: getRolesProgramsCommunitiesValues(roles),
          appName: appName,
        },
      };
    }
  }

  return {}; // Default return if payload type is invalid
}
