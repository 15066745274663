import { GET_COMMUNITY_PROFILE, RESET_COMMUNITY_PROFILE } from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

export const fetchCommunityProfileAction =
  (communityId: string) => async (dispatch: Dispatch, getState: any) => {
    try {
      const { data } =
        await apiLibrary.Communities.getCommunityProfile(communityId);

      dispatch({
        type: GET_COMMUNITY_PROFILE,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/community/list";
    }
  };
export const resetCommunityProfileAction = () => {
  return {
    type: RESET_COMMUNITY_PROFILE,
  };
};

// export const getMyProfileSuccessAction = (res: any) => {
//   return {
//     type: getMyProfileSuccess,
//     payload: res,
//   };
// };

// export const updateContactPrefrencesAction = (res: any) => {
//   return {
//     type: updateContactPrefrences,
//     payload: res,
//   };
// };

// export const updateSocialAccountsAction = (res: any) => {
//   return {
//     type: updateSocialaccounts,
//     payload: res,
//   };
// };

// export const resetData = (res: any) => {
//   return {
//     type: "RESET_USER_DATA",
//     payload: res,
//   };
// };
