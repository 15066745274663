import { apiClient } from "../config";

async function getAllImports(queryParams: any) {
  const res = await apiClient.get(`/admin/imports`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllImports;
