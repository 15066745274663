import React, { useEffect } from "react";
// Components
import {
  Filters,
  Header,
  Pagination,
  Table,
} from "Components/PollAndSurveyResults";

// Hooks
import { PollAndSurveyResultsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import { useParams } from "react-router-dom";
import { fetchPollAndSurveyResults } from "store/pollsAndSurveyResults/initialState";
import {
  fetchSurveyAndPollResultAction,
  pollsAndSurveyResetResultAction,
} from "store/pollsAndSurveyResults/reducer.actions";
import useDateFormatter from "hooks/formatDateWithPattern";

export const ViewSurveyResults = () => {
  const { pollId } = useParams();
  const { formatDate } = useDateFormatter();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, fetchPollAndSurveyResults>(
    (state) => state?.pollAndSurveyResults
  );
  const { pollAndSurveyResultsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = PollAndSurveyResultsTable(data?.responses);

  useEffect(() => {
    if (pollId) {
      dispatch(fetchSurveyAndPollResultAction(Number(pollId)));
    }
  }, [pollAndSurveyResultsFilter]);
  useEffect(() => {
    return () => {
      dispatch(pollsAndSurveyResetResultAction());
    };
  }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex w-full px-4 py-2 mt-4 rounded-lg bg-bgWhite dark:bg-bgtetriary">
          <p className="flex items-center gap-2 text-sm capitalize text-textMidLight font-Overpass dark:text-caption">
            created {formatDate(data?.createdOn)}{" "}
            <div className="w-1 h-1 rounded-full text-[#000] dark:bg-caption bg-[#000] mb-1"></div>
            created by {data?.createdBy}
          </p>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full bg-bgWhite dark:bg-secondaryLight">
          {stateIs == "Idle" && data?.responses?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
export default ViewSurveyResults;
