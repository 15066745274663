import { apiClient } from "../config";

async function removeProgram(
  organizationsId: any,
  entity: any,
  entityId: any
) {
  //   console.log(programId,entity, id);

  const res = await apiClient.delete(
    `/admin/organizations/${organizationsId}/entity`,
    {
      params: {
        entityType: entity,
        entityId: entityId,
      },
    }
  );
  return res.data;
}

export default removeProgram;
