import mapboxgl from "mapbox-gl";
import store from "store";
import {
  updateChoosedLayersItems,
  updateSelectedLayersItems,
} from "store/surveyJS";
import separateColorAndOpacity from "utils/separateColorAndOpacity";

function __paintShapeByGeoJsonOnMap__(
  map: any,
  draw: any,
  sourceId: string,
  layerId: string,
  item: any,
  geoJson: any,
  defaultColor: any,
  cb?: CallableFunction
) {
  // // adding geojson source
  // map.addSource(sourceId, {
  //   type: "geojson",
  //   data: geoJson,
  // });

  // const isGeoJsonHasLineString =
  //   geoJson &&
  //   geoJson?.features?.find(
  //     (f: any) =>
  //       f.geometry.type === "LineString" ||
  //       f.geometry.type === "MultiLineString"
  //   );

  const { color, opacity } = separateColorAndOpacity(defaultColor ?? "#FF0000");

  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  map.addLayer({
    id: layerId,
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        "#FFA500", // Color for selected features
        color ?? "#FF0000", // Default color
      ],
      "fill-opacity": opacity,
    },
  });

  map.addLayer({
    id: `${layerId}-border`,
    type: "line",
    source: sourceId,
    layout: {},
    paint: {
      "line-color": "#000000",
      "line-width": 1,
    },
  });

  map.on("click", layerId, (e: any) => {
    if (e.features.length > 0) {
      const feature = e.features[0];

      const FeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: feature.geometry,
            id: feature.id,
          },
        ],
      };
      if (item.isAnswer) {
        const newProperties = { ...feature.properties, isAnswer: true };
        feature.properties = newProperties;
        if (cb) {
          cb(feature);
        }
      } else {
        if (cb && !feature.properties.isUploaded) {
          cb(feature);
        }
      }
    }
  });
  // // design and register the added geojson source layer
  // map.addLayer({
  //   id: layerId,
  //   type: isGeoJsonHasLineString ? "line" : "fill",
  //   source: sourceId,
  //   layout: {},
  //   paint: {
  //     [isGeoJsonHasLineString ? "line-color" : "fill-color"]: color,
  //     [isGeoJsonHasLineString ? "line-opacity" : "fill-opacity"]: opacity,
  //   },
  // });

  // if (!isGeoJsonHasLineString) {
  //   map.addLayer({
  //     id: `${layerId}-border`,
  //     type: "line",
  //     source: sourceId,
  //     layout: {},
  //     paint: {
  //       "line-color": "#000000",
  //       "line-width": 1,
  //     },
  //   });
  // }
}

export { __paintShapeByGeoJsonOnMap__ };
