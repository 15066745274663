import React from 'react'
import Button from 'view/components/Button';
import InputField from "view/components/InputField";

function DrawMaps() {
  return (
    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
      <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative pt-1">
        <p className="flex-grow px-2 text-sm font-medium text-left capitalize text-secondaryMid">
          New region
        </p>
      </div>
      <div className="w-full px-2">
        <InputField placeholder="Region name" />
      </div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[312px] text-base font-semibold text-left text-textMid">
          Draw on the map
        </p>
      </div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1.5">
          <div className="flex flex-col justify-start items-center flex-grow gap-2 p-2 rounded-lg bg-primary/[0.14]">
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-8 relative">
              <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0"
                preserveAspectRatio="none"
              >
                <path
                  d="M0.5 16.4142C0.5 16.2816 0.552678 16.1544 0.646446 16.0607L15.491 1.21609C15.7141 0.992977 16.0856 1.02976 16.2606 1.29229L31.416 24.0254C31.4708 24.1076 31.5 24.2041 31.5 24.3028V31C31.5 31.2761 31.2761 31.5 31 31.5H1C0.723858 31.5 0.5 31.2761 0.5 31V16.4142Z"
                  fill="#99BED0"
                  stroke="#005C89"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex justify-center items-start flex-grow-0 flex-shrink-0 relative py-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-primary">Polygon</p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-center flex-grow gap-2 p-2 rounded-lg border border-lineMid">
            <div className="flex flex-col justify-end items-center flex-grow-0 flex-shrink-0 h-8 relative">
              <svg
                width={32}
                height={24}
                viewBox="0 0 32 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M31.5 12C31.5 15.121 29.8144 17.9847 27.0137 20.0853C24.2128 22.1859 20.3206 23.5 16 23.5C11.6794 23.5 7.78716 22.1859 4.98629 20.0853C2.18557 17.9847 0.5 15.121 0.5 12C0.5 8.87895 2.18557 6.01526 4.98629 3.91472C7.78716 1.81406 11.6794 0.5 16 0.5C20.3206 0.5 24.2128 1.81406 27.0137 3.91472C29.8144 6.01526 31.5 8.87895 31.5 12Z"
                  fill="#005C89"
                  fill-opacity="0.08"
                  stroke="#2C3236"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex justify-center items-start flex-grow-0 flex-shrink-0 relative py-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">Circle</p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-center flex-grow gap-2 p-2 rounded-lg border border-lineMid">
            <div className="flex flex-col justify-end items-center flex-grow-0 flex-shrink-0 h-8 relative">
              <div className="flex-grow-0 flex-shrink-0 w-8 h-6 rounded-[1px] bg-primary/[0.08] border border-textMid" />
            </div>
            <div className="flex justify-center items-start flex-grow-0 flex-shrink-0 relative py-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText">Rectangle</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col justify-start items-start relative gap-2">
          <p className="p-3 self-stretch flex-grow-0 flex-shrink-0 w-[312px] text-base font-semibold text-left text-textMid">
            Share this region you have created with other communities?
          </p>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 pl-1 pr-3 rounded-lg">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                      fill="#005C89"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                      fill="#005C89"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex justify-start items-center flex-grow pl-2 py-2">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p>Share</p>
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 pl-1 pr-3 rounded-lg">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[100px]">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#6F747B"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex justify-start items-center flex-grow pl-2 py-2">
                <div className="flex justify-start items-center flex-grow relative py-0.5">
                  <p>Don't Share</p>
                </div>
              </div>
            </div>
          </div>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-6 h-6 absolute left-72 top-[5px]"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0007 6.66732C9.05513 6.66732 6.66732 9.05513 6.66732 12.0007C6.66732 14.9462 9.05513 17.334 12.0007 17.334C14.9462 17.334 17.334 14.9462 17.334 12.0007C17.334 9.05513 14.9462 6.66732 12.0007 6.66732ZM5.33398 12.0007C5.33398 8.31875 8.31875 5.33398 12.0007 5.33398C15.6826 5.33398 18.6673 8.31875 18.6673 12.0007C18.6673 15.6826 15.6826 18.6673 12.0007 18.6673C8.31875 18.6673 5.33398 15.6826 5.33398 12.0007ZM11.334 9.33398C11.334 8.96579 11.6325 8.66732 12.0007 8.66732H12.0073C12.3755 8.66732 12.674 8.96579 12.674 9.33398C12.674 9.70217 12.3755 10.0007 12.0073 10.0007H12.0007C11.6325 10.0007 11.334 9.70217 11.334 9.33398ZM10.6673 12.0007C10.6673 11.6325 10.9658 11.334 11.334 11.334H12.0007C12.3688 11.334 12.6673 11.6325 12.6673 12.0007V14.0007C13.0355 14.0007 13.334 14.2991 13.334 14.6673C13.334 15.0355 13.0355 15.334 12.6673 15.334L12.0007 15.334C11.6325 15.334 11.334 15.0355 11.334 14.6673V12.6673C10.9658 12.6673 10.6673 12.3688 10.6673 12.0007Z"
              fill="#2C3236"
            />
          </svg>
        </div>
      </div>
      <div className="flex gap-2 px-2">
        <Button
          text="Cancel"
          filledColor="primary"
          variant="outlined"
          textColor="primary"
          className=""
          onClick={() => {
          }}
          fontStyle="font-semibold"
        />
        <Button
          text="Save"
          textColor=""
          className="text-[white] "
          onClick={() => {
          }}
          fontStyle="font-semibold"
        />
      </div>
    </div>
  )
}

export default DrawMaps