import { SET_LOADER_DECREMENT, SET_LOADING } from "./action-types";


const initialState = {
  loading: 0,
};

export const loadingReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      case SET_LOADER_DECREMENT: 
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};


