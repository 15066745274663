import React from "react";
// Components
import {
  Date,
  Observations,
  SelectedCreatedBy,
  SelectedArchivedBy,
  SelectedPrograms,
  SelectedProtocols,
  SelectedCommunities,
} from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import {
  communitiesResetFiltersAction,
  communitiesResetFiltersExceptOrganizationAction,
  dynamicFormsResetFilterExceptProgramsAction,
  dynamicFormsResetFiltersAction,
} from "store/filters/reducer.actions";
import { dynamicFormsDirectoryFilterState } from "store/filters/initialState";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting dynamicFormsFilter state from Redux store
  const { dynamicFormsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  // Getting programId from URL parameters
  const { programId } = useParams<{
    programId: string;
  }>();
  const typeLabels = ["Open", "Private"];

  // Function to check if any filter is applied

  const clearAllFilters = () => {
    dispatch(dynamicFormsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyDynamicFormFilterApplied(dynamicFormsFilter) && (
        <button
          onClick={() => clearAllFilters()}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start "
      >
        <div className="flex flex-wrap gap-2">
          <SelectedCommunities />
          <SelectedPrograms />
          <SelectedCreatedBy />
          <SelectedProtocols />
          {dynamicFormsFilter.status === "archived" && <SelectedArchivedBy />}
          <Observations
            name="observations"
            min={dynamicFormsFilter.observations.min}
            max={dynamicFormsFilter.observations.max}
            isApplied={dynamicFormsFilter.observations?.applied}
          />

          <Date
            name="dateAdded"
            from={dynamicFormsFilter.dateAdded.from}
            to={dynamicFormsFilter.dateAdded.to}
            isApplied={dynamicFormsFilter.dateAdded?.applied}
          />
          {dynamicFormsFilter.status === "archived" && (
            <Date
              name="dateArchived"
              from={dynamicFormsFilter.dateArchived.from}
              to={dynamicFormsFilter.dateArchived.to}
              isApplied={dynamicFormsFilter.dateArchived?.applied}
            />
          )}
          {dynamicFormsFilter.status !== "archived" && (
            <Date
              name="dateUpdated"
              from={dynamicFormsFilter.dateUpdated.from}
              to={dynamicFormsFilter.dateUpdated.to}
              isApplied={dynamicFormsFilter.dateUpdated?.applied}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;

export function isAnyDynamicFormFilterApplied(
  dynamicFormsFilter: dynamicFormsDirectoryFilterState
): boolean {
  const {
    dateAdded,
    dateArchived,
    dateUpdated,
    archivedByIds,
    createdByIds,
    observations,
    programIds,
    protocolIds,
    communityIds,
  } = dynamicFormsFilter;
  const applied =
    dateAdded?.applied ||
    dateArchived?.applied ||
    dateUpdated?.applied ||
    archivedByIds?.applied ||
    createdByIds?.applied ||
    observations?.applied ||
    programIds?.applied ||
    protocolIds?.applied ||
    communityIds?.applied;
  return applied;
}
