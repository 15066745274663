import { apiClient } from "../config";

async function attachReviewer(observationId: any, personId: any) {
  const res = await apiClient.put(
    `/admin/observations/${observationId}/attach-reviewer`,
    {
      personId,
    }
  );
  return res.data;
}

export default attachReviewer;
