import * as turf from "@turf/turf";

function getCenterCoordinatesForRegionLayers(layer: any) {
    const features = layer ? layer.item?.mapData?.features : null;
  
    if (!features || features.length === 0) {
      return;
    }
    // getting average center of layers coordinates
    const featuresCenter = features.map((f: any) => turf.center(f));
    const geoJson: any = {
      type: "FeatureCollection",
      features: featuresCenter,
    };
    const center = turf.center(geoJson);
  
    return center.geometry.coordinates;
  };

export {getCenterCoordinatesForRegionLayers};

