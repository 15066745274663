import React, { useEffect, useState } from "react";
import { Map, RegionFilterFieldsArray } from "./components";
interface DrawOnMapProps {
  selectedOption: any;
  reportId?: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleBlur: any;
  isSubmitting: boolean;
  editData: any;
  drawRef:any
}

export const DrawOnMap: React.FC<DrawOnMapProps> = ({
  selectedOption,
  reportId,
  errors,
  values,
  touched,
  handleBlur,
  setFieldValue,
  isSubmitting,
  editData,
  drawRef
}) => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const updateSelectedShapeRegions = (newRegion: any) => {
      // const lastIndex = values.regionFilter.length - 1;

      // const updatedRegionFilter = values.regionFilter.map(
      //   (item: any, index: number) =>
      //     index === lastIndex ? { ...item, regions: newRegion } : item
      // );

      const updatedRegionFilter = values.regionFilter.map((item: any) => ({
        ...item,
        regions: newRegion,
      }));

      setFieldValue("regionFilter", updatedRegionFilter);
    };
    updateSelectedShapeRegions(features);
  }, [features]);

  const updateSelectedShapeRegions = (newRegion: any) => {
    const lastIndex = values.regionFilter.length - 1;

    const updatedRegionFilter = values.regionFilter.map(
      (item: any, index: number) =>
        index === lastIndex ? { ...item, regions: newRegion } : item
    );

    setFieldValue("regionFilter", updatedRegionFilter);
  };

  const allRegions = values.regionFilter.reduce(
    (accumulator: any, currentValue: any, index: number) => {
      const regionsWithIndex = currentValue.regions?.map((region: any) => {
        const item = { ...region };
        item.regionFilterIndex = index;
        return item;
      });

      return accumulator.concat(regionsWithIndex);
    },
    []
  );

  return (
    <>
      <Map
        selectedOption={selectedOption}
        selectedGeographies={allRegions}
        updateSelectedShapeRegions={updateSelectedShapeRegions}
        reportId={reportId}
        values={values}
        setFeatures={setFeatures}
        editData={editData}
        drawRef={drawRef}
      />

      <RegionFilterFieldsArray
        errors={errors}
        touched={touched}
        values={values}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
        features={features}
      />
    </>
  );
};

export default DrawOnMap;
