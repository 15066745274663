import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddCategoryBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditCategoryModalAction } from "store/modals";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";

// Generating URLs

const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [
        { path: `/category/list`, label: `Categories` },

      ];
  }
};
export const Header = () => {
  const { categoriesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { categories } = usePermissions();
  const dispatch = useDispatch();
  const dynamicCategoryLinks = generateDynamicBreadcrumbLinks(
    categoriesFilter.status
  );

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCategoryLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {categories.canCreateCategories && (
                <AddCategoryBtn
                  handleAddCategoryModel={() =>
                    dispatch(openAddOrEditCategoryModalAction())
                  }
                  label={"Add Category"}
                />
              )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
