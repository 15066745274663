import PlaceholderAvatar from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";
import { RenderCellContentProps } from "../../types";
import { useNavigate } from "react-router-dom";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const navigate = useNavigate();
  const navigateToCommunityProfile = (communityName: any) => {
    // if (communityName?.accessibleForSuperAdmin) {
    //   navigate(`/community/${communityName.id}/profile`);
    // }
    // return;
  };
  const { formatDate } = useDateFormatter();

  if (columnDef.id === "createdBy" && cellValue.id !== null) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 pr-2 py-3 ">
          {cellValue?.image ? (
            <img
              src={cellValue?.image}
              alt=""
              height="100"
              width="100"
              className="mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
          ) : (
            <svg
              width={25}
              height={24}
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <circle cx="12.3335" cy={12} r={12} fill="#99BED0" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3336 5.33203C10.4926 5.33203 9.00024 6.82442 9.00024 8.66536C9.00024 10.5063 10.4926 11.9987 12.3336 11.9987C14.1745 11.9987 15.6669 10.5063 15.6669 8.66536C15.6669 6.82442 14.1745 5.33203 12.3336 5.33203ZM10.3336 8.66536C10.3336 7.5608 11.229 6.66536 12.3336 6.66536C13.4381 6.66536 14.3336 7.56079 14.3336 8.66536C14.3336 9.76993 13.4381 10.6654 12.3336 10.6654C11.229 10.6654 10.3336 9.76993 10.3336 8.66536Z"
                fill="white"
              />
              <path
                d="M8.33358 17.9987C8.33358 15.7896 10.1244 13.9987 12.3336 13.9987C14.5427 13.9987 16.3336 15.7896 16.3336 17.9987C16.3336 18.3669 16.6321 18.6654 17.0002 18.6654C17.3684 18.6654 17.6669 18.3669 17.6669 17.9987C17.6669 15.0532 15.2791 12.6654 12.3336 12.6654C9.38806 12.6654 7.00024 15.0532 7.00024 17.9987C7.00024 18.3669 7.29872 18.6654 7.66691 18.6654C8.0351 18.6654 8.33358 18.3669 8.33358 17.9987Z"
                fill="white"
              />
            </svg>
          )}
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[190.67px] text-sm text-left text-textMid">
              {cellValue?.name}
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    (cellValue !== null && columnDef.id === "organizations") ||
    columnDef.id === "communityAdmins"
  ) {
    const formattedValue = ellipsize(`${cellValue}`, 50);
    const commaSeparatedValue = formattedValue.split(",").join(", ");
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {commaSeparatedValue}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "layerName") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {/* {sentenceCase(ellipsize(`${cellValue}`, 50))} */}
          {ellipsize(`${cellValue.layerName}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {/* {sentenceCase(ellipsize(`${cellValue}`, 50))} */}
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
