import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const UsersIcon =  ({
  fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <g filter="url(#filter0_d_6535_1108446)">
      <circle cx="25" cy="24" r="19" fill="#FFEEE9" />
    </g>
    <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_12905_15337)">
      <path
        d="M25 22.0723C26.8769 22.0723 28.3984 20.5507 28.3984 18.6738C28.3984 16.7969 26.8769 15.2754 25 15.2754C23.1231 15.2754 21.6016 16.7969 21.6016 18.6738C21.6016 20.5507 23.1231 22.0723 25 22.0723Z"
        fill="#F85F32"
      />
      <path
        d="M31.875 22.0723C33.0615 22.0723 34.0234 21.1104 34.0234 19.9238C34.0234 18.7373 33.0615 17.7754 31.875 17.7754C30.6885 17.7754 29.7266 18.7373 29.7266 19.9238C29.7266 21.1104 30.6885 22.0723 31.875 22.0723Z"
        fill="#F85F32"
      />
      <path
        d="M18.125 22.0723C19.3115 22.0723 20.2734 21.1104 20.2734 19.9238C20.2734 18.7373 19.3115 17.7754 18.125 17.7754C16.9385 17.7754 15.9766 18.7373 15.9766 19.9238C15.9766 21.1104 16.9385 22.0723 18.125 22.0723Z"
        fill="#F85F32"
      />
      <path
        d="M20.2418 24.0017C19.3961 23.3088 18.6302 23.4005 17.6523 23.4005C16.1898 23.4005 15 24.5833 15 26.0368V30.3028C15 30.9341 15.5152 31.4474 16.1488 31.4474C18.8842 31.4474 18.5547 31.4969 18.5547 31.3294C18.5547 28.3065 18.1966 26.0897 20.2418 24.0017Z"
        fill="#F85F32"
      />
      <path
        d="M25.9297 23.4152C24.2217 23.2728 22.7372 23.4169 21.4567 24.4738C19.3138 26.1902 19.7262 28.5013 19.7262 31.3285C19.7262 32.0765 20.3348 32.6965 21.0942 32.6965C29.3395 32.6965 29.6677 32.9625 30.1567 31.8797C30.317 31.5135 30.2731 31.6299 30.2731 28.127C30.2731 25.3447 27.864 23.4152 25.9297 23.4152Z"
        fill="#F85F32"
      />
      <path
        d="M32.3473 23.4004C31.3641 23.4004 30.6024 23.3096 29.7578 24.0016C31.7877 26.0741 31.4449 28.1397 31.4449 31.3293C31.4449 31.4978 31.1714 31.4473 33.8098 31.4473C34.466 31.4473 34.9996 30.9156 34.9996 30.2621V26.0367C34.9996 24.5832 33.8098 23.4004 32.3473 23.4004Z"
        fill="#F85F32"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6535_1108446"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6535_1108446"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6535_1108446"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default UsersIcon;
