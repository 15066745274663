import { SurveyQuestionElementBase } from "survey-react-ui";

import Map from "./Map";

import "mapbox-gl/dist/mapbox-gl.css";

export const pointPickerProperties = [{
  name: "_id",
  category: "general",
  visibleIndex: 2,
}];
export default class SurveyPointPickerQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderElement() {
    // @ts-ignore
    return <Map questionId={this.question._id} name={this.question.name} />;
  }
}
