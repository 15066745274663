export const permissionActions = {
  qaActions: {
    view: "view",
    edit: "edit",
    externalReviewer: "external_reviewers",
    reviewerObservation: "reviewer-observations",
    reviewSection: "review-section",
    review: "review",
    create: "create",
    delete: "delete",
  },
  trackingsActions: {
    view: "view",
  },
  verifyEmailPhoneActions: {
    view: "view",
    edit: "edit",
    create: "create",
    delete: "delete",
  },
  permissionsActions: {
    createAction: "create-action",
    view: "view",
    edit: "edit",
  },
  attachSocialAccountActions: { create: "create" },
  usersActions: {
    notification: "notification",
    settings: "settings",
    edit: "edit",
    dropDown: "drop-down",
    restore: "restore",
    view: "view",
    directory: "directory",
    invitation: "invitation",
    profile: "profile",
    communityMember: "community-member",
    person: "person",
    communitiesRole: "communities-role",
    create: "create",
    bulkEmails: "bulk-emails",
    resetPassword: "reset-password",
    changeStatus: "change-status",
    delete: "delete",
  },
  typesActions: {
    archive: "archive",
    create: "create",
    view: "view",
    edit: "edit",
    delete: "delete",
    dropdown: "dropdown",
  },
  newPasswordActions: { create: "create" },
  contactInformationActions: {
    delete: "delete",
    edit: "edit",
    view: "view",
    create: "create",
  },
  passwordActions: {
    edit: "edit",
    delete: "delete",
    view: "view",
    create: "create",
  },
  protocolsActions: {
    edit: "edit",
    connectProgramForm: "connect-program-form",
    archive: "archive",
    disconnectProgramForm: "disconnect-program-form",
    download: "download",
    create: "create",
    directory: "directory",
    delete: "delete",
    view: "view",
    files: "files",
  },
  programsActions: {
    observersDropdown: "observers-dropdown",
    edit: "edit",
    status: "status",
    statusHistory: "status-history",
    entityDropdown: "entity-dropdown",
    externalEndDataViewer: "external-end-data-viewer",
    externalEndDataViewers: "external-end-data-viewers",
    inviteExternalEndDataViewer: "invite-external-end-data-reviewer",
    userMembers: "user-members",
    organizationsDropdown: "organizations-dropdown",
    managers: "managers",
    adminsDropdown: "admins-dropdown",
    managersDropdown: "managers-dropdown",
    reviewers: "reviewers",
    forms: "forms",
    dataAccessPolicy: "data-access-policy",
    directory: "directory",
    communities: "communities",
    contactInfo: "contact-info",
    archive: "archive",
    manager: "manager",
    member: "member",
    ReinviteExternalEndDataViewer: "reinvite-external-end-data-viewer",
    files: "files",
    view: "view",
    members: "members",
    entity: "entity",
    tags: "tags",
    delete: "delete",
    create: "create",
  },
  logoutActions: { view: "view" },
  deleteAccountRequestActions: { delete: "delete" },
  organizationsActions: {
    communities: "communities",
    directory: "directory",
    entity: "entity",
    view: "view",
    edit: "edit",
    admins: "admins",
    tags: "tags",
    archive: "archive",
    create: "create",
    delete: "delete",
    communitiesMembers: "communities-members",
    contactInfo: "contact-info",
  },
  confirmEmailPhoneActions: {
    create: "create",
    view: "view",
    edit: "edit",
    delete: "delete",
  },
  tagsActions: {
    edit: "edit",
    view: "view",
    delete: "delete",
    create: "create",
    dropdown: "dropdown",
  },
  dynamicFormsActions: {
    status: "status",
    delete: "delete",
    directory: "directory",
    view: "view",
    create: "create",
    archive: "archive",
    edit: "edit",
    template: "template",
    forms: "forms",
    fields: "fields",
    geographies: "geographies",
  },
  detachSocialAccountActions: { create: "create" },
  dashboardActions: {
    activeUsers: "active-users",
    qaNotifications: "qa-notifications",
    horizontalBarGraph: "horizontal-bar-graph",
    cumulativeObservations: "cumulative-observations",
    kpis: "kpis",
    mostObservedObjects: "most-observed-objects",
    reportNotifications: "report-notifications",
    qaProgress: "qa-progress",
    notifications: "notifications",
    mostObservedSpecies: "most-observed-species",
    clusteredMap: "clustered-map",
    observations: "observations",
    configuration: "configuration",
    view: "view",
    observationsByRegion: "observations-by-region",
  },
  speciesActions: {
    dropdown: "dropdown",
    view: "view",
    delete: "delete",
    create: "create",
    archive: "archive",
    edit: "edit",
    file: "file",
  },
  observationsActions: {
    requestConsent: "request-consent",
    attachReviewer: "attach-reviewer",
    managers: "managers",
    externalReviewer: "external-reviewer",
    activities: "activities",
    archive: "archive",
    reinviteReviewer: "reinvite-reviewer",
    managersDropdown: "managers-dropdown",
    inviteExternalReviewer: "invite-external-reviewer",
    view: "view",
    seenActivities: "seen-activities",
    manager: "manager",
    reviewerAccessFor: "reviewer-access-for",
    comment: "comment",
    copyProperty: "copy-property",
    edit: "edit",
    importInfo: "import-info",
    delete: "delete",
    create: "create",
  },
  downloadActions: { create: "create" },
  fileActions: { create: "create", view: "view" },
  directoryActions: {
    edit: "edit",
    selected: "selected",
    create: "create",
    delete: "delete",
    view: "view",
    save: "save",
  },
  userQueriesActions: { view: "view", answer: "answer" },
  universalSettingsActions: { view: "view", edit: "edit" },
  accountInfoActions: {
    view: "view",
    edit: "edit",
    delete: "delete",
    create: "create",
  },
  rolesActions: {
    delete: "delete",
    duplicateRole: "duplicate-role",
    create: "create",
    edit: "edit",
    editable: "editable",
    view: "view",
  },
  assignRolesActions: { edit: "edit" },
  conversationsActions: {
    chat: "chat",
    muteUnmute: "mute-unmute",
    create: "create",
    delete: "delete",
    archive: "archive",
    view: "view",
    export: "export",
    message: "message",
    contactPersons: "contact-persons",
  },
  reportsActions: {
    result: "result",
    processedVersion: "processed-version",
    downloadResult: "download-result",
    view: "view",
    summary: "summary",
    entityFilters: "entity-filters",
    columns: "columns",
    setting: "setting",
    admins: "admins",
    version: "version",
    analytics: "analytics",
    create: "create",
    archive: "archive",
    analyticsDropdown: "analytics-dropdown",
    manualVersion: "manual-version",
    entityDrodown: "entity-dropdown",
    addPermission: "add-permission",
    exportPermission: "export-permission",
    delete: "delete",
    chooseColumns: "choose-columns",
    geospatialFiltering: "geospatial-filtering",
    edit: "edit",
    filtering: "filtering",
    geoSpatialColumn: "geospatial-columns",
  },
  objectsActions: {
    dropdown: "dropdown",
    edit: "edit",
    archive: "archive",
    view: "view",
    file: "file",
    create: "create",
  },
  geographiesActions: {
    reportsGeographies: "reports-geographies",
    edit: "edit",
    mapList: "map-list",
    view: "view",
    directory: "directory",
    archive: "archive",
    create: "create",
    vectorLayer: "vector-layers",
  },
  importsActions: {
    create: "create",
    delete: "delete",
    edit: "edit",
    viewData: "view-data",
    view: "view",
    propertyDropdown: "property-dropdown",
    summary: "summary",
    columnMapping: "column-mapping",
    upload: "upload",
    import: "import",
    updateObserver: "update-observer",
    statusDetail: "status-detail",
  },
  categoriesActions: {
    create: "create",
    dropdown: "dropdown",
    edit: "edit",
    archive: "archive",
    view: "view",
  },

  behaviorsActions: {
    create: "create",
    // dropdown: "dropdown",
    edit: "edit",
    archive: "archive",
    view: "view",
  },
  conditionsActions: {
    create: "create",
    // dropdown: "dropdown",
    edit: "edit",
    archive: "archive",
    view: "view",
  },
  universalSettingsImagesActions: { edit: "edit", view: "view" },
  communitiesActions: {
    userAdmins: "user-admins",
    entity: "entity",
    savedRegions: "saved-regions",
    tags: "tags",
    directory: "directory",
    contactInfo: "contact-info",
    access: "access",
    objects: "objects",
    vectorLayers: "vector-layers",
    create: "create",
    delete: "delete",
    regions: "regions",
    geography: "geography",
    member: "member",
    reuseList: "reuse-list",
    entityDropdown: "entity-dropdown",
    edit: "edit",
    species: "species",
    view: "view",
    archive: "archive",
    userMembers: "user-members",
    file: "file",
    status: "status",
    organizations: "organizations",
    attachRegion: "attach-region",
    geographyMapList: "geography-map-list",
    members: "members",
  },
  profileActions: {
    settings: "settings",
    primaryEmailPhone: "primary-email-phone",
    view: "view",
    usersQueries: "users-queries",
    edit: "edit",
    roles: "roles",
    appTheme: "app-theme",
    deleteAddress: "delete-address",
    notification: "notification",
    password: "password",
    accountInfo: "account-info",
    canEditUser: "can_edit_user",
    secondaryEmailPhone: "secondary-email-phone",
    additionalInformation: "additional-information",
    allowNotification: "allow-notification",
    create: "create",
    delete: "delete",
  },
  remindersActions: {
    statusHistory: "status-history",
    delete: "delete",
    view: "view",
    create: "create",
    person: "person",
    edit: "edit",
    members: "members",
    targetMembers: "target-members",
  },
  notificationsActions: {
    view: "view",
    readAll: "read-all",
    read: "read",
  },
  surveyAndPollsActions: {
    create: "create",
    delete: "delete",
    edit: "edit",
    fields: "fields",
    status: "status",
    view: "view",
    result: "result",
    dropdown: "dropdown",
    version: "versions",
  },
  globalSearchActions: {
    view: "view",
  },
  markerStylesActions: {
    archive: "archive",
    create: "create",
    directory: "directory",
    dropdown: "dropdown",
    edit: "edit",
    view: "view",
  },
  markerGroupsActions: {
    archive: "archive",
    create: "create",
    directory: "directory",
    dropdown: "dropdown",
    edit: "edit",
    view: "view",
  },
};

export const permissionOperations = {
  qaOperations: {
    canView: "view",
    canEdit: "edit",
    canCreate: "create",
    canDelete: "delete",
  },
  verifyEmailPhoneOperations: {
    canView: "view",
    canEdit: "edit",
    canCreate: "create",
    canDelete: "delete",
  },
  permissionsOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
  },
  attachSocialAccountOperations: { canCreate: "create" },
  usersOperations: {
    canView: "view",
    canEdit: "edit",
    canCreate: "create",
    canDelete: "delete",
  },
  typesOperations: {
    canArchive: "archive",
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canDelete: "delete",
  },
  newPasswordOperations: { canCreate: "create" },
  contactInformationOperations: {
    canDelete: "delete",
    canEdit: "edit",
    canView: "view",
    canCreate: "create",
  },
  passwordOperations: {
    canEdit: "edit",
    canDelete: "delete",
    canView: "view",
    canCreate: "create",
  },
  protocolsOperations: {
    canEdit: "edit",
    canArchive: "archive",
    canCreate: "create",
    canDelete: "delete",
    canView: "view",
  },
  programsOperations: {
    canView: "view",
    canEdit: "edit",
    canCreate: "create",
    canArchive: "archive",
    canDelete: "delete",
  },
  logoutOperations: { canView: "view" },
  deleteAccountRequestOperations: { canDelete: "delete" },
  organizationsOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canDelete: "delete",
    canArchive: "archive",
  },
  confirmEmailPhoneOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canDelete: "delete",
  },
  tagsOperations: {
    canEdit: "edit",
    canView: "view",
    canDelete: "delete",
    canCreate: "create",
  },
  dynamicFormsOperations: {
    canEdit: "edit",
    canDelete: "delete",
    canView: "view",
    canCreate: "create",
    canArchive: "archive",
  },
  detachSocialAccountOperations: { canCreate: "create" },
  dashboardOperations: { canView: "view", canCreate: "create" },
  speciesOperations: {
    canView: "view",
    canDelete: "delete",
    canCreate: "create",
    canArchive: "archive",
    canEdit: "edit",
  },
  observationsOperations: {
    canEdit: "edit",
    canCreate: "create",
    canView: "view",
    canArchive: "archive",
    canDelete: "delete",
  },
  downloadOperations: { canCreate: "create" },
  fileOperations: { canCreate: "create", canView: "view" },
  directoryOperations: {
    canEdit: "edit",
    canView: "view",
    canCreate: "create",
    canDelete: "delete",
  },
  userQueriesOperations: { canView: "view", canEdit: "edit" },
  universalSettingsOperations: { canView: "view", canEdit: "edit" },
  accountInfoOperations: {
    canView: "view",
    canEdit: "edit",
    canDelete: "delete",
    canCreate: "create",
  },
  rolesOperations: {
    canDelete: "delete",
    canCreate: "create",
    canEdit: "edit",
    canView: "view",
  },
  assignRolesOperations: { canEdit: "edit" },
  conversationsOperations: {
    canDelete: "delete",
    canEdit: "edit",
    canCreate: "create",
    canArchive: "archive",
    canView: "view",
  },
  reportsOperations: {
    canView: "view",
    canCreate: "create",
    canDelete: "delete",
    canArchive: "archive",
    canEdit: "edit",
  },
  objectsOperations: {
    canView: "view",
    canEdit: "edit",
    canArchive: "archive",
    canDelete: "delete",
    canCreate: "create",
  },
  geographiesOperations: {
    canView: "view",
    canEdit: "edit",
    canArchive: "archive",
    canCreate: "create",
  },
  importsOperations: {
    canCreate: "create",
    canDelete: "delete",
    canEdit: "edit",
    canView: "view",
  },
  categoriesOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canArchive: "archive",
  },
  behaviorsOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canArchive: "archive",
  },
  conditionsOperations: {
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
    canArchive: "archive",
  },
  universalSettingsImagesOperations: { canEdit: "edit", canView: "view" },
  communitiesOperations: {
    canView: "view",
    canEdit: "edit",
    canCreate: "create",
    canDelete: "delete",
    canArchive: "archive",
  },
  profileOperations: {
    canEdit: "edit",
    canView: "view",
    canCreate: "create",
    canDelete: "delete",
  },
  remindersOperations: {
    canView: "view",
    canDelete: "delete",
    canCreate: "create",
    canEdit: "edit",
  },
  notificationsOperations: {
    canView: "view",
    canCreate: "create",
    canEdit: "edit",
  },
  surveyAndPollsOperations: {
    canCreate: "create",
    canDelete: "delete",
    canEdit: "edit",
    canView: "view",
  },
  trackingsOperations: {
    canView: "view",
  },
  globalSearchOperations: {
    canView: "view",
  },
  markerStylesOperations: {
    canArchive: "archive",
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
  },
  markerGroupsOperations: {
    canArchive: "archive",
    canCreate: "create",
    canView: "view",
    canEdit: "edit",
  },
};
