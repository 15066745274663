import { Ifetch } from "../initialState";

export interface IObaservationByLocation {
  coordinates: number[];
  type: string;
}

export interface IfetchObservationByLocation extends Ifetch {
  data: any[];
}

const clusteredMapInitialState: IfetchObservationByLocation = {
  data: [],
  message: "",
  stateIs: "Pending",
};
export default clusteredMapInitialState;
