import React, { useState } from "react";
/************ Components ************/
import { Toasts } from "view/components/Toasts";
/************ Utils ************/
import { facebookAppId } from "config";
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
/************ API Services ************/
import apiLibrary from "services/api";
/************ Store Utils ************/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { updateSocialAccountsAction } from "store/userProfile";
import { useDispatch, useSelector } from "react-redux";
/************ Assets ************/
import SocialFacebookIcon from "assets/icons/SocialIcons/SocialFacebookIcon";
import SocialButton from "./SocialAccountButton";
/************ External Library ************/
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";

export const ConnectFacebook: React.FC = () => {
  const dispatch = useDispatch();
  const { detachSocialAccount, attachSocialAccount } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  /**
   * Handles the response from Facebook login and performs appropriate actions.
   * @param {ReactFacebookLoginInfo | ReactFacebookFailureResponse} response - The response object from Facebook login.
   */
  const responseFacebook = async (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {
    if ("name" in response) {
      const { accessToken } = response as ReactFacebookLoginInfo & {
        // Extract the access token from the Facebook login response.
        accessToken: string;
      };
      setIsLoading(true);
      try {
        if (
          isConnected("facebook") === "Connected" &&
          detachSocialAccount.canCreateDetachSocialAccount
        ) {
          const res = await apiLibrary.Auth.detachSocialAccounts({
            channel: "facebook",
          });
          dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
          Toasts.success(res.message);
          return;
        }
        if (attachSocialAccount.canCreateAttachSocialAccount) {
          const res = await apiLibrary.Auth.attachSocialAccounts(
            convertKeysToSnakeCase({
              accessToken,
              channel: "facebook",
            })
          );

          const convertedData = convertKeysToCamelCase(
            res?.data?.socialAccounts
          );
          dispatch(updateSocialAccountsAction(convertedData));

          Toasts.success(res.message);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("Facebook login failed:", response);
      setIsLoading(false);
    }
  };

  const appId = facebookAppId;

  /**
   * Determines if a social account is connected based on the provided name.
   *
   * @param {string} name - The name of the social account to check for connection.
   * @returns {string} The connection status, either "Connected" or "Disconnected".
   */
  const isConnected = (name: any) => {
    return userProfile?.socialAccounts?.find((item: any) => item === name)
      ? "Connected"
      : "Disconnected";
  };

  const deAttachSocialAccounts = async () => {
    if (
      isConnected("facebook") === "Connected" &&
      detachSocialAccount.canCreateDetachSocialAccount
    ) {
      setIsLoading(true);
      try {
        const res = await apiLibrary.Auth.detachSocialAccounts({
          channel: "facebook",
        });
        dispatch(updateSocialAccountsAction(res?.data?.socialAccounts));
        Toasts.success(res.message);
      } catch (error: any) {
        console.error("Error detaching social account:", error);
        // Handle error here
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isConnected("facebook") === "Connected") {
    return (
      <>
        <div className="relative">
          {isLoading && (
            <div className="loader absolute justify-center items-center flex w-full">
              <TailSpin
                height="30"
                width="30"
                color="#005C89"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{ marginTop: "3px" }}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          )}
          <SocialButton
            onClick={deAttachSocialAccounts}
            className={isLoading ? "opacity-[0.4]" : ""}
            itemHover={isLoading ? true : false}
            disabled={
              isLoading || !detachSocialAccount.canCreateDetachSocialAccount
                ? true
                : false
            }
            icon={SocialFacebookIcon}
            text={isConnected("facebook")}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className="relative">
        {isLoading && (
          <div className="loader absolute justify-center items-center flex w-full">
            <TailSpin
              height="30"
              width="30"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{ marginTop: "3px" }}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        )}
        <FacebookLogin
          appId={appId}
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={(renderProps: any) => (
            <SocialButton
              className={isLoading ? "opacity-[0.4]" : ""}
              disabled={
                renderProps.disabled ||
                isLoading ||
                (isConnected("facebook") === "Connected" &&
                  !detachSocialAccount.canCreateDetachSocialAccount) ||
                !attachSocialAccount.canCreateAttachSocialAccount
                  ? true
                  : false
              }
              itemHover={isLoading ? true : false}
              onClick={renderProps.onClick}
              icon={SocialFacebookIcon}
              text={isConnected("facebook")}
            />
          )}
        />
      </div>
    );
  }
};
