export interface IObservation {
  communityName: {
    image: string;
    id: number;
    name: string;
  };
  dateEdited: string;
  id: number;
  observationCode: string;
  oldCode: any;
  submissionDate: string;
  observer: {
    id: number;
    name: string;
    image: string;
  };
  protocolId: number;
  programName: {
    id: number;
    name: string;
    image: string;
  };
  form: {
    id: any;
    name: string;
  };
  status: string;
  programStatus: string | undefined;
  flag: string | null;
}

export interface IobservationsData {
  // fields: string[];
  observations: IObservation[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchObservations {
  data: IobservationsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialObservationsData: IfetchObservations = {
  data: {
    observations: [],
    // fields: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};

export default initialObservationsData;
