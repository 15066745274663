import React from "react";
import { Box, Grid } from "@mui/material";
import { ObservationsByLocationMap } from "./components/Mapbox";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { TailSpin } from "react-loader-spinner";

const ObservationsByLocation = () => {
  const {
    dashboard: { observationByLocation },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchObservationByLocation());
  }, [dashboardFilter]);

  return (
    <Box>
      <DashboardCardHeading text="Observations By Location"  widgetId="observations_by_location"/>
      {observationByLocation.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ObservationsByLocationMap />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ObservationsByLocation;
