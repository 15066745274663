import { apiClient } from "../config";

async function deleteNotificationRecipients(id: any, personId: any): Promise<any> {
    try {
        const res = await apiClient.delete(`/admin/reminders/${id}/person`, {
            data: {
                personId, // Assuming personId needs to be sent in the request body
            },
        });

        return res.data;
    } catch (error:any) {
        // Handle error appropriately (logging, error message, etc.)
        console.error('Error deleting notification recipients:', error);
        throw error; // Propagate the error
    }
}

export default deleteNotificationRecipients;
