import getAllTags from "./getAllTags";
import deleteAtag from "./deleteATag";
import addTag from "./addTag";
import editTag from "./editTag";
const Tagging = {
  getAllTags,
  deleteAtag,
  addTag,
  editTag,
};

export default Tagging;
