import { apiClient } from "../config";

async function addManagersToProgram(
  programId: string,
  personIds:any
) {

  const response = await apiClient.post(`/admin/programs/${programId}/managers`, personIds);

  return response.data;
}

export default addManagersToProgram;