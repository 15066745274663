import React, { useEffect, useState } from "react";
import { ObservationStatuses } from "./Components/Observers";
import Select, { SingleValue, components } from "react-select";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { RootState } from "store";
import { importsViewDataStatusAction } from "store/filters";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { DarkMode } from "store/darkMode/initialState";

interface OptionType {
  value: string;
  label: string;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { importsViewDataFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  const [selectedOption, setSelectedOption] = useState<OptionType | null>({
    label: "All Statuses",
    value: "",
  });

  const options = [
    {
      label: "All Statuses",
      value: "",
    },
    {
      label: "imported",
      value: "1",
    },
    {
      label: "failed",
      value: "2",
    },
  ];

  useEffect(() => {
    if (selectedOption?.value) {
      if (
        importsViewDataFilter.status &&
        importsViewDataFilter.status === selectedOption?.label
      ) {
        dispatch(importsViewDataStatusAction(""));
      } else {
        dispatch(importsViewDataStatusAction(selectedOption?.label));
      }
    } else {
      dispatch(importsViewDataStatusAction(""));
    }
  }, [selectedOption]);

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option as OptionType);
  };

  return (
    <div>
      <div className="flex flex-wrap relative items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        <div className="z-[99]">
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isSearchable={false}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            styles={{
              container: (provided) => ({
                position: "relative",
                zIndex: 999,
                border: "none",
              }),
              control: (provided) => ({
                ...provided,
                fontWeight: "500",
                background: "none",
                cursor: "pointer",
                fontSize: "14px",
                textTransform: "capitalize",
                borderRadius: "0.5rem",
                borderColor: "#E6E8E9",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#E6E8E9",
                },
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: "180px",
                background: isDarkMode ? "#21262D" : "#fff",
              }),
              menuList: (provided) => ({
                ...provided,
                textTransform: "capitalize",
                fontSize: "14px",
              }),
              indicatorsContainer: () => ({
                padding: 0,

                "&.indicatorContainer": {
                  padding: 0,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                display: "flex",
                paddingRight: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: isDarkMode ? "#E8EFF4" : "#000",
              }),
              option: (provided, state) => ({
                ...provided,
                cursor: "pointer",
                color: isDarkMode
                  ? state.isSelected
                    ? "#005C89"
                    : "#A4B3C1"
                  : state.isSelected
                    ? "#005C89"
                    : "#2C3236",
                backgroundColor: state.isSelected ? "#DBE8EE" : "transparent",
                fontWeight: state.isSelected ? "500" : "400",
              }),
            }}
          />
        </div>
      </div>
      {/* <AppliedFilters /> */}
    </div>
  );
};

export default FiltersList;

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CheveronDownIcon
        style={{
          transform: props.selectProps.menuIsOpen
            ? "rotate(180deg)"
            : "rotate(0deg)",
          transition: "transform 0.2s",
          position: "relative",
          height: "18px",
          width: "18px",
        }}
      />
    </components.DropdownIndicator>
  );
};
