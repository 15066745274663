import React, { useState, useEffect } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { TailSpin } from "react-loader-spinner";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import Button from "view/components/Button";
import { settings } from "survey-core";
import { Header, SurveyForm } from "./Components";
import { themeJson } from "./Components/theme";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import { sentenceCase } from "change-case";
// settings.lazyRowsRendering = true;

export const SubmitSurvey: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [surveyDetails, setSurveyDetails] = useState<any>(null);
  const [surveyResult, setSurveyResult] = useState<any>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { surveyId } = useParams<{ surveyId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      if (!surveyId) return setIsLoading(false);
      try {
        let { data } =
          await apiLibrary.pollsAndSurveys.getSurveyDetailsByToken(surveyId);

        data.surveyFields = convertJson(data.surveyFields);

        setSurveyDetails(data);
      } catch (error: any) {
        Toasts.error(error?.response?.data?.message ?? error.message);
        // navigate("/polls-and-surveys/list");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurveyDetails();
  }, [surveyId, navigate]);

  const handleSubmit = async (surveyData: any) => {
    // console.log(
    //   "surveyData",
    //   processSurveyJsData(surveyDetails.surveyFields, surveyData)
    // );

    try {
      await apiLibrary.pollsAndSurveys.submitSurvey(surveyDetails?.id, {
        response: surveyData,
        survey_and_poll_version_id: surveyDetails.surveyAndPollVersionId,
      });
      setSurveyResult(surveyData);
      Toasts.success("Survey submitted successfully");
    } catch (error: any) {
      setSubmitError(error?.response?.data?.message ?? error.message);
    }
  };
  if (isLoading) {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <TailSpin
          height={50}
          width={50}
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius={2}
          visible={true}
        />
      </div>
    );
  }

  if (submitError) {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <div className="flex flex-col gap-4">
          <p className="flex-grow w-full text-xl font-semibold text-center capitalize text-accent_1Dark">
            {submitError}
          </p>
          <Button
            type="button"
            text="Try Again"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="filled"
            onClick={() => setSubmitError(null)}
          />
        </div>
      </div>
    );
  }

  if (surveyResult) {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <p className="flex-grow w-full text-xl font-semibold text-center capitalize text-secondaryMid">
          Thank you for completing the survey
        </p>
      </div>
    );
  }

  return surveyDetails ? (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20 mx-5">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header surveyDetails={surveyDetails} />

        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {surveyDetails.surveyFields && (
            <SurveyForm
              handleSubmit={handleSubmit}
              setSubmitError={setSubmitError}
              surveyDetails={surveyDetails}
              setSurveyResult={setSurveyResult}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <p>Survey details not available.</p>
  );
};

export default SubmitSurvey;

function convertJson(json: any) {
  function addProperty(element: any) {
    if (element.type === "file" || element.type === "signaturepad") {
      element.storeDataAsText = false;
    }

    if (Array.isArray(element.elements)) {
      element.elements.forEach(addProperty);
    }

    if (Array.isArray(element.templateElements)) {
      element.templateElements.forEach(addProperty);
    }
  }

  json.pages.forEach((page: any) => {
    page.elements.forEach(addProperty);
  });

  return json;
}

function processSurveyJsData(json: any, responses: any) {
  const updatedResponses = { ...responses };
  json.pages.forEach((page: any) => {
    page.elements.forEach((element: any) => {
      processElement(element, updatedResponses);
    });
  });

  return updatedResponses;
}

function processElement(element: any, responses: any) {
  if (element.type === questionTypes.paneldynamic && responses[element.name]) {
    handlePaneldynamic(element, responses);
  } else if (element.type === questionTypes.panel) {
    handlePanel(element, responses);
  } else {
    handleSimpleType(element, responses);
  }
}

function updateElementResponses(element: any, response: any) {
  if (!response) return response;

  switch (element.type) {
    case questionTypes.checkbox:
    case questionTypes.tagbox:
    case "ranking":
    case questionTypes.imagepicker:
      const normalizedResponses = Array.isArray(response)
        ? response
        : [response];
      return normalizedResponses.map((answer) => {
        const matchingChoice = element.choices.find((choice: any) =>
          typeof choice === "object"
            ? choice.value === answer
            : choice === answer
        );
        return matchingChoice;
      });
    case questionTypes.radio:
    case questionTypes.dropdown:
      const matchingChoice = element.choices.find((choice: any) =>
        typeof choice === "object"
          ? choice.value === response
          : choice === response
      );

      if (typeof matchingChoice === "object" && matchingChoice !== null) {
        return matchingChoice;
      }

      return {
        text: String(matchingChoice),
        value: matchingChoice,
      };
    case questionTypes.file:
      const updatedResponse = response.map((response: any) => {
        return {
          name: response.name,
          fileLink: response.content,
          fileType: response.type,
        };
      });

      return updatedResponse;
    case questionTypes.boolean:
      return response ? element.labelTrue : element.labelFalse ?? response;
    default:
      return response;
  }
}

function handlePaneldynamic(element: any, responses: any) {
  responses[element.name] = responses[element.name].map((panel: any) => {
    const updatedPanel = element.templateElements.map(
      (templateElement: any) => ({
        title: templateElement.title || sentenceCase(templateElement.name),
        answer: updateElementResponses(
          templateElement,
          panel[templateElement.name]
        ),
        type: templateElement.type,
      })
    );

    return {
      title: element.title || sentenceCase(element.name),
      answers: updatedPanel,
      type: element.type,
    };
  })[0];
}

function handlePanel(element: any, responses: any) {
  element.elements.forEach((subElement: any) => {
    if (responses[subElement.name]) {
      responses[subElement.name] = {
        title: subElement.title || sentenceCase(subElement.name),
        type: subElement.type,
        answer: updateElementResponses(subElement, responses[subElement.name]),
      };
    }
  });
}

function handleSimpleType(element: any, responses: any) {
  if (responses[element.name]) {
    responses[element.name] = {
      title: element.title || sentenceCase(element.name),
      type: element.type,
      answer: updateElementResponses(element, responses[element.name]),
    };
  }
}
