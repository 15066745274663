import { Dispatch } from "redux";
import {
  GET_ALL_REPORTS_RESULT,
  RESET_DATA,
  START_LOADING_REPORTS_RESULT,
  STOP_LOADING_REPORTS_RESULT,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import axios from "axios";

export const fetchResultReportsAction =
  (id: any, versionId: any) => async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_REPORTS_RESULT });
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const { data } = await apiLibrary.Reports.getResultVersion(id, versionId);
      const responseData = await axios.get(data.path);
      dispatch({
        type: GET_ALL_REPORTS_RESULT,
        payload: { data, responseData: responseData },
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the notifications:", error);
    } finally {
      dispatch({ type: STOP_LOADING_REPORTS_RESULT });
      dispatch(setLoadingProgress(100));
    }
  };
export const reportsResultResetActions = () => {
  return {
    type: RESET_DATA,
  };
};
