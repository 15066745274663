import React, { useEffect, useState } from "react";
import Select, { MenuPlacement } from "react-select";
import { useParams } from "react-router-dom";

const customStyles = (transformY: string, height: string) => {
  return {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      padding: "0px",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "8px",
      width: "100%",
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: null,
      outline: "none",
      "&:hover": {
        cursor: "text",
      },
    }),
    menu: (provided: any) => {
      return {
        ...provided,
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        minHeight: "100px",
        height: height,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 10000,
        transform: transformY,
        width: "100%",
      };
    },
    menuList: (provided: any) => {
      return {
        ...provided,
        overflowX: "hidden",
        /* Add additional styles for MenuList here */
      };
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#ebf2f6" : null,
        color: state.isFocused ? "#005C89" : null,
        "&:hover": {
          backgroundColor: state.isFocused ? "#ebf2f6" : "#f8f9fa",
          cursor: "pointer",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowX: "hidden",
        },
        overflowWrap: "break-word",
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        display: "none",
      };
    },
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        cursor: "pointer",
        display: "none",
      };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        backgroundColor: "#f1f5f8",
        borderRadius: "8px",
        display: "inline-flex",
        gap: "0.5rem",
        alignItems: "center",
        padding: "4px 8px",
      };
    },
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: null,
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      svg: {
        fill: "black",
      },
    }),
  };
};

const MultiValue = ({ data, remove }: any) => {
  const { communityId } = useParams<{ communityId: string }>();

  return (
    <div className="flex justify-center items-center mr-1 mb-1 flex-grow-0 flex-shrink-0 relative gap-1 p-1 rounded bg-primary/[0.08]">
      <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 p-1">
        <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-textMid ">
          {data.label}
        </p>
      </div>
      <button
        className="disabled:cursor-not-allowed"
        disabled={communityId !== undefined && data.id === communityId}
        onClick={() => remove(data)}
      >
        {" "}
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.86128 4.86201C5.12163 4.60166 5.54374 4.60166 5.80409 4.86201L7.99935 7.05727L10.1946 4.86201C10.455 4.60166 10.8771 4.60166 11.1374 4.86201C11.3978 5.12236 11.3978 5.54447 11.1374 5.80482L8.94216 8.00008L11.1374 10.1953C11.3978 10.4557 11.3978 10.8778 11.1374 11.1382C10.8771 11.3985 10.455 11.3985 10.1946 11.1382L7.99935 8.94289L5.80409 11.1382C5.54374 11.3985 5.12163 11.3985 4.86128 11.1382C4.60093 10.8778 4.60093 10.4557 4.86128 10.1953L7.05654 8.00008L4.86128 5.80482C4.60093 5.54447 4.60093 5.12236 4.86128 4.86201Z"
            fill="#2C3236"
          />
        </svg>
      </button>
    </div>
  );
};

interface MultiSelectProps {
  inputValue?: string;
  onInputChange: (value: string) => void;
  options: { value: string; label: string }[];
  defaultValue: { value: string; label: string };
  isMulti: boolean;
  value: any;
  onChange: (selectedOptions: any) => void;
  transformY?: string;
  isDisable?: boolean;
  placeholder?: string;
  name?: string;
  formatOption?: any;
  maxWidth?: string;
  closeOnSelect?: boolean;
  onMenuOpen?: any;
  height?: string;
  className?: any;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  inputValue,
  onInputChange,
  options,
  defaultValue,
  isMulti,
  value,
  onChange,
  transformY,
  isDisable,
  placeholder,
  formatOption,
  name,
  onMenuOpen,
  closeOnSelect = true,
  height,
  maxWidth,
  className,
}) => {
  const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>("bottom");
  const [showClearIcon, setShowClearIcon] = useState(false); // State to control the clear icon display

  useEffect(() => {
    const menuElement = document.querySelector(".css-1pahdxg-control__menu");

    if (menuElement) {
      const windowHeight = window.innerHeight;
      const menuRect = menuElement.getBoundingClientRect();

      if (menuRect.bottom > windowHeight) {
        setMenuPlacement("top");
      } else {
        setMenuPlacement("bottom");
      }
    }
  }, [value, options]);

  // Function to clear the input field
  const clearInput = () => {
    onInputChange(""); // Clear the input by setting it to an empty string
    setShowClearIcon(false); // Hide the clear icon
  };

  // Function to handle input change
  const handleInputChange = (newValue: string) => {
    onInputChange(newValue); // Call the provided input change handler
    setShowClearIcon(newValue.length > 0); // Show the clear icon if the input is not empty
  };

  return (
    <>
      <div className="relative w-full">
        <Select
          isClearable={true}
          inputValue={inputValue}
          name={name}
          classNamePrefix="my-react-select"
          className={`${className} dark:bg-secondaryLight  my-react-select-container`}
          onMenuOpen={onMenuOpen}
          onInputChange={handleInputChange}
          options={options}
          defaultValue={defaultValue}
          placeholder={placeholder || "Select..."}
          menuPlacement={menuPlacement}
          isMulti={isMulti}
          value={value}
          onChange={onChange}
          maxMenuHeight={200}
          isDisabled={isDisable}
          closeMenuOnSelect={closeOnSelect}
          styles={{
            ...customStyles(
              transformY ? transformY : "0",
              height ? height : "unset"
            ),
            control: (provided, state) => ({
              ...provided,
              height: height,
              alignItems: "flex-start",
              border: "1px solid transparent",
              boxShadow: "none",
              outline: "none",
              width: "255px",

              "&:hover": {
                // border: "1px solid transparent",
              },
              "&:focus": {
                outline: "none",
                border: "1px solid transparent",
                boxShadow: "none",
              },
            }),
            menu: (provided, state) => ({
              ...provided,
              marginTop: "2px", // Adjust the margin-top value as needed
              // Add any other styles for the menu here
            }),
          }}
          formatOptionLabel={formatOption}
          isSearchable={true}
          noOptionsMessage={() => "No Record found"}
        />
      </div>
      {showClearIcon && (
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2"
          onClick={clearInput}
        >
          <svg
            width={20}
            height={20}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.86128 4.86201C5.12163 4.60166 5.54374 4.60166 5.80409 4.86201L7.99935 7.05727L10.1946 4.86201C10.455 4.60166 10.8771 4.60166 11.1374 4.86201C11.3978 5.12236 11.3978 5.54447 11.1374 5.80482L8.94216 8.00008L11.1374 10.1953C11.3978 10.4557 11.3978 10.8778 11.1374 11.1382C10.8771 11.3985 10.455 11.3985 10.1946 11.1382L7.99935 8.94289L5.80409 11.1382C5.54374 11.3985 5.12163 11.3985 4.86128 11.1382C4.60093 10.8778 4.60093 10.4557 4.86128 10.1953L7.05654 8.00008L4.86128 5.80482C4.60093 5.54447 4.60093 5.12236 4.86128 4.86201Z"
              fill="#2C3236"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default MultiSelect;
