import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ArrowUpIcon from "assets/icons/HeroIcons/ArrowUpIcon";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  IObservationsActivities,
  IfetchObservationsActivities,
} from "store/observationsActivities/initialState";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchObservationsActivitiesAction } from "store/observationsActivities";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import FlagIcon from "assets/icons/HeroIcons/FlagIcon";
import CheckCircleIcon from "assets/icons/HeroIcons/CheckCircleIcon";
import {
  activitiesFilterReset,
  activitiesPaginationAction,
} from "store/filters";
import { filtersInitialState } from "store/filters/initialState";
import {
  appendNewComment,
  fetchPaginatedObservationsActivitiesAction,
  resetObservationsActivitiesAction,
} from "store/observationsActivities/reducer.actions";
import { Toasts } from "view/components/Toasts";
import useDateFormatter from "hooks/formatDateWithPattern";
import { sentenceCase } from "change-case";
import { TailSpin } from "react-loader-spinner";
import RestoreIcon from "assets/icons/HeroIcons/RestoreIcon";
import usePermissions from "hooks/usePermissions";
import { IProfileObservation } from "store/observationProfile/initialState";
import { DarkMode } from "store/darkMode/initialState";

// React Mentions Editor
import { MentionsInput, Mention } from "react-mentions";
import { debounce } from "lodash";
import classNames from "./mentions.module.css";
import { capitalize } from "@mui/material";
import moment from "moment";
import useRoles from "hooks/roles";
import { getBrandPlaceholder } from "utils/customBranding";
import CustomToolTip from "view/components/ToolTip";

interface Comment {
  id: number;
  text: string;
  timestamp: string;
}

interface ResultObject {
  display: string;
  id: number;
}

interface IProps {
  handleExpandAllPanels: () => void;
  canEdit?: boolean;
}

const ProfileMessage: React.FC<IProps> = ({
  handleExpandAllPanels,
  canEdit,
}: any) => {
  const { observationId } = useParams();
  const { timeFromNowInTimeZone } = useDateFormatter();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const activityContainerRef = useRef<HTMLDivElement>(null);
  const [doFetchData, setDoFetchData] = useState(false);
  const observationsActivities = useSelector<
    RootState,
    IfetchObservationsActivities
  >((state) => state?.observationActivities);
  const { primary } = useSelector((state: any) => state.theme.colors);

  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const { observationActivityFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const auth = useSelector((state: RootState) => state.auth);
  const { observations } = usePermissions();

  const [newComment, setNewComment] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );
  const { isExternalReviewer } = useRoles();

  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );
  const editorRef = useRef<HTMLDivElement>(null);

  const mentionRef: any = useRef();

  useEffect(() => {
    dispatch(fetchObservationsActivitiesAction(observationId));
    return () => {
      dispatch(activitiesFilterReset());
      dispatch(resetObservationsActivitiesAction());
    };
  }, []);

  const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (commentHTML && commentHTML.trim() !== "") {
      // const plainTextComment = convertHtmlToPlainText(newComment);
      const formValues = {
        message: commentHTML,
      };
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currentTime = moment().tz(
        profileSettings.timezone || localTimeZone
      );
      setLoading(true);
      try {
        const { data, message } =
          await apiLibrary.Observations.editObservationActivity(
            formValues,
            observationId
          );
        // dispatch(fetchObservationsActivitiesAction(observationId));

        const newComment = {
          id: data.id,
          message: formValues.message,
          addedBy: {
            id: auth.person.personId,
            name: auth.person.firstName + " " + auth.person.lastName,
            image: auth.person.personImage,
          },
          messageDate: currentTime.format("YYYY-MM-DD"),
          messageDetails: {
            status: "comment",
          },
          messageTime: currentTime.format("HH:mm:ss"),
        };
        dispatch(appendNewComment(newComment));
        setNewComment("");
        Toasts.success(message);
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errMsg);
        console.error("Error while marking the section as completed:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const scrollToBottom = () => {
    if (activityContainerRef.current) {
      // Set the initial scroll position to the bottom
      activityContainerRef.current.scrollTop =
        activityContainerRef.current?.scrollHeight + 100;
    }
  };

  useEffect(() => {
    if (
      activityContainerRef.current &&
      observationsActivities?.data?.activities.length <= 20
    ) {
      scrollToBottom();
    }
  }, [observationsActivities]);

  // Handling scrolling
  useEffect(() => {
    if (doFetchData) {
      if (
        observationsActivities?.data.totalPages > observationActivityFilter.page
      ) {
        dispatch(
          activitiesPaginationAction(observationActivityFilter.page + 1)
        );
        dispatch(fetchPaginatedObservationsActivitiesAction(observationId));
      }
      setDoFetchData(false);
    }
  }, [doFetchData]);

  const handleScroll = () => {
    if (activityContainerRef.current) {
      const isScrolledToTop = activityContainerRef.current.scrollTop === 0;
      if (isScrolledToTop) {
        setDoFetchData(true);
      }
    }
  };

  useEffect(() => {
    if (activityContainerRef.current) {
      activityContainerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (activityContainerRef.current) {
        activityContainerRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, []);

  const seenActivtiesData = useMemo(() => {
    return (
      observationsActivities?.data?.activities.map((profile) => profile.id) ||
      []
    );
  }, [observationsActivities]);

  useEffect(() => {
    // dispatch(fetchObservationsActivitiesAction(observationId));
    if (seenActivtiesData.length > 0 && observations.canEditSeenActivities) {
      const seenActivitiesCall = async () => {
        try {
          const data =
            await apiLibrary.Observations.seenObservationsActivites(
              seenActivtiesData
            );
          // Toasts.success(data.message);
        } catch (error: any) {
          const errorMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errorMsg);
          console.error("Error calling seenObservationsActivites:", error);
        }
      };
      seenActivitiesCall();
    }
  }, [seenActivtiesData.length, seenActivtiesData]);

  const resultArray = useMemo(() => {
    const newArray: ResultObject[] = [];

    // Iterate through each section object in the data
    observationProfile?.sections?.forEach((section: any) => {
      // Extract sectionName and sectionId
      const { name: name, sectionId, title } = section;

      // Initialize propertyName to empty string
      let propertyName: string = "";

      newArray.push({ display: capitalize(title || name), id: sectionId });

      // Check if templateElements exist and is not null
      if (section.templateElements && section.templateElements.length > 0) {
        // Find the first non-null template element's title or name
        section.templateElements.forEach((element: any) => {
          if (!element.visible) {
            return;
          }
          if (element.title || element.name) {
            propertyName = element.title || element.name;
            newArray.push({ display: capitalize(propertyName), id: sectionId });
          }
        });
      } else if (section.elements && section.elements.length > 0) {
        // Check if elements exist and is not null
        // Find the first non-null element's title or name
        section.elements.forEach((element: any) => {
          if (!element.visible) {
            return;
          }

          if (element.title || element.name) {
            propertyName = element.title || element.name;
            newArray.push({ display: capitalize(propertyName), id: sectionId });
          }
        });
      }
    });

    return newArray;
  }, [observationProfile]);

  const handleChange = (
    event: any,
    newValue: string,
    newPlainTextValue: string,
    mentions: any
  ) => {
    setNewComment(newValue);
  };

  const convertToHTML = (text: string) => {
    // This regex matches patterns like " " and captures the link text and ID separately
    const regex = /\[([^\]]+)\]\((\d+)\)/g;

    // Replace each match with an anchor tag and wrap the entire result in a <p> tag
    const htmlContent = text.replace(
      regex,
      (match: any, linkText: any, id: any) => {
        return `<a style="color: rgb(0, 92, 137); text-decoration: none; font-style: italic" href="#${id}">${linkText}</a>`;
      }
    );

    return `<p>${htmlContent}</p>`;
  };

  const commentHTML = useMemo(() => {
    if (newComment) {
      return convertToHTML(newComment);
    }
  }, [newComment]);

  const debouncedDataFetch = useCallback(
    debounce((search, callback) => {
      if (!search) {
        callback(resultArray);
        return;
      }
      const filteredSuggestions = resultArray.filter((item) =>
        item.display.toLowerCase().includes(search.toLowerCase())
      );
      callback(filteredSuggestions);
    }, 100),
    [resultArray]
  ); // Debounce for 300ms

  // Update for Mention component's data prop
  const fetchData = (search: any, callback: any) => {
    debouncedDataFetch(search, callback);
  };

  const portalHost = useRef<any>(null);

  return (
    <div className="relative bg-bgWhite dark:bg-secondaryLight">
      <div
        className="h-[73vh] overflow-y-auto pb-28"
        ref={activityContainerRef}
      >
        {!observationsActivities.isLoading ? (
          observationsActivities?.data?.activities?.map((profile) => {
            return observationProfile.requireQa &&
              profile?.messageDetails?.status === "comment" ? (
              <></>
            ) : (
              <div key={profile?.id} className="mb-4">
                <ShowFlaggedAndCompleted
                  message={profile}
                  handleExpandAllPanels={handleExpandAllPanels}
                />
              </div>
            );
          })
        ) : (
          <div className="flex items-center justify-center h-full loader">
            <TailSpin
              height="50"
              width="50"
              color={primary}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        )}
      </div>
      {!observationProfile.requireQa && (
        <form
          onSubmit={handleSumbit}
          className="absolute h-[110px] bottom-0 w-full bg-bgWhite dark:bg-secondaryLight shadow-[rgba(0,0,0,0.03)_0px_-5px_4px_0px]"
        >
          <div
            className="relative z-50 flex items-center h-full gap-3 px-4 py-4"
            ref={portalHost}
          >
            <div className="w-6 h-6 rounded-full shrink-0">
              <img
                src={
                  auth?.person.personImage?.file?.original ??
                  getBrandPlaceholder("profile")
                }
                alt=""
                className="w-full h-full rounded-full"
              />
            </div>

            <div
              className="relative w-full qaqc-comment"
              style={{ width: "calc(100% - 35px)" }}
              aria-controls="activities-menu"
              aria-haspopup="true"
            >
              <MentionsInput
                value={newComment}
                onChange={handleChange}
                // style={mentionsInputStyle}
                className="mentions"
                classNames={classNames}
                placeholder="Add a comment"
                allowSpaceInQuery
                allowSuggestionsAboveCursor
                suggestionsPortalHost={portalHost?.current}
              >
                <Mention
                  trigger="#"
                  // data={suggestions}
                  data={fetchData}
                  renderSuggestion={(
                    suggestion: any,
                    search: any,
                    highlightedDisplay: any,
                    index: any,
                    focused: any
                  ) => (
                    <div className={`suggestion ${focused ? "focused" : ""}`}>
                      {suggestion.display}
                    </div>
                  )}
                  markup="[__display__](__id__)"
                  className={classNames.mentions__mention}
                  appendSpaceOnAdd
                />
              </MentionsInput>
            </div>

            {newComment.length > 0 && (
              <div className="absolute rounded-full right-6">
                <button
                  className={`rounded-full p-1 bg-brandHover25 dark:bg-bgtetriary z-10 focus:outline-none ${
                    loading || !observations.canEditObservationComment
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={loading || !observations.canEditObservationComment}
                >
                  <ArrowUpIcon className="p-0.5 w-4 h-4 dark:stroke-secondary z-20 dark:stroke-bgWhite" />
                </button>
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default ProfileMessage;

const ShowFlaggedAndCompleted = ({
  message,
  handleExpandAllPanels,
}: {
  message: IObservationsActivities;
  handleExpandAllPanels: any;
}) => {
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const { timeFromNowInTimeZone } = useDateFormatter();
  const { timeSinceFormat, FormatedDateWithTime } = useDateFormatter();
  const { isExternalReviewer, isInternalReviewer } = useRoles();
  const { users } = usePermissions();
  const { messageDetails } = message;
  const renderActivityByStatus = (status: string) => {
    switch (status) {
      case "flagged_section":
      case "request_rejected":
        return <FlaggedActivity messageDetails={messageDetails} />;
      case "archived":
        return <ArchivedActivity messageDetails={messageDetails} />;
      case "completed":
        return <CompletedActivity messageDetails={messageDetails} />;
      case "completed_section":
        return <CompletedSectionActivity messageDetails={messageDetails} />;
      case "assigned_reviewer":
        return <AssignedReviewerActivity messageDetails={messageDetails} />;
      case "external_reviewer":
        return <ExternalReviewerActivity messageDetails={messageDetails} />;
      case "restored":
        return <RestoreActivity messageDetails={messageDetails} />;
      case "incomplete":
        return <IncompleteActivity messageDetails={messageDetails} />;
      case "updated_section":
        return <UpdatedActivity messageDetails={messageDetails} />;
      case "request_to_edit":
        return (
          <RequestedActivity
            messageDetails={messageDetails}
            handleExpandAllPanels={handleExpandAllPanels}
          />
        );
      case "request_granted":
        return <RequestGrantedActivity messageDetails={messageDetails} />;

      case "request_granted":
        return <RequestGrantedActivity messageDetails={messageDetails} />;
      case "transcript_update":
        return <TranscriptActivity messageDetails={messageDetails} />;
      // Add cases for each specific status
      case "comment":
        return <CommentActivity message={message.message} />;
      default:
        return <UnknownActivity messageDetails={messageDetails} />;
    }
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div>
      <div className="mb-4">
        <div className="flex items-center justify-between gap-3 px-4 py-2">
          {users.canViewUsersProfile && message?.addedBy?.profilePermission ? (
            <Link
              to={`/profile/${message?.addedBy?.id}/person`}
              className="flex items-center gap-3"
            >
              <div className="w-6 h-6 rounded-full">
                <img
                  src={
                    message?.addedBy?.image?.file?.thumb
                      ? message?.addedBy?.image?.file?.thumb
                      : getBrandPlaceholder("profile")
                  }
                  alt={message?.addedBy?.name}
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="font-semibold text-secondary dark:text-textMain">
                {message?.addedBy?.name}
              </div>
            </Link>
          ) : (
            <div className="flex items-center gap-3">
              <div className="w-6 h-6 rounded-full">
                <img
                  src={
                    message?.addedBy?.image?.file?.thumb
                      ? message?.addedBy?.image?.file?.thumb
                      : getBrandPlaceholder("profile")
                  }
                  alt={message?.addedBy?.name}
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="font-semibold text-secondary dark:text-textMain">
                {message?.addedBy?.name}
              </div>
            </div>
          )}

          <div className="text-secondaryLight text-[12px] dark:text-textMain ">
            <div className="">
              <CustomToolTip
                title={`${FormatedDateWithTime(message.messageDate, message.messageTime)}`}
                showTooltip={showTooltip}
                toggleTooltip={toggleTooltip}
                moveFromRight={true}
              >
                <button
                  onClick={toggleTooltip}
                  className="hover:cursor-pointer hover:underline"
                >
                  {timeSinceFormat(message?.messageDate, message?.messageTime)}
                </button>
              </CustomToolTip>
            </div>
          </div>
        </div>
        {/* Activity Statuses */}
        <div>
          {messageDetails && messageDetails.status ? (
            renderActivityByStatus(messageDetails.status)
          ) : (
            <div className="pl-12 pr-4">
              <div className="ml-[4.5px] flex items-center text-textMidLight dark:text-caption rounded gap-2 text-sm max-w-max">
                {message?.message}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function isHtml(text: any) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

// STATUSES
const FlaggedActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, sectionName, sectionId, status } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-accent_1Light dark:bg-accent_1_14 text-textMid dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max">
      <FlagIcon fill="#BF200B" className="w-5 h-5 shrink-0" />
      <span>
        {message}{" "}
        {status === "flagged_section" && (
          <Link
            to={`#${sectionId}`}
            className="capitalize transition text-primary hover:text-primaryMid"
          >
            {sentenceCase(sectionName)}
          </Link>
        )}
      </span>
    </div>
  );
};

const TranscriptActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, sectionId, questionIds, sectionTitle, sectionName } =
    messageDetails;

  return (
    <div className="pl-12 mr-[10px] flex items-center dark:text-inputText rounded px-3 gap-2 text-sm max-w-max">
      <div className="flex items-start flex-col ml-[4.5px] gap-1 text-textMidLight dark:text-caption">
        <span className="text-textLight text-[13px] dark:text-textMain">
          Section{" "}
          <Link
            to={`#${sectionId}`}
            className="transition text-primary hover:text-primaryMid capitalize"
            replace
          >
            {" "}
            {sectionTitle || sentenceCase(sectionName)}
          </Link>
        </span>
        <span>
          {`${message} `}
          {questionIds?.map((field: any) => (
            <span key={field?.id}>
              <Link
                to={`#${sectionId}?q=${field?.name}`}
                className="transition text-primary hover:text-primaryMid capitalize"
                replace
              >
                {field?.title || convertToTitleCase(field?.name)}
              </Link>
              {questionIds?.length > 1 && ", "}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
};

const ArchivedActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, status } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-brandHover text-textMidLight dark:text-secondary rounded px-3 py-2 gap-2 text-sm max-w-max">
      {`${message} ${sentenceCase(status)}`}
    </div>
  );
};

const RequestedActivity = ({
  messageDetails,
  handleExpandAllPanels,
}: {
  messageDetails: any;
  handleExpandAllPanels: any;
}) => {
  const { users } = usePermissions();
  const { isExternalReviewer, isInternalReviewer } = useRoles();
  const { message, status, firstName, lastName, id, observationCode } =
    messageDetails;
  const name = firstName + " " + lastName;

  //   // Split the text
  const splitText = message?.split(/click here/i);

  // Get Observation Id from code
  const observationId = observationCode?.split("_").pop();

  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-accent_3Light text-textMidLight dark:text-caption rounded px-3 py-2 gap-2 text-sm max-w-max">
      <span>
        {users.canViewUsersProfile &&
        isInternalReviewer() &&
        !isExternalReviewer() ? (
          <Link
            to={users.canViewUsersProfile && `/profile/${id}/person`}
            className="transition text-primary hover:text-primaryMid"
          >
            {name}
          </Link>
        ) : (
          <div className="transition text-primary hover:text-primaryMid inline-block">
            {name}
          </div>
        )}{" "}
        {splitText[0]}
        <Link
          to={`/quality-assurance/${observationId}/profile`}
          className="transition text-primary hover:text-primaryMid"
          onClick={handleExpandAllPanels}
        >
          Click here
        </Link>
        {splitText[1]}
      </span>
    </div>
  );
};

const CompletedSectionActivity = ({
  messageDetails,
}: {
  messageDetails: any;
}) => {
  const { sectionName, sectionId, message, status } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-primary_8 text-textMid dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max">
      <CheckCircleIcon fill="#009F88" className="w-5 h-5 shrink-0" />
      <span>
        <Link
          to={`#${sectionId}`}
          className="capitalize transition text-primary hover:text-primaryMid"
          replace
        >
          {sectionName ? sentenceCase(sectionName) : ""}
        </Link>{" "}
        {message}
      </span>
    </div>
  );
};

const CompletedActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-start bg-primary_8 text-textMid dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max">
      {isHtml(message) ? (
        <div
          className="dark:text-caption break-words comment-activity transition"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        <>
          <CheckCircleIcon
            fill="#009F88"
            className="w-5 h-5 shrink-0 mt-[2px]"
          />
          {message}
        </>
      )}
    </div>
  );
};

const RequestGrantedActivity = ({
  messageDetails,
}: {
  messageDetails: any;
}) => {
  const { message, status, firstName, lastName, id } = messageDetails;
  const name = sentenceCase(firstName + " " + lastName);
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-primary_8 dark:bg-accent_1_14 text-textMid dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max capitalize">
      <CheckCircleIcon fill="#009F88" className="w-5 h-5 shrink-0" />
      {name + " " + message}
    </div>
  );
};

const IncompleteActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, flag } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-accent_1Light dark:bg-accent_1_14 dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max">
      <div className="flex items-center ml-[4.5px] gap-1 text-textMid dark:text-inputText">
        {message}
      </div>
    </div>
  );
};

const AssignedReviewerActivity = ({
  messageDetails,
}: {
  messageDetails: any;
}) => {
  const { message, status, firstName, lastName, id } = messageDetails;
  const { users } = usePermissions();
  const { isExternalReviewer, isInternalReviewer } = useRoles();
  const name = sentenceCase(firstName + " " + lastName);
  return (
    <div className="pl-12 mr-[10px] flex items-center dark:text-inputText rounded px-3 gap-2 text-sm max-w-max">
      <div className="flex items-center ml-[4.5px] gap-1 text-textMidLight dark:text-caption">
        <span>
          {isExternalReviewer() || isInternalReviewer() ? (
            <span className="text-primary">{name}</span>
          ) : (
            <>
              {users.canViewUsersProfile ? (
                <Link
                  to={users.canViewUsersProfile && `/profile/${id}/person`}
                  className="transition text-primary hover:text-primaryMid"
                >
                  {name}
                </Link>
              ) : (
                <div className="transition text-primary hover:text-primaryMid">
                  {name}
                </div>
              )}
            </>
          )}
          {" " + message}
        </span>
      </div>
    </div>
  );
};

const ExternalReviewerActivity = ({
  messageDetails,
}: {
  messageDetails: any;
}) => {
  const { message, status, firstName, lastName, id } = messageDetails;
  const { users } = usePermissions();
  const { isExternalReviewer, isInternalReviewer } = useRoles();

  const name = sentenceCase(firstName + " " + lastName);
  return (
    <div className="pl-12 mr-[10px] flex items-center dark:text-inputText rounded px-3 gap-2 text-sm max-w-max">
      <div className="flex items-center ml-[4.5px] gap-1 text-textMidLight dark:text-caption">
        <span>
          {isExternalReviewer() || isInternalReviewer() ? (
            <span className="text-primary">{name}</span>
          ) : (
            <>
              {users.canViewUsersProfile ? (
                <Link
                  to={users.canViewUsersProfile && `/profile/${id}/person`}
                  className="transition text-primary hover:text-primaryMid"
                >
                  {name}
                </Link>
              ) : (
                <div className="transition text-primary hover:text-primaryMid">
                  {name}
                </div>
              )}
            </>
          )}
          {" " + message}
        </span>
      </div>
    </div>
  );
};

const RestoreActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, status } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center bg-primary_8 text-textMid dark:text-inputText rounded px-3 py-2 gap-2 text-sm max-w-max">
      <RestoreIcon className="w-5 h-5 shrink-0" />
      <div className="flex items-center gap-1 text-textMid dark:text-inputText">
        {message + " " + sentenceCase(status)}
      </div>
    </div>
  );
};

const UpdatedActivity = ({ messageDetails }: { messageDetails: any }) => {
  const {
    message,
    fields,
    status,
    sectionId,
    sectionName,
    sectionTitle,
    questionIds,
  } = messageDetails;
  return (
    <div className="pl-12 mr-[10px] flex items-center dark:text-inputText rounded px-3 gap-2 text-sm max-w-max">
      <div className="flex items-start flex-col ml-[4.5px] gap-1 text-textMidLight dark:text-caption">
        <span className="text-textLight text-[13px] dark:text-textMain">
          Section{" "}
          <Link
            to={`#${sectionId}`}
            className="transition text-primary hover:text-primaryMid capitalize"
            replace
          >
            {" "}
            {sectionTitle || sentenceCase(sectionName)}
          </Link>
        </span>
        <span>
          {`${message} fields `}

          {questionIds?.map((field: any) => (
            <span key={field.id}>
              <Link
                to={`#${sectionId}?q=${field.name}`}
                className="transition text-primary hover:text-primaryMid capitalize"
                replace
              >
                {field.title || convertToTitleCase(field.name)}
              </Link>
              {questionIds.length > 1 && ", "}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
};

const CommentActivity = ({ message }: { message: any }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      if (target.tagName === "A") {
        event.preventDefault(); // Prevent default anchor click behavior
        const href = target.getAttribute("href"); // Get the href attribute

        if (href?.startsWith("#")) {
          // If it's an anchor link, use navigate with replace to avoid history stack entry
          navigate(href, { replace: true });
        } else if (href) {
          // Open external links or handle differently if needed
          window.open(href, "_blank");
        }
      }
    };

    // Add event listener to the container
    const current = ref.current;
    if (current) {
      current.addEventListener("click", handleAnchorClick as EventListener);
    }

    // Cleanup function to remove event listener
    return () => {
      if (current) {
        current.removeEventListener(
          "click",
          handleAnchorClick as EventListener
        );
      }
    };
  }, [navigate]); // Depend on navigate to recreate the effect if navigate changes

  const isHtml = (str: any) => /<\/?[a-z][\s\S]*>/i.test(str); // A simple check for HTML tags in the message

  return (
    <p className="pl-12 pr-4 text-sm text-secondaryMidLight">
      {isHtml(message) ? (
        <div
          ref={ref}
          className="ml-[4.5px] dark:text-caption break-words comment-activity transition"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        <div className="ml-[4.5px] dark:text-caption break-words">
          {message}
        </div>
      )}
    </p>
  );
};

const UnknownActivity = ({ messageDetails }: { messageDetails: any }) => {
  const { message, status } = messageDetails;
  return (
    <div className="ml-[55px] mr-[10px] flex items-center text-textMidLight dark:text-caption rounded px-3 py-2 gap-2 text-sm max-w-max">
      {`${message} ${sentenceCase(status ?? "")}`}
    </div>
  );
};

const convertToTitleCase = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
