import React, { useEffect } from "react";
import { Header, MediaGrid } from "Components/Programs/DataAccessPolicy";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { fetchDataAccessPolicyAction } from "store/DataAccessPolicy/reducer.actions";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useParams } from "react-router-dom";
import Content from "./Content";
import { IDataAccessPolicy } from "store/DataAccessPolicy/initialState";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


export const DataAccessPolicy = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const dataAccessPolicy = useSelector<RootState, IDataAccessPolicy>(
    (state) => state?.dataAccessPolicy
  );

  const { programId } = useParams<{ programId: string }>();

  useEffect(() => {
    if (programId) {
      dispatch(fetchDataAccessPolicyAction(programId));
    }
  }, [programId]);

  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full mt-10 gap-4 bg-bgWhite dark:bg-secondaryLight p-6 rounded-lg">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 mb-6">
              <p className="mb-3 text-secondaryMid dark:text-caption">
                Last updated:
                <span className="font-medium ml-2 ">
                  {dataAccessPolicy?.lastUpdated
                    ? `${moment(dataAccessPolicy?.lastUpdated).format(
                        "DD MMM YYYY"
                      )}`
                    : ""}
                </span>
              </p>
              <Content />
            </div>
            <MediaGrid />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataAccessPolicy;
