import React from "react";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";

const EditButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2 bg-white border-0 rounded-lg focus:outline-none"
    >
      <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1">
        <PencilAltIcon width={24} height={24} fill="#54595F" />
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
          <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-textMid dark:text-inputText hover:text-primary">
            Edit
          </p>
        </div>
      </div>
    </button>
  );
};

export { EditButton };
