import { HARVEST_QUESTIONS_NAME } from "../panelsQuestionsName";
import questionTypes from "../questionTypes";
import {
  convertJsonOfUpComingPointPicker,
  convertJsonOfUpComingShapePicker,
} from "./index";

function convertUpComingJsonOfHarvestPanel(ele: any) {
  const elements = ele.elements ? ele.elements : ele.templateElements ?? [];

  let element = {
    // ...ele,
    type: "panel",
    name: ele.name,
    titleLocation: ele.titleLocation,
    _id: ele?._id ?? ele?.id ?? ele?.Id,
    elements: elements,
    retrievalShapePicker: convertJsonOfUpComingShapePicker(
      elements.find(
        (e: any) => e.type === questionTypes.shapePicker && e.name === HARVEST_QUESTIONS_NAME.RetrievalLocation
      )
    ),
    struckShapePicker: convertJsonOfUpComingShapePicker(
      elements.find(
        (e: any) => e.type === questionTypes.shapePicker && e.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion
      )
    ),
    retrievalPointPicker: convertJsonOfUpComingPointPicker(
      elements.find(
        (e: any) => e.type === questionTypes.pointPicker && e.name === HARVEST_QUESTIONS_NAME.ValuedFeautres
      )
    ),
    struckPointPicker: convertJsonOfUpComingPointPicker(
      elements.find(
        (e: any) => e.type === questionTypes.pointPicker && e.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint
      )
    )
  };

  // delete ele.type;
  // delete ele.id;
  delete ele?.templateElements;
  // delete ele.panelType;

  element = { ...ele, ...element };

  return element;
}

export { convertUpComingJsonOfHarvestPanel };
