import { TOGGLE_SIDEBAR } from "./action-types";
import { initialState } from "./initialState";

const sidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isCollapsed: action.payload,
      };

    default:
      return state;
  }
};

export default sidebarReducer;
