import React from "react";
// Components
import { Date, SelectedCategories, SelectedTypes } from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { objectsResetFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting objectsFilter state from Redux store
  const { objectsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();

  const clearAllFilters = () => {
    dispatch(objectsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyObjectFilterApplied(objectsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <SelectedTypes />
          <Date
            name="dateAdded"
            from={objectsFilter.dateAdded.from}
            to={objectsFilter.dateAdded.to}
            isApplied={objectsFilter.dateAdded?.applied}
          />
          <SelectedCategories />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;




  // Function to check if any filter is applied
  export function isAnyObjectFilterApplied  (objectsFilter: any): boolean  {
    const { dateAdded, typeIds, categoryIds } = objectsFilter;
    const applied =
      dateAdded?.applied || typeIds?.applied || categoryIds?.applied;

    return applied;
  };
