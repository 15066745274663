import { apiClient } from "../config";

async function getAllPolls(queryParams: any) {
  const res = await apiClient.get(`/admin/survey-and-polls`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllPolls;
