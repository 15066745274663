import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Stepper } from "store/addReportStepper/initialState";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

export const chooseFromExistingOperators = [
  {
    name: "and",
    value: "and",
  },
  {
    name: "or",
    value: "or",
  },
];
interface RegionFilterItem {
  column: string;
  condition: string;
  operator: string;
  regions: any[];
}

interface RegionFilterItemProps {
  index: number;
  reportId?: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleBlur: any;
  removeFilterItem: () => void;
  columns: any[];
}

export const RegionFilterItem: React.FC<RegionFilterItemProps> = ({
  index,
  reportId,
  errors,
  values,
  touched,
  handleBlur,
  setFieldValue,
  removeFilterItem,
}) => {
  const { regions } = useSelector<RootState, Stepper>(
    (state) => state?.reportStepper
  );
  const regionFilterErrors: any = errors?.regionFilter
    ? errors.regionFilter[index]
    : {
      column: null,
      condition: null,
      operator: null,
      regions: null,
    };

  const regionFilterTouched = regionFilterErrors || {
    column: false,
    condition: false,
    operator: false,
    regions: false,
  };

  return (
    <div key={index} className="pb-1">
      <div className="flex items-center w-full gap-2 mb-1">
        <div>
          {index > 0 ? (
            <TextInput
              fieldAs="select"
              label=" "
              type="select"
              name={``}
              data={chooseFromExistingOperators}
              handleChange={(event: any) => {
                const { value } = event.target;
                setFieldValue(`regionFilter[${index}].operator`, value);
              }}
              handleBlur={handleBlur}
              value={values?.regionFilter[index]?.operator}
              error={regionFilterErrors?.operator}
              touched={regionFilterTouched}
            />
          ) : (
            <div className=" dark:text-textMain">
              <p className="mt-[5px]">Where</p>
            </div>
          )}
        </div>
        <div className="w-full">
          <TextInput
            fieldAs="select"
            label=" "
            type="select"
            name={``}
            data={regions}
            handleChange={(event: any) => {
              const { value } = event.target;
              console.log("handle change event", value);
              setFieldValue(`regionFilter[${index}].column`, value);
            }}
            handleBlur={handleBlur}
            value={values?.regionFilter[index]?.column?.name || null}
            error={regionFilterErrors?.column?.name}
            touched={regionFilterTouched}
          />
        </div>
        <div className="flex flex-col justify-start items-start  w-[104px] opacity-80 dark:border dark:border-lineLight ">
          <div className="flex flex-col items-start justify-start gap-1">
            <p className="flex justify-start items-center  gap-1.5 px-3 py-2 rounded bg-textMain dark:bg-secondaryLight dark:text-textMain">
              inside
            </p>
          </div>
        </div>

        <div className="relative justify-center items-center w-full max-w-[500px]">
          <div className="flex justify-start items-center py-1 h-[40px] overflow-x-auto max-w-96 min-w-96 px-2 gap-1.5 rounded bg-textMain dark:border-lineLight dark:bg-secondaryLight ">
            {values.regionFilter[index].regions.length > 0 &&
              values.regionFilter[index].regions.map((region: any) => {
                return (
                  <div className="flex justify-center items-center px-3 py-1.5 rounded bg-primary/[0.14] dark:bg-primary_40">
                    <p className="text-xs text-center whitespace-nowrap text-secondaryMid">
                      {region.name}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="absolute flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 py-0.5">
            {regionFilterErrors?.regions && (
              <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
                {regionFilterErrors?.regions}
              </p>
            )}
          </div>
        </div>

        <button type="button" onClick={removeFilterItem} title="Remove filter">
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default RegionFilterItem;
