import { apiClient } from "../config";

async function getVectorLayersByCommunityId(
  communityId: string,
  layerType: any,
  params?:any
) {
  const res = await apiClient.get(
    `/admin/communities/${communityId}/vector-layers?type=${layerType}`,{
      params
    }
  );
  return res.data;
}

export default getVectorLayersByCommunityId;
