import React, { useEffect, useRef, useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import { IfetchPrograms } from "store/programs/initialState";
import ProgramsColumnModal from "view/components/Modals/Programs/ProgramsColumnModal";
import { isProgramFiltersApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { data } = useSelector<RootState, IfetchPrograms>(
    (state) => state?.allprograms
  );
  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

  useEffect(() => {
    setIsCloseDiv(isProgramFiltersApplied(programsFilter));
  }, []);
  // Initializing event listener
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      {isOpen && (
        <ProgramsColumnModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columns={columns.filter((item: any) => item.id !== "select")}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
      )}
    </>
  );
};
