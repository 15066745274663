import React, { useState } from "react";
import _ from "lodash";
import EmailCreator from "./components";
import { FieldArray } from "formik";
import AddAnotherButton from "../AddAnotherBtn";
import { UserProfileState } from "store/userProfile/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import Button from "view/components/Button";
import { MaterialUiSelect } from "view/pages/MyProfile/Components/Inputs/Select";
import ChangePrimaryEmailAddress from "./components/ChangePrimaryEmailAddress";
import * as Yup from "yup";

const EmailBox: React.FC<{
  values: any;
  errors: any;
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  updatePersonData: any;
  isLoading: boolean;
  refreshNewData: (data: any) => void;
}> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  updatePersonData,
  isLoading,
  refreshNewData,
}) => {
  const [newEmail, setNewEmail] = useState<string>(""); // Initialize with an empty string
  const [hasError, setHasError] = useState<string | null>(null);

  const validateEmail = async (email: string) => {
    try {
      await Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required.")
        .validate(email);
      setHasError(null);
    } catch (error: any) {
      setHasError(error.message);
    }
  };

  const addNewEmail = async () => {
    // Validate email
    await validateEmail(newEmail);

    if (!hasError) {
      // Proceed to update the list of secondary emails
      const updatedValue = { ...values };

      const updatedEmails = [
        ...updatedValue.secondaryEmail,
        {
          email: newEmail,
          isPrimary: false,
          isVerified: false,
        },
      ];

      updatedValue.secondaryEmail = updatedEmails;

      await updatePersonData(updatedValue);
      setNewEmail(""); // Reset email field after adding
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full border-b border-lineMid dark:border-lineLight">
      <ChangePrimaryEmailAddress refreshNewData={refreshNewData} />

      <FieldArray name="secondaryEmail">
        {({ push, remove }) => (
          <div className="w-full my-3">
            <div className="w-full border rounded-md border-lineMid dark:border-lineLight">
              {values.secondaryEmail.map((contact: any, index: number) => (
                <EmailCreator
                  remove={remove}
                  key={index}
                  values={values}
                  currentEmailIndex={index}
                  contact={contact}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  refreshNewData={refreshNewData}
                />
              ))}
            </div>

            <div className="flex items-center">
              <div className="w-[300px]">
                <TextInput
                  label="Add email address"
                  type="email"
                  placeholder={"Email address"}
                  name="email"
                  error={hasError}
                  touched={true}
                  handleChange={(e: any) => {
                    setNewEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  handleBlur={handleBlur}
                  value={newEmail}
                />
              </div>
              <button
                disabled={!newEmail || hasError !== null || isLoading}
                type="button"
                className={`border disabled:cursor-not-allowed border-lineMid dark:border-lineLight rounded ml-3 w-[70px] h-[40px] ${hasError ? "mt-2" : "mt-6"} bg-primary text-textMain dark:text-caption`}
                onClick={addNewEmail}
              >
                Add
              </button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export { EmailBox };
