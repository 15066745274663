import DownloadIcon from "assets/icons/HeroIcons/DownloadIcon";
import { AddDownloadBtnProps } from "../../types";
import { useParams } from "react-router-dom";

const DownloadReportBtn = ({ handleDownload }: AddDownloadBtnProps) => {
  const {reportsId, versionId} = useParams();
  return (
    <div
      onClick={()=>{handleDownload(reportsId,versionId)}}
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary dark:border-borderSilverBlue hover:bg-primaryExtraLight dark:bg-secondaryLight dark:hover:bg-primary_8 dark:hover:border-primary group transition cursor-pointer"
    >
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
        <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary flex gap-2">
          <DownloadIcon fill="#005C89"/>
          Download
        </p>
      </div>
    </div>
  );
};

export { DownloadReportBtn };
