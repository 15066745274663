import React from "react";
import { PrimaryContactDetails } from "./PrimaryContactDetails";
import { SecondaryContactDetails } from "./SecondaryContactsDetails";

interface ContactDetailsProps {
  editMode: boolean;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ editMode }) => {
  return (
    <>
      <PrimaryContactDetails editMode={editMode} />
      <SecondaryContactDetails editMode={editMode} />
    </>
  );
};

export { ContactDetails };
