import React, { useEffect, useLayoutEffect, useState } from "react";
import BpCheckbox from "view/components/CheckBox";
import "./Categories.scss";
import apiLibrary from "services/api";
import InputField from "view/components/InputField";
import { useDispatch } from "react-redux";
import { typesCategoriesAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ellipsize from "ellipsize";
import { filtersInitialState } from "store/filters/initialState";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import _ from "lodash";

export const Categories = () => {
  const { typesFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const [searchString, setSearchString] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const [dropDownValues, setDropDownValues] = useState<any[]>([]);

  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedValue: any
  ) => {
    dispatch(typesCategoriesAction(selectedValue));
  };

  const fetchOrgDropdownData = async (
    isLoadMore: boolean,
    page: number = 1,
    search?: string
  ) => {
    setIsLoading(true);
    try {
      const { data } = await apiLibrary.SpeciesAndObjects.getCategoriesDropdown(
        search,
        page
      );
      const CategoriesData = data.map((item: any) => {
        return { id: item.id, name: item.categoryName };
      });
      if (isLoadMore) {
        setDropDownValues((pre: any[]) => {
          return [...pre, ...CategoriesData];
        });
      } else {
        setDropDownValues(CategoriesData);
      }
      setHasMoreData(data.hasMore);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      console.error("Failed to fetch program dropdown values:", error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOrgDropdownData(false);
  }, []);
  const handleSearchEntities = (searchString: string): void => {
    setPage((pre: number) => {
      fetchOrgDropdownData(false, 1, searchString);
      return 1;
    });
  };

  const debouncedSearch = _.debounce(handleSearchEntities, 100);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchOrgDropdownData(true, page, searchString);
      return page;
    });
  };

  return (
    <div className="bg-bgWhite  w-[315px] overflow-hidden shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid">
      <div className="w-full">
        <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
          <InputField
            placeholder="Search"
            type="Search"
            onChange={(e: any) => {
              setSearchString(e.target.value);
              debouncedSearch(e.target.value);
            }}
          />
        </div>
        <div className="pl-1.5 bg-bgWhite dark:bg-secondaryLight">
          <InfiniteScroll
            dataLength={dropDownValues.length}
            next={handleLoadMore}
            hasMore={hasMoreData}
            height={400}
            loader={
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            }
            pullDownToRefresh={true}
            refreshFunction={() => { }}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh &#8595;
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh &#8593;
              </h3>
            }
          >
            {dropDownValues.length === 0 && !isLoading && (
              <div className="w-full flex items-center justify-center py-32 text-textMid font-semibold dark:text-inputText">
                No Record Found
              </div>
            )}

            {hasMoreData && isLoading && (
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            )}
            {dropDownValues.map((dropDown: any, index: any) => (
              <div
                key={index}
                className="flex items-center justify-start w-full px-1 rounded-lg"
              >
                <BpCheckbox
                  checked={typesFilter.categoryIds.value.some(
                    (item: any) => item.id === dropDown.id
                  )}
                  label={
                    <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
                      <p className="dark:text-inputText">
                        {ellipsize(`${dropDown.name}`, 35)}
                      </p>
                    </div>
                  }
                  labelPlacement="end"
                  onChange={(e) => handleSelection(e, dropDown)}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );

  // const [totalPages, setTotalPages] = useState(1);
  // const [isLoading, setIsLoading] = useState(false);
  // const [hasMoreData, setHasMoreData] = useState(true);
  // const [page, setPage] = useState(1);

  // const fetchOrgDropdownData = async (
  //   isLoadMore: boolean,
  //   page: number = 1,
  //   search?: string
  // ) => {
  //   setIsLoading(true);
  //   try {
  //     const { data } = await apiLibrary.SpeciesAndObjects.getCategoriesDropdown(search, page);
  //     const allTags = data.tags.map((item: any) => {
  //       return { id: item.id, name: item.name };
  //     });
  //     if (isLoadMore) {
  //       setDropDownValues((pre: any[]) => {
  //         return [...pre, ...allTags];
  //       });
  //     } else {
  //       setDropDownValues(allTags);
  //     }
  //     setHasMoreData(data.hasMore);
  //     setTotalPages(data.totalPages);
  //     setHasMoreData(data.totalPages > page);
  //   } catch (error: any) {
  //     // Handle error
  //     console.error("Failed to fetch program dropdown values:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchOrgDropdownData(false);
  // }, []);

  // const handleSearchEntities = (searchString: string): void => {
  //   setPage((pre: number) => {
  //     fetchOrgDropdownData(false, 1, searchString);
  //     return 1;
  //   });
  // };

  // const debouncedSearch = _.debounce(handleSearchEntities, 100);

  // const handleLoadMore = () => {
  //   setPage((prevPage) => {
  //     const page = prevPage + 1;
  //     fetchOrgDropdownData(true, page, searchString);
  //     return page;
  //   });
  // };

  // return (
  //   <div className="bg-bgWhite dark:bg-secondaryLight  min-w-[315px]  shadow-xl rounded-xl flex flex-col justify-start items-start relative  border border-lineMid dark:border-lineLight">
  //     <div className="w-full">
  //       <div className="w-full pl-3 pr-3 mt-2 mb-3 bg-bgWhite dark:bg-secondaryLight ">
  //         <InputField
  //           placeholder="Search"
  //           type="Search"
  //           onChange={(e: any) => {
  //             setSearchString(e.target.value);
  //             debouncedSearch(e.target.value);
  //           }}
  //         />
  //       </div>
  //       <div className="pl-1.5 bg-bgWhite dark:bg-secondaryLight">
  //         <InfiniteScroll
  //           dataLength={dropDownValues.length}
  //           next={handleLoadMore}
  //           hasMore={hasMoreData}
  //           height={400}
  //           loader={
  //             <div className="flex items-center justify-center w-full loader">
  //               <TailSpin
  //                 height="30"
  //                 width="30"
  //                 color="#005C89"
  //                 ariaLabel="tail-spin-loading"
  //                 radius="2"
  //                 wrapperStyle={{}}
  //                 wrapperClass="tailspin-loader"
  //                 visible={true}
  //               />
  //             </div>
  //           }
  //           pullDownToRefresh={true}
  //           refreshFunction={() => {}}
  //           pullDownToRefreshContent={
  //             <h3 style={{ textAlign: "center" }}>
  //               &#8595; Pull down to refresh &#8595;
  //             </h3>
  //           }
  //           releaseToRefreshContent={
  //             <h3 style={{ textAlign: "center" }}>
  //               &#8593; Release to refresh &#8593;
  //             </h3>
  //           }
  //         >
  //           {dropDownValues.map((dropDown: any, index: any) => (
  //             <div
  //               key={index}
  //               className="flex items-center justify-start w-full px-1 rounded-lg"
  //             >
  //               <BpCheckbox
  //                 checked={programsFilter.tagIds.value.some(
  //                   (item: any) => item.id === dropDown.id
  //                 )}
  //                 label={
  //                   <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
  //                     <p className="dark:text-inputText">
  //                       {ellipsize(`${dropDown.name}`, 35)}
  //                     </p>
  //                   </div>
  //                 }
  //                 labelPlacement="end"
  //                 onChange={(e) => handleSelection(e, dropDown)}
  //               />
  //             </div>
  //           ))}
  //         </InfiniteScroll>
  //       </div>
  //     </div>
  //   </div>
  // );
};
