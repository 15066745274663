import { point, featureCollection } from "@turf/turf";

import {
  GET_PROGRAM_PROFILE,
  RESET_PROGRAM_PROFILE,
  PROGRAM_PROFILE_LOADING_STATE
} from "./action-types";
import { initialState } from "./initialState";

export const programProfileReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case PROGRAM_PROFILE_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROGRAM_PROFILE:
      return {
        ...action.payload,
        isLoading: false,
      };
    case RESET_PROGRAM_PROFILE:
      return initialState;
    default:
      return state;
  }
};



function formatNumber(num: any) {
  if (num < 1000) {
    return num.toString(); // Return the number as is if less than 1000
  }
  const units = ["k", "m", "b", "t"]; // Units for thousands, millions, billions, trillions
  let unitIndex = -1; // Index for units array

  // Reduce the number and increase the unit index until the number is small enough
  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
  }

  // Format the number to one decimal place if not an integer
  if (num % 1 !== 0) {
    num = num.toFixed(1);
  } else {
    num = Math.floor(num);
  }

  // Return the formatted number with the appropriate unit
  return num + units[unitIndex];
}



function coordToGeoJSON(coord: [number, number], properties?: any) {
  console.log(coord);
  // Create a point feature using Turf.js
  const pointFeature = point(coord);

  if (properties) {
    pointFeature.properties = properties;
  }

  // Create a feature collection with the point feature
  const geoJSONFeatureCollection = featureCollection([pointFeature]);

  return geoJSONFeatureCollection;
}


