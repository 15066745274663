import React, { useCallback, useEffect, useLayoutEffect } from "react";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import TextInputField from "view/components/InputField";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { filtersInitialState } from "store/filters/initialState";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { reportsSearchAction, usersSearchAction } from "store/filters";

const SearchBar = () => {
  const [showInput, setShowInput] = useState(false);

  const inputDivRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );

  // Close the main search input if search by is not empty
  useEffect(() => {
    if (usersFilter.searchBy) {
      setShowInput(false)
    }
  }, [usersFilter.searchBy])

  useLayoutEffect(() => {
    if (usersFilter.searchString !== "" && usersFilter.searchBy === "") {
      setShowInput(true)
    }

  }, [usersFilter.searchString])


  const handleOutsideClick = useCallback((event: any) => {
    if (inputFieldRef.current) {
      if (inputFieldRef.current.value === "") {
        setShowInput(false);
        return
      }

    }
    if (
      inputDivRef.current &&
      !inputDivRef.current.contains(event.target as Node) &&
      usersFilter.searchString === ""
    ) {
      setShowInput(false);
    }
  }, [usersFilter.searchString]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);


  // useEffect(() => {
  //   return () => {
  //     dispatch(usersSearchAction(""));
  //   };
  // }, []);

  useEffect(() => {
    if (showInput) {
      const inputField = document.querySelector(
        ".getInput"
      ) as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showInput]);

  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput((prevState: any) => !prevState);
  };
  return (
    <div className="flex py-[12px]">
      <div onClick={toggleInputVisibility} className="cursor-pointer mr-7">
        <SearchIcon
          className={` ${showInput ? "mt-[5px]" : "mt-[-12px]"} absolute `}
        />
      </div>

      <div
        className={`${showInput ? "block" : "hidden"
          } border-2 border-t-0 border-l-0 border-r-0 border-b-primaryMid  pb-2`}
        ref={inputDivRef}
      >
        <TextInputField
          className="bg-bgBluish w-[250px] getInput mt-1 dark:bg-[transparent] dark:text-inputText"
          placeholder="Search"
          type="search"
          ref={inputFieldRef}
          fieldAs="searchData"
          value={usersFilter.searchBy !== "" ? "" : usersFilter.searchString}
          onChange={(e: any) => {
            dispatch(usersSearchAction(e.target.value, ""));
          }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
