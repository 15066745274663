import React, { useState } from "react";
/************ Store Utils ************/
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

/*********** Components  *************/
import { NewAccountSetup } from "Components/Profile/Account";

export const Account = () => {
  const [editMode, setEditMode] = useState(false);
  const { personId } = useParams();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  return (
    <NewAccountSetup
      editMode={editMode}
      setEditMode={setEditMode}
      personId={personId}
      AccountCreated={userProfile.user.id !== null}
    />
  );
};
