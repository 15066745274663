import * as actionTypes from "./action-types";

export const usersDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_USERS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const usersDirectoryColumnsVisibilityAction = (visibility: any) => {
  return {
    type: actionTypes.CHANGE_USERS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetUsersDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_USERS_DIRECTORY_COLUMNS,
  };
};

export const communitiesDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const communitiesDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetCommunnitiesDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_COMMUNITIES_DIRECTORY_COLUMNS,
  };
};

export const organizationsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const organizationsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetOrganizationsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_ORGANIZATIONS_DIRECTORY_COLUMNS,
  };
};

export const dynamicFormsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const dynamicFormsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetDynamicFormsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_DYNAMIC_FORMS_DIRECTORY_COLUMNS,
  };
};

export const updateColumnsVisibilityByStatus = (status: any) => {
  return {
    type: actionTypes.UPDATE_DYNAMIC_FORMS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: status,
  };
};
export const notificationsRecipientsDirectoryColumnsOrderAction = (
  order: any
) => {
  return {
    type: actionTypes.CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const notificationsRecipientsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetnotificationsRecipientsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS,
  };
};

export const updatenotificationsRecipientsColumnsVisibilityByStatus = (
  status: any
) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_RECIPIENTS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: status,
  };
};

export const protocolsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};
export const updateColumnsVisibilityOfProtocolsById = (
  programId: any,
) => {
  return {
    type: actionTypes.UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_ID,
    payload: programId,
  };
};
export const protocolsDirectoryColumnsVisibilityAction = (visibility: any) => {
  return {
    type: actionTypes.CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetProtocolsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_PROTOCOLS_DIRECTORY_COLUMNS,
  };
};

export const updateProtocolsColumnsVisibilityByStatus = (status: any) => {
  return {
    type: actionTypes.UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: status,
  };
};

export const programsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_PROGRAMS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const programsDirectoryColumnsVisibilityAction = (visibility: any) => {
  return {
    type: actionTypes.CHANGE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const updateProgramsDirectoryColumnsVisibilityByStatus = (status: any) => {
  return {
    type: actionTypes.UPDATE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY_BY_STATUS,
    payload: status,
  };
};
export const resetProgramsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_COMMUNITIES_DIRECTORY_COLUMNS,
  };
};

export const observationsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const observationsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetObservationsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_OBSERVATIONS_DIRECTORY_COLUMNS,
  };
};

export const updateColumnsVisibilityOfObservationsByStatus = (path: string) => {
  return {
    type: actionTypes.UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: path,
  };
};
export const updateColumnsVisibilityOfObservations = (Id?: any) => {
  return {
    type: actionTypes.UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY,
    payload: Id,
  };
};

export const qualityAssuranceObservationsDirectoryColumnsOrderAction = (
  order: any
) => {
  return {
    type: actionTypes.CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const qualityAssuranceObservationsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetQualityAssuranceObservationsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS,
  };
};

export const updateColumnsVisibilityOfQualityAssuranceObservationsByStatus = (
  status: string,
  role?: boolean
) => {
  return {
    type: actionTypes.UPDATE_QUALITY_ASSURANCE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: { status, role },
  };
};

export const managersDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_MANAGERS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const managersDirectoryColumnsVisibilityAction = (visibility: any) => {  
  return {
    type: actionTypes.CHANGE_MANAGERS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetManagersDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_MANAGERS_DIRECTORY_COLUMNS,
  };
};

export const updateColumnsVisibilityOfManagersByStatus = (type: string) => {
  return {
    type: actionTypes.UPDATE_MANAGERS_COLUMNS_VISIBILITY_BY_TYPE,
    payload: type,
  };
};

export const notificationsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const notificationsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetNotificationsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_NOTIFICATIONS_DIRECTORY_COLUMNS,
  };
};
export const reportsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_REPORTS_COLUMNS_ORDER,
    payload: order,
  };
};

export const reportsDirectoryColumnsVisibilityAction = (visibility: any) => {
  return {
    type: actionTypes.CHANGE_REPORTS_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetreportsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_REPORTS_COLUMNS,
  };
};

export const reportsProfileDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_REPORTS_PROFILE_COLUMNS_ORDER,
    payload: order,
  };
};

export const reportsProfileDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_REPORTS_PROFILE_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetreportsProcessedDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_REPORTS_PROFILE_COLUMNS,
  };
};
export const reportsResultDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_REPORTS_RESULT_COLUMNS_ORDER,
    payload: order,
  };
};

export const reportsResultDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_REPORTS_RESULT_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetReportsResultDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_REPORTS_RESULT_COLUMNS,
  };
};

export const reportsAnalyticsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_REPORTS_ANALYTTICS_COLUMNS_ORDER,
    payload: order,
  };
};

export const reportsAnalyticsDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_REPORTS_ANALYTTICS_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const geographiesDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const geographiesDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const importsDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_IMPORTS_COLUMNS_ORDER,
    payload: order,
  };
};

export const importsDirectoryColumnsVisibilityAction = (visibility: any) => {
  return {
    type: actionTypes.CHANGE_IMPORTS_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetpollsAndSurveysDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS,
  };
};

export const resetImportsDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_IMPORT_COLUMNS,
  };
};

export const pollsAndSurveysDirectoryColumnsOrderAction = (order: any) => {
  return {
    type: actionTypes.CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_ORDER,
    payload: order,
  };
};

export const pollsAndSurveysDirectoryColumnsVisibilityAction = (
  visibility: any
) => {
  return {
    type: actionTypes.CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_VISIBILITY,
    payload: visibility,
  };
};

export const resetPollsAndSurveysDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS,
  };
};

export const updatePollsAndSurveysColumnsVisibilityByStatus = (status: any) => {
  return {
    type: actionTypes.UPDATE_POLLS_AND_SURVEYS_COLUMNS_VISIBILITY_BY_STATUS,
    payload: status,
  };
};

export const joinRequestDirectoryChangeColumnOrderAction = (status: any) => {
  return {
    type: actionTypes.CHANGE_JOIN_REQUEST_COLUMN_COLUMN_ORDER,
    payload: status,
  };
};

export const resetjoinRequestDirectoryOrderAction = () => {
  return {
    type: actionTypes.RESET_JOIN_REQUEST_COLUMNS,
  };
};